import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import "./tag.scss";

const TagPropTypes = {
  /**
   * The style this tag will be rendered in
   *
   * @default 'base'
   */
  brand: PropTypes.oneOf(["primary", "success", "danger", "base"]),
  /**
   * If the tag should be rendered as a pill and have rounded corners
   */
  pill: PropTypes.bool,
  /**
   * The content that will be rendered in the tag
   */
  children: PropTypes.node.isRequired,
  /**
   * The content that will be displayed as the title attribute on the wrapper
   * tag
   */
  title: PropTypes.string,
  /**
   * The extra classes that will be added to the wrapper tag
   */
  className: PropTypes.string,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof TagPropTypes>>}
 */
export const Tag = ({ brand, pill, children, className, title }) => {
  return (
    <div title={title} className={clsx(className, "custom-columns-tag", `is-${brand}`, { "is-pill": pill })}>
      {children}
    </div>
  );
};

Tag.defaultProps = {
  brand: "base",
  pill: false,
};

Tag.propTypes = TagPropTypes;
