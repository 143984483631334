import React, { Component } from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import Modal from "simple-react-modal";
import Textarea from "react-expanding-textarea";
import ReactTimeout from "react-timeout";

import {
  deleteNotification,
  sendNotification,
  awardLots,
} from "../../../../../actions/qq/emarketplace/emSummaryActions";
import AwardToolTips from "./AwardToolTips";
import formatData from "../../../js/languageFormatter";
import { fetchConfigurationLimit } from "../../../../../actions/qq/qqActions";
import RenderHTML from "@/components/common/RenderHTML";

class AwardLots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModel: false,
      showAwardNotice: false,
      awardedParticipantsList: [],
      notifiedParticipantslist: [],
      totalBidValue: 0,
      awardNoticeMessage: this.props.translations.emarketplace.award_alloted_email,
      pusrchaseOrderNumber: "",
      ccEmail: "",
      successMessage: "",
      errorMessage: "",
      flag: false,
    };
  }

  async UNSAFE_componentWillMount() {
    if (this.props.user) {
      await this.props.fetchConfigurationLimit(this.props.user.company_id);
    }
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    let bidValues = 0;
    const checkAwardedRecord = [];
    let isAlreadyAwarded = false;
    let flag = false;
    const refThis = this;
    if (newProps.emSummary.awardedLotsDetails) {
      await newProps.emSummary.awardedLotsDetails.forEach(async (lot) => {
        const allBids = await lot.bids.filter((bid) => this.props.declinedParticipantsList.indexOf(bid.user_id) === -1);
        let minValue = await _.minBy(allBids, "price");
        const tiedBids = minValue ? await allBids.filter((bid) => minValue.price === bid.price) : [];
        minValue = tiedBids.length > 1 ? undefined : minValue;
        await lot.bids.forEach(async (bid) => {
          if (lot.supplier_user_id === bid.user_id && this.props.declinedParticipantsList.indexOf(bid.user_id) === -1) {
            bidValues += _.round(lot.quantity * bid.price, 2);
            const awardRecord = { lotId: lot.id, name: lot.name, participant: bid };
            checkAwardedRecord.push(awardRecord);
            isAlreadyAwarded = true;
            flag = true;
          } else if (!lot.supplier_user_id) {
            if (refThis.refs[`${lot.id}BidValue`]) {
              let bidString = refThis.refs[`${lot.id}BidValue`].innerHTML.replace(/[^/\d]/g, "");
              bidString = bidString.replace(lot.bids[0].currency.symbol, "");
              const selectedBid = `${bidString.slice(-0, -2)}.${bidString.slice(-2)}`;
              const bidValue = Number(selectedBid);
              if (bidValue) {
                const awardRecord = this.state.awardedParticipantsList.filter((item) => item.lotId === lot.id)[0];
                checkAwardedRecord.push(awardRecord);
                bidValues += bidValue;
              } else if (
                minValue &&
                minValue.user_id === bid.user_id &&
                this.props.declinedParticipantsList.indexOf(bid.user_id) === -1
              ) {
                bidValues += _.round(lot.quantity * bid.price, 2);
                const awardRecord = { lotId: lot.id, name: lot.name, participant: bid };

                checkAwardedRecord.push(awardRecord);
                if (refThis.refs[`${lot.id}ParticipantValue`]) {
                  refThis.refs[`${lot.id}ParticipantValue`].value = bid.user_id;
                }
                refThis.refs[`${lot.id}BidValue`].innerHTML = formatData(
                  this.props.selectedLang,
                  bid.currency.symbol,
                  _.round(lot.quantity * bid.price, 2)
                );
              }
            } else {
              const awardRecord = { lotId: lot.id, name: lot.name, participant: null };
              checkAwardedRecord.push(awardRecord);
            }
          }
        });
      });
      if (isAlreadyAwarded) {
        this.setState({
          flag,
          totalBidValue: _.round(bidValues, 2),
          awardNoticeMessage: this.props.translations.emarketplace.award_alloted_email,
          awardedParticipantsList: checkAwardedRecord,
        });
      } else {
        this.setState({
          flag,
          awardNoticeMessage: this.props.translations.emarketplace.award_alloted_email,
        });
      }
    }
  }

  async showAwardPopup() {
    const refThis = this;
    let bidValues = 0;
    const checkAwardedRecord = [];
    if (
      this.props.qqDetails.limits &&
      this.props.qqDetails.limits.cc_on_award_lot &&
      this.props.qqDetails.limits.cc_on_award_lot_email
    ) {
      this.setState({ ccEmail: this.props.qqDetails.limits.cc_on_award_lot_email });
    }
    await this.setState({ showModel: true }, async () => {
      if (this.props.emSummary.awardedLotsDetails && !this.state.flag) {
        await this.props.emSummary.awardedLotsDetails.forEach(async (lot) => {
          const allBids = await lot.bids.filter(
            (bid) => this.props.declinedParticipantsList.indexOf(bid.user_id) === -1
          );
          let minValue = await _.minBy(allBids, "price");
          const tiedBids = minValue ? await allBids.filter((bid) => minValue.price === bid.price) : [];
          minValue = tiedBids.length > 1 ? undefined : minValue;
          await lot.bids.forEach(async (bid) => {
            if (
              lot.supplier_user_id === bid.user_id &&
              this.props.declinedParticipantsList.indexOf(bid.user_id) === -1
            ) {
              bidValues += lot.quantity * bid.price;
              const awardRecord = { lotId: lot.id, name: lot.name, participant: bid };
              checkAwardedRecord.push(awardRecord);
            } else if (
              !lot.supplier_user_id &&
              minValue &&
              minValue.user_id === bid.user_id &&
              this.props.declinedParticipantsList.indexOf(bid.user_id) === -1
            ) {
              bidValues += lot.quantity * bid.price;
              const awardRecord = { lotId: lot.id, name: lot.name, participant: bid };
              checkAwardedRecord.push(awardRecord);
              if (refThis.refs[`${lot.id}ParticipantValue`]) {
                refThis.refs[`${lot.id}ParticipantValue`].value = bid.user_id;
              }
              refThis.refs[`${lot.id}BidValue`].innerHTML = formatData(
                this.props.selectedLang,
                bid.currency.symbol,
                _.round(lot.quantity * bid.price, 2)
              );
            }
          });
          this.setState({
            totalBidValue: _.round(bidValues, 2),
            awardedParticipantsList: checkAwardedRecord,
          });
        });
      }
    });
  }

  async closeAwardPopup() {
    let bidValues = 0;
    let flag = false;
    await this.props.emSummary.awardedLotsDetails.forEach((lot) => {
      lot.bids.forEach((bid) => {
        if (lot.supplier_user_id === bid.user_id && this.props.declinedParticipantsList.indexOf(bid.user_id) === -1) {
          bidValues += lot.quantity * bid.price;
          flag = true;
        }
      });
    });
    this.setState({
      showModel: false,
      showAwardNotice: false,
      notifiedParticipantslist: [],
      flag,
    });
  }

  async changeParticipant(event, lot) {
    const refThis = this;
    const lots = this.props.emSummary.awardedLotsDetails;
    refThis.refs[`${lot.id}Spinner`].classList.remove("hidden");
    lots.forEach((item) => {
      if (refThis.refs[`${item.id}ParticipantValue`]) {
        refThis.refs[`${item.id}ParticipantValue`].disabled = true;
      }
    });
    const participantId = Number(event.target.value);
    const participant = lot.bids.filter((item) => item.user_id === participantId)[0];
    let awardedLot = {};
    if (participant) {
      const awardRecord = {
        lotId: lot.id,
        name: lot.name,
        participant,
        position: lot.position,
      };
      awardedLot = awardRecord;
      const checkAwardedRecord = await this.state.awardedParticipantsList.filter(
        (item) => item.lotId !== awardRecord.lotId
      );
      const checkNotifiedRecord = await this.state.notifiedParticipantslist.filter(
        (item) => item.lotId !== awardRecord.lotId
      );
      checkAwardedRecord.push(awardRecord);
      checkNotifiedRecord.push(awardRecord);
      this.setState({
        awardedParticipantsList: checkAwardedRecord,
        notifiedParticipantslist: checkNotifiedRecord,
      });
      refThis.refs[`${lot.id}BidValue`].innerHTML = formatData(
        this.props.selectedLang,
        participant.currency.symbol,
        _.round(participant.price * lot.quantity, 2)
      );
      refThis.refs[`${lot.id}IncludeButton`].classList.add("btn-success");
      refThis.refs[`${lot.id}RemoveButton`].classList.remove("btn-danger");
    } else {
      const awardRecord = {
        lotId: lot.id,
        name: lot.name,
        participant: null,
        position: lot.position,
      };
      awardedLot = awardRecord;
      const checkAwardedRecord = await this.state.awardedParticipantsList.filter((item) => item.lotId !== lot.id);
      const checkNotifiedRecord = await this.state.notifiedParticipantslist.filter((item) => item.lotId !== lot.id);
      checkAwardedRecord.push(awardRecord);
      this.setState({
        awardedParticipantsList: checkAwardedRecord,
        notifiedParticipantslist: checkNotifiedRecord,
      });
      refThis.refs[`${lot.id}BidValue`].innerHTML = "-";
      refThis.refs[`${lot.id}IncludeButton`].classList.remove("btn-success");
      refThis.refs[`${lot.id}RemoveButton`].classList.remove("btn-danger");
    }
    let totalBids = 0;
    this.props.emSummary.awardedLotsDetails.forEach((item) => {
      const bidString = (refThis.refs[`${item.id}BidValue`].innerHTML)
        .replace(/[^/\d.]/g, '')
        .replace(item.bids[0].currency.symbol, '');

      const bid = parseFloat(bidString);
      if (!isNaN(bid)) {
        totalBids += bid;
      }
    });

    this.setState({
      totalBidValue: _.round(totalBids, 2),
    });
    await this.props.awardLots([awardedLot], this.props.requestId);
    lots.forEach((item) => {
      if (refThis.refs[`${item.id}ParticipantValue`]) {
        refThis.refs[`${item.id}ParticipantValue`].disabled = false;
      }
    });
    refThis.refs[`${lot.id}Spinner`].classList.add("hidden");
  }

  async includeInAwardNotice(event, lot) {
    const refThis = this;
    refThis.refs[`${lot.id}Spinner`].classList.remove("hidden");
    const { classList } = refThis.refs[`${lot.id}IncludeButton`];
    const participant = lot.bids.filter(
      (item) => item.user_id === Number(refThis.refs[`${lot.id}ParticipantValue`].value)
    )[0];
    if (participant) {
      const record = {
        lotId: lot.id,
        name: lot.name,
        participant,
        position: lot.position,
      };
      if (classList.contains("btn-success")) {
        classList.remove("btn-success");
        const checkNotifiedRecord = await this.state.notifiedParticipantslist.filter(
          (item) => item.lotId !== record.lotId
        );
        this.setState({
          notifiedParticipantslist: checkNotifiedRecord,
        });
        await this.props.awardLots([record], this.props.requestId);
      } else {
        classList.add("btn-success");
        const checkNotifiedRecord = await this.state.notifiedParticipantslist.filter(
          (item) => item.lotId !== record.lotId
        );
        checkNotifiedRecord.push(record);
        this.setState({
          notifiedParticipantslist: checkNotifiedRecord,
        });
        refThis.refs[`${lot.id}RemoveButton`].classList.remove("btn-danger");
      }
    }
    refThis.refs[`${lot.id}Spinner`].classList.add("hidden");
  }

  async removeFromAwardNotice(event, lot) {
    const refThis = this;
    const { classList } = refThis.refs[`${lot.id}RemoveButton`];
    if (refThis.refs[`${lot.id}ParticipantValue`].value) {
      if (classList.contains("btn-danger")) {
        classList.remove("btn-danger");
      } else {
        const checkNotifiedRecord = await this.state.notifiedParticipantslist.filter((item) => item.lotId !== lot.id);
        this.setState({
          notifiedParticipantslist: checkNotifiedRecord,
        });
        classList.add("btn-danger");
        refThis.refs[`${lot.id}IncludeButton`].classList.remove("btn-success");
      }
    }
  }

  async createNewAwardNotice() {
    if (this.state.notifiedParticipantslist.length) {
      await this.setState({
        showAwardNotice: !this.state.showAwardNotice,
        notifiedError: false,
        pusrchaseOrderNumber: "",
      });
      if (this.awardMsgPreview) {
        this.awardMsgPreview.innerHTML = this.state.awardNoticeMessage.replace(/\r?\n/g, "<br>");
      }
    } else {
      this.setState({
        notifiedError: true,
        showAwardNotice: false,
        pusrchaseOrderNumber: "",
      });
      this.props.setTimeout(() => {
        this.setState({
          notifiedError: false,
        });
      }, 2000);
    }
  }

  updateAwardNoticeMsg(event) {
    this.awardMsgPreview.innerHTML = event.target.value.replace(/\r?\n/g, "<br>");
    this.setState({
      awardNoticeMessage: event.target.value,
    });
  }

  updatePurchaseOrderNo(event) {
    this.setState({
      pusrchaseOrderNumber: event.target.value.trim(),
    });
  }

  async deleteAwardNotice(event, lot) {
    const refThis = this;
    const response = await this.props.deleteNotification(lot.id, this.props.requestId);
    if (response.data) {
      this.setState({
        errorMessage: response.data.error,
      });
      this.props.setTimeout(() => {
        this.setState({
          errorMessage: "",
        });
      }, 5000);
    } else {
      refThis.refs[`${lot.id}ParticipantValue`].value = lot.supplier_user_id;
      this.setState({
        successMessage: this.props.translations.emarketplace.award_lot_updated,
      });
      this.props.setTimeout(() => {
        this.setState({
          successMessage: "",
        });
      }, 5000);
    }
  }

  async sendAwardNotice() {
    this.setState({
      sendNoticeSpinner: true,
    });
    if (this.state.notifiedParticipantslist.length) {
      const response = await this.props.sendNotification(
        this.state.notifiedParticipantslist,
        this.state.awardNoticeMessage.toString(),
        this.state.pusrchaseOrderNumber,
        this.props.requestId,
        this.state.ccEmail
      );
      if (response.data) {
        this.setState({
          errorMessage: response.data.error,
        });
        this.props.setTimeout(() => {
          this.setState({
            errorMessage: "",
          });
        }, 5000);
      } else {
        this.setState({
          notifiedParticipantslist: [],
          showAwardNotice: false,
          successMessage: this.props.translations.emarketplace.award_notice_success,
        });
        this.props.setTimeout(() => {
          this.setState({
            successMessage: "",
          });
        }, 2000);
      }
    } else {
      this.setState({
        notifiedError: true,
        showAwardNotice: false,
        pusrchaseOrderNumber: "",
      });
      this.props.setTimeout(() => {
        this.setState({
          notifiedError: false,
        });
      }, 5000);
    }
    this.setState({
      sendNoticeSpinner: false,
    });
  }

  async awardLotsToParticipants(lots) {
    const refThis = this;
    this.setState({
      saveSpinner: true,
    });
    const response = await this.props.awardLots(this.state.awardedParticipantsList, this.props.requestId);
    if (response.data) {
      this.setState({
        errorMessage: response.data.error,
      });
      this.props.setTimeout(() => {
        this.setState({
          errorMessage: "",
        });
      }, 5000);
    } else {
      await this.setState({
        notifiedParticipantslist: [],
        showAwardNotice: false,
        successMessage: "Awarded Lots have been saved.",
      });
      lots.forEach((lot) => {
        if (refThis.refs[`${lot.id}IncludeButton`]) {
          refThis.refs[`${lot.id}IncludeButton`].classList.remove("btn-success");
          refThis.refs[`${lot.id}RemoveButton`].classList.remove("btn-danger");
        }
      });
      this.props.setTimeout(() => {
        this.setState({
          successMessage: "",
        });
      }, 5000);
    }
    this.setState({
      saveSpinner: false,
    });
  }

  updateccEmail(e) {
    this.setState({ ccEmail: e.target.value });
  }

  getBidValue(item, lots) {
    let value;
    item.bids.forEach((bid) => {
      if (bid.user_id === item.supplier_user_id && this.props.declinedParticipantsList.indexOf(bid.user_id) === -1) {
        value = formatData(
          this.props.selectedLang,
          lots[0].bids[0].currency.symbol,
          _.round(bid.price * item.quantity, 2)
        );
      }
    });
    return value !== undefined ? value : "";
  }
  render() {
    const { translations } = this.props;
    const lots = this.props.emSummary.awardedLotsDetails ? this.props.emSummary.awardedLotsDetails : [];
    let awardNotice = false;
    let isAlreadyAwarded = false;
    lots.forEach((lot) => {
      if (!lot.is_award_notify) {
        awardNotice = true;
      }
      if (lot.supplier_user_id) {
        isAlreadyAwarded = true;
      }
    });
    return (
      <div>
        {lots.length > 0 ? (
          <div>
            {this.props.showAwardLotButton && (
              <button
                type="button"
                className="btn btn-primary pull-right modal-btn-open"
                onClick={() => {
                  this.showAwardPopup();
                }}
                // >{isAlreadyAwarded ? {translations.} translations.emarketplace.review_awarded_lots : translations.emarketplace.award_lots}
              >
                {isAlreadyAwarded
                  ? translations.emarketplace.review_awarded_lots
                  : translations.emarketplace.award_lots}
              </button>
            )}
            <div className="main-popup emarketplace-popup-lg">
              <Modal
                show={this.state.showModel}
                onClose={() => {
                  this.closeAwardPopup();
                }}
                closeOnOuterClick
              >
                <div className="vertical-top">
                  <div className="vertical-align-center" role="document">
                    <div className="modal-content clearfix">
                      <div className="modal-header">
                        <h5 className="modal-title">{translations.emarketplace.award_modal_title}</h5>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.closeAwardPopup();
                          }}
                          className="close-icon modal-btn-close"
                        >
                          <i className="fa fa-times" aria-hidden="true" />
                        </a>
                      </div>
                      <div className="modal-body">
                        <div className="alert alert-info">
                          {translations.emarketplace.award_modal_info}
                          {/* {translations.emarketplace.award_modal_info} */}
                        </div>
                        {this.state.successMessage && (
                          <div className="msg_award_lots">
                            <div className="alert alert-success msg">{this.state.successMessage}</div>
                          </div>
                        )}
                        {this.state.errorMessage && (
                          <div className="flash_notice alert alert-danger">{this.state.errorMessage}</div>
                        )}
                        <div className="table-scroll award-lot-scroll">
                          <table className="award-lot-table tbody-fixed common-table">
                            <thead>
                              <tr>
                                <th>
                                  {translations.emarketplace.lot} <AwardToolTips tooltipData={this.props.toolTipTranslations} tooltipName="lot" />
                                </th>
                                <th className="award-lot-name">
                                  {translations.emarketplace.lot_name} <AwardToolTips tooltipData={this.props.toolTipTranslations} tooltipName="lotName" />
                                </th>
                                <th>
                                  {translations.emarketplace.awarded_participant}{" "}
                                  <AwardToolTips tooltipData={this.props.toolTipTranslations} tooltipName="participant" />
                                </th>
                                <th>
                                  {translations.emarketplace.bid_value} <AwardToolTips tooltipData={this.props.toolTipTranslations} tooltipName="bid" />
                                </th>
                                <th>
                                  {translations.emarketplace.include_in_awardnotice}{" "}
                                  <AwardToolTips tooltipData={this.props.toolTipTranslations} tooltipName="notice" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {lots.map((item, index) => (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>{item.name}</td>
                                  <td>
                                    {this.props.userHasEditPermission ? (
                                      <div className="selectbox">
                                        {item.is_award_notify && item.supplier_user_id ? (
                                          <select className="form-control" disabled>
                                            {item.bids.map((bid) => {
                                              if (bid.user_id === item.supplier_user_id) {
                                                return (
                                                  <option key={bid.id} value={bid.user_id}>
                                                    {bid.user_name}
                                                  </option>
                                                );
                                              }
                                              return "";
                                            })}
                                          </select>
                                        ) : (
                                          <select
                                            defaultValue={item.supplier_user_id ? item.supplier_user_id : "-1"}
                                            ref={`${item.id}ParticipantValue`}
                                            className="form-control"
                                            onChange={(e) => {
                                              this.changeParticipant(e, item);
                                            }}
                                            disabled={!this.props.userHasEditPermission}
                                          >
                                            {item.bids.map((bid) =>
                                              bid.rejected_lot ||
                                              (this.props.declinedParticipantsList &&
                                                this.props.declinedParticipantsList.indexOf(bid.user_id) !== -1) ? (
                                                "-1"
                                              ) : (
                                                <option key={bid.id} value={bid.user_id}>
                                                  {bid.user_name}
                                                </option>
                                              )
                                            )}
                                            <option value="-1">{translations.emarketplace.none}</option>
                                          </select>
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        {item.supplier_user_id && item.supplier_user_id !== -1 ? (
                                          item.bids.map(
                                            (bid) =>
                                              bid.user_id === item.supplier_user_id && (
                                                <p key={bid.id} value={bid.user_id}>
                                                  {bid.user_name}
                                                </p>
                                              )
                                          )
                                        ) : (
                                          <p>{translations.emarketplace.none}</p>
                                        )}
                                      </div>
                                    )}
                                    {
                                      <i
                                        ref={`${item.id}Spinner`}
                                        className="fa fa-spinner fa-spin text-danger hidden"
                                      />
                                    }
                                  </td>
                                  <td>
                                    <span className="bid-value" ref={`${item.id}BidValue`}>
                                      {this.getBidValue(item, lots)}
                                    </span>
                                  </td>
                                  <td className="options">
                                    {!item.is_award_notify ? (
                                      <div align="center">
                                        {this.props.userHasEditPermission ? (
                                          <div>
                                            <button
                                              ref={`${item.id}IncludeButton`}
                                              name="include-in-notice"
                                              type="button"
                                              className="btn clearfix small tick fa fa-check fa-white"
                                              onClick={(e) => {
                                                this.includeInAwardNotice(e, item);
                                              }}
                                            />
                                            &nbsp;
                                            <button
                                              ref={`${item.id}RemoveButton`}
                                              name="remove-from-notice"
                                              type="button"
                                              className="btn award-lot-btn clearfix small remove hover cross fa fa-remove"
                                              onClick={(e) => {
                                                this.removeFromAwardNotice(e, item);
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          "-"
                                        )}
                                      </div>
                                    ) : (
                                      <div className="text-center">
                                        <div className="yes notified text-success">
                                          <span className="fa fa-fw fa-check" />
                                          {translations.emarketplace.notified}
                                        </div>
                                        <div align="center">
                                          <div className="commentbox">
                                            <button
                                              name="button"
                                              type="button"
                                              className="btn clearfix small notify_info btn-award fa fa-comment"
                                            />
                                            <div className="message-box-top hidden">
                                              <div className="msg-top-content">
                                                <h4>{translations.emarketplace.award_notice}</h4>
                                                <div>
                                                  <RenderHTML content={item.notify_message} />
                                                </div>
                                                <p>
                                                  <strong>
                                                    <RenderHTML content={item.notify_content} />
                                                  </strong>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          &nbsp;
                                          {this.props.userHasEditPermission && (
                                            <button
                                              onClick={(e) => {
                                                this.deleteAwardNotice(e, item);
                                              }}
                                              name="button"
                                              type="button"
                                              className="btn clearfix small white button red notified_trashed btn-award fa fa-trash-o fa-red"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr className="totals">
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td className="text_center">
                                  <b>{translations.emarketplace.totals}</b>
                                </td>
                                <td id="totalBid">
                                  {formatData(
                                    this.props.selectedLang,
                                    lots[0].bids[0].currency.symbol,
                                    this.state.totalBidValue
                                  )}
                                </td>
                                <td>&nbsp;</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <br />
                        {this.state.notifiedError && (
                          <div className="flash_notice alert alert-danger">
                            {translations.emarketplace.notification_pop_up_error}
                          </div>
                        )}
                      </div>
                      <div className="modal-footer">
                        <div className="pull-left">
                          {awardNotice && this.props.userHasEditPermission && (
                            <button
                              type="button"
                              className="btn btn-primary new-award"
                              onClick={() => this.createNewAwardNotice()}
                            >
                              {translations.emarketplace.new_notice}
                            </button>
                          )}
                        </div>
                        <div className="button-box">
                          <button
                            type="button"
                            className="btn btn-secondary modal-btn-close"
                            onClick={() => this.closeAwardPopup()}
                          >
                            {translations.emarketplace.cancel}
                          </button>
                          {this.props.userHasEditPermission && (
                            <button
                              label="save-awards"
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.awardLotsToParticipants(lots)}
                            >
                              {this.state.saveSpinner && <i className="fa fa-spinner fa-spin" />}&nbsp;
                              {translations.emarketplace.save}
                            </button>
                          )}
                        </div>
                      </div>
                      {this.state.showAwardNotice && awardNotice ? (
                        <div className="notify-section">
                          <div className="col-md-12 notify-content m-b20">
                            <div className="form-group">
                              <label>
                                {translations.emarketplace.purchase_order_no}
                                <AwardToolTips tooltipData={this.props.toolTipTranslations} tooltipName="po_number" />
                              </label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  this.updatePurchaseOrderNo(e);
                                }}
                                className="form-control"
                              />
                            </div>
                            {this.props.qqDetails.limits && this.props.qqDetails.limits.cc_on_award_lot && (
                              <div className="form-group">
                                <label>cc:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={translations.emarketplace.entitled_cc_email}
                                  value={this.state.ccEmail}
                                  disabled={this.props.qqDetails.limits.cc_on_award_lot_email ? "disabled" : ""}
                                  onChange={(e) => {
                                    this.updateccEmail(e);
                                  }}
                                />
                              </div>
                            )}
                            <div className="form-group">
                              <label>{translations.emarketplace.email_edit}</label>
                              <div className="clearfix" />
                              <div className="col-md-6 m-t10 p-l0 notify-message">
                                <Textarea
                                  rows="3"
                                  className="form-control msg more-height"
                                  height="auto"
                                  name="textA"
                                  defaultValue={this.state.awardNoticeMessage}
                                  onChange={(e) => {
                                    this.updateAwardNoticeMsg(e);
                                  }}
                                />
                              </div>
                              <div className="col-md-6 m-t10 p-r0 notify-message">
                                <div className="text-information">
                                  <div className="generated-notify">
                                    <div
                                      ref={(ref) => {
                                        this.awardMsgContent = ref;
                                      }}
                                    >
                                      <div className="generated-text">
                                        <b>
                                          <div
                                            ref={(ref) => {
                                              this.awardMsgPreview = ref;
                                            }}
                                          />
                                        </b>
                                      </div>
                                      <br />
                                      <div className="generated_lot_details">
                                        <b>
                                          <ul className="pull-left">
                                            {this.state.notifiedParticipantslist.map((lot, index) => (
                                              <li key={lot.lotId}>
                                                {lot.position}. {lot.name}
                                              </li>
                                            ))}
                                          </ul>
                                        </b>
                                      </div>
                                      <br />
                                      {this.state.pusrchaseOrderNumber && (
                                        <div>
                                          <b>
                                            <span>
                                              {translations.emarketplace.po_number}: {this.state.pusrchaseOrderNumber}
                                            </span>
                                          </b>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix" />
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary modal-btn-close"
                              onClick={() => this.closeAwardPopup()}
                            >
                              {translations.emarketplace.cancel}
                            </button>
                            <button type="button" title={translations.emarketplace.send_award_notice} className="btn btn-primary" onClick={() => this.sendAwardNotice()}>
                              {this.state.sendNoticeSpinner && <i className="fa fa-spinner fa-spin" />}&nbsp;
                              {translations.emarketplace.send_award_notice}
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedLang: state.changeLanguageReducer,
  emSummary: state.emSummaryReducer,
  // user: state.userReducer,
  qqDetails: state.qqReducer,
});

const mapDispatchToProps = (dispatch) => ({
  deleteNotification: (lotId, requestId) => dispatch(deleteNotification(lotId, requestId)),
  sendNotification: (participants, msg, orderNumber, requestId, ccEmail) =>
    dispatch(sendNotification(participants, msg, orderNumber, requestId, ccEmail)),
  awardLots: (lots, requestId) => dispatch(awardLots(lots, requestId)),
  fetchConfigurationLimit: (companyId) => dispatch(fetchConfigurationLimit(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReactTimeout(AwardLots));
