import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal, { closeStyle } from 'simple-react-modal';
import * as _ from 'lodash';
import Loader from 'react-loader-advanced';
import Draggable from 'react-draggable';
import cookies from 'browser-cookies';

import { fetchMessages, markReadToMessage, sendMessage } from '../../../../actions/qq/messagesActions';
import { fetchEventParticipants } from '../../../../actions/qq/qqActions';
import { showToasterMessages } from '../../../../actions/qq/genericActions';
import { notifyParticipant } from '../../../../actions/qq/editActions';
import LoaderMessage from '../../LoaderMessage';
import { scrollToMessage } from '../../js/common';
import TrixEditor from '@/components/common/TrixEditor';
import { isEditorEmpty } from "../QQEvent/EditorPopup";
import OutboxList from './message-section/OutboxList';
import InboxList from './message-section/InboxList';

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModel: false,
      isNewMessage: '',
      message: '',
      selectedParticipants: [],
      messageReplyId: null,
      selectionArray: [],
      tabHostSelected: false,
      addedParticipant: [],
      hosts: [],
      replyToHost: false,
      showLoader: true,
      messages: [],
    };
  }

  async UNSAFE_componentWillMount() {
    const { quoteId } = this.props;
    if (quoteId) {
      await this.props.fetchMessages(quoteId);
    }
    this.setState({
      showLoader: false,
    });

    this.props.socketObject.on('message', async (data) => {
      const { messages } = this.state;
      let parentMessage;
      if (typeof data === 'object') {
        if (data.parent_id) {
          let parentIndex;
          await messages.forEach((message, idx) => {
            if ((data.parent_id === (message.id).toString()) || data.parent_id === message.parent_id) {
              parentIndex = idx;
              parentMessage = message;
            }
          });
          messages.splice(parentIndex + 1, 0, data);
        } else {
          messages.unshift(data);
          [parentMessage] = messages;
        }
        this.setState({
          messages,
        }, () => {
          this.props.showToasterMessages('SHOW_SUCCESS', this.props.translations.summary_status.message.new_message_alert);
        });
        if (this.messageSection && this.messageSection.classList.contains('collapsed')) {
          await this.messageSection.click();
        }
        scrollToMessage(false, parentMessage.id);
      }
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.quoteDetail.event_participants_list) {
      this.setState({ addedParticipant: newProps.quoteDetail.event_participants_list });
    }
    if (!_.isEmpty(newProps.quoteDetail.hosts)) {
      let hosts = [];
      hosts = _.filter(newProps.quoteDetail.hosts.users, (row) => {
        row.user_id = row.id;
        row.role = newProps.quoteDetail.hosts.roles[row.user_id];
        return row.user_id !== this.props.user.id;
      });

      if (newProps.notify.postEditModal && newProps.notify.postEditModal.status) {
        this.setState({
          showModel: true,
          isNewMessage: true,
        });
      }

      this.setState({ hosts, messages: newProps.messages.messages_list ? newProps.messages.messages_list : [] });
    }
  }

  showMessageModal = (flag, messageReplyId, replyUserId) => {
    if (messageReplyId) {
      const replyToHost = _.findIndex(this.state.addedParticipant, ['user_id', replyUserId]) === -1;
      const replyToId = this.state.selectedParticipants.concat(replyUserId);
      this.setState({
        messageReplyId, selectedParticipants: replyToId, replyToHost, tabHostSelected: replyToHost,
      }, () => {
        if (replyToHost) {
          $('#host_tab').addClass('active in');
          $('#participant_tab').removeClass('active in');
        }
      });
    }
    this.setState({ showModel: true, isNewMessage: flag });
  }

  closeMessageModal() {
    const { quoteId } = this.props;
    this.setState({
      showModel: false,
      selectedParticipants: [],
      messageReplyId: null,
      message: '',
      selectionArray: [],
      replyToHost: false,
      tabHostSelected: false,
    });
    this.props.notifyParticipant(quoteId, false, '');
  }

  addSingleParticipant(id) {
    const index = _.indexOf(this.state.selectedParticipants, id);
    let selected = [];
    if (index === -1) {
      selected = this.state.selectedParticipants.concat(id);
    } else {
      selected = this.state.selectedParticipants;
      selected.splice(index, 1);
    }
    this.setState({ selectedParticipants: selected });
  }

  addParticipants(flag, checkedFlag) {
    let temp = this.state.selectedParticipants;
    const participantsList = !this.state.tabHostSelected ? this.state.addedParticipant : this.state.hosts;
    switch (flag) {
      case 'Pending': {
        _.filter(participantsList, (o) => {
          if (o.status === 'Pending') {
            const idx = _.indexOf(temp, o.user_id);
            if (idx === -1) {
              temp.push(o.user_id);
            }
          }
          if (checkedFlag) {
            if (o.status === 'Pending') {
              const idx = _.indexOf(temp, o.user_id);
              temp.splice(idx, 1);
            }
          }
          return true;
        });

        const { selectionArray } = this.state;
        const i = selectionArray.indexOf(1);
        if (i === -1) {
          selectionArray.push(1);
        } else {
          selectionArray.splice(i, 1);
        }
        this.setState({ selectionArray });
        break;
      }
      case 'Active': {
        _.filter(participantsList, (o) => {
          if (o.status === 'Active') {
            const idx = _.indexOf(temp, o.user_id);
            if (idx === -1) {
              temp.push(o.user_id);
            }
          }
          if (checkedFlag) {
            if (o.status === 'Active') {
              const idx = _.indexOf(temp, o.user_id);
              temp.splice(idx, 1);
            }
          }
          return true;
        });
        const { selectionArray } = this.state;
        const i = selectionArray.indexOf(2);
        if (i === -1) {
          selectionArray.push(2);
        } else {
          selectionArray.splice(i, 1);
        }
        this.setState({ selectionArray });
        break;
      }
      case 'Declined': {
        _.filter(participantsList, (o) => {
          if (o.status === 'Declined') {
            const idx = _.indexOf(temp, o.user_id);
            if (idx === -1) {
              temp.push(o.user_id);
            }
          }
          if (checkedFlag) {
            if (o.status === 'Declined') {
              const idx = _.indexOf(temp, o.user_id);
              temp.splice(idx, 1);
            }
          }
          return true;
        });

        const { selectionArray } = this.state;
        const i = selectionArray.indexOf(3);
        if (i === -1) {
          selectionArray.push(3);
        } else {
          selectionArray.splice(i, 1);
        }
        this.setState({ selectionArray });
        break;
      }

      case 'Owner': {
        _.filter(participantsList, (o) => {
          if (o.role.indexOf('Owner') > -1) {
            const idx = _.indexOf(temp, o.user_id);
            if (idx === -1) {
              temp.push(o.user_id);
            }
          }
          if (checkedFlag) {
            if (o.role.indexOf('Owner') > -1) {
              const idx = _.indexOf(temp, o.user_id);
              temp.splice(idx, 1);
            }
          }
          return true;
        });

        const { selectionArray } = this.state;
        const i = selectionArray.indexOf(4);
        if (i === -1) {
          selectionArray.push(4);
        } else {
          selectionArray.splice(i, 1);
        }
        this.setState({ selectionArray });
        break;
      }

      case 'Editors': {
        _.filter(participantsList, (o) => {
          if (o.role.indexOf('Editor') > -1) {
            const idx = _.indexOf(temp, o.user_id);
            if (idx === -1) {
              temp.push(o.user_id);
            }
          }
          if (checkedFlag) {
            if (o.role.indexOf('Editor') > -1) {
              const idx = _.indexOf(temp, o.user_id);
              temp.splice(idx, 1);
            }
          }
          return true;
        });

        const { selectionArray } = this.state;
        const i = selectionArray.indexOf(5);
        if (i === -1) {
          selectionArray.push(5);
        } else {
          selectionArray.splice(i, 1);
        }
        this.setState({ selectionArray });
        break;
      }

      case 'Viewers': {
        _.filter(participantsList, (o) => {
          if (o.role.indexOf('Viewer') > -1) {
            const idx = _.indexOf(temp, o.user_id);
            if (idx === -1) {
              temp.push(o.user_id);
            }
          }
          if (checkedFlag) {
            if (o.role.indexOf('Viewer') > -1) {
              const idx = _.indexOf(temp, o.user_id);
              temp.splice(idx, 1);
            }
          }
          return true;
        });

        const { selectionArray } = this.state;
        const i = selectionArray.indexOf(6);
        if (i === -1) {
          selectionArray.push(6);
        } else {
          selectionArray.splice(i, 1);
        }
        this.setState({ selectionArray });
        break;
      }

      case 'all hosts': {
        let selectionArray = [];
        if (this.state.selectionArray.length !== 4) {
          temp = _.map(participantsList, 'user_id');
          selectionArray = [4, 5, 6, 7];
        } else {
          temp = [];
        }

        this.setState({
          selectionArray,
          selectedParticipants: temp,
        });
        break;
      }

      default: {
        let selectionArray = [];
        if (this.state.selectionArray.length !== 3) {
          temp = _.map(participantsList, 'user_id');
          selectionArray = [1, 2, 3];
        } else {
          temp = [];
        }

        this.setState({
          selectionArray,
          selectedParticipants: temp,
        });
      }
    }
    this.setState({ selectedParticipants: temp });
  }

  sendMessage = async (e) => {
    e.target.disabled = true;
    const { quoteId } = this.props;
    const messages = {
      message: this.state.message,
      recipient_ids: this.state.selectedParticipants,
    };
    if (this.state.messageReplyId) {
      messages.parent_id = this.state.messageReplyId;
    }
    const data = {
      messages,
    };
    const response = await this.props.sendMessage(quoteId, data);
    this.sendMessageResponse(e, response, quoteId);
    this.setState({
      message: "",
    });
  };

  sendMessageResponse = (e, response, quoteId) => {
    if (response && response.status === 201) {
      if (this.state.messageReplyId) {
        this.props.markAsRead(quoteId, this.state.messageReplyId, { message: { read: true } });
      }
      this.props.showToasterMessages('SHOW_SUCCESS', this.props.translations.success_message.your_message_send);
      this.closeMessageModal();
    } else {
      e.target.disabled = false;
    }
  };

  isChecked(id) {
    const flag = _.indexOf(this.state.selectedParticipants, id) > -1;
    return flag;
  }

  async selectTabs(string) {
    let addedParticipant = [];
    let tabHostSelected;
    let hosts = [];
    if (string === 'Participant') {
      tabHostSelected = false;
    } else {
      tabHostSelected = true;
      hosts = await _.filter(this.props.quoteDetail.hosts.users, (row) => {
        row.user_id = row.id;
        row.role = this.props.quoteDetail.hosts.roles[row.user_id];
        return row.user_id !== this.props.user.id;
      });
    }
    addedParticipant = this.props.quoteDetail.event_participants_list;
    await this.setState({
      addedParticipant,
      tabHostSelected,
      hosts,
      selectionArray: [],
      selectedParticipants: [],
      message: '',
    }, () => {
      if (tabHostSelected) {
        $('#host_tab').addClass('active in');
      }
    });
  }

  statusColor(status) {
    switch (status) {
      case 'Active':
        return 'active-color';
      case 'Pending':
        return 'pending-color';
      case 'Declined':
        return 'declined-color';
      case 'Owner':
        return 'active-color';
      case 'Editor':
        return 'pending-color';
      case 'Viewer':
        return 'declined-color';
      default:
        return 'active-color';
    }
  }

  sendMessageData = (content) => {
    this.setState({ message: content });
  };

  render() {
    const messageList = this.state.messages;
    // const { details } = this.props.user;
    const userId = this.props.user.id;
    const newMessage = this.state.isNewMessage;
    const { addedParticipant, tabHostSelected, hosts, replyToHost, message } = this.state;
    const { translations } = this.props;
    return (
      <Loader show={ this.state.showLoader } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles priority={ 1 }>
        <div className='panel panel-default message-panel'>
          <div className='panel-heading' role='tab' id='headingThree'>
            <h4 className='panel-title'>
              <a className='collapsed' id='messageCollapse' role='button' data-toggle='collapse' href='#collapseThree' ref={ (ref) => { this.messageSection = ref; } }>
                {translations.summary_status.message.messages}
              </a>
            </h4>
          </div>
          <div id='collapseThree' className='panel-collapse collapse'>
            <div className='panel-body'>
              {userId ?
                <div className='col-xs-12 message-top'>
                  <div className='table-box'>
                    <div className='heading-button m-b20 clearfix'>
                      {addedParticipant.length > 0 &&
                      <button
                        type='button'
                        onClick={ () => this.showMessageModal(true, null, null) }
                        className='btn btn-primary new-message pull-right modal-btn-open'
                      >{translations.summary_status.message.send_new_message}
                      </button>}
                    </div>
                    <div className='clearfix' />
                    {messageList.length > 0 ? (
                      <div className="message-all">
                        {messageList.map((message, idx) =>
                          message.user.id === userId ? (
                            <OutboxList
                              key={message.id}
                              message={message}
                              quoteId={this.props.quoteId}
                              userId={userId}
                              translations={translations}
                              markAsRead={this.props.markAsRead}
                            />
                          ) : (
                            <InboxList
                              key={message.id}
                              message={message}
                              quoteDetail={this.props.quoteDetail}
                              quoteId={this.props.quoteId}
                              userId={userId}
                              translations={translations}
                              markAsRead={this.props.markAsRead}
                              showMessageModal={this.showMessageModal}
                            />
                          )
                        )}
                      </div>
                    ) : (
                      <div>{translations.summary_status.message.no_message_available}</div>
                    )}
                  </div>
                </div> : ''}
            </div>
          </div>
          {addedParticipant.length > 0 &&
          <div className='main-popup popup-lg popup-new-message send-message-popup'>
            <Modal style={ { zIndex: 1100, backgroundColor: '#00000033' } } show={ this.state.showModel } onClose={ () => this.closeMessageModal() } closeOnOuterClick>
              <Draggable handle={".modal-header"}>
                <div className='vertical-top'>
                  <div className='vertical-align-center'>
                    <div className='modal-content clearfix'>
                      <a
                        href='#'
                        onClick={ (e) => { e.preventDefault(); this.closeMessageModal(); } }
                        className='close-icon modal-btn-close'
                      >
                        <i className='fa fa-times' aria-hidden='true' />
                      </a>
                        <div className="modal-header">
                          <h4>
                            <b>{translations.summary_status.message.event}: </b>
                            {this.props.quoteName}
                          </h4>
                        </div>
                      <div className='modal-body'>

                        {newMessage &&
                        <ul className='m-b20 nav nav-tabs'>
                          <li className='active'>
                            <a data-toggle='tab' href='#participant_tab' onClick={ () => this.selectTabs('Participant') }>
                              {translations.qq_edit.participants}
                            </a>
                          </li>
                          {cookies.get('sandpit') !== 'true' &&
                            <li>
                              <a data-toggle='tab' href='#host_tab' onClick={ () => this.selectTabs('Host') }>
                                {translations.qq_listing.qq_list_table.host}
                              </a>
                            </li>
                          }
                        </ul>}

                        <div className='tab-content'>
                          {/* tab participant */}
                          {!tabHostSelected ?
                            <div id='participant_tab' className='tab-pane fade in active'>
                                <EditorComponent
                                  message={message}
                                  newMessage={newMessage}
                                  sendMessageData={this.sendMessageData}
                                  translations={translations}
                                />
                              <div className='form-group'>
                                <label>{translations.summary_status.message.send_message_to}</label>
                                <div className='checkbox-split'>
                                  <div className='checkbox'>
                                    <div>
                                        <input
                                          onClick={() =>
                                            this.addParticipants("all", this.state.selectionArray.length === 3)
                                          }
                                          data-testid="select-all"
                                          id="checkbox7"
                                          type="checkbox"
                                          name="checkbox"
                                          value="7"
                                          checked={this.state.selectionArray.length === 3}
                                        />
                                      <label htmlFor='checkbox7'>
                                        <span />{translations.summary_status.message.all_participants}
                                      </label>
                                    </div>
                                  </div>
                                  <div className='checkbox'>
                                    <div>
                                      <input
                                        onClick={ () =>
                                  this.addParticipants('Active', (this.state.selectionArray.indexOf(2) > -1)) }
                                        id='checkbox8'
                                        type='checkbox'
                                        name='checkbox'
                                        value='8'
                                        checked={ this.state.selectionArray.indexOf(2) > -1 }
                                      />
                                      <label htmlFor='checkbox8'>
                                        <span />{translations.summary_status.message.active_participants}
                                      </label>
                                    </div>
                                  </div>
                                  <div className='checkbox'>
                                    <div>
                                      <input
                                        onClick={ () =>
                                  this.addParticipants('Pending', (this.state.selectionArray.indexOf(1) > -1)) }
                                        id='checkbox9'
                                        type='checkbox'
                                        name='checkbox'
                                        value='9'
                                        checked={ this.state.selectionArray.indexOf(1) > -1 }
                                      />
                                      <label htmlFor='checkbox9'>
                                        <span />{translations.summary_status.message.pending_participants}
                                      </label>
                                    </div>
                                  </div>
                                  <div className='checkbox'>
                                    <div>
                                      <input
                                        onClick={ () =>
                                  this.addParticipants('Declined', (this.state.selectionArray.indexOf(3) > -1)) }
                                        id='checkbox10'
                                        type='checkbox'
                                        name='checkbox'
                                        value='10'
                                        checked={ this.state.selectionArray.indexOf(3) > -1 }
                                      />
                                      <label htmlFor='checkbox10'>
                                        <span />{translations.summary_status.message.declined_participants}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='table-responsive'>
                                <table className='status-table fixed_headers'>
                                  <thead>
                                    <tr>
                                      <th>&nbsp;</th>
                                      <th>{translations.summary_status.message.company_name}</th>
                                      <th>{translations.summary_status.message.status}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {addedParticipant
                              .map((row, index) => (
                                <tr key={ row.id }>
                                  <td>
                                    <div className='checkbox'>
                                      <div>
                                        <input
                                          onChange={ () => this.addSingleParticipant(row.user_id) }
                                          id={ `${index}_checkbox4` }
                                          type='checkbox'
                                          name='checkbox'
                                          checked={ _.indexOf(this.state.selectedParticipants, row.user_id) > -1 }
                                        />
                                        <label htmlFor={ `${index}_checkbox4` }>
                                          <span />
                                        </label>
                                      </div>
                                    </div>
                                  </td>
                                  <td className={ this.statusColor(row.status) }>{row.display_name}</td>
                                  <td>{row.status}</td>
                                </tr>
                              ))}
                                  </tbody>
                                </table>
                              </div>
                              <ul className='color-represent'>
                                <li>{translations.summary_status.message.active}</li>
                                <li>{translations.summary_status.message.pending}</li>
                                <li>{translations.summary_status.message.declined}</li>
                              </ul>
                            </div> : ''}
                          {/* tab participant end */}
                          {/* tab hosts */}
                          {(newMessage || (!newMessage && replyToHost)) && tabHostSelected && (cookies.get('sandpit') !== 'true') &&
                          <div id='host_tab' className='tab-pane fade'>
                            {hosts.length > 0 ?
                              <div>
                                <EditorComponent
                                  message={message}
                                  newMessage={newMessage}
                                  sendMessageData={this.sendMessageData}
                                  translations={translations}
                                />
                                <div className='form-group'>
                                  <label>{translations.summary_status.message.send_message_to}</label>
                                  <div className='checkbox-split'>
                                    <div className='checkbox'>
                                      <div>
                                        <input
                                          onClick={ () =>
                                          this.addParticipants('all hosts', (this.state.selectionArray.length === 4)) }
                                          id='checkbox11'
                                          type='checkbox'
                                          name='checkbox'
                                          value='11'
                                          checked={ this.state.selectionArray.length === 4 }
                                        />
                                        <label htmlFor='checkbox11'>
                                          <span />{translations.summary_status.message.all_hosts}
                                        </label>
                                      </div>
                                    </div>
                                    <div className='checkbox'>
                                      <div>
                                        <input
                                          onClick={ () =>
                                          this.addParticipants('Owner', (this.state.selectionArray.indexOf(4) > -1)) }
                                          id='checkbox12'
                                          type='checkbox'
                                          name='checkbox'
                                          value='12'
                                          checked={ this.state.selectionArray.indexOf(4) > -1 }
                                        />
                                        <label htmlFor='checkbox12'>
                                          <span />{translations.summary_status.owner}
                                        </label>
                                      </div>
                                    </div>
                                    <div className='checkbox'>
                                      <div>
                                        <input
                                          onClick={ () =>
                                          this.addParticipants('Editors', (this.state.selectionArray.indexOf(5) > -1)) }
                                          id='checkbox13'
                                          type='checkbox'
                                          name='checkbox'
                                          value='13'
                                          checked={ this.state.selectionArray.indexOf(5) > -1 }
                                        />
                                        <label htmlFor='checkbox13'>
                                          <span />{translations.summary_status.message.editors}
                                        </label>
                                      </div>
                                    </div>
                                    <div className='checkbox'>
                                      <div>
                                        <input
                                          onClick={ () =>
                                          this.addParticipants('Viewers', (this.state.selectionArray.indexOf(6) > -1)) }
                                          id='checkbox14'
                                          type='checkbox'
                                          name='checkbox'
                                          value='14'
                                          checked={ this.state.selectionArray.indexOf(6) > -1 }
                                        />
                                        <label htmlFor='checkbox14'>
                                          <span />{translations.summary_status.message.viewers}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='table-responsive'>
                                  <table className='status-table fixed_headers'>
                                    <thead>
                                      <tr>
                                        <th>&nbsp;</th>
                                        <th>{translations.qq_edit.name}</th>
                                        <th>{translations.summary_status.message.role}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {hosts
                                    .map((row, index) => (
                                      <tr key={ row.id }>
                                        <td>
                                          <div className='checkbox'>
                                            <div>
                                              <input
                                                onChange={ () => this.addSingleParticipant(row.user_id) }
                                                id={ `${index}_checkbox13` }
                                                type='checkbox'
                                                name='checkbox'
                                                checked={ _.indexOf(this.state.selectedParticipants, row.user_id) > -1 }
                                              />
                                              <label htmlFor={ `${index}_checkbox13` }>
                                                <span />
                                              </label>
                                            </div>
                                          </div>
                                        </td>
                                        <td className={ this.statusColor(row.role) }>{row.email}</td>
                                        <td>{row.role}</td>
                                      </tr>
                                    ))}
                                    </tbody>
                                  </table>
                                </div>
                                <ul className='color-represent'>
                                  <li>{translations.summary_status.owner}</li>
                                  <li>{translations.summary_status.message.editors}</li>
                                  <li>{translations.summary_status.message.viewers}</li>
                                </ul>
                              </div> :
                              <div className='alert alert-info' dangerouslySetInnerHTML={{__html: translations.summary_status.message.no_host_message}} >
                              </div>}
                          </div>}
                          {/* tab hosts end */}
                        </div>

                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='btn btn-secondary cancel-reply modal-btn-close'
                          onClick={ () => this.closeMessageModal() }
                        >{translations.summary_status.message.cancel}
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary cancel-reply modal-btn-close'
                          disabled={ isEditorEmpty(this.state.message) || this.state.selectedParticipants.length === 0 }
                          onClick={ this.sendMessage }
                        >{translations.summary_status.message.send_message}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Draggable>
            </Modal>
          </div>}
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedLang: state.changeLanguageReducer,
  messages: state.qqmessagesReducer,
  // user: state.userReducer,
  quoteDetail: state.qqReducer,
  notify: state.notifyParticipant,
});

const mapDispatchToProps = dispatch => ({
  fetchMessages: quoteId => dispatch(fetchMessages(quoteId)),
  fetchEventParticipants: quoteId => dispatch(fetchEventParticipants(quoteId)),
  markAsRead: (quoteId, messageId, data) => dispatch(markReadToMessage(quoteId, messageId, data)),
  sendMessage: (quoteId, data) => dispatch(sendMessage(quoteId, data)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
  notifyParticipant: (quoteId, status, message) => dispatch(notifyParticipant(quoteId, status, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);

export const EditorComponent = ({ message, newMessage, sendMessageData, translations }) => {
  const labelText = newMessage
    ? translations.summary_status.message.message
    : translations.summary_status.message.reply;
  return (
    <div className="form-group">
      <label>{labelText}</label>
      <TrixEditor value={message} onChange={(content) => sendMessageData(content)} />
      <span hidden={!isEditorEmpty(message)} className="text-danger">
        {translations.error_message.no_white_space}
      </span>
    </div>
  );
};
