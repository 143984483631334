import React from "react";
export default (props) => {
  const { participantData, title } = props;
  const participantList =
    participantData &&
    participantData.list.map((details, idx) => {
      return (
        <li key={idx}>
          <span className="light-gray-color">{details["key"]}:&nbsp; </span>
          {details["value"]}
        </li>
      );
    });
  return (
    <>
      <div className="title fs-14">{title}</div>
      <ul className="with-style last">{participantList}</ul>
    </>
  );
};
