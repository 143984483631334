import React, { useCallback } from "react";
import type { CheckboxParameter, Leaf, NormalizedNode } from "@/slices/rules/types";
import { useAppDispatch } from "@/hooks/redux";
import { checkboxChanged } from "@/slices/rules";
import { useDebouncedSync } from "@/hooks/use-debounced-sync";
import { Checkbox } from "@/cl/checkbox";
import styles from "../node.module.scss";
import { t } from "@/i18n";
import { InputContainer } from "../leaf-node";
import { useParentCollapsed } from "../collapse-context";

function useInputs(node: NormalizedNode<Leaf<CheckboxParameter>>) {
  const dispatch = useAppDispatch();
  const [value, handleChange] = useDebouncedSync(
    node.state.leaf_parameter.target_selection,
    useCallback((value) => dispatch(checkboxChanged({ id: node.uuid, value })), [dispatch, node.uuid])
  );
  return { value, handleChange };
}

export default function CheckboxParameter({ node }: { node: NormalizedNode<Leaf<CheckboxParameter>> }) {
  const { value, handleChange } = useInputs(node);
  const collapsed = useParentCollapsed();
  return (
    <>
      <span className={styles.word}>{t("workflow_rules.joiner.is")}</span>
      <InputContainer>
        <Checkbox
          name={`checkbox-${node.uuid}`}
          checked={value}
          onChange={(e) => handleChange(e.target.checked)}
          disabled={!!collapsed}
        />
      </InputContainer>
    </>
  );
}
