import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import Loader from 'react-loader-advanced';
import ReactTimeout from 'react-timeout';

import { fetchLots } from '../../../../actions/qq/qqActions';
import formatData from '../../js/languageFormatter';
import LoaderMessage from '../../LoaderMessage';

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      bidsList: [],
    };
  }
  async UNSAFE_componentWillMount() {
    const { quoteId } = this.props;
    if (quoteId) {
      await this.props.fetchLots(quoteId);
    }
    this.props.setTimeout(() => {
      this.setState({
        showLoader: false,
      });
    }, 2000);

    this.props.socketObject.on('bid', async (data) => {
      const { bidsList } = this.state;
      await bidsList.forEach((bid) => {
        if (bid.lot_id === data.lot_id) {
          if (data.rank === 1 && bid.rank === 1) {
            bid.rank = 2;
          } else if (data.host_price === bid.host_price) {
            bid.rank = data.rank;
          }
        }
      });
      bidsList.push(data);
      this.setState({
        bidsList,
      });
    });
  }

  UNSAFE_componentWillReceiveProps(newPros) {
    const bidsList = newPros.bids.bids_list;
    if (bidsList) {
      this.setState({
        bidsList,
      });
    }
  }

  getBestBids(lot, index) {
    const { quoteDetail, translations, user } = this.props;
    const bidList = this.state.bidsList;
    const bestBid = this.bestBids(bidList, lot.id);
    let participantStr = '';
    if (bestBid.length === 1) {
      participantStr = bestBid[0].user_name;
    } else if (bestBid.length > 1) {
      participantStr = translations.summary_status.tied;
    }

    return (
      <tr key={ lot.id }>
        <td><span title={ lot.name }>{lot.name}</span></td>
        <td>{lot.unit_of_measure}</td>
        <td>{bestBid.length > 0 ?
          formatData(user.locale, bestBid[0].currency.symbol, _.round(bestBid[0].host_price, 2))
          : `${quoteDetail.data.currency.symbol}-`}
        </td>
        <td>{formatData(user.locale, '', lot.quantity)}</td>
        <td>{bestBid.length > 0 ?
        formatData(user.locale, bestBid[0].currency.symbol, _.round(bestBid[0].host_price * lot.quantity, 2))
          : `${quoteDetail.data.currency.symbol}-`}
        </td>
        <td>{participantStr}</td>
      </tr>
    );
  }

  bestBids(bidList, lotId) {
    let bids = [];
    let rank = null;
    if (bidList && bidList.length > 0) {
      const data = _.maxBy(bidList, 'rank');
      rank = data ? data.rank : rank;
      if (rank) {
        _.forEach(bidList, (value, key) => {
          if (lotId === value.lot_id && !value.rejected_lot && value.rank !== null) {
            if (rank === value.rank) {
              bids.push(value);
            } else if (value.rank !== null && rank > value.rank) {
              bids = [];
              bids.push(value);
              ({ rank } = value);
            }
          }
        });
      }
    }
    return bids;
  }

  render() {
    const { translations } = this.props;
    const data = this.props.lots.lots_list ? this.props.lots.lots_list : [];
    const bestBids = _.map(data, (obj, idx) => this.getBestBids(obj, idx));

    return (
      <Loader show={ this.state.showLoader } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles priority={ 1 }>
        <div className='panel panel-default'>
          <div className='panel-heading' role='tab' id='headingOne'>
            <h4 className='panel-title'>
              <a role='button' data-toggle='collapse' href='#collapseOne' className='collapsed'>
                {translations.summary_status.summary.summary}
              </a>
            </h4>
          </div>
          <div id='collapseOne' className='panel-collapse collapse'>
            <div className='panel-body'>
              {(data.length > 0) ?
                <div className='summary-table table-scroll'>
                  <table className='common-table'>
                    <thead>
                      <tr>
                        <th>{translations.summary_status.summary.lot_name}</th>
                        <th>{translations.summary_status.unit_of_measure}</th>
                        <th>{translations.summary_status.summary.best_bid}</th>
                        <th>{translations.summary_status.quantity_of_uom}</th>
                        <th>{translations.summary_status.best_bid_value}</th>
                        <th>{translations.summary_status.summary.participant_name}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bestBids}
                    </tbody>
                  </table>
                </div> : <div className='text-center'>{translations.summary_status.summary.no_lots_available}</div>}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // selectedLang: state.changeLanguageReducer,
  quoteDetail: state.qqReducer,
  lots: state.qqReducer,
  bids: state.qqmessagesReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchLots: quoteId => dispatch(fetchLots(quoteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReactTimeout(Summary));
