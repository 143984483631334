import { Field } from "redux-form";

/**
 * Split out the quantity limit message to reduce the complexity of
 * getErrorMessage
 *
 * @param {String} error - The name of the erorr
 * @param {Object} translations - The name of the erorr
 */
const getQuantityMessage = (error, translations) => {
  const message = error === "QUANTITY_LIMIT_HIGH" ? "less" : "greater";
  const number = error === "QUANTITY_LIMIT_HIGH" ? 999999999999.99 : 0;
  return translations.error_message.quantity_limit
    .replace("%{string}", translations.qq_edit[message])
    .replace("%{number}", number);
};

/**
 * Helper function to get the correct error message for the error.
 *
 * @param {String} id - The input ID so we can get the correct message for NAME_CHAR_LONG
 * @param {String} error - The name of the erorr
 * @param {Object} translations - The name of the erorr
 */
export const getErrorMessage = (id, error, translations) => {
  if (id === "quote_name" && error === "NAME_CHAR_LONG") {
    return translations.error_message[error.toLowerCase()]
      .replace("%{name_string}", translations.qq_edit.name)
      .replace("%{name_limit}", "50");
  }

  if (error === "NAME_CHAR_LONG") {
    return translations.error_message[error.toLowerCase()]
      .replace("%{name_string}", translations.summary_status.summary.lot_name)
      .replace("%{name_limit}", "150");
  }

  if (error.startsWith("QUANTITY_LIMIT_")) {
    return getQuantityMessage(error, translations);
  }

  return translations.error_message[error.toLowerCase()];
};

export const Input = ({ translations, id, input, label, type, meta: { touched, error } }) => (
  <div>
    <input {...input} id={id} placeholder={label} title={label} type={type} className="form-control" />
    {touched && error && <span className="text-danger">{getErrorMessage(id, error, translations)}</span>}
  </div>
);

export const FormField = (props) => {
  return <Field {...props} component={Input} type="text" />;
};

export default FormField;
