// @ts-check
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { isAxiosError } from "axios";
import * as actionTypes from "@/actions/actionsTypes";

/**
 * Resolve the error message from an AxiosError
 *
 * @param {Error} error
 */
const errorMessage = (error) => {
  if (isAxiosError(error)) {
    // Try and get the message from the request data. This will be json returned
    // in the request body.
    if (typeof error?.response?.data?.message === "string") {
      return error.response.data.message;
    }

    if (typeof error?.response?.data?.errors === "string") {
      return error?.response?.data?.errors;
    }
  }

  // Fall back to the exception message if we don't have anything else.
  return error.message;
};

/**
 * @template Arg
 * @template ReturnType
 *
 * @param {import("@reduxjs/toolkit").AsyncThunkPayloadCreator<ReturnType, Arg>} payloadCreator
 */
export const withErrorDispatch = (payloadCreator) => {
  /**
   * @type {import("@reduxjs/toolkit").AsyncThunkPayloadCreator<ReturnType, Arg>}
   */
  return async (arg, api) => {
    try {
      return await payloadCreator(arg, api);
    } catch (error) {
      api.dispatch({
        type: actionTypes.ERROR,
        error: errorMessage(error instanceof Error ? error : new Error(JSON.stringify(error))),
      });

      throw error;
    }
  };
};

export const listCustomFields = createAsyncThunk(
  "customFields/list",
  /**
   * @param {1 | 2 | 3 | undefined} type
   *
   * @returns {Promise<import('axios').AxiosResponse<Array<import('@/common-prop-types/custom-field').CustomFieldType>>>}
   */
  withErrorDispatch(({ companyId }, { signal }) => {
    return axios.get(`/api/v2/companies/${companyId}/custom_columns`, { signal });
  })
);

export const createCustomField = createAsyncThunk(
  "customFields/create",
  /**
   * @param {Promise<import('axios').AxiosResponse<import('@/common-prop-types/custom-field').CustomFieldType>>} customField
   */
  withErrorDispatch(({ companyId, customField }, { signal }) =>
    axios.post(`/api/v2/companies/${companyId}/custom_columns`, { custom_column: customField }, { signal })
  )
);

export const updateCustomField = createAsyncThunk(
  "customFields/update",
  /**
   * @param {Promise<import('axios').AxiosResponse<import('@/common-prop-types/custom-field').CustomFieldType>>} customField
   */
  withErrorDispatch(({ companyId, customField }, { signal }) => {
    return axios.put(`/api/v2/companies/${companyId}/custom_columns`, { custom_column: customField }, { signal });
  })
);

export const updateCustomFieldPosition = createAsyncThunk(
  "customFields/updatePosition",
  /**
   * @param {Promise<import('axios').AxiosResponse<import('@/common-prop-types/custom-field').CustomFieldType>>} customField
   */
  withErrorDispatch(async ({ companyId, customField }, { signal }) => {
    await axios.put(`/api/v2/companies/${companyId}/custom_columns`, { custom_column: customField }, { signal });
    return await axios.get(`/api/v2/companies/${companyId}/custom_columns`, { signal });
  })
);
