import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import Modal from 'simple-react-modal';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import Loader from 'react-loader-advanced';
import cookies from 'browser-cookies';

import EditorPopupSection from './EditorPopupSection';
import PopupMobile from './PopupMobile';
import { fetchParticipantGroups, fetchParticipantGrpsForEvnt, fetchParticipantOnboardingStatuses } from '../../../../actions/qq/companyActions';
import { fetchAdminText } from '../../../../actions/qq/adminTextActions';
import BaseComponent from '../../base';
import { showToasterMessages, validateParticipant, fetchSandpitParticipants } from '../../../../actions/qq/genericActions';
import NewSupplierChkbox from '../../NewSupplierChkbox';
import LoaderMessage from '../../LoaderMessage';
import { openParticipantsGroupPopup } from '../../js/common';
import { formGroupActions, FormGroupActions, canSelectMore, checkExisting, stateMutator, getSelectedById } from '../../js/QQEventRelay';
import DeferRenderer from '../../DeferRenderer';

const InitialState = (mutators) => ({
  tags: [],
  selectedGroups: [],
  selectedGroupsName: [],
  participantGroups: [],
  eventParticipants: [],
  showModel: false,
  showLimitMessage: false,
  adminText: '',
  showLoader: true,
  allowSuppliers: false,
  ...mutators
})
class QQParticipant extends BaseComponent {
  constructor() {
    super();
    this.state = InitialState(stateMutator);
    this.formGroupActions = formGroupActions.bind(this, true);
    this.canSelectMore = canSelectMore.bind(this);
    this.checkExisting = checkExisting.bind(this);
    this.getSelectedById = getSelectedById.bind(this);
    this.FormGroupActions = FormGroupActions;

    this.groupPopup = this.groupPopup.bind(this);
    this.close = this.close.bind(this);
  }

  async UNSAFE_componentWillMount() {
    await this.props.fetchParticipantGroups(this.props.companyId);
    await this.props.fetchParticipantGrpsForEvnt(this.props.companyId);
    await this.props.fetchAdminText(this.props.companyId, this.props.sectionKey);
    this.props.fetchParticipantOnboardingStatuses(this.props.companyId);
    const restField = JSON.parse(localStorage.getItem('restField'));

    const selectedGroups = restField.participantGroups.map(group => {
      group.selectedParticipants = new Set(group.selectedParticipants);
      return group;
    });

    this.setState({ showLoader: false, selectedGroups, tags: restField.participantTags });
    const groupIds = restField.participantGroups.map(o => o.id);
    this.props.onTextValue('participant_group_id', groupIds);
    this.props.onTextValue('participantGroups', selectedGroups);
    if (restField.participantTags && restField.participantTags.length > 0) {
      this.handleChange(restField.participantTags);
    }
    if (cookies.get('sandpit') === 'true') {
      await this.props.fetchSandpitParticipants();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText.FETCH_ADMIN_TEXT_PARTICIPANTS ? newProps.adminText.FETCH_ADMIN_TEXT_PARTICIPANTS : '';
    if (newProps.default.FETCH_ADMIN_TEXT_PARTICIPANTS_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_PARTICIPANTS_DEL;
    }
    let { allowSuppliers } = this.state;
    if (newProps.companyDetails.whitelabel) {
      allowSuppliers = newProps.companyDetails.whitelabel.allow_adding_new_participants_for_quick_quotes;
    }

    if (newProps.status.supplierStatus) {
      allowSuppliers = newProps.status.supplierStatus.qq_allow_adding_new_participants;
    }

    this.updateOnBoardingStatus(newProps.eventParticipants, newProps.eventParticipantsOnboardingStatus);

    this.setState({
      eventParticipantsOnboardingStatus: newProps.eventParticipantsOnboardingStatus,
      participantGroups: newProps.participantGroups,
      adminText,
      allowSuppliers,
    });
  }


  updateOnBoardingStatus = (eventParticipantsList, eventParticipantsOnboardingStatus = []) => {
    let epForSelect = [];
    if (eventParticipantsList) {
      epForSelect = eventParticipantsList.map((ep) => {
        const onboardingStatus = eventParticipantsOnboardingStatus.filter(e => e.email === ep.email && e.onboarding_status === 'rejected');
        const color = onboardingStatus.length > 0 ? 'red' : '';
        return { value: ep.email, label: ep.email, color };
      });
    }
    this.setState({eventParticipants: epForSelect})
  }

  async groupPopup() {
    let allowedParticipantsLength = this.props.participantsLimit;
    this.setState({ showModel: true });
    await this.state.selectedGroups.forEach((group, index) => {
      allowedParticipantsLength -= group.participant_emails.length;
    });
    if (allowedParticipantsLength <= 0) {
      this.setState({
        showLimitMessage: true,
      });
      const uncheckedGroups = $('.group-list-section').find('input[type="checkbox"]').not(':checked');
      _.forEach(uncheckedGroups, (value, key) => {
        $(value).prop('disabled', true);
      });
    }
  }

  async handleChange(tags) {
    tags = tags || [];
    const notEmailAddress = [];
    let hostEmailAddress = [];
    const isDelete = !tags.length;
    
    if (!isDelete) { 
      const newTag = tags[tags.length - 1];
      const regex = /\S+@\S+\.\S+/;
      if (!regex.test(newTag.value)) {
        notEmailAddress.push(newTag.value);
        tags.pop();
        this.setState({ tags });
      } else {

        if(!this.canSelectMore(false)) {
          return false;
        }

        if(!this.checkExisting(newTag.value)) {
          this.props.showToasterMessages('SHOW_ERROR', this.props.translations.qq_create.qq_participant.already_participating);
          return false;
        }

        const payload = { users: { emails: [newTag.value] } };
        const response = await this.props.validateParticipant(payload);
        if (response.status === 200 && response.data.host_users.length === 0) {
          this.setState({ tags });
        } else {
          tags.pop();
          this.setState({ tags });
          hostEmailAddress = response.data.host_users;
        }
      }
    } else {
      this.setState({ tags });
    }
    if (notEmailAddress.length > 0) {
      this.props.showToasterMessages('SHOW_ERROR', `${notEmailAddress.toString()}: ${this.props.translations.error_message.email_regex}`);
    }
    if (hostEmailAddress.length > 0) {
      this.props.showToasterMessages('SHOW_ERROR', `${hostEmailAddress.toString()}: ${this.props.translations.error_message.host_email_used}`);
    }
    
    const selectedEmails = _.map(tags, 'value');
    this.props.onTextValue('participantTags', selectedEmails);

    const restField = JSON.parse(localStorage.getItem('restField'));
    restField.participantTags = tags;
    localStorage.setItem('restField', JSON.stringify(restField));
  }

  onInputKeyDown(event) {
    switch (event.keyCode) {
      case 9: // TAB
        event.preventDefault();
        break;
    }
  }

  close() {
    this.setState({ showModel: false });
    this.formGroupActions(FormGroupActions.CHANGE_STEP, 1);
  }

  handleSandpitParticipants(event) {
    let tags = this.state.tags.filter(tag => tag.email !== event.target.email);
    const participant = event.target.value;
    if (event.target.checked) {
      tags.push({ id: null, email: participant });
    } else {
      tags = tags.filter(user => user.email !== participant);
    }
    this.setState({
      tags,
    });
    const restField = JSON.parse(localStorage.getItem('restField'));
    restField.participantTags = tags;
    localStorage.setItem('restField', JSON.stringify(restField));
    const selectedEmails = _.map(tags, 'email');
    this.props.onTextValue('participantTags', selectedEmails);
  }

  handleSandpitUsers(event) {
    let tags = this.state.tags.filter(tag => tag.email !== event.target.email);
    const participant = event.target.value;
    if (event.target.checked) {
      tags.push({ id: null, email: participant });
    } else {
      tags = tags.filter(user => user.email !== participant);
    }
    this.setState({
      tags,
    });
    const restField = JSON.parse(localStorage.getItem('restField'));
    restField.participantTags = tags;
    localStorage.setItem('restField', JSON.stringify(restField));
    const selectedEmails = _.map(tags, 'email');
    this.props.onTextValue('participantTags', selectedEmails);
  }

  handleTagRemove = (props) => {
    let { tags } = this.state;
    tags = tags.filter(tag => tag.value.toLowerCase() !== props.data.value.toLowerCase());

    this.setState({ tags }, () => {
      const selectedEmails = _.map(this.state.tags, 'value');
      this.props.onTextValue('participantTags', selectedEmails);

      const restField = JSON.parse(localStorage.getItem('restField'));
      restField.participantTags = this.state.tags;
      localStorage.setItem('restField', JSON.stringify(restField));
    });

    return props;
  }

  render() {
    const Option = props => (
      <div>
        <components.Option { ...props }>
          {props.data.label}
          {props.data.color &&
            <span className='rejected-text'>{this.props.translations.qq_create.qq_participant.rejected}</span>}
        </components.Option>
      </div>
    );

    const MultiValueLabel = props => (
      <div>
        <components.MultiValueLabel { ...props }>
          {props.data.label}
          {props.data.color &&
            <span className='rejected-text'>{this.props.translations.qq_create.qq_participant.rejected}</span>}
        </components.MultiValueLabel>
      </div>
    );

    const MultiValueRemove = props => {
      return (
        <components.MultiValueRemove {...props}>
          <span className="fa fa-times" onClick={this.handleTagRemove.bind(this, props)} />
        </components.MultiValueRemove>
      );
    };

    const colourStyles = {
      option: (styles, { data }) =>
        ({
          ...styles,
          color: data.color,
        }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
      }),
    };
    const {
      participantGroups, eventParticipants, tags, selectedGroups, showLoader, allowSuppliers, formGroupStep, eventParticipantsOnboardingStatus, activeFormGroup
    } = this.state;
    const { sectionKey, companyId, participantsLimit, permissions, translations} = this.props;

    const Rejected = ({ email }) => {
      let present = eventParticipantsOnboardingStatus.filter(e => e.email === email && e.onboarding_status === 'rejected').length;

      if(present) {
        return <span className='rejected-text pull-right'>{this.props.translations.qq_create.qq_participant.rejected}</span>;
      }

      return '';
    }

    let allPermission = [];
    if (permissions) {
      allPermission = permissions.permission;
    }
    const isEditable = !!_.find(allPermission, { action: 'edit', section: 'quick_quotes_settings' });
    const sandpit = cookies.get('sandpit') === 'true';
    const sandpitParticipants = sandpit && this.props.sandpitData.sandpitParticipants ? this.props.sandpitData.sandpitParticipants.map((user, idx) => (
      <div key={ user.user_id }>
        <input
          type='checkbox'
          id={ user.user_id }
          name='checkbox'
          value={ user.email }
          onChange={ e => this.handleSandpitUsers(e) }
          checked={ (tags.filter(item => user.email === item.email)).length > 0 }
        />
        <label htmlFor={ user.user_id }><span />{`${user.company_name} ${user.email}`}</label>
      </div>
    )) : '';

    return (
      <Loader show={ this.state.showLoader } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles>
        <div>
          <div className='block-item'>
            <div className='col-md-6 p-l0 table-cell-box'>
              <div className='form-item-block'>
                {!sandpit && isEditable && <NewSupplierChkbox companyId={ companyId } translations={ translations } />}
                <PopupMobile
                  companyId={ companyId }
                  sectionKey={ sectionKey }
                  text={ this.state.adminText }
                  translations={ translations }
                />
                <div className='form-group choose-box'>
                  <label htmlFor='name'>{translations.qq_create.qq_participant.who_to_invite}</label>
                  {sandpit ?
                    <div className='checkbox'>
                      {sandpitParticipants}
                    </div>
                  :
                    <div className="invite-box">
                      {(isEditable || (!isEditable && allowSuppliers)) &&
                      <CreatableSelect
                        isMulti
                        closeMenuOnSelect={ false }
                        value={ tags }
                        onChange={ v => this.handleChange(v) }
                        options={ this.canSelectMore(false) ? eventParticipants.filter(part => this.checkExisting(part.value)) : tags }
                        styles={ colourStyles }
                        components={ { Option, MultiValueLabel, MultiValueRemove } }
                      />}
                      {participantGroups.length ?
                        <div className="mt-15 btn-group-sm">
                          {(isEditable || (!isEditable && allowSuppliers)) &&
                            <span className='or'>&nbsp;{
                              translations.qq_edit.or}&nbsp;
                            </span>
                          }
                          <Field
                            name='button'
                            label={ translations.qq_create.qq_participant.choose_group }
                            type='button'
                            component={ props =>
                        (
                          <button
                            type='button'
                            className='btn btn-primary btn-fontsmall modal-btn-open'
                            onClick={ () => { openParticipantsGroupPopup(participantsLimit, this); } }
                          >
                            {props.label}
                          </button>)
                          }
                          />
                        </div>
                      : ''}
                    </div>
                  }

                </div>
                {!sandpit && (tags.length >= participantsLimit || this.state.showLimitMessage) &&
                <div className='text-danger mb-10'>
                  { translations.error_message.configuration_limit.replace("%{config_limit}", participantsLimit).replace("%{config_string}", 'participants') }
                </div>}
                {selectedGroups.length > 0 ? (
                  <div className='groupname-list'>
                    {selectedGroups
                      .map((item, idx) => (
                      <ul className='list-group' key={idx}>
                        <li className="list-group-item text-uppercase active-heading" key={'list-first'}>
                          <span className='badge' href='' onClick={ (e) => this.formGroupActions(FormGroupActions.UNSELECT_GROUP, {
                            group_id: item.id
                          }) }>
                            <i className='fa fa-times' aria-hidden='true' />
                          </span>
                          <strong>
                            {item.group_name}
                          </strong>
                        </li>
                        {item.selectedParticipants && Array.from(item.selectedParticipants).map((email, idx) => (
                          <li className="list-group-item" key={email}>
                            <span className='badge' onClick={ 
                            () => this.formGroupActions(FormGroupActions.REMOVE_PARTICIPANT, {
                              email, 
                              group_id: item.id
                            })
                            } >
                              <i className='fa fa-times' aria-hidden='true' />
                            </span>
                            {eventParticipantsOnboardingStatus.filter(e => e.email === email && e.onboarding_status === 'rejected').length ?
                                <div className='rejected-participant'>
                                  {email}
                                  <span className='rejected-text'>{translations.qq_create.qq_participant.rejected}</span>
                                </div> :
                                <div>
                                  {email}
                                </div>
                              }
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
              ) : ''}
              </div>
            </div>
            <EditorPopupSection 
              text={ this.state.adminText }
              companyId={ companyId }
              sectionKey={ sectionKey }
              translations={ translations }
              updateText={ (adminText) => { this.setState({ adminText }); } }
            />
          </div>
          <div className='main-popup popup-md select-group-popup'>
            <Modal show={ this.state.showModel } onClose={ this.close }>
              <div className='vertical-top'>
                <div className='vertical-align-center'>
                { formGroupStep === 1
                    ? <div className='modal-content clearfix'>
                      <div className='modal-header'>
                        <h5 className='modal-title'>{ translations.pop_up.select_groups }</h5>
                      </div>
                      <div className='modal-body group-list-section list-group'>
                        <DeferRenderer
                          nodes={participantGroups}
                          translations={translations}
                          wrapper={(item) => (
                            <div className={`list-group-item ${_.findIndex(selectedGroups, grp => grp.id === item.id) > -1 ? 'active-heading' : ''}`} key={ item.id }>
                              {_.findIndex(selectedGroups, grp => grp.id === item.id) > -1 
                                  ? <span className='badge pull-left mr-10 pointer' 
                                    onClick={e => this.formGroupActions(FormGroupActions.UNSELECT_GROUP, {
                                      group_id: item.id
                                    })}>
                                    <i className="fa fa-times"></i>
                                  </span> 
                                : ''
                              }
                              <span className="pointer" 
                                title={item.group_name}
                                onClick={ () => _.findIndex(selectedGroups, grp => grp.id === item.id) > -1 
                                  ? this.formGroupActions(FormGroupActions.SHOW_ACTIVE, item)
                                  : this.formGroupActions(FormGroupActions.SELECT_GROUP, item) }>
                                  { item.truncated_group_name }
                              </span>
                                &nbsp;<span className='badge'>{item.selectedParticipants && item.selectedParticipants.size ? item.selectedParticipants.size + '/' : ''}{item.no_of_participants}</span>
                            </div>)}
                            mutator={(item) => {
                              const selectedItem = this.getSelectedById(item.id);
                              let strLength = 70;
                              if(selectedItem && selectedItem.selectedParticipants) {
                                item.selectedParticipants = selectedItem.selectedParticipants;
                                strLength = 62;
                              }
                              
                              item.truncated_group_name = (item.group_name.length > strLength) ? (item.group_name.slice(0, strLength)) + '...' : item.group_name 
                              return item;
                            }}
                          />
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='btn btn-primary modal-btn-close'
                          onClick={ () => this.close() }
                        >{ translations.pop_up.done }
                        </button>
                      </div>
                    </div>
                    : <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>{translations.qq_create.qq_participant.group }: {activeFormGroup.group_name}</h5>
                    </div>
                    <div className='modal-body'>
                      <div className={`checkbox m-0`}>
                        <div>
                          <input id={ `chk_1` } 
                            type="checkbox" 
                            onChange={ () => _.findIndex(selectedGroups, grp => grp.id === activeFormGroup.id) > -1 
                              ? this.formGroupActions(FormGroupActions.UNSELECT_GROUP, {
                                group_id: activeFormGroup.id
                              })
                              : this.formGroupActions(FormGroupActions.SELECT_GROUP, activeFormGroup) }
                              checked={activeFormGroup.is_all_selected}
                            name="checkbox" />
                          <label htmlFor={`chk_1`}>
                            <span />
                            {translations.qq_create.qq_participant.select_all_email }
                          </label>
                        </div>
                      </div>
                      <hr className="mt-10 mb-10"/>
                      <ul className="list-group">
                      <DeferRenderer
                        nodes={activeFormGroup.participant_emails}
                        translations={translations}
                        paginate={200}
                        wrapper={(email, idx) => {
                          return <li className={`list-group-item`} key={ idx }>
                          <div className={`checkbox m-0`}>
                            <div>
                              <input id={ `${idx}_grp_checkbox` } 
                                type="checkbox" 
                                onChange={(e) => this.formGroupActions(FormGroupActions.MANAGE_EMAIL_SELECTION, {
                                  email, 
                                  group_id: activeFormGroup.id
                                })}
                                checked={activeFormGroup.selectedParticipants && activeFormGroup.selectedParticipants.has(email)}
                                name="checkbox" />
                              <label htmlFor={`${idx}_grp_checkbox`}>
                                <span />
                                {email}
                              </label>
                              <Rejected email={email} key={idx} />
                            </div>
                          </div>
                        </li>
                        }}
                      />
                      </ul>
                    </div>
                    <div className='modal-footer'>
                      <button
                          type='button'
                          className='btn btn-primary pull-left'
                          onClick={ () => this.formGroupActions(FormGroupActions.CHANGE_STEP, 1) }
                        >{translations.qq_create.qq_participant.back }
                      </button>
                      <button
                          type='button'
                          className='btn btn-primary modal-btn-close'
                          onClick={ () => this.close() }
                        >{ translations.pop_up.done }
                      </button>
                    </div>
                  </div>
                  }
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  configurations: state.qqReducer,
  participantGroups: state.participantGroupsReducer,
  eventParticipants: state.eventParticipantsReducer,
  eventParticipantsOnboardingStatus: state.eventParticipantsOnboardingStatusReducer,
  adminText: state.adminTextReducer,
  selectedLang: state.changeLanguageReducer,
  default: state.deleteAdminTextReducer,
  permissions: state.permissionReducer,
  status: state.supplierStatus,
  sandpitData: state.sandpitParticipantsReducer,
  companyDetails: state.companyDetailsReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchParticipantGroups: companyId => dispatch(fetchParticipantGroups(companyId)),
  fetchParticipantGrpsForEvnt: (companyId) => dispatch(fetchParticipantGrpsForEvnt(companyId)),
  fetchAdminText: (companyId, sectionKey) => dispatch(fetchAdminText(companyId, sectionKey)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
  validateParticipant: payload => dispatch(validateParticipant(payload)),
  fetchParticipantOnboardingStatuses: companyId => dispatch(fetchParticipantOnboardingStatuses(companyId)),
  fetchSandpitParticipants: () => dispatch(fetchSandpitParticipants()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QQParticipant);
