import React, { Component } from 'react';
import Modal, { closeStyle } from 'simple-react-modal';
import { connect } from 'react-redux';
import _ from 'lodash';

import { saveAdminText, deleteAdminText } from '../../../../actions/qq/adminTextActions';
import { showToasterMessages, emptyCurrentState } from '../../../../actions/qq/genericActions';
import TrixEditor from '@/components/common/TrixEditor';
import RenderHTML from "@/components/common/RenderHTML";

class PopupMobile extends Component {
  constructor() {
    super();
    this.state = {
      showModel: false,
      showAdminTxt: true,
      toggleForm: false,
      value: '',
      emptyEditor: false,
      editorText: '',
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(content) {
    this.setState({
      value: content,
      editorText: content,
    });
  }

  async saveAdminText(e) {
    if (this.state.editorText.trim()) {
      this.setState({ emptyEditor: false });
      const { companyId, sectionKey } = this.props;
      const text = this.state.value;
      this.emptyState(sectionKey);
      await this.props.saveAdminText(companyId, sectionKey, text);
      this.toggleForm(e);
    //   this.props.updateText(text);
    } else {
      this.setState({ emptyEditor: true });
    }
  }

  emptyState(sectionKey) {
    let actionType = '';
    switch (sectionKey) {
      case 'qq_header_block':
        actionType = 'FETCH_ADMIN_TEXT_HEADER_DEL';
        break;
      case 'qq_attributes_block':
        actionType = 'FETCH_ADMIN_TEXT_ATTRIBUTES_DEL';
        break;
      case 'qq_documents_block':
        actionType = 'FETCH_ADMIN_TEXT_DOCUMENTS_DEL';
        break;
      case 'qq_prices_block':
        actionType = 'FETCH_ADMIN_TEXT_PRICES_DEL';
        break;
      case 'qq_questions_block':
        actionType = 'FETCH_ADMIN_TEXT_QUESTIONS_DEL';
        break;
      case 'qq_participants_block':
        actionType = 'FETCH_ADMIN_TEXT_PARTICIPANTS_DEL';
        break;
      default:
    }
    this.props.emptyCurrentState(actionType, undefined);
  }

  openModal(e) {
    e.preventDefault();
    const { text } = this.props;
    const tempValue = text;
    this.setState({
      showModel: true,
      showAdminTxt: true,
      toggleForm: false,
      value: tempValue,
      emptyEditor: false,
      editorText: tempValue,
    });
  }

  closeModal() {
    this.setState({ showModel: false });
  }

  toggleForm(e) {
    e.preventDefault();
    const form = !this.state.toggleForm;
    const text = !this.state.showAdminTxt;
    this.setState({ toggleForm: form, showAdminTxt: text });
  }

  async deleteAdminText() {
    const { companyId, sectionKey } = this.props;
    const response = await this.props.deleteAdminText(companyId, sectionKey);
    if (response.status === 200) {
      this.props.showToasterMessages('SHOW_SUCCESS', this.props.translations.success_message.deleted);
    }
    this.closeModal();
  }

  render() {
    const { value } = this.state;
    const htmlString = value;
    const { companyId, sectionKey, translations } = this.props;
    const allPermission = this.props.permissions.permission;
    const isEditable = !!_.find(allPermission, { action: 'edit', section: 'quick_quotes_settings' });
    return (
      <div>
        <a
          href='#'
          className='information-box modal-btn-open'
          data-toggle='modal'
          data-target='#infobox'
          onClick={ e => this.openModal(e) }
        >
          <i className='fa fa-question-circle-o' aria-hidden='true' />
        </a>
        <div className='main-popup popup-md select-group-popup'>
          <Modal style={ { zIndex: 1100 } } show={ this.state.showModel } onClose={ () => this.closeModal() } closeOnOuterClick>
            <div className='vertical-top'>
              <div className='vertical-align-center'>
                <div className='modal-content clearfix'>
                  <div className='modal-body'>
                    { this.state.showAdminTxt ?
                      <div className='form-item-block border-left'>
                        {isEditable ?
                          <a
                            href='#'
                            className='editbox'
                            onClick={ e => this.toggleForm(e) }
                          >
                            <i className='fa fa-pencil-square-o' aria-hidden='true' />
                          </a> : '' }
                        <RenderHTML content={htmlString} />
                      </div> : '' }
                    {this.state.toggleForm ?
                      <div>
                        {this.state.emptyEditor ? <div className='text-danger'>{translations.error_message.required}</div> : ''}
                        <TrixEditor value={value} onChange={(content) => this.onChange(content)} />
                        <div className='m-t20'>
                          <button
                            type='button'
                            className='btn btn-primary'
                            onClick={ e => this.saveAdminText(e) }
                          >{ translations.pop_up.submit }
                          </button>
                        </div>
                      </div> : ''}
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-secondary modal-btn-close'
                      onClick={ () => this.closeModal() }
                    >{ translations.pop_up.close }
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary modal-btn-close'
                      onClick={ () => this.deleteAdminText() }
                    >{ translations.pop_up.delete }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  permissions: state.permissionReducer,
});

const mapDispatchToProps = dispatch => ({
  saveAdminText: (companyId, sectionKey, text) => dispatch(saveAdminText(companyId, sectionKey, text)),
  deleteAdminText: (companyId, sectionKey) => dispatch(deleteAdminText(companyId, sectionKey)),
  emptyCurrentState: (actionType, payload) => dispatch(emptyCurrentState(actionType, payload)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupMobile);
