import React from "react";
import { useSelector } from "react-redux";
import { smallOrMiniCell, formatPercentileNumber } from "../../../common";
import LiDetailModal from "./LiDetailModal";

/**
 *
 * @description Host monitor
 *
 * Column - Weighted score
 *
 */
export default function LiWeightedScore(props) {
  const { displayScores, weightedRfqOrAuction, lineItem, lineItemBestBlic } = props;
  const { translations, locale } = useSelector((state) => state.lotReducers);

  const weightedScore = lineItemBestBlic ? formatPercentileNumber(lineItemBestBlic?.weighted_score, locale) : "-%";
  const header = `${translations.current_bids}: ${lineItem?.name}`;
  let showOverallScore = true;
  return (
    displayScores && (
      <div className={`mycell ${smallOrMiniCell(weightedRfqOrAuction)}`}>
        {lineItemBestBlic ? (
          <LiDetailModal {...props} header={header} viewComponent={weightedScore} showOverallScore={showOverallScore} />
        ) : (
          <span title="-">-</span>
        )}
      </div>
    )
  );
}
