import React from "react";
import SimSettingInput from "./SimSettingInput";
import SimSettingCheckbox from "./SimSettingCheckbox";
import { ExpiredQAPropTypes, SettingWithSelectPropTypes } from "./prop-types";

const settings = [
  {
    label: "expire_questionnaire_answers_after",
    id: "expired_questionnaire_answers_period_of_expiry",
  },
  {
    label: "notify_participants",
    id: "expired_questionnaire_answers_before_expiry",
  },
  {
    label: "notify_participants_every",
    id: "expired_questionnaire_answers_frequency_of_notification",
  },
];

/**
 * @type {React.FC<typeof SettingWithSelectPropTypes>}
 */

const SettingWithSelect = (props) => {
  const { configurationSettingValues, currentUserEditConfigPermission } = props;
  const onChange = (value, id) => {
    if (value !== configurationSettingValues[id]) {
      props.onValueChange(value, id);
    }
  };

  return (
    <>
      {settings.map((setting) => (
        <SimSettingInput
          key={setting.id}
          translations={props.translations}
          durationType={props.durationType}
          disabled={!currentUserEditConfigPermission}
          value={configurationSettingValues[setting.id]}
          onChange={(value) => onChange(value, setting.id)}
          name={setting.id}
          label={setting.label}
        />
      ))}
    </>
  );
};

SettingWithSelect.propTypes = SettingWithSelectPropTypes;

/**
 * @type {React.FC<typeof ExpiredQAPropTypes>}
 */

const ExpiredQA = (props) => {
  const { translations, configurationSettingValues } = props;

  return (
    <div className="clearboth form-item-block">
      <div className="form-item-block-title">
        {translations.admin_views.configuration.expired_questionnaire_answers}
      </div>
      <SettingWithSelect {...props} />
      <SimSettingCheckbox
        value={configurationSettingValues.existing_answer_to_remain_on_the_questionnaire}
        name="existing_answer_to_remain_on_the_questionnaire"
        onChange={props.onValueChange}
        disabled={!props.currentUserEditConfigPermission}
      />
    </div>
  );
};

ExpiredQA.propTypes = ExpiredQAPropTypes;

export default ExpiredQA;
