import * as actionTypes from "../actions/actionsTypes";

const messageReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MESSAGE_SENT:
      return {
        message: action.payload,
      };
    case actionTypes.SHOW_MESSAGE_MODAL:
      return {
        showModal: action.payload,
      };
    case actionTypes.GET_MESSAGES:
      return {
        messages: action.payload,
      };
    default:
      return state;
  }
};

export default messageReducer;
