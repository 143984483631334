import React from "react";
import { currencyStringWithSymbol, hostCellValue, show } from "../../common";

const LicHostCellComponent = (props) => {
  const _currWithSymbol = currencyStringWithSymbol({
    value: show(props.lic, "used_price"),
    symbol: props.licCurrency.symbol,
    precision: props.lic.decimal_place,
    locale: props.locale,
    numberFormat: props.translations.number_format,
    currency: props.licCurrency,
  });
  return (
    <div className="bid-cell-content">
      {props.lic.is_formula_lic ? (
        <span className="calc-price lot-cell">{_currWithSymbol}</span>
      ) : !props.lic.is_price ? (
        <span className="attribute-value">
          <div className="editor-information white-space-pre-wrap" title={hostCellValue(props.type, props.lic)}>
            {hostCellValue(props.type, props.lic)}
          </div>
        </span>
      ) : (
        _currWithSymbol
      )}
    </div>
  );
};

export default LicHostCellComponent;
