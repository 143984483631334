import * as actionTypes from "./actionsTypes";
import * as apiCall from "./contract/contractAjax";
import { getError, getErrorMessage } from "./errorActions";

const simSettingConfigurationSuccess = (data) => {
  return {
    type: actionTypes.SIM_CONFIGURATION_SUCCESS,
    payload: data,
  };
};

const SimSettingEnforceLocale = (data) => {
  return {
    type: actionTypes.ENFORCE_LOCALES,
    payload: data,
  };
};
const tagUiSuccess = (data) => {
  return {
    type: actionTypes.TAG_UI_SUCCESS,
    payload: data,
  };
};

const showSuccess = (successMessage) => {
  return { type: actionTypes.SUCCESS, success: successMessage };
};

export const simSettingConfiguration = (data) => (dispatch) => {
  return apiCall
    .post(`/simdojo/companies/${data[0]}/configuration`, data[1])
    .then((response) => {
      dispatch(simSettingConfigurationSuccess(response.data.data));
      dispatch(showSuccess(response.data.message));
    })
    .catch((error) => {
      dispatch(simSettingConfigurationSuccess(error.response.data.data));
      dispatch(getError(error.response));
    });
};

export const unauthorized = (data) => (dispatch) => {
  dispatch(getErrorMessage(data.unauthorized)),
    dispatch(simSettingConfigurationSuccess(data.configuration_setting_values));
};

export const mdSettingConfiguration = (data) => (dispatch) => {
  return apiCall
    .post(`/companies/${data[0]}/configuration`, data[1])
    .then((response) => {
      dispatch(simSettingConfigurationSuccess(response.data.data));
      dispatch(showSuccess(response.data.message));
    })
    .catch((error) => {
      dispatch(simSettingConfigurationSuccess(error.response.data.data));
      dispatch(getError(error.response));
    });
};

export const EnfoceLocaleUpdate = (data) => (dispatch) => {
  return apiCall
    .post(`/simdojo/companies/${data[0]}/enforce_locale`, data[1])
    .then((response) => {
      if (response.status == 200) {
        dispatch(SimSettingEnforceLocale(data[1])), dispatch(showSuccess(response.data.message));
      } else {
        dispatch(getError(response));
      }
    })
    .catch((error) => {
      dispatch(getErrorMessage(error.message));
    });
};

export const TagUi = (data) => (dispatch) => {
  return apiCall
    .put(`/companies/${data[0]}/tag_categories/${data[1].tag_category.id}`, data[1])
    .then((response) => {
      dispatch(tagUiSuccess(response.data.data));
      dispatch(showSuccess(response.data.message));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
      return error.response.data;
    });
};

export const TagUiCreate = (data) => (dispatch) => {
  return apiCall
    .post(`/companies/${data[0]}/tag_categories`, data[1])
    .then((response) => {
      dispatch(tagUiSuccess(response.data.data));
      dispatch(showSuccess(response.data.message));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const RemoveTagCategory = (data, companyId, catgoryId) => (dispatch) => {
  return apiCall
    .remove(`/companies/${companyId}/tag_categories/${catgoryId}`, data)
    .then((response) => {
      dispatch(tagUiSuccess(response.data.data));
      dispatch(showSuccess(response.data.message));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const noPermission = (data) => (dispatch) => {
  dispatch(getErrorMessage(data.unauthorized));
};

export const cannotRemoveTags = (message) => (dispatch) => dispatch(getErrorMessage(message));
