// @ts-check
import { Controller } from "@hotwired/stimulus";
import { formatInput, formatAndValidate } from "@/price_input_component";

export default class extends Controller {
  connect() {
    const input = this.#input();

    input.addEventListener("input", this.#formatInput);
    input.addEventListener("blur", this.#formatAndValidate);

    formatAndValidate(input);
  }

  disconnect() {
    const input = this.#input();

    input.removeEventListener("input", this.#formatInput);
    input.removeEventListener("blur", this.#formatAndValidate);
  }

  #input = () => {
    const input = this.element.querySelector('input[type="text"]');
    if (!input) {
      throw new Error("Price input controller requires an input element");
    }

    return input;
  };

  #formatInput = () => {
    formatInput(this.#input());
  };

  #formatAndValidate = () => {
    formatAndValidate(this.#input());
  };
}
