import React from "react";
import PropTypes from "prop-types";
import RenderHTML from "@/components/common/RenderHTML";
import RenderPermissionDetails from "./AdminPermission";
import { t } from "@/i18n";
import { AdminPermissionPropTypes } from "./prop-types";

const EventParticipantPermissionPropTypes = {
  eventparticipantPermissions: PropTypes.array,
  simPermissionsList: PropTypes.arrayOf(PropTypes.string),
  simPermissions: PropTypes.arrayOf(PropTypes.shape(AdminPermissionPropTypes)),
};

/**
 * @type {React.FC<typeof EventParticipantPermissionPropTypes>}
 */
const EventParticipantPermission = ({ eventparticipantPermissions, simPermissionsList, simPermissions }) => {
  return (
    <>
      <RenderPermissionDetails allPermissions={simPermissionsList} permissionList={simPermissions} />
      {Array.isArray(eventparticipantPermissions) &&
        eventparticipantPermissions.map((eventParticipantPermission) => {
          const resourceName = eventParticipantPermission.resource_name;
          const string = `${t("can")} ${t(eventParticipantPermission.action)} ${resourceName} ${t(
            "sim_dojo:owned_sim_participants"
          )}`;

          return (
            <>
              <RenderHTML key={eventParticipantPermission.id} content={string} />
              <br />
            </>
          );
        })}
    </>
  );
};
EventParticipantPermission.propTypes = EventParticipantPermissionPropTypes;
export default EventParticipantPermission;
