export const updateEskerRequisitionLotData = (event, currencies, esker_integration_details) => {
  return function (lot) {
    const procurementLineId = esker_integration_details.find((details) => details.lot_id === lot.id).details;
    return {
      procurementLineId,
      ItemDescription__: lot.name,
      VendorName__: lot.participant?.user_company_name || lot.participant?.user_company_name,
      ItemQuantity__: lot.quantity,
      ItemUnitPrice__: lot.participantWinningBid?.host_price,
      ItemCurrency__: currencies[lot.exchange_rate_id || event.currency_id].name,
    };
  };
};

export const updateEskerRequisitionUrl = (procurementDocType, url, event, procurementDocId, lotData) => {
  const data = JSON.stringify({
    Reason__: event.name,
    procurementDocType: procurementDocType,
    procurementDocId: procurementDocId,
    lineItems__: lotData,
  });

  return url + encodeURIComponent(data);
};

export default updateEskerRequisitionUrl;
