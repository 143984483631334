import React from "react";
import { checkNode } from "@/slices/rules/types";
import { LeafNode } from "./leaf-node";
import { BranchNode } from "./branch-node";
import { useAppSelector } from "@/hooks/redux";
import { selectRuleById } from "@/slices/rules";

type NodeProps = {
  uuid: string;
};

export function NodeComp({ uuid }: NodeProps) {
  const node = useAppSelector((state) => selectRuleById(state, uuid));
  if (!node) {
    return null;
  }
  if (checkNode.isLeaf(node)) {
    return <LeafNode node={node} />;
  } else if (checkNode.isBranch(node)) {
    return <BranchNode node={node} />;
  }
  return null;
}
