import React, { Fragment } from "react";
import { ShowTooltip } from "./ContractTooltip";
import SupplierStakeholderInput from "./SupplierStakeholderInput";

const GetSupplier = (props) => {
  const {
    includedSuppliers,
    handleIncludeRecipients,
    inputIds,
    supplier,
    index
  } = props;
  return(
    <tr key={index}>
      <td>
        <input type="checkbox"
        id={`${inputIds[1]}${supplier.id}`}
        className="notification-supplier"
        checked={includedSuppliers.includes(supplier.user_id)}
        onChange={(e) => handleIncludeRecipients(e, false, false, supplier.user_id)}
        />
        <label htmlFor={`${inputIds[1]}${supplier.id}`} />
      </td>
      <td>{supplier.name ? supplier.name : supplier.email}</td>
      <td>{supplier.status}</td>
    </tr>
  );
};

const SupplierHeaders = (props) => {
  const {translations} = props;

  return(
    <thead>
      <tr className="select_all">
        <th />
        <th>
          {translations.user_name}
        </th>
        <th>
          {translations.user_status}
          <ShowTooltip
            translations={translations}
            titleText={'notification_supplier_status'}
          />
        </th>
      </tr>
    </thead>
  );
}

const SuppliersNotification = (props) => {
  const { stakeHoldersBox, translations, suppliers, inputIds } = props;
  return(
    <div>
      {!stakeHoldersBox && (suppliers.length > 0 ? (
      <Fragment>
        <SupplierStakeholderInput {...props}
          userType={'suppliers'} inputId={inputIds[0]}
        />
        <label htmlFor={inputIds[0]}>{translations.all_suppliers}</label>
        <div className="notification-content-box custom_notification_table">
          <table className="table _scrolling _thead">
            <SupplierHeaders translations={translations} />
            <tbody>
              {suppliers.map((supplier, index) => (
                <GetSupplier {...props} supplier={supplier} index={index} />
              ))};
            </tbody>
          </table>
        </div>
      </Fragment>
      ) : (
        <div className="notification-content">{translations.notification_supplier_msg}</div>
      ))}
    </div>
  );
};

export default SuppliersNotification;
