import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { getHistory } from "../../../actions/contract/contractCreateEditActions";
import Tooltip from "../../common/Tooltip";
import { mapCommonReducerProps } from "./selectors";
import RenderHTML from "@/components/common/RenderHTML";
import PropTypes from "prop-types";

const ItemObjectPropTypes = {
  entityChanged: PropTypes.string,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changeMadeBy: PropTypes.string,
  whatChanged: PropTypes.string,
  previousValue: PropTypes.string,
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updatedAt: PropTypes.string,
  eventType: PropTypes.string,
  whatChangedForSim: PropTypes.string,
};

const HistoryDetailsTableBodyPropTypes = {
  history: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))).isRequired,
  isSimDojo: PropTypes.bool.isRequired,
};

const HistoryDetailsTableRowPropTypes = {
  itemObject: PropTypes.shape(ItemObjectPropTypes).isRequired,
  isSimDojo: PropTypes.bool.isRequired,
};

/**
 * @param {Array<string>} array
 * @param {boolean} isSimDojo
 * @returns {object} itemObject
 */
const getTheItemObject = (array, isSimDojo) => {
  const keys = [
    "entityChanged",
    "entityId",
    "changeMadeBy",
    "whatChanged",
    "previousValue",
    "newValue",
    "updatedAt",
    "eventType",
  ];
  if (isSimDojo) keys.push("whatChangedForSim");

  return array.reduce((obj, value, index) => {
    obj[keys[index]] = value;
    return obj;
  }, {});
};

/**
 * @param {string} key
 * @param {string} value
 * @returns {string}
 */
const tableCellEllipsis = (key, value) => {
  const valueCheck = key === "previousValue" || key === "newValue";
  let valueToShow = value;
  if (valueCheck && typeof value == "object") {
    valueToShow = JSON.stringify(value);
  }
  return `<div className="table-cell-ellipsis" title="${valueToShow}">${valueToShow}</div>`;
};

/**
 * @param {ItemObjectPropTypes} itemObject
 * @param {boolean} isSimDojo
 * @returns string
 */
const tabelCellWhatChanged = (itemObject, isSimDojo) => {
  const whatChanged = itemObject.whatChanged;
  if (isSimDojo) {
    const whatChangedForSim = itemObject.whatChangedForSim;
    return `<div className="table-cell-ellipsis" title="${whatChanged}"><b>${whatChanged}<br />${whatChangedForSim}</b></div>`;
  } else {
    return tableCellEllipsis("whatChanged", whatChanged);
  }
};

/**
 * @type {React.FC<typeof HistoryDetailsTableRowPropTypes>}
 */
const HistoryDetailsTableRow = ({ itemObject, isSimDojo }) => {
  return (
    <>
      {Object.keys(itemObject).map((key, index) => {
        const string =
          key === "whatChanged" ? tabelCellWhatChanged(itemObject, isSimDojo) : tableCellEllipsis(key, itemObject[key]);
        return (
          <td className="align-middle" key={index}>
            {string ? <RenderHTML content={string} /> : "-"}
          </td>
        );
      })}
    </>
  );
};
HistoryDetailsTableRow.propTypes = HistoryDetailsTableRowPropTypes;

/**
 * @type {React.FC<typeof HistoryDetailsTableBodyPropTypes>}
 */
export const HistoryDetailsTableBody = ({ history, isSimDojo }) => {
  return (
    <>
      {history.map((item, index) => {
        const itemObject = getTheItemObject(item, isSimDojo);
        return (
          <tr key={index}>
            <HistoryDetailsTableRow itemObject={itemObject} isSimDojo={isSimDojo} />
          </tr>
        );
      })}
    </>
  );
};
HistoryDetailsTableBody.propTypes = HistoryDetailsTableBodyPropTypes;

class HistoryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      history: [],
    };
    this.sortHistoryDetails = this.sortHistoryDetails.bind(this);
  }

  static getDerivedStateFromProps(newProps) {
    let { history } = newProps.data;
    if (history) {
      history = history.sort(function (a, b) {
        return new Date(a[6]) > new Date(b[6]) ? -1 : 1;
      });
      document.getElementById("historyTable")?.classList.remove("loading");
      return { history };
    }
    return null;
  }

  sortHistoryDetails(e) {
    const { history } = this.state;
    let sortedHistory;
    const currentElementClassList = e.target.classList;
    if (currentElementClassList.contains("desc")) {
      currentElementClassList.remove("desc");
      currentElementClassList.add("asc");
      sortedHistory = history.reverse();
    } else {
      currentElementClassList.remove("asc");
      currentElementClassList.add("desc");
      sortedHistory = history.reverse();
    }
    this.setState({
      history: sortedHistory,
    });
  }

  openModal = () => {
    this.props.getHistory(this.props.contractId, this.props.eventParticipantId, this.props.eventId);
    this.setState({
      openModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      openModal: false,
      history: [],
    });
  };

  render() {
    const { translations, componentName, downloadHistory } = this.props;
    const { openModal, history } = this.state;
    let style = {
      content: {
        border: "0px",
        background: "rgb(255, 255, 255)",
        overflow: "auto",
        outline: "none",
        maxWidth: "80%",
      },
    };
    return (
      <>
        <button
          className="border btn btn-sm btn-default btn-primary shadow-light"
          onClick={() => {
            this.openModal();
          }}
        >
          {translations.history}
        </button>
        <div className="inline-block p-l10">
          <Tooltip {...translations.help_texts.history} />
        </div>
        <Modal
          isOpen={openModal}
          onRequestClose={() => this.closeModal()}
          contentLabel="History Modal"
          style={style}
          className="shadow"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.closeModal();
                }}
              >
                &times;
              </button>
              <h4 className="modal-title">
                {translations.history}
                {componentName == "HistoryDetails" && (
                  <a className="font-s14 m-l10" href={downloadHistory} title={translations.download}>
                    <i className="fa fa-download"></i>
                  </a>
                )}
              </h4>
            </div>
            <div className="clearfix modal-body">
              <div className="col-md-12 col-sm-12">
                <div className="form-section-block newcontract-history overflow-x-auto">
                  <div className="scrollable">
                    <div className="create-edit-container scrollable">
                      <table
                        id="historyTable"
                        className="loading table table-bordered table-layout-fixed style-table-contracts"
                      >
                        <thead className="table-block-title">
                          <tr>
                            <td className="w-15">{translations.entity_changed}</td>
                            <td className="w-8">{translations.entity_id}</td>
                            <td className="w-20">{translations.change_made_by}</td>
                            <td className="w-15">{translations.what_changed}</td>
                            <td className="w-10">{translations.previous_value}</td>
                            <td className="w-10">{translations.new_value}</td>
                            <td className="sortable desc w-15" onClick={this.sortHistoryDetails}>
                              {translations.updated_at}
                            </td>
                            <td className="w-8">{translations.event_type}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <HistoryDetailsTableBody history={history} isSimDojo={componentName === "HistoryDetails"} />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDipatchToProps = (dispatch) => ({
  getHistory: (contractId = null, eventParticipantId = null, eventId = null) =>
    dispatch(getHistory(contractId, eventParticipantId, eventId)),
});
export default connect(mapCommonReducerProps, mapDipatchToProps)(HistoryDetails);
