import React from "react";
import { participantBidInRound } from "../../../../../common";
import { completed, isRfq, pause, pending, postRfqPreAuction, running } from "../../../../../eventCommon";
import LotBidComponent from "../../../../../bids/LotBidComponent";
import { useSelector } from "react-redux";
import last from "lodash/last";
import { bidSubmitted, participantNotTimedOutOrDeclined } from "../../../../../bidCommon";

export const ProxyBidButtonOrStatusDuringAuction = ({ rejectedLots, rankValue, ...rest }) => {
  let result;
  const {
    event,
    isJapaneseEvent,
    translations,
    auctionControls,
    lot,
    participant,
    bids,
    bidRound,
    lots,
    currency,
    allUnitSets,
    latestBid,
    user,
  } = rest;
  const {
    bid_placed,
    event_is_paused,
    event_participant_pending_your_acceptance,
    bidding_has_not_started,
    bidding_closed,
  } = translations;
  const defaultProp = {
    ...rest,
    licCurrency: currency,
    isProxyBid: true,
    unitSets: allUnitSets,
  };
  const event_rounds = useSelector(({ lotReducers }) => lotReducers.event_rounds);
  const isAcceptedByParticipant =
    !lot.is_event_total && participant.accepted && [null, true, 1].includes(participant.participating);
  const isParticipantBidRound = participantBidInRound(participant, event, bidRound, auctionControls, bids, lots, lot);

  const isMsrfq = isRfq(event) && event_rounds?.length > 1;
  const bid = isMsrfq ? latestBid : bids.find?.(({ lot_id }) => lot_id === lot.id);
  const bidExist = latestBid && latestBid.bid_round === bidRound;
  const isBidExisting = bidRound > 1 && bid && !bidExist;

  const lastRound = last(event_rounds);
  const lastBid = bids
    .filter(({ lot_id, user_id }) => lot_id === lot.id && user_id === participant.user_id)
    .reverse()?.[0];
  const isBidSubmittedForMSRFQ = lastBid && lastBid?.event_round_id === lastRound?.id && bidSubmitted(lastBid);
  const isRfqEventNotCompleted = isMsrfq && !completed(event);

  const isBidPlacedShowingInPausedAuction =
    isJapaneseEvent &&
    ((isParticipantBidRound && !isBidExisting) ||
      (!isAcceptedByParticipant && participant.accepted && !lot.is_event_total));

  if (rejectedLots.length) return "";
  if (completed(event)) result = bidding_closed;
  if (pause(event)) {
    if (isBidPlacedShowingInPausedAuction) result = bid_placed;
    else result = event_is_paused;
  }
  if (postRfqPreAuction(event) || pending(event)) result = bidding_has_not_started;
  if (running(event) || isRfqEventNotCompleted) {
    if (isJapaneseEvent) {
      if (isParticipantBidRound) {
        const prop = {
          ...defaultProp,
          rankValue,
        };

        if (isBidExisting) result = <LotBidComponent {...prop} />;
        else result = bid_placed;
      } else if (isAcceptedByParticipant) {
        result = <LotBidComponent {...defaultProp} />;
      } else if (!participant.accepted) result = event_participant_pending_your_acceptance;
      else result = !lot.is_event_total ? bid_placed : "";
    } else {
      const prop = {
        rankValue,
        isPlaceBidActiveFlag: true,
        isAuction: true,
        ...defaultProp,
      };
      if (!participant.accepted) result = event_participant_pending_your_acceptance;
      else if (isAcceptedByParticipant) result = <LotBidComponent {...prop} />;
      if (isAcceptedByParticipant && isBidSubmittedForMSRFQ) result = bid_placed;
    }
  }
  const bidsForParticularUser = bids.filter((bid) => bid.user_id === user.id);
  const LBidsForParticularUser = bidsForParticularUser.filter((b) => b.lot_id === lot.id);
  const selectedBid = last(LBidsForParticularUser);
  if (
    isJapaneseEvent &&
    biddingClosedForProxy({ selectedBid, LBidsForParticularUser, bidRound, user, auctionControls })
  )
    result = bidding_closed;

  return <>{result}</> ?? "";
};

export default ProxyBidButtonOrStatusDuringAuction;

export const biddingClosedForProxy = (props) => {
  return (
    Boolean(props.selectedBid?.decline_bid) ||
    (props.bidRound > 1 &&
      !participantNotTimedOutOrDeclined(
        props.LBidsForParticularUser,
        props.bidRound,
        props.user,
        props.auctionControls
      ))
  );
};
