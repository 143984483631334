import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
  addStakeholderContact,
  removeContact,
  updateContactLabel,
} from "../../../actions/contract/contractCreateEditActions";
import { Confirm } from "../../common/Confirm";
import ContractTooltip from "./ContractTooltip";
import RenderHTML from "@/components/common/RenderHTML";

class StakeholdersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableAddUserButton: false,
      selectedUsers: [],
      userIdToRemove: "",
    };

    this.handleAddUser = this.handleAddUser.bind(this);
  }

  componentDidMount() {
    this.addTagItToTextBox();
  }

  componentDidUpdate() {
    this.addTagItToTextBox();
  }

  async handleAddUser(e) {
    this.setState({
      disableAddUserButton: true,
    });
    const userEmails = this.userEmails.value;
    if (userEmails.length) {
      await this.props.addStakeholderContact(this.props.contractId, userEmails);
      $("#stakeholder_tag_handler").tagit("removeAll");
    }
    this.setState({
      disableAddUserButton: false,
    });
  }

  addTagItToTextBox() {
    const { availableStakeholders } = this.props;
    $("#stakeholder_tag_handler").tagit({
      availableTags: availableStakeholders,
      singleField: true,
      singleFieldNode: $("#stakeholder_emails"),
    });
  }

  handleRemove(userId) {
    this.props.removeContact(this.props.contractId, userId, "stakeholder");
  }

  async updateUserToRemove(userId, e) {
    e.preventDefault();
    this.setState({
      userIdToRemove: userId,
    });
  }

  handleLabelChange(e, supplierId) {
    this.props.updateContactLabel(this.props.contractId, supplierId, e.target.value, "", "supplier");
  }

  /**
   * @param {string} value
   * @returns {string}
   */
  tableCellEllipsis(value) {
    return `<div className="table-cell-ellipsis" title="${value}">${value}</div>`;
  }

  render() {
    const { translations, showPage, addedStakeholders } = this.props;
    return (
      <div className="col-md-12 col-sm-12">
        <div className="form-section-block overflow-x-auto supplier-contract-section">
          <ContractTooltip translations={translations} titleText={"all_stakeholders"} />
          {!showPage && (
            <Fragment>
              <div className="form-group" style={{ width: "45%", float: "left" }}>
                <label htmlFor="event-name" className="label-ellipsis">
                  {translations.find_an_existing_user_or_host_group}
                </label>
                <input type="hidden" id="stakeholder_emails" ref={(ref) => (this["userEmails"] = ref)} />
                <ul id="stakeholder_tag_handler" />
              </div>
              <div className="col-12 contract_type_select mt-add-button">
                <label>&nbsp;</label>
                <button
                  className="btn btn-sm btn-default"
                  style={{
                    color: "#ff8c00",
                    borderColor: "#ff8c00",
                    float: "left",
                    height: "37px",
                    paddingTop: "8px",
                  }}
                  disabled={this.state.disableAddUserButton}
                  onClick={this.handleAddUser}
                >
                  {translations.add}
                </button>
              </div>
              <p className="clear-both" />
            </Fragment>
          )}
          {addedStakeholders.length > 0 && (
            <table className="w-100 table-layout-fixed">
              <thead className="table-block-title">
                <tr>
                  <td className="w-20">
                    <div>{translations.label}</div>
                  </td>
                  <td className="w-10">
                    <div>{translations.name}</div>
                  </td>
                  <td className="w-10">
                    <div>{translations.email}</div>
                  </td>
                  <td className="w-10">
                    <div>{translations.phone_number || "-"}</div>
                  </td>
                  {!showPage && <td className="w-8"> </td>}
                </tr>
              </thead>
              <tbody id="participant_summary_body">
                {addedStakeholders.map((stakeholder) => (
                  <tr key={stakeholder.id}>
                    <td>
                      {showPage ? (
                        stakeholder.label
                      ) : (
                        <input
                          className="form-control w-75"
                          type="text"
                          onBlur={(e) => this.handleLabelChange(e, stakeholder.user_id)}
                          defaultValue={stakeholder.label}
                        />
                      )}
                    </td>
                    <td>
                      <RenderHTML content={this.tableCellEllipsis(stakeholder.name)} />
                    </td>
                    <td>
                      <RenderHTML content={this.tableCellEllipsis(stakeholder.email)} />
                    </td>
                    <td>
                      {stakeholder.phone_number ? (
                        <RenderHTML content={this.tableCellEllipsis(stakeholder.phone_number)} />
                      ) : (
                        "-"
                      )}
                    </td>
                    {!showPage && (
                      <td>
                        <a
                          onClick={(e) => this.updateUserToRemove(stakeholder.user_id, e)}
                          data-toggle="modal"
                          data-target="#stakeholderRemoveConfirmModal"
                          rel="nofollow"
                        >
                          <i title={translations.remove} className="fa fa-times text-danger" />
                        </a>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <p className="clear-both" />
        </div>
        <Confirm
          translations={translations}
          title={translations.confirmation_popup_details.delete_stakeholder_title}
          message={translations.confirmation_popup_details.delete_stakeholder_text}
          htmlId="stakeholderRemoveConfirmModal"
          onOkayButtonClick={() => this.handleRemove(this.state.userIdToRemove)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ createEditReducers }) => ({
  availableStakeholders: createEditReducers.contractUserData.stakeholders.available,
  addedStakeholders: createEditReducers.contractUserData.stakeholders.added,
  contractId: createEditReducers.contract.id,
  translations: createEditReducers.translations,
  showPage: createEditReducers.showPage,
});

const mapDispatchToProps = (dispatch) => ({
  addStakeholderContact: (contractId, userEmails) => dispatch(addStakeholderContact(contractId, userEmails)),
  removeContact: (contractId, userId, type) => dispatch(removeContact(contractId, userId, type)),
  updateContactLabel: (contractId, userId, label, type) =>
    dispatch(updateContactLabel(contractId, userId, label, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StakeholdersForm);
