import React from "react";

export const statusLabel = (param) => {
  switch (param) {
    case "open":
    case "in_progress":
      return "#70b9eb";
    case "overdue":
      return "#f75945";
    case "completed":
      return "#46c37b";
    case "cancelled":
      return "lightgray";
    default:
      return "pink";
  }
};

const _defaultLabelColors = {
  low: "grey",
  medium: "blue",
  high: "orange",
  critical: "red",
};

const _resolveColor = (color, labelName) =>
  color || (labelName && _defaultLabelColors[labelName.toLowerCase()]) || "#70b9eb";

const _resolvePriority = (priority, option) =>
  (priority === option.name || priority == option.id) && option.custom_column_name === "priority";

export const priorityLabel = (priority, options) => {
  let color = false;
  let labelName = "";
  try {
    for (let i = 0; i < options.length; i++) {
      if (_resolvePriority(priority, options[i])) {
        color = JSON.parse(options[i].config).color;
        labelName = options[i].name;
        break;
      }
    }
  } catch (err) {
    color = false;
  }

  return _resolveColor(color, labelName);
};
