import * as actionTypes from "../../actions/actionsTypes";

// ================ Action creators ================ //

//reset all states
export const resetCommonModal = () => ({
  type: actionTypes.COMMON_MODAL_SET_INITIAL,
});

export const dispatchModalSubmitRequest = () => ({
  type: actionTypes.COMMON_MODAL_SUBMIT_REQUEST,
});

//If request is completed
export const dispatchModalSubmitRequestCompleted = () => ({
  type: actionTypes.COMMON_MODAL_SUBMIT_REQUEST_COMPLETED,
});

//If request is failed
export const dispatchModalSubmitRequestFailure = () => ({
  type: actionTypes.COMMON_MODAL_SUBMIT_FAILURE,
});

//Called automatically when common modal get activated
export const makeCommonModalActive = () => ({
  type: actionTypes.COMMON_MODAL_ACTIVE,
});

// ================ Reducer ================ //

//initial states
const initialState = {
  isSubmitting: false,
  isSubmitted: false,
  isSubmitError: false,
  isActive: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type } = action;
  switch (type) {
    case actionTypes.COMMON_MODAL_SUBMIT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case actionTypes.COMMON_MODAL_SUBMIT_REQUEST_COMPLETED: {
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };
    }
    case actionTypes.COMMON_MODAL_SUBMIT_FAILURE: {
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: false,
        isSubmitError: true,
      };
    }
    case actionTypes.COMMON_MODAL_SET_INITIAL:
      return initialState;

    case actionTypes.COMMON_MODAL_ACTIVE:
      return {
        ...state,
        isActive: true,
      };

    default:
      return state;
  }
}

// ================ Thunks ================ //

//Send Submit request
export const sendCommonModalSubmitRequest = () => (dispatch) => {
  dispatch(dispatchModalSubmitRequest());
};

// Call when request is completed
export const commonModalSubmitRequestCompleted = () => (dispatch) => {
  dispatch(dispatchModalSubmitRequestCompleted());
};

//Call request is failed
export const sendCommonModalSubmitRequestFailure = () => (dispatch) => {
  dispatch(dispatchModalSubmitRequestFailure());
};
