import React, { Component } from 'react';
import _ from 'lodash';

class AlreadyUploadedDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alreadyUploaded: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { parentComponent } = this.props;
    if(parentComponent == 'Documents'){
      const documentLibraryuploads = JSON.parse(localStorage.getItem('documentLibraryuploads'));
      if(documentLibraryuploads){
        this.setState({
          alreadyUploaded: documentLibraryuploads.documents,
        });
      }
    } else {
      const restField = JSON.parse(localStorage.getItem('restField'));
      this.setState({
        alreadyUploaded: restField?.documents,
      });
    } 
    
  }

  UNSAFE_componentWillReceiveProps(next) {
    if(next.alreadyUploaded) {
      this.setState({alreadyUploaded: next.alreadyUploaded})
    }
  }

  removeDocument(index) {
    const { alreadyUploaded } = this.state;
    let slicedAlreadyUploaded = alreadyUploaded.splice(index, 1);
    const { RefreshDocuments, parentComponent } = this.props;
    if(parentComponent == 'Documents'){
      const documentLibraryuploads = JSON.parse(localStorage.getItem('documentLibraryuploads'));
      documentLibraryuploads.documents = alreadyUploaded;
      localStorage.setItem('documentLibraryuploads', JSON.stringify(documentLibraryuploads));
      RefreshDocuments(documentLibraryuploads.documents);
    }else{
      const restField = JSON.parse(localStorage.getItem('restField'));
      restField.documents.splice(_.findIndex(restField.documents, slicedAlreadyUploaded[0]), 1);
      localStorage.setItem('restField', JSON.stringify(restField));
      RefreshDocuments(alreadyUploaded);
    }
    this.setState({
      alreadyUploaded,
    });
  }

  render() {
    const {  alreadyUploaded } = this.state;
    const { labelText } = this.props;
    return ( 
      <div>
        {alreadyUploaded?.length > 0 ? (
          <div className='table-scroll event-list col-md-12 m-b20'>
            <label>{labelText}</label>
            <table className='common-table table-align-top'>
              <tbody>
                {alreadyUploaded.map((item, idx) => (
                  <tr key={ item.name + idx }>
                    <td className='document-col-one'>
                      <div>
                        {item.name}
                      </div>
                    </td>
                    <td className='text-right document-col-two'>
                      <div>
                        <button type='button' className='icon-link' onClick={ () => this.removeDocument(idx) }>
                          <i className='fa fa-trash-o' aria-hidden='true' />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      ) : ''}
      </div>
    )
  }
}  

export default AlreadyUploadedDocuments;
