import React, { memo } from "react";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import ParticipantBidDetail from "../participantBidDetail";
import ParticipantBidValueForLotAndLineItem from "../ParticipantBidValueForLotAndLineItem";
import { expandLiForLot, expandLcForParticipant } from "../../../customCollapse";

/**
 *
 * @description Participants tabs
 *
 */
const ParticipatingParticipants = memo((props) => {
  const {
    loaderReducers: { isFetchBlicsLoading },
    lotReducers: { participating_participants },
  } = useSelector((state) => state);

  const { permissionToView, isLcOpened, isEpOpened, isLiOpened, lotLi, licCurrency, lineItemLic, totalLc } = props;
  const { current_bids } = props.translations;

  const header = `${current_bids}: ${lotLi.name}`;
  return (
    permissionToView &&
    participating_participants.map((ep, index) => {
      return (isEpOpened || (isEpOpened && expandLiForLot(isLiOpened, lot.id))) &&
        !expandLcForParticipant(isLcOpened, ep.id) ? (
        <ParticipantBidValueForLotAndLineItem
          {...props}
          key={`${index}-${ep.id}-${Date.parse(ep.updated_at)}`}
          ep={ep}
          currency={licCurrency}
          isLineItemDetail={true}
          lineItem={lotLi}
          lic={lineItemLic}
          lotComponent={totalLc}
          isLoadingStatus={isFetchBlicsLoading}
          header={header}
        />
      ) : (
        expandLcForParticipant(isLcOpened, ep.id) && (
          <ParticipantBidDetail
            {...props}
            key={`${index}-${ep.id}-${Date.parse(ep.updated_at)}`}
            lineItemId={lotLi.id}
            ep={ep}
            isLoadingStatus={isFetchBlicsLoading}
            lineItem={lotLi}
            isLineItemDetail={true}
            header={header}
          />
        )
      );
    })
  );
}, isEqual);

export default ParticipatingParticipants;
