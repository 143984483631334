import React from "react";

function NumbersAndOperatorsForFormula(props) {
  const { classes, values, handleFormulaChange, name, dragEnd, dragStart, draggable } = props;
  let result = [];
  values.map((i) =>
    result.push(
      <span
        key={i}
        className={classes}
        onClick={handleFormulaChange}
        data-name={name}
        draggable={draggable}
        onDragEnd={dragEnd}
        onDragStart={dragStart}
      >
        {i}
      </span>
    )
  );
  return result;
}

export default NumbersAndOperatorsForFormula;
