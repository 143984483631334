import React from "react";
import { t } from "@/i18n";
import type { CustomColumnCommonProps } from "./pick-one-from-list";
import { useTextAttribute } from "@adeattwood/react-form";
import Feedback from "@/cl/input/feedback";

interface NumericFieldProps extends CustomColumnCommonProps {}

const NumericField: React.FC<NumericFieldProps> = ({ field, readOnly }) => {
  const { props, error } = useTextAttribute(field.id.toString());

  return (
    <>
      <input
        {...props}
        type="number"
        className="form-control height-30"
        placeholder={t("value")}
        disabled={readOnly || !field.active}
        required={field.required}
        aria-labelledby={`custom-field-${field.id}-name`}
      />
      <Feedback className="required" id={props.id} error={error} />
    </>
  );
};

export default NumericField;
