import React, { Component } from 'react';

class InfoPopover extends Component {
  render() {
    const { title, popoverContent } = this.props;
    return (
      <div className='popover-top'>
        <i
          className='ion-ios-information-outline position-left'
        />
        <div className='popover-content-box'>
          <div className='popover-content-inner'>
            <h4>{title}</h4>
            <p dangerouslySetInnerHTML={ { __html: popoverContent } } />
          </div>
        </div>
      </div>
    );
  }
}

export default InfoPopover;
