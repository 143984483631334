import React from "react";
import PropTypes from "prop-types";

const FeedbackPropTypes = {
  /**
   * This will be used to add the ID to the wrapper element. It will be
   * suffixed with `--feedback` and can be used as the `aria-describedby`
   * attribute on the input.
   */
  id: PropTypes.string.isRequired,
  /**
   * The help message that will be displayed to the user.
   */
  help: PropTypes.string,
  /**
   * The error message that will be displayed to the user.
   */
  error: PropTypes.string,

  className: PropTypes.string,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof FeedbackPropTypes>>}
 */
const Feedback = ({ id, error, help, className }) => {
  return (
    <div id={id + "--feedback"} className={className} aria-live="polite">
      {Boolean(error) && <div className="d-block invalid-feedback">{error}</div>}
      {Boolean(help) && <div className="form-text">{help}</div>}
    </div>
  );
};

Feedback.propTypes = FeedbackPropTypes;

export default Feedback;
