import _ from 'lodash';
import $ from 'jquery';
import isEmpty from "lodash/isEmpty";

export const openParticipantsGroupPopup = async (participantsLimit, self) => {
  let allowedParticipantsLength = participantsLimit;
  self.setState({ showModel: true });
  await self.state.selectedGroups.forEach((group, index) => {
    allowedParticipantsLength -= group.participant_emails.length;
  });
  if (allowedParticipantsLength <= 0) {
    disableUncheckedGroups(self);
  }
};

/* function to commonly disable unchecked groups  */
const disableUncheckedGroups = (self) => {
  self.setState({
    showLimitMessage: true,
  });
  const uncheckedGroups = $('.group-list-section').find('input[type="checkbox"]').not(':checked');
  _.forEach(uncheckedGroups, (value, key) => {
    $(value).prop('disabled', true);
  });
}

export const scrollToMessage = (marketplace, messageId) => {
  if ($(`#message_${messageId}`).length) {
    const scrollToElement = '';
    if (marketplace) {
      scrollToElement = $('.each_message').parent();
    } else {
      scrollToElement = $('.message-all');
    }
    animateScrollTop(scrollToElement);
  }
};

const animateScrollTop = (element) => {
  element.animate({
    scrollTop:
  (element.scrollTop() + $(`#message_${messageId}`).offset().top) - (element.offset().top),
  }, 1300);
}

export const backgroundOnModalOpen = (flag) => {
  if (flag) {
    $('body').addClass('open-modal');
  } else {
    $('body').removeClass('open-modal');
  }
};

export const alertOptions = {
  offset: '40px',
  position: 'top center',
  theme: 'light',
  timeout: 5000,
  transition: 'scale',
  containerStyle: {
    zIndex: 9999999
  }
};

export const makeElementSticky = (parentOfEelement, elementClass) => {
  $(window).scroll((e) => {
    const topOfForm = $(`.${parentOfEelement}`).length > 0 ? $(`.${parentOfEelement}`).offset().top : 0;
    if ($(e.target).scrollTop() > (topOfForm - $(`.${elementClass}`).height())) {
      $(`.${elementClass}`).css({ position: 'fixed' });
      $(`.${elementClass}`).css('zIndex', 999);
      $(`.${elementClass}`).css('top', ($(`.${elementClass}`).height() + 10));
      $(`.${elementClass}`).addClass('on_scroll');
    } else {
      $(`.${elementClass}`).css({ position: 'relative' });
      $(`.${elementClass}`).css('zIndex', 1);
      $(`.${elementClass}`).offset({ top: topOfForm });
      $(`.${elementClass}`).removeClass('on_scroll');
    }
  });
};

export const removeGroupParticipants = (selfThis, idx, item) => {
  let { selectedGroups } = selfThis.state;
  item.participant_emails.splice(idx, 1);
  if (item.participant_emails.length === 0) {
    selectedGroups = selectedGroups.filter((grp) => grp.id !== item.id);
  }
  selfThis.setState({ selectedGroups });
};

export const notifyToastMsgQQ = (data, alert) => {
  if (!isEmpty(data.error)) {
    alert.error(data.error.message.toString());
  }

  if (!isEmpty(data.success)) {
    alert.success(data.success.message.toString())
  }
};
