import React from "react";
import Select2 from "react-select2-wrapper";
import Tooltip from "../../../common/Tooltip";
import { currenciesForSelect, unitOfMeasures } from "../../common";
import UnitSetButtons from "./UnitSetButtons";
import { useAppSelector } from "@/hooks/redux";
import { lotAutomationEnforcedAndNotSandpit } from "../lotCommon";

export default function PriceComponentModalDetail(props) {
  const {
    translations,
    currenciesHash,
    event,
    anyCompletedBids,
    decimalPlace,
    uomTypeChosen,
    provider,
    defaultExchangeRateId,
    uomName,
    unitSetId,
    classFromEventState,
    currentUser,
    allUnitSetUnitOfMeasures,
    allUnitSets,
    defaultUomId,
    rankingOptions,
    setDecimalPlace,
    setIsUomSet,
    setUnitSetId,
    setDefaultUomId,
    setUomTypeChosen,
    setUomName,
    setDefaultExchangeRateId,
  } = props;

  const lAEnforcedAndNotSandpit = useAppSelector(lotAutomationEnforcedAndNotSandpit);
  const isInputDisabled = anyCompletedBids || lAEnforcedAndNotSandpit;

  const handleUomChange = (event) => {
    const selectedOption = event.target.value;
    if (["0", "1"].includes(selectedOption)) {
      setUomTypeChosen(selectedOption);
      setIsUomSet(false);
      setUnitSetId(0);
      setDefaultUomId(undefined);
      setUomName("");
    } else if (selectedOption === "2") {
      setUomTypeChosen(selectedOption);
      setIsUomSet(true);
      setUomName("");
    }
  };

  const handleUnitSetChange = (event) => {
    setUnitSetId(event.target.value);
    setDefaultUomId(undefined);
  };

  // When component type is price then display these UOM radio buttons (Free Text, Enter Default or Unit Set)
  function uomRadioButtons() {
    const { lot_component_uom } = translations.infos;
    const { free_text, enter_default, use_uom_set } = translations;
    return (
      <div className="col input form-group wizard-step uom_options">
        <div className="surround">
          <div className="label-title">
            <Tooltip title={lot_component_uom.title} body={lot_component_uom.body} />
            <label htmlFor="uom">{translations.uom}</label>
            <div className="clear" />
          </div>
          {[free_text, enter_default, use_uom_set].map((option, index) => (
            <label
              className="css-input css-radio css-radio-sm css-radio-primary display-as-line push-10-r"
              htmlFor={`lot_component_is_uom_set_${index}`}
              key={option}
            >
              <input
                className="lot_component_uom"
                type="radio"
                disabled={isInputDisabled}
                value={`${index}`}
                checked={uomTypeChosen === `${index}`}
                onChange={handleUomChange}
                id={`lot_component_is_uom_set_${index}`}
              />
              <span />
              {option}
            </label>
          ))}
          <div className="clear" />
        </div>
      </div>
    );
  }
  // When component type is price and Enter Default UOM is chosen
  function defaultUOMTextField() {
    const { lot_component_default_uom } = translations.infos;
    return (
      <div className="input uom-text form-group">
        <div id="uom_txt_field">
          <Tooltip title={lot_component_default_uom.title} body={lot_component_default_uom.body} />
          <label htmlFor="lot_component_unit_of_measure_uomname">{translations.uom}</label>
          <div className="clear" />
          <input
            className="form-control"
            type="text"
            disabled={isInputDisabled}
            id="lot_component_unit_of_measure_uomname"
            value={uomName}
            onChange={(e) => setUomName(e.target.value)}
            autoFocus={true}
          />
        </div>
      </div>
    );
  }

  // When component type is price and Unit Set is chosen for UOM
  function unitSetAndUnitOfMeasureOptions() {
    const { lot_component_unit_set } = translations.infos;
    const unitSet = allUnitSets.filter((us) => us.id === unitSetId)[0];
    const chosenUnitSetUoms = unitOfMeasures(allUnitSetUnitOfMeasures, parseInt(unitSetId));
    const uomOptions = chosenUnitSetUoms.map((uom) => {
      return { text: uom.name, id: uom.id };
    });
    return (
      <div id="unit_set_fields" className="input wizard-step lot-dropdowns">
        <div className="unit-set form-group row m-b10">
          <div className="uom-set-wrapper">
            <div className="col-md-6">
              <Tooltip title={lot_component_unit_set.title} body={lot_component_unit_set.body} />
              <label htmlFor="lot_component_unit_set">
                {translations.uom_set}
                <span className="required">*</span>
              </label>
              <Select2
                id="lot_component_unit_set"
                className="form-control permission-action-select wid-100"
                data={unitSetsOptions()}
                onSelect={(e) => handleUnitSetChange(e)}
                value={unitSetId}
                disabled={isInputDisabled}
                options={{
                  minimumResultsForSearch: -1,
                  placeholder: translations.select,
                }}
              />
            </div>
            {unitSetId !== null && unitSetId > 0 && unitSetUnitOfMeasureOptions(uomOptions)}
          </div>
        </div>
        <UnitSetButtons
          classFromEventState={classFromEventState}
          translations={translations}
          currentUser={currentUser}
          unitSet={unitSet}
          chosenUnitSetUoms={chosenUnitSetUoms}
          handleUnitSetChange={handleUnitSetChange}
        />
      </div>
    );
  }
  function unitSetsOptions() {
    if (allUnitSets !== undefined && allUnitSets.length > 0) {
      return allUnitSets.map((unitSet) => {
        return { text: unitSet.name, id: unitSet.id };
      });
    } else {
      return [];
    }
  }

  // When component type is price and a Unit Set is chosen which has UnitSetOptions then show
  // the text fields to enter values for those options
  function unitSetUnitOfMeasureOptions(chosenUnitSetUoms) {
    const { lot_component_default_uom } = translations.infos;
    return (
      <div className="col-md-6">
        <div id="unit_set_default_uom" className="unit-set default-uom">
          <Tooltip title={lot_component_default_uom.title} body={lot_component_default_uom.body} />
          <label htmlFor="lot_component_default_uom">
            {translations.default_uom}
            <span className="required">*</span>
          </label>
          <Select2
            className="form-control permission-action-select wid-100"
            data={chosenUnitSetUoms}
            onSelect={(e) => setDefaultUomId(e.target.value)}
            value={defaultUomId}
            defaultValue={chosenUnitSetUoms[0]["id"]}
            disabled={isInputDisabled}
            options={{
              minimumResultsForSearch: -1,
            }}
          />
        </div>
      </div>
    );
  }

  function priceTypeComponentFields() {
    const { lot_component_decimal_place, lot_currency } = translations.infos;
    return (
      <>
        {/* Decimal Places */}
        <div className="alert alert-info font-s12">{translations.advlots_price_column_alert}</div>
        <div className="col input form-group wizard-step" id="decimal_place_field">
          <div className="surround">
            <div className="label-title">
              <Tooltip title={lot_component_decimal_place.title} body={lot_component_decimal_place.body} />
              <label htmlFor="lot_component_decimal_place">{translations.decimal_places}</label>
              <div className="clear" />
            </div>
            <div className="input">
              <span className="input_field">
                <Select2
                  id="lot_component_decimal_place"
                  className="form-control permission-action-select"
                  data={[0, 1, 2, 3, 4, 5, 6]}
                  onSelect={(e) => setDecimalPlace(e.target.value)}
                  value={decimalPlace}
                  disabled={isInputDisabled}
                  options={{ minimumResultsForSearch: -1 }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        {/* UOM Type */}
        {uomRadioButtons()}
        {/* Unit of Measure UOM Name */}
        {uomTypeChosen === "1" && defaultUOMTextField()}
        {uomTypeChosen === "2" && unitSetAndUnitOfMeasureOptions()}
        {/* Default Currency Select */}
        {event.multi_currency_event && (
          <>
            <Tooltip title={lot_currency.title} body={lot_currency.body} />
            <label htmlFor="default_currency">&nbsp;{translations.default_currency}</label>
            <Select2
              id="default_currency"
              className="form-control permission-action-select"
              data={currenciesForSelect(currenciesHash)}
              onSelect={(e) => setDefaultExchangeRateId(e.target.value)}
              value={defaultExchangeRateId}
              options={{ minimumResultsForSearch: -1 }}
              disabled={lAEnforcedAndNotSandpit}
            />
          </>
        )}
        {/* Ranking checkboxes */}
        {provider === "participant" && rankingOptions(translations)}
      </>
    );
  }
  return priceTypeComponentFields();
}
