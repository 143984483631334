/**
 * This function will add ellipses to a string after a limited number of
 * characters passed. The limit will be of 50 characters by default.
 */
function truncate(string: string, limit: number = 50): string {
  if (string.length > limit) {
    return `${string.slice(0, limit)}...`;
  } else {
    return string;
  }
}

export default truncate;
