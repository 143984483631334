import React, { useState } from "react";
import { connect } from "react-redux";
import BidTemplateDropdown from "../bids/BidTemplateDropdown";
import TurnDownEvent from "./participantScreenComponents/TurnDownEvent";
import { pending, edit, completed, isRfq, postRfqPreAuction, qualAucBidRunningComplete } from "../eventCommon";
import Modal from "@/components/common/Modal";
import LotBidComponent from "../bids/LotBidComponent";
import { validBidsBeforeAuction } from "../bidCommon";
import DownloadEventReport from "./participantScreenComponents/DownloadEventReport";
import FeedbackSurveyModal from "./host_rfq_screen/modal/FeedbackSurveyModal";
import ThanksYouSurveyModal from "./host_rfq_screen/modal/ThanksYouSurveyModal";
import EventRoundTabs from "./host_rfq_screen/msrfq/EventRoundTabs";

const ParticipantPendingEventLots = (props) => {
  const {
    translations,
    role,
    event,
    event_participant: eventParticipant,
    lots,
    event_currency: currency,
    lot_components: lotComponents,
    line_items: lineItems,
    line_item_components: lineItemComponents,
    currencies_hash: currenciesHash,
    unit_of_measures: unitOfMeasures,
    current_user: currentUser,
    picklist_options: picklistOptions,
    bidLineItemComponents,
    bids,
    openModal,
    unit_sets: _unitSets = [],
    unit_set_unit_of_measures: _unitSetUoms,
    errors,
    locale,
    lot_total_blics: lotTotalBlics,
    auction_tab: auctionTab,
    weighted_rfq_or_auction: weightedRfqOrAuction,
    before_auction,
  } = props;

  const [initalState, setInitalState] = useState({
    modalIsOpen: false,
    surveyScore: null,
    surveyText: "",
    hasShownCongratulationModal: false,
    surveyThanksModal: false,
  });
  const setState = (_state) => {
    setInitalState({ ...initalState, ..._state });
  };
  const { surveyScore, surveyText, modalIsOpen, surveyThanksModal } = initalState;
  let licCurrency = currency;
  const unitSetUoms = Array.isArray(_unitSetUoms) ? _unitSetUoms : [];
  const unitSets = Array.isArray(_unitSets) ? _unitSets : [];
  const submittedBid = bids.filter(({ current_state }) => current_state === "submitted");
  const rejectedBid = bids.filter(({ rejected_lot }) => !!rejected_lot);

  if (
    lots.length === submittedBid.length &&
    bids.find(({ showLabel }) => showLabel) &&
    !initalState.hasShownCongratulationModal &&
    !eventParticipant.survey_offered
  ) {
    openModal("#participantsCongratulationsModal");
  }

  const askSurvey = () => {
    setState({
      modalIsOpen: true,
      hasShownCongratulationModal: true,
    });
  };

  if (
    lots.length === rejectedBid.length &&
    !initalState.hasShownCongratulationModal &&
    !eventParticipant.survey_offered
  ) {
    askSurvey();
  }

  const activeBids = ["closed", "cancelled", "completed", "running", "post_rfq_pre_auction", "pause"].includes(
    event.current_state
  )
    ? validBidsBeforeAuction(event, [eventParticipant], bids)
    : bids;

  let setLotStateCb = {};

  const rfqCompleted = event.current_state === "completed" && event.event_category === "RFQ";

  const getMessage = () => {
    let message = "";
    switch (true) {
      case qualAucBidRunningComplete(event):
        message = translations.show_prev_event_bids;
        break;
      case rfqCompleted:
        message = translations.rfq_deadline_passed;
        break;
    }
    return (
      message && (
        <div className="container">
          <div className="alert alert-info">{message}</div>
        </div>
      )
    );
  };

  const showDownloadEventReportButton = () => {
    const activeBids = validBidsBeforeAuction(event, [eventParticipant], bids);
    return (
      event.current_state == "completed" &&
      (!event.event_category_auction || event.event_category_rfq) &&
      activeBids.length > 0
    );
  };

  const isQualBidTab = () => {
    return before_auction && event.event_category === "Qualification Bids and Auction";
  };
  return (
    <>
      <div className="container m-b10">
        <div className="row">
          <div className="col-md-12">
            <EventRoundTabs {...props} />
            {!["running", "pause", "completed"].includes(event.current_state) &&
              !rfqCompleted &&
              !postRfqPreAuction(event) && (
                <BidTemplateDropdown
                  translations={translations}
                  role={role}
                  event={event}
                  eventParticipant={eventParticipant}
                  setLotStateCb={setLotStateCb}
                  lots={lots}
                />
              )}
          </div>
        </div>
      </div>
      {getMessage()}
      {lots.map((lot) => {
        return (
          <LotBidComponent
            key={lot.id}
            event={event}
            lot={lot}
            licCurrency={licCurrency}
            bidLineItemComponents={bidLineItemComponents}
            lineItems={lineItems}
            currenciesHash={currenciesHash}
            unitOfMeasures={unitOfMeasures}
            currentUser={currentUser}
            picklistOptions={picklistOptions}
            lotComponents={lotComponents}
            lineItemComponents={lineItemComponents}
            bids={activeBids}
            translations={translations}
            openModal={openModal}
            lots={lots}
            unitSetUoms={unitSetUoms}
            setLotStateCb={setLotStateCb}
            errors={errors}
            unitSets={unitSets}
            locale={locale}
            lotTotalBlics={lotTotalBlics}
            participant={eventParticipant}
            auctionTab={auctionTab}
            beforeAuction={true}
            role={role}
            weightedRfqOrAuction={weightedRfqOrAuction}
            isPlaceBidActiveFlag={true}
          />
        );
      })}
      {showDownloadEventReportButton() && <DownloadEventReport />}
      <Modal
        translations={translations}
        messageBody={translations.participant_bid_submit_success.replace(/<p>/g, "")}
        modalId={"participantsCongratulationsModal"}
        modalClass={"modal-lg"}
        showCancelButton={false}
        title={
          <h3 className="modal-title pop-title text-center congratulation-modal">
            <p className="text-center text-capitalize">
              <i className="fa fa-check-circle font-s20 m-r5 text-success"></i> {translations.congratulations}
            </p>
          </h3>
        }
        okButtonClick={askSurvey}
      />
      {!isQualBidTab() && (
        <FeedbackSurveyModal
          modalIsOpen={modalIsOpen}
          setState={setState}
          surveyScore={surveyScore}
          surveyText={surveyText}
          {...props}
        />
      )}
      <ThanksYouSurveyModal
        surveyThanksModal={surveyThanksModal}
        surveyScore={surveyScore}
        setState={setState}
        {...props}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { bid_line_item_components, openModal, bids, errors, lot_total_blics } = state.lotReducers;

  return {
    bidLineItemComponents: bid_line_item_components,
    openModal,
    bids,
    errors,
    lotTotalBlics: lot_total_blics,
  };
};

export default connect(mapStateToProps, null, null)(ParticipantPendingEventLots);
