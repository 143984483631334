import React, { useCallback, useEffect, useRef } from "react";
import { t } from "@/i18n";
import moment from "moment";
import type { CustomColumnCommonProps } from "./pick-one-from-list";
import type { Field } from ".";
import { useAttribute } from "@adeattwood/react-form";
import Feedback from "@/cl/input/feedback";

interface DateFieldProps extends CustomColumnCommonProps {}
type useInitializeDateTimePickerProps = {
  field: Field;
  inputRef: React.RefObject<HTMLInputElement>;
  handleDateChange: (date: string) => void;
};

/**
 * This will initialize the date-time-picker for the provided element.
 */
const initializeDateTimePicker = (inputElement: HTMLInputElement, handleDateChange: (date: string) => void) => {
  $(inputElement).datetimepicker({
    format: "Y-m-d",
    timepicker: false,
    scrollInput: false,
    onSelectDate: (ct: Date) => {
      // We need to format this to date format because ct will have time with it
      // and we don't want the time here.
      const date = moment(ct).format("YYYY-MM-DD");
      handleDateChange(date);
    },
  });
};

const useInitializeDateTimePicker = ({ field, inputRef, handleDateChange }: useInitializeDateTimePickerProps) => {
  useEffect(() => {
    const inputElement = inputRef.current;
    if (!inputElement) return;

    initializeDateTimePicker(inputElement, handleDateChange);

    return () => {
      $(inputElement).datetimepicker("destroy");
    };
  }, [inputRef, field.id, handleDateChange]);
};

export function updateError(element: HTMLElement, requiredText: string) {
  element.classList.add("error-text");
  element.classList.remove("required");
  element.innerText = requiredText;
}

export function updateValid(element: HTMLElement) {
  element.classList.add("required");
  element.classList.remove("error-text");
  element.innerText = "*";
}

/**
 * This will check if the field's value has been updated or not
 * It will return true if the value has not been updated
 */
const hasOldValue = (oldProps: DateFieldProps, newProps: DateFieldProps): boolean => {
  return oldProps.field.custom_column_values[0]?.name === newProps.field.custom_column_values[0]?.name;
};

const DateField: React.FC<DateFieldProps> = ({ field, readOnly }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { id, value, set, error } = useAttribute(field.id.toString(), field.custom_column_values?.[0]?.name ?? "");
  useInitializeDateTimePicker({
    field,
    inputRef,
    handleDateChange: useCallback(
      (date) => {
        set(date);
      },
      [set]
    ),
  });

  return (
    <>
      <input
        type="text"
        className="form-control datepicker height-30"
        id={id}
        placeholder={t("select")}
        onChange={(e) => set(e.target.value)}
        autoComplete="off"
        ref={inputRef}
        required={field.required}
        disabled={readOnly || !field.active}
        value={value}
        aria-labelledby={`custom-field-${field.id}-name`}
      />
      <Feedback className="required" id={id} error={error} />
    </>
  );
};

export default React.memo(DateField, hasOldValue);
