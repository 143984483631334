import i18n from "i18next";
import { categoryLanguages, languages, simLanguages, srmLanguages } from "./languageMaps";

/**
 * Gets the language from the cookie string. This can be found in the browser
 * with `document.cookie`.
 */
export const getLangFromCookie = (cookie) => {
  const language = cookie.match(/locale=([a-z-]+);?/);
  if (language && language[1]) return language[1];
};

/**
 * Custom plugin to detect the language from the cookie. This cookie will be
 * set by the rails app when changing the language. Its to ensure the back and
 * frontend are displaying the same language.
 */
export const languageDetector = {
  type: "languageDetector",
  init() {},
  detect() {
    return document.documentElement.lang || "en";
  },
};

/**
 * Object to get the translations map based on the module.
 */
const moduleTranslationsMap = {
  sim_dojo: simLanguages,
  category_dojo: categoryLanguages,
  srm: srmLanguages,
};

/**
 * @param {string} namespace
 * @returns {string} resolvedNamespace
 */
const getResolvedNamespace = (namespace) => {
  return namespace === "translation" || namespace in moduleTranslationsMap ? "" : `${namespace}.`;
};

/**
 * @param {() => void} languageModule
 * @param {string} language
 * @param {() => void} callback
 */
const loadLanguageModule = (languageModule, language, callback) => {
  languageModule().then((mod) => {
    callback(null, mod.default[language]);
  });
};

/**
 * Custom i18next backend to load the translations from the YAML files that are
 * usd by the rails backend.
 */
export const backend = {
  type: "backend",
  init() {},
  read(language, namespace, callback) {
    const languageMap = namespace in moduleTranslationsMap ? moduleTranslationsMap[namespace] : languages;
    const resolvedNamespace = getResolvedNamespace(namespace);
    const key = `${resolvedNamespace}${language}`;

    if (key in languageMap) {
      loadLanguageModule(languageMap[key], language, callback);
    } else if (namespace === "tooltips" && languageMap[`tooltips.en`]) {
      // We will have to remove this "else if" block of code from here
      // once we get all the translations for our tooltips in our project.
      loadLanguageModule(languageMap[`tooltips.en`], "en", callback);
    } else {
      callback(`Language ${language} not found`);
    }
  },
};

export function initI18n() {
  return i18n
    .use(languageDetector)
    .use(backend)
    .init({
      defaultNS: "translation",
      ns: ["translation", "sim_dojo", "category_dojo", "srm", "tooltips"],
      // Configure the same interpolation as rails uses so everything works as
      // expected when using variables / placeholders in our translations.
      interpolation: { prefix: "%{", suffix: "}" },
      react: { wait: true },
    });
}

export const { t } = i18n;

export default i18n;
