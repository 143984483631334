function formatInput(input) {
  const locale = input.closest(".price-input").getAttribute("data-price-validation-locale");
  let rawValue = input.value.replace(/[^0-9.,]/g, ""); // Allow only numbers, commas, and periods

  // Normalize the value based on the locale
  rawValue = normalizeSeparators(rawValue, locale);

  // Update the UI input value
  input.value = rawValue;

  // Convert commas to periods for server consistency
  if (localeUsesCommaForDecimal(locale)) {
    rawValue = rawValue.replace(/,/g, ".");
  }

  // Update hidden server input value: no thousands separators, period for decimal
  const serverInput = input.closest(".price-input").querySelector(".price-server");
  serverInput.value = formatForServer(rawValue);
}

function normalizeSeparators(value, locale) {
  if (localeUsesCommaForDecimal(locale)) {
    return handleCommaAsDecimal(value);
  } else {
    return handlePeriodAsDecimal(value);
  }
}

function handleCommaAsDecimal(value) {
  value = preventMultipleSeparators(value, ",");
  return value.replace(/\./g, ""); // Remove periods which are thousand separators
}

function handlePeriodAsDecimal(value) {
  value = preventMultipleSeparators(value, ".");
  return value.replace(/,/g, ""); // Remove commas which are thousand separators
}

function preventMultipleSeparators(value, separator) {
  const parts = value.split(separator);
  if (parts.length > 2) {
    // Prevent multiple decimal separators
    value = parts.shift() + separator + parts.join("");
  }
  return value;
}

function formatForServer(rawValue) {
  // Normalize the number by removing any remaining thousands separators and ensure decimal is a period
  return rawValue.replace(/,/g, "").replace(/(\.\d*?)0+$/, "$1"); // Also strip off any extraneous trailing zeros
}

function formatAndValidate(input) {
  const container = input.closest(".price-input");
  const locale = container.getAttribute("data-price-validation-locale");
  const serverInput = container.querySelector(".price-server");
  let rawValue = serverInput.value;

  let value = parseFloat(rawValue); // Normalize input for parsing

  if (!isNaN(value)) {
    // Correctly format the value using the locale rules
    input.value = value.toLocaleString(locale, { maximumFractionDigits: 2, useGrouping: true });
  } else {
    handleInvalidValue(container);
    return; // Exit the function early since the value is not valid
  }

  validateValueRange(container, rawValue, value);
}

function handleInvalidValue(container) {
  container.classList.add("invalid");
  container.classList.remove("normal", "valid");
}

function validateValueRange(container, rawValue, value) {
  const min = parseFloat(container.getAttribute("data-price-validation-min-value"));
  const max = parseFloat(container.getAttribute("data-price-validation-max-value"));

  if (rawValue === "") {
    setContainerState(container, "normal");
  } else if (value < min || value > max) {
    setContainerState(container, "invalid");
  } else {
    setContainerState(container, "valid");
  }
}

function setContainerState(container, state) {
  const states = ["normal", "valid", "invalid"];
  states.forEach((s) => container.classList.remove(s));
  container.classList.add(state);
}

function localeUsesCommaForDecimal(locale) {
  const commaDecimalLocales = [
    "id",
    "de",
    "es",
    "fr",
    "it",
    "ro",
    "nl",
    "no",
    "pl",
    "pt",
    "ru",
    "sl",
    "sv",
    "el",
    "vi",
    "tr",
    "ar",
  ];
  return commaDecimalLocales.includes(locale);
}

// Exporting functions for testing
export {
  formatInput,
  normalizeSeparators,
  handleCommaAsDecimal,
  handlePeriodAsDecimal,
  preventMultipleSeparators,
  formatForServer,
  formatAndValidate,
  localeUsesCommaForDecimal,
};
