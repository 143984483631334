import React from "react";

import HostPendingEventLots from "./host_rfq_screen/HostPendingEventLots";

const RunningEventLots = (props) => {
  const { role } = props;
  let renderRunningEventLots = "";
  if (["Host", "Admin"].includes(role)) {
    renderRunningEventLots = <HostPendingEventLots {...props} />;
  }
  return <div>{renderRunningEventLots}</div>;
};
export default RunningEventLots;
