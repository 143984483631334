import actionTypes from '../../actions/qq/actionsTypes';

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.fetch_user_details:
      return {
        ...state,
        details: action.payload,
      };
    default:
      return state;
  }
};

export const permissionReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.fetch_admin_permission:
      return {
        ...state,
        permission: action.payload,
      };
    default:
      return state;
  }
};

export const companyDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.fetch_company_details:
      return {
        ...state,
        whitelabel: action.payload,
      };
    default:
      return state;
  }
};

export const featureEnableReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.feature_enabled:
      return action.payload;
    default:
      return state;
  }
};

export const tooltipReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.tooltip_locale:
      return {
        ...state,
        tooltip_text: action.payload,
      };
    default:
      return state;
  }
};

export const notifyParticipant = (state = {}, action) => {
  switch (action.type) {
    case 'notify':
      return {
        ...state,
        postEditModal: action.payload,
      };
    default:
      return state;
  }
};

export const supplierStatus = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.supplier_status:
      return {
        ...state,
        supplierStatus: action.payload,
      };
    default:
      return state;
  }
};

export const updateSummaryHeader = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.update_summary_header:
      return {
        ...state,
        updated: action.payload,
      };
    default:
      return state;
  }
};

export const sandpitParticipantsReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.sandpit_participants:
      return {
        ...state,
        sandpitParticipants: action.payload,
      };
    default:
      return state;
  }
};
export const setListingParametersReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.set_listing_parameter:
      return {
        flag: action.payload,
      };
    default:
      return { flag: false };
  }
};

export const updateEventStateReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.change_event_state:
      return {
        updatedEvent: action.payload,
      };
    default:
      return { updatedEvent: {} };
  }
};

export const documentTemplateReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.fetch_document_templates:
      return { documentResponse: true, documentPayload: action.payload };
    default:
      return state;
  }
};
