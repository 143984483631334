import React, { useEffect } from "react";
import Select2 from "react-select2-wrapper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TrixEditor from "../../common/TrixEditor";
import Tooltip from "../../common/Tooltip";
import { statusLabel, priorityLabel } from "../StatusLabel";
import { useSelector } from "react-redux";

const setLabelColors = (labels, options) =>
  labels.map((type) => {
    const nodes = document.getElementById(type.id).getElementsByClassName("select2-selection__rendered");

    if (nodes.length > 0) {
      nodes[0].style.backgroundColor = type.setter(type.value, options);
    }
  });

const generalTab = (props) => {
  const {
    valuesForStatus,
    translations,
    activityTypes,
    priorityOptions,
    alphaNumericString,
    activityTypeId,
    setActivityType,
    subject,
    setSubject,
    duration,
    setDuration,
    status,
    setStatus,
    purpose,
    setPurpose,
    dueDate,
    setDueDate,
    alertDate,
    setAlertDate,
    completionDate,
    setCompletionDate,
    priorityId,
    setPriorityId,
  } = props;

  const { custom_column_options } = useSelector((state) => state.ActivityReducer);
  const { tooltips } = translations;

  useEffect(() => {
    setLabelColors(
      [
        {
          id: "priorityStatus",
          setter: priorityLabel,
          value: priorityId,
        },
        {
          id: "selectStatus",
          setter: statusLabel,
          value: status,
        },
      ],
      custom_column_options
    );
  }, [status, priorityId]);

  return (
    <div
      id={`general-${alphaNumericString}`}
      className="tab-pane fade in active general"
      role="tabpanel"
      aria-labelledby="general-tab"
    >
      <div className="bg-white border-t0 col-md-12 form-item-block m-b0">
        <div className="col-md-6  p-l0">
          <div className="form-group">
            <label htmlFor={translations.type}>
              {translations.type}
              &nbsp;<span className="required">*</span>
            </label>
            <Select2
              className="form-control clearfix"
              data={activityTypes}
              name="activityType"
              onSelect={(e) => setActivityType(e.target.value)}
              value={activityTypeId}
              options={{ minimumResultsForSearch: -1 }}
            />
          </div>
          <div className="form-group">
            <label htmlFor={translations.subject}>
              {translations.subject}
              &nbsp;<span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control input-sm"
              autoComplete="off"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
          </div>
          {/* <div className='form-group'>
            <label htmlFor={translations.duration}>
              {translations.duration}
            </label>
            <input
              type='text'
              className='form-control input-sm'
              autoComplete='off'
              onChange={(e) => setDuration(e.target.value)}
              value={duration}
            />
          </div> */}
          <div className="select2-labelstyle select2-labelstyle-halfwidth" id="selectStatus">
            <div className="col input">
              <Tooltip title={tooltips.status.title} body={tooltips.status.body} additionalClasses={"pull-left m-r5"} />
              <label htmlFor={translations.status}>
                {translations.status}
                &nbsp;<span className="required">*</span>
              </label>
              <Select2
                className="form-control clearfix"
                data={statusValues(valuesForStatus, translations)}
                name="status"
                onSelect={(e) => setStatus(e.target.value)}
                value={status}
                options={{ minimumResultsForSearch: -1 }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 p-r0">
          <div className="form-group">
            <label htmlFor={translations.purpose}>{translations.purpose}</label>
            <TrixEditor value={purpose} onChange={setPurpose} />
          </div>
          <div className="col-md-12 p-0">
            <div className="col-md-7 form-group p-0">
              <Tooltip
                title={tooltips.due_date.title}
                body={tooltips.due_date.body}
                additionalClasses={"pull-left vertical-align-top"}
              />
              <label htmlFor={translations.due_date} className={status == "overdue" ? "red" : ""}>
                {status == "overdue" ? <i className="fa fa-exclamation-triangle red m-r5"></i> : ""}
                {translations.due_date} {status == "overdue" ? <i>({translations.overdue.toLowerCase()})</i> : ""}
              </label>
              <DatePicker
                name="dueDate"
                selected={dueDate}
                onChange={(value) => setDueDate(value)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="yyyy-MM-dd H:mm"
                timeCaption="time"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="dtpicker form-control"
                autoComplete="off"
              />
            </div>
            <div className="col-md-5 form-group p-0">
              <Tooltip
                title={tooltips.date_of_alert.title}
                body={tooltips.date_of_alert.body}
                additionalClasses={"pull-left vertical-align-top"}
              />
              <label htmlFor={translations.date_of_alert}>{translations.date_of_alert}</label>
              <DatePicker
                name="alertDate"
                selected={alertDate}
                onChange={(value) => setAlertDate(value)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="yyyy-MM-dd H:mm"
                timeCaption="time"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="dtpicker form-control"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md-12 p-0">
            <div className="col-md-5 p-0 select2-labelstyle" id="priorityStatus">
              <label htmlFor={translations.priority}>{translations.priority}</label>
              <Select2
                className="form-control clearfix"
                data={priorityOptions}
                name="priority"
                onSelect={(e) => setPriorityId(e.target.value)}
                value={priorityId}
                options={{ minimumResultsForSearch: -1 }}
              />
            </div>
            {status === "completed" && (
              <div className="col-md-5 p-0 pull-right">
                <label htmlFor={translations.completion_date}>{translations.completion_date}</label>
                <DatePicker
                  name="completionDate"
                  selected={completionDate}
                  onChange={(value) => setCompletionDate(value)}
                  showTimeSelect
                  dateFormat="yyyy-MM-dd"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="dtpicker form-control"
                  autoComplete="off"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const statusValues = (valuesForStatus, translations) => {
  return valuesForStatus.map((statusOption) => {
    return { id: statusOption, text: translations[statusOption] };
  });
};

export default generalTab;
