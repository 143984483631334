import React, { useState, lazy, Suspense } from "react";
import classNames from "classnames";
import { currencyStringWithSymbol, show, getProcessedData, bidPropsAreEqual, cellClass } from "../../common";
import moment from "moment";
import { preferredQuantity, participantPreferredValue, roundValue } from "../../bidCommon";
const SummaryPanel = lazy(() => import("../participantScreenComponents/SummaryPanel"));
import useEvent from "../../hooks/useEvent";

const PriceComponentSummaryPanel = React.memo((props) => {
  const {
    licCurrency,
    lic,
    isDetailView,
    isPlaceBidActive,
    event,
    bid,
    translations,
    isBidSubmitted,
    currentUser,
    unitSetUoms,
    blic,
    isHost,
    bidLineItemComponents,
    unitSet,
    locale,
    isParticipant,
    uom,
    style,
    rankValue,
    lc,
    lot,
    role,
    auctionTab,
    isAuctionPending,
    isBidForCurrentMSRFQRoundSubmitted,
  } = props;
  const { isEventMsrfq } = useEvent();
  const bidSubmitted = isBidSubmitted || (isEventMsrfq && !isBidForCurrentMSRFQRoundSubmitted && bid);
  const isCalculation = lic.calculation;
  let hostField = null;

  const { initState, type, hasValue } = getProcessedData({
    lic,
    licCurrency,
    currentUser,
    blic,
    unitSetUoms,
    bidLineItemComponents,
    bids: [bid],
    bid,
    unitSet,
  });

  let bidFields = "-";
  let fieldValue = "-";
  let yourPricePerUom = "-";
  let answer = "-";

  const renderData = [];
  const _currWithSymbol = currencyStringWithSymbol({
    value: show(lic, "used_price"),
    symbol: licCurrency.symbol,
    precision: lic.decimal_place,
    locale,
    numberFormat: translations.number_format,
    currency: licCurrency,
  });

  const getCellClass = () => {
    return auctionTab ? cellClass(bid, rankValue) : "";
  };
  const rankCellClass = getCellClass();
  const [lotLisState, setLotLisState] = useState({
    style2: {},
    cellClass: rankCellClass,
  });
  const { style2 } = lotLisState;
  const setState = (state) => {
    setLotLisState({ ...lotLisState, ...state });
  };
  const setLotStyle2 = (style) => {
    if (style && style.width && style.width.replace && typeof style.width.replace === "function") {
      let maxWidth = parseInt(style.width.replace(/px/g, "")) - 25 + "px";
      return {
        maxWidth,
      };
    } else {
      return style2;
    }
  };
  React.useEffect(() => {
    if (!lic.is_price) {
      const style2 = setLotStyle2(style);
      setState({ style2 });
    }
  }, []);
  if (auctionTab) {
    React.useEffect(() => {
      setTimeout(() => {
        setState({ cellClass: "" });
      }, 500);
    }, [rankCellClass]);
  }

  if (isHost) {
    switch (type) {
      case "isDate":
        const date = moment(show(lic, "date_value")).format("DD MMM HH:MM");
        answer = fieldValue = date !== "Invalid date" ? date : "-";
        break;
      case "isText":
      case "isPicklist":
        answer = fieldValue = show(lic, "attribute_value");
        break;
      case "isDecimal":
        const num = show(lic, "used_price");
        answer = fieldValue = !isNaN(Number(num)) ? Number(num) : num;
        break;
      case "isPrice":
        fieldValue = _currWithSymbol;
        break;
    }
  } else if (bidSubmitted) {
    if (isParticipant) {
      fieldValue = initState[type];
      switch (type) {
        case "isDate":
          answer = fieldValue = moment(initState[type]).format("DD MMM HH:MM");
          break;
        case "isPrice":
          yourPricePerUom = currencyStringWithSymbol({
            value: fieldValue,
            symbol: licCurrency.symbol,
            precision: lic.decimal_place,
            locale,
            numberFormat: translations.number_format,
            currency: licCurrency,
            returnValue: true,
          });
          const _participantPreferredValue = participantPreferredValue(
            blic,
            lic,
            unitSetUoms,
            unitSet,
            bid,
            bidLineItemComponents,
            [bid]
          );
          const precision = lic && lic.decimal_place ? lic.decimal_place : 2;
          fieldValue = currencyStringWithSymbol({
            value: _participantPreferredValue,
            symbol: licCurrency.symbol,
            precision,
            locale,
            numberFormat: translations.number_format,
            currency: licCurrency,
            rankValue: lic.is_ranked && lic.is_visible_rank,
            cellClass: rankCellClass,
            dataObject: {
              event,
              blic,
              bid,
              lic,
              lc,
              lot,
              role,
              edit: false,
              auctionTab,
            },
          });
          break;
        case "isPicklist":
        case "isDecimal":
        case "isText":
          answer = initState[type];
          break;
      }
    }
  }

  switch (true) {
    case lic.is_formula_lic:
      bidFields = (
        <>
          <span className="dummy-bid-field">{bidSubmitted ? fieldValue : _currWithSymbol}</span>
        </>
      );
      hostField = (
        <div className="bid-cell-content">
          <span className="calc-price lot-cell">{_currWithSymbol}</span>
        </div>
      );
      renderData.push(show(licCurrency, "name", lic), "-", "-", "-");
      break;
    case !lic.is_price:
      bidFields = (
        <span className="dummy-bid-field">
          <div className="attribute-value dummy-bid-field" title={fieldValue} style={style2}>
            {fieldValue}
          </div>
        </span>
      );
      renderData.push("-", "-", "-", "-");
      hostField = (
        <div className="bid-cell-content">
          <span className="attribute-value">
            <div className="editor-information white-space-pre-wrap" title={fieldValue}>
              {fieldValue}
            </div>
          </span>
        </div>
      );
      break;
    default:
      renderData.push(
        licCurrency.name,
        uom.name,
        preferredQuantity(lic, unitSetUoms, unitSet, blic, bid, bidLineItemComponents, [bid]),
        isHost ? _currWithSymbol : "-"
      );

      if (isHost) {
        bidFields = <span className="dummy-bid-field">{bidSubmitted ? fieldValue : _currWithSymbol}</span>;
        hostField = <div className="bid-cell-content">{_currWithSymbol}</div>;
      } else {
        if (isCalculation) {
          bidFields = (
            <span className="dummy-bid-field">
              <span className="real-time">{bidSubmitted ? fieldValue : _currWithSymbol}</span>
            </span>
          );
        } else {
          bidFields = (
            <span className="dummy-bid-field">
              {bidSubmitted && !(isAuctionPending && auctionTab) ? fieldValue : `${Number(lic.quantity)} X ${uom.name}`}
            </span>
          );
        }
      }
      break;
  }

  return (
    <>
      {bidFields}
      <div
        className={classNames({
          "summary-panel": true,
          "-show": !isPlaceBidActive && isDetailView,
          "-hide": !(!isPlaceBidActive && isDetailView),
        })}
      >
        <Suspense fallback={<div>{translations.loading}</div>}>
          <SummaryPanel
            {...props}
            renderData={renderData}
            lic={lic}
            isBidSubmitted={bidSubmitted}
            yourPricePerUom={yourPricePerUom}
            isHost={isHost}
            blic={blic}
            answer={answer}
            event={event}
            blic={blic}
            bid={bid}
            lc={lc}
            lot={lot}
            role={role}
            auctionTab={auctionTab}
          />
        </Suspense>
      </div>
    </>
  );
}, bidPropsAreEqual);

export default PriceComponentSummaryPanel;
