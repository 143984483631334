import React, { useState, useMemo } from "react";
import LotMatrixLicDetailsTable from "./LotMatrixLicDetailsTable";
import LotMatrixDetailsTable from "./LotMatrixDetailsTable";
import { eventStatusClass, findUser, lotBids, lotLineItems, lotLotComponents } from "../../../../common";
import { useSelector } from "react-redux";
import { bidBidLineItemComponents } from "@/components/advanced_lots/bidCommon";
import { selectEventCurrencyInfo } from "@/selectors/lotSelectors";
import PropTypes from "prop-types";
import { Lot, Bid } from "@/common-prop-types";

const SingleLotMatrixPropTypes = {
  bid: PropTypes.shape(Bid).isRequired,
  lot: PropTypes.shape(Lot).isRequired,
  isLineItemDetail: PropTypes.bool.isRequired,
  showOverallScore: PropTypes.bool.isRequired,
  translations: PropTypes.object.isRequired,
};

/**
 * @type {React.FC<typeof SingleLotMatrixPropTypes>}
 */
const SingleLotMatrix = (props) => {
  const { bid, lot, isLineItemDetail, showOverallScore, translations } = props;
  const role = useSelector(({ lotReducers }) => lotReducers.role);
  const locale = useSelector(({ lotReducers }) => lotReducers.locale);
  const timeZoneIdentifier = useSelector(({ lotReducers }) => lotReducers.time_zone_identifier);
  const event = useSelector(({ lotReducers }) => lotReducers.event);
  const event_rounds = useSelector(({ lotReducers }) => lotReducers.event_rounds);
  const beforeAuction = useSelector(({ lotReducers }) => lotReducers.before_auction);
  const isJapaneseEvent = useSelector(({ lotReducers }) => lotReducers.is_japanese_event);
  const eventPassedFinalDeadline = useSelector(({ lotReducers }) => lotReducers.event_passed_final_deadline);
  const answers = useSelector(({ lotReducers }) => lotReducers.answers);
  const questionnaires = useSelector(({ lotReducers }) => lotReducers.questionnaires);
  const questionnaireAnswers = useSelector(({ lotReducers }) => lotReducers.questionnaireAnswers);
  const questionnaireSections = useSelector(({ lotReducers }) => lotReducers.questionnaireSections);
  const questionnaireQuestions = useSelector(({ lotReducers }) => lotReducers.questionnaireQuestions);
  const questionnaireAnswersQuestions = useSelector(({ lotReducers }) => lotReducers.questionnaireAnswersQuestions);
  const canShowDeleteBtn = useSelector(({ lotReducers }) => lotReducers.can_show_delete_btn);
  const permissionToView = useSelector(({ lotReducers }) => lotReducers.permission_to_view);
  const permittedToEdit = useSelector(({ lotReducers }) => lotReducers.permitted_to_edit);
  const allUnitSets = useSelector(({ lotReducers }) => lotReducers.unit_sets) || [];
  const unitOfMeasures = useSelector(({ lotReducers }) => lotReducers.unit_of_measures) || [];
  const unitSetUoms = useSelector(({ lotReducers }) => lotReducers.unit_set_unit_of_measures) || [];
  const allUom = [...unitSetUoms, ...unitOfMeasures];
  const hosts = useSelector(({ lotReducers }) => lotReducers.hosts) || [];
  const currentUser = useSelector(({ lotReducers }) => lotReducers.current_user);
  const participants = useSelector(({ lotReducers }) => lotReducers.participants);
  const eventParticipants = useSelector(({ lotReducers }) => lotReducers.event_participants);
  const participatingParticipants = useSelector(({ lotReducers }) => lotReducers.participating_participants);
  const bids = useSelector(({ lotReducers }) => lotReducers.bids);
  const bidList = useSelector(({ lotReducers }) => lotReducers.bidList);
  const bidRound = useSelector(({ lotReducers }) => lotReducers.bid_round);
  const lots = useSelector(({ lotReducers }) => lotReducers.lots);
  const lineItems = useSelector(({ lotReducers }) => lotReducers.line_items);
  const lotComponents = useSelector(({ lotReducers }) => lotReducers.lot_components);
  const bidLineItemComponents = useSelector(({ lotReducers }) => lotReducers.bid_line_item_components);
  const lineItemComponents = useSelector(({ lotReducers }) => lotReducers.line_item_components);
  const picklistOptions = useSelector(({ lotReducers }) => lotReducers.picklist_options);
  const auctionTab = useSelector(({ lotReducers }) => lotReducers.auction_tab);
  const bidControls = useSelector(({ lotReducers }) => lotReducers.bid_controls);
  const auctionControls = useSelector(({ lotReducers }) => lotReducers.auction_controls);
  const { isMultiCurrencyEvent, currency, currenciesHash } = useSelector(selectEventCurrencyInfo);

  const bidBlics = bidBidLineItemComponents(bid.id, bidLineItemComponents);
  const host = bid.host_id && findUser(hosts, bid.host_id);
  const lotLcs = lotLotComponents(lot, lotComponents);
  const lotLis = lotLineItems(lot, lineItems);
  const participant = eventParticipants?.find((participant) => participant.user_id === bid.user_id);
  const user = participants?.find((participant) => participant.id === bid.user_id);
  const LBids = lotBids(lot.id, bids);

  const [isShowFirstTable, setIsShowFirstTable] = useState(false);
  const eventClasses = eventStatusClass(event);

  /** never re-render this component */
  const LotMatrixLicDetailsTableUseMemo = useMemo(
    () => (
      <LotMatrixLicDetailsTable
        lot={lot}
        bid={bid}
        participant={participant}
        user={user}
        event={event}
        bidList={bidList}
        isLineItemDetail={isLineItemDetail}
        bidBlics={bidBlics}
        isJapaneseEvent={isJapaneseEvent}
        beforeAuction={beforeAuction}
        permittedToEdit={permittedToEdit}
        translations={translations}
        participatingParticipants={participatingParticipants}
        eventParticipants={eventParticipants}
        currency={currency}
        locale={locale}
        bidControls={bidControls}
        auctionControls={auctionControls}
        hosts={hosts}
        showOverallScore={showOverallScore}
        lotLcs={lotLcs}
        lotLis={lotLis}
        lineItemComponents={lineItemComponents}
        role={role}
        allUom={allUom}
        isMultiCurrencyEvent={isMultiCurrencyEvent}
        LBids={LBids}
        allUnitSets={allUnitSets}
        timeZoneIdentifier={timeZoneIdentifier}
        bidRound={bidRound}
        lots={lots}
        bids={bids}
        permissionToView={permissionToView}
        currenciesHash={currenciesHash}
        eventPassedFinalDeadline={eventPassedFinalDeadline}
        lotComponents={lotComponents}
        lineItems={lineItems}
        bidLineItemComponents={bidLineItemComponents}
        participants={participants}
        unitOfMeasures={unitOfMeasures}
        currentUser={currentUser}
        unitSetUoms={unitSetUoms}
        questionnaireAnswers={questionnaireAnswers}
        questionnaireQuestions={questionnaireQuestions}
        answers={answers}
        questionnaireAnswersQuestions={questionnaireAnswersQuestions}
        questionnaires={questionnaires}
        questionnaireSections={questionnaireSections}
        picklistOptions={picklistOptions}
        auctionTab={auctionTab}
        canShowDeleteBtn={canShowDeleteBtn}
        event_rounds={event_rounds}
        host={host}
      />
    ),
    [false]
  );

  return (
    <div>
      <div className="popup-main padding-top">
        {isShowFirstTable ? (
          <div className="lot-content">
            <LotMatrixDetailsTable
              lot={lot}
              bid={bid}
              host={host}
              user={user}
              event={event}
              locale={locale}
              currency={currency}
              bidControls={bidControls}
              participant={participant}
              translations={translations}
              beforeAuction={beforeAuction}
              isJapaneseEvent={isJapaneseEvent}
              auctionControls={auctionControls}
              lineItemComponents={lineItemComponents}
            />
          </div>
        ) : null}
        <br />
        <div
          onClick={() => {
            setIsShowFirstTable(!isShowFirstTable);
          }}
          className="hide-lot-content btn-group-lg"
        >
          <button className="btn btn-default">
            <span data-info={translations.hide}>{isShowFirstTable ? translations.hide : translations.show}</span>
            &nbsp;
            {translations.lot_details}
            <i className="caret push-5-l"></i>
          </button>
        </div>
        <div className="alert alert-info m-t10">{translations.participant_bids_note}</div>
        <div className="lot-content-wrapper">
          <div className={`lot-lic ${eventClasses}`} style={{ overflow: "auto" }}>
            {LotMatrixLicDetailsTableUseMemo}
          </div>
        </div>
      </div>
    </div>
  );
};
SingleLotMatrix.propTypes = SingleLotMatrixPropTypes;

export default SingleLotMatrix;
