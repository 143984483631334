import PropTypes from "prop-types";

import { Select as MDSelect, SelectPropTypes as MDSelectPropTypes } from "./select.jsx";
import { withReactFormTextAttribute } from "../input/react-form.jsx";

const SelectPropTypes = {
  /**
   * The react from attribute that this input will control
   */
  attribute: PropTypes.string.isRequired,
  options: MDSelectPropTypes.options,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof SelectPropTypes>>}
 */
const Select = withReactFormTextAttribute(MDSelect);
Select.displayName = "ReactFormSelect";
Select.propTypes = SelectPropTypes;

export default Select;
