import React from "react";
import { AllLotDetails } from "./AllLotDetails";

const ShowBeforeAuction = (props) => {
  return (
    <tbody>
      <AllLotDetails {...props} />
    </tbody>
  );
};

export default ShowBeforeAuction;
