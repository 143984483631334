import React, { Component } from "react";
import flatten from "lodash/flatten";
import join from "lodash/join";
import last from "lodash/last";
import trim from "lodash/trim";
import { connect } from "react-redux";
import { splitFormula } from "../../common";
import Tooltip from "../../../common/Tooltip";
import DropFieldForTags from "./DropFieldForTags";
import LicTagsForFormula from "./LicTagsForFormula";
import NumberAndOperatorKeysForFormula from "./NumberAndOperatorKeysForFormula";
import { selectLotAutomationState } from "../lotCommon";
import SelectLotCurrencyExchangeRate from "./common/SelectLotCurrencyExchangeRate";

class LineItemComponentFormulaField extends Component {
  constructor() {
    super();
    this.selected = null;
    this.dragOver = this.dragOver.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.dragStart = this.dragStart.bind(this);
    this.isBefore = this.isBefore.bind(this);
    this.handleFormulaChange = this.handleFormulaChange.bind(this);
    this.handleAutoSumFormula = this.handleAutoSumFormula.bind(this);
    this.handleAutocompleteFormulaSave = this.handleAutocompleteFormulaSave.bind(this);
    this.handleDragDrop = this.handleDragDrop.bind(this);
  }

  handleFormulaChange(event) {
    let result;
    let tag;
    const { formula, setFormula, isInputDisabled } = this.props;

    if (isInputDisabled) return false;

    const splitFor = splitFormula(formula);
    const dataName = event.target && event.target.getAttribute("data-name");
    if (event.target) {
      tag = event.target.textContent;
    } else {
      tag = event;
    }

    if (dataName === "number" && (Number(last(splitFor)) || (last(splitFor) && last(splitFor).includes(".")))) {
      let lastVal = last(splitFor);
      lastVal = lastVal + tag;
      splitFor.pop();
      splitFor.push(lastVal);
      result = join(splitFor, ",");
    } else if (dataName === "backspace") {
      splitFor.pop();
      result = join(splitFor, ",");
    } else if (dataName === "clear-formula") {
      result = "";
    } else {
      if (tag === ".") {
        const resultArr = formula.split(",");
        resultArr.pop();
        if (resultArr.length > 0 && !isNaN(last(splitFor))) {
          const lastVal = resultArr.pop();
          result = `${resultArr.join()},${lastVal + tag}`;
        } else {
          result = formula + tag;
        }
      } else {
        result = formula + tag;
      }
    }
    setFormula(result ? `${result},` : result);
  }

  handleAutoSumFormula() {
    const { lic, calculationComponent, setFormula, filteredLicsForFormula, isInputDisabled } = this.props;

    if (isInputDisabled) return;

    let formulaLics = filteredLicsForFormula;
    if (lic.is_calculation && calculationComponent) {
      formulaLics = filteredLicsForFormula.filter(
        (licomponent) => licomponent.id !== lic.id && lic.lot_component_id === licomponent.lot_component_id
      );
    } else if (calculationComponent) {
      formulaLics = filteredLicsForFormula.filter((lotLic) => {
        return lotLic.calculable_currency_lc;
      });
    }
    const formulaLicTags = formulaLics.map((liComponent) => liComponent.tag);
    setFormula(`${join(formulaLicTags, ",+,")},`);
  }

  // This function is used for drag and drop of the tags on Formula Builder
  // It loops on the tags and checks if there are two numbers are placed near by
  // if yes then it concats them or else does nothing
  handleDragDrop(tags) {
    let previousTag = undefined;
    let result = [];
    const { arrangeFormula, setFormula } = this.props;
    tags.pop();
    if (tags.length > 1) {
      tags.map(function (tag) {
        let concatedTag = "";
        if (!isNaN(tag) && !isNaN(previousTag)) {
          concatedTag = String(previousTag) + tag;
          result[result.length - 1] = concatedTag;
        } else {
          result.push(tag);
        }
        if (concatedTag.length) {
          previousTag = concatedTag;
        } else {
          previousTag = tag;
        }
      });
      const lastVal = last(result);
      if (lastVal === ".") {
        result.pop();
        if (tags.length > 0 && !isNaN(last(result))) {
          const numberVal = result.pop();
          result.push(numberVal + lastVal);
        } else {
          result = formula + tag;
        }
      }
    } else if (tags.length === 1) {
      result.push(tags[0]);
    }
    const finalResult = arrangeFormula(result.join());
    setFormula(finalResult);
  }

  // This funtion checkes string which user enter into auto complete text box and
  // here we are breaking string into character and check all the character one by one
  // and if previous and the current character is integer then merge it else seperated
  // it using coma(,)
  createFormulaForAutocomplete(formula, val) {
    val = trim(val);
    if (val) {
      let result = "";
      let isReverse = false;
      for (let i = 0; i < val.length; i++) {
        if (val[i].match(/^[0-9a-z.]+$/) && !isReverse) {
          result = result + val[i];
          isReverse = false;
        } else {
          result = result + "," + val[i];
          if (val[i].match(/^[0-9a-z.]+$/)) {
            isReverse = false;
          } else {
            isReverse = true;
          }
        }
      }
      return `${formula}${result},`;
    } else {
      return formula;
    }
  }

  flattenedLics(formulaLics, lots) {
    let lics = [];
    if (lots) {
      lots.map(function (lot) {
        const lotLics = formulaLics[lot.id];
        if (lotLics) {
          lics.push(flatten(Object.values(lotLics)));
        }
      });
    }
    return flatten(lics);
  }

  handleAutocompleteFormulaSave(val) {
    const { formula, setFormula } = this.props;
    setFormula(this.createFormulaForAutocomplete(formula, val));
  }

  dragOver(e) {
    e.preventDefault();
    const { selected, isBefore } = this;
    const { isInputDisabled } = this.props;

    if (isInputDisabled) return;

    const target = e.target;
    const element = document.getElementById("formula");
    if (target.id == "formula") {
      const formulaInput = document.getElementById("formulaDiv");
      element.insertBefore(selected, formulaInput);
    } else if (target.parentNode.id == "formula") {
      const insertBefore = isBefore(selected, target);
      element.insertBefore(selected, insertBefore ? target : target.nextSibling);
    }
  }

  dragEnd(e) {
    e.preventDefault();

    const { isInputDisabled } = this.props;

    if (isInputDisabled) return;

    const parentElement = document.getElementById("formula");
    // This class is added from LotsContainer component if the user has dropped
    // the tag outside the formula field to remove it from the formula
    let greyTag = document.getElementsByClassName("grey-tag");
    if (greyTag.length) {
      parentElement.removeChild(this.selected);
    }
    const children = parentElement.children;
    let tags = [];
    for (var i = 0; i < children.length; i++) {
      tags.push(children[i].textContent);
    }
    this.handleDragDrop(tags);
    this.selected = null;
  }

  dragStart(e, effect = "copy") {
    const { isInputDisabled } = this.props;

    if (isInputDisabled) return;

    if (e.dataTransfer === undefined) {
      e = e.nativeEvent;
    }
    const target = e.target;
    const dataEffect = target.getAttribute("data-effect");
    if (dataEffect) {
      effect = dataEffect;
    }
    e.dataTransfer.setData("text", target.innerText);
    e.dataTransfer.effectAllowed = effect;
    // We can sort the tags inside formula field, so in that case we need to move
    // the tags instead of copying them
    if (effect === "copy") {
      this.selected = target.cloneNode(true);
    } else {
      this.selected = target;
    }
    // This class makes the UI similar to what we have on Rails end, it pretends
    // to add a blank space equivalent to the tag size in between the tags or
    // wherever we are trying to add it whereas it actually appends the original
    // tag there but in white background and text
    this.selected.className += " grey-tag";
  }

  // This method checks whether we need to append the dragged tag before the existing
  // tag or after that
  isBefore(el1, el2) {
    var cur;
    if (el2.parentNode === el1.parentNode) {
      for (cur = el1.previousSibling; cur; cur = cur.previousSibling) {
        if (cur == el2) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  render() {
    const {
      event,
      lot,
      lic,
      anyCompletedBids,
      translations,
      isDefaultFormulaLic,
      handleRankChange,
      lots,
      formula,
      filteredLicsForFormula,
      currenciesHash,
      exchangeRateId,
      lotWiseFormulaLics,
      setExchangeRateId,
      isInputDisabled,
    } = this.props;

    const { lot_formula, lot_apply_default_formula } = translations.infos;
    const lotTotalLicIds = lots.map((lot) => lot.lot_total_lic_id);
    const lotTotalLicTags = filteredLicsForFormula
      .filter((lineItemC) => lotTotalLicIds.includes(lineItemC.id))
      .map((lineItemCo) => lineItemCo.tag);

    return (
      <div className="row" key={lic.id}>
        <div id="formula_fields">
          <div className="col input">
            <div className="col-md-12">
              <div className="form-group">
                <div className="markup">
                  <Tooltip title={lot_formula.title} body={lot_formula.body} />
                  <label>{translations.formula}</label>
                  {!lot.is_event_total && (
                    <div className="pull-right surround radios">
                      <Tooltip title={lot_apply_default_formula.title} body={lot_apply_default_formula.body} />
                      <label className="css-input css-checkbox css-checkbox-default" htmlFor="isDefaultFormula">
                        <input
                          type="checkbox"
                          name="isDefaultFormulaLic"
                          checked={isDefaultFormulaLic == "1"}
                          disabled={anyCompletedBids || lic.is_default_formula || isInputDisabled}
                          onChange={(e) => handleRankChange(e)}
                          id="isDefaultFormula"
                        />
                        <span />
                        {lic.is_price && lic.calculation
                          ? translations.apply_to_entire_column
                          : translations.apply_to_entire_row}
                      </label>
                    </div>
                  )}
                  <DropFieldForTags
                    formula={formula}
                    handleFormulaChange={this.handleFormulaChange}
                    handleAutocompleteFormulaSave={this.handleAutocompleteFormulaSave}
                    filteredLicsForFormula={filteredLicsForFormula}
                    lot={lot}
                    dragEnd={this.dragEnd}
                    dragOver={this.dragOver}
                    dragStart={this.dragStart}
                    lotTotalLicTags={lotTotalLicTags}
                    isTagAutocompleteDisabled={isInputDisabled}
                  />
                  <div className="clear" />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div id="builder">
                <div className="col-sm-8 lic-tag-formula">
                  <LicTagsForFormula
                    lot={lot}
                    lots={lots}
                    handleFormulaChange={this.handleFormulaChange}
                    dragEnd={this.dragEnd}
                    dragStart={this.dragStart}
                    lotWiseFormulaLics={lotWiseFormulaLics}
                    draggable={!isInputDisabled}
                  />
                </div>

                <div className="col-sm-4">
                  <NumberAndOperatorKeysForFormula
                    lot={lot}
                    lic={lic}
                    translations={translations}
                    handleFormulaChange={this.handleFormulaChange}
                    handleAutoSumFormula={this.handleAutoSumFormula}
                    dragEnd={this.dragEnd}
                    dragOver={this.dragOver}
                    dragStart={this.dragStart}
                    draggable={!isInputDisabled}
                  />
                </div>
              </div>
            </div>
            {event.multi_currency_event && lic.is_price && (
              <SelectLotCurrencyExchangeRate
                currenciesHash={currenciesHash}
                onChange={setExchangeRateId}
                disabled={isInputDisabled}
                value={exchangeRateId}
                layout="builder"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isInputDisabled: selectLotAutomationState(state).isEnforced && !props.lic.host && !state.lotReducers.in_sandpit,
});

export default connect(mapStateToProps)(LineItemComponentFormulaField);
