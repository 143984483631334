import React, { Component } from "react";
import { connect } from "react-redux";

import PicklistModal from "./PicklistModal";
import { createPicklist, updatePicklist } from "../../../../actions/picklistActions";
import { ModalTriggerButton } from "./UnitSetButtons";

export class PicklistButtons extends Component {
  defaultState(modalIsOpen) {
    const { translations } = this.props;
    return {
      modalIsOpen: modalIsOpen,
      picklistName: "",
      picklistType: 0, // 0 - None, 1 - Currency, 2 - Numeric
      picklistOptions: [{ value: "" }],
      picklistId: undefined,
      title: translations.new_picklist,
    };
  }

  editPicklistState(picklist, chosenPicklistOptions) {
    const { translations } = this.props;
    return {
      modalIsOpen: true,
      picklistName: picklist.name,
      picklistType: picklist.picklist_type,
      picklistOptions: this.buildPicklistOptions(chosenPicklistOptions),
      picklistId: picklist.id,
      title: translations.edit_picklist,
    };
  }

  buildPicklistOptions(picklistOptions) {
    return picklistOptions.map((po) => {
      return { id: po.id, value: po.value };
    });
  }

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.defaultState = this.defaultState.bind(this);
    this.editPicklistState = this.editPicklistState.bind(this);
    this.addNewPicklistOption = this.addNewPicklistOption.bind(this);
    this.handleOptionNameChange = this.handleOptionNameChange.bind(this);
    this.handlePicklistNameChange = this.handlePicklistNameChange.bind(this);
    this.handlePicklistTypeChange = this.handlePicklistTypeChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.removePicklistOption = this.removePicklistOption.bind(this);
    this.state = this.defaultState(false);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal(picklist, chosenPicklistOptions) {
    const state = picklist ? this.editPicklistState(picklist, chosenPicklistOptions) : this.defaultState(true);
    this.setState(state);
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    const { currentUser, handlePicklistChange } = this.props;
    const { picklistName, picklistType, picklistOptions, picklistId } = this.state;
    let payload = { picklist: { user_id: currentUser.id } };
    let picklistPayload = payload["picklist"];
    picklistPayload["name"] = picklistName;
    // 0 - None, 1 - Currency, 2 - Numeric
    picklistPayload["picklist_type"] = ["none", "currency", "numeric"][parseInt(picklistType)];
    picklistPayload["picklist_options_attributes"] = {};
    picklistOptions.map(function (po, i) {
      if (po["id"] === undefined && !po["_destroy"]) {
        picklistPayload["picklist_options_attributes"][i] = po;
      } else {
        picklistPayload["picklist_options_attributes"][i] = po;
      }
    });
    let result = undefined;
    if (picklistId) {
      result = await this.props.updatePicklist(payload, picklistId);
    } else {
      result = await this.props.createPicklist(payload);
    }
    if (result) {
      if (picklistId === undefined) {
        handlePicklistChange({ target: { value: result } });
      }
      this.closeModal();
    }
  }

  handlePicklistNameChange(event) {
    this.setState({ picklistName: event.target.value });
  }

  handleOptionNameChange(e, id) {
    let picklistOptions = [...this.state.picklistOptions];
    picklistOptions[e.target.dataset.id][e.target.name] = e.target.value;
    this.setState({ picklistOptions });
  }

  addNewPicklistOption() {
    let picklistOptions = [...this.state.picklistOptions, { value: "" }];
    this.setState({ picklistOptions });
  }

  handlePicklistTypeChange(e) {
    this.setState({ picklistType: e.target.value });
  }

  removePicklistOption(e) {
    let picklistOptions = [...this.state.picklistOptions];
    picklistOptions[e.target.dataset.id]["_destroy"] = true;
    this.setState({ picklistOptions });
  }

  render() {
    const { classFromEventState, translations, picklist, chosenPicklistOptions } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <>
        {picklist && (
          <span id="edit_picklist_link">
            <ModalTriggerButton
              className={classFromEventState}
              handleClick={() => this.openModal(picklist, chosenPicklistOptions)}
              label={translations.edit_picklist}
              edit
            />
          </span>
        )}
        <ModalTriggerButton
          className={classFromEventState}
          handleClick={() => this.openModal(false)}
          label={translations.new_picklist}
        />
        {modalIsOpen && (
          <PicklistModal
            closeModal={this.closeModal}
            picklist={picklist}
            translations={translations}
            handleFormSubmit={this.handleFormSubmit}
            handleOptionNameChange={this.handleOptionNameChange}
            handlePicklistTypeChange={this.handlePicklistTypeChange}
            handlePicklistNameChange={this.handlePicklistNameChange}
            addNewPicklistOption={this.addNewPicklistOption}
            removePicklistOption={this.removePicklistOption}
            {...this.state}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createPicklist: (payload) => dispatch(createPicklist(payload)),
  updatePicklist: (payload, picklistId) => dispatch(updatePicklist(payload, picklistId)),
});

export default connect(null, mapDispatchToProps)(PicklistButtons);
