import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import orderBy from "lodash/orderBy";
import { createAsyncThunk } from "@reduxjs/toolkit";

const participantPermissionSuccess = (participantPermission, participantPermissionId) => ({
  type: actionTypes.MAKE_PARTICIPANT_PERMISSION,
  payload: participantPermission,
  participantPermissionId: participantPermissionId,
});

const deleteparticipantPermissionSuccess = (participantPermissionId) => ({
  type: actionTypes.DELETE_PARTICIPANT_PERMISSION,
  payload: participantPermissionId,
});

const getEventsSuccess = (userPermissions, type) => {
  return {
    type: type,
    payload: userPermissions,
  };
};

const deleteUserPermissionSuccess = (type, response, userPermissionId, userPermissions) => {
  let newUserPermissions = {};
  if (response.status === 204) {
    newUserPermissions = userPermissions.filter((up) => up.id !== userPermissionId);
  } else {
    newUserPermissions = userPermissions;
  }
  return getEventsSuccess(newUserPermissions, type);
};

const deleteAdminPermissionSuccess = (userPermissions, adminPermission, type) => {
  let userPermission = userPermissions.filter((up) => up.id === adminPermission.user_permission_id)[0];
  const userPermissionIndex = userPermissions.findIndex((up) => up.id === adminPermission.user_permission_id);
  const newAdminPermissions = userPermission.admin_permissions.filter((ap) => ap.id !== adminPermission.id);
  userPermission.admin_permissions = newAdminPermissions;
  userPermissions[userPermissionIndex] = userPermission;
  return getEventsSuccess(userPermissions, type);
};

const addUserSuccess = (response, userPermissions) => {
  const addedUserPermissions = response ? response.data.user_permissions : response;
  if (addedUserPermissions === undefined) {
    return {
      type: "NONE",
    };
  } else {
    addedUserPermissions.map((up) => {
      userPermissions.push(up);
      userPermissions = orderBy(
        userPermissions,
        [(userPermission) => userPermission.user.email.toLowerCase()],
        ["asc"]
      );
    });
    return getEventsSuccess(userPermissions, actionTypes.CREATE_USER_PERMISSION);
  }
};

const deleteEventPermissionSuccess = (userPermissions, eventPermissionId, userPermissionId, type) => {
  let userPermission = userPermissions.filter((up) => up.id === userPermissionId)[0];
  const userPermissionIndex = userPermissions.findIndex((up) => up.id === userPermissionId);
  const newEventPermissions = userPermission.event_permissions.filter((ep) => ep.id !== parseInt(eventPermissionId));
  userPermission.event_permissions = newEventPermissions;
  userPermissions[userPermissionIndex] = userPermission;
  return { payload: userPermissions, type, userPermission };
};

const deleteContractPermissionSuccess = (userPermissions, contractPermissionId, userPermissionId, type) => {
  let userPermission = userPermissions.filter((up) => up.id === userPermissionId)[0];
  const userPermissionIndex = userPermissions.findIndex((up) => up.id === userPermissionId);
  const newContractPermissions = userPermission.contract_permissions.filter(
    (cp) => cp.id !== parseInt(contractPermissionId)
  );
  userPermission.contract_permissions = newContractPermissions;
  userPermissions[userPermissionIndex] = userPermission;
  return { payload: userPermissions, type, userPermission };
};

const saveNewSuperhostSuccess =
  (userPermissions, currentUserId, response, newUserId, companyId, type) => (dispatch) => {
    let currentUserPermission = userPermissions.filter((up) => up.user.id === currentUserId)[0];
    currentUserPermission.user.superhost = false;
    const currentUserPermissionIndex = userPermissions.findIndex((up) => up.user.id === currentUserId);
    userPermissions[currentUserPermissionIndex] = currentUserPermission;
    let newUserPermission = userPermissions.filter((up) => up.user.id === parseInt(newUserId))[0];
    if (newUserPermission) {
      let newSuperHostPermission = userPermissions.filter((up) => up.user.id === response.data.new_superhost_id)[0];
      newSuperHostPermission.user.superhost = true;
      const newSuperHostPermissionIndex = userPermissions.findIndex(
        (up) => up.user.id === response.data.new_superhost_id
      );
      userPermissions[newSuperHostPermissionIndex] = newSuperHostPermission;
      dispatch(getAdminPermissions(newUserPermission.id, userPermissions, type));
    } else {
      dispatch(getUserPermissionDetails(companyId, newUserId, userPermissions, type));
    }
  };

const getAdminPermissionsSuccess = (userPermissionId, userPermissions, type, response) => {
  let userPermission = userPermissions.filter((up) => up.id === userPermissionId)[0];
  const userPermissionIndex = userPermissions.findIndex((up) => up.id === userPermissionId);
  userPermission.admin_permissions = response.data.admin_permissions;
  userPermissions[userPermissionIndex] = userPermission;
  return { payload: userPermissions, type, userPermission };
};

const getEventPermissionsSuccess = (userPermissionId, userPermissions, type, response) => {
  let userPermission = userPermissions.filter((up) => up.id === userPermissionId)[0];
  const userPermissionIndex = userPermissions.findIndex((up) => up.id === userPermissionId);
  userPermission.event_permissions = response.data.event_permissions;
  userPermissions[userPermissionIndex] = userPermission;
  return { payload: userPermissions, type, userPermission };
};

const getContractPermissionsSuccess = (userPermissionId, userPermissions, type, response) => {
  let userPermission = userPermissions.filter((up) => up.id === userPermissionId)[0];
  const userPermissionIndex = userPermissions.findIndex((up) => up.id === userPermissionId);
  userPermission.contract_permissions = response.data.contract_permissions;
  userPermissions[userPermissionIndex] = userPermission;
  return { payload: userPermissions, type, userPermission };
};

const getUserPermissionDetailsSuccess = (userPermissions, type, response) => {
  userPermissions.push(response.data.user_permission);
  return { payload: userPermissions, type: type };
};

const getError = (error = {}) => {
  error.data ??= error.response?.data;
  if (error.data?.error) {
    return {
      type: actionTypes.ERROR,
      error: error.data.error,
    };
  }

  return {
    type: actionTypes.ERROR,
  };
};

const showSuccess = (successMessage) => {
  return { type: actionTypes.SUCCESS, success: successMessage };
};

const getCategoryPermissionsSuccess = (userPermissionId, userPermissions, type, response) => {
  let userPermission = userPermissions.filter((up) => up.id === userPermissionId)[0];
  const userPermissionIndex = userPermissions.findIndex((up) => up.id === userPermissionId);
  userPermission.category_permissions = response.data.category_permissions;
  userPermissions[userPermissionIndex] = userPermission;
  return { payload: userPermissions, type, userPermission };
};

const deleteCategoryPermissionSuccess = (userPermissions, eventPermissionId, userPermissionId, type) => {
  let userPermission = userPermissions.filter((up) => up.id === userPermissionId)[0];
  const userPermissionIndex = userPermissions.findIndex((up) => up.id === userPermissionId);
  const newEventPermissions = userPermission.category_permissions.filter((ep) => ep.id !== parseInt(eventPermissionId));
  userPermission.category_permissions = newEventPermissions;
  userPermissions[userPermissionIndex] = userPermission;
  return { payload: userPermissions, type, userPermission };
};

const createUserPermissionQuery = (categoryDojo, page, search) =>
  `${categoryDojo ? "cat_dojo=true" : "market_dojo=true"}${page ? "&page=" + page : ""}${
    search ? "&search=" + search : ""
  }`;

const getAllPermissions = (getState, page, permissions) =>
  page > 1 ? [...getState().adminReducers.userPermissions, ...permissions] : permissions;

export const getCompanyUserPermissions = (companyId, categoryDojo, page, search) => async (dispatch, getState) => {
  try {
    const query = createUserPermissionQuery(categoryDojo, page, search);
    const response = await apiCall.get(`${config.BASE_URL}/companies/${companyId}/user_permissions?&${query}`);
    const permissions = response.data.user_permissions;
    const userPermissions = getAllPermissions(getState, page, permissions);

    dispatch(getEventsSuccess(userPermissions, actionTypes.GET_COMPANY_USER_PERMISSIONS));
    return permissions;
  } catch (error) {
    dispatch(getError(error.response));
  }
};

export const deleteUserPermission = (userPermissionId, userPermissions, successMessage) => (dispatch) =>
  apiCall
    .remove(`${config.BASE_URL}/user_permissions/${userPermissionId}`)
    .then((response) => {
      dispatch(
        deleteUserPermissionSuccess(actionTypes.DELETE_USER_PERMISSIONS, response, userPermissionId, userPermissions)
      );
      dispatch(showSuccess(successMessage));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const showUserPermissionModal = (userPermission) => (dispatch) =>
  dispatch({
    type: actionTypes.SHOW_USER_PERMISSION_MODAL,
    payload: userPermission,
  });

export const showAlert =
  (show, objectId, eventPermissionId = 0) =>
  (dispatch) => {
    let objectIdToModify = 0;
    if (eventPermissionId !== 0) {
      objectIdToModify = eventPermissionId;
    } else {
      objectIdToModify = objectId;
    }
    return dispatch({
      type: actionTypes.SHOW_ALERT,
      showAlert: show,
      objectIdToModify: objectIdToModify,
      objectId: objectId,
    });
  };

export const updateAdminPermission = (adminPermission, payload) => (dispatch) =>
  apiCall
    .put(`${config.BASE_URL}/admin_permissions/${adminPermission.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const deleteAdminPermission = (adminPermission, userPermissions) => (dispatch) =>
  apiCall
    .remove(`${config.BASE_URL}/admin_permissions/${adminPermission.id}`)
    .then((response) => {
      dispatch(deleteAdminPermissionSuccess(userPermissions, adminPermission, actionTypes.DELETE_ADMIN_PERMISSION));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const createAdminPermission = (userPermissionId, payload, userPermissions) => (dispatch) =>
  apiCall
    .post(`${config.BASE_URL}/user_permissions/${userPermissionId}/admin_permissions/`, payload)
    .then((response) => {
      dispatch(getAdminPermissions(userPermissionId, userPermissions, actionTypes.CREATE_ADMIN_PERMISSION));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const addUser = (companyId, userEmails, userPermissions, categoryDojo) => (dispatch) =>
  apiCall
    .post(`${config.BASE_URL}/companies/${companyId}/user_permissions`, {
      user_permissions: { user_emails: userEmails },
      cat_dojo: categoryDojo,
      market_dojo: !categoryDojo,
    })
    .then((response) => {
      dispatch(addUserSuccess(response, userPermissions));
      dispatch(showSuccess(response.data.message));
    })
    .catch((error) => {
      dispatch(addUserSuccess(error.response, userPermissions));
      dispatch(getError(error.response));
    });

export const notifyUsersAboutChanges = (companyId, userPermissionId, categoryDojo, simDojo) => (dispatch) =>
  apiCall
    .post(`${config.BASE_URL}/companies/${companyId}/user_notification_email/`, {
      user_permission_id: userPermissionId,
      cat_dojo: categoryDojo,
      sim_dojo: simDojo,
    })
    .then((response) => {
      dispatch(getCompanyUserPermissions(companyId, categoryDojo));
      dispatch(showSuccess(response.data.message));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const defaultGroupUsers = (companyId) => (dispatch) =>
  apiCall
    .get(`${config.BASE_URL}/companies/${companyId}/hosts`)
    .then((response) => {
      dispatch({
        allUsers: response.data.users,
        type: actionTypes.GET_ALL_DEFAULT_GROUP_USERS,
      });
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const createEventPermission = (permission, resourceType, resourceId, userPermissionId) => (dispatch) =>
  apiCall
    .post(`${config.BASE_URL}/user_permissions/${userPermissionId}/event_permissions/`, {
      event_permission: {
        action: permission,
        resource_type: resourceType,
        resource_id: resourceId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const updateEventPermission = (eventPermissionId, permission, resourceType, resourceId) => (dispatch) =>
  apiCall
    .put(`${config.BASE_URL}/event_permissions/${eventPermissionId}/`, {
      event_permission: {
        action: permission,
        resource_type: resourceType,
        resource_id: resourceId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const deleteEventPermission = (eventPermissionId, userPermissionId, userPermissions) => (dispatch) =>
  apiCall
    .remove(`${config.BASE_URL}/event_permissions/${eventPermissionId}`)
    .then((response) => {
      dispatch(
        deleteEventPermissionSuccess(
          userPermissions,
          eventPermissionId,
          userPermissionId,
          actionTypes.DELETE_EVENT_PERMISSION
        )
      );
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const createContractPermission = (permission, resourceType, resourceId, userPermissionId) => (dispatch) =>
  apiCall
    .post(`${config.BASE_URL}/user_permissions/${userPermissionId}/contract_permissions/`, {
      contract_permission: {
        action: permission,
        resource_type: resourceType,
        resource_id: resourceId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const createParticipantPermission =
  (action, resourceId, userPermissionId, participantPermissionId) => (dispatch) => {
    if (participantPermissionId) {
      apiCall
        .put(`${config.BASE_URL}/sim_participant_permissions/${participantPermissionId}`, {
          participant_permission: {
            action: action,
            resource_type: "Group",
            resource_id: resourceId,
          },
        })
        .then((response) => {
          dispatch(participantPermissionSuccess(response.data.participant_permission, participantPermissionId));
        })
        .catch((error) => {
          dispatch(getError(error.response));
        });
    } else {
      apiCall
        .post(`${config.BASE_URL}/user_permissions/${userPermissionId}/sim_participant_permission`, {
          participant_permission: {
            action: action,
            resource_type: "Group",
            resource_id: resourceId,
          },
        })
        .then((response) => {
          dispatch(participantPermissionSuccess(response.data.participant_permission));
        })
        .catch((error) => {
          dispatch(getError(error.response));
        });
    }
  };

export const deleteParticipantPermission = (participantPermissionId) => (dispatch) => {
  apiCall
    .remove(`${config.BASE_URL}/sim_participant_permissions/${participantPermissionId}`)
    .then((response) => {
      dispatch(deleteparticipantPermissionSuccess(participantPermissionId));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const deleteContractPermission = (contractPermissionId, userPermissionId, userPermissions) => (dispatch) =>
  apiCall
    .remove(`${config.BASE_URL}/contract_permissions/${contractPermissionId}`)
    .then((response) => {
      dispatch(
        deleteContractPermissionSuccess(
          userPermissions,
          contractPermissionId,
          userPermissionId,
          actionTypes.DELETE_CONTRACT_PERMISSION
        )
      );
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const updateContractPermission = (contractPermissionId, permission, resourceType, resourceId) => (dispatch) =>
  apiCall
    .put(`${config.BASE_URL}/contract_permissions/${contractPermissionId}/`, {
      contract_permission: {
        action: permission,
        resource_type: resourceType,
        resource_id: resourceId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const saveNewSuperhost = (companyId, newUserId, userPermissions, currentUserId, successMessage) => (dispatch) =>
  apiCall
    .post(`${config.BASE_URL}/companies/${companyId}/transfer_superhost`, {
      company: { user_id: newUserId },
    })
    .then((response) => {
      dispatch(
        saveNewSuperhostSuccess(
          userPermissions,
          currentUserId,
          response,
          newUserId,
          companyId,
          actionTypes.TRANSFER_SUPERHOST
        )
      );
      dispatch(showSuccess(successMessage));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const closeUserPermissionModal = () => (dispatch) =>
  dispatch({
    type: actionTypes.CLOSE_MODAL,
  });

export const getAdminPermissions = (userPermissionId, userPermissions, type) => (dispatch) =>
  apiCall
    .get(`${config.BASE_URL}/user_permissions/${userPermissionId}/admin_permissions`)
    .then((response) => {
      dispatch(getAdminPermissionsSuccess(userPermissionId, userPermissions, type, response));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const getEventPermissions = (userPermissionId, userPermissions, type) => (dispatch) =>
  apiCall
    .get(`${config.BASE_URL}/user_permissions/${userPermissionId}/event_permissions`)
    .then((response) => {
      dispatch(getEventPermissionsSuccess(userPermissionId, userPermissions, type, response));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const getContractPermissions = (userPermissionId, userPermissions, type) => (dispatch) =>
  apiCall
    .get(`${config.BASE_URL}/user_permissions/${userPermissionId}/contract_permissions`)
    .then((response) => {
      dispatch(getContractPermissionsSuccess(userPermissionId, userPermissions, type, response));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const getUserPermissionDetails = (companyId, newUserId, userPermissions, type) => (dispatch) =>
  apiCall
    .get(`${config.BASE_URL}/companies/${companyId}/user_permission_details?user_id=${newUserId}`)
    .then((response) => {
      dispatch(getUserPermissionDetailsSuccess(userPermissions, type, response));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const createCategoryPermission = (permission, resourceType, resourceId, userPermissionId) => (dispatch) => {
  const categoryResourceType =
    resourceType == "Portfolio" || resourceType == "Category" ? `CategoryDojo::${resourceType}` : resourceType;
  return apiCall
    .post(`${config.BASE_URL}/user_permissions/${userPermissionId}/category_permissions`, {
      category_permission: {
        action: permission,
        catdojo_resource_type: categoryResourceType,
        catdojo_resource_id: resourceId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const updateCategoryPermission = (eventPermissionId, permission, resourceType, resourceId) => (dispatch) => {
  const categoryResourceType =
    resourceType == "Portfolio" || resourceType == "Category" ? `CategoryDojo::${resourceType}` : resourceType;
  return apiCall
    .put(`${config.BASE_URL}/category_permissions/${eventPermissionId}/`, {
      category_permission: {
        action: permission,
        catdojo_resource_type: categoryResourceType,
        catdojo_resource_id: resourceId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const getCategoryPermissions = (userPermissionId, userPermissions, type) => (dispatch) =>
  apiCall
    .get(`${config.BASE_URL}/user_permissions/${userPermissionId}/category_permissions`)
    .then((response) => {
      dispatch(getCategoryPermissionsSuccess(userPermissionId, userPermissions, type, response));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const deleteCategoryPermission = (eventPermissionId, userPermissionId, userPermissions) => (dispatch) =>
  apiCall
    .remove(`${config.BASE_URL}/category_permissions/${eventPermissionId}`)
    .then((response) => {
      dispatch(
        deleteCategoryPermissionSuccess(
          userPermissions,
          eventPermissionId,
          userPermissionId,
          actionTypes.DELETE_CATEGORY_PERMISSION
        )
      );
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const deactivateUserPermission = (userPermissionId, userPermissions, successMessage) => (dispatch) =>
  apiCall
    .remove(`${config.BASE_URL}/user_permissions/${userPermissionId}/deactivate/`)
    .then((response) => {
      // using the same action as delete to update the deactivated user permission as its removing the userpermission from the list
      dispatch(
        deleteUserPermissionSuccess(actionTypes.DELETE_USER_PERMISSIONS, response, userPermissionId, userPermissions)
      );
      dispatch(showSuccess(successMessage));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

const withFetchStatusDispatch = (payloadCreator) => async (args, api) => {
  try {
    const { data } = await payloadCreator(args);
    api.dispatch(showSuccess(data.message));
  } catch (error) {
    api.dispatch(getError(error));
  }
};

export const requestLicence = createAsyncThunk(
  "requestLicence",
  withFetchStatusDispatch(async ({ companyId, userId }) => {
    const url = `${config.BASE_URL}/companies/${companyId}/user_permissions/license_mail_request?user_id=${userId}`;
    const response = await apiCall.get(url);

    return response;
  })
);
