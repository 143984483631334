import React from "react";
import type { CommonFilterInputProps } from "@/components/contract/new/listings/filter-input";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import type { Comparison } from "@/slices/contracts/listing";
import { dateComparisonChanged, dateFilterChanged, selectDateFilter } from "@/slices/contracts/listing";
import { Select } from "@/cl/select";
import { DatePicker } from "@/cl/input/date";
import styles from "./filter-input-number.module.scss";

const optionLabels: Record<Comparison, string> = {
  "": "=",
  gt: ">",
  lt: "<",
  ge: ">=",
};

const options = Object.entries(optionLabels).map(([value, label]) => ({ value, label }));

export function FilterInputDate({ attribute }: CommonFilterInputProps) {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => selectDateFilter(state, attribute));
  const type = filter?.type ?? "";
  return (
    <div className="d-flex">
      <Select
        name="comparison"
        options={options}
        value={type}
        onChange={(e) => {
          dispatch(dateComparisonChanged(attribute, e.target.value as Comparison));
        }}
        className={styles.select}
      />
      <DatePicker
        value={filter?.value ?? ""}
        onChange={(_, v) => {
          dispatch(dateFilterChanged(attribute, v));
        }}
        className={styles.input}
      />
    </div>
  );
}
