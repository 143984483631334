import type { ThunkAction, UnknownAction } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import promise from "redux-promise";

import rootReducer from "./slices";
import { listenerInstance } from "@/middleware/listener";
import { hydrated } from "@/slices/hydrate";
import type { Persistor } from "redux-persist";
import { persistStore } from "redux-persist";
import { emptyApi } from "./slices/api";
import { actionLogEnhancer } from "@/enhancers/action-log";

let store: AppStore;
export const getStore = () => {
  if (!store) {
    store = makeStore();
    getPersistor(store);
  }

  if (typeof window !== "undefined" && window.__HYDRATION_INFO) {
    store.dispatch(hydrated(window.__HYDRATION_INFO));
    delete window.__HYDRATION_INFO;
  }

  return store;
};

const persistorMap = new WeakMap<AppStore, Persistor>();

export const getPersistor = (store: AppStore) => {
  let cached = persistorMap.get(store);
  if (!cached) {
    cached = persistStore(store);
    persistorMap.set(store, cached);
  }
  return cached;
};

export function makeStore(preloadedState?: Parameters<typeof rootReducer>[0]) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false /* TODO: find all the code that mutates state using this check :( */,
        serializableCheck: {
          ignoreActions: true /* our state is serializeable, but we dispatch AxiosError instances in actions */,
        },
      })
        .prepend(listenerInstance.middleware)
        .concat(emptyApi.middleware, promise),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().prepend(actionLogEnhancer),
  });
}

export default makeStore;

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, undefined, UnknownAction>;
