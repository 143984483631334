import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { statusLabel } from "../StatusLabel";

const _resolveClass = (hasChild, isExpanded) => {
  if (!hasChild) {
    return "m-r20";
  }

  if (!isExpanded) {
    return "fa fa-chevron-circle-up text-muted fa-2x m-r10 cursor-pointer";
  }

  return "fa fa-chevron-circle-down text-dark fa-2x m-r10 cursor-pointer";
};

const _showChild = (activity, setShownChilds, isExpanded) => {
  setShownChilds((state) => {
    if (isExpanded) {
      state = state.filter((act) => act != activity.id);
    } else {
      state.push(activity.id);
    }
    return [...state];
  });
};

const ActivitySubject = ({ activity, activeId, depth, childToggle, hasChild }) => {
  const isExpanded = childToggle.shownChilds.includes(activity.id);

  return (
    <td className="title activity-subject table-cell-ellipsis">
      <i
        style={{
          marginLeft: depth * 10 + "px",
        }}
        className={_resolveClass(hasChild, isExpanded)}
        onClick={(e) => _showChild(activity, childToggle.setShownChilds, isExpanded)}
      ></i>
      <a target="_blank" href={"/srm/activities?show_activity=" + activity.id}>
        {activeId === activity.id ? <strong>{activity.subject}</strong> : activity.subject}
      </a>
    </td>
  );
};

ActivitySubject.propTypes = {
  activity: PropTypes.object.isRequired,
  activeId: PropTypes.number.isRequired,
  depth: PropTypes.number,
  childToggle: PropTypes.object.isRequired,
  hasChild: PropTypes.bool,
};

ActivitySubject.defualtProps = {
  depth: 0,
  hasChild: false,
};

const ActivityDate = ({ activity }) => {
  return (
    <td className="activity-due-date">
      <span className="text-gray-dark">{activity.due_date ? moment(activity.due_date).format("D MMMM YYYY") : ""}</span>
    </td>
  );
};

ActivityDate.propTypes = {
  activity: PropTypes.object.isRequired,
};

const ActivityLabel = ({ activity, translations }) => {
  return (
    <td className="activity-status">
      <span className="font-s12 label" style={{ backgroundColor: statusLabel(activity.current_state) }}>
        {translations[activity.current_state]}
      </span>
    </td>
  );
};

ActivityLabel.propTypes = {
  activity: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
};

const Row = ({ activity, depth, isChild, translations, childActivities, activeId, childToggle }) => {
  const children = childActivities.filter((child) => child.parent_id == activity.id);

  return (
    <React.Fragment key={"tab-table-fragment-" + activity.id}>
      <tr
        className={(isChild ? "bg-grey" : "bg-white") + " activity activity-my_activities"}
        key={"tab-table-row-" + activity.id}
      >
        <ActivitySubject
          activity={activity}
          activeId={activeId}
          depth={depth}
          childToggle={childToggle}
          hasChild={!!children.length}
        />
        <ActivityDate activity={activity} />
        <ActivityLabel activity={activity} translations={translations} />
      </tr>
      {!childToggle.shownChilds.includes(activity.id) &&
        children.map((child) => (
          <Row
            key={"tab-table-row-child-" + child.id}
            activity={child}
            depth={depth + 1}
            translations={translations}
            childActivities={childActivities}
            childToggle={childToggle}
            activeId={activeId}
          />
        ))}
    </React.Fragment>
  );
};

Row.propTypes = {
  activity: PropTypes.object.isRequired,
  depth: PropTypes.number,
  isChild: PropTypes.bool,
  translations: PropTypes.object.isRequired,
  activeId: PropTypes.number.isRequired,
  childToggle: PropTypes.object.isRequired,
};

Row.defualtProps = {
  depth: 0,
  isChild: false,
};

export default Row;
export { ActivityDate, ActivityLabel, ActivitySubject };
