import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";

import { uploadTemplate } from "../../../actions/lotActions";
import Tooltip from "../../common/Tooltip";
import { disableButton, isIE } from "../common";
import classNames from "classnames";
import actionsTypes from "../../../actions/actionsTypes";

const UploadLotsForm = (props) => {
  const dispatch = useDispatch();
  const [lotTemplate, setLotTemplate] = useState(null);
  const [uploading, setUploading] = useState(false);
  const refBtn = useRef(null);
  const { translations, event, modalIsOpen, setModalIsOpen } = props;
  const { lots_csv } = translations.infos;

  const handleFileUpload = (event) => {
    setLotTemplate(event.target.files[0]);
    document.getElementById(`inputfileLabel`).innerHTML = event.target.value.split("\\").pop();
  };

  const handleFormSubmit = (ev) => {
    ev.preventDefault();
    disableButton(refBtn.current, translations.uploading);

    const reader = new FileReader();
    let fileObj = {};
    reader.readAsDataURL(lotTemplate);
    reader.onload = () => {
      const obj = {};
      obj.name = lotTemplate.name;
      obj.source = reader.result;
      obj.file_type = lotTemplate.type;
      fileObj = obj;
      dispatch(uploadTemplate({ csv: fileObj }, event.id))
        .then((response) => {
          setModalIsOpen(false);
        })
        .catch((err) => {
          dispatch({ type: actionsTypes.ERROR, error: err.message });
          setModalIsOpen(false);
        });
    };
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      className={classNames({
        "modal-content col-md-4 col-sm-4 custom-field-popup": true,
        "modal2-ie": isIE(),
      })}
      appElement={document.getElementById("advanced-lots-body")}
    >
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <div className="block block-transparent remove-margin-b">
          <div className="block-header bg-primary-default">
            <ul className="block-options">
              <li>
                <button type="button" onClick={() => setModalIsOpen(false)}>
                  <i className="ion-close" />
                </button>
              </li>
            </ul>
            <h3 className="block-title">{translations.upload_lots}</h3>
          </div>

          <div className="block-content lot-fieldset">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="upload_csv_of_lots">
                    {translations.upload_csv_information}
                    &nbsp;
                    <span className="required">*</span>
                    &nbsp;
                    <Tooltip title={lots_csv.title} body={lots_csv.body} />
                  </label>
                  <div className="markup m-t10 upload_lot">
                    <input
                      type="file"
                      name="csv"
                      id="csv"
                      className="inputfile inputfile-6"
                      onChange={(e) => handleFileUpload(e)}
                    />
                    <label htmlFor="csv" className="wid-100" style={{ maxWidth: "100%" }}>
                      <span id="inputfileLabel"></span>{" "}
                      <strong className="float-right">
                        <i className="fa fa-upload"></i>
                      </strong>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="input inline">
              <button
                data-dismiss="modal"
                type="button"
                className={classNames({
                  "btn btn-sm btn-default": true,
                  disabled: uploading,
                })}
                onClick={() => setModalIsOpen(false)}
              >
                {translations.cancel} <i className="fa fa-times" />
              </button>
              <button
                name="button"
                className={classNames({
                  "btn btn-sm btn-primary": true,
                  disabled: !lotTemplate || uploading,
                })}
                type="submit"
                ref={refBtn}
              >
                {uploading ? translations.uploading : translations.upload_lots} <i className="fa fa-check" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default UploadLotsForm;
