import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { useSelector } from "react-redux";

/**
 *
 * @param {object} event
 * @param {boolean} auctionTab
 * @returns {string} downloadLink
 */
const getDownloadLink = (event, auctionTab) => {
  const isRfqTab = event.event_category_rfq && !auctionTab;

  return `/events/${event.id}/lots.xlsx?bid_report=true&bid_upload=true${isRfqTab ? "&report_type=RFQ" : ""}`;
};

/**
 * state selector for DownloadEventReport
 */
const selectDownloadReportState = createSelector(
  (store) => store.lotReducers.event,
  (store) => store.lotReducers.auction_tab,
  (store) => store.lotReducers.translations,
  (event, auctionTab, translations) => {
    const {
      download_event_report,
      infos: {
        participant_bid_report: { title, body },
      },
    } = translations;

    return { event, auctionTab, download_event_report, title, body };
  }
);

const DownloadEventReport = () => {
  const { event, auctionTab, download_event_report, title, body } = useSelector(selectDownloadReportState);

  return (
    <div className="pull-right" style={{ marginBottom: "20px" }}>
      <div className="m-t10 pull-left push-5-r">
        <i
          className="ion-ios-information-outline position-left "
          data-toggle="popover"
          data-placement="right"
          data-title={title}
          data-content={body}
        ></i>
      </div>
      <a
        className="download-event-report pull-right btn btn-default btn-blue-outline"
        href={getDownloadLink(event, auctionTab)}
      >
        {download_event_report}
      </a>
    </div>
  );
};

export default DownloadEventReport;
