import React from "react";
import JapaneseEvent from "./JapaneseEvent";
import LotLevelEvent from "./LotLevelEvent";
import RankedEvent from "./RankedEvent";
const ParticipantMonitorTab = (props) => {
  const { event, isEventCompleted, bestBids } = props;
  let setLotStateCb = {};
  const isLotLevelAuction = event.event_type !== "Japanese" && !event.bid_at_detail_level;
  const isJapaneseAuction = event.event_type === "Japanese";
  const isRankedMonitor = event.event_type === "Ranked";
  const showMonitorTab = () => {
    switch (true) {
      case isJapaneseAuction:
        return (
          <JapaneseEvent
            {...props}
            isRankedMonitor={isRankedMonitor}
            isEventCompleted={isEventCompleted}
            setLotStateCb={setLotStateCb}
          />
        );
      case isLotLevelAuction:
        return <LotLevelEvent {...props} isRankedMonitor={isRankedMonitor} isEventCompleted={isEventCompleted} />;
      default:
        return (
          <RankedEvent
            {...props}
            isRankedMonitor={isRankedMonitor}
            isEventCompleted={isEventCompleted}
            setLotStateCb={setLotStateCb}
            bestBids={bestBids}
          />
        );
    }
  };

  return showMonitorTab();
};

export default ParticipantMonitorTab;
