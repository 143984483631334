import classNames from "classnames";
import React, { Fragment, useEffect } from "react";
import {
  bidValue,
  differenceOfferedForBid,
  differenceOfferedPercentageForBid,
  differenceOfferedWithCurrency,
  rankForParticipant,
  showBid,
  timedOutBid,
} from "../../../../../bidCommon";
import LotBidComponent from "../../../../../bids/LotBidComponent";
import {
  eventParticipantStatus,
  findLotTotalLic,
  findUser,
  formatPercentileNumber,
  showCurrency,
} from "../../../../../common";
import { deadlinesInThePast, hideRankIfRunningRfq } from "../../../../../eventCommon";
import { statusForBids } from "../../../../../eventParticipantCommon";
import { ProxyBidButtonOrStatusDuringAuction } from "./proxyBidButtonOrStatusDuringAuction";
import DeleteBidButton from "./delete-bid-button";

export const showCurrencyValue = (value, props, precision = 2) => {
  const { translations, lotCurrency, locale } = props;
  return showCurrency(value, lotCurrency, precision, locale, translations.number_format);
};

export const ProxyBidButtonOrStatus = (props) => {
  const { accepted, participating } = props.participant;
  const { latestBid, event, translations, lot, currency, allUnitSets } = props;
  const isAcceptedbyParticipant = accepted && [null, true].includes(participating);
  const isEventClosed = ["completed", "closed"].includes(event.current_state);
  const islatestBid = !latestBid || (latestBid && !latestBid.decline_bid);
  const isEventRunning = !lot.is_event_total && !isEventClosed && islatestBid && !deadlinesInThePast(event);

  if (isAcceptedbyParticipant && isEventRunning)
    return <LotBidComponent {...props} licCurrency={currency} isProxyBid={true} unitSets={allUnitSets} />;
  else if (accepted) return translations.event_participant_pending_your_acceptance;
  return null;
};

export const BidStatusRow = ({ status, rowData, translations }) => {
  return (
    <>
      <td
        className={classNames({
          "red-color": status === "Declined",
        })}
      >
        {status === "-" ? status : translations[status]}
      </td>
      {rowData.map((data, index) => (
        <td key={index}>{data}</td>
      ))}
    </>
  );
};
BidStatusRow.defaultProps = {
  rowData: [],
};

export const GetBeforeAuctionText = (props) => {
  if (props.beforeAuction) return "-";
  else return <ProxyBidButtonOrStatusDuringAuction {...props} rejectedLots={props.rejectedLots} />;
};

export const BeforeAuctionRow = (props) => {
  const currencyText = Array(3).fill(showCurrencyValue("-", props));
  if (props.beforeAuction && !props.preAuctionRanksIntact) {
    const beforeAuctionText = <GetBeforeAuctionText {...props} />;
    return (
      <BidStatusRow
        status={eventParticipantStatus(props.participant)}
        translations={props.translations}
        rowData={[...currencyText, "-%", beforeAuctionText, ""]}
      />
    );
  } else {
    const bidStatus = statusForBids(props.participant);
    const beforeAuctionText = props.beforeAuction ? (
      <ProxyBidButtonOrStatus {...props} rejectedLots={null} />
    ) : (
      <ProxyBidButtonOrStatusDuringAuction {...props} key="rejectedLot" />
    );
    return (
      <BidStatusRow
        status={bidStatus}
        translations={props.translations}
        rowData={[...currencyText, "-", beforeAuctionText, ""]}
      />
    );
  }
};

export const RankColumn = ({ lot, participant, latestBid, weightedRfqOrAuction, setRank }) => {
  const typeOfRank = weightedRfqOrAuction ? "weightedScore" : "price";
  const rankValue = rankForParticipant(latestBid, typeOfRank, participant);
  const rankedClass = `ranked${rankValue}`;
  useEffect(() => {
    return () => setRank(rankValue);
  }, []);
  return (
    <td key={`${typeOfRank}-${Date.parse(lot.updated_at)}`}>
      <span
        className={classNames({
          "blic-rank-symbol badge": Number.isInteger(rankValue),
          "red-color": !Number.isInteger(rankValue),
          [rankedClass]: true,
        })}
      >
        {rankValue}
      </span>
    </td>
  );
};
RankColumn.defaultProps = {
  lot: {},
};

export const ConditionalElse = ({ setRank, ...rest }) => {
  const ePStatus = eventParticipantStatus(rest.participant);
  const gaurdClause = !rest.beforeAuction || rest.preAuctionRanksIntact;
  const isRankHidden = hideRankIfRunningRfq(rest.event, rest.eventPassedFinalDeadline);
  if (gaurdClause) return <RankColumn key={ePStatus} {...rest} setRank={setRank} />;
  else if (isRankHidden)
    return (
      <td key={`${Date.parse(rest.lot.updated_at)}`} style={{ textAlign: "center" }}>
        -
      </td>
    );
  return <td key={`${ePStatus}-${Date.parse(rest.lot.updated_at)}`}>{rest.translations[ePStatus]}</td>;
};

export const bidDetails = (props, precision) =>
  showBid(props.latestBid, props.lot, props.lotCurrency, props.locale, "host", { ...props }, precision);

export const currencyDiffercenceDetail = (props) =>
  differenceOfferedWithCurrency(
    differenceOfferedForBid(props.latestBid, props.lot, props.event),
    props.lotCurrency,
    props.locale,
    props.translations.number_format,
    props.precision
  );

export const percentileFormatDetail = (props) =>
  formatPercentileNumber(differenceOfferedPercentageForBid(props.latestBid, props.lot, props.event), props.locale);

export const deleteButtonDetail = (props) =>
  props.latestBid &&
  props.permittedToEdit &&
  !timedOutBid(props.latestBid) &&
  props.canShowDeleteBtn &&
  !props.lot.is_event_total ? (
    <DeleteBidButton {...{ ...props, bidToDelete: props.latestBid }} />
  ) : null;

export const UnConditionalElse = (props) => {
  const { latestBid, lot, beforeAuction, lineItemComponents } = props;
  const totalLic = findLotTotalLic(lineItemComponents, lot.lot_total_lic_id);
  const precision = totalLic && totalLic.decimal_place ? totalLic.decimal_place : 2;

  const rowData = [
    bidDetails(props, precision),
    showCurrencyValue(bidValue(latestBid, lot, "host"), props, precision),
    currencyDiffercenceDetail(props),
    percentileFormatDetail(props),
    beforeAuction ? null : <ProxyBidButtonOrStatusDuringAuction {...props} />,
    deleteButtonDetail(props),
  ];

  return (
    <Fragment key={`show-bid-${Date.parse(lot.updated_at)}`}>
      {rowData.map((data, index) => (
        <td className="table-cell-ellipsis" key={`show-bid-${index}`}>
          {data}
        </td>
      ))}
    </Fragment>
  );
};

export const RejectedLotRow = (props) => {
  const dash = Array(3).fill("-");
  const beforeAuctionText = props.beforeAuction ? "-" : <ProxyBidButtonOrStatusDuringAuction {...props} />;
  const deleteButtonText =
    props.permittedToEdit && props.canShowDeleteBtn && !props.lot.is_event_total ? (
      <DeleteBidButton {...{ ...props, bidToDelete: props.rejectedLots[0] }} />
    ) : null;

  const host = props.latestBid && props.latestBid.host_id && findUser(props.hosts, props.latestBid.host_id);
  return (
    <BidStatusRow
      status={props.translations[statusForBids(props.participant)]}
      key="rejectedLots"
      translations={props.translations}
      rowData={[
        [
          props.translations.lot_rejected,
          host && (
            <strong title={props.translations.proxy_bid + " " + host.name} className="proxy_bid">
              P
            </strong>
          ),
        ],
        ...dash,
        beforeAuctionText,
        deleteButtonText,
      ]}
    />
  );
};
