import { BaseController } from "./controller_base";
import { t } from "@/i18n";

export default class extends BaseController {
  static targets = ["item"];

  setLinkLoadingText = (event: Event) => {
    if (event.currentTarget instanceof HTMLElement) {
      event.currentTarget.textContent = t("qq_translations.loader_text");
    }

    this.getTargetList("itemTargets").forEach((item) => {
      if (item instanceof HTMLButtonElement || item instanceof HTMLAnchorElement) {
        item.toggleAttribute("disabled");
      }
    });
  };
}
