import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import type { AppStore } from "@/store";
import { getPersistor } from "@/store";

const WrapperPropTypes = {
  /**
   * The redux store we want to use in this react rapper.
   */
  store: PropTypes.object.isRequired,
  /**
   * What is says on the tin
   */
  children: PropTypes.node.isRequired,
};

const Wrapper = ({ children, store }: PropTypes.InferProps<typeof WrapperPropTypes> & { store: AppStore }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={getPersistor(store)}>{children}</PersistGate>
    </Provider>
  );
};

Wrapper.propTypes = WrapperPropTypes;

export default Wrapper;
