import PropTypes from "prop-types";

export const CustomFieldItemType = {
  active: PropTypes.bool,
  created_at: PropTypes.string,
  custom_column_id: PropTypes.number,
  id: PropTypes.number,
  include_in_invite: PropTypes.bool,
  item_type: PropTypes.string,
  required: PropTypes.bool,
  show_in_summary: PropTypes.bool,
  updated_at: PropTypes.string,
};

export const CustomFieldOption = {
  active: PropTypes.bool,
  created_at: PropTypes.string,
  custom_field_id: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  updated_at: PropTypes.string,
};

export const ColumnTypeCD = Object.freeze({
  TEXT: 0,
  NUMERIC: 1,
  DATE: 2,
  CHECKBOX: 3,
  PICK_ONE_FROM_LIST: 4,
  MULTIPLE_CHOICE: 5,
});

export const CustomField = {
  column_type_cd: PropTypes.oneOf(Object.values(ColumnTypeCD)),
  created_at: PropTypes.string,
  id: PropTypes.number,
  item_types: PropTypes.arrayOf(PropTypes.shape(CustomFieldItemType)),
  options: PropTypes.arrayOf(PropTypes.shape(CustomFieldOption)),
  position: PropTypes.number,
  updated_at: PropTypes.string,
  name: PropTypes.string,
};

/**
 * @typedef {PropTypes.InferProps<typeof CustomField>} CustomFieldType
 */

export default CustomField;
