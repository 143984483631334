import React from "react";
import CommonModal from "../../../../../hoc/modalHOC/CommonModal";
import Tooltip from "../../../../common/Tooltip";

const LotDetailModal = (props) => (
  <CommonModal
    viewComponent={props.viewComponent}
    header={`${props.translations.lot}: ${props.lot.name}`}
    content={displayContent(props)}
    modalCloseButtonText={props.translations.ok}
    modalCloseButtonClass="btn btn-sm btn-default"
  />
);

export default LotDetailModal;

export const displayContent = (props) => {
  const { translations, lot, currentValue, qualificationValue } = props;
  const { lot_lot_name, lot_current_value, lot_qualification_value } = translations.infos;
  return (
    <>
      <div className="flash list-bullet notice alert alert-info m-t10">{translations.lot_notes}</div>

      <div className="form-group">
        <ul className="list-bullet lot-information">
          <li>
            <b>{translations.lot_name} : </b>
            {lot.name}
            <Tooltip title={lot_lot_name.title} body={lot_lot_name.body} />
          </li>
          <li>
            <b>{translations.current_value_in_popup} : </b>
            {currentValue}
            <Tooltip title={lot_current_value.title} body={lot_current_value.body} />
          </li>
          <li>
            <b>{translations.qualification_value_nobr} : </b>
            {qualificationValue}
            <Tooltip title={lot_qualification_value.title} body={lot_qualification_value.body} />
          </li>
        </ul>
      </div>
    </>
  );
};
