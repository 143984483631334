import { createEntityAdapter } from "@reduxjs/toolkit";

export interface BaseField {
  field: string;
  id: string;
}

export interface NumberField extends BaseField {
  type: "numeric";
}

export interface DateField extends BaseField {
  type: "date";
}

export interface CheckboxField extends BaseField {
  type: "checkbox";
}

export interface PicklistField extends BaseField {
  type: "pick_one_from_list";
  options: Array<string>;
}

export interface MultiChoiceField extends BaseField {
  type: "multiple_choice";
  options: Array<string>;
}

export type Field = NumberField | DateField | CheckboxField | PicklistField | MultiChoiceField;

export const fieldAdapter = createEntityAdapter<Field>();

export const {
  selectAll: selectAllFields,
  selectById: selectFieldById,
  selectIds: selectFieldIds,
  selectEntities: selectFieldEntities,
  selectTotal: selectTotalFields,
} = fieldAdapter.getSelectors();
