import { createSelector } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";

/* eslint-disable-next-line no-unused-vars */
import { LineItemComponent } from "@/common-prop-types/line-item-component";

/**
 * Selects all of the line item components that are formula components
 */
export const selectFormulaComponents = createSelector(
  (store) => store.lotReducers.line_item_components,
  (lineItemComponents) => lineItemComponents?.filter((lineItem) => lineItem.formula !== null) || []
);

/**
 * Hook that will return all of the formula line item component that are
 * affected if another line item component changes, selected buy its tag.
 * Indirect dependencies will be picked up one level deep and in some cases
 * more.
 *
 * @returns {import('prop-types').InferProps<LineItemComponent>[]}
 */
export const useAffectedFormulas = (tag) => {
  const formulaComponents = useSelector(selectFormulaComponents);

  return useMemo(() => {
    const tags = [tag];
    return formulaComponents.filter((component) => {
      const hasTag = tags.findIndex((tag) => component.formula.includes(tag)) > -1;
      if (hasTag) {
        tags.push(component.tag);
      }

      return hasTag;
    });
  }, [tag, formulaComponents]);
};

/**
 * Gets all of the values for a pick list from the pickListId. The values are
 * mapped so they can be use in a dropdown list.
 *
 * @returns {String[]}
 */
export const usePickListOptions = (pickListId) => {
  const options = useSelector((store) => store.lotReducers.picklist_options);
  return useMemo(() => {
    return options.filter((option) => option.picklist_id === pickListId).map((option) => option.value);
  }, [options, pickListId]);
};

/**
 * Gets all of the unit of measures and unit sets mapped so we can easily use
 * them. All measures and sets are keyed by there ID's for better access and
 * set options are nested under the `options` prop. The measures also have a
 * empty `options` array nested under them to prevent undefined errors
 */
export const selectUnitOfMeasures = createSelector(
  (store) => store.lotReducers.unit_sets || [],
  (store) => store.lotReducers.unit_set_unit_of_measures || [],
  (store) => store.lotReducers.unit_of_measures || [],
  (unitSets, unitSetMeasures, unitOfMeasures) => {
    const sets = unitSets.reduce((current, unitSet) => {
      // Need to copy the unitSet to avoid manipulating the global state value
      // in the store.
      current[unitSet.id] = { ...unitSet };
      current[unitSet.id].options = unitSetMeasures.filter((measures) => unitSet.id === measures.unit_set_id);
      return current;
    }, {});

    const measures = unitOfMeasures.reduce((current, measure) => {
      return {
        ...current,
        [measure.id]: { ...measure, options: [] },
      };
    }, {});

    return { sets, measures };
  }
);

/**
 * Gets all of the currency info in the store. Mapped and stored so we can
 * easily get out the correct currency a bid is in.
 *
 * @example
 * const eventCurrencyInfo = useSelector(selectEventCurrencyInfo);
 * const exchangeRateId = bidLineItemComponent?.exchange_rate_id || lineItemComponent.exchange_rate_id;
 * const currency = eventCurrencyInfo.currenciesHash[exchangeRateId] || eventCurrencyInfo.currency;
 */
export const selectEventCurrencyInfo = createSelector(
  (store) => store.lotReducers.event?.multi_currency_event || false,
  (store) => store.lotReducers.event_currency,
  (store) => store.lotReducers.currencies_hash || {},
  (isMultiCurrencyEvent, currency, currenciesHash) => {
    return {
      isMultiCurrencyEvent,
      currency,
      currenciesHash,
    };
  }
);

/**
 * Get the error for an individual line item component. Undefined will be
 * returned if there is no error linked to a line item component.
 *
 * @returns {String | undefined}
 */
export const useLineItemComponentError = (lotId, lineItemComponentId) => {
  const errors = useSelector((state) => state.lotReducers.lotItemComponentErrors);
  return errors?.[lotId]?.[lineItemComponentId]?.messages?.[0];
};

/**
 * Get the warning for an individual line item component. Undefined will be
 * returned if there is no warning linked to a line item component.
 *
 * @returns {String | undefined}
 */
export const useLineItemComponentWarning = (lotId, lineItemComponentId) => {
  const warnings = useSelector((state) => state.lotReducers.lineItemComponentWarnings);
  return warnings?.[lotId]?.[lineItemComponentId]?.message;
};

/**
 * Hook to get the current status for a lot. It will also return a boolean
 * `isPending` this will be true if any of the states are in the pending state,
 * it will use the `pending:` naming convention.
 *
 * @returns {{ lotStatus: string, isPending: boolean }}
 */
export const useLotStatus = (lotId) => {
  const allLotStatus = useSelector(({ lotReducers }) => lotReducers.lotStatus);
  const lotStatus = allLotStatus?.[lotId] || "viewing";
  const isPending = lotStatus.startsWith("pending:");

  return { lotStatus, isPending };
};
