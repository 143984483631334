import React from "react";
import {
  showRankOfCell,
  fetchAssociatedBlicOrBidCurrency,
  timeOutOrDeclinedBidPrice,
  timedOutBid,
  findLicBidBlic,
} from "../../../../bidCommon";
import {
  findLIC,
  hideLic,
  fetchAssociatedCurrency,
  findUnitSet,
  roundBidControls,
  showCurrency,
  bidRoundPrice,
  findLotTotalLic,
} from "../../../../common";

import moment from "moment";
import classNames from "classnames";
import { rankForParticipant } from "../../../../bidCommon";
import { isWeightedRfqOrAuction } from "../../../../eventCommon";
import { eventParticipantStatus } from "../../../../common";
import ShowSingleBidLineItemComponent from "../../ShowSingleBidLineItemComponent";
import { useSelector } from "react-redux";
import features from "@/utils/features";

export const GetCellValue = (props) => {
  const { component, item } = props;
  const lic = findLIC(component, item, props.lineItemComponents);
  const isHideLic = hideLic(lic);

  // return if lic ishidden or not present
  if (!lic || isHideLic) return <></>;
  return <CellBlicValue {...props} lic={lic} />;
};

const CellBlicValue = (props) => {
  const { bid, lot, lic, role, auctionTab, component, item } = props;
  const { allBlics } = useSelector(({ lotReducers }) => lotReducers);

  // get all blics
  const overallBlics = props.bidBlics ?? allBlics ?? props.bidLineItemComponents;

  // get blic for lic
  const blicFilter = ({ line_item_component_id, bid_id }) => line_item_component_id === lic?.id && bid?.id === bid_id;
  const blic = overallBlics?.find(blicFilter);

  const { unitSet, licCurrency, blicCurrency } = getCurrencyForLic({ ...props, blic });

  const cellRank = showRankOfCell(props.event, blic, bid, lic, component, lot, role, false, auctionTab);
  const header = `${props.translations.current_bids}: ${item.name}`;

  return (
    <>
      <span className={`bid_blic ${cellRank ? "" : "no-cell-rank"}`} title={cellRank ?? ""}>
        <ShowSingleBidLineItemComponent
          {...props}
          edit={false}
          licCurrency={licCurrency}
          blic={blic}
          lotComponent={component}
          lineItem={item}
          blicCurrency={blicCurrency}
          unitSet={unitSet}
          preferred={"participant"}
          isCompleteDetailed={true}
          header={header}
          lineItemLic={lic}
        />
      </span>
      &nbsp;
      {cellRank}
    </>
  );
};

const getCurrencyForLic = (props) => {
  const { lic, allUnitSets, currenciesHash, lot, component, bid, blic, currency } = props;
  let unitSet, licCurrency, blicCurrency;
  if (lic) unitSet = findUnitSet(allUnitSets, lic.unit_set_id);
  if (props.isMultiCurrencyEvent) {
    licCurrency = fetchAssociatedCurrency(currenciesHash, lot, lic, component);
    blicCurrency = fetchAssociatedBlicOrBidCurrency(currenciesHash, bid, blic);
  } else {
    licCurrency = currency;
    blicCurrency = currency;
  }

  return { unitSet, licCurrency, blicCurrency };
};

export const getChildElement = (props) => {
  const { bid, beforeAuction, event, translations, participant, isJapaneseEvent } = props;
  const isWegRfqOrAuction = isWeightedRfqOrAuction(event);

  const displayRank =
    (beforeAuction && !event.start_at ? true : moment() < moment(event.start_at)) ||
    (!beforeAuction && (!event.start_at ? true : moment() > moment(event.start_at))) ||
    (!beforeAuction && (!event.start_at ? true : moment() < moment(event.start_at)))
      ? true
      : false;

  const getFirstValue = () => {
    if (displayRank) {
      if (event.event_type == "Japanese" && !beforeAuction) {
        {
          return translations.bid_round;
        }
      } else {
        if (isWeightedRfqOrAuction(event)) {
          {
            return translations.weighted_rank;
          }
        } else {
          {
            return translations.rank;
          }
        }
      }
    } else if (beforeAuction && !event.start_at ? true : moment() > moment(event.start_at)) {
      {
        return translations.user_status;
      }
    }
  };

  const getSecondValue = () => {
    const epStatus = eventParticipantStatus(participant);
    if (displayRank) {
      if (isJapaneseEvent && !beforeAuction) {
        {
          return bid.bid_round;
        }
      } else if (isWegRfqOrAuction) {
        if (!bid.rejected_lot && epStatus != "Declined") {
          let typeOfRank = isWegRfqOrAuction ? "weightedScore" : "price";
          let rankValue = rankForParticipant(bid, typeOfRank, participant);
          return (
            <span
              className={`${Number.isInteger(rankValue) ? "blic-rank-symbol badge " : "red-color"}ranked${rankValue}`}
            >
              {rankValue}
            </span>
          );
        } else if (epStatus === "Declined") {
          return (
            <span
              className={classNames({
                "red-color": epStatus === "Declined",
              })}
            >
              {translations.Declined}
            </span>
          );
        } else {
          return <> &nbsp;</>;
        }
      } else {
        if (!bid.rejected_lot && epStatus !== "Declined") {
          let typeOfRank = isWegRfqOrAuction ? "weightedScore" : "price";
          let rankValue = rankForParticipant(bid, typeOfRank, participant);
          return (
            <span
              className={`${Number.isInteger(rankValue) ? "blic-rank-symbol badge" : "red-color"} ranked${rankValue}`}
            >
              {rankValue}
            </span>
          );
        } else {
          return (
            <span
              className={classNames({
                "red-color": epStatus === "Declined",
              })}
            >
              {translations[epStatus]}
            </span>
          );
        }
      }
    } else if (beforeAuction && (!event.start_at ? true : moment() > moment(event.start_at))) {
      return translations[epStatus];
    }
  };

  if (
    bid.rank ||
    (beforeAuction && !event.start_at ? true : moment() > moment(event.start_at)) ||
    event.event_type == "Japanese"
  ) {
    return (
      <tr className={"lot-info"}>
        <td>{getFirstValue()}</td>
        <td>
          <strong>{getSecondValue()}</strong>
        </td>
      </tr>
    );
  }
};

export const getTdValue = (props) => {
  const {
    bid,
    event,
    translations,
    lot,
    auctionControls,
    currency,
    locale,
    host,
    user,
    bidControls,
    lineItemComponents,
    bidLineItemComponents,
  } = props;
  let result;
  const totalLic = findLotTotalLic(lineItemComponents, lot.lot_total_lic_id);
  const precision = totalLic && totalLic.decimal_place ? totalLic.decimal_place : 2;

  if (bid.rejected_lot || bid.decline_bid || timedOutBid(bid)) {
    if (bid.rejected_lot) {
      result = translations.lot_rejected;
    } else {
      result = timeOutOrDeclinedBidPrice(lot, event, bid, auctionControls, currency, locale, translations);
    }
  } else {
    if (bidControls.length > 0 && !roundBidControls(bidControls, bid.bid_round, user)) {
      result = `${translations.reaccepted_bid_level} (${translations.at} ${showCurrency(
        bidRoundPrice(lot, event, bid.bid_round, auctionControls) || "-",
        currency,
        precision,
        locale,
        translations.number_format
      )}`;
    } else {
      const bidOrBlic = features.enabled("show_total_blic_price")
        ? findLicBidBlic(bidLineItemComponents, totalLic.id, bid.id)
        : bid;

      result = showCurrency(bidOrBlic.price, currency, precision, locale, translations.number_format);
    }
    return (
      <>
        {result}
        {host ? (
          <span className="proxy_bid" title={`${translations.proxy_bid} ${host.name}`}>
            P
          </span>
        ) : null}
      </>
    );
  }
};
