import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import { makeApiCall } from "./commonActions";
import { getError } from "./errorActions";

const updateLineItemComponentSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_LINE_ITEM_COMPONENT,
    payload: {
      lineItemComponents: data.line_item_components,
      lot: data.lot,
      unitOfMeasure: data.unit_of_measure,
      lineItems: data.line_items,
      lotComponents: data.lot_components,
      lotBids: data.lot_bids,
      updateReferenceMessage: data.update_reference_message,
    },
  };
};

const editLineItemComponentSuccess = (data) => {
  return {
    type: actionTypes.EDIT_LINE_ITEM_COMPONENT,
    payload: {
      lineItemComponent: data.line_item_component,
      licPicklistOptions: data.lic_picklist_options,
      unitOfMeasures: data.unit_of_measures,
    },
  };
};

const showSuccess = (successMessage) => {
  if (successMessage === undefined || !(successMessage.length > 1)) {
    return { type: "NA" };
  } else {
    return { type: actionTypes.SUCCESS, success: successMessage };
  }
};

export const updateLineItemComponent = (lotComponentId, lineItemComponentId, payload) => async (dispatch) =>
  await apiCall
    .put(`${config.V2_BASE_URL}/lot_components/${lotComponentId}/line_item_components/${lineItemComponentId}`, payload)
    .then((response) => {
      dispatch(updateLineItemComponentSuccess(response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const editLineItemComponent = (lotComponentId, lineItemComponentId) => (dispatch) =>
  makeApiCall(
    "get",
    `${config.V2_BASE_URL}/lot_components/${lotComponentId}/line_item_components/${lineItemComponentId}/edit`,
    editLineItemComponentSuccess,
    dispatch
  );
