import React from "react";
import BestBidOrOverallScore from "./BestBidOrOverallScore";
import { formatPercentileNumber } from "../../../common";

/**
 * @description Participant Overall Score for Lot
 * Monitor Tab
 *
 * Column - Overall Score
 */
export default function OverallScore(props) {
  const { translations, lot, locale, bestBidBeforeAuction, uom } = props;
  const showOverallScore = true;
  const bestBidValue = !isNaN(bestBidBeforeAuction?.weighted_score)
    ? Number(bestBidBeforeAuction.weighted_score?.toFixed(2))
    : "%";
  const viewComponent = formatPercentileNumber(bestBidValue, locale);
  const header = `${translations.best_overall_score}: ${lot?.name}`;
  return (
    <BestBidOrOverallScore
      {...props}
      uom={uom}
      showOverallScore={showOverallScore}
      viewComponent={viewComponent}
      header={header}
    />
  );
}
