import { createAppSlice } from "@/slices/util/createAppSlice";
import { safeAssign, toggleAllKeys, toggleInArray, toggleKey } from "@/utils";
import { hydrated } from "@/slices/hydrate";
import type { RootState } from "@/store";
import { withCustomCreators } from "@/slices/util/customCreators";
import {
  contractSelectors,
  contractAdapter,
  columnSelector,
  contractSelector,
  columnOptionsSelector,
  selectionLengthSelector,
  selectionStateSelector,
  customColumnAdapter,
  filteredContractsSelector,
  selectFilters,
  makeFilterSelector,
} from "./selectors";
import { filterContracts, toggleArchived } from "./actions";
import type { KeysOfType } from "@/types/util";
import { persistSlice } from "@/slices/util/persistSlice";
import pick from "lodash/pick";
import type { Comparison, FilterMap, Filters, ListingState } from "@/slices/contracts/listing/types";
import { ColumnType } from "@/slices/contracts/listing/types";

const emptyFilters: Filters = {
  [ColumnType.Text]: {},
  [ColumnType.Numeric]: {},
  [ColumnType.Date]: {},
  [ColumnType.Checkbox]: {},
  [ColumnType.SinglePick]: {},
  [ColumnType.MultiPick]: {},
};

const initialState: ListingState = contractAdapter.getInitialState({
  search: "",
  showArchived: false,
  canCreateContract: false,
  contractColumns: {
    data: {},
  },
  customColumns: customColumnAdapter.getInitialState(),
  customColumnValues: {},
  selected: {},
  filteredIds: undefined,
  filters: emptyFilters,
  customFilters: emptyFilters,
  pagination: {
    page: 1,
    perPage: 10,
  },
});

export const listingsSlice = createAppSlice({
  name: "contracts/listings",
  reducerPath: "listings",
  initialState,
  reducers: withCustomCreators(
    (create) => ({
      filterChangeReducer: <Type extends ColumnType>(type: Type) =>
        create.preparedReducer(
          (name: string, value: FilterMap[Type], isCustom?: boolean) => ({ payload: { name, value, isCustom } }),
          (state, { payload: { name, value, isCustom } }) => {
            // TS finds this kinda of operation difficult to typecheck
            // but we already know we're checking in the prepare function
            (selectFilters(state, isCustom)[type] as Record<string, unknown>)[name] = value;
          }
        ),
      multiToggleReducer: <Type extends KeysOfType<FilterMap, unknown[]>>(type: Type) =>
        create.preparedReducer(
          (name: string, value: FilterMap[Type][number], isCustom?: boolean) => ({
            payload: { name, value, isCustom },
          }),
          (state, { payload: { name, value, isCustom } }) => {
            const filters = selectFilters(state, isCustom)[type] as Partial<Record<string, FilterMap[Type]>>;
            toggleInArray<FilterMap[Type][number]>((filters[name] ??= []), value);
            if (filters[name].length === 0) delete filters[name];
          }
        ),
    }),
    (create) => ({
      pageChanged: create.reducer<number>((state, { payload }) => {
        state.pagination.page = payload;
      }),
      perPageChanged: create.reducer<number>((state, { payload }) => {
        safeAssign(state.pagination, { perPage: payload, page: 1 });
      }),
      newListingSearch: create.reducer<string>((state, action) => {
        state.search = action.payload;
      }),
      showArchivedToggled: create.reducer<boolean | undefined>((state, { payload = !state.showArchived }) => {
        state.showArchived = payload;
      }),
      selectionToggled: create.reducer<number>((state, { payload }) => toggleKey(state.selected, payload)),
      allSelectionToggled: create.reducer((state) => {
        state.selected = toggleAllKeys(state.selected, state.filteredIds ?? state.ids);
      }),
      textFilterChanged: create.filterChangeReducer(ColumnType.Text),
      numericComparisonChanged: create.preparedReducer(
        (name: string, type: Comparison, isCustom?: boolean) => ({ payload: { name, type, isCustom } }),
        (state, { payload: { name, type, isCustom } }) => {
          (selectFilters(state, isCustom)[ColumnType.Numeric][name] ??= { type: "", value: 0 }).type = type;
        }
      ),
      numericFilterChanged: create.preparedReducer(
        (name: string, value: number, isCustom?: boolean) => ({ payload: { name, value, isCustom } }),
        (state, { payload: { name, value, isCustom } }) => {
          const filters = selectFilters(state, isCustom);
          if (Number.isNaN(value)) {
            delete filters[ColumnType.Numeric][name];
          } else {
            (filters[ColumnType.Numeric][name] ??= { type: "", value: 0 }).value = value;
          }
        }
      ),
      dateComparisonChanged: create.preparedReducer(
        (name: string, type: Comparison, isCustom?: boolean) => ({ payload: { name, type, isCustom } }),
        (state, { payload: { name, type, isCustom } }) => {
          (selectFilters(state, isCustom)[ColumnType.Date][name] ??= { type: "", value: "" }).type = type;
        }
      ),
      dateFilterChanged: create.preparedReducer(
        (name: string, value: string, isCustom?: boolean) => ({ payload: { name, value, isCustom } }),
        (state, { payload: { name, value, isCustom } }) => {
          const filters = selectFilters(state, isCustom);
          if (!value) {
            delete filters[ColumnType.Date][name];
          } else {
            (filters[ColumnType.Date][name] ??= { type: "", value: "" }).value = value;
          }
        }
      ),
      checkboxFilterToggled: create.multiToggleReducer(ColumnType.Checkbox),
      singleFilterChanged: create.filterChangeReducer(ColumnType.SinglePick),
      multiFilterToggled: create.multiToggleReducer(ColumnType.MultiPick),
      filtersReset: create.reducer((state) => {
        safeAssign(state, pick(initialState, "filters", "customFilters", "search", "filteredIds", "showArchived"));
      }),
      listingsReset: create.reducer(() => initialState),
      contractsSearchOrFiltered: create.asyncThunk(filterContracts, {
        fulfilled(state, { payload: { selected_contract_ids } }) {
          listingsSlice.caseReducers.filtersReset(state, filtersReset());
          state.filteredIds = selected_contract_ids;
        },
      }),
      toggleArchivedOrDelete: create.asyncThunk(toggleArchived, {
        fulfilled(state, { payload: { new_state }, meta: { arg } }) {
          if (new_state === "deleted") {
            contractAdapter.removeOne(state, arg);
          } else {
            contractAdapter.updateOne(state, { id: arg, changes: { current_state: new_state } });
          }
        },
      }),
      sortToggled: create.preparedReducer(
        (column: string, isCustom?: boolean) => ({
          payload: { column, isCustom },
        }),
        (state, { payload: { column, isCustom } }) => {
          const { sort } = state;
          if (sort?.column === column && sort.isCustom === isCustom) {
            sort.direction = sort.direction === "asc" ? "desc" : "asc";
          } else {
            state.sort = { column, direction: "asc", isCustom } as never;
          }
        }
      ),
    })
  ),
  extraReducers(builder) {
    builder.addCase(hydrated, (state, action) => {
      const { contractListing } = action.payload;
      if (contractListing) {
        const { contracts, customColumns, ...rest } = contractListing;
        contractAdapter.setAll(state, contracts);
        customColumnAdapter.setAll(state.customColumns, customColumns);
        safeAssign(state, rest);
      }
    });
  },
  selectors: {
    selectCanCreateContract: (state) => state.canCreateContract,
    selectPagination: (state) => state.pagination,
    selectListingSearch: (state) => state.search,
    selectShowArchived: (state) => state.showArchived,
    selectSelectedById: (state, id: number) => !!state.selected[id],
    selectSelectionLength: selectionLengthSelector,
    selectSelectionState: selectionStateSelector,
    selectAllFilters: (state) => state.filters,
    selectTextFilter: makeFilterSelector(ColumnType.Text),
    selectNumericFilter: makeFilterSelector(ColumnType.Numeric),
    selectDateFilter: makeFilterSelector(ColumnType.Date),
    selectCheckboxFilter: makeFilterSelector(ColumnType.Checkbox),
    selectSingleFilter: makeFilterSelector(ColumnType.SinglePick),
    selectMultiFilter: makeFilterSelector(ColumnType.MultiPick),
    ...contractSelectors,
    selectFilteredIds: (state) => state.filteredIds,
    selectColumns: columnSelector,
    selectFilteredContracts: filteredContractsSelector,
    selectContracts: contractSelector,
    selectColumnOptions: columnOptionsSelector,
    selectCustomColumnValues: (state) => state.customColumnValues,
    selectListingSort: (state) => state.sort,
  },
});

export const {
  newListingSearch,
  showArchivedToggled,
  selectionToggled,
  allSelectionToggled,
  textFilterChanged,
  numericComparisonChanged,
  numericFilterChanged,
  dateComparisonChanged,
  dateFilterChanged,
  checkboxFilterToggled,
  singleFilterChanged,
  multiFilterToggled,
  filtersReset,
  listingsReset,
  toggleArchivedOrDelete,
  pageChanged,
  perPageChanged,
  contractsSearchOrFiltered,
  sortToggled,
} = listingsSlice.actions;

export const {
  selectListingSearch,
  selectShowArchived,
  selectSelectedById,
  selectSelectionLength,
  selectSelectionState,
  selectAllFilters,
  selectTextFilter,
  selectNumericFilter,
  selectDateFilter,
  selectCheckboxFilter,
  selectSingleFilter,
  selectMultiFilter,
  selectAll: selectAllListings,
  selectById: selectListingById,
  selectIds: selectListingIds,
  selectEntities: selectListingMap,
  selectTotal: selectTotalListings,
  selectFilteredIds,
  selectColumns,
  selectFilteredContracts,
  selectContracts,
  selectPagination,
  selectColumnOptions,
  selectCanCreateContract,
  selectCustomColumnValues,
  selectListingSort,
} = listingsSlice.getSelectors((state: RootState) => listingsSlice.selectSlice(state.contracts));

export default persistSlice(listingsSlice, { whitelist: ["showArchived", "search", "filters"] });
