import React from "react";
import { clsx } from "clsx";

type AlertType = keyof typeof ALERT_TYPE;

interface AlertProps {
  message: string | undefined;
  type: AlertType;
  className?: string;
}

const ALERT_TYPE = {
  danger: "alert-danger",
  info: "alert-info",
};

const getAlertClass = (type: AlertType, className?: string) => {
  return clsx(
    "alert font-s13 m-b0 m-t10 vertical-center white-space-pre-wrap wid-100 animated fadeIn",
    ALERT_TYPE[type],
    className
  );
};

export default function Alert({ message, type, className }: AlertProps) {
  if (!message) return null;
  const alertClass = getAlertClass(type, className);

  return (
    <div title={message} className={alertClass}>
      {message}
    </div>
  );
}
