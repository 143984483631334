import { isEmpty, omit } from "lodash";

/**
 * Mutate activity object to hide children
 *
 * @param {Object} activity Activity Object
 * @returns {object}
 */
export const showChildren = (activity) => {
  activity.show_children = false;
  return activity;
};

export const _handleFilterInit = (filter = {}) => {
  let filterString = "&fetch_parent_child=true";
  if (!filter.myFilters && Object.keys(filter).length > 1) {
    filterString = "";
  }

  if (!isEmpty(filter)) {
    for (let key in filter) {
      filterString += `&filter[${key}]=${filter[key]}`;
    }
  }

  return filterString;
};

/**
 * Create filter string based on different filters.
 *
 * @param {object} filter
 * @param {object}
 *
 * @returns {string}
 */
export const createFilterString = (filter, { supplierId, contactId }) => {
  let filterString = _handleFilterInit(filter);

  if (supplierId) {
    filterString = `&filter[suppliers]=${supplierId}`;
  }

  if (contactId) {
    filterString = `&filter[contacts]=${contactId}`;
  }

  return filterString;
};

const _updateBaseData = ({
  setActivities,
  setConcernedParties,
  setPaginationParams,
  setChildActivities,
  response,
  newPaginationParams,
  filter,
}) => {
  setActivities(response.data.activities.map(showChildren));
  setConcernedParties(response.data.concerned_parties);
  setPaginationParams({
    ...newPaginationParams,
    total_count: response.data.total_count,
    myFilters: filter && filter.myFilters,
  });
  setChildActivities(response.data.child_activities);
};

const _resetSearch = ({ resetSearch, setSearchParam, setHighlight, searchText }) => {
  if (resetSearch) {
    setSearchParam(searchText);
    setHighlight(true);
  }
};

const _handleSearch = ({ searchText, setHighlight }) => {
  if (searchText.length > 0) {
    setHighlight(true);
  }
};

const _handleFilter = ({ filter, setFilter }) => {
  if (filter === undefined) {
    setFilter({});
  }
};

/**
 * Updates different state variables.
 *
 * @param {Object} response Reponse from fetch API
 * @param {Object} newPaginationParams Paginator params
 * @param {Object} param State mutator functions
 */
export const batchUpdates = (
  response,
  newPaginationParams,
  {
    setActivities,
    setConcernedParties,
    setPaginationParams,
    setChildActivities,
    setSearchParam,
    setHighlight,
    setFilter,
    resetSearch,
    searchText,
    filter,
  }
) => {
  if (response && response.status === 200) {
    _updateBaseData({
      setActivities,
      setConcernedParties,
      setPaginationParams,
      setChildActivities,
      response,
      newPaginationParams,
      filter,
    });

    _resetSearch({
      resetSearch,
      setSearchParam,
      setHighlight,
      searchText,
    });

    _handleSearch({
      searchText,
      setHighlight,
    });

    _handleFilter({
      filter,
      setFilter,
    });
  }
};

/**
 * Checks if current state have filters
 *
 * @param {Object} filters
 * @returns {boolean}
 */
export const hasFilters = (filters) => {
  if (!filters) {
    return false;
  }

  if (filters.myFilters) {
    return false;
  }

  if (!isEmpty(omit(filters, "myFilters"))) {
    return true;
  }

  return false;
};
