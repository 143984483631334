import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const ActivityType = ({ type }) => {
  const { translations } = useSelector((state) => state.ActivityReducer);

  const _resolveTranslation = (value) => {
    if (translations[value.toLowerCase()]) {
      return translations[value.toLowerCase()];
    }
    return value;
  };

  return <td>{_resolveTranslation(type)}</td>;
};

ActivityType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ActivityType;
