import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";

import { createLot, updateLot, sortLot } from "../../../actions/lotActions";
import { disableButton, enableButton, isIE } from "../common";
import classNames from "classnames";
import Tooltip from "../../common/Tooltip";

const LotForm = (props) => {
  const dispatch = useDispatch();
  const refBtn = useRef(null);
  const {
    translations,
    eventId,
    allowedLotType,
    lot,
    create,
    total,
    modalIsOpen,
    setModalIsOpen,
    partialBiddingSwitch,
    anyCompletedBids,
  } = props;
  const lotName = lot !== undefined ? lot.name : "";
  const [newLotName, setNewLotName] = useState(lotName);
  const [permitPartialBids, setPermitPartialBids] = useState(lot && lot.permit_partial_bids);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    disableButton(refBtn.current, translations.saving);
    const complexStructure = lot !== undefined ? lot.complex_structure : ["complex", "both"].includes(allowedLotType);
    const payload = {
      lot: {
        name: newLotName,
        complex_structure: complexStructure,
        is_event_total: total,
        permit_partial_bids: permitPartialBids,
      },
    };

    dispatch(create || total ? createLot(eventId, payload) : updateLot(eventId, lot.id, payload)).then((response) => {
      if (response) {
        setModalIsOpen(false);
      } else {
        enableButton(refBtn.current, translations.save, "<i class='fa fa-check'></i>");
      }
      const items = Array.from(document.getElementsByClassName("complex-lot")).map((el) => el.getAttribute("data-id"));
      const payload = {
        sorted_element_id: response.payload.id,
        item: items,
      };
      dispatch(sortLot(response.payload.event_id, payload));
    });
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className={classNames({
          "modal-content col-md-4 col-sm-4 custom-field-popup": true,
          "modal2-ie": isIE(),
        })}
        appElement={document.getElementById("advanced-lots-body")}
      >
        <form onSubmit={(e) => handleFormSubmit(e)}>
          <div className="block block-transparent remove-margin-b">
            <div className="block-header bg-primary-default">
              <ul className="block-options">
                <li>
                  <button type="button" onClick={() => setModalIsOpen(false)}>
                    <i className="ion-close" />
                  </button>
                </li>
              </ul>
              <h3 className="block-title">{lot ? translations.edit_lot : translations.new_lot}</h3>
            </div>
            <div className="block-content lot-fieldset">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="lot_name">{translations.lot_name}</label>
                    <span className="required">*</span>
                    <input
                      autoComplete="off"
                      maxLength="150"
                      className="form-control"
                      size="150"
                      type="text"
                      id="lot_name"
                      text={newLotName}
                      value={newLotName}
                      onChange={(e) => setNewLotName(e.target.value)}
                      autoFocus={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            {partialBiddingSwitch && (
              <div className="block-content lot-fieldset">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="col no-padding">
                        <div className="input no-padding">
                          <div className="radios">
                            <Tooltip {...translations.infos.allow_partial_bidding} />
                            <label htmlFor="lot_name" className="css-input css-checkbox css-checkbox-default">
                              <input
                                name="permitPartialBids"
                                className="provider_option"
                                type="checkbox"
                                id="permit_partial_bids"
                                disabled={anyCompletedBids}
                                onChange={(e) => setPermitPartialBids(e.target.checked)}
                                checked={permitPartialBids}
                              />
                              <span />
                              {translations.allow_partial_bidding}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                data-dismiss="modal"
                type="button"
                className="btn btn-sm btn-default"
                onClick={() => setModalIsOpen(false)}
              >
                {translations.cancel}
              </button>
              <button name="button" className="btn btn-sm btn-primary" type="submit" ref={refBtn}>
                {translations.save} <i className="fa fa-check" />
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default LotForm;
