import React from "react";
import Modal from "react-modal";
import ModalHeader from "./ModalHeader";

const ModalContentWrapper = ({ props, closeModal }) => {
  return (
    <div className="block block-transparent remove-margin-b">
      <ModalHeader {...props} closeModal={closeModal} title={props.translations.survey_created} />
      <div className="block-content lot-fieldset">
        <div className="row">
          <div className="col-md-12">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  props.surveyScore > 8
                    ? props.translations.survey_created_promoter_html
                    : props.surveyScore < 7
                    ? props.translations.survey_created_detractor_html
                    : props.translations.survey_created,
              }}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="input inline">
          <button name="button" className="btn btn-sm btn-primary" type="button" onClick={closeModal}>
            {props.translations.ok}
          </button>
        </div>
      </div>
    </div>
  );
};

const ThanksYouSurveyModal = (props) => {
  const { surveyThanksModal, setState } = props;
  const closeModal = () => setState({ surveyThanksModal: false });

  return (
    <Modal
      isOpen={surveyThanksModal}
      onRequestClose={closeModal}
      className="modal-content col-md-4 col-sm-4 custom-field-popup"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <ModalContentWrapper props={props} closeModal={closeModal} />
    </Modal>
  );
};

export default ThanksYouSurveyModal;
