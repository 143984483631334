import PropTypes from "prop-types";

export const BidLineItemComponent = {
  id: PropTypes.number,
  attribute_value: PropTypes.oneOf([PropTypes.number, PropTypes.string, null]),
  price: PropTypes.oneOf([PropTypes.number, null]),
  bid_id: PropTypes.number,
  line_item_component_id: PropTypes.number,
  updated_at: PropTypes.instanceOf(Date),
  unit_of_measure_id: PropTypes.number,
  rank: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  rank_when_created: PropTypes.oneOf([PropTypes.number, null]),
  overall_rank: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  overall_rank_when_created: PropTypes.oneOf([PropTypes.number, null]),
  price_score: PropTypes.oneOf([PropTypes.number, null]),
  weighted_score: PropTypes.oneOf([PropTypes.number, null]),
  exchange_rate_id: PropTypes.oneOf([PropTypes.number, null]),
  subsequent_bid: PropTypes.number,
  host_price: PropTypes.oneOf([PropTypes.number, null]),
  number_value: PropTypes.oneOf([PropTypes.number, null]),
  date_value: PropTypes.oneOf([PropTypes.instanceOf(Date), null]),
  picklist_value: PropTypes.oneOf([PropTypes.string, null]),
};

/** @typedef {PropTypes.InferProps<typeof BidLineItemComponent>} BidLineItemComponent */

export default BidLineItemComponent;
