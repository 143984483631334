import { reject } from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteLinkedQuestion, fetchSimQuestionnaires } from "../../actions/srmActions";
import { Confirm } from "../common/Confirm";
import Tooltip from "../common/Tooltip";
import LinkQuestionsForm from "./LinkQuestionsForm";

const LinkQuestions = (props) => {
  const {
    company,
    translations,
    linkedQuestions,
    srmLinkedQuestions,
    questionnaires,
    sections,
    currentUserEditConfigPermission,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedLinkedQuestions, setSelectedLinkedQuestions] = useState(linkedQuestions);
  const [linkedQuestionnaires, setLinkedQuestionnaires] = useState(questionnaires);
  const [linkedSections, setLinkedSections] = useState(sections);
  const [selectedSrmLinkedQuestions, setSelectedSrmLinkedQuestions] = useState(srmLinkedQuestions);
  const [deletedSrmLinkedQuestion, setDeletedSrmLinkedQuestion] = useState(false);

  const dispatch = useDispatch();
  const fetchSimQuestionnaireDetails = () => {
    dispatch(fetchSimQuestionnaires(company.id)).then((response) => {
      setModalIsOpen(true);
    });
  };

  const setDeleteId = (questionId) => {
    setDeletedSrmLinkedQuestion(
      selectedSrmLinkedQuestions.find((slq) => slq.questionnaire_question_id === questionId).id
    );
  };

  return (
    <div className="clearboth form-item-block">
      <div className="form-item-block-title">
        {translations.link_sim_questions}
        <div className="vertical-center-info_icons">
          <Tooltip {...translations.srm_link_sim_questions} />
        </div>
      </div>
      <div className="input inline m-b10">
        <div className="btn-group-sm">
          <div className="row">
            <table className="m-b0 table table-bordered table-layout-fixed">
              <thead className="">
                <tr>
                  <th className="wid-20">{translations.title}</th>
                  <th className="wid-12">{translations.source}</th>
                  {currentUserEditConfigPermission && <th className="text-center wid-15">{translations.actions}</th>}
                </tr>
              </thead>
              <tbody>
                {selectedLinkedQuestions.map((question, idx) => {
                  const section = linkedSections.find((ls) => ls.id === question.questionnaire_section_id);
                  const questionnaire = linkedQuestionnaires.find((lq) => lq.id === question.questionnaire_id);

                  return (
                    <tr key={idx}>
                      <td>{question.title}</td>
                      <td>
                        {`${questionnaire.title} : ${section.position} : ${section.position}.${question.position}`}
                      </td>
                      {currentUserEditConfigPermission && (
                        <td className="text-center">
                          <div className="dropdown">
                            <div className="btn-group-sm dropdown">
                              <a
                                className="btn btn-icon"
                                title={translations.delete}
                                onClick={() => setDeleteId(question.id)}
                                data-toggle="modal"
                                data-target="#linkedQuestionRemoveConfirmModal"
                                rel="nofollow"
                              >
                                <i className="fa fa-trash-o red-color"></i>
                              </a>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
                {currentUserEditConfigPermission && (
                  <tr>
                    <td colSpan="3">
                      <button
                        className={"btn btn-primary btn-sm"}
                        onClick={() => fetchSimQuestionnaireDetails()}
                        style={{
                          float: "left",
                        }}
                      >
                        <i className="fa fa-plus-circle add-tag" aria-hidden="true"></i>
                        &nbsp;{translations.link_sim_questions}
                      </button>
                      {modalIsOpen && (
                        <LinkQuestionsForm
                          modalIsOpen={modalIsOpen}
                          setModalIsOpen={setModalIsOpen}
                          translations={translations}
                          company={company}
                          selectedLinkedQuestions={selectedLinkedQuestions}
                          setSelectedLinkedQuestions={setSelectedLinkedQuestions}
                          setSelectedSrmLinkedQuestions={setSelectedSrmLinkedQuestions}
                          setLinkedQuestionnaires={setLinkedQuestionnaires}
                          setLinkedSections={setLinkedSections}
                        />
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Confirm
              translations={translations}
              message={translations.are_you_sure}
              htmlId="linkedQuestionRemoveConfirmModal"
              onOkayButtonClick={() =>
                dispatch(deleteLinkedQuestion(deletedSrmLinkedQuestion)).then((response) => {
                  const questionId = selectedSrmLinkedQuestions.find(
                    (slq) => slq.id === deletedSrmLinkedQuestion
                  ).questionnaire_question_id;

                  const finalQuestions = reject(selectedLinkedQuestions, (q) => q.id === questionId);
                  setSelectedLinkedQuestions(finalQuestions);
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkQuestions;
