import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Loader from "react-loader-advanced";
import Modal from "simple-react-modal";

import { getParticipantsByRequestId } from "../../../../../actions/qq/emarketplace/emSummaryActions";
import LoaderMessage from "../../../LoaderMessage";
import formatData from "../../../js/languageFormatter";
import { backgroundOnModalOpen } from "../../../js/common";

class ParticipantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      showModel: false,
      lot: {},
      bid: {},
      lotQuantity: 1,
      hasDeliveryDateView: false,
    };
  }
  async UNSAFE_componentWillMount() {
    const { requestId } = this.props;
    if (requestId) {
      await this.props.getParticipantsByRequestId(requestId);
      this.setState({
        showLoader: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.emSummary.bids_summary) {
      this.setState({
        showLoader: false,
        hasDeliveryDateView: newProps.emSummary.bids_summary.lots[0].lot.participant_lot_components_size === 3,
      });
    }
  }

  getParticipantStatus(status) {
    const { translations } = this.props;
    switch (status) {
      case "Accepted Invite":
        return (
          <div className="status-txt green-text">
            <span className="fa fa-check" aria-hidden="true" /> {translations.emarketplace.summary_status.accepted}
          </div>
        );
      case "Not Accepted Invite":
        return (
          <div className="status-txt orange-text">
            <span className="fa fa-history" aria-hidden="true" />{" "}
            {translations.emarketplace.summary_status.not_accepted}
          </div>
        );
      case "Declined Event":
        return (
          <div className="status-txt red-text">
            <span className="fa fa-times" aria-hidden="true" /> {translations.emarketplace.summary_status.declined}
          </div>
        );
      default:
        return (
          <div className="status-txt red-text">
            <span className="fa fa-times" aria-hidden="true" />
            {translations.emarketplace.summary_status.not_registered}
          </div>
        );
    }
  }

  async showLotDetails(rowId, lotDetails, index, spanLength, lotName) {
    const { data } = lotDetails;
    let listItems = "";
    let description;
    let quantity;
    await data.forEach((item) => {
      const lotComponentName = item.lot_component.name.toLowerCase();
      if (lotComponentName === "description") {
        description = item.line_item_components[0].permitted_attributes.attribute_value;
      } else if (lotComponentName === "price") {
        quantity = `<li>Quantity : ${formatData(
          this.props.selectedLang,
          "",
          item.line_item_components[0].permitted_attributes.quantity
        )}</li>`;
      } else if (lotComponentName === "earliest delivery date" || lotComponentName === "latest delivery date") {
        listItems += `<li>${lotComponentName} : ${item.line_item_components[0].permitted_attributes.formatted_date_value}</li>`;
      } else if (lotComponentName !== "achievable delivery date" && lotComponentName !== "lead time(working days)") {
        listItems += `<li>${lotComponentName} : ${item.line_item_components[0].permitted_attributes.attribute_value}</li>`;
      }
    });
    listItems += quantity;
    const list = `<tr class="expand_content"> <td colspan=${
      spanLength + 2
    } class="expand_content_details"> <div class="details clear"><div>
    <span class="info">details</span><span class="arrow"><img src="/images/emarketplace-arrow.png">
    </span><span class="info">${lotName}</span></div><div class="content"><ul><li>Lot ${index + 1}</li>
    ${listItems}</ul>${
      description ? `<ul class="description-content"> <li>Description :</li><li> ${description}</li></ul>` : ""
    }</div></div></td> </tr>`;
    if ($(`#${rowId}`).next().hasClass("expand_content")) {
      $(".expand_content").remove();
    } else {
      $(".expand_content").remove();
      $(`#${rowId}`).after(list);
    }
  }

  async openPopup(e, lot, bid, bidData) {
    let quantityValue = 1;
    await bidData.forEach((item) => {
      if (item.lot_component.name === "Price") {
        quantityValue = item.line_item_components[0].permitted_attributes.quantity;
      }
    });
    backgroundOnModalOpen(true);
    e.preventDefault();
    this.setState(
      {
        lot,
        bid,
        lotQuantity: quantityValue,
      },
      () => {
        this.setState({ showModel: true });
      }
    );
  }

  close() {
    backgroundOnModalOpen(false);
    this.setState({ showModel: false });
  }

  render() {
    const { translations } = this.props;
    const data = this.props.emSummary.bids_summary ? this.props.emSummary.bids_summary : {};
    const lotList = data ? data.lots : [];
    return (
      <Loader
        show={this.state.showLoader}
        message={<LoaderMessage loaderText={translations.loader_text} />}
        disableDefaultStyles
      >
        <div className="category_holder">
          <a className="category_title active" data-toggle="collapse" href="#collapseParticipantList">
            {translations.summary_status.participant_list.participant_list}
          </a>
          <div className="panel-collapse collapse" id="collapseParticipantList">
            {!_.isEmpty(data) ? (
              <div className="fixed-table-container">
                <table name="participant_table" cellPadding="0" cellSpacing="0" border="0">
                  <thead>
                    <tr>
                      <th className="hide" />
                      <th colSpan="2">{translations.summary_status.summary.lot_name}</th>
                      {data.event_participants.map((participant) => (
                        <th key={participant.id}>
                          {participant.name ? participant.name : participant.email}
                          {this.getParticipantStatus(participant.participating_status)}
                          {this.state.hasDeliveryDateView && (
                            <div id="delivery-date-view" className="deadline-box">
                              <span>{translations.emarketplace.price}</span>
                              <span>{translations.emarketplace.achievable_deadline}</span>
                              <span>{translations.emarketplace.lead_time}</span>
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {lotList.map((lot, index) => (
                      <tr key={lot.lot.id} id={`rowId${lot.lot.id}`}>
                        <td>
                          <button
                            className="expand_btn fa fa-plus"
                            onClick={() => {
                              this.showLotDetails(
                                `rowId${lot.lot.id}`,
                                lot.detail,
                                index,
                                data.event_participants.length,
                                lot.lot.name
                              );
                            }}
                          />
                        </td>
                        <td>{lot.lot.name}</td>
                        {data.event_participants.map((participant, idx) =>
                          lot.bids[idx] && participant.status !== "Declined" ? (
                            <td key={lot.bids[idx].id}>
                              {lot.bids[idx].rejected_lot ? (
                                <span className="red-text">
                                  {translations.emarketplace.summary_status.lot_rejected}
                                </span>
                              ) : (
                                <div className="deadline-box">
                                  <a
                                    href="#"
                                    name="bid_value"
                                    onClick={(e) => this.openPopup(e, lot.lot, lot.bids[idx], lot.detail.data)}
                                  >
                                    {formatData(
                                      this.props.selectedLang,
                                      lot.bids[idx].currency.symbol,
                                      _.round(lot.bids[idx].host_price * lot.lot.quantity, 2)
                                    )}
                                  </a>

                                  {this.state.hasDeliveryDateView &&
                                    lot.bids[idx].bid_line_item_components.map((item) => {
                                      if (item.lot_component_title === "Achievable delivery date") {
                                        return item.attribute_value === "Yes" ? (
                                          <span key={item.id} className="green-text">
                                            {translations.emarketplace.yes}
                                          </span>
                                        ) : (
                                          <span key={item.id} className="red-text">
                                            {translations.emarketplace.no}
                                          </span>
                                        );
                                      }
                                    })}
                                  {this.state.hasDeliveryDateView &&
                                    lot.bids[idx].bid_line_item_components.map((item) => {
                                      if (item.lot_component_title === "Lead time(working days)") {
                                        return <span key={item.id}>{item.price}</span>;
                                      }
                                    })}
                                </div>
                              )}
                            </td>
                          ) : (
                            <td name="bid_value" key={participant.id + lot.lot.id}>
                              -
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            {_.isEmpty(data) && !this.state.showLoader && (
              <div className="details_form_box text-center">
                {translations.emarketplace.summary_status.no_participants}
              </div>
            )}
          </div>

          <div className="main-popup popup-md">
            <Modal show={this.state.showModel} onClose={() => this.close()} closeOnOuterClick>
              <div className="vertical-top">
                <div className="vertical-align-center">
                  <div className="modal-content clearfix">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {translations.summary_status.current_lot}: {this.state.lot.name}
                      </h5>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.close();
                        }}
                        className="close-icon modal-btn-close"
                      >
                        <i className="fa fa-times" aria-hidden="true" />
                      </a>
                    </div>
                    <div className="modal-body">
                      <table className="table table-bordered ">
                        <tbody>
                          <tr className="active">
                            <td>{translations.summary_status.unit_of_measure}</td>
                            <td>
                              <strong>{this.state.lot.unit_of_measure}</strong>
                            </td>
                          </tr>
                          <tr className="active">
                            <td>{translations.summary_status.quantity_of_uom}</td>
                            <td>
                              <strong>{formatData(this.props.selectedLang, "", this.state.lotQuantity)}</strong>
                            </td>
                          </tr>
                          <tr className="active">
                            <td>{translations.summary_status.participant_bid}</td>
                            <td>
                              <strong>
                                {formatData(
                                  this.props.selectedLang,
                                  this.state.bid.currency ? this.state.bid.currency.symbol : "",
                                  _.round(this.state.bid.host_price / this.state.lotQuantity, 2)
                                )}
                              </strong>
                            </td>
                          </tr>
                          <tr className="active">
                            <td>{translations.emarketplace.bid_value}</td>
                            <td>
                              <strong>
                                {formatData(
                                  this.props.selectedLang,
                                  this.state.bid.currency ? this.state.bid.currency.symbol : "",
                                  _.round(this.state.bid.host_price, 2)
                                )}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary modal-btn-close" onClick={() => this.close()}>
                        {translations.pop_up.ok}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLang: state.changeLanguageReducer,
  emSummary: state.emSummaryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getParticipantsByRequestId: (requestId) => dispatch(getParticipantsByRequestId(requestId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList);
