import React from "react";
import PropTypes from "prop-types";
import {
  useModuleOptions,
  AvailableModuleGroups,
  ModuleGroups,
  ModulePrefixes,
  ModuleStatus,
  ModuleTypes,
} from "./types";
import { Radio } from "@/cl/radio";
import { useAttribute } from "@adeattwood/react-form";
import ReactSelect, { components } from "react-select";
import { Button } from "@/cl/button";
import { Box } from "@/cl/box";
import { isMandatory } from "./table-row";
import { t } from "@/i18n";
import { Tag } from "./tag";

const DEFAULT_ITEM = {
  required: false,
  active: false,
  include_in_invite: false,
  show_in_summary: false,
};

function itemTypeForModule(itemTypes, module) {
  return itemTypes.find(({ item_type }) => item_type.startsWith(ModulePrefixes[module]));
}

const ModuleOptionsSelectPropTypes = {
  /**
   * The index in the list of custom fields that this input will control the
   * custom column item types for.
   */
  index: PropTypes.number.isRequired,
  /**
   * If this input is locked and unable ot change the input value
   */
  disabled: PropTypes.bool,
  /**
   * The module this input is render for. This will affect the options that are
   * available in the select.
   */
  module: PropTypes.number.isRequired,
};

export const getActiveAndRequired = (option) => {
  if (option.group === ModuleGroups.Status && option.status === ModuleStatus.Mandatory) {
    return { active: true, required: true };
  } else if (option.group === ModuleGroups.Status && option.status === ModuleStatus.Available) {
    return { active: true, required: false };
  } else if (option.group === ModuleGroups.Status) {
    return { active: false, required: false, include_in_invite: false, show_in_summary: false };
  }

  return {};
};

export const useOnChange =
  (attribute, module) =>
  (_, { option }) => {
    // console.log({ attribute, option, module });
    for (const itemTypeName of ModuleTypes[module]) {
      let indexToUpdate = attribute.value.findIndex((item) => item.item_type === itemTypeName);

      // If we don't have the item type configured for the module (sim, srm,
      // contracts) then the new item type.
      if (indexToUpdate === -1) {
        attribute.value.push({ ...DEFAULT_ITEM, item_type: itemTypeName });
        indexToUpdate = attribute.value.length - 1;
      }

      Object.entries({
        [ModuleGroups.ShowInInvite]: "include_in_invite",
        [ModuleGroups.DisplayAsColumn]: "show_in_summary",
      }).forEach(([groupString, key]) => {
        const group = parseInt(groupString);
        if (option.group === group) {
          attribute.value[indexToUpdate][key] = option.status === ModuleStatus.Available;
        }
      });

      attribute.value[indexToUpdate] = { ...attribute.value[indexToUpdate], ...getActiveAndRequired(option) };
    }

    attribute.set([...attribute.value]);
  };

const getModuleStatusValue = (itemType) => {
  if (isMandatory(itemType)) {
    return ModuleStatus.Mandatory;
  }

  if (itemType?.active) {
    return ModuleStatus.Available;
  }

  return ModuleStatus.Inactive;
};

export const getValues = (moduleOptions, itemType, module) => {
  const values = [];
  if (AvailableModuleGroups[module].includes(ModuleGroups.Status)) {
    const status = getModuleStatusValue(itemType);
    values.push(moduleOptions[ModuleGroups.Status].options.find((item) => item.status === status));
  }

  Object.entries({
    [ModuleGroups.DisplayAsColumn]: "show_in_summary",
    [ModuleGroups.ShowInInvite]: "include_in_invite",
  }).forEach(([groupString, key]) => {
    const group = parseInt(groupString);
    if (AvailableModuleGroups[module].includes(group)) {
      const value = itemType?.[key] ? ModuleStatus.Available : ModuleStatus.Inactive;
      values.push(moduleOptions[group].options.find((item) => item.status === value));
    }
  });

  return values;
};

let optionCount = 1;
function Option({ innerProps: { onClick, ...props }, label, isSelected }) {
  const name = `checkbox-${props.id || ++optionCount}`;
  return (
    <div {...props} className="mx-2 mt-3">
      <Radio name={name} label={label} checked={isSelected} onChange={onClick} md />
    </div>
  );
}

Option.propTypes = {
  id: PropTypes.string,
  innerProps: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

const getIsDisabled = (group, value) => {
  return (
    group !== ModuleGroups.Status &&
    value.some((option) => option.group === ModuleGroups.Status && option.status === ModuleStatus.Inactive)
  );
};

function Group(props) {
  // When a module us inactive then it cannot be displayed in any of the table
  // i.e "Display as column" This will disable any of the groups that are not
  // the `Status` module group and when the value of modules group is
  // "Inactive". This will ensure the user knows that having a module that is
  // Inactive and DisplayAsColumn is an invalid combination.
  const isDisabled = getIsDisabled(props.data.group, props.getValue());

  return (
    <fieldset disabled={isDisabled}>
      <components.Group {...props} />
    </fieldset>
  );
}

Group.propTypes = {
  /**
   * A function that will return the current value of the select
   */
  getValue: PropTypes.func.isRequired,
  /**
   * The data for the current group defined by the `useModuleOptions` hook
   */
  data: PropTypes.shape({
    group: PropTypes.number.isRequired,
  }).isRequired,
};

function MenuList(props) {
  return (
    <components.MenuList {...props}>
      <p className="border-1 border-bottom font-w700 p-2" style={{ color: "#272833" }}>
        {t("centralised_custom_fields.options")}
      </p>
      {props.children}
      <Box p={2}>
        <Button w={100} brand="md-primary" onClick={props.selectProps.onMenuClose}>
          {t("apply")}
        </Button>
      </Box>
    </components.MenuList>
  );
}

MenuList.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.shape({
    onMenuClose: PropTypes.func.isRequired,
  }),
};

/**
 * Get the correct tag brand of the current status and group. If the group is
 * not "Status" then it will always be `base`. Then it will check to see if the
 * current option allows the custom column to be visible in the module. This
 * can be either “Mandatory” or "Available" in this case the success or danger
 * bands will be applied.
 *
 * @param {Object} param0
 * @param {number} param0.status The current module status in the select
 * @param {number} param0.group The current module group this option is for
 */
function brandForData({ status, group }) {
  if (group !== ModuleGroups.Status) {
    return "base";
  }

  return [ModuleStatus.Mandatory, ModuleStatus.Available].includes(status) ? "success" : "danger";
}

function MultiValueLabel({ data }) {
  const isStatus = data.group === ModuleGroups.Status;
  const moduleStatusMap = {
    [ModuleGroups.Status]: t("centralised_custom_fields.status"),
    [ModuleGroups.ShowInInvite]: t("centralised_custom_fields.invite"),
    [ModuleGroups.DisplayAsColumn]: t("centralised_custom_fields.column"),
  };

  return (
    <Tag
      title={`${moduleStatusMap[data.group]}: ${data.label}`}
      className={`mb-2 ${isStatus && "mt-2"}`}
      brand={brandForData(data)}
    >
      <strong>{moduleStatusMap[data.group]}:</strong> {data.label}
    </Tag>
  );
}

/**
 * @type {React.FC<PropTypes.InferProps<typeof ModuleOptionsSelectPropTypes>>}
 */
const ModuleOptionsSelect = ({ index, module, disabled }) => {
  const attribute = useAttribute(`customFields.${index}.custom_column_item_types`, []);
  const moduleOptions = useModuleOptions();
  const onChange = useOnChange(attribute, module);
  const itemType = itemTypeForModule(attribute.value, module) || DEFAULT_ITEM;
  const values = getValues(moduleOptions, itemType, module);

  return (
    <ReactSelect
      id={`module-options-select-${module}`}
      isMulti
      isDisabled={disabled}
      closeMenuOnSelect={false}
      filterOption={(item) => AvailableModuleGroups[module].includes(item.data.group)}
      hideSelectedOptions={false}
      isClearable={false}
      components={{ Input: () => null, Option, MenuList, Group, MultiValueLabel }}
      menuPortalTarget={document.getElementById("bs5-portal")}
      styles={{
        multiValue: () => ({ width: "100%" }),
        multiValueGeneric: () => ({ padding: 0 }),
        multiValueRemove: (baseStyles) => ({ ...baseStyles, display: "none" }),
        menuList: (baseStyles) => ({ ...baseStyles, maxHeight: "none" }),
        groupHeading: (baseStyles) => ({
          ...baseStyles,
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "1rem",
          fontWeight: "600",
          color: "black",
          marginBottom: "1rem",
        }),
      }}
      value={values}
      options={Object.values(moduleOptions)}
      onChange={onChange}
    />
  );
};

ModuleOptionsSelect.propTypes = ModuleOptionsSelectPropTypes;

export default ModuleOptionsSelect;
