import * as apiCall from '../api';
import actionTypes from '../actionsTypes';
import * as globalActionTypes from "../../actionsTypes";
import config from '../config';

const getMaterialRequestByIdSuccess = (data) => ({
  type: actionTypes.get_request_by_id,
  payload: data,
});

const getBidsByRequestIdSuccess = (lots) => ({
  type: actionTypes.get_bids_by_request_id,
  payload: lots,
});

const getParticipantsByRequestIdSuccess = (participants) => ({
  type: actionTypes.get_participants_by_request_id,
  payload: participants,
});

const getAwardLotSuccess = (data) => ({
  type: actionTypes.get_awarded_lots,
  payload: data,
});

const getError = (error) => ({
  type: globalActionTypes.ERROR_CODE,
  payload: error,
});

export const getMaterialRequestById = (requestId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/events/${requestId}`)
    .then((response) => {
      dispatch(getMaterialRequestByIdSuccess(response.data.event));
      return response;
    })
    .catch((error) => error.response)
);

export const getBidsByRequestId = (requestId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/events/${requestId}/bids`)
    .then((response) => {
      dispatch(getBidsByRequestIdSuccess(response.data.bids));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const getParticipantsByRequestId = (requestId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/events/${requestId}/summary`)
    .then((response) => {
      dispatch(getParticipantsByRequestIdSuccess(response.data));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const getAwardedLots = (requestId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/events/${requestId}/award_data`)
    .then((response) => {
      dispatch(getAwardLotSuccess(response.data.award_data.filter((item) => item.bids.length > 0)));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error;
    })
);

export const deleteNotification = (lotId, requestId) => async (dispatch) => {
  try {
    const data = { lot_id: lotId.toString() };
    await apiCall.post(`${config.BASE_URL}/events/${requestId}/award_data/update_award_notification`, data);
    const response = await apiCall.get(`${config.BASE_URL}/events/${requestId}/award_data`);
    return dispatch(getAwardLotSuccess(response.data.award_data.filter((item) => item.bids.length > 0)));
  } catch (error) {
    return error.response;
  }
};

export const sendNotification = (participants, msg, orderNumber, requestId, ccEmail) => async (dispatch) => {
  try {
    const data = {
      lots_list: {},
      po_number_text: orderNumber,
      participant_list: [],
      notify_message: msg,
      event_id: requestId,
      cc_email: ccEmail,
    };
    await participants.forEach((item, index) => {
      const participantId = (item.participant.user_id).toString();
      data.lots_list[index] = {
        lot_id: (item.lotId).toString(), lot_name: item.name, participant_id: participantId, position: item.position,
      };
      if (data.participant_list.indexOf() === -1) {
        data.participant_list.push(participantId);
      }
    });
    await apiCall.post(`${config.BASE_URL}/events/${requestId}/award_data/send_award_notification`, data);
    const response = await apiCall.get(`${config.BASE_URL}/events/${requestId}/award_data`);
    return dispatch(getAwardLotSuccess(response.data.award_data.filter((item) => item.bids.length > 0)));
  } catch (error) {
    return error.response;
  }
};

export const awardLots = (lots, requestId) => async (dispatch) => {
  try {
    const data = {
      lots: {},
    };
    await lots.forEach((item) => {
      data.lots[item.lotId] = { awarded_participant_id: (item.participant ? (item.participant.user_id).toString() : '-1') };
    });
    await apiCall.post(`${config.BASE_URL}/events/${requestId}/award_data`, data);
    const response = await apiCall.get(`${config.BASE_URL}/events/${requestId}/award_data`);
    return dispatch(getAwardLotSuccess(response.data.award_data.filter((item) => item.bids.length > 0)));
  } catch (error) {
    return error.response;
  }
};

export const updateDeadline = (requestId, data) => (dispatch) => (
  apiCall.put(`${config.BASE_URL}/events/${requestId}`, data)
    .then((response) => {
      dispatch(getMaterialRequestByIdSuccess(response.data.event));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);
