import { bidRange } from "@/actions/bidActions";
import { createSelector } from "@reduxjs/toolkit";
import { inRange } from "lodash";
import { useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findLicBidRange, findLotBidRange, isANumber, lotAllLiRangesWithPB } from "../../common";
import { running } from "../../eventCommon";
import useLot from "../useLot";
import useFieldStatus from "./useFieldStatus";

const rangeSelector = createSelector(
  ({ lotReducers }) => lotReducers.auction_tab,
  ({ lotReducers }) => lotReducers.event,
  ({ lotReducers }) => lotReducers.lotsBidRange,
  ({ lotReducers }) => lotReducers.event_participant,
  ({ lotReducers }) => lotReducers.current_user,
  (auctionTab, event, lotsBidRange, participant, currentUser) => ({
    auctionTab,
    event,
    lotsBidRange,
    participant: participant ?? currentUser,
  })
);

export const useLotLiPrice = (lot, bid) => {
  const { lotLineItemComponents } = useLot(lot);
  const bidLineItemComponents = useSelector(({ lotReducers }) => lotReducers.bid_line_item_components)?.filter(
    (bidLineItemComponent) => bidLineItemComponent.bid_id === bid?.id
  );

  return useMemo(() => {
    const priceLics = lotLineItemComponents.filter(
      (lotLineItemComponent) => !lotLineItemComponent.is_calculation && Boolean(lotLineItemComponent.is_price)
    );
    const lotLis = priceLics?.map((priceLic) => priceLic?.line_item_id);

    const lotLisPrice = priceLics
      ?.map((priceLic) =>
        bidLineItemComponents?.find(
          (bidLineItemComponent) => priceLic.id === bidLineItemComponent.line_item_component_id
        )
      )
      ?.map((priceBlic) => priceBlic?.price);

    if (!lotLis || !lotLis.length) return {};

    return lotLis?.reduce((current, item, index) => {
      current[item] = lotLisPrice[index];
      return current;
    }, {});
  }, [bidLineItemComponents, lotLineItemComponents]);
};

export const useAllLisAreInRange = (lot, bid) => {
  const { lotsBidRange } = useSelector(rangeSelector);
  const currentLotRange = lotsBidRange?.find(({ lotId }) => lotId === lot.id);
  const lotAllLineItemRanges = lotAllLiRangesWithPB(currentLotRange);
  const lineItemPriceMap = useLotLiPrice(lot, bid);

  return useMemo(() => {
    return Object.keys(lineItemPriceMap).every((lineItemId) => {
      const minRange = lotAllLineItemRanges?.[lineItemId][0];
      const maxRange = lotAllLineItemRanges?.[lineItemId][1];
      return inRange(lineItemPriceMap[lineItemId], minRange, maxRange) || lineItemPriceMap[lineItemId] === maxRange;
    });
  }, [lineItemPriceMap, lotAllLineItemRanges]);
};

const updateBidRange = (event, participant, bid, lotId, dispatch) => {
  let params = {
    event_id: event.id,
    user_id: participant.id,
  };
  if (bid && bid.exchange_rate_id) params.exchange_rate_id = bid.exchange_rate_id;
  dispatch(bidRange(lotId, bid?.id ?? "", params));
};

const useBidRange = ({ lot, bid, isAuctionRunning, lotsBidRange }) => {
  const { isLotTotal, isPBPermitted, activeLi } = useLot(lot);
  const { areAllCellsFilledForPB } = useFieldStatus(lot, bid);
  const allLisAreInRange = useAllLisAreInRange(lot, bid);

  return useMemo(() => {
    if (!isAuctionRunning) return ["", ""];

    // In case of lot total + LI total + PB:
    // - if lot LI's are partially filled then compare range according to LI total range
    // - otherwise compare with Lot total range.
    const isCheckingLotRange =
      isLotTotal && ((isPBPermitted && areAllCellsFilledForPB && allLisAreInRange) || !isPBPermitted);
    const range = isCheckingLotRange ? findLotBidRange(lotsBidRange, lot.id) : findLicBidRange(lotsBidRange, activeLi);

    return range?.some(isANumber) ? range : ["-", "-"];
  }, [
    activeLi,
    allLisAreInRange,
    areAllCellsFilledForPB,
    isAuctionRunning,
    isLotTotal,
    isPBPermitted,
    lot.id,
    lotsBidRange,
  ]);
};

const useIsInRange = ({
  lot,
  maxBidRange,
  minBidRange,
  totalBidValue,
  isBidSubmitted,
  auctionTab,
  isLotLevelMonitor,
  isAuctionRunning,
}) => {
  const lotItemComponentErrors = useSelector(({ lotReducers }) => lotReducers.lotItemComponentErrors);

  const checkRange = useCallback(
    () =>
      inRange(totalBidValue, minBidRange, maxBidRange) ||
      totalBidValue === maxBidRange ||
      (auctionTab && isBidSubmitted),
    [auctionTab, isBidSubmitted, maxBidRange, minBidRange, totalBidValue]
  );

  return useMemo(() => {
    if (isLotLevelMonitor && Object.keys(lotItemComponentErrors?.[lot.id] || {}).length !== 0) return false;
    if (isAuctionRunning && [minBidRange, maxBidRange].some(isANumber)) return checkRange();
    return true;
  }, [isLotLevelMonitor, lotItemComponentErrors, lot.id, isAuctionRunning, minBidRange, maxBidRange, checkRange]);
};

const useRange = (lot, bid, isAuction, totalBidValue, isLotLevelMonitor, isBidSubmitted) => {
  const { auctionTab, event, lotsBidRange, participant } = useSelector(rangeSelector);
  const isAuctionRunning = running(event) && isAuction;
  const lotId = lot?.id;

  const dispatch = useDispatch();

  useEffect(() => {
    // update bid range if auction event is running
    if (isAuctionRunning && auctionTab && bid?.id && lotId) updateBidRange(event, participant, bid, lotId, dispatch);
  }, [auctionTab, bid, dispatch, event, isAuctionRunning, lotId, participant]);

  const [minBidRange, maxBidRange] = useBidRange({ lot, bid, isAuctionRunning, lotsBidRange });

  /**
   * Check if provided bidValue is in range
   */
  const isInRange = useIsInRange({
    lot,
    maxBidRange,
    minBidRange,
    totalBidValue,
    isBidSubmitted,
    auctionTab,
    isLotLevelMonitor,
    isAuctionRunning,
  });

  return {
    isInRange,
    minBidRange,
    maxBidRange,
  };
};

export default useRange;
