import * as actionTypes from "./actionsTypes";

const _resolveErrorMessage = (response) => {
  if (!!response.error) {
    return response.error;
  }

  if (!!response.errors) {
    return response.errors;
  }

  return null;
};

export const getError = (response) => {
  if (!!response?.data && _resolveErrorMessage(response.data)) {
    return {
      type: actionTypes.ERROR,
      error: _resolveErrorMessage(response.data),
    };
  }

  return {
    type: actionTypes.ERROR,
  };
};

export const getErrors = (response) => {
  if (!!response.data && !!response.data.errors) {
    return {
      type: actionTypes.ERRORS,
      errors: response.data.errors,
    };
  }

  return {
    type: actionTypes.ERRORS,
  };
};

export const getErrorMessage = (response) => {
  return {
    type: actionTypes.ERROR,
    error: response,
  };
};
