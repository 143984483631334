import React, { Fragment } from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import LineItemDetail from "./line_item/LineItemDetail";

const LotDetails = React.memo(
  (props) => {
    const {
      lot,
      lots,
      lineItemComponents,
      lotLcs,
      lotLis,
      lotLics,
      translations,
      permittedToEdit,
      classFromEventState,
      anyCompletedBids,
      event,
      maxPrecisionFor,
      currenciesHash,
      eventCurrency,
      openOrJapanese,
      isJapaneseEvent,
      locale,
      reRenderDetails,
      currentUser,
      timeZoneIdentifier,
      showDetails,
      handleShowDetailsStatus,
      setLineItemToBeDeleted,
      isMultiCurrencyEvent,
      hideFromParticipantsSwitch,
    } = props;

    return lotLis.map(function (li) {
      const liLics = lotLics.filter((lic) => lic.line_item_id == li.id);
      let index = undefined;
      if (showDetails.includes(li.id)) {
        index = showDetails.indexOf(li.id);
      }
      let timestamp = "";
      let key = `${li.id}-${index}`;
      if (!isEmpty(reRenderDetails)) {
        timestamp = Object.keys(reRenderDetails).pop();
        const lineItemIds = reRenderDetails[timestamp]["lineItemIds"];
        if (timestamp && lineItemIds && lineItemIds.includes(li.id)) {
          key += `-${timestamp}`;
        }
      }
      return (
        <Fragment key={li.id}>
          <LineItemDetail
            lidKey={key}
            lot={lot}
            permittedToEdit={permittedToEdit}
            li={li}
            lotLcs={lotLcs}
            anyCompletedBids={anyCompletedBids}
            eventCurrency={eventCurrency}
            currenciesHash={currenciesHash}
            maxPrecisionFor={maxPrecisionFor}
            event={event}
            translations={translations}
            lots={lots}
            lineItemComponents={lineItemComponents}
            classFromEventState={classFromEventState}
            openOrJapanese={openOrJapanese}
            isJapaneseEvent={isJapaneseEvent}
            showDetails={showDetails}
            handleShowDetailsStatus={handleShowDetailsStatus}
            currentUser={currentUser}
            locale={locale}
            timeZoneIdentifier={timeZoneIdentifier}
            setLineItemToBeDeleted={setLineItemToBeDeleted}
            isMultiCurrencyEvent={isMultiCurrencyEvent}
            liLics={liLics}
            hideFromParticipantsSwitch={hideFromParticipantsSwitch}
          />
        </Fragment>
      );
    });
  },
  (prevProps, nextProps) => {
    const { lotLis, lot, showDetails, lotLics } = prevProps;

    switch (true) {
      case !isEqual(lotLis, nextProps.lotLis):
      case !isEqual(lot, nextProps.lot):
      case !isEqual(lotLics, nextProps.lotLics):
      case !isEqual(showDetails, nextProps.showDetails):
        return false;
      default:
        return true;
    }
  }
);

export default LotDetails;
