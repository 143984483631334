import React, { Component } from "react";
import { connect } from "react-redux";
import { createFilter } from "react-search-input";
import _ from "lodash";
import URLSearchParams from "url-search-params";
import Loader from "react-loader-advanced";

import getMaterialRequests from "../../../../../actions/qq/emarketplace/emListingActions";
import EMListTable from "./EMListTable";
import { confirm } from "../../../util/confirm";
import { emptyCurrentState, changeEventState } from "../../../../../actions/qq/genericActions";
import LoaderMessage from "../../../LoaderMessage";
import PaginationContainer from "../../../PaginationContainer";

const KEYS_TO_FILTERS = ["id", "name", "event_state"];
class EMListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      materialRequests: [],
      showLoader: true,
      pageDetails: { eventsPerPage: 10, totalEvents: 0 },
      searchState: "",
    };

    this.changePage = this.changePage.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const result = await this.props.getMaterialRequests(this.props.page, this.state.searchTerm, this.state.searchState);
    const paramsString = this.props.location?.search;
    const params = new URLSearchParams(paramsString);
    const status = params.get("view");
    if (status) {
      this.searchByStatus(status);
    }
    if (result.data && result.data.events.length === 0) {
      window.location.href = "/emarketplace/request/new";
    }
    this.setState({
      showLoader: false,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const filteredRequests = nextProps?.EMRequests?.emRequests ? nextProps.EMRequests.emRequests.events : [];
    if (!_.isEmpty(nextProps.updatedEvents.updatedEvent)) {
      const index = _.findIndex(filteredRequests, (o) => o.id === nextProps.updatedEvents.updatedEvent.id);
      filteredRequests.splice(index, 1, nextProps.updatedEvents.updatedEvent);
    }
    if (nextProps.location?.search === "") {
      this.setState({ searchTerm: "" });
    }
    this.setState({
      materialRequests: filteredRequests,
      pageDetails: nextProps.EMRequests.emRequests ? nextProps.EMRequests.emRequests.pageDetails : {},
    });
    if (nextProps.setListingParameters.flag) {
      this.searchBoxInput.value = "";
      this.setState(
        {
          searchTerm: "",
          searchState: "",
        },
        () => {
          this.props.getMaterialRequests(this.props.page, this.state.searchTerm, this.state.searchState);
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.emptyCurrentState("change_event_state", {});
  }

  setStateForSearch() {
    setTimeout(() => {
      this.setState({ searchTerm: this.searchBoxInput.value });
    }, 1);

    if (this.props.page > 1) {
      window.history.pushState({}, null, `/emarketplace/?page=1`);
      this.setState({ page: 1 });
    }
  }

  async changePage(page) {
    window.location.href = `/emarketplace?page=${page}`;
  }

  handleSearchChange(e) {
    if (e.key === "Enter") {
      this.setStateForSearch();
      this.props.getMaterialRequests(1, e.target.value, "");
    } else if (e.target.value.length === 0) {
      this.setState({ searchTerm: "", searchState: "" });
      this.props.getMaterialRequests(1, e.target.value, "");
    }
  }

  searchUpdated(e) {
    e.preventDefault();
    this.setStateForSearch();
    this.props.getMaterialRequests(1, this.searchBoxInput.value, "");
    this.setState({ searchState: "" });
  }

  searchByStatus(requestState) {
    const stateValue = requestState === "closed" ? "closed=true" : "current=true";
    this.setState({ searchState: stateValue });
    if (this.props.page > 1) {
      window.location.href = '/emarketplace?page=1';
    }
    this.props.getMaterialRequests(1, this.searchBoxInput.value, stateValue);
  }

  confirmation(EMRequest, mrState) {
    let popupContext = "";
    if (mrState === "closed") {
      popupContext = this.props.translations.emarketplace.pop_up.confirm_popup_close;
    } else {
      popupContext = this.props.translations.emarketplace.pop_up.confirm_popup_reopen;
    }
    let pop_up_translations = {
      ConfirmLabel: this.props.translations.pop_up.confirm,
      okLabel: this.props.translations.pop_up.ok,
      cancelLabel: this.props.translations.pop_up.cancel,
    };
    confirm(popupContext, pop_up_translations).then(
      () => {
        this.props.changeEventState(EMRequest.id, mrState);
        if (this.state.searchTerm) {
          this.setState({ searchTerm: "" });
          if (this.props.page > 1) {
            window.location.href = '/emarketplace?page=1';
          }
        }
      },
      () => {
        // `cancel` callback
      }
    );
    return false;
  }

  render() {
    const { page, translations } = this.props;
    const { materialRequests, pageDetails } = this.state;
    const searchedRequests = materialRequests.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
    const perPage = parseInt(pageDetails.eventsPerPage, 10);
    const pages = Math.ceil(pageDetails.totalEvents / perPage);
    const stateValue =
      this.state.searchTerm.toLowerCase() === "current" || this.state.searchTerm.toLowerCase() === "closed";
    return (
      <div className="top-content">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-4 event-head p-0 mobile-padding">
              <h4>
                {stateValue
                  ? translations.emarketplace.requests[this.setState.searchTerm.toLowerCase()]
                  : translations.emarketplace.emlisting.material_request}
              </h4>
            </div>
            <div className="col-xs-12 col-md-8 p-0 mobile-padding">
              <div className="form-group col-md-9 col-sm-9 p-0">
                <div className="form-group col-md-9 col-sm-9 p-0">
                  <div className="input-search">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder={translations.emarketplace.emlisting.search_for_request}
                        className="form-control placeholder search-input"
                        ref={(el) => {
                          this.searchBoxInput = el;
                        }}
                        onKeyUp={(e) => {
                          this.handleSearchChange(e);
                        }}
                      />
                      <span className="input-group-btn">
                        <a
                          className="btn btn-default"
                          href="#"
                          onClick={(e) => {
                            this.searchUpdated(e);
                          }}
                        >
                          <i className="fa fa-search" aria-hidden="true" />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group col-md-3 col-sm-3 pull-right p-r0">
                <a href={"/emarketplace/request/new"} className="btn btn-primary btn-fixed pull-right">
                  <i className="fa fa-bullhorn">
                    <span>+</span>
                  </i>{" "}
                  {translations.emarketplace.emlisting.new_request}
                </a>
              </div>
            </div>
            <div className="clear-box" />
            <div className="col-md-12 p-0 m-t20 mobile-padding clear-both">
              <Loader
                show={this.state.showLoader}
                message={<LoaderMessage loaderText={translations.loader_text} />}
                disableDefaultStyles
              >
                <EMListTable
                  translations={translations}
                  tooltipData={this.props.tooltip_translations}
                  searchedRequests={searchedRequests}
                  page={page}
                  pages={pages}
                  perPage={perPage}
                  searchByStatus={(requestState) => {
                    this.searchByStatus(requestState);
                  }}
                  confirmation={(request, state) => {
                    this.confirmation(request, state);
                  }}
                  showLoader={this.state.showLoader}
                  company={this.props.company}
                />
                {pageDetails.totalEvents > perPage && (
                  <PaginationContainer activePage={page} pages={pages} changePage={this.changePage} />
                )}
              </Loader>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  EMRequests: state.emListingReducer,
  selectedLang: state.changeLanguageReducer,
  page: Number(new URLSearchParams(window.location?.search).get("page")) || 1,
  setListingParameters: state.setListingParametersReducer,
  updatedEvents: state.updateEventStateReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getMaterialRequests: (page, searchKey, state) => dispatch(getMaterialRequests(page, searchKey, state)),
  emptyCurrentState: (actionType, payload) => dispatch(emptyCurrentState(actionType, payload)),
  changeEventState: (requestId, state) => dispatch(changeEventState(requestId, state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EMListing);
