import type {
  Branch,
  Leaf,
  Node,
  NormalizedBranch,
  NormalizedNode,
  NormalizedTree,
  TextParameter,
  MultiKind,
} from "@/slices/rules/types";
import { checkNode, checkNodeState, Comparison, Operation } from "@/slices/rules/types";
import { v4 } from "uuid";
import { assert } from "@/utils/assert";
import type { Draft } from "immer";
import type { NormalizedRules } from "@/slices/rules";

export const normalizeNode = (node: Node, tree: NormalizedTree = {}, parentUuid?: string): NormalizedTree => {
  if (checkNode.isLeaf(node)) {
    tree[node.uuid] = {
      ...node,
      parentUuid,
    };
    return tree;
  }
  assert(checkNode.isBranch(node), "Node is not a leaf or a branch");
  const [left, right] = node.state.child_nodes;
  // normalize the children first
  normalizeNode(left, tree, node.uuid);
  normalizeNode(right, tree, node.uuid);
  tree[node.uuid] = {
    ...node,
    parentUuid,
    state: {
      ...node.state,
      child_nodes: [left.uuid, right.uuid],
    },
  };
  return tree;
};

export const denormalizeNode = (node: NormalizedNode, tree: NormalizedTree): Node => {
  if (checkNode.isLeaf(node)) {
    // we need to strip this out of the node
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { parentUuid, ...rest } = node;
    return rest;
  }
  assert(checkNodeState.isBranch(node.state), "Node is not a leaf or a branch");
  const {
    state,
    // we need to strip this out of the node
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parentUuid,
    ...rest
  } = node;
  const [left, right] = state.child_nodes;
  const leftNode = tree[left];
  const rightNode = tree[right];
  assert(leftNode, "Left node not found");
  assert(rightNode, "Right node not found");
  return {
    ...rest,
    state: {
      ...state,
      child_nodes: [denormalizeNode(leftNode, tree), denormalizeNode(rightNode, tree)],
    },
  };
};

export const makeLeaf = (uuid = v4()): NormalizedNode<Leaf> => ({
  uuid,
  negated: false,
  state: {
    comparison: "Eq",
    field_name: "",
    leaf_parameter: {
      count: 0,
      parameters: [],
    },
  },
});

/**
 * Creates a branch node with two children
 */
export const aBranchOf = (left: Node, right: Node): Node<Branch> => ({
  uuid: v4(),
  negated: false,
  state: {
    operation: Operation.And,
    child_nodes: [left, right],
  },
});

/**
 * Recursively nests nodes into a branch
 * @example
 * const one = makeLeaf();
 * const two = makeLeaf();
 * const three = makeLeaf();
 * const branch = aNodeIncluding(one, two, three);
 * expect(branch).toEqual(aBranchOf(aBranchOf(one, two), three))
 */
export const aNodeIncluding = (...nodes: Node[]) => nodes.reduce(aBranchOf);

export const applyMultiKind = (state: Draft<Leaf<TextParameter>>, kind: MultiKind) => {
  switch (kind) {
    case "any":
      state.comparison = Comparison.Gte;
      state.leaf_parameter.count = 1;
      break;
    case "all":
      state.comparison = Comparison.Eq;
      state.leaf_parameter.count = state.leaf_parameter.parameters.length;
      break;
    case "none":
      state.comparison = Comparison.Eq;
      state.leaf_parameter.count = 0;
      break;
    default:
      // we don't know what to do with this kind
      throw new Error(`Unknown multi kind: ${String(kind)}`);
  }
};

/**
 * Nests an indicated node under a new branch, and add a new sibling leaf node.
 */
export const addCondition = (
  state: Draft<NormalizedRules>,
  { currentId, newSiblingId, newParentId }: Record<"currentId" | "newSiblingId" | "newParentId", string>
) => {
  const currentNode = state.nodes[currentId];
  assert(currentNode, "Node not found");

  const newSibling = makeLeaf(newSiblingId);
  state.nodes[newSiblingId] = newSibling;

  const newBranch: NormalizedNode<NormalizedBranch> = {
    uuid: newParentId,
    parentUuid: currentNode.parentUuid,
    negated: false,
    state: {
      operation: Operation.And,
      child_nodes: [currentId, newSiblingId],
    },
  };
  state.nodes[newParentId] = newBranch;

  if (currentNode.parentUuid) {
    const parent = state.nodes[currentNode.parentUuid];
    assert(parent, "Parent not found");
    assert(checkNode.isBranch(parent), "Parent is not a branch");
    const idx = parent.state.child_nodes.indexOf(currentId);
    assert(idx !== -1, "Current node not found in parent's children");
    // replace the current node with the new branch
    parent.state.child_nodes[idx] = newParentId;
  } else {
    state.rootId = newParentId;
  }

  // make sure the new nodes have the correct parent
  currentNode.parentUuid = newParentId;
  newSibling.parentUuid = newParentId;
};

/**
 * Promotes a sibling node to the parent's position, and deletes the parent node.
 */
const promoteSibling = (
  state: Draft<NormalizedRules>,
  ids: {
    grandparent?: string;
    sibling: string;
    parent: string;
  }
) => {
  delete state.nodes[ids.parent];

  const sibling = state.nodes[ids.sibling];
  assert(sibling, "Sibling not found");
  if (!ids.grandparent) {
    state.rootId = ids.sibling;
    delete sibling.parentUuid;
    return;
  }
  const grandparent = state.nodes[ids.grandparent];
  assert(grandparent, "Grandparent not found");
  assert(checkNode.isBranch(grandparent), "Grandparent is not a branch");
  // replace the parent node with the sibling
  const idx = grandparent.state.child_nodes.indexOf(ids.parent);
  assert(idx !== -1, "Parent not found in grandparent's children");
  grandparent.state.child_nodes[idx] = ids.sibling;
  sibling.parentUuid = grandparent.uuid;
};

/**
 * Removes a node and promotes its sibling to the parent's position.
 */
export const removeCondition = (state: Draft<NormalizedRules>, id: string) => {
  const currentNode = state.nodes[id];
  assert(currentNode, "Node not found");
  delete state.nodes[id];

  if (!currentNode.parentUuid) {
    assert(state.rootId === id, "Root node not found");
    state.rootId = null;
    state.nodes = {};
    return;
  }

  const parent = state.nodes[currentNode.parentUuid];
  assert(parent, "Parent not found");
  assert(checkNode.isBranch(parent), "Parent is not a branch");

  const [leftId, rightId] = parent.state.child_nodes;

  promoteSibling(state, {
    sibling: leftId === id ? rightId : leftId,
    parent: parent.uuid,
    grandparent: parent.parentUuid,
  });
};

/**
 * Recursively iterates through the tree from a given node and expands it and all parent nodes.
 */
export const expandParents = (
  state: Draft<NormalizedRules & { collapsed: Partial<Record<string, true>> }>,
  id: string
) => {
  delete state.collapsed[id];
  let parentId = state.nodes[id]?.parentUuid;
  while (parentId) {
    delete state.collapsed[parentId];
    parentId = state.nodes[parentId]?.parentUuid;
  }
};
