import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import EMShippingDetails from "./EMShippingDetails";
import createMaterialRequest from "../../../../../actions/qq/emarketplace/createMaterialRequestActions";
import { showToasterMessages } from "../../../../../actions/qq/genericActions";
import TrixEditor from "@/components/common/TrixEditor";

class EMForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materialLot: [],
      category: this.props.category,
      subCategory: this.props.subCategory,
      shippingDetails: {
        brief: null,
        address: null,
        deadline: null,
        earliestDeliveryDate: null,
        latestDeliveryDate: null,
      },
      validateDetails: false,
      language: this.props.selectedLang,
      selectedAttrOfLots: {},
      anotherOptionLots: [],
      anotherOption: false,
    };
  }
  UNSAFE_componentWillMount() {
    this.createLot();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.selectedLang !== this.state.language) {
      this.setState({
        language: newProps.selectedLang,
      });
    } else {
      this.setState(
        {
          category: newProps.category,
          subCategory: newProps.subCategory,
          materialLot: [],
        },
        () => {
          this.createLot();
        }
      );
    }
  }

  onChangeEditor(content, lotIndex) {
    this.setState({ [`editorValue_${lotIndex}`]: content });
    this.refs[`error_message_another_option_${lotIndex}`].hidden = true;
  }

  async removeLot(lotIndex) {
    const newLots = this.state.materialLot.filter((item) => item.lotIndex !== lotIndex);
    const selectedAttr = this.state.selectedAttrOfLots;
    delete selectedAttr[lotIndex];
    this.setState({
      materialLot: newLots,
      selectedAttrOfLots: selectedAttr,
    });
  }

  async createLot() {
    const lotIndex = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);

    const attrWithData = [];
    if (
      this.state.subCategory.marketplace_attributes.length &&
      this.state.subCategory.marketplace_attribute_data.length
    ) {
      await this.state.subCategory.marketplace_attributes.forEach(async (attr) => {
        const attrObject = {};
        const data = [];
        const checkList = [];
        attrObject.attr = attr;
        this.state.subCategory.marketplace_attribute_data.forEach((attrData) => {
          const dataValue = attrData.data[attr.id].trim();
          if (checkList.indexOf(dataValue) === -1) {
            if (dataValue !== undefined) {
              data.push(dataValue);
              checkList.push(dataValue);
            }
          }
        });
        attrObject.data = data.sort(this.sortByDataString);
        attrWithData.push(attrObject);
      });
      const lotObject = {};
      lotObject.lotIndex = lotIndex;
      lotObject.lotData = attrWithData;
      const { materialLot } = this.state;
      materialLot.push(lotObject);
      this.setState({
        materialLot,
        anotherOption: false,
      });
    } else {
      const lotObject = {};
      lotObject.lotIndex = lotIndex;
      lotObject.lotData = attrWithData;
      const { materialLot, anotherOptionLots } = this.state;
      anotherOptionLots.push(lotIndex);
      materialLot.push(lotObject);
      this.setState({
        materialLot,
        anotherOption: true,
        anotherOptionLots,
      });
    }
  }

  sortByDataString(vala, valb, _order) {
    if (Number(vala) && Number(valb)) {
      return Number(vala) - Number(valb);
    }
    const regex = /^[0-9 /.]*$/;
    const checkFraction = regex.test(vala) && regex.test(valb);
    if (checkFraction) {
      try {
        const evaluatedValA = eval(vala.replace(" ", "+"));
        const evaluatedValB = eval(valb.replace(" ", "+"));
        return evaluatedValA - evaluatedValB;
      } catch (e) {
        return vala.localeCompare(valb, undefined, { numeric: true });
      }
    }
    return vala.localeCompare(valb, undefined, { numeric: true });
  }

  async filterFunction(attributeId, attributeData, lotIndex) {
    let otherAttrData = attributeData;
    const refString = `${attributeId}ofLot${lotIndex}`;
    otherAttrData = await otherAttrData.sort(this.sortByDataString);
    const isCustom = $(`#${refString} option[value="custom"]`).val();
    const selectedVal = $(`#${refString}`).children(":selected").val();
    $(`#${refString}`).children('[value!="reset"]').remove();
    otherAttrData.forEach((value) => {
      $(`#${refString}`).append(new Option(value, value));
    });
    if (isCustom) {
      $(`#${refString}`).append(new Option("custom", "custom"));
    }
    $(`#${refString}`).val(selectedVal);
  }

  async handleChange(attrRef, attrId, lotIndex) {
    const refThis = this;
    refThis.refs[`error_message${attrRef}`].hidden = true;
    const currentAttrValue = refThis.refs[attrRef].value;

    const selectedAttr = this.state.selectedAttrOfLots;
    let allSelectedAttr = selectedAttr[lotIndex] ? selectedAttr[lotIndex] : [];
    allSelectedAttr = await allSelectedAttr.filter((item) => item.id !== attrId);

    if (currentAttrValue !== "custom" && currentAttrValue !== "reset") {
      if (refThis.refs[`custom${attrRef}`]) {
        refThis.refs[`custom${attrRef}`].hidden = true;
      }
      const NewAttributes = await this.state.subCategory.marketplace_attributes.filter((attr) => attr.id !== attrId);
      await NewAttributes.forEach(async (attribute) => {
        const isAlreadySelectedAttribute = await allSelectedAttr.filter((item) => item.id === attribute.id);
        const newSelectedAttributes =
          isAlreadySelectedAttribute.length > 0
            ? await allSelectedAttr.filter((item) => item.id !== attribute.id)
            : allSelectedAttr;
        const otherAttrData = [];
        await this.state.subCategory.marketplace_attribute_data.forEach(async (dataItem) => {
          let checkValue = 0;
          const dataValue = dataItem.data[attribute.id].trim();
          if (dataItem.data[attrId].trim() === currentAttrValue) {
            if (newSelectedAttributes.length) {
              await newSelectedAttributes.forEach(async (sAttr) => {
                if (dataItem.data[sAttr.id].trim() === sAttr.value) {
                  checkValue += 1;
                }
              });
              if (checkValue === newSelectedAttributes.length && otherAttrData.indexOf(dataValue) === -1) {
                otherAttrData.push(dataValue);
              }
            } else if (otherAttrData.indexOf(dataValue) === -1) {
              otherAttrData.push(dataValue);
            }
          }
        });
        await this.filterFunction(attribute.id, otherAttrData, lotIndex);
      });

      const checkSAttr = await allSelectedAttr.filter((item) => item.id !== attrId);
      const sAttrObj = {};
      sAttrObj.id = attrId;
      sAttrObj.value = currentAttrValue;
      checkSAttr.push(sAttrObj);
      selectedAttr[lotIndex] = checkSAttr;
      this.setState({
        selectedAttrOfLots: selectedAttr,
      });
    } else if (currentAttrValue === "reset" || currentAttrValue === "custom") {
      if (currentAttrValue === "custom") {
        refThis.refs[`custom${attrRef}`].hidden = false;
      } else if (refThis.refs[`custom${attrRef}`]) {
        refThis.refs[`custom${attrRef}`].hidden = true;
        refThis.refs[`custom${attrRef}`].value = "";
      }
      const NewAttributes = await this.state.subCategory.marketplace_attributes;
      await NewAttributes.forEach(async (attribute) => {
        const newSelectedAttributes = await allSelectedAttr.filter((item) => item.id !== attribute.id);
        const otherAttrData = [];
        await this.state.subCategory.marketplace_attribute_data.forEach(async (dataItem) => {
          let checkValue = 0;
          const dataValue = dataItem.data[attribute.id].trim();
          if (newSelectedAttributes.length) {
            await newSelectedAttributes.forEach(async (sAttr) => {
              if (dataItem.data[sAttr.id].trim() === sAttr.value) {
                checkValue += 1;
              }
            });
            if (checkValue === newSelectedAttributes.length && otherAttrData.indexOf(dataValue) === -1) {
              otherAttrData.push(dataValue);
            }
          } else if (otherAttrData.indexOf(dataValue) === -1) {
            otherAttrData.push(dataValue);
          }
        });
        await this.filterFunction(attribute.id, otherAttrData, lotIndex);
      });
      selectedAttr[lotIndex] = allSelectedAttr;
      this.setState({
        selectedAttrOfLots: selectedAttr,
      });
    }
  }

  handleCustomInputChange(attrRef) {
    const refThis = this;
    if (`custom${attrRef}`) {
      refThis.refs[`error_message${attrRef}`].hidden = true;
    }
  }

  handleQuantityChange(event, lotIndex) {
    const refThis = this;
    const quantity = event.target.value.toString();
    const RegexQ = /^\d*[1-9]\d*$/;
    if (RegexQ.test(quantity)) {
      refThis.refs[`error_message_quantityofLot${lotIndex}`].hidden = true;
    } else {
      refThis.refs[`error_message_quantityofLot${lotIndex}`].hidden = false;
    }
  }

  async submitRequest() {
    const refThis = this;
    /* API defines the type of lot component according to this array */
    const lotComponentTypes = ["is_price", "is_text", "is_decimal", "is_date", "is_picklist"];
    refThis.disabled = true;
    const data = { lots: [] };
    let checkErrorLot = null;
    const { anotherOptionLots } = this.state;
    const { earliestDeliveryDate, latestDeliveryDate } = this.state.shippingDetails;
    this.state.materialLot.forEach((lot) => {
      const lotData = [];
      const custom = [];
      if (anotherOptionLots.indexOf(lot.lotIndex) === -1) {
        lot.lotData.forEach((item) => {
          const attrRef = refThis.refs[`${item.attr.name}ofLot${lot.lotIndex}`];
          if (attrRef.value && attrRef.value !== "custom" && attrRef.value !== "reset") {
            lotData.push({
              name: item.attr.name,
              attribute_value: attrRef.value,
              lot_component_type: lotComponentTypes.indexOf("is_text"),
            });
          } else if (attrRef.value === "custom") {
            const customValue = refThis.refs[`custom${item.attr.name}ofLot${lot.lotIndex}`].value;
            if (customValue.trim()) {
              custom.push(item.attr.name);
              lotData.push({
                name: item.attr.name,
                attribute_value: customValue,
                lot_component_type: lotComponentTypes.indexOf("is_text"),
              });
            } else {
              checkErrorLot = lot.lotIndex;
              refThis.refs[`error_message${item.attr.name}ofLot${lot.lotIndex}`].hidden = false;
            }
          } else {
            checkErrorLot = lot.lotIndex;
            refThis.refs[`error_message${item.attr.name}ofLot${lot.lotIndex}`].hidden = false;
          }
        });
      } else {
        const editorData = this.state[`editorValue_${lot.lotIndex}`];
        lotData.push({
          name: "description",
          attribute_value: this.state[`editorValue_${lot.lotIndex}`],
          lot_component_type: lotComponentTypes.indexOf("is_text"),
        });
        if (!editorData || this.state[`editorValue_${lot.lotIndex}`].trim() === "") {
          refThis.refs[`error_message_another_option_${lot.lotIndex}`].hidden = false;
          checkErrorLot = lot.lotIndex;
        }
      }
      const quantity = refThis.refs[`quantityOf${lot.lotIndex}`].value;
      const RegexQ = /^\d*[1-9]\d*$/;
      if (RegexQ.test(quantity)) {
        refThis.refs[`error_message_quantityofLot${lot.lotIndex}`].hidden = true;
      } else {
        refThis.refs[`error_message_quantityofLot${lot.lotIndex}`].hidden = false;
        checkErrorLot = lot.lotIndex;
      }
      if (earliestDeliveryDate && latestDeliveryDate) {
        lotData.push({
          name: "earliest delivery date",
          attribute_value: earliestDeliveryDate,
          lot_component_type: lotComponentTypes.indexOf("is_date"),
        });
        lotData.push({
          name: "latest delivery date",
          attribute_value: latestDeliveryDate,
          lot_component_type: lotComponentTypes.indexOf("is_date"),
        });
      }
      const lotValues = { lot_components: lotData, quantity, custom };
      data.lots.push(lotValues);
    });
    if (!this.state.validateDetails) {
      this.setState({
        validateDetails: false,
      });
    } else {
      data.event_brief = this.state.shippingDetails.brief;
      data.postcode = this.state.shippingDetails.address;
      data.deadline = this.state.shippingDetails.deadline;
      data.participant_emails = this.state.shippingDetails.selectedParticipants.join(",");
    }

    if (checkErrorLot === null && this.state.validateDetails) {
      data.category_id = this.state.category.id;
      data.sub_category_id = this.state.subCategory.id;
      data.emarketplace = true;
      const result = await this.props.createRequest(data, this.props.translations.emarketplace.material_create_success);
      if (result && result.status === 201) {
        window.location.href = "/emarketplace";
      }
    }
    return true;
  }

  updateShippingDetails(shippingDetails) {
    if (
      shippingDetails.brief &&
      shippingDetails.address &&
      shippingDetails.deadline &&
      shippingDetails.addressUpdated &&
      shippingDetails.earliestDeliveryDate &&
      shippingDetails.latestDeliveryDate &&
      shippingDetails.selectedParticipants.length
    ) {
      this.setState({
        shippingDetails,
        validateDetails: shippingDetails.addressUpdated,
      });
    } else {
      this.setState({
        shippingDetails,
        validateDetails: false,
      });
    }
  }

  enableAnotherOption(event, lotIndex) {
    event.preventDefault();
    const refThis = this;
    refThis.refs[`attributes_${lotIndex}`].hidden = true;
    refThis.refs[`another_${lotIndex}`].hidden = false;
    refThis.refs[`another_cancel_${lotIndex}`].hidden = false;
    $(`.${lotIndex}`).each((_, errorMessage) => {
      errorMessage.hidden = true;
    });
    const { anotherOptionLots } = this.state;
    if (anotherOptionLots.indexOf(lotIndex) === -1) {
      anotherOptionLots.push(lotIndex);
    }
    refThis.setState({
      anotherOptionLots,
    });
    event.target.hidden = true;
  }

  disableAnotherOption(event, lotIndex) {
    event.preventDefault();
    const refThis = this;
    refThis.refs[`attributes_${lotIndex}`].hidden = false;
    refThis.refs[`another_${lotIndex}`].hidden = true;
    refThis.refs[`another_button_${lotIndex}`].hidden = false;
    refThis.refs[`error_message_another_option_${lotIndex}`].hidden = true;
    event.target.hidden = true;
    let { anotherOptionLots } = this.state;
    anotherOptionLots = anotherOptionLots.filter((idx) => idx !== lotIndex);
    refThis.setState({
      anotherOptionLots,
    });
  }

  showEditorError(wordLimit) {
    this.props.showToasterMessages("SHOW_ERROR", this.props.translations.emarketplace.pasted_text_error + wordLimit);
  }

  render() {
    const selfThis = this;
    const { anotherOption } = this.state;
    const { translations } = this.props;
    const lotList = this.state.materialLot.length ? (
      this.state.materialLot.map((lot) => (
        <div ref={`lot${lot.lotIndex}`} key={`lot${lot.lotIndex}`} className="details_form_box">
          <span className="title active">{translations.emarketplace.product_details}</span>
          <div className="prd_details">
            {!anotherOption && (
              <div ref={`attributes_${lot.lotIndex}`} className="each_row clear attributes">
                {lot.lotData.map((item) => (
                  <div key={item.attr.id} className="form_hodler">
                    <label className="attribute-text">{item.attr.name}</label>
                    {item.attr.type === "dropdown" ? (
                      <div className="input_box">
                        <select
                          onChange={() => {
                            selfThis.handleChange(`${item.attr.name}ofLot${lot.lotIndex}`, item.attr.id, lot.lotIndex);
                          }}
                          className="form-control"
                          ref={`${item.attr.name}ofLot${lot.lotIndex}`}
                          id={`${item.attr.id}ofLot${lot.lotIndex}`}
                          defaultValue="reset"
                        >
                          <option value="reset">{translations.emarketplace.select_a_value}</option>
                          {item.data.map((data) => (
                            <option value={data} key={`lot${lot.lotIndex}${data}`}>
                              {data}
                            </option>
                          ))}
                        </select>
                        <div
                          hidden
                          className={`text-danger error-message ${lot.lotIndex}`}
                          ref={`error_message${item.attr.name}ofLot${lot.lotIndex}`}
                        >
                          <span>{translations.emarketplace.select_box_error}</span>
                        </div>
                      </div>
                    ) : (
                      <div className="input_box two custom">
                        <select
                          onChange={(e) => {
                            selfThis.handleChange(`${item.attr.name}ofLot${lot.lotIndex}`, item.attr.id, lot.lotIndex);
                          }}
                          className="form-control"
                          ref={`${item.attr.name}ofLot${lot.lotIndex}`}
                          id={`${item.attr.id}ofLot${lot.lotIndex}`}
                          defaultValue="reset"
                        >
                          <option value="reset">{translations.emarketplace.select_a_value}</option>
                          {item.data.map((data) => (
                            <option key={`lot${lot.lotIndex}${data}`}>{data}</option>
                          ))}
                          <option value="custom">{translations.emarketplace.custom}</option>
                        </select>
                        <input
                          className="m-t10"
                          ref={`custom${item.attr.name}ofLot${lot.lotIndex}`}
                          onChange={() => {
                            this.handleCustomInputChange(`${item.attr.name}ofLot${lot.lotIndex}`);
                          }}
                          type="text"
                          hidden
                        />
                        <div
                          hidden
                          className={`text-danger error-message ${lot.lotIndex}`}
                          ref={`error_message${item.attr.name}ofLot${lot.lotIndex}`}
                        >
                          <span>{translations.emarketplace.select_box_error}</span>
                        </div>
                        <input className="hidden" type="text" name="" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div ref={`another_${lot.lotIndex}`} className="each_row clear other-editor" hidden={!anotherOption}>
              <div className="form-group">
                <TrixEditor
                  value={this.state[`editorValue_${lot.lotIndex}`]}
                  onChange={(content) => this.onChangeEditor(content, lot.lotIndex)}
                />
                <div className="text-danger error-message" ref={`error_message_another_option_${lot.lotIndex}`} hidden>
                  {this.state[`editorValue_${lot.lotIndex}`] &&
                  this.state[`editorValue_${lot.lotIndex}`].trim() === "" ? (
                    <span>{translations.error_message.no_white_space}</span>
                  ) : (
                    <span>{translations.emarketplace.other_option_error}</span>
                  )}
                </div>
              </div>
            </div>
            {!anotherOption && (
              <div className="text-right">
                <a
                  className="other-option"
                  ref={`another_button_${lot.lotIndex}`}
                  href="#"
                  onClick={(e) => {
                    this.enableAnotherOption(e, lot.lotIndex);
                  }}
                >
                  {translations.emarketplace.other_option_text}
                </a>
                <a
                  className="other-option-cancel"
                  ref={`another_cancel_${lot.lotIndex}`}
                  href="#"
                  onClick={(e) => {
                    this.disableAnotherOption(e, lot.lotIndex);
                  }}
                  hidden
                >
                  {translations.emarketplace.cancel}
                </a>
              </div>
            )}
            <div>
              <hr />
              <div className="each_row clear">
                <div className="form_hodler">
                  <label>{translations.emarketplace.quantity}</label>
                  <div className="input_box">
                    <input
                      className="quantity-postal-ip"
                      type="text"
                      ref={`quantityOf${lot.lotIndex}`}
                      onChange={(e) => {
                        this.handleQuantityChange(e, lot.lotIndex);
                      }}
                    />
                    <div
                      className={`text-danger error-message ${lot.lotIndex}`}
                      ref={`error_message_quantityofLot${lot.lotIndex}`}
                      hidden
                    >
                      <span>{translations.emarketplace.quantity_error}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="each_row clear single pdetails-btn">
              <div className="form_hodler">
                <div className="input_box btn_box_holder">
                  <input
                    type="button"
                    name=""
                    value={translations.emarketplace.add_more}
                    className="btn add_more_btn"
                    onClick={() => {
                      this.createLot();
                    }}
                  />
                  <input
                    type="button"
                    name=""
                    value={translations.emarketplace.remove}
                    className="btn remove_btn"
                    onClick={() => {
                      this.removeLot(lot.lotIndex);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <div className="details_form_box">
        <div className="prd_details">
          <div className="each_row clear text-center">{translations.emarketplace.no_attributes}</div>
        </div>
      </div>
    );
    return (
      <div>
        <div className="form_show_box">
          <div className="prd_details_add_box count_box_product">
            <div className="category_title">
              {_.isEmpty(this.state.category) ? "" : this.state.category.name}-{" "}
              {_.isEmpty(this.state.subCategory) ? "" : this.state.subCategory.name}
            </div>
            <div className="details_holder_form">
              <form name="prd_details">{lotList}</form>
            </div>
          </div>
        </div>
        {_.isEmpty(this.state.subCategory) || this.state.materialLot.length === 0 ? (
          ""
        ) : (
          <div>
            <EMShippingDetails
              translations={translations}
              category={this.state.category}
              subCategory={this.state.subCategory}
              shippingDetails={(e) => {
                this.updateShippingDetails(e);
              }}
              validateDetails={this.state.validateDetails}
              submitRequest={(e) => this.submitRequest(e)}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLang: state.changeLanguageReducer,
});

const mapDispatchToProps = (dispatch) => ({
  createRequest: (data, message) => dispatch(createMaterialRequest(data, message)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EMForm);
