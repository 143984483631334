import React from "react";
import PropTypes from "prop-types";

/**
 * Prop types for the StickyHeader
 */
const StickyHeaderPropTypes = {
  /**
   * All of the table data cells that will be rendered inside of a table row.
   * All the elements MUST be a `td` so we have valid HTML.
   */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /**
   * The classes that will be added to the table row
   */
  className: PropTypes.string,
};

/**
 * @type {React.FC<PropTypes.InferProps<StickyHeaderPropTypes>>}
 */
export const StickyHeader = ({ children, className }) => {
  return (
    <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
      <tr style={{ display: "flex", justifyContent: "space-between" }} className={className}>
        {children}
      </tr>
    </thead>
  );
};

StickyHeader.propTypes = StickyHeaderPropTypes;

export default StickyHeader;
