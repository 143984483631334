import React from "react";

const Footer = (props) => {
  return (
    <div className="modal-footer">
      <a className="btn btn-default pull-right" onClick={() => props.setIsOpen(false)}>
        {props.translations.ok}
      </a>
    </div>
  );
};

export default Footer;
