import React, { Fragment, Component } from "react";

import BasicDetails from "./BasicDetails";
import ContractValue from "./ContractValue";
import ContractUserField from "./ContractUserField";
import ContractDatesAndAlerts from "./ContractDatesAndAlerts";
import CustomNotifications from "./CustomNotifications";
import DocumentUploadType from "./DocumentUploadType";

class NewEditPageConfiguration extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      componentName,
      contractEditPageFields,
      companyId,
      currentUserEditConfigPermission,
      translations,
      documentFileTypes,
    } = this.props;
    const contractEditFieldsData = contractEditPageFields.data;
    return (
      <Fragment>
        <h1 className="sheet-title">{translations.new_edit_page}</h1>
        <div className="col-md-12 col-sm-12">
          <div className="block-item btn-group-sm same-height-block">
            <BasicDetails
              basicDetails={contractEditFieldsData.basic_details}
              companyId={companyId}
              columnId={contractEditPageFields.id}
              translations={translations}
              currentUserEditConfigPermission={currentUserEditConfigPermission}
            />
          </div>
        </div>
        <ContractValue
          contractValues={contractEditFieldsData.contract_value}
          companyId={companyId}
          columnId={contractEditPageFields.id}
          translations={translations}
          currentUserEditConfigPermission={currentUserEditConfigPermission}
        />
        <ContractUserField
          contractUserFields={contractEditFieldsData.contract_user_fields}
          companyId={companyId}
          columnId={contractEditPageFields.id}
          translations={translations}
          currentUserEditConfigPermission={currentUserEditConfigPermission}
        />

        <div className="col-md-12 col-sm-12">
          <div className="block-item btn-group-sm same-height-block">
            <div className="title">{translations.document_upload_types}</div>
            <DocumentUploadType
              componentName={componentName}
              companyId={companyId}
              currentUserEditConfigPermission={currentUserEditConfigPermission}
              translations={translations}
              documentFileTypes={documentFileTypes}
            />
          </div>
        </div>
        <ContractDatesAndAlerts
          contractDates={contractEditFieldsData.dates}
          companyId={companyId}
          columnId={contractEditPageFields.id}
          translations={translations}
          currentUserEditConfigPermission={currentUserEditConfigPermission}
        />
        <CustomNotifications
          notification={contractEditFieldsData.notification}
          companyId={companyId}
          columnId={contractEditPageFields.id}
          translations={translations}
          currentUserEditConfigPermission={currentUserEditConfigPermission}
        />
      </Fragment>
    );
  }
}

export default NewEditPageConfiguration;
