import cookies from 'browser-cookies';

import * as apiCall from './api';
import config from './config';
import * as globalActionTypes from "../actionsTypes";
import actionTypes from './actionsTypes';

export const createQQEventSuccess = (qqEvent) => ({
  type: actionTypes.create_qq_event_success,
  payload: qqEvent,
});

export const fetchQuoteByIdSuccess = (detail) => ({
  type: actionTypes.fetch_quote_by_id,
  payload: detail,
});

export const fetchLotsSuccess = (lots) => ({
  type: actionTypes.fetch_lots_by_id,
  payload: lots,
});

export const fetchEventParticipantsSuccess = (eventParticipants) => ({
  type: actionTypes.fetch_event_participants_by_id,
  payload: eventParticipants,
});

export const QQEventsSuccess = (qqEvents) => ({
  type: actionTypes.fetch_qq_events,
  payload: qqEvents,
});

export const fetchConfigurationLimitSuccess = (limits) => ({
  type: actionTypes.fetch_configuration_limit,
  payload: limits,
});

const getError = (error) => ({
  type: globalActionTypes.ERROR_CODE,
  payload: error,
});

const fetchEventHostsSuccess = (hosts) => ({
  type: actionTypes.fetch_event_hosts,
  payload: hosts,
});

export const createQQEvent = (qqEvent) => (dispatch) => (
  apiCall.post(`${config.BASE_URL}/events`, qqEvent)
    .then((response) => {
      dispatch(createQQEventSuccess(response.data.event));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const fetchQuoteById = (quoteId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/events/${quoteId}`)
    .then((response) => {
      dispatch(fetchQuoteByIdSuccess(response.data.event));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);

export const getQQEvents = (page, searchKey, state) => (dispatch) => {
  const stateParam = state.length ? `&${state}` : '';
  const inSandpit = cookies.get('sandpit') ? cookies.get('sandpit') : false;
  const url = (searchKey.length || state.length)
    ? `${config.BASE_URL
    }/events/search?quick_quotes=true${stateParam}&for_listing=true&page=${page}&search_key=${escape(searchKey.trim())
    }&sandpit_event=${inSandpit}` : `${config.BASE_URL}/events?quick_quotes=true&sandpit_event=${inSandpit}&page=${page}`;

  return apiCall.get(url, { headers: config.headers })
    .then((response) => {
      dispatch(QQEventsSuccess({
        events: response.data.events,
        pageDetails: { eventsPerPage: response.headers['x-per-page'], totalEvents: response.headers['x-total'] },
      }));
    })
    .catch((error) => {
      dispatch(getError(error));
    });
};

export const fetchLots = (quoteId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/events/${quoteId}/lots`)
    .then((response) => {
      dispatch(fetchLotsSuccess(response.data.lots));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const fetchEventParticipants = (quoteId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/events/${quoteId}/event_participants`)
    .then((response) => {
      dispatch(fetchEventParticipantsSuccess(response.data.event_participants));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const fetchConfigurationLimit = (companyId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/companies/${companyId}/qq_configuration_limits`)
    .then((response) => {
      dispatch(fetchConfigurationLimitSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);

export const fetchEventHosts = (quoteId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/quotes/${quoteId}/hosts`)
    .then((response) => {
      dispatch(fetchEventHostsSuccess(response.data));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const fetchPipelineProjectsSuccess = (pipelines) => ({
  type: actionTypes.fetch_pipeline_projects,
  payload: pipelines,
});

export const fetchPipelineProjects = (params) => (dispatch) => (
  apiCall
    // eslint-disable-next-line max-len
    .get(`/company_integrations/${params.company_integration_id}/user_integrations/${params.user_integration_attribute_id}/per_angusta_api/budgets/search?search_key=${params.search}`)
    .then((response) => {
      dispatch(fetchPipelineProjectsSuccess(response.data.results));
      return response.data.results;
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);

export const mutatedEvents = (removedEventId) => ({
  type: actionTypes.mutated_events,
  payload: removedEventId,
});

export const setCurrencies = (payload) => ({
  type: actionTypes.currencies,
  payload,
});

export const fetchCurrencies = (userId) => (dispatch) => (
  apiCall
    .get(`/api/v1/users/${userId}/all_currencies`)
    .then((response) => {
      dispatch(setCurrencies(response.data));
      return response;
    })
    .catch((er) => dispatch(getError(er)))
);
