import React, { Component } from "react";
import { connect } from "react-redux";

import Tooltip from "../../../common/Tooltip";
import { addUser } from "../../../../actions/adminActions";

export class AddUserModal extends Component {
  constructor(props) {
    super(props);
    this.handleAddUser = this.handleAddUser.bind(this);
    this.state = {
      disableAddUserButton: false,
    };
  }

  componentDidMount() {
    this.addTagItToTextBox();
  }

  componentDidUpdate() {
    this.addTagItToTextBox();
  }

  async handleAddUser(e) {
    this.setState({
      disableAddUserButton: true,
    });
    const { companyId, categoryDojo } = this.props;
    const userEmails = this.userEmails.value;
    await this.props.addUser(companyId, userEmails, this.props.userPermissions, categoryDojo);
    $("#array_tag_handler").tagit("removeAll");
    this.setState({
      disableAddUserButton: false,
    });
  }

  render() {
    const { translations, categoryDojo, currentUser } = this.props;
    const { addUserInfo } = translations.infos;
    return (
      <div
        id="addUserModal"
        className="modal fade modal-dialog-popout top-position"
        role="dialog"
        style={{ display: "none" }}
        data-backdrop={!categoryDojo}
      >
        <div className="modal-dialog modal-dialog-popout">
          <div className="modal-content" id="pop-custom-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">{translations.addUser}</h4>
            </div>
            <div className="modal-body">
              <div className="alert alert-info alert-dismissable">{translations.addUserInfo}</div>
              <div className="input">
                <label htmlFor="user_emails">
                  <Tooltip title={addUserInfo.title} body={addUserInfo.body} currentUser={currentUser} />
                  &nbsp;
                  {translations.userEmail}
                </label>
                <div className="markup">
                  <input type="hidden" id="user_emails" ref={(ref) => (this["userEmails"] = ref)} />
                  <ul id="array_tag_handler" />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                data-dismiss="modal"
                type="button"
                className="btn btn-sm btn-default"
                onClick={() => $("#array_tag_handler").tagit("removeAll")}
              >
                {translations.cancel}
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className={`btn btn-sm btn-primary ${categoryDojo ? "btn-default" : ""}`}
                disabled={this.state.disableAddUserButton}
                onClick={(e) => this.handleAddUser(e)}
              >
                {translations.addUser}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  usersForAutocomplete() {
    const { allUsers, userPermissions } = this.props;
    const userPermissionsUsersIds = userPermissions.map((up) => {
      return up.user.id;
    });
    const remainingUsers = allUsers.filter((u) => {
      return !userPermissionsUsersIds.includes(u.id);
    });
    return remainingUsers;
  }

  addTagItToTextBox() {
    const _ = this;
    const availableUserEmails = this.usersForAutocomplete().map((u) => u.email);
    $("#array_tag_handler").tagit({
      availableTags: availableUserEmails,
      singleField: true,
      singleFieldNode: $("#user_emails"),
    });
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.adminReducers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addUser: (companyId, userEmails, userPermissions, categoryDojo) =>
    dispatch(addUser(companyId, userEmails, userPermissions, categoryDojo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
