import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { MESSAGE_ON_LIVE_FEED } from "@/actions/actionsTypes";
import { markAsReadToMessage, sendMessage } from "@/actions/messageActions";

import { closeModal } from "@/components/messages/sendMessageModal";
import { markAsReadPayload } from "../../monitor/ShowUpdateMessages";
import { useMessagePayload } from "./useMessage";

// get all the states that are used in useForm
const formStateSelection = createSelector(
  ({ lotReducers }) => lotReducers.event_participants,
  ({ messageReducers }) => messageReducers.showModal,
  (eventParticipants, showModal) => ({ eventParticipants, showModal })
);

/**
 *
 * @param {boolean} marketDojo
 * @param {Array<number>} selectedParticipants
 * @returns {Array<number>} selected participants
 */
export const useInitialValue = (marketDojo, selectedParticipants) => {
  const { eventParticipants = [], showModal } = useSelector(formStateSelection);
  const { isNewMessage, replyUserId } = showModal;

  /**
   * List of all initially selected participants
   *
   */
  if (marketDojo) return [...(selectedParticipants ?? []), replyUserId].filter(Boolean);

  if ((!isNewMessage && eventParticipants[0]["contact_ids"]?.includes(replyUserId)) || isNewMessage)
    return [replyUserId ?? eventParticipants[0]?.lead_participant?.contact_id];

  return [replyUserId].filter(Boolean);
};

/**
 *
 * @param {Array<number>} selectedParticipants
 * @param {boolean} marketDojo
 */
export const useForm = (selectedParticipants, marketDojo) => {
  const [formState, setFromState] = useState({
    message: "",
    selectedParticipantIds: useInitialValue(marketDojo, selectedParticipants),
    selectedDocumentIds: [],
    quickSelect: [],
  });
  const [isHostTabSelected, setIsHostTabSelected] = useState(false);

  /**
   * Handle tab selection
   * @param {string} tab
   * @returns {void}
   */
  const selectTabs = (tab) => setIsHostTabSelected(tab === "Host");

  /**
   * Set formState
   *
   * @param {object | ()=>({})} state
   * @returns {void}
   */
  const setState = (state) =>
    setFromState((formState) => ({ ...formState, ...(typeof state === "function" ? state(formState) : state) }));

  const handleSendMessage = useSubmitHandler(formState, marketDojo, setState);

  return {
    formState,
    isHostTabSelected,
    setState,
    selectTabs,
    handleSendMessage,
  };
};

// get all the states that are used in useSubmitHandler
const submitHandlerStateSelection = createSelector(
  ({ lotReducers }) => lotReducers.event,
  ({ lotReducers }) => lotReducers.translations.messageCreated,
  ({ lotReducers }) => lotReducers.in_sandpit,
  ({ lotReducers }) => lotReducers.current_user,
  ({ messageReducers }) => messageReducers.showModal,
  (event, messageCreated, inSandpit, currentUser, showModal) => ({
    event,
    messageCreated,
    inSandpit,
    currentUser,
    showModal,
  })
);

/**
 *
 * @param {object} formState
 * @param {boolean} marketDojo
 * @param {()=> void} setState
 */
const useSubmitHandler = (formState, marketDojo, setState) => {
  const { event, messageCreated, inSandpit, currentUser, showModal } = useSelector(submitHandlerStateSelection);
  const dispatch = useDispatch();
  const getPayload = useMessagePayload(marketDojo, formState);

  const { messageReplyId, modalId } = showModal;

  /**
   * Handle message send
   * @param {React.MouseEvent<HTMLElement>} e
   * @param {boolean | undefined} isConfirmedByHost
   */
  return async (e, isConfirmedByHost) => {
    e.persist();
    e.target.disabled = true;

    const messages = getPayload(e, isConfirmedByHost);

    await dispatch(sendMessage(event.id, { messages }, messageCreated, !marketDojo))
      .then(({ status, data }) => {
        handleSuccess(e, status, isConfirmedByHost);
        setState({ message: "" });

        if (messageReplyId) dispatch(markAsReadToMessage(messageReplyId, markAsReadPayload(inSandpit, currentUser)));

        dispatch({
          type: MESSAGE_ON_LIVE_FEED,
          payload: data.message,
        });
      })
      .finally(() => closeModal(modalId));
  };
};

const handleSuccess = (e, status, isConfirmedByHost) => {
  if (status !== 201) {
    e.target.disabled = false;
    return;
  }

  if (isConfirmedByHost) window.location.reload();
};
