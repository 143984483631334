import React from 'react';
import Loader from 'react-loader-advanced';
import { connect } from 'react-redux';
import { showToasterMessages } from '../../../../actions/qq/genericActions';
import LoaderMessage from '../../LoaderMessage';

const initialState = (mutator = {}) => ({
  showLoader: true,
  ...mutator,
});

class SourcingPipeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState();
  }

  UNSAFE_componentWillReceiveProps(props, state) {
    if (props.event_pa_integration_attributes) {
      this.setState({
        showLoader: false,
      });
    }
  }

  generatePALink = (budget_id) => {
    const { serialized_user } = this.props;
    return `${window.location.protocol}//${window.location.host}/company_integrations/${serialized_user.per_angusta_attributes.company_integration_id}/user_integrations/${serialized_user.per_angusta_attributes.user_integration_id}/per_angusta_api/en-budgets-${budget_id}`;
  }

  render() {
    const { showLoader } = this.state;
    const { event_pa_integration_attributes, translations } = this.props;
    return (
      <Loader show={ showLoader } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles priority={ 1 }>
        <div className='panel panel-default message-panel'>
          <div className='panel-heading' role='tab'>
            <h4 className='panel-title'>
              <a
                className='collapsed'
                role='button'
                data-toggle='collapse'
                href='#sourcingPipeline'
              >
                {translations.qq_create.qq_generate_details.sourcing_pipeline}
              </a>
            </h4>
          </div>
          <div id='sourcingPipeline' className='panel-collapse collapse'>
            <div className='panel-body'>
              <ul className='list-group'>
                <li className='list-group-item'>
                  <strong>
                  {translations.qq_create.qq_generate_details.budget_name}:
                  </strong>
                  {' '}
                  <a href={this.generatePALink(event_pa_integration_attributes.integration_attributes.id)}>
                    {event_pa_integration_attributes.integration_attributes.name}
                  </a>
                </li>
                <li className='list-group-item'>
                  <strong>
                  {translations.qq_create.qq_generate_details.budget_reference_code}:
                  </strong>
                  {' '}
                  {event_pa_integration_attributes.integration_attributes.reference_code}
                </li>
                <li className='list-group-item'>
                  <strong>
                  {translations.qq_create.qq_generate_details.budget_external_id}:
                  </strong>
                  {' '}
                  {event_pa_integration_attributes.integration_attributes.external_id}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedLang: state.changeLanguageReducer,
  // user: state.userReducer,
  quoteDetail: state.qqReducer,
  // per_angusta_attributes: state.userReducer.details.per_angusta_attributes
});

const mapDispatchToProps = (dispatch) => ({
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SourcingPipeline);
