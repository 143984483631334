import React from "react";
import { NumericFormat } from "react-number-format";
import { t } from "@/i18n";

/**
 * Number format for the price value. This wraps the react-number-format
 * component and uses the local settings from our translations to format the
 * number correctly.
 */
function PriceValue({ value, currencySymbol }: { value: number; currencySymbol: string }) {
  const currency_position = t("number.currency.format.currency_position");

  return (
    <NumericFormat
      thousandSeparator={t("number.currency.format.delimiter")}
      decimalSeparator={t("number.currency.format.separator")}
      value={value}
      displayType="text"
      decimalScale={parseInt(t("number.currency.format.precision"))}
      fixedDecimalScale={true}
      prefix={currency_position === "prefix" ? currencySymbol : ""}
      suffix={currency_position === "suffix" ? currencySymbol : ""}
    />
  );
}

export default PriceValue;
