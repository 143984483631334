import React from "react";
import PropTypes from "prop-types";

/**
 * Table call component, this has the same API as `react-sticky-table` but will
 * render a `td` element not a div so it can be used in a table
 */
export const Cell = ({ children, ...props }) => {
  return <td {...props}>{children}</td>;
};

Cell.propTypes = {
  /**
   * The content of the table cell
   */
  children: PropTypes.node,
};

export default Cell;
