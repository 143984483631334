import React, { Component } from 'react';
import { connect } from 'react-redux';
import DropzoneComponent from 'react-dropzone-component';
import _ from 'lodash';
import Loader from 'react-loader-advanced';
import cookies from 'browser-cookies';

import EditorPopupSection from '../QQEvent/EditorPopupSection';
import DocumentLibraryModal from '../QQEvent/DocumentLibraryModal';
import { confirm } from '../../util/confirm';
import config from '../../js/config';
import PopupMobile from '../QQEvent/PopupMobile';
import AlreadyUploadedDocuments from '../QQEvent/AlreadyUploadedDocuments';

import { fetchAdminText } from '../../../../actions/qq/adminTextActions';
import { updateDocument, addNewDocument, deleteDocument } from '../../../../actions/qq/editActions';
import LoaderMessage from '../../LoaderMessage';
import { fetchDocuments } from '../../../../actions/qq/genericActions';

class Documents extends Component {
  constructor() {
    super();
    this.djsConfig = {
      addRemoveLinks: true,
      autoProcessQueue: false,
    };

    this.componentConfig = {
      iconFiletypes: ['.jpg', '.png', '.gif'],
      showFiletypeIcon: false,
      postUrl: `${config.DOMAIN_URL}/quick_quotes/uploadHandler`,
    };
    this.state = {
      adminText: '',
      data: [],
      dropZoneArea: true,
      files: [],
      disableUpdateBtn: false,
      disableAddBtn: false,
      userDocuments: [],
      participantDocuments: [],
      exceedMaxFile: true,
      alreadyUploaded: []
    };

    this.success = (file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const obj = {};
        obj.name = file.name;
        obj.source = reader.result;
        const files = this.state.files.concat(obj);
        this.setState({ files });
      };
    };

    this.callback = async (file) => {
      if (this.dropzone.files.length <= this.dropzone.options.maxFiles) {
        let totalFileSize = 0;
        this.state.userDocuments.map((element) => {
          totalFileSize += element.size;
          return element;
        });
        this.dropzone.files.map((fileObj) => {
          totalFileSize += fileObj.size;
          return fileObj;
        });
        const singleDocumentSize = this.props.configurations.limits.single_document_size;
        const TotalSize = this.props.configurations.limits.total_all_documents_size;
        if (file.size >= 1024 * 1024 * singleDocumentSize) {
          this.msg.error(this.props.translations.error_message.attachment_size_too_big.replace("%{attachment_size_too_big}", singleDocumentSize));
          this.dropzone.removeFile(file);
          return false;
        }

        if (totalFileSize >= (1024 * 1024 * TotalSize)) {
          this.msg.error(this.props.translations.error_message.event_total_documents_size_too_big.replace("%{total_all_documents_size}", TotalSize));
          this.dropzone.removeFile(file);

          return false;
        }

        this.success(file);
      }
    };

    this.removedfile = (file) => {
      const { files } = this.state;
      if (this.state.exceedMaxFile) {
        _.remove(files, { name: file.name });
        this.setState({ files });
      } else {
        this.setState({ exceedMaxFile: true });
      }
    };

    this.dropzone = null;
  }

  async UNSAFE_componentWillMount() {
    const {
      sectionKey, companyId, userId, quoteId, user,
    } = this.props;
    const documents = await this.props.fetchDocuments(quoteId);
    const userDocuments = [];
    const participantDocuments = [];
    if (documents.status === 200) {
      _.map(documents.data.documents, (doc) => {
        if (user.id === doc.user_id) {
          userDocuments.push(doc);
        } else {
          participantDocuments.push(doc);
        }
      });
    }

    const documentLibraryuploads = JSON.parse(localStorage.getItem('documentLibraryuploads'));
    this.setState({ alreadyUploaded: documentLibraryuploads.documents });

    this.props.fetchAdminText(companyId, sectionKey);
    this.setState({ userDocuments, participantDocuments });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText.FETCH_ADMIN_TEXT_DOCUMENTS ? newProps.adminText.FETCH_ADMIN_TEXT_DOCUMENTS : '';
    if (newProps.default.FETCH_ADMIN_TEXT_DOCUMENTS_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_DOCUMENTS_DEL;
    }
    this.setState({
      adminText,
    });
  }

  onNameChange(e, index) {
    e.preventDefault();
    const { value } = e.target;
    const tempData = _.cloneDeep(this.state.userDocuments);
    tempData[index].name_of_document = value;
    this.setState({ userDocuments: tempData });
  }

  editRow(index, flag) {
    const selfThis = this;
    selfThis.refs[`document_name_off_${index}`].hidden = flag;
    selfThis.refs[`document_name_on_${index}`].hidden = !flag;
    selfThis.refs[`document_action_off_${index}`].hidden = flag;
    selfThis.refs[`document_action_on_${index}`].hidden = !flag;
    if (!flag) {
      const userDocuments = _.cloneDeep(this.state.userDocuments);
      this.setState({ userDocuments });
    }
  }

  async uploadFiles() {
    const { quoteId } = this.props;
    const { alreadyUploaded, files } = this.state;
    this.setState({ disableAddBtn: true });
    this.dropzone.processQueue();
    const payload = {
      documents: [...files, ...alreadyUploaded],
      event_id: quoteId,
    };
    const response = await this.props.addNewDocument(payload);
    if (response.status === 201) {
      let documents = _.cloneDeep(this.state.userDocuments);
      documents = documents.concat(response.data.documents);
      this.setState({ userDocuments: documents, dropZoneArea: false, files: [] });
    }
    localStorage.setItem('documentLibraryuploads', JSON.stringify({
      documents: [],
    }));
    this.setState({ disableAddBtn: false, dropZoneArea: true, alreadyUploaded: [] });
  }

  async updateDocument(docData, index) {
    const payload = {
      documents: { name: docData.name_of_document },
    };
    this.setState({ disableUpdateBtn: true });
    const response = await this.props.updateDocumentName(docData.id, payload);
    if (response.status === 200) {
      const documents = _.cloneDeep(this.state.userDocuments);
      documents[index] = response.data.document;
      this.setState({ userDocuments: documents });
      this.editRow(index, false);
    }
    this.setState({ disableUpdateBtn: false });
  }

  confirmationDelete(index, docsData, stateKey) {
    let pop_up_translations = {
      ConfirmLabel: this.props.translations.pop_up.confirm,
      okLabel: this.props.translations.pop_up.ok,
      cancelLabel: this.props.translations.pop_up.cancel,
    };
    confirm(this.props.translations.pop_up.confirm_popup_delete, pop_up_translations).then(async () => {
      const response = await this.props.deleteDocument(docsData.id);
      if (response.status === 204) {
        const documents = _.cloneDeep(this.state[stateKey]);
        documents.splice(index, 1);
        this.setState({ [stateKey]: documents });
      }
    }, () => { /* cancel */ });
  }

  RefreshDocuments( alreadyUploaded ){
    this.setState({
      alreadyUploaded,
      disableAddBtn: false
    });
  }

  render() {
    const { companyId, sectionKey, configurations, translations } = this.props;
    const {
      adminText, dropZoneArea, disableUpdateBtn, disableAddBtn, files, userDocuments, participantDocuments, alreadyUploaded
    } = this.state;
    let documentLimit = 0;
    if (configurations.limits) {
      documentLimit = configurations.limits.qq_documents_limit - userDocuments.length;
      documentLimit = documentLimit < 0 ? 0 : documentLimit;
    }
    const dropzoneConfig = this.componentConfig;
    const djsCon = this.djsConfig;
    let dropzoneFileLength = this.dropzone ?  this.dropzone.files.length : 0;
    let dropzoneFileAndDocumentLibraryCount = dropzoneFileLength +  alreadyUploaded.length;
    let dropzoneDocumentLimit = documentLimit - alreadyUploaded.length;
    djsCon.maxFiles = dropzoneDocumentLimit;
    const eventHandlers = {
      init: (dz) => {
        this.dropzone = dz;
      },
      maxfilesexceeded: (file) => {
        if (this.msg.state.alerts.length === 0) {
          this.msg.error(this.props.translations.error_message.document_maxfile_alert);
        }
        this.setState({ exceedMaxFile: false });
        this.dropzone.removeFile(file);
      },
      addedfile: this.callback,
      success: this.success,
      removedfile: this.removedfile,
    };

    return (
      <Loader show={ !this.state.adminText } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles priority={ 1 }>
        <div className='block-item'>
          <div className='col-md-6 p-l0 table-cell-box'>
            <div className='form-item-block bg-white'>
              <h4>{translations.summary_status.participant_list.documents}</h4>
              <PopupMobile
                companyId={ companyId }
                sectionKey={ sectionKey }
                text={ this.state.adminText }
                translations={ translations }
              />
              <div className='table-scroll event-list'>
                <table className='common-table table-align-top'>
                  <thead className='table-block-title'>
                    {userDocuments.length > 0 && (
                      <tr>
                        <th className='document-col-one'>{translations.qq_edit.document_name}</th>
                        <th>&nbsp;</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {userDocuments.length > 0 && (
                    <tr className='host-head'>
                      <td colSpan='2' className='table-section-head text-left'>{translations.qq_edit.host_documents}</td>
                    </tr>
                  )}
                    {userDocuments.map((userDoc, index) => (
                      <tr key={ userDoc.id }>
                        <td className='document-col-one'>
                          <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.document_name}</div>
                          <div ref={ `document_name_off_${index}` }>
                            <a href={ `${userDoc.url}?in_sandpit=${cookies.get('sandpit')}` } className='download-document' target='_blank'>{userDoc.name}</a>
                          </div>
                          <div hidden ref={ `document_name_on_${index}` }>
                            <input
                              type='text'
                              value={ userDoc.name_of_document ? userDoc.name_of_document : '' }
                              onChange={ e => this.onNameChange(e, index) }
                              placeholder={ translations.qq_edit.name }
                              className='form-control'
                            />
                            {userDoc.name_of_document && userDoc.name_of_document.trim().length > 255 && (
                            <span className='text-danger'>
                              {translations.error_message.name_char_long.replace("%{name_string}", translations.qq_edit.document_name).replace("%{name_limit}", 255)}
                            </span>
                          )}
                          </div>
                        </td>
                        <td className='text-right document-col-two'>
                          <div ref={ `document_action_off_${index}` }>
                            <button className='icon-link' onClick={ () => this.editRow(index, true) }>
                              <i className='fa fa-pencil-square-o' aria-hidden='true' />
                            </button>
                            <button className='icon-link' onClick={ () => this.confirmationDelete(index, userDoc, 'userDocuments') }>
                              <i className='fa fa-trash-o' aria-hidden='true' />
                            </button>
                          </div>
                          <div hidden ref={ `document_action_on_${index}` }>
                            <button
                              className='icon-link'
                              disabled={ disableUpdateBtn || (userDoc.name_of_document && userDoc.name_of_document.trim().length > 255) }
                              onClick={ () => this.updateDocument(userDoc, index) }
                            >
                              <i className='fa fa-check' aria-hidden='true' />
                            </button>
                            <button className='icon-link' onClick={ () => this.editRow(index, false) }>
                              <i className='fa fa-times' aria-hidden='true' />
                            </button>
                          </div>
                        </td>
                      </tr>
                  ))}
                    {participantDocuments.length > 0 && (
                    <tr className='participant-head'>
                      <td colSpan='2' className='table-section-head text-left'>{translations.qq_edit.participant_documents}</td>
                    </tr>
                  )}
                    {participantDocuments.map((participantDoc, idx) => (
                      <tr key={ participantDoc.id }>
                        <td>
                          <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.document_name}</div>
                          <div>
                            <a href={ participantDoc.url } className='download-document' target='_blank'>{participantDoc.name}</a>
                          </div>
                        </td>
                        <td className='text-right'>
                          {!participantDoc.questionnaire_questions_answer_id && (
                          <div ref={ `document_action_off_${idx}` }>
                            <button
                              className='icon-link'
                              onClick={ () => this.confirmationDelete(idx, participantDoc, 'participantDocuments') }
                            >
                              <i className='fa fa-trash-o' aria-hidden='true' />
                            </button>
                          </div>
                        )}
                        </td>
                      </tr>
                  ))}
                    {dropZoneArea && (
                    <tr className='bg-gray'>
                      <td colSpan='3' className='table-section-head'>
                        <DropzoneComponent config={ dropzoneConfig } eventHandlers={ eventHandlers } djsConfig={ djsCon }>
                          <div className='dz-message'>{translations.qq_create.qq_documents.drop_or_click_to_upload}</div>
                        </DropzoneComponent>
                        <div className='form-group col-md-2 m-t20'>
                          <button
                            type='button'
                            className='btn btn-primary cancel-reply m-r5'
                            disabled={ disableAddBtn || ( files.length === 0 && alreadyUploaded.length === 0 ) }
                            onClick={ () => this.uploadFiles() }
                          >
                            {translations.qq_edit.upload}
                          </button>
                        </div>
                        <div className='form-group col-md-3'>
                          <DocumentLibraryModal
                            companyId={ companyId }
                            parentComponent='Documents'
                            docsLimit={ documentLimit }
                            dropzoneFileAndDocumentLibraryCount={ dropzoneFileAndDocumentLibraryCount }
                            RefreshDocuments={ (data) => this.RefreshDocuments(data) }
                            translations={ translations }
                          />
                        </div>
                        <AlreadyUploadedDocuments
                          alreadyUploaded={ alreadyUploaded }
                          parentComponent='Documents'
                          RefreshDocuments={ (data) => this.RefreshDocuments(data) }
                          labelText={translations.qq_edit.already_included}
                        />
                      </td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <EditorPopupSection 
            text={ adminText }
            companyId={ companyId }
            sectionKey={ sectionKey }
            translations={ translations }
            updateText={ (adminText) => { this.setState({ adminText }); } }
          />
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  adminText: state.adminTextReducer,
  selectedLang: state.changeLanguageReducer,
  default: state.deleteAdminTextReducer,
  configurations: state.qqReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchDocuments: quoteId => dispatch(fetchDocuments(quoteId)),
  fetchAdminText: (companyId, sectionKey) => dispatch(fetchAdminText(companyId, sectionKey)),
  updateDocumentName: (documentId, payload) => dispatch(updateDocument(documentId, payload)),
  addNewDocument: payload => dispatch(addNewDocument(payload)),
  deleteDocument: documentId => dispatch(deleteDocument(documentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
