import { ColumnTypeCD } from "@/common-prop-types/custom-field";
import features from "@/utils/features";
import { t } from "@/i18n";

export const ModuleStatus = Object.freeze({
  Available: 1,
  Mandatory: 2,
  Inactive: 3,
});

export const ModuleGroups = Object.freeze({
  Status: 1,
  DisplayAsColumn: 2,
  ShowInInvite: 3,
});

export const Modules = Object.freeze({
  SIM: 1,
  CONTRACTS: 3,
  SRM: 2,
  SOURCING: 4,
});

export const ModulePrefixes = Object.freeze({
  [Modules.CONTRACTS]: "Contract",
  [Modules.SRM]: "Srm",
  [Modules.SIM]: "Sim",
  [Modules.SOURCING]: "Event",
});

export const ModuleTypes = Object.freeze({
  [Modules.CONTRACTS]: ["Contract"],
  [Modules.SRM]: ["Srm::Account", "Srm::Contact"],
  [Modules.SIM]: ["SimDojo::EventParticipant"],
  [Modules.SOURCING]: ["Event"],
});

export const AvailableModuleGroups = {
  [Modules.SIM]: [ModuleGroups.Status, ModuleGroups.DisplayAsColumn, ModuleGroups.ShowInInvite],
  [Modules.CONTRACTS]: [ModuleGroups.Status, ModuleGroups.DisplayAsColumn],
  [Modules.SRM]: [ModuleGroups.Status],
  [Modules.SOURCING]: [ModuleGroups.Status],
};

/**
 * Control how the modules will look in the UI
 */
export const useModuleUiConfig = () => {
  const modules = [
    { module: Modules.SOURCING, label: t("sourcing") },
    { module: Modules.SIM, label: t("sim") },
    { module: Modules.CONTRACTS, label: t("contracts") },
    { module: Modules.SRM, label: t("qq_translations.header.srm") },
  ];

  if (features.disabled("sourcing_custom_fields")) {
    // Remove the sourcing module if the feature is disabled. We are doing this
    // in reverse so we can add the sourcing module at top a little easier. It
    // will also leave better code ben the feature switch is removed by the
    // codemod.
    const index = modules.findIndex(({ module }) => module === Modules.SOURCING);
    if (index > -1) {
      modules.splice(index, 1);
    }
  }

  return modules;
};

export const useInputTypeOptions = () => {
  return [
    { value: ColumnTypeCD.TEXT, label: t("text") },
    { value: ColumnTypeCD.NUMERIC, label: t("number_field") },
    { value: ColumnTypeCD.DATE, label: t("date_field") },
    { value: ColumnTypeCD.CHECKBOX, label: t("checkbox") },
    { value: ColumnTypeCD.PICK_ONE_FROM_LIST, label: t("centralised_custom_fields.pick_one_from_list") },
    { value: ColumnTypeCD.MULTIPLE_CHOICE, label: t("centralised_custom_fields.multiple_choice") },
  ];
};

const statusOptions = (t) => [
  {
    value: 1,
    label: t("centralised_custom_fields.available"),
    status: ModuleStatus.Available,
    group: ModuleGroups.Status,
  },
  {
    value: 2,
    label: t("centralised_custom_fields.mandatory"),
    status: ModuleStatus.Mandatory,
    group: ModuleGroups.Status,
  },
  {
    value: 3,
    label: t("centralised_custom_fields.inactive"),
    status: ModuleStatus.Inactive,
    group: ModuleGroups.Status,
  },
];

const displayAsColumnOptions = (t) => [
  {
    value: 4,
    label: t("centralised_custom_fields.display"),
    status: ModuleStatus.Available,
    group: ModuleGroups.DisplayAsColumn,
  },
  {
    value: 5,
    label: t("centralised_custom_fields.do_not_display"),
    status: ModuleStatus.Inactive,
    group: ModuleGroups.DisplayAsColumn,
  },
];

const showInInviteOptions = (t) => [
  {
    value: 6,
    label: t("centralised_custom_fields.show"),
    status: ModuleStatus.Available,
    group: ModuleGroups.ShowInInvite,
  },
  {
    value: 7,
    label: t("centralised_custom_fields.do_not_show"),
    status: ModuleStatus.Inactive,
    group: ModuleGroups.ShowInInvite,
  },
];

export const useModuleOptions = () => {
  return {
    [ModuleGroups.Status]: {
      label: t("centralised_custom_fields.status"),
      group: ModuleGroups.Status,
      options: statusOptions(t),
    },
    [ModuleGroups.DisplayAsColumn]: {
      label: t("centralised_custom_fields.display_as_column"),
      group: ModuleGroups.DisplayAsColumn,
      options: displayAsColumnOptions(t),
    },
    [ModuleGroups.ShowInInvite]: {
      label: t("centralised_custom_fields.show_in_invite"),
      group: ModuleGroups.ShowInInvite,
      options: showInInviteOptions(t),
    },
  };
};
