import { createSlice } from "@reduxjs/toolkit";
import actionsTypes from "@/actions/actionsTypes";
import { setLotStatus } from "@/actions/bidActions";
import { createBidLineItemComponent, setValidationErrors } from "@/actions/bidLineItemComponentActions";
import { getUpdatedValue } from "@/components/advanced_lots/lots/lotCommon";
import { uniqBy } from "lodash";
import { getEskerAuthToken, regenerateLots } from "@/actions/lotActions";
import { liveMessageMarkedAsRead, messageOnLiveFeed } from "@/reducers/lot-reducers/liveFeedMessages";
import initialState from "@/reducers/lotReducersInitialState";
import { safeAssign } from "@/utils";
import { componentType } from "@/components/advanced_lots/common";
import { t } from "@/i18n";

const keyArray = (id, array) => array.reduce((current, item) => ({ ...current, [item[id]]: item }), {});
const merge = (id, ...arrays) =>
  Object.values(
    arrays.reduce((current, item) => {
      return { ...current, ...keyArray(id, item) };
    }, {})
  );

/**
 * Apply a lot status to the lotReducers state
 *
 * @param {typeof import("./lotReducersInitialState").default} state
 * @param {number | string} lotId
 * @param {string} status
 *
 * @returns {typeof import("./lotReducersInitialState").default}
 */
const withLotStatus = (state, lotId, status) => {
  return {
    ...state,
    lotStatus: {
      ...state.lotStatus,
      [lotId]: status,
    },
  };
};

/**
 * Updates the error messages for a lot item component. If no messages are
 * found then the lot item is valid and all error mesasges are removed. A set
 * of legacy error arrays are maintained to keep BC with the rest of the
 * components
 * @type {LotsCaseReducer}
 */
const setValidationErrorsReducer = (state, action) => {
  const { lotId, lineItemId, lineItemComponentId, messages } = action.payload;

  const lotItemComponentErrors = (state.lotItemComponentErrors ??= {});
  const lotItemComponentErrorsForId = (lotItemComponentErrors[lotId] ??= {});

  if (messages && messages.length > 0) {
    lotItemComponentErrorsForId[lineItemComponentId] = {
      lineItemId,
      lineItemComponentId,
      messages,
    };
  } else {
    delete lotItemComponentErrorsForId[lineItemComponentId];
  }

  const lineItemIdsWithError = Object.keys(lotItemComponentErrors).filter(
    (key) => Object.keys(lotItemComponentErrors[key] ?? {}).length
  );

  // Maintain legacy error objects in state. We need to start using the more
  // detailed `lotItemComponentErrors` that we can get error messages all the
  // way down to a lot component.
  //
  // TODO(ade): Create a redux middle are warning up about use of out dated
  // state manipulations in development
  if (lineItemIdsWithError.length) {
    (state.lotErrors ??= {})[lotId] = lineItemIdsWithError;
  }
  state.errors = Object.values(lotItemComponentErrors)
    .map((item) => Object.keys(item))
    .flat();
};

/**
 * Reducer function for when a request has completed. Will remove the line item
 * component from the pending state.
 */
const createBidLineItemComponentFinish = (state, action) => {
  const { lotId, lineItemComponentId } = action.payload;
  delete state.lineItemComponentPendingState?.[lotId]?.[lineItemComponentId];
};

/**
 * Error function to handle errors in requests. Error messages are populated
 * and the lot item component will be removed from the pending state.
 */
const createBidLineItemComponentError = (state, { error, meta: { arg } }) => {
  createBidLineItemComponentFinish(state, {
    payload: {
      lotId: arg.lotId,
      lineItemComponentId: arg.lineItemComponentId,
    },
  });

  setValidationErrorsReducer(state, {
    payload: {
      lotId: arg.lotId,
      lineItemId: arg.lineItemId,
      lineItemComponentId: arg.lineItemComponentId,
      messages: [error.message],
    },
  });
};

/**
 * Called when a request on a lot item component is stated. Puts the lot item
 * component into a pending state in the store.
 */
const createBidLineItemComponentPending = (state, action) => {
  const args = action?.meta?.arg;
  if (typeof args === "undefined") {
    return;
  }

  const current = (state.lineItemComponentPendingState ??= {});
  const { lotId, lineItemComponentId } = args;

  (current[lotId] ??= {})[lineItemComponentId] = true;
};

const deleteWarningIfExist = (lineItemComponentWarnings, lotId, lineItemComponentId) => {
  if (lineItemComponentWarnings[lotId] && lineItemComponentId in lineItemComponentWarnings[lotId]) {
    delete lineItemComponentWarnings[lotId][lineItemComponentId];
  }
};

/**
 * Updates the warning message for a line item component. If the blic price is
 * not 0 then the line item is valid and the warning mesasge is removed. We also
 * wanna remove the warning if there is any error for the line item component.
 */
const setValidationWarningsReducer = (lineItemComponentWarnings, payload) => {
  const { lotId, lineItemId, lineItemComponentId } = payload;
  const bidLineItemComponent = payload.data.bid_line_item_components.find(
    (blic) => blic.line_item_component_id === payload.lineItemComponentId
  );

  // We will not get `bidLineItemComponent` if there is any error in the lic.
  // So just remove any warning if it has.
  if (!bidLineItemComponent || Number(bidLineItemComponent.price) !== 0) {
    return deleteWarningIfExist(lineItemComponentWarnings, payload.lotId, payload.lineItemComponentId);
  }

  const lineItemComponentWarningsForId = (lineItemComponentWarnings[lotId] ??= {});
  lineItemComponentWarningsForId[lineItemComponentId] = {
    lineItemId,
    lineItemComponentId,
    message: t("partial_bid_zero_value_warning"),
  };
};

/**
 * Update any new incoming bid line item components and merge them with the
 * existing line of bid line item components. Any errors that come back from
 * the server will be populated in the store so they can be displayed to the
 * user.
 */
const updateBlicList = (state, { payload }) => {
  const lot_total_blics = merge("bid_id", state.lot_total_blics || [], [payload.data.lot_total_blic]);
  const bids = merge("id", state.bids || [], [payload.data.bid]);
  const bid_line_item_components = merge(
    "id",
    state.bid_line_item_components,
    payload.data.bid_line_item_components,
    payload.data.calculation_blics
  );

  setValidationErrorsReducer(state, {
    payload: {
      lotId: payload.lotId,
      lineItemId: payload.lineItemId,
      lineItemComponentId: payload.lineItemComponentId,
      messages: payload.data.errors,
    },
  });

  // I think this is the best place to do this because this is just a warning
  // and it's not gonna stop you from bidding. So it's better to do when we
  // update any blic.
  const lineItemComponent = state.line_item_components.find((lic) => lic.id === payload.lineItemComponentId);
  const pricelineItem = componentType(lineItemComponent.lot_component_type) === "isPrice";
  const isPartialBid = state.lots.find((lot) => lot.id === payload.lotId)?.permit_partial_bids;
  if (isPartialBid && pricelineItem) {
    setValidationWarningsReducer(state.lineItemComponentWarnings, payload);
  }

  createBidLineItemComponentFinish(state, {
    payload: {
      lotId: payload.lotId,
      lineItemComponentId: payload.lineItemComponentId,
    },
  });

  // This is for opening the bid modal if the user has completed all inputs,
  // they will be  prompted to submit there bid.
  if (typeof window.advancedLots !== "undefined") {
    window.advancedLots.openSuccessModal = state.errors.length === 0 ? parseInt(payload.lotId) : false;
  }

  safeAssign(state, {
    bid_line_item_components,
    bids,
    lot_total_blics,
  });
};

const deleteBidLineItem = (state, { payload }) => {
  const { bid_blics, line_item_id, bid_id } = payload;
  const lotItemComponentErrors = (state.lotItemComponentErrors ??= {});

  const lic_ids = state.line_item_components?.filter((lic) => lic.line_item_id == line_item_id)?.map((lic) => lic.id);
  state.bidLineItemComponents = state.bid_line_item_components = merge(
    "id",
    state.bid_line_item_components,
    bid_blics
  ).filter((bidLineItemComponent) => {
    return !(bidLineItemComponent.bid_id == bid_id && lic_ids.includes(bidLineItemComponent.line_item_component_id));
  });

  state.errors = state.errors?.filter((error) => !lic_ids?.includes(error));
  for (const lotId of Object.keys(lotItemComponentErrors)) {
    Object.keys(lotItemComponentErrors[lotId]).forEach((lineItemComponentId) => {
      if (lotItemComponentErrors[lotId][lineItemComponentId].lineItemId === parseInt(line_item_id)) {
        delete lotItemComponentErrors[lotId][lineItemComponentId];
      }
    });
  }
  state.recentlyDeletedLiId = line_item_id;
};

const bidSubmittedBidRanges = (state, payload) => {
  let lotsBidRange = state.lotsBidRange ? state.lotsBidRange : [];
  if (payload.bid_range && payload.bid && payload.bid.lot_id) {
    lotsBidRange = getUpdatedValue(lotsBidRange, {
      id: payload.bid.lot_id,
      bid_range: payload.bid_range,
    });
  }

  return lotsBidRange;
};

const bidSubmitted = (state, { payload }) => {
  const { event_total_lot_blics, event_total_lot_bid, best_bids } = payload;

  const bid_line_item_components = merge(
    "id",
    state.bid_line_item_components,
    Array.isArray(event_total_lot_blics) ? event_total_lot_blics : [],
    payload.bid_line_item_components || []
  );

  const lotsBidRange = bidSubmittedBidRanges(state, payload);
  const bids = merge("id", state.bids, [payload.bid], event_total_lot_bid ? [event_total_lot_bid] : []);
  const lot_total_blics = payload.lot_total_blics || state.lot_total_blics;
  const baseState = withLotStatus(state, payload.bid.lot_id, "viewing");
  return { ...baseState, bids, bid_line_item_components, lot_total_blics, lotsBidRange, best_bids };
};

const bidSubmittedPending = (state, { lotId }) => withLotStatus(state, lotId, "pending:submit");

const placeBid = (state, action) => {
  return withLotStatus(
    // Do what the bidSubmitted function is doing but override the next lot
    // state to be bidding not viewing
    bidSubmitted(state, action),
    action.payload.bid.lot_id,
    "bidding"
  );
};

const placeBidPending = (state, { lotId }) => withLotStatus(state, lotId, "pending:placeBid");

const cancelBid = (state, { payload }) => {
  let blics = state.bid_line_item_components ? state.bid_line_item_components : [];
  const bid = state.bids.find((bid) => bid.id === payload.bidId);
  const updatedBids = state.event.bid_at_detail_level
    ? state.bids.filter((bid) => bid.id !== payload.bidId)
    : state.bids;
  blics = blics.filter(({ bid_id }) => bid_id !== payload.bidId);

  return {
    ...withLotStatus(state, bid.lot_id, "viewing"),
    bids: updatedBids,
    bid_line_item_components: blics,
    errors: [],
    activeLis: [
      ...uniqBy(
        state.activeLis?.filter((li) => li?.lot_id !== bid?.lot_id),
        "id"
      ),
    ],
  };
};

const cancelBidPending = (state, { lotId }) => withLotStatus(state, lotId, "pending:cancel");

const uploadBidTemplate = (state, action) => {
  window.successAlertTimeout = "none";
  let blics = [];
  let bids = state.bids ?? [];
  if (Array.isArray(action.bidLineItemComponents)) {
    action.bidLineItemComponents.forEach((blic) => {
      blics = getUpdatedValue(blics, blic);
    });
  }

  let newLotStatus = state.lotStatus;
  if (Array.isArray(action.bids)) {
    action.bids.forEach((bid) => {
      newLotStatus = {
        ...newLotStatus,
        [bid.lot_id]: "bidding",
      };
      bids = getUpdatedValue(bids, bid);
    });
  }

  return {
    ...state,
    bids,
    bid_line_item_components: blics,
    lot_total_blics: action.lotTotalBlics,
    lotStatus: state.lotStatus !== newLotStatus ? newLotStatus : state.lotStatus,
  };
};

const setEskerAuthTokenPending = (state) => ({ ...state, eskerAuthTokenStatus: "pending" });
const setEskerAuthTokenFulfilled = (state) => ({ ...state, eskerAuthTokenStatus: "success" });

const setLotStatusReducer = (state, { payload: { lotId, status } }) => withLotStatus(state, lotId, status);

/**
 * @template {import("@reduxjs/toolkit").Action} [A=Action]
 * @typedef {import("@reduxjs/toolkit").CaseReducer<
 *   typeof import("./lotReducersInitialState").default,
 *   A
 * >} LotsCaseReducer
 */

/**
 * @type {LotsCaseReducer<ReturnType<typeof regenerateLots.pending>>}
 */
function pendingRegenerateLots(state) {
  state.isRegenerating = true;
  state.regenerateProgress = 0;
}

/**
 * @type {LotsCaseReducer<ReturnType<typeof regenerateLots.received>>}
 */
function receivedRegenerateLots(state, action) {
  state.regenerateProgress = action.payload.progress;
}

/**
 * @type {LotsCaseReducer<ReturnType<typeof regenerateLots.rejected>>}
 */
function rejectedRegenerateLots(state) {
  state.isRegenerating = false;
  delete state.regenerateProgress;
}

/**
 * @type {LotsCaseReducer<ReturnType<typeof regenerateLots.fulfilled>>}
 */
function fulfilledRegenerateLots(state, action) {
  delete state.regenerateProgress;
  safeAssign(state, {
    isRegenerating: false,
    lots: action.payload.lots,
    lineItemComponents: action.payload.line_item_components,
    lineItems: action.payload.line_items,
    lotComponents: action.payload.lot_components,
    actionType: action.type, // TODO: refactor this awfulness
  });
}

// instead of being registered in the store, this is currently used within the original lotReducers function.
// the intention is to *eventually* replace lotReducers with this slice
// but for now, we're just using external action types.
const lotSlice = createSlice({
  name: "lots",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setLotStatus, setLotStatusReducer)
      .addCase(setValidationErrors, setValidationErrorsReducer)
      .addCase(createBidLineItemComponent.pending, createBidLineItemComponentPending)
      .addCase(createBidLineItemComponent.fulfilled, updateBlicList)
      .addCase(createBidLineItemComponent.rejected, createBidLineItemComponentError)
      .addCase(actionsTypes.DELETE_LINE_ITEM_BLICS, deleteBidLineItem)
      .addCase(actionsTypes.BID_SUBMITTED, bidSubmitted)
      .addCase(actionsTypes.BID_SUBMITTED_PENDING, bidSubmittedPending)
      .addCase(actionsTypes.PLACE_BID, placeBid)
      .addCase(actionsTypes.PLACE_BID_PENDING, placeBidPending)
      .addCase(actionsTypes.CANCEL_BID, cancelBid)
      .addCase(actionsTypes.CANCEL_BID_PENDING, cancelBidPending)
      .addCase(actionsTypes.UPLOAD_BID_TEMPLATE, uploadBidTemplate)
      .addCase(actionsTypes.MESSAGE_ON_LIVE_FEED, messageOnLiveFeed)
      .addCase(actionsTypes.MARK_AS_READ, liveMessageMarkedAsRead)
      .addCase(getEskerAuthToken.pending, setEskerAuthTokenPending)
      .addCase(getEskerAuthToken.fulfilled, setEskerAuthTokenFulfilled)
      .addCase(getEskerAuthToken.rejected, setEskerAuthTokenFulfilled)
      .addCase(regenerateLots.pending, pendingRegenerateLots)
      .addCase(regenerateLots.received, receivedRegenerateLots)
      .addCase(regenerateLots.rejected, rejectedRegenerateLots)
      .addCase(regenerateLots.fulfilled, fulfilledRegenerateLots);
  },
});

export default lotSlice.reducer;
