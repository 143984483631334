import React from "react";
import ReactPaginate from "react-paginate";
import { useAppSelector, useAppDispatch } from "@/hooks/redux";
import { selectPagination, pageChanged, perPageChanged, selectFilteredContracts } from "@/slices/contracts/listing";
import { t } from "@/i18n";

export const PaginationDropDown = ({ perPage }: { perPage: number }) => {
  const dispatch = useAppDispatch();

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(perPageChanged(parseInt(e.target.value)));
  };

  return (
    <div style={{ marginBottom: "1rem" }}>
      <select className="form-control" value={perPage} onChange={onChange} name="contracts-per-page">
        <option value={10}>10 {t("records_per_page")}</option>
        <option value={20}>20 {t("records_per_page")}</option>
        <option value={50}>50 {t("records_per_page")}</option>
        <option value={100}>100 {t("records_per_page")}</option>
      </select>
    </div>
  );
};

export interface PaginationProps {
  page: number;
  pageCount: number;
}

export const Paginator: React.FC<PaginationProps> = ({ page, pageCount }) => {
  const dispatch = useAppDispatch();

  return (
    <ReactPaginate
      previousLabel={"← Previous"}
      pageClassName="hands-on"
      forcePage={page - 1}
      previousClassName="hands-on"
      nextClassName="hands-on"
      disabledClassName="hide"
      nextLabel={"Next →"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={4}
      onPageChange={({ selected }) => dispatch(pageChanged(selected + 1))}
      containerClassName={" pagination pagination-sm"}
      activeClassName={"active"}
    />
  );
};

export const Pagination: React.FC = () => {
  const contractCount = useAppSelector((state) => selectFilteredContracts(state).length);
  const pagination = useAppSelector(selectPagination);
  const pageCount = Math.ceil(contractCount / pagination.perPage);

  if (contractCount <= 10) return null;

  return (
    <div className="col-12 d-flex justify-content-end align-items-center mt-3">
      <PaginationDropDown perPage={pagination.perPage} />
      <Paginator page={pagination.page} pageCount={pageCount} />
    </div>
  );
};
export default Pagination;
