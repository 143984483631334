import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteBid } from "../../../../../actions/bidActions";
import { findUom, smallOrMiniCell, bidRoundValue, showCurrency } from "../../../common";
import ProgressionCharts from "./ProgressionCharts";
import ActiveParticipants from "../ActiveParticipants";
import BestBidValue from "./BestBidValue";
import LeadParticipant from "./LeadParticipant";
import SavingOffered from "./SavingOffered";
import OverallScore from "./OverallScore";
import PropTypes from "prop-types";
import { Lot, EventPropType } from "@/common-prop-types";

const initialState = {
  preUser: null,
  preParticipant: null,
  preLeadParticipantName: null,
  deletedBid: null,
  isOpen: false,
};

const LotInformationForOverviewPropTypes = {
  lot: PropTypes.shape(Lot).isRequired,
  event: PropTypes.shape(EventPropType).isRequired,
  translations: PropTypes.object.isRequired,
};

/**
 * @param {PropTypes.InferProps<typeof LotInformationForOverviewPropTypes>}
 */
export default function LotInformationForOverview(props) {
  const {
    lot,
    event,
    unitOfMeasures = [],
    auctionControls,
    bidRound,
    isJapaneseEvent,
    unitSetUoms = [],
    auctionTab,
    weightedRfqOrAuction,
    precision = 2,
    displayScores,
    bestBidBeforeAuction,
  } = props;
  const { number_format, results_are_sealed } = props.translations;
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();

  const removeBid = (deletedBid, beforeAuction) => {
    setState((state) => ({ ...state, deletedBid: deletedBid }));
    dispatch(deleteBid(deletedBid, beforeAuction));
  };
  const openCharts = (isOpen) => {
    setState((state) => ({ ...state, isOpen: isOpen }));

    let poll = 100;
    let index = 0;
    const timeout = 3000

    const interval = setInterval(function () {
      if (document.querySelector(".modal-content")) {
        clearInterval(interval);
        $(".modal-content").draggable({
          handle: ".modal-header, .block-header",
          cursor: "move",
        });
        return;
      }

      // clear interval if modal is not loaded into dom even after 3secs.
      if (index++ * poll >= timeout) {
        clearInterval(interval);
        return;
      }
    }, 300);
  };
  const resetState = () => setState(initialState);

  if (isJapaneseEvent) {
    advancedLots.reloadOnAuction = true;
  }
  const allUom = [...unitSetUoms, ...unitOfMeasures];
  const uom = findUom(lot?.unit_of_measure_id, allUom);

  return (
    <div className="mycell lot-information best-bid-left-boarder">
      <div className="row">
        {!props.bidsSealed && props.permissionToView ? (
          <>
            {displayScores && (
              <div className={`mycell ${smallOrMiniCell(weightedRfqOrAuction)}`}>
                {bestBidBeforeAuction ? <OverallScore {...props} uom={uom} /> : <>-</>}
              </div>
            )}
            <BestBidValue {...props} uom={uom} />
            {((auctionTab && !isJapaneseEvent) || !auctionTab) && (
              <LeadParticipant {...props} removeBid={removeBid} resetState={resetState} />
            )}
            {auctionTab && isJapaneseEvent && (
              <div className="mycell">
                <span className="mycell mycell-word-wrap">
                  {!lot?.is_event_total &&
                    showCurrency(
                      bidRoundValue(lot, event, bidRound, auctionControls),
                      props.currency,
                      precision,
                      props.locale,
                      number_format
                    )}
                </span>
              </div>
            )}
            <SavingOffered {...props} />
            <div className={`mycell ${smallOrMiniCell(weightedRfqOrAuction)}`}>
              {auctionTab ? (
                isJapaneseEvent && !["running", "pause"].includes(event?.current_state) ? (
                  <LeadParticipant {...props} removeBid={removeBid} resetState={resetState} />
                ) : (
                  !lot?.is_event_total && (
                    <ActiveParticipants {...props} deleteBid={removeBid} modalCloseFunction={resetState} />
                  )
                )
              ) : (
                props.activeBidCountBeforeAuction
              )}
            </div>
            <ProgressionCharts {...props} isOpen={state.isOpen} openCharts={openCharts} modalTitle={lot.name} />
          </>
        ) : (
          <div className={`mycell ${weightedRfqOrAuction ? "weighted-" : ""} sealedcell`}>
            {props.lotIndex === 0 ? results_are_sealed : " "}
          </div>
        )}
      </div>
    </div>
  );
}
LotInformationForOverview.propTypes = LotInformationForOverviewPropTypes;
