import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from "react-redux";
import { fetchActivities, initialState } from "../../../actions/activityActions";
import "../../../styles/activities.css";
import ActivityButton from "../ActivityButton";
import List from "../List";
import { BuildQueryFromParams } from "../RouteFilter";
import ActivitiesInfo from "./ActivitiesInfo";
import DefaultFilters from "./DefaultFilters";
import NewActivityWrapper from "./NewActivityWrapper";
import { batchUpdates, createFilterString, hasFilters, showChildren } from "./utils";

const Index = function (props) {
  const {
    translations,
    show_activity: showActivity,
    called_from_index: calledFromIndex,
    supplier_id: supplierId,
    contact_id: contactId,
    non_persistent_state,
  } = props;
  const [
    activities,
    setActivities,
    childActivities,
    setChildActivities,
    hostOwnerEmails,
    setHostOwnerEmails,
    concernedParties,
    setConcernedParties,
    paginationParams,
    setPaginationParams,
    highlight,
    setHighlight,
    searchParam,
    setSearchParam,
    filter,
    setFilter,
    childActions,
  ] = _setStates({ uState: useState, props });
  const dispatch = useDispatch();

  if (!props.reducerLoaded) {
    dispatch(initialState(_prepareInitialState({ activities, childActivities, props })));
  }

  let newProps = omit(props, "activities", "concerned_parties", "show_activity", "child_activities");
  const filterActive = hasFilters(filter);

  const handlePageChange = (data, resetPagination = false, resetSearch = false) => {
    const selected = _handleSelectedParam({ paginationParams, data, resetPagination });
    let searchText = _handleSearchText({ searchParam, resetSearch });
    dispatch(fetchActivities(selected, 10, searchText, createFilterString(filter, { supplierId, contactId }))).then(
      (response) =>
        unstable_batchedUpdates(() =>
          batchUpdates(
            response,
            { page: selected, per_page: paginationParams.per_page },
            {
              setActivities,
              setChildActivities,
              setConcernedParties,
              setFilter,
              setHighlight,
              setPaginationParams,
              setSearchParam,
              resetSearch,
              searchText,
              filter,
            }
          )
        )
    );
  };

  _bindEffects({ eff: useEffect, handlePageChange, non_persistent_state, filter });

  return (
    <>
      {!filterActive && (
        <NewActivityWrapper>
          {" "}
          <ActivityButton
            activities={activities}
            concernedParties={concernedParties}
            setActivities={setActivities}
            setConcernedParties={setConcernedParties}
            paginationParams={paginationParams}
            setPaginationParams={setPaginationParams}
            showActivity={showActivity}
            hostOwnerEmails={hostOwnerEmails}
            setHostOwnerEmails={setHostOwnerEmails}
            childActions={childActions}
            {...newProps}
          />{" "}
        </NewActivityWrapper>
      )}
      <ActivitiesInfo translations={translations} />
      {calledFromIndex && <DefaultFilters setFilter={setFilter} />}
      <List
        activities={activities}
        concernedParties={concernedParties}
        paginationParams={paginationParams}
        calledFromIndex={calledFromIndex}
        childActions={childActions}
        setActivities={setActivities}
        hostOwnerEmails={hostOwnerEmails}
        setHostOwnerEmails={setHostOwnerEmails}
        setConcernedParties={setConcernedParties}
        setPaginationParams={setPaginationParams}
        highlight={highlight}
        setHighlight={setHighlight}
        searchParam={searchParam}
        setFilter={setFilter}
        myFiltersActive={filter && filter.myFilters}
        filterActive={filterActive}
        {...newProps}
      />
      <Paginator
        previousLabel={translations.previous}
        nextLabel={translations.next}
        breakLabel={"..."}
        breakClassName={"break-me"}
        paginationParams={paginationParams}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination pull-right"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        disableInitialCallback={true}
        forcePage={paginationParams.page - 1}
      />
    </>
  );
};

const Paginator = (props) => {
  const pageCount = Math.ceil(props.paginationParams.total_count / props.paginationParams.per_page);
  const previousButtonClass = props.paginationParams.page > 1 ? "" : "hidden";

  return (
    <div className="p-t10 row">
      <div className="col-md-12 clear-padding">
        <div>
          {pageCount > 1 && (
            <ReactPaginate {...props} pageCount={pageCount} previousLinkClassName={previousButtonClass} />
          )}
        </div>
      </div>
    </div>
  );
};

const _bindEffects = ({ eff, handlePageChange, non_persistent_state, filter }) => {
  eff(() => {
    if (non_persistent_state.refresh) {
      handlePageChange({}, true);
    }
  }, [non_persistent_state.refresh]);

  eff(() => {
    if (!isEmpty(filter) || filter === undefined) {
      handlePageChange({}, true);
    }
  }, [filter]);

  eff(() => {
    if (non_persistent_state.reloadCurrentPage) handlePageChange({});
  }, [non_persistent_state.reloadCurrentPage]);
};

const _handleSelectedParam = ({ paginationParams, data, resetPagination }) => {
  let selected = paginationParams.page;

  if (Object.keys(data).includes("selected")) {
    selected = data.selected + 1;
  }

  if (resetPagination) {
    selected = 1;
  }

  return selected;
};

const _handleSearchText = ({ searchParam, resetSearch }) => {
  let searchText = searchParam;
  if (resetSearch) {
    searchText = "";
  }
  return searchText;
};

const _prepareInitialState = ({ activities, childActivities, props }) => {
  return {
    activities: activities,
    child_activities: childActivities,
    translations: props.translations,
    activitiesCount: {
      open_activities_count: props.open_activities_count,
      overdue_activities_count: props.overdue_activities_count,
    },
    custom_column_options: props.custom_column_options,
    contact_id: props.contact_id,
    supplier_id: props.supplier_id,
    calledFromIndex: props.called_from_index,
    called_from: props.called_from,
    non_persistent_state: props.non_persistent_state,
    current_user: props.current_user,
    loaded: true,
  };
};

const _setStates = ({ uState, props }) => {
  const [activities, setActivities] = uState(
    props.activities.map(showChildren).filter((activity) => !activity.parent_id)
  );
  const [childActivities, setChildActivities] = uState(props.child_activities.map(showChildren));
  const [hostOwnerEmails, setHostOwnerEmails] = uState(props.host_owner_emails);
  const [concernedParties, setConcernedParties] = uState(props.concerned_parties);
  const [paginationParams, setPaginationParams] = uState(props.pagination_params);
  const [highlight, setHighlight] = uState(false);
  const [searchParam, setSearchParam] = uState("");
  const [filter, setFilter] = uState(BuildQueryFromParams(props, window.location.search));
  const childActions = { childActivities, setChildActivities };
  return [
    activities,
    setActivities,
    childActivities,
    setChildActivities,
    hostOwnerEmails,
    setHostOwnerEmails,
    concernedParties,
    setConcernedParties,
    paginationParams,
    setPaginationParams,
    highlight,
    setHighlight,
    searchParam,
    setSearchParam,
    filter,
    setFilter,
    childActions,
  ];
};

export default connect((state) => {
  return {
    reducerLoaded: state.ActivityReducer.loaded,
    non_persistent_state: state.ActivityReducer.non_persistent_state,
  };
})(Index);
