import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select2 from "react-select2-wrapper";
import { NumericFormat } from "react-number-format";
import { unitOfMeasureOptions } from "../../common";
import Tooltip from "../../../common/Tooltip";
import CommonModal from "../../../../hoc/modalHOC/CommonModal";
import AddOrEditModalContent from "./AddOrEditModalContent";
import AddNewUomModal from "./AddNewUomModal";
import { editUom } from "../../../../actions/lotActions";
import { lotAutomationEnforcedAndNotSandpit } from "../lotCommon";
import SelectLotCurrencyExchangeRate from "./common/SelectLotCurrencyExchangeRate";
import UsedPrice from "./common/UsedPrice";
import QualificationPrice from "./common/QualificationPrice";
import CurrentPrice from "./common/CurrentPrice";

const ADD_NEW_UOM_ID = "0000-AddNewUoM";
const PriceComponentField = (props) => {
  const dispatch = useDispatch();
  const [showAddNewModal, setShowAddNewModal] = useState(false);

  const updateFieldChange = useCallback((e) => {
    if (e.target.value === ADD_NEW_UOM_ID) {
      setShowAddNewModal(true);
    }
  }, []);
  const {
    event,
    lic,
    translations,
    currency,
    currentPrice,
    qualificationPrice,
    usedPrice,
    unitOfMeasureUomname,
    currentVal,
    usedVal,
    qualificationVal,
    setQuantity,
    quantity,
    currenciesHash,
    exchangeRateId,
    unitOfMeasureId,
    anyCompletedBids,
    currentUser,
    setQualificationPrice,
    setCurrentPrice,
    setUsedPrice,
    setUnitOfMeasureUomname,
    setUnitOfMeasureId,
    setExchangeRateId,
    uoms,
    setUoms,
  } = props;
  const { lot_unit_of_measure, line_item_component_unit_of_measure_uomname, lot_quantity } = translations.infos;
  const uomSelector = useSelector((states) => states.uomReducers);
  const lAEnforcedAndNotSandpit = useSelector(lotAutomationEnforcedAndNotSandpit);
  const isInputDisabled = lAEnforcedAndNotSandpit && !lic.host;
  const { actionType } = uomSelector;
  const { number_format: numberFormat } = translations;
  const { separator } = numberFormat;
  let uOmOptions = unitOfMeasureOptions(uoms);
  uOmOptions.push({
    text: "Add UoM",
    id: ADD_NEW_UOM_ID,
  });

  const selectedUom = (lic.unit_set_id && uoms && uoms.find((uom) => uom.id === Number(unitOfMeasureId))) || {};

  const [uomConversion, setUomConversion] = useState(selectedUom.ratio);
  const [uomName, setUomName] = useState(selectedUom.name);

  useEffect(() => {
    switch (actionType) {
      case "UPDATE_UOM_SUCCESS":
        setUoms(uomSelector.uoms);
        setUnitOfMeasureId(uomSelector.unitOfMeasure.id);
        break;
    }
  }, [uomSelector]);

  const onCloseAddNewUomModal = useCallback(() => setShowAddNewModal(false), []);

  const updateEditedUomConversion = useCallback((value) => setUomConversion(value), []);

  const updateEditedUomName = (value) => useCallback(setUomName(value), []);

  const handleEditSubmit = () => {
    dispatch(
      editUom(lic.unit_set_id, selectedUom.id, {
        from: "line_item_component",
        ratio: uomConversion,
        name: uomName,
        resource_type: "User",
        resource_id: currentUser.id,
        event_id: event.id,
      })
    );
  };

  const showEditUOMButton =
    !isInputDisabled &&
    !anyCompletedBids &&
    lic.unit_set_name &&
    selectedUom &&
    !selectedUom.global &&
    selectedUom.resource_id &&
    [currentUser.id, currentUser.company_id].includes(selectedUom.resource_id);

  return (
    <>
      <div className={`row ${event.multi_currency_event ? "" : "hidden"}`}>
        <SelectLotCurrencyExchangeRate
          {...{
            name: "exchangeRateId",
            id: "exchangeRateId",
            currenciesHash,
            value: exchangeRateId,
            onChange: setExchangeRateId,
            disabled: isInputDisabled,
          }}
        />
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            {lic.is_uom_set ? (
              <div className="col input uom-set-select">
                <Tooltip title={lot_unit_of_measure.title} body={lot_unit_of_measure.body} />
                <label htmlFor="unitOfMeasureId">{translations.uom}</label> <span className="required">*</span>
                <Select2
                  className="form-control permission-action-select"
                  id="unitOfMeasureId"
                  name="unitOfMeasureId"
                  data={uOmOptions}
                  onSelect={(e) => setUnitOfMeasureId(e.target.value)}
                  style={{ width: "100%" }}
                  onChange={updateFieldChange}
                  value={unitOfMeasureId}
                  options={{ minimumResultsForSearch: -1 }}
                  disabled={anyCompletedBids || isInputDisabled}
                />
                {showEditUOMButton && (
                  <CommonModal
                    viewComponent={
                      <div>
                        {translations.edit_uom} <i className="fa fa-pencil" />
                      </div>
                    }
                    content={
                      <AddOrEditModalContent
                        translations={translations}
                        unitSetName={lic.unit_set_name}
                        selectedUom={selectedUom}
                        unitOfMeasureId={unitOfMeasureId}
                        uoms={uoms}
                        updateConversion={updateEditedUomConversion}
                        updateName={updateEditedUomName}
                        addNew={false}
                      />
                    }
                    header={translations.edit_uom}
                    modalSubmitButtonClass={"btn btn-sm btn-primary"}
                    modalSubmitButtonText={translations.submit}
                    modalCloseButtonText={translations.cancel}
                    modalSubmitFunction={handleEditSubmit}
                    modalCloseButtonClass="btn btn-sm btn-default"
                  />
                )}
              </div>
            ) : (
              <div className="col input">
                <Tooltip
                  title={line_item_component_unit_of_measure_uomname.title}
                  body={line_item_component_unit_of_measure_uomname.body}
                />
                <label htmlFor="unitOfMeasureUomname">{translations.uom}</label>
                <input
                  autoFocus={true}
                  id="unitOfMeasureUomname"
                  name="unitOfMeasureUomname"
                  type="text"
                  className="form-control"
                  value={unitOfMeasureUomname}
                  onChange={(e) => setUnitOfMeasureUomname(e.target.value)}
                  disabled={isInputDisabled}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <div className="col input">
              <Tooltip title={lot_quantity.title} body={lot_quantity.body} />
              <label htmlFor="quantity">{translations.quantity}</label>
              <NumericFormat
                decimalSeparator={separator}
                value={quantity}
                className={"quantity_precision form-control"}
                onValueChange={(values) => {
                  setQuantity(values.value);
                }}
                id="quantity"
                name="quantity"
                valueIsNumericString={true}
                disabled={anyCompletedBids || isInputDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <CurrentPrice
        {...{
          currentPrice,
          currentVal,
          disabled: isInputDisabled,
          onChange: setCurrentPrice,
          precision: lic.decimal_place,
          symbol: currency.symbol,
        }}
      />
      <div className="row">
        <div className="form-group">
          {lic.host ? (
            <UsedPrice
              {...{
                disabled: isInputDisabled,
                onChange: setUsedPrice,
                precision: lic.decimal_place,
                symbol: currency.symbol,
                translations,
                usedPrice,
                usedVal,
              }}
            />
          ) : (
            <QualificationPrice
              {...{
                disabled: isInputDisabled,
                onChange: setQualificationPrice,
                precision: lic.decimal_place,
                qualificationPrice,
                qualificationVal,
                symbol: currency.symbol,
              }}
            />
          )}
          <div className="clearfix" />
        </div>
      </div>
      {showAddNewModal && !isInputDisabled && (
        <AddNewUomModal
          translations={translations}
          unitSetName={lic.unit_set_name}
          unitSetId={lic.unit_set_id}
          showAddNewModal={showAddNewModal}
          header={translations.create_new_uom}
          modalSubmitButtonClass={"btn btn-sm btn-primary"}
          modalSubmitButtonText={translations.submit}
          modalCloseButtonText={translations.cancel}
          modalCloseButtonClass="btn btn-sm btn-default"
          currentUser={currentUser}
          addNew={true}
          event={event}
          onCloseModal={onCloseAddNewUomModal}
        />
      )}
    </>
  );
};

export default PriceComponentField;
