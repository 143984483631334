import * as ajaxCall from "./contractAjax";
import * as actionTypes from "../actionsTypes";

const homePageFiledStatusSuccess = (data) => ({
  type: actionTypes.UPDATE_HOME_PAGE_FIELDS_STATUS,
  payload: data,
});

const createDocumentTypeSuccess = (data, actionType) => ({
  type: actionType,
  payload: data,
});

const deleteDocumentTypeSuccess = (data, actionType) => ({
  type: actionType,
  payload: data,
});

const EditPageFiledStatusSuccess = (data) => ({
  type: actionTypes.EDIT_PAGE_FIELD_STATUS_UPDATE,
  payload: data,
});

const customNotificationStatusUpdateSuccess = (data) => ({
  type: actionTypes.CUSTOM_NOTIFICATION_STATUS_UPDATE,
  payload: data,
});

const getError = (response) => {
  const message = Object.values(response.data)[0];
  return {
    type: actionTypes.ERROR,
    error: message,
  };
};

export const updateHomePageFieldStatus = (companyId, indexFiledId, fieldName, isChecked) => (dispatch) => {
  const payload = { data: {}, field_id: indexFiledId };
  payload.data[fieldName] = isChecked;
  return ajaxCall
    .post(`/companies/${companyId}/contract_configurations/update_contract_column`, payload)
    .then((response) => {
      dispatch(homePageFiledStatusSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const createDocumentType = (companyId, name) => (dispatch) => {
  const data = {
    contract_document_type: {
      name: name,
    },
  };
  let url = `/companies/${companyId}/contract_configurations/create_contract_document_type`;

  return ajaxCall
    .post(`${url}`, data)
    .then((response) => {
      dispatch(createDocumentTypeSuccess(response.data, actionTypes.CREATE_DOCUMENT_TYPE));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const deleteDocumentType = (companyId, documentId) => (dispatch) => {
  let url = `/companies/${companyId}/contract_configurations/${documentId}/destroy_contract_document_type`;

  return ajaxCall
    .remove(`${url}`)
    .then((response) => {
      dispatch(deleteDocumentTypeSuccess(response.data, actionTypes.DELETE_DOCUMENT_TYPE));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const updateEditPageFieldStatus = (columnName, fieldName, columnId, status, companyId) => (dispatch) => {
  const payload = { form_data: {}, field_id: columnId };
  payload.form_data[columnName] = {};
  payload.form_data[columnName][fieldName] = status;
  return ajaxCall
    .post(`/companies/${companyId}/contract_configurations/update_contract_column`, payload)
    .then((response) => {
      dispatch(EditPageFiledStatusSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const updateCustomNotificationStatus = (columnName, columnId, status, companyId) => (dispatch) => {
  const payload = { form_data: {}, field_id: columnId };
  payload.form_data[columnName] = status;
  return ajaxCall
    .post(`/companies/${companyId}/contract_configurations/update_contract_column`, payload)
    .then((response) => {
      dispatch(customNotificationStatusUpdateSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};
