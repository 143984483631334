import React, { Component } from "react";
import moment from "moment";

import InfoPopover from "../../../InfoPopover";

class EMListElement extends Component {
  searchByMRState(e, currentState) {
    e.preventDefault();
    this.props.searchByStatus(currentState.toLowerCase());
  }

  confirmation(event, EMRequest, mrState) {
    event.preventDefault();
    this.props.confirmation(EMRequest, mrState);
  }
  translations;

  render() {
    const { EMRequest, searchByStatus, translations } = this.props;
    return (
      <tr>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            {translations.emarketplace.em_list_table.status}
            <InfoPopover
              title={this.props.tooltipText.host.event.status.title}
              popoverContent={this.props.tooltipText.host.event.status.body}
            />
          </div>
          <div className={`btn-group button-status button-${EMRequest.event_state.toLowerCase()}`}>
            <button
              type="button"
              className={`dropdown-toggle status-event ${EMRequest.event_state.toLowerCase()} form-control`}
              data-toggle="dropdown"
            >
              {translations.emarketplace.em_list_table[EMRequest.event_state.toLowerCase()]}
              <span className="caret" />
            </button>
            <ul className="dropdown-menu" role="menu">
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    this.searchByMRState(e, EMRequest.event_state);
                  }}
                >
                  {translations.emarketplace.em_list_table[EMRequest.event_state.toLowerCase()]}
                </a>
              </li>

              {EMRequest.current_user_has_edit_permission === true &&
                ((EMRequest.current_state === "completed" && (
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        this.confirmation(e, EMRequest, "close");
                      }}
                    >
                      {translations.qq_listing.qq_list_table.close_event}
                    </a>
                  </li>
                )) ||
                  (EMRequest.event_state === "closed" && EMRequest.current_state === "closed" && (
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          this.confirmation(e, EMRequest, "reopen");
                        }}
                      >
                        {translations.emarketplace.em_list_table.reopen}
                      </a>
                    </li>
                  )))}
            </ul>
          </div>
        </td>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            {translations.emarketplace.em_list_table.material_request_name}
            <InfoPopover
              title={this.props.tooltipText.host.event.name.title}
              popoverContent={this.props.tooltipText.host.event.name.body}
            />
          </div>
          <div className="event-text">
            <a
              title={EMRequest.name}
              href={`/emarketplace/request/${EMRequest.id}${EMRequest.event_state === "draft" ? "/edit" : ""}`}
            >
              {EMRequest.name}
            </a>
          </div>
          {this.props.company && this.props.company.publicly_visible_prefix_event ? (
            <div className="event-id">
              <label className="event-type highlight-text">
                {translations.summary_status.id}: {EMRequest.id}
              </label>
            </div>
          ) : (
            ""
          )}
        </td>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            {translations.emarketplace.em_list_table.material_request_owner}
            <InfoPopover
              title={this.props.tooltipText.host.event.owner.title}
              popoverContent={this.props.tooltipText.host.event.owner.body}
            />
          </div>
          <div className="owner-box">
            <span className="light-gray">{translations.emarketplace.em_list_table.host}:</span> {EMRequest.user.name}
          </div>
          <div className="owner-box">
            <span className="light-gray">{translations.emarketplace.em_list_table.company}:</span>{" "}
            {EMRequest.user.user_company_name}
          </div>
        </td>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            {translations.emarketplace.em_list_table.next_key_deadline}
            <InfoPopover
              title={this.props.tooltipText.host.event.time_until_event.title}
              popoverContent={this.props.tooltipText.host.event.time_until_event.body}
            />
          </div>
          <span className="key-deadline light-gray">
            {EMRequest.bid_deadline && moment(EMRequest.bid_deadline).isBefore()
              ? translations.emarketplace.em_list_table.mr_finished
              : translations.emarketplace.em_list_table.mr_deadline}
            :&nbsp;
          </span>
          {EMRequest.formatted_bid_deadline}
        </td>
        <td>
          <div className="btn-action">
            <span className="small-gray-box">
              <a>
                {" "}
                {EMRequest.lots_size} {translations.emarketplace.em_list_table.lots}
              </a>
            </span>
            <div className="small-gray-box btn-group dropdown-sm">
              <a title="Total of participants involved" className="dropdown-toggle" data-toggle="dropdown">
                <i className="fa fa-users" aria-hidden="true" /> {EMRequest.total_number_of_participants}
                <span className="caret" />
              </a>
              <ul className="dropdown-menu" role="menu">
                <li>
                  <a>
                    {EMRequest.total_number_of_participants} {translations.emarketplace.em_list_table.involved}
                  </a>
                </li>
                <li>
                  <a>
                    {EMRequest.total_number_participating_and_accepted}{" "}
                    {translations.emarketplace.em_list_table.participating}
                  </a>
                </li>
                <li>
                  <a>
                    {EMRequest.total_number_bidding} {translations.emarketplace.em_list_table.bidding}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default EMListElement;
