import PropTypes from "prop-types";

export const Note = {
  bookmark: PropTypes.bool,
  content: PropTypes.string,
  created_at: PropTypes.string,
  id: PropTypes.number,
  include_in_alerts: PropTypes.bool,
  private: PropTypes.bool,
  updated_at: PropTypes.string,
  user: PropTypes.string,
};

/** @typedef {import("@/types/util").Compute<import("prop-types").InferProps<typeof Note>>} Note */

export const Contract = {
  alert_date: PropTypes.string,
  annual_value: PropTypes.number,
  auto_renew: PropTypes.bool,
  category: PropTypes.any,
  contact_company_name: PropTypes.string,
  contract_number: PropTypes.string,
  contract_user_company: PropTypes.number,
  created_at: PropTypes.string,
  currency_id: PropTypes.number,
  currency_symbol: PropTypes.string,
  current_state: PropTypes.string,
  dotted_ids: PropTypes.string,
  expired_notified_on: PropTypes.any,
  expiry_date: PropTypes.string,
  id: PropTypes.number.isRequired,
  notice_period: PropTypes.string,
  owner: PropTypes.string,
  owner_email: PropTypes.string,
  parent: PropTypes.any,
  parent_current_state: PropTypes.any,
  parent_id: PropTypes.any,
  parent_user_id: PropTypes.any,
  sequential_contract_id: PropTypes.string,
  sim_ep_id: PropTypes.string,
  stakeholder_user_email: PropTypes.any,
  stakeholder_user_name: PropTypes.any,
  start_date: PropTypes.string,
  supplier: PropTypes.string,
  supplier_company_name: PropTypes.string,
  supplier_user_email: PropTypes.string,
  title: PropTypes.string,
  total_value: PropTypes.number,
  updated_at: PropTypes.string,
  user_id: PropTypes.number,
};

/** @typedef {import("@/types/util").Compute<import("prop-types").InferProps<typeof Contract>>} Contract */

export default Contract;
