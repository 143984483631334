import { useEffect } from "react";
import { useNodeIntersection } from "./useNodeIntersection";
import { useSliceActions, useSliceSelector } from "@/context/SliceContext";
import { useDispatch } from "react-redux";

/**
 * @param {React.MutableRefObject} lastElementRef - ref of last element in the list to observe and
 *        fetch data if it intersects with viewport.
 */
export default function useInfiniteLoader(lastElementRef) {
  const dispatch = useDispatch();
  const { hasMoreData, isLoading } = useSliceSelector();
  const { nextPage } = useSliceActions();

  // observe node intersection to change page
  const isNodeIntersecting = useNodeIntersection(lastElementRef);

  useEffect(() => {
    // update page
    if (isNodeIntersecting && hasMoreData && !isLoading) dispatch(nextPage());
  }, [dispatch, hasMoreData, isLoading, isNodeIntersecting, nextPage]);
}
