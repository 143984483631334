import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import BasicDetailsForm from "./BasicDetailsForm";
import UploadedFilesForm from "./UploadedFilesForm";
import ContractValueForm from "./ContractValueForm";
import SupplierContactForm from "./SupplierContactForm";
import StakeholdersForm from "./StakeholdersForm";
import DatesAndAlertsForm from "./DatesAndAlertsForm";
import CustomNotificationForm from "./CustomNotificationForm";
import NotesForm from "./NotesForm";
import CategoryChildTreeFrom from "./CategoryChildTreeFrom";
import ParentChildTreeForm from "./ParentChildTreeForm";
import HistoryDetails from "./HistoryDetails";
import { saveContract, setInitialContractProps } from "../../../actions/contract/contractCreateEditActions";
import { validateCustomFieldsForContracts } from "../custom-field-form/contract-cf-validator";
import CustomFieldsFormWrapper from "../custom-field-form";

class CreateEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractStartDate: props.dateAndAlerts.start_date,
      contractExpiryDate: props.dateAndAlerts.expiry_date,
      anyActiveFields: props.customFields.some(
        (customField) => customField.active || (!customField.active && customField.custom_column_values[0].name)
      ),
    };
    this.basicDetailsRef = React.createRef();
    this.customFieldsRef = React.createRef();
    this.contractValueRef = React.createRef();
    this.datesAndAlertsRef = React.createRef();
    this.supplierRef = React.createRef();
    // update reducer
    props.setInitialProps();
  }

  checkFormValues = async (e) => {
    e.preventDefault();
    const basicDetailsComponentRef = this.basicDetailsRef.current;
    const customFieldsComponentRef = this.customFieldsRef.current;
    const supplierSectionRef = this.supplierRef.current;
    const contractValuesComponentRef = this.contractValueRef.current;
    const dateAndAlertsComponentRef = this.datesAndAlertsRef.current;

    // scroll behavior
    const behavior = "smooth";
    const inline = "center";

    const isBasicDetailsError = basicDetailsComponentRef ? basicDetailsComponentRef.validateBasicDetails() : false;
    const isCustomFieldsError = validateCustomFieldsForContracts(this.props.customFields);
    const isContractValueError = contractValuesComponentRef
      ? contractValuesComponentRef.validateContractValueFields()
      : false;
    const isSupplierError = supplierSectionRef ? supplierSectionRef.validateSupllierContacts() : false;
    const isDateError = dateAndAlertsComponentRef ? dateAndAlertsComponentRef.validateDateAndAlertField() : false;
    if (isBasicDetailsError) {
      basicDetailsComponentRef.basicDetailsSection.current.scrollIntoView({
        behavior,
        inline,
      });
    } else if (isCustomFieldsError) {
      customFieldsComponentRef.scrollIntoView({
        behavior,
        inline,
      });
    } else if (isContractValueError) {
      contractValuesComponentRef.contractValueSection.current.scrollIntoView({
        behavior,
        inline,
      });
    } else if (isSupplierError) {
      supplierSectionRef.supplierContactSection.current.scrollIntoView({
        behavior,
        inline,
      });
    } else if (isDateError) {
      dateAndAlertsComponentRef.datesAndAlertsSection.current.scrollIntoView({
        behavior,
        inline,
      });
    } else {
      const result = await this.props.saveContract();
      if (result) {
        window.location.href = "/contracts";
      }
    }
  };

  updateContractDates(startDate, expiryDate) {
    this.setState({
      contractStartDate: moment(startDate, "YYYY-MM-DD", true).isValid() ? startDate : "",
      contractExpiryDate: moment(expiryDate, "YYYY-MM-DD", true).isValid() ? expiryDate : "",
    });
  }

  render() {
    const {
      customFields,
      customNotifications,
      contractUserData,
      contractFiles,
      categoryRelation,
      translations,
      showPage,
    } = this.props;
    // based 'showPage' property diffrentiating the component fields for show and edit state and making the fields readonly for show page.
    const { contractStartDate, contractExpiryDate, anyActiveFields } = this.state;
    const isShowingCategoryChildTree =
      (categoryRelation && !showPage) || (showPage && categoryRelation && categoryRelation.selected_category);
    return (
      <React.Fragment>
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-12 clear-padding">
                <div className="row two-block-box create-edit-container">
                  <BasicDetailsForm ref={this.basicDetailsRef} />
                  <ParentChildTreeForm />
                  {isShowingCategoryChildTree && <CategoryChildTreeFrom />}
                  {Boolean(customFields.length) && anyActiveFields && (
                    <CustomFieldsFormWrapper ref={this.customFieldsRef} />
                  )}
                  {((showPage && contractFiles.length) || !showPage) && <UploadedFilesForm />}
                  <ContractValueForm ref={this.contractValueRef} />
                  {((showPage && contractUserData.suppliers.added.length) || !showPage) && (
                    <SupplierContactForm ref={this.supplierRef} />
                  )}
                  {((showPage && contractUserData.stakeholders.added.length) || !showPage) && <StakeholdersForm />}
                  <DatesAndAlertsForm
                    ref={this.datesAndAlertsRef}
                    updateContractDates={(startDate, expiryDate) => this.updateContractDates(startDate, expiryDate)}
                  />
                  {((showPage && customNotifications.notifications.length) || !showPage) &&
                    customNotifications.active && (
                      <CustomNotificationForm
                        contractStartDate={contractStartDate}
                        contractExpiryDate={contractExpiryDate}
                      />
                    )}
                  <NotesForm />
                  {!showPage && <ActionButtons {...{ handleSave: this.checkFormValues, translations }} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const ActionButtons = ({ handleSave, translations }) => {
  return (
    <div className="col-md-12 col-sm-12">
      <div className="block-item btn-group-sm same-height-block">
        <HistoryDetails />
        <button className="btn btn-sm btn-default save-btn btn-primary" onClick={handleSave}>
          {translations.save}
        </button>
        <a className="btn btn-sm btn-default cancel-btn" href="/contracts">
          {translations.cancel}
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = ({ createEditReducers }, props) => ({
  customFields: createEditReducers.customFields ?? props.customFields,
});

const mapDipatchToProps = (dispatch, props) => ({
  setInitialProps: () => dispatch(setInitialContractProps(props)),
  saveContract: () => dispatch(saveContract(props.contract.id)),
});
export default connect(mapStateToProps, mapDipatchToProps)(CreateEditContainer);
