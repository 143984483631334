import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import Checkbox from "./Checkbox";
import { updateHomePageFieldStatus } from "../../../actions/contract/contractConfigurationActions";

const defaultValues = ["owner", "title"];

class ListingPageConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractIndexFields: props.contractIndexFields,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.data.indexFieldsData) {
      this.setState({
        contractIndexFields: newProps.data.indexFieldsData,
      });
    }
  }

  handleChange(e) {
    const fieldName = e.target.name;
    const isChecked = e.target.checked;
    const { contractIndexFields } = this.state;
    const { companyId } = this.props;
    this.props.updateHomePageFieldStatus(companyId, contractIndexFields.id, fieldName, isChecked);
  }

  _resolveCheckBoxPermission = (key) => defaultValues.includes(key) || !this.props.currentUserEditConfigPermission;

  render() {
    const { contractIndexFields } = this.state;
    const { translations } = this.props;
    const fieldsData = contractIndexFields.data;
    return (
      <Fragment>
        <h1 className="sheet-title">{translations.listing_page}</h1>
        <div className="col-md-12 col-sm-12">
          <div className="block-item btn-group-sm same-height-block">
            <div className="title">{translations.home_page_field}</div>
            <div className="form-group">
              {Object.keys(fieldsData).map((key, idx) => (
                <span
                  className={`m-r10 ${idx === 0 ? "" : "listing-field-title"} ${
                    fieldsData[key] ? "" : "disabled-text"
                  }`}
                  key={idx}
                >
                  <Checkbox
                    name={key}
                    checked={fieldsData[key]}
                    onChange={this.handleChange}
                    translations={translations}
                    readOnly={this._resolveCheckBoxPermission(key)}
                  />
                </span>
              ))}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.contractConfigurationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateHomePageFieldStatus: (companyId, indexFiledId, fieldName, isChecked) =>
    dispatch(updateHomePageFieldStatus(companyId, indexFiledId, fieldName, isChecked)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingPageConfiguration);
