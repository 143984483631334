import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from "@/components/common/Tooltip";

const ShowLineItemDetailsButtonPropTypes = {
  /**
   * If true, Show/Hide Details button will be visible
   *
   * @type {boolean}
   */
  isShowingExpandButton: PropTypes.bool,

  /**
   * If true, details of each LineItem will be visible
   *
   * @type {boolean}
   */
  isShowingDetails: PropTypes.bool,

  /**
   * Toggle handler for Show/Hide Details button
   *
   * @type {() => void}
   */
  toggleShowDetails: PropTypes.func,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<ShowLineItemDetailsButtonPropTypes>>}
 */
export const ShowLineItemDetailsButton = ({ isShowingExpandButton, isShowingDetails, toggleShowDetails }) => {
  const { hide_details, show_details } = useSelector(({ lotReducers }) => lotReducers.translations);
  return (
    <td className="btn-group-sm cell-primary lot-cell-primary shadow-diffuse dj-vgrid-sticky-left bg-white cell-lot-component">
      {isShowingExpandButton && (
        <button
          title={isShowingDetails ? hide_details : show_details}
          className="btn btn-blue-outline btn-default small ok hover closed display-items expand-button vertical-center"
          onClick={toggleShowDetails}
        >
          <i className={`fa fa-eye${isShowingDetails ? "-slash" : ""}`}></i>
          &nbsp;{isShowingDetails ? hide_details : show_details}
        </button>
      )}
    </td>
  );
};

ShowLineItemDetailsButton.propTypes = ShowLineItemDetailsButtonPropTypes;
ShowLineItemDetailsButton.defaultProps = {
  toggleShowDetails: () => {},
};

const LotComponentTableHeaderPropTypes = {
  /**
   * Description of lotComponent
   *
   * @type {string}
   */
  description: PropTypes.string,

  /**
   * Name of lotComponent
   *
   * @type {string}
   */
  name: PropTypes.string,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<LotComponentTableHeaderPropTypes>>}
 */
export const LotComponentTableHeader = ({ description, name }) => {
  const lotComponentDescription = useSelector(({ lotReducers }) => lotReducers.translations.lot_component_description);

  return (
    <td className="border-right tag-container shadow-diffuse bg-white" style={{ flexGrow: 1, minWidth: "200px" }}>
      <div className="cell-number bold tag-padding font-s14 vertical-center" title={name}>
        {!!description && (
          <>
            <Tooltip title={lotComponentDescription} body={description} />
            &nbsp;
          </>
        )}
        {name}
      </div>
    </td>
  );
};

LotComponentTableHeader.propTypes = LotComponentTableHeaderPropTypes;
LotComponentTableHeader.defaultProps = {
  description: "",
  name: "",
};
