import React from "react";
import { GetCellValue } from "./services";

const GetDynamicRowsLotMatrixLicDetails = (props) => {
  const { item, index, lotLcs } = props;
  return (
    <tr key={index}>
      <td title={item.name} className="active small-first-td">
        {item.name}
      </td>
      {lotLcs.map((component, index) => {
        return (
          <td key={index} className="blic-cell cell-td">
            <GetCellValue {...props} component={component} item={item} />
          </td>
        );
      })}
    </tr>
  );
};
export default GetDynamicRowsLotMatrixLicDetails;
