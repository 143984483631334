import React, { Component } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { connect } from "react-redux";
import map from "lodash/map";
import {
  changeContractOwner,
  resetContracts,
  selectListAll,
  searchFilter,
} from "../../../actions/contract/contractListingActions";
import { changeYearButtons } from "../../../common";
import { uniqueBy } from "@/utils";
import { array, number, object, record, safeParse, string, tuple, union, unknown } from "valibot";

const comparisonOpts = [
  { text: "=", id: "" },
  { text: ">", id: "gt" },
  { text: "<", id: "lt" },
  { text: ">=", id: "gte" },
  { text: "<=", id: "lte" },
];

const boolOpts = [
  { text: "True", id: "true" },
  { text: "False", id: "false" },
];

const multiAttributes = [
  "owner",
  "title",
  "category",
  "contact_company_name",
  "supplier",
  "reference",
  "stakeholders",
  "current_state",
];

/**
 * @type {import("react").CSSProperties}
 */
const noPadding = { padding: "0px" };

/**
 * @type {import("react").CSSProperties}
 */
const hidden = { display: "none" };

const filterValuesSchema = record(string(), union([array(object({ text: string(), id: string() })), string()]));

const stateSchema = object({
  dirty: array(string()),
  savedCustomFilter: array(tuple([string(), number(), unknown(), unknown()])),
  filterValues: filterValuesSchema,
  customFilterValues: filterValuesSchema,
});

class SearchFilter extends Component {
  constructor(props) {
    super(props);
    const stateFromLocal = this.getStateFromLocal();
    this.state = {
      savedCustomFilter: [],
      filterValues: {},
      customFilterValues: {},
      dirty: [],
      ...stateFromLocal,
    };
  }

  componentDidMount() {
    $(".datepicker").datepicker({
      dateFormat: "yy-mm-dd",
      beforeShow: changeYearButtons,
      onChangeMonthYear: changeYearButtons,
    });
    if (this.state.savedCustomFilter.length) {
      this.searchContracts();
    }
  }

  selectedfields = (e, name) => {
    this.setState(
      ({ filterValues }) => ({
        filterValues: {
          ...filterValues,
          [`${name}`]: e,
        },
      }),
      () => this.syncStateToLocal()
    );
  };

  customSelectedFields = (e, name) => {
    this.setState(
      ({ customFilterValues }) => ({
        customFilterValues: { ...customFilterValues, [`${name}`]: e },
      }),
      () => this.syncStateToLocal()
    );
  };

  getHosts = (contract_hosts) => contract_hosts.map((host) => ({ text: host[0], id: host[1] }));

  generateOptions = (optionKey) => {
    let opts = [];
    optionKey = optionKey || [];
    optionKey.forEach((value) => {
      if (value) opts.push({ text: value, id: value });
    });
    return opts;
  };

  // this is static so we can access it in tests
  static getLocalStorageKey = ({ currentUserCompany, currentUser }) => {
    return `contractListingFilters:${JSON.stringify({ company: currentUserCompany.id, user: currentUser.id })}`;
  };

  getStateFromLocal = () => {
    const state = localStorage.getItem(SearchFilter.getLocalStorageKey(this.props));
    if (!state) return;
    try {
      const parsed = safeParse(stateSchema, JSON.parse(state));
      if (parsed.issues) {
        console.warn("State shape doesn't match schema", parsed.issues, parsed.output);
        return;
      }
      return parsed.output;
    } catch (e) {
      console.warn(e);
      return;
    }
  };

  syncStateToLocal = () => {
    localStorage.setItem(SearchFilter.getLocalStorageKey(this.props), JSON.stringify(this.state));
  };

  clearLocal = () => {
    localStorage.removeItem(SearchFilter.getLocalStorageKey(this.props));
  };

  generateOptionsForCustomFields = (value) => {
    let options = [];

    // column_type_cd value:
    // 0 for text type
    // 1 for numeric type
    // 2 for date type
    // 3 for checkbox type
    // 4 for Pick one from List type
    // 5 for multiple choice type

    map(value, (v) => {
      if (v) options.push({ text: v, id: v });
    });
    return options;
  };

  markDirty = (id) => {
    this.setState(({ dirty }) => ({ dirty: [...dirty, id].filter(uniqueBy()) }));
  };

  customFieldFilter = (cc, dropdown_options) => {
    const { customFilterValues } = this.state;
    return (
      // we are comparing column_type_cd to 3 because it will just have boolean value in list and no placeholder
      <td data-testid="customFieldFilterTD" key={cc.id}>
        <Multiselect
          ref={(ref) => (this[`cc_${cc.id}`] = ref)}
          options={
            cc.column_type_cd === 3
              ? boolOpts
              : this.generateOptionsForCustomFields(dropdown_options[JSON.stringify(cc)])
          }
          selectedValues={customFilterValues[`cc_${cc.id}`] ? customFilterValues[`cc_${cc.id}`] : []}
          onSelect={(e) => this.customSelectedFields(e, `cc_${cc.id}`)}
          onRemove={(e) => this.customSelectedFields(e, `cc_${cc.id}`)}
          displayValue="text"
          showCheckbox={true}
          className=""
          placeholder={cc.column_type_cd === 3 ? "" : "Select"}
        />
      </td>
    );
  };

  customFieldFilterWithSymbol = (cc, i, type) => {
    const { comparisonOpts, customFilterValues, savedCustomFilter } = this.state;
    return (
      <td data-testid="customFieldFilterWithSymbolTD" key={cc.id}>
        <div className="row">
          <div className="col-sm-3" style={noPadding}>
            <Multiselect
              ref={(ref) => (this[`cc_${cc.id}_comp`] = ref)}
              options={comparisonOpts}
              selectedValues={
                customFilterValues[`cc_${cc.id}_comp`]
                  ? customFilterValues[`cc_${cc.id}_comp`]
                  : [{ text: "=", id: "" }]
              }
              onSelect={(e) => this.customSelectedFields(e, `cc_${cc.id}_comp`)}
              singleSelect={true}
              displayValue="text"
              className=""
            />
          </div>
          <div className="col-sm-9" style={noPadding}>
            <input
              ref={(ref) => (this[`cc_${cc.id}`] = ref)}
              type={type}
              name={cc.name}
              onBlur={() => this.markDirty(`cc_${cc.id}`)}
              defaultValue={savedCustomFilter.length > 0 ? savedCustomFilter[i][2] : ""}
              className=" input-small"
              placeholder="Value"
            />
          </div>
        </div>
      </td>
    );
  };

  generateCustomFieldFilter = () => {
    const { dropdown_options } = this.props;

    let custom_columns = [];
    map(Object.keys(dropdown_options), (cc) => {
      custom_columns.push(JSON.parse(cc));
    });
    return map(custom_columns, (cc, i) => {
      let result;
      let type;
      switch (cc.column_type_cd) {
        case 0:
          result = this.customFieldFilter(cc, dropdown_options);
          break;
        case 1:
          type = "number";
          result = this.customFieldFilterWithSymbol(cc, i, type);
          break;
        case 2:
          type = "text";
          result = this.customFieldFilterWithSymbol(cc, i, type);
          break;
        case 3:
          result = this.customFieldFilter(cc, dropdown_options);
          break;
        case 4:
          result = this.customFieldFilter(cc, dropdown_options);
          break;
        case 5:
          result = this.customFieldFilter(cc, dropdown_options);
          break;
      }
      return result;
    });
  };

  homeFieldFilter = (key, filterOptions) => {
    const { filterValues } = this.state;
    return (
      <td key={key} id={key}>
        <Multiselect
          id={`select_${key}`}
          ref={(ref) => (this[key] = ref)}
          options={filterOptions[key]}
          selectedValues={filterValues[key] ? filterValues[key] : []}
          displayValue="text"
          onSelect={(e) => this.selectedfields(e, key)}
          onRemove={(e) => this.selectedfields(e, key)}
          showCheckbox={true}
          className=""
          placeholder="Select"
        />
      </td>
    );
  };

  inputFieldFilter = (key) => {
    const { filterValues } = this.state;
    const { translations } = this.props;
    let inputType = key.includes("date") ? "text" : "number";
    let inputClass = key.includes("value") ? "input-small" : "p5 input-small datepicker";
    return (
      <td id={key} key={key}>
        <div className="row">
          <div className="col-sm-3" style={noPadding}>
            <Multiselect
              id={`select_${key}`}
              ref={(ref) => (this[`${key}_comparison`] = ref)}
              options={comparisonOpts}
              selectedValues={
                filterValues[`${key}_comparison`] && filterValues[`${key}_comparison`].length
                  ? filterValues[`${key}_comparison`]
                  : [{ text: "=", id: "" }]
              }
              onSelect={(e) => this.selectedfields(e, `${key}_comparison`)}
              singleSelect={true}
              displayValue="text"
              className=""
              placeholder="="
            />
          </div>
          <div className="col-sm-9" style={noPadding}>
            <input
              ref={(ref) => (this[key] = ref)}
              type={inputType}
              name={key}
              defaultValue={filterValues[key] ? filterValues[key] : null}
              onBlur={() => this.markDirty(key)}
              placeholder={translations[key]}
              className={inputClass}
              autoComplete="off"
            />
          </div>
        </div>
      </td>
    );
  };

  changeOwner = (e) => {
    let { data } = this.props;
    let dataArray = Object.values(data.selectall.contractsSelected);
    dataArray = [].concat(...dataArray);
    this.props.changeContractOwner({
      target_owner_id: e[0].id,
      contract_ids: dataArray,
    });
    this.props.selectListAll({ selectAllPage: {}, contractsSelected: {} });
  };

  searchContracts = async () => {
    const {
      canShow: { data },
    } = this.props;
    await this.props.selectListAll({ selectAllPage: {}, contractsSelected: {} });
    const { filterValues, customFilterValues } = this.state;
    // for searching contracts
    let filterData = {
      owner: filterValues.owner ? filterValues.owner : [],
      title: filterValues.title ? filterValues.title : [],
      category: filterValues.category ? filterValues.category : [],
      contact_company_name: filterValues.contact_company_name ? filterValues.contact_company_name : [],
      contact_company_names: filterValues.contact_company_name ? filterValues.contact_company_name : [],
      supplier: filterValues.supplier ? filterValues.supplier : [],
      suppliers: filterValues.supplier ? filterValues.supplier : [],
      start_date_comparison: filterValues.start_date_comparison ? filterValues.start_date_comparison : [],
      start_date: data.start_date ? this.start_date.value : "",
      expiry_date_comparison: filterValues.expiry_date_comparison ? filterValues.expiry_date_comparison : [],
      expiry_date: data.expiry_date ? this.expiry_date.value : "",
      total_value_comparison: filterValues.total_value_comparison ? filterValues.total_value_comparison : [],
      total_value: data.total_value ? this.total_value.value : "",
      annual_value_comparison: filterValues.annual_value_comparison ? filterValues.annual_value_comparison : [],
      annual_value: data.annual_value ? this.annual_value.value : "",
      stakeholders: filterValues.stakeholders ? filterValues.stakeholders : [],
      current_state: filterValues.current_state ? filterValues.current_state : [],
      reference: filterValues.reference ? filterValues.reference : [],
    };
    let toSend = [];
    let toSave = [];
    this.props.custom_columns.map((cc) => {
      if ([1, 2].includes(cc.column_type_cd)) {
        if ($(this[`cc_${cc.id}`]).val()) {
          toSend.push([
            cc.name,
            cc.column_type_cd,
            $(this[`cc_${cc.id}`]).val(),
            customFilterValues[`cc_${cc.id}_comp`],
          ]);
          toSave.push([
            cc.name,
            cc.column_type_cd,
            $(this[`cc_${cc.id}`]).val(),
            customFilterValues[`cc_${cc.id}_comp`],
          ]);
        } else {
          toSave.push([cc.name, cc.column_type_cd, $(this[`cc_${cc.id}`]).val(), ""]);
        }
      } else if ([0, 4, 5, 3].includes(cc.column_type_cd)) {
        if (customFilterValues[`cc_${cc.id}`]) {
          toSend.push([cc.name, cc.column_type_cd, customFilterValues[`cc_${cc.id}`]]);
          toSave.push([cc.name, cc.column_type_cd, customFilterValues[`cc_${cc.id}`]]);
        } else {
          toSave.push([cc.name, cc.column_type_cd, []]);
        }
      }
    }, this);
    this.setState(
      {
        filterValues: filterData,
        savedCustomFilter: toSave,
      },
      () => this.syncStateToLocal()
    );
    this.props.showListLoader(false);
    this.props.searchFilter(filterData, toSend);
  };

  getmultiSelectValues = (e) => Array.from(e.selectedOptions).map(({ value }) => value);

  resettingContracts = async () => {
    await this.props.resetContracts("reset");
    const { dirty } = this.state;
    dirty.forEach((i) => {
      this[i].value = null;
    }, this);
    this.setState(
      {
        savedCustomFilter: [],
        filterValues: {},
        customFilterValues: {},
        dirty: [],
        hasPersistedFilters: false,
      },
      () => this.clearLocal()
    );
    this.props.selectListAll({ selectAllPage: {}, contractsSelected: {} });
  };

  render() {
    const {
      translations,
      contracts,
      allow_admin_section,
      canShow,
      contract_hosts,
      currentUserCompany,
      contact_company_names,
      any_non_permitted_contract,
      owner,
      suppliers,
      stakeholders,
      categories,
      states,
      selectedPage,
      data,
      custom_columns,
      titleOptions,
      reference,
      annual_value,
    } = this.props;
    const filterOptions = {
      owner: this.generateOptions(owner),
      title: this.generateOptions(titleOptions),
      category: this.generateOptions(categories),
      contact_company_name: this.generateOptions(contact_company_names.map((a) => a.contact_company_name)),
      supplier: this.generateOptions(suppliers),
      reference: this.generateOptions(reference),
      stakeholders: this.generateOptions(stakeholders),
      current_state: this.getHosts(states),
    };

    return (
      <>
        <tr className="search-row">
          <td></td>
          {any_non_permitted_contract.includes(currentUserCompany.id) && contracts && allow_admin_section && (
            <td className="change_owner_box w-10">
              <div
                className="changing_owner form-inline"
                style={
                  data &&
                  data.selectall &&
                  (data.selectall.selectAllPage[selectedPage] || data.selectall.contractsSelected[selectedPage])
                    ? {}
                    : hidden
                }
              >
                <Multiselect
                  options={this.getHosts(contract_hosts)}
                  onSelect={(e) => this.changeOwner(e)}
                  displayValue="text"
                  showCheckbox={true}
                  selectionLimit={1}
                  className=""
                  placeholder="Select"
                />
              </div>
            </td>
          )}

          {map(canShow.data, (value, key) => {
            if (value) {
              if (["parent", "auto_renew", "notice_period"].includes(key)) {
                return <td key={key}></td>;
              } else if (multiAttributes.includes(key)) {
                return this.homeFieldFilter(key, filterOptions);
              } else {
                return this.inputFieldFilter(key, filterOptions);
              }
            }
          })}

          {custom_columns.length > 0 && this.generateCustomFieldFilter()}

          <td className="contract-filter-btn" style={{ textAlign: "center", right: "0px" }}>
            <button
              className="buttons-orange button-auto btn-orange"
              id="search_contracts"
              title={translations.search}
              onClick={(e) => this.searchContracts(e)}
            >
              <i className="fa fa-search"></i>
            </button>
            <button
              className="buttons-orange button-auto btn-orange"
              id="reset_contracts"
              title={translations.reset_filter_button}
              onClick={() => this.resettingContracts()}
            >
              <i className="fa fa-refresh"></i>
            </button>
          </td>
        </tr>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.contractListingReducers,
});

const mapDispatchToProps = {
  selectListAll,
  changeContractOwner,
  resetContracts,
  searchFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
