import React, { useState, useEffect } from "react";
import ShowSingleBidLineItemComponent from "../ShowSingleBidLineItemComponent";
import { findUnitSet } from "../../../common";

/**
 * @description Participant Bid value for LineItemComponent
 * Monitor Tab =>
 * Opens up once clicked on Show Components
 *
 * Column - Bid value
 */
export default function SingleBlicValue(props) {
  const { participantLotBid, lic, allUnitSets, unitSetUoms, unitOfMeasures } = props;
  const allUom = unitSetUoms.concat(unitOfMeasures);
  const [unitSet, setUnitSet] = useState({});

  useEffect(() => {
    if (lic) setUnitSet(findUnitSet(allUnitSets, lic.unit_set_id));
  }, [lic]);

  return (
    <ShowSingleBidLineItemComponent
      {...props}
      edit={false}
      preferred="host"
      bid={participantLotBid}
      lineItemLic={lic}
      allUom={allUom}
      unitSet={unitSet}
    />
  );
}
