import React from "react";
import {
  relatedBidLineItemComponents,
  validBidsDuringAuction,
  latestBidBeforeAuctionForLots,
} from "../../../../../bidCommon";
import classNames from "classnames";
import last from "lodash/last";
import ShowBeforeAuctionDetail from "./ShowBeforeAuctionDetail";
import { fetchAssociatedCurrency, lotLineItems, lotLotComponents } from "../../../../../common";

import { isBeforeAuction, isRfq } from "../../../../../eventCommon";

const getActiveBids = (props) =>
  props.beforeAuction
    ? latestBidBeforeAuctionForLots(props.bids, props.participant, props.event)
    : isBeforeAuction(props.event)
    ? getActiveBidsBeforeAuctionOrRfq(props)
    : validBidsDuringAuction(props.event, props.participatingParticipants, props.bids);

const getActiveBidsBeforeAuctionOrRfq = (props) =>
  isRfq(props.event) ? latestBidBeforeAuctionForLots(props.bids, props.participant, props.event) : [];

const getRelatedBidInfo = (props) => {
  let numberOfBids;
  let latestBid;
  let bidBlics = [];

  if (props.permissionToView) {
    const latestBids = props.activeBids.filter(
      (bid) => bid.user_id === props.participant.user_id && bid.lot_id === props.lot.id
    );
    latestBid = last(latestBids);
    if (latestBid) {
      bidBlics = relatedBidLineItemComponents(latestBid.id, props.bidLineItemComponents);
    }
    numberOfBids = latestBids.length;
  }
  return [numberOfBids, latestBid, bidBlics];
};

const LotsRow = (props) => {
  const [numberOfBids, latestBid, bidBlics] = getRelatedBidInfo(props);
  return (
    <tr
      className={classNames({
        "event-total-lot-row": props.lot.is_event_total,
      })}
    >
      <td>{props.lot.position}</td>
      <td className="table-cell-ellipsis" title={props.lot.name}>
        {props.lot.name}
      </td>
      {props.permissionToView ? (
        <ShowBeforeAuctionDetail {...props} numberOfBids={numberOfBids} latestBid={latestBid} bidBlics={bidBlics} />
      ) : (
        <td className="center-text" colSpan={7}>
          {!props.index ? props.translations.results_are_sealed : null}
        </td>
      )}
    </tr>
  );
};

export const AllLotDetails = (props) => {
  let result = [];
  const activeBids = getActiveBids({ ...props });
  props.lots.forEach((lot, index) => {
    const lotLis = lotLineItems(lot, props.lineItems);
    const lotLcs = lotLotComponents(lot, props.lotComponents);
    const lotCurrency = props.isMultiCurrencyEvent
      ? fetchAssociatedCurrency(props.currenciesHash, lot)
      : props.currency;
    result.push(
      <LotsRow
        {...props}
        lot={lot}
        index={index}
        key={index}
        activeBids={activeBids}
        lotCurrency={lotCurrency}
        lotLis={lotLis}
        lotLcs={lotLcs}
      />
    );
  });
  return result;
};
