import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "react-modal";
import Tooltip from "../../../common/Tooltip";
import { createLotComponent, updateLotComponent } from "../../../../actions/lotComponentActions";
import { disableButton, enableButton, unitOfMeasures, isIE } from "../../common";
import PicklistComponentModalDetail from "./PicklistComponentModalDetail";
import PriceComponentModalDetail from "./PriceComponentModalDetail";
import classNames from "classnames";
import { lotAutomationEnforcedAndNotSandpit } from "../lotCommon";

const LotComponentForm = (props) => {
  const {
    translations,
    calculationComponent,
    currenciesHash,
    picklists,
    picklistOptions,
    classFromEventState,
    currentUser,
    eventCurrency,
    anyCompletedBids,
    allUnitSetUnitOfMeasures,
    allUnitSets,
    setModalIsOpen,
    modalIsOpen,
    lotComponent,
    uoms,
    lotComponentPicklistOptions,
    create,
    lot,
    hideFromParticipantsSwitch,
  } = props;

  const dispatch = useDispatch();
  const refBtn = useRef(null);
  const no_lot_total_switch = useSelector((state) => state.lotReducers.no_lot_total_switch);
  const event = useSelector((state) => state.lotReducers.event);
  const lAEnforcedAndNotSandpit = useSelector(lotAutomationEnforcedAndNotSandpit);

  const defaultState = () => {
    let defaultExchangeRateId = "";
    if (event.multi_currency_event) {
      for (let key in currenciesHash) {
        if (currenciesHash[key].default) {
          defaultExchangeRateId = key;
        }
      }
    }
    const visibleRank = ["Open", "Japanese"].includes(event.event_type);
    return {
      name: "",
      description: "",
      descriptionButtonClicked: false,
      provider: "",
      componentType: "",
      decimalPlace: 2,
      isUomSet: false,
      unitSetId: 0,
      defaultUomId: undefined,
      uomName: "",
      uomTypeChosen: "0",
      defaultIsRanked: true,
      defaultIsVisibleRank: !visibleRank,
      picklistId: "",
      picklistOptionValues: {},
      defaultExchangeRateId: defaultExchangeRateId,
      openOrJapaneseEvent: visibleRank,
      hideFromParticipants: false,
      line_item_total: false,
    };
  };
  const getUomName = (lotComponent) => {
    if (lotComponent.unit_set_id === null && lotComponent.default_uom_id !== null) {
      const uom = uoms.find((uom) => uom.id === lotComponent.default_uom_id);
      return uom !== undefined ? uom.name : "";
    } else {
      return "";
    }
  };

  const getUomTypeChosen = (lotComponent) => {
    if (lotComponent.unit_set_id !== null) {
      return "2";
    } else if (lotComponent.default_uom_id !== null) {
      return "1";
    } else {
      return "0";
    }
  };

  const getPicklistOptionValues = (lotComponent) => {
    if (lotComponent.picklist_id !== null) {
      const lcpos = lotComponentPicklistOptions.filter((lcpo) => lcpo.lot_component_id === lotComponent.id);
      let picklistOptionsNestedForm = {};
      lcpos.map(function (lcpo) {
        picklistOptionsNestedForm[lcpo.picklist_option_id] = lcpo.value;
      });
      return picklistOptionsNestedForm;
    } else {
      return {};
    }
  };
  const editState = () => {
    const visibleRank = ["Open", "Japanese"].includes(event.event_type);
    let defaultExchangeRateId = lotComponent.default_exchange_rate_id;
    if (event.multi_currency_event && !defaultExchangeRateId) {
      for (let key in currenciesHash) {
        if (currenciesHash[key].default) {
          defaultExchangeRateId = key;
        }
      }
    }
    return {
      name: lotComponent.name,
      description: lotComponent.description,
      descriptionButtonClicked: lotComponent.description !== "",
      provider: lotComponent.provider_cd === 0 ? "host" : "participant",
      componentType: lotComponent.lot_component_type.toString(),
      decimalPlace: lotComponent.decimal_place,
      isUomSet: lotComponent.is_uom_set,
      unitSetId: lotComponent.unit_set_id || 0,
      defaultUomId: lotComponent.default_uom_id,
      uomName: getUomName(lotComponent),
      uomTypeChosen: getUomTypeChosen(lotComponent),
      defaultIsRanked: lotComponent.default_is_ranked,
      defaultIsVisibleRank: lotComponent.default_is_visible_rank,
      picklistId: lotComponent.picklist_id,
      picklistOptionValues: getPicklistOptionValues(lotComponent),
      defaultExchangeRateId: defaultExchangeRateId,
      openOrJapaneseEvent: visibleRank,
      hideFromParticipants: lotComponent.hide_from_participants,
      line_item_total: Boolean(lotComponent.line_item_total),
    };
  };
  const defaultOrEditState = lotComponent === undefined ? defaultState() : editState();

  const [name, setName] = useState(defaultOrEditState["name"]);
  const [description, setDescription] = useState(defaultOrEditState["description"]);
  const [descriptionButtonClicked, setDescriptionButtonClicked] = useState(
    defaultOrEditState["descriptionButtonClicked"]
  );
  const [provider, setProvider] = useState(defaultOrEditState["provider"]);
  const [componentType, setComponentType] = useState(defaultOrEditState["componentType"]);
  const [decimalPlace, setDecimalPlace] = useState(defaultOrEditState["decimalPlace"]);
  const [isUomSet, setIsUomSet] = useState(defaultOrEditState["isUomSet"]);
  const [unitSetId, setUnitSetId] = useState(defaultOrEditState["unitSetId"]);
  const [defaultUomId, setDefaultUomId] = useState(defaultOrEditState["defaultUomId"]);
  const [uomName, setUomName] = useState(defaultOrEditState["uomName"]);
  const [uomTypeChosen, setUomTypeChosen] = useState(defaultOrEditState["uomTypeChosen"]);
  const [defaultIsRanked, setDefaultIsRanked] = useState(defaultOrEditState["defaultIsRanked"]);
  const [defaultIsVisibleRank, setDefaultIsVisibleRank] = useState(defaultOrEditState["defaultIsVisibleRank"]);
  const [picklistId, setPicklistId] = useState(defaultOrEditState["picklistId"]);
  const [picklistOptionValues, setPicklistOptionValues] = useState(defaultOrEditState["picklistOptionValues"]);

  const [defaultExchangeRateId, setDefaultExchangeRateId] = useState(defaultOrEditState["defaultExchangeRateId"]);
  const [openOrJapaneseEvent, setOpenOrJapaneseEvent] = useState(defaultOrEditState["openOrJapaneseEvent"]);

  const [hideFromParticipants, setHideFromParticipants] = useState(defaultOrEditState["hideFromParticipants"]);

  const [lineItemTotal, setLineItemTotal] = useState(Boolean(lotComponent?.line_item_total ?? false));

  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    if (componentType !== "0" || provider !== "participant") setLineItemTotal(false);
  }, [componentType, provider]);

  useEffect(() => {
    if (picklistId) {
      const chosenPicklistOptions = picklistOptions.filter((po) => po.picklist_id === parseInt(picklistId));
      let updatedPicklistOptions = {};
      chosenPicklistOptions.map((cpo) => (updatedPicklistOptions[cpo.id] = picklistOptionValues[cpo.id] || ""));
      setPicklistOptionValues(updatedPicklistOptions);
    }
  }, [picklistOptions]);

  const handleProviderChange = (event) => {
    const { provider } = event.target.value;
    setProvider(event.target.value);
    if (provider === "host") {
      setDefaultIsRanked(false);
      setDefaultIsVisibleRank(false);
    }
  };

  const handleIsRankedChange = () => {
    if (defaultIsRanked) {
      setDefaultIsRanked(false);
      setDefaultIsVisibleRank(false);
    } else {
      setDefaultIsRanked(true);
    }
  };

  const getRankForPayload = (payload) => {
    // If component type is price or the component type is not set
    // Or else if the component is calculation component and the lot is not event total lot
    // then add the ranking attributes
    if (calculationComponent || parseInt(componentType) === 0 || componentType === "") {
      const rankingRequired = calculationComponent || provider !== "host";
      // If it is participant type LC or calculation component then only assign the selected value or else set the ranking to false
      payload["lot_component"]["default_is_ranked"] = rankingRequired ? defaultIsRanked : false;
      payload["lot_component"]["default_is_visible_rank"] =
        rankingRequired && !openOrJapaneseEvent ? defaultIsVisibleRank : false;
    }
    return payload;
  };

  const getUmo = (payload) => {
    let defaultMeasureOfUnitId = defaultUomId;
    payload["lot_component"]["decimal_place"] = decimalPlace;
    payload["lot_component"]["is_uom_set"] = isUomSet;
    payload["lot_component"]["unit_set_id"] = unitSetId === 0 ? "" : unitSetId;
    if (uomTypeChosen === "2") {
      if (defaultUomId === undefined) {
        const chosenUnitSetUoms = unitOfMeasures(allUnitSetUnitOfMeasures, parseInt(unitSetId));
        defaultMeasureOfUnitId = chosenUnitSetUoms[0]?.["id"];
      }
      payload["lot_component"]["default_uom_id"] = defaultMeasureOfUnitId;
    }
    payload["lot_component"]["unit_of_measure_uomname"] = uomName;
    payload["lot_component"]["default_exchange_rate_id"] = defaultExchangeRateId;

    return payload;
  };

  const getPickList = (payload) => {
    // If the component type is picklist then add these attributes to the payload
    payload["lot_component"]["picklist_id"] = picklistId;
    const nestedFormValues = {};
    const picklist = picklists.find((pick) => pick.id === parseInt(picklistId)) ?? {};
    if ([1, 2].includes(picklist.picklist_type) && Object.entries(picklistOptionValues).length > 0) {
      Object.entries(picklistOptionValues).forEach(function (v, i) {
        let lcpoId = "";
        if (lotComponent !== undefined) {
          const lcpo = (lotComponentPicklistOptions ?? []).filter(
            (po) => po.picklist_option_id === parseInt(v[0]) && po.lot_component_id === lotComponent.id
          )[0];
          if (lcpo !== undefined) {
            lcpoId = lcpo.id;
          }
        }
        nestedFormValues[i] = {
          picklist_option_id: v[0],
          value: v[1],
          id: lcpoId,
        };
      });
    }
    if (Object.entries(nestedFormValues).length > 0) {
      payload["lot_component"]["lot_component_picklist_options_attributes"] = nestedFormValues;
    }
    return payload;
  };

  const getUmoOrpickListforPayload = (payload) => {
    if (componentType === "" && uomTypeChosen === "0") {
      payload["lot_component"]["unit_of_measure_uomname"] = "Lot";
    }
    payload["lot_component"]["provider"] = provider === "" ? 1 : provider;
    payload["lot_component"]["lot_component_type"] = componentType === "" ? 0 : componentType;
    // If the component type is price and it is not calculation component then add these attributes
    if (componentType === "0") {
      payload = getUmo(payload);
    } else if (componentType === "4") {
      payload = getPickList(payload);
    }
    return payload;
  };

  const handleSaveButtonClick = (e) => {
    e.preventDefault();
    let payload = {
      lot_component: {
        lot_id: lot.id,
        name,
        description,
        line_item_total: lineItemTotal,
      },
    };
    disableButton(refBtn.current, translations.saving);
    payload = getRankForPayload(payload);

    const hideParticipantsRequired = calculationComponent || provider === "host";
    // If it is host type LC or calculation component then only assign the selected value or else set the hide_from_participants to false
    payload["lot_component"]["hide_from_participants"] = hideParticipantsRequired ? hideFromParticipants : false;
    if (!calculationComponent) {
      payload = getUmoOrpickListforPayload(payload);
    } else if (calculationComponent) {
      // If it is a calculation component then add the correct provider
      payload["lot_component"]["provider"] = "calculation";
    }

    dispatch(create ? createLotComponent(lot.id, payload) : updateLotComponent(lotComponent.id, lot.id, payload)).then(
      (response) => {
        if (response) {
          closeModal();
        } else {
          enableButton(refBtn.current, translations.save, "<i class='fa fa-check'></i>");
        }
      }
    );
  };

  const title = !lotComponent ? translations.new_lot_component : translations.lot_component_edit;
  const nameTextBox = () => {
    const { lot_component_name } = translations.infos;
    return (
      <div className="col input form-group">
        <Tooltip title={lot_component_name.title} body={lot_component_name.body} />
        <label htmlFor="lot_component_name">{translations.name}</label>
        <span className="required">*</span>
        <input
          autoComplete="off"
          maxLength="30"
          tabIndex="0"
          className="form-control"
          size="30"
          type="text"
          id="lot_component_name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          autoFocus={true}
          disabled={lAEnforcedAndNotSandpit}
        />
      </div>
    );
  };

  // If Add Description button is clicked then show the description check box and cancel button
  const descriptionButtonAndTextBox = () => {
    const { lot_component_description } = translations.infos;
    return (
      <div className="col input form-group desc">
        <div className="col input">
          {!descriptionButtonClicked && (
            <div className="desc-link-with-icon">
              <Tooltip title={lot_component_description.title} body={lot_component_description.body} />
              <button
                type="button"
                className="desc-link status-event auto_width_btn draft btn btn-default"
                onClick={() => setDescriptionButtonClicked(true)}
                disabled={lAEnforcedAndNotSandpit}
              >
                {translations.add_description}
              </button>
            </div>
          )}
          {descriptionButtonClicked && (
            <div className="desc-form">
              <Tooltip title={lot_component_description.title} body={lot_component_description.body} />
              <label htmlFor="lot_component_description">{translations.description}</label>
              <div className="desc-wrapper clearfix">
                <input
                  rows="3"
                  className="desc-input float-left form-control wid-90"
                  type="text"
                  id="lot_component_description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  autoFocus={true}
                  disabled={lAEnforcedAndNotSandpit}
                />
                <button
                  className="desc-cancel button float-right white small remove hover btn btn-default"
                  onClick={() => setDescriptionButtonClicked(false)}
                  disabled={lAEnforcedAndNotSandpit}
                >
                  <i className="fa fa-times" />
                </button>
                <div className="clear" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const LabelTitle = (props = {}) => {
    const { title, body, htmlFor, label } = props;
    return (
      <div className="label-title">
        <Tooltip title={title} body={body} />
        <label htmlFor={htmlFor}>{label}</label>
        <div className="clear" />
      </div>
    );
  };

  const LabelInput = (props) => {
    const { labelClass, label, htmlFor, inputClass, onChange, ...rest } = props;

    return (
      <label className={labelClass} htmlFor={htmlFor}>
        <input className={inputClass} onChange={onChange} id={htmlFor} {...rest} />
        <span />
        {label}
      </label>
    );
  };

  // Entered by Host or Participant
  const providerRadioButtons = () => {
    const { lot_component_provider } = translations.infos;
    const inputsData = ["host", "participant"];
    return (
      <div id="provider_fields">
        <LabelTitle
          title={lot_component_provider.title}
          body={lot_component_provider.body}
          htmlFor={"lot_component_provider"}
          label={translations.who_should_complete_field}
        />
        {inputsData.map((data, index) => (
          <LabelInput
            key={index}
            labelClass="css-input css-radio css-radio-sm css-radio-primary display-as-line push-10-r"
            htmlFor={`lot_component_provider_${data}`}
            inputClass="provider_option"
            type="radio"
            value={data}
            checked={provider === data}
            onChange={(e) => handleProviderChange(e)}
            label={translations[data]}
            disabled={lAEnforcedAndNotSandpit}
          />
        ))}
        <div className="clearfix" />
      </div>
    );
  };

  // Type of LC you want to create (Price, Text, Number, Date or Picklist)
  const componentTypeRadioButtons = () => {
    const { lot_component_type } = translations.infos;
    const componentData = [
      { type: 0, text: "price" },
      { type: 1, text: "text" },
      { type: 2, text: "number" },
      { type: 3, text: "date" },
      { type: 4, text: "picklist" },
    ];

    return (
      <div className="surround">
        <LabelTitle
          title={lot_component_type.title}
          body={lot_component_type.body}
          htmlFor={"lot_component_Component Type"}
          label={translations.component_type}
        />
        {componentData.map((data) => (
          <LabelInput
            key={data.type}
            labelClass="css-input css-radio css-radio-sm css-radio-primary display-as-line push-10-r"
            htmlFor={`lot_component_lot_component_type_${data.type}`}
            inputClass=""
            type="radio"
            value={`${data.type}`}
            checked={componentType === `${data.type}`}
            disabled={anyCompletedBids || lAEnforcedAndNotSandpit}
            onChange={(e) => setComponentType(e.target.value)}
            label={translations[data.text]}
          />
        ))}
        <div className="clear" />
      </div>
    );
  };

  const CommonCheckbox = ({ htmlFor, inputClass = "", label, checked, onChange }) => {
    return (
      <LabelInput
        labelClass="css-input css-checkbox push-10-r css-checkbox-default"
        type="checkbox"
        disabled={lAEnforcedAndNotSandpit}
        {...{ htmlFor, inputClass, label, checked, onChange }}
      />
    );
  };

  const hideFromParticipantsOptions = () => {
    const { lot_hide_from_participants } = translations.infos;
    return (
      <span className="m-l5">
        <Tooltip title={lot_hide_from_participants.title} body={lot_hide_from_participants.body} />
        &nbsp;
        <CommonCheckbox
          htmlFor="lot_component_hide_from_participants"
          checked={hideFromParticipants}
          onChange={(e) => setHideFromParticipants(e.target.checked)}
          label={translations.hide_from_participants}
        />
      </span>
    );
  };

  const LineItemTotalOption = () => {
    const { is_line_item_total } = translations.infos;
    return (
      <div id="line_item_total_fields">
        <LabelTitle
          title={is_line_item_total.title}
          body={is_line_item_total.body}
          htmlFor={"line_item_total"}
          label={translations.line_item_total}
        />
        <CommonCheckbox
          htmlFor="line_item_total"
          inputClass="line_item_total"
          checked={lineItemTotal}
          onChange={(e) => setLineItemTotal(Boolean(e.target.checked))}
          label={translations.make_this_lic_total}
        />
        <div className="clearfix" />
      </div>
    );
  };

  // When component type is price and provider is participant then display the ranking checkboxes
  const rankingOptions = () => {
    const { lot_component_ranking_options } = translations.infos;
    return (
      <div className="col input form-group wizard-step default_ranking_options">
        <div className="clear" />
        <div className="surround">
          <LabelTitle
            title={lot_component_ranking_options.title}
            body={lot_component_ranking_options.body}
            htmlFor={"ranking_options"}
            label={translations.ranking_options}
          />
          <CommonCheckbox
            htmlFor="lot_component_default_is_ranked"
            checked={defaultIsRanked}
            onChange={(e) => handleIsRankedChange(e)}
            label={translations.make_this_ranked}
          />
          {defaultIsRanked && !openOrJapaneseEvent && (
            <CommonCheckbox
              htmlFor="lot_component_default_is_visible_rank"
              checked={defaultIsVisibleRank}
              onChange={(e) => setDefaultIsVisibleRank(e.target.checked)}
              label={translations.make_rank_visible}
            />
          )}
          <div className="clear" />
        </div>
      </div>
    );
  };
  // Save and Cancel button for Lot Component modal
  const modalButtons = () => {
    return (
      <div className="modal-footer p-r0" id="lot_component_submit">
        <button data-dismiss="modal" className="btn btn-sm btn-default " onClick={closeModal}>
          {translations.cancel}
        </button>
        {!lAEnforcedAndNotSandpit && (
          <button name="button" type="submit" className="btn btn-primary button sm-butt white ok hover" ref={refBtn}>
            {translations.save} &nbsp;
            <i className="fa fa-check" />
          </button>
        )}
      </div>
    );
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={classNames({
        "modal-content col-md-5 col-sm-5 custom-field-popup": true,
        "modal9-ie": isIE(),
      })}
      appElement={document.getElementById("advanced-lots-body")}
    >
      <form onSubmit={handleSaveButtonClick}>
        <div className="block block-transparent remove-margin-b">
          <div className="block-header bg-primary-default">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={closeModal}>
                  <i className="ion-close" />
                </button>
              </li>
            </ul>
            <h3 className="block-title">{title}</h3>
          </div>
          <div className="block-content">
            <div className="form">
              <div className="col-md-12">
                <div className="lot-component-fields-wrapper">
                  <div className="wizard-step">
                    {/* Name */}
                    {nameTextBox()}
                    {/* Add Description button and input box */}
                    {descriptionButtonAndTextBox()}
                    <div className="clear" />
                    {name.length > 0 && !calculationComponent && (
                      <div
                        className="col input form-group wizard-step provider-fields-surround"
                        data-calc-provider="false"
                      >
                        {/* Entered By */}
                        {providerRadioButtons()}
                        <div className="clearfix" />
                      </div>
                    )}
                    {/* Component Type */}
                    {provider.length > 0 && !calculationComponent && (
                      <div
                        className={`col input form-group wizard-step ${
                          componentType === "0" || componentType === "2" ? "m-b10" : ""
                        }`}
                        id="component_type_field"
                      >
                        {componentTypeRadioButtons()}
                      </div>
                    )}
                    {componentType === "2" && !calculationComponent && (
                      <div className="alert alert-info font-s12">{translations.advlots_number_column_alert}</div>
                    )}
                    {componentType === "0" && !calculationComponent && (
                      <PriceComponentModalDetail
                        translations={translations}
                        currenciesHash={currenciesHash}
                        event={event}
                        anyCompletedBids={anyCompletedBids}
                        decimalPlace={decimalPlace}
                        uomTypeChosen={uomTypeChosen}
                        provider={provider}
                        defaultExchangeRateId={defaultExchangeRateId}
                        uomName={uomName}
                        unitSetId={unitSetId}
                        classFromEventState={classFromEventState}
                        currentUser={currentUser}
                        defaultUomId={defaultUomId}
                        allUnitSetUnitOfMeasures={allUnitSetUnitOfMeasures}
                        allUnitSets={allUnitSets}
                        setDecimalPlace={setDecimalPlace}
                        setIsUomSet={setIsUomSet}
                        setUnitSetId={setUnitSetId}
                        setDefaultUomId={setDefaultUomId}
                        setUomTypeChosen={setUomTypeChosen}
                        setUomName={setUomName}
                        setDefaultExchangeRateId={setDefaultExchangeRateId}
                        rankingOptions={() => rankingOptions()}
                      />
                    )}
                    {componentType === "4" && !calculationComponent && (
                      <PicklistComponentModalDetail
                        picklists={picklists}
                        picklistOptions={picklistOptions}
                        classFromEventState={classFromEventState}
                        currentUser={currentUser}
                        translations={translations}
                        picklistId={picklistId}
                        setPicklistId={setPicklistId}
                        eventCurrency={eventCurrency}
                        picklistOptionValues={picklistOptionValues}
                        setPicklistOptionValues={setPicklistOptionValues}
                      />
                    )}
                    {lotComponent !== undefined && calculationComponent && rankingOptions()}
                    {hideFromParticipantsSwitch &&
                      (provider === "host" || calculationComponent) &&
                      hideFromParticipantsOptions()}

                    {/* Render line item total only when
                          - lot_total_switch is on
                          - component type is price and provider is participant
                          - lot is not event total lot
                          - event bid is at detailed level
                      */}
                    {!!no_lot_total_switch &&
                      componentType === "0" &&
                      provider === "participant" &&
                      !lot.is_event_total &&
                      event.bid_at_detail_level && <LineItemTotalOption />}
                  </div>
                  {modalButtons()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default LotComponentForm;
