import React from "react";
import { hasCalculationRowAdded } from "../../common";
import LineItemModal from "./LineItemModal";

const AddLineItemButton = (props) => {
  const { lot, translations, lotLis, classFromEventState, anyCompletedBids, hideFromParticipantsSwitch } = props;
  return (
    <div className="pull-right m-t1">
      {!anyCompletedBids && (
        <div className="btn-group btn-action">
          {lot.is_event_total ? (
            lotLis.length == 0 && (
              <LineItemModal
                translations={translations}
                classFromEventState={classFromEventState}
                classes="new-line-item-content-loader blue-color xs-full-width"
                lotId={lot.id}
                calcRow={true}
                total={true}
                hideFromParticipantsSwitch={hideFromParticipantsSwitch}
              />
            )
          ) : lotLis.length > 0 && !hasCalculationRowAdded(lotLis) ? (
            <>
              <div className="m-r10">
                <button
                  type="button"
                  className={`status-event sm-butt ${classFromEventState} form-control max-w-none`}
                  data-toggle="dropdown"
                >
                  <i className="fa fa-table" />
                  &nbsp;{translations.add_line_item} {translations.questionnaire_questions.edit.row}
                  <span className="caret" />
                </button>
                <ul className="dropdown-menu cursor-pointer" role="menu">
                  <li>
                    <LineItemModal
                      translations={translations}
                      classFromEventState={classFromEventState}
                      classes="new-line-item-content-loader blue-color xs-full-width"
                      lotId={lot.id}
                      calcRow={false}
                      create={true}
                      hideFromParticipantsSwitch={hideFromParticipantsSwitch}
                    />
                  </li>
                  <li className="box-bottom-calculation">
                    <LineItemModal
                      translations={translations}
                      classFromEventState={classFromEventState}
                      classes="button small green tiny new-line-item-content-loader blue-color xs-full-width"
                      lotId={lot.id}
                      calcRow={true}
                      create={true}
                      hideFromParticipantsSwitch={hideFromParticipantsSwitch}
                    />
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <LineItemModal
              translations={translations}
              classFromEventState={classFromEventState}
              classes={`bg-white status-event sm-butt new-line-item-content-loader ${
                lotLis.length == 0 ? "glowing" : ""
              }`}
              lotId={lot.id}
              calcRow={false}
              create={true}
              hideFromParticipantsSwitch={hideFromParticipantsSwitch}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AddLineItemButton;
