import { useDevDebugValue } from "@/hooks/use-dev-debug-value";
import type { EventForType, UseEventListenerConfig } from "@/hooks/use-event-listener";
import { useEventListener } from "@/hooks/use-event-listener";
import { useShallowStableValue } from "@/hooks/use-shallow-stable";
import { ensureArray } from "@/utils";
import type { RefObject } from "react";
import { useCallback } from "react";

type MouseEvents = {
  [K in keyof DocumentEventMap]: DocumentEventMap[K] extends MouseEvent ? K : never;
}[keyof DocumentEventMap];

interface UseClickOutsideConfig<Evt extends MouseEvents> extends UseEventListenerConfig {
  /** The event type to listen for. Defaults to "click". */
  type?: Evt;
}

/**
 * A hook to call a callback when a click is detected outside a given element, or elements.
 *
 * @param containers The reference to the element(s) to detect clicks outside of.
 * @param callback The callback to call when a click is detected outside the element(s).
 * Ideally, this should be a stable reference, to avoid registering/unregistering the event listener on every render.
 */
export function useClickOutside<Evt extends MouseEvents = "click">(
  containers: RefObject<HTMLElement> | Array<RefObject<HTMLElement>>,
  callback: (event: EventForType<Document, Evt>) => void,
  { type = "click" as Evt, ...config }: UseClickOutsideConfig<Evt> = {}
) {
  const stableContainers = useShallowStableValue(containers);
  useEventListener(
    document,
    type,
    useCallback(
      (event) => {
        if (!event.target || !document.contains(event.target as Node)) return;
        const isOutside = ensureArray(stableContainers)
          .filter((ref): ref is { readonly current: HTMLElement } => !!ref.current)
          .every((ref) => !ref.current.contains(event.target as Node));
        if (isOutside) callback(event);
      },
      [callback, stableContainers]
    ),
    config
  );
  useDevDebugValue({ containers, type });
}
