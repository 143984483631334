import React, { useEffect } from "react";

const dropzoneComponent = ({
  url,
  parallelUploads,
  uploadMultiple,
  autoProcessQueue,
  addRemoveLinks,
  maxFilesize,
  handleSuccess,
}) => {
  const dropzoneEl = document.getElementsByClassName("dropzone")[0];
  let dropzoneInstance = undefined;
  if (dropzoneEl) {
    dropzoneInstance = dropzoneEl.dropzone;
  }

  useEffect(() => {
    if (!dropzoneInstance) {
      new Dropzone("div#dropzone", {
        autoProcessQueue: autoProcessQueue,
        parallelUploads: parallelUploads,
        uploadMultiple: uploadMultiple,
        url: url,
        addRemoveLinks: addRemoveLinks,
        paramName: "document[filename]",
        maxFilesize: maxFilesize,
        timeout: 600000,
        success: handleSuccess,
        sending: function (file, xhr, formData) {
          formData.append("document[name_of_document]", file.name);
        },
      });
    }
  });

  return <div id="dropzone" className="dropzone"></div>;
};

export default dropzoneComponent;
