import React, { Component } from 'react';
import {Field} from 'redux-form';
import { FormField } from "./FormField"
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import ReactTimeout from 'react-timeout';

import * as adminTextActions from '@/actions/qq/adminTextActions';
import EditorPopupSection from './EditorPopupSection';
import PopupMobile from './PopupMobile';
import { emptyCurrentState } from '../../../../actions/qq/genericActions';
import { fetchPipelineProjects } from '../../../../actions/qq/qqActions';
import AsyncSelect from "react-select/async";
import { components }  from 'react-select';
import LinkProjectChkbox from '../../util/LinkProjectChkbox'
import TrixEditor from '@/components/common/TrixEditor';

const ReactSelectComponents = {
  Control: props => {
    const selected = props.getValue();

    if(selected.length && selected[0].label && props.menuIsOpen) {
      return <div>
        {selected.length ? <div className="form-control mb-10 p-10">{selected[0].label}</div> : ''}
        <components.Control {...props} />
      </div>
    }

    return <div>
      <components.Control {...props} />
    </div>
  },
  ValueContainer: ({ children, ...props }) => {
    const selected = props.getValue();

    if(selected.length && selected[0].label && props.selectProps.menuIsOpen) {
      return <components.ValueContainer {...props}>
        {children[children.length - 1]}
      </components.ValueContainer>
    }
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>
  }
}

class QQGeneralDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorValue: '',
      adminText: '',
      showLoader: true,
      showPipelineProjects: false
    };
  }

  async UNSAFE_componentWillMount() {
    await this.props.fetchAdminText(this.props.companyId, this.props.sectionKey);

    const restField = JSON.parse(localStorage.getItem('restField'));
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    const associated_integration_attributes = formValue.QQGeneralDetails.associated_integration_attributes;

    this.setState({
      editorValue: restField.editorValue,
      showLoader: false,
      showPipelineProjects: typeof associated_integration_attributes._destroy === 'undefined' ? false : !associated_integration_attributes._destroy,
      associated_integration_attributes: {
        value: JSON.stringify(associated_integration_attributes.id ? associated_integration_attributes : {
          _destroy: true
        }),
        label: associated_integration_attributes.label
      }
    });

    this.props.onTextValue('editorValue', restField.editorValue);
    this.props.onTextValue('associated_integration_attributes', associated_integration_attributes);
  }

  componentDidMount() {
    const restField = JSON.parse(localStorage.getItem('restField'));
    $('#deadline-custom-date-time').val(restField?.deadline);
    this.props.setTimeout(() => {
      if ($('.popup-timezone > div').length === 0) {
        $('#QQeventName').focus();
      }
    }, 3000);

  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText.FETCH_ADMIN_TEXT_ATTRIBUTES ? newProps.adminText.FETCH_ADMIN_TEXT_ATTRIBUTES : '';
    if (newProps.default.FETCH_ADMIN_TEXT_ATTRIBUTES_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_ATTRIBUTES_DEL;
    }
    this.setState({
      adminText,
    });
  }

  onChange(event) {
    const newContent = event;
    this.setState({ editorValue: newContent });
    if (this.props.onChange) {
      this.props.onChange(newContent);
    }
    this.props.onTextValue('editorValue', newContent);
    const restField = JSON.parse(localStorage.getItem('restField'));
    restField.editorValue = newContent;
    localStorage.setItem('restField', JSON.stringify(restField));
  }

  onDateSelected(event) {
    $('#deadline-custom-date-time').datetimepicker({
      format: 'Y-m-d H:i',
      minDate: 0,
      step: DATETIME_PICKER_STEP_MINUTES,
      minTime: 0,
      scrollMonth: false,
      scrollInput: false,
      onChangeDateTime(currentDateTime) {
        if (currentDateTime && currentDateTime.getDate() === new Date().getDate()) {
          this.setOptions({ minTime: 0 });
        } else {
          this.setOptions({ minTime: false });
        }
        const restField = JSON.parse(localStorage.getItem('restField'));
        restField.deadline = $('#deadline-custom-date-time').val();
        localStorage.setItem('restField', JSON.stringify(restField));
      },
      onShow(currentDateTime) {
        if (currentDateTime.getDate() === new Date().getDate()) {
          this.setOptions({ minTime: 0 });
        } else {
          this.setOptions({ minTime: false });
        }
      },
    });
  }
  onNameChange(event) {
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    formValue.QQGeneralDetails.quote_name = event.target.value;
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  onPipelineChange = (event) => {
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    let value = {};
    try {
      value = JSON.parse(event.value);
    } catch (err){
      // error handling for json parsing failure
    }

    const associated_integration_attributes = {
      ...value,
      label: event.label,
      _destroy: !this.state.showPipelineProjects,
      create_link: true,
    };
    formValue.QQGeneralDetails.associated_integration_attributes = associated_integration_attributes;

    this.setState({
      associated_integration_attributes
    });

    localStorage.setItem('formValue', JSON.stringify(formValue));
    this.props.onTextValue('associated_integration_attributes', associated_integration_attributes);
  }

  promiseOptions = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      this.props.fetchPipelineProjects({
        company_integration_id: this.props.user.per_angusta_attributes.company_integration_id,
        user_integration_attribute_id: this.props.user.per_angusta_attributes.user_integration_id,
        search: inputValue
      })
      .then((data) => {
        callback(data.map(p => ({value: p.id, label: p.text})));
        return data;
      })
      .catch(err => {
        callback([])
      })
    }
  }

  disabledSection = (event) => {
    const showPipelineProjects = event.target.checked;
    const tempState = {
      ...this.state,
      showPipelineProjects
    };

    if(!showPipelineProjects) {
      const formValue = JSON.parse(localStorage.getItem('formValue'));
      formValue.QQGeneralDetails.associated_integration_attributes = {};
      localStorage.setItem('formValue', JSON.stringify(formValue));
      tempState.associated_integration_attributes = {};
      this.props.onTextValue('associated_integration_attributes', {});
    }

    this.setState(tempState);
  }

  getLPPmesage = (message, type) => {
    if (message.inputValue.length < 3) {
      return this.props.translations.qq_create.qq_generate_details.character_search_warning.replace("%{character_length}", (3 - message.inputValue.length));
    } else {
      return type == 'loading' ? this.props.translations.qq_create.qq_generate_details.searching : type == 'no_option' ? this.props.translations.qq_create.qq_generate_details.no_results_found : null;
    }
  }

  render() {
    const { sectionKey, companyId, user, pipeline_projects, per_angusta_attributes, translations } = this.props;
    return (
      <div className="block-item">
        <div className="col-md-6 p-l0 table-cell-box">
          <div className="form-item-block same-height-block">
            <PopupMobile companyId={companyId} sectionKey={sectionKey} text={this.state.adminText} translations={ translations } />
            <div className="form-group">
              <label htmlFor="event_name">
                {translations.qq_create.qq_generate_details.name_of_quote}
              </label>
              <FormField
                id="quote_name"
                name='quote_name'
                label={ translations.qq_edit.name }
                translations={ translations }
                onChange={ e => this.onNameChange(e) }
              />
            </div>
            <div className="form-group">
              <label htmlFor="deadline-custom-date-time">
                {translations.qq_create.qq_generate_details.deadline}
              </label>
              <div>
                <input
                  id="deadline-custom-date-time"
                  className="form-control date-picker-cstm"
                  type="text"
                  onClick={(e) => {
                    this.onDateSelected(e);
                  }}
                  autoComplete="off"
                />
                <span className="time-zone">{moment.tz(user.time_zone).format("z")}</span>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="name">
                {translations.qq_create.qq_generate_details.invitation_details}
              </label>
              <TrixEditor value={this.state.editorValue} onChange={(e) => this.onChange(e)} />
            </div>
            {per_angusta_attributes.company_integration_id && per_angusta_attributes.user_integration_id && (
              <div className="form-group">
                <div className="checkbox">
                <LinkProjectChkbox name="with_pipelineprojects"  id="checkbox45" type="checkbox" value={this.state.showPipelineProjects} onChange={this.disabledSection} label={translations.qq_create.qq_generate_details.link_pipeline_project} />
                  {/* <div>
                    <input
                      name="with_pipelineprojects"
                      id="checkbox45"
                      type="checkbox"
                      value={this.state.showPipelineProjects}
                      onChange={this.disabledSection}
                    />
                    <label htmlFor="checkbox45">
                      <span></span>
                      {translations.qq_create.qq_generate_details.link_pipeline_project}
                    </label>
                  </div> */}
                </div>
                {this.state.showPipelineProjects && (
                  <AsyncSelect
                    loadOptions={this.promiseOptions}
                    className="resource-type-select"
                    cacheOptions
                    onChange={this.onPipelineChange}
                    value={this.state.associated_integration_attributes}
                    loadingMessage={(message) => {this.getLPPmesage(message, 'loading')}}
                    noOptionsMessage={(message) => {this.getLPPmesage(message, 'no_option')}}
                    components={ReactSelectComponents}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <EditorPopupSection 
          show={this.state.showLoader}
          text={ this.state.adminText }
          companyId={ companyId }
          sectionKey={ sectionKey }
          translations={ translations }
          updateText={ (adminText) => { this.setState({ adminText }); } }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  adminText: state.adminTextReducer,
  // selectedLang: state.changeLanguageReducer,
  default: state.deleteAdminTextReducer,
  pipeline_projects: state.qqReducer.pipeline_projects,
  per_angusta_attributes: (state.userReducer && state.userReducer.details.per_angusta_attributes) || {}
});

const mapDispatchToProps = dispatch => ({
  fetchAdminText: (companyId, sectionKey) => dispatch(adminTextActions.fetchAdminText(companyId, sectionKey)),
  emptyCurrentState: (actionType, payload) => dispatch(emptyCurrentState(actionType, payload)),
  fetchPipelineProjects: (request) => dispatch(fetchPipelineProjects(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReactTimeout(QQGeneralDetails));
