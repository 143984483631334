import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Modal from 'simple-react-modal';
import Loader from 'react-loader-advanced';
import cookies from 'browser-cookies';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

import EditorPopupSection from '../QQEvent/EditorPopupSection';
import PopupMobile from '../QQEvent/PopupMobile';
import NewSupplierChkbox from '../../NewSupplierChkbox';
import { fetchAdminText } from '../../../../actions/qq/adminTextActions';
import { addNewParticipants, deleteParticipant } from '../../../../actions/qq/editActions';
import { showToasterMessages, validateParticipant } from '../../../../actions/qq/genericActions';
import LoaderMessage from '../../LoaderMessage';
import { openParticipantsGroupPopup } from '../../js/common';
import { fetchEventParticipants } from '../../../../actions/qq/qqActions';
import DeferRenderer from '../../DeferRenderer';
import { formGroupActions, FormGroupActions, canSelectMore, checkExisting, stateMutator, getSelectedById } from '../../js/QQEventRelay';

const InitialState = (mutator = {}) => ({
  adminText: '',
  addNewParticipantRow: false,
  data: [],
  selectedParticipants: [],
  selectedGroups: [],
  showModel: false,
  disableAddBtn: false,
  allowSuppliers: false,
  showLimitMessage: false,
  sandpitParticipants: [],
  sandpitParticipantsEmails: [],
  eventParticipants: [],
  ...mutator
})

export class Participants extends Component {
  constructor() {
    super();
    this.state = InitialState(stateMutator);
    this.formGroupActions = formGroupActions.bind(this, false);
    this.canSelectMore = canSelectMore.bind(this);
    this.checkExisting = checkExisting.bind(this);
    this.getSelectedById = getSelectedById.bind(this);
    this.FormGroupActions = FormGroupActions;
  }

  async UNSAFE_componentWillMount() {
    const {
      sectionKey, companyId, configurations, quoteId,
    } = this.props;
    let data = [];
    this.props.fetchAdminText(companyId, sectionKey);
    const participants = await this.props.fetchParticipants(quoteId);
    if (participants.status === 200) {
      data = participants.data.event_participants;
    }
    this.setState({ data });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText.FETCH_ADMIN_TEXT_PARTICIPANTS ? newProps.adminText.FETCH_ADMIN_TEXT_PARTICIPANTS : '';
    if (newProps.default.FETCH_ADMIN_TEXT_PARTICIPANTS_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_PARTICIPANTS_DEL;
    }

    let allowSuppliers;
    if (newProps.companyDetails.whitelabel) {
      allowSuppliers = newProps.companyDetails.whitelabel.allow_adding_new_participants_for_quick_quotes;
    }

    if (newProps.status.supplierStatus) {
      allowSuppliers = newProps.status.supplierStatus.qq_allow_adding_new_participants;
    }

    this.updateOnBoardingStatus(newProps.eventParticipants, newProps.eventParticipantsOnboardingStatus);

    this.setState({
      adminText,
      allowSuppliers,
      eventParticipantsOnboardingStatus: newProps.eventParticipantsOnboardingStatus,
    });

  }

  updateOnBoardingStatus = (eventParticipantsList, eventParticipantsOnboardingStatus = []) => {
    let epForSelect = [];
    if (eventParticipantsList) {
      epForSelect = eventParticipantsList.map((ep) => {
        const onboardingStatus = eventParticipantsOnboardingStatus.filter(e => e.email === ep.email && e.onboarding_status === 'rejected');
        const color = onboardingStatus.length > 0 ? 'red' : '';
        return { value: ep.email, label: ep.email, color };
      });
    }
    this.setState({eventParticipants: epForSelect})
  }

  onInputKeyDown(event) {
    switch (event.keyCode) {
      case 9: // TAB
        event.preventDefault();
        break;
    }
  }

  getParticipantStatus(status) {
    switch (status) {
      case 'Accepted Invite':
        return (
          <div className='status-txt green-text'>
            <span className='fa fa-check' aria-hidden='true' /> {this.props.translations.emarketplace.summary_status.accepted}
          </div>);
      case 'Not Accepted Invite':
        return (
          <div className='status-txt orange-text'>
            <span className='fa fa-history' aria-hidden='true' /> {this.props.translations.emarketplace.summary_status.not_accepted}
          </div>);
      case 'Declined Event':
        return (
          <div className='status-txt red-text'>
            <span className='fa fa-times' aria-hidden='true' /> {this.props.translations.emarketplace.summary_status.declined}
          </div>);
      default:
        return (
          <div className='status-txt red-text'>
            <span className='fa fa-times' aria-hidden='true' /> {this.props.translations.emarketplace.summary_status.not_registered}
          </div>);
    }
  }

  onboardingStatusText(status) {
    switch (status) {
      case 'not_onboarded':
        return this.props.translations.summary_status.onboarding_status.not_onboarded;
      case 'expiring':
        return this.props.translations.summary_status.onboarding_status.expiring;
      case 'expired':
        return this.props.translations.summary_status.onboarding_status.expired;
      case 'rejected':
        return this.props.translations.summary_status.onboarding_status.rejected;
    }
  }

  getOnboardingStatus(status) {
    const onboardingStatus = this.onboardingStatusText(status[0]);
    if (onboardingStatus === undefined || status === 'onboard') { return ''; }

    return (
      <div className='status-txt red-text'>
        <span className='fa fa-exclamation-triangle' aria-hidden='true' />
        { onboardingStatus }
      </div>);
  }

  addNewParticipant(flag) {
    this.setState({ addNewParticipantRow: flag });
    if (!flag) {
      this.setState({
        selectedParticipants: [],
        selectedGroups: [],
      });
    }
  }

  async handleChange(tags) {
    tags = tags || [];

    const notEmailAddress = [];
    let hostEmailAddress = [];
    const existingParticipants = [];
    const selectedTags = this.state.selectedParticipants;
    const isDelete = !tags.length;
    const { data } = this.state;

    if (!isDelete) {
      const newTag = tags[tags.length - 1];
      const regex = /\S+@\S+\.\S+/;
      const idx = _.findIndex(data, ['email', newTag.value]);

      if(!this.canSelectMore(false)) {
        return false;
      }

      if(!this.checkExisting(newTag.value)) {
        this.props.showToasterMessages('SHOW_ERROR', this.props.translations.qq_create.qq_participant.already_participating);
        return false;
      }

      if (!regex.test(newTag.value)) {
        notEmailAddress.push(newTag.value);
        tags.pop();
        this.setState({ selectedParticipants: tags });
      } else if (idx !== -1) {
        existingParticipants.push(newTag.value);
      } else {
        const payload = { users: { emails: [newTag.value] } };
        const response = await this.props.validateParticipant(payload);
        if (response.status === 200 && response.data.host_users.length === 0) {
          this.setState({ selectedParticipants: tags });
        } else {
          tags.pop();
          this.setState({ selectedParticipants: tags });
          hostEmailAddress = response.data.host_users;
        }
      }
    } else {
      this.setState({ selectedParticipants: tags });
    }
    if (notEmailAddress.length > 0) {
      return this.props.showToasterMessages('SHOW_ERROR', `${notEmailAddress.toString()}: ${this.props.translations.error_message.email_regex}`);
    }
    if (hostEmailAddress.length > 0) {
      return this.props.showToasterMessages('SHOW_ERROR', `${hostEmailAddress.toString()}: ${this.props.translations.error_message.host_email_used}`);
    }
    if (existingParticipants.length > 0) {
      const errorStr = existingParticipants.length === 1 ?
        this.props.translations.error_message.participant_email_exit : this.props.translations.error_message.participant_emails_exits;
      return this.props.showToasterMessages('SHOW_ERROR', `${existingParticipants.toString()}: ${errorStr}`);
    }

  }

  async saveNewParticipants() {
    const payload = { event_participants: { event_id: this.props.quoteId } };
    this.setState({ disableAddBtn: true });
    const { selectedParticipants, selectedGroups, data } = this.state;
    let callApi = false, selectedEmails = [];

    if (selectedParticipants.length > 0) {
      selectedParticipants.map(o => selectedEmails.push(o.value));
    }
    
    if (selectedGroups.length > 0) {
      callApi = true;
      const participant_emails = _.flatten(selectedGroups.map(o => Array.from(o.selectedParticipants)));
      selectedEmails = [
        ...selectedEmails,
        ...participant_emails
      ];
    }

    payload.event_participants.participant_emails = selectedEmails.toString();
    const response = await this.props.addNewParticipants(payload);
    if (response.status === 201) {
      if (!_.isEmpty(payload.event_participants)) { this.props.showToasterMessages('SHOW_SUCCESS', this.props.translations.success_message.participant_email_added); }
      this.setState({ data: response.data.event_participants });
      this.addNewParticipant(false);
    }
    this.setState({ disableAddBtn: false });
  }

  getInvitedPlatform(string) {
    switch (string) {
      case 'with_invite_code':
        return this.props.translations.qq_edit.with_invite_code;
      case 'through_system':
        return this.props.translations.qq_edit.through_platform;
      case 'through_public_portal':
        return this.props.translations.qq_edit.through_public_portal;
      default:
        return '';
    }
  }

  async removeParticipant(participantId, submittedAnything) {
    if (!submittedAnything) {
      const response = await this.props.deleteParticipant(participantId);
      if (response.status === 204) {
        const { data } = this.state;
        const updatedData = data.filter(item => item.id !== participantId);
        this.setState({
          data: updatedData,
        });
      }
    } else {
      this.props.showToasterMessages('SHOW_ERROR', this.props.translations.qq_edit.participant_delete_error);
    }
  }

  close() {
    this.setState({ showModel: false });
    this.formGroupActions(FormGroupActions.CHANGE_STEP, 1);
  }

  async addSandpitParticipant(flag) {
    if (flag) {
      const sandpitParticipants = [];
      await this.state.data.forEach((item) => {
        sandpitParticipants.push(item.user_id);
      });
      const alreadySelectedParticipant = this.state.data;
      const result = this.props.sandpitData.sandpitParticipants.filter(participant => sandpitParticipants.indexOf(participant.user_id) === -1);
      this.setState({
        sandpitParticipants: result,
      });
    } else {
      this.setState({
        sandpitParticipants: [],
      });
    }
  }

  handleSandpitParticipants(event) {
    let emails = this.state.sandpitParticipantsEmails;
    if (emails.indexOf(event.target.value) === -1 && event.target.checked) {
      emails.push(event.target.value);
    } else {
      emails = emails.filter(item => item !== event.target.value);
    }
    this.setState({
      sandpitParticipantsEmails: emails,
    });
  }

  async saveSandpitParticipants() {
    const payload = { event_participants: { event_id: this.props.quoteId } };
    payload.event_participants.participant_emails = this.state.sandpitParticipantsEmails.toString();
    const response = await this.props.addNewParticipants(payload);
    if (response.status === 201) {
      if (!_.isEmpty(payload.event_participants)) { this.props.showToasterMessages('SHOW_SUCCESS', this.props.translations.success_message.participant_email_added); }
      this.setState({ data: response.data.event_participants });
      this.addSandpitParticipant(false);
      this.setState({
        sandpitParticipantsEmails: [],
      });
    }
  }

  handleTagRemove = (props) => {
    let { selectedParticipants } = this.state;
    selectedParticipants = selectedParticipants.filter(tag => tag.value.toLowerCase() !== props.data.value.toLowerCase());

    this.setState({ selectedParticipants });

    return props;
  }

  render() {
    const Option = props => (
      <div>
        <components.Option { ...props }>
          {props.data.label}
          {props.data.color &&
            <span className='rejected-text'>{this.props.translations.qq_create.qq_participant.rejected}</span>}
        </components.Option>
      </div>
    );

    const MultiValueLabel = props => (
      <div>
        <components.MultiValueLabel { ...props }>
          {props.data.label}
          {props.data.color &&
            <span className='rejected-text'>{this.props.translations.qq_create.qq_participant.rejected}</span>}
        </components.MultiValueLabel>
      </div>
    );

    const MultiValueRemove = props => {
      return (
        <components.MultiValueRemove {...props}>
          <span className="fa fa-times" onClick={this.handleTagRemove.bind(this, props)} />
        </components.MultiValueRemove>
      );
    };

    const colourStyles = {
      option: (styles, { data }) =>
        ({
          ...styles,
          color: data.color,
        }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
      }),
    };
    const {
      companyId, sectionKey, participantGroups, configurations, permissions, translations
    } = this.props;
    const {
      adminText, data, addNewParticipantRow, selectedParticipants, selectedGroups, disableAddBtn, allowSuppliers, eventParticipantsOnboardingStatus, eventParticipants, activeFormGroup, formGroupStep } = this.state;

    const Rejected = ({ email }) => {
      let present = eventParticipantsOnboardingStatus.filter(e => e.email === email && e.onboarding_status === 'rejected').length;

      if(present) {
        return <span className='rejected-text pull-right'>{this.props.translations.qq_create.qq_participant.rejected}</span>;
      }

      return '';
    }

    let participantsLimit;
    if (configurations.limits) {
      participantsLimit = configurations.limits.qq_participants_limit - data.length;
      participantsLimit = participantsLimit < 0 ? 0 : participantsLimit;
    }

    let allPermission = [];
    if (permissions) {
      allPermission = permissions.permission;
    }

    const isEditable = !!_.find(allPermission, { action: 'edit', section: 'quick_quotes_settings' });

    const sandpit = cookies.get('sandpit') === 'true';
    const sandpitParticipants = sandpit && this.state.sandpitParticipants.map((user, idx) => (
      <div key={ user.user_id }>
        <input type='checkbox' id={ user.user_id } name='checkbox' value={ user.email } onChange={ e => this.handleSandpitParticipants(e) } />
        <label htmlFor={ user.user_id }><span />{`${user.company_name} ${user.email}`}</label>
      </div>
    ));
    return (
      <Loader show={ !this.state.adminText } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles priority={ 1 }>
        <div className='block-item'>
          <div className='col-md-6 p-l0 table-cell-box'>
            <div className='form-item-block bg-white'>
              <h4>{translations.qq_create.qq_participant.who_to_invite}</h4>
              {!sandpit && isEditable && <NewSupplierChkbox translations={ translations } companyId={ companyId } allowSuppliers={ allowSuppliers } />}
              <PopupMobile
                companyId={ companyId }
                sectionKey={ sectionKey }
                text={ this.state.adminText }
                translations={ translations }
              />
              <div className='table-scroll event-list'>
                <table className='common-table'>
                  <thead className='table-block-title'>
                    <tr>
                      <th className='participant-col-one'>{translations.qq_edit.participants}</th>
                      <th className='participant-col-two'>{translations.qq_edit.invited}</th>
                      <th className='participant-col-three'>{translations.qq_listing.qq_list_table.status}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((participantsRow, index) => (
                      <tr key={ participantsRow.email }>
                        <td>
                          <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.participants}</div>
                          <div>{participantsRow.display_name ? participantsRow.display_name : participantsRow.email}</div>
                        </td>
                        <td>
                          <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.invited}</div>
                          <div className='text-capitalize'>{this.getInvitedPlatform(participantsRow.invited_through)}</div>
                        </td>
                        <td>
                          <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_listing.qq_list_table.status}</div>
                          <div>{this.getParticipantStatus(participantsRow.participating_status)}</div>
                          {!sandpit &&
                          <div>{this.getOnboardingStatus(participantsRow.onboarding_status)}</div>
                          }
                        </td>
                        <td>
                          <div>
                            <button type='button' className='icon-link' onClick={ (e) => { this.removeParticipant(participantsRow.id, participantsRow.submitted_anything); } }>
                              <i className='fa fa-trash-o' aria-hidden='true' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {addNewParticipantRow && !sandpit &&
                    <tr className='new-participant-row'>
                      {(isEditable || (!isEditable && allowSuppliers)) &&
                      <td colSpan='2' className='add-columns'>
                        <div>
                          <CreatableSelect
                            isMulti
                            closeMenuOnSelect={ false }
                            value={ selectedParticipants }
                            onChange={ v => this.handleChange(v) }
                            options={ this.canSelectMore(false) ? eventParticipants.filter(part => this.checkExisting(part.value)) : selectedParticipants }
                            styles={ colourStyles }
                            placeholder={translations.qq_edit.emails}
                            disabled={ selectedGroups.length > 0 || participantsLimit === 0 }
                            components={ { Option, MultiValueLabel, MultiValueRemove } }
                          />
                          {(selectedParticipants.length >= participantsLimit || this.state.showLimitMessage) &&
                          <div className='text-danger m-t10'>
                            {translations.error_message.configuration_limit.replace("%{config_limit}", participantsLimit).replace("%{config_string}", 'participants')}
                          </div>}
                        </div>
                      </td>}
                      <td colSpan={(isEditable || (!isEditable && allowSuppliers)) ? 1: 3}>
                        {participantGroups.length ?
                          <div>
                            {(isEditable || (!isEditable && allowSuppliers)) && <span className='or'>{translations.qq_edit.or}</span>}
                            <button
                              type='button'
                              className='btn btn-primary btn-fontsmall modal-btn-open'
                              onClick={ () => openParticipantsGroupPopup(participantsLimit, this) }
                            >
                              {translations.qq_create.qq_participant.choose_group}
                            </button>
                          </div>
                        : ''}
                      </td>
                    </tr>}
                    {selectedGroups.length > 0 &&
                    <tr className='bg-gray'>
                      <td colSpan='4'>
                        <div className=''>
                          {selectedGroups
                           .map((item, idx) => (
                            <ul className='list-group' key={idx}>
                              <li className="list-group-item text-uppercase active-heading" key={'list-first'}>
                                <span className='badge pointer' onClick={ (e) => this.formGroupActions(FormGroupActions.UNSELECT_GROUP, {
                                  group_id: item.id
                                }) }>
                                  <i className='fa fa-times' aria-hidden='true' />
                                </span>
                                <strong>
                                  {item.group_name}
                                </strong>
                              </li>
                              {item.selectedParticipants && Array.from(item.selectedParticipants).map((email, idx) => (
                                <li className="list-group-item" key={email}>
                                  <span className='badge pointer' onClick={ 
                                  () => this.formGroupActions(FormGroupActions.REMOVE_PARTICIPANT, {
                                    email, 
                                    group_id: item.id
                                  })
                                  } >
                                    <i className='fa fa-times' aria-hidden='true' />
                                  </span>
                                  {eventParticipantsOnboardingStatus.filter(e => e.email === email && e.onboarding_status === 'rejected').length ?
                                      <div className='rejected-participant'>
                                        {email}
                                        <span className='rejected-text'>{translations.qq_create.qq_participant.rejected}</span>
                                      </div> :
                                      <div>
                                        {email}
                                      </div>
                                    }
                                </li>
                              ))}
                            </ul>
                          ))}
                        </div>
                      </td>
                    </tr>}
                    {addNewParticipantRow && !sandpit &&
                    <tr>
                      <td colSpan='3' className='text-left'>
                        <button
                          type='button'
                          className='btn btn-primary cancel-reply m-r5'
                          onClick={ () => this.saveNewParticipants() }
                          disabled={ disableAddBtn || (!selectedGroups.length && !selectedParticipants.length) }
                        >{translations.qq_edit.save}
                        </button>
                        <button
                          type='button'
                          className='btn btn-secondary cancel-reply'
                          onClick={ () => this.addNewParticipant(false) }
                        >{translations.summary_status.message.cancel}
                        </button>
                      </td>
                    </tr>
                    }
                    {!sandpit &&
                    <tr>
                      <td colSpan='3' className='table-section-head text-left'>
                        <button
                          type='button'
                          className='btn btn-primary add-new-participant'
                          onClick={ () => this.addNewParticipant(true) }
                          disabled={ addNewParticipantRow }
                        >{translations.qq_edit.add_participant}
                        </button>
                      </td>
                    </tr>}
                  </tbody>
                </table>
                {(sandpit && data.length < 3) &&
                <div>
                  <br />
                  {this.state.sandpitParticipants.length === 0 ?
                    <button
                      type='button'
                      className='btn btn-primary add-new-participant'
                      onClick={ () => this.addSandpitParticipant(true) }
                    >{translations.qq_edit.add_participant}
                    </button>
                  :
                    <div className='checkbox'>
                      <h5>{translations.summary_status.message.all_participants}</h5>
                      {sandpitParticipants}
                      <br />
                      <button
                        type='button'
                        className='btn btn-primary cancel-reply m-r5'
                        onClick={ () => this.saveSandpitParticipants() }
                        disabled={ this.state.sandpitParticipantsEmails.length === 0 }
                      >{translations.qq_edit.save}
                      </button>
                      <button
                        type='button'
                        className='btn btn-secondary cancel-reply'
                        onClick={ () => this.addSandpitParticipant(false) }
                      >{translations.summary_status.message.cancel}
                      </button>
                    </div>}
                </div>
                }
              </div>
            </div>
          </div>
          <EditorPopupSection 
            text={ adminText }
            companyId={ companyId }
            sectionKey={ sectionKey }
            translations={ translations }
            updateText={ (adminText) => { this.setState({ adminText }); } }
          />
          <div className='main-popup popup-md select-group-popup'>
            <Modal show={ this.state.showModel } onClose={ this.close }>
              <div className='vertical-top'>
                <div className='vertical-align-center'>
                  { formGroupStep === 1
                    ? <div className='modal-content'>
                        <div className='modal-header'>
                          <h5 className='modal-title'>{translations.pop_up.select_groups}</h5>
                        </div>
                        <div className='modal-body list-group'>
                          <DeferRenderer
                            translations={translations}
                            nodes={participantGroups}
                            wrapper={(item) => (
                              <div 
                                className={`list-group-item groups ${_.findIndex(selectedGroups, grp => grp.id === item.id) > -1 ? 'active-heading' : ''}`} key={ item.id } >
                                  {
                                    _.findIndex(selectedGroups, grp => grp.id === item.id) > -1 
                                      ? <span className='badge pull-left mr-10 pointer' 
                                        onClick={e => this.formGroupActions(FormGroupActions.UNSELECT_GROUP, {
                                          group_id: item.id
                                        })}>
                                        <i className="fa fa-times"></i>
                                      </span> 
                                    : ''
                                  }
                                  <span className="pointer" 
                                    title={item.group_name}
                                    onClick={ () => _.findIndex(selectedGroups, grp => grp.id === item.id) > -1 
                                      ? this.formGroupActions(FormGroupActions.SHOW_ACTIVE, item)
                                      : this.formGroupActions(FormGroupActions.SELECT_GROUP, item) }>
                                      { item.truncated_group_name }
                                  </span>
                                  <span className='badge'>{item.selectedParticipants && item.selectedParticipants.size ? item.selectedParticipants.size + '/' : ''}{item.no_of_participants}</span>
                              </div>
                            )}
                            mutator={(item) => {
                              const selectedItem = this.getSelectedById(item.id);
                              let strLength = 70;
                              if(selectedItem && selectedItem.selectedParticipants) {
                                strLength = 62;
                              }
                              
                              item.truncated_group_name = (item.group_name.length > strLength) ? (item.group_name.slice(0, strLength)) + '...' : item.group_name 
                              return item;
                            }}
                          />
                        </div>
                        <div className='modal-footer'>
                          <button
                            type='button'
                            className='btn btn-primary modal-btn-close'
                            onClick={ () => this.close() }
                          >{translations.pop_up.done}
                          </button>
                        </div>
                      </div>
                      : <div className='modal-content'>
                      <div className='modal-header'>
                        <h5 className='modal-title'>{translations.qq_create.qq_participant.group}: {activeFormGroup.group_name}</h5>
                      </div>
                      <div className='modal-body'>
                        <div className={`checkbox m-0`}>
                          <div>
                            <input id={ `chk_1` } 
                              type="checkbox" 
                              onChange={ () => _.findIndex(selectedGroups, grp => grp.id === activeFormGroup.id) > -1 
                                ? this.formGroupActions(FormGroupActions.UNSELECT_GROUP, {
                                  group_id: activeFormGroup.id
                                })
                                : this.formGroupActions(FormGroupActions.SELECT_GROUP, activeFormGroup) }
                              checked={activeFormGroup.is_all_selected}
                              name="checkbox" />
                            <label htmlFor={`chk_1`}>
                              <span />
                              {translations.qq_create.qq_participant.select_all_email}
                            </label>
                          </div>
                        </div>
                        <hr className="mt-10 mb-10"/>
                        <ul className="list-group">
                        <DeferRenderer
                          translations={translations}
                          nodes={activeFormGroup.participant_emails}
                          paginate={200}
                          wrapper={(email, idx) => {
                            return <li className={`list-group-item emails-list`} key={ idx }>
                            <div className={`checkbox m-0`}>
                              <div>
                                <input id={ `${idx}_grp_checkbox` } 
                                  type="checkbox" 
                                  onChange={(e) => this.formGroupActions(FormGroupActions.MANAGE_EMAIL_SELECTION, {
                                    email, 
                                    group_id: activeFormGroup.id
                                  })}
                                  checked={activeFormGroup.selectedParticipants && activeFormGroup.selectedParticipants.has(email)}
                                  name="checkbox" />
                                <label htmlFor={`${idx}_grp_checkbox`}>
                                  <span />
                                  {email}
                                </label>
                                <Rejected email={email} key={idx} />
                              </div>
                            </div>
                          </li>
                          }}
                        />
                        </ul>
                      </div>
                      <div className='modal-footer'>
                        <button
                            type='button'
                            className='btn btn-primary pull-left'
                            onClick={ () => this.formGroupActions(FormGroupActions.CHANGE_STEP, 1) }
                          >{translations.qq_create.qq_participant.back}
                        </button>
                        <button
                            type='button'
                            className='btn btn-primary modal-btn-close'
                            onClick={ () => this.close() }
                          >{translations.pop_up.done}
                        </button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  adminText: state.adminTextReducer,
  default: state.deleteAdminTextReducer,
  participantGroups: state.participantGroupsReducer,
  configurations: state.qqReducer,
  permissions: state.permissionReducer,
  selectedLang: state.changeLanguageReducer,
  sandpitData: state.sandpitParticipantsReducer,
  companyDetails: state.companyDetailsReducer,
  eventParticipantsOnboardingStatus: state.eventParticipantsOnboardingStatusReducer,
  status: state.supplierStatus,
});

const mapDispatchToProps = dispatch => ({
  fetchAdminText: (companyId, sectionKey) => dispatch(fetchAdminText(companyId, sectionKey)),
  addNewParticipants: payload => dispatch(addNewParticipants(payload)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
  validateParticipant: payload => dispatch(validateParticipant(payload)),
  deleteParticipant: participantId => dispatch(deleteParticipant(participantId)),
  fetchParticipants: quoteId => dispatch(fetchEventParticipants(quoteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Participants);
