import React, { Component } from 'react';
import Modal from 'simple-react-modal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveAdminText, deleteAdminText } from '../../../../actions/qq/adminTextActions';
import { showToasterMessages, emptyCurrentState } from '../../../../actions/qq/genericActions';
import TrixEditor from '@/components/common/TrixEditor';

/**
 * Maps the section kets to the relevent redux dispach actions
 */
const SECTION_KEY_MAP = {
  qq_header_block: "FETCH_ADMIN_TEXT_HEADER_DEL",
  qq_attributes_block: "FETCH_ADMIN_TEXT_ATTRIBUTES_DEL",
  qq_documents_block: "FETCH_ADMIN_TEXT_DOCUMENTS_DEL",
  qq_prices_block: "FETCH_ADMIN_TEXT_PRICES_DEL",
  qq_questions_block: "FETCH_ADMIN_TEXT_QUESTIONS_DEL",
  qq_participants_block: "FETCH_ADMIN_TEXT_PARTICIPANTS_DEL",
};

/**
 * @typedef {PropTypes.InferProps<EditorPopup.propTypes>} Props
 * @extends Component<Props>
 */
class EditorPopup extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    sectionKey: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    translations: PropTypes.object.isRequired,
    permissions: PropTypes.shape({
      permission: PropTypes.arrayOf(
        PropTypes.shape({
          action: PropTypes.string.isRequired,
          section: PropTypes.string.isRequired,
        }),
      ),
    }),
  }
  

  state = {
    /**
     * @property {String}
     */
    value: '',
    /**
     * @property {Boolean}
     */
    showModel: false,
  };

  onChange = (event) => {
    this.setState({
      value: event,
    });
  }

  openPopup = (e) => {
    e.preventDefault();
    const { text } = this.props;

    this.setState({
      value: text,
      showModel: true
    });
  }

  close = () => {
    this.setState({ showModel: false });
    document.body.classList.remove("open-modal");
  };

  saveAdminText = async () => {
    const text = this.state.value;
    if (!isEditorEmpty(text)) {
      const { companyId, sectionKey } = this.props;
      this.emptyState();
      await this.props.saveAdminText(companyId, sectionKey, text);
      this.close();
      this.props.updateText(text);
    }
  };

  emptyState = () => {
    this.props.emptyCurrentState(SECTION_KEY_MAP[this.props.sectionKey] || undefined, undefined);
  }

  deleteAdminText = async () => {
    const { companyId, sectionKey } = this.props;
    const response = await this.props.deleteAdminText(companyId, sectionKey);
    if (response.status === 200) {
      this.props.showToasterMessages('SHOW_SUCCESS', this.props.translations.success_message.deleted);
    }

    this.close();
  }

  /**
   * Tests to see if the user is allowed to edit the content of the editor
   *
   * @returns {Boolean}
   */
  isEditable = () => {
    const { permissions } = this.props;
    if (!permissions?.permission) {
      return false;
    }

    const index = permissions
      .permission
      .findIndex(item => {
        return item.action === 'edit' && item.section === 'quick_quotes_settings';
      })

    return index > -1;
  }
  
  render() {
    const { translations } = this.props;
    const { value } = this.state;

    return (
      <div>
        {this.isEditable() ?
          <a
            href='#'
            className='editbox modal-btn-open'
            onClick={ this.openPopup }
          >
            <i className='fa fa-pencil-square-o' aria-hidden='true' />
          </a> : ''}
        <div className='main-popup popup-md'>
          <Modal style={ { zIndex: 1100 } } show={ this.state.showModel } onClose={ this.close } closeOnOuterClick>
            <div className='vertical-top'>
              <div className='vertical-align-center'>
                <div className='modal-content clearfix'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>{translations.pop_up.edit_content}</h5>
                    <a
                      href='#'
                      onClick={ (e) => { e.preventDefault(); this.close(); } }
                      className='close-icon modal-btn-close'
                    >
                      <i className='fa fa-times' aria-hidden='true' />
                    </a>
                  </div>
                  <div className="modal-body">
                    {isEditorEmpty(value) ? (
                      <div className="text-danger">{translations.error_message.required}</div>
                    ) : (
                      ""
                    )}
                    <TrixEditor value={value} onChange={this.onChange} />
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      data-testid="popup-close"
                      className='btn btn-secondary modal-btn-close'
                      onClick={ this.close }
                    >{translations.pop_up.close}
                    </button>

                    <button
                      type='button'
                      data-testid="popup-delete"
                      className='btn btn-primary modal-btn-close'
                      onClick={ this.deleteAdminText }
                    >{translations.pop_up.delete}
                    </button>

                    <button
                      type="button"
                      data-testid="popup-save"
                      disabled={isEditorEmpty(value)}
                      className="btn btn-primary modal-btn-close"
                      onClick={this.saveAdminText}
                    >
                      {translations.pop_up.submit}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.permissionReducer,
});

const mapDispatchToProps = dispatch => ({
  saveAdminText: (companyId, sectionKey, text) => dispatch(saveAdminText(companyId, sectionKey, text)),
  deleteAdminText: (companyId, sectionKey) => dispatch(deleteAdminText(companyId, sectionKey)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
  emptyCurrentState: (actionType, payload) => dispatch(emptyCurrentState(actionType, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorPopup);

/**
 * Tests to see if the editor has any content.
 * @param {string} value
 *
 * @returns {Boolean}
 */
export const isEditorEmpty = (value) => !value?.replace(/<[^>]+>|&nbsp;*/g, "").trim().length;