import React from "react";
import { stateDisplayText, showCurrency, getFormula, usedValue, qualificationValue } from "../../common";
import { roundValue } from "../../bidCommon";

const ShowLicDetails = React.memo((props) => {
  const { lic, translations, currency } = props;
  const currentVal = lic.current_value;
  const usedVal = usedValue(lic);
  const qualificationVal = qualificationValue(lic);
  const isPriceLc = lic.is_price;
  const isPriceAndNotFormula = isPriceLc && !lic.is_formula_lic;
  const displayLicDetails = () => {
    let displayLicDetails = {};
    displayLicDetails.isRanked = isPriceLc ? stateDisplayText(lic.is_ranked, translations) : "-";
    displayLicDetails.isVisibleRank = isPriceLc ? stateDisplayText(lic.is_visible_rank, translations) : "-";
    displayLicDetails.currency = isPriceLc ? currency && currency.symbol : "-";
    displayLicDetails.uom = lic.uom_name ? lic.uom_name : " -";
    displayLicDetails.quantity = isPriceAndNotFormula ? lic.quantity : "-";
    displayLicDetails.currentPrice = isPriceAndNotFormula ? formattedValue(lic.current_price) : "-";
    displayLicDetails.usedPrice = isPriceAndNotFormula ? formattedValue(lic.used_price) : "-";
    displayLicDetails.qualificationPrice = isPriceAndNotFormula ? formattedValue(lic.qualification_price) : "-";
    displayLicDetails.currentValue = isPriceLc ? formattedValue(roundValue(currentVal), 2) : "-";
    displayLicDetails.usedValue = isPriceAndNotFormula ? formattedValue(usedVal) : "-";
    displayLicDetails.qualificationValue = isPriceLc ? formattedValue(qualificationVal) : "-";
    return displayLicDetails;
  };

  const formattedValue = (value, decimal = lic.decimal_place) => {
    const { currency, locale, translations } = props;
    return showCurrency(value || "-", currency, decimal, locale, translations.number_format);
  };
  const licDetails = displayLicDetails();
  return (
    <ul className="lot-summary summary-panel">
      <li className="clearfix" title={licDetails.isRanked}>
        {licDetails.isRanked}
      </li>
      <li className="clearfix" title={licDetails.isVisibleRank}>
        {licDetails.isVisibleRank}
      </li>
      <li className="clearfix" title={licDetails.currency}>
        {licDetails.currency}
      </li>
      <li className="lic-attr clearfix" title={licDetails.uom}>
        {licDetails.uom}
      </li>
      <li className="clearfix clearboth" title={licDetails.quantity}>
        {licDetails.quantity}
      </li>
      <li className="clearfix" title={licDetails.currentPrice}>
        {licDetails.currentPrice}
      </li>
      <li className="clearfix" title={licDetails.usedPrice}>
        {licDetails.usedPrice}
      </li>
      <li className="clearfix" title={licDetails.qualificationPrice}>
        {licDetails.qualificationPrice}
      </li>
      <li className="clearfix" title={licDetails.currentValue}>
        {licDetails.currentValue}
      </li>
      <li className="clearfix" title={licDetails.usedValue}>
        {licDetails.usedValue}
      </li>
      <li className="clearfix" title={licDetails.qualificationValue}>
        {licDetails.qualificationValue}
      </li>
      <li className="lic-attr clearfix">
        {!isPriceLc ? (
          <span title={lic.value}>{lic.value}</span>
        ) : lic.formula ? (
          <span title={getFormula(lic)}>{getFormula(lic)}</span>
        ) : (
          "-"
        )}
      </li>
    </ul>
  );
});

export default ShowLicDetails;
