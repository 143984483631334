import React, { Component } from "react";
import { connect } from "react-redux";
import TreeNode from "./TreeNodeForCategory";
import { updateCategory } from "../../../actions/contract/contractCreateEditActions";
import ContractTooltip from "./ContractTooltip";
import { commonProps } from "./selectors";
import RenderHTML from "@/components/common/RenderHTML";

class CategoryChildTreeFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryData: props.categoryRelation,
    };
  }

  async updateCategory(category) {
    $("#category_" + category.id).css("pointer-events", "none");
    let { categoryData } = this.state;
    let selectedCategory = category;
    if (categoryData.selected_category && category.id === categoryData.selected_category.id) {
      selectedCategory = null;
    }
    const result = await this.props.updateCategory(this.props.contractId, selectedCategory ? selectedCategory.id : "");
    this.setState({
      categoryData: result,
    });
    $("#category_" + category.id).css("pointer-events", "auto");
  }

  render() {
    const { categoryData } = this.state;
    const { translations, showPage } = this.props;
    return (
      <div className="col-md-12 col-sm-12">
        <div className="form-section-block">
          <ContractTooltip translations={translations} titleText={"category_hierarchy"} />
          <div className="inputWrap">
            <div className="tree category-tree">
              <div className="sub-title"> {translations.select_a_category} </div>
              {categoryData.category_tree_data.length ? (
                categoryData.category_tree_data.map(
                  (tree, index) =>
                    tree.child_nodes && (
                      <ul key={index}>
                        <TreeNode
                          node={tree}
                          childIteration={0}
                          selectedCategory={categoryData.selected_category}
                          updateCategory={(category) => this.updateCategory(category)}
                          showPage={showPage}
                        />
                      </ul>
                    )
                )
              ) : (
                <div>
                  <RenderHTML content={translations.contract_no_category} />
                </div>
              )}
            </div>
          </div>
          <p className="clear-both" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ createEditReducers }) => ({
  ...commonProps(createEditReducers),
  categoryRelation: createEditReducers.categoryRelation,
});

const mapDispatchToProps = (dispatch) => ({
  updateCategory: (contractId, categoryId) => dispatch(updateCategory(contractId, categoryId)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CategoryChildTreeFrom);
