import reject from "lodash/reject";
export const getToggleValue = (dataId, ids, currentVal) => {
  let dataIds;
  if (dataId === "All") {
    dataIds = currentVal.length === ids.length ? [] : ids;
  } else {
    dataId = parseInt(dataId);
    if (currentVal.includes(dataId)) {
      dataIds = reject(currentVal, (id) => id === dataId);
    } else {
      dataIds = [...currentVal, dataId];
    }
  }
  return dataIds;
};

export const expandLiForLot = (lotIds = [], lotId) => lotIds.includes(lotId);

export const expandLcForParticipant = (epIds = [], epId) => epIds.includes(epId);
