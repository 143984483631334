import React from "react";
import GetDetailsTable from "./GetDetailsTable";
import { officialName, getCountryName, eventParticipantStatus } from "../../../../../common";
import GetRecentActivityTable from "./GetRecentActivityTable";
import { useDispatch } from "react-redux";
import { resendInvite } from "../../../../../../../actions/eventActions";

export default (props) => {
  const { translations, user, participant, event, permittedToEdit } = props;
  const {
    user_full_name: userFullName,
    user_email: userEmail,
    office_phone: officePhone,
    position,
    address_line_1: addressLine1,
    town,
    postal_code: postalCode,
    country,
    address_line_2: addressLine2,
    state,
    company,
    website,
    industry,
    turnover,
    years_in_business,
    accreditations,
    number_of_employees,
    resend_invites,
    to_this_participant,
    to_all_unresponded_participants,
    infos: { resend_invite_participants: resendInviteParticipants },
  } = translations;
  const {
    email,
    office_phone: userOfficePhone,
    position: userPosition,
    address_line_1: userAddressLine1,
    town: userTown,
    postal_code: userPostalCode,
    country: userCountry,
    address_line_2: userAddressLine2,
    state: userState,
    user_company_name: userCompany,
    website: userWebsite,
    industry: userIndustry,
    turnover: userTurnover,
    years_in_business: yearsInBusiness,
    accreditations: userAccreditations,
    number_of_employees: userNumberOfEmployees,
  } = user;

  let keys = [userFullName, userEmail, officePhone, position, addressLine1, town, postalCode, country];

  let values = [
    officialName(user),
    email,
    userOfficePhone,
    userPosition,
    userAddressLine1,
    userTown,
    userPostalCode,
    getCountryName(userCountry),
  ];
  const companyKeys = [company, website, industry, turnover, years_in_business, accreditations, number_of_employees];
  const companyValues = [
    userCompany ? userCompany : "-",
    userWebsite,
    userIndustry,
    userTurnover,
    yearsInBusiness,
    userAccreditations,
    userNumberOfEmployees,
  ];

  const dispatch = useDispatch();

  const getKeyValuesPair = (keys, values) => {
    return keys.map((key, idx) => {
      return { key: key, value: values[idx] };
    });
  };
  let participantData = {
    title: translations.participant,
    list: getKeyValuesPair(keys, values),
  };
  let companyData = {
    title: company,
    list: getKeyValuesPair(companyKeys, companyValues),
  };
  userAddressLine2
    ? participantData.list.splice(5, 0, {
        key: addressLine2,
        value: userAddressLine2,
      })
    : null;

  userState
    ? participantData.list.splice(-2, 0, {
        key: state,
        value: userState,
      })
    : null;

  const showInvite = () => {
    return (
      !participant.participating &&
      !["running", "completed", "closed"].includes(event.current_state) &&
      eventParticipantStatus(participant) !== "Declined" &&
      permittedToEdit &&
      participant.invited
    );
  };

  const onResendInviteClick = (toAll) => {
    let payload = { event_id: event.id };
    if (toAll) payload.to_all = true;
    dispatch(resendInvite(participant.id, payload)).then(({ data }) => {
      data && data.message && alert(data.message);
    });
  };

  return (
    <>
      <div className="block">
        <div className="block-content">
          <div className="row two-block-box ">
            <div className="col-md-6 col-sm-6">
              <div className="block-item">
                <GetDetailsTable participantData={participantData} />
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="block-item">
                <GetDetailsTable participantData={companyData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetRecentActivityTable getKeyValuesPair={getKeyValuesPair} {...props} />
      {showInvite() && (
        <div id="resend_link" style={{ marginBottom: "35px" }}>
          <div className="dropdown col-sm-2">
            <div className="button button-black dropdown">
              <a className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                {resend_invites}
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a onClick={() => onResendInviteClick(false)}>{to_this_participant}</a>
                </li>
                <li>
                  <a onClick={() => onResendInviteClick(true)}>{to_all_unresponded_participants}</a>
                </li>
              </ul>
            </div>
          </div>
          <span>
            &nbsp;&nbsp;
            <i
              className="ion-ios-information-outline position-left "
              data-toggle="popover"
              data-placement="up"
              data-title={resendInviteParticipants.title}
              data-content={resendInviteParticipants.body}
            ></i>
          </span>
        </div>
      )}
    </>
  );
};
