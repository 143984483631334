import actionTypes from '../../actions/qq/actionsTypes';

const changeLanguageReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.change_language:
      return action.language;
    default:
      return state;
  }
};

export default changeLanguageReducer;
