import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Loader from "react-loader-advanced";

import getMaterialData from "../../../../../actions/qq/emarketplace/getMaterialDataActions";
import EMForm from "./EMForm";
import LoaderMessage from "../../../LoaderMessage";

class EMCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {},
      subCategory: {},
    };

    if (!this.hasCategories()) {
      this.props.getMaterialData();
    }
  }

  /**
   * Test to see this this compoent has the categories it needs to render.
   *
   * @returns {Boolean}
   */
  hasCategories = () => Boolean(this.props.materialData?.categories && this.props.materialData.categories.length);

  chooseCategory(category) {
    this.setState({
      category,
      subCategory: {},
    });
  }

  chooseSubCategory(subCategory) {
    this.setState({
      subCategory,
    });
  }

  render() {
    const { translations } = this.props;
    const categoryList = _.isEmpty(this.props.materialData)
      ? []
      : this.props.materialData.categories.map((category) => (
          <div className="col-md-2 col-sm-3 col-xs-3" key={category.id}>
            <div className="each_box">
              <input
                type="radio"
                name="category"
                id={category.name}
                value={category.name}
                onClick={() => {
                  this.chooseCategory(category);
                }}
              />
              <label htmlFor={category.name}>
                <span className="holder">
                  <span className="ico">
                    <img alt="" src={category.category_image_url} />
                    <img alt="" src={category.category_image_url} className="hover" />
                  </span>
                  <span className="label category">{category.name}</span>
                </span>
              </label>
            </div>
          </div>
        ));
    const subCategoryList = _.isEmpty(this.state.category.children) ? (
      <span>
        {translations.emarketplace.no_subcategories}&nbsp;
        {this.state.category.name}
      </span>
    ) : (
      this.state.category.children.map((subCategory) => (
        <div className="col-md-4 col-sm-4 col-xs-4" key={subCategory.id}>
          <div className="each_box">
            <input
              type="radio"
              name="sub-category"
              id={`sub${subCategory.name}`}
              value={subCategory.name}
              onClick={() => {
                this.chooseSubCategory(subCategory);
              }}
            />
            <label htmlFor={`sub${subCategory.name}`}>
              <span className="holder">
                <span className="ico">
                  <img alt="" src={subCategory.category_image_url} />
                  <img alt="" src={subCategory.category_image_url} className="hover" />
                </span>
                <span className="label sub_category">{subCategory.name}</span>
              </span>
            </label>
          </div>
        </div>
      ))
    );
    return (
      <div className="top-content">
        <div className="container">
          <div className="wrapper">
            <section className="body_section material_page_sec">
              <h3>{translations.emarketplace.create_material_request}</h3>
              <Loader
                show={!this.hasCategories()}
                message={<LoaderMessage loaderText={translations.loader_text} />}
                disableDefaultStyles
              >
                <div className="category_holder" id="choose_category">
                  <span className="category_title active" data-toggle="collapse" href="#collapseCategory">
                    {translations.emarketplace.choose_a_category}
                  </span>
                  {!this.state.errorMessage ? (
                    <div
                      className="category_box_holder clear text-center panel-collapse collapse in"
                      id="collapseCategory"
                    >
                      {categoryList.length ? categoryList : ""}
                    </div>
                  ) : (
                    <span className="text-primary">{this.state.errorMessage}</span>
                  )}
                </div>
              </Loader>
              <div className="category_holder" id="choose_sub_category">
                <div className="category_title active" data-toggle="collapse" href="#collapseSubCategory">
                  {translations.emarketplace.choose_a_subcategory}
                </div>
                <div
                  className="category_box_holder three_box clear text-center panel-collapse collapse in"
                  id="collapseSubCategory"
                >
                  {subCategoryList}
                </div>
              </div>
              {_.isEmpty(this.state.subCategory) ? (
                ""
              ) : (
                <EMForm
                  translations={translations}
                  category={this.state.category}
                  subCategory={this.state.subCategory}
                  history={this.props.history}
                />
              )}
            </section>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  materialData: state.materialDataReducer,
  success: state.successMessageReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getMaterialData: () => dispatch(getMaterialData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EMCreate);
