import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import isEqual from "lodash/isEqual";
import {
  lotTotalDroppableClass,
  fetchAssociatedCurrency,
  splitFormula,
  isOperator,
  isNumber,
  isValidCell,
} from "../../common";
import LineItemComponentLink from "./LineItemComponentLink";
import { setLotTotal } from "../../../../actions/lotActions";

const LineItemComponentDetail = React.memo(
  (props) => {
    const {
      event,
      lot,
      lic,
      eventCurrency,
      currenciesHash,
      permittedToEdit,
      maxPrecisionFor,
      anyCompletedBids,
      translations,
      lots,
      lineItemComponents,
      classFromEventState,
      openOrJapanese,
      isJapaneseEvent,
      showDetails,
      locale,
      timeZoneIdentifier,
      currentUser,
      isMultiCurrencyEvent,
    } = props;

    const [showLoaderOnLicId, setShowLoaderOnLicId] = useState(0);
    const dispatch = useDispatch();
    // This method allows dropping of HTML elements into some other HTML elements
    const allowDrop = useCallback((ev) => {
      ev.preventDefault();
    }, []);

    // Sends an AJAX request if the drop of star is on correct LIC which is checked by the droppable class
    const drop = useCallback((ev, lic, event, lot, droppableClass) => {
      ev.preventDefault();
      const droppedElClass = ev.dataTransfer.getData("text");
      if (droppableClass === "droppable" && droppedElClass.includes("star")) {
        setShowLoaderOnLicId(lic.id);
        const payload = { lot_total_lic_id: lic.id };
        dispatch(setLotTotal(event.id, lot.id, payload)).then(setShowLoaderOnLicId(0));
      }
    }, []);

    const calculationClasses = useCallback((calculationComponent, calculableCurrency, lot) => {
      const isCalculationCell =
        calculableCurrency && !calculationComponent ? "price-cell draggable-cell" : "calculation-cell";
      let isCalculationColumn = "";
      if (calculationComponent && !lot.is_event_total) {
        isCalculationColumn = "calculation-column-cell draggable-cell";
      } else if (lot.is_event_total) {
        isCalculationColumn = "draggable-cell";
      }
      return `${isCalculationCell} ${isCalculationColumn}`;
    }, []);

    const calculationComponent = lic.is_formula_calculation_component;
    const calculableCurrency = lic.calculable_currency_lc;
    const currency = isMultiCurrencyEvent ? fetchAssociatedCurrency(currenciesHash, lot, lic) : eventCurrency;

    const isHost = lic.host;
    const isParticipant = lic.participant;

    const droppableClass = lotTotalDroppableClass(lic, lot, lineItemComponents, lots, isValidCell(lic));

    // onDrop and onDragOver - to allow drop of star on these tds (HTML5 drag and drop API)
    return (
      <td
        id={`lic_${lic && lic.tag ? lic.tag : "text"}`}
        className={`${calculationClasses(calculationComponent, calculableCurrency, lot)} ${droppableClass}`}
        data-id={lic ? lic.id : ""}
        data-event={event.id}
        onDrop={(ev) => drop(ev, lic, event, lot, droppableClass)}
        onDragOver={allowDrop}
      >
        <LineItemComponentLink
          event={event}
          lot={lot}
          lic={lic}
          currency={currency}
          permittedToEdit={permittedToEdit}
          maxPrecisionFor={maxPrecisionFor}
          anyCompletedBids={anyCompletedBids}
          translations={translations}
          classFromEventState={classFromEventState}
          openOrJapanese={openOrJapanese}
          isJapaneseEvent={isJapaneseEvent}
          showLoaderOnLicId={showLoaderOnLicId}
          lots={lots}
          showDetails={showDetails}
          currenciesHash={currenciesHash}
          locale={locale}
          timeZoneIdentifier={timeZoneIdentifier}
          currentUser={currentUser}
          calculationComponent={calculationComponent}
          isParticipant={isParticipant}
          isHost={isHost}
        />
      </td>
    );
  },
  (prevProps, nextProps) => {
    const { lic, lot } = prevProps;
    switch (true) {
      case !isEqual(lic, nextProps.lic):
      case [nextProps.lot.lot_total_lic_id, lot.lot_total_lic_id].includes(nextProps.lic.id):
        return false;
      default:
        return true;
    }
  }
);

export default LineItemComponentDetail;
