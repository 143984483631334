import React from "react";
import PropTypes from "prop-types";

import { brands } from "../types";
import { BasePropTypes, classNamesFromProps } from "../box/box";
import clsx from "clsx";

const ButtonSpinner = ({ children }) => {
  return (
    <div className="w-100 position-relative">
      <span
        className="bottom-0 end-0 m-auto position-absolute spinner-border spinner-border-sm start-0 top-0"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </span>
      <span aria-hidden="true" className={`opacity-0`}>
        {children}
      </span>
    </div>
  );
};

ButtonSpinner.propTypes = {
  /**
   * The children for the spinner so we can keep the button width when
   * displaying the spinning state
   */
  children: PropTypes.node,
};

const ButtonPropTypes = {
  className: PropTypes.string,
  /**
   * The Market Dojo brand style this button will be styled in.
   *
   */
  brand: PropTypes.oneOf(brands),
  /**
   * The size of the button. If it dose not have a size then it will be
   * displayed as a standard button.
   */
  size: PropTypes.oneOf(["sm"]),
  /**
   * If the button is in a spinning state
   *
   * @type {boolean}
   */
  spinning: PropTypes.bool,
  /**
   * The inner content of the button
   *
   * @type {React.ReactNode}
   */
  children: PropTypes.node,
  /**
   * The action that will run when the button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The type of this button
   */
  type: PropTypes.oneOf(["submit", "button"]),
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  as: PropTypes.elementType,
  ...BasePropTypes,
};

/**
 * HTML tags that can accept button props without prop clashes
 * @typedef {{ [K in keyof JSX.IntrinsicElements]: React.ComponentProps<"button"> extends JSX.IntrinsicElements[K]  ? K : never }[keyof JSX.IntrinsicElements]} ButtonCompatibleTags
 */

/**
 * The Market Dojo button component
 *
 * @type {React.FC<PropTypes.InferProps<typeof ButtonPropTypes> & React.ComponentProps<"button"> & { as?: React.ComponentType<React.ComponentProps<"button">> | ButtonCompatibleTags }>}
 */
export const Button = (props) => {
  const { children, brand, spinning, icon, size, className, disabled, as: Tag = "button", ...buttonProps } = props;

  const finalDisabled = spinning || disabled;

  return (
    <Tag
      {...buttonProps}
      className={clsx(
        "btn btn-md",
        `btn-${brand}`,
        {
          "btn-md-icon": icon,
          disabled: finalDisabled,
          [`btn-${size}`]: size,
        },
        classNamesFromProps(buttonProps),
        className
      )}
      disabled={finalDisabled}
    >
      {spinning ? <ButtonSpinner>{children}</ButtonSpinner> : children}
    </Tag>
  );
};

Button.propTypes = ButtonPropTypes;
Button.defaultProps = {
  brand: "primary",
  spinning: false,
};

export default Button;
