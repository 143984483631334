import React from "react";

const SupplierStakeholderInput = (props) => {
  const { includedStakeholders, includedSuppliers, handleIncludeRecipients, suppliers, stakeholders, userType, inputId } = props;
  const userChecked = (e) => {
    if(userType == 'stakeholders'){
      return(includedStakeholders.length === stakeholders.length);
    }
    else{
      return(includedSuppliers.length === suppliers.length);
    }
  }

  return(
  <input
    type="checkbox"
    name={userType+'_all'}
    id={inputId}
    checked={userChecked()}
    onChange={(e) => handleIncludeRecipients(e, true, userType == 'stakeholders')}
  />
  );
}

export default SupplierStakeholderInput;
