import React from "react";
import { showDateTime } from "../../../../../common";
export default (props) => {
  const { getKeyValuesPair, translations, user, participant, timeZoneIdentifier } = props;
  const { action, auction_date, recent_activity, seen_at, accept_by_participant, accept_by_host } = translations;
  const keysTd1 = [action, action, action];
  const keysTd2 = [auction_date, auction_date, auction_date];

  const valuesTd1 = [seen_at, accept_by_host, accept_by_participant];
  const valuesTd2 = [
    showDateTime(user.seen_at, timeZoneIdentifier),
    showDateTime(participant.host_accepted_at, timeZoneIdentifier),
    showDateTime(participant.participant_accepted_at, timeZoneIdentifier),
  ];
  let data1 = getKeyValuesPair(keysTd1, valuesTd1);
  let data2 = getKeyValuesPair(keysTd2, valuesTd2);

  const gettableDiv = (data) => {
    const { key, value } = data;
    return (
      <td className="text-center">
        <div className="col-caption hidden-lg hidden-md hidden-sm">{key}</div>
        {value}
      </td>
    );
  };

  const getRowElements = () => {
    let result = [];
    for (let i = 0; i < 3; i++) {
      result.push(
        <tr key={i}>
          {gettableDiv(data1[i])}
          {gettableDiv(data2[i])}
        </tr>
      );
    }
    return result;
  };

  return (
    <>
      <div className="col-md-12">
        <div className="title fs-14">{recent_activity}</div>
        <table className="table table-bordered table-sp custom-responsive-table participant-activities">
          <thead>
            <tr>
              <td className="text-left">{action} </td>
              <td className="text-left">{auction_date}</td>
            </tr>
          </thead>
          <tbody>{getRowElements()}</tbody>
        </table>
      </div>
    </>
  );
};
