import type { ReducerCreators, SliceCaseReducers } from "@reduxjs/toolkit";

/**
 * Allows enriching the `create` object with custom creators for common logic within a slice.
 *
 * @param creators - A function that receives the original `create` object and returns an object with custom creators.
 * @param caseReducers - A function that receives the enriched `create` object with custom creators and returns the case reducers.
 *
 * @example
 * reducers: withCustomCreators(
 *   (create) => ({ batchable: (reducer) => create.preparedReducer(prepareAutoBatched(), reducer) })
 *   (create) => ({
 *     increment: create.batchable((state) => { state.value += 1 }),
 *   })
 * )
 */
export const withCustomCreators =
  <State, Creators extends Record<string, unknown>, CaseReducers extends SliceCaseReducers<State>>(
    creators: (create: ReducerCreators<State>) => Creators,
    caseReducers: (create: ReducerCreators<State> & Creators) => CaseReducers
  ) =>
  (create: ReducerCreators<State>) =>
    caseReducers({ ...create, ...creators(create) });
