import React from "react";
import {
  lotTotalHasQualificationPrice,
  canAddRankedCells,
  remainingRankedCells,
  remainingCellsForBidAtDetailLevel,
  canAddCellsForBidAtDetailLevel,
} from "../common";

const LotChecklist = (props) => {
  const lotChecklistChecked = (status) => {
    return (
      <div className={`check-boll ${status ? "ready" : "rose-bg"}`}>
        <i className={`${status ? "fa fa-check" : "fa fa-times"}`} />
      </div>
    );
  };

  const {
    lot,
    lotLcs,
    lotLics,
    participantEnteredPrice,
    translations,
    isJapaneseEvent,
    weightedRfqOrAuction,
    lineItemComponents,
    maxNumberOfCellsPerEvent,
    maxNumberOfRankedLics,
    maxNumberOfCellsForBidAtDetailLevel,
  } = props;

  const {
    remaining_ranked_cells_per_event,
    remaining_ranked_line_item_components,
    remaining_cells_for_bid_at_detail_level,
  } = translations;

  const checkListData = [
    {
      text: remaining_ranked_cells_per_event,
      remaining: remainingRankedCells(maxNumberOfCellsPerEvent, lineItemComponents),
      canAddMore: canAddRankedCells(maxNumberOfCellsPerEvent, lineItemComponents),
    },
    {
      text: remaining_ranked_line_item_components,
      remaining: remainingRankedCells(maxNumberOfRankedLics, lotLics),
      canAddMore: canAddRankedCells(maxNumberOfRankedLics, lotLics),
    },
    {
      text: remaining_cells_for_bid_at_detail_level,
      remaining: remainingCellsForBidAtDetailLevel(maxNumberOfCellsForBidAtDetailLevel, lotLics),
      canAddMore: canAddCellsForBidAtDetailLevel(maxNumberOfCellsForBidAtDetailLevel, lotLics),
    },
  ];

  return (
    <>
      <table className="checklist">
        <tbody>
          <tr>
            <td>
              {lotChecklistChecked(lotLcs.length > 0)}
              {translations.lot_component}
            </td>
            <td>
              {lotChecklistChecked(lotLcs.length > 0)}
              {translations.line_item}
            </td>
            {!lot.is_event_total && (
              <td>
                {lotChecklistChecked(participantEnteredPrice)}
                {translations.participant_entered_price}
              </td>
            )}

            {(weightedRfqOrAuction || isJapaneseEvent) && (
              <td>
                {lotChecklistChecked(lotTotalHasQualificationPrice(lot, lineItemComponents))}
                {translations.qualification_price_for_total}
              </td>
            )}

            {!lot?.is_event_total &&
              checkListData.map((data) => (
                <td key={data.text}>
                  {lotChecklistChecked(data.canAddMore)}
                  {data.text}:&nbsp;
                  <span className="ranked-cells-remaining">{data.remaining}</span>
                </td>
              ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default LotChecklist;
