import config from "../../config";
import * as actionTypes from "../actionsTypes";
import * as apiCall from "../api";
import { getErrors } from "../errorActions";
import {
  discardActivityModalAction,
  reloadActivityList,
  SetInitialState,
  setRefreshAction,
  showSuccess,
} from "./creators";

export const initialState = (props) => (dispatch) => {
  dispatch(SetInitialState(props));
};

export const updateActivitiesCount = (props) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_ACTIVITY_COUNT,
    payload: props,
  });
};

const _relayUpdates = (response, dispatch, successMessage, params) => {
  dispatch(showSuccess(successMessage));
  if (params.refreshList) {
    dispatch(reloadActivityList());
  }

  return response;
};

export const updateActivity = (params, activityId, successMessage) => (dispatch) =>
  apiCall
    .put(`${config.SRM_BASE_URL}/activities/${activityId}`, params)
    .then((response) => {
      if (response.status != 200) {
        return response;
      }

      return _relayUpdates(response, dispatch, successMessage, params);
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const createOrFetchDraftActivity = (params) => (dispatch) =>
  apiCall
    .post(`${config.SRM_BASE_URL}/activities/create_or_fetch_draft_activity`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const searchContacts =
  (value, limit, account_id = null) =>
  (dispatch) =>
    apiCall
      .get(
        `${config.SRM_BASE_URL}/contacts/search?value=${value}&limit=${limit}${
          account_id ? "&account_id=" + account_id : ""
        }`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        dispatch(getErrors(error.response));
      });

export const searchHostUsers = (value, companyId, limit) => (dispatch) =>
  apiCall
    .get(`${config.V2_BASE_URL}/companies/${companyId}/users/search?value=${value}&limit=${limit}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const createConcernedParty = (params, activityId, successMessage) => (dispatch) =>
  apiCall
    .post(`${config.SRM_BASE_URL}/activities/${activityId}/concerned_parties`, params)
    .then((response) => {
      dispatch(showSuccess(successMessage));
      return response;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const updateConcernedParty = (params, id, activityId, successMessage) => (dispatch) =>
  apiCall
    .put(`${config.SRM_BASE_URL}/activities/${activityId}/concerned_parties/${id}`, params)
    .then((response) => {
      dispatch(showSuccess(successMessage));
      return response;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const destroyConcernedParty = (id, activityId, successMessage) => (dispatch) =>
  apiCall
    .remove(`${config.SRM_BASE_URL}/activities/${activityId}/concerned_parties/${id}`)
    .then((response) => {
      dispatch(showSuccess(successMessage));
      return response.status;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const createNote = (params, activityId, successMessage) => (dispatch) =>
  apiCall
    .post(`${config.SRM_BASE_URL}/activities/${activityId}/notes`, params)
    .then((response) => {
      dispatch(showSuccess(successMessage));
      return response;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const fetchActivity = (activityId) => (dispatch) =>
  apiCall
    .get(`${config.SRM_BASE_URL}/activities/${activityId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const deleteActivity = (activityId, page, perPage) => (dispatch) =>
  apiCall
    .remove(`${config.SRM_BASE_URL}/activities/${activityId}?page=${page}&per_page=${perPage}`)
    .then((response) => {
      dispatch(reloadActivityList());
      return response;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const updateNote = (activityId, noteId, params, successMessage) => (dispatch) =>
  apiCall
    .put(`${config.SRM_BASE_URL}/activities/${activityId}/notes/${noteId}`, params)
    .then((response) => {
      dispatch(showSuccess(successMessage));
      return response;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const deleteNote = (activityId, noteId, successMessage) => (dispatch) =>
  apiCall
    .remove(`${config.SRM_BASE_URL}/activities/${activityId}/notes/${noteId}`)
    .then((response) => {
      dispatch(showSuccess(successMessage));
      return response;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const updateDocument = (params, activityId, documentId, successMessage) => (dispatch) =>
  apiCall
    .put(`${config.SRM_BASE_URL}/activities/${activityId}/documents/${documentId}`, params)
    .then((response) => {
      dispatch(showSuccess(successMessage));
      return response;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const deleteDocument = (activityId, documentId, successMessage) => (dispatch) =>
  apiCall
    .remove(`${config.SRM_BASE_URL}/activities/${activityId}/documents/${documentId}`)
    .then((response) => {
      dispatch(showSuccess(successMessage));
      return response;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const fetchActivities = (page, perPage, searchParam, filterParams) => (dispatch) =>
  apiCall
    .get(`${config.SRM_BASE_URL}/activities?page=${page}&per_page=${perPage}&search=${searchParam}${filterParams}`)
    .then((response) => {
      if (response.status) {
        dispatch(updateActivitiesCount(response.data));
      }
      return response;
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const toggleDiscardActivityModal = (payload) => (dispatch) => dispatch(discardActivityModalAction(payload));

export const setRefreshModal = (payload) => (dispatch) => dispatch(setRefreshAction(payload));

export const discardDraftActivity = (id, message) => (dispatch) => {
  return dispatch(deleteActivity(id, 0, 0)).then((response) => {
    if (response && response.status) {
      dispatch(showSuccess(message));
    }

    return response;
  });
};
