import React from "react";

const StakeholderNotification = (props) => {
  const {handleIncludeRecipients, includedStakeholders, translations, stakeholders, inputId} = props;
  return(<div className="notification-content-box custom_notification_table">
      <table className="table _scrolling _thead">
      <thead>
        <tr className="select_all">
          <th /><th>{translations.user_name}</th> <th>{translations.user_status}</th>
        </tr>
      </thead>
        <tbody>
          {stakeholders.map((stakeholder, index) => (
            <tr key={index}>
              <td>
                <input type="checkbox" id={`${inputId}${stakeholder.id}`}
                  className="notification-supplier" checked={includedStakeholders.includes(stakeholder.user_id)}
                  onChange={(e) => handleIncludeRecipients(e, false, true, stakeholder.user_id)}
                />
                <label htmlFor={`${inputId}${stakeholder.id}`} />
              </td>
              <td>{stakeholder.name ? stakeholder.name : stakeholder.email}</td>
              <td>{stakeholder.status}</td>
            </tr>))}
        </tbody>
      </table>
    </div>
  );
}

export default StakeholderNotification;
