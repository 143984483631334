import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import { updateEditPageFieldStatus } from "../../../../actions/contract/contractConfigurationActions";
import Checkbox from "../Checkbox";

class ContractValue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contractValues: props.contractValues,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.data.editFieldsUpdatedData) {
      this.setState({
        contractValues: newProps.data.editFieldsUpdatedData.data["contract_value"],
      });
    }
  }

  handleChange(e) {
    const fieldName = e.target.name;
    const isChecked = e.target.checked;
    const { companyId, columnId } = this.props;
    this.props.updateEditPageFieldStatus("contract_value", fieldName, columnId, isChecked, companyId);
  }

  render() {
    const { contractValues } = this.state;
    const { translations, currentUserEditConfigPermission } = this.props;
    return (
      <div className="col-md-12 col-sm-12">
        <div className="block-item btn-group-sm same-height-block">
          <div className="form-group">
            <div className="title">{translations.contract_value}</div>
            {Object.keys(contractValues).map((key, index) => (
              <div key={index} className="split-checkboxes">
                <Checkbox
                  name={key}
                  checked={contractValues[key]}
                  onChange={this.handleChange}
                  translations={translations}
                  readOnly={!currentUserEditConfigPermission}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.contractConfigurationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateEditPageFieldStatus: (columnName, fieldName, columnId, status, companyId) =>
    dispatch(updateEditPageFieldStatus(columnName, fieldName, columnId, status, companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractValue);
