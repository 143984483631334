import React from "react";
import PropTypes from "prop-types";

const ParentColumn = ({ filterActive, activity, editBtnOrTxt }) => {
  return (
    filterActive && (
      <td className="parent_column">
        {activity.parent_id &&
          editBtnOrTxt({
            id: activity.parent_id,
            subject: activity.parent_subject,
          })}
      </td>
    )
  );
};

ParentColumn.propTypes = {
  filterActive: PropTypes.bool,
  activity: PropTypes.object,
  editBtnOrTxt: PropTypes.func.isRequired,
};

ParentColumn.defaultProps = {
  filterActive: false,
};

export default ParentColumn;
