import React from "react";
import { SettingSectionWrapper } from "./StakeholderInvitation";
import { useSelector } from "react-redux";

const getInputData = ({ translations, configuration_setting_values, onValueChange }) => {
  const { allow_seal_bid_option, sealed_results_by_default, all_events_sealed } = configuration_setting_values;

  const settings = { allow_seal_bid_option, sealed_results_by_default, all_events_sealed };

  return Object.entries(settings).map(([name, value]) => ({
    label: translations[name],
    toolTipData: translations.configuration[name],
    name,
    value: Boolean(value),
    id: name,
    checked: Boolean(value),
    onChange: (e) => onValueChange(Boolean(e.target.checked), name),
  }));
};

export default function SealedResults({ translations, configuration_setting_values, onValueChange }) {
  const configurationSettingValues =
    useSelector((state) => state.simSettingReducers?.configuration_setting_values) ?? configuration_setting_values;

  const inputData = getInputData({
    translations,
    configuration_setting_values: configurationSettingValues,
    onValueChange,
  });

  return <SettingSectionWrapper inputData={inputData} title={translations.sealed_results} />;
}
