import { injectWinningBidsAndParticipantInToLot } from "./esker-purchase-requistion";

export const createEskerProcurementDocData = (
  procurementDocType,
  event,
  lots,
  bids,
  currencies,
  participants,
  awardedParticipantsList,
  targetLotIds,
  unit_of_measures
) => {
  if (procurementDocType === "vendor_contract") {
    return createEskerVendorContractData(
      event,
      lots,
      bids,
      currencies,
      participants,
      awardedParticipantsList,
      targetLotIds,
      unit_of_measures
    );
  } else {
    return createEskerRequisitionData(
      event,
      lots,
      bids,
      currencies,
      participants,
      awardedParticipantsList,
      targetLotIds,
      unit_of_measures
    );
  }
};

const createEskerRequisitionData = (
  event,
  lots,
  bids,
  currencies,
  participants,
  awardedParticipantsList,
  targetLotIds,
  unit_of_measures
) => {
  const lotData = combinedLotData(
    lots,
    targetLotIds,
    bids,
    event,
    participants,
    awardedParticipantsList,
    unit_of_measures
  ).map(createEskerRequisitionLotData(event, currencies));

  const data = {
    Reason__: event.name,
    lineItems__: lotData,
  };
  return data;
};

const combinedLotData = (lots, targetLotIds, bids, event, participants, awardedParticipantsList, unitOfMeasures) => {
  return lots
    .filter((lot) => targetLotIds.includes(lot.id))
    .map(injectWinningBidsAndParticipantInToLot(bids, event, participants, awardedParticipantsList, unitOfMeasures));
};

const createEskerRequisitionLotData = (event, currencies) => {
  return function (lot) {
    return {
      ItemDescription__: lot.name,
      VendorName__: lot.participant?.user_company_name || lot.participant?.user_company_name,
      ItemType__: "QuantityBased",
      ItemQuantity__: lot.quantity,
      ItemUnitPrice__: lot.participantWinningBid?.host_price,
      ItemCurrency__: currencies[lot.exchange_rate_id || event.currency_id].name,
      ItemSourcingEventId__: lot.event_id,
      ItemSourcingLotId__: lot.id,
    };
  };
};

const createEskerContractLotData = (event, currencies) => {
  return function (lot) {
    return {
      ItemDescription__: lot.name,
      ItemQuantity__: lot.quantity,
      ItemUnitPrice__: lot.participantWinningBid?.host_price,
      ItemCurrency__: currencies[lot.exchange_rate_id || event.currency_id].name,
      ItemSourcingLotId__: lot.id,
      ItemUnitOfMeasure__: lot.uom?.name,
    };
  };
};

const createEskerVendorContractData = (
  event,
  lots,
  bids,
  currencies,
  participants,
  awardedParticipantsList,
  targetLotIds,
  unit_of_measures
) => {
  const lotData = combinedLotData(
    lots,
    targetLotIds,
    bids,
    event,
    participants,
    awardedParticipantsList,
    unit_of_measures
  );
  const combinedVendor = [...new Set(lotData.map((lot) => lot.participant?.user_company_name))].join(", ");
  const itemsData = lotData.map(createEskerContractLotData(event, currencies));
  return {
    CombinedVendor__: combinedVendor,
    SourcingEventId__: event.id,
    Name__: event.name,
    ItemsToCreate__: itemsData,
  };
};

export const createEskerRequisitionUrl = (url, event, lotData) => {
  const data = JSON.stringify(lotData);

  return url + encodeURIComponent(data);
};

export default createEskerRequisitionUrl;
