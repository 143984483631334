/**
 * Initial state value for the lot reducer. This can be used to type and
 * document keys in the reducer so we can have a source of truth for what they
 * are and do.
 */
const initialState = {
  /**
   * All of the available status that a lot can be in.
   *
   * @typedef {'bidding' | 'viewing' | pending:cancel' | 'pending:placeBid' | 'pending:submit'} lotStatus
   *
   * Status map to keep track of the current state in the lot state machine.
   * The key of the map will be the lot id and the value will be a string
   * containing the state value. If the map dose not contain the lot id then it
   * is assumed the Initial state value
   *
   * Currently this is working progress and the Initial state is
   * "biddingInvalid" Follow the link below to see a full diagram of the state
   * machine.
   *
   * @see https://excalidraw.com/#json=vO5mQLMIBtPMbP8TXN1HA,c5whdTAHKlno7rsKffkMMw
   * @type {{[key: number]: lotStatus}}
   */
  lotStatus: {},
  isRegenerating: false,
  regenerateProgress: 0,

  /**
   * It will store the warning messages for the line item components.
   * @type {Object<number, {lineItemId: number, lineItemComponentId: number, message: string}>}
   * - The key is `lot ID`.
   * - Each value is an object containing:
   *   - `lineItemId` (number): The ID of the line item.
   *   - `lineItemComponentId` (number): The ID of the line item component.
   *   - `message` (string): The warning message.
   */
  lineItemComponentWarnings: {},

  /**
   * Automatic lot template selection state
   * @type {'enabled' | 'disabled' | 'enforced' | null}
   */
  lot_automation_state: "",

  /**
   * State determining whether the lot generation service should immediately be run in enforced.
   * @type {true | false}
   */
  refresh_enforced_autoselection_lots: false,

  /**
   * State determining where user is in sandpit or not
   * @type {boolean}
   */
  in_sandpit: false,
};

/**
 * @typedef {Object} Translations
 * @property {Record<string, string> | undefined} number_format
 * @property {Record<string, Record<'title' | 'body', string>> | undefined} infos
 */

/**
 * @typedef {Object} LotReducerState
 * @property {import("prop-types").InferProps<typeof import("@/common-prop-types").EventPropType>} event
 * @property {Translations} translations
 */

/**
 * @type {typeof initialState & LotReducerState}
 */
export default initialState;
