import React from "react";
import type { LotComponent } from "@/common-prop-types/lot-component";
import { host, participant } from "../../common";
import { t } from "@/i18n";

import styles from "./lot-component-detail-tag.module.scss";

export interface LotComponentDetailTagProps {
  lotComponent: LotComponent;
}

const HostTag: React.FC = () => {
  return <span className={styles.hostTag}>{t("lot_component_providers.host")}</span>;
};

const ParticipantTag: React.FC = () => {
  return <span className={styles.participantTag}>{t("lot_component_providers.participant")}</span>;
};

export const LotComponentDetailTag: React.FC<LotComponentDetailTagProps> = ({ lotComponent }) => {
  if (host(lotComponent)) {
    return <HostTag />;
  }

  if (participant(lotComponent)) {
    return <ParticipantTag />;
  }

  return null;
};
