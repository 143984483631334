import PropTypes from "prop-types";

export const Bid = {
  bid_at_rfq_state: PropTypes.bool,
  bid_round: PropTypes.number,
  created_at: PropTypes.string,
  current: PropTypes.bool,
  current_state: PropTypes.string,
  decline_bid: PropTypes.bool,
  details_added: PropTypes.bool,
  event_id: PropTypes.number,
  event_round_id: PropTypes.number,
  exchange_rate_id: PropTypes.number,
  host_id: PropTypes.number,
  host_notified: PropTypes.bool,
  host_price: PropTypes.number,
  id: PropTypes.number,
  lot_id: PropTypes.number,
  overall_rank: PropTypes.number,
  overall_rank_when_created: PropTypes.number,
  price: PropTypes.number,
  price_score: PropTypes.number,
  rank: PropTypes.number,
  rank_when_created: PropTypes.number,
  rejected_lot: PropTypes.bool,
  showLabel: PropTypes.bool,
  submitted_at: PropTypes.string,
  user_id: PropTypes.number,
  weighted_score: PropTypes.string,
};

/** @typedef {PropTypes.InferProps<typeof Bid>} Bid */

export default Bid;
