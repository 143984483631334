import React from "react";
import Select2 from "react-select2-wrapper";

class TreeNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  toggle() {
    this.setState({ visible: !this.state.visible });
  }

  handleAddchild(e) {
    this.props.addChildContract(e.target.value);
  }

  createDataPairForSelect(data) {
    return data.map((item) => ({
      text: `${item.title} (ID ${item.id})`,
      id: item.id,
    }));
  }

  render() {
    let childNodes;
    let className = "";
    let { node, childIteration, childs, translations, showPage } = this.props;
    childIteration += 1;
    if (node && node.child_nodes && childIteration <= 5) {
      if (this.state.visible) {
        className += "fa fa-minus-square-o icon-minus-sign";
      } else {
        className += "fa fa-plus-square-o icon-plus-sign";
      }
      childNodes = node.child_nodes.map((child, index) => {
        return (
          <TreeNode
            node={child}
            key={index}
            childIteration={childIteration}
            updateChildIdToRemove={this.props.updateChildIdToRemove}
            showPage={showPage}
          />
        );
      });
    }

    let style;
    if (!this.state.visible) {
      style = { display: "none" };
    }
    return node ? (
      <li className="parent_li">
        {node.child_nodes.length > 0 && (
          <span className="child_span" onClick={() => this.toggle()}>
            <i className={className} />
          </span>
        )}
        <span className="child_span badge orange" id={`child_${node.id}`}>
          <a href={`/contracts/${node.id}/edit`}>
            {node.title}
            {childIteration !== 1 && ` (ID ${node.id})`}
          </a>
        </span>
        {!showPage && childIteration !== 1 && (
          <a
            onClick={() => this.props.updateChildIdToRemove(node.id)}
            data-toggle="modal"
            data-target="#parentChildRemoveConfirmModal"
            rel="nofollow"
            href="javascript:void(0)"
          >
            <i className="fa fa-times" />
          </a>
        )}
        <ul style={style}>
          {childNodes}
          {!showPage && childIteration === 1 && (
            <li className="parent_li">
              <Select2
                className="form-control col-md-4"
                multiple={false}
                data={this.createDataPairForSelect(childs)}
                options={{
                  placeholder: translations.select,
                }}
                onSelect={(e) => this.handleAddchild(e)}
              />
            </li>
          )}
        </ul>
      </li>
    ) : (
      ""
    );
  }
}

export default TreeNode;
