import React from "react";
import { useDevDebugValue } from "./use-dev-debug-value";

/**
 * Like use state but will always revert to the default state if changed.
 *
 * When using values in redux as input values you can get into a situation
 * where the value of the input switches back to redux value while the API
 * request is pending. Then when the request is fulfilled the value will return
 * to the value the user input. You can use this hook to set the local
 * component state but then switch to the value in the store when it changes.
 */
export const useUpToDateValue = <T>(defaultValue: T) => {
  const [value, setValue] = React.useState(defaultValue);
  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useDevDebugValue(value);

  return [value, setValue] as const;
};

export default useUpToDateValue;
