import React from "react";
import { isEmpty } from "lodash";
import { MarkAsReadButton } from "./InboxList";
import PropTypes from "prop-types";
import { MessagePropType, RecipientPropType } from "./prop-types";
import RenderHTML from "@/components/common/RenderHTML";

const OutboxListPropTypes = {
  message: PropTypes.shape(MessagePropType),

  /**
   * Id of the user
   * @type {number} userId
   */
  userId: PropTypes.number,

  /**
   * The object defining all of the display info for the component
   * @type {object}
   */
  translations: PropTypes.object.isRequired,
};

const MessageHeaderPropTypes = {
  ...OutboxListPropTypes,

  /**
   * @type {string} kindOfMessage
   */
  kindOfMessage: PropTypes.oneOf(["outbox", "inbox"]),
};

const MessageContentPropTypes = {
  ...OutboxListPropTypes,

  /**
   * Id of the user
   * @type {number} userId
   */
  userId: PropTypes.number,
};

const MessageRecipientsPropTypes = {
  messageRecipients: PropTypes.shape(RecipientPropType),

  /**
   * @type {string} kindOfMessage
   */
  kindOfMessage: PropTypes.oneOf(["outbox", "inbox"]),
};

const MessageRecipientPropTypes = {
  ...MessageRecipientsPropTypes,
  messageRecipient: PropTypes.shape(RecipientPropType),
};

/**
 *
 * @type {React.FC<typeof OutboxListPropTypes>}
 */
const OutboxList = (props) => {
  const { message, translations } = props;
  const messageBoxId = `message_${message.id}`;
  return (
    <div className="message-box m-b10 clearfix" id={messageBoxId} data-testid={messageBoxId}>
      <span className="small-button-box blue-ouline">{translations.summary_status.message.outbox}</span>
      <div className="message-section outbox-ms">
        <MessageHeader message={message} translations={translations} kindOfMessage="outbox">
          <span className="small-gray-box">{translations.summary_status.message.me}</span>
        </MessageHeader>
        <MessageContent {...props} />
      </div>
    </div>
  );
};
OutboxList.propTypes = OutboxListPropTypes;

/**
 * @type {React.FC<typeof MessageHeaderPropTypes>}
 */
export const MessageHeader = ({ message, translations, kindOfMessage, children }) => {
  const messageRecipients = message.recipients;
  return (
    <div className="message-header">
      <span className="date">{message.created_at}</span>
      <div className="status-info">
        <span className="light-gray-color">{translations.summary_status.message.to}:&nbsp;</span>
        {messageRecipients.length > 1 ? (
          <MessageRecipients messageRecipients={messageRecipients} kindOfMessage={kindOfMessage} />
        ) : (
          <MessageRecipient messageRecipient={messageRecipients[0]} kindOfMessage={kindOfMessage} />
        )}
        <div className="mobile-clearfix" />
        <span className="light-gray-color">{translations.summary_status.message.from}:&nbsp;</span>
        {children}
      </div>
    </div>
  );
};
MessageHeader.propTypes = MessageHeaderPropTypes;

/**
 * @type {React.FC<typeof MessageContentPropTypes>}
 */
export const MessageContent = (props) => {
  const { userId, message } = props;
  const loggedUserRecipient = message.recipients?.find(({ user_id }) => user_id === userId) || {};
  return (
    <div className="message-content">
      <RenderHTML content={message.message} />
      <br />
      {message.bounced &&
        !isEmpty(loggedUserRecipient) &&
        !loggedUserRecipient.message_read &&
        loggedUserRecipient.user_id === userId && <MarkAsReadButton {...props} />}
    </div>
  );
};
MessageContent.propTypes = MessageContentPropTypes;

/**
 * @type {React.FC<typeof MessageRecipientsPropTypes>}
 */
export const MessageRecipients = ({ messageRecipients, kindOfMessage }) => {
  return (
    <div className="small-gray-box btn-group dropdown-sm">
      <button className="dropdown-toggle" data-toggle="dropdown">
        <i className="fa fa-users" aria-hidden="true" /> {messageRecipients?.length}
        <span className="caret" />
      </button>
      <ul className="dropdown-menu" role="menu" data-testid="recipients-dropdown">
        {messageRecipients?.map((recipient) => {
          const listTitle = `Company: ${recipient.company_name ? recipient.company_name : ""}; E-mail: ${
            recipient.email
          }`;
          const listItemLabel = getListItemLabel(kindOfMessage, recipient);
          return (
            <li key={recipient.user_id}>
              <button className="w-100 text-left text-nowrap" data-toggle="tooltip" title={listTitle}>
                {listItemLabel}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
MessageRecipients.propTypes = MessageRecipientsPropTypes;

/**
 * @type {React.FC<typeof MessageRecipientsPropTypes>}
 */
export const MessageRecipient = ({ messageRecipient, kindOfMessage }) => {
  const spanText = getListItemLabel(kindOfMessage, messageRecipient);
  return (
    <span
      className="small-gray-box"
      data-toggle="tooltip"
      title={`Company: ${messageRecipient.company_name}; E-mail: ${messageRecipient.email}`}
    >
      {" "}
      {spanText}
    </span>
  );
};
MessageRecipient.propTypes = MessageRecipientPropTypes;

export const getListItemLabel = (kindOfMessage, recipient) => {
  const liLabelForOutbox = recipient.company_name ? recipient.company_name : recipient.user_display_name;
  const liLabelForInbox = recipient.user_display_name ? recipient.user_display_name : recipient.email;
  if (kindOfMessage === "outbox") return liLabelForOutbox;
  else if (kindOfMessage === "inbox") return liLabelForInbox;
  else return "";
};

export default OutboxList;
