export default [
  {
    className: 'en',
    value: ' English',
    front: true,
  },
  {
    className: 'de',
    value: ' Deutsch',
    front: false,
  },
  {
    className: 'ru',
    value: ' Pусский',
    front: false,
  },
  {
    className: 'fr',
    value: ' Francais',
    front: false,
  },
  {
    className: 'el',
    value: ' ελληνικά',
    front: false,
  },
  {
    className: 'sl',
    value: ' Slovenščina',
    front: false,
  },
  {
    className: 'es',
    value: ' Español',
    front: false,
  },
  {
    className: 'pt',
    value: ' Português',
    front: true,
  },
  {
    className: 'zh-CN',
    value: ' 简体中文',
    front: true,
  },
  {
    className: 'zh-TW',
    value: ' 中國傳統的',
    front: true,
  },
  {
    className: 'pl',
    value: ' Polski',
    front: true,
  },
  {
    className: 'it',
    value: ' Italiano',
    front: true,
  },
  {
    className: 'nl',
    value: ' Nederlands',
    front: true,
  },
  {
    className: 'ar',
    value: ' ٱلْعَرَبِيَّة‎',
    front: true,
  },
  {
    className: 'id',
    value: ' Bahasa Indonesia',
    front: true,
  },
  {
    className: 'ja',
    value: ' 日本人',
    front: true,
  },
  {
    className: 'ro',
    value: ' Limba Română',
    front: true,
  },
  {
    className: 'ko',
    value: ' 한국어',
    front: true,
  },
  {
    className: 'no',
    value: ' Nordsk',
    front: true,
  },
  {
    className: 'sv',
    value: ' Svenska',
    front: true,
  },
  {
    className: 'th',
    value: ' ไทย',
    front: true,
  },
  {
    className: 'vi',
    value: ' Tiếng Việt',
    front: true,
  },
  {
    className: 'tr',
    value: ' Türk',
    front: true,
  },
];
