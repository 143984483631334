const validate = (values) => {
  const errors = { QQGeneralDetails: {}, qqPrice: {}, qqQuestions: {} };

  const { QQGeneralDetails = {}, qqPrice = {}, qqQuestions = {} } = values;
  const quoteName = QQGeneralDetails.quote_name;

  if (!quoteName) {
    errors.QQGeneralDetails.quote_name = 'REQUIRED';
  } else if (quoteName && quoteName.trim().length === 0) {
    errors.QQGeneralDetails.quote_name = 'NO_WHITE_SPACE';
  } else if (quoteName && quoteName.trim().length > 50) {
    errors.QQGeneralDetails.quote_name = 'NAME_CHAR_LONG';
  }
  const { lots } = qqPrice;
  if (!lots || !lots.length) {
    errors.qqPrice.lots = { _error: 'ATLEAST_ONE_LOT' };
  } else {
    const lotsArrayErrors = [];
    lots.forEach((el, elIndex) => {
      const lotErrors = {};
      if (!el || !el.name) {
        lotErrors.name = 'REQUIRED';
        lotsArrayErrors[elIndex] = lotErrors;
      } else if (el.name && el.name.trim().length === 0) {
        lotErrors.name = 'NO_WHITE_SPACE';
        lotsArrayErrors[elIndex] = lotErrors;
      } else if (el.name && el.name.trim().length > 150) {
        lotErrors.name = 'NAME_CHAR_LONG';
        lotsArrayErrors[elIndex] = lotErrors;
      }
      if (el || el.quantity) {
        if (+parseFloat(el.quantity) <= 0) {
          lotErrors.quantity = 'QUANTITY_LIMIT_LOW';
          lotsArrayErrors[elIndex] = lotErrors;
        }
        if (+parseFloat(el.quantity) > 999999999999.99) {
          lotErrors.quantity = 'QUANTITY_LIMIT_HIGH';
          lotsArrayErrors[elIndex] = lotErrors;
        }
        const regexToQuantity = /^[0-9.,]+$/;
        if (!regexToQuantity.test(el.quantity) && el.quantity && el.quantity.trim() !== '') {
          lotErrors.quantity = 'INVALID_NUMBER';
          lotsArrayErrors[elIndex] = lotErrors;
        }
      }
    });
    if (lotsArrayErrors.length) {
      errors.qqPrice.lots = lotsArrayErrors;
    }
  }

  const { questionnaire } = qqQuestions;
  if (!questionnaire || !questionnaire.length) {
    errors.qqQuestions.questionnaire = { _error: 'ATLEAST_ONE_QUESTIONNAIRE' };
  } else {
    const questionnaireArrayErrors = [];
    questionnaire.forEach((el, elIndex) => {
      const questionnaireErrors = {};
      if (!el || !el.title) {
        questionnaireErrors.title = 'REQUIRED';
        questionnaireArrayErrors[elIndex] = questionnaireErrors;
      } else if (el.title && el.title.trim().length === 0) {
        questionnaireErrors.title = 'NO_WHITE_SPACE';
        questionnaireArrayErrors[elIndex] = questionnaireErrors;
      } else if (el.title && el.title.trim().length > 255) {
        questionnaireErrors.title = 'NAME_CHAR_LONG';
        questionnaireArrayErrors[elIndex] = questionnaireErrors;
      }
      if (!el || !el.question_type) {
        questionnaireErrors.question_type = 'REQUIRED';
        questionnaireArrayErrors[elIndex] = questionnaireErrors;
      }
    });
    if (questionnaireArrayErrors.length) {
      errors.qqQuestions.questionnaire = questionnaireArrayErrors;
    }
  }
  return errors;
};

export default validate;
