import React, { Component } from "react";
import { connect } from "react-redux";
import { createParticipantPermission } from "../../../../actions/adminActions";
import Select2 from "react-select2-wrapper";
import Tooltip from "../../../common/Tooltip";
import unionWith from "lodash/unionWith";
import isEqual from "lodash/isEqual";
import remove from "lodash/remove";
export class EventParticipantPermissionTab extends Component {
  constructor(props) {
    super(props);
    this.saveParticipantPermission = this.saveParticipantPermission.bind(this);
    let previous_permissions = props.userPermission.participant_permissions || [];
    let default_permission = [{ action: "view", resource_id: 0 }];

    previous_permissions = unionWith(default_permission, previous_permissions, isEqual);
    this.state = {
      permissionsState: previous_permissions,
      addNewRow: false,
    };
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { data } = newProps;
    const { permissionsState } = prevState;
    if (data && data.deletedParticipantPermissionId) {
      const { permissionsState } = prevState;
      remove(permissionsState, function (permission) {
        return permission.id === data.deletedParticipantPermissionId;
      });
    } else if (data && data.participantPermissions && !data.participantPermissionId) {
      let permissions = unionWith(permissionsState, [data.participantPermissions], isEqual);
      if (prevState.addNewRow) permissions[0] = { action: "view", resource_id: 0 };
      return {
        permissionsState: permissions,
        addNewRow: false,
      };
    }
    return null;
  }

  saveParticipantPermission = (key, participantPermissionId) => {
    const { permissionsState } = this.state;
    const { createParticipantPermission, addParticipantpermissions } = this.props;
    let permissionParams = permissionsState[key];
    createParticipantPermission(
      permissionParams.action,
      permissionParams.resource_id,
      this.props.userPermission.id,
      participantPermissionId
    );
    addParticipantpermissions({ action: "view", resource_id: 0 });
    if (key === "0")
      this.setState({
        addNewRow: true,
      });
  };

  makeStateForParticipantPermission = (e, key, type, epHash) => {
    let loader_ref = this[`loader_${type}_${key}`];
    let tick_ref = this[`tick_${type}_${key}`];
    let { permissionsState } = this.state;
    const { createParticipantPermission, userPermission, addParticipantpermissions } = this.props;
    permissionsState[key][type] = e.target.value;
    let permissionParams = permissionsState[key];
    if (key != "0") {
      loader_ref.classList.remove("hidden");
      setTimeout(() => {
        loader_ref.classList.add("hidden");
        tick_ref.classList.remove("loader-hide");
      }, 200);
      createParticipantPermission(permissionParams.action, permissionParams.resource_id, userPermission.id, epHash.id);
      setTimeout(() => {
        tick_ref.classList.add("loader-hide");
      }, 300);
    }
    addParticipantpermissions(permissionsState[0]);
    this.setState({ permissionsState });
  };

  render() {
    const {
      simDojo,
      translations,
      permissionAction,
      permissionOwner,
      groupsForSelect,
      userPermission,
      companyId,
      alertForParticipantPermission,
      currentUser,
    } = this.props;
    const { permissionsState } = this.state;

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="modal-content-section">
            <div className="section-title">
              <h4>{translations.eventParticipantPermissions}</h4>
            </div>
            <div className={`form-group ${permissionsState.length > 4 ? "vertical-scrollbar" : ""}`}>
              {Object.entries(permissionsState).map(([key, epHash]) => {
                let permittedToEdit = this.permittedToEditPermission(epHash, key);
                return permittedToEdit ? (
                  <React.Fragment key={key}>
                    <div className="row">
                      <div className="col-md-4 col-sm-4" id={`action-select-container-${key}`}>
                        <Tooltip
                          title={permissionAction.title}
                          body={permissionAction.body}
                          currentUser={currentUser}
                        />
                        <label>{translations.userCan}</label>
                        <div className="permission-select-container display-settings-select-container">
                          <Select2
                            data={[
                              {
                                text: translations.view,
                                id: "view",
                              },
                              {
                                text: translations.edit,
                                id: "edit",
                              },
                            ]}
                            value={epHash.action}
                            options={{
                              minimumResultsForSearch: -1,
                            }}
                            name={`action-${key}`}
                            onSelect={(e) => {
                              this.makeStateForParticipantPermission(e, key, "action", epHash);
                            }}
                            className="action-select"
                            disabled={!permittedToEdit}
                          />
                        </div>
                        <span className="hidden up-loader" ref={(ref) => (this[`loader_action_${key}`] = ref)}>
                          <i className="fa fa-spinner fa-white fa-spin" />
                        </span>
                        <span className="loader-hide up-loader" ref={(ref) => (this[`tick_action_${key}`] = ref)}>
                          <i alt="Yes" className="fa fa-check text-success" />
                        </span>
                      </div>
                      <div className="col-md-7 col-sm-7" id={`resource-select-container-${key}`}>
                        <Tooltip title={permissionOwner.title} body={permissionOwner.body} currentUser={currentUser} />
                        <label>{translations.eventParticipantOwnedMemberText}</label>
                        <div className="permission-select-container display-settings-select-container">
                          <Select2
                            data={groupsForSelect}
                            value={epHash.resource_id}
                            name={`resourceId-${key}`}
                            onSelect={(e) => {
                              this.makeStateForParticipantPermission(e, key, "resource_id", epHash);
                            }}
                            className="group-resource-select"
                            disabled={!permittedToEdit}
                          />
                        </div>
                        <span className="hidden up-loader" ref={(ref) => (this[`loader_resource_id_${key}`] = ref)}>
                          <i className="fa fa-spinner fa-white fa-spin" />
                        </span>
                        <span className="loader-hide up-loader" ref={(ref) => (this[`tick_resource_id_${key}`] = ref)}>
                          <i alt="Yes" className="fa fa-check text-success" />
                        </span>
                      </div>
                      <div className="col-md-1 col-sm-1 text-left button_middle" id="action-buttons">
                        {key === "0" ? (
                          <button
                            className="btn btn-default btn-icon m-l10"
                            id="create-button"
                            type="button"
                            title={translations.add}
                            onClick={() => this.saveParticipantPermission(key)}
                            disabled={epHash.resource_id === 0}
                          >
                            <i className="fa fa-plus green-color" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            className="btn btn-default btn-icon m-l10"
                            id="delete-button"
                            type="button"
                            title={translations.delete}
                            data-toggle="modal"
                            data-target="#participantPermissionConfirmModal"
                            onClick={() => alertForParticipantPermission(epHash.id)}
                          >
                            <i className="fa fa-trash-o red-color" aria-hidden="true" />
                          </button>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  ""
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  permittedToEditPermission(epHash, key) {
    if (key === "0" || epHash.resource_id === 0) {
      return true;
    }
    const { currentUserCanEditPermissions } = this.props;
    if (currentUserCanEditPermissions) {
      return true;
    }
    return false;
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.adminReducers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createParticipantPermission: (action, resourceId, userPermissionId, participantPermissionId) =>
    dispatch(createParticipantPermission(action, resourceId, userPermissionId, participantPermissionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventParticipantPermissionTab);
