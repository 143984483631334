import * as apiCall from "./api";
import { getError } from "./errorActions";

export const getSuccess = (successMessage) => {
  if (successMessage) {
    return { type: actionTypes.SUCCESS, success: successMessage };
  }

  return {
    type: actionsTypes.NO_ACTION,
  };
};

export const makeApiCall = (action, url, success, dispatch, payload = null) => {
  return apiCall[action](url, payload)
    .then((response) => {
      dispatch(success(response.data));
      greenTick();
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const greenTick = () => {
  let rfqSubmit = jQuery(".btn-sm.m-r5").length === 0;
  let blicBid = jQuery(".btn-sm.pull-right").length === 0;
  if (rfqSubmit && blicBid) {
    jQuery(".bid_tab .fa").removeClass("fa-times red-color")
    jQuery(".bid_tab .fa").addClass("fa-check green-color")
  }
}
