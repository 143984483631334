import React from "react";
import Tooltip from "../../../common/Tooltip";
import PropTypes from "prop-types";
import { useAppSelector } from "@/hooks/redux";
import { selectCompanyTier } from "@/slices/company";

const getInputData = ({ translations, configuration_setting_values, allowHostsToInviteUser }) => {
  const { allow_hosts_to_invite_other_approved_hosts, allow_hosts_to_invite_new_users } = configuration_setting_values;
  return [
    {
      label: translations.stakeholder_approved_user,
      toolTipData: translations.configuration.stakeholder_approved_user,
      name: "allow_hosts_to_invite_other_approved_hosts",
      value: allow_hosts_to_invite_other_approved_hosts ?? false,
      id: "allow_hosts_to_invite_other_approved_hosts",
      checked: allow_hosts_to_invite_other_approved_hosts,
      onChange: (e) => allowHostsToInviteUser(e, 0),
    },
    {
      label: translations.stakeholder_invite_user,
      toolTipData: translations.configuration.stakeholder_invite_user,
      name: "allow_hosts_to_invite_new_users",
      value: allow_hosts_to_invite_new_users ?? false,
      id: "allow_hosts_to_invite_new_users",
      checked: configuration_setting_values.allow_hosts_to_invite_new_users,
      disabled: !configuration_setting_values.allow_hosts_to_invite_other_approved_hosts,
      onChange: (e) => allowHostsToInviteUser(e, 1),
    },
  ];
};

export default function StakeholderInvitation({ translations, configuration_setting_values, onValueChange }) {
  const companyTier = useAppSelector((state) => selectCompanyTier(state, "sourcing"));
  if (companyTier === "bronze") {
    return null;
  }

  const allowHostsToInviteUser = (e, setting) => {
    let value = !!e.target.checked;
    let setting_type = setting == 0 ? "allow_hosts_to_invite_other_approved_hosts" : "allow_hosts_to_invite_new_users";
    onValueChange(value, setting_type);
  };
  const inputData = getInputData({ translations, configuration_setting_values, allowHostsToInviteUser });

  return <SettingSectionWrapper inputData={inputData} title={translations.stakeholder_invitation} />;
}

const SettingWrapperPropTypes = {
  inputData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<SettingWrapperPropTypes>>}
 */
export const SettingSectionWrapper = ({ inputData, title }) => {
  return (
    <div className="form-item-block">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="form-item-block-title">{title}</div>
          {inputData.map((data, index) => (
            <InputWithTooltip key={index} {...data} />
          ))}
        </div>
      </div>
    </div>
  );
};

SettingSectionWrapper.propTypes = SettingWrapperPropTypes;

export const InputWithTooltip = ({ label = "", toolTipData = {}, ...rest }) => {
  return (
    <div className="switch-toggle input inline m-b10">
      <div className="row">
        <div className="col-md-5 col-sm-6 col-lg-4 col-xs-12 p-0 col-md-offset-5">
          <div className="row">
            <div className="switch-main-div">
              <label className="switch switch-custom-btn config-label p-0 m-0">
                <input type="checkbox" {...rest} />
                <span className="slider round"></span>&nbsp;
              </label>
              <div className="info-custom-text">
                <span className="label-custom-text">
                  {label}
                  <Tooltip {...toolTipData} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default StakeholderInvitation;
