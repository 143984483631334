import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Confirm } from "@/components/common/Confirm";
import { closeBidModal } from "@/actions/lotActions";
import { currencyPosition, isLotTotalPresent, isPBPermittedInLot } from "../../common";
import { Lot } from "@/common-prop-types";

export const confirmationModalId = "participantsPlaceBidConfirmModal-";

const ConfirmationMessagePropTypes = {
  /**
   * areAllCellsFilledForPB will be true if all the cells of filled for all touched lineItems
   *
   * @type {boolean}
   */
  areAllCellsFilledForPB: PropTypes.bool,

  /**
   * price value of bid placed for lot
   *
   * @type {number}
   */
  totalBidPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * LineItemComponent currency to format and show it on the modal
   *
   * @type {{symbol: string}}
   */
  licCurrency: PropTypes.shape({
    symbol: PropTypes.string,
  }),

  /**
   * ID of lot which for which confirmation modal is being render
   *
   * @type {object}
   */
  lot: PropTypes.shape(Lot),
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<ConfirmationMessagePropTypes>>}
 */
const ConfirmationMessage = ({ areAllCellsFilledForPB, totalBidPrice, licCurrency, lot }) => {
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);
  const { no_lot_total_bid_confirmation, number_format } = translations;

  const isLotTotal = isLotTotalPresent(lot);
  const isPBPermitted = isPBPermittedInLot(lot);

  if (!(isLotTotal && ((isPBPermitted && areAllCellsFilledForPB) || !isPBPermitted)))
    return <p>{no_lot_total_bid_confirmation}</p>;

  return (
    <>
      <p className="m-b10">{translations.value_entered}</p>
      <p className="m-b0">
        <span className="font-s26 yes">{currencyPosition(totalBidPrice, licCurrency, number_format)}</span>
      </p>
    </>
  );
};

ConfirmationMessage.propTypes = ConfirmationMessagePropTypes;
ConfirmationMessage.defaultProps = {};

const PortalizeModalPropTypes = {
  children: PropTypes.object.isRequired,
  type: PropTypes.string,
  modalId: PropTypes.string.isRequired,
};

/**
 *
 * @param {string} modalId
 */
const handleModalDisplay = (modalId) => {
  const interval = setInterval(function () {
    const node = document.getElementById(modalId);
    if (node) {
      clearInterval(interval);
      if (!node.classList.contains("in")) {
        node.style.display = "block";
        node.className += " in";
      }
    }
  }, 300);
};

/**
 * Component to render through a portal
 *
 * @type {React.FC<PropTypes.InferProps<PortalizeModalPropTypes>>}
 * @returns {React.ReactPortal}
 */
const PortalizeModal = ({ modalId, type, children }) => {
  const lotReducers = useSelector((state) => state.lotReducers);
  const isModalIdPresent = lotReducers.show_bid_modal && lotReducers.show_bid_modal.substr(1) === modalId;

  if (isModalIdPresent) {
    const portal = ReactDOM.createPortal(<div data-type={type}>{children}</div>, document.body);
    handleModalDisplay(modalId);
    return portal;
  }
  return null;
};

PortalizeModal.propTypes = PortalizeModalPropTypes;
PortalizeModal.defaultProps = {
  type: "Default",
};

const ConfirmBidPropTypes = {
  ...ConfirmationMessagePropTypes,

  /**
   * Success handler for placing bid
   *
   * @type {() => void}
   */

  onOkayButtonClick: PropTypes.func,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<ConfirmBidPropTypes>>}
 */
const ConfirmBid = ({ lot, onOkayButtonClick, ...rest }) => {
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);
  const dispatch = useDispatch();

  const lotId = lot.id;
  const modalId = confirmationModalId + lotId;

  return (
    <PortalizeModal type="modal" modalId={modalId}>
      <Confirm
        key={lotId}
        {...{ translations, onOkayButtonClick }}
        message={<ConfirmationMessage lot={lot} {...rest} />}
        htmlId={modalId}
        modalClass={"modal-md"}
        okButtonText={[translations.submit]}
        removeHook={() => dispatch(closeBidModal())}
      />
    </PortalizeModal>
  );
};

ConfirmBid.propTypes = ConfirmBidPropTypes;
ConfirmBid.defaultProps = {
  onOkayButtonClick: () => {},
};

export default ConfirmBid;
