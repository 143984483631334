import React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

import pick from "lodash/pick";

const CurrencyFieldWithSymbolPropTypes = {
  /**
   * The id attribute added to the input
   */
  id: PropTypes.string,
  /**
   * The name attribute added to the input
   */
  name: PropTypes.string,
  /**
   * The value that will be disabled in the input
   */
  value: PropTypes.any,
  /**
   * The value of the currency symbol that will be display to the user for
   * example "£"
   */
  symbol: PropTypes.string,
  /**
   * The object defining all of the display info for the component
   */
  translations: PropTypes.shape({
    number_format: PropTypes.shape({
      /**
       * There to put the currency symbol. If this is invalid no currency
       * symbol will be displayed even if you pass one in.
       */
      currency_position: PropTypes.oneOf(["prefix", "suffix"]),
      separator: PropTypes.string,
      delimiter: PropTypes.string,
    }),
  }),
  /**
   * Extra css classNames that will be passed on to the input element
   */
  classes: PropTypes.string,
  /**
   * The callback function that will be called when the input value changes.
   * NOTE: This will not be called if the input is disabled.
   * @type {PropTypes.Validator<(value: number | string) => void>}
   */
  handleFieldChange: PropTypes.func.isRequired,
  /**
   * If the input is disabled and the user can input a value into it.
   */
  disabled: PropTypes.bool,
  /**
   * The number of decimal places the value should display
   */
  precision: PropTypes.number,
  /**
   * If true it will add extra 0s to the end to match the `precision` for
   * example if the value is 10, precision is 2 and fixedDecimalScale is true
   * the value displayed to the user will be 10.00.
   *
   * @default false
   */
  fixedDecimalScale: PropTypes.bool,
  /**
   * The className of the icon. If this is not passed in no icon will be used.
   *
   * @example "fa fa-check"
   */
  btnIcon: PropTypes.string,
  /**
   * A callback for the onKeyDown event passed to the input.
   */
  onKeyDown: PropTypes.func,
  /**
   * A callback for the onFocus event passed to the input.
   */
  onFocus: PropTypes.func,
  /**
   * A callback for the onBlur event passed to the input.
   */
  onBlur: PropTypes.func,
  /**
   * If the input should display the `rankValue` to the user
   *
   * @default false
   */
  showRank: PropTypes.bool,
  /**
   * The value of the rank only used when `showRank` is true
   */
  rankValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
};

const onValueChange = (disabled, handleFieldChange) => (values) => {
  if (!disabled) {
    handleFieldChange(() => values.value);
  }
};

const ButtonIcon = ({ btnIcon }) => {
  if (!btnIcon) {
    return null;
  }

  return (
    <div className="btn font-s15 input-group-btn" style={{ cursor: "auto", lineHeight: 1 }}>
      <i className={btnIcon}></i>
    </div>
  );
};

ButtonIcon.propTypes = { btnIcon: CurrencyFieldWithSymbolPropTypes.btnIcon };

const Rank = ({ showRank, rankValue }) => {
  if (!showRank) {
    return null;
  }

  return (
    <span className="input-group-btn bid-rank">
      <span className={`blic-rank-symbol badge ranked${rankValue} active-bid-val`}>{rankValue}</span>
    </span>
  );
};

Rank.propTypes = {
  showRank: CurrencyFieldWithSymbolPropTypes.showRank,
  rankValue: CurrencyFieldWithSymbolPropTypes.rankValue,
};

/**
 * Scenarios handled:
 *   - Currency should be displayed on the left always on this component irrespective of the locale
 *   Disabled Field:
 *     - It should be blank if the left text box is blank
 *     - Should show value with scale of two values (two decimal values by default .00)
 *     - The value should be formatted with thousand separator
 *     - When editing if the enabled text box's (left text box) value has been removed then this should also become blank
 *     - When quantity is changed then the value of this field should also change according to quantity
 *   Enabled Field:
 *     - The value should be displayed with thousand separator by default when trying to edit the component
 *     - Should not allow any other value than the numeric values and decimal separtor (. or ,)
 *     - Can enter any number of decimal values (no limit on scale of number like on the disabled field it is 2)
 *     - No fixed scale displayed here like on the disabled field
 */
const CurrencyFieldWithSymbol = (props) => {
  const {
    number_format: { separator, delimiter, currency_position },
  } = props.translations;

  return (
    <>
      {currency_position === "prefix" && <span className="input-group-addon">{props.symbol}</span>}
      <NumericFormat
        {...pick(props, ["onKeyDown", "onBlur", "onFocus", "name", "disabled", "id"])}
        thousandSeparator={delimiter}
        decimalSeparator={separator}
        value={props.value === null ? "" : props.value}
        className={props.classes}
        onValueChange={onValueChange(props.disabled, props.handleFieldChange)}
        valueIsNumericString={true}
        decimalScale={props.disabled ? 2 : props.precision}
        fixedDecimalScale={props.fixedDecimalScale}
      />
      {currency_position === "suffix" && <span className="input-group-addon">{props.symbol}</span>}
      <ButtonIcon btnIcon={props.btnIcon} />
      <Rank rankValue={props.rankValue} showRank={props.showRank} />
    </>
  );
};

const noop = () => {};
CurrencyFieldWithSymbol.propTypes = CurrencyFieldWithSymbolPropTypes;
CurrencyFieldWithSymbol.defaultProps = {
  onBlur: noop,
  onFocus: noop,
  showRank: false,
  fixedDecimalScale: false,
  onKeyDown: (event) => {
    if (event.key === "Enter" && event.target) {
      event.target.blur();
    }
  },
};

export default CurrencyFieldWithSymbol;
