// eslint-disable-next-line no-unused-vars
import React, { useCallback } from "react";
import { uniq } from "lodash";
import { useSelector } from "react-redux";

/**
 *
 * @param {[]} hosts
 * @param {boolean} isHostTabSelected
 * @returns {(selectedParticipantIds: [number]) => boolean}
 */
const useParticipant = (hosts, isHostTabSelected) => {
  const eventParticipants = useSelector(({ lotReducers }) => lotReducers.event_participants);
  /**
   *
   * if user selects a participant on one tab and moved to other tab
   * and select another participant then isParticipantSelectedonOtherTab will be true
   * else it will return false.
   *
   * @param {boolean} selectedParticipantIds
   */
  return useCallback(
    (selectedParticipantIds) => {
      if (selectedParticipantIds.length && eventParticipants?.length) {
        const hostIds = hosts.map(({ id }) => id);
        const participantIds = eventParticipants.map(({ user_id }) => user_id);

        // Check if selected participants are from host tab
        const isHostIdPresent = Boolean(hostIds.find((id) => selectedParticipantIds.includes(id)));

        // Check if selected participants are from participants tab
        const isParticipantIdPresent = Boolean(participantIds.find((id) => selectedParticipantIds.includes(id)));

        // if host tab is selected and selectedParticipants are from participants tab or vice-versa then return true
        if ((isHostTabSelected && isParticipantIdPresent) || (!isHostTabSelected && isHostIdPresent)) return true;
      }
      return false;
    },
    [isHostTabSelected, hosts, eventParticipants]
  );
};

/**
 *
 * @param {()=> void} setState
 * @param {(selectedParticipantIds: [number]) => boolean} isParticipantSelectedonOtherTab
 * @returns {(e: React.MouseEvent<HTMLElement>) => void}
 */
export const createParticipantChangeHandler = (setState, isParticipantSelectedonOtherTab) => {
  /**
   * handleParticipantChange: Handle participant selection
   * @param {React.MouseEvent<HTMLElement>} e
   */
  return (e) => {
    const { value, checked } = e.target;

    /**
     * remove all previous selectedParticipantIds and quickSelect,
     * If checked and isParticipantSelectedonOtherTab both are true.
     *
     */
    setState(({ selectedParticipantIds }) => {
      const isPreviousDatafromAnotherTab = isParticipantSelectedonOtherTab([
        ...selectedParticipantIds,
        ...(checked ? [parseInt(value)] : []),
      ]);
      return {
        selectedParticipantIds: checked
          ? uniq([...(!isPreviousDatafromAnotherTab ? selectedParticipantIds : []), parseInt(value)])
          : selectedParticipantIds.filter((id) => id !== parseInt(value)),
        ...(isPreviousDatafromAnotherTab && { quickSelect: [] }),
      };
    });
  };
};

export default useParticipant;
