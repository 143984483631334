import uniq from "lodash/uniq";
import reject from "lodash/reject";
import { associatedQuestions } from "./QuestionnaireAnswerCommon";

export const MAX_QUESTIONNAIRES_FOR_NAVIGATION_TAB = 2;

export const weightedSections = (questionnaire) => {
  return (
    questionnaire.with_scoring && ["per_section", "per_section_and_question"].includes(questionnaire.with_weighting)
  );
};
export const weightedQuestions = (questionnaire) => {
  return (
    questionnaire.with_scoring && ["per_question", "per_section_and_question"].includes(questionnaire.with_weighting)
  );
};

export const weighted = (questionnaire) => weightedSections(questionnaire) || weightedQuestions(questionnaire);

export const sectionIdsWithNoQuestionsCreatedBeforeQaArchived = (
  questionnaire,
  qa,
  questions,
  questionnaireSections
) => {
  const sectionIds = uniq(questions.map((q) => q.questionnaire_section_id));
  let allSections = questionnaireSections.filter((qs) => qs.questionnaire_id === questionnaire.id);
  if (!qa.archived_at) {
    allSections = allSections.filter((qs) => isNaN((qs) => qs.deleted_at));
  }
  let noqSections = allSections.filter((section) => !sectionIds.includes(section.id));
  if (qa.archived_at) {
    noqSections = reject(noqSections, function (section) {
      return section.deleted_at && Date.parse(section.deleted_at) < Date.parse(qa.archived_at);
    });
  }
  noqSections.map((section) => section.id);
};

export const totalScoringOfQuestions = (
  questionnaire,
  questions,
  questionnaireAnswersQuestions,
  questionnaireAnswer = null,
  section = null
) => {
  let validQuestions = [];
  if (questionnaireAnswer) {
    valid_questions = associatedQuestions(questionnaireAnswer, questions, questionnaireAnswersQuestions);
  } else {
    validQuestions = filterQuestionsForQuestionnaire(questions, questionnaire);
  }
  validQuestions.forEach((q) => {
    if (!section || q.questionnaire_section_id == section.id) {
      total += question.scoring;
    }
  });
  return total;
};

export const filterQuestionsForQuestionnaire = (questions, questionnaire) => {
  return questions.map((question) => question.questionnaire_id === questionnaire.id);
};

export const preQualification = (questionnaires) => questionnaires?.filter((q) => q.pre_qualification);
