import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Multiselect } from "multiselect-react-dropdown";
import classNames from "classnames";
import { isIE } from "../advanced_lots/common";
import { useDispatch, useSelector } from "react-redux";
import {
  createLinkedQuestion,
  createOrUpdateLinkedQuestion,
  fetchSimQuestions,
  updateLinkedQuestion,
} from "../../actions/srmActions";
import Select2 from "react-select2-wrapper";

Modal.setAppElement("div");

const MultiSelectStyles = {
  groupHeading: {
    fontWeight: 600,
    color: "#424242",
  },
};

const LinkQuestionsForm = (props) => {
  const {
    modalIsOpen,
    setModalIsOpen,
    translations,
    company,
    selectedLinkedQuestions,
    setSelectedLinkedQuestions,
    setSelectedSrmLinkedQuestions,
    setLinkedSections,
    setLinkedQuestionnaires,
  } = props;
  const srmReducers = useSelector((state) => state.srmReducers);
  const { questionnaires, sections = [], questions = [] } = srmReducers;
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState(null);
  const dispatch = useDispatch();
  const fechedSelectedQuestions = () => {
    const questionIds = selectedLinkedQuestions.map((q) => q.questionnaire_question_id);

    return selectedLinkedQuestions.length
      ? questions.map((q) => {
          if (questionIds.includes(q.id)) return q.title;
        })
      : [];
  };
  const selectedQuestionIds = fechedSelectedQuestions();
  const handleSaveButtonClick = (e) => {
    e.preventDefault();
    const payload = {
      company_id: company.id,
      values: selectedQuestions.map((q) => q.id),
      questionnaire_id: selectedQuestionnaireId,
    };
    dispatch(createOrUpdateLinkedQuestion(payload)).then((response) => {
      setLinkedQuestionnaires(response.questionnaires);
      setSelectedSrmLinkedQuestions(response.srm_linked_questions);
      setLinkedSections(response.sections);
      setSelectedLinkedQuestions(response.linked_questions);
      setModalIsOpen(false);
    });
  };

  const fetchRelatedQuestion = (value) => {
    setSelectedQuestionnaireId(value[0].id);
    dispatch(fetchSimQuestions(value[0].id, company.id));
  };
  const objectsForSelect = (objects) => {
    return objects.map((object) => {
      return { id: object["id"], text: object["title"] };
    });
  };

  const questionObjectsForSelect = (objects) => {
    return objects.map((object) => {
      const section_title = sections.find((sec) => sec.id === object.questionnaire_section_id).title;
      return {
        id: object["id"],
        text: object["title"],
        section_title: section_title,
      };
    });
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      className={classNames({
        "modal-content col-md-6 col-sm-6 custom-field-popup": true,
        "modal2-ie": isIE(),
      })}
    >
      <form onSubmit={(e) => handleSaveButtonClick(e)}>
        <div className="block block-transparent remove-margin-b">
          <div className="block-header bg-primary-default">
            <ul className="block-options">
              <li>
                <button type="button" onClick={() => setModalIsOpen(false)}>
                  <i className="ion-close" />
                </button>
              </li>
            </ul>
            <h3 className="block-title">{translations.link_sim_questions}</h3>
          </div>

          <div className="block-content">
            <div className="form">
              <div className="form line-item-form">
                <div className="form-group m-b0">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col input form-group">
                        {questionnaires.length > 0 && (
                          <Select2
                            value={selectedQuestionnaireId}
                            data={objectsForSelect(questionnaires)}
                            onUnselect={(e) => setSelectedQuestionnaireId(0)}
                            onSelect={(e) => fetchRelatedQuestion([e.params.data])}
                            className="form-control"
                            options={{
                              placeholder: translations.select_questionnaire,
                            }}
                          />
                        )}
                        {questionnaires.length === 0 && translations.no_questionnaires_found}
                      </div>
                    </div>
                  </div>

                  {selectedQuestionnaireId && (
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="col input form-group">
                          <Multiselect
                            className="form-control"
                            options={questionObjectsForSelect(questions)}
                            name="question"
                            displayValue="text"
                            groupBy="section_title"
                            onSelect={(values) => setSelectedQuestions(values)}
                            placeholder={translations.select_questions}
                            style={MultiSelectStyles}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="modal-footer p-r0">
                    {questionnaires.length > 0 && (
                      <>
                        <button type="button" className="btn btn-sm btn-default" onClick={() => setModalIsOpen(false)}>
                          {translations.cancel}
                        </button>
                        <button name="button" type="submit" className="btn btn-primary button sm-butt white ok hover">
                          {translations.save} <i className="fa fa-check fa-white" />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default LinkQuestionsForm;
