import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import Select2 from "react-select2-wrapper";
import classNames from "classnames";
import { disableButton, isIE } from "../common";
import Tooltip from "../../common/Tooltip";
import { NumericFormat } from "react-number-format";
import { changeEventTimeInterval } from "../../../actions/eventActions";

const SettingForm = (props) => {
  const {
    modalIsOpen,
    setModalIsOpen,
    translations,
    event,
    giveDisabledDurationValues,
    lastBidTimeInterval,
    lastBidChange,
    locale,
  } = props;
  const refBtn = useRef(null);

  const dispatch = useDispatch();
  const showCorrectValue = (value) => {
    const formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  };

  const [maximumBidChange, setMaximumBidChange] = useState(showCorrectValue(event.maximum_bid_change || 50.0));
  const [minimumBidChange, setMinimumBidChange] = useState(showCorrectValue(event.minimum_bid_change || 0.51));
  const [bidChange, setBidChange] = useState(showCorrectValue(lastBidChange || 5));
  const [timeInterval, setTimeInterval] = useState(lastBidTimeInterval);
  const [extendDuration, setExtendDuration] = useState(null);
  const [dynamicClosePeriod, setDynamicClosePeriod] = useState(event.dynamic_close_period || 0);
  const [dynamicCloseRank, setDynamicCloseRank] = useState(event.dynamic_close_rank || 0);

  const handleSaveButtonClick = (e) => {
    e.preventDefault();
    let payload = {
      event: {},
    };
    disableButton(refBtn.current, translations.saving);
    if (event.event_type === "Japanese") {
      payload["event"]["time_interval"] = timeInterval;
      payload["event"]["bid_change"] = bidChange;
    } else {
      payload["event"]["extend_duration"] = extendDuration;
      payload["event"]["dynamic_close_period"] = dynamicClosePeriod;
      payload["event"]["dynamic_close_rank"] = dynamicCloseRank;
      payload["event"]["minimum_bid_change"] = minimumBidChange;
      payload["event"]["maximum_bid_change"] = maximumBidChange;
    }
    dispatch(changeEventTimeInterval(event.id, payload));
  };

  const { lot_time_interval, lot_bid_change, lot_minimum_duration, lot_dynamic_close_period, lot_applies_to } =
    translations.infos;

  const showData = (id, text, type, disabled) => {
    return {
      id: id,
      text: translations[`${type}_count_${text}`],
      disabled: disabled ? giveDisabledDurationValues.includes(id) : false,
    };
  };

  const renderData = (type) => {
    let data = [];
    switch (type) {
      case "extendDuration":
        return [
          [60, 1, "minute", true],
          [120, 2, "minutes", true],
          [300, 5, "minutes", true],
          [600, 10, "minutes", true],
          [900, 15, "minutes", true],
          [1800, 30, "minutes", true],
          [3600, 1, "hour", true],
          [7200, 2, "hours", true],
          [14400, 4, "hours", true],
          [28800, 8, "hours", true],
          [43200, 12, "hours", true],
          [86400, 1, "day", true],
          [172800, 2, "days", true],
          [259200, 3, "days", true],
          [604800, 1, "week", true],
        ].map((duration) => showData(duration[0], duration[1], duration[2], duration[3]));
      case "dynamicClosePeriod":
        data = [
          [120, 2],
          [300, 5],
          [600, 10],
          [900, 15],
        ].map((duration) => showData(duration[0], duration[1], "minutes", false));
        return [
          {
            id: 0,
            text: translations.none,
          },
          {
            id: 60,
            text: translations.last_minute,
          },
          ...data,
        ];
      case "topBidder":
        data = [1, 2, 3, 4, 5].map((duration) => showData(duration, duration, "top_bidder", false));
        return [
          {
            id: 0,
            text: translations.all,
          },
          ...data,
        ];
      case "timeInterval":
        data = [
          [120, 2],
          [180, 3],
          [240, 4],
          [300, 5],
          [600, 10],
          [900, 15],
        ].map((duration) => showData(duration[0], duration[1], "minutes", false));
        return [
          {
            id: 60,
            text: translations.one_minute,
          },
          ...data,
        ];
      default:
        return data;
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      className={classNames({
        "modal-content col-md-4 col-sm-4 custom-field-popup": true,
        "modal2-ie": isIE(),
      })}
      appElement={document.getElementById("advanced-lots-body")}
    >
      <form onSubmit={(e) => handleSaveButtonClick(e)}>
        <div className="block  block-transparent remove-margin-b">
          <div className="block-header bg-primary-default">
            <ul className="block-options">
              <li>
                <button type="button" onClick={() => setModalIsOpen(false)}>
                  <i className="ion-close" />
                </button>
              </li>
            </ul>
            <h3 className="block-title">{translations.settings}</h3>
          </div>
          <div className="block-content">
            <div className="settings-left m-t10">
              {event.event_type === "Japanese" ? (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{translations.next_time_interval}</label>
                        <span className="required">*</span>{" "}
                        <Tooltip title={lot_time_interval.title} body={lot_time_interval.body} />
                        <Select2
                          name="timeInterval"
                          data={renderData("timeInterval")}
                          onSelect={(e) => setTimeInterval(e.target.value)}
                          value={timeInterval}
                          options={{ minimumResultsForSearch: -1 }}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{translations.bid_change} (%)</label>
                        <span className="required">*</span>{" "}
                        <Tooltip title={lot_bid_change.title} body={lot_bid_change.body} />
                        <NumericFormat
                          type="text"
                          id="min_max_text_input"
                          value={bidChange}
                          onChange={(e) => setBidChange(e.target.value)}
                          autoComplete="off"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{translations.extend_duration}</label>{" "}
                        <Tooltip title={lot_minimum_duration.title} body={lot_minimum_duration.body} />
                        <Select2
                          name="extendDuration"
                          data={renderData("extendDuration")}
                          onSelect={(e) => setExtendDuration(e.target.value)}
                          prompt={translations.select_duration}
                          value={extendDuration}
                          options={{
                            minimumResultsForSearch: -1,
                            placeholder: translations.select_duration,
                          }}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{translations.dynamic_close_period}</label> &nbsp;
                        <Tooltip title={lot_dynamic_close_period.title} body={lot_dynamic_close_period.body} />
                        <Select2
                          name="dynamicClosePeriod"
                          data={renderData("dynamicClosePeriod")}
                          onSelect={(e) => setDynamicClosePeriod(e.target.value)}
                          value={dynamicClosePeriod}
                          options={{ minimumResultsForSearch: -1 }}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="form-group"
                        style={dynamicClosePeriod === 0 || event.event_type != "Ranked" ? { display: "none" } : {}}
                      >
                        <label>{translations.applies_to}</label>
                        <Tooltip title={lot_applies_to.title} body={lot_applies_to.body} />
                        <Select2
                          name="exchangeRateId"
                          data={renderData("topBidder")}
                          onSelect={(e) => setDynamicCloseRank(e.target.value)}
                          value={dynamicCloseRank}
                          options={{ minimumResultsForSearch: -1 }}
                          className={"form-control"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label>{translations.minimum_bid_change} (%)</label>
                        <span className="required"> *</span>
                        &nbsp;
                        <Tooltip title={lot_bid_change.title} body={lot_bid_change.body} />
                        <NumericFormat
                          type="text"
                          id="min_max_text_input"
                          value={minimumBidChange}
                          onChange={(e) => setMinimumBidChange(showCorrectValue(e.target.value))}
                          autoComplete="off"
                          className="min_bid_change_precision form-control"
                          decimalScale={2}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label>{translations.maximum_bid_change} (%)</label>
                        <span className="required"> *</span>
                        &nbsp;
                        <Tooltip title={lot_bid_change.title} body={lot_bid_change.body} />
                        <NumericFormat
                          type="text"
                          id="min_max_text_input"
                          value={maximumBidChange}
                          onChange={(e) => setMaximumBidChange(showCorrectValue(e.target.value))}
                          autoComplete="off"
                          className="max_bid_change_precision form-control"
                          decimalScale={2}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="settings-right modal-footer">
            <div className="input cancel">
              <div className="actions">
                <span className="cancel">
                  <button
                    className="btn btn-sm btn-default"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setModalIsOpen(false)}
                  >
                    {translations.cancel}
                  </button>
                </span>
                <button name="button" type="submit" className="btn btn-primary" ref={refBtn}>
                  {translations.save_changes} <i className="fa fa-check fa-white" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default SettingForm;
