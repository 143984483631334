export const fetchBidsOrBlicsDetails = (state, payload) => {
  return {
    ...state,
    bidList: payload.bidList,
    questionnaires: payload.questionnaires,
    questionnaireSections: payload.questionnaireSections,
    questionnaireQuestions: payload.questionnaireQuestions,
    questionnaireAnswers: payload.questionnaireAnswers,
    answers: payload.answers,
    questionnaireAnswersQuestions: payload.questionnaireAnswersQuestions,
    questionnaireAnswersSections: payload.questionnaireAnswersSections,
  };
};
