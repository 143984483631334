import React from "react";

class TreeNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    let { node, selectedCategory } = this.props;
    let visible = false;
    visible = selectedCategory ? this.processNodesToFindTheSelectedBranch(node, selectedCategory, visible) : true;
    this.setState({
      visible,
    });
  }

  processNodesToFindTheSelectedBranch(node, selectedCategory, visible) {
    if (!!selectedCategory.find((c) => c.id === node.id)) {
      visible = true;
    } else {
      if (node.child_nodes) {
        for (let i = 0; i < node.child_nodes.length; i++) {
          let val = node.child_nodes[i];
          if (selectedCategory.find((c) => c.id === val.id)) {
            visible = true;
          } else if (val.child_nodes) {
            visible = this.processNodesToFindTheSelectedBranch(val, selectedCategory, visible);
          }
        }
      }
    }
    return visible;
  }

  toggle() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    const { visible } = this.state;
    let selfThis = this;
    let childNodes;
    let className = "";
    let { node, selectedCategory, childIteration, showPage } = this.props;
    childIteration += 1;
    if (node && node.child_nodes && childIteration <= 5) {
      if (visible) {
        className += "fa fa-minus-square-o icon-minus-sign";
      } else {
        className += "fa fa-plus-square-o icon-plus-sign";
      }
      childNodes = node.child_nodes.map(function (child, index) {
        return (
          <TreeNode
            node={child}
            key={index}
            childIteration={childIteration}
            selectedCategory={selectedCategory}
            updateCategory={(child) => selfThis.props.updateCategory(child)}
            showPage={showPage}
          />
        );
      });
    }

    let style;
    if (!this.state.visible) {
      style = { display: "none" };
    }
    return (
      <li className="parent_li">
        <span className="child_span" onClick={() => this.toggle()}>
          <i className={className} />
        </span>
        {this.props.childIteration === 0 ? (
          <span className="child_span">{node.name}</span>
        ) : (
          <span
            className={`child_span badge ${
              selectedCategory && selectedCategory.find((c) => c.id === node.id) ? "orange" : ""
            }`}
            id={`category_${node.id}`}
            onClick={() => {
              if (!showPage) {
                this.props.updateCategory(node);
              }
            }}
          >
            {node.name}
          </span>
        )}
        <ul style={style}>{childNodes}</ul>
      </li>
    );
  }
}

export default TreeNode;
