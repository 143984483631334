import React from "react";
import type { Leaf, NormalizedNode } from "@/slices/rules/types";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  collapseToggled,
  conditionAdded,
  conditionRemoved,
  fieldChanged,
  selectCollapsedById,
  selectIssueById,
} from "@/slices/rules";
import { NodeIssue, NodeIssueTranslation } from "@/lib/rules/validate";
import Parameter from "./parameters";
import { useFields } from "./field-context";
import styles from "./node.module.scss";
import { Button } from "@/cl/button";
import { t } from "@/i18n";
import { Select } from "@/cl/select";
import { selectAllFields } from "@/slices/rules/fields";
import { Collapser, useParentCollapsed } from "./collapse-context";
import clsx from "clsx";

/**
 * Forces correct layout of an individual input with Feedback element.
 */
export const InputContainer = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.inputContainer}>{children}</div>
);

export const CollapsedValue = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.collapsedValue}>{children}</div>
);

export const Collapsible = ({
  children,
  collapsed,
  fallback,
}: {
  children: React.ReactNode;
  collapsed: boolean;
  fallback: React.ReactNode;
}) => (collapsed ? <CollapsedValue>{fallback}</CollapsedValue> : children);

interface NodeActionsProps {
  node: NormalizedNode<Leaf>;
  leafCollapsed: boolean;
  parentCollapsed: boolean;
  collapsed: boolean;
}

const NodeActions = ({ node, leafCollapsed, parentCollapsed, collapsed }: NodeActionsProps) => {
  const dispatch = useAppDispatch();
  return (
    <div className={styles.actions}>
      <Button
        size="sm"
        brand="primary"
        className={styles.addCondition}
        onClick={() => dispatch(conditionAdded(node.uuid))}
        aria-label={t("workflow_rules.add_condition")}
      >
        {collapsed ? <i className="fa fa-plus" aria-hidden /> : t("workflow_rules.add_condition")}
      </Button>
      <Button
        brand="outlined-primary"
        icon
        onClick={() => dispatch(conditionRemoved(node.uuid))}
        aria-label={t("delete")}
      >
        <i className="fa fa-trash" aria-hidden />
      </Button>
      {!parentCollapsed && (
        <Button
          brand="outlined-primary"
          icon
          className={styles.collapseButton}
          onClick={() => dispatch(collapseToggled(node.uuid))}
          aria-label={t(`workflow_rules.${leafCollapsed ? "expand" : "collapse"}`)}
          disabled={!node.state.field_name}
        >
          <i className={`fa fa-chevron-${leafCollapsed ? "down" : "up"}`} aria-hidden />
        </Button>
      )}
    </div>
  );
};

interface LeafNodeProps {
  node: NormalizedNode<Leaf>;
}

export function LeafNode({ node }: LeafNodeProps) {
  const dispatch = useAppDispatch();
  const fields = useFields();
  const issue = useAppSelector((state) => selectIssueById(state, node.uuid));
  const leafCollapsed = useAppSelector((state) => selectCollapsedById(state, node.uuid));
  const parentCollapsed = useParentCollapsed();
  const collapsed = parentCollapsed || leafCollapsed;
  return (
    <Collapser collapsed={leafCollapsed}>
      <div className={clsx(styles.node, { [styles.collapsed]: collapsed })}>
        <div className={styles.parameters}>
          <Collapsible
            collapsed={collapsed}
            fallback={
              node.state.field_name ? fields.entities[node.state.field_name]?.field : t("workflow_rules.select_field")
            }
          >
            <InputContainer>
              <Select
                name={`field-${node.uuid}`}
                value={node.state.field_name}
                onChange={(e) => {
                  dispatch(fieldChanged({ id: node.uuid, field: e.target.value, fields: fields.entities }));
                }}
                aria-label={t("workflow_rules.select_field")}
                options={[
                  { value: "", label: t("workflow_rules.select_field"), disabled: true },
                  ...selectAllFields(fields).map(({ field, id }) => ({ value: id, label: field })),
                ]}
                error={
                  issue === NodeIssue.InvalidField || issue === NodeIssue.FieldNotChosen
                    ? t("workflow_rules.issues." + NodeIssueTranslation[issue])
                    : undefined
                }
              />
            </InputContainer>
          </Collapsible>
          <Parameter node={node} />
        </div>
        <NodeActions {...{ node, leafCollapsed, parentCollapsed, collapsed }} />
      </div>
    </Collapser>
  );
}
