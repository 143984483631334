import { combineSlices } from "@reduxjs/toolkit";
import reducers from "@/reducers";
import { companySlice } from "@/slices/company";
import { eventClockSlice } from "@/slices/eventClock";
import { adminPermissionsPaginationSlice, lotLineItemsInformationSlice } from "./pagination";
import contracts from "@/slices/contracts";
import { emptyApi } from "@/slices/api";
import { rulesSlice } from "./rules";

export default combineSlices(
  emptyApi,
  eventClockSlice,
  companySlice,
  adminPermissionsPaginationSlice,
  lotLineItemsInformationSlice,
  rulesSlice,
  {
    contracts,
  },
  reducers
);
