import React from "react";
import BidDetailsForLineItem from "./BidDetailsForLineItem";
import BidDetailsForLot from "./BidDetailsForLot";
import LineItemTableHeading from "./LineItemTableHeading";
import LotTableHeading from "./LotTableHeading";

const BestBidTable = (props) => {
  const condition = props.modalType === "LineItem";
  return (
    <table className="table table-bordered custom-responsive-table show-bid-table">
      <thead>{condition ? <LineItemTableHeading {...props} /> : <LotTableHeading {...props} />}</thead>
      <tbody>{condition ? <BidDetailsForLineItem {...props} /> : <BidDetailsForLot {...props} />}</tbody>
    </table>
  );
};

export default BestBidTable;
