import React from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { rejectLot } from "../../../../actions/bidActions";
import { Confirm } from "../../../common/Confirm";
import PropTypes from "prop-types";

const LotRejectButton = (props) => {
  const dispatch = useDispatch();
  const { translations, setLotState, lotState, afterSuccess } = props;
  const modalHtmlId = `participantsRejectLotConfirm-${props.lot.id}`;
  return (
    <>
      <Confirm
        translations={translations}
        message={translations.are_you_sure}
        htmlId={modalHtmlId}
        modalClass="text-left"
        onOkayButtonClick={(e) => {
          setLotState({
            isLotRejecting: true,
          });
          dispatch(
            rejectLot(props.lot.id, {
              bid: {
                user_id: props.currentUser.id,
                event_id: props.event.id,
                rejected_lot: true,
                host_id: props.isProxy ? props.hostId : null,
              },
            })
          ).then((response) => {
            setLotState({
              isPlaceBidActive: false,
              isLotRejecting: false,
            });
            if (afterSuccess) afterSuccess();
          });
        }}
      />
      <button
        className={classNames({
          "reject_lot btn btn-sm btn-default m-r5": true,
          "btn-danger": props.isProxy,
          disabled: lotState.isLotRejecting,
        })}
        onClick={(e) => {
          e.preventDefault();
          props.openModal(`#${modalHtmlId}`);
        }}
      >
        {lotState.isLotRejecting ? translations.submitting : translations.reject_lot}
        <i className="fa fa-times m-l5"></i>
      </button>
    </>
  );
};

LotRejectButton.propTypes = {
  translations: PropTypes.object.isRequired,
  lotState: PropTypes.object.isRequired,
  lot: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  setLotState: PropTypes.func.isRequired,
  isProxy: PropTypes.bool,
  hostId: PropTypes.number,
  afterSuccess: PropTypes.func,
};

LotRejectButton.defaultProps = {
  isProxy: false,
};

export default LotRejectButton;
