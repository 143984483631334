import React from "react";
import { useSelector } from "react-redux";
import ActivityTableBody from "./ActivityTableBody";
import propTypes from "prop-types";

const activityTableInner = (props) => {
  const { setActivityId, contact_id: contactId } = props;

  return (
    <div className="border m-b15 wg-body widget-content widget-scrollable m-t30">
      <div className="bg-white event-list">
        <table className="border-collapse custom-responsive-table table-layout-fixed table-borderless">
          <thead className="sticky-header table-block-title with-filter">
            <StaticActivityHeader isContactId={!!contactId} />
          </thead>
          <tbody id="activity-list">
            <ActivityTableBody setActivityId={setActivityId} {...props} />
          </tbody>
        </table>
      </div>
    </div>
  );
};

const StaticActivityHeader = ({ isContactId, children }) => {
  const { translations } = useSelector((state) => state.ActivityReducer);

  let contactColumnText = translations.contacts;
  if (isContactId) {
    contactColumnText = translations.other_contacts;
  }

  return (
    <tr>
      <th>{translations.subject}</th>
      <th>{translations.owners}</th>
      <th>{translations.status}</th>
      <th>{translations.type}</th>
      <th>{translations.priority}</th>
      <th>{translations.created_at}</th>
      <th>{translations.due_date}</th>
      {children}
      <th>{contactColumnText}</th>
    </tr>
  );
};

StaticActivityHeader.propTypes = {
  isContactId: propTypes.bool,
};

StaticActivityHeader.defaultProps = {
  isContactId: false,
};

export default activityTableInner;
export { StaticActivityHeader };
