import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Row from "./SubActivityTabContent";

const Header = ({ translations }) => {
  return (
    <thead className="sticky-header table-block-title with-filter">
      <tr>
        <th className="wid-40">{translations.subject}</th>
        <th className="wid-30">{translations.due_date}</th>
        <th className="wid-30">{translations.status}</th>
      </tr>
    </thead>
  );
};

const SubActivityTab = ({ activity, activities, childActivities }) => {
  const { translations } = useSelector((state) => state.ActivityReducer);
  const ancestor = activities.filter((act) => act.id == activity.ancestor_id || act.id === activity.id);
  const [shownChilds, setShownChilds] = useState([]);

  return (
    <div
      id="subactivity"
      className="bg-white border-t0 col-md-12 form-item-block m-b0"
      role="tabpanel"
      aria-labelledby="subactivity-tab"
    >
      <table className="border custom-responsive-table table-layout-fixed">
        <Header translations={translations} />
        <tbody>
          {ancestor.map((act) => {
            return (
              <Row
                activity={act}
                activeId={activity.id}
                depth={0}
                key={"ancestor-row-" + act.id}
                translations={translations}
                childToggle={{ shownChilds, setShownChilds }}
                childActivities={childActivities}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Header.propTypes = {
  translations: PropTypes.object.isRequired,
};

SubActivityTab.propTypes = {
  activity: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  childActivities: PropTypes.array.isRequired,
};

export default SubActivityTab;

export { Header };
