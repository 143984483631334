import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/**
 * A hook to manage the details state for a list of line items. Makes it easy
 * to look up if a line item is displaying its details or not. You can simply
 * change a toggle the line item details in a global but manageable way
 *
 * @param {Boolean} defaultValue The default state for showAllLineItemDetails
 */
export const useLineItemDetails = (defaultValue = false) => {
  const lineItems = useSelector(({ lotReducers }) => lotReducers.line_items);
  const [showAllLineItemDetails, setShowAllLineItemDetails] = useState(defaultValue);
  const [lineItemsDetailsState, setDetailsState] = useState(() => {
    return lineItems.reduce((current, lineItem) => ({ ...current, [lineItem.id]: defaultValue }), {});
  });

  const setLineItemsDetailState = (newState) => {
    setShowAllLineItemDetails(newState);
    setDetailsState((state) => {
      return Object.keys(state).reduce((current, id) => ({ ...current, [id]: newState }), {});
    });
  };

  useEffect(() => {
    // set detailed state for all lineItems true if isPlaceBidActive/defaultValue is true
    if (defaultValue) setLineItemsDetailState(true);
  }, [defaultValue]);

  return {
    /**
     * A map of all the line items and if they have their details displayed.
     * The map is keyed by the line_item_id, so you can look up if a single
     * line item is open or closed.
     *
     * @type {{[k: Number]: Boolean}}
     */
    lineItemsDetailsState,
    /**
     * If the user has opened all of the line items.
     *
     * @type {Boolean}
     */
    showAllLineItemDetails,
    /**
     * Toggle the details for a single line item.
     *
     * @param {Number} id The ID of the line item to toggle
     */
    toggleDetailsView: (id) => {
      setDetailsState((state) => ({ ...state, [id]: !state[id] }));
    },
    /**
     * A toggle that will open all the line item details. It does not matter if
     * the line item is already opened, it will still be opened after this
     * operation
     */
    setLineItemsDetailStateOpen: () => setLineItemsDetailState(true),
    /**
     * A toggle that will close all the line item details. It does not matter
     * if the line item is already closed, it will still be closed after this
     * operation
     */
    setLineItemsDetailStateClosed: () => setLineItemsDetailState(false),
  };
};

export default useLineItemDetails;
