import React, { Component } from "react";
import { lotLotComponents, lotLineItemComponents, lotLineItems } from "../../common";
import LotComponentNameForOverview from "./LotComponentNameForOverview";
import LotComponentColumnTitle from "./LotComponentColumnTitle";
import LotNameAndBidDetailsForOverview from "./LotNameAndBidDetailsForOverview";

export default class HostMonitorPendingOverviewLot extends Component {
  render() {
    const {
      event,
      bids,
      translations,
      lots,
      participatingParticipants,
      displayScores,
      weightedRfqOrAuction,
      permissionToView,
      lot,
      currency,
      firstLot,
      lotComponents,
      lineItems,
      beforeAuction,
      locale,
      lineItemComponents,
      isMultiCurrencyEvent,
      bidLineItemComponents,
      lotIndex,
      participants,
      eventParticipants,
      currenciesHash,
      unitOfMeasures,
      allUnitSets,
      role,
      isLcOpened,
      isEpOpened,
      isLiOpened,
      toggleLiCollapse,
      permittedToEdit,
      bidControls,
      auctionControls,
      isJapaneseEvent,
      hosts,
      currentUser,
      timeZoneIdentifier,
      bidRound,
      eventPassedFinalDeadline,
      toggleEpCollapse,
      unitSetUoms,
      bidsSealed,
      picklistOptions,
      auctionTab,
      canShowDeleteBtn,
      hostPermission,
      inSandpit,
      remainingParticipantsForJapaneseEvent,
      allowtoShowProxyBtn,
    } = this.props;
    const lotLcs = lotLotComponents(lot, lotComponents);
    const lotLis = lotLineItems(lot, lineItems);
    const lotLics = lotLineItemComponents(lotLcs, lineItemComponents);
    return (
      <>
        <span
          id={`row_anchor_${lot.id}`}
          className={`${isLcOpened.length > 0 ? "row-anchor" : ""} ${firstLot ? "first-row-anchor" : ""} `}
        ></span>
        {/*  # First row: Lot Component Names (Leave blank for non-price components) */}
        {isLcOpened.length > 0 && (
          <LotComponentNameForOverview
            translations={translations}
            lot={lot}
            event={event}
            participatingParticipants={participatingParticipants}
            lotLcs={lotLcs}
            permissionToView={permissionToView}
            firstLot={firstLot}
            weightedRfqOrAuction={weightedRfqOrAuction}
            isLcOpened={isLcOpened}
            auctionTab={auctionTab}
            isJapaneseEvent={isJapaneseEvent}
          />
        )}
        {/* Component column titles */}
        {(isEpOpened || firstLot) && (
          <LotComponentColumnTitle
            event={event}
            lot={lot}
            translations={translations}
            participatingParticipants={participatingParticipants}
            displayScores={displayScores}
            weightedRfqOrAuction={weightedRfqOrAuction}
            permissionToView={permissionToView}
            beforeAuction={beforeAuction}
            lotLcs={lotLcs}
            isLcOpened={isLcOpened}
            isEpOpened={isEpOpened}
            toggleEpCollapse={toggleEpCollapse}
            bidsSealed={bidsSealed}
            auctionTab={auctionTab}
            isJapaneseEvent={isJapaneseEvent}
          />
        )}
        {/* Next rows: Lot name */}
        <LotNameAndBidDetailsForOverview
          event={event}
          lot={lot}
          translations={translations}
          lotLis={lotLis}
          lotLcs={lotLcs}
          participatingParticipants={participatingParticipants}
          displayScores={displayScores}
          weightedRfqOrAuction={weightedRfqOrAuction}
          permissionToView={permissionToView}
          beforeAuction={beforeAuction}
          lotLcs={lotLcs}
          lots={lots}
          currency={currency}
          locale={locale}
          lotLics={lotLics}
          isMultiCurrencyEvent={isMultiCurrencyEvent}
          bidLineItemComponents={bidLineItemComponents}
          bids={bids}
          lotIndex={lotIndex}
          participants={participants}
          eventParticipants={eventParticipants}
          lotLis={lotLis}
          currenciesHash={currenciesHash}
          lineItemComponents={lineItemComponents}
          unitOfMeasures={unitOfMeasures}
          allUnitSets={allUnitSets}
          role={role}
          isLcOpened={isLcOpened}
          isLiOpened={isLiOpened}
          isEpOpened={isEpOpened}
          toggleLiCollapse={toggleLiCollapse}
          permittedToEdit={permittedToEdit}
          bidControls={bidControls}
          auctionControls={auctionControls}
          isJapaneseEvent={isJapaneseEvent}
          hosts={hosts}
          currentUser={currentUser}
          timeZoneIdentifier={timeZoneIdentifier}
          bidRound={bidRound}
          eventPassedFinalDeadline={eventPassedFinalDeadline}
          lotComponents={lotComponents}
          lineItems={lineItems}
          unitSetUoms={unitSetUoms}
          bidsSealed={bidsSealed}
          picklistOptions={picklistOptions}
          auctionTab={auctionTab}
          canShowDeleteBtn={canShowDeleteBtn}
          hostPermission={hostPermission}
          inSandpit={inSandpit}
          remainingParticipantsForJapaneseEvent={remainingParticipantsForJapaneseEvent}
          allowtoShowProxyBtn={allowtoShowProxyBtn}
          partialBiddingSwitch={this.props.partialBiddingSwitch}
        />
      </>
    );
  }
}
