import type { PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { createAppSlice } from "./util/createAppSlice";

const paginationState = { currentPage: 1, resultsPerPage: 25, hasMoreData: true, isLoading: true };

type PaginationState = typeof paginationState;

const paginationReducers = {
  /**
   * @description go to next page
   */
  nextPage(state) {
    state.currentPage++;
  },

  /**
   * @description go to previous page
   */
  previousPage(state) {
    state.currentPage--;
  },

  /**
   * @description go directly to page specified in payload
   */
  goToPage(state, action: PayloadAction<{ page: number }>) {
    state.currentPage = action.payload.page;
  },

  /**
   * @description reset state
   */
  resetPaginationState(state) {
    Object.assign(state, paginationState);
  },

  /**
   * @description common reducer to set state in slice
   */
  setPaginationState(state, action: PayloadAction<Partial<PaginationState>>) {
    Object.assign(state, action.payload);
  },
} satisfies SliceCaseReducers<PaginationState>;

export const adminPermissionsPaginationSlice = createAppSlice({
  name: "adminPermissionPagination",
  initialState: paginationState,
  reducers: paginationReducers,
});

export const lotLineItemsInformationSlice = createAppSlice({
  name: "lotLineItemsInformation",
  initialState: paginationState,
  reducers: paginationReducers,
});
