import React from "react";
import { Confirm } from "../../../common/Confirm";
import { useDispatch } from "react-redux";
import { turnDownEvent } from "../../../../actions/eventActions";

const TurnDownEvent = (props) => {
  const _onOkayButtonClick = () => {
    dispatch(turnDownEvent(props.event.id, props.eventParticipant.id));
  };

  const dispatch = useDispatch();
  const htmlModalId = "turnDownEventLot";

  return (
    <>
      <button
        className="auto_width_btn btn-blue-outline sm-butt status-event turn-down-event m-l10 pull-right"
        onClick={(e) => {
          e.preventDefault();
          props.openModal(`#${htmlModalId}`);
        }}
      >
        <i className="fa fa-ban"></i>&nbsp;
        {props.translations.turn_down_event}
      </button>
      <Confirm
        translations={props.translations}
        message={props.translations.you_wish_to_turn_down_this_event}
        htmlId={htmlModalId}
        onOkayButtonClick={_onOkayButtonClick}
      />
    </>
  );
};

export default TurnDownEvent;
