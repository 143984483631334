import React from "react";
import HostPendingEventLots from "./host_rfq_screen/HostPendingEventLots";

const PastEventLots = (props) => {
  const { role } = props;
  let renderPastEventLots = "";
  if (["Host", "Admin"].includes(role)) {
    renderPastEventLots = <HostPendingEventLots {...props} />;
  }
  return <div>{renderPastEventLots}</div>;
};

export default PastEventLots;
