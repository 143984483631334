import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import Modal from "react-modal";
import Select2 from "react-select2-wrapper";
import DatePicker from "react-datepicker";
import moment from "moment";
import flatten from "lodash/flatten";
import "react-datepicker/dist/react-datepicker.css";

import { updateLineItemComponent } from "../../../../actions/lineItemComponentActions";
import {
  isPrice,
  isText,
  isDecimal,
  isDate,
  isPicklist,
  licPicklistOptions,
  qualificationValue,
  currentValue,
  usedValue,
  isLotTotal,
  licsForFormulaWithLot,
  isIE,
} from "../../common";
import LineItemComponentFormulaField from "./LineItemComponentFormulaField";
import PriceComponentField from "./PriceComponentField";
import DescriptionFields from "./DescriptionFields";
import Tooltip from "../../../common/Tooltip";
import classNames from "classnames";
import { lotAutomationEnforcedAndNotSandpit } from "../lotCommon";

const EditLineItemComponentModal = (props) => {
  const {
    modalIsOpen,
    setModalIsOpen,
    handleCloseModal,
    translations,
    event,
    lot,
    lic,
    classFromEventState,
    anyCompletedBids,
    openOrJapanese,
    isJapaneseEvent,
    picklistOptions,
    currency,
    isLotTotalLic,
    setIsLotTotalLic,
    lots,
    currenciesHash,
    currentUser,
    uoms,
    setUoms,
    calculationComponent,
    licsForFormula,
  } = props;
  const dispatch = useDispatch();
  const lAEnforcedAndNotSandpit = useSelector(lotAutomationEnforcedAndNotSandpit);
  const isInputDisabled = lAEnforcedAndNotSandpit && !lic.host;
  //Get state from redux-store
  const states = useSelector((states) => states);
  const { modalHOCReducers, lotReducers } = states;
  const {
    lot_attribute_value,
    lot_number_current_value,
    lot_date_value,
    lot_picklist_value,
    lot_is_lot_total,
    lot_is_ranked,
    lot_is_visible_rank,
  } = translations.infos;
  const updateReferenceMessage =
    lotReducers && lotReducers.updateReferenceMessage ? lotReducers.updateReferenceMessage : {};
  const lotTotalTranslation = {
    ...translations.lot_total_translation,
    ...updateReferenceMessage,
  };

  const flattenedLics = (formulaLics, lots) => {
    let lics = [];
    if (lots) {
      lots.map(function (lot) {
        const lotLics = formulaLics[lot.id];
        if (lotLics) {
          lics.push(flatten(Object.values(lotLics)));
        }
      });
    }
    return flatten(lics);
  };

  const getFormulaLics = (lot, isLotTotalLic) => {
    return licsForFormulaWithLot(lots, lot, lic, licsForFormula, isLotTotalLic);
  };
  const formulaLics = lic.is_formula_lic ? getFormulaLics(lot, isLotTotal(lot, lic)) : {};

  const descriptionStatus = lic.description ? true : false;
  const dateVal = lic.date_value ? moment(lic.date_value).toDate() : "";

  const [showDescription, setShowDescription] = useState(descriptionStatus);
  const [attributeValue, setAttributeValue] = useState(lic.attribute_value || "");
  const [description, setDescription] = useState(lic.description || "");
  const [dateValue, setDateValue] = useState(dateVal);
  const [picklistOptionId, setPicklistOptionId] = useState(lic.picklist_option_id || "");
  const [usedPrice, setUsedPrice] = useState(lic.used_price ?? "");

  const [qualificationPrice, setQualificationPrice] = useState(lic.qualification_price ?? "");
  const [currentPrice, setCurrentPrice] = useState(lic.current_price ?? "");
  const [isRanked, setIsRanked] = useState(lic.is_ranked);
  const [isVisibleRank, setIsVisibleRank] = useState(lic.is_visible_rank);
  const [isDefaultFormulaLic, setIsDefaultFormulaLic] = useState(lic.is_default_formula ? "1" : "0");
  const [quantity, setQuantity] = useState(lic.quantity || 1);
  const [exchangeRateId, setExchangeRateId] = useState(lic.exchange_rate_id);
  const [unitOfMeasureUomname, setUnitOfMeasureUomname] = useState(lic.uom_name && !lic.is_uom_set ? lic.uom_name : "");
  const [unitOfMeasureId, setUnitOfMeasureId] = useState(lic.is_uom_set ? lic.unit_of_measure_id : "");
  const [qualificationVal, setQualificationVal] = useState(qualificationValue(lic));
  const [usedVal, setUsedVal] = useState(usedValue(lic));
  const [currentVal, setCurrentVal] = useState(currentValue(lic));
  const arrangeFormula = (formula) => {
    let result = "";
    if (formula) {
      if (formula.slice(-1) === ",") {
        result = formula;
      } else {
        result = formula.concat(",");
      }
    }
    return result;
  };
  const [formula, setFormula] = useState(arrangeFormula(lic.formula));
  const [filteredLicsForFormula, setFilteredLicsForFormula] = useState(
    lic.is_formula_lic ? flattenedLics(formulaLics, lots) : []
  );
  const [lotWiseFormulaLics, setLotWiseFormulaLics] = useState(formulaLics);

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  useEffect(() => {
    const state = {
      quantity: quantity,
      qualificationPrice: qualificationPrice,
      currentPrice: currentPrice,
      usedPrice: usedPrice,
    };
    setQualificationVal(qualificationValue(lic, state));
    setCurrentVal(currentValue(lic, state));
    setUsedVal(usedValue(lic, state));
  }, [qualificationPrice, usedPrice, currentPrice, quantity]);

  useEffect(() => {
    const formLic = getFormulaLics(lot, isLotTotalLic);
    if (lot.is_event_total && isLotTotalLic !== isLotTotal(lot, lic)) {
      setFormula("");
    }
    setFilteredLicsForFormula(flattenedLics(formLic, lots));
    setLotWiseFormulaLics(formLic);
  }, [isLotTotalLic]);

  const handleRankChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "isRanked") {
      const visibleRank = !target.checked ? false : isVisibleRank;
      setIsRanked(target.checked);
      setIsVisibleRank(visibleRank);
    } else if (name === "isDefaultFormulaLic") {
      setIsDefaultFormulaLic(target.checked ? "1" : "0");
    }
  };

  const updateLineItemComponents = (event) => {
    event.preventDefault();
    const { lot, lic, translations, locale } = props;
    const target = document.getElementById("licSubmitButton");
    target.disabled = true;
    target.innerHTML = translations.submitting;
    let lineItemComponent = {
      line_item_id: lic.line_item_id,
      from_host_form: "true",
      attribute_value: attributeValue,
      used_price: usedPrice,
      picklist_option_id: picklistOptionId,
      date_value: dateValue,
      current_price: currentPrice,
      qualification_price: qualificationPrice,
      is_ranked: isRanked,
      is_visible_rank: isVisibleRank,
      is_lot_total: isLotTotalLic,
      description: description,
      quantity: quantity ? quantity : "1",
      is_default_formula: isDefaultFormulaLic,
      formula: formula || null,
      event_id: lot.event_id,
      exchange_rate_id: exchangeRateId,
    };
    const payload = {
      line_item_component: Object.assign(lineItemComponent, payloadForUoM()),
    };

    dispatch(updateLineItemComponent(lic.lot_component_id, lic.id, payload)).then((response) => {
      if (response) {
        setModalIsOpen(false);
      } else {
        target.disabled = false;
        target.innerHTML = translations.save + " <i class='fa fa-check' />";
      }
    });
  };

  const handlePicklistValue = (event) => {
    setAttributeValue(event.target.value);
    setPicklistOptionId(event.target.picklist_option_id);
  };

  const payloadForUoM = () => {
    const uom = {};
    if (lic.is_uom_set) {
      Object.assign(uom, { unit_of_measure_id: unitOfMeasureId });
    } else {
      Object.assign(uom, { unit_of_measure_uomname: unitOfMeasureUomname });
    }
    return uom;
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => handleCloseModal()}
      className={classNames({
        "modal-content col-md-8 col-sm-8 custom-field-popup": true,
        "modal-ie": isIE(),
      })}
      appElement={document.getElementById("advanced-lots-body")}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          //prevent submitting of modal if any child modal is already opened
          !modalHOCReducers.isActive && updateLineItemComponents(e);
        }}
      >
        <div className="form lic-form complex-lot">
          <div className="block block-transparent remove-margin-b">
            <div className="block-header bg-primary-default">
              <ul className="block-options pull-right">
                <li>
                  <button type="button" onClick={() => handleCloseModal()}>
                    <i className="ion-close" />
                  </button>
                </li>
              </ul>
              <h3 className="block-title pull-left">
                {translations.line_item_component_edit}
                {` (${lic.li_name} - ${lic.lc_name}) `}
                <span className="lic-tag">
                  {translations.line_item_component_ref}: {lic.tag}
                </span>
              </h3>
            </div>
            <div className="block-content">
              {lic.is_price && lic.host ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="flash caption notification alert alert-info">
                      <p> {translations.host_entered_price}</p>
                      <div className="clear" />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <DescriptionFields
                translations={translations}
                setShowDescription={setShowDescription}
                setDescription={setDescription}
                classFromEventState={classFromEventState}
                showDescription={showDescription}
                lc={lic}
                description={description}
              />
              {lic.calculable_lc && lic.is_formula_lic ? (
                <LineItemComponentFormulaField
                  event={event}
                  lot={lot}
                  lic={lic}
                  anyCompletedBids={anyCompletedBids}
                  isFormulaLic={lic.is_formula_lic}
                  translations={translations}
                  isDefaultFormulaLic={isDefaultFormulaLic}
                  handleRankChange={handleRankChange}
                  lots={lots}
                  formula={formula}
                  filteredLicsForFormula={filteredLicsForFormula}
                  currenciesHash={currenciesHash}
                  exchangeRateId={exchangeRateId}
                  setFormula={setFormula}
                  setFilteredLicsForFormula={setFilteredLicsForFormula}
                  lotWiseFormulaLics={lotWiseFormulaLics}
                  setExchangeRateId={setExchangeRateId}
                  calculationComponent={calculationComponent}
                  arrangeFormula={arrangeFormula}
                />
              ) : (
                ""
              )}
              {lic.calculable_lc && lic.is_price && !lic.is_formula_lic ? (
                <PriceComponentField
                  event={event}
                  lot={lot}
                  lic={lic}
                  anyCompletedBids={anyCompletedBids}
                  isFormulaLic={lic.is_formula_lic}
                  translations={translations}
                  event={event}
                  isJapaneseEvent={isJapaneseEvent}
                  currency={currency}
                  setQuantity={setQuantity}
                  setQualificationPrice={setQualificationPrice}
                  setCurrentPrice={setCurrentPrice}
                  setUsedPrice={setUsedPrice}
                  qualificationPrice={qualificationPrice}
                  currentPrice={currentPrice}
                  usedPrice={usedPrice}
                  unitOfMeasureUomname={unitOfMeasureUomname}
                  currentVal={currentVal}
                  qualificationVal={qualificationVal}
                  usedVal={usedVal}
                  quantity={quantity}
                  currenciesHash={currenciesHash}
                  exchangeRateId={exchangeRateId}
                  uoms={uoms}
                  setUoms={setUoms}
                  unitOfMeasureId={unitOfMeasureId}
                  currentUser={currentUser}
                  anyCompletedBids={anyCompletedBids}
                  setExchangeRateId={setExchangeRateId}
                  setUnitOfMeasureUomname={setUnitOfMeasureUomname}
                  setUnitOfMeasureId={setUnitOfMeasureId}
                />
              ) : (
                ""
              )}
              {isText(lic) && !lic.participant ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="col input">
                        <Tooltip title={lot_attribute_value.title} body={lot_attribute_value.body} />
                        <label htmlFor="attributeValue">{translations.your_answer}</label>
                        <input
                          type="text"
                          className="form-control"
                          name="attributeValue"
                          id="attributeValue"
                          value={attributeValue}
                          onChange={(e) => setAttributeValue(e.target.value)}
                          autoFocus={true}
                          disabled={isInputDisabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {isDecimal(lic) && !lic.is_formula_lic ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="col input">
                        <Tooltip title={lot_number_current_value.title} body={lot_number_current_value.body} />
                        <label htmlFor="usedPrice">{translations.current_value}</label>
                        <br />
                        <input
                          type="number"
                          name="usedPrice"
                          id="usedPrice"
                          step="any"
                          style={{ width: "140px" }}
                          className="form-control"
                          value={usedPrice}
                          onChange={(e) => setUsedPrice(e.target.value)}
                          disabled={isInputDisabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {isDate(lic) && !lic.participant ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="col input">
                        <Tooltip title={lot_date_value.title} body={lot_date_value.body} />
                        <label htmlFor="dateValue">{translations.select_date}</label>
                        <br />
                        <DatePicker
                          name="dateValue"
                          id="dateValue"
                          selected={dateValue}
                          onChange={(value) => setDateValue(value)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="yyyy-MM-dd H:mm"
                          timeCaption="time"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="dtpicker form-control"
                          autoComplete="off"
                          disabled={isInputDisabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {isPicklist(lic) && !lic.is_formula_lic ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="col input picklist-value">
                        <Tooltip title={lot_picklist_value.title} body={lot_picklist_value.body} />
                        <label htmlFor="attributeValue">{translations.select_an_option}</label>
                        <Select2
                          className="form-control clearfix lic_picklist_value"
                          data={licPicklistOptions(picklistOptions)}
                          name="attributeValue"
                          id="attributeValue"
                          onSelect={(e) => handlePicklistValue(e)}
                          selected={attributeValue}
                          value={attributeValue}
                          disabled={isInputDisabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {isPrice(lic) && !lic.host ? (
                // Lot total check box
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="col no-padding">
                          <div className="input no-padding">
                            <div className="radios">
                              <label className="css-input css-checkbox css-checkbox-default" htmlFor="lot_total">
                                <input
                                  name="isLotTotalLic"
                                  className="provider_option"
                                  type="checkbox"
                                  id="lot_total"
                                  disabled={anyCompletedBids || lot.lot_total_lic_id === lic.id || isInputDisabled}
                                  onChange={(e) => setIsLotTotalLic(e.target.checked)}
                                  checked={isLotTotalLic}
                                />
                                <span />
                                {translations.is_lot_total}
                              </label>
                              <span className="m-l5">
                                <Tooltip title={lot_is_lot_total.title} body={lot_is_lot_total.body} />
                              </span>
                              <div className="clear" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* is ranked check box */}
                    <div className="col-md-4">
                      <div className="form-froup">
                        <div className="col no-padding">
                          <div className={`input no-padding ${isLotTotalLic ? "hidden" : ""}`}>
                            <div className="radios">
                              <label className="css-input css-checkbox css-checkbox-default" htmlFor="is_ranked">
                                <input
                                  name="isRanked"
                                  className="provider_option"
                                  type="checkbox"
                                  id="is_ranked"
                                  checked={isRanked}
                                  disabled={anyCompletedBids || isInputDisabled}
                                  onChange={(e) => handleRankChange(e)}
                                />
                                <span />
                                {translations.is_ranked}
                              </label>
                              <span className="m-l5">
                                <Tooltip title={lot_is_ranked.title} body={lot_is_ranked.body} />
                              </span>
                              <div className="clear" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!openOrJapanese && (
                      <div className="col-md-4">
                        <div className="form-froup">
                          <div className="col no-padding">
                            <div className={`input no-padding ${isRanked && !isLotTotalLic ? "" : "hidden"} `}>
                              <div className="radios">
                                <label className="css-input css-checkbox css-checkbox-default">
                                  <input
                                    type="checkbox"
                                    name="isVisibleRank"
                                    disabled={anyCompletedBids || isInputDisabled}
                                    checked={isVisibleRank}
                                    onChange={(e) => setIsVisibleRank(e.target.checked)}
                                  />
                                  <span />
                                  {translations.is_visible_rank}
                                </label>
                                <span className="m-l5">
                                  <Tooltip title={lot_is_visible_rank.title} body={lot_is_visible_rank.body} />
                                </span>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {lot.lot_total_lic_id !== lic.id && lic.is_price && lic.participant ? (
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12 notification-block">
                  <div className="flash caption notification alert alert-info">
                    <p>
                      {lot.lot_total_lic_id === null
                        ? translations.no_lot_total_info
                        : lotTotalTranslation[lot.lot_total_lic_id]}
                    </p>
                    <div className="clear"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="modal-footer">
            <button
              data-dismiss="modal"
              type="button"
              className="close-form btn btn-sm btn-default"
              onClick={() => handleCloseModal()}
            >
              {translations.cancel}
            </button>
            {!isInputDisabled && (
              <button name="button" id="licSubmitButton" className="btn btn-sm btn-primary" type="submit">
                {translations.save} <i className="fa fa-check" />
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};

EditLineItemComponentModal.defaultProps = {
  updateReferenceMessage: null,
};

const mapStateToProps = (state) => {
  return {
    lots: state.lotReducers.lots,
  };
};
export default connect(mapStateToProps, null)(EditLineItemComponentModal);
