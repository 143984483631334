import moment from "moment";

// theme colors for chart
const colors = ["#058DC7", "#50B432", "#ED561B", "#DDDF00", "#24CBE5", "#64E572", "#FF9655", "#FFF263", "#6AF9C4"];

function commonToolTipFormatter({ point, series, x, y, symbol }) {
  return `<b>${point?.name ?? series?.name ?? ""}</b>
        <br>${moment(x).format("DD MMM , h:mm:ss A")}
        <br><b>${symbol}${y ?? ""}</b>
        `;
}

export const getParticpantChartOptions = (props) => {
  const { lot, translations, licCurrency, categories, data } = props;
  const { bid_value_price_per_uom_x_quantity, your_bids_for_lot, bid_progression } = translations;
  const symbol = licCurrency.symbol ? licCurrency.symbol : "-";

  const chartOptions = {
    chart: {
      marginBottom: 100,
      marginRight: 75,
      height: 450,
    },
    colors,
    title: {
      text: `<b>${your_bids_for_lot}</b><br>${lot.name}`,
    },
    xAxis: [
      {
        categories,
        labels: {
          align: "center",
          formatter: function () {
            return `<b>${moment(this.value).format("LT")}</b>`;
          },
        },
      },
      {
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        gridLineColor: "transparent",
        labels: { enabled: false },
        minorTickLength: 0,
        tickLength: 0,
      },
    ],
    series: [{ data }],
    yAxis: {
      title: {
        text: `<b>${bid_value_price_per_uom_x_quantity.replace("%{symbol}", symbol)}</b>`,
      },
      lineWidth: 1,
      labels: {
        formatter: function () {
          return `<b>${this.value}</b>`;
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${bid_progression}</b>
        <br>${moment(this.x).format("DD MMM , h:mm:ss A")}
        <br>
        <b>${symbol}${this.y}</b>
        `;
      },
    },
    plotOptions: {
      step: true,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };

  return chartOptions;
};

export const hostsParticipantProgressionChartOptions = (props) => {
  const { translations, currency, data, participants } = props;
  const { bid_value_price_per_uom_x_quantity, participant_progression_chart } = translations;
  const symbol = currency && currency.symbol ? currency.symbol : "-";
  const chartOptions = {
    chart: {
      renderTo: "chart",
      zoomType: "xy",
      type: "column",
      marginBottom: 100,
      marginRight: 75,
      height: 450,
    },
    colors,
    title: {
      text: `<b>${participant_progression_chart}</b>`,
    },
    xAxis: [
      {
        categories: participants,
        labels: {
          align: "center",
          rotation: 45,
          align: "left",
        },
      },
      {
        lineWidth: 1,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        gridLineColor: "transparent",
        labels: { enabled: false },
        minorTickLength: 0,
        tickLength: 0,
      },
    ],
    series: data,
    yAxis: {
      lineColor: "#000000",
      lineWidth: 1,
      title: {
        text: `<b>${bid_value_price_per_uom_x_quantity.replace("%{symbol}", symbol)}</b>`,
      },
      lineWidth: 1,
      labels: {
        formatter: function () {
          return `<b>${this.value}</b>`;
        },
      },
    },
    tooltip: {
      formatter: function () {
        return (
          (!!this.point?.company_and_name ? `${this.point?.company_and_name}` : "") +
          (!!this.series?.name ? `<br>${this.series?.name}` : "") +
          `<br><b>${!!this.point?.formatted_value ? this.point?.formatted_value : symbol + "" + (this.y ?? "")}</b>`
        );
      },
    },
    legend: {
      backgroundColor: "#FFFFFF",
      verticalAlign: "top",
      y: 20,
      shadow: true,
    },
    plotOptions: {
      step: true,
    },
    credits: {
      enabled: false,
    },
  };

  return chartOptions;
};

export const hostsHorseRaceChartOptions = (props) => {
  const { translations, currency, data } = props;
  const { bid_value_price_per_uom_x_quantity, horse_race_chart } = translations;
  const symbol = currency && currency.symbol ? currency.symbol : "-";
  const chartData = Array.from(
    data.map((d) => {
      d.data = d.data.map(({ y, x, name }) => {
        return {
          name,
          x: new Date(x).valueOf(),
          y,
        };
      });
      d.showInLegend = true;
      return d;
    })
  );

  const chartOptions = {
    chart: {
      renderTo: "chart",
      zoomType: "xy",
      type: "column",
      marginBottom: 100,
      marginRight: 75,
      height: 450,
      type: "line",
    },
    colors,
    title: {
      text: `<b>${horse_race_chart}</b>`,
    },
    xAxis: [
      {
        type: "datetime",
        labels: {
          formatter: function () {
            return `<b>${moment(this.value).format("LT")}</b>`;
          },
        },
      },
      {
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        gridLineColor: "transparent",
        labels: { enabled: false },
        minorTickLength: 0,
        tickLength: 0,
      },
    ],
    series: chartData,
    yAxis: {
      title: {
        text: `<b>${bid_value_price_per_uom_x_quantity.replace("%{symbol}", symbol)}</b>`,
      },
      lineWidth: 1,
      labels: {
        formatter: function () {
          return `<b>${this.value}</b>`;
        },
      },
    },
    tooltip: {
      formatter: function () {
        return commonToolTipFormatter({ point: this.point, series: this.series, x: this.x, y: this.y, symbol });
      },
    },
    legend: {
      backgroundColor: "#FFFFFF",
      verticalAlign: "top",
      y: 20,
      shadow: true,
    },
    plotOptions: {
      step: "left",
    },
    credits: {
      enabled: false,
    },
  };

  return chartOptions;
};

export const hostsBestBidProgressionChartOptions = (props) => {
  const { translations, currency, data, qualCurrValue = [] } = props;
  const { bid_value_price_per_uom_x_quantity, best_bid_progression_chart } = translations;
  const symbol = currency && currency.symbol ? currency.symbol : "-";
  const series = [
    {
      name: translations.best_bid,
      data: data.map(({ y, x, name }) => {
        return {
          name,
          x: new Date(x).valueOf(),
          y,
        };
      }),
    },
  ].concat(qualCurrValue);
  const chartOptions = {
    chart: {
      renderTo: "chart",
      zoomType: "xy",
      type: "column",
      marginBottom: 100,
      marginRight: 75,
      height: 450,
      type: "line",
    },
    colors,
    title: {
      text: `<b>${best_bid_progression_chart}</b>`,
    },
    xAxis: [
      {
        type: "datetime",
        labels: {
          formatter: function () {
            return `<b>${moment(this.value).format("LT")}</b>`;
          },
        },
      },
      {
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        gridLineColor: "transparent",
        labels: { enabled: false },
        minorTickLength: 0,
        tickLength: 0,
      },
    ],
    series,
    yAxis: {
      title: {
        text: `<b>${bid_value_price_per_uom_x_quantity.replace("%{symbol}", symbol)}</b>`,
      },
      lineWidth: 1,
      labels: {
        formatter: function () {
          return `<b>${this.value}</b>`;
        },
      },
    },
    tooltip: {
      formatter: function () {
        return commonToolTipFormatter({ point: this.point, series: this.series, x: this.x, y: this.y, symbol });
      },
    },
    legend: {
      backgroundColor: "#FFFFFF",
      verticalAlign: "top",
      y: 20,
      shadow: true,
    },
    plotOptions: {
      series: {
        step: true,
      },
    },
    credits: {
      enabled: false,
    },
  };

  return chartOptions;
};

export const hostsDifferenceProgressionChartOptions = (props) => {
  const { translations, currency, data, savingOrProfit } = props;

  const { total_difference_progression_chart, difference_value_with_currency } = translations;

  const totalDifferenceProgressionChart = total_difference_progression_chart.replace("%{difference}", savingOrProfit());
  const symbol = currency?.symbol ?? "-";

  const savingValue = difference_value_with_currency
    .replace("%{difference}", savingOrProfit())
    .replace("%{symbol}", symbol);

  const chartOptions = {
    chart: {
      renderTo: "chart",
      zoomType: "xy",
      marginBottom: 100,
      marginRight: 75,
      height: 450,
      type: "line",
    },
    colors,
    title: {
      text: `<b>${totalDifferenceProgressionChart}</b>`,
    },
    xAxis: [
      {
        type: "datetime",
        labels: {
          formatter: function () {
            return `<b>${moment(this.value).format("LT")}</b>`;
          },
        },
      },
      {
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: "transparent",
        gridLineColor: "transparent",
        labels: { enabled: false },
        minorTickLength: 0,
        tickLength: 0,
      },
    ],
    series: [
      {
        name: savingOrProfit(),
        data: data.map(({ y, x, name }) => {
          return {
            name,
            x: new Date(x).valueOf(),
            y,
          };
        }),
      },
    ],
    yAxis: {
      title: {
        text: `<b>${savingValue}</b>`,
      },
      lineWidth: 1,
      labels: {
        formatter: function () {
          return `<b>${this.value}</b>`;
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.name}</b>
        <br>${moment(this.x).format("DD MMM , h:mm:ss A")}
        <br><b>${symbol}${this.y}</b>
        `;
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        step: true,
      },
    },
    credits: {
      enabled: false,
    },
  };

  return chartOptions;
};
