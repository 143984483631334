import React from "react";
import Tooltip from "../../common/Tooltip";

const ContractTooltip = (props) => {
  const { translations, titleText } = props;
  return(
    <div className="title">
      {translations[titleText]}
      <ShowTooltip
       {...props}
      />
    </div>
  );
};

export const ShowTooltip = (props) => {
  const {translations, titleText} = props;

  return(
    <div className="inline-block p-l10">
      <Tooltip {...translations.help_texts[titleText]} />
    </div>
  );
};

export default ContractTooltip;
