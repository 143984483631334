import React from "react";
import { connect } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Modal from "simple-react-modal";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import momentTimeZone from "moment-timezone";

import { getCalendarEvents } from "../../actions/dashboardActions";
import customToolbar from "./CalendarToolbar";
import { getLocale } from "../../common";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../styles/calendar.css";

const localizer = momentLocalizer(moment);
const convertDateToTimeZoneDate = (dateTime, timeZoneName) => {
  const momentDate = momentTimeZone.tz(dateTime, timeZoneName);
  return new Date(momentDate.year(), momentDate.month(), momentDate.date(), momentDate.hour(), momentDate.minute(), 0);
};
export class CalendarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarEvents: [],
      showModal: false,
      event: {},
      filteredUsers: [],
      allEvents: [],
      showLoader: true,
      currentDate: convertDateToTimeZoneDate(new Date(), this.props.timeZone),
      disableButton: false,
      eventsForView: [],
    };
  }

  UNSAFE_componentWillMount() {
    const startDate = this.getFormattedDateObject("YYYY-MM-DD", "month", "start");
    const endDate = this.getFormattedDateObject("YYYY-MM-DD", "month", "end");
    this.props.getCalendarEvents(startDate, endDate, this.props.userRole);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let events = newProps.data.events;
    const users = [];
    if (this.props.userRole.toLowerCase() === "participant") {
      events = events.filter((event) => {
        if (event.accepted_event && event.last_deadline) {
          if (users.indexOf(event.user.email) === -1) {
            users.push(event.user.email);
          }
          return this.getFormattedEvent(event);
        }
      });
    } else {
      events = events.filter((event) => {
        if (event.event_state !== "draft" && event.last_deadline) {
          if (users.indexOf(event.user.email) === -1) {
            users.push(event.user.email);
          }
          return this.getFormattedEvent(event);
        }
      });
    }
    this.setState({
      calendarEvents: events,
      allEvents: events,
      showLoader: false,
      filteredUsers: users,
      eventsForView: events,
    });
  }

  eventStyleGetter(event, start, end, isSelected) {
    return {
      className: event.event_state === "closed" ? "calendar-close-event" : "calendar-current-event",
    };
  }

  getFormattedEvent(event) {
    event.start = convertDateToTimeZoneDate(new Date(event.went_live_first_at), this.props.timeZone);
    event.end = convertDateToTimeZoneDate(new Date(event.last_deadline), this.props.timeZone);
    event.title = event.name;
    return event;
  }

  getFormattedDateObject(formatString, view, viewRange, date = new Date()) {
    if (view === "month") {
      if (viewRange === "start") {
        return moment(moment(date).startOf(view)).subtract(5, "days").format(formatString);
      } else {
        return moment(moment(date).endOf(view)).add(5, "days").format(formatString);
      }
    } else {
      if (viewRange === "start") {
        return moment(date).startOf(view).format(formatString);
      } else {
        return moment(date).endOf(view).format(formatString);
      }
    }
  }

  async getEventsByDateRange(date, view) {
    const currentDate = this.state.currentDate;
    await this.setState({
      showLoader: true,
      calendarEvents: [],
      currentDate: date,
      disableButton: true,
    });
    if (moment(date).endOf(view).format("YYYY-MM") !== moment(currentDate).format("YYYY-MM") || view === "agenda") {
      const startDate = this.getFormattedDateObject("YYYY-MM-DD", "month", "start", date);
      const endDate = this.getFormattedDateObject("YYYY-MM-DD", "month", "end", date);
      await this.props.getCalendarEvents(startDate, endDate, this.props.userRole);
    }

    await this.handleViewChange(view);
    this.setState({
      showLoader: false,
      disableButton: false,
    });
  }

  closeModal() {
    document.body.removeAttribute("style");
    this.setState({ showModal: false });
  }

  showEventDetails(event) {
    document.body.style.overflow = "hidden";
    this.setState({
      showModal: true,
      event: event,
    });
  }

  filterByUserEmail(e, userEmail) {
    let users = this.state.filteredUsers;
    let calendarEvents = [];
    if (e.target.checked) {
      users.push(userEmail);
    } else {
      users = users.filter((user) => user != userEmail);
    }
    if (users.length > 0) {
      calendarEvents = this.state.allEvents.filter((event) => users.indexOf(event.user.email) !== -1);
    } else {
      calendarEvents = [];
    }
    this.setState({
      filteredUsers: users,
      calendarEvents,
    });
  }

  async handleViewChange(view) {
    this.setState({
      showLoader: true,
    });
    const date = this.state.currentDate;
    const users = [];
    let startDate;
    let endDate;
    if (view === "month") {
      startDate = this.getFormattedDateObject("YYYY-MM-DD", view, "start", date);
      endDate = this.getFormattedDateObject("YYYY-MM-DD", view, "end", date);
    } else {
      startDate = moment(date).startOf(view).toDate();
      endDate = view === "agenda" ? moment().add(1, "month").toDate() : moment(date).endOf(view).toDate();
    }
    const events = await this.state.allEvents.filter((event) => {
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
      if (
        (eventStart.isBefore(startDate) && eventEnd.isAfter(endDate)) ||
        eventStart.isBetween(startDate, endDate) ||
        eventEnd.isBetween(startDate, endDate) ||
        eventStart.isSame(startDate) ||
        eventStart.isSame(endDate) ||
        eventEnd.isSame(startDate) ||
        eventEnd.isSame(endDate)
      ) {
        if (users.indexOf(event.user.email) === -1) {
          users.push(event.user.email);
        }
        return true;
      }
    });
    this.setState({
      calendarEvents: events,
      showLoader: false,
      filteredUsers: users,
      eventsForView: events,
    });
  }

  getQuestionnairesDeadlines(questionnaires) {
    const refThis = this;
    return questionnaires.map(function (questionnaire, index) {
      return (
        <tr key={index} className="active" data-label={questionnaire.title}>
          <td>
            {questionnaire.title}{" "}
            {moment(questionnaire.deadline).isBefore(new Date())
              ? refThis.props.translations.finished
              : refThis.props.translations.deadline}
          </td>
          <td>{momentTimeZone(questionnaire.deadline).tz(refThis.props.timeZone).format("MMMM Do YYYY, HH:mm z")}</td>
        </tr>
      );
    });
  }

  render() {
    const { translations, publiclyVisiblePrefixEvent } = this.props;
    const userRole = this.props.userRole.toLowerCase();
    let formats = {
      timeGutterFormat: "HH:mm",
      eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
        localizer.format(start, "HH:mm", culture) + " - " + localizer.format(end, "HH:mm", culture),
      agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
        localizer.format(start, "HH:mm", culture) + " - " + localizer.format(end, "HH:mm", culture),
      agendaHeaderFormat: ({ start, end }, culture, localizer) => {
        return (
          localizer.format(start, "MMMM D, YYYY", culture) + " - " + localizer.format(end, "MMMM D, YYYY", culture)
        );
      },
      agendaTimeFormat: "HH:mm",
      eventTimeRangeEndFormat: ({ end }, culture, localizer) => localizer.format(end, "HH:mm", culture),
    };
    return (
      <React.Fragment>
        <div>
          {this.props.className !== "dashboard-view" && (
            <div className="col-sm-2 col-md-5 col-lg-3 filter-box">
              <div className="panel-group">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">{translations.filter_by_user}</h4>
                  </div>
                  <div>
                    <div className="panel-body">
                      {!isEmpty(this.state.eventsForView) &&
                        uniqBy(this.state.eventsForView, (element) => element.user.email).map((event, key) => {
                          return (
                            <div key={event.id} className="form-check-inline">
                              <input
                                id={`checkbox_${event.id}`}
                                className="form-check-input"
                                type="checkbox"
                                value={event.user.email}
                                checked={this.state.filteredUsers.indexOf(event.user.email) !== -1}
                                onChange={(e) => this.filterByUserEmail(e, event.user.email)}
                              />
                              <label
                                htmlFor={`checkbox_${event.id}`}
                                className="form-check-label"
                                title={event.user.email}
                              >
                                <span />
                                {event.user.email.length > 27 && screen.width > 991
                                  ? `${event.user.email.substring(0, 24)}. . .`
                                  : event.user.email}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={
              this.props.className !== "dashboard-view"
                ? `${this.props.className} col-sm-10 col-md-7 col-lg-9`
                : this.props.className
            }
          >
            <div className="event-loader">{this.state.showLoader && <i className="fa fa-spinner fa-spin" />}</div>
            <Calendar
              localizer={localizer}
              messages={{
                date: translations.date,
                time: translations.score_time,
                event: translations.event,
                allDay: translations.allDay,
                yesterday: translations.yesterday,
                tomorrow: translations.tomorrow,
                showMore: (total) => `+${total} ${translations.more}`,
              }}
              culture={getLocale()}
              popup
              events={this.state.calendarEvents}
              defaultDate={convertDateToTimeZoneDate(new Date(), this.props.timeZone)}
              eventPropGetter={this.eventStyleGetter}
              onSelectEvent={(event) => this.showEventDetails(event)}
              onNavigate={(date, view) => {
                this.getEventsByDateRange(date, view);
              }}
              onView={(view) => {
                this.handleViewChange(view);
              }}
              formats={formats}
              components={{
                timeGutterHeader: () => <span>{momentTimeZone().tz(this.props.timeZone).format("z")}</span>,
                toolbar: (toolbar) =>
                  customToolbar(
                    toolbar,
                    this.state.disableButton,
                    this.props.className === "full-view",
                    this.props.translations
                  ),
              }}
            />
          </div>
          <div>
            <Modal
              containerClassName="event-details-modal"
              closeOnOuterClick={true}
              show={this.state.showModal}
              onClose={this.closeModal.bind(this)}
              style={{ background: "#00000040" }}
            >
              <div className="vertical-top">
                <div className="vertical-align-center">
                  <div className="modal-content clearfix">
                    <a onClick={() => this.closeModal()} className="close-icon">
                      <i className="ion-close" aria-hidden="true" />
                    </a>
                    <div className="modal-header">
                      <h4 className="modal-title" data-label="event_title">
                        {this.state.event.name}
                      </h4>
                    </div>
                    <div className="modal-body">
                      <div className="calendar-event-table">
                        <table className="table table-bordered">
                          {!isEmpty(this.state.event) && (
                            <tbody>
                              {userRole !== "participant" && publiclyVisiblePrefixEvent && (
                                <tr className="active" data-label="event_id">
                                  <td>{translations.event_id}</td>
                                  <td>{this.state.event.id}</td>
                                </tr>
                              )}
                              <tr className="active" data-label="host_name">
                                <td>{translations.event_host}</td>
                                <td>{this.state.event.user.name}</td>
                              </tr>
                              <tr className="active" data-label="host_email">
                                <td>{translations.email}</td>
                                <td>{this.state.event.user.email}</td>
                              </tr>
                              <tr className="active" data-label="company_name">
                                <td>{translations.company}</td>
                                <td>{this.state.event.user.user_company_name}</td>
                              </tr>
                              {userRole !== "participant" && this.state.event.went_live_first_at && (
                                <tr className="active" data-label="went_live_first_at">
                                  <td>{translations.went_live_on}</td>
                                  <td>
                                    {momentTimeZone(this.state.event.went_live_first_at)
                                      .tz(this.props.timeZone)
                                      .format("MMMM Do YYYY, HH:mm z")}
                                  </td>
                                </tr>
                              )}
                              {!isEmpty(this.state.event.questionnaire_deadlines.questionnaires) &&
                                this.getQuestionnairesDeadlines(
                                  this.state.event.questionnaire_deadlines.questionnaires
                                )}
                              {this.state.event.bid_deadline && !this.state.event.pre_qualification_required && (
                                <tr className="active" data-label="rfq_deadline">
                                  <td>
                                    {moment(this.state.event.bid_deadline).isBefore(new Date())
                                      ? translations.rfq_finished
                                      : translations.rfq_deadline}
                                  </td>
                                  <td>
                                    {momentTimeZone(this.state.event.bid_deadline)
                                      .tz(this.props.timeZone)
                                      .format("MMMM Do YYYY, HH:mm z")}
                                  </td>
                                </tr>
                              )}
                              {this.state.event.end_at && !this.state.event.pre_qualification_required && (
                                <tr className="active" data-label="auction_deadline">
                                  <td>
                                    {moment(this.state.event.end_at).isBefore(new Date())
                                      ? translations.auction_end
                                      : translations.auction + " " + translations.deadline}
                                  </td>
                                  <td>
                                    {momentTimeZone(this.state.event.end_at)
                                      .tz(this.props.timeZone)
                                      .format("MMMM Do YYYY, HH:mm z")}
                                  </td>
                                </tr>
                              )}
                              <tr className="active">
                                <td>{translations.lots}</td>
                                <td>{this.state.event.lots_size}</td>
                              </tr>
                              {userRole !== "participant" && (
                                <tr className="active" data-label="bids">
                                  <td>{translations.bids}</td>
                                  <td>{this.state.event.total_number_bidding}</td>
                                </tr>
                              )}
                              {userRole !== "participant" && (
                                <tr className="active" data-label="total_participants">
                                  <td>{translations.tab_participants_html}</td>
                                  <td>{this.state.event.total_number_of_participants}</td>
                                </tr>
                              )}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className="modal-footer">
                      {!(
                        this.props.userRole.toLowerCase() === "participant" &&
                        this.state.event.current_state === "closed"
                      ) && (
                        <a
                          href={
                            this.state.event.quick_quotes && userRole !== "participant"
                              ? `/quick_quotes/quote/${this.state.event.id}`
                              : `/events/${this.state.event.id}`
                          }
                          className="btn btn-secondary modal-btn-close"
                          data-label="go_to_event"
                        >
                          {translations.go_to_event}
                        </a>
                      )}
                      <button
                        type="button"
                        className="btn btn-secondary modal-btn-close"
                        onClick={() => this.closeModal()}
                      >
                        {translations.ok}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.dashboardReducers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCalendarEvents: (startDate, endDate, userRole) => dispatch(getCalendarEvents(startDate, endDate, userRole)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);
