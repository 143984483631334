import React from "react";
import { isEmpty } from "lodash";
import { MessageHeader } from "./OutboxList";
import PropTypes from "prop-types";
import { MessagePropType, RecipientPropType } from "./prop-types";
import RenderHTML from "@/components/common/RenderHTML";

const InboxListPropTypes = {
  message: PropTypes.shape(MessagePropType),

  /**
   * Id of the user
   * @type {number} userId
   */
  userId: PropTypes.number,

  /**
   * The object defining all of the display info for the component
   * @type {object}
   */
  translations: PropTypes.object.isRequired,
};

const InboxMessageHeaderPropTypes = {
  ...InboxListPropTypes,
  loggedUserRecipient: PropTypes.shape(RecipientPropType),
};

const InboxMessageContentPropTypes = {
  ...InboxMessageHeaderPropTypes,

  /**
   * function to send reply
   * @type {() => void}
   */
  showMessageModal: PropTypes.func,
};

const MarkAsReadButtonPropTypes = {
  ...InboxMessageContentPropTypes,

  /**
   * Id of the quote
   * @type {number} quoteId
   */
  quoteId: PropTypes.number,

  /**
   * function to mark the message as read
   * @type {() => void}
   */
  markAsRead: PropTypes.func,
};

const SendReplyButtonPropTypes = {
  ...InboxMessageContentPropTypes,

  /**
   * Id of the quote
   * @type {number} quoteId
   */
  quoteId: PropTypes.number,

  /**
   * function to send reply
   * @type {() => void}
   */
  showMessageModal: PropTypes.func,
};

/**
 * @type {React.FC<typeof InboxListPropTypes>}
 */
const InboxList = (props) => {
  const { message, userId, translations } = props;
  const loggedUserRecipient = message.recipients?.find(({ user_id }) => user_id === userId) || {};
  const messageBoxId = `message_${message.id}`;
  return (
    <div className="message-box m-b10 clearfix" id={messageBoxId} data-testid={messageBoxId}>
      <div className="message-section inbox-ms">
        <InboxMessageContent {...props} loggedUserRecipient={loggedUserRecipient} />
        <InboxMessageHeader
          message={message}
          userId={userId}
          translations={translations}
          loggedUserRecipient={loggedUserRecipient}
        />
      </div>
      <span className="small-button-box green-ouline">{translations.summary_status.message.inbox}</span>
    </div>
  );
};
InboxList.propTypes = InboxListPropTypes;

/**
 * @type {React.FC<typeof InboxMessageHeaderPropTypes>}
 */
const InboxMessageHeader = ({ message, userId, loggedUserRecipient, translations }) => {
  return (
    <MessageHeader message={message} translations={translations} kindOfMessage="inbox">
      <span
        className="small-gray-box"
        data-toggle="tooltip"
        title={`Company: ${message.user.user_company_name}; E-mail: ${message.user.email}`}
      >
        {" "}
        {message.user.user_display_name ? message.user.user_display_name : message.user.email}
      </span>
      <span className="message-text-red m-r5">({translations.summary_status.message.external})</span>
      {!isEmpty(loggedUserRecipient) && !loggedUserRecipient.message_read && loggedUserRecipient.user_id === userId && (
        <i className="fa fa-circle color-purpel" aria-hidden="true" />
      )}
    </MessageHeader>
  );
};
InboxMessageHeader.propTypes = InboxMessageHeaderPropTypes;

/**
 * @type {React.FC<typeof InboxMessageContentPropTypes>}
 */
export const InboxMessageContent = (props) => {
  const { message, userId, showMessageModal, loggedUserRecipient, quoteDetail, translations } = props;
  return (
    <div className="message-content">
      <RenderHTML content={message.message} />
      <br />
      {!isEmpty(loggedUserRecipient) && !loggedUserRecipient.message_read && loggedUserRecipient.user_id === userId && (
        <MarkAsReadButton {...props} />
      )}
      {((!isEmpty(loggedUserRecipient) && loggedUserRecipient.user_id === userId) ||
        (quoteDetail.data.current_user_has_edit_permission &&
          message.system_message_type !== "host" &&
          message.user.user_roles.indexOf("Participant") > -1)) && (
        <SendReplyButton showMessageModal={showMessageModal} message={message} translations={translations} />
      )}
    </div>
  );
};
InboxMessageContent.propTypes = InboxMessageContentPropTypes;

/**
 * @type {React.FC<typeof MarkAsReadButtonPropTypes>}
 */
export const MarkAsReadButton = ({ markAsRead, quoteId, message, translations }) => {
  return (
    <button
      type="button"
      className="btn btn-primary reply-button m-t5 m-r10"
      onClick={() => markAsRead(quoteId, message.id, { message: { read: true } })}
    >
      {translations.summary_status.message.mark_as_read}
    </button>
  );
};
MarkAsReadButton.propTypes = MarkAsReadButtonPropTypes;

/**
 * @type {React.FC<typeof SendReplyButtonPropTypes>}
 */
export const SendReplyButton = ({ showMessageModal, message, translations }) => {
  return (
    <button
      type="button"
      className="btn btn-primary reply-button m-t5"
      onClick={() => showMessageModal(false, message.id, message.user.id)}
    >
      {translations.summary_status.message.send_reply}
    </button>
  );
};
SendReplyButton.propTypes = SendReplyButtonPropTypes;

export default InboxList;
