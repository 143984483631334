import React from "react";
import { isLotTotalPresent, isLiTotalPresent } from "../../../common";
import HostProgressionChartModal from "../../../monitor/HostProgressionChartModal";

export default function ProgressionCharts(props) {
  const { openCharts, LBids, lot, auctionTab, lineItem } = props;
  const activeBids = LBids?.filter((b) => !b.decline_bid);
  return auctionTab ? (
    <div className="mycell graph_cell small-cell">
      <div className="solid-box complex-lot">
        {(isLotTotalPresent(lot) || isLiTotalPresent(lineItem)) && activeBids && !!activeBids.length && (
          <>
            <ChartIcon openCharts={openCharts} />
            <HostProgressionChartModal {...props} setIsOpen={openCharts} type={"hostMonitor"} />
          </>
        )}
      </div>
    </div>
  ) : null;
}

export const ChartIcon = ({ openCharts }) => {
  return (
    <a className="cursor-pointer" onClick={() => openCharts(true)}>
      <i className="fa fa-bar-chart"></i>
    </a>
  );
};
