import React from "react";
import DateField from "./date-field";
import TextField from "./text-field";
import NumericField from "./numeric-field";
import CheckboxField from "./checkbox-field";
import MultipleChoice from "./multiple-choice";
import PickOneFromList from "./pick-one-from-list";
import type { CustomFieldProps } from "./custom-field-list";

type RenderFieldsProps = Pick<CustomFieldProps, "showPage" | "readOnly" | "customField">;

const CUSTOM_FIELD_FORM_COMPONENTS = {
  date: DateField,
  text: TextField,
  numeric: NumericField,
  checkbox: CheckboxField,
  multiple_choice: MultipleChoice,
  pick_one_from_list: PickOneFromList,
};

const RenderFields: React.FC<RenderFieldsProps> = (props) => {
  const { showPage, customField, readOnly } = props;
  if (showPage) {
    return customField.custom_column_values[0].name.toString();
  }

  const Component = CUSTOM_FIELD_FORM_COMPONENTS[customField.column_type as keyof typeof CUSTOM_FIELD_FORM_COMPONENTS];
  if (!Component) {
    return null;
  }

  return <Component field={customField} readOnly={readOnly} />;
};

export default RenderFields;
