import * as actionTypes from "../actions/actionsTypes";

const _initialState = () => ({
  activities: [],
  child_activities: [],
  loaded: false,
  translations: {},
  activitiesCount: {
    open_activities_count: 0,
    overdue_activities_count: 0,
  },
  contact_id: null,
  supplier_id: null,
  calledFromIndex: null,
  called_from: null,
  non_persistent_state: {
    show_discard_model: false,
    activity_draft_id: 0,
    refresh: false,
    reloadCurrentPage: 0,
    activeActivityTab: "general",
  },
});

const ActivityReducer = (state = _initialState(), action) => {
  switch (action.type) {
    case actionTypes.SET_INITIAL_ACTIVITY_STATE:
      return { ...state, ...action.payload };

    case actionTypes.UPDATE_ACTIVITY:
      return { ..._reduceUpdatedActivities(state, action) };

    case actionTypes.UPDATE_ACTIVITY_COUNT:
      return { ..._reduceActivitiesCount(state, action) };

    case actionTypes.TOGGLE_DISCARD_ACTIVITY_MODAL:
      return { ..._reduceDiscardActivityModal(state, action) };

    case actionTypes.SET_MODAL_REFRESH:
      state.non_persistent_state.refresh = action.payload.refresh;
      return { ...state };

    case actionTypes.RELOAD_ACTIVITY_LIST:
      return { ..._reloadList(state, action) };

    case actionTypes.SWITCH_ACTIVITY_TAB:
      return { ..._switchTab(state, action) };

    default:
      return state;
  }
};

const _switchTab = (state, action) => {
  state.non_persistent_state = {
    ...state.non_persistent_state,
    activeActivityTab: action.payload.tab,
  };
  return { ...state };
};

const _reloadList = (state, action) => {
  state.non_persistent_state = {
    ...state.non_persistent_state,
    reloadCurrentPage: action.payload,
  };
  return { ...state };
};

const _reduceDiscardActivityModal = (state, action) => {
  let relay = !state.non_persistent_state.show_discard_model;
  if (action.payload.show) {
    relay = true;
  }

  if (action.payload.hide) {
    relay = false;
  }

  if (action.payload.activity_draft_id) {
    state.non_persistent_state.activity_draft_id = action.payload.activity_draft_id;
  } else {
    state.non_persistent_state.activity_draft_id = null;
  }

  state.non_persistent_state.show_discard_model = relay;
  return state;
};

const _reduceUpdatedActivities = (state, action) => {
  state.activities = state.activities.map((activity) => {
    if (activity.id === action.payload.id) {
      return action.payload;
    }
    return activity;
  });

  return state;
};

const _reduceActivitiesCount = (state, action) => {
  const activitiesCount = {};

  if (action.payload.open_activities_count) {
    activitiesCount.open_activities_count = action.payload.open_activities_count;
  }

  if (action.payload.overdue_activities_count) {
    activitiesCount.overdue_activities_count = action.payload.overdue_activities_count;
  }

  return {
    ...state,
    activitiesCount: {
      ...activitiesCount,
    },
  };
};

export default ActivityReducer;
