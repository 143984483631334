import { Input } from "@/cl/input";
import { Select, EnhancedSelect } from "@/cl/select";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useDebouncedSync } from "@/hooks/use-debounced-sync";
import { NodeIssue, NodeIssueTranslation } from "@/lib/rules/validate";
import {
  comparisonChanged,
  multiCountChanged,
  multiKindChanged,
  multiValuesChanged,
  selectIssueById,
} from "@/slices/rules";
import type { MultiChoiceField } from "@/slices/rules/fields";
import { Comparison, type Leaf, type NormalizedNode, type TextParameter } from "@/slices/rules/types";
import { inEnum } from "@/utils/inEnum";
import React, { useCallback } from "react";
import styles from "../node.module.scss";
import { t } from "@/i18n";
import { CollapsedValue, InputContainer } from "../leaf-node";
import { useParentCollapsed } from "../collapse-context";

const toOption = (option: string) => ({ value: option, label: option });

function useInputs(node: NormalizedNode<Leaf<TextParameter>>) {
  const dispatch = useAppDispatch();
  const [comparison, handleComparisonChange] = useDebouncedSync(
    node.state.meta?.multi_kind ?? node.state.comparison,
    useCallback(
      (comparison) => {
        if (inEnum(Comparison, comparison)) {
          dispatch(comparisonChanged({ id: node.uuid, comparison }));
        } else if (comparison === "all" || comparison === "any" || comparison === "none") {
          dispatch(multiKindChanged({ id: node.uuid, kind: comparison }));
        } else {
          throw new Error(`Invalid comparison: ${comparison}`);
        }
      },
      [dispatch, node.uuid]
    )
  );
  const [count, handleCountChange] = useDebouncedSync(
    node.state.leaf_parameter.count,
    useCallback((count) => dispatch(multiCountChanged({ id: node.uuid, count })), [dispatch, node.uuid])
  );
  const [values, handleValuesChange] = useDebouncedSync(
    node.state.leaf_parameter.parameters,
    useCallback((values) => dispatch(multiValuesChanged({ id: node.uuid, values })), [dispatch, node.uuid])
  );
  return { comparison, count, values, handleComparisonChange, handleCountChange, handleValuesChange };
}

export default function MultiChoiceParameter({
  node,
  field,
}: {
  node: NormalizedNode<Leaf<TextParameter>>;
  field: MultiChoiceField;
}) {
  const { comparison, count, values, handleComparisonChange, handleCountChange, handleValuesChange } = useInputs(node);
  const issue = useAppSelector((state) => selectIssueById(state, node.uuid));
  const parentCollapsed = useParentCollapsed();
  const collapsed = !issue && parentCollapsed;
  const collapsedMulti: React.ReactNode =
    collapsed &&
    (values.length ? (
      values.map((value) => <CollapsedValue key={value}>{value}</CollapsedValue>)
    ) : (
      <CollapsedValue>{t("workflow_rules.select_values")}</CollapsedValue>
    ));
  if (collapsed) {
    return (
      <>
        <span className={styles.word}>{t("workflow_rules.joiner.has")}</span>
        <CollapsedValue>{t("workflow_rules.comparison." + comparison.toLowerCase())}</CollapsedValue>
        {inEnum(Comparison, comparison) && <CollapsedValue>{count}</CollapsedValue>}
        <span className={styles.word}>{t("workflow_rules.joiner.of")}</span>
        {collapsedMulti}
      </>
    );
  }
  return (
    <>
      <span className={styles.word}>{t("workflow_rules.joiner.has")}</span>
      <InputContainer>
        <Select
          name={`comparison-${node.uuid}`}
          value={comparison}
          onChange={(e) => handleComparisonChange(e.target.value)}
          aria-label={t("workflow_rules.select_comparison")}
          options={["any", "all", "none"].concat(Object.values(Comparison)).map((comparison) => ({
            value: comparison,
            label: t("workflow_rules.comparison." + comparison.toLowerCase()),
          }))}
        />
      </InputContainer>
      {inEnum(Comparison, comparison) && (
        <InputContainer>
          <Input
            name={`count-${node.uuid}`}
            type="number"
            value={count}
            onChange={(e) => handleCountChange(e.target.valueAsNumber ?? Number(e.target.value))}
            aria-label={t("workflow_rules.enter_count")}
          />
        </InputContainer>
      )}
      <span className={styles.word}>{t("workflow_rules.joiner.of")}</span>
      <InputContainer>
        <EnhancedSelect
          name={`values-${node.uuid}`}
          value={values.map(toOption)}
          isMulti
          options={field.options.map(toOption)}
          onChange={(values) => {
            handleValuesChange(values.map((opt) => opt.value));
          }}
          aria-label={t("workflow_rules.select_values")}
          error={
            issue === NodeIssue.InvalidOption ? t("workflow_rules.issues." + NodeIssueTranslation[issue]) : undefined
          }
          placeholder={t("workflow_rules.select_values")}
        />
      </InputContainer>
    </>
  );
}
