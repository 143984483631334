import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Select2 from "react-select2-wrapper";

import TreeNode from "./TreeNodeForParentChild";
import {
  addChildContract,
  addParentContract,
  removeChildContract,
} from "../../../actions/contract/contractCreateEditActions";
import { Confirm } from "../../common/Confirm";
import ContractTooltip from "./ContractTooltip";
import { commonProps } from "./selectors";

class ParentChildTreeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childs: props.parentChildRelation.childs,
      parents: props.parentChildRelation.parents,
      relation: props.parentChildRelation.relation,
      selectedParent: props.parentChildRelation.selected_parent,
      childIdToRemove: "",
    };
  }

  async handleAddChild(childId) {
    const result = await this.props.addChildContract(this.props.contractId, childId);
    if (result) {
      this.setState({
        relation: result.relation,
      });
    }
  }

  async handleAddParent(e) {
    const parentId = e.target.value;
    if (parentId) {
      const result = await this.props.addParentContract(this.props.contractId, parentId);
      this.setState({
        selectedParent: result.selected_parent,
      });
    }
  }

  createDataPairForSelect(data) {
    return data.map((item) => ({ text: item.title, id: item.id }));
  }

  updateChildIdToRemove(childId) {
    this.setState({
      childIdToRemove: childId,
    });
  }

  async handleRemoveChild(childId) {
    const result = await this.props.removeChildContract(this.props.contractId, childId);
    if (result) {
      this.setState({
        relation: result.relation,
      });
    }
  }

  render() {
    const { relation, childs, parents, selectedParent } = this.state;
    const { translations, showPage } = this.props;
    return (
      <div className="col-md-12 col-sm-12">
        <div className="form-section-block">
          <ContractTooltip translations={translations} titleText={"hierarchy"} />
          <div className="col-12 form-group">
            {translations.parent}:
            {selectedParent && (
              <a href={`/contracts/${selectedParent.id}/edit`}>{selectedParent ? selectedParent.title : ""}</a>
            )}
            <br />
            {!showPage && (
              <Select2
                className="form-control col-md-4"
                multiple={false}
                data={this.createDataPairForSelect(parents)}
                options={{
                  placeholder: translations.select,
                }}
                onSelect={(e) => this.handleAddParent(e)}
              />
            )}
            <br />
            <br />
            <div className="tree category-tree">
              {translations.children}:
              {!isEmpty(relation) && (
                <ul>
                  <TreeNode
                    node={relation}
                    childIteration={0}
                    childs={childs}
                    addChildContract={(childId) => this.handleAddChild(childId)}
                    updateChildIdToRemove={(childId) => this.updateChildIdToRemove(childId)}
                    translations={translations}
                    showPage={showPage}
                  />
                </ul>
              )}
            </div>
          </div>
          <div className="col-12 form-group sd-hidden" />
        </div>
        <Confirm
          translations={translations}
          message={translations.are_you_sure}
          htmlId="parentChildRemoveConfirmModal"
          onOkayButtonClick={() => this.handleRemoveChild(this.state.childIdToRemove)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ createEditReducers }) => ({
  ...commonProps(createEditReducers),
  parentChildRelation: createEditReducers.parentChildRelation,
});

const mapDispatchToProps = (dispatch) => ({
  addChildContract: (contractId, childId) => dispatch(addChildContract(contractId, childId)),
  addParentContract: (contractId, parentId) => dispatch(addParentContract(contractId, parentId)),
  removeChildContract: (contractId, childId) => dispatch(removeChildContract(contractId, childId)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ParentChildTreeForm);
