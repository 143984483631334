import React from "react";

import { companyNameOrEmail } from "../../../../common";
import { getTdValue, getChildElement } from "./services";
import { useSelector } from "react-redux";

const LotMatrixDetailsTable = (props) => {
  const { translations, user } = props;
  const bidLineItemComponents = useSelector(({ lotReducers }) => lotReducers.bid_line_item_components);
  return (
    <table
      className="table table-striped custom-responsive-table gray-table-bg"
      width="100%"
      border="0"
      cellSpacing="0"
      cellPadding="0"
    >
      <tbody>
        {getChildElement(props)}
        <tr className="lot-info">
          <td>{translations.participant} </td>
          <td>
            <strong>{companyNameOrEmail(user)}</strong>
          </td>
        </tr>
        <tr className="lot-info">
          <td>{translations.participant_bid}</td>
          <td>
            <strong>{getTdValue({ ...props, bidLineItemComponents })}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default LotMatrixDetailsTable;
