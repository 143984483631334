import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const ProviderPropTypes = {
  slice: PropTypes.any,
  children: PropTypes.element,
};

const SliceContext = createContext({});

/**
 * @type {React.FC<PropTypes.InferProps<typeof ProviderPropTypes>}
 */
const SliceProvider = ({ slice, children }) => <SliceContext.Provider value={slice}>{children}</SliceContext.Provider>;
SliceProvider.propTypes = ProviderPropTypes;

const useSliceActions = () => useContext(SliceContext).actions;

const useSliceSelector = () => {
  const { name } = useContext(SliceContext);
  return useSelector((state) => {
    return state[name];
  });
};

export default SliceProvider;

export { useSliceActions, useSliceSelector };
