// #ENTRYPOINT
import React from "react";
import ContractListingMain from "@/components/contract/contractListing/ContractListingMain";
import Wrapper from "@/components/Wrapper";
import { getStore } from "@/store";
import ContractControls from "@/components/contract/new/listings/controls";
import ContractTable from "@/components/contract/new/listings/table";
import Pagination from "@/components/contract/new/listings/pagination";
import features from "@/utils/features";

export default function Page(props) {
  return (
    <Wrapper store={getStore()}>
      {features.enabled("contract_listing") ? (
        <div className="main-section index-list">
          <div className="container-fluid">
            <ContractControls />
            <ContractTable />
            <Pagination />
          </div>
        </div>
      ) : (
        <ContractListingMain {...props} />
      )}
    </Wrapper>
  );
}
