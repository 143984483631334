import React from "react";
import Tooltip from "../../../../../common/Tooltip";

const TableHeaderWithTooltip = (props) => {
  const { translations, title, tooltip, classNames } = props;
  const { infos } = translations;
  return (
    <th className={classNames}>
      {translations[title]}
      <Tooltip title={infos[tooltip].title} body={infos[tooltip].body} />
    </th>
  );
};

TableHeaderWithTooltip.defaultProps = {
  classNames: "",
};

export default TableHeaderWithTooltip;
