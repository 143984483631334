// @ts-check
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "results",
    "buttonsContainer",
    "checkbox",
    "hiddenInput",
    "submitButton",
    "searchInput",
    "supplierTypeInput",
    "email",
    "searchErrorMsg",
    "participantForm"
  ];

  selectedEmails = new Set();

  connect() {
    this.hideButtonsContainer();

    // Use event delegation to handle checkbox changes
    this.delegateCheckboxChange = this.delegateCheckboxChange.bind(this);
    this.element.addEventListener("change", this.delegateCheckboxChange);

    // Initialize the search button state
    this.toggleSearchButton();
  }

  disconnect() {
    this.element.removeEventListener("change", this.delegateCheckboxChange);
  }

  resultsRendered = () => {
    this.showButtonsContainer();
  };

  #hasProductKeyword = () => this.searchInputTarget.value.trim() !== "";

  toggleSearchButton() {
    const hasProductKeyword = this.#hasProductKeyword();

    this.supplierTypeInputTarget.disabled = !hasProductKeyword;
  }

  delegateCheckboxChange(event) {
    if (event.target.matches(".participant-checkbox")) {
      this.handleCheckboxChange(event);
    }
  }

  handleCheckboxChange(event) {
    const checkbox = event.target;
    if (!checkbox) return;

    this.toggleRemainingCheckboxes(checkbox);
    this.updateEmailTextStyle(checkbox, checkbox.checked);
    this.updateSelectedEmails();
  }

  /**
   * Disables other checkboxes for the same company when one is checked.
   * If a checkbox is checked, all other checkboxes with the same company index are disabled.
   * If the checkbox is unchecked, the other checkboxes are re-enabled.
   */
  toggleRemainingCheckboxes(checkbox) {
    const companyIndex = checkbox.dataset.companyIndex;
    if (!companyIndex) return;

    const checkboxes = this.checkboxTargets.filter((cb) => cb.dataset.companyIndex === companyIndex);

    if (checkbox.checked) {
      checkboxes.forEach((cb) => {
        if (cb !== checkbox) {
          cb.disabled = true;
        }
      });
    } else {
      checkboxes.forEach((cb) => {
        cb.disabled = false;
      });
    }
  }

  updateEmailTextStyle(checkbox, isChecked) {
    checkbox.labels.forEach((label) => label.classList.toggle("fw-bold", isChecked));
  }

  updateSelectedEmails() {
    this.checkboxTargets.forEach((cb) => cb.checked ? this.selectedEmails.add(cb.value) : this.selectedEmails.delete(cb.value));

    if (this.hiddenInputTarget) {
      this.hiddenInputTarget.value = Array.from(this.selectedEmails).join(", ");
      this.hiddenInputTarget.dispatchEvent(new Event("change"));
    }

    if (this.submitButtonTarget) {
      this.submitButtonTarget.disabled = this.selectedEmails.size === 0;
    }
  }

  updateCheckboxesForSelectedEmails() {
    if (!this.selectedEmails.size) return;

    this.emailTargets.forEach((emailTarget, index) => {
      const email = emailTarget.textContent.trim();
      if (!this.selectedEmails.has(email)) return;

      const checkbox = this.checkboxTargets[index];
      checkbox.checked = true;

      this.toggleRemainingCheckboxes(checkbox);
      this.updateEmailTextStyle(checkbox, true);
    });
  }

  showButtonsContainer() {
    if (this.buttonsContainerTarget) {
      this.buttonsContainerTarget.classList.remove("hide");
    }
  }

  hideButtonsContainer() {
    if (this.buttonsContainerTarget) {
      this.buttonsContainerTarget.classList.add("hide");
    }
  }

  hideSearchErrorMessage() {
    if (this.searchErrorMsgTarget) {
      this.searchErrorMsgTarget.classList.add("hidden");
    }
  }

  hideParticipantForm() {
    if (this.participantFormTarget) {
      this.participantFormTarget.classList.add("hidden");
    }
  }

  showParticipantForm() {
    if (this.participantFormTarget) {
      this.participantFormTarget.classList.remove("hidden");
    }
  }

  /**
   * Callback to be called when the results are rendered into the dom
   * @param {HTMLDivElement} element
   */
  resultsTargetConnected(element) {
    this.hideSearchErrorMessage();
    this.showButtonsContainer();
    this.updateCheckboxesForSelectedEmails();
    this.showParticipantForm();
  }
}
