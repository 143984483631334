import React from "react";
import BestBidTable from "./BestBidTable";
import LotDetails from "./LotDetails";
import TabsHeader from "./TabsHeader";

const BestBidDetail = (props) => {
  return (
    <>
      <LotDetails {...props} />
      <div className="row">
        <div className="col-md-12">
          <div className="block" style={{ overflow: "auto" }}>
            {props.showTabs && (
              <TabsHeader
                activeTab={props.activeTab}
                setActiveTab={props.setActiveTab}
                translations={props.translations}
              />
            )}
            <BestBidTable {...props} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BestBidDetail;
