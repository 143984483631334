import PropTypes from "prop-types";

export const HostPropType = {
  id: PropTypes.number,
  login: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  company_id: PropTypes.number,
  accepted_t_and_c: PropTypes.bool,
  seen_at: PropTypes.instanceOf(Date),
  time_zone: PropTypes.string,
  currency_id: PropTypes.number,
  country: PropTypes.string,
  locale: PropTypes.string,
  user_company_name: PropTypes.string,
  market_dojo: PropTypes.bool,
  category_dojo: PropTypes.bool,
  show_award_lot_msg: PropTypes.bool,
  sim_dojo: PropTypes.bool,
  quick_quotes: PropTypes.bool,
  emarketplace: PropTypes.bool,
  contracts_dojo: PropTypes.bool,
  deactivated: PropTypes.bool,
  srm_dojo: PropTypes.bool,
};
