import React from "react";
import "react-datepicker/dist/react-datepicker.css";

import { useSelector } from "react-redux";
import { showRankOfCell } from "../../bidCommon";
import { isLotTotalPresent } from "../../common";

import PlaceBidFormComponentNew from "./input";

const getShowRank = (rankValue, lot, lic, isBlicPriceUnchanged, isAuction) => {
  const isRankVisible = rankValue || (!isLotTotalPresent(lot) && lic.is_visible_rank);
  return (isAuction && isRankVisible && isBlicPriceUnchanged) || advancedLots.proxyRank;
};

const PlaceBidFormComponent = ({ rankValue, lc, bidLineItemComponents, previousBid, ...props }) => {
  const { isAllDetailView, lot, bid, translations } = props;
  const { lic: lineItemComponent, blic: bidLineItemComponent, li: lineItem } = props;
  const event = useSelector((state) => state.lotReducers.event);
  const role = useSelector((state) => state.lotReducers.role);

  const prevBlic = bidLineItemComponents?.find(
    (blic) => blic?.bid_id === previousBid?.id && blic?.line_item_component_id === lineItemComponent?.id
  );

  const isBlicPriceUnchanged = bidLineItemComponent?.price === prevBlic?.price;
  const showRank = getShowRank(rankValue, lot, lineItemComponent, isBlicPriceUnchanged, props.isAuction);
  const rank = showRankOfCell(event, prevBlic, bid, lineItemComponent, lc, lot, role, false, props.auctionTab);

  return (
    <PlaceBidFormComponentNew
      translations={translations}
      rank={showRank ? rank : undefined}
      bid={bid}
      lineItemComponent={lineItemComponent}
      lineItem={lineItem}
      bidLineItemComponent={bidLineItemComponent}
      lot={lot}
      isAllDetailView={isAllDetailView}
    />
  );
};

export default PlaceBidFormComponent;
