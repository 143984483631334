import React from "react";
const Header = (props) => {
  return (
    <div className="block block-transparent remove-margin-b">
      <div className="modal-header">
        <button type="button" className="close" onClick={() => props.setIsOpen(false)}>
          ×
        </button>
        <h3 className="modal-title pop-title">
          {props.translations.current_bids}:<span title="evnt total">{props.header}</span>
        </h3>
      </div>
    </div>
  );
};

export default Header;
