export type IfMaybeUndefined<T, True, False> = [undefined] extends [T] ? True : False;

/** Allow all strings, but provide the given values as autocomplete */
// `{}` is required here to get the desired behaviour
export type LooseAutocomplete<Values> = Values | (string & {});

/** Like Omit, but with autocomplete for object keys */
export type HelpfulOmit<T, K extends LooseAutocomplete<keyof T>> = Omit<T, K>;

/**
 * Forces Typescript to "compute" the type of something when hovering
 * @example
 * type Test = Compute<{ a: true } & { b: true }>
 * // Test when hovered is { a: true; b: true; }
 */
// `unknown` is required here to get the desired behaviour
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export type Compute<T> = { [K in keyof T]: T[K] } & unknown;

/**
 * Get the keys of a union type as a union type
 */
type KeyofUnion<T> = T extends T ? keyof T : never;

/**
 * Allow access to any key of a union type while still preserving narrowing
 *
 * This is desirable as an 'in' check is more expensive than a simple property access
 *
 * @example
 * function narrow(item: OneOf<{ a: true } | { b: true }>) {
 *   // this would normally have to be `if ('a' in item)`
 *   if (item.a) {
 *     // item is narrowed to { a: true }
 *   } else {
 *     // item is narrowed to { b: true }
 *   }
 * }
 */
export type OneOf<
  Union extends object,
  // stored here to avoid distribution - don't provide this parameter yourself
  AllKeys extends KeyofUnion<Union> = KeyofUnion<Union>,
> = Union extends infer Item ? Compute<Item & { [K in Exclude<AllKeys, keyof Item>]?: never }> : never;

export type MaybePromise<T> = T | Promise<T>;

// Omit over a union
export type DistributiveOmit<T, K extends string | number | symbol> = T extends unknown ? Omit<T, K> : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypeGuard<Guarded extends Input, Input = any> = (input: Input) => input is Guarded;

export type GuardedType<Guard extends TypeGuard<unknown>> = Guard extends TypeGuard<infer Guarded> ? Guarded : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HasMatchFunction<Guarded extends Input, Input = any> = {
  match: TypeGuard<Guarded, Input>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Matcher<Guarded extends Input, Input = any> = HasMatchFunction<Guarded, Input> | TypeGuard<Guarded, Input>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MatchedType<Match extends Matcher<unknown, any>> = Match extends Matcher<infer Guarded> ? Guarded : never;

export type Branded<T, Brand> = T & { __brand: Brand };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const brand = <T, const Brand>(value: T, _brand: Brand) => value as Branded<T, Brand>;

/**
 * Construct a type that satisfies another type
 * @example
 * type Test = Satisfies<"foo", string>
 */
export type Satisfies<T extends U, U> = T;

export type WithRequired<T, K extends keyof T> = Compute<Omit<T, K> & Required<Pick<T, K>>>;

export type WithOptional<T, K extends keyof T> = Compute<Omit<T, K> & Partial<Pick<T, K>>>;

export type TupleOfLength<T, N extends number, Acc extends T[] = []> = Acc["length"] extends N
  ? Acc
  : TupleOfLength<T, N, [T, ...Acc]>;

export type TupleOfAtLeast<T, N extends number> = TupleOfLength<T, N> & T[];

export type Nullish<T> = T | null | undefined;

// this is just {} but named semantically, so the linter doesn't yell
export type NotNullish = NonNullable<unknown>;

export type NotUndefined = NotNullish | null;

export type Override<T, U> = Compute<Omit<T, keyof U> & U>;

export type RemoveIndexSignature<T> = Compute<{
  [K in keyof T as string extends K ? never : number extends K ? never : symbol extends K ? never : K]: T[K];
}>;

export type KeysOfType<T, U> = {
  [K in keyof T]-?: T[K] extends U ? K : never;
}[keyof T];
