import React from "react";
import Tooltip from "../../../../common/Tooltip";

const FeedbackSurveyText = (props) => {
  const { translations, surveyText, setSurveyText } = props;
  const arr = Array.from(Array(11).keys());
  return (
    <div className="form-group">
      <Tooltip title={translations.infos.survey_why.title} body={translations.infos.survey_why.body} />
      <label>{translations.infos.survey_why.title}</label>
      <textarea
        rows="5"
        className="form-control height-sp"
        text={surveyText}
        value={surveyText}
        onChange={(e) => setSurveyText(e)}
      ></textarea>
    </div>
  );
};

export default FeedbackSurveyText;
