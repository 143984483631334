import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import { getError } from "./errorActions";

const showSuccess = (successMessage) => {
  if (successMessage === undefined || !(successMessage.length > 1)) {
    return { type: "NA" };
  } else {
    return { type: actionTypes.SUCCESS, success: successMessage };
  }
};

const unitSetSuccess = (data, actionType) => {
  return {
    type: actionType,
    payload: {
      unitSet: data.unit_set,
      unitOfMeasures: data.unit_of_measures,
    },
  };
};

export const createUnitSet = (payload) => (dispatch) =>
  apiCall
    .post(`${config.V2_BASE_URL}/unit_sets`, payload)
    .then((response) => {
      dispatch(unitSetSuccess(response.data, actionTypes.CREATE_UNIT_SET));
      dispatch(showSuccess(response.data.message));
      return response.data.unit_set.id;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const updateUnitSet = (payload, unitSetId) => (dispatch) =>
  apiCall
    .put(`${config.V2_BASE_URL}/unit_sets/${unitSetId}`, payload)
    .then((response) => {
      dispatch(unitSetSuccess(response.data, actionTypes.UPDATE_UNIT_SET));
      dispatch(showSuccess(response.data.message));
      return response.status;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
