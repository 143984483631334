import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import { updateEditPageFieldStatus } from "../../../../actions/contract/contractConfigurationActions";
import Checkbox from "../Checkbox";

class ContractDatesAndAlerts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contractDates: props.contractDates,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.data.editFieldsUpdatedData) {
      this.setState({
        contractDates: newProps.data.editFieldsUpdatedData.data["dates"],
      });
    }
  }

  handleChange(e) {
    const fieldName = e.target.name;
    const isChecked = e.target.checked;
    const { companyId, columnId } = this.props;
    this.props.updateEditPageFieldStatus("dates", fieldName, columnId, isChecked, companyId);
  }

  render() {
    const { contractDates } = this.state;
    const { translations, currentUserEditConfigPermission } = this.props;
    return (
      <div className="col-md-12 col-sm-12">
        <div className="block-item btn-group-sm same-height-block">
          <div className="form-group">
            <div className="title">{translations.contract_dates_and_alerts}</div>
            {Object.keys(contractDates).map((key, index) => (
              <div key={index} className="split-checkboxes">
                <Checkbox
                  name={key}
                  checked={contractDates[key]}
                  onChange={this.handleChange}
                  translations={translations}
                  readOnly={!currentUserEditConfigPermission}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.contractConfigurationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateEditPageFieldStatus: (columnName, fieldName, columnId, status, companyId) =>
    dispatch(updateEditPageFieldStatus(columnName, fieldName, columnId, status, companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractDatesAndAlerts);
