/**
 * Throws an error if the condition is falsy.
 *
 * Typically should be used as a last resort, for things that should never happen.
 *
 * @param condition - The condition to check.
 * @param message - The message to include in the error.
 */
export function assert(condition: unknown, message?: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}
