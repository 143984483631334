import PropTypes from "prop-types";

export const EventStates = PropTypes.oneOf([
  "cancelled",
  "closed",
  "completed",
  "edit",
  "new",
  "pause",
  "pending",
  "post_rfq_pre_auction",
  "rfq",
  "running",
]);

export const EventPropType = {
  id: PropTypes.number,
  user_id: PropTypes.number,
  name: PropTypes.string,
  start_at: PropTypes.any,
  end_at: PropTypes.any,
  event_direction: PropTypes.string,
  event_type: PropTypes.any,
  minimum_duration: PropTypes.any,
  dynamic_close_period: PropTypes.any,
  minimum_bid_change: PropTypes.any,
  maximum_bid_change: PropTypes.any,
  special_conditions: PropTypes.any,
  event_brief: PropTypes.string,
  current_state: EventStates,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  currency_id: PropTypes.number,
  invite_code: PropTypes.string,
  auto_accept_participants: PropTypes.bool,
  send_rfq_to_auction_invitation_mail: PropTypes.bool,
  parent_id: PropTypes.any,
  event_category: PropTypes.string,
  bid_deadline: PropTypes.string,
  progress_to_auction: PropTypes.any,
  extra_event_state: PropTypes.string,
  time_interval: PropTypes.any,
  bid_change: PropTypes.any,
  bid_change_from: PropTypes.any,
  event_category_rfq: PropTypes.bool,
  event_category_auction: PropTypes.bool,
  with_questionnaires: PropTypes.bool,
  sealed_functionality: PropTypes.bool,
  rfq_with_weighting: PropTypes.bool,
  rfq_which_questionnaire_id: PropTypes.any,
  rfq_price_weighting: PropTypes.number,
  auction_with_weighting: PropTypes.bool,
  auction_which_questionnaire_id: PropTypes.any,
  auction_price_weighting: PropTypes.number,
  tied_bids: PropTypes.string,
  price_weighting_calculation_type: PropTypes.any,
  sandpit_event: PropTypes.bool,
  went_live_first_at: PropTypes.string,
  bid_at_detail_level: PropTypes.bool,
  old_dynamic_close_period: PropTypes.any,
  old_minimum_bid_change: PropTypes.any,
  old_maximum_bid_change: PropTypes.any,
  category_id: PropTypes.any,
  expected_savings: PropTypes.any,
  dynamic_close_rank: PropTypes.number,
  multi_currency_event: PropTypes.bool,
  quick_quotes: PropTypes.bool,
  emarketplace: PropTypes.bool,
  postcode: PropTypes.any,
  company_id: PropTypes.number,
  is_template: PropTypes.bool,
  closed_at: PropTypes.any,
  allow_participant_documents: PropTypes.bool,
  survey_offered: PropTypes.bool,
  tag_list: PropTypes.array,
  formulaLics: PropTypes.shape({}),
  lics: PropTypes.shape({}),
};

export default EventPropType;
