import React from "react";
const RunningEventLotsTooEarly = ({ translations, event, host }) => {
  return (
    <div className="container">
      <div className="alert alert-info" style={{ whiteSpace: "pre-wrap" }}>
        {host ? translations.host_information_will_appear_here : translations.participant_information_will_appear_here}
        {!host ? "\n\n" : ""}
        {!host ? participantMessage(translations, event) : ""}
      </div>
    </div>
  );
};

var setMinute = (mins) => {
  mins = mins === 0 ? "" : `${mins}`;
  return mins;
};

var showTime = (time, translations) => {
  var timeString = "";
  if (time === null) {
    timeString = "-";
  } else if (time === 0) {
    timeString = translations.na;
  } else if (time < 3601) {
    timeString = `${time / 60} ${translations.minutes}`;
  } else {
    var hour = Math.floor(time / 3600);
    var mins = time % 60;
    mins = setMinute(mins);
    timeString = `${translations.hours_count.replace("%{count}", hour)} ${mins}`;
  }

  return timeString;
};

var rankedMessage = (customData, translations, event) => (
  <div>
    {translations[`${customData.direction}_invitaion`].replace("%{type}", translations.ranked.toLowerCase())}&nbsp;
    {customData.isReversed ? translations.ranked_lowest_aim : translations.ranked_highest_aim} <br />
    <br />
    {event.auction_with_weighting ? translations.ranked_weighted + "\n\n" : ""}
    {translations.ranked_instruction}
    <br />
    <br />
    {translations.overview_tab_instruction
      .replace("%{min_bid_change}", customData.min_bid_change)
      .replace("%{max_bid_change}", customData.max_bid_change)}
    <br />
    <br />
    {translations.auction_timing.replace("%{min_duration}", customData.minDuration)}
    <br />
    <br />
    {customData.closePeriod != translations.na
      ? translations.dynamic_close
          .replace(/%{dynamic_close_period}/gi, customData.closePeriod)
          .replace("%{bidders}", customData.bidders) + "\n\n"
      : ""}
    {translations.qual_bid_text}
    <br />
    <br />
    {translations.auction_rules_text}
  </div>
);

var openMessage = (customData, translations, event) => (
  <div>
    {translations[`${customData.direction}_invitaion`].replace("%{type}", translations.open.toLowerCase())}&nbsp;
    {customData.isReversed ? translations.open_lowest_aim : translations.open_highest_aim} <br />
    <br />
    {translations.open_instruction}
    <br />
    <br />
    {translations.overview_tab_instruction
      .replace("%{min_bid_change}", customData.min_bid_change)
      .replace("%{max_bid_change}", customData.max_bid_change)}
    <br />
    <br />
    {translations.auction_timing.replace("%{min_duration}", customData.minDuration)}
    <br />
    <br />
    {customData.closePeriod != translations.na
      ? translations.dynamic_close
          .replace(/%{dynamic_close_period}/gi, customData.closePeriod)
          .replace("%{bidders}", "") + "\n\n"
      : ""}
    {translations.qual_bid_text}
    <br />
    <br />
    {translations.auction_rules_text}
  </div>
);

var japaneseMessage = (customData, translations, event) => (
  <div>
    {translations[`${customData.direction}_invitaion`].replace("%{type}", translations.japanese.toLowerCase())} <br />
    <br />
    {translations.japanese_instructions}
    <br />
    <br />
    {translations.time_period_instruction.replace(
      "%{scale}",
      customData.isReversed ? translations.decrease : translations.increase
    )}
    <br />
    <br />
    {translations.auction_rules_text}
  </div>
);

const eventHandlers = {
  rankedMessage: rankedMessage,
  openMessage: openMessage,
  japaneseMessage: japaneseMessage,
  // Add more event types and their corresponding handlers as needed
};

var participantMessage = (translations, event) => {
  var eventType = event.event_type.toLowerCase();
  if (eventType === null) {
    return "";
  }
  var customData = {};
  customData["direction"] = event.event_direction.toLowerCase();
  customData["minDuration"] = showTime(event.minimum_duration, translations);
  customData["closePeriod"] = showTime(event.dynamic_close_period, translations);
  customData["isReversed"] = event.event_direction === "Reverse";
  customData["bidders"] =
    event.dynamic_close_rank === 0
      ? ` ${translations.within_all}`
      : ` ${translations.within_top.replace("%{no}", event.dynamic_close_rank)}`;
  customData["min_bid_change"] = `${Number(event.minimum_bid_change).toFixed(2)}%`;
  customData["max_bid_change"] = `${Number(event.maximum_bid_change).toFixed(2)}%`;

  const eventHandler = eventHandlers[`${eventType}Message`];
  return eventHandler(customData, translations, event);
};

export default RunningEventLotsTooEarly;
