import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { deleteActivity, reloadActivityList, updateActivity } from "../../../actions/activityActions";
import { Confirm } from "../../common/Confirm";
import AssignmentTab from "../tabs/AssignmentTab";
import DocumentTab from "../tabs/DocumentTab";
import GeneralTab from "../tabs/GeneralTab";
import NotesTab from "../tabs/NotesTab";
import SubActivityTab from "../tabs/SubActivityTab";
import ModalFooter from "./Footer";
import ModalHeader from "./Header";
import TabsContent, { TabItem } from "./TabsContent";
import TabsWrapper from "./TabsWrapper";
import {
  handlePublishButton,
  relayUpdateActivity,
  prepareParams,
  resolveEditButton,
  resolveCheckForChanges,
  resolveSubmitChanges,
  manageStates,
} from "./utils";

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#srmBody");

export default function ActivityModal(props) {
  const {
    modalIsOpen,
    closeModal,
    translations,
    custom_column_options: customColumnOptions,
    values_for_status: valuesForStatus,
    current_user: currentUser,
    activityTypes,
    priorityOptions,
    draftActivity,
    concernedParties,
    documents,
    notes,
    setActivities,
    setConcernedParties,
    allActivities,
    allConcernedParties,
    paginationParams,
    setPaginationParams,
    maxFileSize,
    permitted_to_create_activity: permittedToCreateActivity,
    fetchDraftActivity,
    hostOwnerEmails,
    setHostOwnerEmails,
  } = props;

  const { contact_id, supplier_id, non_persistent_state } = useSelector((state) => state.ActivityReducer);

  const [
    activityTypeId,
    setActivityType,
    subject,
    setSubject,
    duration,
    setDuration,
    status,
    setStatus,
    purpose,
    setPurpose,
    dueDate,
    setDueDate,
    alertDate,
    setAlertDate,
    completionDate,
    setCompletionDate,
    priorityId,
    setPriorityId,
    oldState,
    setOldState,
    returnCurrentStateValues,
  ] = manageStates({ eff: useState, draftActivity, customColumnOptions, activityTypes, priorityOptions });

  const dispatch = useDispatch();

  // Compare the initial state and new state
  const checkIfActivityHasChanges = () => {
    const newState = returnCurrentStateValues();
    return !isEqual(oldState, newState);
  };

  // Followup - if the user clicks on follow up then save the activity and create a new follow up activity and close
  // this modal and open a new one (the new modal is opened from parent components)

  // closePopup - we will close the pop up in most of the cases when the activity is published or saved but there is a
  // case when the user moves from one tab to other we don't want to close the pop up so we use this variable for that

  // draft - when the activity is published then we want to change the value of draft to false (previously we did this
  // on controller so when the activity is updated we published it) so when the user changes the tab then at that time
  // don't change the draft status, it should always be what it was
  const submitActivity = (followup, closePopup = true, draft = false, refreshList = true) => {
    const params = prepareParams({
      activity: {
        subject: subject,
        duration: duration,
        current_state: status,
        purpose: purpose,
        due_date: dueDate,
        alert_date: alertDate,
        completed_on: completionDate,
        priority: priorityId,
        activity_type: activityTypeId,
        company_id: currentUser.company_id,
        draft: draft,
      },
      paginationParams,
      followup,
      contact_id,
      supplier_id,
      refreshList,
    });

    dispatch(updateActivity(params, draftActivity.id, translations.updated_successfully)).then((response) =>
      relayUpdateActivity({
        response,
        closeModal,
        setOldState,
        returnCurrentStateValues,
        fetchDraftActivity,
        closePopup,
        followup,
      })
    );
  };

  const handleDeleteActivity = () => {
    if (draftActivity.draft) {
      dispatch(deleteActivity(draftActivity.id, paginationParams.page, paginationParams.per_page));
    }
    dispatch(reloadActivityList());
    closeModal();
  };

  const editBtnOrTxt = resolveEditButton(props, permittedToCreateActivity);
  const checkForChangesAndClose = resolveCheckForChanges({ checkIfActivityHasChanges, dispatch, closeModal });
  const submitActivityIfChanges = resolveSubmitChanges({
    checkIfActivityHasChanges,
    submitActivity,
    draftActivity,
    dispatch,
  });
  const publishOrSaveBtn = handlePublishButton({
    draftActivity,
    checkIfActivityHasChanges,
    translations,
    submitActivity,
  });

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={checkForChangesAndClose}
        className="modal-content col-md-8 col-sm-8 custom-field-popup scrollbar-hidden"
      >
        <ModalHeader
          draftActivity={draftActivity}
          checkForChangesAndClose={checkForChangesAndClose}
          editBtnOrTxt={editBtnOrTxt}
        />
        <div className="modal-body p-t0">
          <div className="bg-githubgray block content m-b0 p-b0 p-t30 special-tab-content tab-status-current">
            <span className="tab-line"></span>
            <TabsWrapper
              submitActivityIfChanges={submitActivityIfChanges}
              active={non_persistent_state.activeActivityTab}
            />
            <TabsContent active={non_persistent_state.activeActivityTab}>
              <GeneralTab
                name={"general"}
                valuesForStatus={valuesForStatus}
                translations={translations}
                activityTypes={activityTypes(customColumnOptions)}
                priorityOptions={priorityOptions(customColumnOptions)}
                allActivities={allActivities}
                setActivities={setActivities}
                draftActivity={draftActivity}
                currentUser={currentUser}
                closeModal={closeModal}
                paginationParams={paginationParams}
                setConcernedParties={setConcernedParties}
                setPaginationParams={setPaginationParams}
                fetchDraftActivity={fetchDraftActivity}
                alphaNumericString={"alphaNumericString"}
                activityTypeId={activityTypeId}
                setActivityType={setActivityType}
                subject={subject}
                setSubject={setSubject}
                duration={duration}
                setDuration={setDuration}
                status={status}
                setStatus={setStatus}
                purpose={purpose}
                setPurpose={setPurpose}
                dueDate={dueDate}
                setDueDate={setDueDate}
                alertDate={alertDate}
                setAlertDate={setAlertDate}
                completionDate={completionDate}
                setCompletionDate={setCompletionDate}
                priorityId={priorityId}
                setPriorityId={setPriorityId}
              />
              <TabItem name={"assignment"} classes="assignment">
                <AssignmentTab
                  translations={translations}
                  companyId={currentUser.company_id}
                  concernedParties={concernedParties}
                  draftActivity={draftActivity}
                  allConcernedParties={allConcernedParties}
                  setConcernedParties={setConcernedParties}
                  hostOwnerEmails={hostOwnerEmails}
                  setHostOwnerEmails={setHostOwnerEmails}
                />
              </TabItem>
              <TabItem name="documents" classes="documents">
                <DocumentTab
                  translations={translations}
                  draftActivity={draftActivity}
                  documents={documents}
                  maxFileSize={maxFileSize}
                />
              </TabItem>
              <TabItem name="notes" classes="notes">
                <NotesTab translations={translations} draftActivity={draftActivity} notes={notes} />
              </TabItem>
              <TabItem name="subactivity" classes="subactivity">
                <SubActivityTab
                  activity={draftActivity}
                  activities={props.allActivities}
                  childActivities={props.childActions.childActivities}
                />
              </TabItem>
            </TabsContent>
          </div>
        </div>
        <ModalFooter
          draftActivity={draftActivity}
          handleDeleteActivity={handleDeleteActivity}
          submitActivity={submitActivity}
          publishOrSaveBtn={publishOrSaveBtn}
        />
        <Confirm
          translations={translations}
          message={translations.want_to_leave_page}
          htmlId="activityConfirmCloseModal"
          onOkayButtonClick={() => closeModal()}
        />
      </Modal>
    </>
  );
}
