import flatten from "lodash/flatten";
import {
  filterAnswersByQA,
  findQuetionForQQA,
  manuallyOrScoredByHuman,
  notScoredByHumans,
  weightedScore,
  score,
} from "./QuestionnaireQuestionsAnswerCommon";
import {
  weightedSections,
  weightedQuestions,
  sectionIdsWithNoQuestionsCreatedBeforeQaArchived,
  totalScoringOfQuestions,
} from "./QuestionnarieCommon";
import { findSectionForQuestion } from "./QuestionnaireQuestionCommon";
import { formatPercentileNumber } from "./common";

export const getStatus = (qAnswers, participant) => {
  let flag = 0;
  let qAnswer;
  if (qAnswers.length > 0) {
    qAnswer = qAnswers.find((ans) => ans.participant_id === participant.user_id);
  }
  if (qAnswer && waitingForResub(qAnswer) && qAnswer.submitted_on) {
    flag = 1; // waiting for resubmission
  } else {
    if (!qAnswer || (qAnswer && !qAnswer.submitted_on)) {
      flag = 2; // not submitted or saved progress at first attempt
    } else if (
      qAnswer.submitted &&
      !qAnswer.total_weighted_score
      // checkForScoreMandatory(qAnswer, questions, qqas)
    ) {
      flag = 3; // not scored
    }
  }
  return flag;
};

export const waitingForResub = (answer) => !answer.submitted && answer.submitted_on;

/**
 * Because we dont have a flag that says if host has finished marking
 * the questionnaire, we need to figure out if all the mandatory questions
 *  have been scoreed
 */
export const checkForScoreMandatory = (qa, questions, qqas) => {
  let result = true;

  const questionnaireQuestionsAnswers = filterAnswersByQA(qa, qqas);
  questionnaireQuestionsAnswers.forEach((qqa) => {
    const question = findQuetionForQQA(questions, qqa);
    //  && que_q_ans.manually_or_scored_by_human)
    if (
      ["one_line_text", "paragraph_text", "document_upload"].includes(question.question_type) &&
      manuallyOrScoredByHuman(qqa)
    ) {
      result = false;
    } else if (["yes_no", "pick_one_from_list", "multiple_choice"].includes(question.question_type)) {
      if (
        ((qqa.manually_scored && !qqa.content) || (!qqa.manually_scored && qqa.content)) &&
        qa.submitted &&
        !qa.submitted_on
      ) {
        result = false;
      }
    }
  });
  return result;
};
export const questionnaireScoreOrMessage = (
  flag,
  participant,
  qas,
  questions,
  qqas,
  questionnaireAnswersQuestions,
  questionnaires,
  questionnaireSections,
  translations,
  locale
) => {
  /** flag is for the questionnaire status
   * flag : 0 means -> have a questionnaire score to display
   * flag : 1 means -> waiting for resubmission
   * flag : 2 means -> not submitted or saved progress at first attempt
   * flag : 3 means -> not scored
   */
  let qa;
  let qqa;
  let questionnaire;
  if (qas.length) {
    qa = qas.find((ans) => ans.participant_id === participant.user_id);
    questionnaire = qa && findQuestionnaire(questionnaires, qa);
  }
  if (qa) {
    // questionIds = answer.associated_questions.pluck(:id)
    const questionIds = associatedQuestions(qa, questions, questionnaireAnswersQuestions).map((q) => q.id);
    const finalQqas = questionsAnswers(qa, qqas).filter((qqa) => questionIds.includes(qqa.questionnaire_question_id));
    qqa = notScoredByHumans(questions, finalQqas);
  }
  if (qa && qas && qas.length && !waitingForResub(qa) && qqa && !qqa.length) {
    return formatPercentileNumber(qa.total_weighted_score, locale);
  } else if (flag === 1) {
    return translations.awaiting_resubmission_host;
  } else if (flag === 2) {
    return translations.not_submitted;
  } else if (
    qas.length &&
    qa &&
    qa.submitted &&
    !qa.total_weighted_score
    // checkForScoreMandatory(qa, questions, qqas)
  ) {
    return translations.not_scored;
  }
};
export const questionsAnswers = (qa, qqas) => {
  return qqas.filter((qqa) => qqa.questionnaire_answer_id === qa.id);
};

export const filterquestionnaireAnswersQuestions = (qa, qaqs) => {
  return qaqs.filter((qaq) => qaq.questionnaire_answer_id === qa.id);
};

export const scorePercentage = (
  questionnaire,
  qa,
  questions,
  questionnaireAnswersQuestions,
  questionnaireSections,
  qqas
) => {
  let r = 0;
  if (questionnaire && weightedSections(questionnaire)) {
    r = totalSectionsWeightedScores(
      qa,
      questionnaire,
      questions,
      questionnaireAnswersQuestions,
      questionnaireSections,
      qqas
    );
  } else if (questionnaire && weightedQuestions(questionnaire)) {
    //       total_weighted_scores_of_questions
    //       # total_weighted_scores_of_questions_percentage
    r;
  } else {
    //       total_scores_of_questions_percentage
    r;
  }
  if (isNaN(r)) {
    r = 0;
  }
  return parseFloat(r).toFixed(2);
};
export const findQuestionnaire = (questionnaires, qa) =>
  questionnaires.find((questionnaire) => questionnaire.id === qa.questionnaire_id);

export const totalSectionsWeightedScores = (
  qa,
  questionnaire,
  questions,
  questionnaireAnswersQuestions,
  questionnaireSections,
  qqas,
  isDivisible = false
) => {
  let total = 0;
  const sections = associatedSections(
    qa,
    questionnaire,
    questions,
    questionnaireAnswersQuestions,
    questionnaireSections
  );
  sections.forEach((section) => {
    const score = sectionWeightedScore(
      qa,
      section,
      questionnaire,
      questions,
      questionnaireSections,
      questionnaireAnswersQuestions,
      qqas
    );
    if (isDivisible && questionnaire.with_weighting === "per_section") {
      tsq = totalScoringOfQuestions(questionnaire, questions, questionnaireAnswersQuestions, nil, section);
      total = tsq == 0 ? 0 : (score / tsq) * 100;
    } else {
      total = score;
    }
  });
  return total.toFixed(2);
};

export const sectionWeightedScore = (
  qa,
  section,
  questionnaire,
  questions,
  questionnaireSections,
  questionnaireAnswersQuestions,
  qqas
) => {
  let total = 0;

  const associatedQAs = associatedQuestionsAnswers(qa, questions, questionnaireAnswersQuestions, qqas);
  associatedQAs.forEach((question_answer) => {
    const question = findQuetionForQQA(questions, question_answer);
    const qSection = findSectionForQuestion(questionnaireSections, question);
    if (qSection !== section) {
      return;
    }
    if (weightedQuestions(questionnaire)) {
      total = weightedScore(question_answer);
    } else {
      total = score(question_answer);
    }

    if (questionnaire.with_weighting === "per_section") {
      const tsq = totalScoringOfQuestions(questionnaire, null, section);
      total = tsq == 0 ? 0 : (total / tsq) * 100;
    }
    total = total * (section.weighting(self) / 100);
    return isNaN(total) ? 0.0 : total.toFixed(2);
  });
};

export const associatedSections = (
  qa,
  questionnaire,
  questions,
  questionnaireAnswersQuestions,
  questionnaireSections
) => {
  let sectionIds = associatedQuestions(qa, questions, questionnaireAnswersQuestions).map(
    (q) => q.questionnaire_section_id
  );
  sectionIds << sectionIdsWithNoQuestionsCreatedBeforeQaArchived(questionnaire, qa, questions, questionnaireSections);
  sectionIds = flatten(sectionIds);
  questionnaireSections.filter((qs) => sectionIds.includes(qs.id));
};

export const associatedQuestions = (qa, questions, qaqs) => {
  const questionIds = filterquestionnaireAnswersQuestions(qa, qaqs).map((qaq) => qaq.questionnaire_question_id);
  return questions.filter((question) => questionIds.includes(question.id));
};

export const associatedQuestionsAnswers = (qa, questions, qaqs, qqas, associatedQuestionIds = null) => {
  if (!associatedQuestionIds) {
    associatedQuestionIds = associatedQuestions(qa, questions, qaqs);
  }
  return filterAnswersByQA(qa, qqas).filter(
    (qqa) => associatedQuestionIds.includes(qqa.questionnaire_question_id) && qqa.current && isNull(qa.archived_at)
  );
};

export const fetchQuestionnaireScore = (props, participant) => {
  if (props.showOverallScore) {
    return questionnaireScoreOrMessage(
      getStatus(props.questionnaireAnswers, participant),
      participant,
      props.questionnaireAnswers,
      props.questionnaireQuestions,
      props.answers,
      props.questionnaireAnswersQuestions,
      props.questionnaires,
      props.questionnaireSections,
      props.translations,
      props.locale
    );
  }
};
