import React from "react";
import { isWeightedRfqOrAuction } from "../../../../eventCommon";
import TableHeaderWithTooltip from "./TableHeaderWithTooltip";

const RankOrRoundHeading = (props) => {
  const { translations, beforeAuction, event, isJapaneseEvent } = props;

  let heading = "";
  const startAt = Date.parse(event.start_at);

  const titleWithTooltip = (title, tooltip) => (
    <TableHeaderWithTooltip
      translations={translations}
      title={title}
      tooltip={tooltip}
      className={"w-rfq-thead-popup rank-number"}
    />
  );

  if ((beforeAuction && (startAt ? Date.now() < startAt : true)) || !beforeAuction) {
    heading =
      isJapaneseEvent && !beforeAuction
        ? titleWithTooltip("bid_round", "bid_bid_round")
        : titleWithTooltip(isWeightedRfqOrAuction(event) ? "weighted_rank" : "rank", "bid_rank");
  } else if (beforeAuction && (startAt ? Date.now() > startAt : true)) {
    heading = titleWithTooltip("user_status", "bid_user_status");
  }

  return heading;
};

export default RankOrRoundHeading;
