import { useCallback } from "react";
import { useSelector } from "react-redux";
import { uniq } from "lodash";

import { getTab, quickSelectOptions } from "@/components/messages/sendMessageModal/Container";
import { eventParticipantStatus } from "../../common";

/**
 *
 * @param {[]} hosts
 * @param {boolean} isHostTabSelected
 * @returns {(quickSelect: [string]) => [number] }
 */
export const useQuickSelectedParticipants = (hosts, isHostTabSelected) => {
  const eventParticipants = useSelector(({ lotReducers }) => lotReducers.event_participants);
  const hostPermission = useSelector(({ lotReducers }) => lotReducers.host_permission);

  /**
   * Get list of selected particiants ids on change of quick selects
   *
   * @param {string[]} quickSelection list of quickSelect options
   * @returns {number[]} selectedParticipantIds
   */
  return useCallback(
    (quickSelection) => {
      let selectedParticipantIds;

      /**
       * Select participants according to quickSelect option selected
       */
      if (isHostTabSelected) {
        selectedParticipantIds = hosts
          .filter((host) =>
            Boolean(quickSelection.find((select) => hostPermission[host.id]?.toLowerCase()?.includes(select)))
          )
          .map(({ id }) => id);
      } else {
        selectedParticipantIds = eventParticipants
          .filter((participant) => {
            const status = participant.status ?? eventParticipantStatus(participant);
            return Boolean(quickSelection.find((select) => select === status?.toLowerCase()));
          })
          .map(({ user_id }) => user_id);
      }

      return selectedParticipantIds;
    },
    [eventParticipants, hosts, isHostTabSelected, hostPermission]
  );
};

/**
 *
 * @param {[string]} quickSelect
 * @param {boolean} checked
 * @param {string} selectedValue
 * @param {string} tab
 * @returns {[string]}
 */
const getQuickSelection = (quickSelect, checked, selectedValue, tab) => {
  // value for "all_hosts" and "all_participants"
  const optionsToSelectAll = [quickSelectOptions.hostTab[0].value, quickSelectOptions.participantTab[0].value];

  /**
   * If "all_hosts" or "all_participants" is selected or unselect then
   * add or remove all the option of that tab to the quickSelect
   * else add or remove only specific quick select option
   */
  if (optionsToSelectAll.includes(selectedValue)) {
    return checked ? quickSelectOptions[tab].map(({ value }) => value) : [];
  }

  return checked
    ? uniq([...quickSelect, selectedValue])
    : quickSelect.filter((select) => ![selectedValue, ...optionsToSelectAll].includes(select));
};

/**
 *
 * @param {()=> void} setState
 * @param {boolean} isHostTabSelected
 * @param {[]} hosts
 */
export const useQuickSelectHandler = (setState, isHostTabSelected, hosts = []) => {
  const selectedParticipantOnQuickSelectChange = useQuickSelectedParticipants(hosts, isHostTabSelected);

  /**
   * @param {e: React.MouseEvent<HTMLElement>} event
   * @returns {void}
   */
  return (e) => {
    const selectedValue = e.target.value;
    const checked = Boolean(e.target.checked);
    const tab = getTab(isHostTabSelected);

    /**
     * set quickSelect and selectedParticipantIds to state
     */
    setState(({ quickSelect }) => {
      const quickSelection = getQuickSelection(quickSelect, checked, selectedValue, tab);
      const selectedParticipantIds = selectedParticipantOnQuickSelectChange(quickSelection);

      return { quickSelect: quickSelection, selectedParticipantIds };
    });
  };
};
