import React from "react";
import range from "lodash/range";
import NumbersAndOperatorsForFormula from "./NumbersAndOperatorsForFormula";

const NumberAndOperatorKeysForFormula = (props) => {
  const { lot, lic, translations, handleFormulaChange, handleAutoSumFormula, dragEnd, dragOver, dragStart, draggable } =
    props;

  return (
    <div className="key-wrapper">
      <div className="keys">
        <div className="clearfix form-group">
          <div id="lineItemNumbers" className="float-left wid-50">
            <NumbersAndOperatorsForFormula
              classes="value number btn btn-default"
              values={range(10)}
              handleFormulaChange={handleFormulaChange}
              name="number"
              dragEnd={dragEnd}
              dragOver={dragOver}
              dragStart={dragStart}
              draggable={draggable}
            />
          </div>
          <div id="lineItemOperators" className="float-right wid-50">
            <NumbersAndOperatorsForFormula
              classes="value btn btn-default"
              values={[")", "(", "/", "*", "-", "+", ".", "^"]}
              handleFormulaChange={handleFormulaChange}
              name="operator"
              dragEnd={dragEnd}
              dragOver={dragOver}
              dragStart={dragStart}
              draggable={draggable}
            />
            <span
              className="clear-formula btn btn-default value"
              style={{ paddingLeft: "9px", cursor: "pointer" }}
              data-name="clear-formula"
              onClick={handleFormulaChange}
            >
              <i className="fa fa-trash" data-name="clear-formula" />
            </span>
            <span
              className="backspace btn btn-default value"
              style={{ paddingLeft: "8px", cursor: "pointer" }}
              data-name="backspace"
              onClick={handleFormulaChange}
            >
              <i className="fa fa-reply" data-name="backspace" />
            </span>
            {!lot.is_event_total && (
              <span
                className="autosum btn"
                style={{ marginLeft: "2px", marginTop: "2px" }}
                data-line-item={lic.line_item_id}
                data-component={lic.lot_component_id}
                data-name="autosum"
                onClick={handleAutoSumFormula}
              >
                <i className="fa fa-plus" />
                {" " + translations.autosum}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="notifiers">
        <div className="form-group">
          <div className="calculation">
            <span className="color-info">
              <i className="btn" />
            </span>
            <span> {translations.calculation_row_cell}</span>
          </div>
        </div>
        <div className="form-group">
          <span className="lot-total-cell color-info">
            <i className="btn" />
          </span>
          <span> {translations.lot_total_cell}</span>
        </div>
        <div className="form-group">
          <span className="price-cell color-info">
            <i className="btn" />
          </span>
          <span> {translations.price_cell}</span>
        </div>
      </div>
    </div>
  );
};

export default NumberAndOperatorKeysForFormula;
