import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import getParticipantList from "../../../../../actions/qq/emarketplace/getParticipantListActions";
import InfoPopover from "../../../InfoPopover";
import { showToasterMessages } from "../../../../../actions/qq/genericActions";
import { confirm } from "../../../util/confirm";
import TrixEditor from "@/components/common/TrixEditor";
export class EMShippingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      participantList: [],
      showParticipantsError: "hidden",
      addressUpdated: false,
      editorValue: null,
      formSubmitting: false,
      participantListUpdated: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      participantList: this.state.participantListUpdated
        ? this.state.participantList
        : newProps.participantsData.participants,
      participantListUpdated: newProps.participantsData.participants.length > 0,
    });
  }

  onDateSelected(event) {
    this.deadlineError.hidden = true;
    $("#emarketplace-deadline-custom-date-time").datetimepicker({
      format: "Y-m-d H:i",
      minDate: 0,
      step: 60,
      minTime: 0,
      scrollMonth: false,
      scrollInput: false,
      onChangeDateTime(currentDateTime) {
        if (currentDateTime && currentDateTime.getDate() === new Date().getDate()) {
          this.setOptions({ minTime: 0 });
        } else {
          this.setOptions({ minTime: false });
        }
      },
      onShow(currentDateTime) {
        if (currentDateTime.getDate() === new Date().getDate()) {
          this.setOptions({ minTime: 0 });
        } else {
          this.setOptions({ minTime: false });
        }
      },
    });
  }

  onEarliestDateSelect() {
    const refThis = this;
    refThis.earliestDeliveryDateError.hidden = true;
    $("#emarketplace-earliest-delivery-date").datetimepicker({
      format: "Y-m-d H:i",
      scrollMonth: false,
      scrollInput: false,
      defaultTime: "8:00",
      minDate: $("#emarketplace-deadline-custom-date-time").val()
        ? $("#emarketplace-deadline-custom-date-time").val()
        : 0,
      onChangeDateTime(currentDateTime, inputData) {
        if (currentDateTime && currentDateTime.getDate() === new Date().getDate()) {
          this.setOptions({ minTime: 0, defaultTime: false });
          const latestDate = moment(inputData.val()).set({ h: 17, m: 0 }).format("YYYY-MM-DD H:mm");
          $("#emarketplace-latest-delivery-date").val(latestDate);
        } else if (currentDateTime) {
          this.setOptions({ minTime: false, defaultTime: "8:00" });
          const latestDate = moment(inputData.val()).set({ h: 17, m: 0 }).format("YYYY-MM-DD H:mm");
          $("#emarketplace-latest-delivery-date").val(latestDate);
        }
        if (refThis.latestDeliveryDateError) {
          refThis.latestDeliveryDateError.hidden = true;
        }
      },
      onShow(currentDateTime) {
        if (currentDateTime && currentDateTime.getDate() === new Date().getDate()) {
          this.setOptions({ minTime: 0, defaultTime: false });
        } else {
          this.setOptions({ defaultTime: "8:00" });
        }
      },
    });
  }

  onLatestDateSelect() {
    this.latestDeliveryDateError.hidden = true;
    $("#emarketplace-latest-delivery-date").datetimepicker({
      format: "Y-m-d H:i",
      minDate: $("#emarketplace-earliest-delivery-date").val() ? $("#emarketplace-earliest-delivery-date").val() : 0,
      scrollMonth: false,
      scrollInput: false,
      defaultTime: "17:00",
    });
  }

  onChangeEditor(content) {
    this.setState({ editorValue: content });
    this.briefError.hidden = true;
  }

  async updateAddress() {
    const { translations } = this.props;
    this.setState({
      showLoader: true,
    });
    this.addressUpdateError.hidden = true;
    const briefText = this.state.editorValue;
    const shippingAddress = this.address.value.trim();
    const deadlineDate =
      $("#emarketplace-deadline-custom-date-time").val() !== ""
        ? $("#emarketplace-deadline-custom-date-time").val()
        : undefined;
    const earliestDeliveryDate =
      $("#emarketplace-earliest-delivery-date").val() !== ""
        ? $("#emarketplace-earliest-delivery-date").val()
        : undefined;
    const latestDeliveryDate =
      $("#emarketplace-latest-delivery-date").val() !== "" ? $("#emarketplace-latest-delivery-date").val() : undefined;
    if (!briefText || !shippingAddress || !deadlineDate || !earliestDeliveryDate || !latestDeliveryDate) {
      if (!briefText) {
        this.briefError.hidden = false;
      }
      if (!shippingAddress) {
        this.addressError.hidden = false;
      }
      if (!deadlineDate) {
        this.deadlineError.hidden = false;
      }
      if (!earliestDeliveryDate) {
        this.earliestDeliveryDateError.hidden = false;
        this.earliestDeliveryDateError.childNodes[0].innerHTML = translations.emarketplace.date_error;
      }
      if (!latestDeliveryDate) {
        this.latestDeliveryDateError.hidden = false;
        this.latestDeliveryDateError.childNodes[0].innerHTML = translations.emarketplace.date_error;
      }
      if (moment(earliestDeliveryDate).isBefore()) {
        this.earliestDeliveryDateError.hidden = false;
        this.earliestDeliveryDateError.childNodes[0].innerHTML = translations.emarketplace.earliest_delivery_date_error;
      }
      if (moment(latestDeliveryDate).isBefore(new Date(earliestDeliveryDate))) {
        this.latestDeliveryDateError.hidden = false;
        this.latestDeliveryDateError.childNodes[0].innerHTML = translations.emarketplace.latest_delivery_date_error;
      }
    } else {
      const response = await this.props.getParticipants({
        postcode: shippingAddress,
        category_id: this.props.subCategory.id,
      });
      if (response && response.participants.length) {
        await this.setState({
          addressUpdated: true,
        });
        const shippingDetails = {
          brief: briefText,
          address: shippingAddress,
          deadline: deadlineDate,
          addressUpdated: true,
        };
        await this.props.shippingDetails(shippingDetails);
      } else {
        this.setState({
          showParticipantsError: "show",
        });
        const shippingDetails = {
          brief: briefText,
          address: shippingAddress,
          deadline: deadlineDate,
          addressUpdated: false,
        };
        await this.props.shippingDetails(shippingDetails);
      }
    }
    this.setState({
      showLoader: false,
    });
  }

  handleDataChange(attr, errorRef) {
    const refThis = this;
    refThis[errorRef].hidden = true;
    if (attr === "postcode") {
      this.setState({
        addressUpdated: false,
        participantList: [],
        participantListUpdated: false,
      });
    }
  }

  async submitRequest() {
    this.setState({
      formSubmitting: true,
    });
    this.submitRequestButton.disabled = true;
    const briefText = this.state.editorValue;
    const shippingAddress = this.address.value.trim();
    const deadlineDate =
      $("#emarketplace-deadline-custom-date-time").val() !== ""
        ? $("#emarketplace-deadline-custom-date-time").val()
        : undefined;
    const earliestDeliveryDate = $("#emarketplace-earliest-delivery-date").val();
    const latestDeliveryDate = $("#emarketplace-latest-delivery-date").val();
    const selectedParticipants = this.state.participantList.map((participant) => participant.email);
    const shippingDetails = {
      brief: briefText,
      address: shippingAddress,
      deadline: deadlineDate,
      addressUpdated: this.state.addressUpdated,
      earliestDeliveryDate,
      latestDeliveryDate,
      selectedParticipants,
    };
    await this.props.shippingDetails(shippingDetails);
    if (!briefText || !shippingAddress || !deadlineDate || !earliestDeliveryDate || !latestDeliveryDate)
      await this.updateAddress();
    if ((briefText || shippingAddress || deadlineDate) && !this.state.addressUpdated) {
      this.addressUpdateError.hidden = false;
    }
    const response = await this.props.submitRequest();
    if (response && this.submitRequestButton) {
      this.submitRequestButton.disabled = false;
    }
    this.setState({
      formSubmitting: false,
    });
  }

  removeParticipant(participantId) {
    let allParticipants = this.state.participantList;
    let pop_up_translations = {
      ConfirmLabel: this.props.translations.pop_up.confirm,
      okLabel: this.props.translations.pop_up.ok,
      cancelLabel: this.props.translations.pop_up.cancel,
    };
    confirm(this.props.translations.pop_up.confirm_popup_delete, pop_up_translations).then(
      () => {
        if (allParticipants.length > 1) {
          allParticipants = allParticipants.filter((participant) => participant.id !== participantId);
          this.setState({
            participantList: allParticipants,
          });
        } else {
          this.props.showToasterMessages("SHOW_ERROR", this.props.translations.emarketplace.participant_remove_error);
        }
      },
      () => {
        // `cancel` callback
      }
    );
  }

  render() {
    const { translations } = this.props;

    return (
      <div>
        <div className="prd_details_add_box">
          <div className="details_form_box">
            <span className="title active"> {translations.emarketplace.shipping_details}</span>
            <form name="prd_details" id="prd_details">
              <div className="each_row single with_label clear">
                <div className="form_hodler">
                  <label>{translations.emarketplace.brief}</label>
                  <div className="input_box richtext-editor">
                    <TrixEditor value={this.state.editorValue} onChange={(content) => this.onChangeEditor(content)} />
                  </div>
                </div>
                <div
                  hidden
                  className="text-danger error-message"
                  ref={(ref) => {
                    this.briefError = ref;
                  }}
                >
                  <span>{translations.emarketplace.other_option_error}</span>
                </div>
              </div>
              <div className="each_row large_text clear">
                <div className="form_hodler">
                  <label>{translations.emarketplace.deadline}</label>
                  <div className="input_box two">
                    <input
                      id="emarketplace-deadline-custom-date-time"
                      className="form-control date-picker-cstm"
                      type="text"
                      onClick={(e) => {
                        this.onDateSelected(e);
                      }}
                      autoComplete="off"
                    />
                    <div
                      hidden
                      className="text-danger error-message"
                      ref={(ref) => {
                        this.deadlineError = ref;
                      }}
                    >
                      <span>{translations.emarketplace.date_error}</span>
                    </div>
                  </div>
                </div>
                <div className="form_hodler">
                  <label>{translations.emarketplace.post_code}</label>
                  <div className="input_box two">
                    <input
                      className="quantity-postal-ip"
                      id="post-code"
                      type="text"
                      ref={(ref) => {
                        this.address = ref;
                      }}
                      onChange={(e) => {
                        this.handleDataChange("postcode", "addressError");
                      }}
                    />
                    <div
                      hidden
                      className="text-danger error-message"
                      ref={(ref) => {
                        this.addressError = ref;
                      }}
                    >
                      <span>{translations.emarketplace.postcode_error}</span>
                    </div>
                  </div>
                </div>
                <div className="form_hodler">
                  <label>{translations.emarketplace.earliest_delivery_date}</label>
                  <div className="input_box two">
                    <input
                      id="emarketplace-earliest-delivery-date"
                      className="form-control date-picker-cstm"
                      type="text"
                      onClick={() => {
                        this.onEarliestDateSelect();
                      }}
                      autoComplete="off"
                    />
                    <div
                      hidden
                      className="text-danger error-message"
                      ref={(ref) => {
                        this.earliestDeliveryDateError = ref;
                      }}
                    >
                      <span />
                    </div>
                  </div>
                </div>
                <div className="form_hodler">
                  <label>{translations.emarketplace.latest_delivery_date}</label>
                  <div className="input_box two">
                    <input
                      id="emarketplace-latest-delivery-date"
                      className="form-control date-picker-cstm"
                      type="text"
                      onClick={() => {
                        this.onLatestDateSelect();
                      }}
                      autoComplete="off"
                    />
                    <div
                      hidden
                      className="text-danger error-message"
                      ref={(ref) => {
                        this.latestDeliveryDateError = ref;
                      }}
                    >
                      <span>{translations.emarketplace.date_error}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="each_row clear single">
                <div className="form_hodler">
                  <div className="input_box">
                    <input
                      type="button"
                      onClick={() => {
                        this.setState({ participantListUpdated: false });
                        this.updateAddress();
                      }}
                      value={translations.emarketplace.update}
                      className="btn update_btn"
                    />
                    <div
                      hidden
                      className="text-danger error-message text-center"
                      ref={(ref) => {
                        this.addressUpdateError = ref;
                      }}
                    >
                      <span>{translations.emarketplace.address_error}</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {this.state.addressUpdated && this.state.participantList.length > 0 ? (
          <div className="prd_details_add_box">
            <div className="details_form_box">
              <span className="title active">{translations.emarketplace.participants}</span>
              {this.state.showLoader ? (
                <div className="loader-small">
                  <img src="/emarketplace/images/small_loader.gif" alt="" />
                </div>
              ) : (
                <div className="participant_list_holder">
                  <ul>
                    {this.state.participantList.map((item) => (
                      <li key={item.id} className="participant-popover">
                        <span className="ico">
                          <img src="/emarketplace/images/phone_book_ico.png" alt="" />
                        </span>
                        <div className="content">
                          <span className="name">{item.name}</span>
                          <span className="email">
                            <a href={`mailto:${item.email}`}>{item.email}</a>
                          </span>
                        </div>
                        <span className="link_ico">
                          <InfoPopover
                            title={item.name}
                            popoverContent={`<p>Company: ${
                              item.user_company_name ? item.user_company_name : "-"
                            }</p><p>Email: ${item.email ? item.email : "-"}
                            </p><p>Contact: ${item.office_phone ? item.office_phone : "-"}</p>`}
                          />
                          <button
                            id={`trash_${item.id}`}
                            className="participant-trash-icon"
                            onClick={() => {
                              this.removeParticipant(item.id);
                            }}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={`prd_details_add_box ${this.state.showParticipantsError}`}>
            <div className="details_form_box">
              <span className="title active">{translations.emarketplace.participants}</span>
              {this.state.showLoader ? (
                <div className="loader-small">
                  <img src="/emarketplace/images/small_loader.gif" alt="" />
                </div>
              ) : (
                <div>
                  <span className="text-primary">{translations.emarketplace.no_participants}</span>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-sm-12">
            <div className="text-center">
              <button
                id="submit-request"
                className="btn btn-primary"
                type="submit"
                onClick={() => {
                  this.submitRequest();
                }}
                ref={(ref) => {
                  this.submitRequestButton = ref;
                }}
              >
                {translations.emarketplace.submit}
                {this.state.formSubmitting ? <i className="fa fa-cog fa-spin fa-fw" /> : ""}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  participantsData: state.participantReducers,
});

const mapDispatchToProps = (dispatch) => ({
  getParticipants: (shippingDetails) => dispatch(getParticipantList(shippingDetails)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EMShippingDetails);
