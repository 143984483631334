import React from "react";
import { showCurrency, findUom, findLotTotalLic } from "../../common";
import { bidValue, latestBidBeforeAuctionForLot, latestBidDuringAuctionForLot } from "../../bidCommon";
import { fetchBidsDetails } from "../../../../actions/bidActions";
import AllParticipantsBestBidForLotModal from "./modal/AllParticipantsBestBidForLotModal";
import { fetchBlicsDetails } from "../../../../actions/bidLineItemComponentActions";
import { useDispatch, useSelector } from "react-redux";
import features from "@/utils/features";

/**
 *
 * @description HOST MONITOR TAB
 *
 * This will show bid value when expand line item or expand participant
 * or when expand line item and participant both at the same time
 */
export default function ParticipantBidValueForLotAndLineItem(props) {
  const { lot, currency, ep, isLineItemDetail, lotLics, auctionTab, lic = {}, isLoadingStatus } = props;

  const dispatch = useDispatch();
  const { bidList, blicList } = useSelector(({ lotReducers, bidLineItemComponentReducers }) => ({
    bidList: lotReducers.bidList,
    blicList: bidLineItemComponentReducers.bidList,
  }));

  function getBestBidForParticipant() {
    const { LBids, bidLineItemComponents, event, weightedRfqOrAuction } = props;
    const bestBid = auctionTab
      ? latestBidDuringAuctionForLot(LBids, [ep], event, weightedRfqOrAuction)?.reverse()[0]
      : latestBidBeforeAuctionForLot(lot, LBids, ep, event);

    let lineItemComponent;

    // return best bid if feature switch is disable and it's a lot row
    if (features.disabled("show_total_blic_price") && !isLineItemDetail) {
      return bestBid;
    } else if (features.enabled("show_total_blic_price") && !isLineItemDetail) {
      // get total lic if feature switch is enabled and it's a lot row
      lineItemComponent = lotLics.find((l) => l.id === lot.lot_total_lic_id);
    } else {
      // get lic if it's a line item row
      lineItemComponent = lotLics.find(
        (l) => l.lot_component_id === lic.lot_component_id && l.line_item_id === props.lineItem.id
      );
    }

    return bestBid && lineItemComponent && bidLineItemComponents.length > 0
      ? bidLineItemComponents.find((b) => b.line_item_component_id === lineItemComponent?.id && b.bid_id === bestBid.id)
      : null;
  }

  function getCurrency(amount) {
    const totalLic = findLotTotalLic(lotLics, lot.lot_total_lic_id);
    const precision = lic.decimal_place ?? totalLic?.decimal_place;

    return showCurrency(amount, currency, precision, props.locale, props.translations.number_format);
  }

  const bestBidForParticipant = getBestBidForParticipant();

  // If best bid not present return currency without modal
  if (!bestBidForParticipant) {
    return <Row>{!isLineItemDetail ? "-" : getCurrency("-")}</Row>;
  }

  const val = bidValue(bestBidForParticipant, lot, "host", lotLics, true);
  const valWithCurrency = getCurrency(val);
  const allUom = props.unitSetUoms.concat(props.unitOfMeasures);
  const uom = findUom(lot.unit_of_measure_id, allUom);

  return (
    <Row>
      {valWithCurrency !== "-" ? (
        <AllParticipantsBestBidForLotModal
          {...props}
          viewComponent={valWithCurrency}
          uom={uom}
          showOverallScore={false}
          apiCallingFunction={() =>
            isLineItemDetail
              ? dispatch(fetchBlicsDetails(lic?.id, !auctionTab, false, lot.event_id, ep.user_id, auctionTab))
              : dispatch(fetchBidsDetails(lot?.id, false, lot.event_id, ep.user_id, auctionTab))
          }
          bidList={isLineItemDetail ? blicList : bidList}
          isLoading={isLoadingStatus}
          allUom={allUom}
          modalType={isLineItemDetail ? "LineItem" : "Lot"}
          beforeAuction={!auctionTab}
        />
      ) : (
        valWithCurrency
      )}
    </Row>
  );
}

const Row = ({ children }) => (
  <div className={`mycell supplier participant-bid-value`}>
    <div className="row">
      <div className="mycell">{children}</div>
    </div>
  </div>
);
