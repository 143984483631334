import PropTypes from "prop-types";

import MDCheckbox from "./checkbox";
import { useCheckboxAttribute } from "@adeattwood/react-form";
import { withReactFormAttribute } from "../input/react-form";

const CheckboxPropTypes = {
  /**
   * The react from attribute that this input will control
   */
  attribute: PropTypes.string.isRequired,
};

function withReactFormCheckboxAttribute(BaseInput) {
  return withReactFormAttribute(BaseInput, useCheckboxAttribute);
}

/**
 * @type {React.FC<PropTypes.InferProps<typeof CheckboxPropTypes>>}
 */
const Checkbox = withReactFormCheckboxAttribute(MDCheckbox);
Checkbox.displayName = "ReactFormCheckbox";
Checkbox.propTypes = CheckboxPropTypes;

export default Checkbox;
