import { createSelector } from "@reduxjs/toolkit";

// This function checks the existing objects and checks if the new object already exists (in case of update)
// If yes then replaces it or else adds the new object to the list
export const getUpdatedValue = (objects, obj, updateBy = "id") => {
  // We slice the objects array so that it doesn't pass the object with same reference of previous state
  // So if we don't pass this, any change to this will update the state directly of parent component
  let values = objects.slice();
  let isAlreadyExist = false;
  values = values.map((value) => {
    if (value[updateBy] === obj[updateBy]) {
      value = obj;
      isAlreadyExist = true;
    }
    return value;
  });
  if (!isAlreadyExist) {
    values.push(obj);
  }
  return values;
};

/**
 *
 * @description Set event round id to localstorage to persist eventRound dropdown's state
 * @param {Boolean} isHost
 * @param {Number} roundId
 */
export const setEventRoundToLocalStorage = (isHost, roundId) => {
  const roundKey = `event_round_${isHost ? "host" : "participant"}`;
  localStorage.setItem(roundKey, roundId);
};

/** @param {import("@/store").RootState} state */
export const selectLotAutomationState = createSelector(
  (state) => state.lotReducers.lot_automation_state,
  (lotAutomationState) => ({
    isEnforced: lotAutomationState === "enforced",
    isEnabled: lotAutomationState === "enabled",
    isDisabled: !lotAutomationState || lotAutomationState === "disabled",
  })
);

/**
 * select lot automation state and check if it is enforced and not in sandpit
 * @param {import("@/store").RootState} state
 * @returns {boolean} lAEnforcedAndNotSandpit
 */
export const lotAutomationEnforcedAndNotSandpit = (state) =>
  selectLotAutomationState(state).isEnforced && !state.lotReducers.in_sandpit;
