import React, { Component } from "react";
import Modal from "react-modal";

export default class UnitSetModal extends Component {
  constructor() {
    super();
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeydown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown, false);
  }

  handleKeydown(event) {
    const { handleFormSubmit } = this.props;
    if (event.keyCode === 13) {
      handleFormSubmit(event);
    }
  }

  render() {
    const {
      modalIsOpen,
      closeModal,
      translations,
      unitSetId,
      handleFormSubmit,
      handleUnitSetNameChange,
      unitSetName,
      addUOM,
      classFromEventState,
      unitOfMeasures,
      handleUOMNameChange,
      handleUOMRatioChange,
      removeUOM,
      defaultUOM,
      handleDefaultUOMNameChange,
    } = this.props;
    return modalIsOpen ? (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-content col-md-4 col-sm-4 custom-field-popup"
        appElement={document.getElementById("advanced-lots-body")}
      >
        <div className="block block-transparent remove-margin-b">
          <div className="block-header bg-primary-default">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={closeModal}>
                  <i className="ion-close" />
                </button>
              </li>
            </ul>
            <h3 className="block-title">{unitSetId ? translations.edit_unit_set : translations.create_new_unit_set}</h3>
          </div>
          <div className="block-content">
            <div className="form">
              <div className="col-md-12">
                <div className="input inline form-group">
                  <div className="inline form-group">
                    <label htmlFor="unit_set_name">{translations.uom_set}</label>
                    <input
                      autoComplete="off"
                      className="form-control"
                      type="text"
                      id="unit_set_name"
                      value={unitSetName}
                      onChange={handleUnitSetNameChange}
                      placeholder={translations.uom_set}
                    />
                  </div>
                  <div className="form-group">
                    <div id="unit_set_option_fields">
                      <label>{translations.uom}</label>
                      <div className="fields">
                        <input
                          className="default_uom form-control"
                          autoComplete="off"
                          type="text"
                          id="default_uom"
                          value={defaultUOM.name}
                          onChange={handleDefaultUOMNameChange}
                        />
                      </div>
                      <label>{translations.default_group}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 fields">
                {unitOfMeasures.map((uom, i) => {
                  if (uom["_destroy"] === undefined) {
                    return (
                      <div className="col-md-12 input fields message-item p-0 m-b10" key={i}>
                        <div className="col-md-5 col-sm-5 col-xs-12 p-l0">
                          <span>
                            <label>{translations.conversion}</label>&nbsp;
                          </span>
                          <input
                            className="form-control"
                            autoComplete="off"
                            type="text"
                            value={uom.name}
                            onChange={(e) => handleUOMNameChange(e, i)}
                          />
                        </div>
                        <div className="col-md-5 col-sm-5 col-xs-12">
                          <span>
                            <label className="conversion_label">
                              &nbsp;
                              {defaultUOM.name ? `${defaultUOM.name} =` : ""}
                            </label>
                          </span>
                          <input
                            autoComplete="off"
                            className="uom_precision form-control"
                            type="text"
                            value={uom.ratio}
                            onChange={(e) => handleUOMRatioChange(e, i)}
                          />
                        </div>
                        <div className="col-md-2 col-sm-2 col-xs-12">
                          <span>
                            <label>&nbsp;</label>
                          </span>
                          <a
                            title="Remove Unit of Measure"
                            className="button button-mini button-black icon-only clearfix btn btn-default btn-action xs-full-width remove_nested_fields"
                            href="#"
                            onClick={() => removeUOM(i)}
                          >
                            <i className="fa fa-trash-o red-color"></i>
                          </a>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <a
                    className={`btn auto_width_btn ${classFromEventState} status-event sm-butt m-b20`}
                    id="addUOM"
                    onClick={() => addUOM()}
                  >
                    <i className="fa fa-plus"></i>
                    &nbsp;{translations.add_uom}
                  </a>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="input inline form-group">
                <div className="inline">
                  <button name="commit" className="btn btn-primary pull-right" onClick={handleFormSubmit}>
                    {translations.save}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    ) : (
      <></>
    );
  }
}
