import React, { useMemo } from "react";
import type { CommonFilterInputProps } from "@/components/contract/new/listings/filter-input";
import { Multiselect } from "multiselect-react-dropdown";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { checkboxFilterToggled, selectCheckboxFilter } from "@/slices/contracts/listing";
import { t } from "@/i18n";

interface Option {
  id: string;
  text: string;
}

const optionLabels: Record<string, string> = {
  true: "yes",
  false: "no",
} satisfies { [K in `${boolean}`]: string };

export function FilterInputBoolean({ attribute }: CommonFilterInputProps) {
  const dispatch = useAppDispatch();
  const values = useAppSelector((state) => selectCheckboxFilter(state, attribute));
  const options = useMemo(() => Object.entries(optionLabels).map(([id, text]) => ({ id, text: t(text) })), []);
  const valueOptions = useMemo(() => values?.map((v) => ({ id: v, text: t(optionLabels[`${v}`]) })) ?? [], [values]);
  const toggleItem = (_: Option[], { id }: Option) => dispatch(checkboxFilterToggled(attribute, id === "true"));
  return (
    <Multiselect
      options={options}
      selectedValues={valueOptions}
      onSelect={toggleItem}
      onRemove={toggleItem}
      displayValue="text"
      showCheckbox
      placeholder=""
    />
  );
}
