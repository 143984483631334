import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(this.element).modal({
      keyboard: this.element.getAttribute("data-backdrop") !== "static",
      backdrop: this.element.getAttribute("data-backdrop") ?? true,
    });

    $(this.element).on("hidden.bs.modal", this.#onHide.bind(this));
    this.element.addEventListener("hidden.bs.modal", this.#onHide.bind(this));

    this.element
      .querySelectorAll("[data-bs-dismiss]")
      .forEach((element) => element.addEventListener("click", this.#close.bind(this)));

    // Not sure if we want to to this
    $(this.element).modal("show");
  }

  #close() {
    console.log("close");
    $(this.element).modal("hide");
  }

  #onHide() {
    console.log(this.element.getAttribute("data-reload"));
    if (this.element.getAttribute("data-reload") === "true") {
      window.location.reload();
    }
  }
}
