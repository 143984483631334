// #ENTRYPOINT
import React, { useState, useMemo } from "react";
import { Input } from "@/cl/input";
import { useToggle } from "@/hooks/use-toggle";
import StackPanel from "./stack-panel";
import { clsx } from "clsx";
import { Spinner } from "@/cl/spinner";
import { t } from "@/i18n";
import type { Item, CheckedItem } from "./types";

import styles from "./multi_level_select.module.scss";
import { ItemsProvider } from "./items-context";

export interface MultiLevelSelectProps {
  /**
   * Name for the multi-level-select field
   */
  name: string;
  items: Item[];
  /**
   * A map of types to there input names.
   */
  type_name_map: Record<string, string>;
}

/**
 * This function will get us the names of checked groups
 */
export const getCheckedGroups = (checkedItems: CheckedItem[]): string[] => {
  return Array.from(new Set(checkedItems.map((checkedItem) => checkedItem.group)));
};

const getFilteredItems = (items: Item[], search: string) => {
  if (!search) return items;

  return items.filter((item) => {
    return item.label.toLowerCase().includes(search.toLowerCase());
  });
};

export default function MultiLevelSelect({ name, items, type_name_map }: MultiLevelSelectProps) {
  const [checkedItems, setCheckedItems] = useState<CheckedItem[]>([]);
  const [search, setSearch] = useState("");
  const [isOpen, toggleOpen] = useToggle();

  const checkedGroupsLength = useMemo(() => getCheckedGroups(checkedItems).length, [checkedItems]);
  const filteredItems = useMemo(() => getFilteredItems(items, search), [items, search]);
  const caretClass = isOpen ? "fa-caret-up" : "fa-caret-down";

  return (
    <ItemsProvider value={{ items, type_name_map, checkedItems, setCheckedItems }}>
      <div className={clsx("md-bs5 my-2", styles.container)}>
        <div className={clsx("align-items-center d-flex justify-content-between w-100 p-2", styles.header)}>
          <div className="d-flex">
            <button className={clsx("me-2", styles.toggleButton)} type="button" onClick={() => toggleOpen.set(!isOpen)}>
              <i className={clsx("fa", caretClass)} />
            </button>
            <div className="d-flex align-items-center gap-2">
              {name} <div className={clsx("rounded-circle selection-count", styles.badge)}>{checkedGroupsLength}</div>
            </div>
            {items.length === 0 && <Spinner className="ms-2" size="sm" title={t("loading_please_wait")} />}
          </div>
          <div hidden={!isOpen}>
            <Input name="select-search" type="search" onChange={(e) => setSearch(e.target.value.trim())} />
          </div>
        </div>
        <StackPanel filteredItems={filteredItems} hidden={!isOpen} />
      </div>
    </ItemsProvider>
  );
}
