import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { useInputTypeOptions, ModulePrefixes, AvailableModuleGroups, ModuleGroups, useModuleUiConfig } from "./types";
import { Button } from "@/cl/button";
import CustomField from "@/common-prop-types/custom-field";
import { Icon } from "@/cl/icon";
import TableRowEditState from "./table-row-edit-state";
import { AuthPropType } from ".";
import { t } from "@/i18n";
import { useAttribute, useListAttribute } from "@adeattwood/react-form";
import { useRemoveAttributeErrors, useRemoveItem } from "./use-remove-item";
import { useCompanyId } from "./company-id-context";
import { listCustomFields } from "@/actions/customField";
import { useDispatch } from "react-redux";
import { Tag } from "./tag";
import { Ellipsis } from "@/cl/ellipsis";

/**
 * Tests to see if a custom field item type is considered mandatory. A custom
 * field must be both active and required to be mandatory.
 *
 * @param {{required: boolean; active: boolean;} | undefined} customFieldItemType
 */
export function isMandatory(customFieldItemType) {
  return customFieldItemType?.required && customFieldItemType?.active;
}

function itemTypeForModule(itemTypes, module) {
  return itemTypes.find(({ item_type }) => item_type.startsWith(ModulePrefixes[module])) || {};
}

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  display: isDragging ? "table" : "table-row",
});

function useGetStatusText(t) {
  return React.useCallback(
    (item) => {
      if (isMandatory(item)) {
        return t("centralised_custom_fields.mandatory");
      }

      if (item.active) {
        return t("centralised_custom_fields.available");
      }

      return t("centralised_custom_fields.inactive");
    },
    [t]
  );
}

function StatusBlock({ name, description, brand }) {
  return (
    <Tag brand={brand} className="mb-2">
      <strong>{name}:</strong> {description}
    </Tag>
  );
}

StatusBlock.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  brand: PropTypes.oneOf(["success", "danger", "base"]),
};

function ModuleStatusBlocks({ module, customField }) {
  const getStatusText = useGetStatusText(t);
  const itemType = itemTypeForModule(customField.custom_column_item_types, module);

  const moduleStatusMap = {
    [ModuleGroups.Status]: {
      name: t("centralised_custom_fields.status"),
      description: getStatusText(itemType),
      brand: isMandatory(itemType) || itemType.active ? "success" : "danger",
    },
    [ModuleGroups.ShowInInvite]: {
      name: t("centralised_custom_fields.invite"),
      description: itemType.include_in_invite
        ? t("centralised_custom_fields.show")
        : t("centralised_custom_fields.do_not_show"),
    },
    [ModuleGroups.DisplayAsColumn]: {
      name: t("centralised_custom_fields.column"),
      description: itemType.show_in_summary
        ? t("centralised_custom_fields.display")
        : t("centralised_custom_fields.do_not_display"),
    },
  };

  return AvailableModuleGroups[module].map((group) => {
    return <StatusBlock key={group} {...moduleStatusMap[group]} />;
  });
}

/**
 * Renders the table row for a custom field. This will be draggable if there is
 * nothing in the search box and the list custom fields are not being filtered.
 * When we are filtering the custom fields there is no way to know where the
 * user wants to position the custom field so we disable them.
 */
function MaybeDraggableTr({ children, id, index, canEdit }) {
  const { value: searchValue } = useAttribute("search");
  const isDraggable = !(searchValue && searchValue.length) && canEdit;

  if (!isDraggable) {
    return <tr>{children}</tr>;
  }

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <tr
          key={id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          role="row"
        >
          {children}
        </tr>
      )}
    </Draggable>
  );
}

MaybeDraggableTr.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

function useToggleEditing(index, customField) {
  const [isEditing, setIsEditing] = React.useState(false);
  const removeAttributeErrors = useRemoveAttributeErrors();
  const companyId = useCompanyId();
  const dispatch = useDispatch();
  const { remove: baseRemove } = useListAttribute(`customFields`);
  const remove = useRemoveItem("customFields", baseRemove);

  const toggleEditing = () => {
    if (customField.id === -1) {
      remove(index);
    } else {
      setIsEditing(!isEditing);
      dispatch(listCustomFields({ companyId }));
      removeAttributeErrors(`customFields.${index}`);
    }
  };

  return [isEditing, toggleEditing];
}

function TableRow({ customField, index, auth }) {
  const modules = useModuleUiConfig();
  const [isEditing, toggleEditing] = useToggleEditing(index, customField);
  const inputTypeOptions = useInputTypeOptions();

  // A user can edit the item if they have edit permission on any of the
  // modules
  const canEdit = Object.values(auth).some((item) => item === true);

  if (isEditing || customField.id === -1) {
    return <TableRowEditState customField={customField} index={index} onSubmit={toggleEditing} auth={auth} />;
  }

  const editButtonTitle = t("centralised_custom_fields.edit_custom_field");

  return (
    <MaybeDraggableTr canEdit={canEdit} id={customField.id.toString()} index={index}>
      <Ellipsis as="td" title={customField.name} maxWidth={250}>
        <strong>{customField.name}</strong>
      </Ellipsis>
      <td style={{ width: "16.6%" }}>
        {inputTypeOptions.find((item) => item.value === customField.column_type_cd).label}
      </td>
      {modules.map(({ module }) => (
        <td key={module}>
          <ModuleStatusBlocks module={module} customField={customField} />
        </td>
      ))}
      {canEdit && (
        <td style={{ minWidth: "140px", textAlign: "right" }}>
          <Button brand="md-primary" icon type="button" onClick={toggleEditing} title={editButtonTitle}>
            <Icon name="pencil-fill" />
          </Button>
        </td>
      )}
    </MaybeDraggableTr>
  );
}

TableRow.propTypes = {
  /**
   * The index in the list this row is getting rendered
   */
  index: PropTypes.number.isRequired,
  /**
   * The custom field that this row will render
   */
  customField: PropTypes.shape(CustomField).isRequired,
  auth: PropTypes.shape(AuthPropType).isRequired,
};

export default TableRow;
