import React, { Component } from "react";
import PropTypes from "prop-types";
import CloseEvent from "./CloseEvent";
import AutoReminderEmail from "./AutoReminderEmail";
import CommonSettingOption from "./CommonSettingOption";
import StakeholderInvitation from "./StakeholderInvitation";
import { connect } from "react-redux";
import { t } from "@/i18n";
import features from "@/utils/features";

import { mdSettingConfiguration, unauthorized } from "../../../../actions/SimSettingActions";
import SealedResults from "./SealedResults";
import AutomaticTemplateSelection from "./AutomaticTemplateSelection";
import { selectCompanyTier } from "@/slices/company";

class MDSettings extends Component {
  onUpdateValue = (dataValue, name) => {
    const {
      translations,
      company: { id },
      configuration_setting_values,
      configuration_setting_enum_values,
      currentUserEditConfigPermission,
    } = this.props;
    if (!currentUserEditConfigPermission) {
      this.props.unauthorized({
        configuration_setting_values: configuration_setting_values,
        unauthorized: translations.unauthorized,
      });
      return false;
    }
    let setting_type_cd =
      [
        "allow_hosts_to_invite_other_approved_hosts",
        "allow_hosts_to_invite_new_users",
        "allow_participant_questionnaire_upload",
        "allow_participants_to_edit_questionnaire",
      ].indexOf(name) > -1
        ? 4
        : 2;
    let data = [
      id,
      {
        configuration_setting: {
          setting: JSON.stringify([
            {
              company_id: id,
              setting_type_cd: setting_type_cd,
              setting_cd: configuration_setting_enum_values[name],
              value: dataValue,
            },
          ]),
        },
      },
    ];
    this.props.mdSettingConfiguration(data, id);
  };

  render() {
    const { translations, SettingType, configuration_setting_values, durationType } = this.props;
    let { data = {}, hasGoldSourcingLicense, hasGoldSrmLicense } = this.props;

    const commonSettingOptions = [
      "allow_licence_purchases",
      "qq_allow_to_delete_event",
      "allow_participant_questionnaire_upload",
      "allow_participants_to_edit_questionnaire",
      ...(features.enabled("supplier_collaboration") ? ["supplier_collaboration"] : []),
    ];

    if ("configuration_setting_values" in data) {
      Object.assign(configuration_setting_values, data.configuration_setting_values);
    }
    const commonProps = {
      translations,
      configuration_setting_values,
      onValueChange: this.onUpdateValue,
    };

    const automationSettings = [
      { name: "automatic_lot_template_selection", condition: hasGoldSourcingLicense },
      { name: "automatic_supplier_selection", condition: hasGoldSrmLicense },
      { name: "automatic_event_template_selection", condition: hasGoldSourcingLicense },
      { name: "automatic_questionnaire_template_selection", condition: hasGoldSourcingLicense },
      { name: "automatic_document_template_selection", condition: hasGoldSourcingLicense },
    ];

    const filteredAutomationSettings = automationSettings
      .filter((setting) => setting.condition)
      .map((setting) => setting.name);

    return (
      <>
        <h2 className="main-heading">{translations.admin_views.configuration.time_scales}</h2>
        <div className="configuration_fieldset">
          <input type="hidden" name="configuration[setting_type]" id="configuration_setting_type" value={SettingType} />
          <CloseEvent durationType={durationType} {...commonProps} />
          <AutoReminderEmail {...commonProps} />
        </div>
        <h2 className="main-heading">{translations.structure_settings}</h2>
        <StakeholderInvitation {...commonProps} />
        <SealedResults {...commonProps} />
        {commonSettingOptions?.map((settingOption) => (
          <div className="configuration_fieldset" key={settingOption}>
            <CommonSettingOption
              name={settingOption}
              toolTipData={translations.configuration[`${settingOption}_tooltip`]}
              settingTitle={translations[settingOption]}
              {...commonProps}
            />
          </div>
        ))}
        {hasGoldSourcingLicense && (
          <>
            <h2 className="main-heading">{t("automation_settings")}</h2>
            <div className="form-item-block">
              {filteredAutomationSettings.map((setting) => (
                <AutomaticTemplateSelection
                  key={setting}
                  configuration_setting_values={configuration_setting_values}
                  onChange={this.onUpdateValue}
                  settingName={setting}
                />
              ))}
            </div>
          </>
        )}
      </>
    );
  }
}

MDSettings.propTypes = {
  translations: PropTypes.object.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  configuration_setting_values: PropTypes.object.isRequired,
  configuration_setting_enum_values: PropTypes.object.isRequired,
  currentUserEditConfigPermission: PropTypes.bool.isRequired,
  unauthorized: PropTypes.func.isRequired,
  mdSettingConfiguration: PropTypes.func.isRequired,
  SettingType: PropTypes.string.isRequired,
  durationType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  hasGoldSourcingLicense: PropTypes.bool.isRequired,
  hasGoldSrmLicense: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.simSettingReducers,
  hasGoldSourcingLicense: selectCompanyTier(state, "sourcing") === "gold",
  hasGoldSrmLicense: selectCompanyTier(state, "srm") === "gold",
});

const mapDispatchToProps = (dispatch) => ({
  mdSettingConfiguration: (data) => dispatch(mdSettingConfiguration(data)),
  unauthorized: (data) => dispatch(unauthorized(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MDSettings);
