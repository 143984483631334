import React, { Component, Fragment } from "react";
import { isPrice, smallOrMiniCell } from "../../common";
import Tooltip from "../../../common/Tooltip";
import LotComponentCommonHeading from "./LotComponentCommonHeading";
import { expandLcForParticipant } from "../../customCollapse";

export default class LotComponentNameForOverview extends Component {
  render() {
    const {
      event,
      translations,
      participatingParticipants,
      permissionToView,
      lot,
      firstLot,
      lotLcs,
      weightedRfqOrAuction,
      isLcOpened,
      auctionTab,
      isJapaneseEvent,
    } = this.props;

    const { current_value_html, qualification_value_html } = translations;
    const { lot_current_value, lot_qualification_value } = translations.infos;
    return (
      <div
        className={`row ${lot.is_event_total ? "lot-totals-row component-row" : "component-row"} lot_row_${
          lot.id
        } component-overide-visible`}
      >
        <div className="row-title blank blank-top">&nbsp;</div>
        <div className={`mycell lot-headings primary  ${firstLot ? "lot-heading-steptwo" : ""}`}>
          <div className="row">
            <div className="mycell cell-title-header" title={current_value_html}>
              <span className="cell-content">{current_value_html}</span>
              <Tooltip title={lot_current_value.title} body={lot_current_value.body} />
            </div>
            <div className="mycell cell-title-header" title={qualification_value_html}>
              <span className="cell-content">{qualification_value_html}</span>
              <Tooltip title={lot_qualification_value.title} body={lot_qualification_value.body} />
            </div>
          </div>
        </div>
        {permissionToView &&
          participatingParticipants.map((ep, epIndex) => {
            return (
              <Fragment key={`${ep.id}-${epIndex}-${Date.parse(ep.updated_at)}`}>
                {expandLcForParticipant(isLcOpened, ep.id) ? (
                  <div className={`mycell supplier supplier-cell-${ep.user_id} supplier-border`}>
                    <div className="row">
                      {lotLcs.map((lc, lcIndex) => {
                        return (
                          <div
                            key={`${lc.id}-${lcIndex}-${Date.parse(lc.updated_at)}`}
                            className={`mycell cell-title lot-component-name ${isPrice(lc) ? "" : "non-price"} ${
                              lotLcs.size - 1 == lcIndex && participatingParticipants.length - 1 == epIndex
                                ? "lst-border-right"
                                : ""
                            }`}
                          >
                            <div className="cell-inner">
                              <span title={lc.name}>{isPrice(lc) && lc.name}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`mycell supplier supplier-cell-${ep.user_id}  supplier-hidden-secondary ${
                      participatingParticipants.length - 1 == epIndex ? "lst-border-right" : ""
                    }`}
                  >
                    <div className="row">
                      <div className="mycell cell-title"></div>
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        <div className={`mycell lot-headings cover ${firstLot && "lot-heading-steptwo"}`}>
          <div className="row">
            {this.bestBidReceivedView()}
            <LotComponentCommonHeading
              event={event}
              translations={translations}
              weightedRfqOrAuction={weightedRfqOrAuction}
              auctionTab={auctionTab}
              isJapaneseEvent={isJapaneseEvent}
              lot={lot}
            />
          </div>
        </div>
      </div>
    );
  }

  bestBidReceivedView() {
    const { displayScores, translations, weightedRfqOrAuction } = this.props;
    const { best_overall_score, best_bid_value } = translations;
    const { lot_bestoverallscore, lot_best_bid_received } = translations.infos;
    if (displayScores) {
      return (
        <>
          <div className={`mycell cell-title-header ${smallOrMiniCell(weightedRfqOrAuction)}`}>
            <span className="cell-content">{best_overall_score}</span>
            <Tooltip title={lot_bestoverallscore.title} body={lot_bestoverallscore.body} />
          </div>
          <div className="mycell cell-title">
            <div className="cell-inner" title={best_bid_value}>
              <span className="cell-content toggleSuppliers-icon">
                <a href="#" className="white icon-only toggleSuppliers ion-ios-minus-outline"></a>
                {best_bid_value}
              </span>
              <Tooltip title={lot_best_bid_received.title} body={lot_best_bid_received.body} />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="mycell cell-title-header">
          <div className="cell-inner" title={best_bid_value}>
            <span className="cell-content toggleSuppliers-icon">
              <a href="#" className="white icon-only toggleSuppliers ion-ios-minus-outline"></a>
              {best_bid_value}
            </span>
            <Tooltip title={lot_best_bid_received.title} body={lot_best_bid_received.body} />
          </div>
        </div>
      );
    }
  }
}
