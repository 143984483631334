import axios from "axios";
import cookies from "browser-cookies";

import config from "./config";

const inSandpit = config.isQuickQuotes && cookies.get("sandpit") ? cookies.get("sandpit") : false;

function getUpdatedUrl(url) {
  if (config.isQuickQuotes) {
    return url.indexOf("?") === -1 ? `${url}?in_sandpit=${inSandpit}` : `${url}&in_sandpit=${inSandpit}`;
  }
  return url;
}

export const get = (url) => axios.get(getUpdatedUrl(url), { headers: config.headers });

export const post = (url, data) => axios.post(url, { in_sandpit: inSandpit, ...data }, { headers: config.headers });

export const remove = (url) => axios.delete(getUpdatedUrl(url), { headers: config.headers });

export const put = (url, data) => axios.put(url, { in_sandpit: inSandpit, ...data }, { headers: config.headers });
