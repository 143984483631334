import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ModalComponent } from "../../../../../../hoc/modalHOC/CommonModal";
import AddEventForm from "./AddEventForm";
import { createMsrfqRound } from "../../../../../../actions/lotActions";

export default function AddEventRoundFromModal({ viewComponent, viewComponentClass = "", ...rest }) {
  const { translations, event } = useSelector((state) => state.lotReducers);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [formState, setFormState] = useState({
    event_round: { deadline: "", allow_worse_bids: false, show_rank: false },
    participant_ids: [],
    dropped_participant_ids: [],
  });

  const toggleModal = () => setIsOpen((isOpen) => !isOpen);
  const handleSubmit = () => {
    // parse date
    let parsedDate = "";
    if (!!formState.event_round.deadline)
      parsedDate = moment(formState.event_round.deadline).format("YYYY-MM-DD HH:mm");

    const payload = {
      ...formState,
      event_round: {
        ...formState.event_round,
        deadline: parsedDate,
      },
    };
    const successCallback = () => {
      toggleModal();
      // reload whole page to update rails ui
      window.location.reload();
    };
    dispatch(createMsrfqRound(payload, event.id, successCallback, translations.event_round_deadline_must_be_in_future));
  };

  return (
    <>
      <a href="#" onClick={toggleModal} className={viewComponentClass}>
        {viewComponent}
      </a>
      <ModalComponent
        modalHocProps={{ toggleModal, open: isOpen }}
        content={<AddEventForm formState={formState} setFormState={setFormState} {...rest} />}
        header={translations.event + ": " + event.name}
        modalCloseButtonClass="btn btn-sm btn-default"
        modalCloseButtonText={
          <>
            {translations.cancel} <i className="fa fa-times"></i>
          </>
        }
        modalSubmitButtonClass="btn btn-sm btn-primary"
        modalSubmitButtonText={
          <>
            {translations.save} <i className="fa fa-check"></i>
          </>
        }
        modalSubmitFunction={handleSubmit}
      />
    </>
  );
}
