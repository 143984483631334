import React, { useEffect } from "react";
import Modal from "react-modal";
import ModalHOC from "./ModalHOC";
import { useDispatch, useSelector } from "react-redux";
import { sendCommonModalSubmitRequest } from "./modalHOC.duck";

const objectOrFunction = (component, props) => {
  if (typeof component === "function") {
    return component(props);
  }
  return component;
};

/**
 *  @param {modalCloseButton} props required
 *  @param {modalCloseButtonText} props
 *  @param {header} props
 *  @param {modalCloseButtonClass} props
 *  @param {modalSubmitButtonText} props
 *  @param {modalSubmitFunction} props
 *  @param {modalSubmitButtonClass} props
 *
 */

export const ModalComponent = (props) => {
  // 1) content will be the body of the modal.
  // 2) header will the the title which will display on the header.
  // 3) There will be two button one is mandatory(modalCloseButton)
  // which will close the modal and the second will be optional and will use
  // to submit from or to perform any other operations.
  // 4) modalCloseButtonText this should text or any tag which will display
  // for close button
  // 5) modalCloseButtonClass contains the class name to add on cancel button.
  // 6) modalSubmitButtonText will have text display on submit button.
  // 7) modalSubmitFunction contains function which will be used while
  // submitting the form.
  // 8) modalSubmitButtonClass will have classes used on submitting button.

  const dispatch = useDispatch();
  const {
    content,
    header,
    modalCloseButtonText,
    modalCloseButtonClass,
    modalSubmitButtonText,
    modalSubmitFunction,
    modalSubmitButtonClass,
    modalCloseFunction,
    modalHocProps: { toggleModal, open },
  } = props;

  //Get state from redux-store
  const states = useSelector((state) => state.modalHOCReducers);

  const handleSubmit = () => {
    dispatch(sendCommonModalSubmitRequest());
    modalSubmitFunction && modalSubmitFunction();
  };
  const handleClose = () => {
    modalCloseFunction && modalCloseFunction();
    toggleModal();
  };

  useEffect(() => {
    Modal.setAppElement("div");
  }, []);

  return (
    <Modal isOpen={open} toggle={toggleModal} className="modal-content col-md-8 col-sm-8 custom-field-popup">
      <div
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            handleSubmit && handleSubmit();
          }
        }}
        className="block  block-transparent remove-margin-b"
      >
        <div className="block-header bg-primary-default">
          <ul className="block-options">
            <li>
              <button type="button" onClick={() => handleClose()}>
                <i className="ion-close" />
              </button>
            </li>
          </ul>
          <h3 className="block-title">{header}</h3>
        </div>

        <div className="block-content">{objectOrFunction(content, { toggleModal })}</div>
        <div className="pop-footer modal-footer">
          <div className="col-md-12">
            <button className={modalCloseButtonClass} data-dismiss="modal" type="button" onClick={() => handleClose()}>
              {modalCloseButtonText}
            </button>
            {modalSubmitButtonText && (
              <button
                className={modalSubmitButtonClass}
                data-dismiss="modal"
                type="button"
                disabled={states.isSubmitting}
                onClick={handleSubmit}
              >
                {modalSubmitButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

// Just need to import CommonModal in order to show modal also need to
//  pass required props
const CommonModal = ({ viewComponent, apiCallingFunction, showViewComponent, ...rest }) => (
  // apiCallingFunction is an optional parameter. If calling api is required
  // before opening the pop up then pass the function then rest will be handle.
  <ModalHOC
    // Coming from parent component where modal is calling
    viewComponent={viewComponent}
    viewComponentTitle={rest.viewComponentTitle}
    apiCallingFunction={apiCallingFunction || null}
    modalComponent={(modalHocProps) => <ModalComponent {...rest} modalHocProps={modalHocProps} />}
    showViewComponent={showViewComponent}
  />
);

export default CommonModal;
