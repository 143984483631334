const actionTypes = {
  create_qq_event_success: 'CREATE_QQ_EVENT_SUCCESS',
  fetch_quote_by_id: 'FETCH_QUOTE_BY_ID',
  fetch_lots_by_id: 'FETCH_LOTS_BY_ID',
  fetch_event_participants_by_id: 'FETCH_FETCH_PARTICIPANTS_BY_ID',
  fetch_qq_events: 'FETCH_QQ_EVENTS',
  fetch_participant_groups: 'FETCH_PARTICIPANT_GROUPS',
  fetch_event_participants: 'FETCH_EVENT_PARTICIPANTS',
  qq_header_block: 'FETCH_ADMIN_TEXT_HEADER',
  qq_attributes_block: 'FETCH_ADMIN_TEXT_ATTRIBUTES',
  qq_documents_block: 'FETCH_ADMIN_TEXT_DOCUMENTS',
  qq_prices_block: 'FETCH_ADMIN_TEXT_PRICES',
  qq_questions_block: 'FETCH_ADMIN_TEXT_QUESTIONS',
  qq_participants_block: 'FETCH_ADMIN_TEXT_PARTICIPANTS',
  change_language: 'CHANGE_LANGUAGE',
  qq_header_block_delete: 'FETCH_ADMIN_TEXT_HEADER_DEL',
  qq_attributes_block_delete: 'FETCH_ADMIN_TEXT_ATTRIBUTES_DEL',
  qq_documents_block_delete: 'FETCH_ADMIN_TEXT_DOCUMENTS_DEL',
  qq_prices_block_delete: 'FETCH_ADMIN_TEXT_PRICES_DEL',
  qq_questions_block_delete: 'FETCH_ADMIN_TEXT_QUESTIONS_DEL',
  qq_participants_block_delete: 'FETCH_ADMIN_TEXT_PARTICIPANTS_DEL',
  fetch_user_details: 'FETCH_USER_DETAILS',
  fetch_admin_permission: 'FETCH_ADMIN_PERMISSION',
  fetch_company_details: 'FETCH_COMPANY_DETAILS',
  fetch_bids: 'FETCH_BIDS',
  fetch_bids_summary: 'FETCH_BIDS_SUMMARY',
  error: 'ERROR',
  fetch_messages: 'FETCH_MESSAGES',
  tooltip_locale: 'TOOLTIP_LOCALE',
  change_event_state: 'CHANGE_EVENT_STATE',
  fetch_configuration_limit: 'FETCH_CONFIGURATION_LIMIT',
  show_error: 'SHOW_ERROR',
  show_success: 'SHOW_SUCCESS',
  feature_enabled: 'FEATURE_ENABLED',
  fetch_event_hosts: 'FETCH_EVENT_HOSTS',
  supplier_status: 'SUPPLIER_STATUS',
  update_summary_header: 'UPDATE_SUMMARY_HEADER',
  sandpit_participants: 'SANDPIT_PARTICIPANTS',
  set_listing_parameter: 'SET_LISTING_PARAMETERS',
  fetch_onboarding_statuses: 'FETCH_ONBOARDING_STATUSES',
  fetch_document_templates: 'FETCH_DOCUMENT_TEMPLATES',
  fetch_pipeline_projects: 'FETCH_PIPELINE_PROJECTS',
  clear_pipeline_projects: 'CLEAR_PIPELINE_PROJECTS',
  mutated_events: 'MUTATED_EVENTS',
  currencies: 'SET_CURRENCIES',
  get_material_data: 'GET_MATERIAL_DATA',
  get_participant_list: 'GET_PARTICIPANT_LIST',
  get_material_requests: 'GET_MATERIAL_REQUESTS',
  get_request_by_id: 'GET_REQUEST_BY_ID',
  get_participants_by_request_id: 'GET_PARTICIPANTS_BY_REQUEST_ID',
  get_bids_by_request_id: 'GET_BIDS_BY_REQUEST_ID',
  get_bids: 'GET_BIDS',
  get_bids_summary: 'GET_BIDS_SUMMARY',
  get_messages: 'GET_MESSAGES',
  get_awarded_lots: 'GET_AWARDED_LOTS',
};

export default actionTypes;
