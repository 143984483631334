// @ts-check
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LotModal from "./LotModal";
import CreateLotTemplate from "./lot_templates/CreateLotTemplate";
import UseLotTemplate from "./lot_templates/UseLotTemplate";
import UploadLotsModal from "./UploadLotsModal";
import AuctionTypeButton from "./AuctionTypeButton";
import { Lot, EventPropType, LotComponent } from "@/common-prop-types";
import { regenerateLots, showSuccess } from "@/actions/lotActions";
import { lotAutomationEnforcedAndNotSandpit, selectLotAutomationState } from "./lotCommon";
import { t } from "@/i18n";

// The padding added to the buttons when then are fixed to the top. This is
// 15px the same as the bootstrap grid gutter
const STATIC_PADDING = 15;

// The widths of the screen when the UI brakes to mobile devices. This is
// defined by bootstraps `sm`
const MOBILE_BRAKE_POINT = 772;

class CreateLotButtons extends Component {
  static propTypes = {
    /**
     * The event this is creating an event for
     */
    event: PropTypes.shape(EventPropType).isRequired,
    /**
     * The class for the event state. This will be calculate from the events
     * `current_state`
     *
     * @see classesFromEventState
     */
    classFromEventState: PropTypes.string.isRequired,
    /**
     * What buttons create lot buttons to render
     *
     * @default 'complex'
     */
    allowedLotType: PropTypes.oneOf(["simple", "complex"]),
    /**
     * The legacy translations for the component
     */
    translations: PropTypes.any,
    /**
     * If the event has any completed bids
     */
    anyCompletedBids: PropTypes.bool,
    /**
     * If the current_user is allowed to edit the event
     */
    permittedToEdit: PropTypes.bool,
    /**
     * The event total lot. This my be undefined if the event dose not have
     * one.
     */
    etLot: PropTypes.shape(Lot),
    /**
     * All of the lots in the event
     */
    lots: PropTypes.arrayOf(PropTypes.shape(Lot)),
    /**
     * If the event is valid for partial bidding
     */
    partialBiddingSwitch: PropTypes.bool,
    /**
     * If the event has any bids
     */
    eventHasAnyBids: PropTypes.bool,
    /**
     * A list of lot templates that can be used to create lots
     */
    lotTemplates: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * All of the components for each of the lots
     */
    lotComponents: PropTypes.arrayOf(PropTypes.shape(LotComponent)),

    /**
     * automatic_lot_template_selection setting value
     */
    lotAutomationState: PropTypes.shape({
      isEnforced: PropTypes.bool,
      isEnabled: PropTypes.bool,
      isDisabled: PropTypes.bool,
    }),

    /**
     * check if lot automation is set to enforced and not in sandpit
     */
    lAEnforcedAndNotSandpit: PropTypes.bool,

    isRegenerating: PropTypes.bool,

    regenerateProgress: PropTypes.number,

    /**
     * action to generate lots from srm
     */
    regenerateLots: PropTypes.func.isRequired,
    /**
     * action to generate lots when enforced autoselection
     */
    refreshEnforcedAutomationLots: PropTypes.bool,

    /**
     * action to show success message
     */
    showSuccess: PropTypes.func.isRequired,
    inSandpit: PropTypes.bool,
  };

  /**
   * @type {React.RefObject<HTMLDivElement>}
   */
  lotButton = React.createRef();
  /** @type {{ lotButtonStyle: import("react").CSSProperties; isMobile: boolean }} */
  state = { lotButtonStyle: {}, isMobile: false };

  componentDidMount() {
    this.regenerateLotsIfEnforced();

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  /**
   * Get the offset from the top of the page to display the lot buttons. If the
   * navbar is hidden (on mobile) then its not fixed and we need to put the lot
   * buttons at the top of the page. If its fixed then we need to pu it under
   * the navbar.
   *
   * @param {Element} navbar
   * @returns {number}
   */
  getTopOffset(navbar) {
    const style = window.getComputedStyle(navbar);
    return style.display === "none" ? 0 : navbar.clientHeight;
  }

  handleScroll = () => {
    const navbar = document.querySelector(".esker-navbar");

    // Bail out if we don't have all the elements we need to calculate the position
    if (!navbar || !this.lotButton.current || !this.lotButton.current.parentElement) return;

    // Calculate the position of the lot buttons in relation to the page scroll
    // position. If there are scrolled off the top of the screen
    // `hasScrolledPassed` will be `true`
    const [parentPosition] = this.lotButton.current.parentElement.getClientRects();
    const hasScrolledPassed = parentPosition.top - this.getTopOffset(navbar) - STATIC_PADDING < 0;
    const baseStyle = hasScrolledPassed ? { position: "fixed" } : { position: "static" };

    this.setState({
      lotButtonStyle: { ...baseStyle, top: `${this.getTopOffset(navbar)}px` },
      isMobile: window.innerWidth < MOBILE_BRAKE_POINT,
    });
  };

  regenerateLotsIfEnforced = () => {
    const { lots } = this.props;
    if (lots.length <= 0) {
      this.regenerateHandler();
    }
  };

  lotsExistsButtons() {
    const { allowedLotType, classFromEventState } = this.props;
    const isFixed = this.state.lotButtonStyle.position === "fixed";
    const isMobile = this.state.isMobile;

    let buttonClass = `no-border btn btn-${classFromEventState}-outline h4 lot-toggle`;
    buttonClass += !isMobile || !isFixed ? " hide" : "";

    return (
      <div className="col-md-12 mobile-p0 p-0">
        {/* Below element is a dropdown create by js */}
        <div
          id="internal-lot-buttons"
          style={this.state.lotButtonStyle}
          className={`internal-lot-button ${isFixed && "lot-buttons"} ${isMobile && isFixed && "dropdown"}`}
          ref={this.lotButton}
        >
          <button className={buttonClass} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-bars" aria-hidden="true" />
          </button>
          <div className={`lot-button-container ${isMobile && isFixed && "dropdown-menu"}`} id="lotbutton-collapse">
            {allowedLotType == "simple" ? this.simpleLotButtons() : this.complexLotButtons()}
          </div>
        </div>
      </div>
    );
  }

  simpleLotButtons() {
    const { translations, classFromEventState } = this.props;
    return (
      <>
        <a className={`btn auto_width_btn ${classFromEventState} status-event sm-butt simple-lot`}>
          <i className="fa fa-plus" />
          &nbsp;
          {translations.new_lot}
        </a>
        <a className={`btn auto_width_btn ${classFromEventState} status-event sm-butt`}>
          <i className="fa fa-upload" />
          &nbsp;
          {translations.excel_upload}
        </a>
      </>
    );
  }

  complexLotButtons() {
    return (
      <>
        <div className="col-md-6 lot-button-left p-0">{this.complexLotLeftButtons()}</div>

        <div className="col-md-6 text-right lot-button-right p-r0">{this.complexLotRightButtons()}</div>
      </>
    );
  }

  downloadLotButton() {
    const event = this.props.event;
    const classFromEventState = this.props.classFromEventState;
    const text = this.props.translations.download_lot_template;

    return (
      <a
        className={`btn auto_width_btn ${classFromEventState} status-event sm-butt`}
        href={`/events/${event.id}/lots.xlsx`}
      >
        <i className="fa fa-download" /> {text}
      </a>
    );
  }

  regenerateHandler = async () => {
    const { event, regenerateLots, showSuccess } = this.props;
    const response = await regenerateLots(event.id);
    const { feedback_message } = response.payload;

    if (feedback_message) showSuccess(feedback_message);
  };

  lotModalProps() {
    const { lots, allowedLotType, classFromEventState, partialBiddingSwitch, anyCompletedBids } = this.props;
    return { lots, allowedLotType, classFromEventState, partialBiddingSwitch, anyCompletedBids };
  }

  regenerateLotsButton() {
    return (
      <button
        className="btn auto_width_btn btn-prominent draft status-event sm-butt"
        style={{ backgroundColor: "white" }}
        disabled={this.props.isRegenerating}
        onClick={this.regenerateHandler}
      >
        {this.props.isRegenerating ? (
          t("progress_percentage", { percentage: this.props.regenerateProgress })
        ) : (
          <>
            <i className="fa fa-plus-circle" />
            &nbsp; {t("regenerate_lots")}
          </>
        )}
      </button>
    );
  }

  complexLotLeftButtons() {
    const { translations, event, classFromEventState, eventHasAnyBids, etLot } = this.props;
    const { permittedToEdit, lotAutomationState, lAEnforcedAndNotSandpit } = this.props;
    const hideCreateNewLotButton = (etLot && eventHasAnyBids) || lAEnforcedAndNotSandpit;
    const showRegenerateLotsButton = lotAutomationState.isEnabled || lotAutomationState.isEnforced;

    const shouldShowAuctionTypeButton =
      permittedToEdit && event.event_category_auction && event.event_type !== "Japanese" && !lAEnforcedAndNotSandpit;

    return (
      <>
        {shouldShowAuctionTypeButton && <AuctionTypeButton translations={translations} event={event} />}
        {!hideCreateNewLotButton && (
          <LotModal eventId={event.id} create={true} translations={translations} {...this.lotModalProps()} />
        )}
        {showRegenerateLotsButton && this.regenerateLotsButton()}
        {!lAEnforcedAndNotSandpit && (
          <>
            {this.downloadLotButton()}
            <UploadLotsModal translations={translations} classFromEventState={classFromEventState} event={event} />
          </>
        )}
      </>
    );
  }

  complexLotRightButtons() {
    const { lotComponents, translations, classFromEventState, lots, event } = this.props;
    const { lotTemplates, lAEnforcedAndNotSandpit } = this.props;

    if (!lotComponents || lAEnforcedAndNotSandpit) return null;

    return (
      <>
        <CreateLotTemplate
          classFromEventState={classFromEventState}
          translations={translations}
          lots={lots}
          companyId={event.company_id}
        />
        {Boolean(lotTemplates.length) && (
          <UseLotTemplate
            classFromEventState={classFromEventState}
            translations={translations}
            lotTemplates={lotTemplates}
            eventId={event.id}
          />
        )}
      </>
    );
  }

  render() {
    const { permittedToEdit } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-0 m-b20 lot-section">
            <div className="form-group">
              {permittedToEdit && <div id="lot_buttons">{this.lotsExistsButtons()}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/** @param {import("@/store").RootState} state */
const mapStateToProps = (state) => ({
  refreshEnforcedAutomationLots: state.lotReducers.refresh_enforced_autoselection_lots,
  lotAutomationState: selectLotAutomationState(state),
  lAEnforcedAndNotSandpit: lotAutomationEnforcedAndNotSandpit(state),
  isRegenerating: state.lotReducers.isRegenerating,
  regenerateProgress: state.lotReducers.regenerateProgress,
  inSandpit: state.lotReducers.in_sandpit,
});

const mapDispatchToProps = { regenerateLots, showSuccess };

export default connect(mapStateToProps, mapDispatchToProps)(CreateLotButtons);
