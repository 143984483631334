import React from "react";

const ConfigurationSettingInfo = ({ translations }) => {
  return (
    <div className="sp-mg">
      <div className="col-md-12 notification-block clear-padding-desktop">
        <div className="alert alert-info alert-dismissable">
          <p>{translations}</p>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationSettingInfo;
