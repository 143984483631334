import React, { Component } from "react";
import CustomNotificationModal from "./CustomNotificationModal";
import { connect } from "react-redux";
import { changeYearButtons } from "../../../common";
import {
  createCustomNotification,
  updateCustomNotification,
  removeCustomNotification,
} from "../../../actions/contract/contractCreateEditActions";
import { Confirm } from "../../common/Confirm";
import ContractTooltip from "./ContractTooltip";

export function handleIncludeRecipients(e, all, stakeholder, userId) {
  // throw error if handleIncludeRecipients is not used inside a class component
  if (!(this instanceof Component)) {
    throw new Error("handleIncludeRecipients MUST be bound to a React class component");
  }

  const checked = e.target.checked;
  const { stakeholders, suppliers } = this.props;
  let includedRecipients = [];

  if (all) {
    const recipient = stakeholder ? stakeholders : suppliers;
    includedRecipients = checked ? recipient.map(({ user_id }) => user_id) : [];
  } else {
    includedRecipients = stakeholder ? [...this.state.includedStakeholders] : [...this.state.includedSuppliers];
    if (checked) includedRecipients.push(userId);
    else includedRecipients = includedRecipients.filter((item) => item !== userId);
  }

  const keyToSet = stakeholder ? "includedStakeholders" : "includedSuppliers";
  this.setState({ [keyToSet]: includedRecipients });
}

class CustomNotificationForm extends Component {
  constructor(props) {
    super(props);
    let isIE = document.documentMode ? true : false;
    this.state = {
      isItIE: isIE,
      subject: "",
      content: "",
      startDate: "",
      intervalValue: "",
      intervalUnit: "days",
      includeNotes: "",
      addNewNotification: true,
      notificationId: "",
      stakeHoldersBox: true,
      includedStakeholders: [],
      includedSuppliers: [],
      subjectError: "",
      contentError: "",
      startDateError: "",
    };

    this.handleIncludeRecipients = handleIncludeRecipients.bind(this);
  }

  handleAddNewCustomNotification(e) {
    e.preventDefault();
    this.setState({
      subject: "",
      content: "",
      startDate: "",
      intervalValue: "",
      intervalUnit: "days",
      includeNotes: false,
      addNewNotification: true,
      notificationId: "",
      stakeHoldersBox: true,
      includedStakeholders: [],
      includedSuppliers: [],
      subjectError: "",
      contentError: "",
      startDateError: "",
    });
  }

  handleEditCustomNotification(notification) {
    const { id, content, interval, recipient, start_date, subject, include_notes } = notification;
    const intervalValue = Object.keys(interval)[0];
    const intervalUnit = interval[intervalValue];

    const includedStakeholders = recipient.stakeholders
      .filter((stakeholder) => stakeholder.checked)
      .map((stakeholder) => stakeholder.id);

    const includedSuppliers = recipient.suppliers.filter((supplier) => supplier.checked).map((supplier) => supplier.id);
    this.setState({
      subject: subject,
      content: content,
      startDate: start_date,
      intervalValue,
      intervalUnit,
      includeNotes: include_notes,
      notificationId: id,
      stakeHoldersBox: true,
      includedStakeholders: includedStakeholders,
      includedSuppliers: includedSuppliers,
      subjectError: "",
      contentError: "",
      startDateError: "",
    });
  }

  componentDidMount() {
    $(".custom-notification-start-date").datepicker({
      dateFormat: "yy-mm-dd",
      beforeShow: changeYearButtons,
      onChangeMonthYear: changeYearButtons,
      onSelect: (value) => {
        return this.handleDateChange(value);
      },
    });
  }

  handleChange(e, fieldName) {
    const { isItIE } = this.state;
    let { subjectError, contentError } = this.state;
    let value = "";
    if (fieldName === "includeNotes") {
      value = e.target.checked;
    } else if (fieldName === "content") {
      value = isItIE ? e.target.value : e;
      contentError = "";
    } else {
      value = e.target.value;
      subjectError = fieldName === "subject" ? "" : subjectError;
    }
    const data = { subjectError, contentError };
    data[fieldName] = value;
    this.setState(data);
  }

  async handleDateChange(date) {
    this.setState({
      startDate: date,
      startDateError: "",
    });
  }

  showHideStakeholderbox(flag) {
    this.setState({
      stakeHoldersBox: flag,
    });
  }

  createUpdateCustomNotification(notificationId) {
    let {
      subject,
      content,
      startDate,
      includeNotes,
      intervalValue,
      intervalUnit,
      includedStakeholders,
      includedSuppliers,
      subjectError,
      contentError,
      startDateError,
    } = this.state;

    let fieldsAreValid = true;
    if (!subject) {
      fieldsAreValid = false;
      subjectError = "required";
    } else if (subject.length > 65) {
      subjectError = "should be less then 65 charectars";
    } else {
      subjectError = "";
    }
    if (!content) {
      fieldsAreValid = false;
      contentError = "required";
    } else {
      contentError = "";
    }
    if (!startDate) {
      fieldsAreValid = false;
      startDateError = "required";
    } else {
      startDateError = "";
    }
    if (fieldsAreValid) {
      const interval = {};
      interval[intervalValue] = intervalUnit;
      if (notificationId) {
        this.props.updateCustomNotification(
          this.props.contractId,
          notificationId,
          subject,
          content,
          startDate,
          includeNotes,
          interval,
          includedSuppliers,
          includedStakeholders
        );
      } else {
        this.props.createCustomNotification(
          this.props.contractId,
          subject,
          content,
          startDate,
          includeNotes,
          interval,
          includedSuppliers,
          includedStakeholders
        );
      }
      $("#customNotificationModal").modal("hide");
    }
    this.setState({
      startDateError,
      subjectError,
      contentError,
    });
  }

  updateNotificationToRemove(id) {
    this.setState({
      notificationIdToRemove: id,
    });
  }

  removeNotification() {
    this.props.removeCustomNotification(this.props.contractId, this.state.notificationIdToRemove);
  }

  render() {
    const {
      subject,
      content,
      startDate,
      intervalValue,
      intervalUnit,
      includeNotes,
      addNewNotification,
      notificationId,
      stakeHoldersBox,
      includedStakeholders,
      includedSuppliers,
      subjectError,
      contentError,
      startDateError,
      isItIE,
    } = this.state;
    const {
      translations,
      showPage,
      contractStartDate,
      contractExpiryDate,
      stakeholders,
      suppliers,
      customNotifications,
    } = this.props;
    return (
      <div
        className={`col-md-12 col-sm-12 sd-hidden ${
          (contractStartDate && contractExpiryDate) || showPage ? "" : "d-none"
        }`}
      >
        <div className="form-section-block">
          <ContractTooltip translations={translations} titleText={"custom_notifications"} />
          {!showPage && (
            <div className="col-12 form-group">
              <a
                data-action="create"
                data-toggle="modal"
                data-target="#customNotificationModal"
                rel="nofollow"
                onClick={(e) => this.handleAddNewCustomNotification(e)}
                href="#"
              >
                + {translations.add_new_custom_notification}
              </a>
              <CustomNotificationModal
                customNotification={{
                  subject: subject,
                  content: content,
                  startDate: startDate,
                  intervalValue: intervalValue,
                  intervalUnit: intervalUnit,
                  includeNotes: includeNotes,
                  id: notificationId,
                }}
                stakeholders={stakeholders}
                suppliers={suppliers}
                stakeHoldersBox={stakeHoldersBox}
                new={addNewNotification}
                handleChange={(e, fieldName) => this.handleChange(e, fieldName)}
                showHideStakeholderbox={(flag) => this.showHideStakeholderbox(flag)}
                createUpdateCustomNotification={(id) => this.createUpdateCustomNotification(id)}
                handleIncludeRecipients={(e, all, stakeholder, userId) =>
                  this.handleIncludeRecipients(e, all, stakeholder, userId)
                }
                includedStakeholders={includedStakeholders}
                includedSuppliers={includedSuppliers}
                subjectError={subjectError}
                contentError={contentError}
                startDateError={startDateError}
                translations={translations}
                isItIE={isItIE}
              />
            </div>
          )}
          {customNotifications.length > 0 && (
            <table className="border-collapse custom-notification-table style-table-contracts">
              <thead className="table-block-title">
                <tr>
                  <td>
                    <div>{translations.subject}</div>
                  </td>
                  <td>
                    <div>{translations.start_date}</div>
                  </td>
                  <td>
                    <div>{translations.alert_me_every}</div>
                  </td>
                  {!showPage && <td />}
                </tr>
              </thead>
              <tbody id="participant_summary_body">
                {customNotifications.map((notification) => (
                  <tr key={notification.id}>
                    <td>{notification.subject}</td>
                    <td>{notification.start_date}</td>
                    <td>
                      {Object.keys(notification.interval)[0]
                        ? `${Object.keys(notification.interval)[0]}  ${
                            translations[notification.interval[Object.keys(notification.interval)[0]]]
                          }`
                        : "-"}
                    </td>
                    {!showPage && (
                      <td>
                        <a
                          className="btn-action download"
                          onClick={() => this.handleEditCustomNotification(notification)}
                          data-toggle="modal"
                          data-target="#customNotificationModal"
                          rel="nofollow"
                          href="javascript:void(0);"
                        >
                          <i title="Download" className="fa fa-edit" />
                        </a>
                        <a
                          className="btn-action delete"
                          onClick={() => this.updateNotificationToRemove(notification.id)}
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#customNotificationRemoveConfirmModal"
                          rel="nofollow"
                        >
                          <i title="Remove" className="fa fa-times" />
                        </a>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <p className="clear-both" />
          <Confirm
            translations={translations}
            title={translations.confirmation_popup_details.delete_notification_title}
            message={translations.confirmation_popup_details.delete_notification_text}
            htmlId="customNotificationRemoveConfirmModal"
            onOkayButtonClick={() => this.removeNotification()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ createEditReducers }) => {
  return {
    customNotifications: createEditReducers.customNotifications.notifications,
    contractId: createEditReducers.contract.id,
    suppliers: createEditReducers.contractUserData.suppliers.added,
    stakeholders: createEditReducers.contractUserData.stakeholders.added,
    translations: createEditReducers.translations,
    showPage: createEditReducers.showPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createCustomNotification: (
    contractId,
    subject,
    content,
    startDate,
    includeNotes,
    interval,
    suppliers,
    stakeholders
  ) =>
    dispatch(
      createCustomNotification(contractId, subject, content, startDate, includeNotes, interval, suppliers, stakeholders)
    ),
  updateCustomNotification: (
    contractId,
    notificationId,
    subject,
    content,
    startDate,
    includeNotes,
    interval,
    suppliers,
    stakeholders
  ) =>
    dispatch(
      updateCustomNotification(
        contractId,
        notificationId,
        subject,
        content,
        startDate,
        includeNotes,
        interval,
        suppliers,
        stakeholders
      )
    ),
  removeCustomNotification: (contractId, notificationId) =>
    dispatch(removeCustomNotification(contractId, notificationId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomNotificationForm);
