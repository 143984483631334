import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TabButton from "./TabButton";

/**
 * Tab items wrapper for activity modal
 * @param {Object} Props
 * @returns
 */
const TabsWrapper = ({ className, submitActivityIfChanges, children, active }) => {
  const { translations } = useSelector((state) => state.ActivityReducer);
  const tabItems = Tabs({
    submitActivityIfChanges,
    translations,
  });

  return (
    <ul className={className}>
      {tabItems.map((tab, idx) => (
        <TabButton key={`activity-modal-tabs-${idx}`} {...tab} active={active === tab.name} />
      ))}
      {children}
    </ul>
  );
};

TabsWrapper.defaultProps = {
  className: "nav nav-tabs",
  children: [],
};

TabsWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  submitActivityIfChanges: PropTypes.func.isRequired,
};

export default TabsWrapper;

export const Tabs = ({ submitActivityIfChanges, translations }) => [
  {
    name: "general",
    text: translations.general,
    onClick: (e) => submitActivityIfChanges(e, "general", false),
  },
  {
    name: "assignment",
    text: translations.assignment,
    onClick: (e) => submitActivityIfChanges(e, "assignment", false),
  },
  {
    name: "documents",
    text: translations.documents,
    onClick: (e) => submitActivityIfChanges(e, "documents", false),
  },
  {
    name: "notes",
    text: translations.notes,
    onClick: (e) => submitActivityIfChanges(e, "notes", false),
  },
  {
    name: "subactivity",
    text: translations.hierarchy,
    onClick: (e) => submitActivityIfChanges(e, "subactivity", false),
  },
];
