import { ensureAbsoluteUrl, prepareHeaders } from "@/lib/fetch";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = ensureAbsoluteUrl("/api");

export const emptyApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: () => ({}),
});
