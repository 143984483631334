import React, { type ReactNode } from "react";
import Tooltip from "@/components/common/Tooltip";
import { t } from "@/i18n";
import { clsx } from "clsx";
import { chunk } from "lodash";

interface PermissionsLayoutProps {
  name: keyof typeof PERMISSIONS_TYPE;
  permissionsList: string[];
  categoryDojo: boolean;
  actionSelect: (permission: string) => ReactNode;
}

// Note: We could have remove `permissionCollection` from here and make this a
// static value but I did this so we can use this component to render event and
// contract permissions as well when it comes to refactor them.
const PERMISSIONS_TYPE = {
  admin_permissions_html: {
    permissionCollection: "enums.admin_permission.section",
    tooltipCollection: "tooltips:permissions.admin_permissions",
  },
  sim_permissions_html: {
    permissionCollection: "enums.admin_permission.section",
    tooltipCollection: "tooltips:permissions.sim_permissions",
  },
};

export const getTooltipContent = (permission: string, tooltipCollection: string) => {
  return {
    title: t(`${tooltipCollection}.${permission}.title`),
    body: t(`${tooltipCollection}.${permission}.body`),
  };
};

export default function PermissionsLayout({
  name,
  permissionsList,
  categoryDojo,
  actionSelect,
}: PermissionsLayoutProps) {
  const className = clsx("col-md-4 col-sm-4", { "form-itemblock": categoryDojo });
  const { permissionCollection, tooltipCollection } = PERMISSIONS_TYPE[name];

  return (
    <div className="modal-content-section">
      <div className="section-title">
        <h4>{t(name)}</h4>
      </div>
      {chunk(permissionsList, 3).map((permissions, index) => (
        <div key={index} className="form-group">
          <div className="row">
            {permissions.map((permission) => {
              const tooltipContent = getTooltipContent(permission, tooltipCollection);
              return (
                <div className={className} key={permission}>
                  <label>
                    <Tooltip title={tooltipContent.title} body={tooltipContent.body} categoryDojo={categoryDojo} />
                    {t(`${permissionCollection}.${permission}`)}
                  </label>
                  <br />
                  {actionSelect(permission)}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
