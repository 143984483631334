import actionTypes from '../../../actions/qq/actionsTypes';

const emSummaryReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.get_request_by_id:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.get_bids_by_request_id:
      return {
        ...state,
        bids_list: action.payload,
      };
    case actionTypes.get_participants_by_request_id:
      return {
        ...state,
        bids_summary: action.payload,
      };
    case actionTypes.get_awarded_lots:
      return {
        ...state,
        awardedLotsDetails: action.payload,
      };
    default:
      return state;
  }
};

export default emSummaryReducer;
