import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(this.element).select2({
      closeOnSelect: false,
      // theme: "dojo-bs5",
      width: "100%",
      containerCssClass: "md-bs5",
      dropdownCssClass: "md-bs5",
      selectionCssClass: "md-bs5",
      templateResult: this.#renderItem,
    });
  }

  disconnect() {
    $(this.element).select2("destroy");
  }

  #renderItem(state) {
    if (!state.id) return state.text;

    return $(`
      <div class="form-check">
        <label class="form-check-label" for="${state.id}_checkbox">
          <input class="form-check-input" id="${state.id}_checkbox" type="checkbox" ${state.selected ? "checked" : ""}>
          ${state.text}
        </label>
      </div>
    `);
  }
}
