import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import cookies from "browser-cookies";

import { confirm } from "../../util/confirm";
import Summary from "./Summary";
import ParticipantList from "./ParticipantList";
import Message from "./Message";
import SeeBrief from "./SeeBrief";
import AwardLots from "../emarketplace/material_request_summary/AwardLots";
import { fetchQuoteById, fetchEventParticipants, fetchEventHosts, fetchLots } from "../../../../actions/qq/qqActions";
import { fetchBids, sendMessage, bidsSummary, fetchMessages } from "../../../../actions/qq/messagesActions";
import { notifyParticipant } from "../../../../actions/qq/editActions";
import {
  showToasterMessages,
  emptyCurrentState,
  updateSummaryHeader,
  changeEventState,
  removeEvent,
} from "../../../../actions/qq/genericActions";
import { getAwardedLots } from "../../../../actions/qq/emarketplace/emSummaryActions";
import connectToWebsocket from "../../js/websocketConnection";
import Clock from "./Clock";
import SourcingPipeline from "./SourcingPipeline";
import { withAlert } from 'react-alert'
import { notifyToastMsgQQ } from "../../js/common";

class QQSummaryStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      declinedParticipantsList: [],
      showAwardLotButton: false,
      updatedEvent: {},
    };
    const userId = this.props.user.id;
    this.socketObject = connectToWebsocket(this.props.event.id, userId);
  }
  
  UNSAFE_componentWillMount() {
    const quoteId = this.props.event.id;
    if (quoteId) {
      this.fetchQuoteData(quoteId);
      this.fetchAwardedLotsData(quoteId);
    }
  }

  async fetchAwardedLotsData(quoteId) {
    const awardLotData = await this.props.getAwardedLots(quoteId);
    if (awardLotData.status) {
      this.setState({
        showAwardLotButton: true,
      });
    }
  }
  
  async fetchQuoteData(quoteId) {
    const response = await this.props.fetchQuoteById(quoteId);
    if (response && response.status === 200) {
      if (response.data.event.quick_quotes) {
        if (response.data.event.current_state === "edit" && response.data.event.current_user_has_edit_permission) {
          window.location.pathname = `/quick_quotes/quote/${quoteId}/edit`;
        } else {
          this.props.fetchEventParticipants(quoteId);
          this.props.fetchEventHosts(quoteId);
          this.props.fetchBids(quoteId);
          this.setState({ showLoader: false });
        }
      } else {
        window.location.pathname = "/quick_quotes/quote/unauthorised";
      }
    } else {
      window.location.pathname = `/quick_quotes/quote/${quoteId}/notfound`;
    }
  }

  getDeclinedParticipantsList = (newProps) => {
    const declinedParticipants = [];
    if (!_.isEmpty(newProps.quoteDetail)) {
      if (newProps.quoteDetail.event_participants_list) {
        newProps.quoteDetail.event_participants_list.forEach((participant) => {
          if (participant.status === "Declined") {
            declinedParticipants.push(participant.user_id);
          }
        });
        this.setState({
          declinedParticipantsList: declinedParticipants,
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getDeclinedParticipantsList(newProps);
    if (!_.isEmpty(newProps.updatedEvents.updatedEvent)) {
      this.setState({
        updatedEvent: newProps.updatedEvents.updatedEvent,
      });
    }
    notifyToastMsgQQ(newProps, this.props.alert);
  }

  componentWillUnmount() {
    this.props.emptyCurrentState("CHANGE_EVENT_STATE", undefined);
    this.props.emptyCurrentState("UPDATE_SUMMARY_HEADER", undefined);
  }

  async changeToEdit(quoteId) {
    const response = await this.props.changeEventState(quoteId, "edit");
    if (response.status === 200) {
      window.location.pathname = `/quick_quotes/quote/${quoteId}/edit`;
    }
  }

  confirmation(e, QQevent, qqState) {
    e.preventDefault();
    let popupContext = "";
    if (qqState === "close") {
      popupContext = this.props.translations.pop_up.confirm_popup_close;
    } else if (qqState === "delete") {
      popupContext = this.props.translations.pop_up.confirm_popup_delete;
    } else {
      popupContext = this.props.translations.pop_up.confirm_popup_reopen;
    }
    let pop_up_translations = {
      ConfirmLabel: this.props.translations.pop_up.confirm,
      okLabel: this.props.translations.pop_up.ok,
      cancelLabel: this.props.translations.pop_up.cancel,
    };
    confirm(popupContext, pop_up_translations).then(
      () => {
        if (qqState === "delete") {
          this.props.removeEvent(QQevent.id, qqState);
          window.location.pathname = "/quick_quotes/quotes";
        } else if (qqState === "reopen") {
          this.props.changeEventState(QQevent.id, qqState, this.props.translations.info_message.event_reopen_alert);
        }  else {
          this.props.changeEventState(QQevent.id, qqState);
        }
      },
      () => {
        // `cancel` callback
      }
    );
    return false;
  }

  searchByQQState(e, query) {
    e.preventDefault();
    window.location.pathname = `/quick_quotes/quotes?view=${query}`;
  }

  async updateQuoteDetails() {
    const quoteId = this.props.event.id;
    await this.props.fetchQuoteById(quoteId);
    if (this.props.quoteDetail.data.current_state !== "completed") {
      setTimeout(() => {
        this.props.fetchQuoteById(quoteId);
      }, 5000);
    }
    this.props.updateSummaryHeader("UPDATE_SUMMARY_HEADER", true);
    this.props.fetchLots(quoteId);
    this.props.bidsSummary(quoteId);
    this.props.fetchMessages(quoteId);
  }

  render() {
    const quoteId = this.props.event.id;
    let { data } = this.props.quoteDetail;
    const { addedParticipant, tabHostSelected, hosts, showLoader } = this.state;
    const { translations, user } = this.props;
    if (data && !_.isEmpty(this.state.updatedEvent)) {
      data = this.state.updatedEvent;
    }

    const TimerComponent = ({ deadline, formattedDeadline }) => {
      if (deadline) {
        let clock_translations = {
          clock: {
            day: translations.clock.day,
            days: translations.clock.days,
            about_hour: translations.clock.about_hour,
            about_hours: translations.clock.about_hours,
          },
          summary_status: { deadline: translations.summary_status.deadline },
        };
        if (Date.parse(deadline) - Date.parse(new Date()) > 0) {
          return (
            <div
              style={{
                display: "inline-flex",
              }}
            >
              <Clock
                deadline={deadline}
                formattedDeadline={formattedDeadline}
                updateQuoteDetails={() => this.updateQuoteDetails()}
                translations={clock_translations}
              />
            </div>
          );
        }
        return <div />;
      }
      return (
        <div className="time_remaining_wrapper action to-time">
          <i className="fa fa-clock-o" aria-hidden="true" />
          <span className="time">
            {translations.summary_status.deadline}:<b> -</b>
          </span>
        </div>
      );
    };

    const QuickQuotesDeadline = ({ deadline, formattedDeadline }) => {
      let str = "";
      if (deadline) {
        const time = Date.parse(deadline) - Date.parse(new Date());
        str = time > 0 ? "deadline" : "deadline_finished";
      } else {
        str = "deadline";
      }
      return (
        <td>
          <span className="single_date light-gray">{translations.summary_status[str]}: </span>
          {formattedDeadline && <br />}
          <span>{formattedDeadline || " -"}</span>
        </td>
      );
    };
    return (
      <div className='top-content'>
        <div className="container">
          {quoteId && data && data.quick_quotes ? (
            <div className="row">
              <div className="col-md-12 request-quote p-0">
                {
                  (data.current_user_has_edit_permission === true && (
                    <div
                      className={`btn-group button-status button-${
                        data.current_state === "closed" ? data.current_state.toLowerCase() : "current"
                      }`}
                    >
                      <button
                        type="button"
                        className={`dropdown-toggle status-event-summary
                          ${
                            data.current_state === "closed" ? data.current_state.toLowerCase() : "current"
                          }  form-control`}
                        data-toggle="dropdown"
                      >
                        {data.current_state === "closed"
                          ? translations.qq_listing.qq_list_table.closed
                          : translations.qq_listing.qq_list_table.current}
                        <span className="caret" />
                      </button>
                      <ul className="dropdown-menu" role="menu">
                        <li>
                          <a
                            href="#"
                            onClick={(e) => {
                              this.searchByQQState(
                                e,
                                data.current_state === "completed" ? "current" : data.current_state
                              );
                            }}
                          >
                            {data.current_state === "closed"
                              ? translations.qq_listing.qq_list_table.closed
                              : translations.qq_listing.qq_list_table.current}
                          </a>
                        </li>
                        {data.current_state === "closed" && (
                          <li>
                            <a
                              href="#"
                              onClick={(e) => {
                                this.confirmation(e, data, "reopen");
                              }}
                            >
                              {translations.qq_listing.qq_list_table.reopen}
                            </a>
                          </li>
                        )}
                        {data.current_state === "completed" && (
                          <li>
                            <a
                              href="#"
                              onClick={(e) => {
                                this.confirmation(e, data, "close");
                              }}
                            >
                              {translations.qq_listing.qq_list_table.close_event}
                            </a>
                          </li>
                        )}
                        {
                        ((user && user.qq_allow_to_delete_event === 'true') || data.current_user_has_delete_permission) && (
                          <li>
                            <a
                              href="#"
                              onClick={(e) => {
                                this.confirmation(e, data, "delete");
                              }}
                            >
                              {translations.qq_listing.qq_list_table.delete}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  )) || (
                    <div
                      className={`btn-group
                      button-status button-${
                        data.current_state === "closed" ? data.current_state.toLowerCase() : "current"
                      }`}
                    >
                      <button
                        type="button"
                        className={`dropdown-toggle status-event-summary
                        ${
                          data.current_state === "closed" ? data.current_state.toLowerCase() : "current"
                        }  form-control`}
                        data-toggle="dropdown"
                      >
                        {data.current_state === "closed"
                          ? translations.qq_listing.qq_list_table.closed
                          : translations.qq_listing.qq_list_table.current}
                        <span className="caret" />
                      </button>
                      <ul className="dropdown-menu" role="menu">
                        <li>
                          <a
                            href="#"
                            onClick={(e) => {
                              this.searchByQQState(
                                e,
                                data.current_state === "completed" ||
                                  data.current_state === "rfq" ||
                                  data.current_state === "edit"
                                  ? "current"
                                  : data.current_state
                              );
                            }}
                          >
                            {data.current_state === "closed"
                              ? translations.qq_listing.qq_list_table.closed
                              : translations.qq_listing.qq_list_table.current}
                          </a>
                        </li>
                      </ul>
                    </div>
                  )
                }
                <h2 className="remove-line">{data.name}</h2>
                <div className="col-md-6 col-sm-8 pull-right text-right time-remaining-box m- b10 p-0">
                  <div className="sheet-other_actions">
                    <TimerComponent deadline={data.deadline} formattedDeadline={data.formatted_bid_deadline} />
                  </div>
                </div>
                <table className="event-data m-t10">
                  <tbody>
                    <tr>
                      {data.user.company.publicly_visible_prefix_event && (
                        <td className="event-id">
                          <span className="light-gray highlight-text">{translations.summary_status.id}</span>
                          <div className="highlight-text">{quoteId}</div>
                        </td>
                      )}
                      <td>
                        <span className="light-gray">{translations.summary_status.event_type}</span>
                        <div>{translations.summary_status.quick_quotes}</div>
                      </td>
                      <td>
                        <span className="light-gray">{translations.qq_listing.qq_list_table.go_live_date}</span>
                        <div>{data.formatted_live_date || ""}</div>
                      </td>
                      <QuickQuotesDeadline deadline={data.deadline} formattedDeadline={data.formatted_bid_deadline} />
                      <td>
                        <div>
                          <span className="light-gray">{translations.qq_listing.qq_list_table.host}: </span>
                          <span>{data.user.name}</span>
                        </div>
                        <div>
                          <span className="light-gray">{translations.qq_listing.qq_list_table.company}: </span>
                          <span>{data.user.user_company_name}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-xs-12 p-0 view-table mobile-padding">
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                  <SeeBrief quoteDesc={data.event_brief} {...this.props} />
                  <Summary quoteId={quoteId} socketObject={this.socketObject} {...this.props} />
                  <ParticipantList
                    quoteId={quoteId}
                    userHasEditPermission={data.current_user_has_edit_permission}
                    socketObject={this.socketObject}
                    {...this.props}
                  />
                  <Message quoteId={quoteId} quoteName={data.name} socketObject={this.socketObject} {...this.props} />
                  {data.event_pa_integration_attributes ? (
                    <SourcingPipeline
                      quoteId={quoteId}
                      quoteName={data.name}
                      event_pa_integration_attributes={data.event_pa_integration_attributes}
                      {...this.props}
                    />
                  ) : (
                    ""
                  )}
                  <div className="m-t20">
                    {data.current_state !== "closed" && data.current_user_has_edit_permission && (
                      <button
                        label="edit-button"
                        className="btn btn-primary pull-right"
                        onClick={() => this.changeToEdit(quoteId)}
                      >
                        {translations.qq_edit.edit}
                      </button>
                    )}
                    {moment(data.bid_deadline).isBefore(new Date()) ? (
                      <div>
                        <div className="pull-right m-r10">
                          <AwardLots
                            translations={translations}
                            requestId={quoteId}
                            declinedParticipantsList={this.state.declinedParticipantsList}
                            showAwardLotButton={this.state.showAwardLotButton}
                            userHasEditPermission={data.current_user_has_edit_permission}
                            {...this.props}
                          />
                        </div>
                        {(!_.isEmpty(this.props?.emSummary?.awardedLotsDetails) ||
                          data.with_questionnaires == true) && (
                          <a
                            href={`/events/${quoteId}.xlsx?report_type=QQ&& in_sandpit=${
                              cookies.get("sandpit") ? cookies.get("sandpit") : false
                            }`}
                            label="export-report-button"
                            className="btn btn-primary pull-right m-r10"
                          >
                            {translations.summary_status.export_report}
                          </a>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      // </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedLang: state.changeLanguageReducer,
  quoteDetail: state.qqReducer,
  notify: state.notifyParticipant,
  // user: state.userReducer,
  updatedEvents: state.updateEventStateReducer,
  emSummary: state.emSummaryReducer,
  error: state.errorMessageReducer,
  success: state.successMessageReducer,
});

const mapDispatchToProps = (dispatch) => ({
  // changeLanguage: (currentLanguage) => dispatch(changeLanguage(currentLanguage)),
  // updateLocale: (userId, payload) => dispatch(updateLocale(userId, payload)),
  fetchQuoteById: (quoteId) => dispatch(fetchQuoteById(quoteId)), // fetch
  fetchBids: (quoteId) => dispatch(fetchBids(quoteId)),
  fetchEventParticipants: (quoteId) => dispatch(fetchEventParticipants(quoteId)),
  sendMessage: (quoteId, data) => dispatch(sendMessage(quoteId, data)), // user-action
  notifyParticipant: (quoteId, status, message) => dispatch(notifyParticipant(quoteId, status, message)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
  fetchEventHosts: (quoteId) => dispatch(fetchEventHosts(quoteId)),
  emptyCurrentState: (actionType, payload) => dispatch(emptyCurrentState(actionType, payload)),
  updateSummaryHeader: (type, payload) => dispatch(updateSummaryHeader(type, payload)),
  fetchLots: (quoteId) => dispatch(fetchLots(quoteId)),
  bidsSummary: (quoteId) => dispatch(bidsSummary(quoteId)),
  fetchMessages: (quoteId) => dispatch(fetchMessages(quoteId)),
  getAwardedLots: (quoteId) => dispatch(getAwardedLots(quoteId)),
  changeEventState: (quoteId, state, message) => dispatch(changeEventState(quoteId, state, message)),
  removeEvent: (quoteId, state) => dispatch(removeEvent(quoteId, state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(QQSummaryStatus));
