import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import { getError } from "./errorActions";

const createUpdateLotComponentSuccess = (data, actionType) => {
  const { line_item_components, lot_component_picklist_options, unit_of_measure } = data;
  let payload = {
    lotComponent: data.lot_component,
  };
  if (line_item_components !== undefined && line_item_components.length) {
    payload["lineItemComponents"] = line_item_components;
  }
  if (lot_component_picklist_options !== undefined && lot_component_picklist_options.length) {
    payload["lotComponentPicklistOptions"] = lot_component_picklist_options;
  }
  if (unit_of_measure !== undefined && unit_of_measure !== null) {
    payload["unitOfMeasure"] = unit_of_measure;
  }
  return {
    type: actionType,
    payload: payload,
  };
};

const newEditLotComponentSuccess = (data) => {
  return {
    type: actionTypes.NEW_EDIT_LOT_COMPONENT,
    payload: {
      lotComponent: data.lot_component,
      picklists: data.picklists,
      picklistOptions: data.picklist_options,
      allUnitSets: data.all_unit_sets,
      allUnitSetUnitOfMeasures: data.all_unit_set_unit_of_measures,
      lotComponentPicklistOptions: data.lot_component_picklist_options,
      uoms: data.uoms,
      licsForFormula: data.lics_for_formula,
    },
  };
};

const deleteLotComponentSuccess = (data) => {
  return {
    type: actionTypes.DELETE_LOT_COMPONENT,
    payload: {
      lots: data.lots,
      lineItems: data.line_items,
      lotComponents: data.lot_components,
      lineItemComponents: data.line_item_components,
    },
  };
};

const scrollToLC = (lotId, response) => {
  setTimeout(
    function (data, lotId) {
      const scrollLotContainer = document.getElementById(`scroll_lot_container_${lotId}`);
      const lcCell = document.getElementById(`component_column_${data.lot_component.id}`);
      const left = lcCell.getBoundingClientRect().x + 100;
      scrollLotContainer.scrollTo({
        left,
        behavior: "smooth",
      });
    }.bind(this, response && response.data ? response.data : {}, lotId),
    100
  );
};

export const createLotComponent = (lotId, payload) => (dispatch) =>
  apiCall
    .post(`${config.V2_BASE_URL}/lots/${lotId}/lot_components`, payload)
    .then((response) => {
      scrollToLC(lotId, response);
      return dispatch(createUpdateLotComponentSuccess(response.data, actionTypes.CREATE_LOT_COMPONENT));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const updateLotComponent = (lotComponentId, lotId, payload) => (dispatch) =>
  apiCall
    .put(`${config.V2_BASE_URL}/lots/${lotId}/lot_components/${lotComponentId}`, payload)
    .then((response) => dispatch(createUpdateLotComponentSuccess(response.data, actionTypes.UPDATE_LOT_COMPONENT)))
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const deleteLotComponent = (lotComponent) => (dispatch) =>
  apiCall
    .remove(`${config.V2_BASE_URL}/lots/${lotComponent.lot_id}/lot_components/${lotComponent.id}`)
    .then((response) => {
      dispatch(deleteLotComponentSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const editLotComponent = (lotComponentId, lotId, eventId) => (dispatch) =>
  apiCall
    .get(`${config.V2_BASE_URL}/lots/${lotId}/lot_components/${lotComponentId}/edit?event_id=${eventId}`)
    .then((response) => dispatch(newEditLotComponentSuccess(response.data)))
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const newLotComponent = (lotId, eventId) => (dispatch) =>
  apiCall
    .get(`${config.V2_BASE_URL}/lots/${lotId}/lot_components/new?event_id=${eventId}`)
    .then((response) => dispatch(newEditLotComponentSuccess(response.data)))
    .catch((error) => {
      dispatch(getError(error.response));
    });
