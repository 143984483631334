import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import { objectToParamString } from "../components/advanced_lots/common";
import { getError } from "./errorActions";

const { V2_BASE_URL } = config;

const commonData = (chartData, lotId, liId) => ({
  type: actionTypes.LOT_CHART_DATA,
  chartData,
  lotId,
  liId,
});

const hostsParticipantProgressionChartSuccess = ({ data: { chart_data, participants } }, lotId, lineItemId) => ({
  ...commonData(chart_data, lotId, lineItemId),
  participants,
});

const commonChartActionSuccess = ({ data: { chart_data } }, lotId, lineItemId) =>
  commonData(chart_data, lotId, lineItemId);

const makeLIChartApiCall = (url, dispatch, action, lot_id, line_item_id, current_tab = null) =>
  apiCall
    .get(
      `${V2_BASE_URL}/charts/${url}/${objectToParamString({
        lot_id,
        line_item_id,
        current_tab,
      })}`
    )
    .then((response) => dispatch(action(response, lot_id, line_item_id)))
    .catch((error) => dispatch(getError(error.response)));

export const participantsLiveEventLotLine = (lot_id, line_item_id) => {
  return (dispatch) =>
    makeLIChartApiCall(`participants_live_event_lot_line`, dispatch, commonChartActionSuccess, lot_id, line_item_id);
};

export const hostsParticipantProgressionChart = (lot_id, line_item_id) => {
  return (dispatch) =>
    makeLIChartApiCall(
      `hosts_participant_progression_chart`,
      dispatch,
      hostsParticipantProgressionChartSuccess,
      lot_id,
      line_item_id,
      "participant_progression"
    );
};

export const hostsHorseRaceChart = (lot_id, line_item_id) => {
  return (dispatch) =>
    makeLIChartApiCall(
      `hosts_horse_race_chart`,
      dispatch,
      commonChartActionSuccess,
      lot_id,
      line_item_id,
      "horse_race"
    );
};

export const hostsBestBidProgressionChart = (lot_id, line_item_id) => {
  return (dispatch) =>
    makeLIChartApiCall(
      `hosts_best_bid_progression_chart`,
      dispatch,
      commonChartActionSuccess,
      lot_id,
      line_item_id,
      "best_bid_progression"
    );
};

export const hostsDifferenceProgressionChart = (lot_id, line_item_id) => {
  return (dispatch) =>
    makeLIChartApiCall(
      `hosts_difference_progression_chart`,
      dispatch,
      commonChartActionSuccess,
      lot_id,
      line_item_id,
      "diff_progression"
    );
};
