import React, { useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { showRankOfCell } from "../../../bidCommon";
import { showTextWhenNoRank } from "../../../common";

/**
 * @description Participant rank for LineItemComponent
 * Monitor Tab =>
 * Opens up once clicked on Show Components
 *
 * Column - rank
 */
const RankOfCell = memo((props) => {
  const { lot, beforeAuction, lotComponent, lic, blic, bestBidBeforeAuction } = props;
  const { event, role, translations, auction_tab: auctionTab } = useSelector((state) => state.lotReducers);
  const { na_in_small_letters } = translations;

  let rank;
  if ((beforeAuction || auctionTab) && lic)
    rank = showRankOfCell(event, blic, bestBidBeforeAuction, lic, lotComponent, lot, role, false, auctionTab);

  if (!(beforeAuction || auctionTab)) return null;
  if (!lic) return <div className="mycell">-</div>;
  return <div className="mycell">{rank ?? showTextWhenNoRank(lic, na_in_small_letters)}</div>;
});

export default RankOfCell;
