import React, { Component, Fragment } from "react";
import map from "lodash/map";
import {
  TagUi,
  TagUiCreate,
  RemoveTagCategory,
  noPermission,
  cannotRemoveTags,
} from "../../../../actions/SimSettingActions";
import { connect } from "react-redux";
import { Confirm } from "../../../common/Confirm";
import Tooltip from "../../../common/Tooltip";

const _availableTags = (tag_id, data) => !!data.filter((tag) => tag.id === tag_id && tag.tags.length).length;

const beforeTagRemoved = (evt, ui, categories, id, cannotRemoveTags) => {
  let pass = false;
  if ($(".tagit").hasClass("disable-tag")) pass = false;
  else pass = true;

  const activeCategory = categories.filter((category) => category.id === id)[0] || false;
  if (activeCategory && activeCategory.tags.length <= 1 && activeCategory.mandatory) {
    pass = pass && false;
    cannotRemoveTags("Need atleast one tag to be saved!");
  }
  return pass;
};

const afterTagRemoved = async function (evt, ui, { obj, category }) {
  let id = $(evt.target).closest("tr").find("td:nth-child(2) input").prop("id");
  let temp = "";
  if (evt.target.value.includes(ui.tagLabel)) {
    temp = evt.target.value.replace(ui.tagLabel, "");
    temp = temp.split(",").filter((a) => a != "");
    temp = temp.join(",");
  } else {
    temp = evt.target.value;
  }
  let data = [
    obj.props.company.id,
    {
      tag_category: {
        id: id,
        module_cd: obj.props.moduleCd,
      },
      tag_name: temp,
    },
  ];
  $(".tagit").addClass("disable-tag");
  $(".tagit-new input").addClass("wid-80");
  let length = evt.target.nextElementSibling.childNodes.length - 1;
  $(evt.target.nextElementSibling.childNodes[length]).prepend("<i class='fa fa-spinner fa-spin fa-black'></i>");
  let r = await obj.props.TagUi(data);

  if (r.data) {
    r.data.map((cat) => {
      if (cat.id === category.id && !cat.tags.length && cat.mandatory) {
        obj.updateModule(evt, false, cat, cat.tags);
      }
    });
    obj.setState({ data: r.data });
  }
  if (r.error) {
    $("#" + evt.target.id).addClass("cantdelete");
    $("#" + evt.target.id).tagit("createTag", ui.tagLabel);
  }
  $(".fa-spinner").remove();
  $(".tagit-new input").removeClass("wid-80");
  $(".tagit").removeClass("disable-tag");
};

class ParticipantProperties extends Component {
  constructor(props) {
    super(props);
    const { tag_categories } = props;
    this.state = {
      newCategory: {},
      data: tag_categories,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { settingsData, tag_categories } = nextProps;
    let { data } = prevState;
    if (settingsData && settingsData.tag_categories) {
      return { data: settingsData.tag_categories };
    }
    if (JSON.stringify(tag_categories) != JSON.stringify(data)) {
      return {
        data: tag_categories ? tag_categories : data,
      };
    }
    return null;
  }

  componentDidMount() {
    this.addTagItToTextBox();
  }

  componentDidUpdate() {
    this.addTagItToTextBox();
  }

  addTagItToTextBox() {
    let _that = this;
    const { data } = this.state;
    const { canEditParticipantTagging, showSimTagsToLink, tag_categories_to_link: tagCategoriesToLink } = this.props;
    data.map((category) => {
      _that.initializeTagIt(category, _that, canEditParticipantTagging);
    });
    if (showSimTagsToLink) {
      tagCategoriesToLink.map((category) => {
        _that.initializeReadOnlyTagIt(category);
      });
    }
  }

  initializeReadOnlyTagIt = (category) => {
    $("#" + category.name.split(" ").join("") + category.id).tagit({
      availableTags: category.tags,
      allowSpaces: false,
      singleField: true,
      readOnly: true,
    });
  };

  initializeTagIt = (category, obj, canEditParticipantTagging) => {
    let that = this;
    $("#" + category.name.split(" ").join("") + category.id).tagit({
      availableTags: category.tags,
      allowSpaces: true,
      singleField: true,
      readOnly: !canEditParticipantTagging,
      singleFieldNode: $(".category_tags"),
      beforeTagRemoved: (evt, ui) =>
        beforeTagRemoved(evt, ui, this.state.data, category.id, that.props.cannotRemoveTags),
      afterTagRemoved: async (evt, ui) => {
        afterTagRemoved(evt, ui, { obj, category });
      },
      afterTagAdded: async (evt, ui) => {
        if ($("#" + evt.target.id).hasClass("cantdelete")) {
          $("#" + evt.target.id).removeClass("cantdelete");
        } else {
          let id = $(evt.target).closest("tr").find("td:nth-child(2) input").prop("id");
          let temp = evt.target.value.includes(ui.tagLabel) ? evt.target.value : evt.target.value + "," + ui.tagLabel;
          let data = [
            obj.props.company.id,
            {
              tag_category: {
                id: id,
                module_cd: obj.props.moduleCd,
              },
              tag_name: temp,
            },
          ];
          if (!ui.duringInitialization) {
            let length = evt.target.nextElementSibling.childNodes.length - 1;
            $(".tagit-new input").prop("disabled", "true");
            $(".tagit-new input").addClass("wid-80");
            $(evt.target.nextElementSibling.childNodes[length]).prepend(
              "<i class='fa fa-spinner fa-spin fa-black'></i>"
            );
            await obj.props.TagUi(data);
            $(".tagit-new input").removeAttr("disabled");
            $(".fa-spinner").remove();
            $(".tagit-new input").removeClass("wid-80");
            $(evt.target.nextElementSibling.childNodes[length].childNodes[0]).focus();
          }
        }
      },
    });
  };

  removeRow = (e, index) => {
    let { newCategory } = this.state;
    delete newCategory[index];
    this.setState({ newCategory });
  };

  addRow = (e, index) => {
    let tagName = this[`catName_${index}`].value;
    let mandatory = this[`catMandatory_${index}`].checked;
    let data = [
      this.props.company.id,
      {
        tag_category: {
          name: tagName,
          mandatory: mandatory,
          module_cd: this.props.moduleCd,
        },
      },
    ];
    let { newCategory } = this.state;
    delete newCategory[index];
    this.setState({ newCategory });
    this.props.TagUiCreate(data);
  };

  updateValue = (e, index) => {
    let { newCategory } = this.state;
    newCategory[index].value = e.target.value;
    this.setState({ newCategory });
  };

  updateMandatory = (e, index) => {
    let { newCategory } = this.state;
    newCategory[index].mandatory = e.target.checked;
    this.setState({ newCategory });
  };

  addNewTagCategory = (category, index) => {
    const { translations } = this.props;
    return (
      <tr key={index}>
        <td>
          <input
            data_index={index}
            ref={(ref) => {
              this[`catName_${index}`] = ref;
            }}
            type="text"
            className="form-control"
            data_index={index}
            placeholder={translations.enter_category_name}
            defaultValue={category.value}
            autoFocus={true}
            onBlur={(e) => this.updateValue(e, index)}
          />
        </td>
        <td className="text-center">
          <input
            type="checkbox"
            ref={(ref) => {
              this[`catMandatory_${index}`] = ref;
            }}
            tabIndex="-1"
            defaultChecked={category.mandatory}
            onChange={(e) => this.updateMandatory(e, index)}
          />
        </td>
        <td></td>
        <td className="text-center">
          <button className="btn-default btn-icon add-category-btn" onClick={(e) => this.addRow(e, index)}>
            <i className="fa fa-save" aria-hidden="true"></i>
          </button>
          <button className="btn-default remove-category btn-icon m-l10" onClick={(e) => this.removeRow(e, index)}>
            <i className="fa fa-trash red-color" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    );
  };

  addNewCategory = (e) => {
    let { newCategory } = this.state;
    let tmp = Object.keys(newCategory);
    tmp = Object.keys(newCategory).length ? +tmp[tmp.length - 1] + 1 : 1;
    let tmpv = { value: "", mandatory: false };
    Object.assign(newCategory, { [tmp]: tmpv });
    this.setState({
      newCategory,
    });
  };

  updateMandatoryField = (e, mandatoryValue, newModuleCd = undefined, categoryTags) => {
    const {
      canEditParticipantTagging,
      company: { id },
      TagUi,
      noPermission,
      translations,
      moduleCd,
    } = this.props;
    if (mandatoryValue === undefined) {
      mandatoryValue = e.target.checked;
    }
    let data = [
      id,
      {
        tag_category: {
          id: e.target.id,
          name: e.target.name,
          mandatory: mandatoryValue,
          module_cd: newModuleCd !== undefined ? newModuleCd : moduleCd,
        },
        tag_name: categoryTags,
      },
    ];
    if (!canEditParticipantTagging) {
      noPermission({ unauthorized: translations.unauthorized });
      $(e.target).prop("checked", !e.target.checked);
    } else {
      TagUi(data);
    }
  };

  deleteCategory = (catgoryId) => {
    const { company, moduleCd } = this.props;
    let data = { tag_category: { module_cd: moduleCd } };
    this.props.RemoveTagCategory(data, company.id, catgoryId);
  };

  async removeCategory(e, categoryId) {
    e.preventDefault();
    this.setState({
      categoryId,
    });
  }

  updateModule = (e, mandatoryValue, Category, categoryTags) => {
    categoryTags = categoryTags === undefined || categoryTags.length == 0 ? "" : categoryTags.toString();
    const { simModuleCd, linkModuleCd } = this.props;
    const newModuleCd = Category.module_cd == linkModuleCd ? simModuleCd : linkModuleCd;
    this.updateMandatoryField(e, mandatoryValue, newModuleCd, categoryTags);
  };

  existingTagCategory = (Category, canEditParticipantTagging, canLinkTagCategory = false, showLinkCheckbox = false) => {
    const { linkModuleCd } = this.props;
    return (
      <tr key={Category.id}>
        <td>{Category.name.split("_").join(" ")}</td>
        <td className="text-center">
          <input
            id={Category.id}
            name={Category.name}
            tabIndex="-1"
            type="checkbox"
            disabled={!canEditParticipantTagging || !_availableTags(Category.id, this.state.data)}
            defaultChecked={Category.mandatory}
            onChange={(e) => this.updateMandatoryField(e)}
          />
        </td>
        <td>
          <Fragment>
            <div className="wid-100">
              <input
                id={Category.name.split(" ").join("") + Category.id}
                type="hidden"
                tabIndex="-1"
                className="category_tagging"
                value={Category.tags}
                disabled={true}
              />
              <ul className="category_tags m-b0" />
            </div>
          </Fragment>
        </td>
        <td className="text-center">
          {canEditParticipantTagging && (
            <button
              onClick={(e) => this.removeCategory(e, Category.id)}
              data-toggle="modal"
              className="btn-default btn-icon"
              data-target="#supllierRemoveConfirmModal"
              rel="nofollow"
            >
              <i className="fa fa-trash red-color" aria-hidden="true" />
            </button>
          )}
          {showLinkCheckbox && (
            <input
              id={Category.id}
              name={Category.name}
              tabIndex="-1"
              type="checkbox"
              disabled={!canLinkTagCategory}
              defaultChecked={Category.module_cd === linkModuleCd}
              onChange={(e) => this.updateModule(e, Category.mandatory, Category, Category.tags)}
            />
          )}
        </td>
      </tr>
    );
  };

  render() {
    const { data, newCategory } = this.state;
    const {
      translations,
      canEditParticipantTagging,
      title,
      tooltipText,
      simTagsTooltipText,
      btnClass,
      showSimTagsToLink,
      tag_categories_to_link: tagCategoriesToLink,
    } = this.props;
    return (
      <>
        <div className="btn-group-sm form-item-block">
          <div>
            <div className="form-item-block-title">
              {title}
              <div className="vertical-center-info_icons">
                <Tooltip {...tooltipText} />
              </div>
            </div>
            <table className="m-b0 table table-bordered">
              <thead>
                <tr>
                  <th className="wid-30">{translations.category}</th>
                  <th className="text-center wid-10">{translations.required}</th>
                  <th className="wid-50">{translations.tags_text}</th>
                  <th className="wid-10"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((singleData) => this.existingTagCategory(singleData, canEditParticipantTagging))}
                {map(newCategory, (value, index) => this.addNewTagCategory(value, index))}
                {canEditParticipantTagging && (
                  <tr>
                    <td colSpan="4">
                      <button
                        className={btnClass}
                        id="showAddNew"
                        onClick={(e) => this.addNewCategory(e)}
                        style={{
                          float: "left",
                        }}
                      >
                        <i className="fa fa-plus-circle add-tag" aria-hidden="true"></i>
                        &nbsp;{translations.add_new_category}
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <br />
          <br />
          {showSimTagsToLink && (
            <div>
              <div className="form-item-block-title">
                {translations.link_participant_properties}
                <div className="vertical-center-info_icons">
                  <Tooltip {...simTagsTooltipText} />
                </div>
              </div>
              <table className="m-b0 table table-bordered">
                <thead>
                  <tr>
                    <th className="wid-30">{translations.category}</th>
                    <th className="text-center wid-10">{translations.required}</th>
                    <th className="wid-50">{translations.tags_text}</th>
                    <th className="wid-10">{translations.display_in_srm}</th>
                  </tr>
                </thead>
                <tbody>
                  {tagCategoriesToLink.map((singleData) =>
                    this.existingTagCategory(singleData, false, canEditParticipantTagging, true)
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <Confirm
          translations={translations}
          message={translations.are_you_sure}
          htmlId="supllierRemoveConfirmModal"
          onOkayButtonClick={() => this.deleteCategory(this.state.categoryId)}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  TagUi: (data) => dispatch(TagUi(data)),
  TagUiCreate: (data) => dispatch(TagUiCreate(data)),
  noPermission: (data) => dispatch(noPermission(data)),
  RemoveTagCategory: (data, CompanyId, catgoryId) => dispatch(RemoveTagCategory(data, CompanyId, catgoryId)),
  cannotRemoveTags: (message) => dispatch(cannotRemoveTags(message)),
});

const mapStateToProps = (state) => ({
  settingsData: state.simSettingReducers,
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantProperties);

export { beforeTagRemoved, afterTagRemoved };
