import _ from 'lodash';
import React, { Component } from 'react';
import Loader from 'react-loader-advanced';
import { connect } from 'react-redux';
import Select from 'react-select';
import { fetchAdminText } from '../../../../actions/qq/adminTextActions';
import { createNewLot, deleteLot, updateLot, updateOverview } from '../../../../actions/qq/editActions';
import { showToasterMessages } from '../../../../actions/qq/genericActions';
import { fetchCurrencies, fetchLots } from '../../../../actions/qq/qqActions';
import { confirm } from '../../util/confirm';
import LoaderMessage from '../../LoaderMessage';
import EditorPopupSection from '../QQEvent/EditorPopupSection';
import PopupMobile from '../QQEvent/PopupMobile';

export class Lots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminText: '',
      addNewLotRow: false,
      data: [],
      newRowData: {},
      disableAddbtn: false,
      disableUpdateBtn: false,
      eventRfq: false,
      selectedCurrency: {},
      showLoading: false
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchLotsData();
    this.fetchCurrencyData();
    const { sectionKey, companyId } = this.props;
    this.props.fetchAdminText(companyId, sectionKey);
  }
  
  async fetchLotsData() {
    const lots = await this.props.fetchLots(this.props.quoteId);
    let data = [];
    if (lots.status === 200) {
      data = lots.data.lots;
    }
    this.setState({ data });
  }
  
  fetchCurrencyData() {
    this.props.fetchCurrencies(this.props.userId);
  }


  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText.FETCH_ADMIN_TEXT_PRICES ? newProps.adminText.FETCH_ADMIN_TEXT_PRICES : '';
    if (newProps.default.FETCH_ADMIN_TEXT_PRICES_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_PRICES_DEL;
    }
    const state = {
      adminText,
      eventRfq: newProps.configurations.data.event_category_rfq,
      allow_to_change_currency: newProps.configurations.data.allow_to_change_currency,
      addNewLotRow: newProps.configurations.data.event_category_rfq && !this.state.data.length,
    };

    if(newProps.currencies && newProps.selectedCurrency.id && newProps.selectedCurrency.id != this.state.selectedCurrency?.value) {
      state.selectedCurrency = findCurrency(newProps.currencies, newProps.selectedCurrency.id);
    }
    this.setState(state);
  }

  onEditChange(event, i) {
    const { value, name } = event.target;
    const temp = this.state.data[i];
    temp[name] = value;
    const { data } = this.state;
    data[i] = temp;
    this.setState({ data });
  }

  onAddRowChange(event) {
    const { value, name } = event.target;
    const rowData = this.state.newRowData;
    rowData[name] = value;
    this.setState({ newRowData: rowData });
  }

  editRow(index, flag) {
    const selfThis = this;
    selfThis.refs[`lot_name_off_${index}`].hidden = flag;
    selfThis.refs[`lot_name_on_${index}`].hidden = !flag;
    selfThis.refs[`lot_uom_off_${index}`].hidden = flag;
    selfThis.refs[`lot_uom_on_${index}`].hidden = !flag;
    selfThis.refs[`lot_quantity_off_${index}`].hidden = flag;
    selfThis.refs[`lot_quantity_on_${index}`].hidden = !flag;
    selfThis.refs[`lot_actions_off_${index}`].hidden = flag;
    selfThis.refs[`lot_actions_on_${index}`].hidden = !flag;
    if (!flag) {
      const data = _.cloneDeep(this.state.data);
      this.setState({ data });
      selfThis.refs[`lot_row_${index}`].setAttribute('class', '');
    } else {
      selfThis.refs[`lot_row_${index}`].setAttribute('class', 'align-box');
    }
  }

  addNewRow(flag) {
    this.setState({ addNewLotRow: flag, disableAddbtn: false });
    if (flag) {
      this.setState({ newRowData: {} });
    }
  }

  async changeToRfq(rfq) {
    const { data } = this.state;
    const { quoteId } = this.props;
    if (data.length === 0) {
      const payload = {
        event: {
          event_category_rfq: rfq,
        },
      };
      const response = await this.props.updateOverview(quoteId, payload);
      if (response.status === 200) {
        this.setState({ eventRfq: rfq });
      }
    }
    this.addNewRow(rfq);
  }

  _saveCurrency = async (event) => {
    this.setState({
      showLoading: true
    });

    const { selectedCurrency } = this.state;
    const { quoteId } = this.props;
    const payload = {
      event: {
        currency_id: selectedCurrency.value,
      },
    };
    
    this.props.updateOverview(quoteId, payload).then(() => {
      this.setState({
        showLoading: false
      });
    });
  }

  async updateLot(lotData, index) {
    const parsedJson = this.parseJson(lotData);
    this.setState({ disableUpdateBtn: true });
    const payload = {
      lots: parsedJson,
    };
    const response = await this.props.updateLot(lotData.event_id, lotData.id, payload);
    if (response.status === 200) {
      const lots = this.state.data;
      lots[index] = response.data.lot;
      this.setState({ data: lots });
      this.props.updateEventData('lots', lots);
      this.editRow(index, false);
    }
    this.setState({ disableUpdateBtn: false });
  }

  async saveNewLot() {
    const { quoteId, configurations } = this.props;
    const { data } = this.state;
    const lotsLimit = configurations.limits.qq_lots_limit;
    this.setState({ disableAddbtn: true });

    if (data.length < lotsLimit) {
      const parsedJson = this.parseJson(this.state.newRowData);
      const payload = {
        lot: parsedJson,
      };
      const response = await this.props.createNewLot(quoteId, payload);
      if (response.status === 201) {
        let lots = _.cloneDeep(this.state.data);
        lots = lots.concat(response.data.lot);
        this.setState({ data: lots });
        this.addNewRow(false);
        this.setState({ disableAddbtn: false, addNewLotRow: false });
      } else {
        this.setState({ disableAddbtn: false });
      }
    } else {
      this.props.showToasterMessages('SHOW_ERROR', this.props.translations.error_message.max_lots_limit);
    }
  }

  async deleteLot(index, lotData) {
    let pop_up_translations = {
      ConfirmLabel: this.props.translations.pop_up.confirm,
      okLabel: this.props.translations.pop_up.ok,
      cancelLabel: this.props.translations.pop_up.cancel,
    };
    confirm(this.props.translations.pop_up.confirm_popup_delete, pop_up_translations).then(async () => {
      const response = await this.props.deleteLot(lotData.event_id, lotData.id);
      if (response.status === 200) {
        const lots = _.cloneDeep(this.state.data);
        lots.splice(index, 1);
        this.setState({ data: lots });
        if (lots.length === 0) {
          this.setState({ addNewLotRow: !this.state.addNewLotRow, data: [] });
          this.changeToRfq(false);
        }
      }
    }, () => { /* cancel */ });
  }

  parseJson(badJson) {
    return JSON.parse(JSON.stringify(badJson).replace(/"\s+|\s+"/g, '"'));
  }

  _selectCurrency = (data, instance) => {
    this.setState({selectedCurrency: data});
  }

  _isDisabled = () => {
    const { selectedCurrency } = this.state;
    return !(!!selectedCurrency.value && selectedCurrency.value !== this.props.currencyId);
  }

  render() {
    const { companyId, sectionKey, translations } = this.props;
    const {
      adminText, addNewLotRow, data, newRowData, disableAddbtn, disableUpdateBtn, eventRfq, selectedCurrency, allow_to_change_currency
    } = this.state;
    let showLotTable;
    if (eventRfq) {
      showLotTable = true;
    } else if (data.length > 0) {
      showLotTable = true;
    } else {
      showLotTable = false;
    }
    const regexToQuantity = /^[0-9.,]+$/;

    return (
      <Loader show={ !this.state.adminText || !this.props.currencies } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles priority={ 1 }>
        <div className='block-item'>
          <div className='col-md-6 p-l0 table-cell-box'>
            <div className='form-item-block bg-white'>
              {this.props.currencies && <div className="m-b20">
                <h4>{translations.currency}</h4>
                <Select
                  isDisabled={!allow_to_change_currency}
                  value={selectedCurrency}
                  options={this.props.currencies}
                  className="resource-type-select"
                  isSearchable={true}
                  onChange={this._selectCurrency}
                />
              </div>}
              <button
                type='button'
                label='save-curreny-button'
                className='btn btn-primary add-new-lot m-b20'
                disabled={ this._isDisabled() || this.state.showLoading || !allow_to_change_currency}
                onClick={this._saveCurrency}
              >{translations.save_currency} {this.state.showLoading && <i className='fa fa-cog fa-spin fa-fw' /> }
              </button>
              <h4>{translations.summary_status.participant_list.lots}</h4>
              <PopupMobile
                companyId={ companyId }
                sectionKey={ sectionKey }
                text={ this.state.adminText }
                translations={ translations }
              />
              <div className='event-list table-scroll'>
                {data.length === 0 &&
                <div className='checkbox'>
                  <div>
                    <input
                      id='checkbox2'
                      type='checkbox'
                      name='checkbox'
                      checked={ eventRfq }
                      onChange={ () => this.changeToRfq(!eventRfq) }
                    />
                    <label htmlFor='checkbox2'>
                      <span />{translations.qq_create.qq_price.include_price}
                    </label>
                  </div>
                </div>}
                {showLotTable &&
                <table className='common-table table-align-top'>
                  <thead className='table-block-title'>
                    <tr>
                      <th className='column-first'>{translations.summary_status.summary.lot_name}</th>
                      <th className='column-second'>{translations.qq_edit.unit_of_measurement}</th>
                      <th className='column-third'>{translations.qq_edit.quantity}</th>
                      <th className='column-fourth'>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((lotsRow, index) => (
                      <tr ref={ `lot_row_${index}` } key={ lotsRow.id }>
                        <td>
                          <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.summary_status.summary.lot_name}</div>
                          <div ref={ `lot_name_off_${index}` }>{lotsRow.name}</div>
                          <div hidden ref={ `lot_name_on_${index}` }>
                            <input
                              type='text'
                              name='name'
                              value={ lotsRow.name }
                              onChange={ e => this.onEditChange(e, index) }
                              placeholder={translations.qq_edit.name}
                              className='form-control'
                            />
                            {!lotsRow.name && <span className='text-danger'>{translations.error_message.required}</span>}
                            {lotsRow.name && !lotsRow.name.trim() &&
                            <span className='text-danger'>
                              {translations.error_message.no_white_space}
                            </span>}
                            {lotsRow.name && lotsRow.name.trim().length > 150 &&
                            <span className='text-danger'>
                              {translations.error_message.name_char_long.replace("%{name_string}", translations.summary_status.summary.lot_name).replace("%{name_limit}", 150)}
                            </span>}
                          </div>
                        </td>
                        <td>
                          <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.unit_of_measurement}</div>
                          <div ref={ `lot_uom_off_${index}` }>{lotsRow.unit_of_measure ? lotsRow.unit_of_measure : '-'}</div>
                          <div hidden ref={ `lot_uom_on_${index}` }>
                            <input
                              type='text'
                              name='unit_of_measure'
                              value={ lotsRow.unit_of_measure }
                              onChange={ e => this.onEditChange(e, index) }
                              placeholder={ translations.qq_edit.unit_of_measurement }
                              className='form-control'
                            />
                          </div>
                        </td>
                        <td>
                          <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.quantity}</div>
                          <div ref={ `lot_quantity_off_${index}` }>{lotsRow.quantity ? lotsRow.quantity : '-'}</div>
                          <div hidden ref={ `lot_quantity_on_${index}` }>
                            <input
                              type='text'
                              name='quantity'
                              value={ lotsRow.quantity }
                              onChange={ e => this.onEditChange(e, index) }
                              placeholder={translations.qq_edit.quantity}
                              className='form-control'
                            />
                            {(lotsRow.quantity && lotsRow.quantity <= 0) &&
                            <span className='text-danger'>
                              {translations.error_message.quantity_limit.replace("%{string}", translations.qq_edit.greater).replace("%{number}", 0)}
                            </span>}
                            {(lotsRow.quantity && lotsRow.quantity > 999999999999.99) &&
                            <span className='text-danger'>
                              {translations.error_message.quantity_limit.replace("%{string}", translations.qq_edit.less).replace("%{number}", 999999999999.99)}
                            </span>}
                            {(!regexToQuantity.test(lotsRow.quantity) && !(lotsRow.quantity <= 0) && !(lotsRow.quantity > 999999999999.99)) &&
                            <span className='text-danger'>
                              {translations.error_message.invalid_number}
                            </span>}
                          </div>
                        </td>
                        <td className='text-right'>
                          <div ref={ `lot_actions_off_${index}` }>
                            <button className='icon-link' onClick={ () => this.editRow(index, true) }>
                              <i className='fa fa-pencil-square-o' aria-hidden='true' />
                            </button>
                            <button className='icon-link' onClick={ () => this.deleteLot(index, lotsRow) }>
                              <i className='fa fa-trash-o' aria-hidden='true' />
                            </button>
                          </div>
                          <div hidden ref={ `lot_actions_on_${index}` }>
                            <button
                              className='icon-link'
                              disabled={ !lotsRow.name || !lotsRow.name.trim() || lotsRow.name.trim().length > 150 ||
                              disableUpdateBtn || (lotsRow.quantity && (lotsRow.quantity <= 0 || lotsRow.quantity > 999999999999.99)) || (!regexToQuantity.test(lotsRow.quantity) && lotsRow.quantity !== undefined && lotsRow.quantity.trim() !== '') }
                              onClick={ () => this.updateLot(lotsRow, index) }
                            >
                              <i className='fa fa-check' aria-hidden='true' />
                            </button>
                            <button className='icon-link' onClick={ () => this.editRow(index, false) }>
                              <i className='fa fa-times' aria-hidden='true' />
                            </button>
                          </div>
                        </td>
                      </tr>
                  ))}
                    {addNewLotRow &&
                    <tr className='align-box bg-gray'>
                      <td>
                        <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.summary_status.summary.lot_name}</div>
                        <input
                          type='text'
                          name='name'
                          onChange={ e => this.onAddRowChange(e) }
                          placeholder={translations.qq_edit.name}
                          className='form-control'
                        />
                        {!newRowData.name && <span className='text-danger'>{translations.error_message.required}</span>}
                        {newRowData.name && !newRowData.name.trim() &&
                        <span className='text-danger'>{translations.error_message.no_white_space}</span>}
                        {newRowData.name && newRowData.name.trim().length > 150 &&
                        <span className='text-danger'>
                          {translations.error_message.name_char_long.replace("%{name_string}", translations.summary_status.summary.lot_name).replace("%{name_limit}", 150)}
                        </span>}
                      </td>
                      <td>
                        <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.unit_of_measurement}</div>
                        <input
                          type='text'
                          name='unit_of_measure_uomname'
                          onChange={ e => this.onAddRowChange(e) }
                          placeholder={translations.qq_edit.unit_of_measurement}
                          className='form-control'
                        />
                      </td>
                      <td>
                        <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.quantity}</div>
                        <input
                          type='text'
                          name='quantity'
                          onChange={ e => this.onAddRowChange(e) }
                          placeholder={translations.qq_edit.quantity}
                          className='form-control'
                        />
                        {(newRowData.quantity && newRowData.quantity <= 0) &&
                        <span className='text-danger'>
                          {translations.error_message.quantity_limit.replace("%{string}", translations.qq_edit.greater).replace("%{number}", 0)}
                        </span>}
                        {(newRowData.quantity && newRowData.quantity > 999999999999.99) &&
                        <span className='text-danger'>
                          {translations.error_message.quantity_limit.replace("%{string}", translations.qq_edit.less).replace("%{number}", 999999999999.99)}
                        </span>}
                        {(!regexToQuantity.test(newRowData.quantity) && newRowData.quantity !== undefined && newRowData.quantity.trim() !== '' && !(newRowData.quantity <= 0) && !(newRowData.quantity > 999999999999.99)) &&
                        <span className='text-danger'>
                          {translations.error_message.invalid_number}
                        </span>}
                      </td>
                      <td className='text-right'>
                        <button
                          className='icon-link'
                          disabled={ !newRowData.name || !newRowData.name.trim() || newRowData.name.trim().length > 150 ||
                          disableAddbtn || (newRowData.quantity && (newRowData.quantity <= 0 || newRowData.quantity > 999999999999.99)) || (!regexToQuantity.test(newRowData.quantity) && newRowData.quantity !== undefined && newRowData.quantity.trim() !== '') }
                          onClick={ () => this.saveNewLot() }
                        >
                          <i className='fa fa-check' aria-hidden='true' />
                        </button>
                        {data.length === 0 &&
                        <button className='icon-link' onClick={ () => this.changeToRfq(false) }>
                          <i className='fa fa-times' aria-hidden='true' />
                        </button>}
                        {data.length !== 0 &&
                        <button className='icon-link' onClick={ () => this.addNewRow(false) }>
                          <i className='fa fa-times' aria-hidden='true' />
                        </button>}
                      </td>
                    </tr>}
                    {data.length !== 0 &&
                    <tr>
                      <td colSpan='4' className='table-section-head text-left'>
                        <button
                          type='button'
                          className='btn btn-primary add-new-lot'
                          disabled={ addNewLotRow }
                          onClick={ () => this.addNewRow(true) }
                        >{translations.qq_edit.add_lot}
                        </button>
                      </td>
                    </tr>}
                  </tbody>
                </table>}
              </div>
            </div>
          </div>
          <EditorPopupSection 
            text={ adminText }
            companyId={ companyId }
            sectionKey={ sectionKey }
            translations={ translations }
            updateText={ (adminText) => { this.setState({ adminText }); } }
          />
        </div>
      </Loader>
    );
  }
}

export const findCurrency = (currencies, id) => {

  if(!currencies || !id) {
    return null;
  }

  for(let i = 0 ; i < currencies.length ; i++ ) {
    for(let j = 0 ; j < currencies[i].options.length ; j++) {
      if(currencies[i].options[j].value === id) {
        return currencies[i].options[j];
      }
    }
  }

  return null;
}

const mapStateToProps = (state, ownProps) => ({
  adminText: state.adminTextReducer,
  default: state.deleteAdminTextReducer,
  configurations: state.qqReducer,
  selectedLang: state.changeLanguageReducer,
  currencies: state.qqReducer.currencies,
  selectedCurrency: state.qqReducer.data.currency
});

const mapDispatchToProps = dispatch => ({
  fetchAdminText: (companyId, sectionKey) => dispatch(fetchAdminText(companyId, sectionKey)),
  updateLot: (quoteId, lotId, payload) => dispatch(updateLot(quoteId, lotId, payload)),
  createNewLot: (quoteId, payload) => dispatch(createNewLot(quoteId, payload)),
  deleteLot: (quoteId, lotId) => dispatch(deleteLot(quoteId, lotId)),
  updateOverview: (quoteId, payload) => dispatch(updateOverview(quoteId, payload)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
  fetchLots: quoteId => dispatch(fetchLots(quoteId)),
  fetchCurrencies: (userId) => dispatch(fetchCurrencies(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lots);
