import React from "react";
import { useSelector } from "react-redux";
import { preferredBlicValue } from "../../../bidCommon";
import LiDetailModal from "./LiDetailModal";
import { showCurrency } from "../../../common";

/**
 *
 * @description Host monitor
 *
 * Column - Best Bid
 *
 */
export default function LiBestBidValue(props) {
  const { lineItemLic, licCurrency, precision, blicCurrency, lineItemBestBlic, bid, unitSet, allUom, header } = props;

  const {
    bid_line_item_components: bidLineItemComponents,
    locale,
    bids,
    translations: { number_format },
  } = useSelector((state) => state.lotReducers);

  const preferredBlicVal = lineItemBestBlic
    ? preferredBlicValue(
        lineItemBestBlic,
        lineItemLic,
        blicCurrency,
        licCurrency,
        allUom,
        unitSet,
        bid,
        bidLineItemComponents,
        bids,
        locale,
        number_format,
        "host", 
        precision
      )
    : showCurrency("-", licCurrency, precision, locale, number_format);

  return (
    <div className="mycell">
      {lineItemBestBlic ? (
        <LiDetailModal {...props} header={header} viewComponent={preferredBlicVal} />
      ) : (
        preferredBlicVal
      )}
    </div>
  );
}
