import React, { Component } from "react";

class EnforceLocales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enforce_locales: props.enforce_locale,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { enforce_locale } = nextProps;
    const { enforce_locales } = prevState;
    if (enforce_locales.enforced != enforce_locale.enforced || enforce_locales.required != enforce_locale.required) {
      return {
        enforce_locales: {
          enforced: enforce_locale.enforced,
          required: enforce_locale.required,
        },
      };
    }
    return null;
  }

  notificationUpdate = () => {
    let value = {
      enforced: this.enforce_locale.checked,
      required: this.enforce_locale.checked ? this.required.checked : false,
    };
    this.props.onValueChange(value);
  };

  enforceDetails() {
    const { translations } = this.props;
    return { __html: translations.enforce_details };
  }

  render() {
    const { translations } = this.props;
    const { enforce_locales } = this.state;
    return (
      <div className="float-right form-item-block wid-49" style={{ height: "300px" }}>
        <div className="form-item-block-title">{translations.enforce_locales}</div>
        <div className="input inline">
          <label
            className="config-label col-md-4 col-sm-4 col-xs-12 p-0 wid-100"
            dangerouslySetInnerHTML={this.enforceDetails()}
          ></label>
          <div className="input inline">
            <label className="float-left">{translations.enable}:</label>{" "}
            <input
              ref={(ref) => {
                this.enforce_locale = ref;
              }}
              type="checkbox"
              id="enforce_locale"
              name="enforce_locale"
              className="display_none material-checkbox"
              checked={enforce_locales.enforced}
              onChange={(e) => this.notificationUpdate()}
            />
            <label htmlFor="enforce_locale" className="float-left material-checkbox-label">
              <svg width="18px" height="18px" viewBox="0 0 18 18">
                <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                <polyline points="1 9 7 14 15 4"></polyline>
              </svg>
            </label>
          </div>
          <div className="input inline">
            <label className="float-left">{translations.required}:</label>{" "}
            <input
              ref={(ref) => {
                this.required = ref;
              }}
              type="checkbox"
              id="required"
              name="required"
              className="display_none material-checkbox"
              checked={enforce_locales.required}
              disabled={!enforce_locales.enforced}
              onChange={(e) => this.notificationUpdate()}
            />
            <label htmlFor="required" className="float-left material-checkbox-label">
              <svg width="18px" height="18px" viewBox="0 0 18 18">
                <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                <polyline points="1 9 7 14 15 4"></polyline>
              </svg>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default EnforceLocales;
