import React, { useState } from "react";
import UseLotTemplateForm from "./UseLotTemplateForm";

const UseLotTemplate = (props) => {
  const { translations, classFromEventState } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <a
        className={`btn auto_width_btn ${classFromEventState} status-event sm-butt m-r0`}
        onClick={() => setModalIsOpen(true)}
      >
        <i className="fa fa-plus" />
        &nbsp;
        {translations.load_from_library}
      </a>
      {modalIsOpen && <UseLotTemplateForm {...props} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />}
    </>
  );
};

export default UseLotTemplate;
