import React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  differenceOfferedForCell,
  differenceOfferedPercentageForBlic,
  formatDifferenceOfferedPercentage,
} from "../../../bidCommon";
import { differenceOfferedPercentageForLic, host } from "../../../common";

/**
 * @description Participant Saving Percentage for LineItemComponent
 * Monitor Tab =>
 * Opens up once clicked on Show Components
 *
 * Column - Saving Percentage
 */
export default function SavingsPercentage(props) {
  const { lotComponent, lic, participantLotBid, blic, licCurrency, bidBlics, lineItem, allUnitSets, LBids } = props;

  // return "-" if lic is undefined
  if (!lic)
    return (
      <div className="mycell difference" title="-">
        -
      </div>
    );

  const { event, translations, locale } = useSelector((state) => state.lotReducers);
  const { number_format, na_in_small_letters } = translations;
  const allUom = props.unitSetUoms.concat(props.unitOfMeasures);

  const diffValueArgs = [
    participantLotBid,
    blic,
    lic,
    lotComponent,
    lineItem,
    event,
    licCurrency,
    allUnitSets,
    allUom,
    LBids,
    bidBlics,
    locale,
    number_format,
  ];
  const diffValue = useMemo(
    () => differenceOfferedForCell(...diffValueArgs),
    [participantLotBid, blic, lic, lotComponent, lineItem]
  );
  const val = useMemo(
    () =>
      host(lotComponent)
        ? differenceOfferedPercentageForLic(event, lic, lineItem, lotComponent)
        : differenceOfferedPercentageForBlic(event, blic, lic, allUom, participantLotBid, bidBlics, LBids),
    [lic, blic]
  );

  const result = formatDifferenceOfferedPercentage(val, na_in_small_letters, locale);
  return (
    <div className="mycell difference" title={participantLotBid ? `${diffValue} (${result["value"]}` : ""}>
      <span className="value">{diffValue}</span>
      {participantLotBid ? <span className={`percentage diff-${result["class"]}`}>({result["value"]})</span> : ""}
    </div>
  );
}
