import React, { Fragment, lazy, Suspense, memo } from "react";
import { isEqual } from "lodash";
import { isLotTotalPresent } from "../../../common";
import useLineItemInformation from "@/components/advanced_lots/hooks/useLineItemInformation";
const LineItemsInformation = lazy(() => import("./LineItemsInformation"));

/**
 *
 * @description  HOST MONITOR TAB
 *
 * This will show line Item information and bid value when expand Line Items
 */
const LotLineItemsInformation = memo(function LotLineItemsInformation(props) {
  const { lotLis, lot } = props;
  const isLotTotal = isLotTotalPresent(lot);

  const { lastElementRef, paginatedData } = useLineItemInformation(lotLis);

  return (
    <Fragment>
      {paginatedData.map((lotLi, index) => (
        <Suspense fallback={<></>} key={lotLi.id}>
          <LineItemsInformation {...props} lotLi={lotLi} isLotTotal={isLotTotal} index={index} />
        </Suspense>
      ))}
      <div ref={lastElementRef} />
    </Fragment>
  );
}, isEqual);

export default LotLineItemsInformation;
