import React from "react";

import { useUpToDateValue } from "@/hooks/use-up-to-date-value";

import { CommonInputProps } from "./index";
import InputStatusIcon from "./input-status-icon";

const InputTextPropTypes = { ...CommonInputProps };

/**
 * @type {React.FC<typeof InputTextPropTypes>}
 */
export const InputText = ({ value, onChange, onFocus, state }) => {
  const [localValue, setLocalValue] = useUpToDateValue(value);
  const handleChange = ({ target: { value } }) => setLocalValue(value);
  const commit = () => {
    onChange && onChange([{ attribute_value: localValue }]);
  };

  return (
    <div className="text-based-blic-with-button input-group form-width m-b0">
      <input
        size="15"
        className="form-control bid-attribute-field blic_field no-spin"
        type="text"
        value={localValue || ""}
        onChange={handleChange}
        onBlur={commit}
        onFocus={onFocus}
      />
      <InputStatusIcon state={state} />
    </div>
  );
};

InputText.propTypes = InputTextPropTypes;
InputText.defaultProps = {};

export default InputText;
