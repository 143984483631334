import actionTypes from '../../actions/qq/actionsTypes';

export const participantGroupsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.fetch_participant_groups:
      return action.payload;

    default:
      return state;
  }
};

export const eventParticipantsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.fetch_event_participants:
      return action.payload;

    default:
      return state;
  }
};

export const eventParticipantsOnboardingStatusReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.fetch_onboarding_statuses:
      return action.payload;

    default:
      return state;
  }
};
