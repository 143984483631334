import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { openBidModal } from "../../../../actions/lotActions";
import { useLotStatus } from "@/selectors/lotSelectors";

const PlaceBidButtonGroup = (props) => {
  const { translations } = props;
  const { lotStatus, isPending } = useLotStatus(props.lot.id);
  const dispatch = useDispatch();

  const isBidSubmitting = lotStatus === "pending:submit";
  const isCanceling = lotStatus === "pending:cancel";

  return (
    <>
      {!props.hideAlert && (
        <FillCellAlert translations={translations} enableSubmit={props.enableSubmit} lot={props.lot} />
      )}
      {!props.hideTopButton && (
        <>
          <button
            disabled={isPending || !props.enableSubmit}
            className={classNames({
              "btn btn-sm btn-primary pull-right submit-bid m-l5": true,
              disabled: isPending || !props.enableSubmit,
            })}
            onClick={() => {
              dispatch(openBidModal(`#participantsPlaceBidConfirmModal-${props.lot.id}`));
            }}
          >
            {isBidSubmitting ? translations.submitting : translations.submit}
          </button>
          <button
            disabled={isPending}
            className={classNames({
              "btn btn-sm btn-default pull-right cancel-bid": true,
              disabled: isPending,
            })}
            onClick={props.onCancelBid}
          >
            {isCanceling ? translations.canceling : translations.cancel}
          </button>
        </>
      )}
    </>
  );
};

PlaceBidButtonGroup.propTypes = {
  translations: PropTypes.object.isRequired,
  lot: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  onCancelBid: PropTypes.func.isRequired,
  enableSubmit: PropTypes.bool,
  hideAlert: PropTypes.bool,
  hideTopButton: PropTypes.bool,
};

PlaceBidButtonGroup.defaultValue = {
  hideAlert: false,
  hideTopButton: false,
};

const FillCellAlert = ({ enableSubmit, translations, lot }) => (
  <div
    className={[
      enableSubmit ? "hidden " : "",
      " alert alert-info bid-range-wrapper height-auto m-b0 m-l10 p-t2 position-sticky pull-right animated fadeIn",
    ]}
  >
    <div className="font-s13 m-t5">
      {lot.lot_total_lic_id ? translations.please_fill_in_every_cell : translations.please_fill_in_every_li_cell}
    </div>
  </div>
);

FillCellAlert.propTypes = {
  enableSubmit: PropTypes.bool.isRequired,
  translations: PropTypes.object.isRequired,
};

export default PlaceBidButtonGroup;
export { FillCellAlert };
