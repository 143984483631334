import React, { Fragment, useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { linkContent, isLotTotal } from "../../common";

import EditLineItemComponentModal from "./EditLineItemComponentModal";
import ShowLicDetails from "./ShowLicDetails";
import { useDispatch } from "react-redux";
import { editLineItemComponent } from "../../../../actions/lineItemComponentActions";

const LineItemComponentLink = (props) => {
  const {
    event,
    lot,
    anyCompletedBids,
    translations,
    permittedToEdit,
    lic,
    currency,
    classFromEventState,
    openOrJapanese,
    isJapaneseEvent,
    showLoaderOnLicId,
    lots,
    showDetails,
    currenciesHash,
    locale,
    timeZoneIdentifier,
    currentUser,
    calculationComponent,
    calculableCurrency,
    maxPrecisionFor,
  } = props;

  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLotTotalLic, setIsLotTotalLic] = useState(isLotTotal(lot, lic));
  const [licForEdit, setLicForEdit] = useState(lic);
  const [picklistOptions, setPicklistOptions] = useState([]);
  const [uoms, setUoms] = useState([]);
  const [licsForFormula, setLicsForFormula] = useState([]);
  useEffect(() => {
    setIsLotTotalLic(isLotTotal(lot, lic));
  }, [lot]);

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setIsLotTotalLic(isLotTotal(lot, lic));
  };

  const handleDragStart = useCallback((event) => {
    event.dataTransfer.setData("text", event.target.className);
  }, []);

  const displayLicDetails = useCallback(() => {
    return (
      <ShowLicDetails
        lic={lic}
        translations={translations}
        currency={currency}
        maxPrecisionFor={maxPrecisionFor}
        locale={locale}
      />
    );
  }, []);

  const handleModalIsOpen = () => {
    dispatch(editLineItemComponent(lic.lot_component_id, lic.id)).then((response) => {
      if (response) {
        const { line_item_component, lic_picklist_options, unit_of_measures, lics_for_formula } = response;
        setLicForEdit({ ...licForEdit, ...line_item_component });
        setPicklistOptions(lic_picklist_options);
        setUoms(unit_of_measures);
        setLicsForFormula(lics_for_formula);
        setModalIsOpen(true);
      }
    });
  };

  const starRight = permittedToEdit && lic.is_formula_lic && !lic.participant;
  let licContent = [];
  const showLic = linkContent(lic, translations, locale, timeZoneIdentifier, currency);
  licContent.push(
    permittedToEdit ? (
      <a
        className={classNames({
          "enter-info": translations.enter_info === showLic,
        })}
        onClick={() => handleModalIsOpen()}
      >
        {showLic}
      </a>
    ) : (
      <span className="view-permission">{showLic}</span>
    )
  );

  return (
    <>
      <span className="l-tag left">{lic.tag}</span>
      {licContent.map((licCon, i) => (
        <Fragment key={`${lic.id}-${i}`}>{licCon}</Fragment>
      ))}
      {lic && lic.is_price ? (
        <div
          className={`lot-total-reference-wrapper
                      ${permittedToEdit && !anyCompletedBids ? " draggable" : " "}
                      pull-left`}
        >
          {isLotTotalLic && !lic.host ? (
            // draggable - HTML5 drag and drop API - used to drag the star so we can drop it on suitable LICs
            <i
              title={`${anyCompletedBids ? translations.drag_icon_to_cell_to_make_lot_total : ""}`}
              className="fa fa-star green-color lot-total-star"
              data-lot={lot.id}
              draggable={true}
              onDragStart={handleDragStart}
              id="draggable-star"
            />
          ) : showLoaderOnLicId === lic.id ? (
            <i className="fa fa-spinner fa-spin green-color" />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {showDetails.length > 0 && showDetails.includes(lic.line_item_id) && displayLicDetails()}

      {modalIsOpen && (
        <EditLineItemComponentModal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          handleCloseModal={handleCloseModal}
          translations={translations}
          lot={lot}
          lic={licForEdit}
          classFromEventState={classFromEventState}
          anyCompletedBids={anyCompletedBids}
          openOrJapanese={openOrJapanese}
          event={event}
          isJapaneseEvent={isJapaneseEvent}
          picklistOptions={picklistOptions}
          currency={currency}
          isLotTotalLic={isLotTotalLic}
          setIsLotTotalLic={setIsLotTotalLic}
          lots={lots}
          currenciesHash={currenciesHash}
          currentUser={currentUser}
          uoms={uoms}
          setUoms={setUoms}
          calculationComponent={calculationComponent}
          calculableCurrency={calculableCurrency}
          licsForFormula={licsForFormula}
          locale={locale}
        />
      )}
    </>
  );
};
export default LineItemComponentLink;
