import React from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";

/**
 * Component for showing activities count on table top.
 *
 * @param {object} Props
 * @returns {JSX}
 */
const ActivitiesInfo = ({ translations }) => {
  const count = useSelector((state) => state.ActivityReducer.activitiesCount, shallowEqual);
  const activitiesInfoCount = [
    {
      title: translations.open,
      count: count.open_activities_count || 0,
      class: "boldtitle-reverse m-l1 vertical-baseline label-archived_at-documents",
    },
    {
      title: translations.overdue,
      count: count.overdue_activities_count || 0,
      class: "boldtitle-reverse m-l1 vertical-baseline bg-red",
    },
  ];

  return activitiesInfoCount.map((info) => (
    <span className="m-l25" key={`activities-info-count-${info.title}`}>
      {info.title}: <span className={info.class}>{info.count}</span>
    </span>
  ));
};

ActivitiesInfo.propTypes = {
  translations: PropTypes.object.isRequired,
};

export default ActivitiesInfo;
