import type { Contract, Company, LicenseTiers } from "@/common-prop-types";
import type { CustomFieldType } from "@/common-prop-types/custom-field";
import type { CustomColumnValues } from "@/slices/contracts/listing/types";
import type { EventCollaborator } from "@/slices/events/collaborators";
import { createAction } from "@reduxjs/toolkit";

declare global {
  interface Window {
    __HYDRATION_INFO?: HydrationInfo;
  }

  interface JQuery {
    datetimepicker(options?: any): any;
    datepicker(options?: any): any;
  }
}

export interface HydrationInfo {
  contractListing?: {
    contracts: Contract[];
    contractColumns: { data: Record<string, boolean> };
    canCreateContract: boolean;
    customColumns: CustomFieldType[];
    customColumnValues: CustomColumnValues;
  };
  company?: Company;
  licence_tiers?: LicenseTiers;
  eventCollaborators?: {
    event: {
      id: string;
    };
    collaborators: [EventCollaborator, ...Array<EventCollaborator>];
  };
}

export const hydrated = createAction<HydrationInfo, "root/hydrated">("root/hydrated");
