import type { RootState } from "@/store";
import { ColumnType, type ComparisonFilter } from "./types";
import { selectAllFilters, selectListingSearch } from ".";
import { customFetch } from "@/lib/fetch";
import type { AsyncThunkPayloadCreator } from "@reduxjs/toolkit";

export type FilterResponse = {
  filtered: boolean;
  selected_contract_ids: number[];
};

const applyEqualityFilters = (filters: Partial<Record<string, unknown>>, params: Record<string, unknown>) => {
  Object.assign(params, filters);
};

const applyComparisonFilters = (
  filters: Partial<Record<string, ComparisonFilter<string | number>>>,
  params: Record<string, unknown>
) => {
  for (const [key, filter] of Object.entries(filters)) {
    if (!filter) continue;
    params[key] = filter.value;
    params[`${key}_comparison`] = filter.type;
  }
};

const applyMultiPickFilters = (filters: Partial<Record<string, unknown[]>>, params: Record<string, unknown>) => {
  for (const [key, values] of Object.entries(filters)) {
    if (!values) continue;
    params[key] = values.map((text) => ({ id: text, text }));
  }
};

export const filterContracts: AsyncThunkPayloadCreator<FilterResponse, void> = async (
  _,
  { getState, requestId, signal }
) => {
  const state = getState() as RootState;

  const params: Record<string, unknown> = { search: selectListingSearch(state) };

  const filters = selectAllFilters(state);

  applyEqualityFilters(filters[ColumnType.Text], params);
  applyComparisonFilters(filters[ColumnType.Numeric], params);
  applyComparisonFilters(filters[ColumnType.Date], params);
  applyMultiPickFilters(filters[ColumnType.Checkbox], params);
  applyEqualityFilters(filters[ColumnType.SinglePick], params);
  applyMultiPickFilters(filters[ColumnType.MultiPick], params);

  const response = await customFetch(
    {
      url: "/contracts/filter_contracts",
      method: "POST",
      body: params,
    },
    { requestId, signal }
  );

  return (await response.json()) as FilterResponse;
};

interface ToggleArchivedOrDeleteResponse {
  new_state: string;
  contract_archived_destroy_status: string;
  status: string;
}

export const toggleArchived: AsyncThunkPayloadCreator<ToggleArchivedOrDeleteResponse, number> = async (
  contractId,
  { signal, requestId }
) => {
  const response = await customFetch(
    {
      url: `/contracts/${contractId}/mark_archive`,
      method: "DELETE",
      body: JSON.stringify({ contract_id: contractId }),
    },
    { signal, requestId }
  );
  return (await response.json()) as ToggleArchivedOrDeleteResponse;
};
