import React, { Component } from "react";
import { connect } from "react-redux";
import replace from "lodash/replace";
import {
  getCompanyUserPermissions,
  updateAdminPermission,
  deleteAdminPermission,
  createAdminPermission,
  deleteUserPermission,
  notifyUsersAboutChanges,
  createEventPermission,
  createContractPermission,
  deleteContractPermission,
  updateEventPermission,
  updateContractPermission,
  deleteEventPermission,
  getAdminPermissions,
  getEventPermissions,
  getContractPermissions,
  createCategoryPermission,
  updateCategoryPermission,
  getCategoryPermissions,
  deleteCategoryPermission,
  deactivateUserPermission,
} from "../../../../actions/adminActions";
import { HostPermissionsTableWithPagination } from "./HostPermissionsTable";
import UserPermissionEditModal from "./UserPermissionEditModal";
import { Confirm } from "../../../common/Confirm";
import AddUserModal from "./AddUserModal";
import actionTypes from "../../../../actions/actionsTypes";
import SliceProvider from "@/context/SliceContext";
import { adminPermissionsPaginationSlice } from "@/slices/pagination";
import EditPermissionActionButtons from "./EditPermissionActionButtons";
import RenderHTML from "@/components/common/RenderHTML";

export class HostPermissions extends Component {
  constructor(props) {
    super(props);

    // Binding this
    this.updateAdminPermissionComponent = this.updateAdminPermissionComponent.bind(this);
    this.saveEventPermission = this.saveEventPermission.bind(this);
    this.saveContractPermission = this.saveContractPermission.bind(this);
    this.handleDeleteContractPermission = this.handleDeleteContractPermission.bind(this);
    this.handleDeleteEventPermission = this.handleDeleteEventPermission.bind(this);
    this.handleDeleteUserPermission = this.handleDeleteUserPermission.bind(this);
    this.handleDeactivateUser = this.handleDeactivateUser.bind(this);

    // Setting initial state
    this.state = {
      userPermissions: [],
    };
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    let { userPermissions } = newProps.data;
    if (userPermissions) {
      userPermissions = await this.checkNotificationStatus(userPermissions);

      this.setState({ userPermissions });
    }
  }

  async checkNotificationStatus(userPermissions) {
    const { adminPermissionsList, marketDojo, categoryDojo, contractDojo, simDojo } = this.props;
    await userPermissions.forEach(async (userPermission) => {
      const adminPermissions = userPermission.admin_permissions;
      const eventPermissions = userPermission.event_permissions;
      const contractPermissions = userPermission.contract_permissions;
      const participantPermissions = userPermission.participant_permissions;
      let categoryPermissions = userPermission.category_permissions;
      let flag = true;
      adminPermissions.forEach((permission) => {
        if (adminPermissionsList.includes(permission.section) && !permission.notified) {
          flag = false;
        }
      });
      if (marketDojo && eventPermissions.length) {
        await eventPermissions.forEach((permission) => {
          if (!permission.notified) {
            flag = false;
          }
        });
      }
      if (contractDojo && contractPermissions.length) {
        await contractPermissions.forEach((permission) => {
          if (!permission.notified) {
            flag = false;
          }
        });
      }
      if (categoryDojo && categoryPermissions.length) {
        categoryPermissions = await categoryPermissions.map((permission) => {
          if (!permission.notified) {
            flag = false;
          }
          if (
            permission.resource_type === "CategoryDojo::Portfolio" ||
            permission.resource_type === "CategoryDojo::Category"
          ) {
            permission.resource_type = replace(permission.resource_type, "CategoryDojo::", "");
          }
          return permission;
        });
        userPermission.category_permissions = categoryPermissions;
      }
      if (simDojo && participantPermissions.length) {
        participantPermissions.forEach((permission) => {
          if (!permission.notified) {
            flag = false;
          }
        });
      }
      userPermission.notification_status = flag;
    });
    return userPermissions;
  }

  render() {
    const { translations, companyId, allUsers, categoryDojo, currentUser, simDojo, currentUserCanEditPermissions } =
      this.props;
    const { userPermissions } = this.state;
    const editOwnEventPermissions = this.editOwnEventPermissions();
    const editOwnCategoryPermissions = this.editOwnCategoryPermissions();
    const stringToBeParsed = categoryDojo ? translations.tillNowNoUserCatdojoHtml : translations.till_now_no_user_html;
    return (
      <React.Fragment>
        <div className="block-content tab-content">
          <div className="tab-pane active" id="btabs-static-host-permissions">
            <div className="sp-mg">
              <div className="col-md-12 notification-block clear-padding-desktop">
                <div className="alert alert-info alert-dismissable">
                  <RenderHTML content={stringToBeParsed} />
                </div>
              </div>
            </div>
            {currentUserCanEditPermissions && <EditPermissionActionButtons {...{ translations }} />}
            <div className="col-md-12 clear-padding clear-padding-desktop">
              <div className="event-list">
                <SliceProvider slice={adminPermissionsPaginationSlice}>
                  <HostPermissionsTableWithPagination
                    {...this.props}
                    userPermissions={userPermissions}
                    editOwnEventPermissions={editOwnEventPermissions}
                    editOwnCategoryPermissions={editOwnCategoryPermissions}
                  />
                </SliceProvider>
                <UserPermissionEditModal
                  {...this.props}
                  userPermissions={userPermissions}
                  updateAdminPermissionComponent={this.updateAdminPermissionComponent}
                  editOwnEventPermissions={editOwnEventPermissions}
                  usersForSelect={(ownPermissions) => this.usersForSelect(ownPermissions)}
                  eventsForSelect={this.eventsForSelect(editOwnEventPermissions)}
                  groupsForSelect={this.groupsForSelect()}
                  contractsForSelect={this.contractsForSelect()}
                  saveEventPermission={this.saveEventPermission}
                  saveContractPermission={this.saveContractPermission}
                  handleDeleteEventPermission={this.handleDeleteEventPermission}
                  handleDeleteContractPermission={this.handleDeleteContractPermission}
                  categoryForSelect={this.categoryForSelect(editOwnCategoryPermissions)}
                  portfolioForSelect={this.portfolioForSelect(editOwnCategoryPermissions)}
                  editOwnCategoryPermissions={editOwnCategoryPermissions}
                />
              </div>
            </div>
            <Confirm
              translations={translations}
              message={translations.areYouSure}
              htmlId="userPermissionConfirmModal"
              onOkayButtonClick={this.handleDeleteUserPermission}
              categoryDojo={categoryDojo}
            />
            <Confirm
              translations={translations}
              message={translations.areYouSureYouWantToNotifyChanges}
              htmlId="notifyChangesConfirmModal"
              onOkayButtonClick={() => this.props.notifyUsersAboutChanges(companyId, null, categoryDojo, simDojo)}
              categoryDojo={categoryDojo}
            />
            <Confirm
              translations={translations}
              message={translations.areYouSureYouWantToNotifyUser}
              htmlId="notifyChangesToUserConfirmModal"
              onOkayButtonClick={() => this.handleNotifyUser(companyId)}
              categoryDojo={categoryDojo}
            />
            <Confirm
              translations={translations}
              message={translations.deactivateSureAlert}
              htmlId="deactivateUserConfirmModal"
              onOkayButtonClick={() => this.handleDeactivateUser()}
              categoryDojo={categoryDojo}
            />
            <div className="col-md-12 clear-padding clear-padding-desktop">
              <AddUserModal
                translations={translations}
                companyId={companyId}
                userPermissions={userPermissions}
                allUsers={allUsers}
                categoryDojo={categoryDojo}
                currentUser={currentUser}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleDeleteUserPermission() {
    const { objectIdToModify: userPermissionIdToDelete } = this.props.data;
    const { userPermissions } = this.state;
    const { userDeletedSuccessfully } = this.props.translations;
    if (userPermissionIdToDelete) {
      this.props.deleteUserPermission(userPermissionIdToDelete, userPermissions, userDeletedSuccessfully);
    }
  }

  async updateAdminPermissionComponent(actionToCall, data, adminPermission) {
    const { userPermissions } = this.state;
    let result = null;
    let type = "";
    if (actionToCall === "update") {
      const { action } = data.admin_permission;
      result = await this.props.updateAdminPermission(adminPermission, {
        admin_permission: { action },
      });
      type = actionTypes.UPDATE_ADMIN_PERMISSION;
    } else if (actionToCall === "destroy") {
      await this.props.deleteAdminPermission(adminPermission, userPermissions);
    } else if (actionToCall === "create") {
      const userPermissionId = data.admin_permission.user_permission_id;
      result = await this.props.createAdminPermission(userPermissionId, data, userPermissions);
      type = actionTypes.CREATE_ADMIN_PERMISSION;
    }
    if (result) {
      this.props.getAdminPermissions(adminPermission.user_permission_id, userPermissions, type);
    }
    return true;
  }

  editOwnEventPermissions() {
    const { currentUser } = this.props;
    const { userPermissions } = this.state;
    let editPermission = undefined;
    const currentUserPermissions = userPermissions.filter((up) => {
      return up.user.id === currentUser.id;
    })[0];
    if (!(currentUserPermissions === undefined)) {
      editPermission = currentUserPermissions.admin_permissions.filter((ap) => {
        return ap.section === "own_events_permissions";
      })[0];
    }
    return !(editPermission === undefined);
  }

  editOwnCategoryPermissions() {
    const { currentUser } = this.props;
    const { userPermissions } = this.state;
    let editPermission = undefined;
    const currentUserPermissions = userPermissions.filter((up) => {
      return up.user.id === currentUser.id;
    })[0];
    if (!(currentUserPermissions === undefined)) {
      editPermission = currentUserPermissions.admin_permissions.filter((ap) => {
        return ap.section === "own_categories_permissions";
      })[0];
    }
    return !(editPermission === undefined);
  }

  usersForSelect(canEditOnlyOwnEP) {
    const { allUsers, currentUser, companyId } = this.props;
    let users = [];
    if (canEditOnlyOwnEP) {
      users = [currentUser];
    } else {
      users = allUsers.filter((user) => {
        return user.company_id === companyId;
      });
    }
    return users.map((user) => {
      return { text: user.email, id: user.id };
    });
  }

  eventsForSelect(canEditOnlyOwnEP) {
    const { allEvents, currentUser } = this.props;
    let events = [];
    if (canEditOnlyOwnEP && allEvents) {
      events = allEvents.filter((event) => {
        return event.user_id === currentUser.id;
      });
    } else {
      events = allEvents;
    }
    return allEvents
      ? events.map((event) => {
          return { text: event.name, id: event.id };
        })
      : [];
  }

  groupsForSelect() {
    const { hostGroups } = this.props;
    return hostGroups
      ? hostGroups.map((group) => {
          return { text: group.name, id: group.id };
        })
      : [];
  }

  contractsForSelect() {
    const { companyContracts } = this.props;
    return companyContracts
      ? companyContracts.map((contract) => {
          return { text: contract.title, id: contract.id };
        })
      : [];
  }

  categoryForSelect(canEditOnlyOwnCP) {
    const { companyCategories, currentUser } = this.props;
    let categories = [];
    if (canEditOnlyOwnCP && companyCategories) {
      categories = companyCategories.filter((category) => {
        return category.user_id === currentUser.id;
      });
    } else {
      categories = companyCategories;
    }
    return companyCategories
      ? categories.map((category) => {
          return { text: category.name, id: category.id };
        })
      : [];
  }

  portfolioForSelect(canEditOnlyOwnCP) {
    const { companyPortfolios, currentUser } = this.props;
    let portfolios = [];
    if (canEditOnlyOwnCP && companyPortfolios) {
      portfolios = companyPortfolios.filter((portfolio) => {
        return portfolio.user_id === currentUser.id;
      });
    } else {
      portfolios = companyPortfolios;
    }
    return companyPortfolios
      ? portfolios.map((portfolio) => {
          return { text: portfolio.name, id: portfolio.id };
        })
      : [];
  }

  contractsForSelect() {
    const { companyContracts } = this.props;
    return companyContracts
      ? companyContracts.map((contract) => {
          return { text: contract.title, id: contract.id };
        })
      : [];
  }

  async saveEventPermission(eventPermissionId, permission, resourceType, resourceId, userPermissionId) {
    let result = null;
    let type = "";
    const { userPermissions } = this.state;
    const { categoryDojo } = this.props;
    if (eventPermissionId === "0") {
      if (categoryDojo) {
        result = await this.props.createCategoryPermission(permission, resourceType, resourceId, userPermissionId);
        type = actionTypes.CREATE_CATEGORY_PERMISSION;
      } else {
        result = await this.props.createEventPermission(permission, resourceType, resourceId, userPermissionId);
        type = actionTypes.CREATE_EVENT_PERMISSION;
      }
    } else if (eventPermissionId) {
      if (categoryDojo) {
        result = await this.props.updateCategoryPermission(eventPermissionId, permission, resourceType, resourceId);
        type = actionTypes.UPDATE_CATEGORY_PERMISSION;
      } else {
        result = await this.props.updateEventPermission(eventPermissionId, permission, resourceType, resourceId);
        type = actionTypes.UPDATE_EVENT_PERMISSION;
      }
    }
    if (result) {
      if (categoryDojo) {
        this.props.getCategoryPermissions(userPermissionId, userPermissions, type);
      } else {
        this.props.getEventPermissions(userPermissionId, userPermissions, type);
      }
    }
    return true;
  }

  async saveContractPermission(contractPermissionId, permission, resourceType, resourceId, userPermissionId) {
    let result = null;
    let type = "";
    const { userPermissions } = this.state;
    const { contractDojo } = this.props;
    if (contractPermissionId === "0") {
      if (contractDojo) {
        result = await this.props.createContractPermission(permission, resourceType, resourceId, userPermissionId);
        type = actionTypes.CREATE_CONTRACT_PERMISSION;
      }
    } else if (contractPermissionId) {
      if (contractDojo) {
        result = await this.props.updateContractPermission(contractPermissionId, permission, resourceType, resourceId);
        type = actionTypes.UPDATE_CONTRACT_PERMISSION;
      }
    }
    if (result) {
      if (contractDojo) {
        this.props.getContractPermissions(userPermissionId, userPermissions, type);
      } else {
        this.props.getContractPermissions(userPermissionId, userPermissions, type);
      }
    }
    return true;
  }

  handleDeleteEventPermission() {
    const { userPermissions } = this.state;
    const { objectIdToModify: eventPermissionId, objectId } = this.props.data;
    if (this.props.categoryDojo) {
      this.props.deleteCategoryPermission(eventPermissionId, objectId, userPermissions);
    } else {
      this.props.deleteEventPermission(eventPermissionId, objectId, userPermissions);
    }
  }

  handleDeleteContractPermission() {
    const { userPermissions } = this.state;
    const { objectIdToModify: contractPermissionId, objectId } = this.props.data;
    if (this.props.contractDojo) {
      this.props.deleteContractPermission(contractPermissionId, objectId, userPermissions);
    }
  }

  handleNotifyUser(companyId) {
    const { objectId: userPermissionIdTonotify } = this.props.data;
    const { categoryDojo, simDojo } = this.props;
    if (userPermissionIdTonotify) {
      this.props.notifyUsersAboutChanges(companyId, userPermissionIdTonotify, categoryDojo, simDojo);
    }
    let userPermissions = this.state.userPermissions;
    userPermissions = userPermissions.map((permission) => {
      if (permission.id === userPermissionIdTonotify) {
        permission.notification_status = true;
      }
      return permission;
    });
    this.setState({
      userPermissions: userPermissions,
    });
  }

  handleDeactivateUser() {
    const { objectIdToModify: userPermissionIdToDeactivate } = this.props.data;
    const { userPermissions } = this.state;
    const { userDeactivatedSuccessfully } = this.props.translations;
    if (userPermissionIdToDeactivate) {
      this.props.deactivateUserPermission(userPermissionIdToDeactivate, userPermissions, userDeactivatedSuccessfully);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.adminReducers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyUserPermissions: (companyId, categoryDojo) => dispatch(getCompanyUserPermissions(companyId, categoryDojo)),
  updateAdminPermission: (adminPermissionId, action) => dispatch(updateAdminPermission(adminPermissionId, action)),
  deleteAdminPermission: (adminPermissionId, userPermissions) =>
    dispatch(deleteAdminPermission(adminPermissionId, userPermissions)),
  createAdminPermission: (userPermissionId, payload, userPermissions) =>
    dispatch(createAdminPermission(userPermissionId, payload, userPermissions)),
  deleteUserPermission: (userPermissionId, userPermissions, successMessage) =>
    dispatch(deleteUserPermission(userPermissionId, userPermissions, successMessage)),
  notifyUsersAboutChanges: (companyId, userPermissionId, categoryDojo, simDojo) =>
    dispatch(notifyUsersAboutChanges(companyId, userPermissionId, categoryDojo, simDojo)),
  createContractPermission: (permission, resourceType, resourceId, userPermissionId) =>
    dispatch(createContractPermission(permission, resourceType, resourceId, userPermissionId)),
  updateContractPermission: (contractPermissionId, permission, resourceType, resourceId) =>
    dispatch(updateContractPermission(contractPermissionId, permission, resourceType, resourceId)),
  deleteContractPermission: (contractPermissionId, userPermissionId, userPermissions) =>
    dispatch(deleteContractPermission(contractPermissionId, userPermissionId, userPermissions)),
  createEventPermission: (permission, resourceType, resourceId, userPermissionId) =>
    dispatch(createEventPermission(permission, resourceType, resourceId, userPermissionId)),
  updateEventPermission: (eventPermissionId, permission, resourceType, resourceId) =>
    dispatch(updateEventPermission(eventPermissionId, permission, resourceType, resourceId)),
  deleteEventPermission: (eventPermissionId, userPermissionId, userPermissions) =>
    dispatch(deleteEventPermission(eventPermissionId, userPermissionId, userPermissions)),
  getAdminPermissions: (userPermissionId, userPermissions, type) =>
    dispatch(getAdminPermissions(userPermissionId, userPermissions, type)),
  getEventPermissions: (userPermissionId, userPermissions, type) =>
    dispatch(getEventPermissions(userPermissionId, userPermissions, type)),
  getContractPermissions: (userPermissionId, userPermissions, type) =>
    dispatch(getContractPermissions(userPermissionId, userPermissions, type)),
  createCategoryPermission: (permission, resourceType, resourceId, userPermissionId) =>
    dispatch(createCategoryPermission(permission, resourceType, resourceId, userPermissionId)),
  updateCategoryPermission: (eventPermissionId, permission, resourceType, resourceId) =>
    dispatch(updateCategoryPermission(eventPermissionId, permission, resourceType, resourceId)),
  getCategoryPermissions: (userPermissionId, userPermissions, type) =>
    dispatch(getCategoryPermissions(userPermissionId, userPermissions, type)),
  deleteCategoryPermission: (eventPermissionId, userPermissionId, userPermissions) =>
    dispatch(deleteCategoryPermission(eventPermissionId, userPermissionId, userPermissions)),
  deactivateUserPermission: (userPermissionId, userPermissions, successMessage) =>
    dispatch(deactivateUserPermission(userPermissionId, userPermissions, successMessage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HostPermissions);
