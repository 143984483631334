import * as actionTypes from "../actions/actionsTypes";

const AdminReducer = (state = { showUpModal: false }, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_USER_PERMISSIONS:
    case actionTypes.DELETE_ADMIN_PERMISSION:
    case actionTypes.DELETE_USER_PERMISSIONS:
    case actionTypes.CREATE_ADMIN_PERMISSION:
    case actionTypes.UPDATE_ADMIN_PERMISSION:
    case actionTypes.CREATE_USER_PERMISSION:
    case actionTypes.TRANSFER_SUPERHOST:
      return {
        userPermissions: action.payload,
      };
    case actionTypes.SHOW_USER_PERMISSION_MODAL:
      return {
        userPermission: action.payload,
        showUpModal: true,
      };
    case actionTypes.SHOW_ALERT:
      return {
        objectIdToModify: action.objectIdToModify,
        objectId: action.objectId,
      };
    case actionTypes.GET_ALL_DEFAULT_GROUP_USERS:
      return {
        allUsers: action.allUsers,
      };
    case actionTypes.CREATE_EVENT_PERMISSION:
    case actionTypes.UPDATE_EVENT_PERMISSION:
    case actionTypes.DELETE_EVENT_PERMISSION:
    case actionTypes.CREATE_CATEGORY_PERMISSION:
    case actionTypes.UPDATE_CATEGORY_PERMISSION:
    case actionTypes.DELETE_CATEGORY_PERMISSION:
    case actionTypes.CREATE_CONTRACT_PERMISSION:
    case actionTypes.UPDATE_CONTRACT_PERMISSION:
    case actionTypes.DELETE_CONTRACT_PERMISSION:
      return {
        userPermissions: action.payload,
        showUpModal: true,
        userPermission: action.userPermission,
      };
    case actionTypes.MAKE_PARTICIPANT_PERMISSION:
      return {
        participantPermissions: action.payload,
        participantPermissionId: action.participantPermissionId,
      };
    case actionTypes.DELETE_PARTICIPANT_PERMISSION:
      return {
        deletedParticipantPermissionId: action.payload,
      };
    case actionTypes.CLOSE_MODAL:
      return {
        showUpModal: false,
      };
    case "requestLicence/pending":
      return {
        ...state,
        requestLicenceStatus: {
          ...(state.requestLicenceStatus ?? {}),
          [action.meta.arg.userId]: "pending",
        },
      };
    case "requestLicence/rejected":
    case "requestLicence/fulfilled":
      return {
        ...state,
        requestLicenceStatus: {
          ...(state.requestLicenceStatus ?? {}),
          [action.meta.arg.userId]: undefined,
        },
      };
    default:
      return state;
  }
};

export default AdminReducer;
