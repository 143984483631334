import React from "react";
import CurrencyFieldWithSymbol from "../CurrencyFieldWithSymbol";
import Tooltip from "@/components/common/Tooltip";
import { useAppSelector } from "@/hooks/redux";

interface PriceComponentCurrencyFieldPropType {
  required?: boolean;
  disabled?: boolean;
  precision: number;
  name: string;
  symbol: string;
  onChange?: (value: string | number) => void;
  value: number | string;
  label: string;
  tooltipText: Record<"title" | "body", string>;
}

export default function PriceComponentCurrencyField(props: PriceComponentCurrencyFieldPropType) {
  const { required, disabled, precision, name, symbol, value, onChange = () => {}, label, tooltipText } = props;

  const translations = useAppSelector(({ lotReducers }) => lotReducers.translations);

  return (
    <>
      <Tooltip {...tooltipText} />
      <label htmlFor={name}>{label}</label>
      {Boolean(required) && <span className="required">*</span>}
      <div className="input-group col-xs-12">
        <CurrencyFieldWithSymbol
          name={name}
          id={name}
          symbol={symbol}
          value={value}
          precision={precision}
          classes="number_precision form-control"
          handleFieldChange={onChange}
          translations={translations}
          disabled={disabled}
        />
      </div>
    </>
  );
}
