import actionTypes from './actionsTypes';
import * as apiCall from './api';
import config from './config';

const showError = (error) => ({
  type: error.response.status,
  payload: error,
});

export const updateOverviewSuccess = (detail) => ({
  type: actionTypes.fetch_quote_by_id,
  payload: detail,
});

export const updateOverview = (quoteId, payload) => (dispatch) => (
  apiCall.put(`${config.BASE_URL}/events/${quoteId}`, payload)
    .then((response) => {
      dispatch(updateOverviewSuccess(response.data.event));
      return response;
    })
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const updateDocument = (documentId, payload) => (dispatch) => (
  apiCall.put(`${config.BASE_URL}/documents/${documentId}`, payload)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const deleteDocument = (documentId) => (dispatch) => (
  apiCall.remove(`${config.BASE_URL}/documents/${documentId}`)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const addNewDocument = (payload) => (dispatch) => (
  apiCall.post(`${config.BASE_URL}/documents`, payload)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const updateLot = (eventId, lotId, payload) => (dispatch) => (
  apiCall.put(`${config.BASE_URL}/events/${eventId}/lots/${lotId}`, payload)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const createNewLot = (eventId, payload) => (dispatch) => (
  apiCall.post(`${config.BASE_URL}/events/${eventId}/lots`, payload)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const deleteLot = (eventId, lotId) => (dispatch) => (
  apiCall.remove(`${config.BASE_URL}/events/${eventId}/lots/${lotId}`)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const updateQuestion = (questionnaireId, questionnaireSectionId, questionId, payload) => (dispatch) => (
  apiCall.put(`${config.BASE_URL}/questionnaire_questions/${questionId}`, payload)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const createNewQuestion = (quoteId, payload) => (dispatch) => (
  apiCall.post(`${config.BASE_URL}/quotes/${quoteId}/questions`, payload)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const deleteQuestion = (questionnaireId, questionnaireSectionId, questionId) => (dispatch) => (
  apiCall.remove(`${config.BASE_URL}/questionnaire_questions/${questionId}`)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const addNewParticipants = (payload) => (dispatch) => (
  apiCall.post(`${config.BASE_URL}/event_participants`, payload)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const notifyParticipant = (eventId, status, message) => (dispatch) => {
  window.localStorage.setItem('notify', JSON.stringify({eventId, status, message}));
  return dispatch({
    type: 'notify',
    payload: { status, notifyMessage: message },
  })
};

export const deleteParticipant = (participantId) => (dispatch) => (
  apiCall.remove(`${config.BASE_URL}/event_participants/${participantId}`)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const deleteBid = (bidId) => (dispatch) => (
  apiCall.remove(`${config.BASE_URL}/bids/${bidId}`)
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    })
);

export const allowResub = (answerId, quoteId) => (dispatch) => {
  apiCall
    .post(`${config.BASE_URL}/quotes/${quoteId}/allow_resubmit`, {
      questionnaire_answer_id: +answerId,
    })
    .then((response) => response)
    .catch((error) => {
      dispatch(showError(error));
      return error.response;
    });
};
