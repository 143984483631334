import * as actionTypes from "../actions/actionsTypes";
import { isRejected } from "@reduxjs/toolkit";
import { regenerateLots } from "@/actions/lotActions";
import { toggleArchivedOrDelete } from "@/slices/contracts/listing";
import { updateCustomFields } from "@/actions/contract/contractCreateEditActions";

const isRejectedThunk = isRejected(regenerateLots, toggleArchivedOrDelete, updateCustomFields);

const ErrorReducer = (state = {}, action) => {
  if (isRejectedThunk(action)) {
    return {
      error: action.error.message,
    };
  }
  switch (action.type) {
    case actionTypes.ERROR:
      return {
        error: action.error,
      };

    case actionTypes.ERRORS:
      return {
        errors: action.errors,
      };

    case actionTypes.SUCCESS:
      return {
        success: action.success,
      };

    default:
      return state;
  }
};

export default ErrorReducer;
