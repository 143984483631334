import React, { Component } from "react";
import flatten from "lodash/flatten";
import Autocomplete from "react-autocomplete";

export default class AutocompleteFormulaField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formulaValue: "",
      itemsForAutocomplete: props.filteredLicsForFormula,
    };
    this.updateFormula = this.updateFormula.bind(this);
    this.findValidFormulaTags = this.findValidFormulaTags.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeydown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown, false);
  }

  handleKeydown(event) {
    const { target } = event;
    if (event.keyCode === 13 && target.id === "formula_input") {
      const val = target.value;
      this.props.handleAutocompleteFormulaSave(val);
      this.setState({ formulaValue: "" });
    }
  }

  // In this we are setting state for formulaValue which is showing on the
  // auto complete text box and when press space key it resets the text box
  // value and merge value into formula
  updateFormula(event) {
    const val = event.target.value;
    this.findValidFormulaTags(val);
    if (val.match(/\s/g)) {
      this.props.handleAutocompleteFormulaSave(val);
      this.setState({ formulaValue: "" });
    } else {
      this.setState({
        formulaValue: val,
      });
    }
  }

  // This function filter the Lic tags when user enter any value in auto complete and
  // if filteredLicsForFormula and value both are present then filer record from filteredLicsForFormula
  // and if only value is present then filter value from all Lics of the lot and
  // if both are not present then pass all the calculable LICs of lot.
  findValidFormulaTags(value) {
    const { filteredLicsForFormula } = this.props;
    const { itemsForAutocomplete } = this.state;
    let formulaTags = filteredLicsForFormula;
    if (itemsForAutocomplete.length && value) {
      formulaTags = itemsForAutocomplete.filter((liComponent) => liComponent.tag.includes(event.target.value));
    } else if (value) {
      formulaTags = filteredLicsForFormula.filter((liComponent) => liComponent.tag.includes(event.target.value));
    }
    this.setState({
      itemsForAutocomplete: formulaTags,
    });
  }

  render() {
    const { handleFormulaChange, inputProps } = this.props;
    const { formulaValue, itemsForAutocomplete } = this.state;
    return (
      <Autocomplete
        value={formulaValue}
        inputProps={{ id: "formula_input", ...inputProps }}
        wrapperProps={{ id: "formulaDiv" }}
        wrapperStyle={{
          position: "absolute",
          zIndex: 1001,
          display: "inline-block",
        }}
        items={flatten(Object.values(itemsForAutocomplete))}
        getItemValue={(item) => item.tag}
        onChange={this.updateFormula}
        onSelect={handleFormulaChange}
        renderMenu={(children) => (
          <div className="menu" id="autocompleteMenu">
            {children}
          </div>
        )}
        renderItem={(item, isHighlighted) => (
          <div className={`item ${isHighlighted ? "item-highlighted" : ""}`} key={item.tag}>
            {item.tag}
          </div>
        )}
      />
    );
  }
}
