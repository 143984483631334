import React, { useState, useEffect } from "react";
import RunningEventLotsTooEarly from "./RunningEventLotsTooEarly";
import { isPause } from "../eventCommon";
import { connect, useSelector } from "react-redux";
import { validBidsDuringAuction } from "../bidCommon";
import ShowUpdateMessages from "./ShowUpdateMessages";
import DownloadEventReport from "../lots/participantScreenComponents/DownloadEventReport";
import { eventCompletedForParticipant } from "../common";
import ParticipantMonitorTab from "./ParticipantMonitorTab";

if (!window.advancedLots) window.advancedLots = {};
const ParticipantMonitor = (props) => {
  const {
    event,
    translations,
    event_participant: eventParticipant,
    event_participants: eventParticipants,
    current_user: currentUser,
    show_one_tab: showOneTab,
    bids,
    isInitialPropsSet,
    remaining_participants_for_japanese_event: remainingParticipantsForJapaneseEvent,
  } = props;

  const lotReducers = useSelector((state) => state.lotReducers);

  const [allMessages, setAllMessages] = useState(props.all_messages);
  const [messageRecipients, setMessageRecipients] = useState(props.message_recipients);
  const [bestBids, setBestBids] = useState(props.best_bids);
  useEffect(() => {
    $(".modal-backdrop").remove();
    if (lotReducers && lotReducers.all_messages) {
      setAllMessages([...lotReducers.all_messages]);
    }

    if (lotReducers && lotReducers.message_recipients) {
      setMessageRecipients([...lotReducers.message_recipients]);
    }
    if (lotReducers && lotReducers.best_bids) {
      setBestBids(lotReducers.best_bids);
    }
  }, [lotReducers]);
  advancedLots.showRankValue = true;
  const activeBids = validBidsDuringAuction(event, [eventParticipant], bids);
  let participantsForJapaneseEvent = [];
  props.lots.forEach((lot) => {
    if (
      remainingParticipantsForJapaneseEvent &&
      remainingParticipantsForJapaneseEvent[lot.id] &&
      Array.isArray(remainingParticipantsForJapaneseEvent[lot.id])
    ) {
      participantsForJapaneseEvent = remainingParticipantsForJapaneseEvent[lot.id];
    }
  });
  const isEventCompleted = eventCompletedForParticipant(event, participantsForJapaneseEvent, currentUser.id);

  const showDownloadEventReportButton = () => {
    return event.event_type !== "Japanese" && isEventCompleted && activeBids.length > 0;
  };
  const getLotAuctionBidComponent = () => {
    return (
      <>
        {isInitialPropsSet ? (
          <ParticipantMonitorTab
            {...props}
            isEventCompleted={isEventCompleted}
            bestBids={bestBids}
            activeBids={activeBids}
          />
        ) : (
          ""
        )}
        <br />
        {showDownloadEventReportButton() && <DownloadEventReport />}
      </>
    );
  };

  return showOneTab || isPause(props.auction_controls) ? (
    <>
      <div className="container">
        <ShowUpdateMessages
          {...props}
          permittedToEdit={props.permitted_to_edit}
          allMessages={allMessages}
          messageRecipients={messageRecipients}
          currentUser={currentUser}
          bids={bids}
          evenParticipants={eventParticipants}
          participatingParticipants={eventParticipants}
          participants={[currentUser]}
          inSandpit={props.in_sandpit}
          hasRankedLineItemComponents={props.has_ranked_line_item_components}
          timeZoneIdentifier={props.time_zone_identifier}
        />
      </div>
      {getLotAuctionBidComponent()}
    </>
  ) : (
    <RunningEventLotsTooEarly translations={ translations } event={ event } host={false} />
  );
};

const mapStateToProps = (state) => {
  const { lotReducers } = state;
  return {
    bidLineItemComponents: lotReducers.bid_line_item_components,
    openModal: lotReducers.openModal,
    bids: lotReducers.bids,
    errors: lotReducers.errors,
    lot_total_blics: lotReducers.lot_total_blics,
    isInitialPropsSet: lotReducers.isInitialPropsSet,
    lotsBidRange: lotReducers.lotsBidRange,
    lotErrors: lotReducers.lotErrors,
    all_messages: lotReducers.all_messages,
  };
};

ParticipantMonitor.defaultProps = {
  all_messages: [],
  bids: [],
  isInitialPropsSet: "",
};

export default connect(mapStateToProps, null, null)(ParticipantMonitor);
