import React from "react";
import { useSelector, useDispatch } from "react-redux";
import propTypes from "prop-types";
import AllParticipantsBestBidForLotModal from "../modal/AllParticipantsBestBidForLotModal";
import { fetchBidsDetails } from "../../../../../actions/bidActions";

const useQuestionData = () => {
  return {
    questionnaires: useSelector(({ lotReducers }) => lotReducers.questionnaires),
    questionnaireSections: useSelector(({ lotReducers }) => lotReducers.questionnaireSections),
    questionnaireQuestions: useSelector(({ lotReducers }) => lotReducers.questionnaireQuestions),
    questionnaireAnswers: useSelector(({ lotReducers }) => lotReducers.questionnaireAnswers),
    questionnaireAnswersQuestions: useSelector(({ lotReducers }) => lotReducers.questionnaireAnswersQuestions),
    questionnaireAnswersSections: useSelector(({ lotReducers }) => lotReducers.questionnaireAnswersSections),
  };
};

export default function BestBidOrOverallScore(props) {
  const { lot, event, unitOfMeasures, unitSetUoms, auctionTab, header, uom } = props;
  const allUom = [...unitSetUoms, ...unitOfMeasures];

  const isFetchBidsLoading = useSelector(({ loaderReducers }) => loaderReducers?.isFetchBidsLoading);
  const bidList = useSelector(({ lotReducers }) => lotReducers.bidList);
  const answers = useSelector(({ lotReducers }) => lotReducers.answers);
  const questionData = useQuestionData();

  const dispatch = useDispatch();
  const showOverallScore = true;

  return (
    <AllParticipantsBestBidForLotModal
      {...props}
      uom={uom}
      apiCallingFunction={() => dispatch(fetchBidsDetails(lot?.id, showOverallScore, event.id, undefined, auctionTab))}
      {...questionData}
      bidList={bidList}
      answers={answers}
      allUom={allUom}
      isLoading={isFetchBidsLoading}
      header={header}
      modalType={"Lot"}
      isAllParticipant={true}
    />
  );
}

BestBidOrOverallScore.propTypes = {
  header: propTypes.string,
  uom: propTypes.object,
  displayScores: propTypes.bool,
};

BestBidOrOverallScore.defaultProps = {
  unitOfMeasures: [],
  unitSetUoms: [],
  bestBidBeforeAuction: {},
  header: "",
};
