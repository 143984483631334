import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";

const RESET_BUTTON = 0;
const SAVE_BUTTON = 1;

export default function ({ save, reset }) {
  return ShortcutButtonsPlugin({
    button: [{ label: reset }, { label: save }],
    onClick(index, fp) {
      switch (index) {
        case RESET_BUTTON:
          fp.clear();
          fp.close();
          break;

        case SAVE_BUTTON:
          fp.close();
          break;

        default:
          fp.close();
      }
    },
  });
}
