import React, { Component } from "react";
import { InputWithTooltip } from "./StakeholderInvitation";
import Tooltip from "../../../common/Tooltip";

class CommonSettingOption extends Component {
  isQuestionnaireUpload = (name) => {
    return name === "allow_participant_questionnaire_upload";
  };

  timeForAutoReminderUpdate = (e) => {
    const value = this.isQuestionnaireUpload(e.target.name) ? e.target.checked : `${e.target.checked}`;
    this.props.onValueChange(value, this.props.name);
  };

  displayTooltip = () => {
    return (
      <div className="vertical-center ml-15px">
        <Tooltip {...this.props.toolTipData} />
      </div>
    );
  };

  render() {
    const { name, configuration_setting_values, toolTipData, settingTitle } = this.props;
    const checked = this.isQuestionnaireUpload(name)
      ? configuration_setting_values[name]
      : configuration_setting_values[name] === "true";
    const data = {
      label: "",
      toolTipData,
      name,
      className: "form-control col-md-2 col-xs-12",
      checked: checked,
      onChange: (e) => this.timeForAutoReminderUpdate(e),
    };
    return (
      <div className="form-item-block">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <SettingTitle settingTitle={settingTitle} />
            <InputWithTooltip {...data} />
          </div>
        </div>
      </div>
    );
  }
}

const SettingTitle = ({ settingTitle }) => {
  return <div className="form-item-block-title">{settingTitle}</div>;
};

export default CommonSettingOption;
