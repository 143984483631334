import React from "react";
import { Confirm } from "../common/Confirm";
import ActivityTableBody from "./ActivityTableBody";
import ActivityTableHeader from "./ActivityTableHeader";
import { StaticActivityHeader } from "./ActivityTableInner";

const activityTableMain = (props) => {
  const {
    translations,
    setFilter,
    setActivityId,
    handleDeleteActivity,
    values_for_status: statusValues,
    custom_column_options: customColumnOptions,
    hostOwnerEmails,
    activity_concerned_contacts: activityConcernedContacts,
    supplier_names: supplierNames,
  } = props;

  return (
    <div id="activity-list-wrapper" className="m-t30">
      <div className="row">
        <div className="col-md-12 clear-padding">
          <div className="event-list">
            <table className="border-collapse custom-responsive-table highlight-row-onhover table-layout-fixed">
              <thead className="table-block-title with-filter">
                {props.myFiltersActive ? (
                  <StaticActivityHeader>
                    <th>{translations.suppliers}</th>
                  </StaticActivityHeader>
                ) : (
                  <ActivityTableHeader
                    translations={translations}
                    customColumnOptions={customColumnOptions}
                    activityConcernedContacts={activityConcernedContacts}
                    supplierNames={supplierNames}
                    statusValues={statusValues}
                    setFilter={setFilter}
                    hostOwnerEmails={hostOwnerEmails}
                    active={props.filterActive}
                    activities={props.activities}
                  />
                )}
              </thead>
              <tbody id="activity-list">
                <ActivityTableBody setActivityId={setActivityId} {...props} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Confirm
        translations={translations}
        message={
          <>
            <p className="alert alert-danger">{translations.linked_activities_delete_warning}</p>
            <p>{translations.are_you_sure}</p>
          </>
        }
        htmlId="activityConfirmModal"
        onOkayButtonClick={() => handleDeleteActivity()}
      />
    </div>
  );
};

export default activityTableMain;
