import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["apiKey", "apiKeyCheckbox"];

  toggleApiKey() {
    if (this.apiKeyCheckboxTarget.checked) {
      this.apiKeyTarget.removeAttribute("disabled");
    } else {
      this.apiKeyTarget.value = "";
      this.apiKeyTarget.setAttribute("disabled", true);
    }
  }

  removeKey() {
    if (this.apiKeyTarget.value) {
      this.apiKeyTarget.disabled = false;
    }
    this.apiKeyTarget.value = "";
  }
}
