import { useCallback, useEffect, useRef } from "react";

/**
 * Manage a single timeout, that will clear itself on unmount.
 * Setting a new timeout will clear the previous one.
 */
export function useTimeout() {
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const _clearTimeout = useCallback(() => {
    if (timeoutId.current !== null) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  }, []);
  useEffect(() => _clearTimeout, [_clearTimeout]);
  const _setTimeout = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Args extends any[] = []>(callback: (...args: Args) => void, ms: number, ...args: Args) => {
      _clearTimeout();
      const id = setTimeout(callback, ms, ...args);
      timeoutId.current = id;
      return id;
    },
    [_clearTimeout]
  );
  return [_setTimeout, _clearTimeout] as const;
}
