import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { emptyCurrentState } from "@/actions/messageActions";
import { SHOW_MESSAGE_MODAL } from "@/actions/actionsTypes";
import Container from "./Container";
import { SendMessageModalPropTypes } from "./prop-types";

const WrapperPropTypes = {
  /**
   * childrens tobe rendered inside wrapper
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),

  /**
   * html id will be associated with modal
   * so that it can be uniquily identified by DOM.
   */
  htmlId: PropTypes.string.isRequired,
};

/**
 *
 * Main wrapper for sendMessageModal.
 *
 * @type {React.FC<WrapperPropTypes>}
 * @returns {React.ReactElement}
 */
const Wrapper = ({ children, htmlId }) => {
  return (
    <div
      id={htmlId}
      className="modal top-position send-message-modal fade"
      role="dialog"
      style={{ display: "none" }}
      data-backdrop={true}
    >
      <div className="modal-dialog modal-draggable-content">
        <div className="modal-content message-popup" data-content="message-popup">
          {children}
        </div>
      </div>
    </div>
  );
};

Wrapper.propTypes = WrapperPropTypes;
Wrapper.defaultProps = {};

/**
 * @description Close sendMessageMddal
 * @param {string} htmlId
 */
export const closeModal = (htmlId) => {
  $(`#${htmlId}`).modal("hide");
  // set overflow to auto to activate scroll-bar
  document.body.style.overflowY = "auto";
};

/**
 * @description open modal using jquery
 * @param {Boolean} isNewMessage
 */
const openModal = (isNewMessage, htmlId) => {
  $(`#${htmlId}`).modal("show");
  // js to make pop-up draggable
  $(".modal-draggable-content").draggable({
    handle: ".modal-header",
    cursor: "move",
  });
};

/**
 *
 * @type {React.FC<typeof SendMessageModalPropTypes>}
 * @returns {React.ReactElement}
 */
const SendMessageModal = (props) => {
  const { htmlId } = props;
  const showModal = useSelector(({ messageReducers }) => messageReducers.showModal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal?.modalId === htmlId) {
      // open modal
      openModal(showModal.isNewMessage, htmlId);

      /**
       * set showModal state in store to undefined on closing of modal.
       *
       * As $("selector").one will only be once so
       * we do not need to worry about removing any eventListner on unmounting
       */
      $(`#${htmlId}`).one("hidden.bs.modal", () => {
        dispatch(emptyCurrentState(SHOW_MESSAGE_MODAL, undefined));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, htmlId]);

  return <Wrapper htmlId={htmlId}>{showModal?.modalId === htmlId && <Container {...props} />}</Wrapper>;
};

SendMessageModal.propTypes = SendMessageModalPropTypes;
SendMessageModal.defaultProps = {
  documents: [],
  selectedParticipants: [],
  setSelectedParticipants: () => {},
};

export default SendMessageModal;
