import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useDispatch, useSelector } from "react-redux";
import {
  hostsParticipantProgressionChart,
  participantsLiveEventLotLine,
  hostsHorseRaceChart,
  hostsBestBidProgressionChart,
  hostsDifferenceProgressionChart,
} from "../../../actions/chartActions";
import "highcharts/modules/exporting";
import {
  getParticpantChartOptions,
  hostsBestBidProgressionChartOptions,
  hostsHorseRaceChartOptions,
  hostsParticipantProgressionChartOptions,
  hostsDifferenceProgressionChartOptions,
} from "./chartOptions";

const GraphComponent = (props) => {
  const {
    lot,
    type,
    activeTab,
    tabs,
    translations: { qualification_value_nobr: qualVal, current_value: currVal },
    lineItem,
  } = props;
  const dispatch = useDispatch();
  const lotReducers = useSelector((state) => state.lotReducers);
  const [graphData, setGraphData] = React.useState({
    data: [],
    participants: [],
    categories: [],
    qualCurrValue: [],
    chartOptions: hostsBestBidProgressionChartOptions({
      ...props,
      data: [],
    }),
  });

  const setState = (state) => {
    setGraphData({ ...graphData, ...state });
  };

  const { chartOptions, qualCurrValue } = graphData;

  switch (type) {
    case "participantMonitor":
      {
        React.useEffect(() => {
          dispatch(participantsLiveEventLotLine(lot.id, lineItem?.id ?? "")).then((res) => {
            const data = res.chartData.map((d) => d.y);
            const categories = res.chartData.map((d) => {
              return d.x;
            });
            setState({
              data,
              categories,
              chartOptions: getParticpantChartOptions({
                ...props,
                data,
                categories,
              }),
            });
          });
        }, []);
      }
      break;
    case "hostMonitor":
      {
        React.useEffect(() => {
          let action, actionHandler;
          switch (activeTab) {
            //participant_progression_html
            case tabs[0]:
              action = hostsParticipantProgressionChart;
              actionHandler = (res) => {
                const qualCurrValue = res.chartData.filter(({ name }) => [qualVal, currVal].includes(name));
                setState({
                  data: res.chartData,
                  participants: res.participants,
                  qualCurrValue,
                  chartOptions: hostsParticipantProgressionChartOptions({
                    ...props,
                    ...{ data: res.chartData, participants: res.participants },
                  }),
                });
              };
              break;
            //horse_race_html
            case tabs[1]:
              action = hostsHorseRaceChart;
              actionHandler = (res) => {
                const data = res.chartData.concat(qualCurrValue);
                setState({
                  data,
                  chartOptions: hostsHorseRaceChartOptions({
                    ...props,
                    data,
                  }),
                });
              };
              break;
            //best_bid_progression_html
            case tabs[2]:
              action = hostsBestBidProgressionChart;
              actionHandler = (res) => {
                const data = res.chartData;
                setState({
                  data,
                  chartOptions: hostsBestBidProgressionChartOptions({
                    ...props,
                    data,
                    qualCurrValue,
                  }),
                });
              };
              break;
            //difference_progression_html - savings or profit
            case tabs[3]:
              action = hostsDifferenceProgressionChart;
              actionHandler = (res) => {
                const data = res.chartData;
                setState({
                  data,
                  chartOptions: hostsDifferenceProgressionChartOptions({
                    ...props,
                    data,
                  }),
                });
              };
              break;
          }
          setState({ chartOptions: null });
          dispatch(action(lot.id, lineItem?.id ?? "")).then(actionHandler);
        }, [activeTab, lotReducers]);
      }
      break;
  }

  return chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} {...HighchartsReact.props} />;
};

export default GraphComponent;
