import React, { Component } from "react";

import App from "./App";
import { initialize } from "./advanced_lots/eventCommon";
import Wrapper from "./Wrapper";
import { getStore } from "@/store";

initialize();

class Root extends Component {
  render() {
    return (
      <Wrapper store={getStore()}>
        <App {...this.props} />
      </Wrapper>
    );
  }
}

export default Root;
