import React from "react";
import Tooltip from "../../common/Tooltip";

const HeaderForJapaneseAndLotLevelEvent = (props) => {
  const { infos } = props.translations;
  return (
    <div className="table-block-head">
      {props.values.map((value) => {
        const [text, info_tooltip, header_class, width_class] = value;
        return (
          <div className={`font-s13 head-all head-${header_class} ${width_class ? width_class:''}`} key={value}>
            {props.translations[text]}&nbsp;
            {infos[info_tooltip] && <Tooltip title={infos[info_tooltip]?.title} body={infos[info_tooltip]?.body} />}
          </div>
        );
      })}
      <div className="head-all head-iconbar"></div>
    </div>
  );
};
export default HeaderForJapaneseAndLotLevelEvent;