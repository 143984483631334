import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import reject from "lodash/reject";
import { createLotTemplate } from "../../../../actions/lotTemplateActions";
import { disableButton, enableButton, isIE } from "../../common";
import classNames from "classnames";

const CreateLotTemplateForm = (props) => {
  const dispatch = useDispatch();
  const refBtn = useRef(null);
  const { translations, lots, setModalIsOpen, modalIsOpen, companyId } = props;
  const [lotTemplateName, setLotTemplateName] = useState("");
  const [lotsToSave, setLotsToSave] = useState([]);

  const handleLotsToSave = (event) => {
    const value = parseInt(event.target.value);
    let lotsToStore = lotsToSave.slice();
    if (event.target.checked) {
      lotsToStore.push(value);
    } else {
      const index = lotsToSave.indexOf(value);
      if (index > -1) {
        lotsToStore = reject(lotsToStore, (id) => id === value);
      }
    }
    setLotsToSave(lotsToStore);
  };

  const saveLotTemplate = (event) => {
    event.preventDefault();
    const payload = {
      lot_template: {
        name: lotTemplateName,
        lot_id: lotsToSave,
      },
    };
    disableButton(refBtn.current, `${translations.save} <i class='fa fa-spinner fa-spin'></i>`);
    dispatch(createLotTemplate(companyId, payload)).then((response) => {
      if (response) {
        setModalIsOpen(false);
      } else {
        enableButton(refBtn.current, translations.save, "<i class='fa fa-check'></i>");
      }
    });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      className={classNames({
        "modal-content col-md-4 col-sm-4 custom-field-popup": true,
        "modal4-ie": isIE(),
      })}
      appElement={document.getElementById("advanced-lots-body")}
    >
      <form onSubmit={(e) => saveLotTemplate(e)}>
        <div className="block  block-transparent remove-margin-b">
          <div className="block-header bg-primary-default">
            <ul className="block-options">
              <li>
                <button type="button" onClick={() => setModalIsOpen(false)}>
                  <i className="ion-close" />
                </button>
              </li>
            </ul>
            <h3 className="block-title">{translations.create_lot_template}</h3>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="lot_template_name">{translations.name}</label>
                    <div className="markup">
                      <input
                        autoFocus={true}
                        placeholder="Specify Name for Template"
                        className="form-control"
                        type="text"
                        value={lotTemplateName}
                        id="lot_template_name"
                        onChange={(e) => setLotTemplateName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input">
                      <table className="table template_upload" width="100%">
                        <thead>
                          <tr>
                            <th>{translations.lots}</th>
                            <th>{translations.tick_which_ones_to_save}</th>
                          </tr>
                        </thead>
                        <tbody className="list_of_docs upload_template_scrollbar">
                          {lots.map(function (lot, index) {
                            return (
                              <tr key={`lotTemplateLots-${lot.id}`}>
                                <td className="doc_name">
                                  <label htmlFor="participant_add" className="upload_docs">
                                    {index + 1 + " - "}
                                    <span>{lot.name.substring(0, 65)}</span>
                                  </label>
                                </td>
                                <td className="doc_tickbox">
                                  <label className="contact css-input css-checkbox css-checkbox-default">
                                    <input
                                      type="checkbox"
                                      checked={lotsToSave.includes(lot.id)}
                                      value={lot.id}
                                      onChange={(e) => handleLotsToSave(e)}
                                    />
                                    <span></span>
                                  </label>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="pull-right">
              <a onClick={() => setModalIsOpen(false)} className="btn btn-sm btn-default">
                {translations.cancel}
              </a>
              <button className="btn btn-sm btn-primary" type="submit" ref={refBtn}>
                {translations.save} <i className="fa fa-check"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default CreateLotTemplateForm;
