import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import last from "lodash/last";
import { relatedBidLineItemComponents, bidSubmitted, fetchCorrectBidForLotsInAuction, previousBid } from "../bidCommon";
import ProxyBidButtonAndModal from "./ProxyBidButtonAndModal";
import EventLotComponent from "../lots/EventLotComponent";
import { placeBid, bidRange, setLotStatusBidding } from "../../../actions/bidActions";
import { pause, postRfqPreAuction } from "../eventCommon";
import { unPlacedBidInDetailLevelAuction } from "../common";
import { setActiveLi } from "../../../actions/lotActions";
import useLot from "../hooks/useLot";
import useEvent from "../hooks/useEvent";

const LotBidComponent = (props) => {
  const dispatch = useDispatch();
  const {
    isProxyBid,
    participant,
    bids,
    lot,
    bidLineItemComponents: _bidLineItemComponents,
    setIsOpen,
    currentUser,
    event,
    setLotStateCb,
    errors,
    locale,
    isAuction,
    activeTab,
    lotTotalBlics,
    auctionTab,
    isLotLevelMonitor,
    bidDetailsAdded,
    isRankedMonitor,
    beforeAuction,
    role,
    weightedRfqOrAuction,
    bestBids,
    isPlaceBidActiveFlag = false,
    showHeader = true,
    showDetailsButton = true,
    roundedBorders = true,
  } = props;
  const event_rounds = useSelector((state) => state.lotReducers.event_rounds) || [];
  const eventRound = useSelector((state) => state.lotReducers.eventRound);
  const { activeLi } = useLot(lot);
  const { isEventMsrfq } = useEvent();
  const [isBidForCurrentMSRFQRoundSubmitted, setIsBidForCurrentMSRFQRoundSubmitted] = useState(true);
  const lastRound = last(event_rounds);
  let detailsAdded = bidDetailsAdded;
  const [lotState, _setLotState] = useState({
    isPlaceBidActive: false,
    isAllDetailView: false,
    isBidSubmitting: false,
    isLotRejecting: false,
    isCanceling: false,
  });

  const getBlics = () => {
    const { bid_line_item_components, bidLineItemComponents } = useSelector((state) =>
      state.lotReducers && state.lotReducers.bid_line_item_components ? state.lotReducers : props
    );
    return bid_line_item_components || bidLineItemComponents;
  };
  const bidLineItemComponents = isProxyBid ? getBlics() : _bidLineItemComponents;

  const userId = isProxyBid ? participant.user_id : currentUser.id;
  const lBids = bids.filter((bid) => bid.lot_id === lot.id && bid.user_id === userId);

  const bid =
    auctionTab || isAuction
      ? fetchCorrectBidForLotsInAuction(
          event,
          lot,
          bids,
          userId,
          role,
          beforeAuction,
          participant,
          weightedRfqOrAuction
        )
      : last(lBids);

  const isShowingBidsForLastRound = useMemo(
    () => (isEventMsrfq ? !eventRound || (eventRound && eventRound?.id === lastRound?.id) : true),
    [eventRound, lotState]
  );

  useEffect(() => {
    if (isEventMsrfq) {
      if (isShowingBidsForLastRound)
        setIsBidForCurrentMSRFQRoundSubmitted(bid?.event_round_id === lastRound?.id && bidSubmitted(bid));
      else setIsBidForCurrentMSRFQRoundSubmitted(bidSubmitted(bid));
      detailsAdded = !!bid?.details_added;
    }
  }, [lotState, bid, eventRound, bidLineItemComponents]);

  let isBidSubmitted =
    bid &&
    bidSubmitted(bid) &&
    !bid.rejected_lot &&
    ((!isAuction && !auctionTab) || isRankedMonitor || event.event_type === "Open");

  if (
    isEventMsrfq &&
    !isBidForCurrentMSRFQRoundSubmitted &&
    (!eventRound || (!!eventRound && lastRound?.id === eventRound?.id))
  ) {
    isBidSubmitted = false;
    detailsAdded = false;
  }

  if (isLotLevelMonitor) {
    if (detailsAdded) {
      isBidSubmitted = true;
    } else {
      isBidSubmitted = false;
    }
  }

  const lotBidLineItemComponents = bid && bid.id ? relatedBidLineItemComponents(bid.id, bidLineItemComponents) : [];
  const isPostRfqPreAuction = postRfqPreAuction(event);
  const isPlaceBidActive =
    ((!isBidSubmitted && isBidForCurrentMSRFQRoundSubmitted && !!lotBidLineItemComponents.length && !pause(event)) ||
      (!isBidSubmitted && !!isProxyBid)) &&
    (!isPostRfqPreAuction || isRankedMonitor) &&
    isPlaceBidActiveFlag;

  const setLotState = (state) => {
    _setLotState({ ...lotState, ...state });
  };

  useEffect(() => {
    const isAllDetailView = !isProxyBid && isPlaceBidActive;

    setLotState({ isPlaceBidActive, isAllDetailView });

    /**
     * set lot status to 'bidding' state when isPlaceBidActive is true on first render
     * and participant has not entered into bidding state after clicking on Enter bid
     */
    isAllDetailView && dispatch(setLotStatusBidding(lot.id));
  }, []);

  /**
   * Bid should only be placed if event is not JapaneseAuction or LotLevelAuction
   * and there is no bid un submitted bid available for the lot.
   */
  const isPlacingNewBid = useMemo(() => {
    const { isJapaneseAuctionTab, isLotLevelAuctionTab } = getTabInfo(event, activeTab);

    // check if un submitted bid is present for the lot
    const isUpdatingOldBid = bid && !isAuction && isBidForCurrentMSRFQRoundSubmitted;

    // check if event is not JapaneseAuction or LotLevelAuction
    const isAuctionLevelEvent = !isJapaneseAuctionTab && !isLotLevelAuctionTab && !isLotLevelMonitor;

    // check if bid can be placed for auction events
    const unPlacedBid = unPlacedBidInDetailLevelAuction(isAuction, event, bid);
    const canPlaceBidInAuction = !bid || !unPlacedBid || !isBidForCurrentMSRFQRoundSubmitted;
    const shouldPlaceBid = isAuctionLevelEvent && canPlaceBidInAuction;

    return !isUpdatingOldBid && shouldPlaceBid;
  }, [activeTab, bid, event, isAuction, isBidForCurrentMSRFQRoundSubmitted, isLotLevelMonitor]);

  /**
   * onClickPlaceBid is called when a Enter bid or update bid is clicked
   * this creates a new bid for auction/rfq events
   */
  const onClickPlaceBid = () => {
    dispatch(setActiveLi({ removeLi: activeLi }));

    if (isPlacingNewBid) {
      dispatch(
        placeBid(lot.id, {
          bid: {
            user_id: userId,
            event_id: event.id,
          },
          is_auction: `${false}`,
        })
      );
    } else if (!isProxyBid) {
      dispatch(setLotStatusBidding(lot.id));
    }

    setLotState({
      isPlaceBidActive: true,
      isAllDetailView: !isProxyBid,
    });

    if (bid && isAuction) {
      let params = { event_id: event.id };
      if (bid.exchange_rate_id) params.exchange_rate_id = bid.exchange_rate_id;
      dispatch(bidRange(lot.id, bid.id, params));
    }
    if (isProxyBid) setIsOpen(true);
  };
  const preBid = previousBid(bid, bids, lot, userId);
  return isProxyBid ? (
    <ProxyBidButtonAndModal
      {...props}
      onClickPlaceBid={onClickPlaceBid}
      isBidSubmitted={isBidSubmitted}
      isBidForCurrentMSRFQRoundSubmitted={isBidForCurrentMSRFQRoundSubmitted}
      isShowingBidsForLastRound={isShowingBidsForLastRound}
      bid={bid}
      previousBid={preBid}
      lotBidLineItemComponents={lotBidLineItemComponents}
      setLotState={setLotState}
      lotState={lotState}
      isPlaceBidActive={isPlaceBidActive}
      locale={locale}
      isPostRfqPreAuction={isPostRfqPreAuction}
      bidLineItemComponents={bidLineItemComponents}
    />
  ) : (
    <EventLotComponent
      {...props}
      onClickPlaceBid={onClickPlaceBid}
      isBidSubmitted={isBidSubmitted}
      isBidForCurrentMSRFQRoundSubmitted={isBidForCurrentMSRFQRoundSubmitted}
      isShowingBidsForLastRound={isShowingBidsForLastRound}
      bid={bid}
      previousBid={preBid}
      lotBidLineItemComponents={lotBidLineItemComponents}
      setLotState={setLotState}
      lotState={lotState}
      isPlaceBidActive={isPlaceBidActive}
      setLotStateCb={setLotStateCb}
      errors={errors}
      bids={bids}
      locale={locale}
      lotTotalBlics={lotTotalBlics}
      isPostRfqPreAuction={isPostRfqPreAuction}
      auctionTab={auctionTab}
      beforeAuction={beforeAuction}
      weightedRfqOrAuction={weightedRfqOrAuction}
      bestBids={bestBids}
      lBids={lBids}
      bidDetailsAdded={detailsAdded}
      partialBiddingSwitch={props.partial_bidding_switch}
      showHeader={showHeader}
      showDetailsButton={showDetailsButton}
      roundedBorders={roundedBorders}
    />
  );
};

export default LotBidComponent;

export const getTabInfo = (event, activeTab) => ({
  isJapaneseAuctionTab: activeTab === "Auction Bids" && event.event_type === "Japanese",
  isLotLevelAuctionTab: activeTab === "Auction Bids" && event.event_type !== "Japanese" && !event.bid_at_detail_level,
});
