import React, { Component } from "react";

class ContractListingDropdownFilter extends Component {
  render() {
    const { translations, can_create_contract } = this.props;
    return (
      <div className="col-12 col-md-5 mb-3 mb-md-0 text-center text-md-left">
        {can_create_contract && (
          <div className="dropdown dropdown-filter">
            <button onClick={() => (window.location = "/contracts/new")} className="btn-contract">
              {translations.add_new_contract}
            </button>
            <ul className="dropdown-menu dropdown-menu-right">
              <li className="dropdown-item">
                <a href="/contracts/new" className="btn-contract btn-width-auto">
                  <i className="fa fa-plus"></i>
                  {translations.create}
                </a>
              </li>
              <li className="dropdown-item">
                <button
                  data-toggle="modal"
                  data-target="#uploadContractsModal"
                  rel="nofollow"
                  className="btn-contract upload-contract-btn"
                >
                  <i className="fa fa-upload"></i>
                  {translations.just_upload}
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}
export default ContractListingDropdownFilter;
