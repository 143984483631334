export const messageOnLiveFeed = (state, { payload }) => {
  const { user, recipients, id } = payload || {};
  const message = { ...payload, user_id: user?.id };
  const messageRecipients = recipients?.map((recipient) => ({ ...recipient, message_id: id }));

  return {
    ...state,
    all_messages: [...(state.all_messages || []), message],
    message_recipients: [...(state.message_recipients || []), ...(messageRecipients || [])],
  };
};

export const liveMessageMarkedAsRead = (state, { payload }) => {
  const message = payload;
  // We are selecting the first recipient because there will always be just
  // one at a moment. We could have used the current user too here.
  const userId = message.recipients[0].user_id;
  const messageRecipients = state.message_recipients?.map((recipient) =>
    recipient.user_id === userId && recipient.message_id === message.id
      ? { ...recipient, message_read: true }
      : recipient
  );

  return {
    ...state,
    message_recipients: messageRecipients || [],
  };
};
