import React, { Component } from 'react';
import { connect } from 'react-redux';
import QQListing from './pages/QQListing/QQListing';

import isEmpty from 'lodash/isEmpty';
import Script from 'react-load-script';
import URLSearchParams from 'url-search-params';
import Modal from 'simple-react-modal';
import cookies from 'browser-cookies';
import moment from 'moment-timezone';
import config from '../../actions/qq/config';
import { backgroundOnModalOpen, notifyToastMsgQQ } from './js/common';
import { withAlert } from 'react-alert';
class QuickQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }
  
  UNSAFE_componentWillMount() {
    if(!config.headers.is_host)
    {
      window.location.href = '/users/two_factor_authentication';
    }
  }

  componentDidMount() {
    window.onbeforeunload = (e) => {
      cookies.erase('just_logged_in');
    };
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.user.details) {
      window.user = this.props.user.details;
    }
    notifyToastMsgQQ(newProps, this.props.alert);
  }

  closeMessageModal() {
    backgroundOnModalOpen(false);
    cookies.erase('just_logged_in');
    this.setState({
      showModal: false,
    });
  }

  render() {
    const { user, translations } = this.props;
    const loadLiveChat = config.isQuickQuotes && user.details && user.details.application_mode === 'production' && !Script.loadedScripts['/quick_quotes/js/zoho.js'];
    let TimeZoneSystem = '';
    let TimeZoneUser = '';
    if (user.details) {
      TimeZoneSystem = ((moment().tz(moment.tz.guess()).format('z')).indexOf('+') !== -1 || (moment().tz(moment.tz.guess()).format('z')).indexOf('-') !== -1) ? `UTC ${moment().tz(moment.tz.guess()).format('z')}` : moment().tz(moment.tz.guess()).format('z');
      TimeZoneUser = ((moment().tz(user.details.time_zone).format('z')).indexOf('+') !== -1 || (moment().tz(user.details.time_zone).format('z')).indexOf('-') !== -1) ? `UTC ${moment().tz(user.details.time_zone).format('z')}` : moment().tz(user.details.time_zone).format('z');
    }
    const showTimeZonePopup = (user.details && user.details.sign_in_count >= 2 && cookies.get('just_logged_in') === 'true' && cookies.get('sandpit') !== 'true' && (moment().tz(moment.tz.guess()).format('z') !== moment().tz(user.details.time_zone).format('z')));
    if (showTimeZonePopup) {
      backgroundOnModalOpen(true);
    }
    return (
      <div>
        {/* <BrowserRouter> */}
          <div>
            <div className='top-content'>
              <div className='container'>
                {cookies.get('sandpit') === 'true' && (
                  <div className="row">
                    <div className="col-12">
                      <div id="flash_messages">
                        <div className="alert alert-fixed alert-info fade in">
                          <button
                            type="button"
                            className="close"
                            name="info"
                            data-dismiss="alert"
                            aria-hidden="true"
                            onClick={(e) => this.removeMessage(e)}
                            >
                            <i className="fa fa-times fa-xs"></i>
                          </button>
                          <p>{translations.sandpit.welcome_message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className='row'>
                  <div className='col-md-12 maincontent'>
                    {!isEmpty(user) && <div>
                      <QQListing {...this.props} />
                      </div>}
                  </div>
                </div>
              </div>
            </div>
            {loadLiveChat &&
            <div>
              <Script
                url='/quick_quotes/js/zoho.js'
              />
              <Script
                url='/quick_quotes/js/walkme.js'
              />
            </div>
            }
          </div>
        {/* </BrowserRouter> */}
        {showTimeZonePopup &&
          <div className='main-popup popup-lg popup-new-message popup-timezone'>
            <Modal style={ { zIndex: 1100 } } show={ this.state.showModal } closeOnOuterClick onClose={ () => { this.closeMessageModal(); } }>
              <div className='vertical-top time-modal'>
                <div className='vertical-align-center'>
                  <div className='modal-content clearfix'>
                    <a
                      href='#'
                      onClick={ (e) => { e.preventDefault(); this.closeMessageModal(); } }
                      className='close-icon modal-btn-close'
                    >
                      <i className='fa fa-times' aria-hidden='true' />
                    </a>
                    <div className='modal-header'>
                      <h4>
                        {translations.timezone_popup.notification}
                      </h4>
                    </div>
                    <div className='modal-body' >
                      <div className='col-md-12 time-content'>
                        <p>{translations.timezone_popup.body_text_one}<span> ({TimeZoneSystem})</span> {translations.timezone_popup.body_text_two} ({TimeZoneUser}). {translations.timezone_popup.body_text_three}</p>
                      </div>
                      <div className='row time-button'>
                        <div className='col-md-6 col-md-offset-3 text-center'>
                          <a className='btn btn-success col-md-12 m-t5' href={ `/users/${user.details.id}/edit` }>{translations.timezone_popup.go_to_profile}</a>
                          <button className='btn btn-lg btn-default col-md-12 m-t5' type='button' onClick={ (e) => { e.preventDefault(); this.closeMessageModal(); } }>
                          {translations.timezone_popup.no_thanks}
                          </button>
                        </div>
                      </div>
                      <div className='modal-footer' />
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  error: state.errorMessageReducer,
  success: state.successMessageReducer,
});

const mapDispatchToProps = dispatch => ({
  // changeLanguage: currentLanguage => dispatch(changeLanguage(currentLanguage)),
  // fetchUserDetails: () => dispatch(fetchUserDetails()),
  // updateLocale: (userId, payload) => dispatch(updateLocale(userId, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(QuickQuote));
