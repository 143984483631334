import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ContractFilterHeader from "./ContractFilterHeader";
import SearchFilter from "./SearchFilter";
import ContractList from "./ContractList";
import ReactPaginate from "react-paginate";
import { markArchive } from "../../../actions/contract/contractListingActions";
import { Confirm } from "../../common/Confirm";
class ContractFilter extends Component {
  constructor(props) {
    super(props);
    let archived = window.sessionStorage.getItem("archived");
    let contracts = props.contracts.slice(0);
    let tmpContracts = contracts.filter((contract) => {
      return archived == "true" ? contract : contract.current_state != "archived";
    });
    let contractsPerPage = localStorage.getItem("contractsPerPage_" + props.currentUser.id)
      ? localStorage.getItem("contractsPerPage_" + props.currentUser.id)
      : 10;
    this.state = {
      selectedPage: 0,
      pageCount: Math.ceil(contracts.length / contractsPerPage),
      perPageContracts: tmpContracts.splice(0, contractsPerPage),
      session: archived && archived !== "false" ? true : false,
      contracts: props.contracts.slice(0),
      contractToBeDeleted: null,
      showList: true,
      contractsPerPage: contractsPerPage,
      popupTitle: "",
      popupText: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let tmpContracts = [];
    let selectedPage, pageCount, session, perPageContracts;
    let archived = window.sessionStorage.getItem("archived");
    tmpContracts = nextProps.contracts.filter((contract) => {
      return archived == "true" ? contract : contract.current_state != "archived";
    });
    if (
      prevState.session != archived ||
      JSON.stringify(prevState.contracts) != JSON.stringify(tmpContracts) ||
      prevState.contractsPerPage
    ) {
      let t = tmpContracts.slice(0);
      pageCount = Math.ceil(tmpContracts.length / prevState.contractsPerPage);
      selectedPage = prevState.selectedPage <= pageCount ? prevState.selectedPage : pageCount - 1;
      session = archived;
      perPageContracts =
        t.length < prevState.contractsPerPage
          ? t
          : t.splice(selectedPage * prevState.contractsPerPage, prevState.contractsPerPage);
      return {
        pageCount,
        selectedPage,
        session,
        perPageContracts,
        showList: true,
        contracts: tmpContracts,
        contractsPerPage: prevState.contractsPerPage,
      };
    }
    if (nextProps.getSession.filteredContracts)
      return {
        showList: true,
      };
    return null;
  }

  paginate = (page = 0) => {
    const { contracts, contractsPerPage } = this.state;
    let c = contracts.slice(0);
    let archived = window.sessionStorage.getItem("archived");
    let tmpContracts = c.filter((contract) => {
      return archived == "true" ? contract : contract.current_state != "archived";
    });
    this.setState({
      selectedPage: page,
      perPageContracts: tmpContracts.splice(page * contractsPerPage, contractsPerPage),
    });
  };

  contractMarkArchive = () => {
    this.props.markArchive(this.state.contractToBeDeleted);
    this.setState({
      contractToBeDeleted: null,
    });
  };

  handlePageClick = (page) => {
    let selected = page.selected;
    this.paginate(selected);
  };

  handleDelete = (contract, popupTitle, popupText) => {
    this.setState((prevState) => {
      const pageSelected =
        prevState.perPageContracts.length == 1 && prevState.selectedPage != 1
          ? prevState.selectedPage - 1
          : prevState.selectedPage;
      return {
        popupTitle: popupTitle,
        popupText: popupText,
        contractToBeDeleted: contract,
        selectedPage: pageSelected,
      };
    });
  };

  handleContractsPerPage = (e) => {
    const value = e.target.value;
    this.setState({
      contractsPerPage: value,
    });
    localStorage.setItem("contractsPerPage_" + this.props.currentUser.id, value);
    this.paginate(0);
  };

  showListLoader = (showList) => {
    this.setState({ showList });
  };

  render() {
    const {
      translations,
      currentUser,
      allow_admin_section,
      canShow,
      contract_hosts,
      currentUserCompany,
      custom_columns_values,
      contact_company_names,
      owner,
      sim_event,
      suppliers,
      stakeholders,
      categories,
      edit_contracts,
      show_contracts,
      dropdown_options,
      states,
      custom_columns,
      view_only,
      parent_stakeholders,
      titleOptions,
      reference,
    } = this.props;
    const { selectedPage, pageCount, perPageContracts, contracts, showList, contractsPerPage, popupTitle, popupText } =
      this.state;
    let any_non_permitted_contract = contracts.map((contract) => {
      return contract.contract_user_company;
    });
    return (
      <>
        <div className="row">
          <div className="col-12 mt-3 mb-2">
            <div className="table-responsive contracts-listing-table-scroll">
              <table className="table contracts-table table-layout-fixed">
                <thead className="sticky-header">
                  <ContractFilterHeader
                    any_non_permitted_contract={any_non_permitted_contract}
                    {...this.props}
                    allow_admin_section={allow_admin_section}
                    contracts={perPageContracts}
                    currentUserCompany={currentUserCompany}
                    handleSelect={() => this.handleSelectAll()}
                    selectedPage={selectedPage}
                  />
                  <SearchFilter
                    titleOptions={titleOptions}
                    any_non_permitted_contract={any_non_permitted_contract}
                    dropdown_options={dropdown_options}
                    translations={translations}
                    contracts={contracts}
                    allow_admin_section={allow_admin_section}
                    canShow={canShow}
                    contract_hosts={contract_hosts}
                    contact_company_names={contact_company_names}
                    currentUserCompany={currentUserCompany}
                    any_non_permitted_contract={any_non_permitted_contract}
                    reference={reference}
                    owner={owner}
                    suppliers={suppliers}
                    selectedPage={selectedPage}
                    stakeholders={stakeholders}
                    showListLoader={(status) => this.showListLoader(status)}
                    categories={categories}
                    states={states}
                    custom_columns={custom_columns}
                    currentUser={currentUser}
                  />
                </thead>
                <ContractList
                  any_non_permitted_contract={any_non_permitted_contract}
                  translations={translations}
                  custom_columns_values={custom_columns_values}
                  allow_admin_section={allow_admin_section}
                  sim_event={sim_event}
                  canShow={canShow}
                  currentUser={currentUser}
                  contracts={perPageContracts}
                  currentUserCompany={currentUserCompany}
                  custom_columns={custom_columns}
                  selectedPage={selectedPage}
                  pageCount={pageCount}
                  edit_contracts={edit_contracts}
                  show_contracts={show_contracts}
                  view_only={view_only}
                  parent_stakeholders={parent_stakeholders}
                  handleDeleteContract={this.handleDelete}
                />
              </table>
              {!showList && <div id="loader"></div>}
              <Confirm
                translations={translations}
                message={popupText}
                title={popupTitle}
                htmlId="deleteArchiveContract"
                onOkayButtonClick={() => this.contractMarkArchive()}
              />
            </div>
          </div>
        </div>
        <div className="list-footer">
          {contracts.length > 10 && perPageContracts.length > 0 && (
            <div className="col-12 d-flex justify-content-end align-items-center">
              <Fragment>
                <div style={{ marginBottom: "1rem" }}>
                  <select
                    className="form-control"
                    value={contractsPerPage}
                    onChange={(e) => this.handleContractsPerPage(e)}
                    name="selectRecordsPerPage"
                  >
                    <option value={10}>10 {translations.records_per_page}</option>
                    <option value={20}>20 {translations.records_per_page}</option>
                    <option value={50}>50 {translations.records_per_page}</option>
                    <option value={100}>100 {translations.records_per_page}</option>
                  </select>
                </div>
              </Fragment>

              <ReactPaginate
                previousLabel={"← Previous"}
                pageClassName="hands-on"
                forcePage={selectedPage}
                previousClassName="hands-on"
                nextClassName="hands-on"
                disabledClassName="hide"
                nextLabel={"Next →"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={(e) => this.handlePageClick(e)}
                containerClassName={" pagination pagination-sm"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getSession: state.contractListingReducers,
});
const mapDispatchToProps = (dispatch) => ({
  markArchive: (contract) => dispatch(markArchive(contract)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContractFilter);
