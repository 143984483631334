import React, { useCallback } from "react";
import {
  showArchivedToggled,
  newListingSearch,
  selectListingSearch,
  selectShowArchived,
  selectCanCreateContract,
} from "@/slices/contracts/listing";
import { t } from "@/i18n";
import { Switch } from "@/components/common/bootstrap/switch";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useDebouncedSync } from "@/hooks/use-debounced-sync";

function Search() {
  const dispatch = useAppDispatch();
  const searchFromState = useAppSelector(selectListingSearch);
  const [search, setSearch] = useDebouncedSync(
    searchFromState,
    useCallback((value) => dispatch(newListingSearch(value)), [dispatch])
  );
  return (
    <input
      type="search"
      name="search"
      className="form-control search_contract w-auto"
      defaultValue={search}
      placeholder={t("search")}
      onChange={(e) => setSearch(e.target.value)}
    />
  );
}

function ToggleArchived() {
  const dispatch = useAppDispatch();
  const showArchived = useAppSelector(selectShowArchived);
  return (
    <Switch
      id="archived_switch"
      defaultChecked={showArchived}
      onChange={(e) => dispatch(showArchivedToggled(e.target.checked))}
    >
      <span>{t("show_archived")}</span>
    </Switch>
  );
}

function CreateButton() {
  const canCreateContracts = useAppSelector(selectCanCreateContract);

  if (!canCreateContracts) {
    return null;
  }

  return (
    <a href="/contracts/new" id="create_new_contract_button" className="btn btn-contract">
      {t("add_new_contract")}
    </a>
  );
}

export default function ContractControls() {
  return (
    <div className="row mb-4">
      <div className="col-12 filter-holder d-flex justify-content-between">
        <div>
          <CreateButton />
        </div>
        <div className="d-flex">
          <Search />
          <ToggleArchived />
        </div>
      </div>
    </div>
  );
}
