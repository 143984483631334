import React, { Component } from "react";
import HostMonitorPendingOverviewSuppliers from "./HostMonitorPendingOverviewSuppliers";
import { multiCurrencyEvent, fetchAssociatedCurrency } from "../../common";
import HostMonitorPendingOverviewLot from "./HostMonitorPendingOverviewLot";
class LotSummary extends Component {
  constructor(props) {
    super(props);
    this.state = { bids: props.bids };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      bids: [...nextProps.bids],
    };
  }

  render() {
    const { bids } = this.state;
    const {
      event,
      translations,
      lots,
      participatingParticipants,
      isComplex,
      displayScores,
      weightedRfqOrAuction,
      permissionToView,
      participants,
      currenciesHash,
      eventCurrency,
      lineItems,
      lotComponents,
      lineItemComponents,
      beforeAuction,
      locale,
      bidLineItemComponents,
      eventParticipants,
      unitOfMeasures,
      allUnitSets,
      role,
      isLiOpened,
      isLcOpened,
      isEpOpened,
      toggleLiCollapse,
      toggleLcCollapse,
      permittedToEdit,
      bidControls,
      auctionControls,
      isJapaneseEvent,
      hosts,
      currentUser,
      timeZoneIdentifier,
      bidRound,
      eventPassedFinalDeadline,
      toggleEpCollapse,
      unitSetUoms,
      bidsSealed,
      picklistOptions,
      auctionTab,
      canShowDeleteBtn,
      hostPermission,
      inSandpit,
      remainingParticipantsForJapaneseEvent,
      allow_to_show_proxy_btn,
    } = this.props;
    const isMultiCurrencyEvent = multiCurrencyEvent(event);
    return (
      <>
        <HostMonitorPendingOverviewSuppliers
          event={event}
          translations={translations}
          isComplex={isComplex}
          participatingParticipants={participatingParticipants}
          displayScores={displayScores}
          weightedRfqOrAuction={weightedRfqOrAuction}
          permissionToView={permissionToView}
          participants={participants}
          isEpOpened={isEpOpened}
          isLcOpened={isLcOpened}
          toggleLcCollapse={toggleLcCollapse}
          auctionTab={auctionTab}
          isJapaneseEvent={isJapaneseEvent}
        />

        {lots.map((lot, lotIndex) => {
          const currency = isMultiCurrencyEvent ? fetchAssociatedCurrency(currenciesHash, lot) : eventCurrency;
          return (
            <HostMonitorPendingOverviewLot
              key={`${lot.id}-${Date.parse(lot.updated_at)}`}
              event={event}
              currency={currency}
              lot={lot}
              firstLot={lotIndex === 0}
              translations={translations}
              participatingParticipants={participatingParticipants}
              permissionToView={permissionToView}
              lineItems={lineItems}
              lotComponents={lotComponents}
              lineItemComponents={lineItemComponents}
              bids={bids}
              displayScores={displayScores}
              weightedRfqOrAuction={weightedRfqOrAuction}
              beforeAuction={beforeAuction}
              lots={lots}
              locale={locale}
              isMultiCurrencyEvent={isMultiCurrencyEvent}
              bidLineItemComponents={bidLineItemComponents}
              lotIndex={lotIndex}
              participants={participants}
              eventParticipants={eventParticipants}
              currenciesHash={currenciesHash}
              unitOfMeasures={unitOfMeasures}
              allUnitSets={allUnitSets}
              role={role}
              isLcOpened={isLcOpened}
              isEpOpened={isEpOpened}
              isLiOpened={isLiOpened}
              toggleLiCollapse={toggleLiCollapse}
              permittedToEdit={permittedToEdit}
              bidControls={bidControls}
              auctionControls={auctionControls}
              isJapaneseEvent={isJapaneseEvent}
              hosts={hosts}
              currentUser={currentUser}
              bidRound={bidRound}
              timeZoneIdentifier={timeZoneIdentifier}
              eventPassedFinalDeadline={eventPassedFinalDeadline}
              toggleEpCollapse={toggleEpCollapse}
              unitSetUoms={unitSetUoms}
              bidsSealed={bidsSealed}
              picklistOptions={picklistOptions}
              auctionTab={auctionTab}
              canShowDeleteBtn={canShowDeleteBtn}
              hostPermission={hostPermission}
              inSandpit={inSandpit}
              remainingParticipantsForJapaneseEvent={remainingParticipantsForJapaneseEvent}
              allowtoShowProxyBtn={allow_to_show_proxy_btn}
              partialBiddingSwitch={this.props.partialBiddingSwitch}
            />
          );
        })}
      </>
    );
  }
}

export default LotSummary;
