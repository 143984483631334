import { t } from "@/i18n";
import { assert } from "@/utils/assert";
import { Controller } from "@hotwired/stimulus";

/**
 * ToggleController handles the behavior of a toggle switch.
 * It updates the label based on the checked state of the toggle input.
 */
export default class extends Controller {
  /**
   * @type {HTMLElement | null}
   */
  #labelText = null;

  /**
   * @type {HTMLInputElement | null}
   */
  #checkbox = null;

  connect() {
    // Find the toggle label within the current element
    this.#labelText = this.element.querySelector(".label-text");
    if (!this.#labelText) {
      return;
    }

    // Find the checkbox input within the current element
    this.#checkbox = this.element.querySelector("input");
    assert(this.#checkbox, "Could not find the checkbox input.");

    // Set up an event listener for changes to the checkbox
    this.#checkbox.addEventListener("change", this.toggleLabelText);
  }

  disconnect() {
    if (this.#checkbox) {
      // Remove the event listener from the checkbox
      this.#checkbox.removeEventListener("change", this.toggleLabelText);
    }
  }

  /**
   * @param {Event} event
   */
  toggleLabelText = (event) => {
    if (!this.#labelText || !this.#checkbox) return;
    assert(event.target instanceof HTMLInputElement, "Expected an input element.");

    if (event.target.checked) {
      this.#labelText.textContent = t("enabled");
      this.#checkbox.setAttribute("aria-label", t("enabled"));
    } else {
      this.#labelText.textContent = t("disabled");
      this.#checkbox.setAttribute("aria-label", t("disabled"));
    }
  };
}
