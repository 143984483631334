import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from "../../../../../common/Tooltip";
import { isANumber, showCurrency } from "../../../../common";
import { latestBidDuringAuctionForLot, companyNameOrEmail } from "../../../../bidCommon";
import LeadParticipantModal from "../../modal/LeadParticipantModal";
import { isWeightedRfqOrAuction } from "../../../../eventCommon";

/**
 *
 * @description Participants table for AddEventForm
 * @prop isDroppedTable => Boolean => true if rendering table for dropped participants
 * @prop participants => Array => particpants, it could be array of participating particpants or dropped participants
 * @returns {JSX.Element} Particpiant Table
 */
export default function ParticipantTable({ isDroppedTable, participants, ...rest }) {
  const { translations } = useSelector((state) => state.lotReducers);
  const {
    participant: participantText,
    bids_entered,
    dropped_round,
    dropped_participants,
    no_records,
    total_price,
    infos: { lots_entered_for_multistage_rfq, dropped_round_for_multistage_rfq },
  } = translations;

  const droppedColumnHeader = (
    <>
      {isDroppedTable ? dropped_round : bids_entered}&nbsp;
      <Tooltip {...(isDroppedTable ? dropped_round_for_multistage_rfq : lots_entered_for_multistage_rfq)} />
    </>
  );

  const tableHeaders = ["", participantText, droppedColumnHeader, total_price];

  return (
    <ParticipantTableWrapper isDroppedTable={isDroppedTable} droppedParticipantsText={dropped_participants}>
      <table className="table table-bordered custom-responsive-table">
        <thead className="table-block-title with-filter sticky-header">
          <tr>
            {tableHeaders.map((header, index) => (
              <th className="w-rfq-thead-popup" key={index} style={{ zIndex: 0 }}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody id="participant_summary_body">
          {participants.length &&
            participants.map((participant) => (
              <ParticipantTableBodyRow participant={participant} key={participant.id} {...rest} />
            ))}
          {!isDroppedTable && !participants.length && (
            <tr>
              <td colSpan="11">{no_records}</td>
            </tr>
          )}
        </tbody>
      </table>
    </ParticipantTableWrapper>
  );
}

ParticipantTable.defaultProps = {
  isDroppedTable: false,
  participants: [],
};

ParticipantTable.propTypes = {
  isDroppedTable: PropTypes.bool,
  participants: PropTypes.array,
};

/**
 *
 * @description HOC to conditionally render wrapper for eventParticipants or droppedParticipants table
 * @prop isDroppedTable => Boolean => true if rendering table for dropped participants
 * @prop droppedParticipantsText => String => droppedParticipants translations
 * @prop children to be wrapped
 * @returns {JSX.Element} Particpiant Table Wrapper
 */
const ParticipantTableWrapper = ({ isDroppedTable, droppedParticipantsText, children }) => {
  if (isDroppedTable) {
    return (
      <div>
        <div className="panel panel-default">
          <div className="panel-heading">
            <span className="panel-title">
              <a className="collapsed" id="dropped-participants" data-toggle="collapse" href="#collapse1">
                <span className="glyphicon glyphicon-triangle-right"></span>
                <span>
                  <h6 style={{ display: "inline" }}>{droppedParticipantsText}</h6>
                </span>
              </a>
            </span>
          </div>
          <div id="collapse1" className="panel-collapse collapse">
            {children}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-12">{children}</div>
    </div>
  );
};

ParticipantTableWrapper.defaultProps = {
  isDroppedTable: false,
  droppedParticipantsText: "",
};

ParticipantTableWrapper.propTypes = {
  isDroppedTable: PropTypes.bool,
  droppedParticipantsText: PropTypes.string,
  children: PropTypes.element,
};

/**
 *
 * @description Table body row to conditionally render eventParticipant or droppedParticipant data
 * @prop droppedParticipantsText => String => droppedParticipants translations
 * @prop isDroppedTable => Boolean => true if rendering table for dropped participants
 * @returns {JSX.Element} Particpiant Table Body Row
 */
const ParticipantTableBodyRow = ({ participant, isDroppedTable, name, handleChange, formValues, ...rest }) => {
  const lotReducers = useSelector((state) => state.lotReducers);
  const {
    participants,
    bids,
    event,
    event_currency,
    locale,
    translations: { na },
  } = lotReducers;
  const user = participants?.find((user) => user?.id === participant?.user_id) ?? {};
  const userName = companyNameOrEmail(user);

  const bestBid =
    latestBidDuringAuctionForLot(bids, [participant], event, isWeightedRfqOrAuction(event), false)?.[0] ?? {};
  const bidCount =
    latestBidDuringAuctionForLot(bids, [participant], event, isWeightedRfqOrAuction(event), true)?.length ?? na;
  const price = bestBid.price ?? bestBid.host_price;
  const bestBidPrice = showCurrency(price, event_currency, 2, locale);

  return (
    <tr className="participant" key={user.id}>
      <td>
        <label className="css-input css-checkbox css-checkbox-default">
          <input
            type="checkbox"
            id={participant.id}
            name={name}
            onChange={handleChange}
            value={participant.id}
            checked={formValues?.includes(participant.id)}
          />
          <span></span>
        </label>
      </td>
      <td className="part_user thead-colsmall">
        <LeadParticipantModal
          header={user?.name}
          viewComponent={user?.name}
          participant={participant}
          user={user}
          lineItems={lotReducers.line_items}
          lotComponents={lotReducers.lot_components}
          lineItemComponents={lotReducers.line_item_componnets}
          {...rest}
          {...lotReducers}
        />
        <div>{user.email}</div>
        <div>{user.user_company_name}</div>
      </td>
      <td>
        {!isDroppedTable ? (
          <>
            {/* show total bid count for user here */}
            {/* @event.bids.where(bid_at_rfq_state: true, user_id: event_participant.user.id).size %> */}
            {bidCount}
          </>
        ) : (
          <>
            {/* This column shows the participant dropped round */}
            {isANumber(user.ep_last_event_round) ? user.ep_last_event_round + 1 : 0}
          </>
        )}
      </td>
      <td>
        {/* show totalBidPrice here */}
        <LeadParticipantModal
          header={userName}
          viewComponent={bestBidPrice === "-" ? na : bestBidPrice}
          participant={participant}
          user={user}
          lineItems={lotReducers.line_items}
          lotComponents={lotReducers.lot_components}
          lineItemComponents={lotReducers.line_item_componnets}
          {...rest}
          {...lotReducers}
        />
      </td>
    </tr>
  );
};

ParticipantTableBodyRow.defaultProps = {
  isDroppedTable: false,
  participant: {},
};

ParticipantTableBodyRow.propTypes = {
  isDroppedTable: PropTypes.bool,
  participant: PropTypes.object,
};
