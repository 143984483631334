import React from "react";
import { useAppSelector } from "@/hooks/redux";
import { selectContracts, selectColumns, selectCustomColumnValues } from "@/slices/contracts/listing";

export const ContractTable: React.FC = () => {
  const contracts = useAppSelector(selectContracts);
  const columns = useAppSelector(selectColumns);
  const customColumnValues = useAppSelector(selectCustomColumnValues);

  return (
    <div className="table-responsive contracts-listing-table-scroll">
      <table className="table contracts-table table-layout-fixed">
        <thead className="sticky-header">
          <tr className="search-row">
            {columns.map(({ HeaderComponent, key }) => (
              <HeaderComponent key={key} />
            ))}
          </tr>
        </thead>

        <tbody className="table-content">
          {contracts.map((contract) => (
            <tr key={contract.id}>
              {columns.map(({ CellComponent, key }) => (
                <CellComponent row={contract} customValues={customColumnValues[contract.id]} key={key} />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContractTable;
