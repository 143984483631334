import React from "react";
import PropTypes from "prop-types";

export const CellListBuilder = ({ items, linkPrefix }) => {
  return (
    <td className="table-cell-ellipsis">
      <ul className="list-unstyled m-b0">
        {items.map((co) => {
          return (
            <li key={"activity-" + "concernedContact-" + co.id}>
              <a href={linkPrefix + co.id} title={co.name}>
                {co.name}
              </a>
            </li>
          );
        })}
      </ul>
    </td>
  );
};

CellListBuilder.propTypes = {
  items: PropTypes.array.isRequired,
  linkPrefix: PropTypes.string.isRequired,
};
