import { Component } from 'react';

const cookies = require('browser-cookies');
// Note: If you override componentDidMount or componentWillUnmount you will need to
// call super.componentDidMount() or super.componentWillUnmount() or call
// watchStores() and unWatchStores() directly.
export default class BaseComponent extends Component {
  UNSAFE_componentWillMount() {
    const isCookies = cookies.get('auth_headers');
    if (!isCookies) {
      window.location.href = '/users/home';
    }
  }

  _bind(...methods) {
    methods.forEach(method => this[method].bind(this));
  }
}
