import React from "react";
import { t } from "@/i18n";
import type { CustomColumnCommonProps } from "./pick-one-from-list";
import type { CustomColumnValue } from ".";
import { useTextAttribute } from "@adeattwood/react-form";
import Feedback from "@/cl/input/feedback";

interface TextFieldProps extends CustomColumnCommonProps {}

const useTitle = (values: CustomColumnValue[]) => {
  if (values.length === 0) return "";
  return t(values[0].name) || values[0].name;
};

const TextField: React.FC<TextFieldProps> = ({ field, readOnly }) => {
  const title = useTitle(field.custom_column_values) as string;
  const { props, error } = useTextAttribute(field.id.toString());
  return (
    <>
      <input
        {...props}
        type="text"
        className="form-control height-30"
        placeholder={t("value")}
        title={title}
        disabled={readOnly || !field.active}
        required={field.required}
        aria-labelledby={`custom-field-${field.id}-name`}
      />
      <Feedback className="required" id={props.id} error={error} />
    </>
  );
};

export default TextField;
