import axios from "axios";

import config from "../config";

const headers = config.headers;
export const get = (url) => axios.get(url, { headers });
export const post = (url, data) => axios.post(url, data, { headers });
export const remove = (url) => axios.delete(url, { headers });
export const put = (url, data) => axios.put(url, data, { headers });
export const patch = (url, data) => axios.patch(url, data, { headers });
