import React, { useState } from "react";
import { CheckLevel } from "./check_level";
import { ButtonLevel } from "./button_level";
import type { StackItem, StackPanelProps } from "./types";

import styles from "./multi_level_select.module.scss";

export default function StackPanel({ filteredItems, hidden }: StackPanelProps) {
  const [levelStack, setLevelStack] = useState<StackItem[]>([]);

  return (
    <div className={styles.multiLevelStack} hidden={hidden}>
      <ButtonLevel
        filteredItems={filteredItems}
        selectedLevels={levelStack.map((item) => item.parent.label)}
        onClick={(item) => {
          if (item.children) {
            setLevelStack([
              {
                parent: item,
                items: item.children,
              },
            ]);
          }
        }}
      />
      {levelStack.map((item) => (
        <CheckLevel key={`level-${item.parent.value}`} stackItem={item} />
      ))}
    </div>
  );
}
