/* eslint-disable func-names */

/**
 * Mixin for component class.
 *
 * DEVELOPMENT NOTES:
 * 1. To reuse the methods, bind all the methods to a class.
 * 2. 'this' reference inside every fn here references to the class scope.
 * 3. To add extra methods, assign methods to the 'that' variable.
 */
const QQEventRelay = (function () {
  const that = {};
  /**
   * Actions constants for event participants.
   */
  that.FormGroupActions = {
    MANAGE_EMAIL_SELECTION: 'MANAGE EMAIL SELECTION',
    REMOVE_PARTICIPANT: 'REMOVE PARTICIPANT',
    SELECT_GROUP: 'SELECT GROUP',
    UNSELECT_GROUP: 'UNSELECT GROUP',
    CHANGE_STEP: 'CHANGE STEP',
    SHOW_ACTIVE: 'SHOW_ACTIVE',
    SELECT_EMAIL: 'SELECT_EMAIL',
    UNSELECT_EMAIL: 'UNSELECT_EMAIL',
    SYNC_WITH_FORM: 'SYNC_WITH_FORM',
  };

  /**
   * Extra state data required for the component.
   */
  that.stateMutator = {
    activeFormGroup: {},
    formGroupStep: 1,
  };

  /**
   * Action relay for event participants.
   *
   * @param {boolean} syncWithForm
   * @param {String} action
   * @param {any} payload
   *
   * @returns {any}
   */
  that.formGroupActions = function (syncWithForm, action, payload) {
    const { activeFormGroup } = this.state;
    let { selectedGroups } = this.state;
    let { selectedParticipants } = activeFormGroup;

    switch (action) {
      /**
       * Action operation for removing a participant from selected email group.
       */
      case this.FormGroupActions.REMOVE_PARTICIPANT: {
        activeFormGroup.is_all_selected = false;
        selectedGroups = selectedGroups.filter((group, idx) => {
          selectedGroups[idx].is_all_selected = false;
          if (group.id === payload.group_id) {
            selectedGroups[idx].selectedParticipants.delete(payload.email);
          }

          if (!selectedGroups[idx].selectedParticipants.size) {
            return false;
          }

          return true;
        });

        if (activeFormGroup && activeFormGroup.selectedParticipants) {
          activeFormGroup.selectedParticipants.delete(payload.email);
        }

        return this.setState({
          selectedGroups,
          activeFormGroup: activeFormGroup || {},
        }, () => syncWithForm && this.formGroupActions(this.FormGroupActions.SYNC_WITH_FORM));
      }
      /**
       * Adds/removes emails from the selected emails on demand.
       */
      case this.FormGroupActions.MANAGE_EMAIL_SELECTION: {
        if (selectedParticipants.has(payload.email)) {
          selectedParticipants.delete(payload.email);
          activeFormGroup.is_all_selected = false;
        } else {
          if (!this.checkExisting(payload.email)) {
            this.props.showToasterMessages('SHOW_ERROR', this.props.translations.qq_create.qq_participant.already_participating);
            return false;
          }

          let selectableCount = this.canSelectMore();

          if (selectableCount) {
            selectedParticipants.add(payload.email);
            selectableCount -= 1;
            if (!selectableCount) {
              activeFormGroup.is_all_selected = true;
            }

            if (selectableCount >= 1) {
              let noMoreToSelect = true;
              activeFormGroup.participant_emails.map((email) => {
                if (!selectedParticipants.has(email)
                  && this.checkExisting(email)
                  && email.toLowerCase() !== payload.email.toLowerCase()) {
                  noMoreToSelect = false;
                  return email;
                }
                return email;
              });

              if (noMoreToSelect) {
                activeFormGroup.is_all_selected = true;
              }
            }
          }
        }

        return this.setState((prevState) => {
          let found = false;

          prevState.activeFormGroup.selectedParticipants = selectedParticipants;
          prevState.selectedGroups = prevState.selectedGroups.map((group) => {
            if (group.id === payload.group_id) {
              group.selectedParticipants = selectedParticipants;
              found = true;
            }
            return group;
          })
            .filter((group) => {
              if ((group.id !== payload.group_id && group.selectedParticipants.has(payload.email))) {
                this.props.showToasterMessages('SHOW_ERROR', this.props.translations.qq_create.qq_participant.already_participating);
                return false;
              }

              if (group.id === payload.group_id && !selectedParticipants.size) {
                return false;
              }

              return true;
            });

          if (!found) {
            prevState.selectedGroups.push(activeFormGroup);
          }

          return prevState;
        }, () => syncWithForm && this.formGroupActions(this.FormGroupActions.SYNC_WITH_FORM));
      }
      /**
       * Selects a groups on demand.
       */
      case this.FormGroupActions.SELECT_GROUP: {
        const count = this.canSelectMore();

        if (!count) {
          return false;
        }

        selectedParticipants = new Set(payload.participant_emails.filter(this.checkExisting).slice(0, count));

        if (selectedParticipants.size) {
          return this.setState((prevState) => {
            prevState.activeFormGroup = payload;
            prevState.activeFormGroup.selectedParticipants = selectedParticipants;
            prevState.activeFormGroup.is_all_selected = true;
            prevState.selectedGroups.push(prevState.activeFormGroup);
            prevState.formGroupStep = 2;
            return prevState;
          }, () => syncWithForm && this.formGroupActions(this.FormGroupActions.SYNC_WITH_FORM));
        }

        this.props.showToasterMessages('SHOW_ERROR', this.props.translations.error_message.group_error);
      }
      /**
       * Changes form step.
       */
      case this.FormGroupActions.CHANGE_STEP: {
        return this.setState({
          formGroupStep: 1,
        });
      }
      /**
       * Unselects groups and underlying emails form the selected list.
       */
      case this.FormGroupActions.UNSELECT_GROUP: {
        return this.setState((prevState) => {
          prevState.selectedGroups = prevState.selectedGroups.filter((grps) => grps.id !== payload.group_id);

          if (prevState.activeFormGroup.selectedParticipants && prevState.activeFormGroup.id === payload.group_id) {
            prevState.activeFormGroup.selectedParticipants.clear();
            prevState.activeFormGroup.is_all_selected = false;
          }

          return prevState;
        }, () => syncWithForm && this.formGroupActions(this.FormGroupActions.SYNC_WITH_FORM));
      }
      /**
       * Show active email group.
       */
      case this.FormGroupActions.SHOW_ACTIVE: {
        const selectableCount = this.canSelectMore(false);
        payload.is_all_selected = !selectableCount ? 1 : 0;

        if (selectableCount) {
          let noMoreToSelect = true;
          payload.participant_emails.map((email) => {
            // if in selected participants or in existing participants
            // then no more to selected
            if (!payload.selectedParticipants.has(email) && this.checkExisting(email)) {
              noMoreToSelect = false;
              return email;
            }
            return email;
          });

          if (noMoreToSelect) {
            payload.is_all_selected = true;
          }
        }

        return this.setState({
          activeFormGroup: payload,
          formGroupStep: 2,
        });
      }

      /**
       * Sync selected data with localstorage.
       */
      case this.FormGroupActions.SYNC_WITH_FORM: {
        const groupIds = this.state.selectedGroups.map((o) => o.id);
        this.props.onTextValue('participant_group_id', groupIds);

        const restField = JSON.parse(localStorage.getItem('restField'));
        restField.participantGroups = this.state.selectedGroups;
        this.props.onTextValue('participantGroups', this.state.selectedGroups);

        localStorage.setItem('restField', JSON.stringify(restField));
        return true;
      }

      default:
        // Do nothing here
        return false;
    }
  };

  /**
   * Calculates number of participants left to participate.
   *
   * @returns {number|boolean}
   */
  that.canSelectMore = function (notification = true) {
    let count = 0;
    const participantLimit = this.props.configurations.limits
      ? this.props.configurations.limits.qq_participants_limit
      : this.props.participantsLimit;

    if (this.state.data) {
      count += this.state.data.length;
    }

    this.state.selectedGroups.map((group) => {
      count += (group.selectedParticipants ? group.selectedParticipants.size : 0);
      return group;
    });

    // Dropdown tags in create event
    if (this.state.tags) {
      count += this.state.tags.length;
    }

    // Dropdown tags in edit event
    if (this.state.selectedParticipants) {
      count += this.state.selectedParticipants.length;
    }

    const remainingCount = participantLimit - count;

    if (count >= participantLimit) {
      if (notification) {
        this.props.showToasterMessages('SHOW_ERROR', this.props.translations.qq_create.qq_participant.participant_limit_exceeding.replace("%{remaining_participant}", participantLimit));
      }
      return false;
    }

    return remainingCount;
  };

  /**
   * Checks if an email is already participating.
   *
   * returns false if exists
   * @param {string} email
   * @returns {boolean}
   */
  that.checkExisting = function (email) {
    const { data, selectedGroups } = this.state;
    const existingEmails = new Set(data ? data.map((d) => d.email) : []);

    if (existingEmails.has(email)) {
      return false;
    }

    for (let i = 0; i < selectedGroups.length; i += 1) {
      if (selectedGroups[i].selectedParticipants.has(email)) {
        return false;
      }
    }

    return true;
  };

  that.getSelectedById = function (id) {
    return this.state.selectedGroups.find((group) => group.id === id);
  };

  return that;
}());

module.exports = QQEventRelay;
