import React from "react";
import { useSelector } from "react-redux";
import { statusLabel } from "../StatusLabel";
import PropTypes from "prop-types";

const Status = ({ status }) => {
  const { translations } = useSelector((state) => state.ActivityReducer);

  return (
    <td>
      <span className="font-s12 label" style={{ backgroundColor: statusLabel(status) }}>
        {translations[status]}
      </span>
    </td>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Status;
