import React from "react";
import { useSelector } from "react-redux";
import { bidBidLineItemComponents } from "../../../../bidCommon";
import { formatDatetime, componentType } from "../../../../common";
import PriceBlicRowDetails from "../PriceBlicRowDetails";
import TextBlicRowDetails from "../TextBlicRowDetails";
import useEvent from "@/components/advanced_lots/hooks/useEvent";

const BidDetailsForLineItem = (props) => {
  const { bidList, participants, lic } = props;
  const eventBids = useSelector(({ lotReducers }) => lotReducers.bids);
  const allBids = useSelector(({ lotReducers }) => lotReducers.allBids);
  const { isEventMsrfq } = useEvent();
  const bids = isEventMsrfq && allBids ? allBids : eventBids;

  return (
    props.blicBidList &&
    props.blicBidList.map((blic, idx) => {
      const bid = bids && (bids.find((b) => b.id === blic.bid_id) ?? {});
      const participant = props.participatingParticipants.find((p) => p.user_id === bid.user_id) ?? {};
      const bidBlics = bid && bidBidLineItemComponents(bid.id, props.blicBidList);
      const user = participants.find((user) => user.id === bid.user_id);
      const commonProps = {
        bid,
        blic,
        user,
        idx,
        bids,
        formatDatetime,
        componentType,
      };

      return lic.is_price ? (
        <PriceBlicRowDetails
          key={idx}
          {...commonProps}
          {...props}
          participant={participant}
          blics={bidList}
          bidBlics={bidBlics}
        />
      ) : (
        <TextBlicRowDetails key={idx} {...commonProps} {...props} />
      );
    })
  );
};

BidDetailsForLineItem.defaultProps = {
  blicBidList: [],
};

export default BidDetailsForLineItem;
