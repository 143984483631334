import React from "react";
import PropTypes from "prop-types";
import RenderHTML from "@/components/common/RenderHTML";
import { AdminPermissionPropTypes } from "./prop-types";
import { t } from "@/i18n";

const RenderPermissionDetailsPropTypes = {
  allPermissions: PropTypes.arrayOf(PropTypes.string),
  permissionList: PropTypes.arrayOf(PropTypes.shape(AdminPermissionPropTypes)),
};

/**
 * @type {React.FC<typeof RenderPermissionDetailsPropTypes>}
 */
const RenderPermissionDetails = ({ allPermissions, permissionList }) => {
  return (
    <>
      {allPermissions?.map((permissionName) => {
        const matchedPermission = permissionList?.find((permission) => permission.section === permissionName);
        if (matchedPermission) {
          const string = `${t("can")} ${t(matchedPermission?.action)} ${t(
            `enums.admin_permission.section.${matchedPermission.section}`
          )}`;

          return (
            <>
              <RenderHTML key={matchedPermission.id} content={string} />
              <br />
            </>
          );
        }
      })}
    </>
  );
};
RenderPermissionDetails.propTypes = RenderPermissionDetailsPropTypes;
export default RenderPermissionDetails;
