import type { SetStateAction } from "react";
import { useCallback } from "react";
import useUpToDateValue from "./use-up-to-date-value";
import { debounce } from "lodash";
import { useDevDebugValue } from "./use-dev-debug-value";

const isCallback = <T>(value: T | ((prev: T) => T)): value is (prev: T) => T => typeof value === "function";

/**
 * Keeps a local copy of the value which updates immediately, but also debounces a sync back to the source of the value.
 * @param incomingValue Value to sync with
 * @param handleSync Function to sync with
 * @param wait Debounce time
 * @returns A tuple containing the local value and a function to sync the value
 */
export const useDebouncedSync = <T>(incomingValue: T, handleSync: (value: T) => void, wait = 500) => {
  const [localValue, setLocalValue] = useUpToDateValue(incomingValue);
  const debouncedSync = useCallback(debounce(handleSync, wait), [handleSync, wait]);
  const syncValue = (value: SetStateAction<T>) =>
    setLocalValue((prev) => {
      const newValue = isCallback(value) ? value(prev) : value;
      debouncedSync(newValue);
      return newValue;
    });
  useDevDebugValue(localValue);
  return [localValue, syncValue] as const;
};
