import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { isRfq } from "@/components/advanced_lots/eventCommon";
import { BidLineItemComponent } from "@/common-prop-types";

const eventRoundsDetailsSelector = createSelector(
  ({ lotReducers }) => lotReducers.event_rounds,
  ({ lotReducers }) => lotReducers.event,
  ({ lotReducers }) => lotReducers.bids,
  ({ lotReducers }) => lotReducers.allBids,
  (eventRounds, event, bids, allBids) => ({
    eventRounds,
    isRfqEvent: isRfq(event),
    bids: allBids || bids,
  })
);

function EventRoundDetails({ blic }: { blic: BidLineItemComponent }) {
  const { eventRounds, bids, isRfqEvent } = useSelector(eventRoundsDetailsSelector);

  const eventRound = useMemo(() => {
    const blicBid = bids.find((bid: { id: number }) => bid.id === blic.bid_id);
    const eventRoundForBid = eventRounds.find((round: { id: string }) => round.id === blicBid?.event_round_id);

    return eventRoundForBid?.event_round;
  }, [bids, blic, eventRounds]);

  if (isRfqEvent && eventRounds?.length > 1) return <td>{eventRound}</td>;
  return null;
}

export default EventRoundDetails;
