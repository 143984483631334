/**
 * Create an "enum-like" object from a list of strings.
 * @param args Possible values
 * @returns An object with each value as its own key
 *
 * @example
 * const Colors = makeEnum('red', 'green', 'blue');
 * type Colors = keyof typeof Colors;
 * Colors.red === 'red'; // true
 * const color: Colors = Colors.red; // OK
 * const color2: Colors = 'red'; // also OK
 *
 * @remarks
 * This is an alternative to Typescript's enums, which are nominimally typed.
 * ```ts
 * enum Colors {
 *  red = 'red',
 *  green = 'green',
 *  blue = 'blue',
 * }
 *
 * const color: Colors = 'red'; // Type '"red"' is not assignable to type 'Colors'
 * ```
 */
export function makeEnum<const T extends string>(...args: Array<T>) {
  const acc = {} as { [K in T]: K };
  for (const arg of args) {
    acc[arg] = arg;
  }
  return acc;
}
