import PropTypes from "prop-types";
import React from "react";

/**
 * Tab item wrapper for activity modal items.
 * @param {Object} props
 * @returns
 */
const TabButton = ({ className, text, active, onClick }) => {
  return (
    <li key={`activity-modal-tab-item-${text}`} className={`nav-item ${active ? "active" : ""}`}>
      <a href="#" className={className} onClick={onClick}>
        {text}
      </a>
    </li>
  );
};

TabButton.defaultProps = {
  className: "nav-link",
  text: "general",
  active: false,
};

TabButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default TabButton;
