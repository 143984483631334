/**
 * IE, SAFARI MOBILE ELEMENT REMOVE POLYFILL.
 *
 * Dev Notes:
 * Adds remove method to the element prototype if not exists
 */
((arr) => {
  arr.forEach((item) => {
    if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this);
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
