import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-advanced';
import cookies from 'browser-cookies';

import QQForm from './QQForm';
import { createQQEvent } from '../../../../actions/qq/qqActions';
import { fetchUserDetails, emptyCurrentState } from '../../../../actions/qq/genericActions';
import LoaderMessage from '../../LoaderMessage';
import { withAlert } from 'react-alert';
import isEmpty from "lodash/isEmpty";
import { notifyToastMsgQQ } from '../../js/common';

class QQCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qqForm: {},
      formSubmitting: false,
    };
    this.getRestField = this.getRestField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async UNSAFE_componentWillMount() {
    this.props.fetchUserDetails();
    const sessionId = cookies.get('auth_headers');
    if (!localStorage.getItem('sessionId') || localStorage.getItem('sessionId') !== sessionId) {
      localStorage.setItem('sessionId', sessionId);
      localStorage.setItem('formValue', JSON.stringify({
        QQGeneralDetails: {
          quote_name: '',
          associated_integration_attributes: {},
        },
        qqPrice: {
          event_category_rfq: false,
          lots: [],
        },
        qqQuestions: {
          questionnaire: [],
          with_questionnaires: false,
        },
      }));
      localStorage.setItem('restField', JSON.stringify({
        documents: [],
        editorValue: '',
        event_category_rfq: false,
        participantTags: [],
        with_questionnaires: false,
        deadline: '',
        participantGroups: [],
      }));
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    notifyToastMsgQQ(newProps, this.props.alert);
  }

  getRestField(restField) {
    const tempFormRest = this.state.qqForm;
    tempFormRest.restField = restField;
    this.setState({ qqForm: tempFormRest, formSubmitting: true });
    this.callFormSubmission();
  }

  parseJson(badJson) {
    return JSON.parse(JSON.stringify(badJson).replace(/"\s+|\s+"/g, '"'));
  }

  async callFormSubmission() {
    const qqData = this.state.qqForm;
    const lotAttributes = {};

    if (qqData.formValue.qqPrice && qqData.restField.event_category_rfq) {
      qqData.formValue.qqPrice.lots.forEach((item, idx) => {
        lotAttributes[idx] = item;
      });
    }

    const questions = {};

    if (qqData.formValue.qqQuestions && qqData.restField.with_questionnaires) {
      qqData.formValue.qqQuestions.questionnaire.forEach((item, idx) => {
        questions[idx] = item;
      });
    }

    const deadline = $('#deadline-custom-date-time').val() !== '' ? $('#deadline-custom-date-time').val() : undefined;

    const payload = {
      event: {},
    };

    let participantEmails = [];
    let participantGroupId;

    if (qqData.restField.participantTags && qqData.restField.participantTags.length > 0) {
      participantEmails = qqData.restField.participantTags;
    }

    if (qqData.restField.participantGroups && qqData.restField.participantGroups.length > 0) {
      qqData.restField.participantGroups.map((group) => {
        participantEmails = [
          ...participantEmails,
          ...(Array.from(group.selectedParticipants)),
        ];

        return group;
      });
    }

    payload.event = {
      name: qqData.formValue.QQGeneralDetails.quote_name,
      deadline,
      event_brief: qqData.restField.editorValue,
      documents: qqData.restField.documents,
      lot_attributes: lotAttributes,
      questions,
      participant_emails: participantEmails.join(),
      participant_group_ids: participantGroupId,
      event_category_rfq: qqData.restField.event_category_rfq,
      with_questionnaires: qqData.restField.with_questionnaires,
      quick_quotes: true,
      sandpit_event: cookies.get('sandpit') === 'true',
      budget: qqData.restField.associated_integration_attributes,
      return_event_details: true,
    };

    if(qqData.formValue.qqPrice.selected_currency && qqData.formValue.qqPrice.event_category_rfq) {
      payload.event.currency_id = qqData.formValue.qqPrice.selected_currency.value;
    }

    payload.event = this.parseJson(payload.event);
    const apiResponse = await this.props.createQQEvent(payload);
    if (apiResponse.status !== 201 || apiResponse.status !== 200) {
      this.setState({ formSubmitting: false });
    }
    if (this.props.quote.newQuote && Object.keys(this.props.quote.newQuote).length > 0) {
      window.location.pathname = `/quick_quotes/quote/${this.props.quote.newQuote.id}`;
      this.props.emptyCurrentState('CREATE_QQ_EVENT_SUCCESS', {});
      localStorage.clear();
    }
  }

  handleSubmit(values) {
    const tempFormRest = this.state.qqForm;
    tempFormRest.formValue = values;
    this.setState({ qqForm: tempFormRest });
  }

  render() {
    const { formSubmitting } = this.state;
    const user = this.props.user.details ? this.props.user.details : null;
    const { translations, tooltip_translations } = this.props;
    return (
      <div>
        <div className="top-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12 maincontent">
                {/* <React.Fragment> */}
                <Loader show={!user} message={<LoaderMessage loaderText={translations.loader_text} />} disableDefaultStyles priority={1}>
                  {user && (
                    <QQForm
                      user={user}
                      formSubmitting={formSubmitting}
                      onSubmit={this.handleSubmit}
                      getRestField={this.getRestField}
                      translations={translations}
                      tooltip_translations={tooltip_translations}
                      // {...this.props}
                    />
                  )}
                </Loader>
                {/* </React.Fragment> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  quote: state.qqReducer,
  user: state.userReducer,
  error: state.errorMessageReducer,
  success: state.successMessageReducer,
});

const mapDispatchToProps = dispatch => ({
  createQQEvent: qqEvent => dispatch(createQQEvent(qqEvent)),
  fetchUserDetails: () => dispatch(fetchUserDetails()),
  emptyCurrentState: (actionType, payload) => dispatch(emptyCurrentState(actionType, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(QQCreate));

