import React from "react";
import { useSelector } from "react-redux";
import isEqual from "lodash/isEqual";

import { isFormulaCalculationComponent } from "../../common";
import LotComponentModal from "./LotComponentModal";
import clsx from "clsx";
import { lotAutomationEnforcedAndNotSandpit } from "../lotCommon";
import { LotComponentDetailTag } from "./LotComponentDetailTag";

const LotComponentDetail = React.memo(
  (props) => {
    const {
      lot,
      lc,
      permittedToEdit,
      anyCompletedBids,
      translations,
      eventCurrency,
      currenciesHash,
      event,
      classFromEventState,
      currentUser,
      setLotComponentToBeDeleted,
      hideFromParticipantsSwitch,
    } = props;

    const lAEnforcedAndNotSandpit = useSelector(lotAutomationEnforcedAndNotSandpit);

    return (
      <th
        key={lc.id}
        id={`component_column_${lc.id}`}
        style={{ minWidth: "200px" }}
        className={clsx({
          "calculation-column": isFormulaCalculationComponent(lc) && lot.is_event_total,
          "calculation-column-cell": isFormulaCalculationComponent(lc) && !lot.is_event_total,
          thead: true,
        })}
      >
        <div className={`cell-number ${permittedToEdit ? " " : "cell-number2"}`}>
          {permittedToEdit ? (
            <>
              {!anyCompletedBids && <span className="lic-tag left">{lc.tag}</span>}
              {!anyCompletedBids && <LotComponentDetailTag lotComponent={lc} />}
              <LotComponentModal
                classes="component-name lot-component-content-loader push-5-r push-5-l"
                title={lc.name}
                translations={translations}
                eventCurrency={eventCurrency}
                lot={lot}
                lotComponent={lc}
                create={false}
                total={parseInt(lc.provider_cd) === 2}
                currenciesHash={currenciesHash}
                event={event}
                classFromEventState={classFromEventState}
                currentUser={currentUser}
                anyCompletedBids={anyCompletedBids}
                hideFromParticipantsSwitch={hideFromParticipantsSwitch}
              />
            </>
          ) : (
            <span className="component-name">{lc.name}</span>
          )}
          <div className={`lot-total-reference-wrapper pull-right`}>
            {Boolean(lc.line_item_total) && permittedToEdit && (
              <i
                title={`${anyCompletedBids ? translations.drag_icon_to_cell_to_make_line_item_total : ""}`}
                style={{ top: "20px" }}
                className="fa fa-star green-color lot-total-star"
                data-lot={lot.id}
                id="draggable-star"
              />
            )}
            {!(lc.line_item_total && permittedToEdit) && !lc.id && <i className="fa fa-spinner fa-spin green-color" />}
          </div>
          {permittedToEdit && !lAEnforcedAndNotSandpit && (
            <div className="icon-onhover icon-onhover-right" style={{ top: "15px" }} title={lc.name}>
              {!anyCompletedBids && (
                <button
                  className="edit hover opacity red-color"
                  data-toggle="modal"
                  data-target={`#lotComponentDeleteConfirmation-${lc.lot_id}`}
                  onClick={() => setLotComponentToBeDeleted(lc)}
                >
                  <i className="fa fa-trash" />
                </button>
              )}
            </div>
          )}
        </div>
      </th>
    );
  },
  (prevProps, nextProps) => {
    const { lc } = prevProps;

    switch (true) {
      case !isEqual(lc, nextProps.lc):
        return false;
      default:
        return true;
    }
  }
);

LotComponentDetail.displayName = "LotComponentDetail";
export default LotComponentDetail;
