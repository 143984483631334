import React from "react";
import { useSelector } from "react-redux";
import { auctionPending } from "../../eventCommon";
import useEvent from "../../hooks/useEvent";
import useLot from "../../hooks/useLot";

import PropTypes from "prop-types";
import { Lot } from "@/common-prop-types";

/**
 *
 * @param {PropTypes.InferProps<Lot>} lot
 * @param {boolean} bidDetailsAdded
 * @param {boolean} showLabel
 * @returns {string | null} lotStatus
 */
const useLotStatus = (lot, bidDetailsAdded, showLabel) => {
  const auctionTab = useSelector(({ lotReducers }) => lotReducers.auction_tab);
  const event = useSelector(({ lotReducers }) => lotReducers.event);

  const { isRejectedLot } = useLot(lot);
  const { isRankedMonitor, isEventCompleted, isEventPaused } = useEvent();
  const isEventTotal = Boolean(lot.is_event_total);
  const isAuctionPending = auctionPending(event);

  const event_total_status = isEventTotal && !(isAuctionPending && auctionTab);
  const lot_rejected = !isEventTotal && isRejectedLot;
  const bidding_has_not_started = isAuctionPending && auctionTab;
  const bid_detail_submitted = bidDetailsAdded;
  const bidding_closed = isRankedMonitor && isEventCompleted;
  const event_is_paused = isEventPaused;
  const event_pending_or_completed = !["running", "pause"].includes(event.current_state) || isEventCompleted;

  const statuses = [
    event_total_status && "event_total_status",
    lot_rejected && "lot_rejected",
    bidding_has_not_started && "bidding_has_not_started",
    bid_detail_submitted && "bid_detail_submitted",
    bidding_closed && "bidding_closed",
    event_is_paused && "event_is_paused",
    event_pending_or_completed && "event_pending_or_completed",
    showLabel && "bidding_closed",
  ];

  return statuses.find(Boolean) ?? null;
};

const LotStatusLabelPropTypes = {
  /**
   * check if showing label for Bidding Closed i.e bid.showLabel
   *
   * @type {boolean}
   */
  showLabel: PropTypes.bool,

  /**
   * lot for which status is being shown
   */
  lot: PropTypes.shape(Lot),

  /**
   * check if bid details are added
   *
   * @type {boolean}
   */
  bidDetailsAdded: PropTypes.bool,

  /**
   * bid price to be show for eventTotal
   *
   * @type {number | undefined}
   */
  totalBidPrice: PropTypes.number,

  /**
   * currency symbol to be show with bidPrice for eventTotal
   *
   * @type {string | undefined}
   */
  currencySymbol: PropTypes.string,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<LotStatusLabelPropTypes>>}
 * @returns {React.ReactElement}
 */
const LotStatusLabel = ({ showLabel, lot, bidDetailsAdded, currencySymbol, totalBidPrice }) => {
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);

  // calculate lotStatus
  const lotStatus = useLotStatus(lot, bidDetailsAdded, showLabel);

  if (!lotStatus || lotStatus === "event_pending_or_completed") return null;
  if (lotStatus === "event_total_status")
    return (
      <span className="bid-status solid-box blue-box">
        {translations.your_event_total}: &nbsp;
        {totalBidPrice ? currencySymbol : "-"}
        {totalBidPrice}
      </span>
    );
  return <span className="bid-status reject-lot pull-right solid-box blue-box">{translations[lotStatus]}</span>;
};

LotStatusLabel.propTypes = LotStatusLabelPropTypes;
LotStatusLabel.defaultProps = {};

export default LotStatusLabel;
