import React, { Component } from "react";
import AutocompleteFormulaField from "./AutocompleteFormulaField";
import { splitFormula, tagClasses } from "../../common";

export class DropFieldForTags extends Component {
  // The user can drag and drop elements either in the formula field or somewhere
  // else in the page, so to make sure that all the tags dropped outside the formula
  // field are removed from the formula we remove this class from the tags as we
  // have another drop event specified in LotsContainer component which checks
  // for this class
  drop(e) {
    e.preventDefault();
    const element = document.getElementsByClassName("grey-tag");
    if (element.length) {
      element[0].classList.remove("grey-tag");
    }
  }

  render() {
    const {
      formula,
      handleFormulaChange,
      handleAutocompleteFormulaSave,
      filteredLicsForFormula,
      dragEnd,
      dragOver,
      dragStart,
      lotTotalLicTags,
      isTagAutocompleteDisabled,
    } = this.props;
    return (
      // This key here plays a very important role, we append the dragged elements
      // using Javascript instead of React so whenever we have a situation where
      // we need to merge two tags (two numbers placed near by, so they are converted to one)
      // so that should happen through React, without this key the DOM is updated
      // but the element appended using Javascript is not removed because it is not
      // present in React's virtual DOM. So this key re renders the whole component
      // when the formula changes resulting the DOM update and removal of tags added
      // by Javascript.
      <div id="formula" key={formula} onDrop={this.drop} onDragOver={dragOver}>
        {splitFormula(formula).map(function (tag, i) {
          return (
            <span
              className={tagClasses(tag, lotTotalLicTags)}
              key={`${i}_${tag}`}
              data-name="formula"
              data-index={i}
              draggable={!isTagAutocompleteDisabled}
              onDragEnd={dragEnd}
              onDragEnter={dragOver}
              onDragStart={dragStart}
              data-effect="move"
            >
              {tag}
            </span>
          );
        })}
        <AutocompleteFormulaField
          handleFormulaChange={handleFormulaChange}
          handleAutocompleteFormulaSave={handleAutocompleteFormulaSave}
          filteredLicsForFormula={filteredLicsForFormula}
          inputProps={{ disabled: isTagAutocompleteDisabled }}
        />
      </div>
    );
  }
}

export default DropFieldForTags;
