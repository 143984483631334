import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import type { TemplateIdentifier } from "@/slices/lots/templates";
import {
  ruleModalClosed,
  ruleModalOpened,
  rulesReset,
  selectDenormalizedRules,
  selectRootId,
  selectValid,
  validateCurrentRules,
} from "@/slices/rules";
import { fieldAdapter, selectFieldEntities } from "@/slices/rules/fields";
import type { GetRulesAndFieldsResponse, UpdateRulesRequest } from "@/slices/rules/types";
import { Button } from "@/cl/button";
import { getStore, type AppDispatch, type AppThunk } from "@/store";
import { assert } from "@/utils/assert";
import styles from "./modal.module.scss";
import { NodeEditor } from "@/components/rules/node-editor";
import type { TypedUseMutation, TypedUseQuery, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { t } from "@/i18n";
import Wrapper from "@/components/Wrapper";

const saveRules =
  (
    currentTemplate: TemplateIdentifier,
    ruleId: number | null,
    updateLotRules: (arg: UpdateRulesRequest) => { unwrap: () => Promise<unknown> }
  ): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    // if we don't have a root ID, the rules have been deleted - just send a blank request
    const rules = selectRootId(state) !== null ? selectDenormalizedRules(state) : undefined;
    // save the rules
    await updateLotRules({ ...currentTemplate, ruleId: ruleId ?? undefined, rules }).unwrap();
  };

const validateAndSaveRules =
  (
    currentTemplate: TemplateIdentifier,
    updateLotRules: (arg: UpdateRulesRequest) => { unwrap: () => Promise<unknown> },
    closeDialog: () => void,
    selectorFactory: GetSelectorFactory
  ): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { data } = selectorFactory(currentTemplate)(getState());
    assert(data, "No data available for the current template");

    const fields = selectFieldEntities(data.available_fields);

    const valid = dispatch(validateCurrentRules(fields));
    if (valid) {
      await dispatch(saveRules(currentTemplate, data.rule_id, updateLotRules));
      closeDialog();
    }
  };

function makeUseSyncRules(useGetRulesAndFieldsQuery: UseGetRulesAndFieldsQuery) {
  return function useSyncRules(currentTemplate: TemplateIdentifier | null, dispatch: AppDispatch) {
    // fetch the rules for the current template
    const { savedRules, isSuccess, isFetching, fields } = useGetRulesAndFieldsQuery(currentTemplate ?? skipToken, {
      selectFromResult: ({ data, isSuccess, isFetching }) => ({
        savedRules: data?.existing_rule,
        fields: data?.available_fields ?? fieldAdapter.getInitialState(),
        isSuccess,
        isFetching,
      }),
    });
    useEffect(() => {
      if (isSuccess) {
        // if we've just fetched the rules, make sure the slice is up to date
        dispatch(ruleModalOpened(savedRules ?? null));
      }
    }, [savedRules, dispatch, isSuccess]);
    return { isFetching, fields };
  };
}

type UseGetRulesAndFieldsQuery = TypedUseQuery<
  GetRulesAndFieldsResponse,
  TemplateIdentifier,
  ReturnType<typeof fetchBaseQuery>
>;

// annoyingly we have to `any` state here - the state type for injected versions of the API is different
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GetSelectorFactory = (arg: TemplateIdentifier) => (state: any) => { data?: GetRulesAndFieldsResponse | undefined };

interface AutoselectionModalProps extends TemplateIdentifier {
  open: boolean;
  onClose: () => void;
  templateName: string;
}

interface BuildAutoselectionModalOptions {
  getEndpoint: {
    useQuery: UseGetRulesAndFieldsQuery;
    select: GetSelectorFactory;
  };
  updateEndpoint: {
    useMutation: TypedUseMutation<void, UpdateRulesRequest, ReturnType<typeof fetchBaseQuery>>;
  };
}

function buildAutoselectionModal({ getEndpoint, updateEndpoint }: BuildAutoselectionModalOptions) {
  const useSyncRules = makeUseSyncRules(getEndpoint.useQuery);
  return function AutoselectionModal({ companyId, templateId, templateName, open, onClose }: AutoselectionModalProps) {
    const dispatch = useAppDispatch();
    const { fields, isFetching } = useSyncRules(open ? { companyId, templateId } : null, dispatch);
    const rootId = useAppSelector(selectRootId);
    const valid = useAppSelector(selectValid);
    const [updateLotRules] = updateEndpoint.useMutation({ selectFromResult: () => ({}) });
    const handleClose = () => {
      dispatch(ruleModalClosed());
      onClose();
    };
    return (
      <Modal
        isOpen={!!open}
        onRequestClose={handleClose}
        className={styles.modal}
        appElement={document.getElementById("page-container") ?? undefined}
        portalClassName="md-bs5"
      >
        <div className={styles.title}>{templateName}</div>
        <NodeEditor loading={isFetching} {...{ fields, rootId }} />
        <div className={styles.actions}>
          <Button brand="outlined-primary" onClick={() => dispatch(rulesReset())} disabled={rootId === null}>
            {t("reset")}
          </Button>
          <Button brand="outlined-primary" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            brand="primary"
            onClick={() =>
              void dispatch(
                validateAndSaveRules({ companyId, templateId }, updateLotRules, handleClose, getEndpoint.select)
              )
            }
            disabled={!valid}
            spinning={isFetching}
          >
            {t("save_changes")}
          </Button>
        </div>
      </Modal>
    );
  };
}

export interface WorkflowRulesProps extends TemplateIdentifier {
  ruleAttached: boolean;
  templateName: string;
}

function buildAutoselectionButton({ getEndpoint }: BuildAutoselectionModalOptions) {
  return function AutoselectionButton({
    companyId,
    templateId,
    ruleAttached,
    onClick,
  }: WorkflowRulesProps & { onClick: () => void }) {
    const { hasFetchedRules, isLoading } = getEndpoint.useQuery(
      { companyId, templateId },
      {
        selectFromResult: ({ data, isLoading }) => ({
          hasFetchedRules: !!data?.existing_rule,
          isLoading,
        }),
      }
    );
    const hasRules = isLoading ? ruleAttached : hasFetchedRules;
    return (
      <div className="md-bs5">
        <Button size="sm" brand="primary" onClick={onClick}>
          {t(hasRules ? "edit_auto_selection" : "create_auto_selection", { ns: "sim_dojo" })}
        </Button>
      </div>
    );
  };
}

/**
 * Create a root rules modal component based on the provided endpoints.
 * This avoids passing hooks as props, which would break the rules of hooks.
 *
 * The alternative would be a HOC:
 *
 * ```ts
 * const AutoSelectionModal = withRuleEndpoints({ getEndpoint, updateEndpoint })(BaseAutoselectionModal);
 * ```
 *
 * but this is more verbose for no real gain.
 */
export function buildRulesModalRoot(options: BuildAutoselectionModalOptions) {
  const AutoselectionModal = buildAutoselectionModal(options);
  const AutoselectionButton = buildAutoselectionButton(options);
  return function WorkflowRules(props: WorkflowRulesProps) {
    const [modalOpen, setModalOpen] = useState(false);
    return (
      <Wrapper store={getStore()}>
        <AutoselectionButton {...props} onClick={() => setModalOpen(true)} />
        <AutoselectionModal {...props} open={modalOpen} onClose={() => setModalOpen(false)} />
      </Wrapper>
    );
  };
}
