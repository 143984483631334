import React, { Component } from "react";
import ExpiredDocuments from "./ExpiredDocuments";
import InactiveParticipants from "./InactiveParticipants";
import NotifyScorersAndPendingApprovals from "./NotifyScorersAndPendingApprovals";
import NotificationContact from "./NotificationContact";
import EnforceLocales from "./EnforceLocales";
import DocumentLinkLocation from "./DocumentLinkLocation";
import SimWorkFlow from "./SimWorkFlow";
import ParticipantProperties from "./ParticipantProperties";
import { simSettingConfiguration, unauthorized, EnfoceLocaleUpdate } from "../../../../actions/SimSettingActions";
import { connect } from "react-redux";
import ExpiredQA from "./ExpiredQA";
import SettingsContainer from "./settings-container";
import features from "@/utils/features";

class SimDojoSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        configuration_setting_values: props.configuration_setting_values,
        enforce_locales: props.enforce_locales,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { settingsData } = nextProps;
    let { data } = prevState;
    if (settingsData && settingsData.configuration_setting_values) {
      data.configuration_setting_values = settingsData.configuration_setting_values;
    }
    if (settingsData && settingsData.enforce_locales) {
      data.enforce_locales = settingsData.enforce_locales;
    }
    return {
      data,
    };
  }

  onUpdateValue = (dataValue, name) => {
    const {
      translations,
      SettingType,
      configuration_setting_enum_values,
      configuration_setting_values,
      company: { id },
      currentUserEditConfigPermission,
    } = this.props;
    if (!currentUserEditConfigPermission) {
      this.props.unauthorized({
        configuration_setting_values: configuration_setting_values,
        unauthorized: translations.unauthorized,
      });
      return false;
    }

    let data = [
      id,
      {
        configuration_setting: {
          setting: JSON.stringify([
            {
              company_id: id,
              setting_type_cd: [
                "notification_sender",
                "notification_recipient",
                "document_link_location",
                "approved_supplier_form",
              ].includes(name)
                ? 2
                : this.checkBooleanSettingType(name, SettingType),
              setting_cd: configuration_setting_enum_values[name],
              value: dataValue,
            },
          ]),
        },
      },
    ];
    this.props.simSettingConfiguration(data, id);
  };

  setting_values = [
    "require_unexpired_docs_for_onboarding",
    "allow_simplified_expired_document_process",
    "invited_date",
    "onboarded_date",
    "last_submitted_date",
  ];
  checkBooleanSettingType = (name, SettingType) => {
    return this.setting_values.includes(name) ? 4 : SettingType;
  };

  onUpdateEnforceLocale = (dataValue) => {
    const {
      configuration_setting_values,
      currentUserEditConfigPermission,
      translations,
      company: { id },
    } = this.props;
    let data = [id, dataValue];
    if (!currentUserEditConfigPermission) {
      this.props.unauthorized({
        configuration_setting_values: configuration_setting_values,
        unauthorized: translations.unauthorized,
      });
    } else {
      this.props.EnfoceLocaleUpdate(data);
    }
  };

  render() {
    const {
      translations,
      durationType,
      company,
      tagUiFeatureSwitch,
      currentUserEditConfigPermission,
      tag_categories,
    } = this.props;
    let { data } = this.state;
    let canEditParticipantTagging;
    const btnClass = "btn btn-purple shadow-light";
    if (tagUiFeatureSwitch != null && tagUiFeatureSwitch.action_cd == 1) {
      canEditParticipantTagging = true;
    } else {
      canEditParticipantTagging = false;
    }

    const SETTING_NAMES = {
      documentTabAccess: ["hide_documents_tab_from_participants"],
      additionalList: ["invited_date", "onboarded_date", "last_submitted_date"],
    };

    if (features.enabled("sim_list_percentage")) {
      SETTING_NAMES["additionalList"].push("questionnaire_completion_percentage");
    }

    return (
      <>
        <h2 className="main-heading">{translations.admin_views.configuration.sim_time_scales}</h2>
        <div className="configuration_fieldset">
          <ExpiredDocuments
            translations={translations}
            durationType={durationType}
            configuration_setting_values={data.configuration_setting_values}
            company={company}
            onValueChange={(dataValue, name) => this.onUpdateValue(dataValue, name)}
            currentUserEditConfigPermission={currentUserEditConfigPermission}
          />
          <InactiveParticipants
            translations={translations}
            durationType={durationType}
            configuration_setting_values={data.configuration_setting_values}
            onValueChange={(dataValue, name) => this.onUpdateValue(dataValue, name)}
            currentUserEditConfigPermission={currentUserEditConfigPermission}
          />
          <ExpiredQA
            translations={translations}
            durationType={durationType}
            configurationSettingValues={data.configuration_setting_values}
            onValueChange={(dataValue, name) => this.onUpdateValue(dataValue, name)}
            currentUserEditConfigPermission={currentUserEditConfigPermission}
          />
          <NotifyScorersAndPendingApprovals
            translations={translations}
            durationType={durationType}
            configuration_setting_values={data.configuration_setting_values}
            onValueChange={(dataValue, name) => this.onUpdateValue(dataValue, name)}
            currentUserEditConfigPermission={currentUserEditConfigPermission}
          />
          <SimWorkFlow
            translations={translations}
            configuration_setting_values={data.configuration_setting_values}
            company={company}
            onValueChange={(dataValue, name) => this.onUpdateValue(dataValue, name)}
            currentUserEditConfigPermission={currentUserEditConfigPermission}
          />
          <div className="col-md-6 form-item-block wid-49 pull-right">
            <SettingsContainer
              title="document_tab_access"
              settingsList={SETTING_NAMES.documentTabAccess}
              settingValues={data.configuration_setting_values}
              permittedToEdit={currentUserEditConfigPermission}
              onChange={this.onUpdateValue}
            />
          </div>
          <NotificationContact
            translations={translations}
            durationType={durationType}
            configuration_setting_values={data.configuration_setting_values}
            onValueChange={(dataValue, name) => this.onUpdateValue(dataValue, name)}
            currentUserEditConfigPermission={currentUserEditConfigPermission}
          />
          <EnforceLocales
            translations={translations}
            enforce_locale={data.enforce_locales}
            onValueChange={(dataValue) => this.onUpdateEnforceLocale(dataValue)}
          />
          <div className="col-md-6 form-item-block wid-49 pull-left">
            <SettingsContainer
              title="additional_list_view_fields"
              settingsList={SETTING_NAMES.additionalList}
              settingValues={data.configuration_setting_values}
              permittedToEdit={currentUserEditConfigPermission}
              onChange={this.onUpdateValue}
            />
          </div>
          <DocumentLinkLocation
            translations={translations}
            durationType={durationType}
            configuration_setting_values={data.configuration_setting_values}
            company={company}
            onValueChange={(dataValue, name) => this.onUpdateValue(dataValue, name)}
            currentUserEditConfigPermission={currentUserEditConfigPermission}
          />
          {tagUiFeatureSwitch != null && (
            <ParticipantProperties
              translations={translations}
              company={company}
              tag_categories={tag_categories}
              canEditParticipantTagging={canEditParticipantTagging}
              title={translations.participant_properties}
              tooltipText={translations.setting_page_participant_properties}
              moduleCd={1}
              btnClass={btnClass}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  settingsData: state.simSettingReducers,
});

const mapDispatchToProps = (dispatch) => ({
  simSettingConfiguration: (data) => dispatch(simSettingConfiguration(data)),
  unauthorized: (data) => dispatch(unauthorized(data)),
  EnfoceLocaleUpdate: (data) => dispatch(EnfoceLocaleUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimDojoSettings);
