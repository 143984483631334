import React from "react";
import { showLocalisedStrippedDecimal, companyNameOrEmail, findUser } from "../../../common";

const TextBlicRowDetails = (props) => {
  const { blic, user, formatDatetime, componentType, idx, timeZoneIdentifier, bid, translations, lic } = props;
  const getcomponentTypeTd = () => {
    let result = null;
    switch (componentType(lic.lot_component_type)) {
      case "isText":
      case "isPicklist":
        result = blic.attribute_value;
        break;
      case "isDate":
        if (blic.date_value) {
          result = formatDatetime(blic.date_value, timeZoneIdentifier);
        }
        break;
      case "isDecimal":
        if (blic.price) {
          result = showLocalisedStrippedDecimal(blic.price);
        }
        break;
      default:
        result = null;
    }

    return result;
  };
  const host = bid.host_id && findUser(props.hosts, bid.host_id);
  return (
    <tr key={idx} className={blic.rank ? "ranked" : "unranked"} rel={blic.bid_id}>
      <td> {companyNameOrEmail(user)}</td>
      <td>
        {getcomponentTypeTd()}
        {host ? (
          <span className="proxy_bid" title={`${translations.proxy_bid} ${host.name}`}>
            P
          </span>
        ) : null}
      </td>
      <td> {formatDatetime(bid.created_at, timeZoneIdentifier)}</td>
    </tr>
  );
};

export default TextBlicRowDetails;
