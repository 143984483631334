import React, { lazy, Suspense } from "react";
import JapaneseMonitor from "../JapaneseMonitor";
import Header from "./Header";

const BidTemplateDropdown = lazy(() => import("../../bids/BidTemplateDropdown"));
const JapaneseEvent = (props) => {
  return (
    <>
      <br />
      <div className="container m-b10">
        <div className="row">
          <div className="col-md-12">
            {!props.event.bid_at_detail_level && props.isEventCompleted && props.activeBids.length > 0 && (
              <Suspense fallback={<div></div>}>
                <BidTemplateDropdown
                  translations={props.translations}
                  role={props.role}
                  event={props.event}
                  eventParticipant={props.event_participant}
                  setLotStateCb={props.setLotStateCb}
                  lots={props.lots}
                />
              </Suspense>
            )}
          </div>
        </div>
      </div>
      <div className="v2 event-current" id="lot-table-box">
        <div className="clear-padding simple-box">
          <div
            className={`${props.event?.any_complex_lots_exist ? "event-list" : ""} responsive-table-container clearfix`}
          >
            <div className="table-top japanese-auction-mc">
              <Header {...props} />
              {props.lots.map((lot) => {
                const LBids = props.bids.filter((b) => b.lot_id === lot.id);
                return (
                  <JapaneseMonitor
                    key={lot.id}
                    {...props}
                    lot={lot}
                    currency={props.event_currency}
                    LBids={LBids}
                    lotComponents={props.lot_components}
                    lineItems={props.line_items}
                    lineItemComponents={props.line_item_components}
                    licCurrency={props.event_currency}
                    currentUser={props.current_user}
                    setLotStateCb={props.setLotStateCb}
                    unitOfMeasures={props.unit_of_measures}
                    unitSets={props.unit_sets}
                    isAuction={true}
                    auctionTab={props.auction_tab}
                    auctionControls={props.auction_controls}
                    isRankedMonitor={props.isRankedMonitor}
                    lots={props.lots}
                    remainingParticipants={props.remaining_participants_for_japanese_event}
                    bidControls={props.bid_controls}
                    isEventCompleted={props.isEventCompleted}
                    unitSetUoms={props.unit_set_unit_of_measures}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JapaneseEvent;
