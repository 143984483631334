import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LotChecklist from "./LotChecklist";
import AddLotComponentButton from "./lot_component/AddLotComponentButton";
import AddLineItemButton from "./line_item/AddLineItemButton";
import CellsContainer from "./CellsContainer";
import {
  lotLineItems,
  lotBids,
  hasCalculationRowAdded,
  hasAnyCompletedBids,
  lotLotComponents,
  lotHasParticipantPrice,
  lotLineItemComponents,
} from "../common";
import LotModal from "./LotModal";
import { deleteLot } from "../../../actions/lotActions";
import { getUpdatedValue, lotAutomationEnforcedAndNotSandpit } from "./lotCommon";
import { confirm } from "@/utils/confirm";
import { t } from "@/i18n";

const LotContainer = (props) => {
  const dispatch = useDispatch();
  const lAEnforcedAndNotSandpit = useSelector(lotAutomationEnforcedAndNotSandpit);

  function dragStart(e) {
    if (!lot.is_event_total) {
      const target = e.target;
      if (target.getAttribute("data-name") === "lot") {
        e.dataTransfer.setData("text", "");
        props.updateDraggedLotId(e.target.id);
      }
    }
  }

  const {
    lot,
    lineItems,
    bids,
    lots,
    lotComponents,
    lineItemComponents,
    classFromEventState,
    translations,
    isJapaneseEvent,
    weightedRfqOrAuction,
    maxNumberOfRankedLics,
    maxNumberOfCellsPerEvent,
    maxNumberOfCellsForBidAtDetailLevel,
    permittedToEdit,
    eventCurrency,
    event,
    maxPrecisionFor,
    currenciesHash,
    allowedLotType,
    openOrJapanese,
    locale,
    currentUser,
    reRenderDetails,
    timeZoneIdentifier,
    isMultiCurrencyEvent,
    hideFromParticipantsSwitch,
  } = props;

  const lineItemReducers = useSelector((state) => state.lineItemReducers);
  const { lineItem, actionType } = lineItemReducers;

  const [lotLis, setLotLis] = useState(lotLineItems(lot, lineItems));
  const [lotLcs, setLotLcs] = useState(lotLotComponents(lot, lotComponents));
  const [lotLics, setLotLics] = useState(lotLineItemComponents(lotLcs, lineItemComponents));
  const [participantEnteredPrice, setParticipantEnteredPrice] = useState(lotHasParticipantPrice(lotLics));

  const lBids = lotBids(lot.id, bids);
  const anyCompletedBids = hasAnyCompletedBids(lBids);

  useEffect(() => {
    switch (actionType) {
      case "UPDATE_LINE_ITEM": {
        if (lot.id === lineItem.lot_id) {
          setLotLis(getUpdatedValue(lotLis, lineItem));
        }
        break;
      }
      case "CREATE_LINE_ITEM": {
        if (lot.id === lineItem.lot_id) {
          setLotLis(getUpdatedValue(lotLis, lineItem));
          setLotLics([...lotLics, ...lineItemReducers.lineItemComponents]);
        }
        break;
      }
    }
  }, [lineItemReducers]);

  useEffect(() => {
    setLotLis(lotLineItems(lot, lineItems));
  }, [lineItems]);

  useEffect(() => {
    setLotLcs(lotLotComponents(lot, lotComponents));
  }, [lotComponents]);

  useEffect(() => {
    setLotLics(lotLineItemComponents(lotLcs, lineItemComponents));
  }, [lotLcs, lineItemComponents]);

  useEffect(() => {
    setParticipantEnteredPrice(lotHasParticipantPrice(lotLics));
  }, [lotLics]);

  return (
    <div
      id={`item_${lot.id}`}
      data-name="lot"
      data-id={lot.id}
      className="complex-lot m-t10 col-md-12 lot-container m-b20"
      draggable={!lot.is_event_total}
      onDragStart={dragStart}
    >
      <div className="col-md-12 p-0">
        <div id={"matrix_area_" + lot.id} className="matrix-area line-items-closed shadow-diffuse">
          <div className={`box ${lot.is_event_total ? " is_event_total" : ""}`}>
            <div
              className={`box-header ${
                lot.is_event_total ? "unsortable" : "sort-handle"
              } complex-lot-header gradient-gray`}
            >
              <div className="clearfix">
                <div className="col-md-12 col-sm-12 col-xs-12 xs-full-width p-l0">
                  <div
                    id={`position_of_${lot.id}`}
                    className="btn-circle float-left gradient-blue m-r10 m-t5 white-text"
                  >
                    {lot.position}
                  </div>
                  <div className="pull-left m-r15">
                  <span className="header-text m-r5">
                    {lot.name}
                    {lot.permit_partial_bids && (
                      <div className="bg-default fs-12 label label-info m-l10"> {t("partial_bidding")} </div>
                    )}
                  </span>
                    {permittedToEdit && !lAEnforcedAndNotSandpit && (
                      <span className="button-wrap m-l10">
                        <LotModal
                          translations={translations}
                          eventId={event.id}
                          lot={lot}
                          allowedLotType={allowedLotType}
                          lots={lots}
                          partialBiddingSwitch={props.partialBiddingSwitch}
                          anyCompletedBids={props.anyCompletedBids}
                        />
                        <button
                          title={t("delete_lot")}
                          className="btn btn-default"
                          onClick={() => {
                            confirm({ body: translations.are_you_sure }).accept(() => {
                              dispatch(deleteLot(lot));
                            });
                          }}
                        >
                          <i className="fa fa-trash-o color-purpel" />
                        </button>
                      </span>
                    )}
                  </div>
                  {permittedToEdit && !anyCompletedBids && !lAEnforcedAndNotSandpit && (
                    <AddLotComponentButton
                      lot={lot}
                      translations={translations}
                      classFromEventState={classFromEventState}
                      lotLcs={lotLcs}
                      eventCurrency={eventCurrency}
                      currenciesHash={currenciesHash}
                      event={event}
                      currentUser={currentUser}
                      hideFromParticipantsSwitch={hideFromParticipantsSwitch}
                    />
                  )}
                  {permittedToEdit && !lAEnforcedAndNotSandpit && (
                    <AddLineItemButton
                      lot={lot}
                      translations={translations}
                      lotLis={lotLis}
                      lBids={lBids}
                      anyCompletedBids={anyCompletedBids}
                      classFromEventState={classFromEventState}
                      hideFromParticipantsSwitch={hideFromParticipantsSwitch}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="complex-lot-body">
              {!lAEnforcedAndNotSandpit && (
                <LotChecklist
                  lineItemComponents={lineItemComponents}
                  lot={lot}
                  lotLis={lotLis}
                  lotLcs={lotLcs}
                  lotLics={lotLics}
                  participantEnteredPrice={participantEnteredPrice}
                  anyCompletedBids={anyCompletedBids}
                  classFromEventState={classFromEventState}
                  translations={translations}
                  isJapaneseEvent={isJapaneseEvent}
                  weightedRfqOrAuction={weightedRfqOrAuction}
                  maxNumberOfCellsForBidAtDetailLevel={maxNumberOfCellsForBidAtDetailLevel}
                  maxNumberOfRankedLics={maxNumberOfRankedLics}
                  maxNumberOfCellsPerEvent={maxNumberOfCellsPerEvent}
                />
              )}
              <div className="box-body fixed-column-table relative clearfix">
                <div className={`${hasCalculationRowAdded(lotLis) || anyCompletedBids ? "lic_calculation_added" : ""}`}>
                  <CellsContainer
                    lot={lot}
                    translations={translations}
                    lotLis={lotLis}
                    lotLics={lotLics}
                    lotLcs={lotLcs}
                    classFromEventState={classFromEventState}
                    anyCompletedBids={anyCompletedBids}
                    permittedToEdit={permittedToEdit}
                    eventCurrency={eventCurrency}
                    currenciesHash={currenciesHash}
                    maxPrecisionFor={maxPrecisionFor}
                    event={event}
                    lots={lots}
                    lineItemComponents={lineItemComponents}
                    openOrJapanese={openOrJapanese}
                    isJapaneseEvent={isJapaneseEvent}
                    locale={locale}
                    reRenderDetails={reRenderDetails}
                    currentUser={currentUser}
                    timeZoneIdentifier={timeZoneIdentifier}
                    isMultiCurrencyEvent={isMultiCurrencyEvent}
                    hideFromParticipantsSwitch={hideFromParticipantsSwitch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LotContainer;
