import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import {
  commonModalSubmitRequestCompleted,
  dispatchModalSubmitRequestCompleted,
  sendCommonModalSubmitRequestFailure,
} from "../hoc/modalHOC/modalHOC.duck";
import { getError } from "./errorActions";
import moment from "moment";
import { withErrorDispatch } from "./customField";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createCableThunk } from "@/lib/stream";

const createLotSuccess = (data) => {
  return {
    type: actionTypes.CREATE_UPDATE_LOT,
    payload: data.lot,
  };
};

const updateLotSuccess = (data) => {
  return {
    type: actionTypes.CREATE_UPDATE_LOT,
    payload: data.lot,
  };
};

const getBidsSuccess = (data) => {
  return {
    type: actionTypes.GET_LATEST_BID,
    payload: data,
  };
};

const getEditableLot = (data) => {
  window.location.reload();
};

export const showSuccess = (successMessage) => {
  if (successMessage === undefined || !(successMessage.length > 1)) {
    return { type: "NA" };
  } else {
    return { type: actionTypes.SUCCESS, success: successMessage };
  }
};

const uploadTemplateSuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_LOT_TEMPLATE,
    payload: {
      lineItemComponents: data.line_item_components,
      lineItems: data.line_items,
    },
  };
};

const updateAuctionTypeSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_EVENT,
    payload: { event: data.event },
  };
};

const setInitialPropsSuccess = (props) => {
  return {
    type: actionTypes.SET_LOT_INITIAL_PROPS,
    payload: props,
  };
};

const deleteLotSuccess = (data) => {
  return {
    type: actionTypes.DELETE_LOT,
    payload: {
      lots: data.lots,
      lineItems: data.line_items,
      lotComponents: data.lot_components,
      lineItemComponents: data.line_item_components,
    },
  };
};

const updateUOMSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_UOM_SUCCESS,
    payload: data,
  };
};

const setLotTotalSuccess = (data) => {
  return {
    type: actionTypes.SET_LOT_TOTAL,
    payload: {
      lineItemComponents: data.line_item_components,
      lot: data.lot,
      updateReferenceMessage: data.update_reference_message,
    },
  };
};

export const createLot = (eventId, payload) => (dispatch) =>
  apiCall
    .post(`${config.V2_BASE_URL}/events/${eventId}/lots`, payload)
    .then((response) => dispatch(createLotSuccess(response.data)))
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const addNewUom = (unitSetId, payload) => (dispatch) =>
  apiCall
    .post(`${config.V2_BASE_URL}/unit_sets/${unitSetId}/unit_of_measures`, payload)
    .then((response) => {
      dispatch(updateUOMSuccess(response.data));
      dispatch(commonModalSubmitRequestCompleted());
      dispatch(showSuccess(response.data.message));
    })
    .catch((error) => {
      dispatch(sendCommonModalSubmitRequestFailure());
      dispatch(getError(error.response));
    });

export const editUom = (unitSetId, uomId, payload) => (dispatch) =>
  apiCall
    .put(`${config.V2_BASE_URL}/unit_sets/${unitSetId}/unit_of_measures/${uomId}`, payload)
    .then((response) => {
      dispatch(updateUOMSuccess(response.data));
      dispatch(commonModalSubmitRequestCompleted());
      dispatch(showSuccess(response.data.message));
    })
    .catch((error) => {
      dispatch(sendCommonModalSubmitRequestFailure());
      dispatch(getError(error.response));
    });

export const updateLot = (eventId, lotId, payload) => (dispatch) =>
  apiCall
    .put(`${config.V2_BASE_URL}/events/${eventId}/lots/${lotId}`, payload)
    .then((response) => {
      dispatch(updateLotSuccess(response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

/**
 * @typedef {{
 *   line_item_components: Array<import("@/common-prop-types/line-item-component").LineItemComponent>;
 *   line_items: Array<import("@/common-prop-types/line-item").LineItem>;
 *   lot_components: Array<import("@/common-prop-types/lot-component").LotComponent>;
 *   lots: Array<import("@/common-prop-types/lot").Lot>;
 * }} RegenerateLotsResult
 */

/**
 * @type {import("@/lib/stream").CableThunk<{ type: string; progress: number }, RegenerateLotsResult, number>}
 */
export const regenerateLots = createCableThunk("lots/regenerateLots", (eventId) => ({
  url: `${config.V2_BASE_URL}/events/${eventId}/lot_autoselection`,
  method: "POST",
}));

export const deleteLot = (lot) => (dispatch) =>
  apiCall
    .remove(`${config.V2_BASE_URL}/events/${lot.event_id}/lots/${lot.id}`)
    .then((response) => {
      dispatch(deleteLotSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const uploadTemplate = (payload, eventId) => (dispatch) =>
  apiCall.post(`${config.V2_BASE_URL}/events/${eventId}/lots/upload`, payload).then((response) => {
    dispatch(uploadTemplateSuccess(response.data));
    dispatch(showSuccess(response.data.message));
    return response.data;
  });

export const updateAuctionType = (payload, eventId) => (dispatch) =>
  apiCall
    .put(`${config.V2_BASE_URL}/events/${eventId}`, payload)
    .then((response) => {
      dispatch(updateAuctionTypeSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const sortLot = (eventId, payload) => (dispatch) =>
  apiCall
    .post(`${config.V2_BASE_URL}/events/${eventId}/lots/sort`, payload)
    .then((response) => {
      dispatch(deleteLotSuccess(response.data));
      return response.status;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const setLotTotal = (eventId, lotId, payload) => (dispatch) =>
  apiCall
    .post(`${config.V2_BASE_URL}/events/${eventId}/lots/${lotId}/lot_total_reference`, payload)
    .then((response) => {
      dispatch(setLotTotalSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const updateLotDataOnBid = (eventId) => (dispatch) =>
  apiCall
    .get(`${config.V2_BASE_URL}/events/${eventId}/lots/update_remote/`)
    .then((response) => {
      dispatch(getBidsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const reqLotEdit = (eventId) => (dispatch) =>
  apiCall
    .post(`${config.V2_BASE_URL}/events/${eventId}/return_to_editing/`)
    .then((response) => {
      dispatch(getEditableLot(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const setInitialProps = (props) => (dispatch) => {
  dispatch(setInitialPropsSuccess(props));
};

const updateAwardLotSuccess = (data) => ({
  type: actionTypes.UPDATE_AWARD_LOT,
  payload: data,
});

const updateAwardLotsSuccess = (data) => ({
  type: actionTypes.UPDATE_AWARD_LOTS,
  payload: data,
});

const sendRequest = (key) => ({
  type: actionTypes.LOT_LOADER_REQUESTED,
  payload: key,
});

const requestCompleted = (data) => ({
  type: actionTypes.FINISH_LOT_LOADER_REQUEST,
  payload: data,
});

export const updateAwardLots = (eventID, lotId, partId) => (dispatch) => {
  const requestKey = `${lotId}_updateAwardLotsRequested`;
  dispatch(sendRequest(requestKey));
  apiCall
    .post(
      `${config.V2_BASE_URL}/events/${eventID}/lots/change_lot_details_with_participant?id=${lotId}&participant_id=${partId}`
    )
    .then((response) => {
      dispatch(requestCompleted(requestKey));
      dispatch(updateAwardLotSuccess(response));
    })
    .catch((error) => {
      dispatch(requestCompleted(requestKey));
      dispatch(getError(error));
      return error;
    });
};

export const saveAwardLots = (payload, eventID, successMessage) => (dispatch) => {
  payload["exp_saving"] = "";
  payload["event_id"] = eventID;
  const requestKey = "saveAwardLotsRequested";
  dispatch(sendRequest(requestKey));

  apiCall
    .post(`${config.V2_BASE_URL}/events/${eventID}/lots/save_awarded_participant`, payload)
    .then(() => {
      dispatch(requestCompleted(requestKey));
      dispatch(showSuccess(successMessage));
    })
    .catch((error) => {
      dispatch(requestCompleted(requestKey));
      dispatch(getError(error));
      return error;
    });
};

export const deleteNotification = (lotId, eventId, successMessage) => (dispatch) => {
  const requestKey = `${lotId}_deleteNotificationRequest`;
  dispatch(sendRequest(requestKey));
  apiCall
    .post(`${config.V2_BASE_URL}/events/${eventId}/lots/show_info_award_notice`, {
      lot_id: lotId,
      trashed: "true",
      event_id: eventId,
    })
    .then((response) => {
      dispatch(updateAwardLotSuccess(response));
      dispatch(requestCompleted(requestKey));
      dispatch(showSuccess(successMessage));
    })
    .catch((error) => {
      dispatch(requestCompleted(requestKey));
      dispatch(getError(error));
      return error;
    });
};

export const sendNotification = (payload, requestId) => (dispatch) => {
  const requestKey = "sendNotificationRequest";
  dispatch(sendRequest(requestKey));
  apiCall
    .post(`${config.V2_BASE_URL}/events/${requestId}/lots/send_award_notice`, payload)
    .then((response) => {
      dispatch(updateAwardLotsSuccess(response));
      dispatch(requestCompleted(requestKey));
    })
    .catch((error) => {
      dispatch(getError(error));
      dispatch(requestCompleted(requestKey));
    });
};

export const closeBidModal = (payload) => ({
  type: actionTypes.CLOSE_BID_MODAL,
  payload: payload,
});

export const openBidModal = (payload) => ({
  type: actionTypes.OPEN_BID_MODAL,
  payload: payload,
});

export const setActiveLi = (payload) => ({
  type: actionTypes.SET_ACTIVE_LI,
  payload,
});

export const updateRealTimeBids = (payload) => {
  return {
    type: actionTypes.MONITOR_LIVE_FEED,
    payload,
  };
};

export const updateMsrfqRound = (payload) => ({
  type: actionTypes.CREATE_MSRFQ_ROUND,
  payload,
});

export const createMsrfqRound = (payload, eventId, successCallback, errorText) => async (dispatch) => {
  // check if the deadline is in past
  if (moment(payload.event_round.deadline).isBefore(moment())) {
    dispatch(getError({ data: { error: errorText } }));
    dispatch(dispatchModalSubmitRequestCompleted());
    return;
  }

  await apiCall
    .post(`${config.V2_BASE_URL}/events/${eventId}/event_rounds`, payload)
    .then((response) => {
      dispatch(updateMsrfqRound(response.data));
      successCallback();
    })
    .catch((error) => {
      dispatch(getError(error.response));
      dispatch(dispatchModalSubmitRequestCompleted());
    });
};

export const setActiveMsrfqRound = (eventRound) => ({
  type: actionTypes.SET_ACTIVE_MSRFQ_ROUND,
  payload: eventRound,
});

export const getEskerAuthToken = createAsyncThunk(
  "getEskerAuthToken",
  withErrorDispatch(() => apiCall.get(`${config.V2_BASE_URL}/esker_api`))
);
