import React from "react";
import SimSettingCheckbox from "./SimSettingCheckbox";
import { t } from "@/i18n";

interface SettingsContainerProps {
  title: string;
  settingsList: string[];
  settingValues: Record<string, string | boolean>;
  permittedToEdit: boolean;
  onChange: (dataValue: string | boolean, name: string) => void;
}

export default function SettingsContainer({
  title,
  settingsList,
  permittedToEdit,
  settingValues,
  onChange,
}: SettingsContainerProps) {
  return (
    <>
      <div className="form-item-block-title">{t(`sim_dojo:${title}`)}</div>
      {settingsList.map((setting) => {
        return (
          <SimSettingCheckbox
            key={setting}
            value={settingValues[setting]}
            name={setting}
            onChange={onChange}
            disabled={!permittedToEdit}
          />
        );
      })}
    </>
  );
}
