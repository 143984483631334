import React from "react";
import PropTypes from "prop-types";

const HostOwners = ({ hostOwners, activityId }) => {
  return (
    <td>
      <ul className="list-unstyled m-b0">
        {hostOwners.map((ho) => (
          <li className="table-cell-ellipsis" title={ho} key={"activity-" + activityId + "owner-" + ho}>
            {ho}
          </li>
        ))}
      </ul>
    </td>
  );
};

HostOwners.propTypes = {
  hostOwners: PropTypes.array.isRequired,
  activityId: PropTypes.number.isRequired,
};

export default HostOwners;
