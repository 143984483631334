import React from "react";
import { useSelector } from "react-redux";
import useEvent from "./useEvent";
import useLot from "./useLot";

export const getTotalLineItemHeight = (isMultiCurrencyEvent, isShowingDetails) => {
  let height = 46;
  if (isMultiCurrencyEvent && isShowingDetails) {
    height += 65;
  }

  return height;
};

const hasLineItemGotErrors = (lineItem, lotItemComponentErrors) => {
  const errors = Object.values(lotItemComponentErrors?.[lineItem.lot_id] || {}).filter(
    (error) => error.lineItemId === lineItem.id
  );

  return errors.length > 0;
};

const isLineItemTotalRow = (lineItem, lotLics, totalLicId) => {
  return (
    lotLics.findIndex((lotComponent) => {
      return lotComponent.id === totalLicId && lotComponent.line_item_id === lineItem.id;
    }) > -1
  );
};

export const getHeightForErrors = (lineItem, lotItemComponentErrors, isPlaceBidActive) => {
  if (hasLineItemGotErrors(lineItem, lotItemComponentErrors) && isPlaceBidActive) {
    return 38;
  }

  return 0;
};

export const getHeightForUnitSet = (lotLcs, isPlaceBidActive, isShowingDetails) => {
  const hasUnitSet = lotLcs.findIndex((lotComponent) => lotComponent.unit_set_id !== null) > -1;
  if (hasUnitSet && isPlaceBidActive && isShowingDetails) {
    return 52;
  }

  return 0;
};

export const getHeightForMultiCurrency = (isMultiCurrencyEvent, isPlaceBidActive, isShowingDetails) => {
  if (isMultiCurrencyEvent && isPlaceBidActive && isShowingDetails) {
    return 50;
  }

  return 0;
};

export const getHeightForContent = (isPlaceBidActive) => {
  if (isPlaceBidActive) {
    return 75;
  }

  return 50;
};

export const getHeightForDetails = (isPlaceBidActive, isShowingDetails) => {
  if (!isShowingDetails) {
    return 0;
  }

  if (isPlaceBidActive) {
    return 68;
  }

  return 165;
};

const useEventLotGridState = (lot) => {
  const { lotLineItems, lotBidLineItemComponents: lotBlics, lotLotComponents } = useLot(lot);
  const { isMultiCurrencyEvent } = useEvent();
  const lotItemComponentErrors = useSelector(({ lotReducers }) => lotReducers.lotItemComponentErrors);

  return {
    lotLineItems,
    lotBlics,
    lotLotComponents,
    isMultiCurrencyEvent,
    lotItemComponentErrors,
  };
};

export const useEventLotGridSizerCallback = (totalLicId, lotHook, isPlaceBidActive, lot) => {
  const { lotLineItems, lotBlics, lotLotComponents, isMultiCurrencyEvent, lotItemComponentErrors } =
    useEventLotGridState(lot);

  const callback = (index) => {
    const lineItem = lotLineItems[index];
    const isShowingDetails = lotHook.lineItemsDetailsState[lineItem.id];

    if (isPlaceBidActive && lineItem.is_calculation && isLineItemTotalRow(lineItem, lotBlics, totalLicId))
      return getTotalLineItemHeight(isMultiCurrencyEvent, isShowingDetails);

    return (
      getHeightForContent(isPlaceBidActive) +
      getHeightForDetails(isPlaceBidActive, isShowingDetails) +
      getHeightForErrors(lineItem, lotItemComponentErrors, isPlaceBidActive) +
      getHeightForMultiCurrency(isMultiCurrencyEvent, isPlaceBidActive, isShowingDetails) +
      getHeightForUnitSet(lotLotComponents, isPlaceBidActive, isShowingDetails)
    );
  };

  return React.useCallback(callback, [
    lotLineItems,
    isPlaceBidActive,
    lotHook.lineItemsDetailsState,
    lotItemComponentErrors,
    lotLotComponents,
    isMultiCurrencyEvent,
    lotBlics,
    totalLicId,
  ]);
};

export default useEventLotGridSizerCallback;
