import React from "react";
import Tooltip from "../../../common/Tooltip";
import { smallOrMiniCell } from "../../common";

function LotComponentCommonHeading(props) {
  const { translations, weightedRfqOrAuction, auctionTab, isJapaneseEvent, lot, event } = props;
  const { best_placed_participant, difference_offered, bids_received, active_participants, current_bid_level_total } =
    translations;
  const {
    lot_best_placed_participant,
    lot_difference_offered,
    lot_bids_received,
    lot_active_participants,
    lot_current_bid_level_total,
  } = translations.infos;

  const activeParticipantsTitle = () => {
    if (auctionTab) {
      if (isJapaneseEvent && !["running", "pause"].includes(event.current_state)) {
        return (
          <>
            <span className="cell-content">{best_placed_participant}</span>
            <Tooltip title={lot_best_placed_participant.title} body={lot_best_placed_participant.body} />
          </>
        );
      } else {
        return !lot.is_event_total ? (
          <>
            <span className="cell-content">{active_participants}</span>
            <Tooltip title={lot_active_participants.title} body={lot_active_participants.body} />
          </>
        ) : null;
      }
    } else {
      return (
        <>
          <span className="cell-content">{bids_received}</span>
          <Tooltip title={lot_bids_received.title} body={lot_bids_received.body} />
        </>
      );
    }
  };
  return (
    <>
      {/* Hide this if auctionTab is true and event is Japanese and for the
          other tyoe of events or for RFQ/Qual bid tab it will be display */}
      {((auctionTab && !isJapaneseEvent) || !auctionTab) && (
        <div className="mycell cell-title" title={best_placed_participant}>
          <span className="cell-content">{best_placed_participant}</span>
          <Tooltip title={lot_current_bid_level_total.title} body={lot_current_bid_level_total.body} />
        </div>
      )}
      {auctionTab && isJapaneseEvent && (
        <div className="mycell cell-title" title={current_bid_level_total}>
          <span className="cell-content">{current_bid_level_total}</span>
          <Tooltip title={lot_current_bid_level_total.title} body={lot_current_bid_level_total.body} />
        </div>
      )}

      <div className="mycell cell-title big-cell" title={difference_offered}>
        <span className="cell-content">{difference_offered}</span>
        <Tooltip title={lot_difference_offered.title} body={lot_difference_offered.body} />
      </div>
      <div
        className={`mycell cell-title-header ${smallOrMiniCell(weightedRfqOrAuction)} `}
        title={
          auctionTab
            ? isJapaneseEvent && !["running", "pause"].includes(event.current_state)
              ? best_placed_participant
              : active_participants
            : bids_received
        }
      >
        {activeParticipantsTitle()}
      </div>
      {auctionTab && <div className="mycell cell-title-header graph_cell small-cell">&nbsp;</div>}
    </>
  );
}

export default LotComponentCommonHeading;
