import React, { Component } from "react";
import { connect } from "react-redux";

import { updateEditPageFieldStatus } from "../../../../actions/contract/contractConfigurationActions";
import Checkbox from "../Checkbox";
class BasicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicDetails: props.basicDetails,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.data.editFieldsUpdatedData) {
      this.setState({
        basicDetails: newProps.data.editFieldsUpdatedData.data["basic_details"],
      });
    }
  }

  handleFieldStatusChange(e, fieldName) {
    const { companyId, columnId } = this.props;
    this.props.updateEditPageFieldStatus("basic_details", fieldName, columnId, e.target.checked, companyId);
  }

  render() {
    const { basicDetails } = this.state;
    const { translations, currentUserEditConfigPermission } = this.props;
    return (
      <div className="form-group clearfix">
        <div className="title">{translations.basic_details}</div>
        <div className="basic-details-feild">
          <Checkbox
            name={"contract_reference"}
            checked={basicDetails["reference"]}
            onChange={(e) => this.handleFieldStatusChange(e, "reference")}
            translations={translations}
            readOnly={!currentUserEditConfigPermission}
          />
        </div>
        <div className="basic-details-feild">
          <Checkbox
            name={"category"}
            checked={basicDetails["category"]}
            onChange={(e) => this.handleFieldStatusChange(e, "category")}
            translations={translations}
            readOnly={!currentUserEditConfigPermission}
          />
          {currentUserEditConfigPermission ? (
            <a href="/categorydojo" target="_blank" type="button" className="btn btn-primary m-l10 m-b10">
              {translations.spend_categories}
            </a>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.contractConfigurationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateEditPageFieldStatus: (columnName, fieldName, columnId, status, companyId) =>
    dispatch(updateEditPageFieldStatus(columnName, fieldName, columnId, status, companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
