import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton", "checkbox"]

  connect() {
    this.toggleAddButton()
  }

  toggleAddButton() {
    const anyChecked = this.checkboxTargets.some(checkbox => checkbox.checked)
    this.submitButtonTarget.disabled = !anyChecked
  }
}
