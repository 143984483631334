// #ENTRYPOINT
import React from "react";
import type { EventParticipantIdentifier } from "@/slices/events/collaborators";
import { useGetEventCollaboratorsQuery } from "@/slices/events/collaborators";
import Wrapper from "@/components/Wrapper";
import { getStore } from "@/store";

export type CollaboratorsProps = EventParticipantIdentifier;

function Collaborators(props: CollaboratorsProps) {
  const { data } = useGetEventCollaboratorsQuery(props);
  if (!data) return null;
  return <>Collaborators content here</>;
}

export default function WrappedCollaborators(props: CollaboratorsProps) {
  return (
    <Wrapper store={getStore()}>
      <Collaborators {...props} />
    </Wrapper>
  );
}
