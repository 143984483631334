import React, { Component } from 'react';
import { change, Field, FieldArray } from 'redux-form';
import { connect } from 'react-redux';

import {FormField} from './FormField'
import EditorPopupSection from './EditorPopupSection';
import PopupMobile from './PopupMobile';
import * as adminTextActions from '../../../../actions/qq/adminTextActions';
import { QQCurrencySelection } from './QQCurrencySection';
import { fetchCurrencies } from '../../../../actions/qq/qqActions';
import { findCurrency } from '../QQEdit/Lots';

export const LotFormFieldGroup = ({inputs, item, onDelete, translations}) => {
  return (
    <div className='form-group row' key={item}>
      {inputs.map(({id, label, onChange}) => (
        <div key={id} className='col-sm-12 col-md-3 space-bottom p-r0'>
          <FormField id={id} label={translations.qq_edit[label]} onChange={onChange} name={id} translations={translations} />
        </div>
      ))}
      <div className='col-sm-12 col-md-1 space-bottom p-0'>
        <a
          href='#'
          onClick={onDelete}
          className='delete-icon'
        > <i className='fa fa-trash' aria-hidden='true' />
        </a>
      </div>
    </div>
  )
}

class QQPrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPriceSection: false,
      adminText: '',
      selected_currency: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAdminText(this.props.companyId, this.props.sectionKey);
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    const showPriceSection = formValue?.qqPrice.event_category_rfq;
    this.setState({ showPriceSection });
    this.props.onTextValue('event_category_rfq', showPriceSection);
    this.props.fetchCurrencies(this.props.userId);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText.FETCH_ADMIN_TEXT_PRICES ? newProps.adminText.FETCH_ADMIN_TEXT_PRICES : '';
    if (newProps.default.FETCH_ADMIN_TEXT_PRICES_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_PRICES_DEL;
    }
    this.setState({
      adminText,
    });

    if (newProps.currencies && newProps.currency_id) {
      const formValue = JSON.parse(localStorage.getItem('formValue'));
      let currency = findCurrency(newProps.currencies, newProps.currency_id);

      if (formValue.qqPrice.selected_currency) {
        currency = formValue.qqPrice.selected_currency;
      } 
      this.setState({selected_currency: currency}, this.manageCurrency(currency));
      this.props.setFieldValue('QQForm', 'qqPrice.selected_currency', currency);
    }
  }

  getLotName(event, index) {
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    const lot = formValue.qqPrice.lots[index] ? formValue.qqPrice.lots[index] : { name: '', unit_of_measure_uomname: '', quantity: '' };
    lot.name = event.target.value;
    if (!formValue.qqPrice.lots[index]) {
      formValue.qqPrice.lots.push(lot);
    } else {
      formValue.qqPrice.lots[index] = lot;
    }
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  getUnitOfMesure(event, index) {
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    const lot = formValue.qqPrice.lots[index] ? formValue.qqPrice.lots[index] : { name: '', unit_of_measure_uomname: '', quantity: '' };
    lot.unit_of_measure_uomname = event.target.value;
    if (!formValue.qqPrice.lots[index]) {
      formValue.qqPrice.lots.push(lot);
    } else {
      formValue.qqPrice.lots[index] = lot;
    }
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  getLotQuantity(event, index) {
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    const lot = formValue.qqPrice.lots[index] ? formValue.qqPrice.lots[index] : { name: '', unit_of_measure_uomname: '', quantity: '' };
    lot.quantity = event.target.value;
    if (!formValue.qqPrice.lots[index]) {
      formValue.qqPrice.lots.push(lot);
    } else {
      formValue.qqPrice.lots[index] = lot;
    }
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  disabledSection() {
    const showPriceSection = !this.state.showPriceSection;
    this.setState({ showPriceSection });
    this.props.onTextValue('event_category_rfq', showPriceSection);

    const formValue = JSON.parse(localStorage.getItem('formValue')) ? JSON.parse(localStorage.getItem('formValue')) : {};
    formValue.qqPrice ? formValue.qqPrice.event_category_rfq = showPriceSection : formValue.qqPrice = {};
    formValue.qqPrice.event_category_rfq = showPriceSection;
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  removeLot(index) {
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    formValue.qqPrice.lots.splice(index, 1);
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  manageCurrency = (data) => {
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    formValue.qqPrice.selected_currency = data;
    localStorage.setItem('formValue', JSON.stringify(formValue));
    this.props.setFieldValue('QQForm', 'qqPrice.selected_currency', data);
  }

  shouldComponentUpdate(newProps) {
    if (newProps.currencies && !this.props.currencies) {

      const formValue = JSON.parse(localStorage.getItem('formValue'));
      let currency = findCurrency(newProps.currencies, newProps.currency_id);

      if (formValue.qqPrice.selected_currency) {
        currency = formValue.qqPrice.selected_currency;
      }
      this.props.setFieldValue('QQForm', 'qqPrice.selected_currency', currency);
    }

    return true;
  }

  render() {
    const renderLots = ({ fields, lotsLimit, meta: { touched, error, submitFailed } }) => {
      if (fields.length === 0) { fields.push({}); }
      return (
        <div id='optional_prices' className='checkbox-content-box'>
          <label htmlFor='name'>{this.props.translations.qq_create.qq_price.prices_required}</label>
          {lotsLimit > 0 && error && <div className='p-tb5 text-danger'>{this.props.translations.error_message[error]}</div>}
          {fields
            .map((item, index) => (
              <LotFormFieldGroup
                key={item}
                item={item}
                onDelete={
                  (e) => { 
                    e.preventDefault();
                    this.removeLot(index)
                    fields.remove(index)
                    // Add a new item in if its the last one. This needs to be done 
                    // straight after the remove to prevent double rendering
                    if (fields.length === 1) {
                      fields.insert(0, {});
                    }
                  }
                }
                translations={ translations }
                inputs={[
                  {
                    id: `${item}.name`,
                    label: 'name_of_item',
                    onChange: (e) => { this.getLotName(e, index); },
                  },
                  {
                    id: `${item}.unit_of_measure_uomname`,
                    label: 'unit_of_measurement',
                    onChange: (e) => { this.getUnitOfMesure(e, index); },
                  },
                  {
                    id: `${item}.quantity`,
                    label: 'quantity',
                    onChange: (e) => { this.getLotQuantity(e, index); },
                  }
                ]}
              />
            ))}
          <div className='form-group'>
            {fields.length === 0 ?
              <button
                className='btn btn-primary'
                disabled={fields.length >= lotsLimit}
                onClick={(e) => { e.preventDefault(); fields.push({}); }}
              >
                {this.props.translations.qq_create.qq_price.add_item}
              </button> :
              <button
                className='btn btn-primary'
                disabled={fields.length >= lotsLimit || fields.getAll().filter((field, index) => (!!(fields.get(index).name && fields.get(index).name.trim()))).length !== fields.length}
                onClick={(e) => { e.preventDefault(); fields.push({}); }}
              >
                {this.props.translations.qq_create.qq_price.add_another_item}
              </button>}
          </div>
          {fields.length >= lotsLimit ?
            <div className='text-danger'>
              {this.props.translations.error_message.configuration_limit.replace("%{config_limit}", lotsLimit).replace("%{config_string}", 'lots')}
            </div> : ''}
        </div>
      );
    };

    const { sectionKey, companyId, lotsLimit, translations } = this.props;
    return (
      <div className='block-item'>
        <div className='col-md-6 p-l0 table-cell-box'>
          <div className='form-item-block'>
            <PopupMobile
              companyId={companyId}
              sectionKey={sectionKey}
              text={this.state.adminText}
              translations={ translations }
            />
            <div className='checkbox'>
              <div>
                <Field
                  name='event_category_rfq'
                  id='checkbox2'
                  component='input'
                  type='checkbox'
                  onClick={() => this.disabledSection()}
                />
                <label htmlFor='checkbox2'>
                  <span />{translations.qq_create.qq_price.include_price}
                </label>
              </div>
            </div>
            {this.state.showPriceSection && this.props.currencies && <Field name="selected_currency"
              onChange={this.manageCurrency}
              component={QQCurrencySelection} currencies={this.props.currencies} />
            }
            {this.state.showPriceSection &&
              <FieldArray name='lots' component={renderLots} lotsLimit={lotsLimit} />}
          </div>
        </div>
        <EditorPopupSection 
          show={!this.state.adminText}
          text={ this.state.adminText }
          companyId={ companyId }
          sectionKey={ sectionKey }
          translations={ translations }
          updateText={ (adminText) => { this.setState({ adminText }); } }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  adminText: state.adminTextReducer,
  // selectedLang: state.changeLanguageReducer,
  default: state.deleteAdminTextReducer,
  currencies: state.qqReducer?.currencies,
});

const mapDispatchToProps = dispatch => ({
  fetchAdminText: (companyId, sectionKey) => dispatch(adminTextActions.fetchAdminText(companyId, sectionKey)),
  fetchCurrencies: (userId) => dispatch(fetchCurrencies(userId)),
  setFieldValue: (formName, fieldName, data) => dispatch(change(formName, fieldName, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(QQPrice);
