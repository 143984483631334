import React, { useState } from "react";
import { currencyPosition, findLotBidRange } from "../../common";
import { placeBid, bidRange } from "../../../../actions/bidActions";
import { useDispatch } from "react-redux";
import { Confirm } from "../../../common/Confirm";
import CurrencyFieldWithSymbol from "../line_item_component/CurrencyFieldWithSymbol";
import actionsTypes from "../../../../actions/actionsTypes";
import Tooltip from "../../../common/Tooltip";

const LotLevelProxyBid = (props) => {
  const {
    translations,
    lot: _lot,
    allUom,
    event,
    currency,
    openModal,
    participant,
    currentUser,
    setIsOpen,
    lotsBidRange,
    bid,
    onRequestClose,
    setTableState,
  } = props;

  const {
    uom,
    infos,
    number_format: numberFormat,
    new_bid_range,
    value_entered,
    are_you_sure,
    used_price_uom,
    total_lot_value_placed,
    quantity,
    submit,
    cancel,
    new_bid_range_message,
  } = translations;

  const dispatch = useDispatch();

  React.useEffect(() => {
    let params = {
      event_id: event.id,
      user_id: participant.user_id,
    };
    if (bid && bid.exchange_rate_id) params.exchange_rate_id = bid.exchange_rate_id;
    dispatch(bidRange(_lot.id, bid ? bid.id : "", params));
  }, []);

  const { total_lot_value_placed: totalLotValue, bid_range, lot_uom, lot_quantity } = infos;
  const [lotLvlState, setLotLvlState] = useState({
    errors: [],
    lotPrice: "",
    lotMessage: "",
    totalPrice: "",
  });
  const setState = (state) => {
    setLotLvlState({ ...lotLvlState, ...state });
  };

  const { lotPrice, lotMessage, totalPrice } = lotLvlState;

  const { quantity: _quantity } = _lot;

  const _allUom = Array.isArray(allUom) ? allUom : [];
  const _uom = _allUom.find((u) => u.id === _lot.unit_of_measure_id);
  const [minBidRange, maxBidRange] = findLotBidRange(lotsBidRange, _lot.id);
  let bidRangeComponent;
  bidRangeComponent = (
    <>
      {currencyPosition(minBidRange ? minBidRange : "-", currency, numberFormat)}
      {" - "}
      {currencyPosition(maxBidRange ? maxBidRange : "-", currency, numberFormat)}
      &nbsp;({new_bid_range_message}) <Tooltip title={bid_range.title} body={bid_range.body} />
    </>
  );

  const message = (
    <>
      {value_entered}
      <br />
      <strong>{currencyPosition(lotPrice, currency, numberFormat)}</strong>
      <br />
      {are_you_sure}
    </>
  );

  const onClickAccept = () => {
    dispatch(
      placeBid(_lot.id, {
        bid: {
          event_id: event.id,
          lot_id: _lot.id,
          user_id: participant.user_id,
          price: lotPrice,
          host_id: currentUser.id,
        },
        before_auction: "false",
        proxy: "1",
      })
    )
      .then((res) => {
        const { data, type, payload } = res ?? {};
        if (type === actionsTypes.BID_SUBMITTED && payload?.data?.message)
          setTableState({ message: payload.data.message });
        if (data?.message) setState({ lotMessage: data.message });
        else {
          setState({ lotMessage: "" });
          setIsOpen(false);
        }
      })
      .catch((data) => {
        data && data.message && setState({ lotMessage: data.message });
      });
  };

  return (
    <>
      <Confirm
        translations={translations}
        message={message}
        htmlId={`lotLevelProxyBidAuctionConfirm-${_lot.id}`}
        onOkayButtonClick={onClickAccept}
      />
      <div className="block-content special-tab-content">
        <div className="row">
          <div className="col-md-12 m-t10">
            <div className="block">
              {lotMessage && (
                <div className="alert alert-danger alert-dismissable">
                  <a className="close" onClick={() => setState({ lotMessage: "" })}>
                    ×
                  </a>
                  <div id="errorExplanation" className="errorExplanation">
                    <li>{lotMessage}</li>
                  </div>
                </div>
              )}
              <table className="table table-bordered table-sp custom-responsive-table simple-lot-popup">
                <tbody>
                  <tr>
                    <td className="col-md-3"> {uom}</td>
                    <td className="col-md-9">
                      {_uom && _uom.name ? _uom.name : "-"}
                      &nbsp;&nbsp;
                      <Tooltip title={lot_uom.title} body={lot_uom.body} />
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-3"> {quantity}</td>
                    <td className="col-md-9">
                      {_quantity}
                      &nbsp;&nbsp;
                      <Tooltip title={lot_quantity.title} body={lot_quantity.body} />
                    </td>
                  </tr>
                  <tr>
                    <td className="col-md-3"> {new_bid_range}</td>
                    <td className="col-md-9">{bidRangeComponent}</td>
                  </tr>
                  <tr>
                    <td className="col-md-3"> {used_price_uom}</td>
                    <td className="col-md-9">
                      <CurrencyFieldWithSymbol
                        id="bid-price-field"
                        name="isPrice"
                        symbol={currency.symbol}
                        value={lotPrice}
                        precision={2}
                        classes="form-control simple_lot_number_precision"
                        translations={translations}
                        handleFieldChange={(e) => {
                          const lotPrice = e();
                          setState({ lotPrice, totalPrice: lotPrice });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="well well-sm input">
                <b>
                  <label>{total_lot_value_placed} :</label>
                </b>
                <div className="markup">
                  <span className="total_lot_value">{currencyPosition(totalPrice, currency, numberFormat)}</span>
                  <Tooltip title={totalLotValue.title} body={totalLotValue.body} />
                </div>
              </div>
              <div className="modal-footer">
                <a className="btn btn-sm btn-default" onClick={onRequestClose}>
                  {cancel}
                </a>
                <a
                  className="btn btn-sm btn-primary"
                  onClick={() => openModal(`#lotLevelProxyBidAuctionConfirm-${_lot.id}`)}
                >
                  {submit}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LotLevelProxyBid;
