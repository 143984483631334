import { useCallback } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { auctionPending, completed, isRfq, pause, postRfqPreAuction } from "../eventCommon";
import { isJapaneseEvent, isRankedEvent, multiCurrencyEvent } from "../common";

const eventSelector = createSelector(
  ({ lotReducers }) => lotReducers.event,
  ({ lotReducers }) => lotReducers.event_currency,
  ({ lotReducers }) => lotReducers.currencies_hash,
  ({ lotReducers }) => lotReducers.event_rounds,
  (event, eventCurrency, currenciesHash, eventRounds) => {
    return {
      event,
      eventCurrency,
      currenciesHash,
      isMultiCurrencyEvent: multiCurrencyEvent(event),
      isEventCompleted: completed(event),
      isAuctionPending: auctionPending(event),
      isLotLevelAuction: !isJapaneseEvent(event) && !event.bid_at_detail_level,
      isJapaneseAuction: isJapaneseEvent(event),
      isRankedMonitor: isRankedEvent(event),
      isPostRfqPreAuction: postRfqPreAuction(event),
      isEventPaused: pause(event),
      isEventMsrfq: isRfq(event) && eventRounds?.length > 1,
    };
  }
);

const useEvent = () => {
  const { event, currenciesHash, eventCurrency, ...rest } = useSelector(eventSelector);

  return {
    ...rest,
    event,
    getCurrency: useCallback(
      /**
       * Get currency for event or lic by passing lic.exchange_rate_id
       * @param {number | undefined} exchangeRateId
       * @returns currency // TODO(dharma): add currency type
       */
      (exchangeRateId) => {
        if (!multiCurrencyEvent(event) || (!exchangeRateId && !currenciesHash?.[exchangeRateId])) return eventCurrency;

        return currenciesHash?.[exchangeRateId];
      },
      [currenciesHash, event, eventCurrency]
    ),
  };
};

export default useEvent;
