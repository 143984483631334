import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { LineItem } from "@/common-prop-types";
import { isLiTotalPresent } from "../../../common";
import UpdateCurrentPriceComplexLot from "../../../monitor/UpdateCurrentPriceComplexLot";

/**
 * This function will only run in case of Partial Biding
 *
 * @param {object} li
 * @param {Array} bidLineItemComponents
 * @returns {Number} number of bids on that particular line item
 */

export const calculateBidCountForLi = (li, bids, bidLineItemComponents) => {
  const blicsForLineItemTotalLic = bidLineItemComponents?.filter(
    (bidLineItemComponent) => bidLineItemComponent?.line_item_component_id === li?.line_item_total_lic_id
  );
  const submittedBlicsForLineItemTotalLic = blicsForLineItemTotalLic?.filter((blic) =>
    bids?.find((bid) => bid.id === blic.bid_id && bid.current_state === "submitted")
  );

  // Creating an array objects, each object contains userId who did the bidding on that LI and his bid price
  // We had to create this array of objects just to check if the user and his bid value is same for two bids
  // then do not increase the bid count but if the user is different and bid price is same then increase the
  // bid count as it's a different bid.
  const userWithHisBidPrice = submittedBlicsForLineItemTotalLic?.map((blic) => {
    const userId = bids?.find((bid) => bid.id === blic.bid_id)?.user_id;
    return { userId: userId, bidPrice: blic.price };
  });
  return makeUniqueArrayOfObjects(userWithHisBidPrice)?.length;
};

export const makeUniqueArrayOfObjects = (arrayOfObjects) => {
  const map = new Map();
  const result = [];
  for (const obj of arrayOfObjects) {
    const objValues = Object.values(obj);
    const key = objValues.join("|");
    if (!map.has(key)) {
      map.set(key, obj);
      result.push(obj);
    }
  }
  return result;
};

const BidCountComponentPropTypes = {
  /**
   * current line item
   * @type {object} li
   */
  li: PropTypes.shape(LineItem).isRequired,

  /**
   *
   * @type {string} qualificationVal
   */
  qualificationVal: PropTypes.string,

  /**
   * number of bids on the current lot
   * @type {number} lotsActiveBids
   */
  lotsActiveBids: PropTypes.number,

  /**
   * check if Partial Bid is enabled or not for the current lot
   * @type {boolean} isPBEnabledForLot
   */
  isPBEnabledForLot: PropTypes.bool,
};

/**
 * @type {React.FC<typeof BidCountComponentPropTypes>}
 */

export const BidCountComponent = ({ li, isPBEnabledForLot, lotsActiveBids, qualificationVal }) => {
  const bidLineItemComponents = useSelector(({ lotReducers }) => lotReducers.bid_line_item_components);
  const auction_tab = useSelector(({ lotReducers }) => lotReducers.auction_tab);
  const isJapaneseEvent = useSelector(({ lotReducers }) => lotReducers.is_japanese_event);
  const bids = useSelector(({ lotReducers }) => lotReducers.bids);

  const bidCountForLi = isPBEnabledForLot && calculateBidCountForLi(li, bids, bidLineItemComponents);
  const bidCountToShow = isPBEnabledForLot ? bidCountForLi : lotsActiveBids;
  const bidCount = isJapaneseEvent ? null : bidCountToShow;

  return (
    <div className="mycell">
      <span title={qualificationVal}>{auction_tab ? bidCount : qualificationVal}</span>
    </div>
  );
};
BidCountComponent.propTypes = BidCountComponentPropTypes;

/**
 *
 * @description Line Item Name, Current value and total bids count
 */
export default function LineItemNameOrCurrentValue(props) {
  const { li, currentVal, qualificationVal, lotsActiveBids, lot, isMultiCurrencyEvent, currency, lineItemLics } = props;
  const { event, auction_tab, permitted_to_edit, currencies_hash, translations } = useSelector(
    (state) => state.lotReducers
  );
  const isPBEnabledForLot = lot?.permit_partial_bids;
  const isLineItemTotal = isLiTotalPresent(li);
  return (
    <>
      {/* row-title class is used to show or hide LI details  */}
      <div className="mycell row-title first-row-title">
        <span title={li?.name}>{li?.name}</span>
      </div>
      <div className="mycell lot-information">
        <div className="row">
          <div className="mycell">
            <span title={currentVal}>
              {currentVal}
              {isLineItemTotal &&
                (auction_tab || event.event_category_rfq) &&
                event.current_state === "completed" &&
                permitted_to_edit && (
                  <UpdateCurrentPriceComplexLot
                    translations={translations}
                    lot={lot}
                    lotLics={lineItemLics}
                    currency={currency}
                    currenciesHash={currencies_hash}
                    isMultiCurrencyEvent={isMultiCurrencyEvent}
                  />
                )}
            </span>
          </div>
          <BidCountComponent
            li={li}
            isPBEnabledForLot={isPBEnabledForLot}
            lotsActiveBids={lotsActiveBids}
            qualificationVal={qualificationVal}
          />
        </div>
      </div>
    </>
  );
}

LineItemNameOrCurrentValue.propTypes = {
  li: PropTypes.object,
  currentVal: PropTypes.string,
  qualificationVal: PropTypes.string,
  lotsActiveBids: PropTypes.number,
};

LineItemNameOrCurrentValue.defaultProps = {
  li: {},
  currentVal: "",
  qualificationVal: "",
};
