import React from "react";
import BestBidOrOverallScore from "./BestBidOrOverallScore";
import { hasValue, showCurrency, isLotTotalPresent } from "../../../common";
import { bestBidValueBeforeAuctionStarted } from "../../../bidCommon";
import features from "@/utils/features";

/**
 * @description Participant Best Bid for Lot
 * Monitor Tab
 *
 * Column - Best Bid
 */
export default function BestBidValue(props) {
  const { currency, translations, locale, isMultiCurrencyEvent, lot, precision = 2 } = props;
  const { bestBidBeforeAuction, bidLineItemComponents } = props;

  let bestBidOrBlic = bestBidBeforeAuction;

  // let blic for total lic if feature switch is on
  if (features.enabled("show_total_blic_price")) {
    bestBidOrBlic = bidLineItemComponents.find(
      (blic) => blic.bid_id === bestBidBeforeAuction?.id && lot.lot_total_lic_id === blic.line_item_component_id
    );
  }

  const bestValue = hasValue(bestBidOrBlic?.price)
    ? bestBidValueBeforeAuctionStarted(bestBidOrBlic, isMultiCurrencyEvent, lot, "host")
    : "-";

  const showViewComponent = bestValue !== "-";
  const viewComponent = showCurrency(bestValue, currency, precision, locale, translations.number_format);
  const header = `${translations.current_bids}: ${lot.name}`;
  return (
    <div className="mycell" title={`${bestValue}`}>
      {isLotTotalPresent(lot) && showViewComponent ? (
        <BestBidOrOverallScore {...props} viewComponent={viewComponent} header={header} />
      ) : (
        bestValue
      )}
    </div>
  );
}
