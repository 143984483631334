import React, { Component } from "react";
import { connect } from "react-redux";
import { createFilter } from "react-search-input";
import _ from "lodash";
import URLSearchParams from "url-search-params";

import QQListTable from "./QQListTable";
import { getQQEvents } from "../../../../actions/qq/qqActions";
import { emptyCurrentState, changeEventState, removeEvent } from "../../../../actions/qq/genericActions";
import { confirm } from "../../util/confirm";
import PaginationContainer from "../../PaginationContainer";

class QQListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      qqEvents: [],
      newQQEvent: {},
      showLoader: true,
      keyToFilter: ["id", "name", "event_state"],
      pageDetails: { eventsPerPage: 10, totalEvents: 0 },
      searchState: "",
      page: this.props.page,
      popupContext: "",
      qqEventAction: {},
      qqEventActionState: "",
    };
    this.searchUpdated = this.searchUpdated.bind(this);

    // bind <this> to the event method
    this.changePage = this.changePage.bind(this);
    this.searchByStatus = this.searchByStatus.bind(this);
    this.confirmation = this.confirmation.bind(this);
  }
  async UNSAFE_componentWillMount() {
    await this.props.getQQEvents(this.props.page, this.state.searchTerm, this.state.searchState);
    this.setState({ showLoader: false });
    const paramsString = this.props.location?.search;
    const params = new URLSearchParams(paramsString);
    const status = params.get("view");
    if (status) {
      this.setState({ keyToFilter: ["id", "event_state"] });
      this.searchByStatus(status);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const filterQQ = nextProps.QQevents.qqEvents ? nextProps.QQevents.qqEvents.events : [];
    if (nextProps.QQevents.QQevent) {
      _.remove(filterQQ, (QQ) => QQ.id === nextProps.QQevents.QQevent.id);
    } else if (!_.isEmpty(nextProps.updatedEvents?.updatedEvent)) {
      const index = _.findIndex(filterQQ, (o) => o.id === nextProps.updatedEvents.updatedEvent.id);
      filterQQ.splice(index, 1, nextProps.updatedEvents.updatedEvent);
    }
    if (nextProps.location?.search === "") {
      this.setState({ searchTerm: "" });
    }
    this.setState({
      qqEvents: filterQQ,
      pageDetails: nextProps.QQevents.qqEvents ? nextProps.QQevents.qqEvents.pageDetails : {},
    });
    if (nextProps.setListingParameters?.flag) {
      this.searchBoxInput.value = "";
      this.setState({ searchTerm: "", searchState: "" }, () => {
        this.props.getQQEvents(this.props.page, this.state.searchTerm, this.state.searchState);
      });
    }
  }

  componentWillUnmount() {
    this.props.emptyCurrentState("CHANGE_EVENT_STATE", undefined);
  }

  setStateForSearch() {
    const company = this.props.company;
    setTimeout(() => {
      if (company.publicly_visible_prefix_event) {
        this.setState({ searchTerm: this.searchBoxInput.value, keyToFilter: ["id", "name", "event_state"] });
      } else {
        this.setState({ searchTerm: this.searchBoxInput.value, keyToFilter: ["name", "event_state"] });
      }
    }, 1);
    if (this.props.page > 1) {
      window.location.pathname = "/quick_quotes/quotes?page=1";
    }
  }

  async changePage(page) {
    this.setState({ page: page });
    await this.props.getQQEvents(page, this.state.searchTerm, this.state.searchState);
  }

  async handleSearchChange(e) {
    if (e.key === "Enter") {
      this.setStateForSearch();
      await this.props.getQQEvents(1, e.target.value, "");
    } else if (e.target.value.length === 0) {
      await this.props.getQQEvents(1, e.target.value, "");
      this.setState({ searchTerm: "", searchState: "" });
    }
  }

  searchUpdated(e) {
    e.preventDefault();
    this.setStateForSearch();
    this.props.getQQEvents(1, this.searchBoxInput.value, "");
  }

  async searchByStatus(state) {
    const stateValue = state === "closed" ? "closed=true" : "current=true";
    this.setState({ searchState: stateValue });
    if (this.props.page > 1) {
      window.location.pathname = "/quick_quotes/quotes?page=1";
    }
    await this.props.getQQEvents(1, this.searchBoxInput.value, stateValue);
  }

  onQQStateAction(qqEventAction, qqEventActionState) {
    if (qqEventActionState === "delete") {
      this.props.removeEvent(qqEventAction.id, qqEventActionState);
    } else if(qqEventActionState === "reopen") {
      this.props.changeEventState(qqEventAction.id, qqEventActionState, this.props.translations.info_message.event_reopen_alert);
    } else {
      this.props.changeEventState(qqEventAction.id, qqEventActionState);
    }
  }

  confirmation(QQevent, qqState) {
    let popupContext = "";
    if (qqState === "close") {
      popupContext = this.props.translations.pop_up.confirm_popup_close;
    } else if (qqState === "delete") {
      popupContext = this.props.translations.pop_up.confirm_popup_delete;
    } else {
      popupContext = this.props.translations.pop_up.confirm_popup_reopen;
    }
    let pop_up_translations = {
      ConfirmLabel: this.props.translations.pop_up.confirm,
      okLabel: this.props.translations.pop_up.ok,
      cancelLabel: this.props.translations.pop_up.cancel,
    };
    confirm(popupContext, pop_up_translations).then(
      () => {
        qqState === "delete"
          ? this.props.removeEvent(QQevent.id, qqState)
          : qqState === "reopen" ? this.props.changeEventState(QQevent.id, qqState, this.props.translations.info_message.event_reopen_alert) : this.props.changeEventState(QQevent.id, qqState);
        if (this.state.searchTerm) {
          this.setState({ searchTerm: "" });
          if (this.props.page > 1) {
            window.location.pathname = "/quick_quotes/quotes?page=1";
          }
        }
      },
      () => {
        // `cancel` callback
      }
    );
    return false;
  }

  render() {
    const { qqEvents, showLoader, keyToFilter, pageDetails, page } = this.state;
    const searchedEvents = qqEvents ? qqEvents.filter(createFilter(this.state.searchTerm, keyToFilter)) : [];
    const perPage = parseInt(pageDetails.eventsPerPage, 10);
    const pages = Math.ceil(pageDetails.totalEvents / perPage);
    const stateValue =
      this.state.searchTerm.toLowerCase() === "current" || this.state.searchTerm.toLowerCase() === "closed";
    const { translations } = this.props;
    return (
      <>
        <div>
          <div className="col-xs-12 col-md-4 event-head p-0 mobile-padding">
            {stateValue ? (
              <h4>{translations.qq_listing.quotes_ + this.state.searchTerm.toLocaleUpperCase()}</h4>
            ) : (
              <h4>{translations.qq_listing.your_quotes}</h4>
            )}
          </div>
          <div className="col-xs-12 col-md-8 p-0 mobile-padding">
            <div className="form-group col-md-9 col-sm-9 p-0">
              <div className="input-search">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder={translations.qq_listing.search_for_quote}
                    className="form-control placeholder search-input"
                    ref={(el) => {
                      this.searchBoxInput = el;
                    }}
                    onKeyUp={(e) => {
                      this.handleSearchChange(e);
                    }}
                  />
                  <span className="input-group-btn">
                    <a
                      className="btn btn-default"
                      href="#"
                      onClick={(e) => {
                        this.searchUpdated(e);
                      }}
                    >
                      <i className="fa fa-search" aria-hidden="true" />
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group col-md-3 col-sm-3 pull-right p-r0 new-quote">
              <a href={"/quick_quotes/quote/new"} className="btn btn-primary btn-fixed pull-right">
                <i className="fa fa-bullhorn">
                  <span>+</span>
                </i>{" "}
                {translations.qq_listing.new_event}
              </a>
            </div>
          </div>
          <div className="clear-box" />
          <div className="col-md-12 p-0 m-t20 mobile-padding clear-both">
            <QQListTable
              {...this.props}
              searchedEvents={searchedEvents}
              page={page}
              pages={pages}
              perPage={perPage}
              searchByStatus={this.searchByStatus}
              confirmation={this.confirmation}
              showLoader={this.state.showLoader}
            />
            {pageDetails.totalEvents > perPage && (
              <PaginationContainer activePage={page} pages={pages} changePage={this.changePage} activeLabel={null} />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  QQevents: state.qqReducer,
  // selectedLang: state.changeLanguageReducer,
  isOpen: state.errorMessageReducer,
  page: Number(new URLSearchParams(ownProps.location?.search).get("page")) || 1,
  // companyDetails: state.companyDetailsReducer,
  setListingParameters: state.setListingParametersReducer,
  updatedEvents: state.updateEventStateReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getQQEvents: (page, searchText, state) => dispatch(getQQEvents(page, searchText, state)),
  emptyCurrentState: (actionType, payload) => dispatch(emptyCurrentState(actionType, payload)),
  changeEventState: (quoteId, state, message) => dispatch(changeEventState(quoteId, state, message)),
  removeEvent: (quoteId, state) => dispatch(removeEvent(quoteId, state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QQListing);
