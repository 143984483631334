import React from "react";
import PropTypes from "prop-types";

import CurrencyFieldWithSymbol from "../CurrencyFieldWithSymbol";
import { PickListSelect } from "./input-pick-list";

import { useDispatch, useSelector } from "react-redux";
import { selectEventCurrencyInfo, selectUnitOfMeasures } from "@/selectors/lotSelectors";
import { useUpToDateValue } from "@/hooks/use-up-to-date-value";

import { CommonInputProps } from "./index";
import { LineItemComponent } from "@/common-prop-types";
import { findLicBidRange, isANumber } from "@/components/advanced_lots/common";
import { inRange } from "lodash";
import { setValidationErrors } from "@/actions/bidLineItemComponentActions";
import InputStatusIcon from "./input-status-icon";

const InputPricePropTypes = { ...CommonInputProps };

const UnitOfMeasurePropType = {
  translations: PropTypes.any.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  lineItemComponent: PropTypes.shape(LineItemComponent).isRequired,
  unitOfMeasure: PropTypes.shape({
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  }).isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof UnitOfMeasurePropType>>}
 */
const UnitOfMeasure = ({ lineItemComponent, translations, value, onChange, unitOfMeasure }) => {
  if (!lineItemComponent.is_uom_set) {
    return (
      <p className="m-b0">
        {translations.uom}: <span className="text-black">{unitOfMeasure.name || "-"}</span>
      </p>
    );
  }

  return (
    <PickListSelect
      value={value}
      options={unitOfMeasure.options.map((unit) => ({
        text: unit.name,
        id: unit.id,
      }))}
      onChange={onChange}
    />
  );
};

UnitOfMeasure.propTypes = UnitOfMeasurePropType;

/**
 * Calculate quantity of a lineItemComponent, default quantity will be 1
 * @param {object} lineItemComponent
 * @param {number} unitOfMeasureId
 * @param {number} unitOfMeasureValue
 * @returns {number} quantity of a line item component
 */
const preferredQuantity = (lineItemComponent, unitOfMeasureId, unitOfMeasureValue) => {
  const { is_formula_lic, is_uom_set, unit_of_measure_id, quantity } = lineItemComponent;

  if (!is_formula_lic && is_uom_set && unitOfMeasureId === unit_of_measure_id) return quantity || 1;

  return unitOfMeasureValue || 1;
};

/**
 * Tests to see is a value is in the valid price range of bidding
 *
 * @returns {Boolean}
 */
const isInRange = (lotsBidRange, lineItem, value) => {
  const [min, max] = findLicBidRange(lotsBidRange, lineItem);
  return isANumber(min) && isANumber(max) ? inRange(value, min, max) || value === max : true;
};

/**
 * @type {React.FC<import('prop-types').InferProps<InputPricePropTypes>>}
 */
export const InputPrice = ({ value, onChange, onFocus, lineItemComponent, bidLineItemComponent, ...props }) => {
  const { translations, isAllDetailView, rank, state, lineItem, lot } = props;
  const dispatch = useDispatch();
  const eventCurrencyInfo = useSelector(selectEventCurrencyInfo);
  const unitOfMeasures = useSelector(selectUnitOfMeasures);
  const hasNoLotTotalSwitch = useSelector((state) => state.lotReducers.no_lot_total_switch);
  const lotsBidRange = useSelector((state) => state.lotReducers.lotsBidRange);

  const exchangeRateId = bidLineItemComponent?.exchange_rate_id || lineItemComponent.exchange_rate_id;
  const currency = eventCurrencyInfo.currenciesHash[exchangeRateId] || eventCurrencyInfo.currency;

  // Get the unit of measure ID and the unit set ID looking at the bid first.
  // If the value is not found in the bid then it will fall back to the default
  // value in the line item component.
  const unitOfMeasureId = bidLineItemComponent?.unit_of_measure_id || lineItemComponent.unit_of_measure_id;
  const unitSetId = bidLineItemComponent?.unit_set_id || lineItemComponent.unit_set_id;

  const [currentUnitOfMeasureId, setUnitOfMeasure] = useUpToDateValue(unitOfMeasureId);
  const [currentValue, setValue] = useUpToDateValue(value);
  const [currentExchangeRateId, setExchangeRate] = useUpToDateValue(exchangeRateId);

  // Reset the internal state value when someone deletes a line item without having any
  // bid line item components. This can happen when the user is on there first
  // bid and get a validation error. If they choose to delete the line item it
  // will remove the error but not reset the internal value because the current
  // bid value is `null` as nothing has yet been created on the server.
  const previousState = React.useRef(props.state);
  React.useEffect(() => {
    const hasStateChanged = previousState.current === "danger" && props.state === "clean";
    const isValueNull = value === null && currentValue !== null;
    if (hasStateChanged && isValueNull) {
      setValue(null);
    }

    previousState.current = props.state;
  }, [props.state, currentValue, value, setValue]);

  // Get the current unit of measures for this input. This will be a unit set
  // if the `unitSetId` is found in the list of sets if not then it will be
  // unit of measure. The value will only be a unit set if the `is_uom_set`
  // prop on the line item component is set to true.
  const unitOfMeasure = unitOfMeasures.sets[unitSetId] || unitOfMeasures.measures[currentUnitOfMeasureId];

  // Convert the unit of measure based on what the user has chosen as there
  // unit of measure. We are defaulting to "1" for each the ratio and the
  // quantity so if all else fails we will display a quantity of "1" to the
  // user.
  //
  // TODO(ade): Test this with MSRFQ
  const unitOfMeasureValue =
    (unitOfMeasures.measures[currentUnitOfMeasureId]?.ratio || 1) * (lineItemComponent.quantity || 1);

  // range validation should only be done for line_item_total
  const shouldValidateRange = hasNoLotTotalSwitch && lineItemComponent.line_item_total;

  const quantity = preferredQuantity(lineItemComponent, unitOfMeasureId, unitOfMeasureValue);

  const commit = (price, unit_of_measure_id, exchange_rate_id) => {
    const priceValue = price * quantity.toFixed(2);

    if (shouldValidateRange && !isInRange(lotsBidRange, lineItem, priceValue))
      dispatch(
        setValidationErrors({
          lotId: lot.id,
          lineItemId: lineItemComponent.line_item_id,
          lineItemComponentId: lineItemComponent.id,
          messages: [translations.bid_out_of_range],
        })
      );
    else onChange?.([{ price, unit_of_measure_id, exchange_rate_id }]);
  };

  return (
    <>
      <div className="input-group m-b0">
        <CurrencyFieldWithSymbol
          name="isPrice"
          symbol={currency.symbol}
          value={currentValue}
          precision={lineItemComponent.decimal_place}
          classes="form-control bid-price-field number_precision blic_field persisted-true no-spin"
          translations={translations}
          handleFieldChange={(getValue) => setValue(getValue())}
          showRank={typeof rank !== "undefined"}
          rankValue={rank}
          fixedDecimalScale={false}
          onBlur={() => commit(currentValue, currentUnitOfMeasureId, currentExchangeRateId)}
          onFocus={onFocus}
        />
        <InputStatusIcon state={state} />
      </div>
      {isAllDetailView && (
        <div className="m-t10">
          <PickListSelect
            hidden={!eventCurrencyInfo.isMultiCurrencyEvent}
            value={currentExchangeRateId}
            options={Object.entries(eventCurrencyInfo.currenciesHash).map(([exchangeRateId, currency]) => ({
              text: currency.name,
              id: exchangeRateId,
            }))}
            onChange={(exchangeRateId) => {
              setExchangeRate(exchangeRateId);
              if (currentValue) {
                commit(currentValue, currentUnitOfMeasureId, exchangeRateId);
              }
            }}
          />
          <UnitOfMeasure
            translations={translations}
            lineItemComponent={lineItemComponent}
            value={currentUnitOfMeasureId}
            unitOfMeasure={unitOfMeasure}
            onChange={(unitOfMeasureId) => {
              setUnitOfMeasure(unitOfMeasureId);
              if (currentValue) {
                commit(currentValue, unitOfMeasureId, currentExchangeRateId);
              }
            }}
          />
          <p className="m-b0">
            {translations.quantity}: <span className="text-black">{unitOfMeasureValue}</span>
          </p>
          {Boolean(lineItemComponent.description) && (
            <p className="m-b0">
              {translations.description}: <span className="text-black">{lineItemComponent.description}</span>
            </p>
          )}
        </div>
      )}
    </>
  );
};

InputPrice.propTypes = InputPricePropTypes;
InputPrice.defaultProps = {};

export default InputPrice;
