import PropTypes from "prop-types";

export const Products = ["sourcing", "sim", "srm", "contracts"] as const;
export const Tiers = ["bronze", "silver", "gold"] as const;

export type TierType = (typeof Tiers)[number];
export type ProductType = (typeof Products)[number];

export type LicenseTiers = { [key in ProductType]?: TierType };

export const Company = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export type Company = PropTypes.InferProps<typeof Company>;

export default Company;
