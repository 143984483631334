import { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { bidSubmitted } from "../../bidCommon";
import { convertToLocaleString, isLotTotalPresent } from "../../common";
import useBid from "../useBid";
import useEvent from "../useEvent";
import useLot from "../useLot";
import { useAllLisAreInRange } from "./useRange";

const isConsideringLITasLT = (lot, activeLi, isPBPermitted, allLisAreInRange) => {
  const isLotTotal = isLotTotalPresent(lot);
  const isLotTotalLicActive = isLotTotal && activeLi?.id === lot?.lot_total_lic_id;
  return isPBPermitted && (!allLisAreInRange || !isLotTotalLicActive);
};

const calculateLotTotalLic = ({ lot, activeLi, isPBPermitted, allLisAreInRange, lotLineItemComponents }) => {
  const { lot_total_lic_id } = lot;
  const { line_item_total_lic_id } = activeLi ?? {};
  const consideringLITasLT = isConsideringLITasLT(lot, activeLi, isPBPermitted, allLisAreInRange);

  let totalLicId = lot_total_lic_id ?? line_item_total_lic_id;
  if (consideringLITasLT) totalLicId = line_item_total_lic_id;

  return lotLineItemComponents.find(({ id }) => id === totalLicId);
};

const useLotTotalDetails = (lot, bid, showAllLineItemDetails) => {
  const { isPBPermitted, lotLineItemComponents, activeLi } = useLot(lot);
  const allLisAreInRange = useAllLisAreInRange(lot, bid);
  const { getLotTotalBlic } = useBid(bid);
  const lotTotalLic = useMemo(
    () =>
      calculateLotTotalLic({
        lot,
        activeLi,
        isPBPermitted,
        allLisAreInRange,
        lotLineItemComponents,
      }),
    [lot, activeLi, isPBPermitted, allLisAreInRange, lotLineItemComponents]
  );

  const lotTotalBLic = useMemo(
    () => getLotTotalBlic(showAllLineItemDetails, lotTotalLic?.id),
    [getLotTotalBlic, lotTotalLic?.id, showAllLineItemDetails]
  );
  return { lotTotalLic, lotTotalBLic };
};

const useTotal = (lot, bid, showAllLineItemDetails) => {
  const { isRankedMonitor, isPostRfqPreAuction } = useEvent();
  const { getTotalBidValue } = useBid(bid);
  const locale = useSelector(({ lotReducers }) => lotReducers.locale);
  const { lotTotalLic, lotTotalBLic } = useLotTotalDetails(lot, bid, showAllLineItemDetails);

  useEffect(() => {
    window.advancedLots.finalRender = true;
  }, [lotTotalBLic, bid]);

  const showPrice = !(isPostRfqPreAuction && !bidSubmitted(bid) && !isRankedMonitor);
  const totalBidValue = getTotalBidValue(showPrice, lotTotalLic, showAllLineItemDetails);

  return {
    lotTotalLic,
    showPrice,
    lotTotalBLic,
    totalBidValue,
    totalBidPrice: convertToLocaleString(totalBidValue, locale, lotTotalLic?.decimal_place ?? 2, true),
  };
};

export default useTotal;
