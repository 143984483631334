import React from "react";

import { NumericFormat } from "react-number-format";

import useUpToDateValue from "@/hooks/use-up-to-date-value";

import { CommonInputProps } from "./index";
import InputStatusIcon from "./input-status-icon";

const InputDecimalPropTypes = { ...CommonInputProps };

/**
 * @type {React.FC<typeof InputDecimalPropTypes>}
 */
export const InputDecimal = ({ value, onFocus, onChange, state }) => {
  const [localValue, setLocalValue] = useUpToDateValue(value);
  const handleChange = ({ target: { value } }) => setLocalValue(value);
  const commit = () => {
    onChange && onChange([{ price: localValue }]);
  };

  return (
    <div className="text-based-blic-with-button input-group form-width m-b0">
      <NumericFormat
        type="text"
        onChange={handleChange}
        value={localValue === null ? "" : localValue}
        onBlur={commit}
        autoComplete="off"
        className="form-control bid-attribute-field blic_field no-spin"
        onFocus={onFocus}
      />
      <InputStatusIcon state={state} />
    </div>
  );
};

InputDecimal.propTypes = InputDecimalPropTypes;
InputDecimal.defaultProps = {};

export default InputDecimal;
