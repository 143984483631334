import { t } from "@/i18n";
import type { ComponentPropsWithRef, ReactNode } from "react";
import React, { useCallback, useEffect, useRef } from "react";
import styles from "./modal.module.scss";
import clsx from "clsx";
import { useClickOutside } from "@/hooks/use-click-outside";
import { mergeRefs } from "@/lib/dom";
import { useToggle } from "@/hooks/use-toggle";
import { useEventListener } from "@/hooks/use-event-listener";

interface CustomFieldsFormModalProps {
  children: ReactNode;
  fieldCount: number;
}

function useModal() {
  const parentRef = useRef<HTMLElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalOpen, toggleOpen] = useToggle();
  useEffect(() => {
    parentRef.current?.classList[modalOpen ? "add" : "remove"](styles.modalOpen);
  }, [modalOpen]);
  useClickOutside(
    [modalRef, buttonRef],
    useCallback(() => toggleOpen.set(false), [toggleOpen]),
    { disabled: !modalOpen }
  );
  useEventListener(
    document,
    "keydown",
    useCallback(
      (event) => {
        if (event.key === "Escape") toggleOpen.set(false);
      },
      [toggleOpen]
    ),
    { disabled: !modalOpen }
  );
  return {
    buttonProps: {
      ref: mergeRefs(buttonRef, (ref) => {
        parentRef.current = ref?.parentElement ?? null;
      }),
      onClick: () => toggleOpen(),
    } satisfies ComponentPropsWithRef<"button">,
    modalRef,
  };
}

export default function CustomFieldsFormModal({ children, fieldCount }: CustomFieldsFormModalProps) {
  const { buttonProps, modalRef } = useModal();
  return (
    <>
      <button className={styles.button} {...buttonProps}>
        <span className={styles.title}>{t("custom_fields")}</span>
        <span className={styles.count}>{fieldCount}</span>
        <i className={clsx("fa fa-caret-down", styles.caret)} />
      </button>
      <div ref={modalRef} className={clsx("neumorphism-white shadow-diffuse", styles.modal)}>
        {children}
      </div>
    </>
  );
}
