import React, { useMemo } from "react";
import classNames from "classnames";
import LiDetailComponent from "../LiDetailComponent";
import {
  isLotTotalPresent,
  leadingBidPrice,
  findLicBidRange,
  isANumber,
  isPBPermittedInLot,
  isLiTotalPresent,
} from "../../../common";
import { useSelector } from "react-redux";
import { findBlicForLic, participantPreferredValue } from "../../../bidCommon";
import LiDeleteButton from "./LiDeleteButton";
import ShowParticipantChart from "../ShowParticipantChart";

const EyeButton = ({ title = "", onClick = () => {}, isHidden = true }) => {
  return (
    <a title={title} className="btn btn-default btn-xs small-btn small-blue-btn -absolute" onClick={onClick}>
      <i className={`fa fa-eye${isHidden ? "-slash" : ""}`}></i>
    </a>
  );
};

const LiBidrange = (props) => {
  const { lotsBidRange, errors } = useSelector((state) => state.lotReducers);
  const { lic, currency, locale, numberFormat, li, isPlaceBidActive } = props;

  // return if isPlaceBidActive is false
  if (!isPlaceBidActive) return <></>;

  /**
   *
   * @param {Number} value
   * @returns {String} price with currency
   */
  const getPrice = (value) => leadingBidPrice(lic, value, currency, locale, numberFormat);

  // calculate range
  let [min, max] = findLicBidRange(lotsBidRange, li);
  if (!(isANumber(min) && isANumber(max))) {
    min = "-";
    max = "-";
  }

  // do not show range if max and min are "-"
  if (min === "-" && max === "-") return <></>;

  const hasErrors = errors?.find((licId) => licId === li?.line_item_total_lic_id);

  return (
    <>
      {!!hasErrors && (
        <>
          &nbsp;
          <span className="fa fa-lg fa-times text-danger" />
        </>
      )}
      <RangeText min={min} max={max} getPrice={getPrice} {...props} />
    </>
  );
};

const isInRange = ({ value, min, max }) => {
  return min ? Number(value) >= Number(min) : Number(value) <= Number(max);
};

export const RangeText = ({ min = "", max = "", getPrice = () => {}, ...rest }) => {
  const minPrice = getPrice(min);
  const maxPrice = getPrice(max);
  const { bid_line_item_components } = useSelector((state) => state.lotReducers);

  const text = (value, success, suffix) => (
    <span className={`text-${rest.isPlaceBidActive && !success ? "danger" : "success"}`}>
      {suffix}: {value}
    </span>
  );

  const blic = findBlicForLic(rest.li?.line_item_total_lic_id, bid_line_item_components);
  const lic = rest._lotLics.find((lic) => lic?.id === rest.li?.line_item_total_lic_id);

  const price = participantPreferredValue(
    blic,
    lic,
    rest.unitOfMeasures,
    rest.unitSet,
    rest.bid,
    rest.bidLineItemComponents,
    rest.bids
  );

  return (
    <ul className="listing-link m-t10">
      <li className="boldtitle">{text(maxPrice, isInRange({ value: price, max }), "Max")}</li>
      <li></li>
      <li className="boldtitle m-t0">{text(minPrice, isInRange({ value: price, min }), "Min")}</li>
    </ul>
  );
};

const LicNameContainer = ({ li = {}, children }) => {
  return (
    <td
      className={classNames({
        "dj-vgrid-cell dj-vgrid-sticky-left": true,
        "lot-lis-cell-main title cell-lic line_item_title_bg vertical-center": true,
        "bg-light-green": li?.is_calculation,
        "tag-container": true,
      })}
    >
      <div className="cell-number">{children}</div>
    </td>
  );
};

const bidLineItemComponentValueFields = ["price", "picklist_value", "date_value", "number_value", "attribute_value"];
const bidLineItemComponentHasValue = (bidLineItemComponent) => {
  return bidLineItemComponentValueFields.some((key) => bidLineItemComponent[key] !== null);
};

const useAreBlicsPresentForLi = (lineItem, bid) => {
  const bidLineItemComponents = useSelector(({ lotReducers }) => lotReducers.bid_line_item_components);
  const lineItemComponents = useSelector(({ lotReducers }) => lotReducers.line_item_components);
  const lotItemComponentErrors = useSelector(({ lotReducers }) => lotReducers.lotItemComponentErrors);

  return useMemo(() => {
    const lotErrors = lotItemComponentErrors?.[lineItem.lot_id] || {};
    const lineItemHasErrors = Object.values(lotErrors).some((error) => error.lineItemId === lineItem.id);
    if (lineItemHasErrors) {
      return true;
    }

    const licIds = lineItemComponents
      ?.filter((lineItemComponent) => lineItemComponent?.line_item_id === lineItem?.id)
      .map((lineItemComponent) => lineItemComponent?.id);

    return bidLineItemComponents?.some((blic) => {
      return (
        licIds?.includes(blic.line_item_component_id) && bid?.id === blic.bid_id && bidLineItemComponentHasValue(blic)
      );
    });
  }, [bidLineItemComponents, lineItemComponents, lotItemComponentErrors, lineItem, bid]);
};

export default function LicName(props) {
  const { li, translations, isPlaceBidActive, isDetailView, lot, bid } = props;
  const isLotTotal = isLotTotalPresent(lot);
  const isLiTotal = isLiTotalPresent(li);
  const isPBPermitted = isPBPermittedInLot(lot);
  const areBlicsPresentForLi = useAreBlicsPresentForLi(li, bid);

  return (
    <LicNameContainer li={li}>
      {/* Show/Hide Button */}

      {!isPlaceBidActive && (
        <EyeButton isHidden={isDetailView} onClick={props.toggleDetailsView} title={translations?.open_line_item} />
      )}
      {isLiTotal && <ShowParticipantChart {...props} header={li.name} lineItem={li} />}

      {/* Li name and range */}
      <div className={`cell-number${isPlaceBidActive ? " bold font-s14" : "-title"}`}>
        {li.name}
        {(!isLotTotal || (isPBPermitted && isLiTotal)) && <LiBidrange {...props} />}
        {isPBPermitted && !li.is_calculation && isPlaceBidActive && areBlicsPresentForLi && (
          <LiDeleteButton bid={bid} translations={translations} li={li} />
        )}
      </div>
      {/* Show Li details in Detailed view */}
      <div className={isDetailView ? "-show" : "-hide"}>
        <LiDetailComponent translations={translations} isPlaceBidActive={isPlaceBidActive} />
      </div>
    </LicNameContainer>
  );
}
