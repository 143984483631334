import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import Select2 from "react-select2-wrapper";

import { useLotTemplate } from "../../../../actions/lotTemplateActions";
import { disableButton, enableButton, isIE } from "../../common";
import classNames from "classnames";

const UseLotTemplateForm = (props) => {
  const dispatch = useDispatch();
  const refBtn = useRef(null);
  const { lotTemplates, eventId, translations, modalIsOpen, setModalIsOpen } = props;
  const [selectedLotTemplateId, setSelectedLotTemplateId] = useState(0);

  const handleUseLotTemplate = (event) => {
    event.preventDefault();

    const payload = {
      event: {
        lot_template_id: selectedLotTemplateId,
      },
    };
    disableButton(refBtn.current, `${translations.use_template} <i class='fa fa-spinner fa-spin'></i>`);
    dispatch(useLotTemplate(payload, eventId)).then((response) => {
      if (response) {
        setModalIsOpen(false);
      } else {
        enableButton(refBtn.current, translations.use_template, "<i class='fa fa-check'></i>");
      }
    });
  };

  const lotTemplateOptions = () => {
    return lotTemplates.map((lotTemplate) => {
      const text = `${lotTemplate.name} (${translations.currency}: ${lotTemplate.currencies})`;
      return { text: text, id: lotTemplate.id };
    });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      className={classNames({
        "modal-content col-md-4 col-sm-4 custom-field-popup": true,
        "modal2-ie": isIE(),
      })}
      appElement={document.getElementById("advanced-lots-body")}
    >
      <form onSubmit={(e) => handleUseLotTemplate(e)}>
        <div className="block  block-transparent remove-margin-b">
          <div className="block-header bg-primary-default">
            <ul className="block-options">
              <li>
                <button type="button" onClick={() => setModalIsOpen(false)}>
                  <i className="ion-close" />
                </button>
              </li>
            </ul>
            <h3 className="block-title">{translations.load_from_library}</h3>
          </div>
        </div>
        <div className="modal-body">
          <div className="form">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="lot_template">{translations.lot_template}</label>
                  <div className="markup">
                    <Select2
                      id="lot_template"
                      data={lotTemplateOptions()}
                      onSelect={(e) => setSelectedLotTemplateId(parseInt(e.target.value))}
                      value={selectedLotTemplateId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="pull-right">
            <a
              onClick={() => setModalIsOpen(false)}
              className="button sm-butt white remove hover btn btn-default btn-sm "
            >
              {translations.cancel}
              <i className="fa fa-times m-l5"></i>
            </a>
            <button className="btn btn-primary button sm-butt save_lot_template_button" type="submit" ref={refBtn}>
              {translations.use_template} <i className="fa fa-check"></i>
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default UseLotTemplateForm;
