import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import Loader from 'react-loader-advanced';

import EditorPopupSection from '../QQEvent/EditorPopupSection';
import PopupMobile from '../QQEvent/PopupMobile';

import { fetchAdminText } from '../../../../actions/qq/adminTextActions';
import { updateOverview } from '../../../../actions/qq/editActions';
import LoaderMessage from '../../LoaderMessage';
import { fetchPipelineProjects } from '../../../../actions/qq/qqActions';
import { components }  from 'react-select';
import AsyncSelect  from 'react-select/async';
import TrixEditor from '@/components/common/TrixEditor';
import RenderHTML from "@/components/common/RenderHTML";
import IntegrationAttributes from './IntegrationAttributes';

const ReactSelectComponents = {
  Control: props => {
    const selected = props.getValue();

    if(selected.length && selected[0].label && props.menuIsOpen) {
      return <div>
        {selected.length ? <div className="form-control mb-10 p-10">{selected[0].label}</div> : ''}
        <components.Control {...props} />
      </div>
    }

    return <div>
      <components.Control {...props} />
    </div>
  },
  ValueContainer: ({ children, ...props }) => {
    const selected = props.getValue();

    if(selected.length && selected[0].label && props.selectProps.menuIsOpen) {
      return <components.ValueContainer {...props}>
        {children[children.length - 1]}
      </components.ValueContainer>
    }
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>
  }
}

class Overview extends Component {
  constructor() {
    super();
    this.datePrickerRef = '';
    this.state = {
      adminText: '',
      data: {},
      sectionToggle: false,
      editorValue: '',
      disableBtn: false,
      showPipelineProjects: false,
      associated_integration_attributes: {},
    };
  }

  UNSAFE_componentWillMount() {
    const { sectionKey, companyId } = this.props;
    this.props.fetchAdminText(companyId, sectionKey);
    const data = _.assign({}, this.props.data);

    this.setState({ data });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText.FETCH_ADMIN_TEXT_ATTRIBUTES ? newProps.adminText.FETCH_ADMIN_TEXT_ATTRIBUTES : '';
    if (newProps.default.FETCH_ADMIN_TEXT_ATTRIBUTES_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_ATTRIBUTES_DEL;
    }

    const newState = {
      adminText
    }

    if(newProps.data.event_pa_integration_attributes) {
      newState.associated_integration_attributes = {
        value: {...newProps.data.event_pa_integration_attributes.integration_attributes },
        label: newProps.data.event_pa_integration_attributes.integration_attributes.name
      };

      newState.showPipelineProjects = true;
    }

    this.setState(newState);
  }

  componentWillUnmount() {
    $(this.datePrickerRef).datetimepicker('destroy');
  }

  onEditChange(event) {
    const { value, name } = event.target;
    const tempData = _.assign({}, this.state.data);
    tempData[name] = value;
    this.setState({ data: tempData });
  }

  onChange(event) {
    this.setState({
      editorValue: event,
    });
  }

  editSection() {
    const { sectionToggle } = this.state;
    this.setState({ sectionToggle: !sectionToggle }, () => {
      $(this.datePrickerRef).datetimepicker({
        format: 'Y-m-d H:i',
        minDate: new Date(),
        step: DATETIME_PICKER_STEP_MINUTES,
        minTime: 0,
        scrollMonth: false,
        scrollInput: false,
        onChangeDateTime(currentDateTime) {
          if (currentDateTime && currentDateTime.getDate() === new Date().getDate()) {
            this.setOptions({ minTime: 0 });
          } else {
            this.setOptions({ minTime: false });
          }
        },
        onShow(currentDateTime) {
          if (currentDateTime && currentDateTime.getDate() === new Date().getDate()) {
            this.setOptions({ minTime: 0 });
          } else {
            this.setOptions({ minTime: false });
          }
        },
      });
    });

    if (!sectionToggle) { // edit section show
      const { data } = this.state;
      const editorValue = data.event_brief ? data.event_brief : '';
      this.setState({ editorValue });
    } else { // edit section hide
      const data = _.assign({}, this.props.data);
      this.setState({ data });
      $(this.datePrickerRef).datetimepicker('destroy');
    }
  }

  async updateChanges() {
    const { quoteId } = this.props;
    this.setState({ disableBtn: true });
    const deadline = $('#deadline-custom-date-time').val().trim() !== '' ? $('#deadline-custom-date-time').val().trim() : null;
    const brief = this.state.editorValue;

    const payload = {
      event: {
        name: this.state.data.name.trim(),
        deadline,
        event_brief: brief,
        quick_quotes: true,
        budget: {
          ...this.state.associated_integration_attributes.value,
          _destroy: this.state.showPipelineProjects ? 0 : 1,
          create_link: true
        },
        return_event_details: true,
      },
    };

    if(!payload.event.budget.id) {
      delete payload.event.budget;
    };

    const response = await this.props.updateOverview(quoteId, payload);
    if (response.status === 200) {
      this.setState({ data: response.data.event, disableBtn: false });

      if(!response.data.event.event_pa_integration_attributes) {
        this.setState({
          associated_integration_attributes: {},
          showPipelineProjects: false
        })
      }
      this.props.updateEventData('overview', response.data.event);
      this.editSection();
    } else {
      this.setState({ disableBtn: false });
    }
  }

  dateWithTimeZone(deadline) {
    const zoneIdx = deadline.indexOf('+');
    const zoneString = deadline.substring(zoneIdx);
    return moment(deadline).utcOffset(zoneString).format('YYYY-MM-DD HH:mm');
  }

  onPipelineChange = (event) => {
    let value = {};
    try {
      value = JSON.parse(event.value);
    } catch (err){
      // error handling for json parsing failure
    }

    const associated_integration_attributes = {
      value,
      label: event.label,
      _destroy: this.state.showPipelineProjects ? 0 : 1,
      create_link: true
    };

    this.setState({
      associated_integration_attributes
    });
  }

  promiseOptions = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      this.props.fetchPipelineProjects({
        company_integration_id: this.props.per_angusta_attributes.company_integration_id,
        user_integration_attribute_id: this.props.per_angusta_attributes.user_integration_id,
        search: inputValue
      })
      .then((data) => {
        callback(data.map(p => ({value: p.id, label: p.text})));
        return data;
      })
      .catch(err => {
        callback([])
      })
    }
  }

  disabledSection = (event) => {
    const showPipelineProjects = event.target.checked;
    const tempState = {
      ...this.state,
      showPipelineProjects
    };

    if(!showPipelineProjects) {
      tempState.associated_integration_attributes._destroy = 1;
    }

    this.setState(tempState);
  }
  
  render() {
    const { companyId, sectionKey, user, pipeline_projects, translations, per_angusta_attributes } = this.props;
    const { adminText, sectionToggle, data, disableBtn, editorValue } = this.state;

    return (
      <Loader show={ !this.state.adminText } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles priority={ 1 }>
        <div className='block-item'>
          <div className='col-md-6 p-l0 table-cell-box'>
            <div className='form-item-block same-height-block'>
              {!sectionToggle ?
                <div>
                  <h4>{translations.qq_edit.overview}</h4>
                  <a href='#' className='editbox' onClick={ () => this.editSection() }>
                    <i className='fa fa-pencil-square-o' aria-hidden='true' />
                  </a>
                  <PopupMobile
                    companyId={ companyId }
                    sectionKey={ sectionKey }
                    text={ this.state.adminText }
                    translations={ translations }
                  />
                  <form className='static-info'>
                    <div className='form-group'>
                      <label htmlFor='name'>{translations.qq_create.qq_generate_details.name_of_quote}: </label>
                      <span> {data.name}</span>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='deadline'>{translations.qq_create.qq_generate_details.deadline}: </label>
                      <span> {data.formatted_bid_deadline}</span>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='invitation_message'>{translations.qq_edit.invitation_message}: </label>
                      <span>
                        <RenderHTML content={data.event_brief} />
                      </span>
                    </div>
                    {Boolean(data.event_pa_integration_attributes) && (
                      <IntegrationAttributes
                        detailsTranslations={translations.qq_create.qq_generate_details}
                        integrationAttributes={data.event_pa_integration_attributes.integration_attributes}
                        perAngustaAttributes={per_angusta_attributes}
                      />
                    )}
                  </form>
                </div> :
                <div>
                  <h4>
                    <b>{translations.qq_edit.edit_overview}:</b>
                  </h4>
                  <div className='form-group'>
                    <label>{translations.qq_create.qq_generate_details.name_of_quote} :</label>
                    <input
                      type='text'
                      value={ data.name }
                      onChange={ e => this.onEditChange(e) }
                      className='form-control'
                      placeholder={ translations.qq_edit.quote_name }
                      name='name'
                    />
                    {!data.name && <span className='text-danger'>{translations.error_message.required}</span>}
                    {data.name && !data.name.trim() && <span className='text-danger'>{translations.error_message.no_white_space}</span>}
                    {data.name && data.name.trim().length > 50 &&
                    <span className='text-danger'>
                      {translations.error_message.name_char_long.replace("%{name_string}", translations.qq_edit.name).replace("%{name_limit}", 50)}
                    </span>}
                  </div>
                  <div className='form-group'>
                    <label>{translations.qq_create.qq_generate_details.deadline} :</label>
                    <input
                      id='deadline-custom-date-time'
                      ref={ (el) => { this.datePrickerRef = el; } }
                      defaultValue={ data.deadline && data.deadline !== '' ? this.dateWithTimeZone(data.deadline) : '' }
                      className='form-control date-picker-cstm'
                      type='text'
                      placeholder= {translations.qq_create.qq_generate_details.deadline}
                      name='bid_deadline'
                      autoComplete='off'
                    />
                    <span className='time-zone' >{ moment.tz(user.time_zone).format('z') }</span>
                  </div>
                  <div className='form-group'>
                    <label>{translations.qq_edit.invitation_message} :</label>
                    <TrixEditor value={editorValue} onChange={(e) => this.onChange(e)} />
                  </div>

                  {per_angusta_attributes.company_integration_id
                    && per_angusta_attributes.user_integration_id
                    && <div className="form-group">
                      <div className="checkbox">
                        <div>
                          <input
                            name='with_pipelineprojects'
                            id='checkbox45'
                            type='checkbox'
                            checked={this.state.showPipelineProjects}
                            onChange={ this.disabledSection }
                          />
                          <label htmlFor="checkbox45"><span></span>{translations.qq_create.qq_generate_details.link_pipeline_project}</label>
                        </div>
                      </div>
                      {this.state.showPipelineProjects && <AsyncSelect
                        loadOptions={this.promiseOptions}
                        className="resource-type-select"
                        cacheOptions
                        onChange={this.onPipelineChange}
                        value={this.state.associated_integration_attributes}
                        loadingMessage={(message) => {
                          if(message.inputValue.length < 3 ) {
                            return translations.qq_create.qq_generate_details.character_search_warning.replace("%{character_length}", (3 - message.inputValue.length));
                          } else {
                            return translations.qq_create.qq_generate_details.searching;
                          }
                        }}
                        noOptionsMessage={(message) => {
                          if(message.inputValue.length < 3 ) {
                            return translations.qq_create.qq_generate_details.character_search_warning.replace("%{character_length}", (3 - message.inputValue.length));
                          } else {
                            return translations.qq_create.qq_generate_details.no_results_found;
                          }
                        }}
                        components={ReactSelectComponents}
                        />
                      }
                    </div>
                  }

                  <button
                    type='button'
                    className='btn btn-secondary cancel-reply m-r5'
                    onClick={ () => this.editSection() }
                  >{translations.summary_status.message.cancel}
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary cancel-reply'
                    onClick={ () => this.updateChanges() }
                    disabled={ disableBtn || !data.name || !data.name.trim() || data.name.trim().length > 50 }
                  >{translations.qq_edit.save} {disableBtn && <i className='fa fa-cog fa-spin fa-fw' />}
                  </button>
                </div>}
            </div>
          </div>
          <EditorPopupSection 
            text={ adminText }
            companyId={ companyId }
            sectionKey={ sectionKey }
            translations={ translations }
            updateText={ (adminText) => { this.setState({ adminText }); } }
          />
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  adminText: state.adminTextReducer,
  default: state.deleteAdminTextReducer,
  // selectedLang: state.changeLanguageReducer,
  pipeline_projects: state.qqReducer.pipeline_projects,
  per_angusta_attributes: state.userReducer.details.per_angusta_attributes
});

const mapDispatchToProps = dispatch => ({
  fetchAdminText: (companyId, sectionKey) => dispatch(fetchAdminText(companyId, sectionKey)),
  updateOverview: (quoteId, payload) => dispatch(updateOverview(quoteId, payload)),
  fetchPipelineProjects: (request) => dispatch(fetchPipelineProjects(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
