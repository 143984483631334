import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import InfoPopover from "../../InfoPopover";

class QQListElement extends Component {
  constructor(props) {
    super(props);
  }

  searchByQQState(e, query) {
    e.preventDefault();
    this.props.searchByStatus(query);
  }

  confirmation(e, QQevent, qqState) {
    e.preventDefault();
    this.props.confirmation(QQevent, qqState);
  }

  render() {
    const { QQevent, searchByStatus, translations, tooltip_translations, company, user } = this.props;
    return (
      <tr>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            {translations.qq_listing.qq_list_table.status}
            <InfoPopover
              title={tooltip_translations.host.event.status.title}
              popoverContent={tooltip_translations.host.event.status.body}
            />
          </div>
          <div className={`btn-group button-status button-${QQevent.event_state.toLowerCase()}`}>
            <button
              type="button"
              className={`dropdown-toggle status-event ${QQevent.event_state.toLowerCase()} form-control`}
              data-toggle="dropdown"
            >
              {translations.qq_listing.qq_list_table[QQevent.event_state.toLowerCase()]} <span className="caret" />
            </button>
            <ul className="dropdown-menu" role="menu">
              <li>
                <a href="#" onClick={(e) => this.searchByQQState(e, QQevent.event_state)}>
                  {translations.qq_listing.qq_list_table[QQevent.event_state.toLowerCase()]}
                </a>
              </li>

              {QQevent.current_user_has_edit_permission === true &&
                ((QQevent.current_state === "completed" && (
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        this.confirmation(e, QQevent, "close");
                      }}
                    >
                      {translations.qq_listing.qq_list_table.close_event}
                    </a>
                  </li>
                )) ||
                  (QQevent.event_state === "closed" && QQevent.current_state === "closed" && (
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          this.confirmation(e, QQevent, "reopen");
                        }}
                      >
                        {translations.qq_listing.qq_list_table.reopen}
                      </a>
                    </li>
                  )))}
              {((user && user.qq_allow_to_delete_event === "true") || QQevent.current_user_has_delete_permission) && (
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      this.confirmation(e, QQevent, "delete");
                    }}
                  >
                    {translations.qq_listing.qq_list_table.delete}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </td>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            {translations.qq_listing.qq_list_table.quick_quote_name}
            <InfoPopover
              title={tooltip_translations.host.event.name.title}
              popoverContent={tooltip_translations.host.event.name.body}
            />
          </div>
          <div className="event-text">
            {QQevent.current_state === "edit" && QQevent.current_user_has_edit_permission ? (
              <a href={`/quick_quotes/quote/${QQevent.id}/edit`} title={QQevent.name}>
                {QQevent.name}
              </a>
            ) : (
              <a href={`/quick_quotes/quote/${QQevent.id}`} title={QQevent.name}>
                {QQevent.name}
              </a>
            )}
          </div>
          {company && company.publicly_visible_prefix_event ? (
            <div className="event-id">
              <label className="event-type highlight-text">
                {translations.summary_status.id}: {QQevent.id}
              </label>
            </div>
          ) : (
            ""
          )}
        </td>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            {translations.qq_listing.qq_list_table.quick_quote_owner.id}
            <InfoPopover
              title={tooltip_translations.host.event.owner.title}
              popoverContent={tooltip_translations.host.event.owner.body}
            />
          </div>
          <div className="owner-box">
            <span className="light-gray">{translations.qq_listing.qq_list_table.host}:</span> {QQevent.user.name}
          </div>
          <div className="owner-box">
            <span className="light-gray">{translations.qq_listing.qq_list_table.company}:</span>{" "}
            {QQevent.user.user_company_name}
          </div>
        </td>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            <div className="col-caption hidden-lg hidden-md hidden-sm">
              {translations.qq_listing.qq_list_table.bids}
              <InfoPopover
                title={tooltip_translations.host.event.bids.title}
                popoverContent={tooltip_translations.host.event.bids.body}
              />
            </div>
          </div>
          <span className="key-deadline">{QQevent.total_number_bidding}</span>
        </td>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            <div className="col-caption hidden-lg hidden-md hidden-sm">
              {translations.qq_listing.qq_list_table.acceptance}
              <InfoPopover
                title={tooltip_translations.host.event.acceptance.title}
                popoverContent={tooltip_translations.host.event.acceptance.body}
              />
            </div>
          </div>
          <span className="key-deadline">
            {`${QQevent.total_number_participating_and_accepted} / ${QQevent.total_number_of_participants}`}
          </span>
        </td>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            <div className="col-caption hidden-lg hidden-md hidden-sm">
              {translations.qq_listing.qq_list_table.go_live_date}
              <InfoPopover
                title={tooltip_translations.host.event.live_date.title}
                popoverContent={tooltip_translations.host.event.live_date.body}
              />
            </div>
          </div>
          <span className="key-deadline">{`${QQevent.formatted_live_date || ""}`}</span>
        </td>
        <td>
          <div className="col-caption hidden-lg hidden-md hidden-sm">
            {translations.qq_listing.qq_list_table.next_key_deadline}
            <InfoPopover
              title={tooltip_translations.host.event.time_until_event.title}
              popoverContent={tooltip_translations.host.event.time_until_event.body}
            />
          </div>
          <span className="key-deadline">
            {QQevent.bid_deadline && moment(new Date(QQevent.bid_deadline)).isBefore()
              ? translations.qq_listing.qq_list_table.qq_finished
              : translations.qq_listing.qq_list_table.qq_deadline}
            : {QQevent.formatted_bid_deadline}
          </span>
        </td>
        <td>
          <div className="btn-action">
            <span className="small-gray-box">
              <a>
                {QQevent.lots_size} {translations.qq_listing.qq_list_table.lots}
              </a>
            </span>
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // selectedLang: state.changeLanguageReducer,
  // tooltripData: state.tooltipReducer,
});

export default connect(mapStateToProps, null)(QQListElement);
