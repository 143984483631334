import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { unstable_batchedUpdates } from "react-dom";
import moment from "moment-timezone";

import { updateDocument, deleteDocument, showSuccess } from "../../../actions/activityActions";
import { getUpdatedValue } from "../../advanced_lots/lots/lotCommon";
import { Confirm } from "../../common/Confirm";
import { default as DropzoneComponent } from "../../common/DropzoneComponent";
import config from "../../../config";

const documentTab = (props) => {
  const { documents, translations } = props;
  const { draftActivity: activity } = props;
  const [files, setFiles] = useState(documents);
  const [fileId, setFileId] = useState();
  const [fileName, setFileName] = useState();
  const [file, setFile] = useState();
  const [nameOfDocument, setNameOfDocument] = useState();
  const [includeInNotification, setIncludeInNotification] = useState();
  const [documentIdToDelete, setDocumentIdToDelete] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (file && fileId === undefined) {
      unstable_batchedUpdates(() => {
        setFiles([].concat(files, file));
        setFile();
      });
    }
  }, [file]);

  const handleSuccess = function (file, response) {
    var myDropzone = this;
    if (response.status == 404) {
      file.status = Dropzone.ERROR;
      myDropzone.emit("error", file, Dropzone.options.dictMaxFilesExceeded);
      myDropzone.emit("complete", file);
    } else if (file.status === "success") {
      setFile(response.document);
      dispatch(showSuccess(translations.updated_successfully));
      myDropzone.removeFile(file);
    }
  };

  const handleDocumentEdit = (file) => {
    unstable_batchedUpdates(() => {
      setFileId(file.id);
      setNameOfDocument(file.name_of_document);
      setIncludeInNotification(file.include_in_invite);
      setFileName(file.filename);
    });
  };

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
    document.getElementById(`inputfileLabel`).innerHTML = event.target.value.split("\\").pop();
  };

  const handleUpdate = () => {
    let params = {
      document: {
        name_of_document: nameOfDocument,
        include_in_invite: includeInNotification,
      },
    };
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const obj = {};
        obj.name = file.name;
        obj.source = reader.result;
        params["file"] = obj;
        updateDoc(params);
      };
    } else {
      updateDoc(params);
    }
  };

  const updateDoc = (params) => {
    dispatch(updateDocument(params, activity.id, fileId, translations.updated_successfully)).then((response) => {
      if (response.status === 200) {
        setFiles(getUpdatedValue(files, response.data.document));
        resetForm();
      }
    });
  };

  const resetForm = () => {
    unstable_batchedUpdates(() => {
      setFileId();
      setNameOfDocument("");
      setIncludeInNotification("");
      setFileName("");
    });
  };

  const handleDocumentDelete = () => {
    let newFiles = [...files];
    dispatch(deleteDocument(activity.id, documentIdToDelete, translations.updated_successfully)).then((response) => {
      if (response.status === 204) {
        newFiles = newFiles.filter((f) => f.id !== documentIdToDelete);
        unstable_batchedUpdates(() => {
          setDocumentIdToDelete("");
          setFiles(newFiles);
        });
      }
    });
  };

  return (
    <div
      id="document"
      className="bg-white border-t0 col-md-12 form-item-block m-b0"
      role="tabpanel"
      aria-labelledby="document-tab"
    >
      {fileId && (
        <div className="col-md-8 p-l0">
          <div className="form-group">
            <label htmlFor={translations.name}>{translations.name}</label>
            <input
              type="text"
              className="form-control input-sm"
              autoComplete="off"
              onChange={(e) => setNameOfDocument(e.target.value)}
              value={nameOfDocument}
            />
          </div>
          <div className="form-group">
            <label
              className="css-input css-checkbox css-checkbox-default"
              htmlFor={translations.include_in_notification}
            >
              <input
                type="checkbox"
                onChange={() => setIncludeInNotification(!includeInNotification)}
                checked={includeInNotification}
                className="cursor-pointer"
              />
              &nbsp;
              {translations.include_in_notification}
            </label>
          </div>
          <div className="markup m-t10 text-center form-group">
            <input
              type="file"
              name="file"
              id="file"
              className="inputfile inputfile-6 wid-100"
              onChange={handleFileUpload}
            />
            <label htmlFor="file" className="wid-100" style={{ maxWidth: "100%" }}>
              <span id="inputfileLabel">{fileName}</span>
              <strong className="float-right">
                <i className="fa fa-upload"></i>
              </strong>
            </label>
          </div>
          <div className="form-group btn-group-sm">
            <button name="button" className="btn btn-sm btn-primary" onClick={handleUpdate}>
              {translations.save}
            </button>
            <button name="button" className="btn btn-sm btn-default m-l10" onClick={resetForm}>
              {translations.cancel}
            </button>
          </div>
        </div>
      )}
      {!fileId && (
        <DropzoneComponent
          url={`${config.SRM_BASE_URL}/activities/${activity.id}/documents`}
          parallelUploads={1}
          uploadMultiple={false}
          autoProcessQueue={true}
          addRemoveLinks={false}
          maxFilesize={props.maxFileSize}
          files={files}
          handleSuccess={handleSuccess}
        />
      )}
      <div className="m-t30 scrolling_inner">
        <table className="custom-responsive-table table-layout-fixed">
          <thead className="sticky-header table-block-title with-filter">
            <tr>
              <th scope="col">{translations.name}</th>
              <th scope="col">{translations.include_in_notification}</th>
              <th scope="col">{translations.created_at}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {files.length > 0 &&
              files.map((file) => (
                <tr key={file.id}>
                  <td className="table-cell-ellipsis">
                    <a target="_blank" href={`/srm/activities/${activity.id}/documents/${file.id}`}>
                      {file.name_of_document}
                    </a>
                  </td>
                  <td>
                    {file.include_in_invite ? (
                      <i className="fa fa-check green" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-times red" aria-hidden="true"></i>
                    )}
                  </td>
                  <td>{moment.utc(moment(file.created_at)).format("DD MMMM YYYY")}</td>
                  <td>
                    <a
                      className="btn btn-icon"
                      title={translations.edit}
                      onClick={() => handleDocumentEdit(file)}
                      disabled={fileId}
                    >
                      <i className="fa fa-pencil"></i>
                    </a>
                    <a
                      className="btn btn-icon"
                      title={translations.delete}
                      onClick={() => setDocumentIdToDelete(file.id)}
                      data-toggle="modal"
                      data-target="#documentConfirmModal"
                      rel="nofollow"
                      disabled={fileId}
                    >
                      <i className="fa fa-trash-o red-color"></i>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Confirm
        translations={translations}
        message={translations.are_you_sure}
        htmlId="documentConfirmModal"
        onOkayButtonClick={() => handleDocumentDelete()}
      />
    </div>
  );
};

export default documentTab;
