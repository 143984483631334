import React from "react";
import Select2 from "react-select2-wrapper";
import PropTypes from "prop-types";
import Tooltip from "@/components/common/Tooltip";
import { t } from "@/i18n";

interface IAutomaticTemplateSelection {
  configuration_setting_values: Record<string, string>;
  onChange: (value: string, name: string) => void;
  settingName: string;
}

export default function AutomaticTemplateSelection({
  configuration_setting_values,
  onChange,
  settingName,
}: IAutomaticTemplateSelection): React.JSX.Element {
  const options: string[] = ["disabled"];

  if (
    [
      "automatic_lot_template_selection",
      "automatic_questionnaire_template_selection",
      "automatic_document_template_selection",
      "automatic_event_template_selection",
      "automatic_supplier_selection",
    ].includes(settingName)
  ) {
    options.push("enabled");
  }

  if (
    [
      "automatic_lot_template_selection",
      "automatic_document_template_selection",
      "automatic_supplier_selection",
    ].includes(settingName)
  ) {
    options.push("enforced");
  }

  const formattedOptions = options.map((option) => ({ id: option, text: t(option) }));

  const handleSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    onChange(value, name);
  };

  return (
    <div className="row m-b30">
      <div className="form-item-block-title col-sm-6">{t(settingName)}</div>
      <div className="input inline col-md-2 col-sm-4 col-xs-11">
        <div>
          <Select2
            className="form-control"
            name={settingName}
            value={configuration_setting_values[settingName]}
            data={formattedOptions}
            onSelect={handleSelection}
            options={{
              minimumResultsForSearch: -1,
            }}
          />
        </div>
      </div>
      <div className="vertical-center">
        <Tooltip
          body={t(`tooltips:contextual_help.host.configuration.${settingName}.body`)}
          title={t(`tooltips:contextual_help.host.configuration.${settingName}.title`)}
        />
      </div>
    </div>
  );
}

AutomaticTemplateSelection.propTypes = {
  configuration_setting_values: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  settingName: PropTypes.string.isRequired,
};
