import { assert } from "@/utils/assert";
import { Controller } from "@hotwired/stimulus";

/**
 * IntegrationFilterController handles the filtering of integrations
 * based on their status ('All', 'Enabled', 'Disabled').
 */
export default class extends Controller {
  static targets = ["integrationFilterSection", "filterButton"];

  connect() {
    // Automatically apply the 'All' filter on load
    this.showAllIntegrations();
  }

  /**
   * Handles the filtering of integration sections based on the selected filter.
   * @param {Event} event - The click event triggered by filter buttons.
   */
  filter(event) {
    const filter = event.target.dataset.filter;

    // Apply the filter to show/hide sections
    this.integrationFilterSectionTargets.forEach((section) => {
      section.style.display = this.isSectionVisible(section, filter) ? "" : "none";
    });

    // Update the active button styling
    this.updateActiveButton(event.target);
  }

  /**
   * Determines if a section should be visible based on the current filter.
   * @param {HTMLElement} section - The integration section element.
   * @param {string} filter - The current filter ('All', 'Enabled', 'Disabled').
   * @returns {boolean} - Whether the section should be visible.
   */
  isSectionVisible(section, filter) {
    return filter === "All" || section.classList.contains(`integration-${filter.toLowerCase()}`);
  }

  /**
   * Updates the active filter button by toggling the 'active' class.
   * @param {HTMLElement} activeButton - The button that was clicked.
   */
  updateActiveButton(activeButton) {
    this.filterButtonTargets.forEach((button) => {
      button.classList.toggle("active", button === activeButton);
    });
  }

  /**
   * Automatically triggers the 'All' filter button click on page load.
   */
  showAllIntegrations() {
    const allButton = this.filterButtonTargets.find((button) => button.dataset.filter === "All");
    assert(allButton, "No 'All' button found!");
    allButton.click();
  }
}
