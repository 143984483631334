import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { unstable_batchedUpdates } from "react-dom";
import moment from "moment-timezone";

import TrixEditor from "../../common/TrixEditor";
import { createNote, updateNote, deleteNote } from "../../../actions/activityActions";
import { getUpdatedValue } from "../../advanced_lots/lots/lotCommon";
import { Confirm } from "../../common/Confirm";

const notesTab = (props) => {
  const { translations, draftActivity: activity, notes: existingNotes } = props;
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [noteId, setNoteId] = useState("");
  const [notes, setNotes] = useState(existingNotes);
  const [includeInNotification, setIncludeInNotification] = useState(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState();
  const dispatch = useDispatch();

  const submitNote = () => {
    const params = {
      note: {
        title: title,
        body: body,
        include_in_notification: includeInNotification,
      },
    };
    dispatch(createNote(params, activity.id, translations.updated_successfully)).then((response) => {
      if (response.status === 201) {
        setNotes([].concat(notes, response.data.note));
        resetForm();
      }
    });
  };

  const resetForm = () => {
    unstable_batchedUpdates(() => {
      setNoteId("");
      setTitle("");
      setBody("");
      setIncludeInNotification(false);
    });
  };

  const handleEditNote = (note) => {
    unstable_batchedUpdates(() => {
      setNoteId(note.id);
      setTitle(note.title);
      setBody(note.body);
      setIncludeInNotification(note.include_in_notification);
    });
  };

  const handleUpdateNote = () => {
    const params = {
      note: {
        title: title,
        body: body,
        include_in_notification: includeInNotification,
      },
    };
    dispatch(updateNote(activity.id, noteId, params, translations.updated_successfully)).then((response) => {
      if (response.status === 200) {
        setNotes(getUpdatedValue(notes, response.data.note));
        resetForm();
      }
    });
  };

  const handleNoteDelete = () => {
    let newNotes = [...notes];
    dispatch(deleteNote(activity.id, noteIdToDelete, translations.updated_successfully)).then((response) => {
      if (response.status === 204) {
        newNotes = newNotes.filter((n) => n.id !== noteIdToDelete);
        unstable_batchedUpdates(() => {
          setNoteIdToDelete("");
          setNotes(newNotes);
        });
      }
    });
  };

  const noteButton = () => {
    if (noteId) {
      return (
        <>
          <button name="button" className="btn btn-sm btn-primary" onClick={handleUpdateNote}>
            {translations.save}
          </button>
          <button name="button" className="btn btn-sm btn-default m-l10" onClick={resetForm}>
            {translations.cancel}
          </button>
        </>
      );
    } else {
      return (
        <button name="button" className="btn btn-sm btn-primary" onClick={submitNote}>
          <i className="fa fa-plus-circle font-s14"></i>&nbsp; {translations.add}
        </button>
      );
    }
  };
  return (
    <div
      id="notes"
      className="bg-white border-t0 col-md-12 form-item-block m-b0"
      role="tabpanel"
      aria-labelledby="notes-tab"
    >
      <div className="form-group">
        <label htmlFor={translations.title}>{translations.title}</label>
        <input
          type="text"
          className="form-control input-sm"
          autoComplete="off"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
      </div>
      <div className="form-group">
        <label className="css-input css-checkbox css-checkbox-default" htmlFor={translations.include_in_notification}>
          <input
            type="checkbox"
            onChange={() => setIncludeInNotification(!includeInNotification)}
            checked={includeInNotification}
            className="cursor-pointer"
          />
          &nbsp;
          {translations.include_in_notification}
        </label>
      </div>
      <div className="form-group">
        <label htmlFor={translations.note}>{translations.note}</label>
        <TrixEditor value={body} onChange={setBody} />
      </div>
      <div className="btn-group-sm form-group">{noteButton()}</div>
      <div className="height-145 m-t25 scrolling_inner">
        <table className="custom-responsive-table table-borderless table-condensed table-layout-fixed">
          <thead className="sticky-header table-block-title with-filter">
            <tr>
              <th className="wid-20" scope="col">
                {translations.title}
              </th>
              <th className="wid-35" scope="col">
                {translations.note}
              </th>
              <th className="wid-20" scope="col">
                {translations.include_in_notification}
              </th>
              <th className="wid-15" scope="col">
                {translations.created_at}
              </th>
              <th className="wid-10" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {notes.length > 0 &&
              notes.map((note) => (
                <tr key={note.id}>
                  <td className="table-cell-ellipsis" title={note.title}>
                    {note.title}
                  </td>
                  <td dangerouslySetInnerHTML={{ __html: note.body }} />
                  <td>
                    {note.include_in_notification ? (
                      <i className="fa fa-check green" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-times red" aria-hidden="true"></i>
                    )}
                  </td>
                  <td>{moment.utc(moment(note.created_at)).format("DD MMMM YYYY")}</td>
                  <td>
                    <a
                      className="btn btn-icon"
                      title={translations.edit}
                      onClick={() => handleEditNote(note)}
                      disabled={noteId}
                    >
                      <i className="fa fa-pencil"></i>
                    </a>
                    <a
                      className="btn btn-icon"
                      title={translations.delete}
                      onClick={() => setNoteIdToDelete(note.id)}
                      data-toggle="modal"
                      data-target="#noteConfirmModal"
                      rel="nofollow"
                      disabled={noteId}
                    >
                      <i className="fa fa-trash-o red-color"></i>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Confirm
        translations={translations}
        message={translations.are_you_sure}
        htmlId="noteConfirmModal"
        onOkayButtonClick={() => handleNoteDelete()}
      />
    </div>
  );
};

export default notesTab;
