import React from "react";
import moment from "moment";

import { CommonInputProps } from "./index";

import ReactDatePicker from "react-datepicker";
import InputStatusIcon from "./input-status-icon";

const InputDatePropTypes = {
  ...CommonInputProps,
};

/**
 * @type {React.FC<typeof InputDatePropTypes>}
 */
export const InputDate = ({ value, onChange, state }) => {
  const handelChange = (value) => {
    const date_value = moment(value).format("YYYY-MM-DD HH:mm");
    onChange && onChange([{ date_value }]);
  };

  return (
    <div className="text-based-blic-with-button input-group -datepicker m-b0">
      <ReactDatePicker
        portalId="react-datepicker-portal"
        selected={value}
        onChange={handelChange}
        timeIntervals={15}
        name="dateValue"
        showTimeSelect
        timeFormat="HH:mm"
        dateFormat="yyyy-MM-dd H:mm"
        timeCaption="time"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        className="dtpicker form-control"
        autoComplete="off"
      />
      <InputStatusIcon state={state} />
    </div>
  );
};

InputDate.propTypes = InputDatePropTypes;
InputDate.defaultProps = {
  showTitle: true,
};

export default InputDate;
