import React, { useCallback } from "react";
import { useAppDispatch } from "@/hooks/redux";
import { Comparison, type Leaf, type NormalizedNode, type NumberParameter } from "@/slices/rules/types";
import { comparisonChanged, numberChanged } from "@/slices/rules";
import { assert } from "@/utils/assert";
import { useDebouncedSync } from "@/hooks/use-debounced-sync";
import { inEnum } from "@/utils/inEnum";
import { Select } from "@/cl/select";
import { Input } from "@/cl/input";
import styles from "../node.module.scss";
import { t } from "@/i18n";
import { Collapsible, InputContainer } from "../leaf-node";
import { useParentCollapsed } from "../collapse-context";
import type { AppDispatch } from "@/store";

function useInputs(node: NormalizedNode<Leaf<NumberParameter>>, dispatch: AppDispatch) {
  const [value, handleChange] = useDebouncedSync(
    node.state.leaf_parameter.target_figure,
    useCallback((value) => dispatch(numberChanged({ id: node.uuid, value })), [dispatch, node.uuid])
  );
  return { value, handleChange };
}

export default function NumberParameter({ node }: { node: NormalizedNode<Leaf<NumberParameter>> }) {
  const dispatch = useAppDispatch();
  const { value, handleChange } = useInputs(node, dispatch);
  const collapsed = useParentCollapsed();
  return (
    <>
      <span className={styles.word}>{t("workflow_rules.joiner.is")}</span>
      <Collapsible
        collapsed={collapsed}
        fallback={t("workflow_rules.comparison." + node.state.comparison.toLowerCase())}
      >
        <InputContainer>
          <Select
            name={`comparison-${node.uuid}`}
            value={node.state.comparison}
            onChange={(e) => {
              assert(inEnum(Comparison, e.target.value), "Invalid comparison");
              dispatch(comparisonChanged({ id: node.uuid, comparison: e.target.value }));
            }}
            aria-label={t("workflow_rules.select_comparison")}
            options={Object.values(Comparison).map((comparison) => ({
              value: comparison,
              label: t("workflow_rules.comparison." + comparison.toLowerCase()),
            }))}
          />
        </InputContainer>
      </Collapsible>
      <Collapsible collapsed={collapsed} fallback={value}>
        <InputContainer>
          <Input
            name={`value-${node.uuid}`}
            type="number"
            value={value}
            onChange={(e) => handleChange(e.target.valueAsNumber ?? Number(e.target.value))}
          />
        </InputContainer>
      </Collapsible>
    </>
  );
}
