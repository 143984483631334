import React, { useState } from "react";
import { ReactTags } from "react-tag-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { unstable_batchedUpdates } from "react-dom";

import {
  searchContacts,
  searchHostUsers,
  createConcernedParty,
  destroyConcernedParty,
  updateConcernedParty,
} from "../../../actions/activityActions";
import Tooltip from "../../common/Tooltip";
import { getUpdatedValue } from "../../advanced_lots/lots/lotCommon";

const assignmentTab = (props) => {
  const {
    translations,
    companyId,
    concernedParties,
    draftActivity: activity,
    setConcernedParties,
    hostOwnerEmails,
    setHostOwnerEmails,
  } = props;

  const { supplier_id, called_from } = useSelector((state) => state.ActivityReducer);

  const [contacts, setContacts] = useState(concernedParties.filter((cp) => cp.concerned_type === "Srm::Contact"));
  const [hostOwners, setHostOwners] = useState(filterHostOwners(concernedParties));
  // hO - Host Owners
  const [hOSuggestions, setHOSuggestions] = useState([]);
  const [contactSuggestions, setContactSuggestions] = useState([]);
  const dispatch = useDispatch();
  const { tooltips } = translations;

  // Calling SRM API to search the contacts once three characters are entered
  // by the user
  async function fetchContactSuggestions(value) {
    if (value.length <= 2) {
      setContactSuggestions([]);
    } else {
      const contacts = await dispatch(searchContacts(value, 10, called_from === "SUPPLIER" ? supplier_id : null));
      const contactSuggestion = Array.isArray(contacts) ? contacts : [];
      setContactSuggestions(formatDataAccordingToTags(contactSuggestion));
    }
  }

  // Calling V2 API to search the company default group users once three
  // characters are entered by the user
  async function fetchHostSuggestions(value) {
    if (value.length <= 2) {
      setHOSuggestions([]);
    } else {
      const hostUsers = await dispatch(searchHostUsers(value, companyId, 10));
      const hostSuggestions = Array.isArray(hostUsers) ? hostUsers : [];
      setHOSuggestions(formatDataAccordingToTags(hostSuggestions));
    }
  }

  const deleteTag = (i, objects, stateUpdateFunction) => {
    const deletedTag = objects[i];
    const newTags = [...objects];
    const concernedPartyId = deletedTag.concerned_party_id;
    dispatch(destroyConcernedParty(concernedPartyId, activity.id, translations.updated_successfully)).then((status) => {
      if (status === 204) {
        newTags.splice(i, 1);
        const updatedConcernedParties = concernedParties.filter((cp) => cp.id !== concernedPartyId);
        unstable_batchedUpdates(() => {
          stateUpdateFunction(newTags);
          setConcernedParties(updatedConcernedParties);
          const newHostOwnerEmails = hostOwnerEmails.filter((ho) => ho.cp_id !== concernedPartyId);
          setHostOwnerEmails(newHostOwnerEmails);
        });
      }
    });
  };

  // For the host we save the tag and display the added hosts on the input itself so we use the above method deleteTag
  // and for contact we display a table below with the list of contacts and a checkbox whether you want to notify them
  // or not so using a separate method deleteContact for deleting those
  const deleteContact = (contact) => {
    dispatch(destroyConcernedParty(contact.id, activity.id, translations.updated_successfully)).then((status) => {
      if (status === 204) {
        let newContacts = contacts.filter((c) => c.id !== contact.id);
        const updatedConcernedParties = concernedParties.filter((cp) => cp.id !== contact.id);
        unstable_batchedUpdates(() => {
          setContacts(newContacts);
          setConcernedParties(updatedConcernedParties);
        });
      }
    });
  };

  // The react-tag-autocomplete library requires the objects with value and label
  // key so formatting the data in that format
  const formatDataAccordingToTags = (tags) => tags.map(({ id, email }) => ({ value: id, label: email }));

  const transformTag = (tag) => ({ id: tag.value, email: tag.label, ...tag });

  // Create the ConcernedParty object in database (send an AJAX request to do that)
  const onAddition = (tag, partyType, owner, setter, objects) => {
    const params = {
      concerned_party: {
        concerned_type: partyType,
        concerned_id: tag.id,
        owner: owner,
      },
    };
    dispatch(createConcernedParty(params, activity.id, translations.updated_successfully)).then((response) => {
      if (response.status === 201) {
        const concernedParty = response.data.concerned_party;
        tag["concerned_party_id"] = concernedParty.id;
        unstable_batchedUpdates(() => {
          setConcernedParties([].concat(concernedParties, concernedParty));
          if (concernedParty.concerned_type === "User" && concernedParty.owner) {
            setter([].concat(objects, tag));
            setHostOwnerEmails(
              [].concat(hostOwnerEmails, {
                id: concernedParty.user_id,
                email: concernedParty.user_email,
                cp_id: concernedParty.id,
              })
            );
          } else {
            // For contact we save the concerned party object as it is so concating it separately
            setter([].concat(objects, concernedParty));
          }
        });
      }
    });
  };

  // Update the concerned party and set the owner to true or false (depends on the notify checkbox)
  const makeContactOwner = (contact) => {
    const params = {
      concerned_party: {
        owner: !contact.owner,
      },
    };
    dispatch(updateConcernedParty(params, contact.id, activity.id, translations.updated_successfully)).then(
      (response) => {
        if (response.status === 200) {
          setContacts(getUpdatedValue(contacts, response.data.concerned_party));
        }
      }
    );
  };

  const onShouldExpand = (value) => value?.length > 2;
  const suggestionsTransform = (_, suggestions) => suggestions?.slice(0, 10);

  return (
    <div id="assignment" className="tab-pane fade in active" role="tabpanel" aria-labelledby="assignment-tab">
      <div className="bg-white border-t0 col-md-12 form-item-block m-b0">
        <div className="form-group">
          <Tooltip
            title={tooltips.host_owners.title}
            body={tooltips.host_owners.body}
            additionalClasses={"pull-left vertical-align-top"}
          />
          <label htmlFor={translations.host_owners}>{translations.host_owners}</label>
          {/* Host Owners autocomplete field */}
          <ReactTags
            labelText=""
            selected={hostOwners}
            suggestions={hOSuggestions}
            onDelete={(i) => deleteTag(i, hostOwners, setHostOwners)}
            onAdd={(tag) => onAddition(transformTag(tag), "User", true, setHostOwners, hostOwners)}
            onInput={(value) => fetchHostSuggestions(value)}
            placeholderText={translations.choose_hosts}
            noOptionsText={translations.no_matching_contact}
            onShouldExpand={onShouldExpand}
            suggestionsTransform={suggestionsTransform}
          />
        </div>
        <div className="form-group">
          <Tooltip
            title={tooltips.concerned_contacts.title}
            body={tooltips.concerned_contacts.body}
            additionalClasses={"pull-left vertical-align-top"}
          />
          <label htmlFor={translations.supplier_contacts}>{translations.supplier_contacts}</label>
          {/* Contacts autocomplete field */}
          <ReactTags
            labelText=""
            suggestions={contactSuggestions}
            // For the contacts we are not displaying tags here so onDelete is not relevant but for the library it is
            // a required callback so just adding i.length to make it work
            onDelete={(i) => i.length}
            onAdd={(tag) => onAddition(transformTag(tag), "Srm::Contact", false, setContacts, contacts)}
            onInput={(value) => fetchContactSuggestions(value)}
            placeholderText={translations.choose_contacts}
            noOptionsText={translations.no_matching_contact}
            onShouldExpand={onShouldExpand}
            suggestionsTransform={suggestionsTransform}
          />
        </div>
        <div className="m-t30 scrolling_inner">
          <table className="custom-responsive-table table-borderless table-condensed table-layout-fixed">
            <thead className="sticky-header table-block-title with-filter">
              <tr>
                <th className="wid-50" scope="col">
                  {translations.contact}
                </th>
                <th scope="col">{translations.notify}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {contacts.length > 0 &&
                contacts.map((contact) => (
                  <tr key={contact.id}>
                    <td className="table-cell-ellipsis" title={contact.user_email}>
                      {contact.user_email}
                    </td>
                    <td>
                      <input type="checkbox" checked={contact.owner} onChange={() => makeContactOwner(contact)} />
                    </td>
                    <td>
                      <a
                        className="btn btn-icon"
                        title={translations.delete}
                        onClick={() => deleteContact(contact)}
                        rel="nofollow"
                      >
                        <i className="fa fa-trash-o red-color"></i>
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const filterHostOwners = (concernedParties) => {
  const hostOwners = concernedParties.filter((cp) => cp.concerned_type === "User" && cp.owner);
  return hostOwners.map((ho) => ({
    value: ho.concerned_id,
    label: ho.user_email,
    concerned_party_id: ho.id,
  }));
};

export default assignmentTab;
