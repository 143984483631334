import React, { Component } from "react";
import { smallOrMiniCell, eventParticipantUser, companyNameOrEmail } from "../../common";
import Tooltip from "../../../common/Tooltip";
import { expandLcForParticipant } from "../../customCollapse";
export default class HostMonitorPendingOverviewSuppliers extends Component {
  render() {
    const {
      translations,
      participatingParticipants,
      isComplex,
      weightedRfqOrAuction,
      permissionToView,
      participants,
      isEpOpened,
      isLcOpened,
      toggleLcCollapse,
    } = this.props;
    const {
      lot_current_value,
      lot_qualification_value,
      lot_best_placed_participant,
      lot_difference_offered,
      lot_bids_received,
    } = translations.infos;
    return (
      <div className={`row supplier-row ${isComplex && "-row"}`}>
        <div className="row-title blank blank-top top-blank-supplier">&nbsp;</div>
        {/* # If simple lot then display headings */}
        <div className="cell lot-headings primary cover">
          <div className="row">
            <div className="cell-title-header" title={translations.current_value_html}>
              <span className="cell-content">{translations.current_value_html}</span>
              <Tooltip title={lot_current_value.title} body={lot_current_value.body} />
            </div>
            <div className="cell-title-header" title={translations.qualification_value_html}>
              <span className="cell-content">{translations.qualification_value_html}</span>
              <Tooltip title={lot_qualification_value.title} body={lot_qualification_value.body} />
            </div>
          </div>
        </div>

        {/* // # Loop over suppliers with bids */}
        {isEpOpened &&
          permissionToView &&
          participatingParticipants?.map((ep, i) => {
            const user = eventParticipantUser(participants, ep.user_id);
            return (
              <div
                key={`${ep.user_id}-${Date.parse(ep.updated_at)}`}
                className={`cell-title-header supplier-header ${
                  participatingParticipants.length - 1 === i ? "lastest-supplier " : ""
                }`}
                data-id={ep.user_id}
              >
                <div className="cell-inner cell-expand" title={companyNameOrEmail(user)}>
                  {isComplex && (
                    <a
                      onClick={toggleLcCollapse}
                      className={`white icon-only supplier-button collapse-expend-butt ${
                        expandLcForParticipant(isLcOpened, ep.id) ? "ion-ios-minus-outline" : "ion-ios-plus-outline"
                      }`}
                      data-ep-id={ep.id}
                    ></a>
                  )}
                  {companyNameOrEmail(user)}
                </div>
              </div>
            );
          })}
        <div className="cell lot-headings cover">
          <div className="row">
            {this.bidDetailHeading()}
            <div className="cell-title-header" title={translations.best_placed_participant}>
              <span className="cell-content">{translations.best_placed_participant}</span>
              <Tooltip title={lot_best_placed_participant.title} body={lot_best_placed_participant.body} />
            </div>
            <div className="cell-title-header big-cell" title={translations.difference_offered}>
              <span className="cell-content">{translations.difference_offered}</span>
              <Tooltip title={lot_difference_offered.title} body={lot_difference_offered.body} />
            </div>
            <div
              className={`cell-title-header ${smallOrMiniCell(weightedRfqOrAuction)}`}
              title={translations.bids_received}
            >
              <span className="cell-content">{translations.bids_received}</span>
              <Tooltip title={lot_bids_received.title} body={lot_bids_received.body} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  bidDetailHeading() {
    let heading = "";
    const { displayScores, translations, participatingParticipants, permissionToView, weightedRfqOrAuction } =
      this.props;
    const { best_overall_score, best_bid_received, best_bid_value } = translations;
    const { lot_bestoverallscore, lot_best_bid_received } = translations.infos;
    if (displayScores) {
      heading = (
        <>
          <div className={`cell-title-header ${smallOrMiniCell(weightedRfqOrAuction)}`} title={best_overall_score}>
            {" "}
            <span className="cell-content">{best_overall_score}</span>
            <Tooltip title={lot_bestoverallscore.title} body={lot_bestoverallscore.body} />
          </div>
          <div className="cell-title-header">
            <div className="cell-inner cell-expand" title={best_bid_received}>
              {participatingParticipants.length > 0 && permissionToView && (
                <a href="#" className="white icon-only toggleSuppliers ion-ios-plus-outline collapse-expend-butt"></a>
              )}

              <span className="cell-content">{best_bid_received}</span>
              <Tooltip title={lot_best_bid_received.title} body={lot_best_bid_received.body} />
            </div>
          </div>
        </>
      );
    } else {
      heading = (
        <div className="cell-title-header">
          <div
            className={`cell-inner cell-expand ${
              participatingParticipants.length === 0 && permissionToView && "without-expand"
            }`}
            title={best_bid_value}
          >
            {participatingParticipants.length > 0 && permissionToView && (
              <a href="#" className="white icon-only toggleSuppliers ion-ios-plus-outline collapse-expend-butt"></a>
            )}

            <span className="cell-content">{translations.best_bid_value}</span>
            <Tooltip title={lot_best_bid_received.title} body={lot_best_bid_received.body} />
          </div>
        </div>
      );
    }
    return heading;
  }
}
