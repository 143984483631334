import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import { getError } from "./errorActions";

const createLotTemplateSuccess = (data) => {
  return {
    type: actionTypes.CREATE_LOT_TEMPLATE,
    lotTemplate: data.lot_template,
  };
};

const useLotTemplateSuccess = (data) => {
  return {
    type: actionTypes.USE_LOT_TEMPLATE,
    payload: {
      lots: data.lots,
      lineItems: data.line_items,
      lotComponents: data.lot_components,
      lineItemComponents: data.line_item_components,
      unitOfMeasures: data.unit_of_measures,
      lotComponentPicklistOptions: data.lot_component_picklist_options,
    },
  };
};

const showSuccess = (successMessage) => {
  return { type: actionTypes.SUCCESS, success: successMessage };
};

export const createLotTemplate = (companyId, payload) => (dispatch) =>
  apiCall
    .post(`${config.V2_BASE_URL}/companies/${companyId}/lot_templates`, payload)
    .then((response) => {
      dispatch(createLotTemplateSuccess(response.data));
      dispatch(showSuccess(response.data.message));
      // We are returning from here so that we can check on the component that if we have
      // some response then close the modal
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const useLotTemplate = (payload, eventId) => (dispatch) =>
  apiCall
    .post(`${config.V2_BASE_URL}/events/${eventId}/use_template`, payload)
    .then((response) => {
      dispatch(useLotTemplateSuccess(response.data));
      dispatch(showSuccess(response.data.message));
      // We are returning from here so that we can check on the component that if we have
      // some response then close the modal
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
