import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Loader from 'react-loader-advanced';

import EditorPopupSection from '../QQEvent/EditorPopupSection';
import { confirm } from '../../util/confirm';
import PopupMobile from '../QQEvent/PopupMobile';

import { fetchAdminText } from '../../../../actions/qq/adminTextActions';
import { updateQuestion, createNewQuestion, deleteQuestion } from '../../../../actions/qq/editActions';
import { showToasterMessages, fetchQuestions } from '../../../../actions/qq/genericActions';
import LoaderMessage from '../../LoaderMessage';

export class Questions extends Component {
  constructor() {
    super();
    this.state = {
      adminText: '',
      addNewQuestionRow: false,
      data: [],
      newRowData: {},
      disableAddBtn: false,
      disableUpdateBtn: false,
      showQuestionSection: false,
      showLoader: true,
    };
  }

  UNSAFE_componentWillMount() {
    const { sectionKey, companyId } = this.props;
    this.props.fetchAdminText(companyId, sectionKey);
    this.fetchQuestionsData();
  }

  async fetchQuestionsData() {
    let data = [];
    const questions = await this.props.fetchQuestions(this.props.quoteId);
    if (questions.status === 200) {
      data = questions.data.questions;
    }
    this.setState({ data, showLoader: false });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText.FETCH_ADMIN_TEXT_QUESTIONS ? newProps.adminText.FETCH_ADMIN_TEXT_QUESTIONS : '';
    if (newProps.default.FETCH_ADMIN_TEXT_QUESTIONS_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_QUESTIONS_DEL;
    }
    this.setState({
      adminText,
    });
  }

  onEditChange(event, i) {
    const { value, name } = event.target;
    const tempData = this.state.data[i];
    tempData[name] = value;
    const { data } = this.state;
    data[i] = tempData;
    this.setState({ data });
  }

  onAddRowChange(event) {
    const { value, name } = event.target;
    const rowData = this.state.newRowData;
    rowData[name] = value;
    this.setState({ newRowData: rowData });
  }

  getQuestionType(type) {
    switch (type) {
      case 'yes_no':
        return 'Yes/No';
      case 'one_line_text':
        return 'Text';
      case 'document_upload':
        return 'Upload file';
      default:
        return '';
    }
  }

  editRow(index, flag) {
    const selfThis = this;
    selfThis.refs[`questions_name_off_${index}`].hidden = flag;
    selfThis.refs[`questions_name_on_${index}`].hidden = !flag;
    selfThis.refs[`questions_type_off_${index}`].hidden = flag;
    selfThis.refs[`questions_type_on_${index}`].hidden = !flag;
    selfThis.refs[`questions_actions_off_${index}`].hidden = flag;
    selfThis.refs[`questions_actions_on_${index}`].hidden = !flag;
    if (!flag) {
      const data = _.cloneDeep(this.state.data);
      this.setState({ data });
      selfThis.refs[`questions_row_${index}`].setAttribute('class', '');
    } else {
      selfThis.refs[`questions_row_${index}`].setAttribute('class', 'align-box');
    }
  }

  addNewQuestion(flag) {
    this.setState({ addNewQuestionRow: flag, disableAddBtn: false });
    if (!flag) {
      this.setState({ newRowData: {}, showQuestionSection: this.state.data.length > 0 });
    }
  }

  async updateQuestion(questionData, index) {
    const parsedJson = this.parseJson(questionData);
    this.setState({ disableUpdateBtn: true });
    const payload = {
      questionnaire_question: parsedJson,
    };
    const response = await this.props.updateQuestion(
      questionData.questionnaire_id,
      questionData.questionnaire_section_id,
      questionData.id,
      payload,
    );
    if (response.status === 200) {
      const questions = _.cloneDeep(this.state.data);
      questions[index] = response.data.questionnaire_question;
      this.setState({ data: questions });
      this.editRow(index, false);
    }
    this.setState({ disableUpdateBtn: false });
  }

  async deleteQuestion(index, questionData) {
    let pop_up_translations = {
      ConfirmLabel: this.props.translations.pop_up.confirm,
      okLabel: this.props.translations.pop_up.ok,
      cancelLabel: this.props.translations.pop_up.cancel,
    };
    confirm(this.props.translations.pop_up.confirm_popup_delete, pop_up_translations).then(async () => {
      const response = await this.props.deleteQuestion(
        questionData.questionnaire_id,
        questionData.questionnaire_section_id,
        questionData.id,
      );
      if (response.status === 204) {
        const questions = _.cloneDeep(this.state.data);
        questions.splice(index, 1);
        this.setState({ data: questions });
        if (questions.length === 0) {
          this.setState({ showQuestionSection: false });
        }
      }
    }, () => { /* cancel */ });
  }

  async saveNewQuestion() {
    const { quoteId, configurations } = this.props;
    const { data } = this.state;
    this.setState({ disableAddBtn: true });
    const questionLimit = configurations.limits.qq_questions_limit;

    if (data.length < questionLimit) {
      const parsedJson = this.parseJson(this.state.newRowData);
      const payload = {
        questions: parsedJson,
      };
      const response = await this.props.createNewQuestion(quoteId, payload);
      if (response.status === 201) {
        let questions = _.cloneDeep(this.state.data);
        questions = questions.concat(response.data.question);
        this.setState({ data: questions });
        this.addNewQuestion(false);
      }
      this.setState({ disableAddBtn: false });
    } else {
      this.props.showToasterMessages('SHOW_ERROR', this.props.translations.error_message.max_questions_limit);
    }
  }

  parseJson(badJson) {
    return JSON.parse(JSON.stringify(badJson).replace(/"\s+|\s+"/g, '"'));
  }

  disabledSection(flag) {
    this.setState({
      showQuestionSection: flag,
    });
    if (flag) {
      this.addNewQuestion(flag);
    }
  }

  render() {
    const { companyId, sectionKey, translations } = this.props;
    const {
      adminText, data, addNewQuestionRow, newRowData, disableUpdateBtn, disableAddBtn, showQuestionSection, showLoader,
    } = this.state;
    let showQuestionTable;
    if (showQuestionSection) {
      showQuestionTable = true;
    } else if (data.length > 0) {
      showQuestionTable = true;
    } else {
      showQuestionTable = false;
    }

    return (
      <Loader show={ showLoader } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles priority={ 1 }>
        <div className='block-item'>
          <div className='col-md-6 p-l0 table-cell-box'>
            <div className='form-item-block bg-white'>
              <h4>{translations.summary_status.participant_list.questions}</h4>
              <PopupMobile
                companyId={ companyId }
                sectionKey={ sectionKey }
                text={ this.state.adminText }
                translations={ translations }
              />
              {data.length === 0 &&
                <div className='checkbox'>
                  <div>
                    <input
                      id='checkbox3'
                      type='checkbox'
                      name='checkbox'
                      checked={ showQuestionSection }
                      onChange={ () => this.disabledSection(!showQuestionSection) }
                    />
                    <label htmlFor='checkbox3'>
                      <span />{translations.qq_create.qq_questions.include_questions}
                    </label>
                  </div>
                </div>}
              <div className='table-scroll event-list'>
                {showQuestionTable &&
                  <table className='common-table table-align-top'>
                    <thead className='table-block-title'>
                      <tr>
                        <th className='question-col-one'>{translations.qq_edit.question_name}</th>
                        <th className='question-col-two'>{translations.qq_edit.question_type}</th>
                        <th className='column-fourth'>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((questionsRow, index) => (
                        <tr ref={ `questions_row_${index}` } key={ questionsRow.id }>
                          <td>
                            <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.question_name}</div>
                            <div ref={ `questions_name_off_${index}` }>{translations.qq_edit.question} {index + 1}: {questionsRow.title}</div>
                            <div hidden ref={ `questions_name_on_${index}` }>
                              <input
                                type='text'
                                name='title'
                                value={ questionsRow.title }
                                placeholder={translations.qq_edit.name}
                                className='form-control'
                                onChange={ e => this.onEditChange(e, index) }
                              />
                              {!questionsRow.title && <span className='text-danger'>{translations.error_message.required}</span>}
                              {questionsRow.title && !questionsRow.title.trim() &&
                              <span className='text-danger'>{translations.error_message.no_white_space}</span>}
                              {questionsRow.title && questionsRow.title.trim().length > 255 &&
                              <span className='text-danger'>
                                {translations.error_message.name_char_long.replace("%{name_string}", translations.qq_edit.title).replace("%{name_limit}", 255)}
                              </span>}
                            </div>
                          </td>
                          <td>
                            <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.question_type}</div>
                            <div ref={ `questions_type_off_${index}` }>{this.getQuestionType(questionsRow.question_type)}</div>
                            <div hidden ref={ `questions_type_on_${index}` } className='selectbox'>
                              <select
                                value={ questionsRow.question_type }
                                onChange={ e => this.onEditChange(e, index) }
                                name='question_type'
                                className='form-control'
                              >
                                <option value=''>{translations.qq_edit.select}</option>
                                <option value='one_line_text'>{translations.qq_edit.text}</option>
                                <option value='document_upload'>{translations.qq_edit.upload_file}</option>
                                <option value='yes_no'>{translations.qq_edit.yes_no}</option>
                              </select>
                              {!questionsRow.question_type && <span className='text-danger'>{translations.error_message.required}</span>}
                            </div>
                          </td>
                          <td className='text-right'>
                            <div ref={ `questions_actions_off_${index}` }>
                              <button disabled={ questionsRow.answered } className='icon-link' onClick={ () => this.editRow(index, true) }>
                                <i className='fa fa-pencil-square-o' aria-hidden='true' />
                              </button>
                              <button className='icon-link' onClick={ () => this.deleteQuestion(index, questionsRow) }>
                                <i className='fa fa-trash-o' aria-hidden='true' />
                              </button>
                            </div>
                            <div hidden ref={ `questions_actions_on_${index}` }>
                              <button
                                className='icon-link'
                                disabled={ !questionsRow.title || !questionsRow.title.trim() ||
                              questionsRow.title.trim().length > 255 || disableUpdateBtn }
                                onClick={ () => this.updateQuestion(questionsRow, index) }
                              >
                                <i className='fa fa-check' aria-hidden='true' />
                              </button>
                              <button className='icon-link' onClick={ () => this.editRow(index, false) }>
                                <i className='fa fa-times' aria-hidden='true' />
                              </button>
                            </div>
                          </td>
                        </tr>
                  ))}
                      {addNewQuestionRow &&
                      <tr className='align-box bg-gray'>
                        <td>
                          <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.question_name}</div>
                          <input
                            type='text'
                            name='title'
                            onChange={ e => this.onAddRowChange(e) }
                            placeholder={ translations.qq_edit.name }
                            className='form-control'
                          />
                          {!newRowData.title && <span className='text-danger'>{translations.error_message.required}</span>}
                          {newRowData.title && !newRowData.title.trim() &&
                          <span className='text-danger'>{translations.error_message.no_white_space}</span>}
                          {newRowData.title && newRowData.title.trim().length > 255 &&
                          <span className='text-danger'>
                            {translations.error_message.name_char_long.replace("%{name_string}", translations.qq_edit.title).replace("%{name_limit}", 255)}
                          </span>}
                        </td>
                        <td>
                          <div className='col-caption hidden-lg hidden-md hidden-sm'>{translations.qq_edit.question_type}</div>
                          <div className='selectbox'>
                            <select
                              className='form-control'
                              onChange={ e => this.onAddRowChange(e) }
                              name='question_type'
                            >
                              <option value=''>{translations.qq_edit.select}</option>
                              <option value='one_line_text'>{translations.qq_edit.text}</option>
                              <option value='document_upload'>{translations.qq_edit.upload_file}</option>
                              <option value='yes_no'>{translations.qq_edit.yes_no}</option>
                            </select>
                            {!newRowData.question_type && <span className='text-danger'>{translations.error_message.required}</span>}
                          </div>
                        </td>
                        <td className='text-right'>
                          <button
                            className='icon-link'
                            onClick={ () => this.saveNewQuestion() }
                            disabled={ !newRowData.title || !newRowData.title.trim() || !newRowData.question_type ||
                          newRowData.title.trim().length > 255 || disableAddBtn }
                          >
                            <i className='fa fa-check' aria-hidden='true' />
                          </button>
                          <button className='icon-link' onClick={ () => this.addNewQuestion(false) }>
                            <i className='fa fa-times' aria-hidden='true' />
                          </button>
                        </td>
                      </tr>}
                      {data.length !== 0 &&
                      <tr>
                        <td colSpan='3' className='table-section-head text-left'>
                          <button
                            type='button'
                            className='btn btn-primary add-new-question'
                            disabled={ addNewQuestionRow }
                            onClick={ () => this.addNewQuestion(true) }
                          >{translations.qq_create.qq_questions.add_question}
                          </button>
                        </td>
                      </tr>
                      }
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>
          <EditorPopupSection 
            text={ adminText }
            companyId={ companyId }
            sectionKey={ sectionKey }
            translations={ translations }
            updateText={ (adminText) => { this.setState({ adminText }); } }
          />
        </div>
      </Loader>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  adminText: state.adminTextReducer,
  default: state.deleteAdminTextReducer,
  configurations: state.qqReducer,
  selectedLang: state.changeLanguageReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchAdminText: (companyId, sectionKey) => dispatch(fetchAdminText(companyId, sectionKey)),
  updateQuestion: (questionnaireId, questionnaireSectionId, questionId, payload) => dispatch(updateQuestion(
    questionnaireId,
    questionnaireSectionId,
    questionId,
    payload,
  )),
  deleteQuestion: (questionnaireId, questionnaireSectionId, questionId) => dispatch(deleteQuestion(
    questionnaireId,
    questionnaireSectionId,
    questionId,
  )),
  createNewQuestion: (quoteId, payload) => dispatch(createNewQuestion(quoteId, payload)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
  fetchQuestions: quoteId => dispatch(fetchQuestions(quoteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
