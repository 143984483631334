import React from "react";
import CommonModal from "../../../../../../hoc/modalHOC/CommonModal";
import Main from "./Main";
const LeadParticipantModal = (props) => {
  const { viewComponent, header, translations, modalCloseFunction, showViewComponent, canShowDeleteBtn } = props;
  return (
    <CommonModal
      showViewComponent={showViewComponent}
      viewComponent={viewComponent}
      viewComponentTitle={props.viewComponentTitle}
      header={header}
      modalCloseFunction={modalCloseFunction}
      content={<Main {...props} />}
      modalCloseButtonText={translations.ok}
      modalCloseButtonClass="btn btn-sm btn-primary"
      canShowDeleteBtn={canShowDeleteBtn}
    />
  );
};

export default LeadParticipantModal;
