import { getStore } from "@/store";
import consumer from "./consumer";

import bidMessage from "./handlers/bid_message";
// import eventEndMessage from "./handlers/event_end_message";

const HANDLERS = {
  "CableMessages::BidMessage": bidMessage,
  //"CableMessages::EventDateMessage": eventEndMessage,
};

const EVENTS_PATTERN = /^\/events\/(\d+)/;
const [, event_id] = window.location.pathname.match(EVENTS_PATTERN) || [];

// We don't want to subscribe to the channel if we are not on an event page.
// This will try and connect over and over again if we try and connect without
// a event_id
if (event_id) {
  const channel = { channel: "MonitorChannel", event_id };
  consumer.subscriptions.create(channel, {
    received({ type, ...message }) {
      if (typeof HANDLERS[type] === "function") {
        HANDLERS[type].call(this, message, getStore());
      }
    },
  });
}
