import React from "react";
import { formatPercentileNumber } from "../../../../common";
import { auctionQuestionnaireWeighting, rfqQuestionnaireWeighting } from "../../../../eventCommon";

const OverallScoreHeaderValues = (props) => {
  const { translations, event, beforeAuction, locale, headerValue } = props;
  const isEventCategoryRfq = beforeAuction && event.event_category_rfq;
  const weightedPrice = isEventCategoryRfq ? event.rfq_price_weighting : event.auction_price_weighting;
  const quWeighting = isEventCategoryRfq ? rfqQuestionnaireWeighting(event) : auctionQuestionnaireWeighting(event);
  const weightedvalue = formatPercentileNumber(weightedPrice, locale);
  const questionnaireValue = formatPercentileNumber(quWeighting, locale);
  const calculationType = translations[event.price_weighting_calculation_type];
  return (
    <>
      {headerValue("price_weighting", weightedvalue, "active")}
      {headerValue("questionnaire_weighting", questionnaireValue, "active")}
      {headerValue("price_score_calculation_type", calculationType, "active")}
    </>
  );
};

export default OverallScoreHeaderValues;
