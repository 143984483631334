import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import { getError } from "./errorActions";
import { handleMessageCount } from "@/components/messages/MessageContainer";

const sentMessageSuccess = (data) => ({
  type: actionTypes.MESSAGE_SENT,
  payload: data.message,
});

const getMessagesSuccess = (data) => ({
  type: actionTypes.GET_MESSAGES,
  payload: data.messages,
});

const showSuccess = (successMessage) => {
  return { type: actionTypes.SUCCESS, success: successMessage };
};

export const getNewMessages = (lastMessageId) => (dispatch) =>
  apiCall
    .get(`messages/${lastMessageId}/messages_greater_than.json`)
    .then((response) => {
      dispatch(getMessagesSuccess(response.data));
    })
    .catch((error) => {
      console.log(error);
    });

export const sendMessage = (eventId, data, successMessage, simDojo) => (dispatch) =>
  apiCall
    .post(`${config.BASE_URL}/${simDojo ? "sim_dojo/" : ""}events/${eventId}/messages`, data)
    .then((response) => {
      dispatch(sentMessageSuccess(response.data));
      dispatch(showSuccess(successMessage));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error.response));
      return error.response;
    });

export const showMessageModal = (isNewMessage, messageReplyId, replyUserId, modalId) => (dispatch) =>
  dispatch({
    type: actionTypes.SHOW_MESSAGE_MODAL,
    payload: { isNewMessage, messageReplyId, replyUserId, modalId },
  });

export const markAsReadToMessage = (messageId, payload) => (dispatch) =>
  apiCall
    .put(`${config.BASE_URL}/messages/${messageId}`, payload)
    .then((response) => {
      handleMessageCount();
      dispatch({
        type: actionTypes.MARK_AS_READ,
        payload: response.data.message,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const bookmarkToMessage = (messageId) => (dispatch) =>
  apiCall
    .post(`${config.BASE_URL}/messages/${messageId}/bookmark`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const removeBookmark = (messageId) => (dispatch) =>
  apiCall
    .remove(`${config.BASE_URL}/messages/${messageId}/bookmark`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const emptyCurrentState = (actionType, payload) => (dispatch) => {
  dispatch({
    type: actionType,
    payload,
  });
};
