import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { auctionPending, isBeforeAuction } from "../../eventCommon";

const LotDetail = ({ event, translations, isBidSubmitted, lot: { position, name } }) => {
  const { auction_tab } = useSelector(({ lotReducers }) => lotReducers);

  const isShowinBidSubmittedStatus = useMemo(
    () => ((!auction_tab && isBeforeAuction(event)) || !auctionPending(event)) && isBidSubmitted,
    [auction_tab, event, isBidSubmitted]
  );

  return (
    <>
      <div className="tbl-head-toplft wid-auto">
        <h4 className="m-l5 m-t5">
          <span className="boldtitle-reverse font-s16 m-l5 m-r10">{position}</span>
          <span className="font-s17" title={name}>
            {name}
          </span>
        </h4>
      </div>
      {isShowinBidSubmittedStatus && (
        <div className="alert alert-info height-30 m-b0 m-l25 p-t5 pull-left">{translations.submitted}</div>
      )}
    </>
  );
};

export default LotDetail;
