import * as actionTypes from "../../actions/actionsTypes";

const contractListingReducers = (state = [], action) => {
  switch (action.type) {
    case actionTypes.ARCHIVED: {
      return { session: action.payload };
    }
    case actionTypes.SELECT_ALL: {
      return { selectall: action.payload };
    }
    case actionTypes.SORT_CONTRACTS: {
      return { sort: action.payload };
    }
    case actionTypes.RESET_CONTRACTS: {
      return { reset: action.payload };
    }
    case actionTypes.ARCHIVED_SUCCESS: {
      return { archived: action.payload };
    }
    case actionTypes.CONTRACT_OWNER_CHANGED: {
      return { ownerChangedData: action.payload };
    }
    case actionTypes.FILTERED_CONTRACTS: {
      return { filteredContracts: action.payload };
    }
    case actionTypes.SEARCH_RESULTS: {
      return { searchedIds: action.payload };
    }
    case actionTypes.FLASH_FOR_UPLOAD: {
      return { uploadMessage: action.payload };
    }
    default:
      return state;
  }
};

export default contractListingReducers;
