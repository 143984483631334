import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateLineItemComponent } from "../../../actions/lineItemComponentActions";
import Tooltip from "../../common/Tooltip";
import { disableButton, enableButton, fetchAssociatedCurrency, isLotTotal } from "../common";
import CurrencyFieldWithSymbol from "../lots/line_item_component/CurrencyFieldWithSymbol";
import propTypes from "prop-types";

const ColumnLayout = ({ tooltipTitle, tooltipBody, label, children }) => {
  return (
    <div className="col-md-4">
      <div className="form-group">
        <div className="col input">
          <Tooltip title={tooltipTitle} body={tooltipBody} />
          <label title={label}>{label}</label>
          {children}
        </div>
      </div>
    </div>
  );
};

const LicDescriptionCol = (props) => {
  return (
    <ColumnLayout {...props}>
      <input
        name={props.name}
        autoComplete="off"
        className="form-control"
        type="text"
        tabIndex="0"
        disabled={true}
        value={props.value}
      />
    </ColumnLayout>
  );
};

export const CurrencyFieldCol = (props) => {
  const { tooltipTitle, tooltipBody, label, ...rest } = props;
  const [fixedDecimalScale, setFixedDecimalScale] = useState(true);
  const onBlur = () => {
    setFixedDecimalScale(true);
  };
  const onFocus = () => {
    setFixedDecimalScale(false);
  };
  return (
    <ColumnLayout tooltipTitle={tooltipTitle} tooltipBody={tooltipBody} label={label}>
      <div className="input-group col-xs-12">
        <CurrencyFieldWithSymbol
          classes="number_precision form-control"
          fixedDecimalScale={fixedDecimalScale}
          onFocus={onFocus}
          onBlur={onBlur}
          {...rest}
        />
      </div>
    </ColumnLayout>
  );
};

const defaultPropTypes = {
  tooltipTitle: propTypes.string.isRequired,
  tooltipBody: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  value: propTypes.any,
};

LicDescriptionCol.propTypes = defaultPropTypes;

CurrencyFieldCol.propTypes = {
  translations: propTypes.object.isRequired,
  symbol: propTypes.string.isRequired,
  disabled: propTypes.bool,
  precision: propTypes.number,
  handleFieldChange: propTypes.func,
  ...defaultPropTypes,
};

const UpdateSingleLicCurrentPrice = (props) => {
  const { translations, lot, lic, currency, currenciesHash, isMultiCurrencyEvent } = props;
  const refBtn = useRef(null);
  const dispatch = useDispatch();
  const {
    line_item_component_unit_of_measure_uomname,
    lot_quantity,
    lot_used_price,
    lot_qualification_price,
    lot_current_price,
    lot_is_lot_total,
  } = translations.infos;
  const [currentPrice, setCurrentPrice] = useState(lic.current_price);
  const handleSaveButtonClick = (e) => {
    e.preventDefault();
    disableButton(refBtn.current, translations.submitting);
    const payload = {
      line_item_component: {
        event_id: lot.event_id,
        quantity_value: lic.quantity,
        current_price: currentPrice,
      },
    };

    dispatch(updateLineItemComponent(lic.lot_component_id, lic.id, payload)).then((response) => {
      enableButton(refBtn.current, translations.update, "<i class='fa fa-check'></i>");
    });
  };
  const isLotTotalLic = isLotTotal(lot, lic);
  const licCurrency = isMultiCurrencyEvent ? fetchAssociatedCurrency(currenciesHash, lot, lic) : currency;
  return (
    <form onSubmit={(e) => handleSaveButtonClick(e)}>
      <div className="block-content">
        <div className="form-group">
          <h4 className="block-title">
            {translations.line_item_component_edit}&nbsp;(
            {lic.li_name} - {lic.lc_name})&nbsp;
            <span className="lic-tag">
              {translations.line_item_component_ref}: {lic.tag}
            </span>
          </h4>
        </div>
        {lic.calculable_lc && lic.is_price ? (
          <>
            <div className="row">
              {[
                {
                  tooltipTitle: line_item_component_unit_of_measure_uomname.title,
                  tooltipBody: line_item_component_unit_of_measure_uomname.body,
                  label: translations.uom_set,
                  name: "unit_of_measure_uomname",
                  value: lic.uom_name,
                },
                {
                  tooltipTitle: lot_quantity.title,
                  tooltipBody: lot_quantity.body,
                  label: translations.quantity,
                  name: "quantity",
                  value: lic.quantity,
                },
              ].map((data, index) => (
                <LicDescriptionCol key={index} {...data} />
              ))}
              {lic.host ? (
                <CurrencyFieldCol
                  key={"used_price"}
                  tooltipTitle={lot_used_price.title}
                  tooltipBody={lot_used_price.body}
                  label={translations.used_price_uom}
                  name={"used_price"}
                  symbol={licCurrency.symbol}
                  value={lic.used_price}
                  precision={lic.decimal_place}
                  translations={translations}
                  disabled
                />
              ) : (
                <CurrencyFieldCol
                  key={"qualification_price"}
                  tooltipTitle={lot_qualification_price.title}
                  tooltipBody={lot_qualification_price.body}
                  label={translations.qualification_price_uom}
                  name={"qualification_price"}
                  symbol={licCurrency.symbol}
                  value={lic.qualification_price}
                  precision={lic.decimal_place}
                  translations={translations}
                  disabled
                />
              )}
            </div>
            <div className="row">
              <CurrencyFieldCol
                key={"current_price"}
                tooltipTitle={lot_current_price.title}
                tooltipBody={lot_current_price.body}
                label={translations.current_price_uom}
                name={"current_price"}
                symbol={licCurrency.symbol}
                value={currentPrice}
                handleFieldChange={setCurrentPrice}
                precision={lic.decimal_place}
                translations={translations}
              />
            </div>
          </>
        ) : null}

        <div className="row">
          {isLotTotalLic ? (
            <div className="col-md-4">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="col no-padding">
                    <div className="input no-padding">
                      <div className="radios">
                        <label className="css-input css-checkbox css-checkbox-default">
                          <input disabled type="hidden" />
                          <input
                            name="isLotTotalLic"
                            className="provider_option"
                            type="checkbox"
                            id="lot_total"
                            disabled={true}
                            checked={isLotTotalLic}
                          />
                          <span />
                          {translations.is_lot_total}
                        </label>
                        <Tooltip title={lot_is_lot_total.title} body={lot_is_lot_total.body} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-md-4 pull-right form-group">
            <button name="button" type="submit" className="btn btn-sm btn-primary pull-right mb-10" ref={refBtn}>
              {translations.update} <i className="fa fa-check fa-white" />
            </button>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </form>
  );
};

export default UpdateSingleLicCurrentPrice;
