import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { isPrice, smallOrMiniCell, host, savingsOrProfit } from "../../common";
import Tooltip from "../../../common/Tooltip";
import LotComponentCommonHeading from "./LotComponentCommonHeading";
import { expandLcForParticipant } from "../../customCollapse";
export default class LotComponentColumnTitle extends Component {
  render() {
    const { event, translations, lot, permissionToView, weightedRfqOrAuction, auctionTab, isJapaneseEvent } =
      this.props;
    const { current_value_html, qualification_value_html, bids_received, bid_round } = translations;
    const { lot_current_value, lot_qualification_value, lot_bids_received, bid_bid_round } = translations.infos;
    return (
      <div className={`row ${lot.is_event_total ? "lot-totals-row" : "lot-row"} component-titles-row`}>
        <div className="mycell row-title blank blank-top">&nbsp;</div>
        <div className="mycell lot-headings lot-heading-steptwo">
          <div className="row">
            <div className="mycell cell-title" title={current_value_html}>
              <span className="cell-content">{current_value_html}</span>
              <Tooltip title={lot_current_value.title} body={lot_current_value.body} />
            </div>
            {auctionTab ? (
              isJapaneseEvent ? (
                <div className="mycell cell-title" title={bid_round}>
                  <span className="cell-content">{bid_round}</span>
                  <Tooltip title={bid_bid_round.title} body={bid_bid_round.body} />
                </div>
              ) : (
                <div className="mycell cell-title" title={bids_received}>
                  <span className="cell-content">{bids_received}</span>
                  <Tooltip title={lot_bids_received.title} body={lot_bids_received.body} />
                </div>
              )
            ) : (
              <div className="mycell cell-title" title={qualification_value_html}>
                <span className="cell-content">{qualification_value_html}</span>
                <Tooltip title={lot_qualification_value.title} body={lot_qualification_value.body} />
              </div>
            )}
          </div>
        </div>

        {permissionToView && this.eventParticipantsComponentTitle()}
        <div className="mycell lot-headings lot-heading-steptwo">
          <div className="row">
            {this.displayBestBidHeading()}

            <LotComponentCommonHeading
              event={event}
              translations={translations}
              weightedRfqOrAuction={weightedRfqOrAuction}
              auctionTab={auctionTab}
              isJapaneseEvent={isJapaneseEvent}
              lot={lot}
            />
          </div>
        </div>
      </div>
    );
  }

  eventParticipantsComponentTitle() {
    const { event, lotLcs, participatingParticipants, translations, displayScores, isLcOpened, isEpOpened } =
      this.props;
    let content = [];
    const { overall_score, bid_value } = translations;
    participatingParticipants.map((ep) => {
      {
        content.push(
          <Fragment key={`${ep.id}-${Date.parse(ep.updated_at)}`}>
            {expandLcForParticipant(isLcOpened, ep.id) && (
              <div className={`mycell supplier supplier-cell-${ep.user_id}`}>
                <div className="row">
                  {lotLcs.map((lotComponent) => {
                    return (
                      <Fragment key={`${lotComponent.id}-${Date.parse(lotComponent.updated_at)}`}>
                        {isPrice(lotComponent) ? (
                          <>
                            {displayScores && (
                              <div className="mycell cell-title" title={overall_score}>
                                {overall_score}
                              </div>
                            )}

                            {this.showHeadingForColumn(this.showValueTitle(lotComponent))}
                            {this.showHeadingForColumn(savingsOrProfit(event, translations))}
                            {this.showRankOrWeightedRank()}
                          </>
                        ) : (
                          <div
                            className="mycell cell-title non-price lot-component-cell-title"
                            title={lotComponent.name}
                          >
                            {lotComponent.name}
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            )}
            {!expandLcForParticipant(isLcOpened, ep.id) && isEpOpened && (
              <div className={`mycell supplier supplier-cell-${ep.user_id} `}>
                <div className="row">
                  <div className="mycell cell-title" title={bid_value}>
                    {bid_value}
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        );
      }
    });
    return content;
  }
  showValueTitle(lotComponent) {
    const { translations } = this.props;
    const { your_value, bid_value } = translations;
    return host(lotComponent) ? your_value : bid_value;
  }

  showRankOrWeightedRank() {
    const { weightedRfqOrAuction, beforeAuction, translations, auctionTab } = this.props;
    const { weighted_rank, rank } = translations;
    if (weightedRfqOrAuction && (beforeAuction || auctionTab)) {
      return this.showHeadingForColumn(weighted_rank);
    } else if (beforeAuction || auctionTab) {
      return this.showHeadingForColumn(rank);
    }
  }
  showHeadingForColumn(title) {
    return (
      <div className="mycell cell-title lot-component-cell-title" title={title}>
        {title}
      </div>
    );
  }
  displayBestBidHeading() {
    const { displayScores, translations, weightedRfqOrAuction } = this.props;
    const { best_bid_received, best_bid_value, best_overall_score } = translations;
    const { lot_bestoverallscore } = translations.infos;
    if (displayScores) {
      return (
        <>
          <div className={`mycell cell-title ${smallOrMiniCell(weightedRfqOrAuction)}`}>
            <span className="cell-content">{best_overall_score}</span>
            <Tooltip title={lot_bestoverallscore.title} body={lot_bestoverallscore.body} />
          </div>
          {this.displayBestBidHeadingContent(best_bid_received)}
        </>
      );
    } else {
      return this.displayBestBidHeadingContent(best_bid_value);
    }
  }
  displayBestBidHeadingContent(title) {
    const { translations, toggleEpCollapse, isEpOpened, participatingParticipants, bidsSealed } = this.props;
    const { lot_best_bid_received } = translations.infos;
    return (
      <div className="mycell cell-title">
        <div className="cell-inner" title={title}>
          <span className="cell-content toggleSuppliers-icon">
            {participatingParticipants.length > 0 && !bidsSealed ? (
              <i
                onClick={() => toggleEpCollapse()}
                className={classNames({
                  "white icon-only toggleSuppliers": true,
                  "ion-ios-plus-outline": !isEpOpened,
                  "ion-ios-minus-outline": isEpOpened,
                })}
              ></i>
            ) : null}
            {title}
          </span>
          <Tooltip title={lot_best_bid_received.title} body={lot_best_bid_received.body} />
        </div>
      </div>
    );
  }
}
