import React from "react";

const ShowDetailsTitle = ({ translations }) => {
  return (
    <ul className="lot-summary-headings summary-panel">
      <li>
        <span title={translations.is_ranked}>{translations.is_ranked}</span>
      </li>
      <li>
        <span title={translations.is_visible_rank}>{translations.is_visible_rank}</span>
      </li>
      <li>
        <span title={translations.currency}>{translations.currency}</span>
      </li>
      <li>
        <span title={translations.uom}>{translations.uom}</span>
      </li>
      <li>
        <span title={translations.quantity}>{translations.quantity}</span>
      </li>
      <li>
        <span title={translations.current_price_uom}>{translations.current_price_uom}</span>
      </li>
      <li>
        <span title={translations.used_price_uom}>{translations.used_price_uom}</span>
      </li>
      <li>
        <span title={translations.qualification_price_uom}>{translations.qualification_price_uom}</span>
      </li>
      <li>
        <span title={translations.total_current_value}>{translations.total_current_value}</span>
      </li>
      <li>
        <span title={translations.total_used_value}>{translations.total_used_value}</span>
      </li>
      <li>
        <span title={translations.total_qualification_value}>{translations.total_qualification_value}</span>
      </li>
      <li>
        <span title={translations.your_answer}>{translations.your_answer}</span>
      </li>
    </ul>
  );
};

export default ShowDetailsTitle;
