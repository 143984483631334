export const commonProps = (createEditReducers) => ({
  contractId: createEditReducers.contract.id,
  translations: createEditReducers.translations,
  showPage: createEditReducers.showPage,
});

export const mapCommonReducerProps = ({ createEditReducers }, props) => ({
  ...createEditReducers,
  data: createEditReducers,
  contractId: props.contractId ?? createEditReducers.contract?.id,
});
