import React from "react";
import PropTypes from "prop-types";
import RenderHTML from "@/components/common/RenderHTML";

const IntegrationAttributesPropTypes = {
  detailsTranslations: PropTypes.objectOf(PropTypes.string),
  integrationAttributes: PropTypes.objectOf(PropTypes.string),
  perAngustaAttributes: PropTypes.objectOf(PropTypes.string),
};

const AttributePropTypes = {
  content: PropTypes.string,
  attribute: PropTypes.string,
  pALink: PropTypes.string,
};

const Attribute = ({ attribute, content, pALink }) => {
  if (attribute === "budget_name")
    return (
      <a href={pALink}>
        <RenderHTML {...{ content }} />
      </a>
    );

  return <RenderHTML {...{ content }} />;
};

Attribute.propTypes = AttributePropTypes;

function IntegrationAttributes({ detailsTranslations, integrationAttributes, perAngustaAttributes }) {
  const attributes = {
    budget_name: integrationAttributes.name,
    budget_reference_code: integrationAttributes.reference_code,
    budget_external_id: integrationAttributes.external_id,
  };

  const { protocol, host } = window.location;
  const { company_integration_id, user_integration_id } = perAngustaAttributes;
  const pALink = `${protocol}//${host}/company_integrations/${company_integration_id}/user_integrations/${user_integration_id}/per_angusta_api/en-budgets-${integrationAttributes.id}`;

  return (
    <>
      <h4 style={{ margin: "30px 0 20px" }}>{detailsTranslations.sourcing_pipeline}</h4>
      {Object.entries(attributes).map(([attribute, content]) => (
        <div className="form-group" key={attribute}>
          <label htmlFor="invitation_message">{detailsTranslations[attribute]}: </label>
          <span>
            <Attribute {...{ attribute, content, pALink }} />
          </span>
        </div>
      ))}
    </>
  );
}

IntegrationAttributes.propTypes = IntegrationAttributesPropTypes;

export default IntegrationAttributes;
