import React from "react";
import PropTypes from "prop-types";
import RenderHTML from "./RenderHTML";

interface TooltipProps {
  title: string;
  body: string;
  categoryDojo?: boolean;
  additionalClasses?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ title, body, categoryDojo, additionalClasses }) => {
  if (categoryDojo) {
    return (
      <>
        <span />
        <span className="hint hidden" title={title}>
          <RenderHTML content={body} />
        </span>
      </>
    );
  }

  return (
    <i
      className={"ion-ios-information-outline position-left " + additionalClasses}
      data-toggle="popover"
      data-placement="right"
      data-title={title}
      data-content={body}
    />
  );
};

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  categoryDojo: PropTypes.bool,
  additionalClasses: PropTypes.string,
};

export default Tooltip;
