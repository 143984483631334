import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "../../styles/messages.css";
import EventNavigation from "./lots/EventNavigation";
import AdvancedLotComponent from './AdvancedLotComponent';
import { SocketConnectionHelper } from "../common/SocketServices";
import { updateLotDataOnBid, setInitialProps } from "../../actions/lotActions";

const AdvancedLot = (props) => {
  // This will set locale according to user on moment and work accordingly.
  moment.locale(props.locale);
  const dispatch = useDispatch();
  const lotReducers = useSelector((state) => state.lotReducers);
  const data = lotReducers && lotReducers.isInitialPropsSet ? lotReducers : props;

  let { event, role, translations, permitted_to_edit } = data;

  const getUpdatedData = () => dispatch(updateLotDataOnBid(event.id));
  const currentState = event.current_state;
  // TODO(ade): Update the dependencies of this hook, We are using a mix of
  // hooks and props and never reacting to changes. This is mainly for the
  // socket never getting disconnect or updating the frequency.
  useEffect(() => {
    dispatch(setInitialProps(data));
    const { page_refresh_frequency: frequency } = props;
    SocketConnectionHelper(getUpdatedData, frequency);
  }, []);

  // Bail out if the lotReducer have not been initialized. This could be
  // refactored to set the initial store value as the default data is coming
  // from rails
  if (!lotReducers || !lotReducers.isInitialPropsSet) {
    return null;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 clear-padding">
            <div className="block-content tab-content clear-padding">
              <div className="tab-pane active">
                <AdvancedLotComponent data={data} getUpdatedData={getUpdatedData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {event.current_state !== "new" && role === "Host" && permitted_to_edit && (
        <EventNavigation translations={translations} currentState={currentState} event={event} />
      )}
    </>
  );
};

export default AdvancedLot;
