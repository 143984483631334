import * as actionTypes from "../actions/actionsTypes";

const srmReducers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SIM_QUESTIONS: {
      const { payload } = action;
      return {
        ...state,
        questions: payload.questions,
        linkedQuestions: payload.linkedQuestions,
        sections: payload.sections,
      };
    }
    case actionTypes.FETCH_SIM_QUESTIONNAIRES: {
      const { payload } = action;
      return {
        ...state,
        questionnaires: payload.questionnaires,
      };
    }
    case actionTypes.CREATE_OR_UPDATE_LINKED_QUESTION: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default srmReducers;
