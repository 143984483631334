import * as apiCall from './api';
import * as globalActionTypes from "../actionsTypes";
import actionTypes from './actionsTypes';
import config from './config';

const fetchAdminTextSuccess = (type, text) => ({
  type,
  payload: text,
});

const saveAdminTextSuccess = (type, response) => ({
  type,
  payload: response,
});

const deleteAdminTextSuccess = (type, response) => ({
  type,
  payload: response,
});

const getError = (error) => ({
  type: globalActionTypes.ERROR_CODE,
  payload: error,
});

export const fetchAdminText = (companyId, sectionKey) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/companies/${companyId}/admin_texts/${sectionKey}`)
    .then((response) => {
      dispatch(fetchAdminTextSuccess(actionTypes[sectionKey], response.data));
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);

export const saveAdminText = (companyId, sectionKey, text) => (dispatch) => {
  const payload = {
    admin_texts: {
      section_text: text,
    },
  };
  apiCall.post(`${config.BASE_URL}/companies/${companyId}/admin_texts/${sectionKey}`, payload)
    .then((response) => {
      dispatch(fetchAdminText(companyId, sectionKey));
    })
    .catch((error) => {
      dispatch(getError(error));
    });
};

export const deleteAdminText = (companyId, sectionKey, text) => (dispatch) => (
  apiCall.remove(`${config.BASE_URL}/companies/${companyId}/admin_texts/${sectionKey}`)
    .then(async (response) => {
      try {
        const defaultText = await apiCall.get(`${config.BASE_URL}/companies/${companyId}/admin_texts/${sectionKey}`);
        dispatch(deleteAdminTextSuccess(actionTypes[`${sectionKey}_delete`], defaultText.data));
        return defaultText;
      } catch (error) {
        dispatch(getError(error));
        return error.response;
      }
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);
