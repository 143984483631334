import { selectFieldById } from "@/slices/rules/fields";
import type { Leaf, NormalizedNode, TextParameter } from "@/slices/rules/types";
import PicklistParameter from "./picklist";
import React from "react";
import MultiChoiceParameter from "./multi";
import { useFields } from "../field-context";

export default function TextParameter({ node }: { node: NormalizedNode<Leaf<TextParameter>> }) {
  const fields = useFields();
  const field = selectFieldById(fields, node.state.field_name);
  if (field?.type === "pick_one_from_list") return <PicklistParameter node={node} field={field} />;
  if (field?.type === "multiple_choice") return <MultiChoiceParameter node={node} field={field} />;
  return null;
}
