import { useMemo, useRef } from "react";
import { useSliceActions, useSliceSelector } from "@/context/SliceContext";
import { useDispatch } from "react-redux";
import useInfiniteLoader from "./useInfiniteLoader";

export default function useLineItemInformation(data = []) {
  const { currentPage, resultsPerPage } = useSliceSelector();
  const { setPaginationState } = useSliceActions();
  const dispatch = useDispatch();
  const lastElementRef = useRef();
  useInfiniteLoader(lastElementRef);

  const paginatedData = useMemo(() => {
    dispatch(setPaginationState({ isLoading: true }));

    const endingIndex = currentPage * resultsPerPage;
    const updatedData = data.slice(0, endingIndex);

    dispatch(setPaginationState({ isLoading: false, hasMoreData: updatedData.length !== data.length }));

    return updatedData;
  }, [currentPage, data, dispatch, resultsPerPage, setPaginationState]);

  return { lastElementRef, paginatedData };
}
