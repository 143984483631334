import React from "react";
import { connect } from "react-redux";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import intersection from "lodash/intersection";
import Select2 from "react-select2-wrapper";

import "../../styles/messages.css";
import MessageDetails from "./MessageDetails";
import SendMessageModal from "./sendMessageModal";
import {
  showMessageModal,
  markAsReadToMessage,
  bookmarkToMessage,
  removeBookmark,
  getNewMessages,
} from "../../actions/messageActions";
import { setInitialProps } from "@/actions/lotActions";

export const handleMessageCount = () => {
  let messageCountElement = document.querySelector("div#message_count");
  if (!messageCountElement) return;

  let messageCount = parseFloat(messageCountElement.innerHTML);
  if (isNaN(messageCount)) return;

  messageCount -= 1;

  if (messageCount < 1) {
    messageCountElement.innerHTML = "";
    messageCountElement.style.display = "none";
  } else {
    messageCountElement.innerHTML = messageCount;
  }
};

/**
 * Get data tobe updated in lotReducers
 * so that it can be used by SendMessageModal
 *
 * @param {{}} props
 * @returns {{event: {}, translations: {},
 *  role: string, in_sandpit: boolean, current_user: {},
 *  event_participants: {}, hosts: [], host_permission: {}, documents: []}}
 */
const getLotReducersdata = (props) => ({
  event: props.event,
  translations: props.translations,
  role: props.isCurrentUserHost ? "Host" : "Participant",
  in_sandpit: props.inSandpit,
  current_user: props.currentUser,
  event_participants: props.eventParticipantsData.event_participants,
  hosts: props.hosts,
  host_permission: props.hostsRole,
  documents: props.documentsData.documents,
});

export class MessageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: props.messages.messages,
      eventParticipants: props.eventParticipantsData.event_participants,
      documents: props.documentsData.documents,
      hosts: props.hosts.filter((host) => host.id !== props.currentUserActingAsId),
      showAllMessages: true,
      showUnreadMessages: false,
      showBookmarkedMessages: false,
      showMessagesOf: "",
      selectedSupplier: "",
      bookmarkMessage: props.bookmarkMessage,
    };
    //binding functions to component
    this.placeNewMessageInArray = this.placeNewMessageInArray.bind(this);
    this.markAsBookmark = this.markAsBookmark.bind(this);
  }

  componentDidMount() {
    /**
     * update lotReducers data
     */
    this.props.setInitialProps(getLotReducersdata(this.props));
    const selfThis = this;
    let socketConnectionInitialized = false;
    if (!this.props.simDojo) {
      setTimeout(function () {
        const socketObject = document.ChartSocket;
        if (socketObject.connected) {
          selfThis.getMessagesFromSocket();
          socketConnectionInitialized = true;
        } else {
          selfThis.periodicallyUpdateMessages();
        }
        socketObject.on("connect", () => {
          if (!socketConnectionInitialized) {
            selfThis.getMessagesFromSocket();
            socketConnectionInitialized = true;
          }
        });
        socketObject.on("disconnect", () => {
          selfThis.periodicallyUpdateMessages();
        });
      }, 1000);
    }
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.data.message) {
      this.placeNewMessageInArray(newProps.data.message);
    }
    const newMessages = newProps.data.messages;
    let { messages } = this.state;
    if (newMessages) {
      await newMessages.forEach(async (newMessage) => {
        if (newMessage.parent_id && newMessage.system_message_type !== "setting") {
          let parentIndex;
          await messages.forEach((message, idx) => {
            if (newMessage.parent_id === message.id.toString() || newMessage.parent_id === message.parent_id) {
              parentIndex = idx;
            }
          });
          messages.splice(parentIndex + 1, 0, newMessage);
        } else {
          messages.unshift(newMessage);
        }
      });
      this.setState({
        messages,
      });
    }
  }

  getMessagesFromSocket() {
    const selfThis = this;
    const socketObject = document.ChartSocket;
    socketObject.on("message", async (data) => {
      if (typeof data === "object") {
        selfThis.placeNewMessageInArray(data);
        //Update mesage count after receving the new message
        let loggedUserRecipient = find(data.recipients, ["user_id", selfThis.props.currentUserActingAsId]);
        if (data.system_message_type !== "setting" && !isEmpty(loggedUserRecipient)) {
          let messageCount = parseInt($("div#message_count").html());
          messageCount = messageCount ? messageCount + 1 : 1;
          $("div#message_count").show();
          $("div#message_count").html(messageCount);
        }
      }
    });
  }
  // periodically updating the messages when websocket is not connected
  periodicallyUpdateMessages() {
    const selfThis = this;
    const { getNewMessages, frequency, inSandpit, currentUserActingAsId } = this.props;
    const socketObject = document.ChartSocket;
    if (frequency) {
      let timerId = setTimeout(function periodicUpdate() {
        if (!socketObject || !socketObject.connected) {
          const lastMessageId = selfThis.state.messages[0].id;
          getNewMessages(lastMessageId);
          clearTimeout(timerId);
          timerId = setTimeout(periodicUpdate, frequency * 1000);
        }
      }, frequency * 1000);
    }
  }

  async placeNewMessageInArray(newMessage) {
    let { messages } = this.state;
    if (newMessage.parent_id && newMessage.system_message_type !== "setting") {
      let parentIndex;
      await messages.forEach((message, idx) => {
        if (newMessage.parent_id === message.id.toString() || newMessage.parent_id === message.parent_id) {
          parentIndex = idx;
        }
      });
      messages.splice(parentIndex + 1, 0, newMessage);
    } else {
      messages.unshift(newMessage);
    }
    this.setState({
      messages,
    });
  }

  messageType(message) {
    const { isCurrentUserHost, translations } = this.props;
    let messageIcon = "";
    const systemMessageType = message.system_message_type;
    messageIcon = (
      <React.Fragment>
        {isCurrentUserHost &&
          (systemMessageType === "host" ? (
            <span className="message-text-green">({translations.internal})</span>
          ) : (
            <span className="message-text-red">({translations.external})</span>
          ))}
        <span className={`system-icon ${systemMessageType !== "setting" ? "hide" : ""}`}>
          <i className="fa fa-cog" aria-hidden="true" title={translations.systemMessage} />
        </span>
      </React.Fragment>
    );
    return messageIcon;
  }

  isAMessagerecipient(message, epContactIds) {
    const recipient_ids = message.recipients.map(function (hash) {
      return hash.user_id;
    });
    return intersection(recipient_ids, epContactIds) > 0;
  }

  isAMessageSender(message, epContactIds) {
    return epContactIds.indexOf(message.user.id) !== -1;
  }

  async markAsBookmark(messageId, isBookmark) {
    let response = undefined;
    let { bookmarkMessage } = this.state;
    if (isBookmark) {
      response = await this.props.removeBookmark(messageId);
      bookmarkMessage.splice(bookmarkMessage.indexOf(messageId), 1);
    } else {
      response = await this.props.bookmarkToMessage(messageId);
      bookmarkMessage.push(messageId);
    }
    if (response) {
      this.setState({
        bookmarkMessage: bookmarkMessage,
      });
    }
  }

  async markAsReadMessage(messageId) {
    $("#mark_as_read_btn_" + messageId).prop("disabled", true);
    const { inSandpit, currentUserActingAsId } = this.props;
    const payload = { message: { read: true } };
    if (inSandpit) {
      payload.in_sandpit = "true";
      payload.in_sandpit_as_user = currentUserActingAsId;
    }
    const response = await this.props.markAsReadToMessage(messageId, payload);
    let { messages } = this.state;
    if (response) {
      messages = await messages.map((msg) => (msg.id === messageId ? response.message : msg));
      this.setState({
        messages: messages,
      });

      //update mark as read button
      const element = $("#message_" + messageId);
      element.removeClass("unread");
      element.find(".fa-circle").hide();
      $("#mark_as_read_btn_" + messageId).hide();
    }
    $("#mark_as_read_btn_" + messageId).prop("disabled", false);
  }

  classesForMessage(message) {
    const { currentUserActingAsId, isCurrentUserHost, bookmarkMessage } = this.props;
    let classes = "";
    if (message.user.id === currentUserActingAsId) {
      classes += "outbox-ms";
    } else {
      classes = "inbox-ms m-r0";
    }
    if (message.system_message_type !== "setting") {
      message.recipients.forEach((recipient) => {
        if (
          (recipient.user_id === currentUserActingAsId &&
            !recipient.message_read &&
            recipient.user_id !== message.user.id) ||
          (message.bounced && !recipient.message_read)
        ) {
          classes += " unread";
        }
      });
    }
    if (message.system_message_type == "host") {
      classes += " host-message";
    } else {
      if (message.system_message_type == "setting") {
        classes += " system-message";
      } else {
        classes += isCurrentUserHost ? " participant-message" : " host-message";
      }
    }
    if (bookmarkMessage.includes(message.id)) {
      classes += " bookmarked-ms";
    }
    return classes;
  }

  filterMessagesBystatus(filter) {
    let { messages } = this.state;
    this.setState((state) => ({
      messages: messages,
      showAllMessages: filter === "all",
      showUnreadMessages: filter === "unread",
      showBookmarkedMessages: filter === "bookmarked",
    }));
  }

  filterMessagesByType(type) {
    let { showMessagesOf } = this.state;
    if (showMessagesOf === type) {
      showMessagesOf = "";
    } else {
      showMessagesOf = type;
    }
    this.setState({
      showMessagesOf,
      selectedSupplier: "",
    });
  }

  suppliersForFilter() {
    let data = this.state.eventParticipants.map((participant) => {
      return { text: participant.display_name, id: participant.user_id };
    });
    data.unshift({});
    return data;
  }

  handleFilterBySupplier(e) {
    this.setState({
      selectedSupplier: parseInt(e.target.value),
      showMessagesOf: "participant-message",
    });
  }

  handleUnselectSupplier(e) {
    this.setState({
      selectedSupplier: "",
    });
    $(".select2-selection__rendered").attr("title", "");
  }

  render() {
    const {
      messages,
      eventParticipants,
      documents,
      hosts,
      showAllMessages,
      showUnreadMessages,
      showBookmarkedMessages,
      showMessagesOf,
      selectedSupplier,
    } = this.state;
    const {
      currentUser,
      event,
      isCurrentUserHost,
      hostsRole,
      translations,
      marketDojo,
      simDojo,
      inSandpit,
      currentUserActingAsId,
      currentUserHasEditPermissionOnEvent,
      epContactIds,
      participantEditPermission,
      bookmarkMessage,
      timeZoneIdentifier,
    } = this.props;
    const replyUserId = isCurrentUserHost ? null : event.user_id;
    return (
      <React.Fragment>
        <div className="row">
          <div className="message-bar">
            <div className="col-md-6 col-sm-6 col-xs-6 m-b20 xs-full-width">
              {(isCurrentUserHost || participantEditPermission) && (
                <button
                  className="btn btn-primary xs-full-width btn-height"
                  onClick={() =>
                    this.props.showMessageModal(true, null, replyUserId, "MessageContainerSendMessageModal")
                  }
                  data-label="send_new_message_btn"
                >
                  {translations.sendNewMessage}
                </button>
              )}
              {!simDojo && !inSandpit && messages.length > 0 && isCurrentUserHost && (
                <a href={`/events/${event.id}/messages.xlsx`} className="btn btn-default xs-full-width">
                  {translations.download}
                </a>
              )}
            </div>
            <div className="col-md-6 col-sm-6 col-xs-6 m-b20 xs-full-width text-right">
              <div className="btn-group" data-toggle="buttons" data-label="message_filter_btn">
                <label
                  className={`btn btn-default btn-default-gray ${showAllMessages ? "active" : ""}`}
                  onClick={() => this.filterMessagesBystatus("all")}
                  data-label="all_message_filter_btn"
                >
                  <input type="checkbox" autoComplete="off" />
                  {translations.all}
                </label>
                <label
                  className={`btn btn-default btn-default-gray ${showUnreadMessages ? "active" : ""}`}
                  onClick={() => this.filterMessagesBystatus("unread")}
                  data-label="unread_message_filter_btn"
                >
                  <input type="checkbox" autoComplete="off" />
                  {translations.unread}
                </label>
                <label
                  className={`btn btn-default btn-default-gray ${showBookmarkedMessages ? "active" : ""}`}
                  onClick={() => this.filterMessagesBystatus("bookmarked")}
                >
                  <input type="checkbox" autoComplete="off" />
                  {translations.bookmarked}
                </label>
              </div>
              <div className="btn-group m-r0" data-toggle="buttons">
                {isCurrentUserHost && (
                  <label
                    className={`btn btn-default btn-default-gray ${
                      showMessagesOf === "participant-message" ? "active" : ""
                    }`}
                    onClick={() => this.filterMessagesByType("participant-message")}
                  >
                    <input type="checkbox" autoComplete="off" />
                    {translations.participant}
                  </label>
                )}
                {isCurrentUserHost && !simDojo && showMessagesOf === "participant-message" && (
                  <span className={`supplier-filter ${selectedSupplier ? "active-supplier-filter" : ""}`}>
                    <Select2
                      data={this.suppliersForFilter()}
                      onSelect={(e) => this.handleFilterBySupplier(e)}
                      value={selectedSupplier}
                      className="col-md-10 col-sm-10 col-xs-10"
                      options={{
                        placeholder: translations.selectSupplier,
                        allowClear: true,
                        language: {
                          noResults: function () {
                            return translations.noResultsMatch;
                          },
                        },
                      }}
                      onUnselect={(e) => this.handleUnselectSupplier(e)}
                    />
                  </span>
                )}
                {(isCurrentUserHost || simDojo) && (
                  <label
                    className={`btn btn-default btn-default-gray ${showMessagesOf === "host-message" ? "active" : ""}`}
                    onClick={() => this.filterMessagesByType("host-message")}
                  >
                    <input type="checkbox" autoComplete="off" />
                    {translations.event_host}
                  </label>
                )}
                {simDojo && (
                  <label
                    className={`btn btn-default btn-default-gray ${
                      showMessagesOf === "system-message" ? "active" : ""
                    }`}
                    onClick={() => this.filterMessagesByType("system-message")}
                  >
                    <input type="checkbox" autoComplete="off" /> {translations.system}
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="message-thread row" id="load_messages">
          <div className="col-md-12 messages-row">
            {messages.length > 0 ? (
              messages.map((message, idx) => {
                if (
                  isCurrentUserHost ||
                  this.isAMessageSender(message, epContactIds) ||
                  this.isAMessagerecipient(message, epContactIds)
                ) {
                  return (
                    <MessageDetails
                      message={message}
                      key={idx}
                      messageType={this.messageType(message)}
                      isCurrentUserHost={isCurrentUserHost}
                      currentUser={currentUser}
                      markAsReadMessage={(message) => this.markAsReadMessage(message)}
                      markAsBookmark={this.markAsBookmark}
                      bookmarkMessage={bookmarkMessage}
                      showBookmarkedMessages={showBookmarkedMessages}
                      classesForMessage={this.classesForMessage(message)}
                      translations={translations}
                      currentUserActingAsId={currentUserActingAsId}
                      inSandpit={inSandpit}
                      showMessagesOf={showMessagesOf}
                      showAllMessages={showAllMessages}
                      simDojo={simDojo}
                      selectedSupplier={selectedSupplier}
                      epContactIds={epContactIds}
                      timeZoneIdentifier={timeZoneIdentifier}
                    />
                  );
                }
              })
            ) : (
              <p>{translations.noMessages}</p>
            )}
          </div>
        </div>
        <SendMessageModal
          documents={documents}
          marketDojo={marketDojo}
          hasPermissionToEdit={currentUserHasEditPermissionOnEvent}
          htmlId="MessageContainerSendMessageModal"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.messageReducers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showMessageModal: (isNewMessage, messageReplyId, replyUserId, modalId) =>
    dispatch(showMessageModal(isNewMessage, messageReplyId, replyUserId, modalId)),
  markAsReadToMessage: (messageId, data) => dispatch(markAsReadToMessage(messageId, data)),
  bookmarkToMessage: (messageId) => dispatch(bookmarkToMessage(messageId)),
  removeBookmark: (messageId) => dispatch(removeBookmark(messageId)),
  getNewMessages: (lastMessageId) => dispatch(getNewMessages(lastMessageId)),
  setInitialProps: (data) => dispatch(setInitialProps(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer);
