import actionTypes from '../../actions/qq/actionsTypes';

const qqReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.create_qq_event_success:
      return {
        ...state,
        newQuote: action.payload,
      };
    case actionTypes.fetch_quote_by_id:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.fetch_lots_by_id:
      return {
        ...state,
        lots_list: action.payload,
      };
    case actionTypes.fetch_event_participants_by_id:
      return {
        ...state,
        event_participants_list: action.payload,
      };
    case actionTypes.fetch_qq_events:
      return {
        ...state,
        qqEvents: action.payload,
      };
    case actionTypes.fetch_configuration_limit:
      return {
        ...state,
        limits: action.payload,
      };
    case actionTypes.fetch_event_hosts:
      return {
        ...state,
        hosts: action.payload,
      };
    case actionTypes.fetch_pipeline_projects:
      return {
        ...state,
        pipeline_projects: action.payload,
      };
    case actionTypes.mutated_events:
      state.qqEvents.events = (state.qqEvents.events).filter((event) => (event.id !== action.payload));
      return {
        ...state,
      };
    case actionTypes.currencies:
      state.currencies = action.payload.currencies_data;
      return { ...state };
    default:
      return state;
  }
};

export default qqReducer;
