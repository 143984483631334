import { createConfirmation } from 'react-confirm';
import ComplexConfirmation from '../InfoPopover';
import Confirmation from '../ConfirmationPopUp';

const defaultConfirmation = createConfirmation(Confirmation);
export function confirm(confirmation, options = {}) {
  return defaultConfirmation({ confirmation, ...options });
}

export const confirmComplex = createConfirmation(ComplexConfirmation);

