import PropTypes from "prop-types";
import React from "react";

const setShowChild = (activity, target_id) => {
  if (target_id === activity.id) {
    activity.show_child = !activity.show_child;
  }

  return activity;
};

const _resolveClass = (show_child) =>
  (show_child ? "fa fa-chevron-circle-up text-muted" : "fa fa-chevron-circle-down text-dark") +
  " fa-2x m-r10 cursor-pointer";

const _clickHandler = (isChild, childActions, setActivities, activities, setShowChild, activityId) => {
  if (isChild) {
    childActions.setChildActivities(childActions.childActivities.map((child) => setShowChild(child, activityId)));
  } else {
    setActivities(activities.map((a) => setShowChild(a, activityId)));
  }
};

const Styles = {
  Icon: (depth) => ({
    marginLeft: (depth || 0) * 12 + (depth ? 10 : 0) + "px",
  }),
  Label: (children, depth) => ({
    marginLeft: !children.length ? (depth || 0) * 10 + (depth ? 12 : 0) + "px" : 0,
  }),
};

const Subject = ({
  filterActive,
  activity,
  depth,
  children,
  isChild,
  childActions,
  setActivities,
  editBtnOrTxt,
  activities,
}) => (
  <td className="table-cell-ellipsis" title={activity.subject}>
    {children.length > 0 && !filterActive && (
      <i
        className={_resolveClass(activity.show_child)}
        style={Styles.Icon(depth)}
        onClick={(e) => _clickHandler(isChild, childActions, setActivities, activities, setShowChild, activity.id)}
      ></i>
    )}
    <span style={Styles.Label(children, depth)}>{editBtnOrTxt(activity)}</span>
  </td>
);

Subject.propTypes = {
  filterActive: PropTypes.bool,
  activity: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  depth: PropTypes.number,
  children: PropTypes.array.isRequired,
  isChild: PropTypes.bool,
  childActions: PropTypes.object.isRequired,
  setActivities: PropTypes.func.isRequired,
  editBtnOrTxt: PropTypes.func.isRequired,
};

Subject.defaultProps = {
  depth: 0,
  isChild: false,
  filterActive: false,
};

export default Subject;
