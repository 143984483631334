import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TrixEditor from "../../common/TrixEditor";
import { updateNotes } from "../../../actions/contract/contractCreateEditActions";
import Modal from "react-modal";

const editNoteModal = (props) => {
  const { isModalOpen, note, closeModal, contractId, translations } = props;
  const [contrctId, setContrctId] = useState(contrctId);
  const [content, setContent] = useState(note.content);
  Modal.setAppElement("body");

  const dispatch = useDispatch();

  const handleSubmit = (note) => {
    dispatch(
      updateNotes(contractId, note.id, note.include_in_alerts, note.bookmark, false, content, note.private)
    ).then((response) => {
      if (response.status === 200) {
        closeModal();
      }
    });
  };

  const style = {
    content: {
      overflow: "auto",
      outline: "none",
    },
  };

  return (
    <React.Fragment>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal-content col-md-6 col-sm-6" style={style}>
        <div className="modal-header">
          {translations.edit} {translations.notes}
          <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={closeModal}>
            <span className="fa fa-remove" title="close"></span>
          </button>
        </div>
        <div className="modal-body notes-modal">
          <TrixEditor value={content} onChange={setContent} />
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" onClick={closeModal}>
            {translations.cancel}
          </button>
          <button type="button" className={`btn btn-primary btn-default ok-btn`} onClick={() => handleSubmit(note)}>
            {translations.save}
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default editNoteModal;
