import React, { useState, Fragment } from "react";
import { Confirm } from "../../common/Confirm";
import {
  bidRoundPrice,
  bidRoundValue,
  canShowComplexBidButtons,
  hasValue,
  showCurrency,
  toggleButton,
  currenciesForSelect,
} from "../common";
import { useDispatch } from "react-redux";
import { placeBid } from "../../../actions/bidActions";
import { latestBidDuringAuctionForLot, participantActiveInJapaneseAuction } from "../bidCommon";
import { running } from "../eventCommon";
import LotBidComponent from "../bids/LotBidComponent";
import ParticipantProgressionChartModal from "../lots/participantScreenComponents/ParticipantProgressionGraph";
import Select2 from "react-select2-wrapper";
import ShowButtons, { isBidInRound, biddingClosedCondition } from "./common/japaneeseEvent/showButtons";

const JapaneseMonitor = (props) => {
  const {
    translations,
    lot,
    event,
    currency,
    event_participant: participant,
    unitOfMeasures,
    unitSetUoms,
    locale,
    lotErrors,
    bid_round,
    auctionControls,
    weighted_rfq_or_auction: weightedRfqOrAuction,
    LBids,
    remainingParticipants,
    currentUser,
    currencies_hash = [],
  } = props;
  const precision = 2;
  const latestBids = latestBidDuringAuctionForLot(LBids, [participant], event, weightedRfqOrAuction).sort(function (
    bid2,
    bid1
  ) {
    return new Date(bid1.created_at) - new Date(bid2.created_at);
  });
  const bid = latestBids?.[0] ?? {};
  const htmlAcceptId = `JapaneseMonitorAcceptConfirmModal-${lot.id}`;
  const htmlDeclineId = `JapaneseMonitorDeclineConfirmModal-${lot.id}`;
  const { number_format: numberFormat } = translations;

  const dispatch = useDispatch();
  const [japMonState, setJapMonitorState] = useState({
    errors: [],
    lotPrice: "",
    lotMessage: "",
    totalPrice: "",
    activeEnterBid: false,
    detailedView: false,
  });

  const exchangeRates = currenciesForSelect(currencies_hash);

  const [selectedCurrencyExchangeId, setSelectedCurrencyExchangeId] = useState(
    bid?.exchange_rate_id ?? lot.exchange_rate_id
  );
  const exchangeRateText = exchangeRates.find((obj) => obj.id === selectedCurrencyExchangeId?.toString())?.text;

  const isShowingCurrencyDropDown = () =>
    props.bid_round === 1 &&
    event.multi_currency_event &&
    running(event) &&
    !isBidInRound(props) &&
    biddingClosedCondition(props);
  const setState = (state) => {
    setJapMonitorState({ ...japMonState, ...state });
  };
  const { detailedView } = japMonState;

  const isSubmitted = bid && bid.current_state === "submitted" ? true : false;
  const bidPrice = bid && hasValue(bid.price) ? bid.price : "";
  const price = bidRoundPrice(lot, event, bid_round, auctionControls);
  const totalPrice = bidRoundValue(lot, event, bid_round, auctionControls);
  const allUom = [...unitSetUoms, ...unitOfMeasures];
  const uom = allUom.find((u) => u.id === lot.unit_of_measure_id);
  const isParticipantActive = participantActiveInJapaneseAuction(event, remainingParticipants[lot.id], currentUser.id);
  const onSubmit = (decline = null) => {
    dispatch(
      placeBid(lot.id, {
        bid: {
          event_id: event.id,
          lot_id: lot.id,
          user_id: participant.user_id,
          price: decline ? null : price,
          decline_bid: decline,
          bid_round,
          exchange_rate_id: event.multi_currency_event ? selectedCurrencyExchangeId : null,
        },
        before_auction: "false",
      })
    )
      .then((res) => {
        if (res && res.message) {
          setState({ lotMessage: res.message, activeEnterBid: false });
        } else {
          setState({ lotMessage: "", activeEnterBid: false });
        }
      })
      .catch((data) => {
        data && data.message && setState({ lotMessage: data.message });
      });
  };
  let timedOutOrDeclined = bid && bid.decline_bid ? bid.decline_bid : false;
  const bidDetailsAdded = bid && bid.details_added;
  const [isOpen, setIsOpen] = useState(false);
  const onGraphClick = (e) => {
    setIsOpen(true);
  };

  const onClick = () => {
    setState({ detailedView: !detailedView });
  };

  return (
    <>
      <div className="table-body">
        <div className="table-in-form lot">
          <div className="tbody-all head-all transform-none head-lot">{lot.position}</div>
          <div className="tbody-all head-all transform-none head-lotname">
            {canShowComplexBidButtons(event, lot, participant.user_id) && toggleButton(onClick)}
            <span title={lot.name}>{lot.name}</span>
          </div>
          <div className="tbody-all head-all transform-none head-quantity">
            {lot.quantity} x {uom && uom.name ? uom.name : ""}
          </div>

          <div className="tbody-all head-all transform-none head-price">
            {isSubmitted && hasValue(bidPrice)
              ? showCurrency(bidPrice, currency, precision, locale, numberFormat)
              : translations.no_bids}
          </div>

          <div className="text_center tbody-all tbody-current-bid">
            {!lot.is_event_total ? (
              isParticipantActive ? (
                <span>{showCurrency(price, currency, numberFormat.precision, locale, numberFormat)}</span>
              ) : (
                "-"
              )
            ) : null}
          </div>

          <div className="text_center text_center tbody-all tbody-current-total">
            {!lot.is_event_total ? (
              isParticipantActive ? (
                <span>{showCurrency(totalPrice, currency, numberFormat.precision, locale, numberFormat)}</span>
              ) : (
                "-"
              )
            ) : null}
          </div>
          <div className="tbody-all tbody-jap-currency exchange-rate">
            {isShowingCurrencyDropDown() ? (
              <div className="input">
                <Select2
                  className={`exchange_rate_${lot.id} form-control`}
                  name="selectedCurrencyExchangeId"
                  data={exchangeRates}
                  onSelect={(e) => setSelectedCurrencyExchangeId(e.target.value)}
                  value={selectedCurrencyExchangeId}
                  options={{ minimumResultsForSearch: -1 }}
                />
              </div>
            ) : (
              <Fragment>{exchangeRateText}</Fragment>
            )}
          </div>

          <div className="tbody-all head-all transform-none head-jap-buttons">
            <ShowButtons
              {...props}
              timedOutOrDeclined={timedOutOrDeclined}
              htmlAcceptId={htmlAcceptId}
              htmlDeclineId={htmlDeclineId}
              isParticipantActive={isParticipantActive}
            />
          </div>
          {isSubmitted && !timedOutOrDeclined ? (
            <div className="tbody-all tbody-iconbar">
              <div className="solid-box simple-lot">
                <a onClick={onGraphClick}>
                  <i className="fa fa-bar-chart"></i>
                </a>
              </div>
              <ParticipantProgressionChartModal {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
          ) : (
            <div className="tbody-all tbody-iconbar">
              <div className="solid-box simple-lot invisible" style={{ display: "none" }}>
                <div className="work-area" colSpan="10"></div>
              </div>
            </div>
          )}
        </div>

        {lotErrors && lotErrors[lot.id] && lotErrors[lot.id][0] && (
          <div className="notification error alert-dismissable" style={{ maxWidth: "none" }}>
            <div className="col-md-12">
              <i className="fa fa-times"></i>
              <p>{lotErrors[lot.id][0]}</p>
              <div className="clear"></div>
            </div>
          </div>
        )}
        {detailedView && (
          <LotBidComponent
            {...props}
            isLotLevelMonitor={true}
            bidDetailsAdded={bidDetailsAdded}
            isJapaneseMonitor={true}
            participant={participant}
            weightedRfqOrAuction={weightedRfqOrAuction}
            isPlaceBidActiveFlag={!bidDetailsAdded}
            picklistOptions={props.picklist_options}
          />
        )}
      </div>
      <Confirm
        translations={translations}
        message={
          <>
            {translations.value_entered}
            <br />
            <strong>{showCurrency(totalPrice, currency, precision, locale, numberFormat)}</strong>
            <br />
            {translations.are_you_sure}
          </>
        }
        htmlId={htmlAcceptId}
        okButtonText={translations.submit}
        onOkayButtonClick={() => onSubmit()}
      />

      <Confirm
        translations={translations}
        message={translations.are_you_sure}
        htmlId={htmlDeclineId}
        onOkayButtonClick={() => {
          onSubmit(true);
        }}
      />
    </>
  );
};

export default JapaneseMonitor;
