import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tooltip from "../../../common/Tooltip";
import HostPermissionsTableRows from "./HostPermissionsTableRows";
import { useSliceSelector } from "@/context/SliceContext";
import useHostPermissionsFetch from "@/components/advanced_lots/hooks/host/useHostPermissionsFetch";
import RenderHTML from "@/components/common/RenderHTML";
import { t } from "@/i18n";

const PermissionTableHeadRowPropTypes = {
  marketDojo: PropTypes.bool,
  categoryDojo: PropTypes.bool,
  contractDojo: PropTypes.bool,
  simDojo: PropTypes.bool,
};

/**
 * @param {() => void} t
 * @returns {Array} permissionTableHeads
 */
const getPermissionTableHeads = (t) => {
  const infoTranslations = "tooltips:contextual_help.host.user_permission";
  const headers = [
    { name: "User", title: "user" },
    { name: "event_status", title: "status" },
    { name: "my_group_html", title: "host_groups" },
    { name: "administration", title: "admin" },
    { name: "events_html", title: "items" },
    { name: "contracts", title: "items" },
    { name: "sim_dojo", title: "sim_permissions" },
    { name: "categories", title: "items" },
    { name: "action" },
  ];

  return headers.map((header) => ({
    ...header,
    ...(header.name !== "action"
      ? { title: t(`${infoTranslations}.${header.title}.title`), body: t(`${infoTranslations}.${header.title}.body`) }
      : {}),
    class: header.name === "User" || header.name === "action" ? "w-20" : undefined,
  }));
};

export const HostPermissionsTable = (props) => {
  useEffect(() => {
    if (props.categoryDojo) {
      initializeInputHelpAndTooltip();
    }
    // We should be doing the cleanup here in case of multiple initialization
  }, [props.categoryDojo]);

  return (
    <>
      <table className="border-collapse user_permissions table-layout-fixed">
        <thead className="table-block-title">
          <PermissionTableHeadRow {...props} />
        </thead>
        <HostPermissionsTableRows {...props} />
      </table>
    </>
  );
};

/**
 * @type {React.FC<typeof PermissionTableHeadRowPropTypes>}
 */
export const PermissionTableHeadRow = (props) => {
  const { marketDojo, categoryDojo, contractDojo, simDojo } = props;
  const permissionTableHeads = getPermissionTableHeads(t);

  return (
    <tr>
      {permissionTableHeads.map((header) => {
        const headerName = header.name;
        const className = header.class ?? "w-15";
        const cantShowEventsHeader = headerName === "events_html" && !marketDojo;
        const cantShowContractsHeader = headerName === "contracts" && !contractDojo;
        const cantShowSimDojoHeader = headerName === "sim_dojo" && !simDojo;
        const cantShowCategoriesHeader = headerName === "categories" && !categoryDojo;
        if (cantShowEventsHeader || cantShowContractsHeader || cantShowSimDojoHeader || cantShowCategoriesHeader)
          return;

        return (
          <td key={headerName} className={className}>
            <RenderHTML content={t(headerName === "categories" ? "category_permissions.categories" : headerName)} />
            {headerName !== "action" && <Tooltip title={header.title} body={header.body} categoryDojo={categoryDojo} />}
          </td>
        );
      })}
    </tr>
  );
};
PermissionTableHeadRow.propTypes = PermissionTableHeadRowPropTypes;

export const HostPermissionsTableWithPagination = (props) => {
  const { companyId, categoryDojo, translations } = props;
  const { isLoading } = useSliceSelector();
  const { lastElementRef, search, setSearch } = useHostPermissionsFetch(companyId, categoryDojo);

  return (
    <>
      <input
        type="search"
        className="form-control my-4"
        style={{ width: "25%" }}
        aria-label={translations.search_user}
        placeholder={translations.search_user}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <HostPermissionsTable {...props} />
      <div style={{ width: "100%", textAlign: "center" }} ref={lastElementRef}>
        {isLoading && translations.loading}
      </div>
    </>
  );
};
