import React, { Component } from "react";
import { canShowExpandButtons, classesFromEventState } from "../../common";
export default class HostMonitorActions extends Component {
  render() {
    const { event, participatingParticipants, isComplex, isEpOpened, beforeAuction } = this.props;

    return (
      <div className="col-md-6">
        {participatingParticipants.length > 0 && <>{this.showOrHideEpButton()}</>}
        {isComplex && canShowExpandButtons(event, beforeAuction) && (
          <>
            {this.showOrHideLiButton()}
            {isEpOpened && this.showOrHideLcButton()}
          </>
        )}
      </div>
    );
  }
  showOrHideLiButton() {
    const { event, translations, toggleLiCollapse, isLiOpened, lots } = this.props;
    const content =
      isLiOpened.length === lots.length ? (
        <>
          <i className="fa fa-minus-circle"></i> {translations.hide_line_items}
        </>
      ) : (
        <>
          <i className="fa fa-plus-circle"></i> {translations.expand_line_items}
        </>
      );

    return (
      <a
        onClick={(e) => toggleLiCollapse(e)}
        data-lot-id="All"
        className={`btn ${classesFromEventState(event)} status-event sm-butt push-10-r button white`}
      >
        {content}
      </a>
    );
  }

  showOrHideEpButton() {
    const { event, translations, toggleEpCollapse, isEpOpened } = this.props;
    const content = isEpOpened ? (
      <>
        <i className="fa fa-minus-circle"></i> {translations.hide_participants}
      </>
    ) : (
      <>
        <i className="fa fa-plus-circle"></i> {translations.expand_participants}
      </>
    );

    return (
      <a
        onClick={toggleEpCollapse}
        className={`btn ${classesFromEventState(event)} status-event sm-butt push-10-r button white `}
      >
        {content}
      </a>
    );
  }
  showOrHideLcButton() {
    const { event, translations, toggleLcCollapse, isLcOpened, participatingParticipants } = this.props;
    const content =
      isLcOpened.length === participatingParticipants.length ? (
        <>
          <i className="fa fa-minus-circle"></i> {translations.hide_components}
        </>
      ) : (
        <>
          <i className="fa fa-plus-circle"></i> {translations.expand_components}
        </>
      );

    return (
      <a
        onClick={(e) => toggleLcCollapse(e)}
        className={`btn ${classesFromEventState(event)} status-event sm-butt push-10-r button white `}
        data-ep-id="All"
      >
        {content}
      </a>
    );
  }
}
