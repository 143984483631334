import type { UnknownAction, StoreEnhancer, Store, Reducer, Action } from "@reduxjs/toolkit";

interface ActionLogStore {
  getActions(): Array<this extends Store<any, infer A> ? A : UnknownAction>;
  clearActions(): void;
}

const wrapReducer =
  <State, A extends Action, PreloadedState>(
    reducer: Reducer<State, A, PreloadedState>,
    actions: A[]
  ): Reducer<State, A, PreloadedState> =>
  (state, action) => {
    actions.push(action);
    return reducer(state, action);
  };

/**
 * A store enhancer that logs all dispatched actions, and exposes them via the `getActions` method.
 *
 * A `clearActions` method is also exposed to clear the action log.
 *
 * Actions are not logged in production.
 *
 */
export const actionLogEnhancer: StoreEnhancer<ActionLogStore> = (next) => (reducer, preloadedState) => {
  type A = Parameters<typeof reducer>[1];
  const actions: A[] = [];
  const store = next(process.env.NODE_ENV !== "production" ? wrapReducer(reducer, actions) : reducer, preloadedState);

  return {
    ...store,
    getActions() {
      // Skip the initial `@@INIT` action
      return actions.slice(1);
    },
    clearActions() {
      // Keep the initial `@@INIT` action
      actions.length = 1;
    },
  };
};
