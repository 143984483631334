import React from "react";
import { useAppSelector } from "@/hooks/redux";
import { t } from "@/i18n";
import { needsQualificationPrice } from "@/components/advanced_lots/common";
import PriceComponentCurrencyField from "./PriceComponentCurrencyField";

interface QualificationPricePropType {
  precision: number;
  disabled?: boolean;
  onChange: (value: string | number) => void;
  qualificationPrice: number;
  qualificationVal: number;
  symbol: string;
}

const useTooltipText = () => {
  return {
    qualificationPriceTooltipText: {
      title: t("qq_tooltips.tooltips.host.lot.qualification_price.title", { ns: "tooltips" }),
      body: t("qq_tooltips.tooltips.host.lot.qualification_price.body", { ns: "tooltips" }),
    },
    qualificationValueTooltipText: {
      title: t("qq_tooltips.tooltips.host.lot.qualification_value.title", { ns: "tooltips" }),
      body: t("qq_tooltips.tooltips.host.lot.qualification_value.body", { ns: "tooltips" }),
    },
  };
};

export default function QualificationPrice(props: QualificationPricePropType) {
  const { precision, symbol, disabled, onChange, qualificationPrice, qualificationVal } = props;
  const event = useAppSelector(({ lotReducers }) => lotReducers.event);
  const { qualificationPriceTooltipText, qualificationValueTooltipText } = useTooltipText();

  return (
    <Wrapper>
      <PriceComponentCurrencyField
        {...{ symbol, precision, onChange, disabled }}
        label={`${t("qualification_price_uom")} ${t("not_shown_to_participants")}`}
        name="qualificationPrice"
        tooltipText={qualificationPriceTooltipText}
        value={qualificationPrice}
        required={needsQualificationPrice(event)}
      />
      <PriceComponentCurrencyField
        {...{ symbol, precision, onChange, disabled: true }}
        label={t("total_qualification_value")}
        name="qualificationVal"
        tooltipText={qualificationValueTooltipText}
        value={qualificationVal}
      />
    </Wrapper>
  );
}

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const [first, second] = React.Children.toArray(children);
  return (
    <>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-12">{first}</div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div className="row">
            <div className="col-md-12">{second}</div>
          </div>
        </div>
      </div>
    </>
  );
};
