import React, { Component } from "react";
import { connect } from "react-redux";
import Select2 from "react-select2-wrapper";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import replace from "lodash/replace";
import "../../../../styles/hostPermissions.css";
import { Confirm } from "../../../common/Confirm";
import Tooltip from "../../../common/Tooltip";
import {
  showAlert,
  saveNewSuperhost,
  closeUserPermissionModal,
  deleteParticipantPermission,
  getCompanyUserPermissions,
} from "../../../../actions/adminActions";
import { CategoriesPermissionTab } from "./CategoriesPermissionTab";
import { EventsPermissionTab } from "./EventsPermissionTab";
import { ContractsPermissionTab } from "./ContractsPermissionTab";
import SimPermissionsTab from "./SimPermissionsTab";
import PermissionsLayout from "./permissions-layout";

export class UserPermissionEditModal extends Component {
  constructor(props) {
    super(props);

    // Binding this
    this.saveSuperhost = this.saveSuperhost.bind(this);

    // Setting intial state
    this.state = {
      show: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { userPermission, showUpModal } = newProps.data;
    const { categoryDojo } = this.props;
    if (userPermission) {
      let permissions = categoryDojo ? userPermission.category_permissions : userPermission.event_permissions;
      let contractPermissions = userPermission.contract_permissions;
      const { permissionsStateObject, objectChanged } = this.buildState(permissions);
      if (categoryDojo) {
        permissions = userPermission.category_permissions.map((permission) => {
          if (
            permission.resource_type === "CategoryDojo::Portfolio" ||
            permission.resource_type === "CategoryDojo::Category"
          ) {
            permission.resource_type = replace(permission.resource_type, "CategoryDojo::", "");
          }
          return permission;
        });

        this.setState({
          show: showUpModal,
          userPermission: userPermission,
          permissionsState: permissionsStateObject,
          initialState: cloneDeep(permissionsStateObject),
          objectChanged: objectChanged,
          superhostValue: 0,
        });
      } else {
        const { permissionsStateObjectContract, objectChangedContract } =
          this.buildStateForContract(contractPermissions);
        this.setState({
          show: showUpModal,
          userPermission: userPermission,
          permissionsState: permissionsStateObject,
          initialState: cloneDeep(permissionsStateObject),
          objectChanged: objectChanged,
          permissionsStateContract: permissionsStateObjectContract,
          initialStateContract: cloneDeep(permissionsStateObjectContract),
          objectChangedContract: objectChangedContract,
          superhostValue: 0,
        });
      }
    } else if (showUpModal !== undefined) {
      this.setState({
        show: showUpModal,
      });
      if (!showUpModal) {
        $("#host-permissions-modal").modal("hide");
      }
    }
  }

  componentDidUpdate() {
    if (this.modalEnd && this.state.lastUpdatedKey === "0") {
      this.modalEnd.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        this.setState({
          lastUpdatedKey: null,
        });
      }, 500);
    }
  }

  componentDidMount() {
    $("#host-permissions-modal").on("hidden.bs.modal", () => {
      this.closeModal();
    });
  }

  render() {
    const { userPermission, objectChanged, objectChangedContract, superhostValue } = this.state;
    const {
      translations,
      companyId,
      simDojoOwnerId,
      currentUser,
      adminPermissionsList,
      simPermissionsList,
      usersForSelect,
      eventsForSelect,
      groupsForSelect,
      contractsForSelect,
      marketDojo,
      categoryDojo,
      contractDojo,
      simDojo,
      categoryForSelect,
      portfolioForSelect,
      editOwnEventPermissions,
      editOwnCategoryPermissions,
      currentUserCanEditPermissions,
    } = this.props;
    const {
      transferSuperhostUsers,
      objectPermissionAction,
      objectPermissionResourceId,
      objectPermissionOwner,
      objectPermissionResourceType,
    } = this.props.translations.infos;
    const showAdminPermission =
      userPermission &&
      userPermission.user.company_id === companyId &&
      ((userPermission.user.superhost && userPermission.user.id === currentUser.id) || !userPermission.user.superhost);

    return (
      <React.Fragment>
        <div
          id="host-permissions-modal"
          className="modal fade host-permissions-modal"
          role="dialog"
          style={{ display: "none" }}
          data-backdrop={!categoryDojo}
        >
          <div className="modal-dialog modal-lg">
            {this.state.show && (
              <div className="modal-content" id="pop-custom-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.resetHostPermission()}
                    data-dismiss="modal"
                  >
                    &times;
                  </button>
                  <h3 className="modal-title">
                    {`${translations.user_permission} - ${userPermission.user.name || userPermission.user.email}`}
                  </h3>
                </div>
                <div className="content special-tab-content bid-download">
                  <span className="tab-line" />
                  <div className="row">
                    <div className="col-md-12">
                      <div className="participant-nav-tabs block">
                        <ul className="nav nav-tabs" id="hostPermissionsTab" role="tablist">
                          {showAdminPermission && (
                            <li className="nav-item active">
                              <a
                                className="nav-link"
                                id="admin-permission-tab"
                                data-toggle="tab"
                                href="#adminPermission"
                                role="tab"
                                aria-controls="adminPermission"
                                aria-selected="true"
                              >
                                {translations.admin_permissions}
                              </a>
                            </li>
                          )}
                          {marketDojo && (
                            <li className={`nav-item ${showAdminPermission ? "" : "active"}`}>
                              <a
                                className="nav-link"
                                id="event-permission-tab"
                                data-toggle="tab"
                                href="#eventPermission"
                                role="tab"
                                aria-controls="eventPermission"
                                aria-selected="false"
                              >
                                {translations.eventPermissions}
                              </a>
                            </li>
                          )}
                          {contractDojo && (
                            <li className={`nav-item ${showAdminPermission ? "" : ""}`}>
                              <a
                                className="nav-link"
                                id="contract-permission-tab"
                                data-toggle="tab"
                                href="#contractPermission"
                                role="tab"
                                aria-controls="contractPermission"
                                aria-selected="false"
                              >
                                {translations.contractPermissions}
                              </a>
                            </li>
                          )}
                          {categoryDojo && (
                            <li className={`nav-item ${showAdminPermission ? "" : "active"}`}>
                              <a
                                className="nav-link"
                                id="category-permission-tab"
                                data-toggle="tab"
                                href="#categoriesPermission"
                                role="tab"
                                aria-controls="categoriesPermission"
                                aria-selected="false"
                              >
                                Categories Permissions
                              </a>
                            </li>
                          )}
                          {showAdminPermission && simDojo && (
                            <li className={`nav-item ${showAdminPermission ? "" : ""}`}>
                              <a
                                className="nav-link"
                                id="sim-permission-tab"
                                data-toggle="tab"
                                href="#simPermission"
                                role="tab"
                                aria-controls="simPermission"
                                aria-selected="false"
                              >
                                {translations.sim_permissions}
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-content" id="hostPermissionsTabContent">
                  {showAdminPermission && (
                    <div
                      className={`tab-pane ${categoryDojo ? "" : "fade"} active in`}
                      id="adminPermission"
                      role="tabpanel"
                      aria-labelledby="adminPermission-tab"
                    >
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-sm-12">
                            {/* Transfer Superhost */}
                            {userPermission.user.superhost && userPermission.user.id === currentUser.id && (
                              <div className="modal-content-section">
                                <div className="section-title">
                                  <h4>{translations.changeSuperhost}</h4>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-10" id="change-superhost-select-container">
                                      <Tooltip
                                        title={transferSuperhostUsers.title}
                                        body={transferSuperhostUsers.body}
                                        currentUser={currentUser}
                                        categoryDojo={categoryDojo}
                                      />
                                      <label>{translations.popUserEmail}</label>
                                      <Select2
                                        data={this.usersForSuperhost()}
                                        onSelect={(e) => this.saveChangedSuperhost(e)}
                                        value={superhostValue}
                                      />
                                    </div>
                                    <div className="col-md-2" id="transfer-button-container">
                                      <label className="display-block hidden-xs">&nbsp;</label>
                                      <button
                                        className="btn btn-default"
                                        id="transfer-button"
                                        type="button"
                                        data-toggle="modal"
                                        title={translations.transfer}
                                        data-target="#transferSuperhostConfirmModal"
                                        onClick={() => this.props.showAlert(true, superhostValue)}
                                        disabled={superhostValue === 0}
                                      >
                                        <i className="fa fa-exchange blue-color" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* Admin Permissions Selects */}
                            {!userPermission.user.superhost && (
                              <PermissionsLayout
                                name="admin_permissions_html"
                                permissionsList={adminPermissionsList}
                                categoryDojo={categoryDojo}
                                actionSelect={this.actionSelect.bind(this)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="block-content text-left">
                          {userPermission.user.superhost &&
                          userPermission.user.company_id === companyId &&
                          userPermission.user.id === currentUser.id ? (
                            <div className="non_licensed_user_rule_limit">{`* ${translations.transferSuperhost}`}</div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="block-content pull-right">
                          <button
                            className={`btn btn-primary ${categoryDojo ? "btn-default" : ""}`}
                            data-dismiss="modal"
                            onClick={() => this.closeModal("ok")}
                          >
                            {translations.ok}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {showAdminPermission && simDojo && (
                    <SimPermissionsTab
                      translations={translations}
                      simPermissionsList={simPermissionsList}
                      companyId={companyId}
                      currentUser={currentUser}
                      categoryDojo={categoryDojo}
                      simDojoOwnerId={simDojoOwnerId}
                      actionSelect={(ap) => this.actionSelect(ap)}
                      simDojo={simDojo}
                      permissionAction={objectPermissionAction}
                      permissionOwner={objectPermissionOwner}
                      groupsForSelect={groupsForSelect}
                      userPermission={userPermission}
                      alertForParticipantPermission={(userPermissionId) =>
                        this.alertForParticipantPermission(userPermissionId)
                      }
                      resetHostPermission={() => this.resetHostPermission()}
                      currentUserCanEditPermissions={currentUserCanEditPermissions}
                    />
                  )}
                  {marketDojo && (
                    <EventsPermissionTab
                      showAdminPermission={showAdminPermission}
                      categoryDojo={categoryDojo}
                      translations={translations}
                      permissionsState={this.state.permissionsState}
                      permissionAction={objectPermissionAction}
                      permissionResourceType={objectPermissionResourceType}
                      permissionResourceId={objectPermissionResourceId}
                      groupsForSelect={groupsForSelect}
                      contractsForSelect={contractsForSelect}
                      objectChanged={objectChanged}
                      userPermission={userPermission}
                      resourceSelectOptions={() => this.resourceSelectOptions("events")}
                      usersForSelect={usersForSelect(editOwnEventPermissions)}
                      companyId={companyId}
                      closeModal={(flag) => this.closeModal(flag)}
                      optionsForSelect={(e, key, type) => this.optionsForSelect(e, key, type)}
                      savePermission={(key) => this.saveEventPermission(key)}
                      showAlert={(flag, userPermissionId, key) => this.props.showAlert(flag, userPermissionId, key)}
                      eventsForSelect={eventsForSelect}
                      currentUser={currentUser}
                      currentUserCanEditPermissions={currentUserCanEditPermissions}
                      editOwnEventPermissions={editOwnEventPermissions}
                    />
                  )}
                  {categoryDojo && (
                    <CategoriesPermissionTab
                      showAdminPermission={showAdminPermission}
                      categoryDojo={categoryDojo}
                      translations={translations}
                      permissionsState={this.state.permissionsState}
                      permissionAction={objectPermissionAction}
                      permissionResourceType={objectPermissionResourceType}
                      permissionResourceId={objectPermissionResourceId}
                      groupsForSelect={groupsForSelect}
                      objectChanged={objectChanged}
                      userPermission={userPermission}
                      resourceSelectOptions={() => this.resourceSelectOptions()}
                      usersForSelect={usersForSelect(editOwnCategoryPermissions)}
                      companyId={companyId}
                      closeModal={(flag) => this.closeModal(flag)}
                      optionsForSelect={(e, key, type) => this.optionsForSelect(e, key, type)}
                      portfolioForSelect={portfolioForSelect}
                      categoryForSelect={categoryForSelect}
                      savePermission={(key) => this.saveEventPermission(key)}
                      showAlert={(flag, userPermissionId, key) => this.props.showAlert(flag, userPermissionId, key)}
                      currentUser={currentUser}
                      currentUserCanEditPermissions={currentUserCanEditPermissions}
                      editOwnCategoryPermissions={editOwnCategoryPermissions}
                    />
                  )}
                  {contractDojo && (
                    <ContractsPermissionTab
                      showAdminPermission={showAdminPermission}
                      contractDojo={contractDojo}
                      translations={translations}
                      permissionsState={this.state.permissionsStateContract}
                      permissionAction={objectPermissionAction}
                      permissionResourceType={objectPermissionResourceType}
                      permissionResourceId={objectPermissionResourceId}
                      groupsForSelect={groupsForSelect}
                      contractsForSelect={contractsForSelect}
                      objectChanged={objectChangedContract}
                      userPermission={userPermission}
                      resourceSelectOptions={() => this.resourceSelectOptions("contracts")}
                      usersForSelect={usersForSelect(editOwnEventPermissions)}
                      companyId={companyId}
                      closeModal={(flag) => this.closeModal(flag, "contracts")}
                      optionsForSelect={(e, key, type) => this.optionsForSelectContract(e, key, type)}
                      savePermission={(key) => this.saveContractPermission(key)}
                      showAlert={(flag, userPermissionId, key) => this.props.showAlert(flag, userPermissionId, key)}
                      currentUser={currentUser}
                      currentUserCanEditPermissions={currentUserCanEditPermissions}
                      editOwnEventPermissions={editOwnEventPermissions}
                    />
                  )}
                  <div
                    className="scroll-dummy-div"
                    ref={(el) => {
                      this.modalEnd = el;
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Confirm
          translations={translations}
          message={translations.areYouSure}
          htmlId="eventPermissionConfirmModal"
          onOkayButtonClick={this.props.handleDeleteEventPermission}
          categoryDojo={categoryDojo}
        />
        <Confirm
          translations={translations}
          message={translations.areYouSure}
          htmlId="contractPermissionConfirmModal"
          onOkayButtonClick={this.props.handleDeleteContractPermission}
        />
        <Confirm
          translations={translations}
          message={translations.areYouSure}
          htmlId="transferSuperhostConfirmModal"
          onOkayButtonClick={this.saveSuperhost}
          categoryDojo={categoryDojo}
        />
        <Confirm
          translations={translations}
          message={translations.areYouSure}
          htmlId="participantPermissionConfirmModal"
          onOkayButtonClick={() => this.props.deleteParticipantPermission(this.state.participantPermissionId)}
        />
      </React.Fragment>
    );
  }

  resetHostPermission = () => {
    let simPermissionElement = document.getElementById("simPermission");
    let tabActive = simPermissionElement ? simPermissionElement.classList.contains("active") : "";
    if (tabActive) {
      const { getCompanyUserPermissions, companyId } = this.props;
      getCompanyUserPermissions(companyId, false);
      this.closeModal();
    }
  };

  alertForParticipantPermission = (participantPermissionId) => {
    this.setState({ participantPermissionId });
  };

  async closeModal(flag, type) {
    const userSelected = this.state.selectedEmailIndex;
    const { companyId, categoryDojo } = this.props;
    if (userSelected !== undefined && flag) {
      if (type === "contracts") {
        await this.saveContractPermission(this.state.selectedEmailIndex);
        this.props.getCompanyUserPermissions(companyId, categoryDojo);
      } else {
        await this.saveEventPermission(this.state.selectedEmailIndex);
        this.props.getCompanyUserPermissions(companyId, categoryDojo);
      }
    }
    this.setState({
      selectedEmailIndex: undefined,
    });
    this.props.closeUserPermissionModal();
  }

  async handleSelect(adminPermission, section, e) {
    const objectId = adminPermission ? adminPermission.id : null;
    const action = e.target.value;
    e.target.disabled = true;

    const loader_ref = this[`loader_${section}`];
    const tick_ref = this[`tick_${section}`];
    let methodToCall = "";
    let payload = {};
    loader_ref.classList.remove("hidden");
    if (objectId && action === "none") {
      methodToCall = "destroy";
      payload = {
        admin_permission: { adminPermissionId: objectId },
      };
    } else if (objectId && (action === "view" || action === "edit")) {
      methodToCall = "update";
      payload = {
        admin_permission: { action, adminPermissionId: objectId },
      };
    } else if (objectId === null) {
      methodToCall = "create";
      payload = {
        admin_permission: {
          action,
          section,
          user_permission_id: this.state.userPermission.id,
        },
      };
    }
    await this.props.updateAdminPermissionComponent(methodToCall, payload, adminPermission);
    loader_ref.classList.add("hidden");
    tick_ref.classList.remove("loader-hide");
    setTimeout(() => {
      tick_ref.classList.add("loader-hide");
    }, 300);
    e.target.disabled = false;
  }

  actionSelect(adminPermissionName) {
    const { userPermission } = this.state;
    const userAPObject = userPermission.admin_permissions.filter((ap) => {
      return ap.section === adminPermissionName;
    })[0];
    const { none, view, edit } = this.props.translations;
    const { simDojoOwnerId, companyId, currentUserCanEditPermissions } = this.props;
    let userAction = "none";
    if (userAPObject) {
      userAction = userAPObject.action;
    }
    let disabled = userPermission.user.superhost;
    disabled = disabled ? disabled : userPermission.user.company_id !== companyId;
    if (adminPermissionName === "supplier_onboarding" && simDojoOwnerId === userPermission.user.id) {
      disabled = true;
    }
    if (!currentUserCanEditPermissions) {
      disabled = true;
    }
    let options = [{ text: none, id: "none" }];
    if (!this.props.permissionsToSkip.view_action.includes(adminPermissionName)) {
      options.push({ text: view, id: "view" });
    }
    if (!this.props.permissionsToSkip.edit_action.includes(adminPermissionName)) {
      options.push({ text: edit, id: "edit" });
    }
    const selectClassName = adminPermissionName.replace("_", "-");
    return (
      <React.Fragment>
        <div className={`permission-select-container ${selectClassName}-select-container`}>
          <Select2
            className="form-control permission-action-select"
            value={userAction}
            disabled={disabled}
            onSelect={(e) => (disabled ? "" : this.handleSelect(userAPObject, adminPermissionName, e))}
            data={options}
            options={{ minimumResultsForSearch: -1 }}
          />
        </div>
        <span className="hidden up-loader" ref={(ref) => (this[`loader_${adminPermissionName}`] = ref)}>
          <i className="fa fa-spinner fa-white fa-spin" />
        </span>
        <span className="loader-hide up-loader" ref={(ref) => (this[`tick_${adminPermissionName}`] = ref)}>
          <i alt="Yes" className="fa fa-check text-success" />
        </span>
      </React.Fragment>
    );
  }

  optionsForSelect(e, key, type) {
    const chosenValue = e.target.value;
    let { permissionsState: epStateObj, objectChanged } = this.state;
    const { initialState } = this.state;
    if (type === "permission") {
      epStateObj[key]["permissionSelectedOption"] = chosenValue;
    } else if (type === "resource") {
      epStateObj[key]["resourceTypeSelectedOption"] = chosenValue;
      if (
        epStateObj[key]["resourceTypeSelectedOption"] === initialState[key]["resourceTypeSelectedOption"] &&
        epStateObj[key]["permissionSelectedOption"] === initialState[key]["permissionSelectedOption"]
      ) {
        epStateObj[key]["resourceIdSelectedOption"] = initialState[key]["resourceIdSelectedOption"];
      } else {
        epStateObj[key]["resourceIdSelectedOption"] = 0;
      }
    } else if (type === "resourceId") {
      epStateObj[key]["resourceIdSelectedOption"] = chosenValue;
    }
    if (key !== "0" && !isEqual(epStateObj[key], initialState[key])) {
      objectChanged[key] = true;
    } else {
      objectChanged[key] = false;
    }
    this.setState({
      permissionsState: epStateObj,
      objectChanged: objectChanged,
      selectedEmailIndex: key,
    });
    if (key !== "0") this.saveEventPermission(key);
  }

  optionsForSelectContract(e, key, type) {
    const chosenValue = e.target.value;
    let { permissionsStateContract: epStateObj, objectChangedContract } = this.state;
    const { initialStateContract } = this.state;
    if (type === "permission") {
      epStateObj[key]["permissionSelectedOptionContract"] = chosenValue;
    } else if (type === "resource") {
      epStateObj[key]["resourceTypeSelectedOptionContract"] = chosenValue;
      if (
        epStateObj[key]["resourceTypeSelectedOptionContract"] ===
          initialStateContract[key]["resourceTypeSelectedOptionContract"] &&
        epStateObj[key]["permissionSelectedOptionContract"] ===
          initialStateContract[key]["permissionSelectedOptionContract"]
      ) {
        epStateObj[key]["resourceIdSelectedOptionContract"] =
          initialStateContract[key]["resourceIdSelectedOptionContract"];
      } else {
        epStateObj[key]["resourceIdSelectedOptionContract"] = 0;
      }
    } else if (type === "resourceId") {
      epStateObj[key]["resourceIdSelectedOptionContract"] = chosenValue;
    }
    if (key !== "0" && !isEqual(epStateObj[key], initialStateContract[key])) {
      objectChangedContract[key] = true;
    } else {
      objectChangedContract[key] = false;
    }
    this.setState({
      permissionsStateContract: epStateObj,
      objectChangedContract: objectChangedContract,
      selectedEmailIndex: key,
    });
    if (key !== "0") this.saveContractPermission(key);
  }

  async saveEventPermission(key) {
    const {
      permissionSelectedOption: permission,
      resourceTypeSelectedOption: resourceType,
      resourceIdSelectedOption: resourceId,
    } = this.state.permissionsState[key];
    if (resourceId > 0) {
      await this.props.saveEventPermission(key, permission, resourceType, resourceId, this.state.userPermission.id);
    }
    let { objectChanged } = this.state;
    objectChanged[key] = false;
    this.setState({
      lastUpdatedKey: key,
    });
  }

  async saveContractPermission(key) {
    const {
      permissionSelectedOptionContract: permission,
      resourceTypeSelectedOptionContract: resourceType,
      resourceIdSelectedOptionContract: resourceId,
    } = this.state.permissionsStateContract[key];
    if (resourceId > 0) {
      await this.props.saveContractPermission(key, permission, resourceType, resourceId, this.state.userPermission.id);
    }
    let { objectChangedContract } = this.state;
    objectChangedContract[key] = false;
    this.setState({
      lastUpdatedKey: key,
    });
  }

  buildState(eventPermissions) {
    let eps = {
      0: {
        resourceTypeSelectedOption: "User",
        permissionSelectedOption: "view",
        resourceIdSelectedOption: 0,
      },
    };
    let objectChanged = {};
    const { objectChanged: oldObjectChanged, permissionsState: oldPermissionsState } = this.state;
    eventPermissions.map((ep) => {
      const key = ep.id;
      if (oldObjectChanged && oldObjectChanged[key]) {
        eps[key] = oldPermissionsState[key];
        objectChanged[key] = true;
      } else {
        eps[key] = {
          resourceTypeSelectedOption: ep.resource_type,
          permissionSelectedOption: ep.action,
          resourceIdSelectedOption: ep.resource_id,
        };
        objectChanged[key] = false;
      }
    });
    return { permissionsStateObject: eps, objectChanged: objectChanged };
  }

  buildStateForContract(contractPermissions) {
    let cps = {
      0: {
        resourceTypeSelectedOptionContract: "User",
        permissionSelectedOptionContract: "view",
        resourceIdSelectedOptionContract: 0,
      },
    };
    let objectChangedContract = {};
    const { objectChangedContract: oldObjectChangedContract, permissionsStateContract: oldPermissionsStateContract } =
      this.state;
    contractPermissions.map((cp) => {
      const key = cp.id;
      if (oldObjectChangedContract && oldObjectChangedContract[key]) {
        cps[key] = oldPermissionsStateContract[key];
        objectChangedContract[key] = true;
      } else {
        cps[key] = {
          resourceTypeSelectedOptionContract: cp.resource_type,
          permissionSelectedOptionContract: cp.action,
          resourceIdSelectedOptionContract: cp.resource_id,
        };
        objectChangedContract[key] = false;
      }
    });
    return {
      permissionsStateObjectContract: cps,
      objectChangedContract: objectChangedContract,
    };
  }

  usersForSuperhost() {
    const { userPermissions, companyId, allUsers, currentUser } = this.props;
    if (!isEmpty(userPermissions)) {
      const users = allUsers.filter((user) => {
        return user.company_id === companyId && user.registered && user.id !== currentUser.id;
      });
      return users.map((user) => {
        return { text: user.email, id: user.id };
      });
    }
  }

  saveChangedSuperhost(e) {
    const selectedValue = e.target.value;
    this.setState({
      superhostValue: selectedValue,
    });
  }

  saveSuperhost() {
    const newUserId = this.state.superhostValue;
    const { companyId, currentUser, userPermissions, translations } = this.props;
    this.props.saveNewSuperhost(
      companyId,
      newUserId,
      userPermissions,
      currentUser.id,
      translations.superhostTransferSuccessMessage
    );
    this.closeModal();
  }

  resourceSelectOptions(permissionTab) {
    const { editOwnEventPermissions, translations, adminPermissionsList, categoryDojo, editOwnCategoryPermissions } =
      this.props;
    let text = "";
    let resourceList = [];

    if (permissionTab === "contracts") {
      resourceList = ["User", "Group", "Contract"];
      text = translations.contractOwner;
    } else if (categoryDojo) {
      resourceList = ["User", "Group", "Portfolio", "Category"];
      text = "Owner";
    } else {
      resourceList = ["User", "Group", "Event"];
      text = translations.eventOwner;
    }

    const options = resourceList.map((res) => {
      if ((editOwnEventPermissions || editOwnCategoryPermissions) && res === "Group") {
        return null;
      } else if (res == "Contract" && !adminPermissionsList.includes("contracts")) {
        return null;
      } else {
        if (res === "Group") {
          text = translations.group;
        } else if (res === "Event") {
          text = translations.user_permissions.event;
        } else if (res === "Contract") {
          text = translations.contracts;
        } else if (res === "Portfolio") {
          text = res;
        } else if (res === "Category") {
          text = res;
        }
        return {
          id: res,
          text: text,
        };
      }
    });
    return options.filter((op) => op !== null);
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.adminReducers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showAlert: (show, userPermissionId, eventPermissionId) =>
    dispatch(showAlert(show, userPermissionId, eventPermissionId)),
  saveNewSuperhost: (companyId, newUserId, userPermissions, currentUserId, successMessage) =>
    dispatch(saveNewSuperhost(companyId, newUserId, userPermissions, currentUserId, successMessage)),
  closeUserPermissionModal: () => dispatch(closeUserPermissionModal()),
  deleteParticipantPermission: (participantPermissionId) =>
    dispatch(deleteParticipantPermission(participantPermissionId)),
  getCompanyUserPermissions: (companyId, categoryDojo) => dispatch(getCompanyUserPermissions(companyId, categoryDojo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissionEditModal);
