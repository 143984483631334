import { useFormContext } from "@adeattwood/react-form";

export const useRemoveAttributeErrors = () => {
  const { errors, setErrors } = useFormContext();

  return (attribute) => {
    const newErrors = { ...errors };
    Object.keys(newErrors).forEach((key) => {
      if (key.startsWith(attribute)) {
        delete newErrors[key];
      }
    });

    setErrors(newErrors);
  };
};

// A quick hack to remove an item from a attribute list and the errors
// associated with it. This is to fix an issues with ReactForm that will be
// pushed upstream soon.
export const useRemoveItem = (attribute, remove) => {
  const removeErrors = useRemoveAttributeErrors();

  return (itemIndex) => {
    remove(itemIndex);
    removeErrors(`${attribute}.${itemIndex}`);
  };
};

export default useRemoveItem;
