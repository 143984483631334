/* eslint-disable react/prop-types */
import React from "react";
import { t } from "@/i18n";
import type { Contract } from "@/common-prop-types";
import PriceValue from "@/components/common/price-value";
import type { CustomColumnValues } from "@/slices/contracts/listing/types";
import { ColumnType } from "@/slices/contracts/listing/types";
import {
  allSelectionToggled,
  contractsSearchOrFiltered,
  filtersReset,
  selectionToggled,
  selectListingSort,
  selectSelectedById,
  selectSelectionState,
  sortToggled,
} from "@/slices/contracts/listing";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import type { FilterType } from "./filter-input";
import { FilterInput } from "./filter-input";
import { compose } from "@reduxjs/toolkit";
import { format, parseISO } from "date-fns/fp";
import type { CustomFieldType } from "@/common-prop-types/custom-field";
import type { OneOf } from "@/types/util";
import { assert } from "@/utils/assert";
import { useIndeterminate } from "@/hooks/use-indeterminate";
import styles from "./column-definitions.module.scss";
import { clsx } from "clsx";

/**
 * The structure of a table column. This will be used to render each cell in a
 * row for a given type.
 */
export interface TableColumn<T, ExtraProps = unknown> {
  /**
   * The render function for the header. This will need to render the `th`
   * element so we give each column the most flexibility.
   */
  HeaderComponent: () => React.ReactNode;
  /**
   * Render the cell for a given row. This will need to render the `td` element
   * to give maximum flexibility. Because this is a component you will be able
   * to access any state and context you need via hooks.
   */
  CellComponent: (props: { row: T } & ExtraProps) => React.ReactNode;
}

export interface TableColumnWithKey<T, ExtraProps = unknown> extends TableColumn<T, ExtraProps> {
  /**
   * The key for the column. This will be used to identify the in loops for
   * react. This will need to be unique so when we a are sorting / removing
   * dynamically everything works correctly.
   */
  key: string;
}

/**
 * The common header props for a column
 */
type HeaderProps = (
  | {
      attribute: keyof Contract;
      isCustom?: false;
    }
  | {
      attribute: string;
      isCustom: true;
    }
) & {
  classNames: string;
  /**
   * The optional translation key for the header. This will default to the
   * attribute if its not provided.
   */
  translation?: string;
  /**
   * If this column should be sortable
   */
  sortable?: boolean;
  /**
   * The data type of the column filter. If this is undefined then the column
   * will not be filterable and, no inputs will be shown
   */
  filterType?: FilterType;
};

export interface ContractCellProps {
  customValues: CustomColumnValues[string];
}

/**
 * Render a table header with all the default data on the element.
 */
function baseHeader({ attribute, translation, classNames, sortable, filterType, isCustom }: HeaderProps) {
  return function TableHeader() {
    const dispatch = useAppDispatch();
    const direction = useAppSelector((state) => {
      if (!sortable) return undefined;
      const sort = selectListingSort(state);
      return sort?.column === attribute && sort.isCustom === isCustom ? sort.direction : undefined;
    });
    return (
      <th id={attribute} className={`${attribute}-col ${classNames}`}>
        <label
          htmlFor={`select_${attribute}`}
          {...(sortable && {
            className: clsx("sortable", direction),
            onClick() {
              dispatch(sortToggled(attribute, isCustom));
            },
          })}
        >
          {t(translation ?? attribute)}
        </label>
        {filterType && <FilterInput {...{ filterType, attribute, isCustom }} />}
      </th>
    );
  };
}

/**
 * Helper function to abstract the creation of a sortable header. This, most of
 * the code is the same for each of them. This allows us to add any extra
 * classes or change the translation key when needed.
 */
function sortableHeader(props: HeaderProps) {
  return baseHeader({ ...props, sortable: true });
}

type CellProps = React.TdHTMLAttributes<HTMLTableCellElement> & {
  "data-testid"?: string;
} & OneOf<
    | {
        attribute: keyof Contract;
        format?: keyof typeof FORMATTERS;
      }
    | {
        column: CustomFieldType;
      }
  >;

const FORMATTERS = {
  bool: (value) => {
    if (typeof value === "boolean") return value ? "Yes" : "No";
    return "-";
  },
  text: (value) => value as string,
  date: (value) => {
    if (value) {
      try {
        return formatDate(value as string);
      } catch {
        return "Invalid date";
      }
    }
    return "-";
  },
  price: (value, row) => <PriceValue value={(value as number) || 0} currencySymbol={row.currency_symbol || ""} />,
} satisfies Record<string, (value: unknown, row: Contract) => React.ReactNode>;

function getCustomValue(column: CustomFieldType, customValues: CustomColumnValues[string]): unknown {
  const value = customValues[column.name!];
  switch (column.column_type_cd) {
    case ColumnType.MultiPick: {
      if (Array.isArray(value)) {
        return value.join(", ");
      } else if (typeof value === "string") {
        try {
          const parsed = JSON.parse(value);
          if (Array.isArray(parsed)) return parsed.join(", ");
          // eslint-disable-next-line no-empty
        } catch {}
      }
      break;
    }
  }
  if (Array.isArray(value) && value.length === 1) {
    return value[0];
  }
  return value;
}

function getCustomColumnFormatter(column: CustomFieldType): keyof typeof FORMATTERS {
  switch (column.column_type_cd) {
    case ColumnType.Checkbox:
      return "bool";
    case ColumnType.Date:
      return "date";
    default:
      return "text";
  }
}

function baseCell({
  attribute,
  column,
  format = column ? getCustomColumnFormatter(column) : "text",
  ...rest
}: CellProps) {
  return function TableCell({ row, customValues }: { row: Contract } & ContractCellProps) {
    const value = column ? getCustomValue(column, customValues) : row[attribute];
    return (
      <td key={attribute} {...rest}>
        {FORMATTERS[format](value, row)}
      </td>
    );
  };
}

const formatDate = compose(format("dd MMM yyyy"), parseISO);

export const getCustomColumnDefinition = (column: CustomFieldType): TableColumn<Contract, ContractCellProps> => {
  assert(column.name, "Custom column definition must have a name");
  return {
    HeaderComponent: baseHeader({ attribute: column.name, classNames: "w-8", isCustom: true }),
    CellComponent: baseCell({ column, "data-testid": column.name }),
  };
};

export const columnDefinitions: Record<string, TableColumn<Contract, ContractCellProps>> = {
  firstColumn: {
    HeaderComponent: function TableHeader() {
      return <th style={{ width: "13px" }} />;
    },
    CellComponent: function TableCell() {
      return <td />;
    },
  },
  checkbox: {
    HeaderComponent: function HeaderCheckbox() {
      const dispatch = useAppDispatch();
      const selectionState = useAppSelector(selectSelectionState);
      const checkboxRef = useIndeterminate(selectionState === "some");
      return (
        <th className="change_owner_box" id="change_owner_header" style={{ width: "33px" }}>
          <input
            type="checkbox"
            className={styles.checkbox}
            ref={checkboxRef}
            name="select-all"
            onChange={() => {
              dispatch(allSelectionToggled());
            }}
            checked={selectionState === "all"}
            title={selectionState === "all" ? "Deselect all" : "Select all"}
          />
        </th>
      );
    },
    CellComponent: function TableCell({ row }) {
      const dispatch = useAppDispatch();
      const selected = useAppSelector((state) => selectSelectedById(state, row.id));
      return (
        <td className="change_owner_box">
          <input
            type="checkbox"
            className={styles.checkbox}
            name={`select_${row.id}`}
            checked={selected}
            onChange={() => {
              dispatch(selectionToggled(row.id));
            }}
            title={`${selected ? "Deselect" : "Select"} ${row.title || ""}`}
          />
        </td>
      );
    },
  },
  owner: {
    HeaderComponent: sortableHeader({ attribute: "owner", classNames: "w-8", filterType: "text" }),
    CellComponent: baseCell({ attribute: "owner" }),
  },
  title: {
    HeaderComponent: baseHeader({
      attribute: "title",
      translation: "contract_title",
      classNames: "w-10",
      filterType: "text",
    }),
    CellComponent: baseCell({ attribute: "title" }),
  },
  parent: {
    HeaderComponent: baseHeader({ attribute: "parent", classNames: "w-8" }),
    CellComponent: baseCell({ attribute: "parent" }),
  },
  category: {
    HeaderComponent: sortableHeader({ attribute: "category", classNames: "w-11", filterType: "text" }),
    CellComponent: baseCell({ attribute: "category" }),
  },
  contact_company_name: {
    HeaderComponent: sortableHeader({ attribute: "contact_company_name", classNames: "w-8", filterType: "text" }),
    CellComponent: baseCell({ attribute: "contact_company_name" }),
  },
  supplier: {
    HeaderComponent: baseHeader({ attribute: "supplier", classNames: "w-10", filterType: "text" }),
    CellComponent: baseCell({ attribute: "supplier_user_email", className: "non-participants" }),
  },
  reference: {
    HeaderComponent: baseHeader({
      attribute: "contract_number",
      translation: "contract_reference",
      classNames: "w-8",
      filterType: "text",
    }),
    CellComponent: baseCell({ attribute: "contract_number" }),
  },
  auto_renew: {
    HeaderComponent: baseHeader({ attribute: "auto_renew", classNames: "w-8" }),
    CellComponent: baseCell({ attribute: "auto_renew", format: "bool", "data-testid": "auto_renew_cell" }),
  },
  start_date: {
    HeaderComponent: baseHeader({ attribute: "start_date", classNames: "w-11", filterType: "date" }),
    CellComponent: baseCell({ attribute: "start_date", format: "date", "data-testid": "start_date_cell" }),
  },
  expiry_date: {
    HeaderComponent: baseHeader({ attribute: "expiry_date", classNames: "w-11", filterType: "date" }),
    CellComponent: baseCell({ attribute: "expiry_date", format: "date" }),
  },
  annual_value: {
    HeaderComponent: baseHeader({ attribute: "annual_value", classNames: "w-11", filterType: "number" }),
    CellComponent: baseCell({ attribute: "annual_value" }),
  },
  total_value: {
    HeaderComponent: sortableHeader({ attribute: "total_value", classNames: "w-11", filterType: "number" }),
    CellComponent: baseCell({ attribute: "total_value", format: "price" }),
  },
  stakeholders: {
    HeaderComponent: baseHeader({
      attribute: "stakeholder_user_email",
      translation: "stakeholders",
      classNames: "w-8",
      filterType: "text",
    }),
    CellComponent: baseCell({ attribute: "stakeholder_user_email" }),
  },
  current_state: {
    HeaderComponent: sortableHeader({ attribute: "current_state", classNames: "w-10 col-two", filterType: "text" }),
    CellComponent: baseCell({ attribute: "current_state" }),
  },
  notice_period: {
    HeaderComponent: baseHeader({ attribute: "notice_period", classNames: "w-8", filterType: "date" }),
    CellComponent: baseCell({ attribute: "notice_period" }),
  },
  actions: {
    HeaderComponent: function ContractActions() {
      const dispatch = useAppDispatch();

      return (
        <th className="contract-filter-btn w-8" style={{ textAlign: "right", right: 0 }}>
          <button
            className="buttons-orange button-auto btn-orange"
            title="Search"
            onClick={() => void dispatch(contractsSearchOrFiltered())}
          >
            <i className="fa fa-search"></i>
          </button>
          <button
            className="buttons-orange button-auto btn-orange"
            title="Reset Filter"
            onClick={() => dispatch(filtersReset())}
          >
            <i className="fa fa-refresh"></i>
          </button>
        </th>
      );
    },
    CellComponent: function ContractActions() {
      return <td />;
    },
  },
};
