import React from "react";
import { useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { ModalHeaderPropTypes, TableHeaderPropTypes } from "./prop-types";

/**
 *
 * @type {React.FC<PropTypes.InferProps<ModalHeaderPropTypes>>}
 * @returns {React.ReactElement}
 */
export const ModalHeader = ({ eventName }) => {
  const event = useSelector(({ lotReducers }) => lotReducers.translations.event);
  return (
    <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal">
        ×
      </button>
      <h4 className="modal-title">
        <b>{event}:</b> {eventName}
      </h4>
    </div>
  );
};

ModalHeader.propTypes = ModalHeaderPropTypes;
ModalHeader.defaultProps = {
  eventName: "",
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<TableHeaderPropTypes>>}
 * @returns {React.ReactElement}
 */
export const TableHeader = ({ isHostTab }) => {
  const { name, companyName, email, role, userStatus } = useSelector(({ lotReducers }) => lotReducers.translations);

  let nameText = companyName,
    roleText = userStatus,
    emailText;

  if (isHostTab) {
    nameText = name;
    emailText = email;
    roleText = role;
  }

  return (
    <thead>
      <tr className="select_all">
        <th />
        <th>{nameText}</th>
        <th>{emailText}</th>
        <th>{roleText}</th>
      </tr>
    </thead>
  );
};

TableHeader.propTypes = TableHeaderPropTypes;
TableHeader.defaultProps = {};
