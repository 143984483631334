import actionTypes from '../../actions/qq/actionsTypes';

export const adminTextReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.qq_header_block:
      return {
        ...state,
        FETCH_ADMIN_TEXT_HEADER: action.payload,
      };
    case actionTypes.qq_attributes_block:
      return {
        ...state,
        FETCH_ADMIN_TEXT_ATTRIBUTES: action.payload,
      };
    case actionTypes.qq_documents_block:
      return {
        ...state,
        FETCH_ADMIN_TEXT_DOCUMENTS: action.payload,
      };
    case actionTypes.qq_prices_block:
      return {
        ...state,
        FETCH_ADMIN_TEXT_PRICES: action.payload,
      };
    case actionTypes.qq_questions_block:
      return {
        ...state,
        FETCH_ADMIN_TEXT_QUESTIONS: action.payload,
      };
    case actionTypes.qq_participants_block:
      return {
        ...state,
        FETCH_ADMIN_TEXT_PARTICIPANTS: action.payload,
      };
    default:
      return state;
  }
};

export const deleteAdminTextReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.qq_header_block_delete:
      return {
        ...state,
        FETCH_ADMIN_TEXT_HEADER_DEL: action.payload,
      };
    case actionTypes.qq_attributes_block_delete:
      return {
        ...state,
        FETCH_ADMIN_TEXT_ATTRIBUTES_DEL: action.payload,
      };
    case actionTypes.qq_documents_block_delete:
      return {
        ...state,
        FETCH_ADMIN_TEXT_DOCUMENTS_DEL: action.payload,
      };
    case actionTypes.qq_prices_block_delete:
      return {
        ...state,
        FETCH_ADMIN_TEXT_PRICES_DEL: action.payload,
      };
    case actionTypes.qq_questions_block_delete:
      return {
        ...state,
        FETCH_ADMIN_TEXT_QUESTIONS_DEL: action.payload,
      };
    case actionTypes.qq_participants_block_delete:
      return {
        ...state,
        FETCH_ADMIN_TEXT_PARTICIPANTS_DEL: action.payload,
      };
    default:
      return state;
  }
};
