import React, { Fragment, useEffect } from "react";
import Select2 from "react-select2-wrapper";
import Tooltip from "../../../common/Tooltip";
import PicklistButtons from "./PicklistButtons";
import { useAppSelector } from "@/hooks/redux";
import { lotAutomationEnforcedAndNotSandpit } from "../lotCommon";

const PicklistComponentModalDetail = (props) => {
  // When the component type is picklist then show this select box to choose picklist
  const {
    picklists,
    picklistOptions,
    classFromEventState,
    currentUser,
    setPicklistId,
    picklistId,
    translations,
    eventCurrency,
    setPicklistOptionValues,
    picklistOptionValues,
  } = props;
  const { lot_component_picklist } = translations.infos;
  const lAEnforcedAndNotSandpit = useAppSelector(lotAutomationEnforcedAndNotSandpit);
  const picklistsSelect = () => {
    let picklist = undefined;
    let chosenPicklistOptions = undefined;
    if (picklists !== undefined) {
      picklist = picklists.filter((p) => p.id === parseInt(picklistId))[0];
    }
    if (picklist !== undefined && picklistOptions !== undefined) {
      chosenPicklistOptions = picklistOptions.filter((po) => po.picklist_id === parseInt(picklistId));
    }
    return (
      <div id="picklist_fields" className="input wizard-step lot-dropdowns">
        <div className="form-group">
          <div className="label-title">
            <Tooltip title={lot_component_picklist.title} body={lot_component_picklist.body} />
            <label htmlFor="lot_component_picklist_id">{translations.existing_picklists}</label>
            <div className="clear" />
          </div>
          <div className="input m-b10">
            <Select2
              id="lot_component_picklist_id"
              className="form-control permission-action-select"
              data={picklistsForSelect()}
              onSelect={(e) => handlePicklistChange(e)}
              style={{ width: "100%" }}
              value={picklistId}
              options={{
                minimumResultsForSearch: -1,
                placeholder: translations.select,
              }}
              disabled={lAEnforcedAndNotSandpit}
            />
          </div>
          <PicklistButtons
            classFromEventState={classFromEventState}
            translations={translations}
            picklist={picklist}
            chosenPicklistOptions={chosenPicklistOptions}
            currentUser={currentUser}
            handlePicklistChange={handlePicklistChange}
          />
          {/* Picklist Types: [none currency numeric] */}
          {picklist !== undefined &&
            [1, 2].includes(picklist.picklist_type) &&
            chosenPicklistOptions !== undefined &&
            picklistOptionTextBoxes(translations, picklist, chosenPicklistOptions)}
        </div>
      </div>
    );
  };
  const picklistsForSelect = () => {
    return picklists.map((picklist) => {
      return { text: picklist.name, id: picklist.id };
    });
  };

  useEffect(() => {
    let picklist = undefined;
    let chosenPicklistOptions = undefined;

    if (picklists !== undefined) {
      picklist = picklists.filter((p) => p.id === parseInt(picklistId))[0];
    }
    if (picklist !== undefined && picklistOptions !== undefined) {
      chosenPicklistOptions = picklistOptions.filter((po) => po.picklist_id === parseInt(picklistId));
    }
    if (
      [1, 2].includes(picklist && picklist.picklist_type) &&
      Object.keys(picklistOptionValues).length < chosenPicklistOptions.length
    ) {
      chosenPicklistOptions.map((cpo) => {
        picklistOptionValues[cpo.id] = chosenPicklistOptions[cpo.id] || "";
      });
      setPicklistOptionValues(picklistOptionValues);
    } else if (picklist && picklist.picklist_type == 0) {
      setPicklistOptionValues({});
    }
  }, [picklistId]);

  const handlePicklistChange = (event) => {
    setPicklistId(event.target.value);
  };

  const handlePicklistOptionValueChange = (event, pOptionId) => {
    let pickOptions = { ...picklistOptionValues };
    pickOptions[pOptionId] = event.target.value;
    setPicklistOptionValues(pickOptions);
  };

  // When the component type is picklist and chosen picklist is of currency or numeric type
  // and has picklist options then display these text fields to enter value for them
  const picklistOptionTextBoxes = (translations, picklist, chosenPicklistOptions) => {
    const { picklistOptionValues } = props;
    return (
      <div id="picklist_options_fields">
        <div className="row m-b10 m-t10">
          <div className="col-md-6">{translations.picklist_option}</div>
          <div className="col-md-6" />
        </div>
        {chosenPicklistOptions.map(function (picklistOption) {
          return (
            <Fragment key={`picklistOption-${picklistOption.id}`}>
              <div className="row">
                <div className="col-md-6">
                  <span>{picklistOption.value}</span>
                </div>
                <div className="col-md-6">
                  <div className="input-group  col-xs-12">
                    {picklist.picklist_type === 1 && <span className="input-group-addon">{eventCurrency.symbol}</span>}
                    <input
                      type="text"
                      id="lot_component_lot_component_picklist_options_attributes_0_value"
                      value={picklistOptionValues[picklistOption.id] || ""}
                      onChange={(e) => handlePicklistOptionValueChange(e, picklistOption.id)}
                      autoComplete="off"
                      className="number_precision form-control"
                      disabled={lAEnforcedAndNotSandpit}
                    />
                  </div>
                </div>
              </div>
              <br />
            </Fragment>
          );
        })}
      </div>
    );
  };
  return picklistsSelect();
};

export default PicklistComponentModalDetail;
