import { assert } from "@/utils/assert";
import { liquid } from "@codemirror/lang-liquid";
import { xml, xmlLanguage } from "@codemirror/lang-xml";
import { json, jsonLanguage } from "@codemirror/lang-json";
import { Controller } from "@hotwired/stimulus";
import { basicSetup, EditorView } from "codemirror";

export default class extends Controller {
  static values = {
    language: String,
    properties: Array,
  };

  editor = null;

  connect() {
    assert("propertiesValue" in this, "Properties value not initialized something went wrong with stimulus");
    assert(
      this.element instanceof HTMLTextAreaElement,
      "The liquid-editor controller must be used on a textarea element"
    );

    if (this.editor) {
      this.editor.destroy();
    }

    this.editor = new EditorView({
      doc: this.element.value,
      extensions: [
        basicSetup,
        EditorView.domEventHandlers({
          keyup: this.#handleChange.bind(this),
        }),
        liquid({
          base: this.getLanguage(),
          variables: this.propertiesValue,
          properties: this.getProperties.bind(this),
        }),
      ],
    });

    assert(this.element.parentNode, "The textarea must have a parent node");
    this.element.parentNode.insertBefore(this.editor.dom, this.element);
    this.element.hidden = true;
  }

  languageValueChanged() {
    this.connect();
  }

  #handleChange() {
    this.element.value = this.editor.state.doc.toString();
  }

  getLanguage() {
    assert("languageValue" in this, "Really? No language value?");
    switch (this.languageValue) {
      case "json":
        return json({ base: jsonLanguage });
      case "xml":
        return xml({ base: xmlLanguage });
      default:
        return undefined;
    }
  }

  /**
   * @param {readonly string[]} path
   */
  getProperties(path) {
    const currentPath = [...path];
    let currentProperties = [...this.propertiesValue];
    if (currentPath.length === 0) {
      return currentProperties;
    }

    while (currentPath.length > 0) {
      const currentPathPart = currentPath.shift();
      const currentProperty = currentProperties.find((item) => item.label === currentPathPart);
      if (!currentProperty) {
        return [];
      }

      currentProperties = currentProperty.children || [];
    }

    return currentProperties;
  }
}
