import React, { useState } from "react";
import { connect } from "react-redux";
import BestBidDetail from "./best_bid_modal_components/BestBidDetail";

const BestBidForModalWithLoader = (props) => {
  const { auctionTab, beforeAuction, bidList, translations } = props;
  const showTabs = auctionTab && !beforeAuction;
  const [activeTab, setActiveTab] = useState(translations.show_all_bids);

  return bidList && bidList.length > 0 ? (
    <BestBidDetail {...props} showTabs={showTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
  ) : (
    <p className="no_data">
      {translations.negative} {`${translations.bid_object}s`}
    </p>
  );
};

const mapStateToProps = (state) => {
  let newState = {};
  const lotReducers = state.lotReducers;
  const bidLineItemComponentReducers = state.bidLineItemComponentReducers;
  if (lotReducers?.bidList?.length) {
    newState = { bidList: lotReducers.bidList };
  }
  if (bidLineItemComponentReducers?.bidList?.length) {
    newState.blicBidList = bidLineItemComponentReducers.bidList;
  }
  return newState;
};

export default connect(mapStateToProps, null)(BestBidForModalWithLoader);
