import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

// this will return loader if isLoading is true else render component which
// receive as a parameter into this function.
// component should contain isLoading function otherwise loader will not work.
function WithLoading(Component) {
  const override = {
    textAlign: "center",
  };
  return function WihLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return (
      <div className="sweet-loading">
        <BeatLoader css={override} color={"red"} loading={isLoading} />
      </div>
    );
  };
}
export default WithLoading;
