import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "react-loader-advanced";

import QQListElement from "./QQListElement";
import InfoPopover from "../../InfoPopover";
import LoaderMessage from "../../LoaderMessage";

class QQListTable extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { searchedEvents, perPage, translations, tooltip_translations, company, user } = this.props;
    let startCount = 0;
    return (
      <Loader
        show={this.props.showLoader}
        message={<LoaderMessage loaderText={translations.loader_text} />}
        disableDefaultStyles
      >
        <div>
          <div className="event-list event-list-scroll">
            <table className="list-table quick-quotes-list">
              <thead className="table-block-title">
                <tr>
                  <th>
                    {translations.qq_listing.qq_list_table.status}
                    {/* <Tooltip title={tooltip_translations.host.event.status.title} body={tooltip_translations.host.event.status.body} /> */}
                    <InfoPopover
                      title={tooltip_translations.host.event.status.title}
                      popoverContent={tooltip_translations.host.event.status.body}
                    />
                  </th>
                  <th>
                    {translations.qq_listing.qq_list_table.quick_quote_name}
                    <InfoPopover
                      title={tooltip_translations.host.event.name.title}
                      popoverContent={tooltip_translations.host.event.name.body}
                    />
                  </th>
                  <th>
                    {translations.qq_listing.qq_list_table.quick_quote_owner}
                    <InfoPopover
                      title={tooltip_translations.host.event.owner.title}
                      popoverContent={tooltip_translations.host.event.owner.body}
                    />
                  </th>
                  <th>
                    {translations.qq_listing.qq_list_table.bids}
                    <InfoPopover
                      title={tooltip_translations.host.event.bids.title}
                      popoverContent={tooltip_translations.host.event.bids.body}
                    />
                  </th>
                  <th>
                    {translations.qq_listing.qq_list_table.acceptance}
                    <InfoPopover
                      title={tooltip_translations.host.event.acceptance.title}
                      popoverContent={tooltip_translations.host.event.acceptance.body}
                    />
                  </th>
                  <th>
                    {translations.qq_listing.qq_list_table.go_live_date}
                    <InfoPopover
                      title={tooltip_translations.host.event.live_date.title}
                      popoverContent={tooltip_translations.host.event.live_date.body}
                    />
                  </th>
                  <th>
                    {translations.qq_listing.qq_list_table.next_key_deadline}
                    <InfoPopover
                      title={tooltip_translations.host.event.time_until_event.title}
                      popoverContent={tooltip_translations.host.event.time_until_event.body}
                    />
                  </th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {!this.props.showLoader &&
                  searchedEvents.map((QQevent, position) => {
                    if (startCount < perPage) {
                      startCount += 1;
                      return (
                        <QQListElement
                          key={QQevent.id}
                          QQevent={QQevent}
                          searchByStatus={this.props.searchByStatus}
                          confirmation={this.props.confirmation}
                          translations={translations}
                          tooltip_translations={tooltip_translations}
                          company={company}
                          user={user}
                        />
                      );
                    }
                    return false;
                  })}
                {searchedEvents.length === 0 && !this.props.showLoader && (
                  <tr className="no_data center">
                    <td colSpan="7">{translations.qq_listing.qq_list_table.no_quick_quotes_found}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // selectedLang: state.changeLanguageReducer,
  // tooltripData: state.tooltipReducer,
});

export default connect(mapStateToProps, null)(QQListTable);
