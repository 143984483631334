/**
 * Definition of all the roles that can be used in the application
 */
export const Roles = Object.freeze({ HOST: "Host" });

/**
 * Check if role belongs to host
 *
 * @param {string} role
 * @returns {boolean}
 */
export const isHost = (role) => role === Roles.HOST;

export default { Roles, isHost };
