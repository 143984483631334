import React from "react";
import ShowNoFeedMessage from "./ShowNoFeedMessage";
import ShowUpdateMessage from "./ShowUpdateMessage";

const ShowAllUpdateMessages = (props) => {
  const {
    event,
    allMessages,
    role,
    translations,
    hasRankedLineItemComponents,
    currentUser,
    messageRecipients,
    lots,
    bids,
    participants,
    hosts,
    permittedToEdit,
    hostPermission,
    inSandpit,
    eventParticipants,
    markAsReadPayload,
    timeZoneIdentifier,
  } = props;
  let notification = false;

  const showMsgCondition = (message) => {
    return (
      role === "Host" ||
      (message.user_id == currentUser.id && !message.bid_id) ||
      messageRecipients.map((mr) => mr.user_id).includes(currentUser.id)
    );
  };
  const filteredMessages = allMessages?.filter((message) => showMsgCondition(message));

  return (
    <div className="inner-messages-block">
      <ul id="all_messages" className="m-b0">
        {filteredMessages?.map((message, key) => {
          notification = true;
          return (
            <ShowUpdateMessage
              key={key}
              message={message}
              bids={bids}
              lots={lots}
              currentUser={currentUser}
              translations={translations}
              event={event}
              role={role}
              participants={participants}
              hosts={hosts}
              messageRecipients={messageRecipients}
              permittedToEdit={permittedToEdit}
              hostPermission={hostPermission}
              inSandpit={inSandpit}
              eventParticipants={eventParticipants}
              markAsReadPayload={markAsReadPayload}
              timeZoneIdentifier={timeZoneIdentifier}
            />
          );
        })}

        {!notification ? (
          <ShowNoFeedMessage
            role={role}
            translations={translations}
            hasRankedLineItemComponents={hasRankedLineItemComponents}
          />
        ) : null}
      </ul>
    </div>
  );
};

export default ShowAllUpdateMessages;
