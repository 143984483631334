import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Loader from 'react-loader-advanced';
import cookies from 'browser-cookies';

import Overview from './Overview';
import Documents from './Documents';
import Lots from './Lots';
import Questions from './Questions';
import Participants from './Participants';
import { fetchQuoteById, fetchConfigurationLimit } from '../../../../actions/qq/qqActions';
import {
  fetchUserDetails,
  changeEventState,
  emptyCurrentState,
  fetchSandpitParticipants,
} from "@/actions/qq/genericActions";
import { notifyParticipant } from '../../../../actions/qq/editActions';
import { fetchParticipantGrpsForEvnt, fetchParticipantGroups, fetchParticipantOnboardingStatuses } from '../../../../actions/qq/companyActions';
import LoaderMessage from '../../LoaderMessage';
import { fetchAdminText } from '../../../../actions/qq/adminTextActions';
import { withAlert } from 'react-alert'
import { notifyToastMsgQQ } from '../../js/common'

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventData: {},
      disableBtn: false,
      showLoader: true,
    };
  }
  async UNSAFE_componentWillMount() {
    const quoteId = this.props.event.id;
    const eventData = {};
    
    if (quoteId) {
      const overview = await this.props.fetchOverview(quoteId);
      
      if (overview.status === 404) {
        window.location.pathname = `/quick_quotes/quote/${quoteId}/notfound`;
      }
      
      if (overview.status === 200) {
        eventData.overview = overview.data.event;
      }
      
      if (overview.status === 401) {
        eventData.overview = overview.data.event;
      }
      
      if (eventData.overview.current_state !== 'edit' || !eventData.overview.current_user_has_edit_permission) {
        window.location.pathname = `/quick_quotes/quote/${quoteId}`;
      } else {
        const { user } = this.props;
        if (!user.details) {
            user.details = await this.props.fetchUserDetails();
        }

        const companyId = user.details?.company?.id;

        this.props.fetchConfigurationLimit(companyId);
        this.props.fetchParticipantGrpsForEvnt(companyId);
        this.props.fetchParticipantOnboardingStatuses(companyId);
        this.props.fetchParticipantGroups(companyId);
        this.setState({ eventData, showLoader: false });
        const sessionId = cookies.get('auth_headers');
        if (!localStorage.getItem('QQEditSessionId') || localStorage.getItem('QQEditSessionId') !== sessionId) {
          localStorage.setItem('documentLibraryuploads', JSON.stringify({
            documents: [],
          }));
          localStorage.setItem('QQEditSessionId', sessionId);
        }
      }
    }
    if (cookies.get('sandpit') === 'true') {
      this.props.fetchSandpitParticipants();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      eventParticipantsOnboardingStatus: newProps.eventParticipantsOnboardingStatus,
      eventParticipants: newProps.eventParticipants
    });
    if (this.state.lang !== newProps.selectedLang) {
      this.updateAdminText();
      this.setState({
        lang: newProps.selectedLang,
      });
    }
    notifyToastMsgQQ(newProps, this.props.alert);
  }

  componentWillUnmount() {
    this.props.emptyCurrentState('CHANGE_EVENT_STATE', undefined);
  }

  // TODO: I think we can simply delete the next function, it's not being used
  // anywhere. I have a guess that someone left this dead code here by mistake.
  // We can do this when it comes to refactor the code.
  // There is a duplicate function defined in Participants.jsx that you can
  // check here:
  // https://github.com/marketdojo/auction/blob/9659db1d3a28b40f7aae2eb743e36cdcd86479b9/app/javascript/components/quick_quotes/pages/QQEdit/Participants.jsx#L87
  updateOnBoardingStatus = (eventParticipantsList, eventParticipantsOnboardingStatus = []) => {
    let epForSelect = [];
    if (eventParticipantsList) {
      epForSelect = eventParticipantsList.map((ep) => {
        const onboardingStatus = eventParticipantsOnboardingStatus.filter(e => e.email === ep.email && e.onboarding_status === 'rejected');
        const color = onboardingStatus.length > 0 ? 'red' : '';
        return { value: ep.email, label: ep.email, color };
      });
    }
    this.setState({eventParticipants: epForSelect})
  }

  updateEventData(key, data) {
    const { eventData } = this.state;
    eventData[key] = data;
    this.setState({ eventData });
  }

  async changeToCurrent(quoteId) {
    this.setState({ disableBtn: true });
    const response = await this.props.changeEventState(quoteId, 'finish_editing');
    this.setState({ disableBtn: false });
    if (response.status === 200) {
      if (response.data.event.total_number_of_participants) {
        this.props.notifyParticipant(quoteId, true, '');
      }
      window.location.pathname = `/quick_quotes/quote/${quoteId}`;
    }
  }

  updateAdminText() {
    const sectionKeyArray = ['qq_attributes_block', 'qq_documents_block', 'qq_prices_block', 'qq_questions_block', 'qq_participants_block'];
    if (this.props.user.details) {
      sectionKeyArray.forEach((key) => {
        this.props.fetchAdminText(this.props.user.details.company.id, key);
      });
    }
  }

  render() {
    const { eventData, disableBtn, showLoader, eventParticipants } = this.state;
    const { user, translations } = this.props;
    const quoteId = this.props.event.id;
    let userDetails;
    let companyId;
    if (user.details) {
      userDetails = user.details;
      companyId = userDetails.company.id;
    }
    return (
      <div>
        <div className="top-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12 maincontent" data-event-id={eventData?.overview?.id}>
                <Loader show={ showLoader } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles priority={ 1 }>
                  <div>
                    {eventData.overview && userDetails && (
                    <div>
                      {
                        <div className='alert alert-warning'>
                          {translations.info_message.editmode_alert}
                        </div>
                      }
                      {/* Over view section */}
                      <Overview
                        data={ eventData.overview }
                        quoteId={ quoteId }
                        companyId={ companyId }
                        sectionKey='qq_attributes_block'
                        updateEventData={ (key, data) => this.updateEventData(key, data) }
                        user={ userDetails }
                        translations={ translations }
                      />
                      {/* over view section ends */}
                      {/* documents section */}
                      <Documents
                        quoteId={ quoteId }
                        companyId={ companyId }
                        sectionKey='qq_documents_block'
                        user={ userDetails }
                        translations={ translations }
                      />
                      {/* documents section ends */}
                      {/* lots section */}
                      <Lots
                        quoteId={ quoteId }
                        companyId={ companyId }
                        sectionKey='qq_prices_block'
                        userId={ userDetails.id }
                        updateEventData={ (key, data) => this.updateEventData(key, data) }
                        translations={ translations }
                      />
                      {/* lots section ends */}
                      {/* questions section */}
                      <Questions
                        quoteId={ quoteId }
                        companyId={ companyId }
                        sectionKey='qq_questions_block'
                        translations={ translations }
                      />
                      {/* questions section ends */}
                      {/* participants section */}
                      <Participants
                        quoteId={ quoteId }
                        companyId={ companyId }
                        sectionKey='qq_participants_block'
                        eventParticipants={eventParticipants}
                        translations={ translations }
                      />
                      {/* participants section ends */}
                      <button
                        className='btn btn-primary pull-right btn-center-mobile'
                        disabled={ disableBtn }
                        onClick={ () => this.changeToCurrent(quoteId) }
                      >
                        {translations.qq_edit.go_live} {disableBtn && <i className='fa fa-cog fa-spin fa-fw' />}
                      </button>
                    </div>
                  )}
                  </div>
                </Loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.userReducer,
  eventParticipantsOnboardingStatus: state.eventParticipantsOnboardingStatusReducer,
  eventParticipants: state.eventParticipantsReducer,
  error: state.errorMessageReducer,
  success: state.successMessageReducer,

});

const mapDispatchToProps = dispatch => ({
  fetchOverview: quoteId => dispatch(fetchQuoteById(quoteId)),
  fetchUserDetails: () => dispatch(fetchUserDetails()),
  notifyParticipant: (quoteId, status, message) => dispatch(notifyParticipant(quoteId, status, message)),
  fetchParticipantOnboardingStatuses: companyId => dispatch(fetchParticipantOnboardingStatuses(companyId)),
  fetchParticipantGrpsForEvnt: (companyId) => dispatch(fetchParticipantGrpsForEvnt(companyId)),
  fetchParticipantGroups: companyId => dispatch(fetchParticipantGroups(companyId)),
  fetchConfigurationLimit: companyId => dispatch(fetchConfigurationLimit(companyId)),
  fetchAdminText: (companyId, sectionKey) => dispatch(fetchAdminText(companyId, sectionKey)),
  changeEventState: (quoteId, state) => dispatch(changeEventState(quoteId, state)),
  emptyCurrentState: (actionType, payload) => dispatch(emptyCurrentState(actionType, payload)),
  fetchSandpitParticipants: () => dispatch(fetchSandpitParticipants()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAlert()(EditForm));
