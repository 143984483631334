import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ type = "checkbox", name, checked = false, onChange, translations, readOnly }) => (
  <label
    className={`${readOnly ? "cursor-disabled" : "cursor-pointer"} ${
      checked ? "checkbox-selected" : "disabled-text"
    } checkbox-custom`}
  >
    {readOnly ? (
      <input type={type} name={name} checked="checked" readOnly="readonly" />
    ) : (
      <input type={type} name={name} checked={checked} onChange={onChange} />
    )}
    <span className="chk-box" /> <span className="chk-text">{translations[name]}</span>
  </label>
);

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
