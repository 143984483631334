import clsx from "clsx";
import type { ComponentProps, ReactElement, RefAttributes } from "react";
import React, { forwardRef } from "react";
import type { Props, GroupBase, SelectInstance } from "react-select";
import ReactSelect, { components } from "react-select";
import type { CreatableProps } from "react-select/creatable";
import CreatableSelect from "react-select/creatable";
import Label from "../../input/label";
import Feedback from "../../input/feedback";
import type { Override } from "@/types/util";

export interface EnhancedSelectProps {
  /** The name attribute of the input. */
  name: string;
  /** The label given to the input */
  label?: string;
  /** The error message that will be displayed under the input. If this is provided then the input will be put into the error state */
  error?: string;
  /** Help info text that will be displayed under the input */
  help?: string;
  /** The size of the input */
  size?: "sm" | "lg";
  /** The class name to apply to the input */
  className?: string;
}

/* pulled from bootstrap's CSS */
export const BootstrapArrow = (props: ComponentProps<"svg">) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height={12} width={16} {...props}>
    <path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" />
  </svg>
);

function wrapSelect<Select extends ReactSelect | CreatableSelect>(SelectComponent: Select, displayName: string) {
  // we're assigning displayName below, the lint rule just doesn't understand Object.assign
  // eslint-disable-next-line react/display-name
  const Component = forwardRef<SelectInstance, Override<ComponentProps<Select>, EnhancedSelectProps>>(
    ({ size, className, name, id = name, label, help, error, ...props }, ref) => (
      <>
        <Label {...{ id, label }} />
        <SelectComponent
          classNamePrefix="enhanced-select"
          id={id}
          // passing props like this is messy for TS
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(props as any)}
          ref={ref}
          components={{
            DropdownIndicator: (props) => (
              <components.DropdownIndicator {...props}>
                <BootstrapArrow />
              </components.DropdownIndicator>
            ),
            ...props.components,
          }}
          className={clsx("enhanced-select-container", size && `enhanced-select--${size}`, className)}
        />
        <Feedback {...{ id, help, error }} />
      </>
    )
  );
  return Object.assign(Component, { displayName });
}

type EnhancedSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group> &
    EnhancedCreatableSelectProps &
    RefAttributes<SelectInstance<Option, IsMulti, Group>>
) => ReactElement;

export const EnhancedSelect = wrapSelect(ReactSelect, "EnhancedSelect") as EnhancedSelect;

export type EnhancedCreatableSelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Override<CreatableProps<Option, IsMulti, Group>, EnhancedSelectProps>;

type EnhancedCreatableSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: EnhancedCreatableSelectProps<Option, IsMulti, Group> & RefAttributes<SelectInstance<Option, IsMulti, Group>>
) => ReactElement;

export const EnhancedCreatableSelect = wrapSelect(
  CreatableSelect,
  "EnhancedCreatableSelect"
) as EnhancedCreatableSelect;
