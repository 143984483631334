import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { updateNotes } from "@/actions/contract/contractCreateEditActions";
import { Note } from "@/common-prop-types";
import RenderHTML from "@/components/common/RenderHTML";

const NotesListPropTypes = {
  contractId: PropTypes.number,
  translations: PropTypes.object,
  openModal: PropTypes.func,
};

const EditNotePropTypes = {
  note: PropTypes.shape(Note),
  openModal: PropTypes.func,
};

const NotesAlertPropTypes = {
  noteId: PropTypes.number,
  includeInAlerts: PropTypes.bool,
  contractId: PropTypes.number,
};

const EditableNotesPropTypes = {
  note: PropTypes.shape(Note),
  translations: PropTypes.any,
};

const NoteBookmarkPropTypes = {
  contractId: PropTypes.number,
  noteId: PropTypes.number,
  bookmark: PropTypes.bool,
  translations: PropTypes.any,
};

const NoteRowPropTypes = {
  contractId: PropTypes.number,
  note: PropTypes.shape(Note),
  translations: PropTypes.any,
  openModal: PropTypes.func,
};

/**
 * List of table headers for notes table
 */
const notesTableHeader = ["author_name", "note", "date_time", "private", "bookmark", "include_in_alerts"];

/**
 * @description get class for notes table headers
 * @param {string} header
 * @returns {string | undefined}
 */
const getNotesTableHeaderClass = (header) => {
  if (header === "note") return "wid-25";
  if (["bookmark", "include_in_alerts"].includes(header)) return "td-cell-ellipsis";
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof NoteRowPropTypes>>}
 */
const NoteRow = ({ contractId, note, translations, openModal }) => (
  <tr>
    <td>{note.user}</td>
    <td>
      <EditableNotes {...{ note, translations }} />
    </td>
    <td>{moment(note.created_at).format("Do MMMM YYYY HH:mm")}</td>
    <td>{note.private ? "Yes" : "No"}</td>
    <td>
      <NoteBookmark {...{ contractId, noteId: note.id, bookmark: note.bookmark, translations }} />
    </td>
    <td>
      <NotesAlert {...{ noteId: note.id, contractId, includeInAlerts: note.include_in_alerts }} />
    </td>
    <td>
      <EditNote {...{ note, openModal }} />
    </td>
  </tr>
);
NoteRow.propTypes = NoteRowPropTypes;

/**
 * @type {React.FC<PropTypes.InferProps<typeof NotesListPropTypes>>}
 */
export default function NotesList({ contractId, translations, openModal }) {
  const notes = useSelector((state) => state.createEditReducers.contractNotes);

  if (!notes.length) return <></>;

  return (
    <table className="notes-table style-table-contracts">
      <thead className="table-block-title">
        <tr>
          {notesTableHeader.map((header) => (
            <td key={header} className={getNotesTableHeaderClass(header)}>
              {header === "include_in_alerts" ? translations.include_in_alerts : <div>{translations[header]}</div>}
            </td>
          ))}
          <td></td>
        </tr>
      </thead>
      <tbody>
        {notes.map((note) => (
          <NoteRow {...{ contractId, note, openModal, translations }} key={note.id} />
        ))}
      </tbody>
    </table>
  );
}
NotesList.propTypes = NotesListPropTypes;
NotesList.defaultProps = {
  openModal: () => {},
  contractNotes: [],
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof EditNotePropTypes>>}
 */
const EditNote = ({ note, openModal }) => (
  <button
    className="btn m-l8 edit-user-permission-button btn-icon"
    style={{ backgroundColor: "transparent" }}
    onClick={() => openModal(note)}
  >
    <i className="fa fa-pencil" aria-hidden="true" />
  </button>
);
EditNote.propTypes = EditNotePropTypes;
EditNote.defaultProps = {
  openModal: () => {},
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof NotesAlertPropTypes>>}
 */
const NotesAlert = ({ noteId, includeInAlerts, contractId }) => {
  const dispatch = useDispatch();
  const handleIncludeInAlerts = (e) => dispatch(updateNotes(contractId, noteId, e.target.checked, null, true));

  return (
    <>
      <input
        type="checkbox"
        name="include_in_alerts"
        id={`note_${noteId}`}
        checked={includeInAlerts}
        onChange={handleIncludeInAlerts}
      />
      <label htmlFor={`note_${noteId}`}>&nbsp;</label>
    </>
  );
};
NotesAlert.propTypes = NotesAlertPropTypes;

/**
 * @type {React.FC<PropTypes.InferProps<typeof EditableNotesPropTypes>>}
 */
const EditableNotes = ({ note, translations }) => {
  const isNoteEdited = note.updated_at > note.created_at;

  return (
    <>
      {isNoteEdited && (
        <span className="editable-notes mr-1" title={translations.edited}>
          ({translations.edited})
        </span>
      )}
      <div>
        <RenderHTML content={note.content} replaceNewLines={false} />
      </div>
    </>
  );
};
EditableNotes.propTypes = EditableNotesPropTypes;

/**
 * @type {React.FC<PropTypes.InferProps<typeof NoteBookmarkPropTypes>>}
 */
const NoteBookmark = ({ contractId, noteId, bookmark, translations }) => {
  const dispatch = useDispatch();
  const handleBookmark = () => dispatch(updateNotes(contractId, noteId, null, !bookmark));

  const [starType, starColor] = bookmark ? ["fa-star", "orange-link"] : ["fa-star-o", ""];

  return (
    <button
      className={`btn btn-icon ${starColor}`}
      title={translations.bookmark}
      id={`bookmark_${noteId}`}
      onClick={handleBookmark}
      style={{ backgroundColor: "transparent" }}
    >
      <i className={`fa fa-lg ${starType}`} aria-hidden="true" />
    </button>
  );
};
NoteBookmark.propTypes = NoteBookmarkPropTypes;
