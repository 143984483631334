import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import { requestLoaderForActions } from "../utils/requestLoaderForActions";
import { objectToParamString } from "../components/advanced_lots/common";
import { getError } from "./errorActions";
import { makeApiCall } from "./commonActions";
import { createAction } from "@reduxjs/toolkit";

const fetchBidsDetailsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BIDS,
    payload: {
      bidList: data.bid_list,
      questionnaires: data.questionnaires,
      questionnaireSections: data.questionnaire_sections,
      questionnaireQuestions: data.questionnaire_questions,
      questionnaireAnswers: data.questionnaire_answers,
      answers: data.answers,
      questionnaireAnswersQuestions: data.questionnaire_answers_questions,
      questionnaireAnswersSections: data.questionnaire_answers_sections,
    },
  };
};

const deleteBidSuccess = (data) => {
  return {
    type: actionTypes.DELETE_BID,
    payload: {
      bidList: data.bid_list,
      deletedBidId: data.deleted_bid_id,
      bids: data.bids,
      award_total: data.award_total,
      award_saving: data.award_saving,
      award_total_percentage: data.award_total_percentage,
    },
  };
};

const submitBidSuccess = ({ data }) => {
  const bid = { ...data.bid, showLabel: true };
  return {
    type: actionTypes.BID_SUBMITTED,
    payload: {
      bid,
      event_total_lot_bid: data.event_total_lot_bid,
      event_total_lot_blics: data.event_total_lot_blics,
      lot_total_blics: data.lot_total_blics,
      best_bids: data.best_bids,
    },
  };
};

const cancelBidSuccess = (response, bidId) => {
  return {
    type: actionTypes.CANCEL_BID,
    payload: {
      bidId,
    },
  };
};

const placeBidSuccess = (data) => {
  return {
    type: actionTypes.PLACE_BID,
    payload: {
      bid: data.bid,
      bid_line_item_components: data.bid_line_item_components,
      bid_range: data.bid_range,
      ...data,
    },
  };
};

const rejectLotSuccess = (data) => {
  return {
    type: actionTypes.LOT_REJECTED,
    payload: {
      data,
      bidId: data.bid.id,
    },
  };
};

const updateBlicSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_BLICS,
    payload: data,
  };
};

const uploadTemplateSuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_BID_TEMPLATE,
    bids: data.bids,
    bidLineItemComponents: data.bid_line_item_components,
    lotTotalBlics: data.lot_total_blics,
  };
};

const showSuccess = (successMessage) => {
  if (successMessage === undefined || !(successMessage.length > 1)) {
    return { type: "NA" };
  } else {
    return { type: actionTypes.SUCCESS, success: successMessage };
  }
};

const bidRangeSuccess = (res, lotDetails) => {
  return {
    type: actionTypes.BID_RANGE,
    data: { ...res.data, ...lotDetails, id: lotDetails.lotId },
  };
};

export const setLotStatus = createAction("setLotStatus");
export const setLotStatusBidding = (lotId) => setLotStatus({ lotId, status: "bidding" });

export const setLotStatusViewing = (lotId) => setLotStatus({ lotId, status: "viewing" });

export const fetchBidsDetails = (lotId, showOverallScore, eventId, participantId, auctionTab) => async (dispatch) => {
  // dispatch uniq action type before calling API to set isLoading value
  // to true.
  dispatch(requestLoaderForActions(actionTypes.FETCH_BIDS_REQUESTED));
  const params = participantId ? `&participant_id=${participantId}` : "";
  await apiCall
    .get(
      `${
        config.V2_BASE_URL
      }/lots/${lotId}/bids?before_auction=${!auctionTab}&show_overall_score=${showOverallScore}&event_id=${eventId}&auction_tab=${auctionTab}${params}`
    )
    .then((response) => {
      dispatch(fetchBidsDetailsSuccess(response.data));
      // dispatch uniq action type after calling API to set isLoading
      // value to false so that loader can be hide and actual record will show.
      dispatch(requestLoaderForActions(actionTypes.FETCH_BIDS_COMPLETED));
    })
    .catch((error) => {
      dispatch(getError(error.response));
      dispatch(requestLoaderForActions(actionTypes.FETCH_BIDS_COMPLETED));
    });
};

export const deleteBid = (bid, beforeAuction) => async (dispatch) => {
  return await apiCall
    .remove(`${config.V2_BASE_URL}/lots/${bid.lot_id}/bids/${bid.id}?before_auction=${beforeAuction}`)
    .then((response) => {
      dispatch(deleteBidSuccess(response.data));
      dispatch(updateBlicSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const uploadTemplate = (payload, eventId) => (dispatch) =>
  apiCall.post(`${config.V2_BASE_URL}/events/${eventId}/bids/upload`, payload).then((response) => {
    dispatch(uploadTemplateSuccess(response.data));
    dispatch(showSuccess(response.data.message));
    return response.status;
  });

export const placeBid = (lotId, payload) => (dispatch, getState) => {
  // Test for noop, we cannot start to place a bid unless we are in the initial "viewing" state
  const state = getState().lotReducers.lotStatus?.[lotId] || "viewing";
  if (state !== "viewing") {
    return Promise.resolve();
  }

  dispatch({ type: actionTypes.PLACE_BID_PENDING, lotId });
  return makeApiCall("post", `${config.V2_BASE_URL}/lots/${lotId}/bids`, placeBidSuccess, dispatch, payload);
};

export const bidRange = (lotId, bidId, params) => {
  if (bidId) params.id = bidId;
  return (dispatch) =>
    apiCall
      .get(`${config.V2_BASE_URL}/lots/${lotId}/bids/update_bid_range${objectToParamString(params)}`)
      .then((response) => dispatch(bidRangeSuccess(response, { ...params, lotId, bidId })))
      .catch((error) => dispatch(getError(error.response)));
};

export const cancelBid = (lotId, bidId) => (dispatch, getState) => {
  // Test for noop, we can only cancel a bid if we are currently bidding
  const state = getState().lotReducers.lotStatus?.[lotId] || "viewing";
  if (!["bidding", "viewing"].includes(state)) {
    return Promise.resolve();
  }

  dispatch({ type: actionTypes.CANCEL_BID_PENDING, lotId });
  return apiCall
    .remove(`${config.V2_BASE_URL}/lots/${lotId}/bids/${bidId}/cancel`)
    .then((response) => dispatch(cancelBidSuccess(response, bidId)));
};

export const submitBid = (lotId, bidId, payload) => (dispatch, getState) => {
  // Test for noop, we can only place a bid if we are currently bidding
  const state = getState().lotReducers.lotStatus?.[lotId];
  if (state !== "bidding") {
    return Promise.resolve();
  }

  dispatch({ type: actionTypes.BID_SUBMITTED_PENDING, lotId });
  return apiCall
    .patch(`${config.V2_BASE_URL}/lots/${lotId}/bids/${bidId}`, payload)
    .then((response) => dispatch(submitBidSuccess(response)))
    .catch((error) => {
      dispatch(getError(error.response));
      dispatch(setLotStatusViewing(lotId));
    });
};

export const rejectLot = (lotId, payload) => {
  return (dispatch) =>
    makeApiCall("post", `${config.V2_BASE_URL}/lots/${lotId}/bids`, rejectLotSuccess, dispatch, payload);
};

export const updateBidSurveyOffered = (eventParticipantId, payload) => {
  return (dispatch) =>
    makeApiCall(
      "post",
      `${config.V2_BASE_URL}/event_participants/${eventParticipantId}/mark_survey_offered`,
      () => {},
      dispatch,
      payload
    );
};

export const updateBidSurveyFeedback = (eventId, payload) => {
  return (dispatch) =>
    makeApiCall("post", `${config.V2_BASE_URL}/events/${eventId}/surveys`, () => {}, dispatch, payload);
};
