import type { CSSProperties, ElementType, ReactNode, WeakValidationMap } from "react";
import React from "react";
import type { Validator } from "prop-types";
import PropTypes from "prop-types";

type CommonEllipsisProps = {
  as?: ElementType<{ title: string; className: string; style: CSSProperties }>;
  maxWidth: number;
};

type EllipsisProps =
  | (CommonEllipsisProps & { children: string; title?: string })
  | (CommonEllipsisProps & { children: Exclude<ReactNode, string>; title: string });

const EllipsisPropTypes = {
  /**
   * The tag or component that the wrapper tag will be render as.
   *
   * @default 'div'
   */
  as: PropTypes.elementType as Validator<CommonEllipsisProps["as"]>,
  /**
   * The content that will be put inside the wrapper tag. The content will be
   * truncated with a `...` if it overflows the container.
   */
  children: PropTypes.node.isRequired,
  /**
   * The maximum width of the container. If the content is larger than this then
   * it will be truncated.
   */
  maxWidth: PropTypes.number.isRequired,
  /**
   * The title for the container. This will be used to show the full content
   * when the user hovers over the element. This will only need to be used if
   * the children is not a string and a Element.
   */
  title: (props) => {
    if (typeof props.children !== "string" && !props.title) {
      return new Error("The `title` prop is required when the `children` prop is not a string");
    }

    if (props.title && typeof props.title !== "string") {
      return new Error("The `title` prop must be a string");
    }
    return null;
  },
} satisfies WeakValidationMap<EllipsisProps>;

export const Ellipsis = ({ as, maxWidth, title, children }: EllipsisProps) => {
  const Component = as || "div";

  return (
    <Component title={title || (children as string)} className="md-ellipsis" style={{ maxWidth: `${maxWidth}px` }}>
      {children}
    </Component>
  );
};

Ellipsis.propTypes = EllipsisPropTypes;
