import React, { Component } from "react";
import { connect } from "react-redux";

import { updateCustomNotificationStatus } from "../../../../actions/contract/contractConfigurationActions";
import Checkbox from "../Checkbox";

class CustomNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: props.notification,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.data.updatedStatusOfCustomNotification) {
      this.setState({
        notification: newProps.data.updatedStatusOfCustomNotification.data["notification"],
      });
    }
  }

  handleChange(e) {
    const isChecked = e.target.checked;
    const { companyId, columnId } = this.props;
    this.props.updateCustomNotificationStatus("notification", columnId, isChecked, companyId);
  }

  render() {
    const { notification } = this.state;
    const { translations, currentUserEditConfigPermission } = this.props;
    return (
      <div className="col-md-12 col-sm-12">
        <div className="block-item btn-group-sm same-height-block">
          <div className={`title ${notification ? "" : "disabled-text"}`}>
            <Checkbox
              name={"custom_notifications"}
              checked={notification}
              onChange={this.handleChange}
              translations={translations}
              readOnly={!currentUserEditConfigPermission}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.contractConfigurationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateCustomNotificationStatus: (columnName, columnId, status, companyId) =>
    dispatch(updateCustomNotificationStatus(columnName, columnId, status, companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomNotifications);
