import React from "react";
import { confirm } from "@/utils/confirm";
import type { Lot, Bid } from "@/common-prop-types";

export interface DeleteBidButtonProps {
  translations: Record<string, string>;
  permittedToEdit: boolean;
  isRfqTab: boolean | undefined;
  lot: Lot;
  bidToDelete: BidToDelete;
  deleteBid: (bid: BidToDelete, beforeAuction: boolean | undefined) => void;
}

interface BidToDelete extends Bid {
  timed_out_bid?: boolean;
}

const DeleteBidButton: React.FC<DeleteBidButtonProps> = (props) => {
  const { translations, permittedToEdit, isRfqTab, lot, bidToDelete, deleteBid } = props;

  // TODO: Refactor work - we need to check for the expected behaviour and then
  // remove this next condition and manage this within the parent components.
  // I'm keeping the logic same as before for now, just converting the function
  // to be a react component as this is returning an HTML element.
  if (!(bidToDelete && permittedToEdit && !bidToDelete.timed_out_bid && !lot.is_event_total)) return "";

  return (
    <button
      className="btn btn-default delete-small-icon"
      onClick={() => {
        confirm({ body: translations.are_you_sure }).accept(() => {
          deleteBid(bidToDelete, isRfqTab);
        });
      }}
    >
      <i className="fa fa-trash-o red-color" aria-hidden="true" />
    </button>
  );
};

export default DeleteBidButton;
