import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "simple-react-modal";
import * as _ from "lodash";
import Loader from "react-loader-advanced";
import Draggable from "react-draggable";
import { fetchMessages, markReadToMessage, sendMessage } from "../../../../../actions/qq/emarketplace/messagesActions";
import { getParticipantsByRequestId } from "../../../../../actions/qq/emarketplace/emSummaryActions";
import { fetchUserDetails, showToasterMessages } from "../../../../../actions/qq/genericActions";
import { notifyParticipant } from "../../../../../actions/qq/editActions";
import LoaderMessage from "../../../LoaderMessage";
import connectToWebsocket from "../../../js/websocketConnection";
import { scrollToMessage } from "../../../js/common";
import TrixEditor from "@/components/common/TrixEditor";
import RenderHTML from "@/components/common/RenderHTML";

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModel: false,
      isNewMessage: "",
      message: "",
      selectedParticipants: [],
      messageReplyId: null,
      selectionArray: [],
      showLoader: true,
      messages: [],
    };

    this.#connectToMessageSocket();
  }

  /**
   * Remove all the message listeners when the component unmounts.
   */
  componentWillUnmount() {
    this.#disconnectToMessageSocket();
  }

  /**
   * @type {import('socket.io-client').Socket|null}
   */
  #socketObject = null;

  /**
   * Connects to the web socket to listen for new messages if the listener has
   * not already been set up
   */
  #connectToMessageSocket = () => {
    if (this.#socketObject === null) {
      this.#socketObject = connectToWebsocket(this.props.requestId, this.props.user.id);
      this.#socketObject.on("message", this.#onMessage);
    }
  };

  /**
   * If the connection is active when the component unmounts, then the event
   * listeners will get removed.
   */
  #disconnectToMessageSocket = () => {
    if (this.#socketObject !== null) {
      this.#socketObject.off("message", this.#onMessage);
      this.#socketObject = null;
    }
  };

  /**
   * Handel a new incoming message
   */
  #onMessage = async (data) => {
    const { messages } = this.state;
    let parentMessage;
    if (typeof data === "object") {
      if (data.parent_id) {
        let parentIndex;
        messages.forEach((message, idx) => {
          if (data.parent_id === message.id.toString() || data.parent_id === message.parent_id) {
            parentIndex = idx;
            parentMessage = message;
          }
        });
        messages.splice(parentIndex + 1, 0, data);
      } else {
        messages.unshift(data);
        [parentMessage] = messages;
      }
      this.setState(
        {
          messages,
        },
        () => {
          this.props.showToasterMessages(
            "SHOW_SUCCESS",
            this.props.translations.summary_status.message.new_message_alert
          );
        }
      );
      if (this.messageSection && this.messageSection.classList.contains("collapsed")) {
        this.messageSection.click();
      }
      scrollToMessage(true, parentMessage.id);
    }
  };

  async UNSAFE_componentWillMount() {
    const { requestId } = this.props;
    if (requestId) {
      await this.props.fetchMessages(requestId);
      await this.props.getParticipantsByRequestId(requestId);
      this.setState({
        showLoader: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.notify.postEditModal && newProps.notify.postEditModal.status) {
      this.setState({
        showModel: true,
        isNewMessage: true,
      });
    }
    this.setState({ messages: newProps.messages.messages_list ? newProps.messages.messages_list : [] });
  }

  outboxList(message, index) {
    const { requestId, translations } = this.props;
    return (
      <div className="each_message outbox celar" key={index} id={`message_${message.id}`}>
        <span className="tip">{translations.summary_status.message.outbox}</span>
        <div className="message_box">
          <div className="top_heading clear">
            <span className="sender">
              <span>{translations.summary_status.message.to}:</span>
              {message.recipients.length > 1 ? (
                <div className="small-gray-box btn-group dropdown-sm">
                  <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                    <i className="fa fa-users" aria-hidden="true" />
                    <span className="caret" />
                  </a>
                  <ul className="dropdown-menu" role="menu">
                    {message.recipients.map((recipient, idx) => (
                      <li key={`${recipient.user_id + idx}`}>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          title={`Company: ${recipient.company_name ? recipient.company_name : ""}; E-mail: ${
                            recipient.email
                          }`}
                        >
                          {recipient.company_name ? recipient.company_name : recipient.user_display_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <span
                  className="small-gray-box"
                  data-toggle="tooltip"
                  title={`Company: ${message.recipients[0].company_name}; E-mail: ${message.recipients[0].email}`}
                >
                  {message.recipients[0].company_name
                    ? message.recipients[0].company_name
                    : message.recipients[0].user_display_name}
                </span>
              )}
              <span className="light-gray-color">{translations.summary_status.message.from}:&nbsp;</span>
              <span className="small-gray-box">{translations.summary_status.message.me}</span>
            </span>
            <span className="schedule">
              <span>
                <i className="fa fa-calendar-o" /> {message.created_at}
              </span>
            </span>
          </div>
          <div className="message">
            <RenderHTML content={message.message} />
            {message.bounced && !message.recipients[0].message_read ? (
              <button
                type="button"
                className="btn btn-primary reply-button m-r10"
                onClick={() => this.props.markAsRead(requestId, message.id, { message: { read: true } })}
              >
                {translations.summary_status.message.mark_as_read}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }

  inboxList(message, index) {
    const { requestId, translations } = this.props;
    return (
      <div className="each_message inbox celar" key={index} id={`message_${message.id}`}>
        <span className="tip">{translations.summary_status.message.inbox}</span>
        <div className="message_box">
          <div>
            <div className="top_heading clear">
              <span className="sender">
                <span className="light-gray-color">{translations.summary_status.message.to}:&nbsp;</span>
                {/* <span className='small-gray-box'>{message.recipients[0].user_display_name}</span> */}
                {message.recipients.length > 1 ? (
                  <div className="small-gray-box btn-group dropdown-sm">
                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                      <i className="fa fa-users" aria-hidden="true" /> {message.recipients.length}
                      <span className="caret" />
                    </a>
                    <ul className="dropdown-menu" role="menu">
                      {message.recipients.map((recipient) => (
                        <li key={recipient.user_id}>
                          <a
                            href="#"
                            data-toggle="tooltip"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            title={`Company: ${recipient.company_name ? recipient.company_name : ""}; E-mail: ${
                              recipient.email
                            }`}
                          >
                            {recipient.user_display_name ? recipient.user_display_name : recipient.email}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <span
                    className="small-gray-box"
                    data-toggle="tooltip"
                    title={`Company: ${message.recipients[0].company_name}; E-mail: ${message.recipients[0].email}`}
                  >
                    {message.recipients[0].user_display_name
                      ? message.recipients[0].user_display_name
                      : message.recipients[0].email}
                  </span>
                )}
                <span>{translations.summary_status.message.from}:</span>
                <span
                  className="small-gray-box"
                  data-toggle="tooltip"
                  title={`Company: ${message.user.user_company_name}; E-mail: ${message.user.email}`}
                >
                  {message.user.user_company_name ? message.user.user_company_name : message.display_name}
                </span>
              </span>
              <span className="schedule">
                <span>
                  <i className="fa fa-calendar-o" /> {message.created_at}
                </span>
              </span>
            </div>
            <div className="message">
              <RenderHTML content={message.message} />
              &nbsp;
              <div className="m-t10 m-b10 clearfix">
                {message.recipients[0].message_read ? (
                  ""
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary reply-button m-r10"
                    onClick={() => this.props.markAsRead(requestId, message.id, { message: { read: true } })}
                  >
                    {translations.summary_status.message.mark_as_read}
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-primary reply-button"
                  onClick={() => this.showMessageModal(false, message.id, message.user.id)}
                >
                  {translations.summary_status.message.send_reply}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  showMessageModal(flag, messageReplyId, replyUserId) {
    if (messageReplyId) {
      const replyToId = this.state.selectedParticipants.concat(replyUserId);
      this.setState({ messageReplyId, selectedParticipants: replyToId });
    }
    this.setState({ showModel: true, isNewMessage: flag });
  }

  closeMessageModal() {
    this.setState({
      showModel: false,
      selectedParticipants: [],
      messageReplyId: null,
      message: "",
      selectionArray: [],
    });
    this.props.notifyParticipant(this.props.requestId, false, "");
  }

  addSingleParticipant(id) {
    const index = _.indexOf(this.state.selectedParticipants, id);
    let selected = [];
    if (index === -1) {
      selected = this.state.selectedParticipants.concat(id);
    } else {
      selected = this.state.selectedParticipants;
      selected.splice(index, 1);
    }
    this.setState({ selectedParticipants: selected });
  }

  addMessage(content) {
    this.setState({ message: content });
    if (!content.trim() && content.length > 1) {
      this.editorError.hidden = false;
    } else {
      this.editorError.hidden = true;
    }
  }

  addParticipants(flag, checkedFlag) {
    let temp = this.state.selectedParticipants;
    const participantsList = this.props.emSummary.bids_summary.event_participants;
    switch (flag) {
      case "Pending": {
        _.filter(participantsList, (o) => {
          if (o.status === "Pending") {
            const idx = _.indexOf(temp, o.user_id);
            if (idx === -1) {
              temp.push(o.user_id);
            }
          }
          if (checkedFlag) {
            if (o.status === "Pending") {
              const idx = _.indexOf(temp, o.user_id);
              temp.splice(idx, 1);
            }
          }
          return true;
        });

        const { selectionArray } = this.state;
        const i = selectionArray.indexOf(1);
        if (i === -1) {
          selectionArray.push(1);
        } else {
          selectionArray.splice(i, 1);
        }
        this.setState({ selectionArray });
        break;
      }
      case "Active": {
        _.filter(participantsList, (o) => {
          if (o.status === "Active") {
            const idx = _.indexOf(temp, o.user_id);
            if (idx === -1) {
              temp.push(o.user_id);
            }
          }
          if (checkedFlag) {
            if (o.status === "Active") {
              const idx = _.indexOf(temp, o.user_id);
              temp.splice(idx, 1);
            }
          }
          return true;
        });
        const { selectionArray } = this.state;
        const i = selectionArray.indexOf(2);
        if (i === -1) {
          selectionArray.push(2);
        } else {
          selectionArray.splice(i, 1);
        }
        this.setState({ selectionArray });
        break;
      }
      case "Declined": {
        _.filter(participantsList, (o) => {
          if (o.status === "Declined") {
            const idx = _.indexOf(temp, o.user_id);
            if (idx === -1) {
              temp.push(o.user_id);
            }
          }
          if (checkedFlag) {
            if (o.status === "Declined") {
              const idx = _.indexOf(temp, o.user_id);
              temp.splice(idx, 1);
            }
          }
          return true;
        });

        const { selectionArray } = this.state;
        const i = selectionArray.indexOf(3);
        if (i === -1) {
          selectionArray.push(3);
        } else {
          selectionArray.splice(i, 1);
        }
        this.setState({ selectionArray });
        break;
      }
      default: {
        let selectionArray = [];
        if (this.state.selectionArray.length !== 3) {
          temp = _.map(participantsList, "user_id");
          selectionArray = [1, 2, 3];
        } else {
          temp = [];
        }

        this.setState({
          selectionArray,
          selectedParticipants: temp,
        });
      }
    }
    this.setState({ selectedParticipants: temp });
  }

  async sendMessage(e) {
    e.target.disabled = true;
    const { requestId } = this.props;
    const messages = {
      message: this.state.message,
      recipient_ids: this.state.selectedParticipants,
    };
    if (this.state.messageReplyId) {
      messages.parent_id = this.state.messageReplyId;
    }
    const data = {
      messages,
    };
    const response = await this.props.sendMessage(requestId, data);
    if (response && response.status === 201) {
      if (this.state.messageReplyId) {
        this.props.markAsRead(requestId, this.state.messageReplyId, { message: { read: true } });
      }
      this.props.showToasterMessages("SHOW_SUCCESS", this.props.translations.success_message.your_message_send);
      this.closeMessageModal();
    } else {
      e.target.disabled = false;
    }
  }

  isChecked(id) {
    const flag = _.indexOf(this.state.selectedParticipants, id) > -1;
    return flag;
  }

  statusColor(status) {
    switch (status) {
      case "Active":
        return "active-color";
      case "Pending":
        return "pending-color";
      case "Declined":
        return "declined-color";
      default:
        return "active-color";
    }
  }

  render() {
    const messageList = this.state.messages;
    const userId = this.props.user.id;
    const translations = this.props.translations;
    const newMessage = this.state.isNewMessage;
    const participantsList = this.props.emSummary.bids_summary
      ? this.props.emSummary.bids_summary.event_participants
      : [];
    return (
      <Loader
        show={this.state.showLoader}
        message={<LoaderMessage loaderText={translations.loader_text} />}
        disableDefaultStyles
      >
        <div className="category_holder prd_details_add_box">
          <a
            id="messageCollapse"
            className="category_title active collapsed"
            data-toggle="collapse"
            href="#collapseMessage"
            ref={(ref) => {
              this.messageSection = ref;
            }}
          >
            {translations.summary_status.message.messages}
          </a>
          <div className="details_form_box panel-collapse collapse" id="collapseMessage">
            <div className="inbox_holder clear">
              <div>
                <div className="panel-body">
                  {userId ? (
                    <div className="col-xs-12 message-top">
                      <div className="table-box">
                        <div className="heading-button m-b20 clearfix">
                          <button
                            type="button"
                            onClick={() => this.showMessageModal(true, null, null)}
                            className="btn btn-primary new-message pull-right modal-btn-open"
                          >
                            {translations.summary_status.message.send_new_message}
                          </button>
                        </div>
                        <div className="clearfix" />
                        {messageList.length > 0 ? (
                          <div className="message-all">
                            {messageList.map((message, idx) =>
                              message.user.id === userId ? this.outboxList(message, idx) : this.inboxList(message, idx)
                            )}
                          </div>
                        ) : (
                          <div>{translations.summary_status.message.no_message_available}</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-popup popup-lg popup-new-message send-message-popup">
          <Modal
            style={{ zIndex: 1100, backgroundColor: "#00000033" }}
            show={this.state.showModel}
            onClose={() => {
              this.closeMessageModal();
            }}
            closeOnOuterClick
          >
            <Draggable handle={".modal-header"}>
              <div className="vertical-top">
                <div className="vertical-align-center" role="document">
                  <div className="modal-content clearfix">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.closeMessageModal();
                      }}
                      className="close-icon modal-btn-close"
                    >
                      <i className="fa fa-times" aria-hidden="true" />
                    </a>
                    <div className="modal-header">
                      <h4>
                        <b>{translations.summary_status.message.event}: </b>
                        {this.props.requestName}
                      </h4>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        {this.props.notify.postEditModal && this.props.notify.postEditModal.status && (
                          <div className="alert alert-fixed alert-info fade in">
                            <p>{translations.summary_status.post_edit_popup_message}</p>
                          </div>
                        )}
                        {newMessage ? (
                          <label>{translations.summary_status.message.message}</label>
                        ) : (
                          <label>{translations.summary_status.message.reply}</label>
                        )}
                        <TrixEditor value={this.state.message} onChange={(content) => this.addMessage(content)} />
                        <span
                          hidden
                          ref={(ref) => {
                            this.editorError = ref;
                          }}
                          className="text-danger"
                        >
                          {translations.error_message.no_white_space}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>{translations.summary_status.message.send_message_to}</label>
                        <div className="checkbox-split">
                          <div className="checkbox">
                            <div>
                              <input
                                onChange={() => this.addParticipants("all", this.state.selectionArray.length === 3)}
                                id="checkbox7"
                                type="checkbox"
                                name="checkbox"
                                value="7"
                                checked={this.state.selectionArray.length === 3}
                              />
                              <label htmlFor="checkbox7">
                                <span />
                                {translations.summary_status.message.all_participants}
                              </label>
                            </div>
                          </div>
                          <div className="checkbox">
                            <div>
                              <input
                                onChange={() =>
                                  this.addParticipants("Active", this.state.selectionArray.indexOf(2) > -1)
                                }
                                id="checkbox8"
                                type="checkbox"
                                name="checkbox"
                                value="8"
                                checked={this.state.selectionArray.indexOf(2) > -1}
                              />
                              <label htmlFor="checkbox8">
                                <span />
                                {translations.summary_status.message.active_participants}
                              </label>
                            </div>
                          </div>
                          <div className="checkbox">
                            <div>
                              <input
                                onChange={() =>
                                  this.addParticipants("Pending", this.state.selectionArray.indexOf(1) > -1)
                                }
                                id="checkbox9"
                                type="checkbox"
                                name="checkbox"
                                value="9"
                                checked={this.state.selectionArray.indexOf(1) > -1}
                              />
                              <label htmlFor="checkbox9">
                                <span />
                                {translations.summary_status.message.pending_participants}
                              </label>
                            </div>
                          </div>
                          <div className="checkbox">
                            <div>
                              <input
                                onChange={() =>
                                  this.addParticipants("Declined", this.state.selectionArray.indexOf(3) > -1)
                                }
                                id="checkbox10"
                                type="checkbox"
                                name="checkbox"
                                value="10"
                                checked={this.state.selectionArray.indexOf(3) > -1}
                              />
                              <label htmlFor="checkbox10">
                                <span />
                                {translations.summary_status.message.declined_participants}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="status-table fixed_headers">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>{translations.summary_status.message.company_name}</th>
                              <th>{translations.summary_status.message.status}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {participantsList.map((row, index) => (
                              <tr key={row.id}>
                                <td>
                                  <div className="checkbox">
                                    <div>
                                      <input
                                        onChange={() => this.addSingleParticipant(row.user_id)}
                                        id={`${index}_checkbox4`}
                                        type="checkbox"
                                        name="checkbox"
                                        checked={_.indexOf(this.state.selectedParticipants, row.user_id) > -1}
                                      />
                                      <label htmlFor={`${index}_checkbox4`}>
                                        <span />
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td className={this.statusColor(row.status)}>{row.display_name}</td>
                                <td>{row.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <ul className="color-represent">
                        <li>{translations.summary_status.message.active}</li>
                        <li>{translations.summary_status.message.pending}</li>
                        <li>{translations.summary_status.message.declined}</li>
                      </ul>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary cancel-reply modal-btn-close"
                        onClick={() => this.closeMessageModal()}
                      >
                        {translations.summary_status.message.cancel}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary cancel-reply modal-btn-close"
                        disabled={
                          this.state.message === "" ||
                          !this.state.message.replace(/<[^>]+>|&nbsp;/g, "") ||
                          this.state.selectedParticipants.length === 0
                        }
                        onClick={(e) => this.sendMessage(e)}
                      >
                        {translations.summary_status.message.send_message}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Draggable>
          </Modal>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLang: state.changeLanguageReducer,
  messages: state.emMessageReducer,
  emSummary: state.emSummaryReducer,
  notify: state.notifyParticipant,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMessages: (requestId) => dispatch(fetchMessages(requestId)),
  getParticipantsByRequestId: (requestId) => dispatch(getParticipantsByRequestId(requestId)),
  markAsRead: (requestId, messageId, data) => dispatch(markReadToMessage(requestId, messageId, data)),
  sendMessage: (requestId, data) => dispatch(sendMessage(requestId, data)),
  notifyParticipant: (requestId, status, message) => dispatch(notifyParticipant(requestId, status, message)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
