import type { Nullish } from "@/types/util";
import type { ForwardedRef, RefCallback } from "react";

export function getMetaContent(name: string) {
  const metaElement = document.querySelector<HTMLMetaElement>(`meta[name="${name}"]`);
  return metaElement?.content;
}

export function getSelectedValues(target: HTMLSelectElement) {
  return Array.from(target.selectedOptions).map((option) => option.value);
}

export function applyRef<T>(ref: Nullish<ForwardedRef<T>>, value: T | null) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}

export function mergeRefs<T>(...refs: Nullish<ForwardedRef<T>>[]): RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => applyRef(ref, value));
  };
}
