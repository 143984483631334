import { Controller } from "@hotwired/stimulus";
import { assert } from "@/utils/assert";

export class BaseController extends Controller {
  protected getTargetList(targetName: string): unknown[] {
    assert(targetName in this, "Is this even a Stimulus controller?");

    const targets = this[targetName as keyof this];
    assert(targets instanceof Array, "Stimulus should return and an array here");

    return targets;
  }
}
