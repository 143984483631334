import React, { Component } from "react";
import DropzoneComponent from "react-dropzone-component";
import { connect } from "react-redux";
import Select2 from "react-select2-wrapper";

import {
  uploadFileToContract,
  removeFileFromContract,
  updateFilePhysicalLocation,
} from "../../../actions/contract/contractCreateEditActions";
import { Confirm } from "../../common/Confirm";
import ContractTooltip from "./ContractTooltip";

export class UploadedFilesForm extends Component {
  constructor(props) {
    super(props);
    this.djsConfig = {
      autoProcessQueue: false,
      maxFiles: 1,
      uploadMultiple: false,
    };
    this.state = {
      contractFiles: props.contractFiles,
      fileType: 0,
      fileIdToRemove: "",
    };
    this.componentConfig = {
      iconFiletypes: [".jpg", ".png", ".gif"],
      showFiletypeIcon: false,
      postUrl: `no-url`,
    };
  }

  async handleUploadFile(file) {
    const { fileType } = this.state;
    this.dropzone.processQueue();

    const reader = new FileReader();
    let fileObj = {};
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const obj = {};
      obj.name = file.name;
      obj.source = reader.result;
      fileObj = obj;
      const result = await this.props.uploadFileToContract(this.props.contractId, +fileType, fileObj);
      if (result) {
        this.setState({
          contractFiles: result,
        });
      }
      this.dropzone.removeAllFiles();
    };
  }

  createDataPairForSelect(data) {
    const { translations } = this.props;
    let temp = Object.keys(data).map((item) => ({
      text: translations[item] || item,
      id: data[item],
    }));
    temp.unshift({
      text: translations.select,
      id: 0,
    });
    return temp;
  }

  handleDocumentTypeChange(e) {
    this.setState({
      fileType: e.target.value,
    });
  }

  async handleRemove(fileId) {
    const result = await this.props.removeFileFromContract(this.props.contractId, fileId);
    if (result) {
      this.setState({
        contractFiles: result,
      });
    }
  }

  async updateFileToRemove(fileId) {
    this.setState({
      fileIdToRemove: fileId,
    });
  }

  async handlePhysicalLocationChange(e, fileId) {
    const locationValue = e.target.value;
    const result = await this.props.updateFilePhysicalLocation(
      this.props.companyId,
      this.props.contractId,
      fileId,
      locationValue
    );
    if (result) {
      this.setState({
        contractFiles: result,
      });
    }
  }

  render() {
    const { documentFileTypes, contractId, translations, showPage } = this.props;
    const dropzoneConfig = this.componentConfig;
    const djsCon = this.djsConfig;
    const { contractFiles, fileType } = this.state;
    const eventHandlers = {
      init: (dz) => (this.dropzone = dz),
      addedfile: this.handleUploadFile.bind(this),
    };
    return (
      <div className="col-md-12 col-sm-12">
        <div className="form-section-block style-dropzone-contracts">
          <ContractTooltip translations={translations} titleText={"uploaded_files"} />
          {!showPage && (
            <div className="form-group input-field">
              <label>{translations.file_type}</label>
              <br></br>
              <Select2
                className="form-control placeholder w-50"
                multiple={false}
                data={this.createDataPairForSelect(documentFileTypes)}
                options={{
                  minimumResultsForSearch: Infinity,
                  placeholder: translations.select,
                }}
                onSelect={(e) => this.handleDocumentTypeChange(e)}
                defaultValue={fileType}
              />
            </div>
          )}
          {!showPage && fileType > 0 && (
            <div>
              <DropzoneComponent config={dropzoneConfig} eventHandlers={eventHandlers} djsConfig={djsCon} />
            </div>
          )}
          {contractFiles.length > 0 && (
            <table className="mt-4 uploaded-files-section-table">
              <thead className="table-block-title">
                <tr>
                  <td>
                    <div>{translations.file_title}</div>
                  </td>
                  <td>
                    <div>{translations.file_type}</div>
                  </td>
                  <td>
                    <div>{translations.physical_location}</div>
                  </td>
                  <td>
                    <div className="btn-group-sm" />
                  </td>
                </tr>
              </thead>
              <tbody>
                {contractFiles.map((file) => (
                  <tr key={file.id}>
                    <td>
                      {" "}
                      <a
                        className="orange-link"
                        target="_blank"
                        href={`/contracts/${contractId}/download_file_document?document_id=${file.document_id}&file_id=${file.id}`}
                      >
                        {file.name}
                      </a>
                    </td>

                    <td>{translations[file.file_type] || file.file_type}</td>
                    <td>
                      {showPage ? (
                        file.location
                      ) : (
                        <input
                          autoComplete="off"
                          className="form-control"
                          placeholder={translations.physical_location}
                          defaultValue={file.location}
                          onBlur={(e) => this.handlePhysicalLocationChange(e, file.id)}
                        />
                      )}
                    </td>
                    <td className="text-center">
                      <a
                        className="btn-action download"
                        target="_blank"
                        href={`/contracts/${contractId}/download_file_document?document_id=${file.document_id}&file_id=${file.id}`}
                      >
                        <i title={translations.download} className="fa fa-download" />
                      </a>
                      {!showPage && (
                        <a
                          className="ml-2"
                          onClick={() => this.updateFileToRemove(file.id)}
                          data-toggle="modal"
                          data-target="#fileRemoveConfirmModal"
                          rel="nofollow"
                        >
                          <i title={translations.remove} className="fa fa-times text-danger" />
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <Confirm
          translations={translations}
          message={translations.are_you_sure}
          htmlId="fileRemoveConfirmModal"
          onOkayButtonClick={() => this.handleRemove(this.state.fileIdToRemove)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ createEditReducers }) => ({
  contractFiles: createEditReducers.contractFiles,
  contractId: createEditReducers.contract.id,
  documentFileTypes: createEditReducers.documentFileTypes,
  companyId: createEditReducers.companyId,
  translations: createEditReducers.translations,
  showPage: createEditReducers.showPage,
});

const mapDispacthToProps = (dispatch) => ({
  uploadFileToContract: (contractId, fileType, file) => dispatch(uploadFileToContract(contractId, fileType, file)),
  removeFileFromContract: (contractId, fileId) => dispatch(removeFileFromContract(contractId, fileId)),
  updateFilePhysicalLocation: (companyId, contractId, fileId, physicalLocation) =>
    dispatch(updateFilePhysicalLocation(companyId, contractId, fileId, physicalLocation)),
});

export default connect(mapStateToProps, mapDispacthToProps)(UploadedFilesForm);
