import React from "react";
import { smallOrMiniCell } from "../../../common";

/**
 *
 * @description Host monitor
 *
 * Column - Active Participant
 *
 */
export default function LiActiveparticipantCount(props) {
  const { activeBidCountBeforeAuction, weightedRfqOrAuction, auctionTab } = props;
  return (
    <div className={`mycell ${smallOrMiniCell(weightedRfqOrAuction)}`}>
      {auctionTab ? null : activeBidCountBeforeAuction}
    </div>
  );
}
