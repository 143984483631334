import React, { Component, Fragment } from "react";
import Tooltip from "../../common/Tooltip";
import StakeholderNotification from "./StakeholderNotification";
import SuppliersNotification from "./SuppliersNotification";
import SupplierStakeholderInput from "./SupplierStakeholderInput";

class ExpiryNotificationModal extends Component {
  showHideStakeholder = (show, e) => {
    const { showHideStakeholderbox } = this.props;
    e.preventDefault();
    showHideStakeholderbox(show);
  };

  render() {
    const {
      stakeHoldersBox,
      includedStakeholders,
      includedSuppliers,
      includeNotes,
      updateExpiryNotification,
      handleIncludeRecipients,
      translations,
      suppliers,
      stakeholders,
    } = this.props;
    return (
      <Fragment>
        <a
          href="#"
          data-toggle="modal"
          data-target="#expiryNotificationModal"
          rel="nofollow"
          onClick={(e) => e.preventDefault()}
        >
          + {translations.add_expiry_recipients}
        </a>
        <div className="inline-block p-l10">
          <Tooltip {...translations.help_texts.add_recipients_for_expiry_notifications} />
        </div>
        <div
          id="expiryNotificationModal"
          className="modal fade modal-dialog-popout top-position ui-draggable"
          role="dialog"
          style={{ display: "none" }}
          data-backdrop={true}
        >
          <div className="modal-draggable-content">
            <div className="modal-dialog modal-dialog-popout custom-modal modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                  <h4 className="modal-title">{translations.add_recipients}</h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 form-group">
                      <input
                        type="checkbox"
                        checked={includeNotes}
                        id="contract_expiry_notification_include_notes"
                        onChange={(e) => this.props.handleChange(e)}
                      />
                      <label htmlFor="contract_expiry_notification_include_notes">{translations.include_notes}</label>
                      <span>{translations.include_note_msg}</span>
                    </div>
                    <div className="col-12 form-group recipients-btn">
                      <ul className="nav nav-tabs" role="tablist">
                        <li className={`nav-item ${stakeHoldersBox ? "active" : "inactive"}`}>
                          <a href="#" className="nav-link" onClick={(e) => this.showHideStakeholder(true, e)}>
                            {translations.stakeholders}
                          </a>
                        </li>
                        <li className={`nav-item ${stakeHoldersBox ? "inactive" : "active"}`}>
                          <a href="#" className="nav-link" onClick={(e) => this.showHideStakeholder(false, e)}>
                            {translations.suppliers}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 form-group">
                      {stakeHoldersBox &&
                        (stakeholders.length > 0 ? (
                          <Fragment>
                            <SupplierStakeholderInput
                              {...this.props}
                              userType={'stakeholders'}
                              inputId={'stakeholders_all_expiry_notification'}
                            />
                            <label htmlFor="stakeholders_all_expiry_notification">
                              {translations.all_stakeholders} {translations.all_stakeholders_default_msg}
                            </label>
                            <StakeholderNotification
                              {...this.props}
                              inputId={'notification_user_'}
                            />
                          </Fragment>
                        ) : (
                          <div className="notification-content">{translations.notification_stakeholder_msg}</div>
                        ))}
                        <SuppliersNotification
                          {...this.props}
                          inputIds={['suppliers_all_expiry_notification', 'notification_user_']}
                        />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">
                    {translations.cancel}
                  </button>
                  <button
                    type="button"
                    className={`btn btn-primary btn-default ok-btn`}
                    data-dismiss="modal"
                    onClick={() => updateExpiryNotification()}
                  >
                    {translations.save}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ExpiryNotificationModal;
