import * as apiCall from "./api";
import config from "../config";
import * as csrfApiCall from "./contract/contractAjax";
import { getError } from "./errorActions";

const finishEditingSuccess = (response) => {
  const windowObj = window.location;
  windowObj.href = windowObj.pathname.replace("advanced_lots", "advanced_lots?message_tab=true");
};

export const finishEditing = (eventId) => async (dispatch) =>
  await apiCall
    .post(`${config.V2_BASE_URL}/events/${eventId}/finish_editing`)
    .then((response) => {
      dispatch(finishEditingSuccess(response));
      return response.status;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const turnDownEvent = (eventId, eventParticipantId) => {
  return (dispatch) =>
    csrfApiCall.put(`/events/${eventId}/event_participants/${eventParticipantId}/reject_invite`).then((response) => {
      window.location.replace("/home");
    });
};

export const resendInvite = (eventParticipantId, payload) => {
  return (dispatch) =>
    apiCall
      .post(`${config.V2_BASE_URL}/event_participants/${eventParticipantId}/resend_invite_to_participant`, payload)
      .then((res) => res)
      .catch((error) => {
        dispatch(getError(error.response));
      });
};

export const changeEventTimeInterval = (eventId, payload) => {
  return (dispatch) =>
    apiCall
      .post(`${config.V2_BASE_URL}/events/${eventId}/change_event_time_interval`, payload)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        dispatch(getError(error.response));
      });
};
