import React from "react";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";

import { showMessageModal, markAsReadToMessage, bookmarkToMessage, removeBookmark } from "../../actions/messageActions";
import { connect } from "react-redux";
import { showDateTime } from "../advanced_lots/common";
import RenderHTML from "../common/RenderHTML";

export class MessageDetails extends React.Component {
  componentDidMount() {
    document.querySelectorAll(".ms-item-body a").forEach((element) => {
      element.setAttribute("target", "_blank");
    });
  }
  render() {
    const {
      message,
      messageType,
      isCurrentUserHost,
      classesForMessage,
      markAsReadMessage,
      markAsBookmark,
      translations,
      currentUserActingAsId,
      inSandpit,
      showMessagesOf,
      showAllMessages,
      showUnreadMessages,
      showBookmarkedMessages,
      simDojo,
      selectedSupplier,
      bookmarkMessage,
      timeZoneIdentifier,
    } = this.props;
    const outBoxMessage = message.user.id === currentUserActingAsId;
    const currentUserId = currentUserActingAsId;
    const isBookmark = bookmarkMessage.includes(message.id);
    let loggedUserRecipient = find(message.recipients, ["user_id", currentUserId]);
    loggedUserRecipient = loggedUserRecipient || {};

    let showMessage = true;
    if (selectedSupplier) {
      const isMessageForSelectedSupplier =
        !isEmpty(find(message.recipients, { user_id: selectedSupplier })) || message.user.id === selectedSupplier;
      if (showBookmarkedMessages) {
        showMessage = showAllMessages
          ? isMessageForSelectedSupplier
          : isMessageForSelectedSupplier && classesForMessage.indexOf("bookmarked-ms") !== -1;
      } else {
        showMessage = showAllMessages
          ? isMessageForSelectedSupplier
          : isMessageForSelectedSupplier && classesForMessage.indexOf("unread") !== -1;
      }
    } else if (showBookmarkedMessages) {
      showMessage =
        classesForMessage.indexOf(showMessagesOf) !== -1 && classesForMessage.indexOf("bookmarked-ms") !== -1;
    } else {
      showMessage = showAllMessages
        ? classesForMessage.indexOf(showMessagesOf) !== -1
        : classesForMessage.indexOf(showMessagesOf) !== -1 && classesForMessage.indexOf("unread") !== -1;
    }

    return (
      <div
        id={`message_${message.id}`}
        parent_id={message.parent_id}
        className={`message message-item ${classesForMessage} ${showMessage ? "" : "hidden"}`}
      >
        <div className="message-content_wrapper">
          <div className="ms-item-header clearfix">
            {/* <!-- Showing Message To: --> */}
            <span className="contact-list">
              <span className={`status-event small-box ${outBoxMessage ? "current" : "inbox"}`}>
                {outBoxMessage ? translations.outbox : translations.inbox}
              </span>
            </span>
            {/* We don't show To: to participants unless they have sent the message */}
            {(isCurrentUserHost || message.user.id === currentUserActingAsId) && (
              <React.Fragment>
                <span className="contact-list">
                  <span className="light-gray-color">{translations.messageTo}:</span>
                </span>
                <span className="small-gray-box">
                  {message.recipients.length > 1 ? (
                    <div className="btn-group btn-action">
                      <a
                        href="javascript:void(0)"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        aria-expanded="false"
                      >
                        <i className="fa fa-users" />
                      </a>
                      <ul className="dropdown-menu">
                        {message.recipients.map((recipient, idx) => (
                          <li
                            className="text-center"
                            title={`${translations.company}: ${recipient.company_name}; ${translations.email}: ${recipient.email}`}
                            key={idx}
                          >
                            <a>{recipient.user_display_name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <span
                      className="contact"
                      title={`${translations.company}: ${message.recipients[0].company_name}; ${translations.email}: ${message.recipients[0].email}`}
                    >
                      {message.recipients[0].user_display_name}
                    </span>
                  )}
                </span>
              </React.Fragment>
            )}
            {/* <!-- Showing Message From: --> */}
            <span className="contact-list">
              <span className="light-gray-color">{translations.messageFrom}:</span>
            </span>
            {outBoxMessage && !(inSandpit && !isCurrentUserHost) ? (
              <span className="small-gray-box">
                <span className="contact">{translations.me}</span>
              </span>
            ) : (
              <span className="small-gray-box">
                <span
                  className="contact"
                  title={`${translations.company}: ${message.user.user_company_name}; ${translations.email}: ${message.user.email}`}
                >
                  {message.user.name ? message.user.name : message.user.email}
                </span>
              </span>
            )}
            {messageType}
            {!outBoxMessage &&
              message.system_message_type !== "setting" &&
              !isEmpty(loggedUserRecipient) &&
              !loggedUserRecipient.message_read &&
              loggedUserRecipient.user_id === currentUserId && (
                <i className="fa fa-circle color-purpel" aria-hidden="true" />
              )}
            <div className="pull-right">
              <span className="p-15">
                <a
                  className={`fa fa-2x ${isBookmark ? "fa-star" : "fa-star-o"}`}
                  title={translations.bookmark}
                  onClick={() => markAsBookmark(message.id, isBookmark)}
                  id={`mark_as_bookmark_btn_${message.id}`}
                />
              </span>
              <span className="message-date light-gray-color">
                {outBoxMessage ? `${translations.messageSent}:` : `${translations.messageRecived}:`}{" "}
                {showDateTime(message.created_at, timeZoneIdentifier)}
              </span>
            </div>
          </div>
          <div className="ms-item-body">
            {message.system_message_type === "setting" && !simDojo ? (
              <p>
                <RenderHTML content={message.message} />
              </p>
            ) : (
              <RenderHTML content={message.message} />
            )}
            {message.documents.length > 0 &&
              message.documents.map((doc, idx) => (
                <a
                  key={idx}
                  className="display-block m-b20"
                  href={`/${simDojo ? "simdojo/" : ""}events/${doc.event_id}/documents/${doc.id}`}
                  target="_blank"
                >
                  <i className="fa fa-upload light-gray-color" aria-hidden="true" />
                  {"  " + doc.name}
                </a>
              ))}
            {(!outBoxMessage || message.bounced) && message.system_message_type !== "setting" && (
              <div className="row">
                <div className="col-md-12 btn-group-lg">
                  {!isEmpty(loggedUserRecipient) &&
                    !loggedUserRecipient.message_read &&
                    loggedUserRecipient.user_id === currentUserId && (
                      <button
                        type="button"
                        className="btn btn-blue-outline m-b20 m-r10"
                        onClick={() => markAsReadMessage(message.id)}
                        id={`mark_as_read_btn_${message.id}`}
                      >
                        {translations.messageMarkAsRead}
                      </button>
                    )}
                  {!message.bounced && (
                    <button
                      className="btn btn-blue-outline msg-send-reply"
                      onClick={() => this.props.showMessageModal(false, message.id, message.user.id, "MessageContainerSendMessageModal")}
                    >
                      {translations.postReply}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showMessageModal: (isNewMessage, messageReplyId, replyUserId, modalId) =>
    dispatch(showMessageModal(isNewMessage, messageReplyId, replyUserId, modalId)),
  markAsReadToMessage: (messageId, data) => dispatch(markAsReadToMessage(messageId, data)),
  bookmarkToMessage: (messageId) => dispatch(bookmarkToMessage(messageId)),
  removeBookmark: (messageId) => dispatch(removeBookmark(messageId)),
});

export default connect(null, mapDispatchToProps)(MessageDetails);
