import React, { useState } from "react";
import PropTypes from "prop-types";

import LotForm from "./LotForm";
import { Lot } from "@/common-prop-types";
import { t } from "@/i18n";

const LotModalOpenButton = ({ create, total, classFromEventState, lots, onClick }) => {
  if (create) {
    return (
      <button className={`btn status-event ${classFromEventState} btn-prominent shadow-diffuse`} onClick={onClick}>
        <i className="fa fa-plus-circle" />
        &nbsp; {t("new_lot")}
      </button>
    );
  }

  if (total && lots && lots.length > 0) {
    return (
      <div className="row">
        <div className="col-md-12 m-t10">
          <div className="event_total_lot">
            <div className="complex-box ui-sortable">
              <div className="complex-lot">
                <div className="complex-lot-header">
                  <div id="event_total_button_panel" className="complex-lot-panel">
                    <button
                      className={`btn btn-primary btn-prominent shadow-diffuse ${classFromEventState}`}
                      onClick={onClick}
                    >
                      <i className="fa fa-plus-circle"></i>
                      &nbsp;
                      {t("lots.create_event_total")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (lots && lots.length > 0) {
    return (
      <button className={`btn bg-white`} title={t("edit_lot")} onClick={onClick}>
        <i className="fa fa-pencil blue-color" />
      </button>
    );
  }

  return null;
};

LotModalOpenButton.propTypes = {
  /**
   * If this lot is an event total lot
   */
  total: PropTypes.bool,
  /**
   * If this is the create a new lot button
   */
  create: PropTypes.bool,
  /**
   * The class added to define the state on the event I.E. draft or current
   */
  classFromEventState: PropTypes.string,
  /**
   * The lots for the event
   */
  lots: PropTypes.arrayOf(PropTypes.shape(Lot)),
  /**
   * The callback function that will be called when the open modal button is
   * clicked
   */
  onClick: PropTypes.func.isRequired,
};

const LotModal = (props) => {
  const { translations, classFromEventState, lot, create, total, lots, allowedLotType, eventId } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const onClick = () => setModalIsOpen(true);

  return (
    <>
      <LotModalOpenButton {...{ create, total, lots, classFromEventState, onClick }} />
      {modalIsOpen && (
        <LotForm
          translations={translations}
          eventId={eventId}
          allowedLotType={allowedLotType}
          lot={lot}
          create={create}
          total={total}
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          partialBiddingSwitch={props.partialBiddingSwitch}
          anyCompletedBids={props.anyCompletedBids}
        />
      )}
    </>
  );
};

export default LotModal;
