import type { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "@/slices/util/createAppSlice";

export const eventClockSlice = createAppSlice({
  name: "eventClock",
  initialState: {
    updateEndDateUTC: null as string | null,
    secondsToStart: 0,
    closeDelay: 0,
  },
  reducers: {
    updateEndDateUTC: (
      state,
      {
        payload,
      }: PayloadAction<{ start_date: string; end_date: string; seconds_to_start: number; close_delay: number }>
    ) => {
      const now = new Date();
      const dateList = [new Date(payload.start_date), new Date(payload.end_date)];
      const date = dateList.filter((d) => d > now).sort((a, b) => (a > b ? 1 : -1))[0] || new Date(payload.end_date);

      state.updateEndDateUTC = date.toISOString();
      state.secondsToStart = payload.seconds_to_start;
      state.closeDelay = payload.close_delay * 1000; // Convert to milliseconds
    },
    setTimeEnd: (state) => {
      const in5Seconds = new Date().getTime() + 2000;
      const date = new Date(in5Seconds);

      state.updateEndDateUTC = date.toISOString();
    },
  },
});

export const { updateEndDateUTC, setTimeEnd } = eventClockSlice.actions;
