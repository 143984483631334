import { emptyApi } from "@/slices/api";
import type { TemplateIdentifier } from "@/slices/lots/templates";
import type { GetRulesAndFieldsResponse, UpdateRulesRequest } from "@/slices/rules/types";
import { makeUpdateRulesRequest, transformGetRulesAndFieldsResponse } from "@/slices/rules/types";

export const questionnaireTemplatesApi = emptyApi
  .enhanceEndpoints({ addTagTypes: ["QuestionnaireRules"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getQuestionnaireRulesAndFields: build.query<GetRulesAndFieldsResponse, TemplateIdentifier>({
        query: ({ companyId, templateId }) => `v2/companies/${companyId}/questionnaire_templates/${templateId}/rule`,
        transformResponse: transformGetRulesAndFieldsResponse,
        providesTags: (result, err, { templateId }) => (result ? [{ type: "QuestionnaireRules", id: templateId }] : []),
      }),
      updateQuestionnaireRules: build.mutation<void, UpdateRulesRequest>({
        query: ({ companyId, templateId, ruleId, rules }) => ({
          url: `v2/companies/${companyId}/questionnaire_templates/${templateId}/rule`,
          method: "PUT",
          body: makeUpdateRulesRequest({ companyId, templateId, ruleId, rules }),
        }),
        invalidatesTags: (result, err, { templateId }) => [{ type: "QuestionnaireRules", id: templateId }],
      }),
    }),
    overrideExisting: true,
  });

export const { useGetQuestionnaireRulesAndFieldsQuery, useUpdateQuestionnaireRulesMutation } =
  questionnaireTemplatesApi;
