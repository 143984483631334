import React, { memo, Suspense, lazy, useMemo, useCallback } from "react";
import { isEqual } from "lodash";
import {
  showCurrency,
  findLotTotalLic,
  fetchAssociatedCurrency,
  currentValue,
  qualificationValue,
  findLotComponent,
  showValue,
} from "../../../common";
import LineItemNameOrCurrentValue from "./LineItemNameOrCurrentValue";
import { bestLiTotalBlicsBeforeAuctionByLineItemId } from "../../../bidCommon";
const ParticipatingParticipants = lazy(() => import("./ParticipatingParticipants"));
const LineItemBidInformation = lazy(() => import("../lineItemBidInformation"));

const LineItemsInformation = memo((props) => {
  const {
    lotLi,
    index,
    isLotTotal,
    lotLis,
    lot,
    lotLics,
    currency,
    currenciesHash,
    lotLcs,
    isMultiCurrencyEvent,
    lotsActiveBids,
  } = props;
  const { number_format } = props.translations;
  // calculate total lc and lic
  const totalLicId = isLotTotal ? lot.lot_total_lic_id : lotLi.line_item_total_lic_id;
  const totalLic = useMemo(() => findLotTotalLic(lotLics, totalLicId) ?? {}, [totalLicId]);
  const totalLcId = totalLic.lot_component_id;
  const totalLc = useMemo(() => findLotComponent(lotLcs, totalLcId), [totalLicId]);
  const lineItemLics = useMemo(() => lotLics.filter((lic) => lic.line_item_id === lotLi.id), [lotLi]);
  const lineItemLic = useMemo(() => lineItemLics.find((lic) => lic.lot_component_id === totalLcId), [totalLcId]);

  const licCurrency = useMemo(
    () => (isMultiCurrencyEvent ? fetchAssociatedCurrency(currenciesHash, lot, lineItemLic, totalLc) : currency),
    [totalLcId, lineItemLic]
  );

  // calculate current and qualification price for LireItem
  const precision = totalLic?.decimal_place ?? 2;
  const price = useCallback(
    (amount) => showCurrency(amount, licCurrency, precision, props.locale, number_format),
    [lineItemLic, props.locale]
  );
  const currentValWithCurrency = useMemo(() => price(showValue(currentValue(lineItemLic))), [lineItemLic]);
  const qualificationValWithCurrency = useMemo(() => price(showValue(qualificationValue(lineItemLic))), [lineItemLic]);

  const bestAllBlics = useMemo(
    () =>
      bestLiTotalBlicsBeforeAuctionByLineItemId(
        props.event,
        props.participatingParticipants,
        props.bids,
        props.lots,
        props.lineItemComponents,
        props.bidLineItemComponents,
        props.auctionTab,
        lotLis
      ),
    [lotLis, props.bidLineItemComponents]
  );

  return (
    <div
      className={`row row-values lot_row_${lot.id} ${lotLis.length - 1 == index ? "line-item-row-last-child" : ""}`}
      key={`${index}-${Date.parse(lotLi.updated_at)}`}
    >
      <LineItemNameOrCurrentValue
        lineItemLics={lineItemLics}
        currency={licCurrency}
        isMultiCurrencyEvent={isMultiCurrencyEvent}
        lot={lot}
        li={lotLi}
        currentVal={currentValWithCurrency}
        qualificationVal={qualificationValWithCurrency}
        lotsActiveBids={lotsActiveBids}
      />
      <Suspense fallback={<></>}>
        <ParticipatingParticipants
          {...props}
          lotLi={lotLi}
          licCurrency={licCurrency}
          qualificationValue={qualificationValWithCurrency}
          currentValue={currentValWithCurrency}
          lineItemLic={lineItemLic}
          totalLc={totalLc}
        />
      </Suspense>
      <Suspense fallback={<></>}>
        <LineItemBidInformation
          {...props}
          precision={precision}
          licCurrency={licCurrency}
          lineItemLic={lineItemLic}
          lineItem={lotLi}
          lotComponent={totalLc}
          currentValue={currentValWithCurrency}
          qualificationValue={qualificationValWithCurrency}
          bestAllBlics={bestAllBlics}
        />
      </Suspense>
    </div>
  );
}, isEqual);

export default LineItemsInformation;
