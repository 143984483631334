import React from "react";
import PriceComponentCurrencyField from "./PriceComponentCurrencyField";
import { t } from "@/i18n";

interface CurrentPricePropType {
  precision: number;
  disabled?: boolean;
  onChange: (value: string | number) => void;
  currentPrice: number;
  currentVal: number;
  symbol: string;
}

const useTooltipText = () => {
  return {
    lotCurrentPriceTooltipText: {
      title: t("qq_tooltips.tooltips.host.lot.current_price.title", { ns: "tooltips" }),
      body: t("qq_tooltips.tooltips.host.lot.current_price.body", { ns: "tooltips" }),
    },
    lotCurrentValueTooltipText: {
      title: t("qq_tooltips.tooltips.host.lot.current_value.title", { ns: "tooltips" }),
      body: t("qq_tooltips.tooltips.host.lot.current_value.body", { ns: "tooltips" }),
    },
  };
};

export default function CurrentPrice(props: CurrentPricePropType) {
  const { onChange, precision, symbol, currentPrice, currentVal, disabled } = props;
  const { lotCurrentPriceTooltipText, lotCurrentValueTooltipText } = useTooltipText();

  return (
    <Wrapper>
      <PriceComponentCurrencyField
        {...{ precision, symbol, onChange, disabled }}
        label={`${t("current_price_uom")} ${t("not_shown_to_participants")}`}
        name="currentPrice"
        tooltipText={lotCurrentPriceTooltipText}
        value={currentPrice}
      />
      <PriceComponentCurrencyField
        {...{ precision, symbol, onChange, disabled: true }}
        label={t("total_current_value")}
        name="currentVal"
        tooltipText={lotCurrentValueTooltipText}
        value={currentVal}
        disabled
      />
    </Wrapper>
  );
}

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const [currentPrice, currentValue] = React.Children.toArray(children);
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <div className="col input">{currentPrice}</div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div className="col input">{currentValue}</div>
        </div>
      </div>
    </div>
  );
};
