import React, { Component } from "react";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";
import ContractListingDropDownFilter from "./ContractListingDropDownFilter";
import ContractListingDownload from "./ContractListingDownload";
import ContractFilter from "./ContractFilter";
import { emptyMessage } from "../../../actions/contract/contractListingActions";

const allOperations = {
  "=": (value, filterValue) => value == filterValue,
  ">": (value, filterValue) => value > filterValue,
  "<": (value, filterValue) => value < filterValue,
  ">=": (value, filterValue) => value >= filterValue,
  "<=": (value, filterValue) => value <= filterValue,
};

const numericCheck = (contract_value, custom_filter) => {
  const filter_value = custom_filter[2];
  const filter_opp = custom_filter[3]?.[0].text || "=";

  return allOperations[filter_opp](parseFloat(contract_value), parseFloat(filter_value));
};

const dateCheck = (contract_value, custom_filter) => {
  const filter_value = custom_filter[2];
  const filter_opp = custom_filter[3]?.[0].text || "=";

  return allOperations[filter_opp](contract_value, filter_value);
};

const equalCheck = (contract_value, custom_filter) => {
  return custom_filter[2].some((filterItem) => allOperations["="](contract_value, filterItem.id));
};

const stringCheck = (contract_value, custom_filter) => {
  return custom_filter[2].some((filterItem) => contract_value.includes(filterItem.id));
};

const TEXT_TYPE = 0;
const NUMERIC_TYPE = 1;
const DATE_TYPE = 2;
const BOOL_TYPE = 3;
const MULTI_TYPE_SINGLE = 4;
const MULTI_TYPE_MULTI = 5;

const columnTypeMap = {
  [NUMERIC_TYPE]: numericCheck,
  [TEXT_TYPE]: equalCheck,
  [DATE_TYPE]: dateCheck,
  [BOOL_TYPE]: equalCheck,
  [MULTI_TYPE_SINGLE]: equalCheck,
  [MULTI_TYPE_MULTI]: stringCheck,
};

class ContractListingMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: props.contracts,
      field: null,
      order: null,
      infor: null,
      error: null,
    };
  }

  static filterSearchResults = (searchedIds, contracts) => {
    return contracts.filter((contract) => searchedIds.includes(contract.id));
  };

  static applyCustomFilters(contracts, custom_columns_values, customFilters) {
    return contracts.filter((contract) => {
      return customFilters.some((customFilter) => {
        let custom_column_key = customFilter[0];
        let custom_column_type_cd = customFilter[1];

        const contract_value = custom_columns_values[contract.id]?.[custom_column_key];
        return contract_value ? columnTypeMap[custom_column_type_cd](contract_value, customFilter) : false;
      });
    });
  }

  static filterArchived = (archivedData, contracts) => {
    let index = contracts.findIndex((x) => x.id == archivedData.id);
    if (archivedData.newStatus == "deleted") {
      contracts.splice(index, 1);
    } else {
      if (contracts[index]) {
        contracts[index].current_state = archivedData.newStatus;
      }
    }
    return contracts;
  };

  static updateOwnerData = (nextProps, contracts) => {
    let editContracts = [];
    let filteredC = contracts.filter((contract) => {
      let returnVal = true;
      if (nextProps.data.ownerChangedData.payload.contract_ids.includes(contract.id)) {
        nextProps.data.ownerChangedData.updated_contracts.filter((value) => {
          if (nextProps.owner.includes(value.owner_email)) {
            contract.owner = value.owner;
            contract.owner_email = value.owner_email;
            contract.user_id = value.user_id;
            editContracts.push(contract.id);
          } else {
            returnVal = false;
          }
        });
      }
      return returnVal;
    });
    return { filteredC, editContracts };
  };

  static sortContracts = (sortData, prevState) => {
    let filteredC = [],
      field,
      order;
    filteredC =
      sortData.order == "desc"
        ? prevState.contracts.reverse()
        : orderBy(prevState.contracts, sortData.field, sortData.order);
    field = sortData.field;
    order = sortData.order;
    return { contracts: filteredC, field: field, order: order };
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { custom_columns_values } = nextProps;
    if (nextProps.data.uploadMessage) {
      const { error, info } = nextProps.data.uploadMessage;
      return { error, info };
    }
    if (nextProps.data.searchedIds) {
      prevState.contracts = ContractListingMain.filterSearchResults(nextProps.data.searchedIds, prevState.contracts);
    }
    if (nextProps.data.filteredContracts) {
      const { data, customFilter } = nextProps.data.filteredContracts;
      if (data && data.filtered) {
        prevState.contracts = prevState.contracts.filter((contract) =>
          data.selected_contract_ids.includes(contract.id)
        );
      }
      if (customFilter.length) {
        prevState.contracts = ContractListingMain.applyCustomFilters(
          nextProps.contracts,
          custom_columns_values,
          customFilter
        );
      }
    } else if (nextProps.data.archived) {
      // delete or archive the contracts
      prevState.contracts = ContractListingMain.filterArchived(nextProps.data.archived, prevState.contracts);
    } else if (nextProps.data.ownerChangedData) {
      const updatedOwnerData = ContractListingMain.updateOwnerData(nextProps, prevState.contracts);
      updatedOwnerData.editContracts.forEach((contractId) => {
        if (!nextProps.edit_contracts.includes(contractId)) {
          nextProps.edit_contracts.push(contractId);
        }
      });
      prevState.contracts = updatedOwnerData.filteredC;
    } else if (nextProps.data.sort) {
      //sort contracts
      prevState = ContractListingMain.sortContracts(nextProps.data.sort, prevState);
    } else if (nextProps.data.reset) {
      //for reset button
      prevState.contracts = nextProps.contracts;
    }
    return null;
  }

  removeMessage = (e) => {
    this.props.emptyMessage({ empty: true });
    if (e.target.name == "info") {
      this.setState({
        info: null,
      });
    } else {
      this.setState({
        error: null,
      });
    }
  };

  render() {
    const { translations, can_create_contract } = this.props;
    const { contracts, info, error } = this.state;
    let filtered_ids = contracts.map((contract) => {
      return contract.id.toString();
    });
    return (
      <div className="main-section index-list">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 filter-holder">
              <div className="row">
                <ContractListingDropDownFilter
                  translations={translations}
                  can_create_contract={can_create_contract}
                />
                <ContractListingDownload
                  translations={translations}
                  contracts={contracts}
                  totalCount={this.props.contracts.length}
                  filtered_ids={filtered_ids}
                />
              </div>
              {info && (
                <div className="row">
                  <div className="col-12">
                    <div id="flash_messages">
                      <div className="alert alert-fixed alert-info fade in">
                        <button
                          type="button"
                          className="close"
                          name="info"
                          data-dismiss="alert"
                          aria-hidden="true"
                          onClick={(e) => this.removeMessage(e)}
                        >
                          <i className="fa fa-times fa-xs"></i>
                        </button>
                        <p>{info}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {error && (
                <div className="row">
                  <div className="col-12">
                    <div id="flash_messages">
                      <div className="alert alert-fixed alert-danger fade in">
                        <button
                          type="button"
                          className="close"
                          name="error"
                          data-dismiss="alert"
                          aria-hidden="true"
                          onClick={(e) => this.removeMessage(e)}
                        >
                          <i className="fa fa-times fa-xs"></i>
                        </button>
                        <p>{error}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ContractFilter {...this.props} contracts={contracts} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.contractListingReducers,
});
const mapDispatchToProps = (dispatch) => ({
  emptyMessage: (data) => dispatch(emptyMessage(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContractListingMain);
