import { mergeRefs } from "@/lib/dom";
import clsx from "clsx";
import React, { forwardRef } from "react";
import type { DateTimePickerProps } from "react-flatpickr";
import Flatpickr from "react-flatpickr";

export const DatePicker = forwardRef<Flatpickr, DateTimePickerProps>((props, ref) => (
  <Flatpickr
    {...props}
    className={clsx("md-datepicker-input", props.className)}
    ref={mergeRefs((r) => {
      if (!props.type) {
        // we have to do this evilness because there are global styles for input[type="text"] that override *everything* (woo !important)
        // our Input component has no default type, so we remove it here for consistency
        r?.flatpickr.input.removeAttribute("type");
      }
    }, ref)}
  />
));

DatePicker.displayName = "DatePicker";
