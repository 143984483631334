import * as actionTypes from "../actions/actionsTypes";

const SimSettingReducers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SIM_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configuration_setting_values: action.payload,
      };
    }
    case actionTypes.ENFORCE_LOCALES: {
      return {
        ...state,
        enforce_locales: action.payload,
      };
    }

    case actionTypes.TAG_UI_SUCCESS: {
      return {
        ...state,
        tag_categories: [...action.payload],
      };
    }

    default:
      return state;
  }
};

export default SimSettingReducers;
