import React from "react";
import parse, { domToReact } from "html-react-parser";
import PropTypes from "prop-types";
import type { HTMLReactParserOptions } from "html-react-parser";
import { isTag, isComment, isDirective, isText } from "domhandler";
import { isAnyOf } from "@reduxjs/toolkit";

const isDOMNode = isAnyOf(isTag, isComment, isDirective, isText);

interface RenderHTMLProps {
  content: string;
  /**
   * It will be true by default but we can pass it explicitly while using this component.
   * If we don't want to add a <br /> tag to replace the new line then just pass it false.
   */
  replaceNewLines?: boolean;
}

const options: HTMLReactParserOptions = {
  replace(node) {
    if (isTag(node)) {
      if (node.name === "script") return <></>;
      if (!isValidHTMLTagName(node.name)) {
        return (
          <>
            <span>{`<${node.name}>`}</span>
            {domToReact(node.children.filter(isDOMNode), options)}
          </>
        );
      }
    }
  },
};

const RenderHTML: React.FC<RenderHTMLProps> = ({ content, replaceNewLines = true }) => {
  if (!content) {
    return null;
  }

  const htmlString = replaceNewLines ? content.replace(/\r?\n/g, "<br />") : content;
  return parse(htmlString, options);
};

RenderHTML.propTypes = {
  content: PropTypes.string.isRequired,
  replaceNewLines: PropTypes.bool,
};

export const isValidHTMLTagName = (tagName: string): boolean => {
  const validTagRegex = /^[a-zA-Z]([a-zA-Z0-9-]+)?$/;
  return validTagRegex.test(tagName);
};

export default RenderHTML;
