import React, { Component } from "react";
import Tooltip from "../../../common/Tooltip";
import { connect } from "react-redux";
import { lotAutomationEnforcedAndNotSandpit } from "../lotCommon";

class DescriptionFields extends Component {
  componentDidUpdate() {
    // Had to do it manually as autoFocus does not work as intended.
    document.getElementById("description").focus();
  }
  render() {
    const { translations, setShowDescription, setDescription, classFromEventState, showDescription } = this.props;
    const { lc, description, lAEnforcedAndNotSandpit } = this.props;

    const { lot_component_description, line_item_component_description } = translations.infos;

    const isButtonDisabled = lAEnforcedAndNotSandpit && !lc.host;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <div className="col input desc">
              {!showDescription ? (
                <div className="desc-link-with-icon">
                  <div className="desc-link-info">
                    <Tooltip title={lot_component_description.title} body={lot_component_description.body} />
                    <button
                      className={`desc-link status-event sm-butt ${classFromEventState} form-control`}
                      onClick={() => setShowDescription(true)}
                      disabled={isButtonDisabled}
                    >
                      {translations.line_item_description_add}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="desc-form">
                  <Tooltip title={line_item_component_description.title} body={line_item_component_description.body} />
                  <label htmlFor="description">{translations.description}</label>
                  <div className="desc-wrapper clearfix">
                    <input
                      autoFocus={true}
                      id="description"
                      name="description"
                      autoComplete="off"
                      className={`desc-input float-left form-control ${lc.description ? "no-desc-cancel" : ""}`}
                      style={{ width: "95%" }}
                      type="text"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      disabled={isButtonDisabled}
                    />
                    <button
                      name="showDescription"
                      type="button"
                      className="desc-cancel button float-right white small remove hover btn btn-default"
                      onClick={() => setShowDescription(false)}
                      disabled={isButtonDisabled}
                    >
                      <i className="fa fa-times" />
                    </button>
                    <div className="clear" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lAEnforcedAndNotSandpit: lotAutomationEnforcedAndNotSandpit(state),
});

export default connect(mapStateToProps, null)(DescriptionFields);
