import React, { useState } from "react";
import { bestBidName, bidUser, leadParticipantHtml, findBid, companyNameAndEmail } from "../../../bidCommon";
import LeadParticipantModal from "../modal/LeadParticipantModal";
import { isLotTotalPresent, isLiTotalPresent } from "../../../common";

/**
 * @description Participant Lead Participant for Lot
 * Monitor Tab
 *
 * Column - Lead Participant
 */
export default function LeadParticipant(props) {
  const {
    event,
    participants,
    eventParticipants,
    unitOfMeasures = [],
    unitSetUoms = [],
    bestBidsBeforeAuction,
    lineItemBestBlics,
    removeBid,
    resetState,
    lot,
    bids = [],
    isLineItem,
    lineItem = {},
  } = props;
  const [state, setState] = useState({});
  const isLotTotal = isLotTotalPresent(lot);
  const isLineItemTotal = isLiTotalPresent(lineItem);

  let bestBids = bestBidsBeforeAuction;
  if (!isLotTotal || isLineItem) {
    bestBids = [];
    if (lineItemBestBlics) {
      const bestBid = lineItemBestBlics[0] && findBid(bids, lineItemBestBlics[0]?.bid_id);
      bestBids = [bestBid];
    }
  }

  const { Tied } = props.translations;
  const allUom = [...unitSetUoms, ...unitOfMeasures];
  const bestBidBeforeAuction = bestBids?.filter((bid) => !["", undefined].includes(bid?.price));
  const bestBidUserName = bestBidName(bestBidBeforeAuction, participants, event, Tied);
  const user = !!bestBids?.length && bidUser(bestBids[0], participants);
  const participant = user && eventParticipants?.find((ep) => ep.user_id === user.id);
  const leadParticipantName = leadParticipantHtml(bestBidBeforeAuction[0], bestBidUserName, event, Tied, true, true);

  const participantName = React.Children.toArray(leadParticipantName?.props?.children)[0] ?? leadParticipantName;
  const participantTitle = ["-", Tied].includes(participantName) ? participantName : companyNameAndEmail(user);

  const { preUser, preParticipant, preLeadParticipantName } = state;

  const handleClick = () => {
    if (!(preUser && preParticipant && preLeadParticipantName)) {
      setState({
        preUser: preUser || user,
        preParticipant: preParticipant || participant,
        preLeadParticipantName: leadParticipantName,
      });
    }
  };
  /**
   * show text without link only if it's line item and
   * line_item_total is not present in li
   * if not then show text with link to open participant modal
   */
  const isShowingLink = (!!isLineItem && (!isLotTotal || isLineItemTotal)) || (!isLineItem && isLotTotal);
  const showTitle = bestBidUserName !== "-" && bestBidUserName !== Tied;

  return (
    <>
      <div onClick={handleClick} className="mycell">
        <span className="mycell mycell-word-wrap" title={participantTitle}>
          {
            <>
              {(!showTitle || !isShowingLink) && participantName}
              {isShowingLink && (
                <LeadParticipantModal
                  {...props}
                  showViewComponent={showTitle}
                  viewComponent={leadParticipantName}
                  viewComponentTitle={participantTitle}
                  header={preLeadParticipantName || leadParticipantName}
                  user={preUser || user}
                  participant={preParticipant || participant}
                  deleteBid={removeBid}
                  modalCloseFunction={resetState}
                  allUom={allUom}
                />
              )}
            </>
          }
        </span>
      </div>
    </>
  );
}
