import React, { Component } from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import Modal from "simple-react-modal";
import Loader from "react-loader-advanced";
import ReactTimeout from "react-timeout";
import cookies from "browser-cookies";

import { bidsSummary, fetchBids } from "../../../../actions/qq/messagesActions";
import formatData from "../../js/languageFormatter";
import LoaderMessage from "../../LoaderMessage";
import { backgroundOnModalOpen } from "../../js/common";
import { deleteBid, allowResub } from "../../../../actions/qq/editActions";
import { showToasterMessages } from "../../../../actions/qq/genericActions";
import { getAwardedLots } from "../../../../actions/qq/emarketplace/emSummaryActions";
import { confirm } from "../../util/confirm";

export class ParticipantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModel: false,
      lot: {},
      bid: {},
      showLoader: true,
      bidsSummary: {}
    };
  }

  async UNSAFE_componentWillMount() {
    const { quoteId } = this.props;
    if (quoteId) {
      await this.props.bidsSummary(quoteId);
    }
    this.props.setTimeout(() => {
      this.setState({
        showLoader: false
      });
    }, 2000);

    this.props.socketObject.on("bid", async data => {
      const summaryData = this.state.bidsSummary;
      const participantIndex = await summaryData.event_participants.findIndex(
        participant => participant.user_id === data.user_id
      );
      await summaryData.lots.forEach(item => {
        if (item.lot.id === data.lot_id) {
          item.bids[participantIndex] = data;
        }
      });
      this.setState(
        {
          bidsSummary: summaryData
        },
        () => {
          $(`.bid_${data.id}`)
            .stop()
            .animate({ backgroundColor: "#e8e86f" }, "slow");
          this.props.setTimeout(() => {
            $(`.bid_${data.id}`)
              .stop()
              .animate({ backgroundColor: "" }, "slow");
          }, 1000);
        }
      );
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.summary.bids_summary) {
      this.setState({
        bidsSummary: newProps.summary.bids_summary
      });
    }
  }

  onboardingStatusText(status) {
    switch (status) {
      case "not_onboarded":
        return this.props.translations.summary_status.onboarding_status.not_onboarded;
      case "expiring":
        return (this.props.translations.summary_status.onboarding_status.expiring, {
          DATE: status[1]
        });
      case "expired":
        return this.props.translations.summary_status.onboarding_status.expired;
      case "rejected":
        return this.props.translations.summary_status.onboarding_status.rejected;
    }
  }

  getParticipantStatus(status) {
    switch (status) {
      case "Accepted Invite":
        return (
          <div className="status-txt green-text">
            <span className="fa fa-check" aria-hidden="true" />{" "}
            {this.props.translations.emarketplace.summary_status.accepted}
          </div>
        );
        break;
      case "Not Accepted Invite":
        return (
          <div className="status-txt orange-text">
            <span className="fa fa-history" aria-hidden="true" />{" "}
            {this.props.translations.emarketplace.summary_status.not_accepted}
          </div>
        );
        break;
      case "Declined Event":
        return (
          <div className="status-txt red-text">
            <span className="fa fa-times" aria-hidden="true" />{" "}
            {this.props.translations.emarketplace.summary_status.declined}
          </div>
        );
        break;
      default:
        return (
          <div className="status-txt red-text">
            <span className="fa fa-times" aria-hidden="true" />{" "}
            {this.props.translations.emarketplace.summary_status.not_registered}
          </div>
        );
    }
  }

  getOnboardingStatus(status) {
    const onboardingStatus = this.onboardingStatusText(status[0]);
    if (onboardingStatus === undefined || status === "onboard") {
      return "";
    }

    return (
      <div className="status-txt red-text">
        <span className="fa fa-exclamation-triangle" aria-hidden="true" />
        {onboardingStatus}
      </div>
    );
  }

  getTableClasses(participantsLength) {
    let allClasses = "table-scroll table-scroll-auto ";
    if (participantsLength === 1) {
      allClasses += "tbl-participant-one resize-table-clz";
    } else if (participantsLength === 2) {
      allClasses += "tbl-participant-two resize-table-clz";
    } else if (participantsLength === 3) {
      allClasses += "tbl-participant-three resize-table-clz";
    } else if (participantsLength === 4) {
      allClasses += "tbl-participant-four resize-table-clz";
    } else if (participantsLength >= 5) {
      allClasses += "tbl-participant-all";
    }
    return allClasses;
  }

  participantsDocs(participantDocs, i, participants) {
    return participants.map((user, first) =>
      participantDocs[first] ? (
        <td key={first}>
          {participantDocs[first][i] ? (
            <a
              href={participantDocs[first][i].url}
              title={participantDocs[first][i].name}
              className="download-document"
            >
              {participantDocs[first][i].name}
            </a>
          ) : (
            "-"
          )}
        </td>
      ) : (
        <td key={first}>
          <span>-</span>
        </td>
      )
    );
  }

  listData(obj) {
    return obj.content && typeof obj.content === "object" ? (
      <td key={obj.id}>
        {obj.content.map((val, i) => (
          <span key={val.id}>
            <a
              href={val.url}
              title={val.name}
              target="_blank"
              className="download-document"
            >
              {val.name}
            </a>
            <br />
          </span>
        ))}
      </td>
    ) : (
      <td key={obj.id}>
        <span title={obj.content}>{obj.content ? obj.content : "-"}</span>
      </td>
    );
  }

  createList(object, index, participants) {
    return (
      <tr className="question-row" key={object.question.id}>
        <td>
          <span title={object.question.title}>
            Question {index + 1}: {object.question.title}
          </span>
        </td>
        {participants.map((idx, i) =>
          object.answers[i] ? (
            this.listData(object.answers[i])
          ) : (
            <td key={index + i}>
              <span>-</span>
            </td>
          )
        )}
      </tr>
    );
  }

  openPopup(e, lot, bid) {
    backgroundOnModalOpen(true);
    e.preventDefault();
    this.setState(
      {
        lot,
        bid
      },
      () => {
        this.setState({ showModel: true });
      }
    );
  }

  close() {
    backgroundOnModalOpen(false);
    this.setState({ showModel: false });
  }

  async deleteBid(eventId, bidId) {
    const refThis = this;
    let pop_up_translations = {
      ConfirmLabel: this.props.translations.pop_up.confirm,
      okLabel: this.props.translations.pop_up.ok,
      cancelLabel: this.props.translations.pop_up.cancel,
    };
    confirm(this.props.translations.pop_up.confirm_popup_delete, pop_up_translations).then(
      async () => {
        refThis.refs[`${bidId}Spinner`].classList.remove("hidden");
        const response = await refThis.props.deleteBid(bidId);
        if (response.status === 200) {
          refThis.props.showToasterMessages(
            "SHOW_SUCCESS", this.props.translations.summary_status.participant_list.delete_bid_success);
          await this.props.getAwardedLots(eventId);
          await this.props.fetchBids(eventId);
        }
        await this.props.bidsSummary(eventId);
        this.close();
      },
      () => {
        // `cancel` callback
      }
    );
  }

  waitingForResubmission = (e, answerId, quoteId) => {
    let resubmit_text = this.props.translations.summary_status.waiting_resubmit;
    $("<p><span class='red-text'>"+resubmit_text+"</span></p>").insertAfter(e.target);
    e.target.remove();
    this.props.allowResub(answerId, quoteId);
  };

  render() {
    const { translations, user } = this.props;
    const data = this.state.bidsSummary;
    const lotList = data ? data.lots : [];
    const questionAnswers = data ? data.questions : [];
    const documents = data ? data.documents : [];

    const participantRows = _.map(questionAnswers, (obj, idx) =>
      this.createList(obj, idx, data.event_participants)
    );
    const sandpit = cookies.get("sandpit");
    let quoteDetail = this.props.quoteDetail
    return (
      <Loader
        show={this.state.showLoader}
        message={<LoaderMessage loaderText={translations.loader_text} />}
        disableDefaultStyles
        priority={1}
      >
        <div className="panel panel-default">
          <div className="panel-heading" role="tab" id="headingTwo">
            <h4 className="panel-title">
              <a
                className="collapsed"
                role="button"
                data-toggle="collapse"
                href="#collapseTwo"
              >
                {translations.summary_status.participant_list.participant_list}
              </a>
            </h4>
          </div>
          <div id="collapseTwo" className="panel-collapse collapse">
            <div className="panel-body">
              {!_.isEmpty(data) ? (
                <div
                  className={this.getTableClasses(
                    data.event_participants.length
                  )}
                >
                  <table
                    className={
                      data.event_participants.length === 0
                        ? "participant-table common-table single-col"
                        : "participant-table common-table"
                    }
                  >
                    {data.event_participants.length > 0 && (
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          {data.event_participants.map((participant, index) => (
                            <th key={participant.id}>
                              {participant.display_name
                                ? participant.display_name
                                : participant.email}
                              {this.getParticipantStatus(
                                participant.participating_status
                              )}
                              {sandpit !== "true" &&
                                this.getOnboardingStatus(
                                  participant.onboarding_status
                                )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      <tr className="table-section-head">
                        <td>
                          <h5>
                            {translations.summary_status.participant_list.lots}
                          </h5>
                        </td>
                        {data.event_participants.length !== 0 && (
                          <td colSpan={data.event_participants.length}>
                            &nbsp;
                          </td>
                        )}
                      </tr>
                      {lotList.map((lot, index) => (
                        <tr key={lot.lot.id}>
                          <td>
                            <span title={lot.lot.name}>{lot.lot.name}</span>
                          </td>
                          {data.event_participants.map((participant, idx) =>
                            lot.bids[idx] &&
                            participant.status !== "Declined" ? (
                              <td key={lot.bids[idx].id}>
                                {lot.bids[idx].rejected_lot ? (
                                  <span className="red-text">
                                    {translations.emarketplace.summary_status.lot_rejected}
                                  </span>
                                ) : (
                                  <a
                                    href="#"
                                    className={`bid_${lot.bids[idx].id}`}
                                    onClick={e =>
                                      this.openPopup(e, lot.lot, lot.bids[idx])
                                    }
                                  >
                                    {formatData(
                                      user.locale,
                                      lot.bids[idx].currency.symbol,
                                      _.round(
                                        lot.bids[idx].host_price *
                                          lot.lot.quantity,
                                        2
                                      )
                                    )}
                                  </a>
                                )}
                              </td>
                            ) : (
                              <td key={idx}>
                                <span>-</span>
                              </td>
                            )
                          )}
                        </tr>
                      ))}
                      <tr className="table-section-head">
                        <td>
                          <h5>
                            {translations.summary_status.participant_list.questions}
                          </h5>
                        </td>
                      </tr>
                      {participantRows}
                      {data.event_participants.length !== 0 &&
                        quoteDetail.data.current_state !== 'completed' &&
                        quoteDetail.data.current_user_has_edit_permission &&
                        quoteDetail.data.current_state !== 'closed' && (
                        <tr className="question-row">
                          <td></td>
                          {data.event_participants.map((participant, index) => {
                              let answer = participant.questionnaire_answers[0]
                              return (
                                <td key={index}>
                                  {answer && !answer.waiting_for_resub ? (
                                    <button
                                      className="btn btn-sm btn-primary pull-left"
                                      onClick={e => {
                                        this.waitingForResubmission(
                                          e,
                                          answer.id,
                                          participant.event_id
                                        );
                                      }}
                                    >
                                      {translations.summary_status.allow_resubmit}
                                    </button>
                                  ) : (answer && answer.waiting_for_resub
                                      ? <p><span className="red-text">
                                          {translations.summary_status.waiting_resubmit}
                                        </span></p>
                                      : null
                                  )}
                                </td>
                            )})}
                        </tr>
                      )}
                      <tr className="table-section-head">
                        <td>
                          <h5>
                            {translations.summary_status.participant_list.documents}
                          </h5>
                        </td>
                        {data.event_participants.length !== 0 && (
                          <td colSpan={data.event_participants.length}>
                            &nbsp;
                          </td>
                        )}
                      </tr>
                      {_.times(documents.max_documents_size, i =>
                        documents.host[i] ? (
                          <tr key={documents.host[i].id}>
                            <td>
                              <a
                                href={`${documents.host[i].url}?in_sandpit=${sandpit}`}
                                title={documents.host[i].name}
                                className="download-document"
                                target="_blank"
                              >
                                {documents.host[i].name}
                              </a>
                            </td>
                            {this.participantsDocs(
                              documents.participants,
                              i,
                              data.event_participants
                            )}
                          </tr>
                        ) : (
                          <tr key={i}>
                            <td>
                              <span>-</span>
                            </td>
                            {this.participantsDocs(
                              documents.participants,
                              i,
                              data.event_participants
                            )}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  {translations.emarketplace.summary_status.no_participants}
                </div>
              )}
            </div>
          </div>
          <div className="main-popup popup-md">
            <Modal
              show={this.state.showModel}
              onClose={() => this.close()}
              closeOnOuterClick
            >
              <div className="vertical-top">
                <div className="vertical-align-center">
                  <div className="modal-content clearfix">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {translations.summary_status.current_lot}:{" "}
                        {this.state.lot.name}
                      </h5>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          this.close();
                        }}
                        className="close-icon modal-btn-close"
                      >
                        <i className="fa fa-times" aria-hidden="true" />
                      </a>
                    </div>
                    <div className="modal-body">
                      <table className="table table-bordered bid-detail-table ">
                        <tbody>
                          <tr className="active">
                            <td>
                              {translations.summary_status.unit_of_measure}
                            </td>
                            <td>
                              <strong>{this.state.lot.unit_of_measure}</strong>
                            </td>
                          </tr>
                          <tr className="active">
                            <td>
                              {translations.summary_status.quantity_of_uom}
                            </td>
                            <td>
                              <strong>
                                {formatData(
                                  user.locale,
                                  "",
                                  this.state.lot.quantity
                                )}
                              </strong>
                            </td>
                          </tr>
                          <tr className="active">
                            <td>
                              {translations.summary_status.participant_bid}
                            </td>
                            <td>
                              <strong>
                                {formatData(
                                  user.locale,
                                  this.state.bid.currency
                                    ? this.state.bid.currency.symbol
                                    : "",
                                  _.round(this.state.bid.host_price, 2)
                                )}
                              </strong>
                            </td>
                          </tr>
                          <tr className="active">
                            <td>{translations.emarketplace.bid_value}</td>
                            <td>
                              <strong>
                                {formatData(
                                  user.locale,
                                  this.state.bid.currency
                                    ? this.state.bid.currency.symbol
                                    : "",
                                  _.round(
                                    this.state.bid.host_price *
                                      this.state.lot.quantity,
                                    2
                                  )
                                )}
                              </strong>
                            </td>
                          </tr>
                          {this.props.userHasEditPermission && (
                            <tr className="active">
                              <td>
                                {translations.summary_status.participant_list.delete_bid}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="icon-link"
                                  onClick={e => {
                                    this.deleteBid(
                                      this.state.bid.event_id,
                                      this.state.bid.id
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-trash-o"
                                    aria-hidden="true"
                                  />
                                </button>
                                <i
                                  ref={`${this.state.bid.id}Spinner`}
                                  className="fa fa-spinner fa-spin text-danger hidden"
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary modal-btn-close"
                        onClick={() => this.close()}
                      >
                        {translations.pop_up.ok}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // selectedLang: state.changeLanguageReducer,
  summary: state.qqmessagesReducer,
  quoteDetail: state.qqReducer
});

const mapDispatchToProps = dispatch => ({
  fetchQuoteById: quoteId => dispatch(fetchQuoteById(quoteId)),
  allowResub: (answerId, quoteId) => dispatch(allowResub(answerId, quoteId)),
  bidsSummary: quoteId => dispatch(bidsSummary(quoteId)),
  deleteBid: bidId => dispatch(deleteBid(bidId)),
  showToasterMessages: (type, message) => dispatch(showToasterMessages(type, message)),
  getAwardedLots: quoteId => dispatch(getAwardedLots(quoteId)),
  fetchBids: quoteId => dispatch(fetchBids(quoteId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReactTimeout(ParticipantList));
