import React from "react";
const OutSideRangeBidMessage = (props) => {
  return props.isAuction && !props.isInRange && props.latestBid?.price ? (
    <div className="tbl-head-toprght">
      <span className="bid-range-wrapper pull-right">
        <p className="instructions">{props.message}</p>
      </span>
    </div>
  ) : null;
};

export default OutSideRangeBidMessage;
