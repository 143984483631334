import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-advanced';

import EditorPopup from './EditorPopup';
import { fetchAdminText } from '../../../../actions/qq/adminTextActions';
import LoaderMessage from '../../LoaderMessage';
import RenderHTML from "@/components/common/RenderHTML";

class QQHeaderTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminText: '',
      showLoader: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAdminText(this.props.companyId, this.props.sectionKey);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText.FETCH_ADMIN_TEXT_HEADER ? newProps.adminText.FETCH_ADMIN_TEXT_HEADER : '';
    if (newProps.default.FETCH_ADMIN_TEXT_HEADER_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_HEADER_DEL;
    }
    if (adminText) {
      this.setState({
        adminText,
        showLoader: false,
      });
    }
  }
  
  render() {
    const { sectionKey, companyId, translations } = this.props;
    return (
      <Loader show={ this.state.showLoader } message={ <LoaderMessage loaderText={translations.loader_text} /> } disableDefaultStyles>
        <div className='request-quote'>
          <EditorPopup
            text={ this.state.adminText }
            companyId={ companyId }
            sectionKey={ sectionKey }
            translations={ translations }
            updateText={ (text) => {
                  this.setState({ adminText: text });
                } }
          />
          <RenderHTML content={this.state.adminText} />
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  adminText: state.adminTextReducer,
  default: state.deleteAdminTextReducer,
  selectedLang: state.changeLanguageReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchAdminText: (companyId, sectionKey) => dispatch(fetchAdminText(companyId, sectionKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QQHeaderTitle);
