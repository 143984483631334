import * as actionTypes from "../actions/actionsTypes";

const uomReducers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_UOM_SUCCESS:
      return {
        ...state,
        uoms: action.payload.unit_set_unit_of_measures,
        unitOfMeasure: action.payload.unit_of_measure,
        actionType: action.type,
      };
    default:
      return state;
  }
};

export default uomReducers;
