import React from "react";
import { t } from "@/i18n";
import PriceComponentCurrencyField from "./PriceComponentCurrencyField";

interface UsedPricePropType {
  precision: number;
  disabled?: boolean;
  onChange: (value: string | number) => void;
  usedPrice: number;
  usedVal: number;
  symbol: string;
}

const useTooltipText = () => {
  return {
    lotUsedPriceTooltipText: {
      title: t("qq_tooltips.tooltips.host.lot.used_price.title", { ns: "tooltips" }),
      body: t("qq_tooltips.tooltips.host.lot.used_price.body", { ns: "tooltips" }),
    },
    lotTotalUsedValueTooltipText: {
      title: t("qq_tooltips.tooltips.host.lot.total_used_value.title", { ns: "tooltips" }),
      body: t("qq_tooltips.tooltips.host.lot.total_used_value.body", { ns: "tooltips" }),
    },
  };
};

export default function UsedPrice(props: UsedPricePropType) {
  const { precision, disabled, onChange, usedPrice, usedVal, symbol } = props;
  const { lotUsedPriceTooltipText, lotTotalUsedValueTooltipText } = useTooltipText();

  return (
    <Wrapper>
      <PriceComponentCurrencyField
        {...{ symbol, onChange, precision, disabled }}
        label={t("used_price_uom")}
        tooltipText={lotUsedPriceTooltipText}
        value={usedPrice}
        name="usedPrice"
      />
      <PriceComponentCurrencyField
        {...{ symbol, onChange, precision, disabled: true }}
        label={t("total_used_value")}
        tooltipText={lotTotalUsedValueTooltipText}
        value={usedVal}
        name="usedVal"
      />
    </Wrapper>
  );
}

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const [usedPrice, usedVal] = React.Children.toArray(children);
  return (
    <>
      <div className="col-md-6">
        <div>{usedPrice}</div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-12">{usedVal}</div>
        </div>
      </div>
    </>
  );
};
