import PropTypes from "prop-types";

export const Lot = {
  id: PropTypes.number,
  name: PropTypes.string,
  awarded_participant_id: PropTypes.number,
  complex_structure: PropTypes.bool,
  is_event_total: PropTypes.bool,
  exchange_rate_id: PropTypes.number,
  is_award_notify: PropTypes.bool,
  lot_total_lic_id: PropTypes.number,
  position: PropTypes.number,
  event_id: PropTypes.number,
  updated_at: PropTypes.string,
  quantity: PropTypes.number,
  current_price: PropTypes.number,
  qualification_price: PropTypes.number,
  unit_of_measure_id: PropTypes.number,
  permit_partial_bids: PropTypes.bool,
};

/** @typedef {PropTypes.InferProps<typeof Lot>} Lot */

export default Lot;
