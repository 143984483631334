import React, { useState } from "react";
import { hasAnyCompletedBids } from "../../common";
import { isBeforeAuction } from "../../eventCommon";
import ParticipantProgressionChartModal from "./ParticipantProgressionGraph";
import { ChartIcon } from "../host_rfq_screen/lotInformationForOverview/ProgressionCharts";
const ShowParticipantChart = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const openCharts = () => setIsOpen(true);

  return !props.isRejectedLot &&
    !props.isProxyBid &&
    !isBeforeAuction(props.event) &&
    props.auctionTab &&
    props.LBids.length > 0 &&
    hasAnyCompletedBids(props.LBids) &&
    !props.isLotLevelMonitor ? (
    <>
      <span className="button-wrap solid-box chart-box">
        <ChartIcon openCharts={openCharts} />
      </span>
      <ParticipantProgressionChartModal {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  ) : null;
};

export default ShowParticipantChart;
