import React, { Component } from 'react';
import { reduxForm, FormSection } from 'redux-form';
import { connect } from 'react-redux';
import { Provider as AlertContainer } from 'react-alert';
import cookies from 'browser-cookies';
import AlertTemplate from 'react-alert-template-oldschool-dark';

import QQHeaderTitle from './QQHeaderTitle';
import validate from './validate';
import QQDocuments from './QQDocuments';
import QQGeneralDetails from './QQGeneralDetails';
import QQParticipant from './QQParticipant';
import QQPrice from './QQPrice';
import QQQuestions from './QQQuestions';
import { fetchConfigurationLimit } from '../../../../actions/qq/qqActions';
import { fetchAdminText } from '../../../../actions/qq/adminTextActions';
import { alertOptions } from '../../js/common';

class QQForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qqFormRestValue: {},
    };

    this.onTextValue = this.onTextValue.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { company } = this.props.user;
    if (company.id) {
      this.props.fetchConfigurationLimit(company.id);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.state.lang !== newProps.selectedLang) {
      this.updateAdminText();
      this.setState({
        lang: newProps.selectedLang,
      });
    }
  }

  onTextValue(key, value) {
    const tempFormRest = this.state.qqFormRestValue;
    tempFormRest[key] = value;
    this.setState({ qqFormRestValue: tempFormRest });
  }

  onFormSubmit(e, isQuoteDisabled) {
    e.preventDefault();
    if (isQuoteDisabled) {
      this.msg.removeAll();
      this.msg.error(this.props.translations.error_message.required_field_alert);
    } else {
      this.props.handleSubmit();
      this.props.getRestField(this.state.qqFormRestValue);
    }
  }

  updateAdminText() {
    const sectionKeyArray = ['qq_header_block', 'qq_attributes_block', 'qq_documents_block', 'qq_prices_block', 'qq_questions_block', 'qq_participants_block'];
    if (this.props.user) {
      sectionKeyArray.forEach((key) => {
        this.props.fetchAdminText(this.props.user.company.id, key);
      });
    }
  }

  render() {
    const sandpit = cookies.get('sandpit') === 'true';
    const { handleSubmit, pristine, formSubmitting, invalid, user, translations, tooltip_translations } = this.props;

    const { company } = this.props.user;
    let { limits } = this.props.configurations;
    limits = limits || {};

    const { btnArray } = this.state;
    const isQuoteDisabled = (invalid || formSubmitting || (sandpit ? false : !user.quick_quotes_licence));
    const disableAttr = { disabled: isQuoteDisabled };
    return (
      <div>
        {!sandpit && !user.quick_quotes_licence && (
          <div className="alert alert-warning">{translations.qq_create.qq_form.licence_alert}</div>
        )}
        <div className="m-b50">
          <form onSubmit={(e) => this.onFormSubmit(e, isQuoteDisabled)}>
            <FormSection name="qqHeaderTitle">
              {/* Header title component */}
              <QQHeaderTitle
                companyId={company.id}
                sectionKey="qq_header_block"
                translations={translations}
                tooltip_translations={tooltip_translations}
              />
            </FormSection>
            <FormSection name="QQGeneralDetails">
              {/* event general details component */}
              <QQGeneralDetails
                user={user}
                onTextValue={this.onTextValue}
                companyId={company.id}
                sectionKey="qq_attributes_block"
                translations={translations}
                tooltip_translations={tooltip_translations}
              />
            </FormSection>
            <FormSection name="qqDocuments">
              {/* event docment component */}
              <QQDocuments
                onTextValue={this.onTextValue}
                companyId={company.id}
                sectionKey="qq_documents_block"
                docsLimit={limits.qq_documents_limit}
                limits={limits}
                formSubmitting={formSubmitting}
                translations={translations}
                tooltip_translations={tooltip_translations}
              />
            </FormSection>
            <FormSection name="qqPrice">
              {/* event price component */}
              <QQPrice
                onTextValue={this.onTextValue}
                companyId={company.id}
                userId={user.id}
                currency_id={user.currency_id}
                sectionKey="qq_prices_block"
                lotsLimit={limits.qq_lots_limit}
                translations={translations}
                tooltip_translations={tooltip_translations}
              />
            </FormSection>
            <FormSection name="qqQuestions">
              {/* event questions component */}
              <QQQuestions
                onTextValue={this.onTextValue}
                companyId={company.id}
                sectionKey="qq_questions_block"
                questionsLimit={limits.qq_questions_limit}
                translations={translations}
                tooltip_translations={tooltip_translations}
              />
            </FormSection>
            <FormSection name="qqParticipant">
              {/* event participant component */}
              <QQParticipant
                onTextValue={this.onTextValue}
                companyId={company.id}
                userId={user.id}
                sectionKey="qq_participants_block"
                participantsLimit={limits.qq_participants_limit}
                translations={translations}
                tooltip_translations={tooltip_translations}
              />
            </FormSection>
            <div className="col-md-12 button-section p-0 text-center">
              <button className="btn btn-lg btn-primary m-t20 btn-sizebig" type="submit" {...disableAttr}>
                {translations.qq_create.qq_form.get_quote}
                {formSubmitting ? <i className="fa fa-cog fa-spin fa-fw" /> : ""}
              </button>
            </div>
          </form>
          <AlertContainer
            template={AlertTemplate}
            _ref={(alert) => {
              this.msg = alert;
            }}
            {...alertOptions}
          />
        </div>
      </div>
    );
  }
}

const qqForm = reduxForm({
  form: 'QQForm',
  validate,
  shouldValidate(){
    return true;
  },
  enableReinitialize: true,
})(QQForm);

const mapStateToProps = (state, ownProps) => ({
  selectedLang: state.changeLanguageReducer,
  configurations: state.qqReducer,
  initialValues: localStorage.getItem('formValue') ? JSON.parse(localStorage.getItem('formValue')) : {},
});

const mapDispatchToProps = dispatch => ({
  fetchConfigurationLimit: companyId => dispatch(fetchConfigurationLimit(companyId)),
  fetchAdminText: (companyId, sectionKey) => dispatch(fetchAdminText(companyId, sectionKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(qqForm);
