import React, { useCallback, useMemo } from "react";
import { Comparison, type DateParameter, type Leaf, type NormalizedNode } from "@/slices/rules/types";
import { useAppDispatch } from "@/hooks/redux";
import { useDebouncedSync } from "@/hooks/use-debounced-sync";
import { comparisonChanged, dateChanged } from "@/slices/rules";
import { assert } from "@/utils/assert";
import { inEnum } from "@/utils/inEnum";
import { Select } from "@/cl/select";
import styles from "../node.module.scss";
import { t } from "@/i18n";
import { format, intlFormat, parse } from "date-fns";
import { DatePicker } from "@/cl/input/date";
import { Collapsible, InputContainer } from "../leaf-node";
import { dbDateFormat } from "@/lib/rules/validate";
import { useParentCollapsed } from "../collapse-context";

function useInputs(node: NormalizedNode<Leaf<DateParameter>>) {
  const dispatch = useAppDispatch();
  const [date, handleDateChange] = useDebouncedSync(
    node.state.leaf_parameter.target_date,
    useCallback((value) => dispatch(dateChanged({ id: node.uuid, value })), [dispatch, node.uuid])
  );
  return { date, handleDateChange };
}

export default function DateParameter({ node }: { node: NormalizedNode<Leaf<DateParameter>> }) {
  const dispatch = useAppDispatch();
  const { date, handleDateChange } = useInputs(node);
  const handlePickerChange = ([date]: [Date?, ...Date[]]) => {
    handleDateChange(date ? format(date, dbDateFormat) : "");
  };
  const parsedDate = useMemo(() => date && parse(date, dbDateFormat, new Date()), [date]);
  const collapsed = useParentCollapsed();
  return (
    <>
      <span className={styles.word}>{t("workflow_rules.joiner.is")}</span>
      <Collapsible
        collapsed={collapsed}
        fallback={t("workflow_rules.date_comparison." + node.state.comparison.toLowerCase())}
      >
        <InputContainer>
          <Select
            name={`comparison-${node.uuid}`}
            value={node.state.comparison}
            onChange={(e) => {
              assert(inEnum(Comparison, e.target.value), "Invalid comparison");
              dispatch(comparisonChanged({ id: node.uuid, comparison: e.target.value }));
            }}
            aria-label={t("workflow_rules.select_comparison")}
            options={Object.values(Comparison).map((comparison) => ({
              value: comparison,
              label: t("workflow_rules.date_comparison." + comparison.toLowerCase()),
            }))}
          />
        </InputContainer>
      </Collapsible>
      <Collapsible
        collapsed={collapsed}
        fallback={parsedDate ? intlFormat(parsedDate) : t("workflow_rules.select_date")}
      >
        <DatePicker
          value={parsedDate}
          onChange={handlePickerChange}
          onValueUpdate={handlePickerChange}
          aria-label={t("workflow_rules.select_date")}
        />
      </Collapsible>
    </>
  );
}
