import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import AddOrEditModalContent from "./AddOrEditModalContent";

import { useDispatch, useSelector } from "react-redux";
import { addNewUom } from "../../../../actions/lotActions";
import { sendCommonModalSubmitRequest, resetCommonModal } from "../../../../hoc/modalHOC/modalHOC.duck";
import ModalHeader from "../host_rfq_screen/modal/ModalHeader";

const AddNewUomModal = (props) => {
  const dispatch = useDispatch();

  //Get state from redux-store
  const states = useSelector((state) => state.modalHOCReducers);

  const [conversion, updateConversion] = useState("");
  const [name, updateName] = useState("");
  const {
    header,
    modalCloseButtonText,
    showAddNewModal,
    modalCloseButtonClass,
    modalSubmitButtonText,
    modalSubmitButtonClass,
    onCloseModal,
    translations,
    unitSetId,
    unitSetName,
    currentUser,
    event,
  } = props;

  // To manage previous states
  const usePrevious = (states) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = states;
    });
    return ref.current;
  };

  const prevState = usePrevious(states);

  useEffect(() => {
    if (prevState && prevState.isSubmitted !== states.isSubmitted) {
      dispatch(resetCommonModal());
      onCloseModal();
    }
  }, [states]);

  const handleSubmit = () => {
    dispatch(sendCommonModalSubmitRequest());
    dispatch(
      addNewUom(unitSetId, {
        ratio: conversion,
        name,
        resource_type: "User",
        resource_id: currentUser.id,
        from: "line_item_component",
        event_id: event.id,
        unit_set_id: unitSetId,
      })
    );
  };
  return (
    <Modal isOpen={showAddNewModal} className="modal-content col-md-8 col-sm-8 custom-field-popup">
      <div
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            handleSubmit();
          }
        }}
        className="block  block-transparent remove-margin-b"
      >
        <ModalHeader {...props} closeModal={onCloseModal} title={header} />

        <div className="block-content">
          <AddOrEditModalContent
            translations={translations}
            addNew={true}
            unitSetName={unitSetName}
            updateConversion={updateConversion}
            updateName={updateName}
            handleSubmit={handleSubmit}
          />
        </div>
        <div className="pop-footer modal-footer">
          <div className="col-md-12">
            <button
              className={modalCloseButtonClass}
              data-dismiss="modal"
              type="button"
              disabled={states.isSubmitting}
              onClick={onCloseModal}
            >
              {modalCloseButtonText}
            </button>
            {modalSubmitButtonText && (
              <button
                className={modalSubmitButtonClass}
                data-dismiss="modal"
                type="button"
                disabled={states.isSubmitting}
                onClick={() => handleSubmit()}
              >
                {modalSubmitButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AddNewUomModal;
