import { useMemo } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { last } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { latestBidDuringAuctionForLot, relatedBidLineItemComponents } from "../bidCommon";
import {
  isLotTotalPresent,
  isPBPermittedInLot,
  lotLineItemComponents as getLotLineItemComponents,
  lotLineItems as getLotLineItems,
  lotLotComponents as getLotLotComponent,
} from "../common";

/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { Lot, Bid } from "@/common-prop-types";
/* eslint-enable no-unused-vars */

const lotSelection = createSelector(
  ({ lotReducers }) => lotReducers.lot_components,
  ({ lotReducers }) => lotReducers.line_items,
  ({ lotReducers }) => lotReducers.bids,
  ({ lotReducers }) => lotReducers.activeLis,
  (lotComponents, lineItems, bids, activeLis) => ({
    lotComponents,
    lineItems,
    bids,
    activeLis,
  })
);

const useIsRejectedLot = (lotBids) =>
  useMemo(() => lotBids.some((b) => !!b.rejected_lot && b.current_state === "submitted"), [lotBids]);

const useParticipantLotLineItemComponents = ({ lotComponents, lotLineItemComponents }) => {
  const participantLotComponents = useMemo(
    () => lotComponents.filter(({ provider_cd }) => provider_cd !== 0),
    [lotComponents]
  );

  return useMemo(
    () => getLotLineItemComponents(participantLotComponents, lotLineItemComponents),
    [participantLotComponents, lotLineItemComponents]
  );
};

const useLatestBids = ({ lot, bids }) => {
  const event = useSelector(({ lotReducers }) => lotReducers.event);
  const weightedRfqOrAuction = useSelector(({ lotReducers }) => lotReducers.weighted_rfq_or_auction);
  const participant = useSelector(({ lotReducers }) => lotReducers.event_participant ?? lotReducers.current_user);

  const lotBids = useMemo(
    () => bids.filter((bid) => bid.lot_id === lot.id && bid.user_id === participant?.user_id),
    [bids, lot.id, participant]
  );

  const latestBids = useMemo(
    () =>
      latestBidDuringAuctionForLot(lotBids, [participant], event, weightedRfqOrAuction).sort((bid2, bid1) =>
        moment(bid1.created_at).diff(moment(bid2.created_at))
      ),
    [event, lotBids, participant, weightedRfqOrAuction]
  );

  return { lotBids, latestBids };
};

const useLotBidLineItemComponents = ({ bid }) => {
  const bidLineItemComponents = useSelector(({ lotReducers }) => lotReducers.bid_line_item_components);

  return useMemo(
    () => (bid?.id ? relatedBidLineItemComponents(bid.id, bidLineItemComponents) : []),
    [bid.id, bidLineItemComponents]
  );
};

const useLotLineItemComponents = ({ lotLotComponents }) => {
  const lineItemComponents = useSelector(({ lotReducers }) => lotReducers.line_item_components);

  return useMemo(
    () => getLotLineItemComponents(lotLotComponents, lineItemComponents),
    [lineItemComponents, lotLotComponents]
  );
};

/**
 *
 * @param {PropTypes.InferProps<Lot>} lot
 * @param {PropTypes.InferProps<Bid>} bid
 */
const useLot = (lot = {}, bid = {}) => {
  const { lotComponents, lineItems, bids, activeLis } = useSelector(lotSelection);
  const activeLi = useMemo(() => activeLis?.find((li) => li?.lot_id === lot?.id), [activeLis, lot?.id]);

  /**
   * List of all lotComponents for lot
   *
   * @type {object[]}
   */
  const lotLineItems = useMemo(() => getLotLineItems(lot, lineItems), [lineItems, lot]);

  /**
   * List of all lotComponents for lot
   *
   * @type {object[]}
   */
  const lotLotComponents = useMemo(() => getLotLotComponent(lot, lotComponents), [lot, lotComponents]);

  /**
   * List of all lotComponents for lot
   *
   * @type {object[]}
   */
  const lotLineItemComponents = useLotLineItemComponents({ lotLotComponents });

  const isLotLiActive = lotLineItems.some(({ id }) => id === activeLi?.id);
  const lotBidLineItemComponents = useLotBidLineItemComponents({ bid });
  const { lotBids, latestBids } = useLatestBids({ lot, bids });

  return {
    lotLineItems,
    lotLotComponents,
    lotLineItemComponents,
    /**
     * Host lotComponents for lot
     * @type {object[]}
     */
    participantLotLineItemComponents: useParticipantLotLineItemComponents({ lotComponents, lotLineItemComponents }),
    lotBidLineItemComponents,
    lotBids,
    latestBids,
    latestBid: last(latestBids),
    activeLi,
    isLotLiActive,
    isRejectedLot: useIsRejectedLot(lotBids),

    /**
     * True if lot has lot total
     * @type {boolean}
     */
    isLotTotal: isLotTotalPresent(lot),

    /**
     * True if partial bidding is permitted in lot
     * @type {boolean}
     */

    isPBPermitted: isPBPermittedInLot(lot),
  };
};

export default useLot;
