import * as actionTypes from "../actions/actionsTypes";

const lineItemReducers = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.CREATE_LINE_ITEM:
      return {
        ...state,
        lineItem: payload.lineItem,
        lineItemComponents: payload.lineItemComponents,
        actionType: action.type,
      };
    case actionTypes.UPDATE_LINE_ITEM:
      return {
        ...state,
        lineItem: payload.lineItem,
        actionType: action.type,
      };
    default:
      return state;
  }
};

export default lineItemReducers;
