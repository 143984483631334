import React from "react";
import Modal from "react-modal";
import GraphComponent from "../../../monitor/GraphComponent";
import Footer from "./Footer";
import Header from "./Header";

const ParticipantProgressionChartModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      className="modal-content col-md-6 col-sm-6 custom-field-popup"
      appElement={document.getElementById("advanced-lots-body")}
    >
      <Header translations={props.translations} setIsOpen={props.setIsOpen} header={props.header} />
      <GraphComponent {...props} type={"participantMonitor"} />
      <Footer translations={props.translations} setIsOpen={props.setIsOpen} />
    </Modal>
  );
};

export default ParticipantProgressionChartModal;
