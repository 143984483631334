import { useEffect, useMemo } from "react";
import { uniq } from "lodash";
import { useDispatch } from "react-redux";
import { setActiveLi } from "@/actions/lotActions";
import { componentType, validLineItemComponentForBlic } from "../../common";
import useLot from "../useLot";

/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { Lot, Bid } from "@/common-prop-types";
/* eslint-enable no-unused-vars */

/**
 *
 * @param {PropTypes.InferProps<Lot>} lot
 * @param {PropTypes.InferProps<Bid>} bid
 */
export const useValidLotValues = (lot, bid) => {
  const { participantLotLineItemComponents, lotBidLineItemComponents } = useLot(lot, bid);
  const validLicsForBlics = validLineItemComponentForBlic(participantLotLineItemComponents);
  const validLicIds = validLicsForBlics.map(({ id }) => id);
  const validLotBlics = lotBidLineItemComponents?.filter((blic) => validLicIds.includes(blic.line_item_component_id));

  return {
    validLicsForBlics,
    validLotBlics,
  };
};

const useLotFilledLicsIds = ({ validLotBlics, validLicsForBlics }) => {
  return useMemo(() => {
    const validLicsIdsForBlics = validLicsForBlics?.map((lic) => lic.id);
    return validLotBlics
      ?.filter((blic) => validLicsIdsForBlics.includes(blic.line_item_component_id))
      .map((blic) => blic.line_item_component_id);
  }, [validLicsForBlics, validLotBlics]);
};

const useValidLiIdsToCheck = ({ validLicsForBlics, validLotBlics }) => {
  // get list of all the lic id's which are filled
  const lotFilledLicsIds = useLotFilledLicsIds({ validLotBlics, validLicsForBlics });

  return useMemo(
    () =>
      uniq(
        validLicsForBlics
          ?.filter((lic) => !lic.formula && lotFilledLicsIds.includes(lic.id))
          .map((lic) => lic.line_item_id)
      ),
    [lotFilledLicsIds, validLicsForBlics]
  );
};

/**
 *
 * @param {PropTypes.InferProps<Lot>} lot
 * @param {PropTypes.InferProps<Bid>} bid
 * @returns {boolean} true if all the Line Items which have any cell filled in them, are completely filled
 */
const usePartiallyFilledStatus = (lot, bid) => {
  const { validLicsForBlics, validLotBlics } = useValidLotValues(lot, bid);

  // // get list of all li's which have any filled lic
  const validLiIdsToCheck = useValidLiIdsToCheck({ validLicsForBlics, validLotBlics });

  const areAllValidLicsCompletelyFilled = useMemo(
    () =>
      validLotBlics?.length &&
      !validLiIdsToCheck.some((liId) => {
        const PBLiLicsIds = validLicsForBlics?.filter((lic) => lic.line_item_id === liId).map((lic) => lic.id);
        const PBLiBlics = validLotBlics?.filter((blic) => PBLiLicsIds.includes(blic.line_item_component_id));

        // return false if line item is completely filled
        return PBLiLicsIds?.length !== PBLiBlics?.length;
      }),
    [validLiIdsToCheck, validLicsForBlics, validLotBlics]
  );

  return validLiIdsToCheck?.length && areAllValidLicsCompletelyFilled;
};

/**
 * @typedef {number | undefined | null} PriceLike
 * @typedef {Object} BlicLike
 * @property {number} line_item_component_id
 * @property {PriceLike} price
 * @property {PriceLike} host_price
 */

/**
 * @param {number[]} validPriceLotLicsIds
 */
const validPriceBlicFilter =
  (validPriceLotLicsIds) =>
  /**
   *
   * @param {BlicLike} blic
   * @returns {boolean}
   */
  ({ line_item_component_id, price, host_price }) =>
    validPriceLotLicsIds.includes(line_item_component_id) && (price || host_price);

/**
 * @description filter lics if lot_component_type is price
 * @param {{lot_component_type: string}} lic
 * @returns {boolean}
 */
const priceLicFilter = ({ lot_component_type }) => componentType(lot_component_type) === "isPrice";

const useAreAllCellsFilledForPB = ({ validLicsForBlics, validLotBlics }) => {
  return useMemo(() => {
    if (!(validLotBlics?.length || validLicsForBlics?.length)) return false;

    const validPriceLotLicsIds = validLicsForBlics.filter(priceLicFilter).map(({ id }) => id);

    // get blics for which componentType is price and price is present
    const lotPriceBlics = validLotBlics.filter(validPriceBlicFilter(validPriceLotLicsIds));

    return validPriceLotLicsIds.length === lotPriceBlics.length;
  }, [validLicsForBlics, validLotBlics]);
};

/**
 *
 * @param {PropTypes.InferProps<Lot>} lot
 * @param {PropTypes.InferProps<Bid>} bid
 */
const useFieldStatus = (lot, bid) => {
  const dispatch = useDispatch();
  const { validLicsForBlics, validLotBlics } = useValidLotValues(lot, bid);
  const { isPBPermitted, lotLineItems, isLotLiActive } = useLot(lot, bid);

  const isCompleteLotFilled = validLicsForBlics?.length === validLotBlics?.length;

  const isLotCorrectlyFilledForPB = usePartiallyFilledStatus(lot, bid);
  const validLiIdsToCheck = useValidLiIdsToCheck({ validLicsForBlics, validLotBlics });

  useEffect(() => {
    if (isPBPermitted && isLotCorrectlyFilledForPB && !isLotLiActive) {
      dispatch(setActiveLi({ li: lotLineItems.find(({ id }) => id === validLiIdsToCheck[0]) }));
    }
  }, [isLotLiActive, isLotCorrectlyFilledForPB, lotLineItems, validLiIdsToCheck, isPBPermitted, dispatch]);

  /**
   * @description Check if cells are filled
   * @returns {boolean}
   */
  const areFieldsFilled = useMemo(() => {
    if (!validLotBlics?.length || !validLicsForBlics?.length) return false;
    if (isCompleteLotFilled) return true;

    return isPBPermitted && isLotCorrectlyFilledForPB;
  }, [isCompleteLotFilled, isLotCorrectlyFilledForPB, isPBPermitted, validLicsForBlics?.length, validLotBlics?.length]);

  return {
    areFieldsFilled,
    /**
     * @type {Boolean} - true if all the price lic's are filled
     */
    areAllCellsFilledForPB: useAreAllCellsFilledForPB({ validLicsForBlics, validLotBlics }),
  };
};

export default useFieldStatus;
