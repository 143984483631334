import React from "react";
import PropTypes from "prop-types";

import Label from "./label";
import Feedback from "./feedback";
import clsx from "clsx";

export const buildProps = ({ inputProps, id, name, error, className }) => {
  const { md, ...props } = inputProps;

  props.className = clsx(props.className, className, { "is-invalid": error, "is-market-dojo": md });
  props["aria-describedby"] = id + "--feedback";

  return { ...props, id, name };
};

export const InputPropTypes = {
  /**
   * The name attribute of the input.
   */
  name: PropTypes.string.isRequired,
  /**
   * The id attribute of the input. If you don't supply one the name will be
   * used as the id attribute
   */
  id: PropTypes.string,
  /**
   * The label given to the input
   */
  label: PropTypes.string,
  /**
   * The error message that will be displayed under the input. If this is
   * provided then the input will be put into the error state
   */
  error: PropTypes.string,
  /**
   * Help info text that will be displayed under the input
   */
  help: PropTypes.string,
  /**
   * Use this component in the style of Market Dojo, this will take the styling
   * from the MD blue rather than the esker styling.
   */
  md: PropTypes.bool,
  /**
   * This is to give a chekbox a third state i.e, "indeterminate" state.
   * It'll be false by default if not provided in props.
   */
  isIndeterminate: PropTypes.bool,
};

/**
 * The Market Dojo input component
 *
 * @type {React.FC<PropTypes.InferProps<typeof InputPropTypes> & React.ComponentProps<"input">>}
 */
export const Input = (props) => {
  const { name, label, error, help, ...inputProps } = props;
  const id = props.id || name;
  return (
    <>
      <Label {...{ id, label }} />
      <input {...buildProps({ inputProps, id, name, error, className: "form-control" })} />
      <Feedback {...{ id, help, error }} />
    </>
  );
};

Input.propTypes = InputPropTypes;

export default Input;
