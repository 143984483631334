import React from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";
import { useFormContext, useListAttribute, getAll } from "@adeattwood/react-form";
import { Box } from "@/cl/box";
import { Button } from "@/cl/button";
import Checkbox from "@/cl/checkbox/react-form";
import Input from "@/cl/input/react-form";
import { CustomFieldOption } from "@/common-prop-types/custom-field";
import { Icon } from "@/cl/icon";
import { useRemoveItem } from "./use-remove-item";
import { t } from "@/i18n";
import { Ellipsis } from "@/cl/ellipsis";

function newOption() {
  const id = Math.round(Math.random() * -1000);
  return { id, name: "", active: true };
}

const OptionRowPropTypes = {
  /**
   * The custom field option we are editing or creating. If the `id` is a
   * negative number then it will be taken as a new option.
   */
  option: PropTypes.shape(CustomFieldOption).isRequired,
  /**
   * The index of the custom field we are editing
   */
  fieldIndex: PropTypes.number.isRequired,
  /**
   * The index of the option in the custom field we are editing
   */
  optionIndex: PropTypes.number.isRequired,
  /**
   * A callback that will be called to remove and item from the list. This will
   * need to take the index of the current item you want to remove.
   *
   * @type {(index: number) => void}
   */
  remove: PropTypes.func.isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof OptionRowPropTypes>>}
 */
const OptionRow = ({ option, fieldIndex, optionIndex, remove }) => {
  const baseAttribute = `customFields.${fieldIndex}.custom_column_options.${optionIndex}`;
  return (
    <tr>
      <td>
        {option.id < 0 ? (
          <Input aria-label="Option Name" attribute={`${baseAttribute}.name`} autoFocus />
        ) : (
          <Ellipsis maxWidth={340}>{option.name}</Ellipsis>
        )}
      </td>
      <td className="align-content-center">
        <Checkbox md attribute={`${baseAttribute}.active`} label={t("centralised_custom_fields.is_active")} />
      </td>
      <td>
        {option.id < 0 && (
          <Button type="button" brand="md-outline-primary" icon onClick={() => remove(optionIndex)}>
            <Icon name="x" />
          </Button>
        )}
      </td>
    </tr>
  );
};

OptionRow.propTypes = OptionRowPropTypes;

const EditOptionsModalPropTypes = {
  /**
   * The callback function that will remove the modal from the dom.
   */
  close: PropTypes.func.isRequired,
  /**
   * The index of the custom field that we are editing the options for.
   */
  index: PropTypes.number.isRequired,
};

/**
 * Validates all of the options in a single custom field. This must return true
 * before we close the modal and let the user carry on editing. If we let the
 * user continue then the don't get very helpful error messages when this tries
 * to save.
 */
const useValidateOptions = (index) => {
  const context = useFormContext();

  const validateOptions = async function () {
    const attributes = getAll(context.formState, `customFields.${index}.custom_column_options..name`);
    const errors = { ...context.errors };
    let hasNewErrors = false;

    for (const attribute of attributes) {
      delete errors[attribute.path];
      const attributeErrors = await context.validateAttribute(attribute.path, context.formState);
      if (attributeErrors.length > 0) {
        hasNewErrors = true;
        errors[attribute.path] = attributeErrors;
      }
    }

    context.setErrors(errors);

    return !hasNewErrors;
  };

  const optionErrors = Object.keys(context.errors).filter((key) =>
    key.startsWith(`customFields.${index}.custom_column_options`)
  );
  const isValid = optionErrors.length === 0;

  return { validateOptions, isValid };
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof EditOptionsModalPropTypes>>}
 */
const EditOptionsModal = ({ close, index }) => {
  const { validateOptions, isValid } = useValidateOptions(index);
  const { add, value, remove: baseRemove } = useListAttribute(`customFields.${index}.custom_column_options`, newOption);
  const remove = useRemoveItem(`customFields.${index}.custom_column_options`, baseRemove);
  const [isOpen, setIsOpen] = React.useState(true);
  const requestClose = async () => {
    if (await validateOptions()) {
      setIsOpen(false);
      close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={requestClose}
      className="modal-dialog modal-lg center-content-horizontal-vertical"
      appElement={document.body}
      portalClassName="md-bs5"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="fw-bold modal-title">{t("centralised_custom_fields.pick_type")}</h5>
          <button className="btn-close" onClick={requestClose} aria-label={t("close")}></button>
        </div>
        <div className="modal-body">
          <table className="table is-striped m-0 border-none">
            <tbody>
              {value.length === 0 && (
                <tr>
                  <td className="text-center">{t("centralised_custom_fields.no_options")}</td>
                </tr>
              )}
              {value.map((option, optionIndex) => (
                <OptionRow key={option.id} {...{ option, optionIndex, remove, fieldIndex: index }} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="modal-footer mx-2 px-1">
          <Box textRight>
            <Button brand="md-primary" onClick={add}>
              {t("centralised_custom_fields.add_new_option")}
            </Button>
          </Box>
        </div>
      </div>
    </Modal>
  );
};

EditOptionsModal.propTypes = EditOptionsModalPropTypes;

export default EditOptionsModal;
