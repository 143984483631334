import { Select } from "@/cl/select";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { useDebouncedSync } from "@/hooks/use-debounced-sync";
import { NodeIssue, NodeIssueTranslation } from "@/lib/rules/validate";
import { negationChanged, picklistChanged, selectIssueById } from "@/slices/rules";
import type { PicklistField } from "@/slices/rules/fields";
import type { Leaf, NormalizedNode, TextParameter } from "@/slices/rules/types";
import { assert } from "@/utils/assert";
import { t } from "@/i18n";
import React, { useCallback } from "react";
import { Collapsible, InputContainer } from "../leaf-node";
import { useParentCollapsed } from "../collapse-context";

function useInputs(node: NormalizedNode<Leaf<TextParameter>>) {
  const dispatch = useAppDispatch();
  const [negated, handleNegatedChange] = useDebouncedSync(
    node.negated,
    useCallback((negated) => dispatch(negationChanged({ id: node.uuid, negated })), [dispatch, node.uuid])
  );
  const [value, handleValueChange] = useDebouncedSync(
    node.state.leaf_parameter.parameters[0] ?? "",
    useCallback((value) => dispatch(picklistChanged({ id: node.uuid, value })), [dispatch, node.uuid])
  );
  return { negated, value, handleNegatedChange, handleValueChange };
}

export default function PicklistParameter({
  node,
  field,
}: {
  node: NormalizedNode<Leaf<TextParameter>>;
  field: PicklistField;
}) {
  const { negated, value, handleNegatedChange, handleValueChange } = useInputs(node);
  const issue = useAppSelector((state) => selectIssueById(state, node.uuid));
  const parentCollapsed = useParentCollapsed();
  const collapsed = !issue && parentCollapsed;
  const negatedString = t(`workflow_rules.joiner.is${negated ? "_not" : ""}`);
  return (
    <>
      <Collapsible collapsed={collapsed} fallback={negatedString}>
        <InputContainer>
          <Select
            name={`negation-${node.uuid}`}
            aria-label={t("workflow_rules.select_negation")}
            value={String(negated)}
            onChange={(e) => handleNegatedChange(e.target.value === "true")}
            options={[
              { value: "false", label: t("workflow_rules.joiner.is") },
              { value: "true", label: t("workflow_rules.joiner.is_not") },
            ]}
          />
        </InputContainer>
      </Collapsible>
      <Collapsible collapsed={collapsed} fallback={value || t("workflow_rules.select_a_value")}>
        <InputContainer>
          <Select
            name={`value-${node.uuid}`}
            value={value}
            onChange={(e) => {
              const { value } = e.target;
              assert(field.options.includes(value), `Invalid option: ${value}`);
              handleValueChange(value);
            }}
            aria-label={t("workflow_rules.select_a_value")}
            options={[
              { value: "", label: t("workflow_rules.select_a_value"), disabled: true },
              ...field.options.map((option) => ({ value: option, label: option })),
            ]}
            error={
              issue === NodeIssue.PicklistNotChosen || issue === NodeIssue.InvalidOption
                ? t("workflow_rules.issues." + NodeIssueTranslation[issue])
                : undefined
            }
          />
        </InputContainer>
      </Collapsible>
    </>
  );
}
