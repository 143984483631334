import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { classesFromEventState } from "../common";
import LotTableHeadTop from "./participantScreenComponents/LotTableHeadTop";
import LotLisComponent from "./participantScreenComponents/LotLisComponent";
import { Grid, StickyHeader } from "@/components/common/grid";
import { useLineItemDetails } from "@/components/advanced_lots/hooks/useLineItemDetails";
import useEventLotGridSizerCallback from "../hooks/useEventLotGridSizerCallback";
import { LotComponentTableHeader, ShowLineItemDetailsButton } from "./eventLotComponnet/Header";
import ConfirmBid from "./eventLotComponnet/ConfirmBid";
import useEvent from "../hooks/useEvent";
import useLot from "../hooks/useLot";
import useLotActions from "../hooks/useEventLotComponent/useLotActions";
import useFieldStatus from "../hooks/useEventLotComponent/useFieldStatus";
import useTotal from "../hooks/useEventLotComponent/useTotal";
import useRange from "../hooks/useEventLotComponent/useRange";
import useEnableSubmit, { useLotEffect } from "../hooks/useEventLotComponent/useEnableSubmit";

/**
 * set advancedLot global vars that are being used for eventLots
 * @param {string} lotId
 * @returns {void}
 */
const setUpGlobalVarsForAdvanceLots = (lotId) => {
  const { formulaLics, lics } = window.advancedLots ?? {};
  window.advancedLots = {
    ...window.advancedLots,
    ...(!formulaLics && { formulaLics: {} }),
    ...(!lics && { lics: {} }),
    ...(!Array.isArray(formulaLics?.[lotId]) && { formulaLics: { ...formulaLics, [lotId]: [] } }),
    ...(!Array.isArray(lics?.[lotId]) && { lics: { ...lics, [lotId]: [] } }),
  };
};

let EventLotComponent = (props) => {
  let {
    event,
    lot,
    currenciesHash,
    openModal,
    isProxyBid = false,
    participant,
    setLotStateCb,
    setIsOpen,
    bid,
    isBidSubmitted,
    lotState,
    setLotState,
    isPlaceBidActive,
    onClickPlaceBid,
    isAuction,
    isLotLevelMonitor,
    bidDetailsAdded,
    setEnableSubmitForProxyBidModal,
    showHeader = true,
    showDetailsButton = true,
    roundedBorders,
  } = props;
  const { isMultiCurrencyEvent, isAuctionPending, isEventCompleted, getCurrency } = useEvent();
  const {
    lotLotComponents,
    lotLineItems,
    participantLotLineItemComponents,
    lotLineItemComponents,
    latestBid,
    isRejectedLot,
  } = useLot(lot);

  const lotHook = useLineItemDetails(lotState.isPlaceBidActive);
  const { setLineItemsDetailStateOpen, setLineItemsDetailStateClosed, showAllLineItemDetails } = lotHook;

  if (lot?.id) setUpGlobalVarsForAdvanceLots(lot.id);

  const { is_event_total: isEventTotal } = lot;

  const { areAllCellsFilledForPB } = useFieldStatus(lot, bid);

  if (!isProxyBid) {
    setLotStateCb[lot.id] = setLotState;
  }

  const { lotTotalBLic, totalBidValue, totalBidPrice, showPrice, lotTotalLic } = useTotal(
    lot,
    bid,
    showAllLineItemDetails
  );

  const licCurrency = getCurrency(lotTotalBLic?.exchange_rate_id);

  const { isInRange, maxBidRange, minBidRange } = useRange(
    lot,
    bid,
    isAuction,
    totalBidValue,
    isLotLevelMonitor,
    isBidSubmitted
  );

  const enableSubmit = useEnableSubmit(
    lot,
    bid,
    isPlaceBidActive,
    isLotLevelMonitor,
    bidDetailsAdded,
    isBidSubmitted,
    isInRange
  );

  useEffect(() => {
    if (isProxyBid) setEnableSubmitForProxyBidModal?.(enableSubmit);
  }, [enableSubmit, isProxyBid, setEnableSubmitForProxyBidModal]);

  // open bidConfirmation modal
  useLotEffect(lot, bid, enableSubmit);

  const actionCallback = () => {
    if (isProxyBid) setIsOpen(false);
    setLineItemsDetailStateClosed();
  };
  // Create handlers for bidSubmit and cancelBid buttons
  const { handleBidSubmit, handleCancelBid } = useLotActions(bid, lot, setLotState, enableSubmit, actionCallback);
  const onOkayButtonClick = () => handleBidSubmit(isLotLevelMonitor, isAuction);
  const onCancelBid = () => handleCancelBid();

  // The function that will calculate the height of each row. Needs to be
  // defined above where we bail out so the hook is not used conditionally.
  const sizerCallback = useEventLotGridSizerCallback(lotTotalLic?.id, lotHook, isPlaceBidActive, lot);
  const numberFormat = useSelector(({ lotReducers }) => lotReducers.translations.number_format);

  // We don't need to render anything if there are no lot item components
  if (!lotLineItemComponents.length) {
    return null;
  }

  return (
    <div id="lot-table-box" className={`col-md-12 customtbl-top v2 ${classesFromEventState(event)}`} key={lot.id}>
      <div className="border-none equal-box-top complex-lot-bid matrix-area line-items-closed p-0">
        <div className="box yes">
          {showHeader && (
            <LotTableHeadTop
              {...props}
              openModal={openModal}
              enableSubmit={enableSubmit}
              bid={bid}
              lot={lot}
              event={event}
              isEventTotal={isEventTotal}
              isRejectedLot={isRejectedLot}
              totalBidPrice={totalBidPrice}
              licCurrency={licCurrency}
              isProxyBid={isProxyBid}
              participant={participant}
              setLotState={setLotState}
              lotState={lotState}
              isEventCompleted={isEventCompleted}
              onClickPlaceBid={onClickPlaceBid}
              onCancelBid={onCancelBid}
              isAuction={isAuction}
              isInRange={isInRange}
              latestBid={latestBid}
              totalBidValue={totalBidValue}
              isPlaceBidActive={lotState.isPlaceBidActive}
              currenciesHash={currenciesHash}
              isMultiCurrencyEvent={isMultiCurrencyEvent}
              lotLics={lotLineItemComponents}
              minBidRange={minBidRange}
              maxBidRange={maxBidRange}
              areAllCellsFilledForPB={areAllCellsFilledForPB}
              lotTotalBLic={lotTotalBLic}
            />
          )}
          <Grid
            items={lotLineItems}
            estimateSize={sizerCallback}
            height={600}
            className={`border shadow-diffuse ${roundedBorders ? "rounded-top-left-1 rounded-top-right-1" : ""}`}
          >
            <StickyHeader className="bg-white">
              <ShowLineItemDetailsButton
                isShowingDetails={showAllLineItemDetails}
                isShowingExpandButton={!isEventTotal && showDetailsButton}
                toggleShowDetails={showAllLineItemDetails ? setLineItemsDetailStateClosed : setLineItemsDetailStateOpen}
              />
              {lotLotComponents.map(({ id, name, description }) => (
                <LotComponentTableHeader key={id} {...{ name, description }} />
              ))}
            </StickyHeader>
            {(lineItem, { props: trProps }) => (
              <tr key={lineItem.id} {...trProps}>
                <LotLisComponent
                  {...props}
                  lotHook={lotHook}
                  li={lineItem}
                  lotLics={lotLineItemComponents}
                  isAllDetailView={lotState.isAllDetailView}
                  isPlaceBidActive={lotState.isPlaceBidActive}
                  currency={licCurrency}
                  lotLcs={lotLotComponents}
                  isMultiCurrencyEvent={isMultiCurrencyEvent}
                  numberFormat={numberFormat}
                  _lotLics={participantLotLineItemComponents}
                  isRejectedLot={isRejectedLot}
                  showPrice={showPrice}
                  lotTotalLic={lotTotalLic}
                  isAuctionPending={isAuctionPending}
                />
              </tr>
            )}
          </Grid>
        </div>
      </div>
      <ConfirmBid {...{ areAllCellsFilledForPB, licCurrency, onOkayButtonClick, totalBidPrice, lot }} />
    </div>
  );
};

EventLotComponent.defaultProps = {
  currenciesHash: {},
  unitSetUoms: [],
};

export default EventLotComponent;
