import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { changeYearButtons } from "../../../common";
import {
  updateDateAndAlertsValue,
  updateContractNotificationInterval,
  updateRecipientsForNotification,
} from "../../../actions/contract/contractCreateEditActions";
import ExpiryNotificationModal from "./ExpiryNotificationModal";
import ContractTooltip from "./ContractTooltip";
import { handleIncludeRecipients } from "./CustomNotificationForm";

class DatesAndAlertsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateAndAlerts: props.dateAndAlerts,
      noticePeriodInterval: "other",
      stakeHoldersBox: true,
      includedStakeholders: props.dateAndAlerts.default_notification
        ? props.dateAndAlerts.default_notification.recipient.stakeholders
            .filter((item) => item.checked)
            .map((item) => item.id)
        : [],
      includedSuppliers: props.dateAndAlerts.default_notification
        ? props.dateAndAlerts.default_notification.recipient.suppliers
            .filter((item) => item.checked)
            .map((item) => item.id)
        : [],
      includeNotes: props.dateAndAlerts.default_notification
        ? props.dateAndAlerts.default_notification.include_notes
        : false,
      noticePeriod: props.dateAndAlerts.notice_period,
      alertDate: moment(props.dateAndAlerts.notice_period).diff(props.dateAndAlerts.alert_date, "days") / 7,
    };
    this.expiryDateError = React.createRef();
    this.startDateError = React.createRef();
    this.startDateRef = React.createRef();
    this.expiryDateRef = React.createRef();
    this.noticePeriodDateRef = React.createRef();
    this.noticePeriodDateError = React.createRef();
    this.contractNotificationIntervalUnitRef = React.createRef();
    this.contractNotificationIntervalRef = React.createRef();
    this.alertDateRef = React.createRef();
    this.alertDateUnitRef = React.createRef();
    this.intervalError = React.createRef();
    this.datesAndAlertsSection = React.createRef();
    this.alertIntervalError = React.createRef();

    this.handleIncludeRecipients = handleIncludeRecipients.bind(this);
  }

  componentDidMount() {
    $(".datepicker-for-contract").datepicker({
      dateFormat: "yy-mm-dd",
      beforeShow: changeYearButtons,
      onChangeMonthYear: changeYearButtons,
      onClose: (value, inst) => {
        return this.handleDateChange(value, inst.id);
      },
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const updatedDatesAndAlerts = newProps.updatedDatesAndAlerts;
    if (updatedDatesAndAlerts) {
      this.setState({
        dateAndAlerts: updatedDatesAndAlerts,
        noticePeriod: updatedDatesAndAlerts.notice_period,
      });
    }
  }

  async handleDateChange(date, fieldName) {
    const { translations } = this.props;
    const startDate = this.startDateRef.current.value;
    const expiryDate = this.expiryDateRef.current.value;
    const noticePeriodDate = this.noticePeriodDateRef.current ? this.noticePeriodDateRef.current.value : "";
    const startDateError = this.startDateError.current;
    const expiryDateError = this.expiryDateError.current;
    const { dateAndAlerts } = this.state;
    const data = {};
    let datesAreValid = true;

    // this method will update the state to manage the custom notification according to the start and expiry date values
    this.props.updateContractDates(startDate, expiryDate);
    if (fieldName === "start_date") {
      if (expiryDate && new Date(startDate) >= new Date(expiryDate)) {
        startDateError.classList.remove("invisible");
        startDateError.innerText = translations.start_date_error;
        dateAndAlerts.start_date = startDate;
        datesAreValid = false;
      } else {
        data["expiry_date"] = expiryDate;
        startDateError.classList.add("invisible");
        expiryDateError.classList.add("invisible");
      }
    } else if (fieldName === "expiry_date") {
      if (startDate && new Date(expiryDate) <= new Date(startDate)) {
        expiryDateError.classList.remove("invisible");
        expiryDateError.innerText = translations.contract_expiry_date_error;
        dateAndAlerts.expiry_date = expiryDate;
        datesAreValid = false;
      } else {
        data["start_date"] = startDate;
        expiryDateError.classList.add("invisible");
        startDateError.classList.add("invisible");
      }
    } else {
      datesAreValid = this.validateNoticePeriod(startDate, expiryDate, noticePeriodDate);
      dateAndAlerts.notice_period = noticePeriodDate;
    }
    if (
      this.noticePeriodDateRef.current &&
      fieldName !== "notice_period" &&
      startDate &&
      expiryDate &&
      !dateAndAlerts.notice_period
    ) {
      dateAndAlerts.notice_period = moment(expiryDate).subtract(1, "months").format("YYYY-MM-DD");
      if (this.validateNoticePeriod(startDate, expiryDate, dateAndAlerts.notice_period)) {
        data["notice_period"] = dateAndAlerts.notice_period;
      }
      this.setState({
        noticePeriodInterval: "one_month",
        dateAndAlerts,
      });
    }
    if (datesAreValid) {
      data[fieldName] = date;
      if (fieldName == "notice_period") {
        this.setState({
          noticePeriod: date,
        });
        let alertInterval = moment(date).subtract(this.alertDateRef.current.value * 7, "days");
        if (new Date(alertInterval) > new Date(startDate)) {
          data["alert_date"] = alertInterval.format("YYYY-MM-DD");
          this.setState({
            noticePeriod: date,
          });
        }
      }
      await this.props.updateDateAndAlertsValue(this.props.contractId, data);
    } else {
      this.setState({
        dateAndAlerts,
      });
    }
  }

  validateNoticePeriod(startDate, expiryDate, noticePeriodDate) {
    const { translations } = this.props;
    let noticePeriodDateError = this.noticePeriodDateError.current;
    if (!moment(noticePeriodDate, "YYYY-MM-DD", true).isValid()) {
      noticePeriodDateError.classList.remove("invisible");
      noticePeriodDateError.innerText = translations.validate_notice_period_with_start_date;
      return false;
    } else if (new Date(noticePeriodDate) < new Date(startDate)) {
      noticePeriodDateError.classList.remove("invisible");
      noticePeriodDateError.innerText = translations.validate_notice_period_with_start_date;
      return false;
    } else if (new Date(noticePeriodDate) > new Date(expiryDate)) {
      noticePeriodDateError.classList.remove("invisible");
      noticePeriodDateError.innerText = translations.validate_notice_period_with_expiry_date;
      return false;
    } else {
      noticePeriodDateError.classList.add("invisible");
      return true;
    }
  }

  handleNoticePeriodChange = (e) => {
    const { translations } = this.props;
    const startDate = this.startDateRef.current.value;
    const value = e.target.value;
    const expiryDate = this.expiryDateRef.current.value;
    const noticePeriodDateRef = this.noticePeriodDateRef.current;
    let noticePeriodDateError = this.noticePeriodDateError.current;
    const alertDateRef = this.noticePeriod ? this.alertDateRef.current.value : 0;
    let date = "";
    switch (value) {
      case "one_month":
        date = moment(expiryDate).subtract(1, "months");
        break;
      case "three_month":
        date = moment(expiryDate).subtract(3, "months");
        break;
      case "six_month":
        date = moment(expiryDate).subtract(6, "months");
        break;
      case "twelve_month":
        date = moment(expiryDate).subtract(12, "months");
        break;
      default:
        date = "";
        break;
    }
    noticePeriodDateRef.value = date ? moment(date).format("YYYY-MM-DD") : "";
    if (value !== "other") {
      if (new Date(date) < new Date(startDate)) {
        noticePeriodDateError.classList.remove("invisible");
        noticePeriodDateError.innerText = translations.validate_notice_period_with_start_date;
        this.noticePeriodDateRef.current.value = date.format("YYYY-MM-DD");
        this.setState({
          noticePeriod: null,
          noticePeriodInterval: value,
        });
      } else if (new Date(date) > new Date(expiryDate)) {
        noticePeriodDateError.classList.remove("invisible");
        this.startDateRef.current.value;
        noticePeriodDateError.innerText = translations.validate_notice_period_with_expiry_date;
        this.noticePeriodDateRef.current.value = date.format("YYYY-MM-DD");
        this.setState({
          noticePeriod: null,
          noticePeriodInterval: value,
        });
      } else {
        noticePeriodDateError.classList.add("invisible");
        const data = {};
        data["notice_period"] = date.format("YYYY-MM-DD");
        let alertInterval = moment(date.format("YYYY-MM-DD")).subtract(alertDateRef * 7, "days");
        if (new Date(alertInterval) > new Date(startDate)) {
          data["alert_date"] = alertInterval.format("YYYY-MM-DD");
        }
        this.props.updateDateAndAlertsValue(this.props.contractId, data);
        this.setState({
          noticePeriod: date,
          noticePeriodInterval: value,
        });
      }
    } else {
      noticePeriodDateError.classList.add("invisible");
      this.setState({
        noticePeriodInterval: value,
      });
    }
  };

  handleNotificationAlertValueChange() {
    const unit = this.contractNotificationIntervalUnitRef.current.value;
    const interval = this.contractNotificationIntervalRef.current.value;
    if (interval) {
      if (interval < 0 || interval > 9999) {
        this.intervalError.current.classList.remove("invisible");
      } else {
        this.intervalError.current.classList.add("invisible");
        this.props.updateContractNotificationInterval(this.props.contractId, unit, interval);
      }
    }
  }

  handleAlertDateValueChange() {
    const interval = this.alertDateRef.current.value;
    let date;
    if (interval && this.noticePeriodDateRef.current.value) {
      date = moment(this.noticePeriodDateRef.current.value).subtract(interval * 7, "days");
      if (new Date(date) < new Date(this.startDateRef.current.value)) {
        this.alertIntervalError.current.classList.remove("invisible");
      } else {
        this.alertIntervalError.current.classList.add("invisible");
        this.props.updateDateAndAlertsValue(this.props.contractId, {
          alert_date: moment(date).format("YYYY-MM-DD"),
        });
        this.setState({
          alertDate: +interval,
        });
      }
    }
  }

  handleNoticePeriodDateChange = (e) => {
    this.setState({
      noticePeriod: e.target.value,
    });
  };

  async handleAutoRenewChange(e) {
    const value = e.target.checked;
    const fieldName = e.target.name;
    const data = {};
    data[fieldName] = value;
    await this.props.updateDateAndAlertsValue(this.props.contractId, data);
  }

  showHideStakeholderbox(flag) {
    this.setState({
      stakeHoldersBox: flag,
    });
  }

  handleChange(e) {
    this.setState({
      includeNotes: e.target.checked,
    });
  }

  async updateExpiryNotification() {
    const { includeNotes, includedStakeholders, includedSuppliers, dateAndAlerts } = this.state;
    const { contractId } = this.props;
    const result = await this.props.updateRecipientsForNotification(
      contractId,
      dateAndAlerts.default_notification.id,
      includeNotes,
      includedSuppliers,
      includedStakeholders
    );
    if (result) {
      this.setState({
        dateAndAlerts: result,
      });
    }
  }

  validateDateAndAlertField() {
    let isError = false;
    const {
      startDateRef,
      expiryDateRef,
      noticePeriodDateRef,
      contractNotificationIntervalRef,
      startDateError,
      expiryDateError,
      noticePeriodDateError,
      intervalError,
    } = this;
    const startDate = startDateRef.current.value;
    const expiryDate = expiryDateRef.current.value;
    const { translations } = this.props;
    if (!startDate || !moment(startDate, "YYYY-MM-DD", true).isValid()) {
      startDateError.current.classList.remove("invisible");
      startDateError.current.innerText = translations.enter_contract_start_date;
      isError = true;
    } else {
      startDateError.current.classList.add("invisible");
    }
    if (!expiryDate || !moment(expiryDate, "YYYY-MM-DD", true).isValid()) {
      expiryDateError.current.classList.remove("invisible");
      expiryDateError.current.innerText = translations.enter_contract_expiry_date;
      isError = true;
    } else {
      expiryDateError.current.classList.add("invisible");
    }
    if (noticePeriodDateRef.current) {
      if (!noticePeriodDateRef.current.value) {
        noticePeriodDateError.current.classList.remove("invisible");
        noticePeriodDateError.current.innerText = translations.enter_contract_notice_period;
        isError = true;
      } else {
        isError = !this.validateNoticePeriod(
          startDateRef.current.value,
          expiryDateRef.current.value,
          noticePeriodDateRef.current.value
        );
      }
    }
    if (contractNotificationIntervalRef.current) {
      if (
        (contractNotificationIntervalRef.current.value && contractNotificationIntervalRef.current.value < 0) ||
        contractNotificationIntervalRef.current.value > 9999
      ) {
        isError = true;
        intervalError.current.classList.remove("invisible");
      } else {
        intervalError.current.classList.add("invisible");
      }
    }
    return isError;
  }

  render() {
    const {
      dateAndAlerts,
      noticePeriodInterval,
      stakeHoldersBox,
      includedStakeholders,
      includedSuppliers,
      includeNotes,
      noticePeriod,
      alertDate,
    } = this.state;
    const { translations, showPage, stakeholders, suppliers } = this.props;
    const interval = dateAndAlerts.interval;
    return (
      <div className="col-md-12 col-sm-12" ref={this.datesAndAlertsSection}>
        <div className="form-section-block">
          <ContractTooltip translations={translations} titleText={"contract_dates_and_alerts"} />
          <div className="col-12 float-left date-field">
            <label>
              {translations.start_date}
              {!showPage && <sup>*</sup>}
            </label>
            {showPage ? (
              <input
                size={10}
                autoComplete="off"
                name="start_date"
                className="form-control"
                value={dateAndAlerts.start_date}
                placeholder={translations.select}
                disabled
              />
            ) : (
              <input
                size={10}
                autoComplete="off"
                className="form-control datepicker-for-contract"
                defaultValue={dateAndAlerts.start_date ? dateAndAlerts.start_date : ""}
                placeholder={translations.select}
                id="start_date"
                ref={this.startDateRef}
              />
            )}
            <span className="error-text invisible" ref={this.startDateError} />
          </div>
          <div className="col-12 date-field float-left">
            <label>
              {translations.expiry_date}
              {!showPage && <sup>*</sup>}
            </label>
            {showPage ? (
              <input
                size={10}
                autoComplete="off"
                name="expiry_date"
                className="form-control"
                value={dateAndAlerts.expiry_date}
                disabled
              />
            ) : (
              <input
                size={10}
                autoComplete="off"
                className="form-control datepicker-for-contract"
                defaultValue={dateAndAlerts.expiry_date ? dateAndAlerts.expiry_date : ""}
                placeholder={translations.select}
                id="expiry_date"
                ref={this.expiryDateRef}
              />
            )}
            <span className="error-text invisible" ref={this.expiryDateError} />
          </div>
          <div className={dateAndAlerts.start_date && dateAndAlerts.expiry_date ? "" : "invisible sd-hidden"}>
            {/* this condition will check if the field is visible for contract and show page or not */}
            {((dateAndAlerts.notice_period !== undefined && !showPage) ||
              (showPage && dateAndAlerts.notice_period)) && (
              <Fragment>
                <div className="col-12 form-group notice-period ml-5">
                  <label>
                    {translations.notice_period}
                    {!showPage && <sup>*</sup>}
                  </label>
                  {showPage ? (
                    <input
                      autoComplete="off"
                      name="notice_period"
                      className="form-control"
                      value={translations[noticePeriodInterval]}
                      disabled
                    />
                  ) : (
                    <select
                      className="form-control"
                      value={noticePeriodInterval}
                      onChange={(e) => this.handleNoticePeriodChange(e)}
                    >
                      <option value="one_month">{translations.one_month}</option>
                      <option value="three_month">{translations.three_month}</option>
                      <option value="six_month">{translations.six_month}</option>
                      <option value="twelve_month">{translations.twelve_month}</option>
                      <option value="other">{translations.other}</option>
                    </select>
                  )}
                </div>
                <div className="col-12 date-field float-left">
                  <label className="sd-hidden">&nbsp;</label>
                  {showPage ? (
                    <input className="form-control" value={dateAndAlerts.notice_period} disabled />
                  ) : (
                    <input
                      className="form-control datepicker-for-contract"
                      defaultValue={noticePeriod}
                      ref={this.noticePeriodDateRef}
                      disabled={noticePeriodInterval !== "other"}
                      id="notice_period"
                      onBlur={(e) => this.handleNoticePeriodDateChange(e)}
                      autoComplete="off"
                    />
                  )}
                  <span className="error-text invisible" ref={this.noticePeriodDateError}>
                    {translations.required}
                  </span>
                </div>
              </Fragment>
            )}

            {((!showPage && noticePeriod) || (showPage && dateAndAlerts.alert_date && noticePeriod)) && (
              <Fragment>
                <div className="col-12 notification_interval_id notification_alert_date float-left">
                  <label>{translations.alert_date}</label>
                  {showPage ? (
                    <input className="form-control" value={alertDate} disabled />
                  ) : (
                    <input
                      type="number"
                      name="contract_notification_interval"
                      ref={this.alertDateRef}
                      autoComplete="off"
                      size={10}
                      className="form-control"
                      onBlur={() => this.handleAlertDateValueChange()}
                      defaultValue={alertDate}
                      disabled={noticePeriod ? false : true}
                    />
                  )}
                  <span className="error-text invisible" ref={this.alertIntervalError}>
                    {translations.alert_interval_error}
                  </span>
                </div>
                <div className="col-12 notification_interval_unit">
                  <label>&nbsp;</label>
                  <div className="small">{translations.weeks + " " + translations.before_notice_period}</div>
                </div>
              </Fragment>
            )}

            {((interval !== undefined && !showPage) || (showPage && !isEmpty(interval))) && (
              <Fragment>
                <div
                  className={
                    (!showPage && noticePeriod) || (showPage && dateAndAlerts.alert_date && noticePeriod)
                      ? "float-left w-100"
                      : ""
                  }
                >
                  <div className="col-12 notification_interval_id float-left">
                    <label>{translations.alert_me_every}</label>
                    {showPage ? (
                      <input className="form-control" value={Object.keys(interval)[0]} disabled />
                    ) : (
                      <input
                        type="number"
                        name="contract_notification_interval"
                        ref={this.contractNotificationIntervalRef}
                        autoComplete="off"
                        size={10}
                        className="form-control"
                        id="interval"
                        onBlur={() => this.handleNotificationAlertValueChange()}
                        defaultValue={Object.keys(interval)[0]}
                      />
                    )}
                    <span className="error-text invisible" ref={this.intervalError}>
                      {translations.interval_error}
                    </span>
                  </div>
                  <div className="col-12 notification_interval_unit">
                    <label>&nbsp;</label>
                    {showPage ? (
                      <input
                        name="contract_notification_interval_unit"
                        className="form-control"
                        value={interval[Object.keys(interval)[0]]}
                        disabled
                      />
                    ) : (
                      <select
                        name="contract_notification_interval_unit"
                        className="form-control"
                        id="interval_unit"
                        defaultValue={interval[Object.keys(interval)[0]]}
                        ref={this.contractNotificationIntervalUnitRef}
                        onChange={() => this.handleNotificationAlertValueChange()}
                      >
                        <option value="days">{translations.days}</option>
                        <option value="weeks">{translations.weeks}</option>
                        <option value="months">{translations.months}</option>
                        <option value="years">{translations.years}</option>
                      </select>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
            {dateAndAlerts.auto_renew !== undefined && (
              <div className="col-12 float-left auto-renew">
                {showPage && dateAndAlerts.auto_renew && (
                  <Fragment>
                    <input type="checkbox" name="auto_renew" id="auto_renew" checked disabled />
                    <label htmlFor="auto_renew">{translations.this_contract_auto_renew}</label>
                  </Fragment>
                )}
                {!showPage && (
                  <Fragment>
                    <input
                      type="checkbox"
                      name="auto_renew"
                      id="auto_renew"
                      checked={dateAndAlerts.auto_renew}
                      onChange={(e) => this.handleAutoRenewChange(e)}
                    />
                    <label htmlFor="auto_renew">{translations.this_contract_auto_renew}</label>
                  </Fragment>
                )}
              </div>
            )}
            {!showPage && dateAndAlerts.default_notification && (
              <div className="col-12 expiry-notification sd-hidden">
                <ExpiryNotificationModal
                  stakeHoldersBox={stakeHoldersBox}
                  includedStakeholders={includedStakeholders}
                  includedSuppliers={includedSuppliers}
                  includeNotes={includeNotes}
                  showHideStakeholderbox={(flag) => this.showHideStakeholderbox(flag)}
                  handleIncludeRecipients={(e, all, stakeholder, userId) =>
                    this.handleIncludeRecipients(e, all, stakeholder, userId)
                  }
                  handleChange={(e) => this.handleChange(e)}
                  updateExpiryNotification={() => this.updateExpiryNotification()}
                  translations={translations}
                  suppliers={suppliers}
                  stakeholders={stakeholders}
                />
              </div>
            )}
          </div>
          <p className="clear-both" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ createEditReducers }) => {
  return {
    updatedDatesAndAlerts: createEditReducers.updatedDatesAndAlerts,
    dateAndAlerts: createEditReducers.dateAndAlerts,
    contractId: createEditReducers.contract.id,
    suppliers: createEditReducers.contractUserData.suppliers.added,
    stakeholders: createEditReducers.contractUserData.stakeholders.added,
    translations: createEditReducers.translations,
    showPage: createEditReducers.showPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateDateAndAlertsValue: (contractId, data) => dispatch(updateDateAndAlertsValue(contractId, data)),
  updateContractNotificationInterval: (contractId, unit, interval) =>
    dispatch(updateContractNotificationInterval(contractId, unit, interval)),
  updateRecipientsForNotification: (contractId, notificationId, includeNotes, suppliers, stakeholders) =>
    dispatch(updateRecipientsForNotification(contractId, notificationId, includeNotes, suppliers, stakeholders)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(DatesAndAlertsForm);
