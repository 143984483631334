import React from "react";

import { InputPropTypes } from "../input/input";
import { InternalCheckboxRadio } from "../checkbox/internal-checkbox-radio";

/**
 * @type {React.FC<PropTypes.InferProps<typeof <InputPropTypes>>}
 */
export const Radio = (props) => <InternalCheckboxRadio {...props} type="radio" />;

export default Radio;
