import React, { Component } from "react";
import Tooltip from "../../../common/Tooltip";

class AutoReminderEmail extends Component {
  componentDidUpdate() {
    this.setValue();
  }

  setValue = () => {
    this.timeForAutoReminder.value = this.props.configuration_setting_values.auto_reminder_email_days.split(".")[0];
  };

  timeForAutoReminderUpdate = (e) => {
    let temp = e.target.value + ".days";
    this.props.onValueChange(temp, "auto_reminder_email_days");
  };
  render() {
    const { translations, configuration_setting_values } = this.props;
    return (
      <div className="form-item-block">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="form-item-block-title">{translations.auto_reminder_emails}</div>
            <div className="input inline m-b10">
              <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0" />
              <input
                ref={(ref) => (this.timeForAutoReminder = ref)}
                type="text"
                name="auto_reminder_email_days"
                id="auto_reminder_email_days"
                defaultValue={configuration_setting_values.auto_reminder_email_days.split(".")[0]}
                className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
                size="5"
                onBlur={(e) => this.timeForAutoReminderUpdate(e)}
              />
              <div className="vertical-center m-r10 m-l10">
                <span>{translations.days}</span>
              </div>
              <div className="vertical-center">
                <Tooltip {...translations.configuration.auto_reminder_emails_tooltip} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AutoReminderEmail;
