import React, { useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useAttribute, useListAttribute } from "@adeattwood/react-form";
import { useDispatch, useSelector } from "react-redux";
import { useCompanyId } from "./company-id-context";
import { updateCustomFieldPosition } from "@/actions/customField";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { createSelector } from "@reduxjs/toolkit";
import TableRow from "./table-row";
import { AuthPropType } from ".";
import { t } from "@/i18n";
import features from "@/utils/features";
import { prepareCustomField } from "./table-row-edit-state";

const sortByKey = (key) => (a, b) => (a[key] < b[key] ? -1 : 1);
const customFieldsSelector = createSelector(
  (state) => state.customFieldsReducers.byId,
  (cutomFields) => {
    return Object.values(cutomFields).sort(sortByKey("position"));
  }
);

const searchMatches = (searchTerm, customField) => {
  const searchTermLower = searchTerm.toLowerCase();
  if (customField.name.toLowerCase().includes(searchTermLower)) {
    return true;
  }

  return false;
};

const useFilteredItems = (items) => {
  const { value: searchValue } = useAttribute("search");
  return useMemo(() => {
    // Create a map of custom field ids to there original index before we sort
    // and filter them. When we are editing the fields we need to know what one
    // we are editing in the full list of custom fields. This will allow us to
    // get back to the the original index of the custom field before we sorted
    // and filtered them so we don't update the properties on the wrong custom
    // field.
    const idIndexMap = items.reduce((current, item, index) => {
      current[item.id] = index;
      return current;
    }, {});

    let outputItems = items;
    if (searchValue) {
      outputItems = outputItems.filter((item) => searchMatches(searchValue, item));
    }

    return [outputItems, idIndexMap];
  }, [items, searchValue]);
};

const useKeepCustomFieldsUptoDate = (set) => {
  const customFields = useSelector(customFieldsSelector);
  const currentItems = useRef(customFields);
  useEffect(() => {
    if (currentItems.current !== customFields) {
      currentItems.current = customFields;
      // TODO(AdeAttwood): Find a better way todo a deep copy. Redux returns use
      // read only references to the data so we cannot manipulate it when
      // editing the form
      set(JSON.parse(JSON.stringify(customFields)));
    }
  }, [customFields, set]);
};

const TableBodyPropTypes = {
  auth: PropTypes.shape(AuthPropType).isRequired,
};

const useOnDragEnd = (value, reorder, auth) => {
  const dispatch = useDispatch();
  const companyId = useCompanyId();

  return async ({ source, destination }) => {
    // If there is no destination the item was dropped outside of the for and we
    // don't need to do anything, all the items can be set to the original order.
    if (!destination) {
      return;
    }

    // Update the order in the form so the UI will update correctly and the
    // items don't jump around
    reorder(source.index, destination.index);

    const customField = { ...value[source.index] };
    // The position is 1 based index and our array is 0 based so we need to add
    // one to get the correct position number.
    customField.position = destination.index + 1;

    delete customField.custom_column_item_types;

    await Promise.resolve(
      dispatch(updateCustomFieldPosition({ companyId, customField: prepareCustomField(auth, customField) }))
    );
  };
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof TableBodyPropTypes>>}
 */
const TableBody = ({ auth }) => {
  const { value, reorder, set } = useListAttribute("customFields");
  const [items, idIndexMap] = useFilteredItems(value);
  const onDragEnd = useOnDragEnd(value, reorder, auth);
  useKeepCustomFieldsUptoDate(set);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <tbody {...provided.droppableProps} ref={provided.innerRef}>
            {items.length === 0 ? (
              <tr>
                <td colSpan={features.enabled("sourcing_custom_fields") ? 7 : 6} align="center">
                  {t("centralised_custom_fields.no_results")}
                </td>
              </tr>
            ) : (
              items.map((item) => (
                <TableRow key={item.id.toString()} customField={item} index={idIndexMap[item.id]} auth={auth} />
              ))
            )}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

TableBody.propTypes = TableBodyPropTypes;

export default TableBody;
