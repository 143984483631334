import axios from "axios";

function headers() {
  const csrfToken = document.querySelector("meta[name=csrf-token]")
    ? document.querySelector("meta[name=csrf-token]").content
    : "";

  return {
    common: {
      "X-CSRF-Token": csrfToken,
      "X-Requested-With": "XMLHttpRequest",
    },
  };
}

export const get = (url) => axios({ method: "get", url, headers: headers() });
export const post = (url, data) => axios({ method: "post", url, data, headers: headers() });
export const put = (url, data) => axios({ method: "put", url, data, headers: headers() });
export const remove = (url, data) => axios({ method: "delete", url, data, headers: headers() });
