// import SUPPORTED_LOCALES from '../public/locales/languages';
import SUPPORTED_LOCALES from './languages';

const formatData = (lang, currencySymbol, data) => {
  if (lang && lang.length) {
    const language = SUPPORTED_LOCALES.filter((item) => item.className === lang);
    const formater = new Intl.NumberFormat([lang], { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    // return (language[0].front ? `${currencySymbol} ` : '') + formater.format(data) + (!language[0].front ? ` ${currencySymbol}` : '');
    return (`${currencySymbol} `) + formater.format(data);
  }
  return data;
};

export default formatData;
