import React, { Component } from "react";
import PropTypes from "prop-types";

import EditorPopup from "./EditorPopup";
import Loader from "react-loader-advanced";
import LoaderMessage from "../../LoaderMessage";
import RenderHTML from "@/components/common/RenderHTML";

/**
 * @typedef {PropTypes.InferProps<EditorPopupSection.propTypes>} Props
 * @extends Component<Props>
 */
export class EditorPopupSection extends Component {
  static propTypes = {
    /**
     * The translations for displaying the content in the correct language for
     * the user
     */
    translations: PropTypes.object.isRequired,
    /**
     * The inital value for the editor
     */
    text: PropTypes.string.isRequired,
    /**
     * The section this editor is for
     */
    sectionKey: PropTypes.string.isRequired,
    /**
     * The ID of the company
     */
    companyId: PropTypes.number.isRequired,
    /**
     * A callback function that will be called when the user clicks submit and
     * saves the content in the editor. The first param will be 
     */
    updateText: PropTypes.func.isRequired,
    /**
     * Toggle to display the loader for the component
     *
     * @default false
     */
    show: PropTypes.bool,
  };

  render() {
    const { translations, show, ...rest } = this.props;

    return (
      <div className="col-md-6 p-r0 table-cell-box hidden-xs">
        <Loader
          show={show || false}
          message={<LoaderMessage loaderText={translations.loader_text} />}
          disableDefaultStyles
        >
          <div className="form-item-block border-left same-height-block">
            <EditorPopup {...rest} translations={translations} />
            <div>
              <RenderHTML content={rest.text} />
            </div>
          </div>
        </Loader>
      </div>
    );
  }
}

export default EditorPopupSection;
