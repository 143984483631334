import React from "react";
import PropTypes from "prop-types";
import Tooltip from "./Tooltip";

/**
 * this component is used to display a table's cell
 * with or without a tooltip
 * @prop {string|number} text - the text to display
 * @prop {string} [classes] - the classes to apply to the cell
 * @prop {string} [title] - the title to display in the tooltip
 * @prop {string} [body] - the body to display in the tooltip
 * @prop {boolean} [hidden] - if true, the cell is hidden
 * @returns {JSX.Element} the component
 * @example <TableCell text="cell's content" />
 */
class TableCell extends React.PureComponent {
  render() {
    const { text, classes, title, body, hidden } = this.props;
    return (
      Boolean(!hidden) && (
        <React.Fragment>
          <td className={classes}>
            {text} {Boolean(title) && <Tooltip title={title} body={body} />}
          </td>
        </React.Fragment>
      )
    );
  }
}

// default values
// note: no default value for text as it is required
TableCell.defaultProps = {
  classes: "",
  title: null,
  body: null,
  hidden: false,
};

// prop types
TableCell.propTypes = {
  // text can be a string or a number, and it is required
  // if a different type is passed, a warning will be displayed in console
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // if .isRequired is not used, the default value will be used instead
  classes: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  hidden: PropTypes.bool,
};

export default TableCell;
