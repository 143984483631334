import React from "react";
import {
  differenceOfferedPercentageBeforeAuctionStarted,
  formatDifferenceOfferedPercentage,
  differenceOfferedBeforeAuctionStarted,
  differenceOfferedWithCurrency,
} from "../../../bidCommon";
import { isLotTotalPresent } from "../../../common";

export default function SavingOffered(props) {
  const {
    lot,
    event,
    eventParticipants,
    bids,
    isMultiCurrencyEvent,
    translations,
    locale,
    currency,
    bestBidBeforeAuction,
  } = props;

  const differenceOfferedPercentage = differenceOfferedPercentageBeforeAuctionStarted(
    event,
    lot,
    eventParticipants,
    bids,
    bestBidBeforeAuction,
    isMultiCurrencyEvent
  );
  const result = formatDifferenceOfferedPercentage(
    differenceOfferedPercentage,
    translations.na_in_small_letters,
    locale
  );
  let diffValue = differenceOfferedBeforeAuctionStarted(
    event,
    lot,
    bids,
    eventParticipants,
    bestBidBeforeAuction,
    isMultiCurrencyEvent
  );
  const diffValueWithCurrency = differenceOfferedWithCurrency(diffValue, currency, locale, translations.number_format);
  const isLotTotal = isLotTotalPresent(lot);

  if (!isLotTotal)
    return (
      <div className="mycell difference big-cell" title={"-"}>
        -
      </div>
    );
  return <SavingBlock diffValueWithCurrency={diffValueWithCurrency} result={result} />;
}

export const SavingBlock = ({ diffValueWithCurrency = "", result = {} }) => {
  return (
    <div className="mycell difference big-cell" title={`${diffValueWithCurrency}(${result["value"]})`}>
      <span className="value">{diffValueWithCurrency}</span>
      <span className={`percentage diff-${result["class"]}`}>({result["value"]})</span>
    </div>
  );
};
