import React from "react";
import PropTypes from "prop-types";

const LabelPropTypes = {
  /**
   * The input id this label is associated to. This will be use as the `for`
   * attribute on the element.
   */
  id: PropTypes.string.isRequired,
  /**
   * The label content. If nothing is passed in then this will render nothing.
   */
  label: PropTypes.string,
  /**
   * An override to the default class added to the from label
   *
   * @default {'form-label'}
   */
  className: PropTypes.string,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof LabelPropTypes>>}
 */
export const Label = ({ id, label, className }) => {
  if (!label) return null;

  return (
    <label htmlFor={id} className={className || "form-label"}>
      {label}
    </label>
  );
};

Label.propTypes = LabelPropTypes;

export default Label;
