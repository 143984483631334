import React from 'react';
import { connect } from 'react-redux';
import Modal from 'simple-react-modal';

import { toggleSuppliersStatus } from '../../actions/qq/genericActions';

class NewSupplierChkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowSuppliers: this.props.allowSuppliers,
      showModal: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.status.supplierStatus) {
      this.setState({ allowSuppliers: newProps.status.supplierStatus.qq_allow_adding_new_participants });
    } else {
      this.setState({ allowSuppliers: newProps.companyDetails.whitelabel.allow_adding_new_participants_for_quick_quotes });
    }
  }

  toggleSuppliers() {
    const { companyId } = this.props;
    const allowSuppliers = !this.state.allowSuppliers;
    const payload = { company: { allow_adding_new_participants: allowSuppliers } };
    this.props.toggleSuppliersStatus(companyId, payload);
    this.setState({ allowSuppliers });
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { allowSuppliers, showModal } = this.state;
    const { translations } = this.props;
    return (
      <div>
        <a href='#' className='editbox modal-btn-open' onClick={ e => this.openModal(e) }>
          <i className='fa fa-pencil-square-o' aria-hidden='true' />
        </a>
        <div className='main-popup popup-md'>
          <Modal show={ showModal } onClose={ () => this.closeModal() } closeOnOuterClick>
            <div className='vertical-top'>
              <div className='vertical-align-center'>
                <div className='modal-content clearfix'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>{translations.supplier_chkbox.add_new_supplier}</h5>
                    <a
                      href='#'
                      onClick={ (e) => { e.preventDefault(); this.closeModal(); } }
                      className='close-icon modal-btn-close'
                    >
                      <i className='fa fa-times' aria-hidden='true' />
                    </a>
                  </div>
                  <div className='modal-body'>
                    <p>{translations.supplier_chkbox.supplier_info_text}</p>
                    <br />
                    <div>
                      <input
                        type='checkbox'
                        defaultChecked={ allowSuppliers }
                        id='checkbox-supplier'
                        name='checkbox'
                        value='on'
                        onClick={ () => this.toggleSuppliers() }
                      />
                      <label htmlFor='checkbox-supplier' className='m-b0'><span />{translations.supplier_chkbox.permit_invite_by_mail}</label>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-secondary modal-btn-close'
                      onClick={ () => this.closeModal() }
                    >{translations.pop_up.close}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedLang: state.changeLanguageReducer,
  status: state.supplierStatus,
  companyDetails: state.companyDetailsReducer,
});

const mapDispatchToProps = dispatch => ({
  toggleSuppliersStatus: (companyId, payload) => dispatch(toggleSuppliersStatus(companyId, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSupplierChkbox);
