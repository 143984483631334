import { omit } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/**
 * Toggles the filter state and relays the action.
 *
 * @param {Event} e
 * @param {Function} setState
 */
const toggle = (e, setState) => {
  e.preventDefault();
  setState((state) => {
    state.active = !state.active;
    return { ...state };
  });
};

/**
 * Component for showing/activating the filters
 *
 * @param {Object} Props
 * @returns
 */
const DefaultFilters = ({ setFilter }) => {
  const [state, setState] = useState({ active: false });
  const { translations, current_user } = useSelector((state) => state.ActivityReducer);

  useEffect(() => {
    setFilter((fState) => {
      if (!state.active) {
        fState.myFilters = false;
        return omit(fState, "owners");
      } else {
        return {
          myFilters: true,
          owners: [current_user.id],
        };
      }
    });
  }, [state.active]);

  return (
    <div className="pull-right">
      <a className="btn btn-default btn-blue-outline btn-sm m-b0" onClick={(e) => toggle(e, setState)}>
        {state.active ? translations.all_activities : translations.my_activities}
      </a>
    </div>
  );
};

DefaultFilters.propTypes = {
  setFilter: PropTypes.func.isRequired,
};

export default DefaultFilters;
