import actionsTypes from "@/actions/actionsTypes";
import { cancelBid, submitBid } from "@/actions/bidActions";
import { greenTick } from "@/actions/commonActions";
import { closeBidModal } from "@/actions/lotActions";
import { useDispatch, useSelector } from "react-redux";

// eslint-disable-next-line no-unused-vars
import { Event, Lot, Bid } from "@/common-prop-types";

/**
 * @typedef {{bid: Bid, commit: string, before_auction: string | undefined, auction_tab: string | undefined}} payload
 */

/**
 *
 * @param {Event} event
 * @param {Bid} bid
 * @param {Lot} lot
 * @param {boolean} isAuction
 * @returns {payload} payload
 */
const createPayload = (event, bid, lot, isAuction) => {
  return {
    bid: {
      id: bid.id,
      event_id: event.id,
      lot_id: lot.id,
      user_id: event.user_id,
    },
    commit: "Submit",
    ...(isAuction ? { before_auction: "false" } : { auction_tab: "true" }),
  };
};

const useSubmitHandler = () => {
  const dispatch = useDispatch();
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);

  const dispatchError = (lotId, error) =>
    dispatch({
      type: actionsTypes.LOT_ERROR,
      lotErrors: { [lotId]: error },
    });

  /**
   *
   * @param {payload} payload
   * @param {boolean} isDisplayingError
   * @param {boolean} enableSubmit
   * @returns {Promise<boolean>} isPlaceBidActive
   */
  return async (payload, isDisplayingError, enableSubmit) => {
    // return if Submit button is not enabled
    if (!enableSubmit) {
      dispatch({
        type: actionsTypes.ERROR,
        error: translations.does_not_meet_qualification_price,
      });
      dispatch(closeBidModal());
      return true;
    }

    const lotId = payload.bid.lot_id;
    const bidId = payload.bid.id;

    // submit bid
    const res = await dispatch(submitBid(lotId, bidId, payload));

    const responseHasError = !!res?.error?.[0];
    if (isDisplayingError) dispatchError(lotId, responseHasError ? res.error : []);

    return responseHasError;
  };
};

/**
 *
 * @param {Bid} bid
 * @param {Lot} lot
 * @param {() => void} setLotState
 * @param {boolean} enableSubmit
 * @param {() => void} callback Callback to be called after every action
 * @returns {{handleBidSubmit: () => Promise<void>, handleCancelBid: () => Promise<void> }} Action handlers
 */
const useLotActions = (bid, lot, setLotState, enableSubmit, cb) => {
  const event = useSelector(({ lotReducers }) => lotReducers.event);

  const dispatch = useDispatch();
  const bidSubmitHandler = useSubmitHandler();

  return {
    handleBidSubmit: async (isLotLevelMonitor, isAuction) => {
      setLotState({ isBidSubmitting: true });

      const payload = createPayload(event, bid, lot);
      const isDisplayingError = isAuction && isLotLevelMonitor;
      const isPlaceBidActive = await bidSubmitHandler(payload, isDisplayingError, enableSubmit);

      cb();
      setLotState({ isPlaceBidActive, isBidSubmitting: false });

      dispatch(closeBidModal());
      greenTick();
    },

    handleCancelBid: async () => {
      await dispatch(cancelBid(lot.id, bid?.id));
      setLotState({ isPlaceBidActive: false });

      cb();

      // No need to set null value for the Japanese or Lot level event.
      // Your bid will always be visible to the user.
      // if (event.bid_at_detail_level) setLotTotalBLic(null);
      window.advancedLots.openSuccessModal = false;
    },
  };
};

export default useLotActions;
