import { updateCustomFields } from "@/actions/contract/contractCreateEditActions";
import * as actionTypes from "../../actions/actionsTypes";

const createEditReducers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTRACT_INITIAL_PROPS:
      return { ...state, ...action.payload };
    case actionTypes.UPDATE_BASIC_DETAILS_FIELD_VALUE:
      return { ...state, basicDetails: action.payload, updatedBasicDetails: action.payload };
    case updateCustomFields.fulfilled.type:
      return { ...state, customFields: action.payload, updatedCustomFields: action.payload };
    case actionTypes.UPDATE_STAKEHOLDERS:
      return mergeSupplierOrStakeholder(state, action.payload, "stakeholders");
    case actionTypes.UPDATE_SUPPLIERS:
      return mergeSupplierOrStakeholder(state, action.payload, "suppliers");
    case actionTypes.UPDATE_CUSTOMNOTIFICATIONS:
      return { ...state, customNotifications: action.payload };
    case actionTypes.UPDATE_NOTES:
      return { ...state, contractNotes: action.payload };
    case actionTypes.UPDATE_DATESANDALERTS:
      return { ...state, dateAndAlerts: action.payload, updatedDatesAndAlerts: action.payload };
    case actionTypes.UPDATE_HISTORY:
      return { ...state, history: action.payload };
    default:
      return state;
  }
};

const mergeSupplierOrStakeholder = (state, payload, keyToMerge) => {
  return {
    ...state,
    contractUserData: {
      ...state.contractUserData,
      [keyToMerge]: {
        ...state.contractUserData[keyToMerge],
        added: payload,
      },
    },
  };
};

export default createEditReducers;
