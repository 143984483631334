import React, { useState } from "react";
import PropTypes from "prop-types";
import EditNoteModal from "./EditNoteModal";
import ContractTooltip from "./ContractTooltip";
import NotesList from "./NotesList";
import TrixEditor from "@/components/common/TrixEditor";
import useCreateNote from "@/components/advanced_lots/hooks/contract/useCreateNote";
import { useSelector } from "react-redux";

const CreateNotePropTypes = {
  translations: PropTypes.any,
  contractId: PropTypes.number,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof NotesFormPropTypes>>}
 */
export default function NotesForm() {
  const translations = useSelector((state) => state.createEditReducers.translations);
  const contractId = useSelector((state) => state.createEditReducers.contract.id);
  const contractNotes = useSelector((state) => state.createEditReducers.contractNotes);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    note: {},
  });

  const openModal = (note) => setModalState({ isModalOpen: true, note: note });
  const closeModal = () => setModalState({ isModalOpen: false });

  const { isModalOpen, note } = modalState;

  return (
    <div className="col-md-12 col-sm-12">
      <div className="form-section-block notes-section">
        <ContractTooltip translations={translations} titleText="notes" />
        <CreateNote {...{ translations, contractId }} />
        <NotesList {...{ contractId, contractNotes, openModal, translations }} />
        <p className="clear-both" />
        {isModalOpen && <EditNoteModal {...{ isModalOpen, note, closeModal, contractId, translations }} />}
      </div>
    </div>
  );
}

/**
 * @type {React.FC<PropTypes.InferProps<typeof CreateNotePropTypes>>}
 */
const CreateNote = ({ translations, contractId }) => {
  const { formState, handleAddNote, handleCheckBoxChange, handleContentChange, isLoading } = useCreateNote(contractId);

  return (
    <>
      <div className="form-group col-12">
        <TrixEditor onChange={handleContentChange} value={formState.content} />
      </div>
      <div className="col-12 w-100 mb-4">
        <input
          type="checkbox"
          name="make_private_note"
          id="make_private_note"
          onChange={handleCheckBoxChange}
          checked={formState.make_private_note}
        />
        <label htmlFor="make_private_note" className="mr-2">
          {translations.make_this_a_private_note}
        </label>
        <input
          type="checkbox"
          name="bookmark_note"
          id="bookmark_note"
          onChange={handleCheckBoxChange}
          checked={formState.bookmark_note}
        />
        <label htmlFor="bookmark_note">{translations.bookmark_note}</label>
        <div className="pull-right">
          <button
            name="add_notes_btn"
            className="btn btn-sm btn-default"
            style={{
              color: "#ff8c00",
              borderColor: "#ff8c00",
              float: "left",
              height: "37px",
              paddingTop: "8px",
            }}
            onClick={handleAddNote}
            disabled={isLoading}
          >
            {translations.add}
          </button>
        </div>
      </div>
    </>
  );
};
CreateNote.propTypes = CreateNotePropTypes;
