import React, { useCallback, useMemo, useState, memo } from "react";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { bestBlic, findBid, fetchAssociatedBlicOrBidCurrency } from "../../../bidCommon";
import { multiCurrencyEvent, findUnitSet, isLiTotalPresent } from "../../../common";
import LiWeightedScore from "./LiWeightedScore";
import LiBestBidValue from "./LiBestBidValue";
import LiSavingPercentage from "./LiSavingPercentage";
import LiActiveParticipantCount from "./LiActiveparticipantCount";
import LeadParticipant from "../lotInformationForOverview/LeadParticipant";
import { useDispatch } from "react-redux";
import { deleteBid } from "../../../../../actions/bidActions";
import ProgressionCharts from "../lotInformationForOverview/ProgressionCharts";

const initialState = {
  preUser: null,
  preParticipant: null,
  preLeadParticipantName: null,
  deletedBid: null,
  isOpen: false,
};

/**
 *
 * @description Host monitor
 *
 * Row - WEIGHTED SCORE | BEST BID VALUE | LEAD PARTICIPANT | SAVINGS OFFERED | ACTIVE PARTICIPANTS
 *
 */
const LineItemBidInformation = memo((props) => {
  const { lineItemLic = {}, currenciesHash, licCurrency, bestAllBlics, displayScores, lineItem, allUnitSets } = props;

  const {
    event,
    bids,
    is_japanese_event: isJapaneseEvent,
    translations: { current_bids },
    unit_set_unit_of_measures: unitSetUoms,
    unit_of_measures: unitOfMeasures,
  } = useSelector((state) => state.lotReducers);

  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);

  const bestBlics = bestAllBlics[lineItemLic?.id] || [];
  const lineItemBestBlics = useMemo(
    () => bestBlic(event, bestBlics, props.weightedRfqOrAuction, lineItemLic),
    [bestBlics, lineItemLic]
  );
  const lineItemBestBlic = lineItemBestBlics[0];
  const bid = lineItemBestBlic && findBid(bids, lineItemBestBlic.bid_id);

  const blicCurrency = useMemo(
    () =>
      lineItemBestBlic && multiCurrencyEvent(event)
        ? fetchAssociatedBlicOrBidCurrency(currenciesHash, bid, lineItemLic)
        : licCurrency,
    [bid, lineItemBestBlic, lineItemLic]
  );

  const unitSet = findUnitSet(allUnitSets, lineItemLic?.unit_set_id);
  const allUom = unitSetUoms?.concat(unitOfMeasures);
  const header = `${current_bids}: ${lineItem?.name}`;

  const removeBid = useCallback(
    (deletedBid, beforeAuction) => {
      setState((state) => ({ ...state, deletedBid: deletedBid }));
      dispatch(deleteBid(deletedBid, beforeAuction));
    },
    [bid]
  );
  const openCharts = useCallback((isOpen) => setState((state) => ({ ...state, isOpen })), []);
  const resetState = useCallback(() => setState(initialState), []);

  const leadParticipantAndSavingValue = [
    // SAVINGS OFFERED
    <LiSavingPercentage
      {...props}
      key={"saving-" + lineItemLic?.id}
      lineItemLic={lineItemLic}
      blicCurrency={blicCurrency}
      licCurrency={licCurrency}
      allUom={allUom}
      lineItemBestBlic={lineItemBestBlic}
      bid={bid}
    />,
    // LEAD PARTICIPANT
    <LeadParticipant
      {...props}
      key={"leadParticipant-" + lineItemLic.id}
      removeBid={removeBid}
      resetState={resetState}
      lineItemLic={lineItemLic}
      lineItemBestBlics={lineItemBestBlics}
      isLineItem={true}
    />,
  ];

  return (
    <div className="mycell lot-information">
      <div className="row">
        {!props.bidsSealed && props.permissionToView ? (
          <>
            {/* WEIGHTED SCORE */}
            <LiWeightedScore
              {...props}
              key={"weighted_score-" + lineItemLic?.id}
              displayScores={displayScores}
              lineItem={lineItem}
              lineItemBestBlic={lineItemBestBlic}
            />
            {/* BEST BID VALUE */}
            <LiBestBidValue
              {...props}
              key={"bid_value-" + lineItemLic?.id}
              lineItemLic={lineItemLic}
              licCurrency={licCurrency}
              blicCurrency={blicCurrency}
              lineItemBestBlic={lineItemBestBlic}
              bid={bid}
              unitSet={unitSet}
              allUom={allUom}
              header={header}
            />
            {isJapaneseEvent ? (
              <>
                <div className="mycell">&nbsp;</div>
                {leadParticipantAndSavingValue.map((component) => component)}
              </>
            ) : (
              <>{leadParticipantAndSavingValue.reverse().map((component) => component)}</>
            )}
            {/* ACTIVE PARTICIPANTS */}
            <LiActiveParticipantCount {...props} key={"participant-" + lineItemLic?.id} />
            {/* PROGRESSION CHART  */}
            {isLiTotalPresent(lineItem) && (
              <ProgressionCharts {...props} isOpen={state.isOpen} openCharts={openCharts} modalTitle={lineItem?.name} />
            )}
          </>
        ) : (
          <div className={`mycell ${props.weightedRfqOrAuction ? "weighted-" : ""} sealedcell`}>&nbsp;</div>
        )}
      </div>
    </div>
  );
}, isEqual);

export default LineItemBidInformation;
