import React from "react";
import TrixEditor from "@/components/common/TrixEditor";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { MessageInputProps } from "./prop-types";
import { isIE } from "@/components/advanced_lots/common";

/**
 *
 * @type {React.FC<PropTypes.InferProps<MessageInputProps>>}
 * @returns {React.ReactElement}
 */
const MessageInput = ({ label, message, handleMessageChange }) => {
  const isItIE = isIE();
  return (
    <div className="form-group">
      <div className="input">
        <label className="message_message">{label}</label>
        {isItIE ? (
          <textarea value={message} className="message_area p10" onChange={handleMessageChange}></textarea>
        ) : (
          <TrixEditor onChange={handleMessageChange} value={message} />
        )}
      </div>
    </div>
  );
};

MessageInput.propTyes = MessageInputProps;
MessageInput.defaultPropTyes = {
  label: "",
  message: "",
  handleMessageChange: () => {},
};

export default MessageInput;
