import React, { Component } from "react";
import { connect } from "react-redux";
import { anyComplexLotsExist, hostOverviewTableClasses, isLotTotalPresent } from "../../common";
import { isRfq } from "../../eventCommon";
import HostMonitorActions from "./HostMonitorActions";
import LotSummary from "./LotSummary";
import { getToggleValue } from "../../customCollapse";
import { AnswerProvider } from "../../../../context/AnswerContext";
import ReviewAwardLot from "../ReviewAwardLot";
import EventRoundTabs from "./msrfq/EventRoundTabs";

class HostPendingEventLots extends Component {
  constructor(props) {
    super(props);
    this.toggleLiCollapse = this.toggleLiCollapse.bind(this);
    this.toggleEpCollapse = this.toggleEpCollapse.bind(this);
    this.toggleLcCollapse = this.toggleLcCollapse.bind(this);
    this.state = {
      isLiOpened: this.props.lots.filter((lot) => !isLotTotalPresent(lot)).map((lot) => lot.id),
      isLcOpened: [],
      isEpOpened: false,
      bids: props.bids,
      bidLineItemComponents: props.bid_line_item_components,
      participatingParticipants: props.participating_participants,
      epIds: props.participating_participants.map((ep) => ep.id),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const blics = nextProps.bidLineItemComponents
      ? nextProps.bidLineItemComponents
      : nextProps.bid_line_item_components;
    const pp = nextProps.participatingParticipants
      ? nextProps.participatingParticipants
      : nextProps.participating_participants;
    return {
      bids: [...nextProps.bids],
      bidLineItemComponents: [...blics],
      participatingParticipants: [...pp],
      epIds: pp.map((ep) => ep.id),
    };
  }

  // isLiOpened contain lots id as line item details will be shown according to
  // lot means if user expand only one lot then LI details for only that lot
  // will be shown.
  toggleLiCollapse(e) {
    const dataLotId = e.target.getAttribute("data-lot-id");
    const lotIds = this.props.lots.map((lot) => lot.id);
    const result = getToggleValue(dataLotId, lotIds, this.state.isLiOpened);
    this.setState({
      isLiOpened: result,
    });
  }

  // isLcOpened contain participants id because when user click on + or - sign
  // right side of the participant name then lot component details are display
  // only for that participant.
  toggleLcCollapse(e) {
    const { isLcOpened } = this.state;
    const dataEpId = e.target.getAttribute("data-ep-id");
    const result = getToggleValue(dataEpId, this.state.epIds, isLcOpened);
    this.setState({
      isLcOpened: result,
    });
  }

  toggleEpCollapse() {
    this.setState({
      isEpOpened: !this.state.isEpOpened,
      isLcOpened: [],
    });
  }
  showMessage(event, translations) {
    let message = "";
    if (event.current_state === "running" || (!isRfq(event) && ["closed", "completed"].includes(event.current_state))) {
      message = <div className="alert alert-info">{translations.show_prev_event_bids}</div>;
    } else if (isRfq(event) && ["post_rfq_pre_auction", "completed", "closed"].includes(event.current_state)) {
      message = <div className="flash caption alert alert-info">{translations.rfq_deadline_passed}</div>;
    }

    return message;
  }

  render() {
    const { event, translations } = this.props;
    const { lots, ...rest } = this.props;
    const values = {
      allQuestionnaires: this.props.all_questionnaires,
      allQuestionnaireAnswers: this.props.all_questionnaire_answers,
    };

    if (!lots?.length) return null;

    return (
      <>
        <div className="container">
          <EventRoundTabs isHost {...this.props} />
          {this.showMessage(event, translations)}
          <div className="browser-upgrade-message notification info">{translations.browser_is_old}</div>
        </div>
        <div
          className={`advance-lot-top ${this.state.isEpOpened ? "padding-responsive" : "container"}`}
          id="host_pending_event_lots"
        >
          <div className={hostOverviewTableClasses(event, lots, this.props.event_participants)} id="HostOverviewTable">
            {!this.props.bids_sealed && (
              <div className="container expand-btns">
                <HostMonitorActions
                  event={event}
                  translations={translations}
                  lots={lots}
                  participatingParticipants={this.state.participatingParticipants}
                  isComplex={anyComplexLotsExist(lots)}
                  toggleLiCollapse={this.toggleLiCollapse}
                  toggleLcCollapse={this.toggleLcCollapse}
                  toggleEpCollapse={this.toggleEpCollapse}
                  isLiOpened={this.state.isLiOpened}
                  isLcOpened={this.state.isLcOpened}
                  isEpOpened={this.state.isEpOpened}
                  beforeAuction={this.props.before_auction}
                />
                <ReviewAwardLot {...rest} bids={this.state.bids} />
              </div>
            )}

            <div className="overview-container-scroll">
              <div className="overview-table" id="lot-content">
                <AnswerProvider value={values}>
                  <LotSummary
                    event={event}
                    lots={lots}
                    isComplex={anyComplexLotsExist(lots)}
                    event={event}
                    translations={translations}
                    participatingParticipants={this.state.participatingParticipants}
                    displayScores={this.props.display_scores}
                    weightedRfqOrAuction={this.props.weighted_rfq_or_auction}
                    permissionToView={this.props.permission_to_view}
                    participants={this.props.participants}
                    currenciesHash={this.props.currencies_hash}
                    eventCurrency={this.props.event_currency}
                    lineItems={this.props.line_items}
                    lotComponents={this.props.lot_components}
                    lineItemComponents={this.props.line_item_components}
                    bids={this.state.bids}
                    beforeAuction={this.props.before_auction}
                    locale={this.props.locale}
                    bidLineItemComponents={this.state.bidLineItemComponents}
                    eventParticipants={this.props.event_participants}
                    unitOfMeasures={this.props.unit_of_measures}
                    allUnitSets={this.props.unit_sets}
                    role={this.props.role}
                    isLiOpened={this.state.isLiOpened}
                    isLcOpened={this.state.isLcOpened}
                    isEpOpened={this.state.isEpOpened}
                    toggleLiCollapse={this.toggleLiCollapse}
                    toggleLcCollapse={this.toggleLcCollapse}
                    permittedToEdit={this.props.permitted_to_edit}
                    bidControls={this.props.bid_controls}
                    auctionControls={this.props.auction_controls}
                    isJapaneseEvent={this.props.is_japanese_event}
                    hosts={this.props.hosts}
                    hostPermission={this.props.host_permission}
                    currentUser={this.props.current_user}
                    timeZoneIdentifier={this.props.time_zone_identifier}
                    eventPassedFinalDeadline={this.props.event_passed_final_deadline}
                    bidRound={this.props.bid_round}
                    toggleEpCollapse={this.toggleEpCollapse}
                    unitSetUoms={this.props.unit_set_unit_of_measures}
                    bidsSealed={this.props.bids_sealed}
                    picklistOptions={this.props.picklist_options}
                    auctionTab={false}
                    canShowDeleteBtn={this.props.can_show_delete_btn}
                    partialBiddingSwitch={this.props.partial_bidding_switch}
                  />
                </AnswerProvider>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ lotReducers, loaderReducers }) => {
  return {
    ...lotReducers,
    declinedParticipantsList: [],
    lotsLoaders: (loaderReducers && loaderReducers.lotsLoaders) || {},
  };
};

export default connect(mapStateToProps, null)(HostPendingEventLots);
