import * as actionTypes from "../actionsTypes";

export const showSuccess = (successMessage) => {
  return { type: actionTypes.SUCCESS, success: successMessage };
};

export const SetInitialState = (response) => {
  return {
    type: actionTypes.SET_INITIAL_ACTIVITY_STATE,
    payload: response,
  };
};

export const discardActivityModalAction = (payload) => ({
  type: actionTypes.TOGGLE_DISCARD_ACTIVITY_MODAL,
  payload,
});

export const setRefreshAction = (payload) => ({
  type: actionTypes.SET_MODAL_REFRESH,
  payload,
});

export const reloadActivityList = () => (dispatch) =>
  dispatch({
    type: actionTypes.RELOAD_ACTIVITY_LIST,
    payload: Math.random() * 1000,
  });

export const switchActivityModalTab = (payload) => (dispatch) =>
  dispatch({
    type: actionTypes.SWITCH_ACTIVITY_TAB,
    payload,
  });
