import type { Contract } from "@/common-prop-types";
import type { CustomFieldType } from "@/common-prop-types/custom-field";
import type { SortDirection } from "@/utils";
import type { EntityState } from "@reduxjs/toolkit";

export enum ColumnType {
  Text,
  Numeric,
  Date,
  Checkbox,
  SinglePick,
  MultiPick,
}

export interface ContractColumns {
  data: Record<string, boolean>;
}

export type Comparison = "" | "gt" | "lt" | "ge";
export type ComparisonFilter<Value> = { type: Comparison; value: Value };

export type FilterMap = {
  [ColumnType.Text]: string;
  [ColumnType.Numeric]: ComparisonFilter<number>;
  [ColumnType.Date]: ComparisonFilter<string>;
  [ColumnType.Checkbox]: boolean[];
  [ColumnType.SinglePick]: string;
  [ColumnType.MultiPick]: string[];
};

export type CustomColumnValues = Record</** Row ID */ string, Record</** Custom column name */ string, unknown>>;

export type Sort = (
  | {
      isCustom?: false;
      column: keyof Contract;
    }
  | {
      isCustom: true;
      column: string;
    }
) & {
  direction: SortDirection;
};

export type Filters = { [Type in ColumnType]: Partial<Record<string, FilterMap[Type]>> };

export interface ListingState extends EntityState<Contract, number> {
  search: string;
  showArchived: boolean;
  canCreateContract: boolean;
  contractColumns: ContractColumns;
  customColumns: EntityState<CustomFieldType, NonNullable<CustomFieldType["name"]>>;
  customColumnValues: CustomColumnValues;
  selected: Partial<Record<number, true>>;
  filteredIds: number[] | undefined;
  filters: Filters;
  customFilters: Filters;
  pagination: {
    page: number;
    perPage: number;
  };
  sort?: Sort;
}
