import React from "react";
import Tagging from "../admin/host/sim_settings/ParticipantProperties";
import LinkQuestions from "./LinkQuestions";

const SrmSettings = (props) => {
  const {
    translations,
    company,
    canEditParticipantTagging,
    linked_questions,
    srm_linked_questions,
    questionnaires,
    sections,
  } = props;
  return (
    <>
      {/* These questionnaires are the already selected questionnaires linked to SRM and not ALL questionnaires of SIM
          Dojo, ALL questionnaires are fetched inside the component when the button is clicked via AJAX */}
      <LinkQuestions
        company={company}
        translations={translations}
        linkedQuestions={linked_questions}
        srmLinkedQuestions={srm_linked_questions}
        questionnaires={questionnaires}
        sections={sections}
        currentUserEditConfigPermission={canEditParticipantTagging}
      />
      <Tagging {...props} />
    </>
  );
};

export default SrmSettings;
