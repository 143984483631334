import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { markAsReadToMessage, showMessageModal } from "../../../actions/messageActions";
import { t } from "@/i18n";

import {
  companyNameOrEmail,
  evalMessage,
  filterMessageRecipients,
  isRead,
  showMessageDateFormat,
  userCanReply,
} from "../common";
import { markAsReadPayload } from "./ShowUpdateMessages";
import { LiveFeedMessagePropType } from "@/common-prop-types";
import { messagePayloadStateSelector } from "../hooks/useMessageModal/useMessage";

const LiveFeedActionsPropType = {
  message: PropTypes.shape(LiveFeedMessagePropType),
  sendMessageModalId: PropTypes.string.isRequired,
};

const ReplyButtonPropTypes = {
  /**
   * message object to get message id and user_id to reply to.
   *
   * @type {{id: number, user_id: number}}
   */
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user_id: PropTypes.number.isRequired,
  }),

  /**
   * modalId will uniquily open modal
   *
   * @type {string}
   */
  modalId: PropTypes.string.isRequired,
};

/**
 * ReplyButton
 *
 * @type {React.FC<PropTypes.InferProps<ReplyButtonPropTypes>>}
 * @returns {React.ReactElement}
 */
const ReplyButton = ({ message, modalId }) => {
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(showMessageModal(false, message.id, message.user_id, modalId));

  return (
    <button className="live-feed-actions" onClick={handleClick}>
      {translations?.reply}
    </button>
  );
};

ReplyButton.propTypes = ReplyButtonPropTypes;
ReplyButton.defaultProps = {
  message: {},
  modalId: "",
};

const ShowUpdateMessage = (props) => {
  const {
    message = {},
    bids,
    lots,
    currentUser,
    translations,
    event,
    role,
    messageRecipients,
    participants,
    hosts,
    permittedToEdit,
    timeZoneIdentifier,
  } = props;

  const allUsers = [...hosts, ...participants];
  const bid = message && message.bid_id ? bids.find((b) => b.id === message.bid_id) : null;
  const lot = bid ? lots.find((l) => l.id === bid.lot_id) : null;
  const sendMessageModalId = "ShowUpdateMessageSendMessageModal";

  const getHostClassName = (message) => {
    let className = "update_message messages als-item clearfix";
    if (event.event_type != "Japanese") {
      if (message.system_message_type !== "declined") {
        className = `${className} new-bid`;
      }
    } else {
      className = `${className} ${
        message.message.body.includes("bid_update_message_declined_japanese") ? "declined-bid" : "accepted-bid"
      }`;
    }
    return className;
  };
  const showMessage = () => {
    let result = null;

    if (message.id) {
      if (message && !message.bid_id) {
        if (message.system_message_type == "setting") {
          result = (
            <li key={`setting_${message.id}`} className="update_message messages auction-change clearfix">
              <span className="status-event draft small-box pull-left">
                <i className="fa fa-cogs"></i>&ensp;
                {translations.settings}
              </span>
              <span className="label light-gray-color  pull-left push-10-r">
                {showMessageDateFormat(message.created_at, timeZoneIdentifier)}
              </span>
              &ensp;
              <div className="pull-left">
                <span
                  dangerouslySetInnerHTML={{
                    __html: evalMessage(message),
                  }}
                ></span>
              </div>
            </li>
          );
        } else if (message.user_id == currentUser.id) {
          const recipients =
            Array.isArray(message.recipients) && message.recipients.length > 0
              ? message.recipients
              : filterMessageRecipients(messageRecipients, message.id);

          result = (
            <li
              key={`currentUser_message_${message.id}`}
              className="update_message messages sent-message als-item clearfix"
            >
              <span className="status-event current small-box pull-left">
                "<i className="fa fa-comments-o">&ensp;</i>
                {translations.message_update_to}
              </span>
              <span className="label light-gray-color pull-left push-10-r">
                {showMessageDateFormat(message.created_at, timeZoneIdentifier)}
              </span>
              &ensp;
              <div className="msg-user pull-left">
                {recipients.length > 1
                  ? translations.to_many
                  : recipients.length
                    ? companyNameOrEmail(allUsers.find((user) => user.id === recipients[0].user_id))
                    : ""}
                &#58;&nbsp;
              </div>
              <div
                className="truncated_text_wrapper"
                dangerouslySetInnerHTML={{
                  __html: evalMessage(message),
                }}
              ></div>
              <div className="pull-right">
                {userCanReply(messageRecipients, message, currentUser, role, permittedToEdit) ? (
                  <ReplyButton message={message} modalId={sendMessageModalId} />
                ) : null}
              </div>
            </li>
          );
        } else {
          const message_user_id = message.user && message.user.id ? message.user.id : message.user_id;
          const user = allUsers.find((u) => u.id === message_user_id);

          result = (
            <li
              key={`other_user_message_${message.id}`}
              className="update_message messages new-message als-item clearfix"
            >
              <span className="status-event inbox small-box pull-left max-w-none">
                <i className="fa fa-comments-o">&ensp;</i>
                {translations.message_update_from}
              </span>
              <span className="label light-gray-color pull-left push-10-r">
                {showMessageDateFormat(message.created_at, timeZoneIdentifier)}
              </span>
              <div className="msg-user pull-left">
                {companyNameOrEmail(user)}
                &#58;&nbsp;
              </div>
              <div
                className="truncated_text_wrapper"
                dangerouslySetInnerHTML={{
                  __html: evalMessage(message),
                }}
              ></div>
              <LiveFeedActions message={message} sendMessageModalId={sendMessageModalId} />
            </li>
          );
        }
      } else if (role === "Host" && lot && !lot.is_event_total) {
        result = (
          <li key={message.id} className={getHostClassName(message)}>
            <span
              dangerouslySetInnerHTML={{
                __html: evalMessage(message),
              }}
            ></span>
            <span className="label light-gray-color push-10-r pull-left">
              {showMessageDateFormat(message.updated_at, timeZoneIdentifier)}
            </span>
          </li>
        );
      }
    }
    return result;
  };

  return showMessage();
};

/**
 * @param {LiveFeedMessagePropType} message
 * @returns {{messageId: string, alreadyRead: boolean, canUserReply: boolean, payload: object}}
 */
const useLiveFeedActions = (message) => {
  const messageId = message.id;
  const messageRecipients = useSelector(({ lotReducers }) => lotReducers.message_recipients);
  const { role, inSandpit, currentUser } = useSelector(messagePayloadStateSelector);
  const permittedToEdit = useSelector(({ lotReducers }) => lotReducers.permitted_to_edit);
  const canUserReply = userCanReply(messageRecipients, message, currentUser, role, permittedToEdit);
  const recipients = filterMessageRecipients(messageRecipients, messageId);
  const alreadyRead = isRead(recipients, currentUser?.id);
  const payload = markAsReadPayload(inSandpit, currentUser);

  return { messageId, alreadyRead, canUserReply, payload };
};

/**
 * @type {React.FC<typeof LiveFeedActionsPropType>}
 */
export const LiveFeedActions = ({ message, sendMessageModalId }) => {
  const dispatch = useDispatch();
  const { messageId, alreadyRead, canUserReply, payload } = useLiveFeedActions(message);

  return (
    <div className="pull-right">
      {canUserReply && (
        <>
          {!alreadyRead && (
            <span className="mark_as_read" id={`message_${messageId}`}>
              <button
                className="btn-mark-read live-feed-actions"
                onClick={() => dispatch(markAsReadToMessage(messageId, payload))}
              >
                {t("message_mark_as_read")}
                <span className="pipe-icon">|</span>
              </button>
            </span>
          )}
          <ReplyButton message={message} modalId={sendMessageModalId} />
        </>
      )}
    </div>
  );
};
LiveFeedActions.propTypes = LiveFeedActionsPropType;

export default ShowUpdateMessage;
