import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInitialProps, updateLotDataOnBid } from "../../../actions/lotActions";
import { AnswerProvider } from "../../../context/AnswerContext";
import { anyComplexLotsExist, hostOverviewTableClasses, isLotTotalPresent } from "../common";
import { getToggleValue } from "../customCollapse";
import { isPause } from "../eventCommon";
import HostMonitorActions from "../lots/host_rfq_screen/HostMonitorActions";
import LotSummary from "../lots/host_rfq_screen/LotSummary";
import ReviewAwardLot from "../lots/ReviewAwardLot";
import RunningEventLotsTooEarly from "./RunningEventLotsTooEarly";
import ShowUpdateMessages from "./ShowUpdateMessages";

const HostMonitor = (props) => {
  const {
    event,
    translations,
    participants,
    event_participants: eventParticipants,
    permitted_to_edit: permittedToEdit,
  } = props;
  const { lots, ...rest } = props;
  const values = {
    allQuestionnaires: props.all_questionnaires,
    allQuestionnaireAnswers: props.all_questionnaire_answers,
  };
  const dispatch = useDispatch();
  useEffect(() => dispatch(setInitialProps({ ...props })), []);

  const lotReducers = useSelector((state) => state.lotReducers);
  const lotsLoaders = useSelector((state) => state.loaderReducers && state.loaderReducers.lotsLoaders) || {};
  const [isLiOpened, setIsLiOpened] = useState([]);
  const [isLcOpened, setIsLcOpened] = useState([]);
  const [isEpOpened, setIsEpOpened] = useState(false);
  const [bids, setBids] = useState(props.bids);
  const [bidLineItemComponents, setBidLineItemComponents] = useState(props.bidLineItemComponents);
  const [allMessages, setAllMessages] = useState(props.all_messages);
  const [messageRecipients, setMessageRecipients] = useState(props.message_recipients);
  const [participatingParticipants, setParticipatingParticipants] = useState(props.participating_participants);
  useEffect(() => {
    setIsLiOpened(lots.filter((lot) => !isLotTotalPresent(lot)).map((lot) => lot.id));
  }, [lots]);
  useEffect(() => {
    if (lotReducers.all_messages) {
      setAllMessages([...lotReducers.all_messages]);
    }

    if (lotReducers.message_recipients) {
      setMessageRecipients([...lotReducers.message_recipients]);
    }

    if (lotReducers.bids) {
      setBids([...lotReducers.bids]);
    }
    if (lotReducers.bid_line_item_components) {
      setBidLineItemComponents([...lotReducers.bid_line_item_components]);
    }

    if (lotReducers.participatingParticipants) {
      setParticipatingParticipants([...lotReducers.participatingParticipants]);
    }
  }, [lotReducers]);
  // isLiOpened contain lots id as line item details will be shown according to
  // lot means if user expand only one lot then LI details for only that lot
  // will be shown.
  const toggleLiCollapse = (e) => {
    const dataLotId = e.target.getAttribute("data-lot-id");
    const lotIds = lots.map((lot) => lot.id);
    const result = getToggleValue(dataLotId, lotIds, isLiOpened);
    setIsLiOpened(result);
  };

  const epIds = participatingParticipants.map((ep) => ep.id);
  // isLcOpened contain participants id because when user click on + or - sign
  // right side of the participant name then lot component details are display
  // only for that participant.
  const toggleLcCollapse = (e) => {
    const dataEpId = e.target.getAttribute("data-ep-id");
    const result = getToggleValue(dataEpId, epIds, isLcOpened);
    setIsLcOpened(result);
  };

  const toggleEpCollapse = (e) => {
    setIsEpOpened(!isEpOpened);
    setIsLcOpened([]);
  };
  const getUpdatedData = () => {
    dispatch(updateLotDataOnBid(event.id));
  };
  return (
    <>
      {props.show_one_tab || isPause(props.auction_controls) ? (
        <>
          <div className="container">
            {permittedToEdit ? (
              <ShowUpdateMessages
                {...props}
                permittedToEdit={permittedToEdit}
                event={event}
                translations={translations}
                allMessages={allMessages}
                role={props.role}
                hasRankedLineItemComponents={props.has_ranked_line_item_components}
                messageRecipients={messageRecipients}
                giveDisabledDurationValues={props.give_disabled_duration_values}
                lastBidTimeInterval={props.last_bid_time_interval}
                lastBidChange={props.last_bid_change}
                currentUser={props.current_user}
                participants={participants}
                hosts={props.hosts}
                hostPermission={props.host_permission}
                inSandpit={props.in_sandpit}
                eventParticipants={eventParticipants}
                locale={props.locale}
                lotReducers={lotReducers}
                timeZoneIdentifier={props.time_zone_identifier}
                bids={bids}
              />
            ) : null}
          </div>
          <div className="container">
            <div className={hostOverviewTableClasses(event, lots, eventParticipants)} id="HostOverviewTable">
              {!props.bids_sealed && (
                <div className="container expand-btns">
                  <HostMonitorActions
                    event={event}
                    translations={translations}
                    lots={lots}
                    participatingParticipants={participatingParticipants}
                    isComplex={anyComplexLotsExist(lots)}
                    toggleLiCollapse={toggleLiCollapse}
                    toggleLcCollapse={toggleLcCollapse}
                    toggleEpCollapse={toggleEpCollapse}
                    isLiOpened={isLiOpened}
                    isLcOpened={isLcOpened}
                    isEpOpened={isEpOpened}
                    beforeAuction={props.before_auction}
                  />
                  <ReviewAwardLot
                    getUpdatedData={getUpdatedData}
                    {...rest}
                    bids={bids}
                    lotReducers={lotReducers}
                    lotsLoaders={lotsLoaders}
                    auctionTab={props.auction_tab}
                  />
                </div>
              )}
              <div className="overview-container-scroll">
                <div className="overview-table" id="lot-content">
                  <AnswerProvider value={values}>
                    <LotSummary
                      event={event}
                      lots={lots}
                      isComplex={anyComplexLotsExist(lots)}
                      event={event}
                      translations={translations}
                      participatingParticipants={participatingParticipants}
                      displayScores={props.display_scores}
                      weightedRfqOrAuction={props.weighted_rfq_or_auction}
                      permissionToView={props.permission_to_view}
                      participants={participants}
                      currenciesHash={props.currencies_hash}
                      eventCurrency={props.event_currency}
                      lineItems={props.line_items}
                      lotComponents={props.lot_components}
                      lineItemComponents={props.line_item_components}
                      bids={bids}
                      beforeAuction={props.before_auction}
                      locale={props.locale}
                      bidLineItemComponents={bidLineItemComponents}
                      eventParticipants={eventParticipants}
                      unitOfMeasures={props.unit_of_measures}
                      allUnitSets={props.unit_sets}
                      role={props.role}
                      isLiOpened={isLiOpened}
                      isLcOpened={isLcOpened}
                      isEpOpened={isEpOpened}
                      toggleLiCollapse={toggleLiCollapse}
                      toggleLcCollapse={toggleLcCollapse}
                      permittedToEdit={permittedToEdit}
                      bidControls={props.bid_controls}
                      auctionControls={props.auction_controls}
                      isJapaneseEvent={props.is_japanese_event}
                      hosts={props.hosts}
                      hostPermission={props.host_permission}
                      currentUser={props.current_user}
                      timeZoneIdentifier={props.time_zone_identifier}
                      eventPassedFinalDeadline={props.event_passed_final_deadline}
                      bidRound={props.bid_round}
                      toggleEpCollapse={toggleEpCollapse}
                      unitSetUoms={props.unit_set_unit_of_measures}
                      bidsSealed={props.bids_sealed}
                      picklistOptions={props.picklist_options}
                      auctionTab={props.auction_tab}
                      inSandpit={props.in_sandpit}
                      remainingParticipantsForJapaneseEvent={props.remaining_participants_for_japanese_event}
                      canShowDeleteBtn={props.can_show_delete_btn}
                      partialBiddingSwitch={props.partial_bidding_switch}
                    />
                  </AnswerProvider>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <RunningEventLotsTooEarly translations={translations} event={event} host={true} />
      )}
    </>
  );
};
export default HostMonitor;
