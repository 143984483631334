import PropTypes from "prop-types";

export const ParticipantPropType = {
  id: PropTypes.number,
  user_id: PropTypes.number,
  display_name: PropTypes.string,
  status: PropTypes.string,
  invited: PropTypes.bool,
  registered: PropTypes.bool,
  accepted: PropTypes.bool,
  disable_access: PropTypes.bool,
  participating: PropTypes.bool,
  host_accepted_at: PropTypes.instanceOf(Date),
  participant_accepted_at: PropTypes.instanceOf(Date),
  invited_at: PropTypes.instanceOf(Date),
  invited_through_cd: PropTypes.bool,
  updated_at: PropTypes.instanceOf(Date),
  event_id: PropTypes.number,
};

/** @typedef {PropTypes.InferProps<typeof ParticipantPropType>} Participant */
const Participant = ParticipantPropType;
export default Participant;
