import React, { Component } from 'react';
import { Field, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import * as adminTextActions from '../../../../actions/qq/adminTextActions';

import EditorPopupSection from './EditorPopupSection';
import PopupMobile from './PopupMobile';

class QQQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showQuestionSection: false,
      adminText: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAdminText(this.props.companyId, this.props.sectionKey);
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    const showQuestionSection = formValue?.qqQuestions.with_questionnaires;
    this.setState({ showQuestionSection });
    this.props.onTextValue('with_questionnaires', showQuestionSection);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText.FETCH_ADMIN_TEXT_QUESTIONS ? newProps.adminText.FETCH_ADMIN_TEXT_QUESTIONS : '';
    if (newProps.default.FETCH_ADMIN_TEXT_QUESTIONS_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_QUESTIONS_DEL;
    }
    this.setState({
      adminText,
    });
  }

  getTitle(event, index) {
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    const question = formValue.qqQuestions.questionnaire[index] ? formValue.qqQuestions.questionnaire[index] : { title: '', question_type: '' };
    question.title = event.target.value;
    if (!formValue.qqQuestions.questionnaire[index]) {
      formValue.qqQuestions.questionnaire.push(question);
    } else {
      formValue.qqQuestions.questionnaire[index] = question;
    }
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  getType(event, index) {
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    const question = formValue.qqQuestions.questionnaire[index] ? formValue.qqQuestions.questionnaire[index] : { title: '', question_type: '' };
    question.question_type = event.target.value;
    if (!formValue.qqQuestions.questionnaire[index]) {
      formValue.qqQuestions.questionnaire.push(question);
    } else {
      formValue.qqQuestions.questionnaire[index] = question;
    }
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  disabledSection() {
    const showQuestionSection = !this.state.showQuestionSection;
    this.setState({ showQuestionSection });
    this.props.onTextValue('with_questionnaires', showQuestionSection);
    const formValue = JSON.parse(localStorage.getItem('formValue')) ? JSON.parse(localStorage.getItem('formValue')) : {};
    formValue.qqQuestions ? formValue.qqQuestions.with_questionnaires = showQuestionSection : formValue.qqQuestions = {}
    formValue.qqQuestions.with_questionnaires = showQuestionSection;
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  removeQuestion(index) {
    const formValue = JSON.parse(localStorage.getItem('formValue'));
    formValue.qqQuestions.questionnaire.splice(index, 1);
    localStorage.setItem('formValue', JSON.stringify(formValue));
  }

  render() {
    const renderField = ({
      input, placeholder, type, className, meta: { touched, error },
    }) => (
      <div>
        <input { ...input } type={ type } placeholder={ placeholder } className={ className } />
        {error ?
          <span className='text-danger'>
            {error !== 'NAME_CHAR_LONG' ?
              this.props.translations.error_message[error.toLowerCase()] :
              this.props.translations.error_message[error.toLowerCase()].replace("%{name_string}", this.props.translations.qq_edit.title).replace("%{name_limit}", '255')
            }
          </span> : ''}
      </div>
    );

    const renderSelect = ({
      input, className, meta: { touched, error },
    }) => (
      <div>
        <select { ...input } className={ className }>
          <option value=''>{this.props.translations.qq_edit.select}</option>
          <option value='one_line_text'>{this.props.translations.qq_edit.text}</option>
          <option value='document_upload'>{this.props.translations.qq_edit.upload_file}</option>
          <option value='yes_no'>{this.props.translations.qq_edit.yes_no}</option>
        </select>
        {error ?
          <span className='text-danger'>
            {error !== 'NAME_CHAR_LONG' ?
              this.props.translations.error_message[error.toLowerCase()] : ''}
          </span> : ''}
      </div>
    );

    const renderQuestionnaire = ({ fields, questionsLimit, meta: { touched, error, submitFailed } }) => {
      if (fields.length === 0) { fields.push({}); }
      return (
        <div id='optional_quality' className='checkbox-content-box'>
          <label htmlFor='name'>{this.props.translations.qq_create.qq_questions.question_type}</label>
          {questionsLimit > 0 && error && <div className='p-tb5 text-danger'>{this.props.translations.error_message[error.toLowerCase()]}</div>}
          {fields
      .map((item, index) => (
        <div key={ item } className='form-group clearfix'>
          <div className='col-md-8 p-l0 textbox p-r0'>
            <Field
              name={ `${item}.title` }
              type='text'
              component={ renderField }
              className='form-control'
              placeholder={ this.props.translations.qq_edit.question }
              onChange={ (e) => { this.getTitle(e, index); } }
            />
          </div>
          <div className='col-md-3 textbox'>
            <div className='selectbox'>
              <Field
                name={ `${item}.question_type` }
                component={ renderSelect }
                className='form-control'
                onChange={ (e) => { this.getType(e, index); } }
              />
            </div>
          </div>
          <div className='col-sm-12 col-md-1 space-bottom deleteicon-box p-0'>
            <a
              href='#'
              onClick={ (e) => { e.preventDefault(); fields.remove(index); this.removeQuestion(index); } }
              className='delete-icon'
            > <i className='fa fa-trash' aria-hidden='true' />
            </a>
          </div>
        </div>
      ))}
          <div className='form-group'>
            {fields.length === 0 ?
              <button
                className='btn btn-primary'
                disabled={ fields.length >= questionsLimit }
                onClick={ (e) => { e.preventDefault(); fields.push({}); } }
              >
                {this.props.translations.qq_create.qq_questions.add_question}
              </button> :
              <button
                className='btn btn-primary'
                disabled={ fields.length >= questionsLimit || fields.getAll().filter((field, index) => (!!(fields.get(index).title && fields.get(index).title.trim() && fields.get(index).question_type))).length !== fields.length }
                onClick={ (e) => { e.preventDefault(); fields.push({}); } }
              >
                {this.props.translations.qq_create.qq_questions.add_another_question}
              </button>}
          </div>
          {fields.length >= questionsLimit ? <div className='text-danger'>only {questionsLimit} questions are allowed</div> : ''}
        </div>
      );
    };

    const { sectionKey, companyId, questionsLimit, translations } = this.props;
    return (
      <div className='block-item'>
        <div className='col-md-6 p-l0 table-cell-box'>
          <div className='form-item-block'>
            <PopupMobile
              companyId={ companyId }
              sectionKey={ sectionKey }
              text={ this.state.adminText }
              translations={ translations }
            />
            <div className='checkbox'>
              <div>
                <Field
                  name='with_questionnaires'
                  id='checkbox3'
                  component='input'
                  type='checkbox'
                  onClick={ () => this.disabledSection() }
                />
                <label htmlFor='checkbox3'>
                  <span />{this.props.translations.qq_create.qq_questions.include_questions}
                </label>
              </div>
            </div>
            {this.state.showQuestionSection &&
              <FieldArray name='questionnaire' component={ renderQuestionnaire } questionsLimit={ questionsLimit } />}
          </div>
        </div>
        <EditorPopupSection 
          show={ !this.state.adminText }
          text={ this.state.adminText }
          companyId={ companyId }
          sectionKey={ sectionKey }
          translations={ translations }
          updateText={ (adminText) => { this.setState({ adminText }); } }
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  adminText: state.adminTextReducer,
  selectedLang: state.changeLanguageReducer,
  default: state.deleteAdminTextReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchAdminText: (companyId, sectionKey) => dispatch(adminTextActions.fetchAdminText(companyId, sectionKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QQQuestions);
