import React, { Component } from "react";
import Tooltip from "../../../common/Tooltip";
import Select2 from "react-select2-wrapper";
class NotifyScorersAndPendingApprovals extends Component {
  componentDidUpdate() {
    this.setValue();
  }

  setValue = () => {
    this.scoringBeforeExpiry.value =
      this.props.configuration_setting_values.scoring_required_before_expiry.split(".")[0];
    this.scoringRequiredExpiry.value =
      this.props.configuration_setting_values.scoring_required_period_of_expiry.split(".")[0];
    this.pendingApprovalBeforeExpiry.value =
      this.props.configuration_setting_values.pending_approval_before_expiry.split(".")[0];
    this.pendingApprovalExpiry.value =
      this.props.configuration_setting_values.pending_approval_period_of_expiry.split(".")[0];
  };

  scoringBeforeExpiryUpdate = (e) => {
    let temp =
      +this.scoringBeforeExpiry.value +
      "." +
      (e.target.name == "scoringBeforeExpiryUnit" ? e.target.value : this.scoringBeforeExpiryUnit.props.value);
    if (temp == this.props.configuration_setting_values.scoring_required_before_expiry) {
      return false;
    }
    this.props.onValueChange(temp, "scoring_required_before_expiry");
  };

  scoringRequiredExpiryUpdate = (e) => {
    let temp =
      +this.scoringRequiredExpiry.value +
      "." +
      (e.target.name == "scoringRequiredExpiryUnit" ? e.target.value : this.scoringRequiredExpiryUnit.props.value);
    if (temp == this.props.configuration_setting_values.scoring_required_period_of_expiry) {
      return false;
    }
    this.props.onValueChange(temp, "scoring_required_period_of_expiry");
  };

  pendingApprovalBeforeExpiryUpdate = (e) => {
    let temp =
      +this.pendingApprovalBeforeExpiry.value +
      "." +
      (e.target.name == "pendingApprovalBeforeExpiryUnit"
        ? e.target.value
        : this.pendingApprovalBeforeExpiryUnit.props.value);
    if (temp == this.props.configuration_setting_values.pending_approval_before_expiry) {
      return false;
    }
    this.props.onValueChange(temp, "pending_approval_before_expiry");
  };

  pendingApprovalExpiryUpdate = (e) => {
    let temp =
      +this.pendingApprovalExpiry.value +
      "." +
      (e.target.name == "pendingApprovalExpiryUnit" ? e.target.value : this.pendingApprovalExpiryUnit.props.value);
    if (temp == this.props.configuration_setting_values.pending_approval_period_of_expiry) {
      return false;
    }
    this.props.onValueChange(temp, "pending_approval_period_of_expiry");
  };

  render() {
    const { translations, durationType, configuration_setting_values, currentUserEditConfigPermission } = this.props;

    return (
      <div className="form-item-block">
        <div className="form-item-block-title">{translations.admin_views.configuration.notify_scorers}</div>
        <div className="input inline m-b10">
          <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">
            {translations.admin_views.configuration.notify_scorers}
          </label>
          <input
            ref={(ref) => (this.scoringBeforeExpiry = ref)}
            type="text"
            name="scoring_required_before_expiry"
            id="scoring_required_before_expiry"
            defaultValue={configuration_setting_values.scoring_required_before_expiry.split(".")[0]}
            className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
            size="5"
            onBlur={(e) => this.scoringBeforeExpiryUpdate(e)}
            disabled={!currentUserEditConfigPermission}
          />
          <div className="col-md-2 col-sm-3 col-xs-9">
            <Select2
              ref={(ref) => (this.scoringBeforeExpiryUnit = ref)}
              className="duration_type form-control"
              name="scoringBeforeExpiryUnit"
              value={configuration_setting_values.scoring_required_before_expiry.split(".")[1]}
              data={durationType}
              onSelect={(e) => this.scoringBeforeExpiryUpdate(e)}
              disabled={!currentUserEditConfigPermission}
            />
          </div>
          <div className="vertical-center">{translations.before_the_scoring_deadline}.</div>
          <div className="vertical-center">
            <Tooltip {...translations.configuration.notification_of_scoring_required_before_expiry} />
          </div>
        </div>
        <div className="input inline m-b10">
          <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">{translations.deadline_is}</label>
          <input
            ref={(ref) => (this.scoringRequiredExpiry = ref)}
            type="text"
            name="scoring_required_period_of_expiry"
            id="scoring_required_period_of_expiry"
            defaultValue={configuration_setting_values.scoring_required_period_of_expiry.split(".")[0]}
            className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
            size="5"
            onBlur={(e) => this.scoringRequiredExpiryUpdate(e)}
            disabled={!currentUserEditConfigPermission}
          />
          <div className="col-md-2 col-sm-3 col-xs-9">
            <Select2
              ref={(ref) => (this.scoringRequiredExpiryUnit = ref)}
              className="duration_type form-control"
              name="scoringRequiredExpiryUnit"
              value={configuration_setting_values.scoring_required_period_of_expiry.split(".")[1]}
              data={durationType}
              onSelect={(e) => this.scoringRequiredExpiryUpdate(e)}
              disabled={!currentUserEditConfigPermission}
            />
          </div>
          <div className="vertical-center">{translations.participant_submits_last_q_answer}.</div>
          <div className="vertical-center">
            <Tooltip {...translations.configuration.scoring_required_period_of_expiry} />
          </div>
        </div>
        <div className="form-item-block-title">
          {translations.admin_views.configuration.notification_of_pending_approval}
        </div>
        <div className="input inline m-b10">
          <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">{translations.notify_approvers}</label>
          <input
            ref={(ref) => (this.pendingApprovalBeforeExpiry = ref)}
            type="text"
            name="pending_approval_before_expiry"
            id="pending_approval_before_expiry"
            defaultValue={configuration_setting_values.pending_approval_before_expiry.split(".")[0]}
            className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
            size="5"
            onBlur={(e) => this.pendingApprovalBeforeExpiryUpdate(e)}
            disabled={!currentUserEditConfigPermission}
          />
          <div className="col-md-2 col-sm-3 col-xs-9">
            <Select2
              ref={(ref) => (this.pendingApprovalBeforeExpiryUnit = ref)}
              className="duration_type form-control"
              name="pendingApprovalBeforeExpiryUnit"
              value={configuration_setting_values.pending_approval_before_expiry.split(".")[1]}
              data={durationType}
              onSelect={(e) => this.pendingApprovalBeforeExpiryUpdate(e)}
              disabled={!currentUserEditConfigPermission}
            />
          </div>
          <div className="vertical-center">{translations.before_expiry}.</div>
          <div className="vertical-center">
            <Tooltip {...translations.configuration.pending_approval_before_expiry} />
          </div>
        </div>
        <div className="input inline m-b10">
          <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">{translations.deadline_is}</label>
          <input
            ref={(ref) => (this.pendingApprovalExpiry = ref)}
            type="text"
            name="pending_approval_period_of_expiry"
            id="pending_approval_period_of_expiry"
            defaultValue={configuration_setting_values.pending_approval_period_of_expiry.split(".")[0]}
            className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
            size="5"
            onBlur={(e) => this.pendingApprovalExpiryUpdate(e)}
            disabled={!currentUserEditConfigPermission}
          />
          <div className="col-md-2 col-sm-3 col-xs-9">
            <Select2
              ref={(ref) => (this.pendingApprovalExpiryUnit = ref)}
              className="duration_type form-control"
              name="pendingApprovalExpiryUnit"
              value={configuration_setting_values.pending_approval_period_of_expiry.split(".")[1]}
              data={durationType}
              onSelect={(e) => this.pendingApprovalExpiryUpdate(e)}
              disabled={!currentUserEditConfigPermission}
            />
          </div>
          <div className="vertical-center">
            <Tooltip {...translations.configuration.pending_approval_period_of_expiry} />
          </div>
        </div>
      </div>
    );
  }
}

export default NotifyScorersAndPendingApprovals;
