import * as apiCall from '../api';
import actionTypes from '../actionsTypes';
import config from '../config';

const getMaterialRequestsSuccess = (data) => ({
  type: actionTypes.get_material_requests,
  payload: data,
});

const getMaterialRequestsFail = (error) => ({
  type: error.response.status,
  payload: error,
});

const getMaterialRequests = (page, searchKey, state) => (dispatch) => {
  if (searchKey.length || state.length) {
    const stateParam = state.length ? `&${state}` : '';
    return apiCall.get(
      `${config.BASE_URL
      }/events/search?emarketplace=true${stateParam}&for_listing=true&page=${page}&search_key=${escape(searchKey.trim())}`,
      { headers: config.headers },
    )
      .then((response) => {
        dispatch(getMaterialRequestsSuccess({
          events: response.data.events,
          pageDetails: { eventsPerPage: response.headers['x-per-page'], totalEvents: response.headers['x-total'] },
        }));
        return response;
      })
      .catch((error) => {
        dispatch(getMaterialRequestsFail(error));
        return error.response;
      });
  }
  return apiCall.get(`${config.BASE_URL}/events?emarketplace=true&page=${page}`, { headers: config.headers })
    .then((response) => {
      dispatch(getMaterialRequestsSuccess({
        events: response.data.events,
        pageDetails: { eventsPerPage: response.headers['x-per-page'], totalEvents: response.headers['x-total'] },
      }));
      return response;
    })
    .catch((error) => {
      dispatch(getMaterialRequestsFail(error));
      return error.response;
    });
};

export default getMaterialRequests;
