import type { AxiosError } from "axios";
import { addListener, createListenerMiddleware } from "@reduxjs/toolkit";
import { ERROR_CODE } from "@/actions/actionsTypes";
import type { AppDispatch, RootState } from "@/store";
import { setupEventCollaboratorListener } from "@/slices/events/collaborators";

export const listenerInstance = createListenerMiddleware();

export const startAppListening = listenerInstance.startListening.withTypes<RootState, AppDispatch>();

export type AppStartListening = typeof startAppListening;

export const addAppListener = addListener.withTypes<RootState, AppDispatch>();

export type AppAddListener = typeof addAppListener;

startAppListening({
  predicate: (action) => {
    if (action.type === ERROR_CODE) {
      const error = action.payload as AxiosError;
      return error.response?.status === 401;
    }
    return false;
  },
  effect: () => {
    window.location.href = "/quick_quote/unauthorised";
  },
});

setupEventCollaboratorListener(startAppListening);
