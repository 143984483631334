import React from "react";
import ActivityButton from "../ActivityButton";
import moment from "moment-timezone";
import { reloadActivityList, switchActivityModalTab } from "../../../actions/activityActions";

/**
 * Operations to be performed after updating a activity.
 *
 * @param {Object} Props
 * @returns
 */
export const relayUpdateActivity = ({
  response,
  closeModal,
  setOldState,
  returnCurrentStateValues,
  fetchDraftActivity,
  closePopup,
  followup,
}) => {
  if (response.status != 200) {
    return response;
  }

  if (closePopup) {
    closeModal();
  } else {
    // This will execute when the tab is changed and we save some values in database, then update the intial state
    // to new values
    setOldState(returnCurrentStateValues());
  }

  if (followup) {
    fetchDraftActivity();
  }

  return response;
};

/**
 * Conditional publish button rendering.
 * @param {Object} Props
 * @returns
 */
export const handlePublishButton = ({ draftActivity, checkIfActivityHasChanges, translations, submitActivity }) => {
  // Added this variable to check the button text we want to show either publish or save, when the activity is draft we
  // will show publish button and when the activity is already published and has any unsaved changes in General tab then
  // we will show the Save button
  let publishOrSaveBtn = "";

  if (draftActivity.draft || checkIfActivityHasChanges()) {
    const text = draftActivity.draft ? translations.publish : translations.save;
    publishOrSaveBtn = (
      <button name="button" className="btn btn-sm btn-primary" onClick={() => submitActivity(false)}>
        {text} <i className="fa fa-check"></i>
      </button>
    );
  }

  return publishOrSaveBtn;
};

/**
 * Conditional edit button renderer.
 * @param {Object} props
 * @param {Boolean} permittedToCreateActivity
 * @returns
 */
export const resolveEditButton = (props, permittedToCreateActivity) => (activity) => {
  if (permittedToCreateActivity) {
    return (
      <ActivityButton
        activityId={activity.parent_id}
        activitySubject={activity.parent_subject}
        activities={props.allActivities}
        concernedParties={props.allConcernedParties}
        hostOwnerEmails={props.hostOwnerEmails}
        setHostOwnerEmails={props.setHostOwnerEmails}
        {...props}
      />
    );
  } else {
    return activity.parent_subject;
  }
};

/**
 * Params for updating/creating an activity.
 *
 * @param {Object} Props
 * @returns {Object}
 */
export const prepareParams = ({ activity, paginationParams, followup, contact_id, supplier_id, refreshList }) => {
  let params = {
    activity,
    page: paginationParams.page,
    per_page: paginationParams.per_page,
    refreshList,
    filter: {},
  };
  if (followup) {
    params["activity"]["followup"] = true;
  }

  if (contact_id) {
    params["filter"]["contacts"] = String(contact_id);
    params["from_contacts"] = true;
  }

  if (supplier_id) {
    params["filter"]["suppliers"] = String(supplier_id);
    params["from_suppliers"] = true;
  }

  return params;
};

/**
 * Converts date time to specified format.
 * @param {string} dateTime
 * @returns {moment.Moment}
 */
export const formatDateTimeValue = (dateTime) => {
  const momentDateTime = moment(dateTime);
  return momentDateTime.isValid() ? momentDateTime.toDate() : "";
};

// If there are any changes then show a confirmation box if they really want to close the modal, sadly I am using
// jQuery here due to our current implementation of the confirmation box, we should move that to React Modal
// currently it is bootstrap modal which doesn't work with vanillascript (atleast with our version), it requires jQuery
export const resolveCheckForChanges =
  ({ checkIfActivityHasChanges, dispatch, closeModal }) =>
  () => {
    if (checkIfActivityHasChanges()) {
      $("#activityConfirmCloseModal").modal("show");
    } else {
      dispatch(reloadActivityList());
      dispatch(
        switchActivityModalTab({
          tab: "general",
        })
      );
      closeModal();
    }
  };

// Save the activity only when there are changes
export const resolveSubmitChanges =
  ({ checkIfActivityHasChanges, submitActivity, draftActivity, dispatch }) =>
  (event, tab, refreshList = true) => {
    event.preventDefault();
    dispatch(
      switchActivityModalTab({
        tab,
      })
    );
    if (checkIfActivityHasChanges()) {
      submitActivity(false, false, draftActivity.draft, refreshList);
    }
  };

/**
 * Manages the state for activity modal component.
 *
 * @param {Object} Props
 * @returns {Array}
 */
export const manageStates = ({ eff, customColumnOptions, draftActivity, activityTypes, priorityOptions }) => {
  // We use this oldState to compare if there are any changes in the General tab so when the user tries to close the
  // activity modal we show them a warning or if the user switches from one tab to other we save the changes in database
  const returnCurrentStateValues = () => ({
    activityTypeId: activityTypeId,
    subject: subject,
    duration: duration,
    status: status,
    purpose: purpose,
    dueDate: dueDate,
    alertDate: alertDate,
    priorityId: priorityId,
  });

  const draftActivityType = activityTypes(customColumnOptions).filter(
    (at) => at.text === draftActivity.activity_type
  )[0];
  const draftPriority = priorityOptions(customColumnOptions).filter((po) => po.text === draftActivity.priority)[0];

  const [activityTypeId, setActivityType] = eff(draftActivityType ? draftActivityType.id : "");
  const [subject, setSubject] = eff(draftActivity.subject);
  const [duration, setDuration] = eff(draftActivity.duration || "");
  const [status, setStatus] = eff(draftActivity.current_state);
  const [purpose, setPurpose] = eff(draftActivity.purpose || "");
  const [dueDate, setDueDate] = eff(formatDateTimeValue(draftActivity.due_date));
  const [alertDate, setAlertDate] = eff(formatDateTimeValue(draftActivity.alert_date));
  const [completionDate, setCompletionDate] = eff(formatDateTimeValue(draftActivity.completed_on) || "");
  const [priorityId, setPriorityId] = eff(draftPriority ? draftPriority.id : "");
  const [oldState, setOldState] = eff(returnCurrentStateValues());

  return [
    activityTypeId,
    setActivityType,
    subject,
    setSubject,
    duration,
    setDuration,
    status,
    setStatus,
    purpose,
    setPurpose,
    dueDate,
    setDueDate,
    alertDate,
    setAlertDate,
    completionDate,
    setCompletionDate,
    priorityId,
    setPriorityId,
    oldState,
    setOldState,
    returnCurrentStateValues,
  ];
};
