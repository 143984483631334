import React, { createContext, useContext } from "react";

const CollapseContext = createContext<boolean>(false);

export const useParentCollapsed = () => useContext(CollapseContext);

export function Collapser({ children, collapsed }: { children: React.ReactNode; collapsed?: boolean }) {
  const parentCollapsed = useParentCollapsed();
  return <CollapseContext.Provider value={collapsed || parentCollapsed}>{children}</CollapseContext.Provider>;
}
