/* eslint-disable @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-explicit-any */
import consumer from "@/channels/consumer";
import { createAction, createAsyncThunk, isAnyOf, isAsyncThunkAction } from "@reduxjs/toolkit";
import { customFetch } from "@/lib/fetch";
import type { ActionCreatorWithPreparedPayload, AsyncThunk } from "@reduxjs/toolkit";
import type { HasMatchFunction, MatchedType } from "@/types/util";
import type { FetchArgs } from "@/lib/fetch";

export const channel = "RequestChannel";

type ReceivedActionCreator<Event, Arg> = ActionCreatorWithPreparedPayload<
  [Event, string, Arg],
  Event,
  string,
  never,
  {
    arg: Arg;
    requestId: string;
    requestStatus: "received";
  }
>;

const makeReceivedActionCreator = <Event, Arg>(typePrefix: string): ReceivedActionCreator<Event, Arg> =>
  createAction(`${typePrefix}/received`, (data: Event, requestId: string, arg: Arg) => ({
    payload: data,
    meta: { arg, requestId, requestStatus: "received" as const },
  }));

export type CableThunk<Event, Return, Arg> = AsyncThunk<Return, Arg, NonNullable<unknown>> & {
  received: ReceivedActionCreator<Event, Arg>;
};

/**
 * Create a thunk creator for a cable connection.
 *
 * As well as the typical async thunk behavior, this will also create a subscription to a cable channel, and dispatch whenever data is received.
 *
 *
 * @param typePrefix The prefix for the action types.
 * @param getFetchArgs A function that returns the fetch arguments.
 * *Note: unlike a normal `fetch`, this defaults to a POST request instead of a GET request.*
 *
 * @template Event The type of the data received from the cable channel.
 * @template Return The type of the data returned from the fetch request.
 * @template Arg The type of the argument passed to the thunk creator.
 */
export function createCableThunk<Event, Return, Arg>(
  typePrefix: string,
  getFetchArgs: (arg: Arg) => string | FetchArgs
): CableThunk<Event, Return, Arg> {
  const received = makeReceivedActionCreator<Event, Arg>(typePrefix);

  return Object.assign(
    createAsyncThunk(typePrefix, async (arg: Arg, { dispatch, requestId, signal }) => {
      const subscription = consumer.subscriptions.create(
        { channel, room: requestId },
        {
          received(data) {
            dispatch(received(data, requestId, arg));
          },
        }
      );
      signal.addEventListener("abort", () => subscription.unsubscribe());

      try {
        const fetchArgs = getFetchArgs(arg);
        const asObj: FetchArgs = typeof fetchArgs === "string" ? { url: fetchArgs } : fetchArgs;
        asObj.method ??= "POST";
        const res = await customFetch(asObj, { requestId, signal });

        return (await res.json()) as Return;
      } finally {
        subscription.unsubscribe();
      }
    }),
    { received }
  );
}

type AnyCableThunk = {
  pending: HasMatchFunction<any>;
  fulfilled: HasMatchFunction<any>;
  rejected: HasMatchFunction<any>;
  received: HasMatchFunction<any>;
};

function hasRequiredMetadata(action: any, validStatus: readonly string[]) {
  if (!action || !action.meta) return false;
  const hasValidRequestId = typeof action.meta.requestId === "string";
  const hasValidRequestStatus = validStatus.includes(action.meta.requestStatus);
  return hasValidRequestId && hasValidRequestStatus;
}

function isCableThunkArray(a: [any] | AnyCableThunk[]): a is [AnyCableThunk, ...AnyCableThunk[]] {
  return typeof a[0] === "function" && "received" in a[0];
}

type UnknownReceivedAction = ReturnType<ReceivedActionCreator<unknown, unknown>>;

/**
 * Returns a type guard that checks if an action is a received action from any cable thunk.
 */
export function isReceived(): (action: any) => action is UnknownReceivedAction;
/**
 * Returns a type guard that checks if an action is a received action from any of the given cable thunks.
 */
export function isReceived<CableThunks extends [AnyCableThunk, ...AnyCableThunk[]]>(
  ...cableThunks: CableThunks
): (action: any) => action is MatchedType<CableThunks[number]["received"]>;
/**
 * Checks if an action is a received action from any cable thunk.
 */
export function isReceived(action: any): action is UnknownReceivedAction;
export function isReceived(...thunks: [any] | AnyCableThunk[]) {
  if (thunks.length === 0) {
    return (action: any): action is UnknownReceivedAction => {
      return hasRequiredMetadata(action, ["received"]);
    };
  }
  if (!isCableThunkArray(thunks)) {
    return isReceived()(thunks[0]);
  }
  return isAnyOf(...thunks.map((t) => t.received));
}

type UnknownCableThunk = CableThunk<unknown, unknown, unknown>;
type UnknownCableThunkAction = ReturnType<
  | UnknownCableThunk["pending"]
  | UnknownCableThunk["fulfilled"]
  | UnknownCableThunk["rejected"]
  | UnknownCableThunk["received"]
>;

type ActionsFromCableThunk<CableThunks extends AnyCableThunk> = MatchedType<
  CableThunks["pending"] | CableThunks["fulfilled"] | CableThunks["rejected"] | CableThunks["received"]
>;

/**
 * Returns a type guard that checks if an action is from any cable thunk.
 *
 * Because cable thunks are built with `createAsyncThunk`, async thunk actions are also matched.
 */
export function isCableThunkAction(): (action: any) => action is UnknownCableThunkAction;
/**
 * Returns a type guard that checks if an action is from any of the given cable thunks.
 *
 * Because cable thunks are built with `createAsyncThunk`, async thunk actions are also matched.
 */
export function isCableThunkAction<CableThunks extends [AnyCableThunk, ...AnyCableThunk[]]>(
  ...cableThunks: CableThunks
): (action: any) => action is ActionsFromCableThunk<CableThunks[number]>;
/**
 * Checks if an action is from any cable thunk.
 *
 * Because cable thunks are built with `createAsyncThunk`, async thunk actions are also matched.
 */
export function isCableThunkAction(action: any): action is UnknownCableThunkAction;
export function isCableThunkAction(...thunks: [any] | AnyCableThunk[]) {
  if (thunks.length === 0) {
    return isAnyOf(isAsyncThunkAction(), isReceived());
  }
  if (!isCableThunkArray(thunks)) {
    return isCableThunkAction()(thunks[0]);
  }
  return isAnyOf(isAsyncThunkAction(...thunks), isReceived(...thunks));
}
