import type { Slice, UnknownAction } from "@reduxjs/toolkit";
import type { PersistConfig } from "redux-persist";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

/**
 * Create a version of a slice with a persisted reducer.
 * Includes sensible defaults for config.
 */
export const persistSlice = <S extends Slice>(
  { reducer, ...slice }: S,
  config: Partial<PersistConfig<ReturnType<S["reducer"]>>>
) => ({
  ...slice,
  reducer: persistReducer<ReturnType<S["reducer"]>, UnknownAction, ReturnType<S["reducer"]>>(
    {
      key: slice.name,
      storage,
      ...config,
    },
    reducer
  ),
});
