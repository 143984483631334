// eslint-disable-next-line no-unused-vars
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { isHost } from "@/utils/roles";

// get state form store for useMessagePayload
export const messagePayloadStateSelector = createSelector(
  ({ lotReducers }) => lotReducers.event_participants,
  ({ lotReducers }) => lotReducers.role,
  ({ lotReducers }) => lotReducers.in_sandpit,
  ({ lotReducers }) => lotReducers.current_user,
  ({ messageReducers }) => messageReducers.showModal,
  (eventParticipants, role, inSandpit, currentUser, showModal) => ({
    eventParticipants,
    isCurrentUserHost: isHost(role),
    showModal,
    inSandpit,
    currentUser,
  })
);

/**
 *
 * @typedef {{ message: string, selectedParticipantIds: number[], selectedDocumentIds: number[] }} formStateType
 *
 * @param {boolean} marketDojo
 * @param {formStateType} formState
 * @returns {(e: React.MouseEvent<HTMLElement>, isConfirmedByHost: boolean | undefined) => ({})} function to crate payload
 */
export const useMessagePayload = (marketDojo, formState = {}) => {
  const { message, selectedParticipantIds, selectedDocumentIds } = formState;
  const { eventParticipants, isCurrentUserHost, showModal, inSandpit, currentUser } =
    useSelector(messagePayloadStateSelector);
  const { messageReplyId } = showModal;

  /**
   * Create payload to send message
   */
  return useCallback(
    (e, isConfirmedByHost) => ({
      /**
       * add message to the payload
       */
      message,

      /**
       * add recipient_ids as selected particpiants
       */
      recipient_ids: selectedParticipantIds,

      /**
       * set parent_id of message if messageReplyId is present
       */
      ...(messageReplyId && { parent_id: messageReplyId }),

      /**
       * set flag of sim_dojo_event_participant_id if event is in simDojo
       */
      ...(!marketDojo && { sim_dojo_event_participant_id: eventParticipants[0]?.id }),

      /**
       * set document_ids flag if current user is host and documents are selected
       */
      ...(isCurrentUserHost && selectedDocumentIds.length && { document_ids: selectedDocumentIds }),

      /**
       * check if user is in sandpit and set in_sandpit and in_sandpit_as_user flags
       */
      ...(inSandpit && { in_sandpit: inSandpit, in_sandpit_as_user: currentUser.id }),

      /**
       * only set reopen_event if host either clicks on
       * cancle or ok button from reopenEvent confirmation modal.
       */
      ...(typeof isConfirmedByHost !== "undefined" &&
        !e.target.classList.value.includes("close") && { reopen_event: isConfirmedByHost }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser.id, eventParticipants, inSandpit, isCurrentUserHost, marketDojo, messageReplyId, formState]
  );
};

/**
 * Get text value
 * returns the extracted value of message input as per the type of editor (i.e, textarea[for IE] or trix-editor)
 */
export function getTextValue(e) {
  if (typeof e === "string") {
    return e;
  }
  if (typeof e?.editor?.getData === "function") {
    return e.editor.getData();
  }
  return e.target.value;
}

/**
 * Create message handler
 * @param {()=> void} setState
 */
export const createMessageChangeHandler = (setState) => {
  /**
   * Handle message change
   * @param {React.MouseEvent<HTMLElement>} e
   */
  return (e) => {
    const message = getTextValue(e);
    setState({ message });
  };
};
