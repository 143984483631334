import React, { Component } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Provider as AlertContainer } from 'react-alert';
import AlertTemplate from 'react-alert-template-oldschool-dark';

import EditorPopupSection from './EditorPopupSection';
import PopupMobile from './PopupMobile';
import DocumentLibraryModal from './DocumentLibraryModal';
import * as adminTextActions from '../../../../actions/qq/adminTextActions';
import rootConfig from '../../js/config';
import { alertOptions } from '../../js/common';
import AlreadyUploadedDocuments from './AlreadyUploadedDocuments'

class QQDocuments extends Component {
  constructor(props) {
    super(props);
    this.djsConfig = {
      addRemoveLinks: true,
    };

    this.componentConfig = {
      iconFiletypes: ['.jpg', '.png', '.gif'],
      showFiletypeIcon: false,
      postUrl: `${rootConfig.DOMAIN_URL}/quick_quotes/uploadHandler`,
    };

    this.state = {
      files: [],
      adminText: '',
      exceedMaxFile: true,
      alreadyUploaded: [],
    };

    this.success = (file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const obj = {};
        obj.name = file.name;
        obj.source = reader.result;
        obj.size = file.size;
        const files = this.state.files.concat(obj);
        const alreadyUploaded = this.state.alreadyUploaded;
        this.setState({ files });
        this.props.onTextValue('documents', [...files, ...alreadyUploaded]);
        const restField = JSON.parse(localStorage.getItem('restField'));
        restField.documents.push(obj);
        localStorage.setItem('restField', JSON.stringify(restField));
      };
      reader.readAsDataURL(file);
    };


    this.callback = (file) => {
      const TotalSize = this.props.limits.total_all_documents_size;
      if (this.dropzone.files.length <= this.dropzone.options.maxFiles) {
        let totalFileSize = 0;
        this.dropzone.files.map((fileObj) => {
          totalFileSize += fileObj.size;
          return fileObj;
        });
        const singleDocumentSize = this.props.limits.single_document_size;
        if (file.size >= 1024 * 1024 * singleDocumentSize) {
          this.msg.error(this.props.translations.error_message.attachment_size_too_big, { ATTACHMENT_SIZE_TOO_BIG: singleDocumentSize });
          this.dropzone.removeFile(file);
          return false;
        }

        if (totalFileSize >= (1024 * 1024 * TotalSize)) {
          this.msg.error(this.props.translations.error_message.event_total_documents_size_too_big, { TOTAL_ALL_DOCUMENTS_SIZE: TotalSize });
          this.dropzone.removeFile(file);

          return false;
        }
      }

      let overAllFileSizes = 0; 
      const { alreadyUploaded, files } = this.state;

      for (let i = 0; i < files.length ; i++ ) {
        overAllFileSizes += (files[i].size || 0);
      }

      for (let i = 0; i < alreadyUploaded.length ; i++ ) {
        overAllFileSizes += (alreadyUploaded[i].size || 0);
      }

      if (overAllFileSizes >= (1024 * 1024 * TotalSize)) {
        this.msg.error(this.props.translations.error_message.event_total_documents_size_too_big, { TOTAL_ALL_DOCUMENTS_SIZE: TotalSize });
        this.dropzone.removeFile(file);

        return false;
      }
    };

    this.removedfile = (file) => {
      const { files } = this.state;
      if (this.state.exceedMaxFile) {
        _.remove(files, { name: file.name });
        this.setState({ files });
        this.props.onTextValue('documents', files);
      } else {
        this.setState({ exceedMaxFile: true });
      }
      const restField = JSON.parse(localStorage.getItem('restField'));
      restField.documents.forEach((item, idx) => {
        if (item.name === file.name) {
          restField.documents.splice(idx, 1);
        }
      });
      localStorage.setItem('restField', JSON.stringify(restField));
    };

    this.dropzone = null;
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAdminText(this.props.companyId, this.props.sectionKey);
    const restField = JSON.parse(localStorage.getItem('restField'));
    this.setState({
      // files: restField.documents,
      alreadyUploaded: restField?.documents,
    });
    this.props.onTextValue('documents', restField?.documents);
  }

  componentDidMount() {
    const restField = JSON.parse(localStorage.getItem('restField'));
    document.querySelector('input.dz-hidden-input').setAttribute('multiple', 'multiple');
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let adminText = newProps.adminText && newProps.adminText.FETCH_ADMIN_TEXT_DOCUMENTS ? newProps.adminText.FETCH_ADMIN_TEXT_DOCUMENTS : '';
    if (newProps.default && newProps.default.FETCH_ADMIN_TEXT_DOCUMENTS_DEL) {
      adminText = newProps.default.FETCH_ADMIN_TEXT_DOCUMENTS_DEL;
    }
    this.setState({
      adminText,
    });

    if (newProps.formSubmitting !== this.props.formSubmitting && newProps.formSubmitting) {
      this.dropzone.processQueue();
    }
  }

  RefreshDocuments( alreadyUploaded ){
    const { files } = this.state;
    this.setState({ alreadyUploaded });
    this.props.onTextValue('documents', [...files, ...alreadyUploaded]);
  }

  render() {
    const dropzoneConfig = this.componentConfig;
    const djsCon = this.djsConfig;
    const { sectionKey, companyId, docsLimit, translations } = this.props;
    const { alreadyUploaded, files } = this.state;
    let dropzoneFileLength = this.dropzone ?  this.dropzone.files.length : 0;
    let dropzoneFileAndDocumentLibraryCount = dropzoneFileLength +  alreadyUploaded?.length;
    let dropzoneDocumentLimit = docsLimit - alreadyUploaded?.length;
    djsCon.maxFiles = dropzoneDocumentLimit;

    const eventHandlers = {
      init: (dropzone) => { this.dropzone = dropzone; },
      maxfilesexceeded: (file) => {
        if (this.msg.state.alerts.length === 0) {
          this.msg.error(translations.error_message.document_maxfile_alert);
        }
        this.setState({ exceedMaxFile: false });
        this.dropzone.removeFile(file);
      },
      drop: this.callbackArray,
      addedfile: this.callback,
      success: this.success,
      error: (file, error, xhr) => {
        if (file.status === 'error') {
          this.success(file);
        }
      },
      removedfile: this.removedfile,
      complete: (file) => {
        this.dropzone.removeFile(file);
      },
    };
    return (
      <div className='block-item'>
        <div className='col-md-6 p-l0 table-cell-box'>
          <div className='form-item-block'>
            <PopupMobile
              companyId={ companyId }
              sectionKey={ sectionKey }
              text={ this.state.adminText }
              translations={ translations }
            />
            <DocumentLibraryModal
              companyId={ companyId }
              parentComponent='QQDocuments'
              docsLimit={ docsLimit }
              dropzoneFileAndDocumentLibraryCount={ dropzoneFileAndDocumentLibraryCount }
              dropzoneFiles={ files }
              RefreshDocuments={ (data) => this.RefreshDocuments(data) }
              translations={ translations }
            />
            <div id='optional_file' className='checkbox-content-box'>
              {files.length > 0 ? (
                <div className='table-scroll event-list col-md-12 m-b20'>
                  <label>{translations.qq_edit.host_documents}</label>
                  <table className='common-table table-align-top'>
                    <tbody>
                      {files.map((item) => (
                        <tr key={ item.name }>
                          <td className='document-col-one'>
                            <div>
                              {item.name}
                            </div>
                          </td>
                          <td className='text-right document-col-two'>
                            <div>
                              <button type='button' className='icon-link' onClick={ () => this.removedfile(item) }>
                                <i className='fa fa-trash-o' aria-hidden='true' />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : ''}
              <div className='form-group'>
                <AlreadyUploadedDocuments
                  alreadyUploaded={ alreadyUploaded }
                  parentComponent='QQDocuments'
                  RefreshDocuments={ (data) => this.RefreshDocuments(data) }
                  labelText={translations.qq_edit.already_included}
                />
                <label htmlFor='name'>
                  {translations.qq_create.qq_documents.dropzone_text}
                </label>
                <DropzoneComponent config={ dropzoneConfig } eventHandlers={ eventHandlers } djsConfig={ djsCon }>
                  <div className='dz-message'>{translations.qq_create.qq_documents.drop_or_click_to_upload}</div>
                </DropzoneComponent>
              </div>
            </div>
          </div>
        </div>
        <EditorPopupSection 
          show={ !this.state.adminText }
          text={ this.state.adminText }
          companyId={ companyId }
          sectionKey={ sectionKey }
          translations={ translations }
          updateText={ (adminText) => { this.setState({ adminText }); } }
        />
        <AlertContainer template={ AlertTemplate } _ref={ (alert) => { this.msg = alert; } } { ...alertOptions } />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  adminText: state.adminTextReducer,
  selectedLang: state.changeLanguageReducer,
  default: state.deleteAdminTextReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchAdminText: (companyId, sectionKey) => dispatch(adminTextActions.fetchAdminText(companyId, sectionKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QQDocuments);
