import React, { Component } from "react";
import Select2 from "react-select2-wrapper";

import Tooltip from "../../../common/Tooltip";
export class CategoriesPermissionTab extends Component {
  componentDidMount() {
    if (this.props.categoryDojo) {
      initializeInputHelpAndTooltip();
    }
  }

  render() {
    const {
      showAdminPermission,
      categoryDojo,
      translations,
      permissionsState,
      permissionAction,
      permissionResourceType,
      permissionResourceId,
      groupsForSelect,
      categoryForSelect,
      objectChanged,
      userPermission,
      resourceSelectOptions,
      usersForSelect,
      portfolioForSelect,
      companyId,
      closeModal,
      optionsForSelect,
      savePermission,
      showAlert,
      currentUser,
    } = this.props;
    return (
      <div
        className={`tab-pane ${categoryDojo ? "" : "fade"} ${showAdminPermission ? "" : "active in"}`}
        id="categoriesPermission"
        role="tabpanel"
        aria-labelledby="categoriesPermission-tab"
        data-backdrop={!categoryDojo}
      >
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="modal-content-section">
                <div className="section-title">
                  <h4>{translations.categoryPermissions}</h4>
                </div>
                {/* Category Permissions Select */}
                <div className="form-group">
                  <div className="row">
                    {Object.entries(permissionsState).map(([key, epHash]) => {
                      let permittedToEdit = this.permittedToEditPermission(epHash, key);
                      return permittedToEdit ? (
                        <React.Fragment key={key}>
                          <div
                            className="col-md-3 col-sm-3 form-itemblock form-itemblock-small"
                            id={`action-select-container-${key}`}
                          >
                            <Tooltip
                              title={permissionAction.title}
                              body={permissionAction.body}
                              currentUser={currentUser}
                              categoryDojo={categoryDojo}
                            />
                            <label>{translations.userCan}</label>
                            <Select2
                              data={[
                                {
                                  text: translations.view,
                                  id: "view",
                                },
                                {
                                  text: translations.edit,
                                  id: "edit",
                                },
                                {
                                  text: translations.create,
                                  id: "create",
                                },
                              ]}
                              value={epHash.permissionSelectedOption}
                              options={{
                                minimumResultsForSearch: -1,
                              }}
                              onSelect={(e) => optionsForSelect(e, key, "permission")}
                              className="action-select"
                            />
                          </div>
                          <div
                            className="col-md-3 col-sm-3 form-itemblock form-itemblock-small"
                            id={`resource-type-select-container-${key}`}
                          >
                            <Tooltip
                              title={permissionResourceType.title}
                              body={permissionResourceType.body}
                              currentUser={currentUser}
                              categoryDojo={categoryDojo}
                            />
                            <label>{translations.categoryIf}</label>
                            <Select2
                              data={resourceSelectOptions()}
                              value={epHash.resourceTypeSelectedOption}
                              options={{
                                minimumResultsForSearch: -1,
                              }}
                              onSelect={(e) => optionsForSelect(e, key, "resource")}
                              className="resource-type-select"
                            />
                          </div>
                          <div className="col-md-4 col-sm-4 form-itemblock" id={`resource-select-container-${key}`}>
                            <Tooltip
                              title={permissionResourceId.title}
                              body={permissionResourceId.body}
                              currentUser={currentUser}
                              categoryDojo={categoryDojo}
                            />
                            <label>{translations.is}</label>
                            {/* We have added three selects intentionally for performance issues */}
                            {epHash.resourceTypeSelectedOption === "User" && (
                              <Select2
                                data={usersForSelect}
                                value={epHash.resourceIdSelectedOption}
                                onSelect={(e) => optionsForSelect(e, key, "resourceId")}
                                className="user-resource-select"
                              />
                            )}
                            {epHash.resourceTypeSelectedOption === "Portfolio" && (
                              <Select2
                                data={portfolioForSelect}
                                value={epHash.resourceIdSelectedOption}
                                onSelect={(e) => optionsForSelect(e, key, "resourceId")}
                                className="event-resource-select"
                              />
                            )}
                            {epHash.resourceTypeSelectedOption === "Group" && (
                              <Select2
                                data={groupsForSelect}
                                value={epHash.resourceIdSelectedOption}
                                onSelect={(e) => optionsForSelect(e, key, "resourceId")}
                                className="group-resource-select"
                              />
                            )}
                            {epHash.resourceTypeSelectedOption === "Category" && (
                              <Select2
                                data={categoryForSelect}
                                value={epHash.resourceIdSelectedOption}
                                onSelect={(e) => optionsForSelect(e, key, "resourceId")}
                                className="contract-resource-select"
                              />
                            )}
                          </div>
                          <div className="col-md-2 col-sm-2 text-left" id="action-buttons">
                            <label className="display-block hidden-xs">&nbsp;</label>
                            {objectChanged[key] ? (
                              <button
                                className="btn btn-default btn-icon m-l10"
                                id="save-button"
                                type="button"
                                title={translations.save}
                                onClick={() => savePermission(key)}
                                disabled={epHash.resourceIdSelectedOption === 0}
                              >
                                <i className="fa fa-save blue-color" aria-hidden="true" />
                              </button>
                            ) : (
                              ""
                            )}
                            {key === "0" ? (
                              <button
                                className="btn btn-default btn-icon m-l10"
                                id="create-button"
                                type="button"
                                title={translations.add}
                                onClick={() => savePermission(key)}
                                disabled={epHash.resourceIdSelectedOption === 0}
                              >
                                <i className="fa fa-plus green-color" aria-hidden="true" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-default btn-icon m-l10"
                                id="delete-button"
                                type="button"
                                title={translations.delete}
                                data-toggle="modal"
                                data-target="#eventPermissionConfirmModal"
                                onClick={() => showAlert(true, userPermission.id, key)}
                              >
                                <i className="fa fa-trash-o red-color" aria-hidden="true" />
                              </button>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="block-content text-left">
            {userPermission.user.company_id !== companyId ? (
              <div className="non_licensed_user_rule_limit">{`* ${translations.ruleCannotBeForExternalUser}`}</div>
            ) : (
              ""
            )}
            {!userPermission.user.cat_dojo_license ? (
              <div className="non_licensed_user_rule_limit">{translations.nonLicensedUserRuleLimit}</div>
            ) : (
              ""
            )}
          </div>
          <div className="block-content pull-right">
            <button className="btn btn-primary" data-dismiss="modal" onClick={() => closeModal("ok")}>
              {translations.ok}
            </button>
          </div>
        </div>
      </div>
    );
  }

  permittedToEditPermission(cpHash, key) {
    if (key === "0" || cpHash.resourceIdSelectedOption === 0) {
      return true;
    }
    const {
      currentUser,
      currentUserCanEditPermissions,
      editOwnCategoryPermissions,
      categoryForSelect,
      portfolioForSelect,
    } = this.props;
    const selectedId = parseInt(cpHash.resourceIdSelectedOption);
    if (currentUserCanEditPermissions) {
      return true;
    } else if (editOwnCategoryPermissions) {
      if (cpHash.resourceTypeSelectedOption === "Group") {
        return false;
      } else if (cpHash.resourceTypeSelectedOption === "User") {
        return cpHash.resourceIdSelectedOption === currentUser.id;
      } else if (cpHash.resourceTypeSelectedOption === "Category") {
        return categoryForSelect.map((ep) => ep.id).includes(selectedId);
      } else if (cpHash.resourceTypeSelectedOption === "Portfolio") {
        return portfolioForSelect.map((ep) => ep.id).includes(selectedId);
      }
    }
    return false;
  }
}
