import * as actionTypes from "../actions/actionsTypes";
import { getUpdatedValue, setEventRoundToLocalStorage } from "../components/advanced_lots/lots/lotCommon";
import last from "lodash/last";
import { fetchBidsOrBlicsDetails } from "./contract/reducerCommonFunctions";
import uniqBy from "lodash/uniqBy";

import lotSliceReducer from "../slices/lots";
import initialState from "./lotReducersInitialState";

const lotReducers = (state = initialState, action) => {
  const payload = action.payload;
  /** @type {typeof initialState} */
  let updatedState = {};
  switch (action.type) {
    case actionTypes.CREATE_UPDATE_LOT: {
      updatedState = { ...state, lot: payload, actionType: action.type };
      break;
    }
    case actionTypes.CREATE_LOT_COMPONENT:
    case actionTypes.UPDATE_LOT_COMPONENT: {
      updatedState = {
        ...state,
        lotComponent: payload.lotComponent,
        lineItemComponents: payload.lineItemComponents,
        actionType: action.type,
      };
      break;
    }
    case actionTypes.UPLOAD_LOT_TEMPLATE: {
      window.successAlertTimeout = "none";
      updatedState = {
        ...state,
        lineItemComponents: payload.lineItemComponents,
        lineItems: payload.lineItems,
        actionType: action.type,
      };
      break;
    }
    case actionTypes.DELETE_LOT:
    case actionTypes.DELETE_LINE_ITEM:
    case actionTypes.DELETE_LOT_COMPONENT: {
      updatedState = {
        ...state,
        lots: payload.lots,
        lineItemComponents: payload.lineItemComponents,
        lineItems: payload.lineItems,
        lotComponents: payload.lotComponents,
        actionType: action.type,
      };
      break;
    }
    case actionTypes.USE_LOT_TEMPLATE: {
      updatedState = {
        ...state,
        lots: payload.lots,
        lineItemComponents: payload.lineItemComponents,
        lineItems: payload.lineItems,
        lotComponents: payload.lotComponents,
        unitOfMeasures: payload.unitOfMeasures,
        lotComponentPicklistOptions: payload.lotComponentPicklistOptions,
        actionType: action.type,
      };
      break;
    }
    case actionTypes.CREATE_LOT_TEMPLATE: {
      updatedState = {
        ...state,
        lotTemplate: action.lotTemplate,
        actionType: action.type,
      };
      break;
    }
    case actionTypes.UPDATE_EVENT: {
      updatedState = {
        ...state,
        event: action.payload.event,
        actionType: action.type,
      };
      break;
    }
    case actionTypes.UPDATE_LINE_ITEM_COMPONENT:
    case actionTypes.SET_LOT_TOTAL: {
      const { lots = [] } = state;
      const _lots = getUpdatedValue(lots, payload.lot);
      updatedState = {
        ...state,
        lineItemComponents: payload.lineItemComponents,
        lot: payload.lot,
        unitOfMeasure: payload.unitOfMeasure,
        lineItems: payload.lineItems,
        lotComponents: payload.lotComponents,
        lotBids: payload.lotBids,
        updateReferenceMessage: payload.updateReferenceMessage,
        actionType: action.type,
        lots: _lots,
      };
      break;
    }
    case actionTypes.UPDATE_BLICS: {
      updatedState = {
        ...state,
        isBLICUpdated: true,
        bidLineItemComponents: payload.bid_line_item_components,
      };
      break;
    }
    case actionTypes.GET_LATEST_BID: {
      // Filter bids and blics according to event round in case of msrfq
      const bids = !!state.eventRound
        ? payload.bids?.filter((bid) => bid.event_round_id === state.eventRound?.id)
        : payload.bids;

      const bidIds = bids.map(({ id }) => id);
      const bidLineItemComponents = !!state.eventRound
        ? payload.bid_line_item_components?.filter(({ bid_id }) => bidIds?.includes(bid_id))
        : payload.bid_line_item_components;

      updatedState = {
        ...state,
        ...payload,
        bids,
        bidLineItemComponents,
        allBids: payload.bids,
        allBlics: payload.bid_line_item_components,
        participatingParticipants: payload.participating_participants,
        isDataUpdated: true,
        actionType: action.type,
      };
      break;
    }
    case actionTypes.SET_LOT_INITIAL_PROPS: {
      /**
       * Adding JS global object for managing flags, to improve performance
       * with less re-rendering and memo component re-rendering
       */
      window.advancedLots = {
        actionQueue: {},
      };
      updatedState = {
        ...state,
        isInitialPropsSet: true,
        lotsBidRange: [],
        lotErrors: {},
        ...action.payload,
        openModal: (modalId) => setTimeout(() => $(modalId).modal("show"), 500),
      };
      break;
    }
    case actionTypes.UPDATE_AWARD_LOT: {
      let index = state.lots.findIndex((item) => item.id === payload.data.lot.id);
      state.lots.splice(index, 1, payload.data.lot);
      const paUrls = {};
      const lotId = payload.data.lot.id;
      Object.keys(state.per_angusta_integration_urls).forEach((key) => {
        if (parseInt(key) === lotId) {
          paUrls[key] = payload.data.per_angusta_integration_url[lotId];
        } else {
          paUrls[key] = state.per_angusta_integration_urls[key];
        }
      });
      updatedState = {
        ...state,
        award_total: payload.data.award_total,
        award_saving: payload.data.award_saving,
        award_total_percentage: payload.data.award_total_percentage,
        per_angusta_integration_urls: paUrls,
      };
      break;
    }
    case actionTypes.UPDATE_AWARD_LOTS: {
      updatedState = {
        ...state,
        award_notification_messages: payload.data.award_notification_messages,
        award_notifications: payload.data.award_notifications,
        lots: payload.data.lots,
        awardNotificationFooter: payload.data.award_notification_footer,
      };
      break;
    }
    case actionTypes.UPDATE_BLIC_LIST: {
      const { bid_line_item_components, calculation_blics, bid, errors, lot_total_blic: lotTotalBlic } = action.data;
      let bids = getUpdatedValue(state.bids, bid);

      let { bid_line_item_components: blics, lot_total_blics = [] } = state;
      lot_total_blics = getUpdatedValue(lot_total_blics, lotTotalBlic, "bid_id");
      bid_line_item_components.forEach((blic) => {
        blics = getUpdatedValue(blics, blic);
      });
      if (Array.isArray(calculation_blics)) {
        calculation_blics.forEach((blic) => {
          blics = getUpdatedValue(blics, blic);
        });
      }
      let _errors = errors && errors.length ? [action.licId] : [];
      _errors = Array.isArray(state.errors) ? state.errors.concat(_errors) : _errors;
      if (errors && errors.length) {
        advancedLots.openSuccessModal = false;
      } else {
        _errors = _errors.filter((id) => id !== action.licId);
        advancedLots.openSuccessModal = action.lotId;
      }

      _errors = [...new Set(_errors)];

      // Remove any errors for this lot item component if its valid
      if (!_errors.includes(action.licId) && state?.lotItemComponentErrors?.[action.lotId]) {
        delete state.lotItemComponentErrors[action.lotId][action.licId];
      }

      updatedState = {
        ...state,
        bid_line_item_components: blics,
        errors: _errors,
        bids,
        lot_total_blics,
        lotItemComponentErrors: { ...state.lotItemComponentErrors },
      };
      break;
    }
    case actionTypes.LOT_REJECTED: {
      updatedState = {
        ...state,
        bids: getUpdatedValue(state.bids, payload.data.bid),
      };
      break;
    }
    case actionTypes.FETCH_BIDS: {
      updatedState = fetchBidsOrBlicsDetails(state, payload);
      break;
    }
    case actionTypes.DELETE_BID: {
      // Filter bids according to event round in case of msrfq
      const allBids = payload.bids;
      const bids = !!state.eventRound ? allBids.filter((bid) => bid.event_round_id === state.eventRound.id) : allBids;

      updatedState = {
        ...state,
        bidList: payload.bidList,
        deletedBidId: payload.deletedBidId,
        allBids,
        bids,
        award_total: payload.award_total,
        award_saving: payload.award_saving,
        award_total_percentage: payload.award_total_percentage,
      };
      break;
    }
    case actionTypes.LOT_ERROR: {
      updatedState = {
        ...state,
        lotErrors: { ...state.lotErrors, ...action.lotErrors },
      };
      break;
    }
    case actionTypes.BLIC_ERROR: {
      let errors = Array.isArray(state.errors) ? state.errors.concat([action.licId]) : [action.licId];
      errors = [...new Set(errors)];

      const lotItemComponentErrors = state.lotItemComponentErrors ? { ...state.lotItemComponentErrors } : {};
      if (!lotItemComponentErrors[action.lotId]) {
        lotItemComponentErrors[action.lotId] = {};
      }

      lotItemComponentErrors[action.lotId][action.licId] = {
        lineItemId: action.lineItemId,
        lineItemComponentId: action.licId,
        messages: [action.message],
      };

      updatedState = {
        ...state,
        errors,
        lotItemComponentErrors,
      };
      break;
    }
    case actionTypes.BID_RANGE: {
      let lotsBidRange = state.lotsBidRange ? state.lotsBidRange : [];
      if (action.data) {
        lotsBidRange = getUpdatedValue(lotsBidRange, action.data);
      }
      const { currency, exchange_rate } = action.data;
      updatedState = {
        ...state,
        currency,
        exchange_rate,
        lotsBidRange,
      };
      break;
    }
    case actionTypes.MONITOR_LIVE_FEED: {
      let bids = state.bids;
      let bid_line_item_components = state.bid_line_item_components;
      // Old bids(of all lots) excluded current lot's(for which channel is calling) bids for which bid placed.
      const allBids = bids.filter((bid) => bid.lot_id !== payload.lot_id);
      const bidIds = allBids.map((bid) => bid.id);
      // Exclude BLICs for current lot.
      const blics = bid_line_item_components.filter((blic) => bidIds.includes(blic.bid_id));
      bids = [...new Set([...allBids, ...payload.bids])];
      bid_line_item_components = [...blics, ...payload.bid_line_item_components];

      const oldBid = last(
        state.bids
          .filter(({ lot_id }) => lot_id === payload.lot_id)
          .sort((a, b) => new Date(a.submitted_at) - new Date(b.submitted_at))
      );
      if (window.advancedLots && !Array.isArray(window.advancedLots.affectedBid)) {
        advancedLots.affectedBid = [oldBid];
      } else {
        advancedLots.affectedBid.push(oldBid);
      }
      let lotsBidRange = state.lotsBidRange ? state.lotsBidRange : [];
      if (payload.bid_range && payload.lot_id) {
        lotsBidRange = getUpdatedValue(lotsBidRange, {
          id: payload.lot_id,
          bid_range: payload.bid_range,
        });
      }
      updatedState = {
        ...state,
        ...payload,
        bids,
        participatingParticipants: payload.participating_participants,
        best_bids: state.event.event_type === "Open" ? payload.best_bids : state.best_bids,
        bid_line_item_components,
        lotsBidRange,
      };
      break;
    }
    case actionTypes.OPEN_BID_MODAL: {
      return {
        ...state,
        show_bid_modal: payload,
      };
    }
    case actionTypes.CLOSE_BID_MODAL: {
      return {
        ...state,
        show_bid_modal: null,
      };
    }
    case actionTypes.SET_ACTIVE_LI: {
      let activeLis;
      if (!!payload.removeLi) {
        activeLis = [
          ...uniqBy(
            state.activeLis?.filter((li) => li?.lot_id !== payload.removeLi?.lot_id),
            "id"
          ),
        ];
      } else {
        activeLis = [
          ...uniqBy(
            state.activeLis?.filter((li) => li?.lot_id !== payload.li?.lot_id),
            "id"
          ),
          payload.li,
        ];
      }
      return {
        ...state,
        activeLis,
      };
    }
    case actionTypes.CREATE_MSRFQ_ROUND:
      setEventRoundToLocalStorage(state.role === "Host", last(action.payload?.event_rounds)?.id);
      updatedState = { ...state, ...action.payload };
      break;
    case actionTypes.SET_ACTIVE_MSRFQ_ROUND:
      const { event_participant_event_rounds, event_participants, bids } = state;
      setEventRoundToLocalStorage(state.role === "Host", action.payload?.id);
      const participantForCurrentRoundIds = event_participant_event_rounds
        .filter((eper) => eper.event_round_id === action.payload.id)
        ?.map(({ event_participant_id }) => event_participant_id);
      const participatingParticipant = event_participants?.filter(({ id }) =>
        participantForCurrentRoundIds?.includes(id)
      );
      const allBids = state.allBids ?? bids;
      const allBlics = state.allBlics ?? state.bid_line_item_components;
      const remainingBids = allBids?.filter((bid) => bid.event_round_id === action.payload.id);
      const remainingBidsIds = remainingBids?.map(({ id }) => id);
      const remainingBlics = allBlics?.filter(({ bid_id }) => remainingBidsIds?.includes(bid_id));
      const bestOverAllParticipant = state.bestOverAllParticipant ?? state.participating_participants;
      updatedState = {
        ...state,
        eventRound: action.payload,
        bids: remainingBids,
        bid_line_item_components: remainingBlics,
        bidLineItemComponents: remainingBlics,
        allBids,
        allBlics,
        participatingParticipant,
        participating_participants: participatingParticipant,
        bestOverAllParticipant,
      };
      break;
    case actionTypes.SET_BEST_OVERALL_ROUND:
      setEventRoundToLocalStorage(state.role === "Host", null);
      updatedState = {
        ...state,
        eventRound: null,
        bids: state.allBids ?? state.bids,
        bid_line_item_components: state.allBlics ?? state.bid_line_item_components,
        bidLineItemComponents: state.allBlics ?? state.bidLineItemComponents,
        participatingParticipant: state.bestOverAllParticipant ?? state.participatingParticipant,
        participating_participants: state.bestOverAllParticipant ?? state.participating_participants,
      };
      break;
    default: {
      updatedState = lotSliceReducer(state, action);
      break;
    }
  }

  return updatedState;
};

export default lotReducers;
