import React from "react";
import HeaderValues from "./HeaderValues";
import LotDetailsForPriceComponent from "./LotDetailsForPriceComponent";
import OverallScoreHeaderValues from "./OverallScoreHeaderValues";

const LotDetails = (props) => {
  const { uom, showOverallScore, modalType, lic } = props;

  const style = {
    width: "100%",
    border: "0",
    cellSpacing: "0",
    cellPadding: "0",
  };
  const headerValue = (translation, value, classNames = "") => (
    <HeaderValues translation={props.translations[translation]} value={value} classNames={classNames} />
  );
  const description = lic && lic.description ? lic.description : "-";
  const isPriceComponent = modalType === "Lot" || (lic && lic.is_price);
  return (
    <div className="popup-main padding-top">
      <div className="lot-content">
        <table className="table custom-responsive-table gray-table-bg" style={style}>
          <tbody>
            {isPriceComponent
              ? headerValue("uom", uom ? uom.name : null, "lot-info active")
              : headerValue("description", description, "lot-info active")}
            <LotDetailsForPriceComponent {...props} headerValue={headerValue} isPriceComponent={isPriceComponent} />
            {showOverallScore && modalType === "Lot" ? (
              <OverallScoreHeaderValues {...props} headerValue={headerValue} />
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LotDetails;
