import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import first from "lodash/first";
import minBy from "lodash/minBy";
import round from "lodash/round";
import last from "lodash/last";
import Modal from "react-modal";
import PropTypes from "prop-types";
import {
  updateAwardLots,
  deleteNotification,
  sendNotification,
  saveAwardLots,
  getEskerAuthToken,
} from "../../../../actions/lotActions";
import Tooltip from "../../../common/Tooltip";
import {
  showCurrency,
  fetchAssociatedCurrency,
  multiCurrencyEvent,
  formatPercentileNumber,
  lotBids,
  fullCompanyNameOrEmail,
  anyLotAwarded,
  checkAllLotsNotified,
  findLotTotalLic,
} from "../../common";
import { sortedParticipantsByLotAndRank } from "../../eventCommon";
import {
  differenceOfferedWithCurrency,
  differenceOfferedForBid,
  differenceOfferedPercentageForBid,
  validBids,
  companyNameAndEmail,
  showBid,
} from "../../bidCommon";
import { isEmpty } from "lodash";
import { createEskerRequisitionUrl, createEskerProcurementDocData } from "./create-esker-requisition-url";
import { updateEskerRequisitionUrl, updateEskerRequisitionLotData } from "./update-esker-requisition-url";
import TrixEditor from "@/components/common/TrixEditor";
import { postRequest } from "@/utils/js-post";
import { EventPropType, HostPropType } from "@/common-prop-types";
import { t } from "@/i18n";
import { injectWinningBidsAndParticipantInToLot } from "./esker-purchase-requistion";

const ActionsForClosedOrCompletedEventsPropTypes = {
  event: PropTypes.shape(EventPropType).isRequired,
  reportType: PropTypes.oneOf(["auction", "RFQ"]).isRequired,
  canShowAwardLotsButton: PropTypes.bool.isRequired,
  isAlreadyAwarded: PropTypes.bool,
  /**
   * @type {(state: Boolean) => void} toggleModal
   */
  toggleModal: PropTypes.func.isRequired,
};

const DownloadReportButtonPropTypes = {
  eventId: PropTypes.number.isRequired,
  reportType: PropTypes.oneOf(["auction", "RFQ"]).isRequired,
  buttonTitle: PropTypes.string.isRequired,
};
class AwardLots extends Component {
  state = {
    showModel: false,
    showAwardNotice: false,
    awardedParticipantsList: [],
    notifiedParticipantsList: [],
    selectedParticipantsList: [],
    awardedParticipantMessages: [],
    eskerRequisition: [],
    totalBidValue: 0,
    awardNoticeMessage: this.props.translations.default_awarded_message,
    purchaseOrderNumber: "",
    ccEmail: "",
    successMessage: "",
    errorMessage: "",
    flag: false,
    unSelectedLotIds: [],
    isMessageUpdated: false,
    participantId: null,
    clickedButton: null,
  };

  static getDerivedStateFromProps(newProps) {
    let bidValues = 0;
    let isAlreadyAwarded = false;
    let flag = false;
    const { bids, lots, declinedParticipantsList = [], participating_participants, event, unit_of_measures } = newProps;
    if (lots) {
      let awardRecord = {};
      lots.forEach((lot) => {
        if (!lot.is_event_total) {
          const activeBids = validBids(participating_participants, bids);
          const allBids = bids.filter((bid) => declinedParticipantsList.indexOf(bid.user_id) === -1);
          let minValue = minBy(allBids, "price");
          const tiedBids = minValue ? allBids.filter((bid) => minValue.price === bid.price) : [];
          minValue = tiedBids.length > 1 ? undefined : minValue;
          isAlreadyAwarded = true;
          flag = true;
          let lBids = lotBids(lot.id, activeBids);
          if (event.progress_to_auction || event.event_category_auction) {
            lBids = lBids.filter((l) => !l.bid_at_rfq_state);
          }
          if (!event.bid_at_detail_level) {
            lBids = lBids.filter((b) => b.details_added);
          }
          lBids = sortedParticipantsByLotAndRank(lBids, event);
          const userIds = lBids.map((b) => b.user_id);

          const userID = lot.awarded_participant_id || (lBids && first(lBids) && first(lBids).user_id);

          if (userID) {
            awardRecord[lot.id] = {
              awarded_participant_id: userID,
            };
          }
        }
      });
      if (isAlreadyAwarded) {
        return {
          flag,
          totalBidValue: round(bidValues, 2),
          awardedParticipantsList: awardRecord,
        };
      } else {
        return {
          flag,
        };
      }
    }
  }

  componentDidMount() {
    const { company_cc_email, company_cc_email_on } = this.props;
    if (company_cc_email && company_cc_email_on) {
      this.setState({
        ccEmail: company_cc_email,
      });
    }
    Modal.setAppElement("div");
  }

  changeParticipant = (event, lot, participant) => {
    let { awardLots } = this.props;
    const participantId = Number(event.target.value);

    const { awardedParticipantsList, eskerRequisition } = this.state;
    awardedParticipantsList[lot.id] = {
      awarded_participant_id: participantId,
    };
    this.setState({
      awardedParticipantsList: awardedParticipantsList,
    });
    awardLots(this.props.event.id, lot.id, participantId);
    // this.includeInAwardNotice(lot, participant, event.target.value);
    this.resetAwardedNotice(lot, participant, event.target.value);
    let { unSelectedLotIds } = this.state;
    if (event.target.value < 0) {
      this.setState({
        // If the user changes the awarded participant to "None" then we must
        // remove them from the perches requisition.
        eskerRequisition: eskerRequisition.filter((id) => id !== lot.id),
        unSelectedLotIds: [...new Set([...unSelectedLotIds, lot.id])],
      });
    } else {
      let index = unSelectedLotIds.indexOf(lot.id);
      if (index > -1) {
        unSelectedLotIds.splice(index, 1);
      }
      this.setState({
        unSelectedLotIds: [...new Set(unSelectedLotIds)],
      });
    }
  };

  resetAwardedNotice(lot, participant, selectedValue = 0) {
    const refThis = this;
    const { classList } = refThis.refs[`${lot.id}IncludeButton`];

    if (participant) {
      const record = { lotId: lot.id, name: lot.name, participant, position: lot.position };
      const checkNotifiedRecord = this.state.notifiedParticipantsList.filter((lot) => lot.lotId !== record.lotId);
      const checkNotifiedParticipantRecord = this.state.selectedParticipantsList.filter((p) => p.id !== participant.id);
      checkNotifiedParticipant(checkNotifiedRecord, checkNotifiedParticipantRecord, participant);
      classList.remove("btn-success");
      this.resetNotificationMessage();
      this.setState({
        notifiedParticipantsList: checkNotifiedRecord,
        selectedParticipantsList: checkNotifiedParticipantRecord,
      });
    }
  }

  includeInAwardNotice(lot, participant, selectedValue = 0) {
    const { awardLots, event } = this.props;
    const { notifiedParticipantsList, unSelectedLotIds, selectedParticipantsList } = this.state;
    const refThis = this;
    const { classList } = refThis.refs[`${lot.id}IncludeButton`];
    this.changeParticipant({ target: { value: selectedValue } }, lot, participant);
    if (unSelectedLotIds.includes(lot.id)) return null;

    if (participant) {
      const record = {
        lotId: lot.id,
        name: lot.name,
        participant,
        position: lot.position,
      };

      const checkNotifiedRecord = notifiedParticipantsList.filter((lot) => lot.lotId !== record.lotId);
      const checkNotifiedParticipantRecord = selectedParticipantsList.filter((p) => p.id !== participant.id);
      if (classList.contains("btn-success")) {
        classList.remove("btn-success");
        checkNotifiedParticipant(checkNotifiedRecord, checkNotifiedParticipantRecord, participant);
        this.setState({
          notifiedParticipantsList: checkNotifiedRecord,
          selectedParticipantsList: checkNotifiedParticipantRecord,
        });
        if (event.id && lot.lotId && participant.id) {
          awardLots(event.id, lot.lotId, participant.id);
        }
      } else if (selectedValue > 0) {
        classList.add("btn-success");
        checkNotifiedRecord.push(record);
        checkNotifiedParticipantRecord.push(participant);
        this.setState({
          notifiedParticipantsList: checkNotifiedRecord,
          selectedParticipantsList: checkNotifiedParticipantRecord,
        });
        refThis.refs[`${lot.id}RemoveButton`].classList.remove("btn-danger");
      }
      this.resetNotificationMessage();
    }
  }

  setNotificationMessage(participantId) {
    if (participantId) {
      const { notifiedParticipantsList, selectedParticipantsList } = this.state;
      const checkNotifiedRecord = notifiedParticipantsList.filter(
        (lot) => lot.participant.id === parseInt(participantId)
      );
      this.setState({
        awardedParticipantMessages: checkNotifiedRecord,
        participantId: parseInt(participantId),
      });
    } else {
      this.resetNotificationMessage();
    }
  }

  resetNotificationMessage() {
    this.setState({
      participantId: null,
      awardedParticipantMessages: [],
    });
  }

  removeFromAwardNotice(lot, participant) {
    this.changeParticipant({ target: { value: -1 } }, lot, participant);
    const refThis = this;
    const selectTag = refThis.refs[`${lot.id}ParticipantValue`];
    const { classList } = refThis.refs[`${lot.id}RemoveButton`];
    if (selectTag.value) {
      if (classList.contains("btn-danger")) {
        classList.remove("btn-danger");
      } else {
        const checkNotifiedRecord = this.state.notifiedParticipantsList.filter((item) => item.lotId !== lot.id);
        this.setState({
          notifiedParticipantsList: checkNotifiedRecord,
        });
        refThis.refs[`${lot.id}IncludeButton`].classList.remove("btn-success");
      }
    }
    this.resetNotificationMessage();
    selectTag.value = -1;
  }

  async createNewEskerProcurementDoc(procurement_doc_type) {
    const { lots, bids, event, participants, event_currency, currencies_hash, unit_of_measures } = this.props;
    const { eskerRequisition, awardedParticipantsList } = this.state;
    // Build a map of all the currencies that a host price could be while
    // copying the currencies_hash so we don't accidentally change the
    // reference that will be stored in redux
    const currencies = { ...currencies_hash, [event_currency.id]: event_currency };
    this.setState({ clickedButton: procurement_doc_type });

    const procurementDocData = createEskerProcurementDocData(
      procurement_doc_type,
      event,
      lots,
      bids,
      currencies,
      participants,
      awardedParticipantsList,
      eskerRequisition,
      unit_of_measures
    );

    const integrationUrl =
      this.props.esker_integration_url +
      (procurement_doc_type === "vendor_contract" ? "&pName=P2P+-+Contract" : "&pName=Purchase+Requisition+V2") +
      "#InputData:";
    const { payload } = await this.props.getEskerAuthToken();
    this.setState({ clickedButton: null });
    const connectionToken = payload?.data.token.token;

    if (connectionToken) {
      this.submitEskerRequisition(integrationUrl, event, procurementDocData, connectionToken);
    }
  }

  submitEskerRequisition(integrationUrl, event, procurementDocData, connectionToken) {
    postRequest({
      url: createEskerRequisitionUrl(integrationUrl, event, procurementDocData),
      data: { connectionToken },
      target: "_blank",
    });
  }

  async updateEskerRequisition() {
    const { lots, bids, event, participants, event_currency, currencies_hash, unit_of_measures } = this.props;
    const { eskerRequisition, awardedParticipantsList } = this.state;

    const currencies = { ...currencies_hash, [event_currency.id]: event_currency };
    this.setState({ clickedButton: "update_requisition" });
    const lotData = lots
      .filter((lot) => eskerRequisition.includes(lot.id))
      .filter((lot) => this.props.esker_integration_details.some((element) => element.lot_id === lot.id))
      .map(injectWinningBidsAndParticipantInToLot(bids, event, participants, awardedParticipantsList, unit_of_measures))
      .map(updateEskerRequisitionLotData(event, currencies, this.props.esker_integration_details));

    const { payload } = await this.props.getEskerAuthToken();
    this.setState({ clickedButton: null });
    const connectionToken = payload?.data.token.token;

    if (connectionToken) {
      postRequest({
        url: updateEskerRequisitionUrl(
          this.props.esker_procurement_doc_type,
          this.props.esker_integration_update_procurement_doc_url,
          event,
          this.props.esker_procurement_doc_id,
          lotData
        ),
        data: { connectionToken },
        target: "_blank",
      });
    }
  }

  createNewAwardNotice() {
    const { notifiedParticipantsList, showAwardNotice } = this.state;
    if (notifiedParticipantsList.length) {
      this.setState({
        showAwardNotice: !showAwardNotice,
        notifiedError: false,
        purchaseOrderNumber: "",
      });
    } else {
      this.setState({
        notifiedError: true,
        showAwardNotice: false,
        purchaseOrderNumber: "",
      });
      setTimeout(() => {
        this.setState({
          notifiedError: false,
        });
      }, 2000);
    }
  }

  updateAwardNoticeMsg(event) {
    this.setState({
      awardNoticeMessage: event,
      isMessageUpdated: true,
    });
  }

  updatePurchaseOrderNo(event) {
    this.setState({
      purchaseOrderNumber: event.target.value.trim(),
    });
  }

  deleteAwardNotice(lot) {
    const { deleteNotification, event } = this.props;
    deleteNotification(lot.id, event.id, t("success_message_for_awarded_lot_deleted"));
  }

  sendAwardNotice() {
    let { event, sendNotification } = this.props;
    let { awardNoticeMessage, notifiedParticipantsList, ccEmail } = this.state;

    if (notifiedParticipantsList.length) {
      let lotsList = {};
      let participantList = notifiedParticipantsList.map((lot, idx) => {
        if (!lot.is_event_total) {
          lotsList[idx] = {
            lot_id: lot.lotId,
            lot_name: lot.name,
            participant_id: lot.participant.id,
            position: lot.position,
          };
          return lot.participant.id;
        }
      });
      let payload = {
        lots_list: lotsList,
        participant_list: participantList.filter(Boolean),
        event_id: event.id,
        notify_message: awardNoticeMessage.toString(),
        po_number_text: "",
        cc_email: ccEmail,
      };

      sendNotification(payload, event.id);
      this.setState({
        showAwardNotice: false,
        notifiedParticipantsList: [],
      });
    }
  }

  awardLotsToParticipants() {
    const { saveAwardLots, event, lots } = this.props;
    const { awardedParticipantsList } = this.state;
    this.setState({
      notifiedParticipantsList: [],
    });
    const refThis = this;
    lots.forEach((lot) => {
      if (!lot.is_event_total) {
        if (refThis.refs[`${lot.id}IncludeButton`]) {
          const { classList } = refThis.refs[`${lot.id}IncludeButton`];
          classList.remove("btn-success");
        }
      }
    });
    saveAwardLots({ lot: awardedParticipantsList }, event.id, t("success_message_for_awarded_lots"));
    this.toggleModal(false);
  }

  updateccEmail(e) {
    this.setState({ ccEmail: e.target.value });
  }

  // Enable update button if the eskerRequisition array contains a lot.id that
  // is in the eskerData array of objects
  isEskerUpdateButtonDisabled() {
    const eskerRequisition = this.state.eskerRequisition;
    const eskerData = this.props.esker_integration_details;
    const requestHasNotBeenLinked = eskerRequisition.some((id) => !eskerData.some((data) => data.lot_id === id));
    // why do we have to call this.props for eskerAuthTokenStatus? why can't we just use the state?
    return requestHasNotBeenLinked || eskerRequisition.length === 0 || this.props.eskerAuthTokenStatus === "pending";
  }

  getRows = (lots) => {
    const {
      currencies_hash: currenciesHash,
      event_currency: eventCurrency,
      event,
      locale,
      translations,
      permitted_to_edit,
      bids,
      participating_participants,
      participants,
      award_notifications,
      award_notification_messages,
      lotsLoaders,
      awardNotificationFooter: awardNotificationFooterData,
      per_angusta_integration_urls: perAngustaIntegrationUrls,
    } = this.props;
    const isMultiCurrencyEvent = multiCurrencyEvent(event);
    const activeBids = validBids(participating_participants, bids);
    const { unSelectedLotIds, awardedParticipantsList } = this.state;
    return lots.map((lot, index) => {
      const selectedNull = unSelectedLotIds.includes(lot.id);

      if (lot.is_event_total) return null;

      let lBids = lotBids(lot.id, activeBids);
      if (event.progress_to_auction || event.event_category_auction) {
        lBids = lBids.filter((l) => !l.bid_at_rfq_state);
      }
      if (!event.bid_at_detail_level) {
        lBids = lBids.filter((b) => b.details_added);
      }
      lBids = sortedParticipantsByLotAndRank(lBids, event);

      const userIds = lBids.map((b) => b.user_id);
      const activeParticipants = participants.filter((participant) => userIds.includes(participant.id));
      const userID = lot.awarded_participant_id || (lBids && lBids.length && first(lBids).user_id);
      let participant =
        userID && userID != -1
          ? activeParticipants.find((participant) => participant.id === userID)
          : first(activeParticipants);
      const latestBids = lBids.filter((bid) => bid.user_id === participant.id);
      const latestBid = last(latestBids);
      const currency = isMultiCurrencyEvent ? fetchAssociatedCurrency(currenciesHash, lot) : eventCurrency;
      let awardNotifications = award_notifications && award_notifications.filter((x) => x.lot_id === lot.id);
      const awardNotification = last(awardNotifications);
      let lotMessageId = awardNotification && awardNotification.message_id;
      let awardNotificationMessages =
        award_notification_messages && award_notification_messages.find((x) => x.id === lotMessageId);
      let lotMessage = awardNotificationMessages && awardNotificationMessages.message;
      const defaultValue = lot.awarded_participant_id > -1 ? participant && participant.id : -1;
      const awardNotificationFooter = awardNotificationFooterData
        ? awardNotificationFooterData
        : t("award_notification_footer");
      const bidDetailsProps = {
        hosts: this.props.hosts,
        showOverallScore: this.props.show_overall_score,
        isLineItemDetail: false,
        translations: translations,
      };
      const totalLic = findLotTotalLic(this.props.line_item_components, lot.lot_total_lic_id);
      const decimalPlace = totalLic && totalLic.decimal_place ? totalLic.decimal_place : 2;

      return (
        <tr className="lot-details" key={lot.id}>
          {this.props.can_create_esker_procurement_doc && (
            <td>
              <label
                htmlFor={`esker[${lot.id}]`}
                className="css-input css-checkbox css-checkbox-default center-content-horizontal-vertical"
              >
                <input
                  type="checkbox"
                  id={`esker[${lot.id}]`}
                  name={`esker[${lot.id}]`}
                  // The input is disabled if the user has set the current
                  // awarded lot to be "None", we can not send this lot in the
                  // perches requisition if no one has bid on the lot or the user
                  // chooses not to include it.
                  disabled={(this.state.awardedParticipantsList?.[lot.id]?.awarded_participant_id || -1) === -1}
                  checked={this.state.eskerRequisition.includes(lot.id)}
                  onChange={() => {
                    let eskerRequisition = [...this.state.eskerRequisition];
                    if (eskerRequisition.includes(lot.id)) {
                      eskerRequisition = eskerRequisition.filter((id) => id !== lot.id);
                    } else {
                      eskerRequisition.push(lot.id);
                    }
                    this.setState({ eskerRequisition });
                  }}
                />
                <span />
              </label>
            </td>
          )}
          <td>{index + 1}</td>
          <td>{lot.name}</td>
          <td>
            {permitted_to_edit ? (
              <div className="row">
                <div className="selectbox col-sm-12 award-articipants">
                  <select
                    ref={`${lot.id}ParticipantValue`}
                    className="form-control awarded_participant"
                    onChange={(e) => {
                      this.changeParticipant(e, lot, participant);
                    }}
                    defaultValue={defaultValue}
                    disabled={lot.is_award_notify}
                    title={
                      companyNameAndEmail(
                        participants.find(
                          (participant) => participant.id === awardedParticipantsList[lot.id]?.awarded_participant_id
                        )
                      ) || t("none")
                    }
                  >
                    {lBids.map((bid) => {
                      return (
                        <option key={bid.id} value={bid.user_id}>
                          {companyNameAndEmail(participants.find((participant) => participant.id === bid.user_id))}
                        </option>
                      );
                    })}
                    <option key={lot.id + 1} value={-1}>
                      {t("none")}
                    </option>
                  </select>
                </div>
                <div className="col-sm-2">
                  {lotsLoaders[`${lot.id}_updateAwardLotsRequested`] ? (
                    <i className="fa fa-spinner fa-spin text-danger" />
                  ) : null}
                </div>
              </div>
            ) : (
              <div>
                {lot.awarded_participant_id && lot.awarded_participant_id !== -1 ? (
                  lBids.map(
                    (bid) =>
                      bid.user_id === lot.awarded_participant_id && (
                        <p key={bid.id} value={bid.user_id}>
                          {fullCompanyNameOrEmail(
                            activeParticipants.find((participant) => participant.id === bid.user_id)
                          )}
                        </p>
                      )
                  )
                ) : (
                  <p>{t("node")}</p>
                )}
              </div>
            )}
          </td>
          <td className="bid_values">
            <span className="bid-value" ref={`${lot.id}BidValue`}>
              {!selectedNull && lot.awarded_participant_id > -1 && latestBid
                ? showBid(latestBid, lot, currency, locale, "host", bidDetailsProps, decimalPlace)
                : showCurrency("-", currency, 2, locale, t("number_format"))}
            </span>
          </td>
          <td className="bid_diff_values" ref={`${lot.id}diffOfferedForBid`}>
            {!selectedNull && latestBid
              ? differenceOfferedWithCurrency(
                  differenceOfferedForBid(latestBid, lot, event),
                  currency,
                  locale,
                  t("number_format"),
                  2
                )
              : differenceOfferedWithCurrency("-", currency, locale, t("number_format"), 2)}
          </td>
          <td ref={`${lot.id}OfferedPercentageForBid`}>
            {!selectedNull && latestBid
              ? formatPercentileNumber(differenceOfferedPercentageForBid(latestBid, lot, event), locale)
              : "-"}
          </td>
          <td className="options">
            {!lot.is_award_notify ? (
              <div align="center">
                {permitted_to_edit ? (
                  <div>
                    <button
                      ref={`${lot.id}IncludeButton`}
                      name="button"
                      type="button"
                      className="btn clearfix small tick fa fa-check fa-white"
                      label="includeAward"
                      data-testid={`includeAward_${lot.id}`}
                      onClick={() => {
                        this.includeInAwardNotice(lot, participant, defaultValue);
                        // this.changeParticipant({ target: { value: defaultValue } }, lot, participant);
                      }}
                    />
                    &nbsp;
                    <button
                      ref={`${lot.id}RemoveButton`}
                      name="button"
                      type="button"
                      label="removeAward"
                      data-testid={`removeAward_${lot.id}`}
                      className={`btn award-lot-btn clearfix small remove hover cross fa fa-remove`}
                      onClick={() => {
                        this.removeFromAwardNotice(lot, participant);
                      }}
                    />
                  </div>
                ) : (
                  "-"
                )}
              </div>
            ) : (
              <div className="text-center">
                <div className="yes notified pull-left text-success">
                  <span className="fa fa-fw fa-check" />
                  {t("award_notified")}
                </div>
                <div className="m-t5 clearfix">
                  <>
                    {permitted_to_edit && (
                      <button
                        onClick={() => {
                          this.deleteAwardNotice(lot);
                        }}
                        name="button"
                        type="button"
                        disabled={lotsLoaders[`${lot.id}_deleteNotificationRequest`]}
                        className={`btn clearfix small white button red notified_trashed btn-award`}
                      >
                        {lotsLoaders[`${lot.id}_deleteNotificationRequest`] ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : (
                          <span className="fa fa-trash-o red-color" />
                        )}
                      </button>
                    )}
                    &nbsp;
                    <button
                      type="button"
                      className={`btn clearfix small notify_info btn-award `}
                      data-toggle="popover"
                      title={t("award_comment_title")}
                      data-content={
                        lotMessage &&
                        `${lotMessage.replace(/\r?\n/g, "<br>")}<hr/>
                          <p>
                            <strong>
                            ${awardNotificationFooter && awardNotificationFooter[lotMessageId]}
                            </strong>
                           </p>`
                      }
                    >
                      <span className="fa fa-comment" />
                    </button>
                  </>
                </div>
              </div>
            )}
          </td>
          {!isEmpty(perAngustaIntegrationUrls) ? (
            <td>
              {perAngustaIntegrationUrls[lot.id] ? (
                <a
                  href={perAngustaIntegrationUrls[lot.id]["url"]}
                  className={classNames({
                    "btn btn-primary": true,
                    disabled: perAngustaIntegrationUrls[lot.id]["url"] === "#",
                  })}
                  target="_blank"
                  rel="noreferrer"
                >
                  {perAngustaIntegrationUrls[lot.id]["title"]}
                </a>
              ) : null}
            </td>
          ) : null}
        </tr>
      );
    });
  };

  getTableHead = () => {
    const { translations, per_angusta_integration_urls: perAngustaIntegrationUrls } = this.props;
    const {
      lot_position,
      lot_lot_name,
      lot_awarded_participant,
      bid_latest_value,
      lot_difference_value,
      lot_award_notice,
      lot_total_lot_difference_percentage,
    } = translations.infos;
    return (
      <thead>
        <tr className="table-block-title">
          {this.props.can_create_esker_procurement_doc && <td className="table-cell-ellipsis wid-5">&nbsp;</td>}
          <td className="table-cell-ellipsis wid-8" title={t("lot_position")}>
            {t("lot_position")}&nbsp;
            <Tooltip title={lot_position.title} body={lot_position.body} />
          </td>
          <td className="award-lot-name table-cell-ellipsis wid-15" title={t("lot_name")}>
            {t("lot_name")}&nbsp;
            <Tooltip title={lot_lot_name.title} body={lot_lot_name.body} />
          </td>
          <td className="p-8-narrow table-cell-ellipsis wid-15" title={t("awarded_participant")}>
            {t("awarded_participant")}&nbsp;
            <Tooltip title={lot_awarded_participant.title} body={lot_awarded_participant.body} />
          </td>
          <td className="p-8-narrow table-cell-ellipsis wid-15" title={t("latest_bid_value")}>
            {t("latest_bid_value")}&nbsp;
            <Tooltip title={bid_latest_value.title} body={bid_latest_value.body} />
          </td>
          <td className="p-8-narrow table-cell-ellipsis wid-15" title={t("difference_value")}>
            {t("difference_value")}&nbsp;
            <Tooltip title={lot_difference_value.title} body={lot_difference_value.body} />
          </td>
          <td className="saving-column p-8-narrow table-cell-ellipsis wid-15" title={t("difference_percentage")}>
            {t("difference_percentage")}&nbsp;
            <Tooltip
              title={lot_total_lot_difference_percentage.title}
              body={lot_total_lot_difference_percentage.body}
            />
          </td>
          <td className="p-8-narrow table-cell-ellipsis wid-20" title={t("award_notice")}>
            {t("award_notice")}&nbsp;
            <Tooltip title={lot_award_notice.title} body={lot_award_notice.body} />
          </td>
          {!isEmpty(perAngustaIntegrationUrls) && (
            <td className="wid-15" title={t("actions")}>
              {t("actions")}
            </td>
          )}
        </tr>
      </thead>
    );
  };

  toggleModal = (state) => {
    const { unSelectedLotIds } = this.state;
    this.setState({
      showModel: state,
      showAwardNotice: false,
      unSelectedLotIds: state ? [...new Set(unSelectedLotIds)] : [],
      eskerRequisition: [],
    });
  };

  render() {
    const { event, auctionTab, lotsLoaders, award_total, award_saving, translations } = this.props;
    const { company_cc_email, company_cc_email_on, award_total_percentage } = this.props;
    const { permitted_to_edit, show_award_button, lots, eskerAuthTokenStatus } = this.props;

    const {
      showModel,
      successMessage,
      errorMessage,
      notifiedError,
      showAwardNotice,
      ccEmail,
      awardNoticeMessage,
      notifiedParticipantsList,
      awardedParticipantMessages,
      purchaseOrderNumber,
      sendNoticeSpinner,
      clickedButton,
    } = this.state;

    const awardNotice = checkAllLotsNotified(lots);
    const isAlreadyAwarded = anyLotAwarded(lots);
    const { lot_po_number } = translations.infos;
    const canShowAwardLotsButton =
      show_award_button &&
      ((event.event_category === "Qualification Bids and Auction" && auctionTab) || event.event_category === "RFQ");

    return (
      <div>
        <ActionsForClosedOrCompletedEvents
          event={event}
          reportType={auctionTab ? "auction" : "RFQ"}
          canShowAwardLotsButton={canShowAwardLotsButton}
          isAlreadyAwarded={isAlreadyAwarded}
          toggleModal={this.toggleModal}
        />
        <Modal
          isOpen={showModel}
          className="modal-content col-md-10 col-sm-8 custom-field-popup"
          onRequestClose={() => {
            this.toggleModal(false);
          }}
          contentLabel="Review Modal"
        >
          {showModel ? (
            <>
              <div className="block-header bg-primary-default">
                <ul className="block-options">
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        this.toggleModal(false);
                      }}
                    >
                      <i className="ion-close" />
                    </button>
                  </li>
                </ul>
                <h3 className="block-title">{isAlreadyAwarded ? t("review_award_lots") : t("award_lots")}</h3>
              </div>
              <div className="modal-body">
                <div className="flash list-bullet notice alert alert-info font-s13 m-b5">
                  {t("show_welcome_msg_to_host")}
                </div>
                <div>
                  <div className="col-md-12 remove-padding">
                    <div className="form-group">
                      <div className="fieldset award-lot-scroll">
                        <table className="award-lot-popup custom-responsive-table m-t25 table table-layout-fixed v2">
                          {this.getTableHead()}
                          <tbody>{this.getRows(lots)}</tbody>
                          <tfoot>
                            <tr className="totals">
                              {this.props.can_create_esker_procurement_doc && <td>&nbsp;</td>}
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td className="text_center">
                                <b>{t("total")}</b>
                              </td>
                              <td id="totalBid">{award_total}</td>
                              <td>{award_saving}</td>
                              <td>{award_total_percentage}</td>
                            </tr>
                          </tfoot>
                        </table>
                        {successMessage && (
                          <div className="msg_award_lots">
                            <div className="alert alert-success msg">{successMessage}</div>
                          </div>
                        )}
                        {errorMessage && <div className="flash_notice alert alert-danger">{errorMessage}</div>}
                        {notifiedError && (
                          <div className="flash_notice alert alert-danger">{t("create_award_notice_error")}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className="modal-footer">
                  <div className="pull-left">
                    {awardNotice && permitted_to_edit && (
                      <button
                        type="button"
                        className="btn btn-primary new-award"
                        onClick={() => this.createNewAwardNotice()}
                      >
                        {t("new_award_notice")}
                      </button>
                    )}
                    {this.props.can_create_esker_procurement_doc && (
                      <button
                        disabled={this.state.eskerRequisition.length === 0 || eskerAuthTokenStatus === "pending"}
                        type="button"
                        className="btn btn-primary new-award"
                        onClick={() => this.createNewEskerProcurementDoc("purchase_requisition")}
                      >
                        {t("create_purchase_requisition")}
                        {clickedButton === "purchase_requisition" && <i className="fa fa-spinner fa-spin m-l5"></i>}
                      </button>
                    )}
                    {this.props.can_create_esker_procurement_doc && (
                      <button
                        disabled={this.state.eskerRequisition.length === 0 || eskerAuthTokenStatus === "pending"}
                        type="button"
                        className="btn btn-primary new-award"
                        onClick={() => this.createNewEskerProcurementDoc("vendor_contract")}
                      >
                        {t("create_contract")}
                        {clickedButton === "vendor_contract" && <i className="fa fa-spinner fa-spin m-l5"></i>}
                      </button>
                    )}
                    {this.props.can_create_esker_procurement_doc && this.props.esker_procurement_doc_id && (
                      <button
                        disabled={this.isEskerUpdateButtonDisabled()}
                        type="button"
                        className="btn btn-primary new-award"
                        onClick={() => this.updateEskerRequisition()}
                      >
                        {this.props.esker_procurement_doc_type === "vendor_contract"
                          ? t("update_contract")
                          : t("update_purchase_requisition")}
                        {clickedButton === "update_requisition" && <i className="fa fa-spinner fa-spin m-l5"></i>}
                      </button>
                    )}
                  </div>
                  <div className="button-box">
                    <button type="button" className="btn btn-sm btn-default" onClick={() => this.toggleModal(false)}>
                      {t("cancel")}
                    </button>
                    {permitted_to_edit && (
                      <button
                        label="save-awards"
                        type="button"
                        className="btn btn-sm btn-primary"
                        disabled={lotsLoaders.saveAwardLotsRequested}
                        onClick={() => this.awardLotsToParticipants(lots)}
                      >
                        {t("save")}
                        {lotsLoaders.saveAwardLotsRequested ? (
                          <i className="fa fa-spinner fa-spin m-l5" />
                        ) : (
                          <i className="fa fa-check m-l5"></i>
                        )}
                      </button>
                    )}
                  </div>
                </div>
                {showAwardNotice && awardNotice ? (
                  <div className="notify-section">
                    <hr className="notify_hr" />

                    <div className="block-content">
                      <div className="notify_fieldset ">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group form-inline">
                              <label>
                                {t("purchase_order_no_label")}
                                <Tooltip title={lot_po_number.title} body={lot_po_number.body} />
                              </label>
                              <input
                                type="text"
                                maxLength="30"
                                size="35"
                                onChange={(e) => {
                                  this.updatePurchaseOrderNo(e);
                                }}
                                className="form-control"
                              />
                            </div>
                            <br />
                            {company_cc_email_on ? (
                              <div className="form-group form-inline">
                                <label htmlFor="company_cc_email">cc:</label>
                                <input
                                  type="text"
                                  id="company_cc_email"
                                  name="company_cc_email"
                                  size={75}
                                  className="form-control push-10-l"
                                  placeholder={t("entitled_cc_emails")}
                                  value={ccEmail}
                                  disabled={company_cc_email}
                                  onChange={(e) => {
                                    this.updateccEmail(e);
                                  }}
                                />
                              </div>
                            ) : null}
                            <div className="row">
                              <div className="col-md-12">
                                <label htmlFor="company_web_address">{t("notify_message_label")}</label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>{t("email_edit")}</label>
                              <div className="clearfix" />
                              <div className="col-md-6 m-t10 p-l0 notify-message">
                                <TrixEditor value={awardNoticeMessage} onChange={(e) => this.updateAwardNoticeMsg(e)} />
                              </div>
                              <div className="col-md-6 m-t10 p-r0 notify-message">
                                <select
                                  ref={`ParticipantValue1`}
                                  className="form-control prticipants"
                                  onChange={(e) => {
                                    this.setNotificationMessage(e.target.value) || null;
                                  }}
                                >
                                  <option key={-1} value="" selected={this.state.participantId === null}>
                                    {t("none")}
                                  </option>
                                  {this.state.selectedParticipantsList.map((participant) => {
                                    return (
                                      <option key={participant.id} value={participant.id}>
                                        {participant.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <br />

                                <div className="text-information">
                                  <div className="form-item-block award_notify">
                                    <div className="generated_notify">
                                      <div>
                                        <div id="generated_text">
                                          <b>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: awardNoticeMessage,
                                              }}
                                            />
                                          </b>
                                        </div>
                                        <br />
                                        <div id="generated_lot_details">
                                          <b>
                                            <ul>
                                              {awardedParticipantMessages.map((lot, index) => (
                                                <li key={lot.lotId}>
                                                  {index + 1}. {lot.name}
                                                </li>
                                              ))}
                                            </ul>
                                          </b>
                                        </div>
                                        <br />
                                        {purchaseOrderNumber && (
                                          <div>
                                            <b>
                                              <span>
                                                {t("po_number_detail")}:{purchaseOrderNumber}
                                              </span>
                                            </b>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix" />
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary modal-btn-close"
                              onClick={() => this.toggleModal(false)}
                            >
                              {t("cancel")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={!notifiedParticipantsList.length}
                              onClick={() => this.sendAwardNotice()}
                            >
                              {sendNoticeSpinner && <i className="fa fa-spinner fa-spin" />}
                              &nbsp;{t("send_award_notice")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : null}
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  awardLots: (evId, lotId, partId) => dispatch(updateAwardLots(evId, lotId, partId)),
  deleteNotification: (lotId, requestId, successMessage) =>
    dispatch(deleteNotification(lotId, requestId, successMessage)),
  sendNotification: (payload, requestId) => dispatch(sendNotification(payload, requestId)),
  saveAwardLots: (payload, eventID, successMessage) => dispatch(saveAwardLots(payload, eventID, successMessage)),
  getEskerAuthToken: (cb) => dispatch(getEskerAuthToken(cb)),
});

const mapStateToProps = ({ lotReducers }) => {
  return {
    lots: lotReducers.lots,
    award_total: lotReducers.award_total,
    award_saving: lotReducers.award_saving,
    award_total_percentage: lotReducers.award_total_percentage,
    per_angusta_integration_urls: lotReducers.per_angusta_integration_urls,
    esker_integration_url: lotReducers.esker_integration_url,
    has_annual_license: lotReducers.has_annual_license,
    can_create_esker_procurement_doc:
      Boolean(lotReducers.esker_integration_url) && lotReducers.permitted_to_edit && lotReducers.has_annual_license,
    eskerAuthTokenStatus: lotReducers.eskerAuthTokenStatus,
  };
};

AwardLots.defaultProps = {
  lots: [],
};

AwardLots.propTypes = {
  hosts: PropTypes.arrayOf(HostPropType),
  show_overall_score: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  getEskerAuthToken: PropTypes.func,
  eskerAuthTokenStatus: PropTypes.oneOf(["success", "pending"]),
};

const checkNotifiedParticipant = (checkNotifiedRecord, checkNotifiedParticipantRecord, participant) => {
  if (checkNotifiedRecord.map((lot) => lot.participant.id).includes(participant.id)) {
    checkNotifiedParticipantRecord.push(participant);
  }
};

/**
 * @type {React.FC<typeof ActionsForClosedOrCompletedEventsPropTypes>}
 */
export const ActionsForClosedOrCompletedEvents = (props) => {
  const { event, reportType, canShowAwardLotsButton, isAlreadyAwarded, toggleModal } = props;
  const closedOrCompletedEvent = ["closed", "completed"].includes(event.current_state);
  if (!closedOrCompletedEvent) return null;

  return (
    <div className="pull-right">
      {canShowAwardLotsButton ? (
        <div>
          <DownloadReportButton eventId={event.id} reportType={reportType} buttonTitle={t("download_report")} />
          <button className="btn auto_width_btn status-event-left btn-blue-outline" onClick={() => toggleModal(true)}>
            {isAlreadyAwarded ? t("review_award_lots") : t("award_lots")}
          </button>
        </div>
      ) : (
        <DownloadReportButton eventId={event.id} reportType={reportType} buttonTitle={t("download_now")} />
      )}
    </div>
  );
};
ActionsForClosedOrCompletedEvents.propTypes = ActionsForClosedOrCompletedEventsPropTypes;

/**
 * @type {React.FC<typeof DownloadReportButtonPropTypes>}
 */
const DownloadReportButton = ({ eventId, reportType, buttonTitle }) => {
  return (
    <a
      className="btn auto_width_btn status-event-left btn-blue-outline"
      href={`/events/${eventId}.xlsx?report_type=${reportType}`}
    >
      {buttonTitle}
    </a>
  );
};
DownloadReportButton.propTypes = DownloadReportButtonPropTypes;

export default connect(mapStateToProps, mapDispatchToProps)(AwardLots);
