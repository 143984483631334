import React, { useState } from "react";
import { Confirm } from "../../common/Confirm";
import { findLotBidRange, hasValue, leadingBidPrice, multiCurrencyEvent, showCurrency, toggleButton } from "../common";
import CurrencyFieldWithSymbol from "../lots/line_item_component/CurrencyFieldWithSymbol";
import { connect, useDispatch } from "react-redux";
import { placeBid, bidRange, setLotStatusViewing } from "../../../actions/bidActions";
import { latestBidDuringAuctionForLot, overallRankIfWeighted } from "../bidCommon";
import LotBidComponent from "../bids/LotBidComponent";
import Select2 from "react-select2-wrapper";
import classNames from "classnames";
import { auctionPending, pause, pending } from "../eventCommon";
import { isEmpty } from "lodash";
import ParticipantProgressionChartModal from "../lots/participantScreenComponents/ParticipantProgressionGraph";

const LotLevelMonitor = (props) => {
  const {
    translations,
    lot,
    event,
    currency = {},
    openModal,
    participant,
    lotsBidRange,
    unitOfMeasures,
    unitSetUoms,
    lotNumber,
    weightedRfqOrAuction,
    LBids,
    locale,
    lotErrors,
    auctionTab,
    beforeAuction,
    role,
    currencies_hash: currenciesHash = [],
    lineItemComponents,
    isEventCompleted,
    bestBids,
  } = props;

  const [lotLvlState, setLotLvlState] = useState({
    errors: [],
    lotPrice: "",
    lotMessage: "",
    totalPrice: "",
    activeEnterBid: false,
    detailedView: false,
    lotDetailsView: false,
    exchangeRateId: null,
    selectedExchangeRateId: Number(currency.id),
    selectedExchangeRateObj: currency,
  });
  const setState = (state) => {
    setLotLvlState({ ...lotLvlState, ...state });
  };

  const { selectedExchangeRateObj, selectedExchangeRateId } = lotLvlState;

  const selectedCurrency = selectedExchangeRateObj ? selectedExchangeRateObj : currency;

  let selectOptions = [],
    multiCurrencyField = null;
  const isMultiCurrencyEvent = multiCurrencyEvent(event);
  if (isMultiCurrencyEvent) {
    selectOptions = Object.values(currenciesHash).map((u) => {
      return { text: u.name, id: u.id };
    });

    const getExchangeObj = (id) => {
      return Object.entries(currenciesHash).find(([blic, currency]) => currency.id === id);
    };

    const handleChange = ({ target }) => {
      const [selectedExchangeRateId, selectedExchangeRateObj] = getExchangeObj(Number(target.value));

      setState({
        selectedExchangeRateId: Number(selectedExchangeRateId),
        selectedExchangeRateObj,
      });
    };

    multiCurrencyField = (
      <Select2
        className="form-control form-width"
        value={selectedExchangeRateObj && selectedExchangeRateObj.id ? selectedExchangeRateObj.id : null}
        data={selectOptions}
        options={{ placeholder: "Select" }}
        onClose={(e) => {
          e.preventDefault();
          if (e.target && e.target.value) {
            handleChange({ target: e.target });
          }
        }}
      />
    );
  }

  const latestBids = latestBidDuringAuctionForLot(LBids, [participant], event, weightedRfqOrAuction).sort(function (
    bid2,
    bid1
  ) {
    return new Date(bid1.created_at) - new Date(bid2.created_at);
  });

  const latestBid = latestBids && latestBids[0] ? latestBids[0] : {};
  const htmlId = `lotLevelMonitorConfirmModal-${lot.id}`;
  const isSubmitted = latestBid && latestBid.current_state === "submitted";
  const isCompleted = event && event.current_state === "completed";

  const isAuctionPending = auctionTab && auctionPending(event);

  const { number_format: numberFormat } = translations;

  const dispatch = useDispatch();

  const getBidRange = () => {
    let params = {
      event_id: event.id,
      user_id: participant.user_id,
    };
    if (latestBid && latestBid.exchange_rate_id)
      params.exchange_rate_id = selectedExchangeRateId ? selectedExchangeRateId : latestBid.exchange_rate_id;
    dispatch(bidRange(lot.id, latestBid ? latestBid.id : "", params));
  };

  React.useEffect(() => {
    getBidRange();
  }, [selectedExchangeRateId]);

  const { activeEnterBid, lotPrice, totalPrice, detailedView, lotDetailsView, errors } = lotLvlState;
  const allUom = [...unitSetUoms, ...unitOfMeasures];
  const uom = allUom.find((u) => u.id === lot.unit_of_measure_id);
  let [minBidRange = "-", maxBidRange = "-"] = findLotBidRange(lotsBidRange, lot.id);
  if (!hasValue(minBidRange)) minBidRange = "-";
  if (!hasValue(maxBidRange)) maxBidRange = "-";

  const onEnterBid = () => {
    setState({
      activeEnterBid: true,
    });
  };

  const onCancel = () => {
    setState({
      activeEnterBid: false,
    });
  };

  const onSubmit = () => {
    dispatch(
      placeBid(lot.id, {
        bid: { event_id: event.id, lot_id: lot.id, user_id: participant.user_id, price: lotPrice },
        before_auction: "false",
      })
    )
      .then((res) => {
        const { data } = res;

        if (data && data.message) {
          setState({ lotMessage: data.message, activeEnterBid: false });
        } else if (data && data.errors) {
          setState({ lotMessage: data.errors, activeEnterBid: true });
        } else {
          setState({ lotMessage: "", activeEnterBid: false });
        }
        getBidRange();
      })
      .catch((data) => {
        data && data.message && setState({ lotMessage: data.message });
      })
      .finally(() => dispatch(setLotStatusViewing(lot.id)));
  };

  const handleFieldChange = (e) => {
    const lotPrice = e();
    setState({ lotPrice, totalPrice: lotPrice });
  };

  const showButtons = () => {
    switch (true) {
      case pending(event):
        return translations.bidding_has_not_started;
      case isCompleted:
        return translations.bidding_closed;
      case pause(event):
        return translations.event_is_paused;
      case activeEnterBid:
        return (
          <>
            <button className="btn btn-default m-b0" onClick={onCancel}>
              {translations.cancel}
            </button>
            <button
              name="button"
              type="submit"
              className="btn btn-primary push-5-l"
              onClick={() => openModal(`#${htmlId}`)}
            >
              {translations.submit}
              <i className="fa fa-check m-l5"></i>
            </button>
          </>
        );

      default:
        return (
          <a className="btn btn-default m-b0 p-t10" onClick={onEnterBid}>
            <i className="fa fa-check green-color m-r5"></i>
            {translations.enter_bid}
          </a>
        );
    }
  };

  const rank = overallRankIfWeighted(latestBid, weightedRfqOrAuction);

  const totalLotValue = latestBid && hasValue(latestBid.price) ? latestBid.price : "";
  const lotLic = lineItemComponents.find(({ id }) => id === lot.lot_total_lic_id);

  const precision = lotLic?.decimal_place || 2;

  const [isOpen, setIsOpen] = useState(false);
  const onGraphClick = (e) => {
    setIsOpen(true);
  };

  const onClick = () => {
    setState({ detailedView: !detailedView });
  };

  // show popup with Lot Details
  const onClickLotDetails = () => {
    setState({ lotDetailsView: !lotDetailsView });
  };

  const showLeadingPriceOrRank = () => {
    let result = null;
    if (!isEmpty(latestBid) && !isAuctionPending && !activeEnterBid) {
      if (event.event_type == "Open") {
        result =
          bestBids && bestBids[lot.id]
            ? leadingBidPrice(lotLic, bestBids[lot.id][0].price, selectedCurrency, locale, numberFormat)
            : "-";
      } else {
        result = rank ? <span className={`lot-rank-symbol badge font-s14 ranked${rank}`}>{rank}</span> : "-";
      }
    }
    return result;
  };
  return (
    <>
      <div className={`table-top ranked-auction ${parseInt(lotNumber) % 2 === 0 ? "bg-githubgray" : "" }`}>
        <div
          className={classNames({
            "table-block-head": true,
            "multi-currency": activeEnterBid && isMultiCurrencyEvent ? true : false,
          })}
        >
          <div className="center-content-vertical head-all head-lot p-l20 tbody-all transform-none wid-7">
            <span className="font-s16">{lotNumber}</span>
          </div>

          <div className="center-content-vertical tbody-all head-all transform-none wid-17">
            {!event.bid_at_detail_level && lot.complex_structure && isEventCompleted && toggleButton(onClick)}

            {!event.bid_at_detail_level && lot.complex_structure && !isEventCompleted && 
              <>
                <a title="Expand Lot" class="btn btn-default btn-xs small-btn small-blue-btn m-b0" onClick={onClickLotDetails}><i class={`fa fa-eye${lotDetailsView ? '-slash':''}`}></i></a>
              </>
            }

            <span className="font-s16 m-l5 table-cell-ellipsis" title={lot.name}>{lot.name}</span>
          </div>

          <div className="center-content-vertical font-s16 tbody-all head-all transform-none head-bidrange wid-14">
            {isAuctionPending
              ? "-"
              : !lot.is_event_total
              ? `${
                  activeEnterBid && hasValue(minBidRange) && !isCompleted
                    ? showCurrency(minBidRange, selectedCurrency, precision, locale, numberFormat)
                    : ""
                } ${"-"} ${
                  activeEnterBid && hasValue(maxBidRange) && !isCompleted
                    ? showCurrency(maxBidRange, selectedCurrency, precision, locale, numberFormat)
                    : ""
                }`
              : null}
          </div>

          <div className="center-content-vertical font-s16 tbody-all head-all transform-none head-price wid-16">
            {activeEnterBid ? (
              <>
                {!lot.is_event_total ? multiCurrencyField : null}
                <div className="input-group m-b0">
                  <CurrencyFieldWithSymbol
                    id="bid-price-field"
                    name="isPrice"
                    symbol={selectedCurrency.symbol}
                    value={lotPrice}
                    precision={precision}
                    classes="form-control bid-price-field number_precision blic_field persisted-true no-spin"
                    translations={translations}
                    handleFieldChange={handleFieldChange}
                  />
                </div>
              </>
            ) : isSubmitted && hasValue(totalLotValue) && !isAuctionPending ? (
              <>
                {lot.is_event_total ? multiCurrencyField : null}
                {showCurrency(totalLotValue, selectedCurrency, precision, locale, numberFormat)}
              </>
            ) : (
              translations.no_bids
            )}
          </div>

          <div className="center-content-vertical font-s16 tbody-all head-all transform-none wid-16">
            {(activeEnterBid || isSubmitted) && !isAuctionPending
              ? showCurrency(
                  activeEnterBid ? totalPrice : totalLotValue,
                  selectedCurrency,
                  precision,
                  locale,
                  numberFormat
                )
              : "-"}
          </div>

          <div className="center-content-vertical tbody-all head-all transform-none head-rank wid-10">{showLeadingPriceOrRank()}</div>

          <div className="center-content-vertical tbody-all head-all transform-none head-newbid">{!lot.is_event_total && showButtons()}</div>
          
          <div className="center-content-horizontal-vertical tbody-all tbody-iconbar">
            { isSubmitted && 
              <div className="cursor-pointer solid-box simple-lot">
                  <a onClick={onGraphClick}>
                  <i className="fa fa-bar-chart"></i>
                  </a>   
              </div>
            }
            <ParticipantProgressionChartModal {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>
        </div>

        {lotErrors && lotErrors[lot.id] && lotErrors[lot.id][0] && (
          <div className="notification error alert-dismissable" style={{ maxWidth: "none" }}>
            <div className="col-md-12">
              <i className="fa fa-times"></i>
              <p>{lotErrors[lot.id][0]}</p>
              <div className="clear"></div>
            </div>
          </div>
        )}

        {detailedView && (
          <LotBidComponent
            {...props}
            lotsBidRange={lotsBidRange}
            isLotLevelMonitor={true}
            bidDetailsAdded={latestBid.details_added}
            rankValue={rank}
            auctionTab={auctionTab}
            beforeAuction={beforeAuction}
            role={role}
            participant={participant}
            weightedRfqOrAuction={weightedRfqOrAuction}
            currenciesHash={currenciesHash}
            picklistOptions={props.picklist_options}
          />
        )}

        {lotDetailsView && (
            <div className="wid-100">
              <LotBidComponent
                {...props}
                lotsBidRange={lotsBidRange}
                isLotLevelMonitor={true}
                bidDetailsAdded={latestBid.details_added}
                rankValue={rank}
                auctionTab={auctionTab}
                beforeAuction={beforeAuction}
                role={role}
                participant={participant}
                weightedRfqOrAuction={weightedRfqOrAuction}
                currenciesHash={currenciesHash}
                picklistOptions={props.picklist_options}
                showHeader={false}
                showDetailsButton={false}
                roundedBorders={false}
              />
          </div>
        )}

      </div>
      <Confirm
        translations={translations}
        message={
          <>
            <p className="m-b10">{translations.value_entered}</p>
            <p className="m-b0">
              <span className="font-s26 yes">
                {showCurrency(lotPrice, selectedCurrency, precision, locale, numberFormat)}
              </span>
            </p>
          </>
        }
        htmlId={htmlId}
        onOkayButtonClick={onSubmit}
        modalClass={"modal-md"}
        okButtonText={translations.submit}
        cancelButtonText={translations.cancel}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currency, exchange_rate } = state.lotReducers;
  let stateObj = {};
  if (hasValue(currency)) stateObj.currency = currency;
  if (hasValue(exchange_rate)) stateObj.exchange_rate = exchange_rate;
  return stateObj;
};

export default connect(mapStateToProps, null)(LotLevelMonitor);