import React from "react";
import { showCurrency } from "../../../../common";

const show_currency = (price, props) =>
  showCurrency(price, props.currency, props.lic?.decimal_place, props.locale, props.translations?.number_format);

const LotDetailsForPriceComponent = (props) => {
  const { lic, modalType, currentValue, qualificationValue } = props;

  const condition = modalType === "LineItem";
  const current_price = lic?.current_price ?? "-";
  const qualification_price = lic?.qualification_price ?? "-";

  const currentPrice = condition ? show_currency(current_price, props) : currentValue;

  const qualPrice = condition ? show_currency(qualification_price, props) : qualificationValue;

  return props.isPriceComponent ? (
    <>
      {condition && props.headerValue("quantity", lic ? lic.quantity : null)}
      {props.headerValue(condition ? "current_price_in_popup" : "current_value_html", currentPrice, "active")}
      {props.headerValue(
        condition ? "qualification_price_in_popup" : "qualification_value_html",
        qualPrice,
        "lot-info active"
      )}
    </>
  ) : null;
};

export default LotDetailsForPriceComponent;
