import React, { useState } from "react";
import ShowBidsHeaders from "./ShowBidsHeaders";
import ShowBeforeAuction from "./ShowBeforeAuction";
import { isWeightedRfqOrAuction, preAuctionRanksIntact } from "../../../../../eventCommon";

const TabDetails = (props) => {
  const { event, translations, beforeAuction, canShowDeleteBtn, role } = props;
  const weightedRfqOrAuction = isWeightedRfqOrAuction(event);
  const preAuctionRanks = preAuctionRanksIntact(event);

  const [tableState, setState] = useState({
    message: "",
    messageClass: "alert-success",
  });
  const setTableState = (state) => setState({ ...tableState, ...state });

  const { message, messageClass } = tableState;

  return (
    <>
      <div id="bid-upload-flash" className="flash hidden"></div>
      <div className="p-t10">
        <div className="row">
          {message && (
            <div className={`alert m-t10 ${messageClass}`}>
              <a className="close" onClick={() => setTableState({ message: "" })}>
                ×
              </a>
              <div className="flash notice">{message}</div>
            </div>
          )}
          <div className="col-md-12">
            <div className="block">
              <table className="table table-bordered table-layout-fixed show-bid-table auction-bid-tab">
                <ShowBidsHeaders
                  event={event}
                  weightedRfqOrAuction={weightedRfqOrAuction}
                  translations={translations}
                  beforeAuction={beforeAuction}
                  preAuctionRanksIntact={preAuctionRanks}
                />
                <ShowBeforeAuction
                  {...props}
                  beforeAuction={beforeAuction}
                  weightedRfqOrAuction={weightedRfqOrAuction}
                  preAuctionRanksIntact={preAuctionRanks}
                  setTableState={setTableState}
                  canShowDeleteBtn={canShowDeleteBtn}
                  role={role}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabDetails;
