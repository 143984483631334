import React from "react";
import { ModalCloseIcon } from "../../../monitor/UpdateLicCurrentPrice"

const ModalHeader = (props) => {
  const { title, closeModal } = props;

  return (
    <div className="block-header bg-primary-default">
      <ModalCloseIcon closeModal={closeModal} />
      <h3 className="block-title">{title}</h3>
    </div>
  );
};

export default ModalHeader;
