import React from "react";
import { useSelector } from "react-redux";
import { last } from "lodash";
import { showRankOfCell } from "../../bidCommon";
import { isRfq } from "../../eventCommon";

const SummaryPanel = (props) => {
  const { renderData, lic, event } = props;
  const { eventRound, event_rounds } = useSelector(({ lotReducers }) => lotReducers);
  const description = lic?.description ?? "-";
  const _yourPricePerUOM =
    (props.isBidSubmitted || !props.isBidForCurrentMSRFQRoundSubmitted) && !props.isHost ? props.yourPricePerUom : "-";

  const isShowingRank = isRfq(event) ? !eventRound && last(event_rounds)?.show_rank : true;

  return (
    <ul className="blic-info listing-link">
      {renderData.map((fld, i) => (
        <li key={i} title={fld}>
          {fld}
        </li>
      ))}
      <li key={renderData.length}>
        <span className="real-time real-time-other" title={_yourPricePerUOM}>
          {_yourPricePerUOM}
          &nbsp;
          {isShowingRank &&
            showRankOfCell(
              props.event,
              props.blic,
              props.bid,
              lic,
              props.lc,
              props.lot,
              props.role,
              lic.is_formula_lic,
              props.auctionTab
            )}
        </span>
      </li>
      <li key={renderData.length + 1}>
        <div className="attribute-value dummy-bid-field" title={props.answer}>
          {props.answer}
        </div>
      </li>
      <li key={renderData.length + 2} title={description}>
        {description}
      </li>
    </ul>
  );
};

export default SummaryPanel;
