import React, { useMemo } from "react";
import Tooltip from "@/components/common/Tooltip";
import useUpToDateValue from "@/hooks/use-up-to-date-value";
import { SimSettingCheckboxPropTypes } from "./prop-types";
import { t } from "@/i18n";

/**
 * To convert the values into Boolean becasue values for some settings are in string
 *
 * @param {boolean|string} value
 * @returns {boolean}
 */

export const toBool = (value) => {
  if (value === "false") return false;
  if (value === "true") return true;
  return Boolean(value);
};

const useHandleUpdate = ({ value, name, onChange }) => {
  const [checkboxValue, setCheckboxValue] = useUpToDateValue(toBool(value));
  const isNewlyAddedSettings = useMemo(
    () =>
      [
        "invited_date",
        "onboarded_date",
        "existing_answer_to_remain_on_the_questionnaire",
        "last_submitted_date",
      ].includes(name),
    [name]
  );

  // We are checking with a variable "isNewlyAddedSettings" because previously we were saving
  // the checkbox value as a string which was not the right way and we can't correct the old
  // data in db at the moment. So, we are adding one condition for the new settings which we
  // are adding and based on that we will change the value in string or boolean.

  const handleChange = (event) => {
    const dataValue = isNewlyAddedSettings ? Boolean(event.target.checked) : event.target.checked.toString();
    onChange && onChange(dataValue, name);
    setCheckboxValue(Boolean(dataValue));
  };
  return { checkboxValue, handleChange };
};

/**
 *
 * @param {string} name
 * @returns {object} An object containing the tooltip title and body.
 * The object has the following structure:
 *   - `title` {string}: The translated title of the tooltip.
 *   - `body` {string}: The translated body content of the tooltip.
 */
const getTooltipContent = (name) => {
  return {
    title: t(`tooltips:contextual_help.host.configuration.${name}.title`),
    body: t(`tooltips:contextual_help.host.configuration.${name}.body`),
  };
};

/**
 * @type {React.FC<typeof SimSettingCheckboxPropTypes>}
 */

const SimSettingCheckbox = (props) => {
  const { value, onChange, disabled, name } = props;
  const { checkboxValue, handleChange } = useHandleUpdate({ value, name, onChange });
  const tooltipContent = getTooltipContent(name);

  return (
    <div className="input inline m-b10">
      <label htmlFor={name} className="config-label col-md-5 col-sm-5 col-xs-12 p-0">
        {t(`sim_dojo:${name}`)}
      </label>
      <input
        type="checkbox"
        name={name}
        id={name}
        className="material-checkbox"
        checked={checkboxValue}
        onChange={handleChange}
        disabled={disabled}
      />
      <div className="inline-block p-l15">
        <Tooltip title={tooltipContent.title} body={tooltipContent.body} />
      </div>
    </div>
  );
};

SimSettingCheckbox.propTypes = SimSettingCheckboxPropTypes;

export default SimSettingCheckbox;
