import React from "react";
import PropTypes from "prop-types";

import { useTextAttribute } from "@adeattwood/react-form";
import MDInput from "./input";

const InputPropTypes = {
  /**
   * The react from attribute that this input will control
   */
  attribute: PropTypes.string.isRequired,
  /**
   * The type of input this will render.
   *
   * @default {'text'}
   */
  type: PropTypes.string,
};

export function withReactFormAttribute(BaseInput, hook) {
  const Input = ({ attribute, ...inputProps }) => {
    const { props, error } = hook(attribute);
    return <BaseInput {...inputProps} {...props} error={error} />;
  };

  Input.displayName = "ReactFormBaseComponent";
  Input.propTypes = InputPropTypes;

  return Input;
}

/**
 * Component creator to wrap a component in with the `useTextAttribute` hook
 * from react form. and pass on the props and errors to the child component
 */
export function withReactFormTextAttribute(BaseInput) {
  return withReactFormAttribute(BaseInput, useTextAttribute);
}

/**
 * @type {React.FC<PropTypes.InferProps<typeof InputPropTypes>>}
 */
const Input = withReactFormTextAttribute(MDInput);
Input.displayName = "ReactFormInput";

export default Input;
