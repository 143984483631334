import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { last } from "lodash";

import { classesFromEventState } from "../../../common";
import AddEventRoundFromModal from "./addEventRoundFromModal";
import { canAddMoreRounds } from "./common";
import { setActiveMsrfqRound } from "../../../../../actions/lotActions";
import actionsTypes from "../../../../../actions/actionsTypes";
import { isRfq } from "@/components/advanced_lots/eventCommon";

export default function EventRoundTabs({ isHost, ...rest }) {
  const event = useSelector(({ lotReducers }) => lotReducers.event);

  // return if event type is not RFQ and event rounds are not enabled
  if (!isRfq(event)) return <></>;

  return (
    <>
      {/* show best overall button */}
      <BestOverallButton isHost={isHost} />

      {/* Dropdown to change event rounds */}
      <EventRoundDropdown isHost={isHost} />

      {/* show add event round buttons */}
      <AddEventRoundButton isHost={isHost} {...rest} />
    </>
  );
}

EventRoundTabs.defaultProps = {
  isHost: false,
};

EventRoundTabs.propTypes = {
  isHost: PropTypes.bool,
};

const BestOverallButton = ({ isHost }) => {
  const dispatch = useDispatch();
  const { translations, event, eventRound, event_rounds } = useSelector(({ lotReducers }) => lotReducers);
  const { overall } = translations;

  return event_rounds?.length > 1 && isHost ? (
    <div className="event-round-tab">
      <a
        onClick={() => eventRound && dispatch({ type: actionsTypes.SET_BEST_OVERALL_ROUND })}
        className={`btn status-event sm-butt ${classesFromEventState(event)} ${!eventRound ? "tab-active" : ""}`}
      >
        {overall}
      </a>
    </div>
  ) : (
    <></>
  );
};

const EventRoundDropdown = ({ isHost }) => {
  const dispatch = useDispatch();
  const { translations, eventRound, event_rounds } = useSelector(({ lotReducers }) => lotReducers);
  const { view_round, round } = translations;

  useEffect(() => {
    const key = `event_round_${isHost ? "host" : "participant"}`;
    const roundId = localStorage.getItem(key);
    const er = event_rounds?.find(({ id }) => id === Number(roundId));
    if (!!er) dispatch(setActiveMsrfqRound(er));
  }, []);

  // do not show anything if event_rounds's length is less than 1
  if (!(event_rounds?.length > 1)) return <></>;

  const roundText = (er) => round + " " + er.event_round;

  const textForParticipant = !isHost ? roundText(last(event_rounds)) : view_round;
  // for participant show last event round by default instead on view rounds
  const eventRoundDropdownText = !eventRound ? textForParticipant : roundText(eventRound);

  // if on participant side clicks on the latest round then show all bids so that it could show lot rejected or bids
  //  from previous rounds to update and prefill lic inputs with previous bid's blics.
  const handleDropDownClick = (er) =>
    er?.id === last(event_rounds)?.id && !isHost
      ? dispatch({ type: actionsTypes.SET_BEST_OVERALL_ROUND })
      : dispatch(setActiveMsrfqRound(er));

  return (
    <div className="btn-right-bottom event-round-tab">
      <div className="btn-group btn-action">
        <button
          type="button"
          className="dropdown-toggle status-event sm-butt current form-control"
          data-toggle="dropdown"
        >
          {eventRoundDropdownText}
          <span className="caret"></span>
        </button>
        <ul className="dropdown-menu" role="menu">
          {event_rounds?.map((eventRound) => (
            <li key={eventRound.id} onClick={() => handleDropDownClick(eventRound)}>
              <a>{roundText(eventRound)}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const AddEventRoundButton = ({ isHost, ...rest }) => {
  const { event, translations, permitted_to_edit } = useSelector(({ lotReducers }) => lotReducers);
  const { add_another_round } = translations;

  return canAddMoreRounds(isHost, permitted_to_edit, event) ? (
    <div className="event-round-tab">
      <AddEventRoundFromModal
        {...rest}
        viewComponent={
          <>
            <i className="fa fa-plus"></i> {add_another_round}
          </>
        }
        viewComponentClass="btn status-event sm-butt auto_width_btn add_another_rfq_round"
      />
    </div>
  ) : (
    <></>
  );
};
