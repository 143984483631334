import React, { Suspense, lazy, useState } from "react";
import { useDispatch } from "react-redux";
import reject from "lodash/reject";

import CellDetail from "./CellDetail";
import LotComponentDetail from "./lot_component/LotComponentDetail";
import { Confirm } from "../../common/Confirm";
import { deleteLineItem } from "../../../actions/lineItemActions";
import { deleteLotComponent } from "../../../actions/lotComponentActions";
const LotDetails = lazy(() => import("./LotDetails"));
import classNames from "classnames";

const CellsContainer = (props) => {
  const dispatch = useDispatch();
  const {
    lot,
    lots,
    lineItemComponents,
    lotLis,
    lotLcs,
    lotLics,
    translations,
    permittedToEdit,
    classFromEventState,
    anyCompletedBids,
    event,
    maxPrecisionFor,
    currenciesHash,
    eventCurrency,
    openOrJapanese,
    isJapaneseEvent,
    locale,
    reRenderDetails,
    currentUser,
    timeZoneIdentifier,
    isMultiCurrencyEvent,
    hideFromParticipantsSwitch,
  } = props;

  const [showDetails, setShowDetails] = useState([]);
  const [hideOrShowDetailTitle, setHideOrShowDetailTitle] = useState(translations.details);
  const [lineItemToBeDeleted, setLineItemToBeDeleted] = useState();
  const [lotComponentToBeDeleted, setLotComponentToBeDeleted] = useState();

  const handleShowDetailsStatus = (event) => {
    let dataLiId = event.target.getAttribute("data-line-item-id");
    const liIds = lotLis.map((lItem) => lItem.id);
    if (showDetails.length && dataLiId === "All") {
      setHideOrShowDetailTitle(translations.details);
      setShowDetails([]);
    } else if (dataLiId === "All") {
      setHideOrShowDetailTitle(translations.close_all_line_items);
      setShowDetails(liIds);
    } else {
      dataLiId = parseInt(dataLiId);
      let showDets = [...showDetails];
      if (showDets.includes(dataLiId)) {
        showDets = reject(showDets, (id) => id === dataLiId);
      } else {
        showDets.push(dataLiId);
      }
      const detailTitle = showDets.length ? translations.close_all_line_items : translations.details;
      setHideOrShowDetailTitle(detailTitle);
      setShowDetails(showDets);
    }
  };

  return (
    <div className="redact-lot-table">
      {lotLis.length == 0 && lotLcs.length == 0 ? (
        <div className="col-md-12 m-b10">
          <div className="bg-info-light message-block alert-info">{translations.lot_line_item_empty}</div>
        </div>
      ) : (
        <div className="col-md-12 col-sm-12 col-xs-12 m-b10">
          <div id={`scroll_lot_container_${lot.id}`} className="" style={{ overflow: "auto", maxHeight: "429px" }}>
            <table
              className={classNames({
                "complex-lots-table": true,
                "no-li": lotLis.length == 0,
                "no-lc": lotLcs.length == 0,
                "view-permission": !permittedToEdit,
              })}
              id={`lot_table_${lot.id}`}
              width="100%"
            >
              <thead>
                <tr className="thead thead lot-primary-row">
                  <th className="cell-primary col-md-2 col-sm-2 col-xs-2 text-uppercase">
                    <CellDetail
                      lot={lot}
                      translations={translations}
                      lotLcs={lotLcs}
                      lotLis={lotLis}
                      classFromEventState={classFromEventState}
                      handleShowDetailsStatus={handleShowDetailsStatus}
                      hideOrShowDetailTitle={hideOrShowDetailTitle}
                    />
                  </th>
                  {lotLcs.map((lc) => (
                    <LotComponentDetail
                      lot={lot}
                      key={lc.id}
                      translations={translations}
                      permittedToEdit={permittedToEdit}
                      setLotComponentToBeDeleted={setLotComponentToBeDeleted}
                      lc={lc}
                      anyCompletedBids={anyCompletedBids}
                      eventCurrency={eventCurrency}
                      lotId={lot.id}
                      currenciesHash={currenciesHash}
                      event={event}
                      classFromEventState={classFromEventState}
                      currentUser={currentUser}
                      hideFromParticipantsSwitch={hideFromParticipantsSwitch}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>
                <Suspense
                  fallback={
                    <tr>
                      <td className="loading" key={lot.id}></td>
                      {lotLcs.map((d) => (
                        <td className="loading" key={d.id}></td>
                      ))}
                    </tr>
                  }
                >
                  <LotDetails
                    lot={lot}
                    lotLis={lotLis}
                    lotLcs={lotLcs}
                    lotLics={lotLics}
                    translations={translations}
                    permittedToEdit={permittedToEdit}
                    classFromEventState={classFromEventState}
                    anyCompletedBids={anyCompletedBids}
                    event={event}
                    maxPrecisionFor={maxPrecisionFor}
                    currenciesHash={currenciesHash}
                    eventCurrency={eventCurrency}
                    lots={lots}
                    lineItemComponents={lineItemComponents}
                    openOrJapanese={openOrJapanese}
                    isJapaneseEvent={isJapaneseEvent}
                    locale={locale}
                    reRenderDetails={reRenderDetails}
                    currentUser={currentUser}
                    timeZoneIdentifier={timeZoneIdentifier}
                    showDetails={showDetails}
                    handleShowDetailsStatus={handleShowDetailsStatus}
                    setLineItemToBeDeleted={setLineItemToBeDeleted}
                    isMultiCurrencyEvent={isMultiCurrencyEvent}
                    hideFromParticipantsSwitch={hideFromParticipantsSwitch}
                  />
                </Suspense>
                <tr className="lot-work-row" id={`work_area_${lot.id}`} style={{ display: "none" }}>
                  <td colSpan={lotLcs.length + 1}>
                    lot.id &&
                    <div className="work-area" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Confirm
            onOkayButtonClick={() => dispatch(deleteLotComponent(lotComponentToBeDeleted))}
            translations={translations}
            message={translations.delete_lot_component_confirmation}
            htmlId={`lotComponentDeleteConfirmation-${lot.id}`}
          />
          <Confirm
            onOkayButtonClick={() => dispatch(deleteLineItem(lineItemToBeDeleted))}
            translations={translations}
            message={translations.delete_line_item_confirmation}
            htmlId={`lineItemDeleteConfirmation-${lot.id}`}
          />
        </div>
      )}
    </div>
  );
};

export default CellsContainer;
