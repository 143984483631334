import React from "react";

import { InputPropTypes } from "../input/input";
import { InternalCheckboxRadio } from "./internal-checkbox-radio";

/**
 * @type {React.FC<import("prop-types").InferProps<typeof InputPropTypes> & React.ComponentProps<"input">>}
 */
export const Checkbox = (props) => <InternalCheckboxRadio {...props} type="checkbox" />;

export default Checkbox;
