import * as apiCall from '../api';
import actionTypes from '../actionsTypes';
import config from '../config';

const getParticipantsSuccess = (data) => ({
  type: actionTypes.get_participant_list,
  payload: data,
});

const getParticipantsFail = (error) => ({
  type: error.response.status,
  payload: error,
});

const getParticipants = (data) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/materialrequest/participants/?category_id=${data.category_id}&postcode=${data.postcode}`)
    .then((response) => {
      dispatch(getParticipantsSuccess(response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(getParticipantsFail(error));
      return null;
    })
);

export default getParticipants;
