import React from "react";
import propTypes from "prop-types";
import SingleBlicValue from "./SingleBlicValue";
import { showCurrency, isLotTotalPresent } from "../../../common";
import { useSelector } from "react-redux";
import { useMemo } from "react";

/**
 * @description Participant bid value for LineItemComponent
 * Monitor Tab =>
 * Opens up once clicked on Show Components
 *
 * Column - Bid Value
 */
export default function BidValue(props) {
  const { locale, translations } = useSelector((state) => state.lotReducers);
  const { lotComponent, licCurrency, participantLotBid, lineItem, lot } = props;
  const isLotTotal = isLotTotalPresent(lot);

  if (participantLotBid)
    return (
      <div className="mycell">
        <SingleBlicValue {...props} />
      </div>
    );

  const currencyVal = useMemo(
    () => showCurrency("-", licCurrency, lotComponent.decimal_place, locale, translations.number_format),
    [licCurrency, locale]
  );
  return (
    <div className="mycell">
      <span title={currencyVal}>{!isLotTotal && !lineItem ? "-" : currencyVal}</span>
    </div>
  );
}

BidValue.propTypes = {
  lotComponent: propTypes.object.isRequired,
  participantLotBid: propTypes.object,
};

BidValue.defaultProps = {
  lotComponent: {},
};
