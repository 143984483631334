import React from "react";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import { bidRoundPrice, participantBidInRound } from "../../../common";
import { participantNotTimedOutOrDeclined } from "../../../bidCommon";
import { pause, pending, postRfqPreAuction, running } from "../../../eventCommon";
import { Fragment } from "react";

export const biddingClosedCondition = (props) => {
  return (
    !!props.selectedBid?.decline_bid ||
    props.timedOutOrDeclined ||
    !props.isParticipantActive ||
    (props.bid_round > 1 &&
      !participantNotTimedOutOrDeclined(props.LBids, props.bid_round, props.currentUser, props.bidControls))
  );
};

export const isBidInRound = (props) => {
  const { bid, event_participant, lot, bid_round, auctionControls } = props;
  const isUserIsMatching = bid && !isEmpty(bid) && bid.user_id === event_participant.user_id;
  if (isUserIsMatching) {
    return (
      bid.id &&
      !isEmpty(bid) &&
      bid.lot_id === lot.id &&
      bid.bid_round === bid_round &&
      bidRoundPrice(lot, props.event, bid.bid_round, auctionControls) === bid.host_price
    );
  } else {
    return participantBidInRound(
      event_participant,
      props.event,
      bid_round,
      auctionControls,
      props.LBids,
      props.lots,
      lot
    );
  }
};

export const Buttons = (props) => {
  const { translations, openModal } = props;
  if (isBidInRound(props)) return translations.bid_placed;
  else if (biddingClosedCondition(props)) return translations.bidding_closed;
  else if (pause(props.event)) return translations.event_is_paused;
  return (
    <div className="accept-decline-btn-jpn-proxy">
      {[props.htmlAcceptId, props.htmlDeclineId].map((modalId) => {
        const isDecline = modalId === props.htmlDeclineId;
        return (
          <Fragment key={modalId}>
            <a
              className={`btn btn-default light-gray-color br-${isDecline ? "red" : "green"}-color`}
              onClick={() => openModal(`#${modalId}`)}
            >
              {isDecline ? translations.decline : translations.accept}
            </a>
            {!isDecline && <>&nbsp; &nbsp;</>}
          </Fragment>
        );
      })}
    </div>
  );
};

export const getSelectedBid = (bids = [], lot = {}) => {
  const lastBid = last(bids.filter((bid) => bid.lot_id === lot.id));
  return typeof lastBid === "object" ? lastBid : {};
};

export const JapaneseButtonOrText = (props) => {
  const { event, translations } = props;
  const selectedBid = getSelectedBid(props.bids, props.lot);

  if (!!selectedBid?.rejected_lot) return translations.lot_rejected;
  else if (postRfqPreAuction(event) || pending(event)) return translations.bidding_has_not_started;
  else if (running(event) || pause(event)) {
    return <Buttons {...props} selectedBid={selectedBid} />;
  }
  return translations.bidding_closed;
};

export default function ShowButtons(props) {
  if (props.lot?.is_event_total) return null;
  return <JapaneseButtonOrText {...props} />;
}
