import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { updateBidSurveyFeedback, updateBidSurveyOffered } from "../../../../../actions/bidActions";
import actionsTypes from "../../../../../actions/actionsTypes";
import FeedbackSurveyScore from "./FeedbackSurveyScore";
import FeedbackSurveyText from "./FeedbackSurveyText";
import ModalHeader from "./ModalHeader";
import { getError } from "../../../../../actions/errorActions"

const submitFeedbackForm = (dispatch, surveyScore, surveyText, props, setState) => {
  let params = { survey: { survey_type: 2, survey_score: surveyScore, survey_text: surveyText }, event_id: props.event.id };
  dispatch(updateBidSurveyFeedback(props.event.id, params))
    .then((response) => {
      setState({ modalIsOpen: false, surveyThanksModal: true });
    })
    .catch((err) => {
      dispatch(getError(error.response));
    });
};

const triggerSurveyOffered = (dispatch, props, setModalClose) => {
  let params = { event_id: props.event.id };
  setModalClose();
  dispatch(updateBidSurveyOffered(props.event_participant.id, params))
    .then((response) => {})
    .catch((err) => {
      dispatch(getError(error.response));
    });
};

const ModalContent = ({props, closeModal, setSurveyScore, setSurveyText}) => {
  return (
    <div className="block block-transparent remove-margin-b">
      <ModalHeader {...props} closeModal={closeModal} title={props.translations.new_survey} />
      <div className="block-content lot-fieldset">
        <div className="row">
          <div className="col-md-12">
            <FeedbackSurveyScore {...props} setSurveyScore={setSurveyScore} />
            <FeedbackSurveyText {...props} setSurveyText={setSurveyText} />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="input inline">
          <button data-dismiss="modal" type="button" className="btn btn-sm btn-default" onClick={closeModal}>
            {props.translations.cancel}
          </button>
          <button name="button" className="btn btn-sm btn-primary" type="submit" disabled={!props.surveyScore}>
            {props.translations.save}
          </button>
        </div>
      </div>
    </div>
  );
};

const FeedbackSurveyModal = (props) => {
  const { modalIsOpen, setState, surveyScore, surveyText } = props;
  const dispatch = useDispatch();
  const setSurveyScore = (e) => setState({ surveyScore: e.target.value });
  const setSurveyText = (e) => setState({ surveyText: e.target.value });
  const setModalClose = () => setState({ modalIsOpen: false });

  function handleFormSubmit(ev) {
    ev.preventDefault();
    submitFeedbackForm(dispatch, surveyScore, surveyText, props, setState);
  }

  function closeModal() {
    triggerSurveyOffered(dispatch, props, setModalClose);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="modal-content col-md-4 col-sm-4 custom-field-popup"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <form onSubmit={handleFormSubmit}>
        <ModalContent props={props} setSurveyScore={setSurveyScore} setSurveyText={setSurveyText} closeModal={closeModal} />
      </form>
    </Modal>
  );
};

export default FeedbackSurveyModal;
