import React, { Component } from "react";
import Tooltip from "../../../common/Tooltip";
import Select2 from "react-select2-wrapper";

class InactiveParticipants extends Component {
  componentDidUpdate() {
    this.setValue();
  }

  setValue = () => {
    this.inactiveParticpantExpiry.value =
      this.props.configuration_setting_values.inactive_participants_period_of_expiry.split(".")[0];
    this.inactiveParticpantNotif.value =
      this.props.configuration_setting_values.inactive_participants_frequency_of_notification.split(".")[0];
    this.inactiveParticpantLim.value =
      this.props.configuration_setting_values.inactive_participants_limit_of_notification.split(".")[0];
  };

  inactiveParticExpiry = (e) => {
    let temp =
      +this.inactiveParticpantExpiry.value +
      "." +
      (e.target.name == "inactiveParticpantExpiryUnit"
        ? e.target.value
        : this.inactiveParticpantExpiryUnit.props.value);
    if (temp == this.props.configuration_setting_values.inactive_participants_period_of_expiry) {
      return false;
    }
    this.props.onValueChange(temp, "inactive_participants_period_of_expiry");
  };

  inactiveParticipantNotification = (e) => {
    let temp =
      +this.inactiveParticpantNotif.value +
      "." +
      (e.target.name == "inactiveParticpantNotifUnit" ? e.target.value : this.inactiveParticpantNotifUnit.props.value);
    if (temp == this.props.configuration_setting_values.inactive_participants_frequency_of_notification) {
      return false;
    }
    this.props.onValueChange(temp, "inactive_participants_frequency_of_notification");
  };

  inactiveParticipantLimit = (e) => {
    let temp =
      +this.inactiveParticpantLim.value +
      "." +
      (e.target.name == "inactiveParticpantLimUnit" ? e.target.value : this.inactiveParticpantLimUnit.props.value);
    if (temp == this.props.configuration_setting_values.inactive_participants_limit_of_notification) {
      return false;
    }
    this.props.onValueChange(temp, "inactive_participants_limit_of_notification");
  };
  render() {
    const {
      translations,
      durationType,
      configuration_setting_values,
      configuration_setting_enum_values,
      currentUserEditConfigPermission,
    } = this.props;
    return (
      <div className="col-md-6 form-item-block wid-49 pull-right">
        <div className="form-item-block-title">{translations.admin_views.configuration.inactive_participants}</div>
        <div className="input inline m-b10">
          <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">{translations.notify_participants}</label>
          <input
            ref={(ref) => (this.inactiveParticpantExpiry = ref)}
            type="text"
            name="inactive_participants_period_of_expiry"
            id="inactive_participants_period_of_expiry"
            defaultValue={configuration_setting_values.inactive_participants_period_of_expiry.split(".")[0]}
            className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
            size="5"
            onBlur={(e) => this.inactiveParticExpiry(e)}
            disabled={!currentUserEditConfigPermission}
          />
          <div className="col-md-3 col-sm-3 col-xs-9">
            <Select2
              ref={(ref) => (this.inactiveParticpantExpiryUnit = ref)}
              className="duration_type form-control"
              name="inactiveParticpantExpiryUnit"
              value={configuration_setting_values.inactive_participants_period_of_expiry.split(".")[1]}
              data={durationType}
              onSelect={(e) => this.inactiveParticExpiry(e)}
              disabled={!currentUserEditConfigPermission}
            />
          </div>
          <div className="vertical-center">{translations.of_inactivity}.</div>
          <div className="vertical-center">
            <Tooltip {...translations.configuration.inactive_participants_period_of_expiry} />
          </div>
        </div>

        <div className="input inline m-b10">
          <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">
            {translations.notify_participants_every}
          </label>
          <input
            ref={(ref) => (this.inactiveParticpantNotif = ref)}
            type="text"
            name="inactive_participants_frequency_of_notification"
            id="inactive_participants_frequency_of_notification"
            defaultValue={configuration_setting_values.inactive_participants_frequency_of_notification.split(".")[0]}
            className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
            size="5"
            onBlur={(e) => this.inactiveParticipantNotification(e)}
            disabled={!currentUserEditConfigPermission}
          />
          <div className="col-md-3 col-sm-3 col-xs-9">
            <Select2
              ref={(ref) => (this.inactiveParticpantNotifUnit = ref)}
              className="duration_type form-control"
              name="inactiveParticpantNotifUnit"
              value={configuration_setting_values.inactive_participants_frequency_of_notification.split(".")[1]}
              data={durationType}
              onSelect={(e) => this.inactiveParticipantNotification(e)}
              disabled={!currentUserEditConfigPermission}
            />
          </div>
          <div className="vertical-center">
            <Tooltip {...translations.configuration.inactive_participants_frequency_of_notification} />
          </div>
        </div>

        <div className="input inline m-b10">
          <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">{translations.notify_participants_for}</label>
          <input
            ref={(ref) => (this.inactiveParticpantLim = ref)}
            type="text"
            name="inactive_participants_limit_of_notification"
            id="inactive_participants_limit_of_notification"
            defaultValue={configuration_setting_values.inactive_participants_limit_of_notification.split(".")[0]}
            className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
            size="5"
            onBlur={(e) => this.inactiveParticipantLimit(e)}
            disabled={!currentUserEditConfigPermission}
          />
          <div className="col-md-3 col-sm-3 col-xs-9">
            <Select2
              ref={(ref) => (this.inactiveParticpantLimUnit = ref)}
              className="duration_type form-control"
              name="inactiveParticpantLimUnit"
              value={configuration_setting_values.inactive_participants_limit_of_notification.split(".")[1]}
              data={durationType}
              onSelect={(e) => this.inactiveParticipantLimit(e)}
              disabled={!currentUserEditConfigPermission}
            />
          </div>
          <div className="vertical-center">
            <Tooltip {...translations.configuration.inactive_participants_limit_of_notification} />
          </div>
        </div>
      </div>
    );
  }
}

export default InactiveParticipants;
