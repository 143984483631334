import React, { useState } from "react";
import LotComponentForm from "./LotComponentForm";
import { useDispatch, useSelector } from "react-redux";
import { editLotComponent, newLotComponent } from "../../../../actions/lotComponentActions";
import { useEffect } from "react";
import { getUpdatedValue } from "../lotCommon";

const LotComponentModal = (props) => {
  const dispatch = useDispatch();

  const {
    lotComponent,
    event,
    lot,
    currenciesHash,
    create,
    classFromEventState,
    total,
    translations,
    currentUser,
    classes,
    title,
    showPlusIcon,
    editIcon,
    eventCurrency,
    hideFromParticipantsSwitch,
  } = props;

  const [lcForEdit, setLcForEdit] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [picklists, setPicklists] = useState([]);
  const [picklistOptions, setPicklistOptions] = useState([]);
  const [allUnitSets, setAllUnitSets] = useState([]);
  const [allUnitSetUnitOfMeasures, setAllUnitSetUnitOfMeasures] = useState([]);
  const [uoms, setUoms] = useState([]);
  const [lotComponentPicklistOptions, setLotComponentPicklistOptions] = useState([]);

  const pickAndUinitSetSelector = useSelector((state) => state.picklistAndUnitSetReducers);
  const { actionType } = pickAndUinitSetSelector;
  useEffect(() => {
    switch (actionType) {
      case "CREATE_PICKLIST":
        setPicklists(getUpdatedValue(picklists, pickAndUinitSetSelector.picklist));
        setPicklistOptions([...picklistOptions, ...pickAndUinitSetSelector.picklistOptions]);
        break;
      case "UPDATE_PICKLIST":
        setPicklists(getUpdatedValue(picklists, pickAndUinitSetSelector.picklist));
        const pickOptions = picklistOptions.filter((po) => po.picklist_id !== pickAndUinitSetSelector.picklist.id);
        setPicklistOptions([...pickOptions, ...pickAndUinitSetSelector.picklistOptions]);
        break;
      case "CREATE_UNIT_SET":
        setAllUnitSets(getUpdatedValue(allUnitSets, pickAndUinitSetSelector.unitSet));
        setAllUnitSetUnitOfMeasures([...allUnitSetUnitOfMeasures, ...pickAndUinitSetSelector.unitOfMeasures]);
        break;
      case "UPDATE_UNIT_SET":
        setAllUnitSets(getUpdatedValue(allUnitSets, pickAndUinitSetSelector.unitSet));
        const unitOfMeasures = allUnitSetUnitOfMeasures.filter(
          (us) => us.unit_set_id !== pickAndUinitSetSelector.unitSet.id
        );
        setAllUnitSetUnitOfMeasures([...unitOfMeasures, ...pickAndUinitSetSelector.unitOfMeasures]);
        break;
    }
  }, [pickAndUinitSetSelector]);

  const handleModalIsOpen = () => {
    dispatch(create ? newLotComponent(lot.id, event.id) : editLotComponent(lotComponent.id, lot.id, event.id)).then(
      (response) => {
        if (response) {
          const { payload } = response;
          setLcForEdit(payload.lotComponent);
          setPicklists(payload.picklists);
          setPicklistOptions(payload.picklistOptions);
          setAllUnitSets(payload.allUnitSets);
          setAllUnitSetUnitOfMeasures(payload.allUnitSetUnitOfMeasures);
          setLotComponentPicklistOptions(payload.lotComponentPicklistOptions);
          setUoms(payload.uoms);
          setModalIsOpen(true);
        }
      }
    );
  };
  const editButton = () => {
    if (editIcon) {
      return (
        <a className="edit hover opacity lot-component-content-loader pencil-box" onClick={() => handleModalIsOpen()}>
          <i className="fa fa-pencil" />
        </a>
      );
    } else {
      return (
        <a type="button" className={classes} title={title} onClick={() => handleModalIsOpen()}>
          {showPlusIcon && (
            <>
              <i className="fa fa-table" />
              <span>&nbsp;</span>
            </>
          )}
          {title} {showPlusIcon && <>{translations.questionnaire_questions.edit.column}</>}
        </a>
      );
    }
  };

  return (
    <>
      {editButton()}
      {modalIsOpen && (
        <LotComponentForm
          lotComponent={lcForEdit}
          lot={lot}
          uoms={props.unitOfMeasures}
          currenciesHash={currenciesHash}
          create={create}
          classFromEventState={classFromEventState}
          calculationComponent={total}
          picklists={picklists}
          picklistOptions={picklistOptions}
          allUnitSets={allUnitSets}
          allUnitSetUnitOfMeasures={allUnitSetUnitOfMeasures}
          lotComponentPicklistOptions={lotComponentPicklistOptions}
          uoms={uoms}
          currentUser={currentUser}
          translations={translations}
          eventCurrency={eventCurrency}
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          hideFromParticipantsSwitch={hideFromParticipantsSwitch}
        />
      )}
    </>
  );
};

export default LotComponentModal;
