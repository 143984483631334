import React from "react";
import PropTypes from "prop-types";
import Input from "@/cl/input/react-form";
import Select from "@/cl/select/react-form";
import { Modules, ModuleTypes, useInputTypeOptions, useModuleUiConfig } from "./types";
import { Button } from "@/cl/button";
import { Icon } from "@/cl/icon";
import { useDispatch } from "react-redux";
import { useCompanyId } from "./company-id-context";
import { useFormContext } from "@adeattwood/react-form";
import { createCustomField, updateCustomField } from "@/actions/customField";
import ModuleOptionsSelect from "./module-options-select";
import CustomField, { ColumnTypeCD } from "@/common-prop-types/custom-field";
import { Box } from "@/cl/box";
import EditOptionsModal from "./edit-options-modal";
import { AuthPropType } from ".";
import { t } from "@/i18n";

const validateRow = async ({ validate, errors, formState, index }) => {
  const formErrors = await validate(formState);
  const newErrors = { ...errors };
  let haveAddedErrors = false;
  Object.keys(formErrors).forEach((key) => {
    if (key.startsWith(`customFields.${index}`)) {
      haveAddedErrors = true;
      newErrors[key] = formErrors[key];
    }
  });

  return [haveAddedErrors, newErrors];
};

const authMap = {
  [Modules.SIM]: "can_edit_sim",
  [Modules.SRM]: "can_edit_srm",
  [Modules.CONTRACTS]: "can_edit_contracts",
  [Modules.SOURCING]: "can_edit_sourcing",
};

/**
 * Removes the negative ids from the custom_column_options. They are only used
 * in the frontend and we should not be sending them to the server.
 */
const removeId = (customFieldOptions) => {
  return customFieldOptions.map(
    /**
     * @param {{ id: number }} item
     */
    ({ id, ...rest }) => (id < 0 ? rest : { id, ...rest })
  );
};

export const prepareCustomField = (auth, customField) => {
  // Remove any custom_column_item_types for modules the user dose not have
  // permission to edit. Its easier to do this here and just throw a 403 when
  // the user tries to updated them. The alternative is to check if the user
  // has permissions to the module and it has changed all server side.
  if (customField.custom_column_item_types) {
    customField.custom_column_item_types = customField.custom_column_item_types.filter((item) => {
      const module = Object.keys(ModuleTypes).find((type) => ModuleTypes[type].includes(item.item_type));
      return auth[authMap[parseInt(module)]] || false;
    });
  }

  // If we have a sim_srm item type in the list then we need to remove it
  // because its not a valid item type. This can happen with some old data
  // from the API. We need to add the null operator to this because they may
  // not always be updated. this can happen when changing the rank for
  // example.
  const simSrmIndex = customField.custom_column_item_types?.findIndex(({ item_type }) => item_type === "sim_srm");
  if (simSrmIndex > -1) {
    customField.custom_column_item_types.splice(simSrmIndex, 1);
  }

  // Remove the ID if this is a new item so it gets created on the server. We
  // will add a placeholder of a negative number so we know this is a new
  // item in the frontend while keeping a unique id to use for our react key.
  if (customField.custom_column_options) {
    customField.custom_column_options = removeId(customField.custom_column_options);
  }

  return customField;
};

const useSaveRow = (index, auth, callback) => {
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const { getAttribute, errors, formState, setErrors, validate } = useFormContext();

  const hasErrors = Object.keys(errors).some((key) => key.startsWith(`customFields.${index}`));

  const saveTheRow = async function () {
    const customField = getAttribute(`customFields.${index}`);
    const [haveAddedErrors, newErrors] = await validateRow({ validate, errors, formState, index });

    if (haveAddedErrors) {
      setErrors(newErrors);
      return;
    }

    const action = customField.id > -1 ? updateCustomField : createCustomField;

    await Promise.resolve(dispatch(action({ companyId, customField: prepareCustomField(auth, customField) })));

    callback();
  };

  return [saveTheRow, hasErrors];
};

const TypeEditButtonPropTypes = {
  customField: PropTypes.shape(CustomField).isRequired,
  index: PropTypes.number.isRequired,
};

/**
 * All of the item types that can have multiple choice options.
 */
const multiChoiceTypes = [ColumnTypeCD.PICK_ONE_FROM_LIST, ColumnTypeCD.MULTIPLE_CHOICE];

/**
 * @type {React.FC<PropTypes.InferProps<typeof TypeEditButtonPropTypes>>}
 */
export const TypeEditButton = ({ customField, index }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const inputTypeOptions = useInputTypeOptions();
  if (!multiChoiceTypes.includes(customField.column_type_cd)) {
    return inputTypeOptions.find((item) => item.value === customField.column_type_cd).label;
  }

  if (isOpen) {
    return <EditOptionsModal {...{ customField, index }} close={() => setIsOpen(false)} />;
  }

  return (
    <Button
      brand="md-primary"
      size="sm"
      onClick={() => setIsOpen(true)}
      type="button"
      title={t("centralised_custom_fields.add_options")}
    >
      {inputTypeOptions.find((item) => item.value === customField.column_type_cd).label}
      <Box as="span" mx={1} />
      <Icon name="pencil-fill" />
    </Button>
  );
};

TypeEditButton.propTypes = TypeEditButtonPropTypes;

const TableRowEditStatePropTypes = {
  /**
   * The customField that is getting edited
   */
  customField: PropTypes.shape(CustomField).isRequired,
  /**
   * The index in the form state of the custom field getting edited
   */
  index: PropTypes.number.isRequired,
  /**
   * A callback that will get called after the custom field is saved
   */
  onSubmit: PropTypes.func,
  auth: PropTypes.shape(AuthPropType).isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof TableRowEditStatePropTypes>>}
 */
const TableRowEditState = ({ customField, index, onSubmit, auth }) => {
  const [saveTheRow, hasErrors] = useSaveRow(index, auth, onSubmit);
  const modules = useModuleUiConfig();
  const inputTypeOptions = useInputTypeOptions();

  function canEditModule(module) {
    return auth[authMap[module]] || false;
  }

  const nameLabel = t("centralised_custom_fields.custom_field_name_label", { index });
  const typeLabel = t("centralised_custom_fields.custom_field_type_label", { index });

  return (
    <tr className="new-custom-column">
      <td style={{ width: "16.6%" }}>
        {customField.id > -1 ? (
          <strong>{customField.name}</strong>
        ) : (
          <Input attribute={`customFields.${index}.name`} aria-label={nameLabel} />
        )}
      </td>
      <td style={{ width: "16.6%" }}>
        {customField.id > -1 ? (
          <TypeEditButton {...{ customField, index }} />
        ) : (
          <Select
            attribute={`customFields.${index}.column_type_cd`}
            options={inputTypeOptions}
            aria-label={typeLabel}
          />
        )}
      </td>
      {modules.map(({ module }) => (
        <td key={module} style={{ width: "16.6%" }}>
          <ModuleOptionsSelect index={index} module={module} disabled={!canEditModule(module)} />
        </td>
      ))}
      <td style={{ minWidth: "140px", textAlign: "right" }}>
        <Button icon type="button" brand="md-outline-primary" onClick={onSubmit} title={t("cancel")}>
          <Icon name="x" />
        </Button>
        <Button
          disabled={hasErrors}
          icon
          ms={2}
          type="button"
          brand="md-primary"
          onClick={saveTheRow}
          title={t("centralised_custom_fields.save_custom_field")}
        >
          <Icon name="floppy" />
        </Button>
      </td>
    </tr>
  );
};

TableRowEditState.propTypes = TableRowEditStatePropTypes;

export default TableRowEditState;
