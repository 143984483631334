import React from "react";
import PropTypes from "prop-types";

/**
 * Tab content wrapper for activity modal component.
 *
 * @param {Object} Props
 * @returns {JSX}
 */
const TabsContent = ({ children, id, active }) => {
  return (
    <div className="container m-t2 p-0">
      <div className="row">
        <div className="col-md-12 clear-padding">
          <div className="tab-content block-content p-0" id={id}>
            {children.filter((child) => child.props.name == active)}
          </div>
        </div>
      </div>
    </div>
  );
};

TabsContent.defaultProps = {
  id: "activityTabContent",
  active: "general",
};

TabsContent.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

/**
 * Wrapper for tab items for activity modal component
 * @param {Object} props
 * @returns {JSX}
 */
const TabItem = ({ children, className, classes }) => <div className={className + " " + classes}>{children}</div>;

TabItem.defaultProps = {
  className: "tab-pane active fade in",
  classes: "",
};

TabItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  className: PropTypes.string,
  classes: PropTypes.string,
};

export { TabItem };

export default TabsContent;
