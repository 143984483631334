import * as actionTypes from "../actions/actionsTypes";
import { fetchBidsOrBlicsDetails } from "./contract/reducerCommonFunctions";

const bidLineItemComponentReducers = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_BLICS:
      return fetchBidsOrBlicsDetails(state, payload);
    default:
      return state;
  }
};

export default bidLineItemComponentReducers;
