import React, { Component } from "react";
import { connect } from "react-redux";
import { isRfq, isWeightedRfqOrAuction } from "../../../eventCommon";
import { findLotTotalLic, hasValue, leadParticipantModalProps } from "../../../common";
import { deleteBid } from "../../../../../actions/bidActions";
import { Confirm } from "../../../../common/Confirm";
import {
  rankForParticipant,
  awardedBid,
  differenceOfferedPop,
  differenceOfferedPercentageForBid,
  differenceOfferedWithCurrency,
  showBid,
  timeOutOrDeclinedBidPrice,
  timedOutBid,
} from "../../../bidCommon";
import {
  eventParticipantStatus,
  companyNameOrEmail,
  formatDatetime,
  formatPercentileNumber,
  showCurrency,
  bidRoundPrice,
  roundBidControls,
} from "../../../common";
import LeadParticipantModal from "./LeadParticipantModal";
import { fetchQuestionnaireScore } from "../../../QuestionnaireAnswerCommon";
import DeleteBidButton from "./LeadParticipantModal/TabDetails/delete-bid-button";

class BidRowDetails extends Component {
  render() {
    const { bid, showOverallScore, timeZoneIdentifier } = this.props;
    return (
      <>
        {this.showRankOrRoundValue()}
        {this.leadParticipantModalTd()}
        {this.showEventround()}
        {this.showParticipantBid()}
        {showOverallScore && this.showWeighedScore()}
        <td>{formatDatetime(bid.created_at, timeZoneIdentifier)}</td>
        {this.showDifferenceOfferedPercentage()}
        {this.showDestroyButton()}
      </>
    );
  }

  leadParticipantModalTd() {
    const modalProps = leadParticipantModalProps(this.props);
    const allUom = [...this.props.unitSetUoms, ...this.props.unitOfMeasures];
    let companyEmailOrName = companyNameOrEmail(this.props.user);
    return (
      <td className="bid-user">
        <LeadParticipantModal
          viewComponent={companyEmailOrName}
          header={companyEmailOrName}
          allUom={allUom}
          {...modalProps}
        />
      </td>
    );
  }

  showEventround() {
    const { event_rounds, event, bid } = this.props;
    if (isRfq(event) && event_rounds?.length) {
      const bidEventRound = event_rounds.find((round) => round.id === bid.event_round_id)?.event_round ?? "";
      return <td>{bidEventRound}</td>;
    }
  }

  showRankOrRoundValue() {
    const { beforeAuction, event, bid, startAt, participant, isJapaneseEvent } = this.props;
    let value = "";
    if ((beforeAuction && (startAt ? Date.now() < startAt : true)) || !beforeAuction) {
      if (isJapaneseEvent && !beforeAuction) {
        value = <td>{bid.bid_round}</td>;
      } else if (isWeightedRfqOrAuction(event)) {
        // Added a condition for Rank
        if (!bid.rejected_lot && participant.status !== "Declined") {
          value = this.showRankValue();
        } else if (participant.status === "Declined") {
          value = <td>{translations.Declined}</td>;
        } else {
          value = <td>&nbsp;</td>;
        }
      } else {
        value = this.showRankValue();
      }
    } else if (beforeAuction && (startAt ? Date.now() > startAt : true)) {
      value = <td>{eventParticipantStatus(participant)}</td>;
    }
    return value;
  }

  showRankValue() {
    const { bid, participant, event } = this.props;
    const typeOfRank = isWeightedRfqOrAuction(event) ? "weightedScore" : "price";
    const rankValue = rankForParticipant(bid, typeOfRank, participant);

    return (
      <td>
        <span className={`${Number.isInteger(rankValue) ? "blic-rank-symbol badge" : "red-color"} ranked${rankValue}`}>
          {rankValue}
        </span>
      </td>
    );
  }

  bidRejectDeclinedTimeout() {
    if (this.props.bid.rejected_lot)
      return (
        <td>
          <b>{this.props.translations.lot_rejected}</b>
        </td>
      );
    return timeOutOrDeclinedBidPrice(
      this.props.lot,
      this.props.event,
      this.props.bid,
      this.props.auctionControls,
      this.props.currency,
      this.props.locale,
      this.props.translations
    );
  }

  showParticipantBid() {
    let result;
    if (this.props.bid.rejected_lot || this.props.bid.decline_bid || timedOutBid(this.props.bid)) {
      result = this.bidRejectDeclinedTimeout();
    } else {
      if (
        this.props.bidControls.length > 0 &&
        !roundBidControls(this.props.bidControls, this.props.bid.bid_round, this.props.user)
      ) {
        result = (
          <td>
            <b>{`${this.props.translations.timed_out_bid_level} ${this.showReacceptedBid()}`}</b>
          </td>
        );
      } else {
        const totalLic = findLotTotalLic(this.props.lineItemComponents, this.props.lot.lot_total_lic_id);
        const decimalPlace = totalLic && totalLic.decimal_place ? totalLic.decimal_place : 2;
        result = (
          <td>
            {showBid(
              this.props.bid,
              this.props.lot,
              this.props.currency,
              this.props.locale,
              "host",
              { ...this.props },
              decimalPlace
            )}
          </td>
        );
      }
    }
    return result;
  }

  showWeighedScore() {
    const { bid = {}, participant, locale } = this.props;
    const price_score = hasValue(bid.price_score) ? bid.price_score : 0;
    const weighted_score = hasValue(bid.weighted_score) ? bid.weighted_score : 0;
    const priceScoreTd = bid.rejected_lot || !bid.current ? "-" : formatPercentileNumber(price_score, locale);
    const weightedScoreTd = bid.rejected_lot || !bid.current ? "-" : formatPercentileNumber(weighted_score, locale);

    return (
      <>
        <td>{priceScoreTd}</td>
        <td>{fetchQuestionnaireScore(this.props, participant)}</td>
        <td>{weightedScoreTd}</td>
      </>
    );
  }
  showDifferenceOfferedPercentage() {
    const {
      bid,
      lot,
      event,
      bidList,
      participatingParticipants,
      currency,
      locale,
      translations,
      lineItemComponents,
      lic,
    } = this.props;
    let result;
    if (bid.rejected_lot || bid.decline_bid || timedOutBid(bid)) {
      result = (
        <>
          <td>-</td>
          <td>-</td>
        </>
      );
    } else {
      const diffOfferedPop = differenceOfferedPop(bid, lot, event, bidList, participatingParticipants);
      const diffOfferedPercentage = differenceOfferedPercentageForBid(bid, lot, event);
      const totalLic = findLotTotalLic(lineItemComponents, lot.lot_total_lic_id);
      const precision = lic?.decimal_place ?? totalLic?.decimal_place;
      result = (
        <>
          <td>
            {differenceOfferedWithCurrency(diffOfferedPop, currency, locale, translations.number_format, precision)}
          </td>
          <td>{formatPercentileNumber(diffOfferedPercentage, locale)}</td>
        </>
      );
    }
    return result;
  }
  showDestroyButton() {
    const { lot, bid, permittedToEdit, translations, beforeAuction, canShowDeleteBtn } = this.props;
    if (permittedToEdit && !timedOutBid(bid) && canShowDeleteBtn && !lot.is_event_total) {
      if (awardedBid(bid, lot))
        return (
          <td>
            <button
              className="btn btn-default disabled-participant btn-icon"
              onClick={() => alert(translations.cannot_delete_bid)}
              type="button"
            >
              <i className="fa fa-trash-o red-color" aria-hidden="true"></i>
            </button>
          </td>
        );

      return (
        <td>
          <DeleteBidButton {...{ ...this.props, bidToDelete: bid }} />
        </td>
      );
    }
  }
  showReacceptedBid() {
    const { event, lot, bid, translations, locale, auctionControls, currency } = this.props;
    const bidValue = showCurrency(
      bidRoundPrice(lot, event, bid.bid_round, auctionControls) || "-",
      currency,
      2,
      locale,
      translations.number_format
    );
    if (lot.complex_structure && bid.details_added)
      return (
        <a href="#" className="poppable">
          {bidValue}
        </a>
      );

    return bidValue;
  }
}

const mapStateToProps = ({ lotReducers: { event_rounds } }) => ({ event_rounds });

const mapDispatchToProps = (dispatch) => ({
  deleteBid: (bid, beforeAuction) => dispatch(deleteBid(bid, beforeAuction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BidRowDetails);
