import * as ajaxCall from "./contractAjax";
import * as actionTypes from "../actionsTypes";
import { ensureAbsoluteUrl } from "@/lib/fetch";

const archived = (payload) => {
  return {
    type: actionTypes.ARCHIVED,
    payload: payload,
  };
};
const flashForUpload = (payload) => {
  return {
    type: actionTypes.FLASH_FOR_UPLOAD,
    payload: payload,
  };
};
export const ownerChanged = (payload) => {
  return {
    type: actionTypes.CONTRACT_OWNER_CHANGED,
    payload: payload,
  };
};
export const searchResults = (payload) => {
  return {
    type: actionTypes.SEARCH_RESULTS,
    payload: payload,
  };
};
export const filteredResults = (payload) => {
  return {
    type: actionTypes.FILTERED_CONTRACTS,
    payload: payload,
  };
};
filteredResults;
const resetContractsToDefault = (payload) => {
  return {
    type: actionTypes.RESET_CONTRACTS,
    payload: payload,
  };
};
export const sortContracts = (payload) => {
  return {
    type: actionTypes.SORT_CONTRACTS,
    payload: payload,
  };
};
const selectAll = (payload) => {
  return {
    type: actionTypes.SELECT_ALL,
    payload: payload,
  };
};
export const archivedSuccess = (payload) => {
  return {
    type: actionTypes.ARCHIVED_SUCCESS,
    payload: payload,
  };
};
const getError = (response) => {
  const message = Object.values(response.data)[0];
  return {
    type: actionTypes.ERROR,
    error: message,
  };
};
const success = (successMessage) => {
  return {
    type: actionTypes.SUCCESS,
    success: successMessage,
  };
};
export const showArchived = (archive) => (dispatch) => {
  dispatch(archived(archive));
};
export const selectListAll = (data) => (dispatch) => {
  dispatch(selectAll(data));
};
export const markArchive = (contract) => (dispatch) => {
  const payload = { contract_id: contract.id };
  return ajaxCall
    .remove(`/contracts/${contract.id}/mark_archive`, payload)
    .then((response) => {
      dispatch(success(response.data.contract_archived_destroy_status));
      dispatch(archivedSuccess({ id: contract.id, newStatus: response.data.new_state }));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const changeContractOwner = (data) => (dispatch) => {
  return ajaxCall
    .post(`/contracts/change_contracts_owner`, data)
    .then((response) => {
      dispatch(ownerChanged({ payload: data, updated_contracts: response.data.contracts }));
      dispatch(success(response.data.message));
      return response.data;
      SEARCH_RESULTS;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};
export const sortContractsBy = (field, order) => (dispatch) => {
  let payload = { field: field, order: order };
  dispatch(sortContracts(payload));
};
export const resetContracts = (reset) => (dispatch) => {
  dispatch(resetContractsToDefault({ reset: reset }));
};

export const searchFilter = (data, customFilter) => (dispatch) => {
  return ajaxCall
    .post(ensureAbsoluteUrl(`/contracts/filter_contracts`), data)
    .then((response) => {
      dispatch(filteredResults({ data: response.data, customFilter: customFilter }));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
      return error.response;
    });
};

export const searchContracts = (searchKey) => (dispatch) => {
  let payload = { search: searchKey };
  return ajaxCall
    .post(`/contracts/filter_contracts`, payload)
    .then((response) => {
      dispatch(searchResults(response.data.selected_contract_ids));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const uploadContracts = (data) => (dispatch) => {
  return ajaxCall
    .post("/contracts/upload", data)
    .then((response) => {
      dispatch(flashForUpload(response.data));
    })
    .catch((error) => {
      dispatch(flashForUpload(error.response.data));
    });
};

export const emptyMessage = (data) => (dispatch) => {
  return dispatch(flashForUpload(data));
};
