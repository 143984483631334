import React from "react";
import PropTypes from "prop-types";
import Icon from "@/components/common/Icon";

const InputStatusIconPropTypes = {
  /**
   * The state you would like to show an icon for. If the state is not valid
   * then no icon will be shown
   */
  state: PropTypes.string.isRequired,
};

// All of the valid icons we want display next to an input. There are no
// success icons that are shown so the user dose not get confused there bid has
// been submitted.
const VALID_ICONS = { danger: true, loading: true };

/**
 * @type {React.FC<PropTypes.InferProps<typeof AddonIconPropTypes>>}
 */
const InputStatusIcon = ({ state }) => {
  return state in VALID_ICONS ? <Icon state={state} /> : null;
};

InputStatusIcon.propTypes = InputStatusIconPropTypes;

export default InputStatusIcon;
