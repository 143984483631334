import * as actionTypes from "../actions/actionsTypes";

const loaderReducers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BIDS_REQUESTED:
      return {
        ...state,
        isFetchBidsLoading: true,
      };
    case actionTypes.FETCH_BIDS_COMPLETED:
      return {
        ...state,
        isFetchBidsLoading: false,
      };
    case actionTypes.FETCH_BLICS_REQUESTED:
      return {
        ...state,
        isFetchBlicsLoading: true,
      };
    case actionTypes.FETCH_BLICS_COMPLETED:
      return {
        ...state,
        isFetchBlicsLoading: false,
      };
    case actionTypes.LOT_LOADER_REQUESTED:
      return {
        ...state,
        lotsLoaders: {
          [action.payload]: true,
        },
      };
    case actionTypes.FINISH_LOT_LOADER_REQUEST:
      return {
        ...state,
        lotsLoaders: {
          [action.payload]: false,
        },
      };

    default:
      return state;
  }
};

export default loaderReducers;
