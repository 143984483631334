import { useCallback } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { findUnitSet } from "../common";

// eslint-disable-next-line no-unused-vars
import { LineItemComponent } from "@/common-prop-types";

const unitSetStateSelection = createSelector(
  ({ lotReducers }) => lotReducers.lot_components,
  ({ lotReducers }) => lotReducers.unit_set_unit_of_measures ?? [],
  ({ lotReducers }) => lotReducers.unit_sets ?? [],
  (lotComponents, unitSetUoms, unitSets) => ({ lotComponents, unitSetUoms, unitSets })
);

const useUnitSets = () => {
  const { lotComponents, unitSetUoms, unitSets } = useSelector(unitSetStateSelection);

  const getUnitSet = useCallback(
    /**
     *
     * @param {import("prop-types").InferProps<LineItemComponent>} lic
     */
    (lic) => {
      const lc = lic ? lotComponents.find(({ id }) => id === lic.lot_component_id) : null;
      return lc && findUnitSet(unitSets, lc.unit_set_id);
    },
    [lotComponents, unitSets]
  );

  return {
    unitSetUoms,
    getUnitSet,
  };
};

export default useUnitSets;
