import React from "react";
import RenderHTML from "@/components/common/RenderHTML";
import { t } from "@/i18n";
// As we are importing a variable which is being used in jsdoc so eslint was giving an error for no-unused-vars
// eslint-disable-next-line no-unused-vars
import { CategoryPermissionPropTypes } from "./prop-types";
import PropTypes from "prop-types";

const CategoryPermissionsPropTypes = {
  categoryDojoLicense: PropTypes.string,
  showIcon: PropTypes.bool,
  categoryPermissions: PropTypes.arrayOf(PropTypes.shape(CategoryPermissionPropTypes)),
};

/**
 * @param {CategoryPermissionPropTypes} categoryPermission
 * @param {(key: stirng) => void} t
 * @returns {{categoryPermissionText: string, categoryPermissionActionText: string}}
 */
export const getStringTexts = (categoryPermission, t) => {
  const categoryPermissionText = getCategoryPermissionText(categoryPermission, t);
  const categoryPermissionActionText = t(categoryPermission.action);

  return { categoryPermissionText, categoryPermissionActionText };
};

/**
 * @param {CategoryPermissionPropTypes} categoryPermission
 * @param {(key: stirng) => void} t
 * @returns {string} categoryPermissionText
 */
const getCategoryPermissionText = (categoryPermission, t) => {
  const userOrGroup = categoryPermission?.resource_type === "User" || categoryPermission?.resource_type === "Group";

  if (userOrGroup) return renderForUserOrGroup(categoryPermission, t);
  else return renderDefaultText(categoryPermission, t);
};

const renderForUserOrGroup = (categoryPermission, t) => {
  const text =
    categoryPermission.action === "create"
      ? "category_permissions.categories_in_portfolio"
      : "category_permissions.portfolios";
  return `${t(text)} ${t("category_permissions.created_by")} ${categoryPermission.resource_name}`;
};

const renderDefaultText = (categoryPermission, t) => {
  const isPortfolioResourceType = categoryPermission?.resource_type === "Portfolio";
  const createActionText = isPortfolioResourceType
    ? "category_permissions.categories_in_portfolio"
    : "category_permissions.categories_in_category";
  const editViewActionText = isPortfolioResourceType ? "category_permissions.portfolio" : "category";

  return categoryPermission.action === "create"
    ? `${t(createActionText)} ${categoryPermission.resource_name}`
    : `${t(editViewActionText)} ${categoryPermission.resource_name}`;
};

/**
 * @type {React.FC<typeof CategoryPermissionsPropTypes>}
 */
const CategoryPermission = ({ categoryDojoLicense, showIcon, categoryPermissions }) => {
  return (
    <>
      {Array.isArray(categoryPermissions) &&
        categoryPermissions.map((categoryPermission, index) => {
          const { categoryPermissionText, categoryPermissionActionText } = getStringTexts(categoryPermission, t);
          const canShowExclamationMark = showIcon && categoryPermission.action === "edit" && !categoryDojoLicense;
          const string = `${t("can")} ${categoryPermissionActionText} ${categoryPermissionText}`;

          return (
            <>
              <RenderHTML key={index} content={string} />
              {canShowExclamationMark && <span role="alert" className="exclamation_mark" />}
              <br />
            </>
          );
        })}
    </>
  );
};
CategoryPermission.propTypes = CategoryPermissionsPropTypes;
export default CategoryPermission;
