import React, { useState } from "react";
import UploadLotsForm from "./UploadLotsForm";

const UploadLotsModal = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { translations, classFromEventState } = props;

  return (
    <>
      <a
        className={`btn auto_width_btn ${classFromEventState} status-event sm-butt`}
        onClick={() => setModalIsOpen(true)}
      >
        <i className="fa fa-upload" />
        &nbsp;
        {translations.upload_lot_template}
      </a>
      {modalIsOpen && <UploadLotsForm {...props} setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} />}
    </>
  );
};

export default UploadLotsModal;
