import React from "react";
import clsx from "clsx";
import { t } from "@/i18n";
import CustomFieldList from "./custom-field-list";
import type { CustomFieldsFormProps } from "./custom-field-form";

export type CustomFieldsTableProps = Pick<
  CustomFieldsFormProps,
  "componentName" | "customFields" | "showPage" | "readOnly" | "eventId" | "hideTableHeader"
> & { entityId: number };

const getClassNames = (className: string, hideTableHeader?: boolean): string => {
  return clsx(className, { "border-none": hideTableHeader });
};

const CustomFieldsTable: React.FC<CustomFieldsTableProps> = (props) => {
  const { hideTableHeader, showPage } = props;
  const tableHeaderClass = getClassNames("wid-46", hideTableHeader);

  return (
    <table className="border-none m-b0 table table-bordered table-layout-fixed table-sim table-condensed table-current">
      <thead className="sticky-header" style={{ visibility: hideTableHeader ? "collapse" : "visible" }}>
        <tr>
          <th className={tableHeaderClass}>
            <div className="center-content-horizontal-vertical m-t1 p-0 p-t5">{t("title")}</div>
          </th>
          <th className={tableHeaderClass}>
            <div className="center-content-horizontal-vertical m-t1 p-0 p-t5">{t("value")}</div>
          </th>
          {!showPage && <th className={getClassNames("wid-8", hideTableHeader)}></th>}
        </tr>
      </thead>
      <tbody>
        <CustomFieldList {...props} />
      </tbody>
    </table>
  );
};

export default CustomFieldsTable;
