import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import cookies from "browser-cookies";

const getEventsSuccess = (events) => {
  return {
    type: actionTypes.GET_CALENDAR_EVENTS,
    payload: events,
  };
};

const getError = (error) => {
  console.log("error");
};

export const getCalendarEvents = (startDate, endDate, userRole) => (dispatch) => {
  let sandpit_event = cookies.get("sandpit") ? cookies.get("sandpit") : false;
  let eventFilter = window.location.pathname.includes("home") ? true : false;
  let in_sandpit_as_user = cookies.get("in_sandpit_as_user") ? cookies.get("in_sandpit_as_user") : false;
  return apiCall
    .get(
      `${config.BASE_URL}/events?per_page=500&start_date_time=${startDate}&end_date_time=${endDate}${
        userRole.toLowerCase() === "participant" ? "" : "&emarketplace=false"
      }&sandpit_event=${sandpit_event}&in_sandpit_as_user=${in_sandpit_as_user}&in_sandpit=${sandpit_event}&calendar_event_filter=${eventFilter}`
    )
    .then((response) => {
      dispatch(getEventsSuccess(response.data.events));
    })
    .catch((error) => {
      getError(error);
    });
};
