import actionTypes from '../../actions/qq/actionsTypes';

const successMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.show_success:
      return {
        status: 200,
        message: action.payload,
      };

    default:
      return {};
  }
};

export default successMessageReducer;
