import React, { useState } from "react";
import { rejectedLotsByUserId } from "../../../../../eventCommon";
import { ConditionalElse, RejectedLotRow, UnConditionalElse, BeforeAuctionRow } from "./Common";

export const ConditionalElements = (props) => {
  let result = [];
  const [rank, setRank] = useState();
  const rejectedLots = rejectedLotsByUserId(props.bids, props.lot.id, props.participant.user_id);

  if (rejectedLots.length) {
    result = <RejectedLotRow {...props} rejectedLots={rejectedLots} />;
  } else if ((props.beforeAuction && props.numberOfBids === 0) || (!props.beforeAuction && !props.latestBid)) {
    result = <BeforeAuctionRow {...props} rejectedLots={rejectedLots} />;
  } else {
    Array.prototype.push.apply(result, [
      <ConditionalElse setRank={setRank} {...props} />,
      <UnConditionalElse {...props} rank={rank} rejectedLots={rejectedLots} />,
    ]);
  }
  return result;
};
