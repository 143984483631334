import React from "react";

const OverallScoreHeading = (props) => {
  const { titleWithTooltip } = props;
  return props.showOverallScore ? (
    <>
      <th>{props.translations.price_score} </th>
      {titleWithTooltip("questionnaire_score", "bid_qscore")}
      {titleWithTooltip("overall_score", "bid_overallscore")}
    </>
  ) : null;
};
export default OverallScoreHeading;
