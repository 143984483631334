import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { updateContractValue } from "../../../actions/contract/contractCreateEditActions";
import ContractTooltip from "./ContractTooltip";

class ContractValueForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractValues: props.contractValues,
    };
    this.annualValueError = React.createRef();
    this.totalValueError = React.createRef();
    this.contractValueSection = React.createRef();
  }

  async handleCurrencyChange(e) {
    let currency = e.target.value;
    let currenyObject = this.props.currencyList.filter((item) => item.name === currency)[0];
    if (currenyObject.id) {
      const data = { currency_id: currenyObject.id };
      const result = await this.props.updateContractValue(this.props.contractId, data);
      if (result) {
        this.setState({
          contractValues: result,
        });
      }
    }
  }

  handleValueChange(e, valueType) {
    let temp = this.state.contractValues;
    if (valueType === "annual_value") {
      this.annualValueError.current.classList.add("invisible");
      if (isNaN(+e.currentTarget.value)) {
        e.currentTarget.value = temp.annual_value;
      }
    } else {
      this.totalValueError.current.classList.add("invisible");
      if (isNaN(+e.currentTarget.value)) {
        e.currentTarget.value = temp.total_value;
      }
    }
  }

  async updateValueChange(e, valueType) {
    const targetedField = e.target;
    targetedField.type = "text";
    const { contractValues } = this.state;
    const { translations } = this.props;
    const value = e.target.value;
    contractValues[valueType] = e.target.value;
    if (valueType === "annual_value") {
      if (value === "") {
        this.annualValueError.current.classList.remove("invisible");
        this.annualValueError.current.innerText = translations.blank_annual_value;
        return;
      } else if (value < 0 || value > 999999999999.99) {
        this.annualValueError.current.classList.remove("invisible");
        this.annualValueError.current.innerText = translations.annual_value_error;
        return;
      } else {
        this.annualValueError.current.classList.add("invisible");
      }
    }
    if (valueType === "total_value") {
      if (value === "") {
        this.totalValueError.current.classList.remove("invisible");
        this.totalValueError.current.innerText = translations.blank_total_value;
        return;
      } else if (value < 0 || value > 999999999999.99) {
        this.totalValueError.current.classList.remove("invisible");
        this.totalValueError.current.innerText = translations.total_value_error;
        return;
      } else {
        this.totalValueError.current.classList.add("invisible");
      }
    }
    if (value) {
      const data = {};
      data[valueType] = value;
      const result = await this.props.updateContractValue(this.props.contractId, data);
      targetedField.value = result[valueType];
      if (result) {
        this.setState({
          contractValues: result,
        });
      }
    }
  }

  validateContractValueFields() {
    let isError = false;
    const { total_value, annual_value } = this.state.contractValues;
    const { translations } = this.props;
    if (total_value !== undefined) {
      if (!total_value) {
        this.totalValueError.current.classList.remove("invisible");
        this.totalValueError.current.innerText = translations.blank_total_value;
        isError = true;
      } else if (total_value < 0 || total_value > 999999999999.99) {
        this.totalValueError.current.classList.remove("invisible");
        this.totalValueError.current.innerText = translations.total_value_error;
        isError = true;
      } else {
        this.totalValueError.current.classList.add("invisible");
      }
    }
    if (annual_value !== undefined) {
      if (!annual_value) {
        this.annualValueError.current.classList.remove("invisible");
        this.annualValueError.current.innerText = translations.blank_annual_value;
        isError = true;
      } else if (annual_value < 0 || annual_value > 999999999999.99) {
        this.annualValueError.current.classList.remove("invisible");
        this.annualValueError.current.innerText = translations.annual_value_error;
        isError = true;
      } else {
        this.annualValueError.current.classList.add("invisible");
      }
    }
    return isError;
  }

  changeTypeIn = (e) => {
    let temp = e.target.value.split(",").join("");
    e.target.value = temp;
  };

  checkForAnnualAndTotalValue = (contractValues, showPage, translations, value) => {
    return (
      <Fragment>
        {/* this condition will check if the field is visible for contract and show page or not */}
        {((contractValues.hasOwnProperty(value) && !showPage) || (showPage && contractValues[value])) && (
          <div className="col-12 field">
            <label>{translations[value]}</label>
            <span className="required"> *</span>
            <div className="input-group mb-3 currency_symbol">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  {contractValues.symbol}
                </span>
              </div>
              {showPage ? (
                <input
                  type="text"
                  className="form-control"
                  name={value}
                  aria-describedby="basic-addon1"
                  value={contractValues[value]}
                  disabled={showPage}
                />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  name={value}
                  aria-describedby="basic-addon1"
                  defaultValue={contractValues[value]}
                  onBlur={(e) => this.updateValueChange(e, value)}
                  onKeyUp={(e) => this.handleValueChange(e, value)}
                  onFocus={(e) => this.changeTypeIn(e)}
                />
              )}
            </div>
            <span
              className="error-text invisible"
              ref={value === "total_value" ? this.totalValueError : this.annualValueError}
            />
          </div>
        )}
      </Fragment>
    );
  };

  render() {
    const { contractValues } = this.state;
    const { currencyList, translations, showPage } = this.props;
    const condition =
      contractValues.show_currency || "annual_value" in contractValues || "total_value" in contractValues;
    if (condition) {
      return (
        <div className="col-md-12 col-sm-12" ref={this.contractValueSection}>
          <div className="form-section-block contract-value-section">
            <ContractTooltip translations={translations} titleText={"contract_value"} />
            {condition && (
              <div className="col-12 field form-group">
                <label>{translations.currency}</label>
                <span className="required"> *</span>
                {showPage ? (
                  <input
                    type="text"
                    className="form-control"
                    name="contract_currency"
                    value={contractValues.currency}
                    disabled={showPage}
                  />
                ) : (
                  <select
                    className="form-control"
                    name="contract_currency"
                    defaultValue={contractValues.currency}
                    onChange={(e) => {
                      this.handleCurrencyChange(e);
                    }}
                  >
                    {currencyList.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            )}
            {this.checkForAnnualAndTotalValue(contractValues, showPage, translations, "annual_value")}
            {this.checkForAnnualAndTotalValue(contractValues, showPage, translations, "total_value")}
            <p className="clear-both" />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

const mapStateToProps = ({ createEditReducers }) => ({
  contractValues: createEditReducers.contractValues,
  currencyList: createEditReducers.currencyList,
  contractId: createEditReducers.contract.id,
  translations: createEditReducers.translations,
  showPage: createEditReducers.showPage,
});

const mapDispatchToProps = (dispatch) => ({
  updateContractValue: (contractId, data) => dispatch(updateContractValue(contractId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ContractValueForm);
