import React from "react";
import PropTypes from "prop-types";

import RunningEventLots from "./lots/RunningEventLots";
import ParticipantPendingEventLots from "./lots/ParticipantPendingEventLots";
import PastEventLots from "./lots/PastEventLots";
import EditEventLots from "./lots/EditEventLots";
import HostPendingEventLots from "./lots/host_rfq_screen/HostPendingEventLots";

import { EventPropType, RolePropType } from "@/common-prop-types";

/**
 * Gets the correct component for a past or current event, if it can. If not
 * then `undefined` will be returned and a fallback component should be used
 *
 * @param {Boolean} current
 * @param {Boolean} past
 *
 * @returns {React.FC | undefined}
 */
const getCurrentOrPastComponent = (current, past) => {
  if (current) {
    return RunningEventLots;
  }

  if (past) {
    return PastEventLots;
  }

  return undefined;
};

/**
 * PropTypes for AdvancedLotComponent
 *
 * @see AdvancedLotComponent
 */
const AdvancedLotComponentPropTypes = {
  /**
   * A callback function that will update the `data` for the current event
   */
  getUpdatedData: PropTypes.func.isRequired,
  /**
   * All of the data for the current event
   */
  data: PropTypes.shape({
    role: RolePropType,
    current: PropTypes.bool.isRequired,
    past: PropTypes.bool,
    event: PropTypes.shape(EventPropType),
  }).isRequired,
};

/**
 * The inner component that will be rendered by the main AdvancedLot component.
 * This will render different components based on the state of the
 * `current_state` of the event
 *
 * @type {React.FC<PropTypes.InferProps<AdvancedLotComponentPropTypes>>}
 */
const AdvancedLotComponent = ({ data, getUpdatedData }) => {
  const { role, current, past, event } = data;
  if (!["Host", "Admin"].includes(role)) {
    return <ParticipantPendingEventLots {...data} />;
  }

  const isNewOrEdit = ["new", "edit"].includes(event.current_state);
  let Component = isNewOrEdit ? EditEventLots : HostPendingEventLots;
  if (!isNewOrEdit) {
    Component = getCurrentOrPastComponent(current, past) || Component;
  }

  return <Component {...data} getUpdatedData={getUpdatedData} />;
};

AdvancedLotComponent.propTypes = AdvancedLotComponentPropTypes;

export default AdvancedLotComponent;
