import React from "react";
import PropTypes from "prop-types";

const Spacing = PropTypes.oneOf(/** @type {const} */ ([0, 1, 2, 3, 4, 5, "auto"]));

/**
 * All of the props that will apply margin to a component
 */
const MarginPropTypes = {
  /**
   * Adds margin all around the component
   */
  m: Spacing,
  /**
   * Adds margin to the bottom of the component
   */
  mb: Spacing,
  /**
   * Adds margin to the top of the component
   */
  mt: Spacing,
  /**
   * Adds margin to the left and right of the component
   */
  mx: Spacing,
  /**
   * Adds margin to the top and bottom of the component
   */
  my: Spacing,
  /**
   * Adds margin to the end component. This replaces the right and left for
   * better accessibility styling with left to right languages
   */
  me: Spacing,
  /**
   * Adds margin to the start component. This replaces the right and left for
   * better accessibility styling with left to right languages
   */
  ms: Spacing,
};

/**
 * All of the props that will apply margin to a component
 */
const PaddingPropTypes = {
  /**
   * Adds padding all around the component
   */
  p: Spacing,
  /**
   * Adds padding to the bottom of the component
   */
  pb: Spacing,
  /**
   * Adds padding to the top of the component
   */
  pt: Spacing,
  /**
   * Adds padding to the left and right of the component
   */
  px: Spacing,
  /**
   * Adds padding to the top and bottom of the component
   */
  py: Spacing,
};

const DisplayPropTypes = {
  flex: PropTypes.bool,
  justifyContentBetween: PropTypes.bool,
  alignItemsCenter: PropTypes.bool,
};

/**
 * All of the base props that can be added to a component
 */
export const BasePropTypes = { ...MarginPropTypes, ...DisplayPropTypes, ...PaddingPropTypes };

/**
 * All of the props that can be added to a box
 */
export const BoxPropTypes = {
  ...BasePropTypes,
  /**
   * The component or element that this box will be rendered as gives you the
   * ability to change this element from a div.
   *
   * @default {'div'}
   */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * The inner content of the box
   *
   * @type {React.ReactNode}
   */
  children: PropTypes.node,
};

/**
 * Map of all the classes and values to the relevant class names. The prop of
 * `m` and a value of `0` will result in the class of `m-0`. This map **MUST**
 * include the full class name. If the full class name is not in the source
 * code of the application, we will not be able to do fancy things like unused
 * CSS optimizations.
 */
const SPACING_CLASSES = {
  m: { 0: "m-0", 1: "m-1", 2: "m-2", 3: "m-3", 4: "m-4", 5: "m-5", auto: "m-auto" },
  mb: { 0: "mb-0", 1: "mb-1", 2: "mb-2", 3: "mb-3", 4: "mb-4", 5: "mb-5", auto: "mb-auto" },
  mt: { 0: "mt-0", 1: "mt-1", 2: "mt-2", 3: "mt-3", 4: "mt-4", 5: "mt-5", auto: "mt-auto" },
  mx: { 0: "mx-0", 1: "mx-1", 2: "mx-2", 3: "mx-3", 4: "mx-4", 5: "mx-5", auto: "mx-auto" },
  my: { 0: "my-0", 1: "my-1", 2: "my-2", 3: "my-3", 4: "my-4", 5: "my-5", auto: "my-auto" },
  me: { 0: "me-0", 1: "me-1", 2: "me-2", 3: "me-3", 4: "me-4", 5: "me-5", auto: "me-auto" },
  ms: { 0: "ms-0", 1: "ms-1", 2: "ms-2", 3: "ms-3", 4: "ms-4", 5: "ms-5", auto: "ms-auto" },

  p: { 0: "p-0", 1: "p-1", 2: "p-2", 3: "p-3", 4: "p-4", 5: "p-5", auto: "p-auto" },
  pb: { 0: "pb-0", 1: "pb-1", 2: "pb-2", 3: "pb-3", 4: "pb-4", 5: "pb-5", auto: "pb-auto" },
  pt: { 0: "pt-0", 1: "pt-1", 2: "pt-2", 3: "pt-3", 4: "pt-4", 5: "pt-5", auto: "pt-auto" },
  px: { 0: "px-0", 1: "px-1", 2: "px-2", 3: "px-3", 4: "px-4", 5: "px-5", auto: "px-auto" },
  py: { 0: "py-0", 1: "py-1", 2: "py-2", 3: "py-3", 4: "py-4", 5: "py-5", auto: "py-auto" },

  w: { 100: "w-100" },
};

const DISPLAY_CLASSES = {
  flex: "d-flex",
  justifyContentBetween: "justify-content-between",
  alignItemsCenter: "align-items-center",
  textRight: "text-right",
  textLeft: "text-left",
};

function addSpacingClasses(classList, prop, props) {
  if (typeof SPACING_CLASSES[prop] !== "undefined") {
    classList.add(SPACING_CLASSES[prop][props[prop]]);
    delete props[prop];
  }
}

function addDisplayClasses(classList, prop, props) {
  if (typeof DISPLAY_CLASSES[prop] !== "undefined" && props[prop]) {
    classList.add(DISPLAY_CLASSES[prop]);
    delete props[prop];
  }
}

/**
 * Converts a props object into a string that can be used in the `className` of
 * a component. It will look for all the common props we are allowing on a
 * component.
 *
 * @param {Record<string, string | number} props
 *
 * @returns {string}
 */
export function classNamesFromProps(props) {
  const classList = new Set();

  for (const prop in props) {
    addSpacingClasses(classList, prop, props);
    addDisplayClasses(classList, prop, props);
  }

  return Array.from(classList.values()).join(" ").trim();
}

/**
 * The Market Dojo box component
 *
 * @type {React.FC<PropTypes.InferProps<typeof BoxPropTypes>>}
 */
export const Box = ({ children, as, ...props }) => {
  const Component = as || "div";
  props.className = classNamesFromProps(props);

  return <Component {...props}> {children} </Component>;
};

Box.propTypes = BoxPropTypes;

export default Box;
