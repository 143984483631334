import React, { Component } from "react";
import { Pagination } from "react-bootstrap";

function PaginationContainer(props) {
  const { activePage, pages, changePage } = props;
  const items = [];
  let itemsCount = 0;
  /** previous page button start* */
  items.push(
    activePage === 1 ? (
      <Pagination.Prev disabled key='prevBtn' />
    ) : (
      <Pagination.Prev
        key='prevBtn'
        onClick={ () => changePage(activePage - 1) }
        disabled={ activePage === 1 }
      />
    )
  );
  /** previous page button end * */

  /** First page button start * */
  items.push(
    <Pagination.Item
      key={ 1 }
      active={ activePage === 1 }
      onClick={ () => changePage(1) }
      activeLabel={props.activeLabel}
    >
      {1}
    </Pagination.Item>
  );
  /** First page button end * */

  if (pages > 2) {
    /** Ellipsis to indicate previous pages * */
    if (activePage > 4) {
      items.push(<Pagination.Ellipsis key='ellipseStart' />);
    }

    for (let number = 2; number <= pages - 1; number += 1) {
      if (activePage <= 4 && itemsCount < 3) {
        items.push(
          <Pagination.Item
            key={ number }
            active={ number === activePage }
            onClick={ () => changePage(number) }
            activeLabel={props.activeLabel}
          >
            {number}
          </Pagination.Item>
        );
      } else if (activePage > pages - 2 && number > pages - 3) {
        items.push(
          <Pagination.Item
            key={ number }
            active={ number === activePage }
            onClick={ () => changePage(number) }
            activeLabel={props.activeLabel}
          >
            {number}
          </Pagination.Item>
        );
      } else if (
        (activePage > 4 || activePage < pages - 3) &&
        (number > activePage - 3 && number < activePage + 2)
      ) {
        items.push(
          <Pagination.Item
            key={ number }
            active={ number === activePage }
            onClick={ () => changePage(number) }
            activeLabel={props.activeLabel}
          >
            {number}
          </Pagination.Item>
        );
      }
      itemsCount += 1;
    }

    /** Ellipsis to indicate next pages * */
    if (activePage < pages - 3) {
      items.push(<Pagination.Ellipsis key='ellipseNext' />);
    }
  }

  /** Last page button start * */
  items.push(
    <Pagination.Item
      key={ pages }
      active={ activePage === pages }
      onClick={ () => changePage(pages) }
      activeLabel={props.activeLabel}
    >
      {pages}
    </Pagination.Item>
  );
  /** Last page button start * */

  /** Next page button start* */
  items.push(
    activePage === pages ? (
      <Pagination.Next disabled key='nextBtn' />
    ) : (
      <Pagination.Next
        key='nextBtn'
        onClick={ () => changePage(activePage + 1) }
        disabled={ activePage === pages }
      />
    )
  );
  
  /** Next page button end* */
  return (
    <Pagination className='users-pagination pull-right' size='medium'>
      {items}
    </Pagination>
  );
}

export default PaginationContainer;
