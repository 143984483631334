interface EnumLike {
  [key: string]: string | number;
  [index: number]: string;
}

// filter out reverse mappings
const getValidEnumValues = (obj: EnumLike) => Object.values(obj).filter((v) => typeof obj[v] !== "number");

export const inEnum = <Enum extends EnumLike>(obj: Enum, value: string | number): value is Enum[keyof Enum] =>
  getValidEnumValues(obj).includes(value);
