import { event } from "jquery";
import React, { useState } from "react";
import Modal from "react-modal";
import { isReverseDirection } from "../common";
import GraphComponent from "./GraphComponent";
import { ModalLotTitle } from "../bids/ProxyBidButtonAndModal";

const HostProgressionChartModal = (props) => {
  const { isOpen, setIsOpen, translations, event, modalTitle } = props;
  const { participant_progression_html, horse_race_html, best_bid_progression_html, difference_progression_html } =
    props.translations;
  const savingOrProfit = () => {
    return isReverseDirection(event) ? translations.savings : translations.profit;
  };
  const difference_progression = difference_progression_html.replace("%{difference}", savingOrProfit());

  const tabs = [participant_progression_html, horse_race_html, best_bid_progression_html, difference_progression];
  const [tab] = tabs;
  const [activeTab, setActiveTab] = useState(tab);

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="modal-content col-md-6 col-sm-6 custom-field-popup"
        appElement={document.getElementById("advanced-lots-body")}
      >
        <ModalLotTitle suffix={translations.current_bids} onClose={() => setIsOpen(false)} name={modalTitle} />
        <div className="content special-tab-content tab-status-current lg-tab-list">
          <ul className="nav nav-tabs">
            {tabs.map((tab, idx) => (
              <li key={idx} className={tab === activeTab ? "active" : ""} onClick={() => setActiveTab(tab)}>
                <a>{tab}</a>
              </li>
            ))}
          </ul>
        </div>
        <GraphComponent
          {...props}
          type={"hostMonitor"}
          activeTab={activeTab}
          tabs={tabs}
          savingOrProfit={savingOrProfit}
        />
      </Modal>
    </>
  );
};

export default HostProgressionChartModal;
