import React from "react";
import clsx from "clsx";
import { t } from "@/i18n";
import RenderFields from "./render-fields";
import type { CustomFieldsTableProps } from "./custom-fields-table";
import type { Field } from ".";

interface CustomFieldListProps extends CustomFieldsTableProps {}

export type CustomFieldProps = Pick<
  CustomFieldListProps,
  "showPage" | "componentName" | "hideTableHeader" | "readOnly"
> & { customField: Field };

type RequiredTableCellProps = Pick<CustomFieldProps, "componentName" | "hideTableHeader" | "showPage" | "customField">;

const getClassNames = (componentName: string, hideTableHeader?: boolean, noEllipsis: boolean = false) => {
  const isCreateEditContainer = componentName === "CreateEditContainer";

  return clsx({
    "vertical-align-middle": isCreateEditContainer,
    "table-cell-ellipsis": !isCreateEditContainer && !noEllipsis,
    "border-none": hideTableHeader,
  });
};

const RequiredTableCell: React.FC<RequiredTableCellProps> = (props) => {
  const { showPage, customField, componentName, hideTableHeader } = props;
  if (showPage) return null;

  return (
    <td id={`required_${customField.id}`} className={getClassNames(componentName, hideTableHeader, true)}>
      <RequiredCell customField={customField} />
    </td>
  );
};

const RequiredCell = ({ customField }: { customField: Field }) => {
  if (!customField.required) return null;

  return (
    <span id={`error_${customField.id}`} className="col-4 required width-fit">
      *
    </span>
  );
};

const CustomField: React.FC<CustomFieldProps> = (props) => {
  const { customField, componentName, hideTableHeader, showPage, ...rest } = props;
  const borderNone = hideTableHeader ? "border-none" : "";

  return (
    <tr>
      <td
        className={getClassNames(componentName, hideTableHeader)}
        title={customField.name}
        id={`custom-field-${customField.id}-name`}
      >
        {t(customField.name) || customField.name}
      </td>
      <td className={borderNone}>
        <RenderFields showPage={showPage} customField={customField} {...rest} />
      </td>
      <RequiredTableCell
        showPage={showPage}
        customField={customField}
        componentName={componentName}
        hideTableHeader={hideTableHeader}
      />
    </tr>
  );
};

const CustomFieldList: React.FC<CustomFieldListProps> = (props) => {
  const { showPage, customFields, ...rest } = props;

  return customFields.map((customField) => {
    const fieldShow = !showPage && (customField.active || customField.custom_column_values[0].name);
    const fieldCustomColumn = customField.custom_column_values.length && showPage;

    if (fieldShow || fieldCustomColumn) {
      return <CustomField key={customField.id} showPage={showPage} customField={customField} {...rest} />;
    }
  });
};

export default CustomFieldList;
