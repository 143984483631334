import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";
import { isIE, triStateDisplay, companyNameOrEmail, online, notDeclinedForEvent } from "../../common";
import {
  participantActiveInJapaneseAuction,
  thisParticipantBidOnThisRound,
  validBidsDuringAuction,
} from "../../bidCommon";
import LeadParticipantModal from "./modal/LeadParticipantModal";
import SendMessageModal from "../../../messages/sendMessageModal";
import { useDispatch, useSelector } from "react-redux";
import { showMessageModal } from "../../../../actions/messageActions";
import TableCell from "@/components/common/TableCell";

const ActiveParticipants = (props) => {
  const {
    lot,
    event,
    participants,
    translations,
    eventParticipants,
    lots,
    timeZoneIdentifier,
    bids,
    permissionToView,
    participatingParticipants,
    permittedToEdit,
    currenciesHash,
    locale,
    deleteBid,
    eventPassedFinalDeadline,
    currency,
    isMultiCurrencyEvent,
    hosts,
    lotComponents,
    lineItems,
    lineItemComponents,
    bidLineItemComponents,
    allUnitSets,
    unitOfMeasures,
    LBids,
    bidControls,
    auctionControls,
    bidRound,
    isJapaneseEvent,
    role,
    currentUser,
    unitSetUoms,
    picklistOptions,
    auctionTab,
    canShowDeleteBtn,
    modalCloseFunction,
    bestBidsBeforeAuction,
    bestBidBeforeAuction,
    hostPermission,
    inSandpit,
    remainingParticipantsForJapaneseEvent,
  } = props;
  const documents = useSelector(({ lotReducers }) => lotReducers.documentsData?.documents);
  const allUom = [...unitSetUoms, ...unitOfMeasures];
  const { ep_send_message, ep_user, ep_participant_bidding, lot_bid_round, ep_participant_bid_count, ep_user_online } =
    translations.infos;

  const participatingUserIds = participatingParticipants.map((participant) => participant.user_id);
  const isCurrentUserHost = role === "Host";
  const replyUserId = isCurrentUserHost ? null : event.user_id;
  const validEp = notDeclinedForEvent(eventParticipants);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [participatingUsers, setParticipatingUsers] = useState(
    participants.filter((participant) => participatingUserIds.includes(participant.id))
  );
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const sendMessageModalId = "activeParticipantsSendMessageModal";

  const dispatch = useDispatch();

  const activeParticipantsCount = uniq(
    validBidsDuringAuction(event, participatingParticipants, bids).map((bid) => bid.user_id)
  ).length;

  const handleShowAll = () => {
    const userIds = validEp.map((ep) => ep.user_id);
    const part = participants.filter((participant) => userIds.includes(participant.id));
    if (participatingUsers.length < participants.length)
      setParticipatingUsers(uniq([...participatingUsers, ...part], isEqual));
  };

  const handleClosePopUp = () => {
    setParticipatingUsers(participants.filter((participant) => participatingUserIds.includes(participant.id)));
    setModalIsOpen(false);
  };
  const participatingAndClassName = () => {
    let className = "";
    let participating = "";

    const handleChange = (e) => {
      e.persist();
      const selectedId = parseInt(e.target.value);
      const isChecked = Boolean(e.target.checked);
      setSelectedParticipants((selected) =>
        isChecked ? [...selected, selectedId] : selected.filter((id) => id !== selectedId)
      );
    };

    return participatingUsers.map((user) => {
      const participant = eventParticipants.find((part) => part.user_id === user.id);

      const userBids = (participant && validBidsDuringAuction(event, [participant], LBids).length) || 0;
      if (event.event_type == "Japanese") {
        if (userBids >= bidRound || thisParticipantBidOnThisRound(LBids, user.id, bidRound)) {
          className = "active";
          participating = triStateDisplay(true, translations);
        } else if (participantActiveInJapaneseAuction(event, remainingParticipantsForJapaneseEvent[lot.id], user.id)) {
          className = "active";
          participating = translations.waiting_html;
        } else {
          className = "inactive";
          participating = triStateDisplay(false, translations);
        }
      } else {
        if (userBids == 0) {
          className = "inactive";
          participating = triStateDisplay(false, translations);
        } else {
          className = "";
          participating = triStateDisplay(true, translations);
        }
      }
      const companyNameEmail = companyNameOrEmail(user);
      return (
        <tr className={className} key={`active-participant-${user.id}`}>
          {permittedToEdit ? (
            <td>
              <label className="css-input css-checkbox css-checkbox-default">
                <input
                  type="checkbox"
                  name={`message_${user.id}`}
                  value={user.id}
                  onChange={handleChange}
                  checked={selectedParticipants.includes(user.id)}
                ></input>
                <span></span>
              </label>
            </td>
          ) : null}

          <td>
            <LeadParticipantModal
              viewComponent={companyNameEmail}
              header={companyNameEmail}
              event={event}
              participant={participant}
              participants={participants}
              lots={lots}
              translations={translations}
              user={user}
              timeZoneIdentifier={timeZoneIdentifier}
              bidRound={bidRound}
              bids={bids}
              participatingParticipants={participatingParticipants}
              permittedToEdit={permittedToEdit}
              currenciesHash={currenciesHash}
              locale={locale}
              deleteBid={deleteBid}
              modalCloseFunction={modalCloseFunction}
              eventPassedFinalDeadline={eventPassedFinalDeadline}
              permissionToView={permissionToView}
              isMultiCurrencyEvent={isMultiCurrencyEvent}
              currency={currency}
              hosts={hosts}
              lotComponents={lotComponents}
              lineItems={lineItems}
              lineItemComponents={lineItemComponents}
              bidLineItemComponents={bidLineItemComponents}
              bidControls={bidControls}
              allUnitSets={allUnitSets}
              allUom={allUom}
              LBids={LBids}
              unitOfMeasures={unitOfMeasures}
              auctionControls={auctionControls}
              isJapaneseEvent={isJapaneseEvent}
              role={role}
              currentUser={currentUser}
              unitSetUoms={unitSetUoms}
              eventParticipants={eventParticipants}
              picklistOptions={picklistOptions}
              auctionTab={auctionTab}
              canShowDeleteBtn={canShowDeleteBtn}
            />
          </td>
          <td>{participating}</td>
          <td>{userBids}</td>
          <td>{triStateDisplay(!!online(user), translations)}</td>
          <td></td>
        </tr>
      );
    });
  };

  return (
    <>
      <a onClick={() => setModalIsOpen(true)} data-testid="activeParticipantsCount">
        {activeParticipantsCount}
      </a>
      {modalIsOpen && (
        <Modal
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={() => handleClosePopUp()}
          className={classNames({
            "modal-content col-md-4 col-sm-4 custom-field-popup min-700": true,
            "modal2-ie": isIE(),
          })}
        >
          <div className="block block-transparent remove-margin-b">
            <div className="block-header bg-primary-default">
              <ul className="block-options">
                <li>
                  <button type="button" onClick={() => handleClosePopUp()}>
                    <i className="ion-close" />
                  </button>
                </li>
              </ul>
              <h3 className="block-title">{`${lot.name} - ${translations.current_participants}`}</h3>
            </div>
            <div className="modal-body p-l15 p-r15">
              <div className="m-0 row">
                <div className="clear-padding height-auto max-h400 scrolling_inner">
                  {!participants.length || !participatingUsers.length ? (
                    <p className="no_data m-r10">{translations.no_participants}</p>
                  ) : (
                    <table className="current-participants-list event-list m-b0 table table-cell-ellipsis max-w-none">
                      <thead>
                        <tr>
                          <TableCell
                            text={translations.sendMessage}
                            title={ep_send_message.title}
                            body={ep_send_message.body}
                            hidden={!permittedToEdit}
                          />

                          <TableCell text={translations.participant_name} title={ep_user.title} body={ep_user.body} />

                          <TableCell
                            text={translations.bidding}
                            title={ep_participant_bidding.title}
                            body={ep_participant_bidding.body}
                          />

                          {event.event_type == "Japanese" ? (
                            <TableCell
                              text={translations.bid_round_accepted}
                              title={lot_bid_round.title}
                              body={lot_bid_round.body}
                            />
                          ) : (
                            <TableCell
                              text={translations.bids}
                              title={ep_participant_bid_count.title}
                              body={ep_participant_bid_count.body}
                            />
                          )}

                          <TableCell
                            text={translations.logged_on}
                            title={ep_user_online.title}
                            body={ep_user_online.body}
                          />

                          <td>
                            {event.event_type == "Japanese" && !lot.is_event_total ? translations.reaccept : null}
                          </td>
                        </tr>
                      </thead>

                      <tbody>{participatingAndClassName()}</tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            <div className="pop-footer modal-footer">
              <div className="col-md-6 btn-group-lg">
                {participatingUsers.length !== validEp.length ? (
                  <button
                    id="show_all"
                    className="btn btn-sm btn-default pull-left"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => handleShowAll()}
                  >
                    {translations.show_all}
                  </button>
                ) : null}
              </div>
              {permittedToEdit ? (
                <div className="col-md-6 btn-group-lg">
                  <button
                    className="btn btn-sm btn-default"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => handleClosePopUp()}
                  >
                    {translations.cancel}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => dispatch(showMessageModal(true, null, replyUserId, sendMessageModalId))}
                  >
                    {translations.sendMessage}
                  </button>
                </div>
              ) : null}
            </div>
            <SendMessageModal
              documents={documents}
              hasPermissionToEdit={permittedToEdit}
              marketDojo
              selectedParticipants={selectedParticipants}
              setSelectedParticipants={setSelectedParticipants}
              htmlId={sendMessageModalId}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ActiveParticipants;
