// Module to help with feature detection in javascript
//

/**
 * Gets all of the features that have been set in the `__md_data` global
 * variable.
 *
 */
const getFeatures = () => {
  if (
    "__md_data" in window &&
    typeof window.__md_data === "object" &&
    window.__md_data &&
    "features" in window.__md_data &&
    typeof window.__md_data.features === "object"
  ) {
    return Object(window.__md_data.features) as Record<string, boolean>;
  }

  return {};
};

/**
 * Get the value of a single feature flag. If the feature has not been defined
 * in the `__md_data` then it is set to "OFF"
 *
 */
const getFeature = (feature: string) => {
  const features = getFeatures();
  if (feature in features) {
    return features[feature];
  }

  return false;
};

const enabled = (feature: string) => getFeature(feature);

const disabled = (feature: string) => !getFeature(feature);

export default { enabled, disabled };
