import React, { Component } from "react";
import Tooltip from "../../../common/Tooltip";
import Select2 from "react-select2-wrapper";
import NotifyBeforeOnboardingComplete from "./NotifyBeforeOnboardingComplete";
class ExpiredDocuments extends Component {
  componentDidUpdate() {
    this.setValue();
  }

  setValue = () => {
    this.expiredDocExpiry.value =
      this.props.configuration_setting_values.expired_document_period_of_expiry.split(".")[0];
    this.expiredDocFreqNotif.value =
      this.props.configuration_setting_values.doc_expiry_reminder_interval.split(".")[0];
  };

  expiry = (e) => {
    let temp =
      +this.expiredDocExpiry.value +
      "." +
      (e.target.name == "expiredDocExpiryUnit" ? e.target.value : this.expiredDocExpiryUnit.props.value);
    if (temp == this.props.configuration_setting_values.expired_document_period_of_expiry) {
      return false;
    }
    this.props.onValueChange(temp, "expired_document_period_of_expiry");
  };

  expiryNotification = (e) => {
    let temp =
      +this.expiredDocFreqNotif.value +
      "." +
      (e.target.name == "expiredDocFreqNotifUnit" ? e.target.value : this.expiredDocFreqNotifUnit.props.value);
    if (temp == this.props.configuration_setting_values.doc_expiry_reminder_interval) {
      return false;
    }
    this.props.onValueChange(temp, "doc_expiry_reminder_interval");
  };
  render() {
    const { translations, durationType, configuration_setting_values, currentUserEditConfigPermission } = this.props;
    return (
      <div className="col-md-6 form-item-block wid-49">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="form-item-block-title">{translations.admin_views.configuration.expired_documents}</div>
            <div className="input inline m-b10">
              <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">{translations.notify_participants}</label>
              <input
                ref={(ref) => (this.expiredDocExpiry = ref)}
                type="text"
                name="expired_document_period_of_expiry"
                id="expired_document_period_of_expiry"
                defaultValue={configuration_setting_values.expired_document_period_of_expiry.split(".")[0]}
                className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
                size="5"
                onBlur={(e) => this.expiry(e)}
                disabled={!currentUserEditConfigPermission}
              />
              <div className="col-md-3 col-sm-3 col-xs-9">
                <Select2
                  ref={(ref) => (this.expiredDocExpiryUnit = ref)}
                  className="duration_type form-control"
                  name="expiredDocExpiryUnit"
                  value={configuration_setting_values.expired_document_period_of_expiry.split(".")[1]}
                  data={durationType}
                  onSelect={(e) => this.expiry(e)}
                  disabled={!currentUserEditConfigPermission}
                />
              </div>
              <div className="vertical-center">{translations.before_expiry}.</div>
              <div className="vertical-center">
                <Tooltip {...translations.configuration.expired_document_period_of_expiry} />
              </div>
            </div>
            <div className="input inline m-b10">
              <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">
                {translations.notify_participants_every}
              </label>
              <input
                ref={(ref) => (this.expiredDocFreqNotif = ref)}
                type="text"
                name="doc_expiry_reminder_interval"
                id="doc_expiry_reminder_interval"
                defaultValue={configuration_setting_values.doc_expiry_reminder_interval.split(".")[0]}
                className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
                size="5"
                onBlur={(e) => this.expiryNotification(e)}
                disabled={!currentUserEditConfigPermission}
              />
              <div className="col-md-3 col-sm-3 col-xs-9">
                <Select2
                  ref={(ref) => (this.expiredDocFreqNotifUnit = ref)}
                  className="duration_type form-control"
                  name="expiredDocFreqNotifUnit"
                  value={configuration_setting_values.doc_expiry_reminder_interval.split(".")[1]}
                  disabled={!currentUserEditConfigPermission}
                  data={durationType}
                  onSelect={(e) => this.expiryNotification(e)}
                />
              </div>
              <div className="vertical-center">
                <Tooltip {...translations.configuration.doc_expiry_reminder_interval} />
              </div>
            </div>
            <NotifyBeforeOnboardingComplete
              translations={translations}
              configuration_setting_values={configuration_setting_values}
              currentUserEditConfigPermission={currentUserEditConfigPermission}
              onValueChange = {this.props.onValueChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ExpiredDocuments;
