import { useCallback, useMemo } from "react";
import type { CheckedItem, Item, StackItem } from "./types";
import { useItems } from "./items-context";

interface UseChildChange {
  /**
   * Current stack item
   */
  stackItem: StackItem;
  /**
   * Checked items from the current stack item
   */
  checkedChildItems: CheckedItem[];
}

/**
 * Check if the item is parent item for a given stack item
 */
export const isParentItem = (childItem: CheckedItem, stackItem: StackItem) => {
  return childItem.item.value === stackItem.parent.value && childItem.item.type === stackItem.parent.type;
};

/**
 * Check if parent item is checked for a given stack item
 */
export const isParentChecked = (stackItem: StackItem, checkedChildItems: CheckedItem[]) => {
  return checkedChildItems.some((checkedItem) => isParentItem(checkedItem, stackItem));
};

/**
 * Check if we need to add an indeterminate state to the checkbox.
 * The `checkedItems` variable is restricted to the checked items within the
 * current stack. This filtering logic is implemented in the
 * `useCheckedChildItems` hook.
 * @see useCheckedChildItems
 */
export const isIndeterminate = (areAllSelected: boolean, checkedItems: CheckedItem[]) => {
  return !areAllSelected && checkedItems.length > 0;
};

/**
 * Check if the given child item belongs to the given stack item
 */
export const belongsToSameGroup = (childItem: CheckedItem, stackItem: StackItem) => {
  return childItem.group === stackItem.parent.label;
};

export const useCheckedChildItems = (stackItem: StackItem) => {
  const { checkedItems } = useItems();
  return useMemo(
    () => ({
      checkedChildItems: checkedItems.filter((checkedItem) => belongsToSameGroup(checkedItem, stackItem)),
      otherCheckedItems: checkedItems.filter((checkedItem) => !belongsToSameGroup(checkedItem, stackItem)),
    }),
    [checkedItems, stackItem]
  );
};

export const useParentSelect = (stackItem: StackItem) => {
  const { checkedChildItems } = useCheckedChildItems(stackItem);
  const parentChecked = isParentChecked(stackItem, checkedChildItems);
  const indeterminate = isIndeterminate(parentChecked, checkedChildItems);

  return { parentChecked, indeterminate };
};

const remainingCheckedItems = (stackItem: StackItem, childItem: Item) => {
  const parentLabel = stackItem.parent.label;
  return stackItem.items.reduce<CheckedItem[]>((checkedItems, item) => {
    if (item.value !== childItem.value) {
      checkedItems.push({ group: parentLabel, item: item });
    }
    return checkedItems;
  }, []);
};

const useChildChange = ({ stackItem, checkedChildItems }: UseChildChange) => {
  const parentLabel = stackItem.parent.label;
  const isThisTheLastChild = stackItem.items.length === checkedChildItems.length + 1;
  const selectChildItem = useCallback(
    (childItem: Item) => {
      // Just return the parent item if this is the last child, otherwise return
      // all the selected child for this stack
      return isThisTheLastChild
        ? [{ group: parentLabel, item: stackItem.parent }]
        : [...checkedChildItems, { group: parentLabel, item: childItem }];
    },
    [checkedChildItems, stackItem.parent, isThisTheLastChild, parentLabel]
  );

  const deselectChildItem = useCallback(
    (childItem: Item) => {
      if (isParentChecked(stackItem, checkedChildItems)) {
        // return all the children except the current one if parent is checked
        return remainingCheckedItems(stackItem, childItem);
      }
      return checkedChildItems.filter((checkedItem) => checkedItem.item.value !== childItem.value);
    },
    [stackItem, checkedChildItems]
  );

  return { selectChildItem, deselectChildItem };
};

export const useSelectItem = (stackItem: StackItem) => {
  const { setCheckedItems } = useItems();
  const { checkedChildItems, otherCheckedItems } = useCheckedChildItems(stackItem);
  const { selectChildItem, deselectChildItem } = useChildChange({ stackItem, checkedChildItems });
  const parentLabel = stackItem.parent.label;

  const onParentChange = (checked: boolean) => {
    const itemsToBePushed = checked
      ? [...otherCheckedItems, { group: parentLabel, item: stackItem.parent }]
      : [...otherCheckedItems];
    setCheckedItems(itemsToBePushed);
  };

  const onChildChange = (childItem: Item, checked: boolean) => {
    const itemsToBePushed = checked ? selectChildItem(childItem) : deselectChildItem(childItem);
    setCheckedItems([...otherCheckedItems, ...itemsToBePushed]);
  };

  return { onParentChange, onChildChange };
};
