import React, { Component } from "react";
import Select2 from "react-select2-wrapper";
import Tooltip from "../../../common/Tooltip";

class CloseEvent extends Component {
  componentDidUpdate() {
    this.setValue();
  }

  setValue = () => {
    this.timeForEventAfterDeadline.value =
      this.props.configuration_setting_values.time_events_stay_open_after_final_deadline.split(".")[0];
  };

  timeForEventAfterDeadlineUpdate = (e) => {
    let temp =
      +this.timeForEventAfterDeadline.value +
      "." +
      (e.target.name == "timeForEventAfterDeadlineUnit"
        ? e.target.value
        : this.timeForEventAfterDeadlineUnit.props.value);

    if (temp == this.props.configuration_setting_values.time_events_stay_open_after_final_deadline) {
      return false;
    }
    this.props.onValueChange(temp, "time_events_stay_open_after_final_deadline");
  };
  render() {
    const { translations, durationType, configuration_setting_values } = this.props;
    return (
      <div className="form-item-block">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="form-item-block-title">{translations.close_event}</div>
            <div className="input inline m-b10">
              <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">
                {translations.time_events_stay_open_after_final_deadline}
              </label>
              <input
                ref={(ref) => (this.timeForEventAfterDeadline = ref)}
                type="text"
                name="time_events_stay_open_after_final_deadline"
                id="time_events_stay_open_after_final_deadline"
                defaultValue={configuration_setting_values.time_events_stay_open_after_final_deadline.split(".")[0]}
                className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
                size="5"
                onBlur={(e) => this.timeForEventAfterDeadlineUpdate(e)}
              />
              <div className="col-md-2 col-sm-3 col-xs-9">
                <Select2
                  ref={(ref) => (this.timeForEventAfterDeadlineUnit = ref)}
                  className="duration_type form-control"
                  name="timeForEventAfterDeadlineUnit"
                  value={configuration_setting_values.time_events_stay_open_after_final_deadline.split(".")[1]}
                  data={durationType}
                  onSelect={(e) => this.timeForEventAfterDeadlineUpdate(e)}
                />
              </div>
              <div className="vertical-center">
                <Tooltip {...translations.configuration.time_events_stay_open_after_final_deadline_tooltip} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CloseEvent;
