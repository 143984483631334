// @ts-check
import { Controller } from "@hotwired/stimulus";
import { assert } from "@/utils/assert";

export default class extends Controller {
  static targets = ["filter", "item"];

  /**
   * Preform all the filtering.
   *
   * This can be used as an action when the filter inputs change. For example
   * you can add `data-action="filter#doFiltering"` to an input element to
   * trigger the filtering when the input changes.
   */
  doFiltering() {
    this.#getItems().forEach((element) => this.#filterElement(element));
  }

  /**
   * Callback when a item is connected into the dom. This will ensure the
   * element is filtered when its dynamically added, with turbo streams for
   * example.
   *
   * @param {HTMLElement} element The element you want to filter on
   */
  itemTargetConnected(element) {
    this.#filterElement(element);
  }

  /**
   * Returns all the item ensuring we have them. Helper function to get over
   * stimulus magic
   */
  #getItems() {
    assert("itemTargets" in this && this.itemTargets instanceof Array);
    return this.itemTargets;
  }

  /**
   * Returns all the filter elements ensuring we have them Helper function to
   * get over stimulus magic
   */
  #getFilters() {
    assert("filterTargets" in this && this.filterTargets instanceof Array);
    return this.filterTargets;
  }

  /**
   * @param {HTMLElement} element The element you want to filter on
   */
  #filterElement(element) {
    this.#filterMatches(element) ? element.classList.remove("hidden") : element.classList.add("hidden");
  }

  /**
   * @param {HTMLElement} element The element you want to filter on
   */
  #filterMatches(element) {
    return this.#getFilters().some((filter) => {
      switch (filter.type) {
        case "checkbox":
          const attribute = filter.dataset.attribute;
          const value = this.#isTruthy(element.dataset[attribute]);
          return filter.checked ? value : true;

        default:
          throw new Error(`Unknown filter type: ${filter.type}`);
      }
    });
  }

  /**
   * @param {String | undefined} value
   */
  #isTruthy(value) {
    if (value === "true") return true;
    if (value === "false") return false;

    return value;
  }
}
