import type { Draft } from "immer";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, isAnyOf, prepareAutoBatched } from "@reduxjs/toolkit";
import { v4 } from "uuid";
import { createAppSlice } from "@/slices/util/createAppSlice";
import { withCustomCreators } from "@/slices/util/customCreators";
import { Comparison, checkNode, emptyParameters } from "./types";
import type { NormalizedNode, Node, NormalizedTree, Operation, MultiKind } from "./types";
import type { AppThunk, RootState } from "@/store";
import type { NodeValidationMap } from "@/lib/rules/validate";
import { validateNode } from "@/lib/rules/validate";
import {
  normalizeNode,
  denormalizeNode,
  removeCondition,
  addCondition,
  applyMultiKind,
  makeLeaf,
  expandParents,
} from "@/lib/rules";
import { assert } from "@/utils/assert";
import type { Field } from "./fields";
import { toggleKey } from "@/utils";
import type { Compute } from "@/types/util";

export interface NormalizedRules {
  rootId: string | null;
  nodes: NormalizedTree;
}

interface RulesState extends NormalizedRules {
  issues: NodeValidationMap;
  collapsed: Partial<Record<string, true>>;
}

const initialState: RulesState = {
  rootId: null,
  nodes: {},
  issues: {},
  collapsed: {},
};

type WithId<T> = Compute<T & { id: string }>;

const updateNodeIfExists =
  <Action extends { payload: { id: string } }>(recipe: (node: Draft<NormalizedNode>, action: Action) => void) =>
  (state: RulesState, action: Action) => {
    const node = state.nodes[action.payload.id];
    assert(node, `Node ${action.payload.id} not found`);
    recipe(node, action);
  };

const getRulesState = (payload: Node | null): RulesState =>
  payload ? { ...initialState, rootId: payload.uuid, nodes: normalizeNode(payload) } : initialState;

export const rulesSlice = createAppSlice({
  name: "rules",
  initialState,
  reducers: withCustomCreators(
    (create) => ({
      /** A reducer which updates a specific node, based on `action.payload.id`. Defaults to being batchable. */
      nodeReducer: <Payload extends Record<string, unknown>>(
        recipe: (node: Draft<NormalizedNode>, action: PayloadAction<WithId<Payload>>) => void,
        { batch = true }: { batch?: boolean } = {}
      ) =>
        create.preparedReducer(
          batch ? prepareAutoBatched() : (payload: WithId<Payload>) => ({ payload }),
          updateNodeIfExists(recipe)
        ),
    }),
    (create) => ({
      ruleModalOpened: create.reducer<Node | null>((state, { payload }) => getRulesState(payload)),
      ruleModalClosed: create.reducer(() => initialState),
      rulesReset: create.reducer(() => initialState),
      rulesInitialised: create.preparedReducer(
        () => ({ payload: makeLeaf() }),
        (state, { payload }) => getRulesState(payload)
      ),
      conditionAdded: create.preparedReducer(
        // create our IDs in the action creator so the reducer remains pure and deterministic
        (currentId: string) => ({ payload: { currentId, newSiblingId: v4(), newParentId: v4() } }),
        (state, { payload }) => {
          addCondition(state, payload);
          expandParents(state, payload.newSiblingId);
        }
      ),
      conditionRemoved: create.reducer<string>((state, { payload }) => {
        if (state.rootId === payload) {
          state.issues = {};
          state.collapsed = {};
        }

        removeCondition(state, payload);

        delete state.issues[payload];
        delete state.collapsed[payload];
        const parentUuid = state.nodes[payload]?.parentUuid;
        // when we remove a condition, its parent branch also gets removed
        if (parentUuid) {
          delete state.issues[parentUuid];
          delete state.collapsed[parentUuid];
        }
      }),
      operationChanged: create.nodeReducer<{ operation: Operation; negated: boolean }>((node, { payload }) => {
        assert(checkNode.isBranch(node), "Node is not a branch");
        node.state.operation = payload.operation;
        node.negated = payload.negated;
      }),
      fieldChanged: create.nodeReducer<{ field: string; fields: Record<string, Field> }>(
        (node, { payload }) => {
          assert(checkNode.isLeaf(node), "Node is not a leaf");
          const fieldData = payload.fields[payload.field];
          assert(fieldData, "Field not found");
          node.negated = false;
          node.state.field_name = payload.field;
          node.state.comparison = Comparison.Eq;
          assert(fieldData.type in emptyParameters, `Unknown field type: ${fieldData.type}`);
          node.state.leaf_parameter = emptyParameters[fieldData.type];
        },
        { batch: false }
      ),
      checkboxChanged: create.nodeReducer<{ value: boolean }>((node, { payload }) => {
        assert(checkNode.isLeaf.withCheckbox(node), "Node is not a leaf or does not have a checkbox parameter");
        node.state.leaf_parameter.target_selection = payload.value;
      }),
      comparisonChanged: create.nodeReducer<{ comparison: Comparison }>((node, { payload }) => {
        assert(checkNode.isLeaf(node), "Node is not a leaf");
        node.state.comparison = payload.comparison;
        delete node.state.meta?.multi_kind;
      }),
      numberChanged: create.nodeReducer<{ value: number }>((node, { payload }) => {
        assert(checkNode.isLeaf.withNumber(node), "Node is not a leaf or does not have a number parameter");
        node.state.leaf_parameter.target_figure = payload.value;
      }),
      dateChanged: create.nodeReducer<{ value: string }>((node, { payload }) => {
        assert(checkNode.isLeaf.withDate(node), "Node is not a leaf or does not have a date parameter");
        node.state.leaf_parameter.target_date = payload.value;
      }),
      negationChanged: create.nodeReducer<{ negated: boolean }>((node, { payload }) => {
        node.negated = payload.negated;
      }),
      picklistChanged: create.nodeReducer<{ value: string }>((node, { payload }) => {
        assert(checkNode.isLeaf.withText(node), "Node is not a leaf or does not have a text parameter");
        node.state.leaf_parameter.parameters[0] = payload.value;
      }),
      multiValuesChanged: create.nodeReducer<{ values: string[] }>((node, { payload }) => {
        assert(checkNode.isLeaf.withText(node), "Node is not a leaf or does not have a text parameter");
        node.state.leaf_parameter.parameters = payload.values;
        if (node.state.meta?.multi_kind === "all") {
          node.state.leaf_parameter.count = payload.values.length;
        }
      }),
      multiCountChanged: create.nodeReducer<{ count: number }>((node, { payload }) => {
        assert(checkNode.isLeaf.withText(node), "Node is not a leaf or does not have a text parameter");
        node.state.leaf_parameter.count = payload.count;
        delete node.state.meta?.multi_kind;
      }),
      multiKindChanged: create.nodeReducer<{ kind: MultiKind }>((node, { payload }) => {
        assert(checkNode.isLeaf.withText(node), "Node is not a leaf or does not have a text parameter");
        (node.state.meta ??= {}).multi_kind = payload.kind;
        applyMultiKind(node.state, payload.kind);
      }),
      rulesValidated: create.reducer<NodeValidationMap>((state, { payload }) => {
        state.issues = payload;
        // expand all nodes with issues and their parents
        for (const id of Object.keys(payload)) expandParents(state, id);
      }),
      collapseToggled: create.reducer<string>((state, { payload }) => toggleKey(state.collapsed, payload)),
    })
  ),
  extraReducers(builder) {
    builder.addMatcher(shouldClearIssues, (state, action) => {
      delete state.issues[action.payload.id];
    });
  },
  selectors: {
    selectRules: (state) => state.nodes,
    selectRuleById: (state, id: string) => state.nodes[id],
    selectRootId: (state) => state.rootId,
    selectRootRule: (state) => (state.rootId ? state.nodes[state.rootId] : null),
    selectIssueById: (state, id: string) => state.issues[id],
    selectValid: (state) => Object.keys(state.issues).length === 0,
    selectCollapsedById: (state, id: string) => !!state.collapsed[id],
    selectBranchIsSameAsParent(state, id: string) {
      const node = state.nodes[id];
      assert(node && checkNode.isBranch(node), `Node ${id} either does not exist or is not a branch`);
      const parent = node.parentUuid && state.nodes[node.parentUuid];
      // impossible for parent to not be a branch, but we need to prove it to TS anyway
      if (!parent || !checkNode.isBranch(parent)) return false;
      return parent.state.operation === node.state.operation && parent.negated === node.negated;
    },
  },
});

export const {
  ruleModalOpened,
  ruleModalClosed,
  rulesReset,
  rulesInitialised,
  conditionAdded,
  conditionRemoved,
  operationChanged,
  fieldChanged,
  checkboxChanged,
  comparisonChanged,
  numberChanged,
  dateChanged,
  picklistChanged,
  negationChanged,
  multiValuesChanged,
  multiCountChanged,
  multiKindChanged,
  collapseToggled,
  rulesValidated: _rulesValidated,
} = rulesSlice.actions;

const shouldClearIssues = isAnyOf(
  operationChanged,
  fieldChanged,
  checkboxChanged,
  comparisonChanged,
  numberChanged,
  dateChanged,
  picklistChanged,
  negationChanged,
  multiValuesChanged,
  multiCountChanged,
  multiKindChanged
);

export const {
  selectRules,
  selectRootRule: selectNullableRoot,
  selectRootId,
  selectRuleById,
  selectIssueById,
  selectCollapsedById,
  selectValid,
  selectBranchIsSameAsParent,
} = rulesSlice.selectors;

/**
 * Selects the current rules, throwing an error if they are not present.
 * @param state RootState
 * @returns Rules
 * @throws {Error} if rules are not present
 */
export const selectRootRule = (state: RootState) => {
  const current = selectNullableRoot(state);
  assert(current, "Rules not found in state.");
  return current;
};

export const selectDenormalizedRules = createSelector([selectRootRule, selectRules], denormalizeNode);

/**
 * Validate the current rules against the given fields.
 * @param fields Fields
 * @returns thunk to dispatch
 */
export const validateCurrentRules =
  (fields: Record<string, Field>): AppThunk<boolean> =>
  (dispatch, getState) => {
    const rules = selectNullableRoot(getState());
    if (!rules) return true; // having no rules is valid
    const tree = selectRules(getState());
    const [valid, validationMap] = validateNode(rules.uuid, fields, tree);
    dispatch(_rulesValidated(validationMap ?? {}));
    return valid;
  };
