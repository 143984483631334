import { createReducer } from "@reduxjs/toolkit";
import * as actions from "@/actions/customField";

/** @typedef {import('@/common-prop-types/custom-field').CustomFieldType} CustomFieldType */
/** @typedef {'pending' | 'success' | 'error'} CustomFieldStatus */

export const defaultState = {
  /**
   * A map of custom field ids and the status that this field is in
   *
   * @type {{[key: number]: CustomFieldStatus}}
   */
  statuses: {},
  /**
   * All of the custom fields stored by id in a map for easy access and updating
   *
   * @type {{[key: number]: CustomFieldType}}
   */
  byId: {},
};

const setCustomField = (state, { payload }) => {
  state.byId[payload.data.id] = payload.data;
  state.statuses[payload.data.id] = "success";
};

const listActions = [actions.updateCustomFieldPosition, actions.listCustomFields];

const isListPendingAction = (action) => listActions.some((listAction) => listAction.pending.match(action));
const isListActionSuccess = (action) => listActions.some((listAction) => listAction.fulfilled.match(action));

const setError = (key, state) => {
  state.statuses[key] = "error";
};

const setPending = (key, state) => {
  state.statuses[key] = "pending";
};

export const customFieldsReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(actions.createCustomField.fulfilled, (state, { payload }) => {
      state.statuses["new"] = "success";
      setCustomField(state, { payload });
    })
    .addCase(actions.updateCustomField.pending, (state, { meta }) => {
      state.statuses[meta.arg.customField.id] = "pending";
    })
    .addCase(actions.updateCustomField.fulfilled, (state, { payload }) => {
      setCustomField(state, { payload });
    })
    .addCase(actions.listCustomFields.rejected, (state) => setError("all", state))
    .addCase(actions.createCustomField.rejected, (state) => setError("new", state))
    .addCase(actions.createCustomField.pending, (state) => setPending("new", state))
    .addMatcher(isListPendingAction, (state) => setPending("all", state))
    .addMatcher(isListActionSuccess, (state, { payload }) => {
      state.statuses["all"] = "success";
      payload.data.forEach((item) => setCustomField(state, { payload: { data: item } }));
    });
});

export default customFieldsReducer;
