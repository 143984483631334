import * as globalActionTypes from "../actionsTypes";
import actionTypes from './actionsTypes';
import * as apiCall from './api';
import config from './config';

export const fetchParticipantGroupsSuccess = (participantGroups) => ({
  type: actionTypes.fetch_participant_groups,
  payload: participantGroups,
});

export const fetchParticipantGrpsForEvntSuccess = (eventParticipants, eventParticipantsOnboardingStatus) => {
  let epForSelect = [];
  if (eventParticipants) {
    epForSelect = eventParticipants.map((ep) => {
      const onboardingStatus = eventParticipantsOnboardingStatus.filter((e) => e.email === ep.email && e.onboarding_status === 'rejected');
      const color = onboardingStatus.length > 0 ? 'red' : '';
      return { value: ep.email, label: ep.email, color };
    });
  }
  return { type: actionTypes.fetch_event_participants, payload: epForSelect };
};

export const fetchParticipantOnboardingStatusesSuccess = (eventParticipants) => ({
  type: actionTypes.fetch_onboarding_statuses,
  payload: eventParticipants,
});

const getError = (error) => ({
  type: globalActionTypes.ERROR_CODE,
  payload: error,
});

export const fetchParticipantGroups = (companyId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/companies/${companyId}/participant_groups`)
    .then((response) => {
      dispatch(fetchParticipantGroupsSuccess(response.data.participant_groups));
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);

export const fetchParticipantGrpsForEvnt = (companyId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/companies/${companyId}/participants?only=email`)
    .then((response) => {
      const data = response.data.event_participants.sort().map((participant) => ({ email: participant }));
      dispatch({ type: actionTypes.fetch_event_participants, payload: data });
      return data;
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);

export const fetchParticipantOnboardingStatuses = (companyId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/sim_dojo/companies/${companyId}/participants?only_onboarding_status=true`)
    .then((response) => {
      dispatch(fetchParticipantOnboardingStatusesSuccess(response.data.event_participants));
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);
