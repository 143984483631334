import React from "react";

import Select2 from "react-select2-wrapper";

import { usePickListOptions } from "@/selectors/lotSelectors";

import { CommonInputProps } from "./index";
import InputStatusIcon from "./input-status-icon";

export const PickListSelect = ({ value, options, hidden, onChange, state }) => {
  if (hidden) {
    return null;
  }

  return (
    <div className={"input simple-bid-input bid-input input-group m-b0"} style={{ display: "flex" }}>
      <Select2
        className="form-control form-width"
        value={value || ""}
        data={options}
        options={{ placeholder: "Select" }}
        onClose={(event) => {
          event.preventDefault();
          if (event.target && event.target.value) {
            onChange && onChange(event.target.value);
          }
        }}
      />
      <InputStatusIcon state={state} />
    </div>
  );
};

const InputPickListPropTypes = { ...CommonInputProps };

/**
 * @type {React.FC<typeof InputPickListPropTypes>}
 */
export const InputPickList = ({ value, onChange, lineItemComponent, state }) => {
  const options = usePickListOptions(lineItemComponent.picklist_id);
  const handleChange = (attribute_value) => {
    onChange && onChange([{ attribute_value }]);
  };

  return <PickListSelect value={value} options={options} onChange={handleChange} state={state} />;
};

InputPickList.propTypes = InputPickListPropTypes;
InputPickList.defaultProps = {};

export default InputPickList;
