import React, { Fragment, useMemo } from "react";
import isEqual from "lodash/isEqual";

import LineItemModal from "./LineItemModal";
import ShowDetailsTitle from "./ShowDetailsTitle";
import LineItemComponentDetail from "../line_item_component/LineItemComponentDetail";
import { findLIC } from "../../common";

const LineItemDetail = React.memo(
  (props) => {
    const {
      li,
      anyCompletedBids,
      translations,
      lot,
      permittedToEdit,
      lotLcs,
      event,
      eventCurrency,
      currenciesHash,
      maxPrecisionFor,
      lots,
      lineItemComponents,
      classFromEventState,
      openOrJapanese,
      isJapaneseEvent,
      showDetails,
      handleShowDetailsStatus,
      locale,
      timeZoneIdentifier,
      currentUser,
      lidKey,
      setLineItemToBeDeleted,
      isMultiCurrencyEvent,
      liLics,
      hideFromParticipantsSwitch,
    } = props;
    let objects = liLics;
    let objectType = "LineItemComponent";
    if (li.is_calculation) {
      objects = lotLcs;
      objectType = "LotComponent";
    }
    const ShowDetailsTitleMemo = useMemo(() => <ShowDetailsTitle translations={translations} />, [false]);
    return (
      <Fragment key={lidKey}>
        <tr
          className={`${
            li.is_calculation
              ? lotLcs.length == 0
                ? "calculation-row no-lot-component"
                : "calculation-row"
              : "line-item-row"
          }`}
          id={`line_item_row_${li.id}`}
        >
          <td className="title col-md-2 col-sm-2 col-xs-2">
            <span className="cell-number">
              {lotLcs.length > 0 && (
                <a title={translations.open_line_item} className="button small white ok hover icon-only">
                  <i
                    className="fa fa-sort-amount-desc blue-color vertical-alignsuper"
                    aria-hidden="true"
                    data-line-item-id={li.id}
                    onClick={handleShowDetailsStatus}
                  />
                </a>
              )}
              {permittedToEdit ? (
                <LineItemModal
                  translations={translations}
                  lotId={lot.id}
                  lineItem={li}
                  calcRow={li.is_calculation}
                  anyCompletedBids={anyCompletedBids}
                  setLineItemToBeDeleted={setLineItemToBeDeleted}
                  hideFromParticipantsSwitch={hideFromParticipantsSwitch}
                />
              ) : (
                <span className="line-item-name">{li.name}</span>
              )}
              <span className="lic-tag left top-left">{li.tag}</span>
            </span>
            {showDetails.length > 0 && showDetails.includes(li.id) && ShowDetailsTitleMemo}
          </td>
          {/*
            Here the objects can be Lot Components or Line Item Components, this has been done specifically for
            the calculation type line items. If we have a line item with provider calculation and lot components
            of type text or date or picklist (none type) then we don't create their LICs for the calculation
            line item as those lot components are not calculable.
          */}
          {objects.map((obj, i) => {
            let lic = obj;
            let lc = undefined;
            if (objectType == "LotComponent") {
              lc = obj;
              lic = findLIC(lc, li, liLics);
            }

            const key = lic ? `${lic.id}-${Date.parse(lic.updated_at)}` : i;
            return lic && !(lic.is_calculation && !lic.calculable_lc) ? (
              <Fragment key={key}>
                <LineItemComponentDetail
                  lic={lic}
                  anyCompletedBids={anyCompletedBids}
                  eventCurrency={eventCurrency}
                  currenciesHash={currenciesHash}
                  permittedToEdit={permittedToEdit}
                  maxPrecisionFor={maxPrecisionFor}
                  event={event}
                  lot={lot}
                  translations={translations}
                  lots={lots}
                  lineItemComponents={lineItemComponents}
                  classFromEventState={classFromEventState}
                  openOrJapanese={openOrJapanese}
                  isJapaneseEvent={isJapaneseEvent}
                  showDetails={showDetails}
                  locale={locale}
                  timeZoneIdentifier={timeZoneIdentifier}
                  currentUser={currentUser}
                  isMultiCurrencyEvent={isMultiCurrencyEvent}
                />
              </Fragment>
            ) : (
              <td key={key}>
                <div className="lic-links">
                  <div className="link-wrapper" />
                  &nbsp;
                </div>
              </td>
            );
          })}
        </tr>
        <tr className="lot-work-row" id={`line_item_work_area_${li.id}`} style={{ display: "none" }}>
          <td colSpan={lotLcs.length + 1}>
            <div className="work-area" />
          </td>
        </tr>
      </Fragment>
    );
  },
  (prevProps, nextProps) => {
    const { li, lot, showDetails, liLics } = prevProps;

    switch (true) {
      case !isEqual(li, nextProps.li):
      case !isEqual(lot, nextProps.lot):
      case !isEqual(liLics, nextProps.liLics):
      case !isEqual(showDetails, nextProps.showDetails):
        return false;
      default:
        return true;
    }
  }
);

export default LineItemDetail;
