import React, { Component } from "react";
import { connect } from "react-redux";
import Select2 from "react-select2-wrapper";

import {
  updateBasicDetailsTextFeilds,
  updateBasicDetailsSelectFeilds,
} from "../../../actions/contract/contractCreateEditActions";
import ContractTooltip from "./ContractTooltip";
import { commonProps } from "./selectors";

class BasicDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicDetails: props.basicDetails,
    };
    this.basicDetailsSection = React.createRef();
  }

  static getDerivedStateFromProps(newProps) {
    const { updatedBasicDetails } = newProps;
    if (updatedBasicDetails) {
      return {
        basicDetails: updatedBasicDetails,
      };
    }
    return null;
  }

  createDataPairForSelect(data) {
    const { translations } = this.props;
    let contractOptions = [];
    data.map((item) => {
      contractOptions.push({ text: translations[item.name] || item.name, id: item.name });
    });
    return contractOptions;
  }

  handleChange(e, flag) {
    const fieldName = e.target.name;
    const value = e.target.value;
    const { basicDetails } = this.state;
    const { translations } = this.props;
    if (basicDetails[fieldName].required) {
      if (value.trim() === "") {
        this[`${fieldName}_error`].classList.remove("invisible");
        this[`${fieldName}_error`].innerText = translations[`${fieldName}_error`];
      } else if (fieldName == "title" && value.length > 65) {
        this[`${fieldName}_error`].classList.remove("invisible");
        this[`${fieldName}_error`].innerText = translations.length_error;
      } else {
        this[`${fieldName}_error`].classList.add("invisible");
      }
    }
    if (flag !== "input" && value.trim() !== "") {
      const data = {};
      data[fieldName] = value;
      this.props.updateBasicDetailsSelectFeilds(this.props.contractId, basicDetails[fieldName].id, value);
    } else {
      basicDetails[fieldName].value = value;
      this.setState({
        basicDetails,
      });
    }
  }

  updateInputFieldValue(e, allowBlank) {
    const fieldName = e.target.name == "reference" ? "contract_number" : e.target.name;
    const value = e.target.value;
    if (value.trim() || allowBlank) {
      const data = {};
      data[fieldName] = value;
      this.props.updateBasicDetailsTextFeilds(this.props.contractId, data);
    }
  }

  updateDefaultTitleText() {
    const { basicDetails } = this.state;
    const { translations } = this.props;
    if (translations.new_contract === basicDetails.title.value) {
      basicDetails.title.value = "";
      this.setState({
        basicDetails,
      });
    }
  }

  renderBaicDetailsFileds(basicDetails) {
    const { translations, showPage } = this.props;
    return Object.keys(basicDetails).map((key, index) => {
      // this condition specify that if the current field have a value then only it will be visible for show contract
      if (
        (showPage && basicDetails[key].value && key !== "sequential_contract_id") ||
        (!showPage && key !== "history" && key !== "sequential_contract_id")
      ) {
        return (
          <div key={index} className={`form-group input-field`}>
            <label className="label-ellipsis">
              {translations[key]}
              {!showPage && basicDetails[key].required && <span className="required">*</span>}
            </label>
            {showPage ? (
              <input
                className="form-control placeholder"
                name={key}
                placeholder={translations[key]}
                value={basicDetails[key].value || ""}
                disabled
              />
            ) : key === "title" && window.location.href.includes("/new") ? (
              <input
                className="form-control placeholder"
                name={key}
                placeholder={translations[key]}
                onChange={(e) => this.handleChange(e, "input")}
                onBlur={(e) => this.updateInputFieldValue(e, false)}
                value={basicDetails[key].value || ""}
                autoComplete="off"
                onFocus={() => this.updateDefaultTitleText()}
              />
            ) : (
              <input
                className="form-control placeholder"
                name={key}
                placeholder={key == "reference" ? translations.optional_leave_blank_to_auto_assign : translations[key]}
                onChange={(e) => this.handleChange(e, "input")}
                onBlur={(e) => this.updateInputFieldValue(e, true)}
                value={basicDetails[key].value || ""}
                autoComplete="off"
              />
            )}

            {!showPage && basicDetails[key].required && (
              <span className="error-text invisible" ref={(ref) => (this[`${key}_error`] = ref)} />
            )}
          </div>
        );
      }
    });
  }

  validateBasicDetails() {
    const { basicDetails } = this.state;
    const { translations } = this.props;
    const { title, reference } = basicDetails;
    let isError = false;
    let message = null;
    if ((title.required && !title.value) || title.value.length > 65) {
      message = translations.title_error;
      isError = this.errorMessage(this.title_error, message);
    } else {
      this.title_error.classList.add("invisible");
    }
    if (reference && reference.required) {
      if (reference.required && !reference.value) {
        message = translations.reference_error;
        isError = this.errorMessage(this.reference, message);
      } else {
        this.reference_error.classList.add("invisible");
      }
    }
    return isError;
  }

  errorMessage(element, message) {
    element.classList.remove("invisible");
    element.innerText = message;

    return true;
  }

  render() {
    const { basicDetails } = this.state;
    const { translations } = this.props;
    return (
      <div ref={this.basicDetailsSection} className="col-md-12 col-sm-12">
        <div className="container">
          <div className="contract-title">
            <h1>
              {basicDetails.sequential_contract_id.value} {basicDetails.title.value}{" "}
              <em>({translations[this.props.contract.current_state]})</em>
            </h1>
          </div>
        </div>
        <div className="form-section-block">
          <ContractTooltip translations={translations} titleText={"basic_details"} />
          <div className="inputWrap">{this.renderBaicDetailsFileds(basicDetails)}</div>
          <p className="clear-both" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ createEditReducers }) => ({
  ...commonProps(createEditReducers),
  updatedBasicDetails: createEditReducers.updatedBasicDetails,
  basicDetails: createEditReducers.basicDetails,
  contract: createEditReducers.contract,
});

const mapDispatchToProps = (dispatch) => ({
  updateBasicDetailsTextFeilds: (contractId, data) => dispatch(updateBasicDetailsTextFeilds(contractId, data)),
  updateBasicDetailsSelectFeilds: (contractId, fieldId, fieldValue) =>
    dispatch(updateBasicDetailsSelectFeilds(contractId, fieldId, fieldValue)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BasicDetailsForm);
