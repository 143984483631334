import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { showMessageModal } from "../../../actions/messageActions";
import SettingForm from "./SettingForm";
import ShowAllUpdateMessages from "./ShowAllUpdateMessages";
import { classesFromEventState } from "../common";
import SendMessageModal from "../../messages/sendMessageModal";

export const markAsReadPayload = (inSandpit, currentUser) => {
  const payload = { message: { read: true } };
  if (inSandpit) {
    payload.in_sandpit = "true";
    payload.in_sandpit_as_user = currentUser.id;
  }
  return payload;
};

const ShowUpdateMessages = (props) => {
  const {
    permittedToEdit,
    event,
    translations,
    allMessages,
    role,
    hasRankedLineItemComponents,
    messageRecipients,
    giveDisabledDurationValues,
    lastBidTimeInterval,
    lastBidChange,
    currentUser,
    lots,
    bids,
    participants,
    hosts,
    hostPermission,
    inSandpit,
    eventParticipants,
    locale,
    timeZoneIdentifier,
  } = props;

  const sendMessageModalId = "ShowUpdateMessageSendMessageModal";
  const documents = props.documentsData?.documents;

  const isCurrentUserHost = role === "Host";
  const replyUserId = isCurrentUserHost ? null : event.user_id;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const messagesEndRef = useRef(null);

  const dispatch = useDispatch();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "nearest",
      inline: "start",
      behavior: "smooth",
      boundary: document.getElementsByClassName("panel-body")?.[0],
    });
  };

  return (
    <div className="updates message-centre">
      <div className="messages-top row">
        <div className="col-md-12 text-right btn-group-lg">
          {permittedToEdit && ["running", "pause"].includes(event.current_state) ? (
            <a
              id="settings"
              className="btn btn-default light-gray-color push-10-r btn-blue-outline"
              onClick={() => setModalIsOpen(true)}
            >
              {translations.settings}
            </a>
          ) : null}
          {modalIsOpen && (
            <SettingForm
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
              translations={translations}
              event={event}
              giveDisabledDurationValues={giveDisabledDurationValues}
              lastBidTimeInterval={lastBidTimeInterval}
              lastBidChange={lastBidChange}
              locale={locale}
            />
          )}

          <a
            className={`btn btn-default ${classesFromEventState(event)}`}
            onClick={() => dispatch(showMessageModal(true, null, replyUserId, sendMessageModalId))}
          >
            {translations.sendNewMessage}
          </a>
        </div>
        <SendMessageModal
          documents={documents}
          marketDojo
          htmlId={sendMessageModalId}
          hasPermissionToEdit={permittedToEdit}
        />
        <div className="col-md-12">
          <strong>{translations.live_auction_feed}</strong>
        </div>
        <div className="clear"></div>
      </div>
      <div className="arrow-box">
        <div
          className="max-h135 height-auto panel-body overflow-messages-block scroll-pane jspScrollable"
          onMouseLeave={scrollToBottom}
        >
          <ShowAllUpdateMessages
            allMessages={allMessages}
            role={role}
            hasRankedLineItemComponents={hasRankedLineItemComponents}
            translations={translations}
            messageRecipients={messageRecipients}
            bids={bids}
            lots={lots}
            currentUser={currentUser}
            event={event}
            participants={participants}
            hosts={hosts}
            permittedToEdit={permittedToEdit}
            hostPermission={hostPermission}
            inSandpit={inSandpit}
            eventParticipants={eventParticipants}
            markAsReadPayload={markAsReadPayload}
            timeZoneIdentifier={timeZoneIdentifier}
          />
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default ShowUpdateMessages;
