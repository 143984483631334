import React, { Component } from "react";
import Tooltip from "../../../common/Tooltip";
import Select2 from "react-select2-wrapper";
class NotificationContact extends Component {
  notificationUpdate = (e) => {
    this.props.onValueChange(e.target.value, e.target.name);
  };

  render() {
    const { translations, configuration_setting_values, currentUserEditConfigPermission } = this.props;
    let options = [
      {
        text: translations.participant_owner,
        id: "Participant Owner",
      },
      {
        text: translations.sim_event_owner,
        id: "SIM Event Owner",
      },
    ];

    return (
      <div className={"float-left form-item-block wid-49 clearboth"} style={{ height: "300px" }}>
        <div className="form-item-block-title">{translations.notification_contact}</div>
        <div className="inpnotificationRecipientUpdateut inline m-b10">
          <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0 wid-100">
            {translations.notification_recipient_label}
          </label>
          <div className="vertical-center">
            <Select2
              name="notification_recipient"
              ref={(ref) => (this.notificationRecipient = ref)}
              className="settings form-control"
              value={configuration_setting_values.notification_recipient}
              data={options}
              onSelect={(e) => this.notificationUpdate(e)}
              disabled={!currentUserEditConfigPermission}
            />
          </div>
          <div className="m-l10 vertical-center">
            <Tooltip {...translations.event.notification_recipient} />
          </div>
        </div>
        <div className="input inline m-b10">
          <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0 wid-100">
            {translations.notification_sender_label}
          </label>
          <div className="vertical-center">
            <Select2
              name="notification_sender"
              ref={(ref) => (this.notificationSender = ref)}
              className="settings form-control"
              value={configuration_setting_values.notification_sender}
              data={options}
              onSelect={(e) => this.notificationUpdate(e)}
              disabled={!currentUserEditConfigPermission}
            />
          </div>
          <div className="m-l10 vertical-center">
            <Tooltip {...translations.event.notification_sender} />
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationContact;
