import React, { useCallback } from "react";
import { Checkbox } from "@/cl/checkbox";
import { useCheckedChildItems, useParentSelect, useSelectItem } from "./use-select-item";
import { useItems } from "./items-context";
import { t } from "@/i18n";
import HiddenCheckedItems from "./hidden-checked-items";
import type { Item, StackItem } from "./types";

import styles from "./multi_level_select.module.scss";

export function CheckLevel({ stackItem }: { stackItem: StackItem }) {
  const { type_name_map } = useItems();
  const { onParentChange, onChildChange } = useSelectItem(stackItem);
  const { checkedChildItems, otherCheckedItems } = useCheckedChildItems(stackItem);
  const { parentChecked, indeterminate } = useParentSelect(stackItem);

  // If we don't have a name for the parent type, this means the backend will
  // want to receive all the children. When the user checks `all` this should
  // be searching the hole parent level. Sometimes we don't have a parent level.
  // In this case we will need to ship the optimization and post all the
  // children individually.
  const shouldPostChildren = !type_name_map[stackItem.parent.type];
  const isChecked = useCallback(
    (childItem: Item): boolean => {
      return checkedChildItems.some((checkedItem) => checkedItem.item.value === childItem.value);
    },
    [checkedChildItems]
  );

  return (
    <div className="p-2 w-50 d-flex flex-column">
      <Checkbox
        label={t("select_all")}
        id={`all-${stackItem.parent.label.split(" ").join("-")}`}
        isIndeterminate={indeterminate}
        name={!shouldPostChildren ? type_name_map[stackItem.parent.type] : ""}
        value={stackItem.parent.value}
        onChange={(e) => onParentChange(e.target.checked)}
        checked={parentChecked}
      />
      <div className={`flex-grow-1 overflow-auto ${styles.slimScroll}`}>
        {stackItem.items.map((childItem) => (
          <div key={childItem.label}>
            <Checkbox
              id={`stackItem-${childItem.type}-${childItem.value}`}
              name={shouldPostChildren || !parentChecked ? type_name_map[childItem.type] : ""}
              label={childItem.label}
              value={childItem.value}
              onChange={(e) => onChildChange(childItem, e.target.checked)}
              checked={parentChecked || isChecked(childItem)}
            />
          </div>
        ))}
        {otherCheckedItems.length > 0 && <HiddenCheckedItems otherCheckedItems={otherCheckedItems} />}
      </div>
    </div>
  );
}
