import React, { useState, useEffect } from "react";
import { auctionPending, completed, isBeforeAuction, isRfq, pause, postRfqPreAuction } from "../../eventCommon";
import ComplexLotBidStatus from "../ComplexLotBidStatus";
import { isLotTotalPresent, requiresComplexDetailAdded } from "../../common";
import { rejectedBidForUser } from "../../bidCommon";
import BidButtonsOrStatus from "./BidButtonsOrStatus";
import OutSideRangeBidMessage from "./OutSideRangeBidMessage";
import LotDetail from "./LotDetail";
import ShowParticipantChart from "./ShowParticipantChart";
import { useSelector } from "react-redux";
import useLot from "../../hooks/useLot";

const LotTableHeadTop = (props) => {
  const { translations, bid, lot, event, isEventTotal, isBidSubmitted } = props;
  const { isAuction, isInRange, auctionTab, isJapaneseMonitor, bidDetailsAdded: detailsAdded } = props;
  const { isBidForCurrentMSRFQRoundSubmitted, isShowingBidsForLastRound, lotTotalBLic, isLotLevelMonitor } = props;

  const { isRejectedLot } = useLot(lot, bid);
  const eventRound = useSelector(({ lotReducers }) => lotReducers.eventRound);
  const event_rounds = useSelector(({ lotReducers }) => lotReducers.event_rounds);

  const [bidDetailsAdded, setBidDetailsAdded] = useState(
    detailsAdded && ((isJapaneseMonitor && bid.bid_round) || !auctionTab)
  );

  const isMsrfqEvent = isRfq(event) && event_rounds?.length > 1;
  useEffect(() => {
    if (isMsrfqEvent) {
      if (bid && isBidForCurrentMSRFQRoundSubmitted)
        setBidDetailsAdded(!!bid?.details_added ?? isBidForCurrentMSRFQRoundSubmitted);
      else setBidDetailsAdded(false);
    }
  }, [isBidForCurrentMSRFQRoundSubmitted, bid, eventRound]);

  const isAuctionPending = auctionPending(event);
  const isRankedAuCompleted = props.isRankedMonitor && completed(event);
  const showActionButtons = () => {
    return (
      !isEventTotal &&
      !isRejectedLot &&
      !props.isEventCompleted &&
      ((!isBidSubmitted &&
        isShowingBidsForLastRound &&
        !["running", "pause", "post_rfq_pre_auction"].includes(event.current_state) &&
        !postRfqPreAuction(event)) ||
        (!pause(event) && isAuction))
    );
  };
  const showButtons =
    showActionButtons() &&
    (((auctionPending(event) || event.current_state === "rfq") && !auctionTab) ||
      (event.current_state === "running" && auctionTab)) &&
    !bidDetailsAdded &&
    !isRankedAuCompleted;

  const isLotTotal = isLotTotalPresent(lot);
  const isShowingComplexLotBidStatus = () => {
    const isShowingStatus = shouldShowStatus(isLotTotal, lotTotalBLic, isLotLevelMonitor, lot, auctionTab);

    return (
      bid &&
      isShowingStatus &&
      !isEventTotal &&
      !rejectedBidForUser(bid) &&
      (isBeforeAuction(event) ||
        ((auctionTab || isAuction || bid) &&
          ((event.bid_at_detail_level && ["running", "pause", "completed", "closed"].includes(event.current_state)) ||
            requiresComplexDetailAdded(event, lot))))
    );
  };

  return (
    <>
      <div className={`equal-table-head-top bg-white box-header p-0 p-b10 bid-header-${lot.id}`}>
        {props.isProxyBid && <p className="no_data m-b0 alert alert-info m-b20">{translations.proxy_bid_info}</p>}
        <LotDetail lot={lot} translations={translations} isBidSubmitted={isBidSubmitted} event={event} />
        <div className={`btn-group-sm p-l15 pull-left tbl-head-toprght wid-auto`}>
          {isLotTotal && <ShowParticipantChart {...props} header={lot.name} />}
          <BidButtonsOrStatus
            showButtons={showButtons}
            allowtoShowProxyBtn={props.allowtoShowProxyBtn}
            lotState={props.lotState}
            setLotState={props.setLotState}
            enableSubmit={props.enableSubmit}
            bid={bid}
            lot={lot}
            currentUser={props.currentUser}
            event={event}
            translations={translations}
            onCancelBid={props.onCancelBid}
            onClickPlaceBid={props.onClickPlaceBid}
            detailsAdded={detailsAdded}
            isLotLevelMonitor={isLotLevelMonitor}
            openModal={props.openModal}
            auctionTab={auctionTab}
            isJapaneseMonitor={isJapaneseMonitor}
            isAuction={isAuction}
            isEventTotal={isEventTotal}
            isRejectedLot={isRejectedLot}
            bidDetailsAdded={bidDetailsAdded}
            isRankedAuCompleted={isRankedAuCompleted}
            isEventCompleted={props.isEventCompleted}
            licCurrency={props.licCurrency}
            totalBidPrice={props.totalBidPrice}
            hideTopButton={props.hideTopButton}
          />
        </div>
        <OutSideRangeBidMessage
          isAuction={isAuction}
          isInRange={isInRange}
          latestBid={props.latestBid}
          message={translations.outside_range_revise_your_bid}
        />

        {isShowingComplexLotBidStatus() ? (
          <ComplexLotBidStatus
            translations={translations}
            isAuctionPending={isAuctionPending}
            totalBidPrice={props.totalBidPrice}
            isInRange={isInRange}
            minBidRange={props.minBidRange}
            maxBidRange={props.maxBidRange}
            licCurrency={props.licCurrency}
            isBidSubmitted={isBidSubmitted}
            rankValue={props.rankValue}
            auctionTab={auctionTab}
            lotBidLineItemComponents={props.lotBidLineItemComponents}
            event={event}
            lot={lot}
            bid={bid}
            weightedRfqOrAuction={props.weightedRfqOrAuction}
            bestBids={props.bestBids}
            locale={props.locale}
            isAuction={isAuction}
            isProxyBid={props.isProxyBid}
            isPlaceBidActive={props.lotState.isPlaceBidActive}
            currenciesHash={props.currenciesHash}
            isMultiCurrencyEvent={props.isMultiCurrencyEvent}
            lotLics={props.lotLics}
          />
        ) : null}
      </div>
    </>
  );
};
LotTableHeadTop.defaultProps = {
  isProxyBid: false,
  isAuction: false,
  LBids: [],
  hideTopButton: false,
  lotComponents: [],
};

/**
 *
 * @param {Boolean} isLotTotal
 * @param {Object} lotTotalBLic
 * @param {Boolean} isLotLevelMonitor
 * @param {Object} lot
 * @param {Boolean} auctionTab
 * @returns {Boolean}
 */
export const shouldShowStatus = (isLotTotal, lotTotalBLic, isLotLevelMonitor, lot, auctionTab) => {
  // When you have a Lot with a Lot Total and Partial Bidding enabled, and a participant goes to bid on it:
  // - Do not show or use the lot total ranges until all cells used in the lot total have been filled in
  // - isLotLevelMonitor is true for Japanese and Lot level events only.

  const isLotTotalLicFilled = lot.lot_total_lic_id === lotTotalBLic?.line_item_component_id;
  const isAuctionAndLotHasQualPrice = auctionTab && Boolean(lot?.qualification_price);
  const isLotTotalLicFilledAndHasPrice = Boolean(lotTotalBLic?.price) && (isLotLevelMonitor || isLotTotalLicFilled);

  return isLotTotal && (isLotTotalLicFilledAndHasPrice || isAuctionAndLotHasQualPrice);
};

export default LotTableHeadTop;
