import * as actionTypes from "../../actions/actionsTypes";

const contractConfigurationReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.UPDATE_HOME_PAGE_FIELDS_STATUS:
      return {
        indexFieldsData: action.payload,
      };
    case actionTypes.EDIT_PAGE_FIELD_STATUS_UPDATE:
      return {
        editFieldsUpdatedData: action.payload,
      };
    case actionTypes.CUSTOM_NOTIFICATION_STATUS_UPDATE:
      return {
        updatedStatusOfCustomNotification: action.payload,
      };
    default:
      return state;
  }
};

export default contractConfigurationReducer;
