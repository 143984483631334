import cookies from "browser-cookies";
import { jwtDecode } from "jwt-decode";

const headers = "";
const BASE_URL = `${window.location.origin}/api/v1`;
const DOMAIN_URL = window.location.origin;
const SINGLE_DOCUMENT_SIZE = 10;
const MAX_DOCUMENT_FILE_SIZE = 10;
const TOTAL_ALL_DOCUMENTS_SIZE = 25;
const isCookies = cookies.get("auth_headers");
const SUBDOMAIN = window.location.hostname.slice(0, window.location.hostname.indexOf("."));
const isQuickQuotes = window.location.pathname.indexOf("quick_quote") === 1;
const config = {
  BASE_URL,
  headers,
  DOMAIN_URL,
  SINGLE_DOCUMENT_SIZE,
  MAX_DOCUMENT_FILE_SIZE,
  TOTAL_ALL_DOCUMENTS_SIZE,
  SUBDOMAIN,
  isQuickQuotes,
};

if (isCookies) {
  config.headers = jwtDecode(cookies.get("auth_headers"));
}

export default config;
