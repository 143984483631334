import type { ComponentType, ReactNode } from "react";
import React from "react";
import ReactDOM from "react-dom";
import type { WeakValidationMap } from "prop-types";
import PropTypes from "prop-types";
import { t } from "@/i18n";

import Modal from "react-modal";

import { renderProp } from "@/utils/render-prop";
import type { HelpfulOmit } from "@/types/util";

export interface ConfirmComponentProps {
  reject: () => void;
  confirm: () => void;
  title?: ReactNode | (() => ReactNode);
  body: ReactNode | (() => ReactNode);
  isOpen: boolean;
  element: HTMLElement;
  confirmBtnText?: string;
}

export const ConfirmComponentPropTypes = {
  /**
   * A function to call to reject the confirmation
   */
  reject: PropTypes.func.isRequired,
  /**
   * A function to call to confirm the confirmation
   */
  confirm: PropTypes.func.isRequired,
  /**
   * The title that will be displayed in the confirmation. The default value
   * will be the `Please confirm`
   *
   * This can be a function or node like see `body` for more info
   */
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * The body of the confirm. This can be any node like ie 'string' or
   * 'component', it can also be function that will return a node like type.
   */
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
   * A flag to define if the component is open
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * The HTML dom element the confirmation will be rendered into by ReactDOM.
   *
   */
  element: (props) => {
    if (!(props.element instanceof HTMLElement)) {
      return new Error("'element' must be a valid HTMLElement");
    }
    return null;
  },
} satisfies WeakValidationMap<ConfirmComponentProps>;

/**
 * The default confirm component that will be rendered when when the `confirm`
 * method is called.
 *
 */
const ConfirmComponent = (props: ConfirmComponentProps) => {
  const { reject, confirm, body, title, element, confirmBtnText } = props;

  if (process.env.NODE_ENV !== "production" && !body) {
    console.warn("Confirm component was called without a message. This is unhelpful for the user!!");
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={reject} className="modal-content modal-sm" appElement={element}>
      <div className="bg-white modal-header">
        <button type="button" className="close" onClick={reject}>
          &times;
        </button>
        <h4 className="modal-title">{renderProp(title || t("please_confirm"))}</h4>
      </div>
      <div className="modal-body">{renderProp(body)}</div>
      <div className="modal-footer">
        <button className="btn btn-default height-40 m-b0" onClick={reject}>
          {t("cancel")}
        </button>
        <button className={`btn btn-primary ok-btn height-40`} autoFocus onClick={confirm}>
          {confirmBtnText ? t(confirmBtnText) : t("ok")} <i className="fa fa-check m-l5"></i>
        </button>
      </div>
    </Modal>
  );
};

ConfirmComponent.propTypes = ConfirmComponentPropTypes;

/**
 * Handles all of the differed function until a confirmation is accepted or
 * rejected.
 */
export class ConfirmHandler {
  /**
   * Callback store for call of the functions that will be run if the
   * conformation is accept or rejected
   */
  #closeCallbacks: Array<(result: boolean) => void> = [];
  /**
   * Callback store for call of the functions that will be run if the
   * conformation is accepted
   */
  #acceptCallbacks: Array<() => void> = [];
  /**
   * Callback store for call of the functions that will be run if the
   * conformation is rejected
   *
   */
  #rejectCallbacks: Array<() => void> = [];
  /**
   * Register a function to be called if the conformation is accepted
   */
  accept(callback: () => void) {
    this.#acceptCallbacks.push(callback);
    return this;
  }
  /**
   * Register a function to be called if the conformation is rejected
   */
  reject(callback: () => void) {
    this.#rejectCallbacks.push(callback);
    return this;
  }
  /**
   * Register a function to be called if the conformation is accepted or
   * rejected
   */
  close(callback: (result: boolean) => void) {
    this.#closeCallbacks.push(callback);
    return this;
  }
  /**
   * Resolve the conformation running all of the callbacks
   *
   * @param result The result of the confirmation
   */
  resolve(result: boolean) {
    const callbacks = result ? this.#acceptCallbacks : this.#rejectCallbacks;
    callbacks.forEach((callback) => callback());
    this.#closeCallbacks.forEach((callback) => callback(result));
  }
}

export interface CreateConfirmOptions
  extends HelpfulOmit<ConfirmComponentProps, "element" | "isOpen" | "confirm" | "reject"> {
  parentElement?: HTMLElement;
}

/**
 * Makes component confirmable, it will return a `ConfirmHandler` that you can
 * attach callbacks to that will run when the user confirms or rejects the
 * conformation.
 *
 * It will attach the `Component` into the the `parentElement` if one is found,
 * if not then the body element. After the user resolves the conformation all
 * elements will be cleaned up after 500ms to give it time for animations to
 * finish.
 */
export const createConfirm: {
  (Component: ComponentType<ConfirmComponentProps>, options: CreateConfirmOptions): ConfirmHandler;
  parentElement?: HTMLElement;
} = (Component, options) => {
  options.parentElement = options.parentElement || createConfirm.parentElement || document.body;
  const { parentElement, ...props } = options;

  const element = document.createElement("div");
  parentElement.appendChild(element);

  const handler = new ConfirmHandler();
  handler.close(() =>
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(element);
      parentElement.removeChild(element);
    }, 500)
  );

  function WrappedComponent() {
    const [isOpen, setIsOpen] = React.useState(true);

    const resolve = (result: boolean) => {
      setIsOpen(false);
      handler.resolve(result);
    };
    return (
      <Component
        {...props}
        element={element}
        isOpen={isOpen}
        confirm={() => resolve(true)}
        reject={() => resolve(false)}
      />
    );
  }

  ReactDOM.render(<WrappedComponent />, element);

  return handler;
};

/**
 * The same as `createConfirm` but will use `ConfirmComponent` as the default
 * component
 */
export const confirm = (options: CreateConfirmOptions) => {
  return createConfirm(ConfirmComponent, options);
};
