import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showArchived, selectListAll, searchContracts } from "../../../actions/contract/contractListingActions";
import { CSRFToken } from "@/cl/input/csrf-token";

const DownloadButtonPropTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  filteredIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

/**
 * @param {PropTypes.InferProps<typeof DownloadButtonPropTypes>} props
 */
const DownloadButton = ({ type, children, filteredIds }) => {
  return (
    <form action={"/contracts/download" + (type ? `?type=${type}` : "")} method="post">
      <CSRFToken />
      <input type="hidden" name="download_ids" value={filteredIds} />
      <button className="btn-contract btn-width-auto">
        <i className="fa fa-download"></i>
        {children}
      </button>
    </form>
  );
};

DownloadButton.propTypes = DownloadButtonPropTypes;

class ContractListingDownload extends Component {
  constructor(props) {
    super(props);
    let archived = window.sessionStorage.getItem("archived");
    let contracts = props.contracts.slice(0);
    let tmpContracts = contracts.filter((contract) => {
      return archived == "true" ? contract : contract.current_state != "archived";
    });
    this.state = {
      session: archived && archived !== "false" ? true : false,
      contracts_count: tmpContracts.length,
      contracts: contracts,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let archived = window.sessionStorage.getItem("archived");
    if (JSON.stringify(prevState.contracts) != JSON.stringify(nextProps.contracts)) {
      let contracts = nextProps.contracts.slice(0);
      let tmpContracts = contracts.filter((contract) => {
        return archived == "true" ? contract : contract.current_state != "archived";
      });
      return {
        session: archived && archived !== "false" ? true : false,
        contracts_count: tmpContracts.length,
        contracts: contracts,
      };
    }
    return null;
  }

  handleChange = async (e) => {
    window.sessionStorage.setItem("archived", e.target.checked);
    let session = e.target.checked;
    let contracts = this.props.contracts.slice(0);
    let tmpContracts = contracts.filter((contract) => {
      return window.sessionStorage.getItem("archived") == "true" ? contract : contract.current_state != "archived";
    });
    this.setState({
      session,
      contracts_count: tmpContracts.length,
    });
    await this.props.showArchived(session);
    this.props.selectListAll({ selectAllPage: {}, contractsSelected: {} });
  };

  searchContract = (e) => {
    if (e.key === "Enter") {
      if (e.currentTarget.value) $(e.currentTarget).blur();
      this.props.searchContracts(e.currentTarget.value);
    }
  };
  render() {
    const { translations, filtered_ids, totalCount } = this.props;
    const { session, contracts_count } = this.state;
    return (
      <div className="col-12 col-md-7 d-flex flex-column flex-md-row justify-content-center justify-content-md-end align-items-center pl-0">
        {totalCount == 0 || contracts_count == 0 ? (
          <button
            className="btn-orange button-auto download-contracts"
            title={translations.download_contracts_button}
            disabled
          >
            {contracts_count + " / " + totalCount}
            <i className="fa fa-download ml-1"></i>
          </button>
        ) : (
          <div className="dropdown dropdown-filter ml-0 mr-1">
            <button
              className="btn-orange button-auto download-contracts"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              title={translations.download_contracts_button}
            >
              {contracts_count + " / " + totalCount}
              <i className="fa fa-download ml-1"></i>
            </button>

            <ul className="dropdown-menu dropdown-menu-right">
              <li className="dropdown-item">
                <DownloadButton type="xlsx" filteredIds={filtered_ids}>
                  {translations.download_contracts_summary}
                </DownloadButton>
              </li>
              <li className="dropdown-item">
                <DownloadButton filteredIds={filtered_ids}>{translations.download_contracts_button}</DownloadButton>
              </li>
            </ul>
          </div>
        )}
        <input
          type="search"
          name="search"
          className="form-control search_contract w-auto"
          placeholder={translations.search}
          onKeyPress={(e) => this.searchContract(e)}
        />
        <div className="switch-toggle">
          <label className="switch">
            <input
              type="checkbox"
              id="archived_switch"
              defaultChecked={session}
              onChange={(e) => this.handleChange(e)}
            />
            <span className="slider round"></span>
          </label>
          <span>{translations.show_archived}</span>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  selectListAll: (data) => dispatch(selectListAll(data)),
  showArchived: (archived) => dispatch(showArchived(archived)),
  searchContracts: (searchKey) => dispatch(searchContracts(searchKey)),
});

export default connect(null, mapDispatchToProps)(ContractListingDownload);
