import actionTypes from '../../actions/qq/actionsTypes';

const qqmessagesReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.fetch_bids:
      return {
        ...state,
        bids_list: action.payload,
      };
    case actionTypes.fetch_bids_summary:
      return {
        ...state,
        bids_summary: action.payload,
      };
    case actionTypes.fetch_messages:
      return {
        ...state,
        messages_list: action.payload,
      };
    default:
      return state;
  }
};

export default qqmessagesReducer;
