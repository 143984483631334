import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { createLineItem, updateLineItem } from "../../../../actions/lineItemActions";
import { disableButton, enableButton, isIE } from "../../common";
import Tooltip from "../../../common/Tooltip";
import classNames from "classnames";

const LineItemForm = (props) => {
  const {
    translations,
    calcRow,
    lineItem,
    lotId,
    modalIsOpen,
    setModalIsOpen,
    title,
    anyCompletedBids,
    hideFromParticipantsSwitch,
  } = props;
  const { line_item_name, lot_hide_from_participants } = translations.infos;
  const [newLineItemName, setNewLineItemName] = useState(props.lineItem !== undefined ? props.lineItem.name : "");

  const [hideFromParticipants, setHideFromParticipants] = useState(
    props.lineItem !== undefined ? props.lineItem.hide_from_participants : false
  );

  const refBtn = useRef(null);

  const dispatch = useDispatch();

  const handleSaveButtonClick = (event) => {
    event.preventDefault();
    disableButton(refBtn.current, translations.saving);
    const payload = {
      line_item: {
        name: newLineItemName,
        lot_id: lotId,
        is_calculation: calcRow,
        hide_from_participants: hideFromParticipants,
      },
    };

    dispatch(
      lineItem === undefined ? createLineItem(lotId, payload) : updateLineItem(lotId, lineItem.id, payload)
    ).then((response) => {
      if (response) {
        setModalIsOpen(false);
      } else {
        enableButton(refBtn.current, translations.save, "<i className='fa fa-check'></i>");
      }
    });
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      className={classNames({
        "modal-content col-md-4 col-sm-4 custom-field-popup": true,
        "modal2-ie": isIE(),
      })}
      appElement={document.getElementById("advanced-lots-body")}
    >
      <form onSubmit={(e) => handleSaveButtonClick(e)}>
        <div className="block  block-transparent remove-margin-b">
          <div className="block-header bg-primary-default">
            <ul className="block-options">
              <li>
                <button type="button" onClick={() => setModalIsOpen(false)}>
                  <i className="ion-close" />
                </button>
              </li>
            </ul>
            <h3 className="block-title">{title}</h3>
          </div>
          <div className="block-content">
            <div className="form">
              <div className="form line-item-form">
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col input form-group">
                        <Tooltip title={line_item_name.title} body={line_item_name.body} />
                        <label htmlFor="lot_name">{translations.name}</label>
                        <span className="required">*</span>
                        <input
                          autoComplete="off"
                          maxLength="30"
                          className="form-control"
                          type="text"
                          id="line_item_name"
                          tabIndex="0"
                          text={newLineItemName}
                          value={newLineItemName}
                          onChange={(e) => setNewLineItemName(e.target.value)}
                          autoFocus={true}
                        />
                      </div>
                    </div>
                  </div>
                  {hideFromParticipantsSwitch && calcRow && (
                    <span className="m-l5">
                      <Tooltip title={lot_hide_from_participants.title} body={lot_hide_from_participants.body} />

                      <label className="css-input css-checkbox css-checkbox-default" htmlFor="is_ranked">
                        <input
                          name="isRanked"
                          className="provider_option"
                          type="checkbox"
                          id="is_ranked"
                          checked={hideFromParticipants}
                          disabled={anyCompletedBids}
                          onChange={(e) => setHideFromParticipants(e.target.checked)}
                        />
                        <span />
                        {translations.hide_from_participants}
                      </label>
                    </span>
                  )}
                </div>

                <div className="modal-footer p-r0" id="lot_component_submit">
                  <button
                    data-dismiss="modal"
                    type="button"
                    className="btn btn-sm btn-default"
                    onClick={() => setModalIsOpen(false)}
                  >
                    {translations.cancel}
                  </button>
                  <button
                    name="button"
                    type="submit"
                    className="btn btn-primary button sm-butt white ok hover"
                    ref={refBtn}
                  >
                    {translations.save} <i className="fa fa-check fa-white" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default LineItemForm;
