import PropTypes from "prop-types";

export const RecipientPropType = {
  user_display_name: PropTypes.string,
  user_id: PropTypes.number,
  email: PropTypes.string,
  company_name: PropTypes.string,
  message_read: PropTypes.bool,
};

export const CompanyPropType = {
  id: PropTypes.number,
  name: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  organisation_type: PropTypes.string,
  background_hex: PropTypes.string,
  text_hex: PropTypes.string,
  subdomain: PropTypes.string,
  domain: PropTypes.string,
  custom_domain: PropTypes.string,
  email_opt_out: PropTypes.bool,
  publically_visible_events: PropTypes.bool,
  publicly_visible_sim_dojo_event: PropTypes.bool,
  publicly_visible_prefix_event: PropTypes.bool,
  api_enabled: PropTypes.bool,
  marketdojo_help_link: PropTypes.any,
  sim_invited_help_link: PropTypes.any,
  sim_portal_help_link: PropTypes.any,
  srm: PropTypes.bool,
  sso_required: PropTypes.array,
  tag_list: PropTypes.array,
};

export const UserPropType = {
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  time_zone: PropTypes.string,
  market_dojo: PropTypes.bool,
  category_dojo: PropTypes.bool,
  sim_dojo: PropTypes.bool,
  quick_quotes: PropTypes.bool,
  user_company_name: PropTypes.string,
  address_line_1: PropTypes.any,
  address_line_2: PropTypes.any,
  town: PropTypes.any,
  state: PropTypes.any,
  country: PropTypes.string,
  postal_code: PropTypes.any,
  accepted_t_and_c: PropTypes.bool,
  company: PropTypes.shape(CompanyPropType),
  locale: PropTypes.string,
  user_roles: PropTypes.oneOf(["Host", "SuperHost"]),
  quick_quotes_licence: PropTypes.bool,
  application_mode: PropTypes.string,
  sign_in_count: PropTypes.number,
  registered: PropTypes.bool,
  per_angusta_enabled: PropTypes.bool,
  per_angusta_attributes: PropTypes.shape({
    company_integration_id: PropTypes.any,
    user_integration_id: PropTypes.any,
  }),
  currency_id: PropTypes.number,
};

export const MessagePropType = {
  id: PropTypes.number,
  user: PropTypes.shape(UserPropType),
  display_name: PropTypes.string,
  message_subject_type: PropTypes.string,
  message_reply_required: PropTypes.any,
  parent_id: PropTypes.any,
  message: PropTypes.string,
  system_message_type: PropTypes.string,
  created_at: PropTypes.string,
  recipients: PropTypes.arrayOf(PropTypes.shape(RecipientPropType)),
  bounced: PropTypes.bool,
  documents: PropTypes.array,
};
