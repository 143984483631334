import React from 'react';
import LoaderMessage from './LoaderMessage';

/**
 * Initial mutable state.
 */
const InitialState = () => ({
  isResolved: false,
});

/**
 * Callable pure component for deferring the nodes rendering
 * @param {Parent props} props
 */
const DeferRenderer = ({translations, ...props}) => {
  const pages = props.nodes.length > props.paginate ? props.nodes.length / props.paginate : 1;
  const component = [];

  const wrapMapper = (innerContent, index) => {
    props.mutator(innerContent, index);
    return props.wrapper(innerContent, index);
  };

  component.push(<React.Fragment key={ 0 }>{props.nodes.slice(0, props.paginate).map(wrapMapper)}</React.Fragment>);

  for (let i = 1; i < pages; i += 1) {
    component.push(
      <Deferred
        key={ i }
        node={ props.nodes.slice(i * props.paginate, (i + 1) * props.paginate).map(wrapMapper) }
        delay={ props.delay * i }
      >
        <LoaderMessage loaderText={translations.loader_text}  />
      </Deferred>
    );
  }

  return component;
};

/**
 * Default props for the callable component.
 */
DeferRenderer.defaultProps = {
  nodes: [],
  delay: 0,
  wrapper(innerContent, index) {
    return <div key={index} className='wrapper'>{innerContent}</div>;
  },
  paginate: 20,
  mutator: () => {},
};

/**
 * Component for async rendering of the node groups
 */
class Deferred extends React.Component {
  constructor() {
    super();
    this.state = InitialState();
  }

  /**
   * LIFECYCLE HOOK
   */
  componentDidMount() {
    const { delay } = this.props;

    setTimeout(() => {
      this.setState({
        isResolved: true,
      });
    }, delay);
  }

  render() {
    const { isResolved } = this.state;
    const { node, children } = this.props;

    if (isResolved) {
      return node;
    }
    if (children) {
      return children;
    }

    return null;
  }
}

/**
 * Default props for async component.
 */
Deferred.defaultProps = {
  delay: 10,
};

export default DeferRenderer;
