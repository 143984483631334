import React from "react";
import { t } from "@/i18n";
import truncate from "@/lib/truncate";
import type { CustomColumnOption, Field } from ".";
import { useSelectAttribute } from "@adeattwood/react-form";
import Feedback from "@/cl/input/feedback";

export type ComponentName =
  | "SrmCustomFieldsForm"
  | "SrmContactCustomFieldsForm"
  | "CustomFieldsForm"
  | "CreateEditContainer";

/**
 * This is an interface for common props, that all type of our custom columns
 * are going to have.
 */
export interface CustomColumnCommonProps {
  field: Field;
  readOnly?: boolean;
}

interface PickOneFromListProps extends CustomColumnCommonProps {}

interface PickOneFromListOptionProps {
  index: number;
  option: CustomColumnOption;
}

const PickOneFromListOption: React.FC<PickOneFromListOptionProps> = ({ index, option }) => {
  const optionName = option.name;
  const value = t(optionName) || optionName;

  return (
    <option key={index} value={optionName} title={value}>
      {truncate(value)}
    </option>
  );
};

const PickOneFromList: React.FC<PickOneFromListProps> = ({ field, readOnly }) => {
  const { props, error } = useSelectAttribute(field.id.toString());

  const options = (
    field.custom_column_options.length ? field.custom_column_options : field.custom_column_values
  ) as CustomColumnOption[];

  return (
    <>
      <select
        {...props}
        className="form-control height-30"
        required={field.required}
        disabled={readOnly || !field.active}
        aria-labelledby={`custom-field-${field.id}-name`}
      >
        <option value="">{t("select")}</option>
        {options.map((option, index) => {
          return <PickOneFromListOption key={index} index={index} option={option} />;
        })}
      </select>
      <Feedback className="required" id={props.id} error={error} />
    </>
  );
};

export default PickOneFromList;
