import * as apiCall from './api';
import config from './config';
import * as globalActionTypes from "../actionsTypes";
import actionTypes from './actionsTypes';

const fetchBidsSuccess = (bids) => ({
  type: actionTypes.fetch_bids,
  payload: bids,
});

const bidsSummarySuccess = (summary) => ({
  type: actionTypes.fetch_bids_summary,
  payload: summary,
});

const fetchMessagesSuccess = (messages) => ({
  type: actionTypes.fetch_messages,
  payload: messages,
});

const fetchParticipantUsersSuccess = (participantUsers) => ({
  type: actionTypes.fetch_messages,
  payload: participantUsers,
});

const getError = (error) => ({
  type: globalActionTypes.ERROR_CODE,
  payload: error,
});

export const fetchBids = (quoteId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/events/${quoteId}/bids`)
    .then((response) => {
      dispatch(fetchBidsSuccess(response.data.bids));
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);

export const bidsSummary = (quoteId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/quotes/${quoteId}/summary`)
    .then((response) => {
      dispatch(bidsSummarySuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);

export const fetchMessages = (eventId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/events/${eventId}/messages`)
    .then((response) => {
      dispatch(fetchMessagesSuccess(response.data.messages));
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);

export const markReadToMessage = (eventId, messageId, payload) => (dispatch) => {
  apiCall.put(`${config.BASE_URL}/messages/${messageId}`, payload)
    .then((response) => {
      dispatch(fetchMessages(eventId));
    })
    .catch((error) => {
      dispatch(getError(error));
    });
};

export const sendMessage = (eventId, data) => (dispatch) => (
  apiCall.post(`${config.BASE_URL}/events/${eventId}/messages`, data)
    .then((response) => {
      dispatch(fetchMessages(eventId));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error;
    })
);
