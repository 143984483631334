import * as globalActionTypes from "../../actions/actionsTypes";
import actionTypes from "../../actions/qq/actionsTypes";

const errorMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case globalActionTypes.ERROR_CODE: {
      switch (action.payload.response.status) {
        case 404:
          return {
            status: 404,
            message:
              action.payload.response.data && action.payload.response.data.error
                ? action.payload.response.data.error
                : "404, something went wrong!",
          };

        case 400:
          return {
            status: 400,
            message:
              action.payload.response.data && action.payload.response.data.error
                ? action.payload.response.data.error
                : "400, Bad request !",
          };

        case 401:
          return {};

        case 403:
          return {
            status: 403,
            message:
              action.payload.response.data && action.payload.response.data.error
                ? action.payload.response.data.error
                : "403, Forbidden access !",
          };

        case 409:
          return {
            status: 409,
            message:
              action.payload.response.data && action.payload.response.data.error
                ? action.payload.response.data.error
                : "409, conflicts in api call !",
          };

        case 500:
          return {
            status: 500,
            message:
              action.payload.response.data && action.payload.response.data.error
                ? action.payload.response.data.error
                : "500, Internal server error!",
          };

        case 503:
          return {
            status: 503,
            message:
              action.payload.response.data && action.payload.response.data.error
                ? action.payload.response.data.error
                : "503, Service Unavailable !",
          };
        default:
          return {
            status: action.payload.response.status,
            message: "Unknown error",
          };
      }
    }

    case actionTypes.show_error:
      return {
        message: action.payload,
      };

    default:
      return state;
  }
};

export default errorMessageReducer;
