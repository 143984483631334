/**
 * Resolves if the parameter container single 
 * or multiple comma seperated values
 * 
 * @param {string} param Incoming get parameter value string
 * @returns {Array}
 */
const _resolveMultipleEntities = (param) => {
    if (param.includes(',')) {
        return param.split(',');
    }

    return [param];
};

const _resolveParams = (params) => {
    const searchParams = new URLSearchParams(params);
    let resolvedParams = [];
    for (let param of searchParams) {
        let match = param[0].match(/\[(.*?)\]/);
        if (match && param[1] && Object.keys(_supportedFilters()).includes(match[1])) {
            resolvedParams.push({
                key: match[1],
                value: param[1]
            });
        }
    }

    return resolvedParams;
}

const _extractDates = (key, dates) => {
    let filter = {};

    if (key === 'created_at' && dates[0]) {
        filter.createdAtFrom = new Date(dates[0]);
    }

    if (key === 'created_at' && dates[1]) {
        filter.createdAtTo = new Date(dates[1]);
    }

    if (key === 'due_date' && dates[0]) {
        filter.dueDateFrom = new Date(dates[0]);
    }

    if (key === 'due_date' && dates[1]) {
        filter.dueDateTo = new Date(dates[1]);
    }

    return filter;
}

/**
 * List of currently supported filters
 * 
 * @returns {Object}
 */
const _supportedFilters = () => ({
    subject: "",
    owners: [],
    status: [],
    activity_type: [],
    priority: [],
    suppliers: [],
    contacts: [],
    due_date: [],
    parent_id: [],
    created_at: [],
    applied: true
});

const _build = {
    activity_type: function (param, { customColumnOptions }, entities, translations = null) {
        let filters = {};

        if (param.key === 'activity_type' || param.key === 'priority') {
            filters[param.key] = [];
            customColumnOptions.map(column => {
                entities.map(entity => {
                    if (column.custom_column_name == param.key && column.name == entity) {
                        filters[param.key].push({
                            id: column.id,
                            text: column.name,
                            translated_text: translations && translations[column.name.toLowerCase()] 
                                ? translations[column.name.toLowerCase()]
                                : column.name
                        });
                    }
                    return entity;
                })
            });
        }

        return filters;
    },
    owners: function (param, { hostOwnerEmails }, entities) {
        let filters = {};

        if (param.key === 'owners') {
            filters[param.key] = [];
            hostOwnerEmails.map(host => {
                entities.map(entity => {
                    if (host.id == entity) {
                        filters[param.key].push({
                            id: host.id,
                            text: host.email
                        });
                    }
                });
            });
        }

        return filters;
    },
    suppliers: function (param, { selectables }, entities) {
        let filters = {};
        if (param.key === 'suppliers' || param.key === 'contacts') {
            filters[param.key] = [];
            selectables[param.key].map(supplier => {
                entities.map(entity => {
                    if (supplier[0] == entity) {
                        filters[param.key].push({
                            id: supplier[0],
                            text: supplier[1]
                        });
                    }
                });
            });
        }

        return filters;
    },
    status: function (param, { statusValues, translations }, entities) {
        let filters = {}
        if (param.key === 'status') {
            filters.status = [];
            entities.map(entity => {
                if (statusValues.includes(entity)) {
                    filters.status.push({
                        id: entity,
                        text: translations[entity]
                    });
                }
            });
        }

        return filters;
    },
    dates: function (param, props, entities) {
        if (param.key === 'created_at' || param.key === 'due_date') {
            return _extractDates(param.key, entities)
        }

        return {};
    }
}

/**
 * Extracts url params from the current URL and converts 
 * to respective filter object to be feed into 
 * ActivitiesTableHeader component
 * 
 * @param {Object} Props Component props 
 * @returns {Object} Filter Object
 */
export const ExtractUrlParams = ({ customColumnOptions, hostOwnerEmails, statusValues, supplierNames, translations, activityConcernedContacts }, params) => {

    let filters = {};

    const selectables = {
        suppliers: supplierNames,
        contacts: activityConcernedContacts
    };

    const resolvedParams = _resolveParams(params)
    for (let i = 0; i < resolvedParams.length; i++) {

        const param = resolvedParams[i];
        const entities = _resolveMultipleEntities(param.value);
        filters = {
            ...filters,
            ..._build.activity_type(param, { customColumnOptions }, entities, translations),
            ..._build.owners(param, { hostOwnerEmails }, entities),
            ..._build.suppliers(param, { selectables }, entities),
            ..._build.status(param, { statusValues, translations }, entities),
            ..._build.dates(param, { statusValues, translations }, entities)
        }

        if(!['created_at', 'due_date', 'status', 'suppliers', 'contacts', 'owners', 'activity_type', 'priority'].includes(param.key)) {
            filters[param.key] = param.value;
        }
    }

    return filters;
};

/**
 * Converts URL parameters to respective filter object for the backend service.
 * 
 * @param {Object} Props Component props
 * @returns {Object}
 */
export const BuildQueryFromParams = ({ custom_column_options }, params) => {
    let filters = {};

    _resolveParams(params).map((urlFilter) => {
        const entities = _resolveMultipleEntities(urlFilter.value);


        if (urlFilter.key === 'activity_type' || urlFilter.key === 'priority') {
            custom_column_options.map(column => {
                entities.map(entity => {
                    if (column.custom_column_name == urlFilter.key && column.name == entity) {
                        if (filters[urlFilter.key]) {
                            filters[urlFilter.key].push(column.name);
                            return entity;
                        }

                        filters[urlFilter.key] = [column.name]
                    }

                    return entity;
                })
            });
        }

        if ((urlFilter.key === 'created_at' || urlFilter.key === 'due_date')) {
            filters[urlFilter.key] = entities.map(date => {
                return new Date(date);
            });
        }

        if (!['created_at', 'due_date', 'activity_type', 'priority'].includes(urlFilter.key)) {
            filters[urlFilter.key] = urlFilter.value;
        }

        return urlFilter;
    });

    return filters;
};
