import React from 'react';
import Select from 'react-select';

export const QQCurrencySelection = function (props) {
    return props.currencies && <div className="checkbox-content-box m-b20">
        <label>
            {props.label}
        </label>
        <Select
            defaultValue={props.input.value}
            options={props.currencies}
            className="resource-type-select"
            isSearchable={true}
            onChange={props.input.onChange}
        />
    </div>
}
