import React, { memo } from "react";
import { useSelector } from "react-redux";
import { blicWeightedScore } from "../../../bidCommon";

/**
 * @description Participant weighted score for LineItemComponent
 * Monitor Tab =>
 * Opens up once clicked on Show Components
 *
 * Column - Weighted Score
 */
const WeightedScore = memo(({ lotComponent, blic, displayScores }) => {
  if (!displayScores) return null;

  const {
    translations: { na_in_small_letters },
  } = useSelector((state) => state.lotReducers);

  const weightedScore = blicWeightedScore(lotComponent, blic, na_in_small_letters);

  return (
    <div className="mycell">
      <span title={weightedScore}>{weightedScore}</span>
    </div>
  );
});

export default WeightedScore;
