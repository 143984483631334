import * as apiCall from "../api";
import config from "../config";
import actionTypes from "../actionsTypes";
import * as globalActionTypes from "../../actionsTypes";

export const fetchBidsSuccess = (bids) => ({
  type: actionTypes.get_bids,
  payload: bids,
});

export const bidsSummarySuccess = (summary) => ({
  type: actionTypes.get_bids_summary,
  payload: summary,
});

export const fetchMessagesSuccess = (messages) => ({
  type: actionTypes.get_messages,
  payload: messages,
});

export const fetchParticipantUsersSuccess = (participantUsers) => ({
  type: actionTypes.get_messages,
  payload: participantUsers,
});

const getError = (error) => ({
  type: globalActionTypes.ERROR_CODE,
  payload: error,
});

export const fetchBids = (requestId) => (dispatch) => {
  apiCall
    .get(`${config.BASE_URL}/events/${requestId}/bids`)
    .then((response) => {
      dispatch(fetchBidsSuccess(response.data.bids));
    })
    .catch((error) => {
      dispatch(getError(error));
    });
};

export const bidsSummary = (requestId) => (dispatch) => {
  apiCall
    .get(`${config.BASE_URL}/events/${requestId}/summary`)
    .then((response) => {
      dispatch(bidsSummarySuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error));
    });
};

export const fetchMessages = (requestId) => (dispatch) => {
  apiCall
    .get(`${config.BASE_URL}/events/${requestId}/messages`)
    .then((response) => {
      dispatch(fetchMessagesSuccess(response.data.messages));
    })
    .catch((error) => {
      dispatch(getError(error));
    });
};

export const markReadToMessage = (requestId, messageId, payload) => (dispatch) => {
  apiCall
    .put(`${config.BASE_URL}/messages/${messageId}`, payload)
    .then((response) => {
      dispatch(fetchMessages(requestId));
    })
    .catch((error) => {
      dispatch(getError(error));
    });
};

export const sendMessage = (requestId, data) => (dispatch) =>
  apiCall
    .post(`${config.BASE_URL}/events/${requestId}/messages`, data)
    .then((response) => {
      dispatch(fetchMessages(requestId));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error;
    });
