/**
 *  maintain connection with Socket IO
 *  @param  {Function}  callback
 *  @param  {Number}  PageRefreshFrequency
 *
 */

export const SocketConnectionHelper = (cb, frequency) => {
  let socketConnectionInitialized = false;
  let socketObject = document.ChartSocket;
  setInterval(function () {
    removeHighlightClass();
  }, 2000);

  // periodically updating the bids when websocket is connected
  setTimeout(function () {
    socketObject = document.ChartSocket;
    if (socketObject && socketObject.connected) {
      socketConnectionInitialized = true;
    } else {
      periodicallyUpdateBids(cb, frequency);
    }
    if (socketObject) {
      socketObject.on("connect", () => {
        if (!socketConnectionInitialized) {
          socketConnectionInitialized = true;
        }
      });
      socketObject.on("message", () => {
        cb();
        addHighlightClass();
      });
      socketObject.on("disconnect", () => {
        periodicallyUpdateBids(cb, frequency);
      });
    }
  }, 1000);
};

// periodically updating the bids when websocket is not connected
const periodicallyUpdateBids = (cb, frequency) => {
  const socketObject = document.ChartSocket;
  if (frequency) {
    let timerId = setTimeout(function periodicUpdate() {
      if (!socketObject || !socketObject.connected) {
        cb();
        addHighlightClass();
        clearTimeout(timerId);
        timerId = setTimeout(periodicUpdate, frequency * 1000);
      }
    }, frequency * 1000);
  }
  addHighlightClass();
};

const addHighlightClass = () => {
  const element = document.getElementById("lot-content");
  element && element.classList.add("lot-content-highlight-alert");
};

const removeHighlightClass = () => {
  const element = document.getElementById("lot-content");
  element && element.classList.remove("lot-content-highlight-alert");
};
