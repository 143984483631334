import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import map from "lodash/map";
import filter from "lodash/filter";
import { NumericFormat } from "react-number-format";
import { selectListAll } from "../../../actions/contract/contractListingActions";
import ContractActions from "./ContractActions";
class ContractList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: props.contracts,
      selectedPage: props.selectedPage,
      contractPossibleCount: 10,
      contractSelected: {},
      stakeholders: {
        ...props.custom_columns_values,
        ...props.parent_stakeholders,
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let contracts = [...nextProps.contracts];
    let temp = [];
    contracts.map((contract) => {
      if (nextProps.currentUserCompany.id == contract.contract_user_company && nextProps.allow_admin_section) {
        temp.push(contract.id);
      }
    });
    if (JSON.stringify(nextProps.contracts) !== JSON.stringify(prevState.contracts)) {
      return {
        contracts: nextProps.contracts,
        contractSelected:
          (nextProps.data && nextProps.data?.ownerChangedData) || nextProps.data?.session || nextProps.data?.reset
            ? {}
            : prevState.contractSelected,
      };
    } else if (nextProps.data && nextProps.data.selectall) {
      if (nextProps.data.selectall.selectAllPage[nextProps.selectedPage]) {
        let tmp = nextProps.data.selectall.contractsSelected;
        tmp[nextProps.selectedPage] = temp;
        return {
          contractSelected: tmp,
        };
      } else {
        return {
          contractSelected: nextProps.data.selectall.contractsSelected,
        };
      }
    }
    return null;
  }

  truncate = (str, length) => {
    return str ? (str.length > length ? str.substring(0, length - 1) + "..." : str) : "-";
  };

  editContract = (contractId, ContractUser, ContractCurrentState) => {
    let { edit_contracts, currentUser } = this.props;
    const check_redirection = this.checkRedirection(contractId, ContractUser, ContractCurrentState);
    if (edit_contracts.includes(contractId) || ContractUser == currentUser.id) {
      return `/contracts/${contractId}/edit`;
    } else if (check_redirection) {
      return `/contracts/${contractId}`;
    } else {
      return null;
    }
  };

  checkRedirection = (contractId, ContractUser, ContractCurrentState) => {
    let { edit_contracts, view_only, currentUser, show_contracts } = this.props;
    let { stakeholders } = this.state;
    const contractViewPermission = show_contracts.includes(contractId) && !edit_contracts.includes(contractId);
    const archivedContractCreatorOrEditor =
      (edit_contracts.includes(contractId) || ContractUser == currentUser.id) && ContractCurrentState == "archived";
    const contractStakeholder = stakeholders[contractId]?.stakeholders.includes(currentUser.id);
    if (view_only || contractStakeholder || contractViewPermission || archivedContractCreatorOrEditor) {
      return true;
    }
    return false;
  };

  generateCustomFieldValues = (contract) => {
    const { custom_columns, custom_columns_values, translations } = this.props;
    return map(custom_columns, (cc) => {
      if (cc.column_type_cd == 5) {
        var value = JSON.parse(custom_columns_values[contract.id]?.[cc.name] || "[]").join(",");
      } else {
        var value = custom_columns_values[contract.id]?.[cc.name];
      }
      return <td key={cc.id}>{translations[custom_columns_values[contract.id]?.[cc.name]] || value || "-"}</td>;
    });
  };

  contract_state_colour = (state) => {
    switch (state) {
      case "active":
        return "green";
      case "expired":
        return "red";
      case "expiring":
        return "yellow";
      case "archived":
        return "grey";
      case "pending":
        return "orange";
      default:
        break;
    }
  };
  contract_user_status = (contract) => {
    const { translations, custom_columns_values, currentUser } = this.props;
    if (contract.user_id == currentUser.id) return translations.owner;
    else if (custom_columns_values[contract.id]?.stakeholders.includes(currentUser.id)) return translations.stakeholder;
    else return translations.permitted;
  };

  contract_span_class = (contract) => {
    const { custom_columns_values, currentUser } = this.props;
    if (contract.user_id == currentUser.id) return "orange-left-border";
    else if (custom_columns_values[contract.id]?.stakeholders.includes(currentUser.id)) return "green-left-border";
    else return "yellow-left-border";
  };

  openConfirmation = (e, contract) => {
    e.preventDefault();
    const { translations } = this.props;
    const { popupTitle, popupText } = handlePopupDetail(contract.current_state, translations);
    this.props.handleDeleteContract(contract, popupTitle, popupText);
    $("#deleteArchiveContract").modal("show");
  };

  changeSelected = (e, id) => {
    const { contracts, currentUserCompany, allow_admin_section, selectedPage, selectListAll, data } = this.props;
    let { contractSelected } = this.state;
    let c = [...contracts];
    let temp = [];
    c.map((contract) => {
      if (currentUserCompany.id == contract.contract_user_company && allow_admin_section) {
        temp.push(contract.id);
      }
    });
    let index;
    if (e.target.checked) {
      if (contractSelected[selectedPage] && contractSelected[selectedPage].length + 1 == temp.length) {
        contractSelected[selectedPage].push(id);
        let temp = data.selectall.selectAllPage;
        temp[selectedPage] = true;
        selectListAll({
          selectAllPage: temp,
          contractsSelected: contractSelected,
        });
      } else {
        if (contractSelected[selectedPage]) {
          contractSelected[selectedPage].push(id);
        } else {
          contractSelected[selectedPage] = [id];
        }
        selectListAll({
          selectAllPage: data.selectall ? data.selectall.selectAllPage : {},
          contractsSelected: contractSelected,
        });
      }
    } else {
      index = contractSelected[selectedPage].indexOf(id);
      contractSelected[selectedPage].splice(index, 1);
      if (contractSelected[selectedPage].length == 0) delete contractSelected[selectedPage];
      temp = data.selectall.selectAllPage;
      delete temp[selectedPage];
      selectListAll({
        selectAllPage: temp,
        contractsSelected: contractSelected,
      });
    }
  };

  render() {
    const {
      translations,
      allow_admin_section,
      sim_event,
      canShow: { data },
      any_non_permitted_contract,
      currentUserCompany,
      custom_columns,
      edit_contracts,
      currentUser,
      selectedPage,
      custom_columns_values,
    } = this.props;
    const { contracts, contractSelected } = this.state;
    let columns = filter(data, (d) => {
      return d;
    });
    return (
      <tbody className="table-content">
        {contracts.length == 0 && (
          <tr>
            <td
              className="no_contracts"
              style={{ height: "200px" }}
              colSpan={2 + columns.length + custom_columns.length}
            >
              <div className="contracts_not_present">{translations.contracts_not_present}</div>
            </td>
          </tr>
        )}
        {contracts.map((contract) => {
          return (
            <tr key={contract.id}>
              <td>
                <span
                  title={this.contract_user_status(contract)}
                  className={this.contract_span_class(contract) + " user-badge"}
                ></span>
              </td>
              {currentUserCompany.id == contract.contract_user_company && allow_admin_section ? (
                <td className="change_owner_box">
                  <input
                    type="checkbox"
                    className="individual_contract"
                    name={contract.id}
                    id={"individual_contract" + contract.id}
                    checked={
                      contractSelected[selectedPage] && contractSelected[selectedPage].includes(contract.id)
                        ? true
                        : false
                    }
                    onChange={(e) => this.changeSelected(e, contract.id)}
                  />
                  <label htmlFor={"individual_contract" + contract.id}></label>
                </td>
              ) : (
                any_non_permitted_contract.includes(currentUserCompany.id) && allow_admin_section && <td></td>
              )}
              {data.owner && (
                <td>
                  <div
                    className="table-cell-ellipsis"
                    title={contract.owner || contract.owner_email}
                    style={{
                      marginLeft:
                        currentUserCompany.id == contract.contract_user_company && allow_admin_section ? "0px" : "6px",
                    }}
                  >
                    {this.truncate(contract.owner || contract.owner_email, 18)}
                  </div>
                </td>
              )}
              {data.title && (
                <td>
                  <a
                    className="orange-link"
                    href={this.editContract(contract.id, contract.user_id, contract.current_state)}
                  >
                    {contract.title}
                  </a>
                </td>
              )}
              {data.parent && (
                <td>
                  {contract.parent ? (
                    this.editContract(contract.parent_id, contract.parent_user_id, contract.parent_current_state) ? (
                      <a
                        className="orange-link"
                        href={this.editContract(
                          contract.parent_id,
                          contract.parent_user_id,
                          contract.parent_current_state
                        )}
                      >
                        {contract.parent}
                      </a>
                    ) : (
                      <span className="non-participants">{contract.parent}</span>
                    )
                  ) : (
                    "-"
                  )}
                </td>
              )}
              {data.category && (
                <td>
                  {(contract.category && (
                    <div title={contract.category}>
                      {contract.category.split(",").map(function (category, index) {
                        return (
                          <span key={index} className="label label-category mr-1 d-block mt-1 text-wrap" title={category}>
                            {category}
                          </span>
                        );
                      })}
                    </div>
                  )) ||
                    "-"}
                </td>
              )}
              {data.contact_company_name && (
                <td>
                  <div className="table-cell-ellipsis" title={contract.contact_company_name}>
                    {contract.contact_company_name || "-"}
                  </div>
                </td>
              )}
              {data.supplier && (
                <td>
                  {contract.sim_ep_id ? (
                    <a
                      className="orange-link"
                      href={`/simdojo/events/${sim_event.id}/event_participants/${contract.sim_ep_id}/contracts`}
                    >
                      {contract.supplier}
                    </a>
                  ) : (
                    <span className="non-participants">{contract.supplier || contract.supplier_user_email || "-"}</span>
                  )}
                </td>
              )}
              {data.reference && (
                <td>
                  <div className="table-cell-ellipsis" title={this.truncate(contract.contract_number, 15)}>
                    {this.truncate(contract.contract_number, 15)}
                  </div>
                </td>
              )}
              {data.auto_renew && <td>{contract.auto_renew ? "Yes" : "No"}</td>}
              {data.start_date && (
                <td>{contract.start_date ? moment(contract.start_date).format("DD MMM YYYY") : "-"}</td>
              )}
              {data.expiry_date && (
                <td className={this.contract_state_colour(contract.current_state)}>
                  {contract.expiry_date ? moment(contract.expiry_date).format("DD MMM YYYY") : "-"}
                </td>
              )}

              {data.annual_value && (
                <ValueDetails
                  id='annualValue'
                  translations={translations}
                  valueType={contract.annual_value}
                  currencySymbol={contract.currency_symbol}
                  value={contract.annual_value}
                />
              )}

              {data.total_value && (
                <ValueDetails
                  id='totalValue'
                  translations={translations}
                  valueType={contract.total_value}
                  currencySymbol={contract.currency_symbol}
                  value={contract.total_value}
                />
              )}
              {data.stakeholders && (
                <td>
                  <div
                    className="table-cell-ellipsis"
                    title={contract.stakeholder_user_name || contract.stakeholder_user_email}
                  >
                    {this.truncate(contract.stakeholder_user_name || contract.stakeholder_user_email, 18)}
                  </div>
                </td>
              )}
              {data.current_state && (
                <td className={this.contract_state_colour(contract.current_state)}>
                  {translations[contract.current_state].toUpperCase()}
                </td>
              )}
              {data.notice_period && (
                <td>{contract.notice_period ? moment(contract.notice_period).format("DD MMM YYYY") : "-"}</td>
              )}

              {custom_columns.length > 0 && this.generateCustomFieldValues(contract)}

              <ContractActions
                contract={contract}
                editContracts={edit_contracts}
                currentUser={currentUser}
                translations={translations}
                openConfirmation={this.openConfirmation}
              />
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export const ValueDetails = (props) => {
  const { id, translations, value, currencySymbol, valueType } = props;
  const { delimiter, separator, precision, currency_position } = translations.number_format;
  return (
    <td id={id}>
      {(valueType && (
        <NumericFormat
          thousandSeparator={delimiter}
          decimalSeparator={separator}
          value={value}
          displayType="text"
          decimalScale={precision}
          fixedDecimalScale={true}
          prefix={currency_position === "prefix" ? currencySymbol : ""}
          suffix={currency_position === "suffix" ? currencySymbol : ""}
        />
      )) ||
        "-"}
    </td>
  );
};

// function to set the correct translations for the confirmation popup
export const handlePopupDetail = (currentState, translations) => {
  const popupTranslations = translations.confirmation_popup_details;
  let popupTitle;
  let popupText;
  if (currentState === "draft") {
    popupTitle = popupTranslations.remove_contract_title;
    popupText = popupTranslations.remove_contract_text;
  } else if (currentState === "archived") {
    popupTitle = popupTranslations.unarchive_contract_title;
    popupText = popupTranslations.unarchive_contract_text;
  } else {
    popupTitle = popupTranslations.archive_contract_title;
    popupText = popupTranslations.archive_contract_text;
  }
  return { popupTitle, popupText };
};

const mapStateToProps = (state) => ({
  data: state.contractListingReducers,
});
const mapDispatchToProps = (dispatch) => ({
  selectListAll: (data) => dispatch(selectListAll(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContractList);
