import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MessageInput from "./MessageInput";
import TabContent from "./TabContent";
import { getTab } from "./Container";
import { isHost } from "@/utils/roles";
// eslint-disable-next-line no-unused-vars
import { ModalBodyPropTypes, TabContentPropTypes } from "./prop-types";

/**
 *
 * @type {React.FC<PropTypes.InferProps<{ tabName: string }>>}
 * @returns {React.ReactElement}
 */
const ModalBodyWrapper = ({ tabName, children }) => (
  <div className="tab-content">
    <div
      className={`tab-pane fade in active`}
      id={tabName}
      role="tabpanel"
      aria-labelledby={tabName}
      data-backdrop={true}
    >
      <div className="modal-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="form message-form" id="new_message">
              <fieldset>{children}</fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

/**
 *
 * @type {React.FC<PropTypes.InferProps<TabContentPropTypes>>}
 * @returns {React.ReactElement}
 */
const TabContentContainer = (props) => {
  const role = useSelector(({ lotReducers }) => lotReducers.role);
  if (!isHost(role)) return null;
  return (
    <div className="tab-content">
      <TabContent {...props} />
    </div>
  );
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<ModalBodyPropTypes>>}
 * @returns {React.ReactElement}
 */
const ModalBody = (props) => {
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);
  const { isNewMessage } = useSelector(({ messageReducers }) => messageReducers.showModal);
  const { message, handleMessageChange, isHostTabSelected } = props;

  const tabName = getTab(isHostTabSelected);

  return (
    <ModalBodyWrapper tabName={tabName}>
      <MessageInput
        label={isNewMessage ? translations.message : translations.yourReply}
        handleMessageChange={handleMessageChange}
        message={message}
      />
      <TabContentContainer {...props} />
    </ModalBodyWrapper>
  );
};

ModalBody.propTypes = ModalBodyPropTypes;
ModalBody.defaultProps = {
  isHostTabSelected: false,
};

export default ModalBody;
