import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import Tooltip from "../../common/Tooltip";
import { uploadTemplate } from "../../../actions/bidActions";
import classNames from "classnames";
import actionsTypes from "../../../actions/actionsTypes";
import { isIE } from "../common";
import { ModalCloseIcon } from "../monitor/UpdateLicCurrentPrice";

export default function BidUploadModal(props) {
  Modal.setAppElement("div");
  const [uploadState, setUploadState] = useState({
    modalIsOpen: false,
    uploading: false,
    bidTemplate: null,
  });

  const { modalIsOpen, uploading, bidTemplate } = uploadState;

  const setState = (_state) => {
    setUploadState({ ...uploadState, ..._state });
  };

  const dispatch = useDispatch();

  function openModal() {
    setState({
      modalIsOpen: true,
    });
  }

  function closeModal() {
    setState({
      modalIsOpen: false,
      bidTemplate: null,
    });
  }

  function handleFileUpload(event) {
    setState({
      bidTemplate: event.target.files[0],
    });
    document.getElementById(`inputfileLabel`).innerHTML = event.target.value.split("\\").pop();
  }

  function handleFormSubmit(ev) {
    ev.preventDefault();
    setState({
      uploading: true,
    });
    const { event, eventParticipant, setLotStateCb, lots } = props;
    const reader = new FileReader();
    let fileObj = {};
    reader.readAsDataURL(bidTemplate);
    let result = undefined;
    reader.onload = () => {
      const obj = {};
      obj.name = bidTemplate.name;
      obj.source = reader.result;
      obj.file_type = bidTemplate.type;
      fileObj = obj;
      dispatch(uploadTemplate({ csv: fileObj, event_participant: eventParticipant.id }, event.id))
        .then((response) => {
          lots.forEach((lot) => {
            setLotStateCb[lot.id]({
              isPlaceBidActive: true,
              isAllDetailView: true,
            });
          });
          setState({
            uploading: false,
            bidTemplate: null,
            modalIsOpen: false,
          });
        })
        .catch((err) => {
          dispatch({ type: actionsTypes.ERROR, error: err.message });
          setState({
            uploading: false,
            bidTemplate: null,
            modalIsOpen: false,
          });
        });
    };
  }

  const { translations } = props;
  const { bid_template } = translations.infos;
  return (
    <>
      <li>
        <a id="upload_bid_template" className="cursor-pointer" onClick={openModal}>
          <i className="fa fa-upload m-r5"></i>
          {translations.upload_bids}
        </a>
      </li>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={classNames({
          "modal-content col-md-4 col-sm-4 custom-field-popup": true,
          "modal2-ie": isIE(),
        })}
      >
        <form onSubmit={handleFormSubmit}>
          <div className="block block-transparent remove-margin-b">
            <div className="block-header bg-primary-default">
              <ModalCloseIcon closeModal={closeModal} />
              <h3 className="block-title">{translations.upload_bids}</h3>
            </div>

            <div className="block-content lot-fieldset">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="upload_bids">
                      {translations.upload_bids}
                      &nbsp;
                      <span className="required">*</span>
                      &nbsp;
                      <Tooltip title={bid_template.title} body={bid_template.body} />
                    </label>
                    <div className="markup m-t10 text-center upload_lot">
                      <input
                        type="file"
                        name="csv"
                        id="csv"
                        className="inputfile inputfile-6 wid-100"
                        onChange={handleFileUpload}
                      />
                      <label htmlFor="csv" className="wid-100">
                        <span id="inputfileLabel"></span>{" "}
                        <strong className="float-right">
                          <i className="fa fa-upload"></i>
                        </strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="input inline">
                <button
                  data-dismiss="modal"
                  type="button"
                  className={classNames({
                    "btn btn-sm btn-default": true,
                    disabled: uploading,
                  })}
                  onClick={closeModal}
                >
                  {translations.cancel}
                </button>
                <button
                  name="button"
                  className={classNames({
                    "btn btn-sm btn-primary": true,
                    disabled: !bidTemplate || uploading,
                  })}
                  type="submit"
                >
                  {uploading ? translations.uploading : translations.upload_bids} <i className="fa fa-check" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
