import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

/**
 * Header wrapper for activity modal.
 *
 * @param {object} props
 * @returns {JSX}
 */
const ModalHeader = ({ checkForChangesAndClose, draftActivity, editBtnOrTxt }) => {
  const { translations } = useSelector((state) => state.ActivityReducer);

  return (
    <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={checkForChangesAndClose}>
        <span className="fa fa-remove" title={translations.close}></span>
      </button>
      <h4 className="modal-title">{draftActivity.subject || translations.new_activity}</h4>
      {draftActivity.parent_subject && (
        <small>
          <b>{translations.parent}:</b> {editBtnOrTxt(draftActivity)}
        </small>
      )}
    </div>
  );
};

ModalHeader.propTypes = {
  draftActivity: PropTypes.object.isRequired,
  checkForChangesAndClose: PropTypes.func.isRequired,
  editBtnOrTxt: PropTypes.func.isRequired,
};

export default ModalHeader;
