import React from "react";

const CellDetail = (props) => {
  const { lotLcs, lotLis, translations, classFromEventState, handleShowDetailsStatus, hideOrShowDetailTitle } = props;
  let cellLink = "";
  if (lotLcs.length > 0 && lotLis.length > 0) {
    cellLink = (
      <a
        title={hideOrShowDetailTitle}
        className={`button small bg-white ok hover white display-items status-event sm-butt ${classFromEventState}`}
        data-line-item-id="All"
        onClick={handleShowDetailsStatus}
      >
        <i className="fa fa-sort-amount-desc" />
        &nbsp;{hideOrShowDetailTitle}
      </a>
    );
  } else if (lotLcs.length == 0 && lotLis.length == 0) {
    cellLink = <div className="message-block">{translations.lot_line_item_empty}</div>;
  } else {
    cellLink = (
      <a
        onClick={handleShowDetailsStatus}
        data-line-item-id="All"
        className={`invisible status-event sm-butt ${classFromEventState}`}
      >
        <i className="fa fa-sort-amount-desc" />
        &nbsp;{translations.details}
      </a>
    );
  }

  return <>{cellLink}</>;
};

export default CellDetail;
