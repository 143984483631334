export const getLocale = () => {
  return document.getElementsByTagName("html")[0].getAttribute("lang");
};

// Datepciker customization to add button for next year and previous year
export const changeYearButtons = function () {
  const inputDate = $(this);
  setTimeout(function () {
    $(".ui-datepicker").css("z-index", 99999);
    var widgetHeader = inputDate.datepicker("widget").find(".ui-datepicker-header");
    var widgetPrev = inputDate.datepicker("widget").find(".ui-datepicker-prev");
    var widgetNext = inputDate.datepicker("widget").find(".ui-datepicker-next");

    var prevYrBtn = $('<a class="prevYr" title="Prev year"><span class="fa fa-angle-double-left"></span></a>');
    prevYrBtn.bind("click", function () {
      $.datepicker._adjustDate(inputDate, -1, "Y");
    });
    var nextYrBtn = $('<a class="nextYr" title="Next year"><span class="fa fa-angle-double-right"></span></a>');
    nextYrBtn.bind("click", function () {
      $.datepicker._adjustDate(inputDate, +1, "Y");
    });
    prevYrBtn.prependTo(widgetHeader);
    nextYrBtn.appendTo(widgetHeader);
    widgetPrev.css({
      left: "45px",
      top: "2px",
    });
    widgetNext.css({
      right: "45px",
      top: "2px",
    });
  }, 1);
};

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overlfow: "scroll",
  },
};
