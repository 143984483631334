import { useEffect, useState } from "react";

/**
 * @description check if the node is intersecting with viewport
 * @param {import('react').MutableRefObject} node
 * @returns {boolean} isIntersecting
 */
export const useNodeIntersection = (node) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!node) return;

    const intersectionCallback = ([element]) => {
      if (element.isIntersecting) setIsIntersecting(true);
      else setIsIntersecting(false);
    };

    const observer = new IntersectionObserver(intersectionCallback);
    observer.observe(node.current);

    return () => observer.unobserve(node.current);
  }, [node]);

  return isIntersecting;
};
