import React, { useState } from "react";
import moment from "moment-timezone";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import omit from "lodash/omit";

import ActivityButton from "./ActivityButton";
import ChildActivities from "./ChildActivities";
import Priority from "./ActivityTableCells/Priority";
import { CellListBuilder } from "./ActivityTableCells/ConcernedParties";
import ParentColumn from "./ActivityTableCells/ParentColumn";
import Status from "./ActivityTableCells/Status";
import HostOwner from "./ActivityTableCells/HostOwner";
import Subject from "./ActivityTableCells/Subject";
import Date from "./ActivityTableCells/Date";
import ActivityType from "./ActivityTableCells/ActivityType";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const activityTableBody = (props) => {
  const {
    activities,
    concernedParties,
    calledFromIndex,
    setActivityId,
    contactId,
    permitted_to_create_activity: permittedToCreate,
    childActions,
    setActivities,
    depth,
  } = props;

  let newProps = omit(props, "activities", "activity");
  moment.updateLocale("utc", { invalidDate: "-" });

  const editBtnOrTxt = (activity) => {
    if (permittedToCreate) {
      return <ActivityButton activityId={activity.id} activitySubject={activity.subject} {...props} />;
    } else {
      return activity.subject;
    }
  };

  return (
    <>
      {activities.map((activity) => {
        const activityConcernedParties = concernedParties.filter((cp) => cp.srm_activity_id === activity.id);
        const filteredCPs = filterConcernedParties(activityConcernedParties, contactId);
        const accountNames = uniqBy(
          filteredCPs[0].map((cc) => {
            return {
              name: cc.account_name,
              id: cc.account_id,
            };
          }),
          "id"
        );
        const contacts = uniq(
          filteredCPs[0].map((cc) => {
            return { name: cc.user_email, id: cc.concerned_id };
          })
        );
        const hostOwners = uniq(filteredCPs[2].map((ho) => ho.user_email));

        const children = childActions.childActivities.filter((a) => a.parent_id === activity.id);
        return (
          <React.Fragment key={activity.id + "-" + activity.subject}>
            <RowWrapper activity={activity} depth={depth}>
              <Subject
                filterActive={props.filterActive}
                activities={activities}
                activity={activity}
                children={children}
                depth={depth}
                isChild={props.isChild}
                childActions={childActions}
                editBtnOrTxt={editBtnOrTxt}
                setActivities={setActivities}
                key={`activity-id-${activity.id}`}
              />
              <HostOwner hostOwners={hostOwners} activityId={activity.id} />
              <Status status={activity.current_state} />
              <ActivityType type={activity.activity_type} />
              <Priority priority={activity.priority} />
              <Date dateTime={activity.created_at} />
              <Date dateTime={activity.due_date} />
              {calledFromIndex && <CellListBuilder items={accountNames} linkPrefix={"/srm/accounts/"} />}
              <CellListBuilder items={contacts} linkPrefix={"/srm/contacts/"} />
              <ParentColumn activity={activity} filterActive={props.filterActive} editBtnOrTxt={editBtnOrTxt} />
              {calledFromIndex && !props.filterActive && (
                <td className="action border-r0">
                  {permittedToCreate && (
                    <div className="text-center">
                      <ActivityButton activityId={activity.id} {...props} />
                      <a
                        className="btn btn-icon"
                        onClick={() => setActivityId(activity.id)}
                        data-toggle="modal"
                        data-target="#activityConfirmModal"
                        rel="nofollow"
                      >
                        <i className="fa fa-trash-o red-color"></i>
                      </a>
                    </div>
                  )}
                </td>
              )}
              {props.filterActive && <td></td>}
            </RowWrapper>
            {children.length > 0 && activity.show_child && (
              <ChildActivities activities={children} activity={activity} {...newProps} depth={(props.depth || 0) + 1} />
            )}
          </React.Fragment>
        );
      })}
      <EmptyRow activities={activities} />
    </>
  );
};

const RowWrapper = ({ children, activity, depth }) => {
  const RowStyle = {
    backgroundColor: !!depth ? "#f5f5f5" : "#fff",
    borderTop: !depth ? "1px solid #ccc" : "none",
  };

  return (
    <tr key={activity.id + "-row-table-" + activity.subject} style={RowStyle}>
      {children}
    </tr>
  );
};

RowWrapper.propTypes = {
  children: PropTypes.array,
  activity: PropTypes.object.isRequired,
  depth: PropTypes.number,
};

RowWrapper.defaultProps = {
  depth: 0,
};

const EmptyRow = ({ activities }) => {
  const { translations } = useSelector((state) => state.ActivityReducer);

  return (
    activities.length === 0 && (
      <tr className="no_activities_present">
        <td colSpan="10" style={{ textAlign: "center", borderRight: "none" }}>
          {translations.no_records}
        </td>
      </tr>
    )
  );
};

EmptyRow.propTypes = {
  activities: PropTypes.array.isRequired,
};

const filterConcernedParties = (concernedParties, contactId) => {
  const contactOwners = [];
  const concernedContacts = [];
  const hostOwners = [];
  for (let count = 0; count < concernedParties.length; count++) {
    let cp = concernedParties[count];
    if (cp.concerned_type == "Srm::Contact" && cp.concerned_id !== contactId) {
      concernedContacts.push(cp);
    } else if (cp.concerned_type == "User" && cp.owner) {
      hostOwners.push(cp);
    }
  }
  return [concernedContacts, contactOwners, hostOwners];
};

export default activityTableBody;
