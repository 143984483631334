import type { ComponentType } from "react";
import React from "react";
import { FilterInputText } from "./filter-input-text";
import { FilterInputNumber } from "./filter-input-number";
import { FilterInputBoolean } from "@/components/contract/new/listings/filter-input-boolean";
import { FilterInputDate } from "@/components/contract/new/listings/filter-input-date";

const FilterComponentMap = {
  text: FilterInputText,
  number: FilterInputNumber,
  boolean: FilterInputBoolean,
  date: FilterInputDate,
} satisfies Record<string, ComponentType<CommonFilterInputProps>>;

export type FilterType = keyof typeof FilterComponentMap;

export interface CommonFilterInputProps {
  attribute: string;
}

interface FilterInputProps extends CommonFilterInputProps {
  filterType: FilterType;
}

export function FilterInput({ attribute, filterType }: FilterInputProps) {
  const Component = FilterComponentMap[filterType];
  if (!Component) {
    return null;
  }

  return <Component attribute={attribute} />;
}
