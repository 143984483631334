import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import type { ContractCellProps, TableColumnWithKey } from "@/components/contract/new/listings/column-definitions";
import { getCustomColumnDefinition, columnDefinitions } from "@/components/contract/new/listings/column-definitions";
import type { Contract } from "@/common-prop-types";
import type { ColumnType, ListingState } from "./types";
import { sortBy } from "@/utils";
import type { CustomFieldType } from "@/common-prop-types/custom-field";
import { assert } from "@/utils/assert";

export const selectFilters = (state: ListingState, isCustom: boolean | undefined) =>
  isCustom ? state.customFilters : state.filters;

export const makeFilterSelector =
  <Type extends ColumnType>(type: Type) =>
  (state: ListingState, name: string, isCustom?: boolean) =>
    selectFilters(state, isCustom)[type][name];

export const contractAdapter = createEntityAdapter<Contract>();
export const contractSelectors = contractAdapter.getSelectors();

export const customColumnAdapter = createEntityAdapter({
  selectId: (column: CustomFieldType) => {
    assert(column.name != null, "Custom column must have a name");
    return column.name;
  },
});
export const customColumnSelectors = customColumnAdapter.getSelectors<ListingState>((state) => state.customColumns);

const createListingSelector = createSelector.withTypes<ListingState>();

// we make a Set because .has is much faster than .includes
const selectFilteredIdsAsSet = createListingSelector(
  [(state) => state.filteredIds],
  (filteredIds) => filteredIds && new Set(filteredIds)
);

export const filteredContractsSelector = createListingSelector(
  [contractSelectors.selectAll, selectFilteredIdsAsSet, (state) => state.showArchived],
  (allContracts, filteredIds, showArchived) =>
    allContracts.filter((contract) => {
      const isFiltered = filteredIds ? filteredIds.has(contract.id) : true;
      const isArchived = showArchived || contract.current_state !== "archived";
      // TODO: better naming?
      return isFiltered && isArchived;
    })
);

export const sortedContractsSelector = createListingSelector(
  [filteredContractsSelector, (state) => state.sort, (state) => state.customColumnValues],
  (filteredContracts, sort, customColVals) =>
    sort
      ? filteredContracts
          .slice()
          .sort(
            sortBy(
              (contract): unknown => (sort.isCustom ? customColVals[contract.id][sort.column] : contract[sort.column]),
              sort.direction
            )
          )
      : filteredContracts
);

/**
 * Selects all of the contracts to show in the contract table. This will be a
 * sorted filtered, and paginated list of contracts. You can take this data and
 * dump it straight into the UI
 */
export const contractSelector = createListingSelector(
  [sortedContractsSelector, (state) => state.pagination],
  (filteredContracts, pagination) => {
    const cursor = pagination.perPage * pagination.page;

    return filteredContracts.slice(cursor - pagination.perPage, cursor);
  }
);

/**
 * Selects all of the contract columns that are currently active. These are the
 * columns that will show on the listings page. The user can configure this in
 * the company contract settings.
 */
export const columnSelector = createListingSelector(
  [(state) => state.contractColumns.data, customColumnSelectors.selectAll],
  (columns, customColumns) => {
    const baseColumns: TableColumnWithKey<Contract, ContractCellProps>[] = [
      { ...columnDefinitions.firstColumn, key: "firstColumn" },
      { ...columnDefinitions.checkbox, key: "checkbox" },
    ];

    const columnsToRender = Object.entries(columns).reduce((acc, [key, value]) => {
      if (value && columnDefinitions[key]) {
        acc.push({ ...columnDefinitions[key], key });
      }

      return acc;
    }, baseColumns);

    customColumns.forEach((customColumn) => {
      if (customColumn.column_type_cd == null || customColumn.name == null) {
        return;
      }
      columnsToRender.push({
        ...getCustomColumnDefinition(customColumn),
        key: customColumn.name,
      });
    });

    columnsToRender.push({ ...columnDefinitions.actions, key: "actions" });

    return columnsToRender;
  }
);

/**
 * Get all of the options for a given contract attribute. This will be any of
 * the values assigned to the contracts. This will prevent ppl making a search
 * and it returning no results
 */
export const columnOptionsSelector = createListingSelector(
  [
    contractSelectors.selectAll,
    (_, attribute: string) => attribute,
    (state) => state.customColumnValues,
    (_, __, isCustom?: boolean) => isCustom,
  ],
  (contracts, attribute, customColVals, isCustom) =>
    Array.from(
      contracts
        .reduce<Map<string, { id: string; text: string }>>((acc, contract) => {
          const item = isCustom ? customColVals[contract.id][attribute] : contract[attribute as keyof Contract];
          /* eslint-disable @typescript-eslint/no-unsafe-argument */
          if (item && !acc.get(item)) {
            acc.set(item, { id: item, text: item });
          }
          /* eslint-enable @typescript-eslint/no-unsafe-argument */

          return acc;
        }, new Map())
        .values()
    )
);

type SelectionState = "none" | "some" | "all";

export const selectionAsArraySelector = createListingSelector([(state) => state.selected], (selected) =>
  Object.keys(selected).map(Number)
);

export const selectionLengthSelector = (state: ListingState) => selectionAsArraySelector(state).length;

export const selectionStateSelector = createListingSelector(
  [selectionLengthSelector, (state) => state.filteredIds?.length ?? contractSelectors.selectTotal(state)],
  (selectedLength, totalLength): SelectionState => {
    if (selectedLength === 0) {
      return "none";
    }
    if (selectedLength >= totalLength) {
      return "all";
    }
    return "some";
  }
);
