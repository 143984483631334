import React from "react";
import PropTypes from "prop-types";

/**
 * A big json file with all the available icons in.
 *
 * To view all the json files you can visit `https://icons.getbootstrap.com/`.
 * To produce the json file from the website you can open the inspector and
 * run the following code in the console.
 *
 * ```js
 * JSON.stringify([...document.querySelectorAll('.name')].map(item => {return {name: item.innerText, className: 'bi-' + item.innerText}}));
 * ```
 *
 * @see https://icons.getbootstrap.com/
 */
import icons from "./icons.json";

const IconPropTypes = {
  name: PropTypes.oneOf(icons.map((icon) => icon.name)),
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof IconPropTypes>>}
 */
export const Icon = ({ name }) => {
  const foundIcon = icons.find((item) => item.name === name);
  if (!foundIcon) return null;

  return <i className={foundIcon.className} />;
};

Icon.propTypes = IconPropTypes;

export default Icon;
