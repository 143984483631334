import React from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import reject from "lodash/reject";
import union from "lodash/union";
import range from "lodash/range";
import isObject from "lodash/isObject";
import moment from "moment-timezone";
import iso3166 from "iso-3166-2";

import {
  isWeightedRfqOrAuction,
  noChangeBidChange,
  bidBidChange,
  isBeforeAuction,
  lotsUserBidInRound,
} from "./eventCommon";
import { roundValue, preferredQuantity, participantActiveInJapaneseAuction, showRankOfCell } from "./bidCommon";

import leadParticipantModalPropsData from "./data/leadParticipantModalProps.json";

export const classesFromEventState = (event, autoWidthBtn = true) => {
  let htmlClasses = autoWidthBtn ? "auto_width_btn " : "";
  if (cancelledOrClosed(event)) {
    htmlClasses += "closed";
  } else if (event.current_state == "new") htmlClasses += "draft";
  else {
    htmlClasses += "current";
  }
  return htmlClasses;
};

export const lotLotComponents = (lot, lotComponents) => {
  return lotComponents.filter((lotComponent) => lotComponent.lot_id == lot.id);
};

export const lotLineItems = (lot, lineItems) => {
  return lineItems.filter((lineItem) => lineItem.lot_id == lot.id);
};

export const lotBids = (lotId, bids) => {
  return bids.filter((bid) => bid.lot_id == lotId);
};

export const hasAnyCompletedBids = (bids) => {
  return bids.some((bid) => bid.current_state === "submitted");
};

export const findLcLineItemComponents = (lcId, lics) => {
  return lics.filter((lic) => lic.lot_component_id == lcId);
};

export const findLiLineItemComponents = (li, lics) => {
  return lics.filter((lic) => lic.line_item_id == li.id);
};

export const lotHasParticipantPrice = (lics) => {
  return lics.some((lic) => lic.participant && [0, 2, 4].includes(lic.lot_component_type));
};

export const LineItemComponentsExist = (lc, lics) => {
  return lics.some((lic) => lic.lot_component_id == lc.id);
};

export const lotTotalHasQualificationPrice = (lot, lics) => {
  if (lot.lot_total_lic_id) {
    const lot_lic = lics.find((lic) => lic.id == lot.lot_total_lic_id);
    return lot_lic.qualification_price ? true : false;
  }
};

export const lotLineItemComponents = (lotComponents, lics = []) => {
  const lcIds = lotComponents.map((lc) => lc.id);
  return lics.filter((lic) => lcIds.includes(lic.lot_component_id));
};

export const isLotTotal = (lot, lic) => {
  return lot.lot_total_lic_id === lic.id;
};
export const isLotTotalComponent = (lics, lotTotalLic) => lics.map((lic) => lic.id).includes(lotTotalLic);

/**
 *
 * @param {Number} maxCount
 * @param {Array} lics
 * @returns {Array}
 */
export const rankedLineItemComponents = (lics = []) => {
  return lics.filter((lic) => lic.is_ranked);
};

/**
 *
 * @param {Number} maxCount
 * @param {Array} lics
 * @returns {Boolean}
 */
export const canAddRankedCells = (maxCount, lics = []) => {
  return remainingRankedCells(maxCount, lics) > 0;
};

/**
 *
 * @param {Number} maxCount
 * @param {Array} lics
 * @returns {Number}
 */
export const remainingRankedCells = (maxCount, lics = []) => {
  const rankedLics = rankedLineItemComponents(lics);
  return maxCount - rankedLics.length;
};

/**
 *
 * @param {Number} maxCount
 * @param {Array} lics
 * @returns {Boolean}
 */
export const canAddCellsForBidAtDetailLevel = (maxCount, lics = []) => {
  return remainingCellsForBidAtDetailLevel(maxCount, lics) > 0;
};

/**
 *
 * @param {Number} maxCount
 * @param {Array} lics
 * @returns {Number}
 */
export const remainingCellsForBidAtDetailLevel = (maxCount, lics = []) => {
  return maxCount - lics.length;
};

// Lot Component
export const isFormulaCalculationComponent = (lc) => lc && isPrice(lc) && calculation(lc);

// For lot_component_type 'LC' can be lot_component or line_item_component
export const isPrice = (lc) => lc.lot_component_type === 0;
export const isText = (lc) => lc.lot_component_type === 1;
export const isDecimal = (lc) => lc.lot_component_type === 2;
export const isDate = (lc) => lc.lot_component_type === 3;
export const isPicklist = (lc) => lc.lot_component_type === 4;

export const host = (lc) => lc.provider_cd === 0;
export const participant = (lc) => lc.provider_cd === 1;
export const calculation = (lc) => lc.provider_cd === 2;
export const hideLic = (lic = {}) => lic && lic.is_calculation && !lic.calculable_lc;

export const calculableCurrencyLc = (lc, picklists) => {
  return isPrice(lc) || (isPicklist(lc) && isCurrencyPicklist(lc, picklists));
};

export const isCurrencyPicklist = (lc, picklists) => {
  const picklist = picklists.find((pick) => pick.id === lc.picklist_id && pick.picklist_type === 1);
  return isPicklist(lc) && picklist;
};

export const calculableLc = (lc, picklists) =>
  isPrice(lc) || isDecimal(lc) || (isPicklist(lc) && picklistForCalculation(lc, picklists));

export const picklistForCalculation = (lc, picklists) => {
  const picklist = picklists.find((pick) => pick.id === lc.picklist_id && [1, 2].includes(pick.picklist_type));
  return isPicklist(lc) && picklist;
};

// Line Item
export const hasCalculationRowAdded = (lineItems) => {
  return lineItems.some((lineItem) => lineItem.is_calculation);
};

// Line Item Component

export const findLIC = (lc, li, lics) => {
  return lics.find((lic) => lic.lot_component_id == lc.id && lic.line_item_id == li.id);
};

export const isFormulaLic = (li, lc) => {
  return isFormulaCalculationComponent(lc) || li.is_calculation;
};

export const isDefaultFormula = (li, lc, lic) => {
  let result = false;
  if (isFormulaCalculationComponent(lc)) {
    result = lc.default_formula_lic_id == lic.id;
  } else if (li.is_calculation) {
    result = li.default_formula_lic_id == lic.id;
  }
  return result;
};

export const lotTotalDroppableClass = (lic, lot, lics, lots, validCell) => {
  let result = "";
  if (lic) {
    if (lot.is_event_total) {
      result =
        lic &&
        (lic.formula === "" ||
          lic.formula == null ||
          (lic.formula && validCell && anyCommonTagForTotal(lic, lics, lots).length));
    } else {
      result = lic && lic.is_price && (validCell || (!lic.is_calculation && lic.participant));
    }
    return result ? "droppable" : "";
  }
  return result;
};

export const anyCommonTagForTotal = (lic, lics, lots) => {
  return union(cellsUsedInFormula(lic), nonLotTotalReferenceTags(lics, lots));
};

export const priceLineItemComponents = (lics) => {
  return lics.filter((lic) => lic.is_price);
};
export const nonLotTotalReferenceTags = (lics, lots) => {
  const lotTotalLicIds = lots.filter((lot) => lot.lot_total_lic_id).map((lot) => lot.lot_total_lic_id);
  const priceLics = priceLineItemComponents(lics);
  return priceLics.filter((lic) => !lotTotalLicIds.includes(lic.id)).map((lic) => lic.tag);
};

export const cellsUsedInFormula = (lic) => {
  lic.formula;
};
export const fetchAssociatedCurrency = (currenciesHash, lot, lic, lc) => {
  let er_id;

  if (lic && lic.exchange_rate_id) {
    er_id = lic.exchange_rate_id;
  } else if (lc && lc.default_exchange_rate_id) {
    er_id = lc.default_exchange_rate_id;
  } else {
    er_id = lot.exchange_rate_id;
  }
  if (er_id) {
    return currenciesHash[er_id];
  } else {
    return currenciesHash["default"];
  }
};

// Scenarios for currentValue, qualificationValue and usedValue:
// - If state is present then use the value from state instead of lic object so we can show realtime update on the disabled field
// - If the user enters '.' or ',' only in the field then don't show NaN instead return it blank
// - Return blank in any case where the value is not valid or value is null (not entered by user)

export const currentValue = (lic, state = {}) => {
  let result;
  if (lic) {
    const totalCurrentValue = isEmpty(state) ? lic.total_current_value : state.totalCurrentVal;
    const currentPrice = isEmpty(state) ? lic.current_price : state.currentPrice;
    const quantity = isEmpty(state) ? lic.quantity : state.quantity;
    if (![null, undefined].includes(totalCurrentValue)) {
      result = totalCurrentValue;
    } else if (![null, undefined, ",", ".", ""].includes(currentPrice)) {
      result = currentPrice * quantity;
    }
  }
  return result;
};

export const qualificationValue = (lic, state = {}) => {
  let result;
  if (lic) {
    const totalQualificationValue = isEmpty(state) ? lic.total_qualification_value : state.totalQualificationVal;
    const qualificationPrice = isEmpty(state) ? lic.qualification_price : state.qualificationPrice;
    const quantity = isEmpty(state) ? lic.quantity : state.quantity;
    if (![null, undefined].includes(totalQualificationValue)) {
      result = totalQualificationValue;
    } else if (![null, undefined, ",", ".", ""].includes(qualificationPrice)) {
      result = qualificationPrice * quantity;
    }
  }
  return result;
};

export const usedValue = (lic, state = {}) => {
  let result;
  const totalUsedValue = isEmpty(state) ? lic.total_used_value : state.totalUsedVal;
  const usedPrice = isEmpty(state) ? lic.used_price : state.usedPrice;
  const quantity = isEmpty(state) ? lic.quantity : state.quantity;
  if (![null, undefined].includes(totalUsedValue)) {
    result = totalUsedValue;
  } else if (![null, undefined, ",", ".", ""].includes(usedPrice)) {
    result = usedPrice * quantity;
  }
  return result;
};

export const isRankedCell = (lic, host = true) => {
  return host ? lic.is_ranked : lic.is_ranked && lic.is_visible_rank;
};

export const isRanked = (lic) => lic.is_ranked;
export const isVisibleRank = (lic) => lic.is_visible_rank;
export const showLocalisedStrippedDecimal = (amount, locale, precision = 2) => {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: precision,
  });
  return formatter.format(amount);
};

export const showPicklistValue = (lic, currency, translations, locale) => {
  if (lic.is_formula_lic && lic.current_value) {
    if (lic.calculable_currency_lc) {
      return showCurrency(lic.current_value, currency, lic.decimal_place, locale, translations.number_format);
    } else {
      return lic.current_value && showLocalisedStrippedDecimal(lic.current_value, locale);
    }
  } else {
    return lic.value || translations.enter_info;
  }
};

export const formatDatetime = (dateValue, timeZoneIdentifier) => {
  if (dateValue) {
    return moment.tz(dateValue, timeZoneIdentifier).format("DD MMM H:mm");
  }
};

export const showDateTime = (dateValue, timeZoneIdentifier) => {
  let result = "-";
  if (dateValue) {
    result = moment.tz(dateValue, timeZoneIdentifier).format("MMMM DD, YYYY H:mm z");
  }
  return result;
};

export const linkContent = (lic, translations, locale, timeZoneIdentifier, currency) => {
  let result;
  const currentVal = lic.current_value;

  switch (componentType(lic.lot_component_type)) {
    case "isPrice":
      if (currentVal || currentVal === 0) {
        const currentRoundValue = roundValue(lic.value, lic.decimal_place);
        result = showCurrency(
          currentRoundValue,
          currency,
          lic && !["", null, undefined].includes(lic.decimal_place) ? lic.decimal_place : 2,
          locale,
          translations.number_format
        );
      } else {
        result = translations.enter_info;
      }
      break;
    case "isText":
      result = lic.value || translations.enter_info;
      break;
    case "isDecimal":
      if (lic.value && lic.value >= 0) {
        const formatter = new Intl.NumberFormat(locale, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 6,
        });
        result = formatter.format(lic.value);
      } else {
        result = translations.enter_info;
      }
      break;
    case "isDate":
      result = lic.value ? formatDatetime(lic.value, timeZoneIdentifier) : translations.enter_info;
      break;
    case "isPicklist":
      const val = lic.value;
      if (lic.is_formula_lic && val) {
        if (lic.calculable_currency_lc) {
          result = showCurrency(val, currency, lic.decimal_place, locale, translations.number_format);
        } else {
          result = val && showLocalisedStrippedDecimal(val, locale);
        }
      } else {
        result = val || translations.enter_info;
      }
      break;
  }
  return result;
};

export const componentType = (lcType) => {
  switch (lcType) {
    case 0:
      return "isPrice";
    case 1:
      return "isText";
    case 2:
      return "isDecimal";
    case 3:
      return "isDate";
    case 4:
      return "isPicklist";
    default:
      return "";
  }
};

export const getFormula = (lic) => lic.formula && lic.formula.replace(new RegExp(",", "g"), "");

export const stateDisplayText = (field, translations) => {
  return field ? translations.yes : translations.no;
};

export const showCurrency = (amount, currency, precision, locale, numberFormat) => {
  let value = "-";
  if (amount === "-" && currency) {
    value = currencyPosition(amount, currency, numberFormat);
  } else if (amount >= 0 && currency) {
    const opts = {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    };
    const formatter = new Intl.NumberFormat(locale, opts);
    value = formatter.format(amount);
    value = currencyPosition(value, currency, numberFormat);
  }
  return value;
};

export const currencyPosition = (value, currency = {}, numberFormat) => {
  if (numberFormat && numberFormat.currency_position === "suffix") {
    return `${value} ${currency.symbol}`;
  } else {
    return `${currency.symbol}${value}`;
  }
};

export const stripInsignificantZeros = (num, precision) => {
  if (num) {
    return num.toFixed(precision);
  }
};

export const findUom = (uomId, unitOfMeasures = []) => {
  return unitOfMeasures.find((uom) => uom.id == uomId) ?? {};
};

export const convertQuantity = (currentUom = {}, toUomId, quantity, unitOfMeasures, decimalPlace = 2) => {
  const toUom = findUom(toUomId, unitOfMeasures);
  let result;
  if (toUom.id === currentUom.id) {
    result = quantity;
  } else {
    result = (toUom.ratio / currentUom.ratio) * quantity;
  }
  return result.toFixed(decimalPlace);
};

/**
 * @description check if event needs a qualification price
 * @param {import("prop-types").InferProps<typeof import("@/common-prop-types/event").EventPropType>} event
 * @returns {boolean}
 */
export const needsQualificationPrice = (event) => {
  const isEventJapanese = isJapaneseEvent(event);
  return isEventJapanese || event.price_weighting_calculation_type == "pro_rata";
};

export const licPicklistOptions = (picklistOptions) => {
  let result = [];
  picklistOptions.map((option) =>
    result.push({
      text: option.value,
      id: option.value,
      picklist_option_id: option.id,
    })
  );
  return result;
};

export const licsForFormulaWithLot = (lots, lot, lic, finalLics, isLotTotalLic) => {
  if (lot.is_event_total && lots.length) {
    return filterLicsForEventTotalFormula(lot, lic, finalLics, lots, isLotTotalLic);
  } else {
    return { [lot.id]: filterLicsForFormula(finalLics) };
  }
};

export const filterLicsForFormula = (finalLics) => {
  let lineItemComponents = {};
  finalLics.forEach((liComponent) => {
    if (lineItemComponents.hasOwnProperty(liComponent.line_item_id)) {
      lineItemComponents[liComponent.line_item_id].push(liComponent);
    } else {
      lineItemComponents[liComponent.line_item_id] = [liComponent];
    }
  });

  return lineItemComponents;
};

export const filterLicsForEventTotalFormula = (lot, lic, finalLics, lots, isLotTotalLic) => {
  lots = reject(lots, function (l) {
    return l.id === lot.id;
  });
  let result = {};
  lots.forEach((l) => {
    let lineItemComponents = {};
    if (isLotTotalLic) {
      finalLics.forEach((liComponent) => {
        if (l.lot_total_lic_id === liComponent.id) {
          lineItemComponents[lic.line_item_id] = new Array(liComponent);
        }
      });
      result[l.id] = lineItemComponents;
    } else {
      const lotLics = finalLics.filter((liComponent) => liComponent.lot_id === l.id);
      result[l.id] = filterLicsForFormula(lotLics);
    }
  });
  return result;
};

export const splitFormula = (formula) => {
  return formula
    ? reject(formula.split(","), function (i) {
        return !i;
      })
    : [];
};

export const tagClasses = (tag, lotTotalLicTags) => {
  if (isOperator(tag)) {
    return "btn btn-default";
  } else if (Number(tag)) {
    return "value number btn btn-default";
  } else if (lotTotalLicTags.includes(tag)) {
    return "btn btn-default value calc lot_total";
  } else {
    return "btn btn-default value btn btn-default calc";
  }
};

export const isOperator = (tag) => [")", "(", "/", "*", "-", "+"].includes(tag);
export const isNumber = (tag) => /^-?\d*\.?\d*$/.test(tag);

export const eventTotalLot = (lots) => {
  if (lots !== undefined) {
    return lots.filter((lot) => lot.is_event_total)[0];
  }
};

export const currenciesForSelect = (currenciesHash) => {
  let currencyValues = [];
  Object.keys(currenciesHash).map((key) =>
    currencyValues.push({
      id: key,
      text: currenciesHash[key].name,
    })
  );
  return currencyValues;
};
export const findUnitSet = (unitSets, unitSetId) => {
  return unitSets?.find((unitSet) => unitSet.id === unitSetId);
};

export const unitOfMeasureOptions = (uoms) => {
  return uoms.map((uom) => {
    return {
      text: uom.name,
      id: uom.id,
    };
  });
};

export const unitOfMeasures = (unitSetUoms, unitSetId) => {
  if (unitSetUoms !== undefined && unitSetUoms.length > 0) {
    return unitSetUoms.filter((uom) => uom.unit_set_id === unitSetId);
  } else {
    return [];
  }
};

export const eventStatusClass = (event) => {
  let eventClass;
  if (event.current_state === "new") {
    eventClass = "event-draft";
  } else if (cancelledOrClosed(event)) {
    eventClass = "event-closed";
  } else {
    eventClass = "event-current";
  }
  return eventClass;
};

export const cancelledOrClosed = (event) => {
  return ["cancelled", "closed"].includes(event.current_state);
};

export const anyComplexLotsExist = (lots) => {
  return lots.some((lot) => lot.complex_structure);
};

export const htmlClassWithParticipant = (event, eventParticipants, lots) => {
  const epLength = eventParticipants.length;
  let classes;
  if (anyComplexLotsExist(lots)) {
    const eventType = event.event_type === "Japanese" ? "japanese-" : "";
    const weighted = isWeightedRfqOrAuction(event) ? "weighted" : "";
    if (epLength < 3) {
      classes = `advance-${eventType}part-${epLength} ${weighted}`;
    } else if (epLength >= 3) {
      classes = `advance-${eventType}many-part ${weighted}`;
    }
  } else {
    if (epLength < 3) {
      classes = `simple-part-${epLength}`;
    } else if (epLength >= 3) {
      classes = "simple-many-part";
    }
  }
  return classes;
};

export const canShowExpandButtons = (event, beforeAuction) => {
  return beforeAuction || event.bid_at_detail_level || !["running", "pause"].includes(event.current_state);
};

export const smallOrMiniCell = (weightedRfqOrAuction) => (weightedRfqOrAuction ? "mini-cell" : "small-cell");

export const eventParticipantUser = (participants, userId) => {
  return participants.find((participant) => participant.id === userId);
};

export const activeEventParticipants = (eventParticipants) => {
  return eventParticipants.filter((ep) => {
    return ep.disable_access !== true && ep.accepted !== false;
  });
};

export const fullCompanyNameOrEmail = (user = {}) => {
  const userCompanyName = user.user_company_name ?? "";
  if (userCompanyName && userCompanyName !== "-") {
    return userCompanyName;
  } else {
    return user.email ?? "";
  }
};

export const companyNameOrEmail = (user = {}) => {
  return fullCompanyNameOrEmail(user)?.slice(0, 21);
};

export const multiCurrencyEvent = (event) => event.multi_currency_event;

export const lotQualificationValue = (lot, checkZero = false) => {
  if (lot.qualification_price || (checkZero && lot.qualification_price === 0)) {
    return lot.qualification_price * lot.quantity;
  }
};
export const lotCurrentValue = (lot, checkZero = false) => {
  if (lot.current_price || (checkZero && lot.current_price === 0)) {
    return lot.current_price * lot.quantity;
  }
};

export const findLotTotalLic = (lics, totalLicId) => {
  return lics.find((lic) => lic.id === totalLicId);
};

export const isReverseDirection = (event) => {
  return event.event_direction === "Reverse";
};

export const findLotComponent = (lotComponents, lcId) => {
  return lotComponents.find((lotComponent) => lotComponent.id === lcId);
};

export const findLineItem = (lineItems, lcId) => {
  return lineItems.find((lineItem) => lineItem.id === lcId);
};

export const differenceOfferedForHostEnteredLic = (event, lic, li, lc) => {
  const val = isFormulaLic(li, lc) ? qualificationValue(lic) : usedValue(lic);
  let res;
  const currentVal = currentValue(lic);
  if (!currentVal || !val) {
    res = "-";
  } else if (isReverseDirection(event)) {
    res = currentVal - val;
  } else {
    res = val - currentVal;
  }
  if (res != "-") {
    res = res.toFixed(2);
  }
  return res;
};
export const differenceOfferedPercentageForLic = (event, lic, li, lc) => {
  const val = differenceOfferedForHostEnteredLic(event, lic, li, lc);
  let result;
  if (val === "-") {
    result = "-";
  } else {
    const currentVal = currentValue(lic);
    if (currentVal && currentVal !== 0) {
      result = ((val / currentVal) * 100).toFixed(2);
    } else {
      result = "-";
    }
  }
  return result;
};

export const disableButton = (ref, text) => {
  ref.setAttribute("disabled", "disabled");
  ref.innerHTML = text;
};

export const enableButton = (ref, text, icon = undefined) => {
  if (ref) {
    ref.removeAttribute("disabled");
    text += icon && " " + icon;
    ref.innerHTML = text;
  }
};

// Returns Savings/Profit translation string based on event direction
export const savingsOrProfit = (event, translations) => {
  const { savings, profit } = translations;
  return isReverseDirection(event) ? savings : profit;
};

export const showTextWhenNoRank = (lic, naInSmallLetters) => {
  let result = naInSmallLetters;
  if (lic) {
    result = isRanked(lic) ? "-" : naInSmallLetters;
  }
  return result;
};

export const isValidCell = (lic) => {
  if (lic.host) {
    return false;
  } else if (lic.is_price && lic.calculation && lic.valid_cell) {
    return true;
  } else if (lic.calculation) {
    return false;
  } else {
    return (
      lic.is_calculation &&
      !lic.calculation &&
      splitFormula(lic.formula)
        .map((tag) => !isOperator(tag) && !isNumber(tag))
        .includes(true)
    );
  }
};
export const eventParticipantStatus = (ep) => {
  let userStatus;
  if (ep.accepted && ep.participating) {
    userStatus = "Active";
  } else if (
    (ep.accepted === false && ep.participating === false) ||
    (ep.accepted === false && (ep.participating === true || !ep.participating))
  ) {
    userStatus = "Declined";
  } else {
    userStatus = "Pending";
  }

  return userStatus;
};

// Format percentage numbers
// Sometimes they come up with special chars
// and so need to display them as is.
export const formatPercentileNumber = (value, locale) => {
  const specialValues = ["-%", "-", "%"];
  if (specialValues.includes(value)) {
    return value;
  } else if (value || value == 0) {
    return `${value.toLocaleString(locale, { minimumFractionDigits: 2 })} %`;
  }
};

export const bidRoundPrice = (lot, event, bidRound, auctionControls) => {
  // # This is for Japanese auctions only !!
  let res = 0;
  const qualPrice = lot.qualification_price;

  if (bidRound === 0 || bidRound === 1) {
    res = qualPrice;
  } else {
    let change = 0;
    const noBidChange = noChangeBidChange(auctionControls);
    // If we are calculating the change from last bid,
    // we can use a calc of compound interest
    if (event.bid_change_from == "latest_bid") {
      if (noBidChange) {
        res = isReverseDirection(event)
          ? qualPrice * (1 - event.bid_change / 100.0) ** (bidRound - 1)
          : qualPrice * (1 + event.bid_change / 100.0) ** (bidRound - 1);
      } else {
        res = qualPrice;
        range(2, bidRound).map((round) => {
          change = res * (bidBidChange(auctionControls, event, round) / 100);
          res = isReverseDirection(event) ? res - change : res + change;
        });
      }
      // If we are calculating the change from qualification,
      // we can just multiply the % of qual. by the round
    } else if (event.bid_change_from === "qualification_price") {
      if (noBidChange) {
        change = qualPrice * (event.bid_change / 100) * (bidRound - 1);
      } else {
        range(1, bidRound).map((round) => {
          change += qualPrice * (bidBidChange(auctionControls, event, round) / 100);
        });
      }
      res = isReverseDirection(event) ? qualPrice - change : qualPrice + change;
    }
  }
  return res;
};

export const bidRoundValue = (lot, event, bidRound, auctionControls) => {
  const quantity = lot && lot.quantity ? lot.quantity : 1;
  return !isNaN(parseFloat(lot.price))
    ? roundValue(parseFloat(lot.price) * quantity)
    : bidRoundPrice(lot, event, bidRound, auctionControls);
};

export const roundBidControls = (bidControls, round, user) => {
  let isBidControl = isControl(bidControls, round, user);
  return isBidControl && isBidControl.length === 0;
};

export const isControl = (bidControls, round, user) => {
  return bidControls.filter((bc) => {
    bc.bid_round === round && bc.participant_id === user.id && !bc.reaccept;
  });
};

export const findUser = (users, userId) => users.find((user) => user.id === userId);

// It will check the tab(Qaul/RFQ or Auction) and Action has started or not.
export const canShowRank = (event, beforeAuction) => {
  return (beforeAuction && isBeforeAuction(event)) || !beforeAuction;
};

export const officialName = (user) => (user.name && user.name !== "-" ? user.name : user.email);

export const getCountryName = (userCountry) => {
  return iso3166.country(userCountry) && iso3166.country(userCountry).name;
};

export const isJapaneseEvent = (event) => event.event_type === "Japanese";
export const isRankedEvent = (event) => event.event_type === "Ranked";
export const participantBidInRound = (participant, event, bidRound, auctionControls, bids, lots, lot) => {
  let r = true;
  if (!isJapaneseEvent(event)) {
    r = false;
  } else if (!bidRound) {
    r = false;
  } else {
    const val = lotsUserBidInRound(participant, bidRound, auctionControls, bids, lots, event);
    return val.includes(lot.id);
  }
  return r;
};

export const currencyStringWithSymbol = ({
  value,
  precision,
  locale,
  numberFormat,
  currency,
  returnValue = false,
  rankValue,
  cellClass = "",
  dataObject = {},
}) => {
  const localisedValue = convertToLocaleString(value, locale, precision);
  const _value = currencyPosition(localisedValue, currency, numberFormat);
  if (returnValue) {
    return _value;
  } else {
    return (
      <>
        <span className="num">
          <span id="" className="str_check_bid_value">
            {" "}
            {_value}{" "}
          </span>{" "}
        </span>
        &nbsp;
        {rankValue &&
          advancedLots.showRankValue &&
          showRankOfCell(
            dataObject.event,
            dataObject.blic,
            dataObject.bid,
            dataObject.lic,
            dataObject.lc,
            dataObject.lot,
            dataObject.role,
            dataObject.edit,
            dataObject.auctionTab
          )}
      </>
    );
  }
};

export const getFileName = (fileDetails) => {
  let splittedUrl = fileDetails && fileDetails.url && fileDetails.url.split("/");
  splittedUrl = splittedUrl && splittedUrl.length ? splittedUrl[splittedUrl.length - 1] : "";
  return splittedUrl.substring(0, splittedUrl.lastIndexOf("?") + 0);
};

export const anyLotAwarded = (lots) => awardedLots(lots);

export const awardedLots = (lots) => lots.find((lot) => lot.awarded_participant_id && lot.awarded_participant_id > -1);

export const checkAllLotsNotified = (lots) => {
  const isAwardNotify = lots.map((lot) => {
    return !lot.is_event_total ? (lot.is_award_notify ? true : false) : undefined;
  });
  return isAwardNotify.includes(false);
};

export const convertToLocaleString = (amount, locale = "en", precision = 2, roundDP = false) => {
  let value = amount;
  if (value && typeof value.toLocaleString === "function") {
    let opts = {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    };
    if (roundDP && amount > 1.0) {
      opts.minimumFractionDigits = 2;
      opts.maximumFractionDigits = 2;
    }
    value = value.toLocaleString(locale, opts);
  }
  return value;
};

export const objectToParamString = (paramObj) => {
  if (typeof paramObj === "object") {
    let str = new URLSearchParams(paramObj);
    return `?${str.toString()}`;
  } else {
    return "";
  }
};

/**
 *
 * If Partial Bidding is not enabled then we'll get the lot bid range based on the lot_total
 * @see findLotBidRange
 * bid_range will be an array e.i. [lotTotalmin, lotTotalmax]
 *
 * If Partial Bidding is enabled and
 * - if a lot is having both li and lot total then range for both totals are given in lot bid range
 * @see lotAllLiRangesWithPB
 * bid_range will be an array e.i. [lotTotalmin, lotTotalmax, { object containing range for all LIs in lot }]
 *
 * - if a lot has only Line item total then the bid_range will be counted for each line item
 * @see findLicBidRange
 * bid_range will be an object e.i. { lineItemId: [minRange, maxRange] }
 */

export const findLotBidRange = (bidRange = [], lotId) => {
  const lotRange = bidRange.find(({ id }) => id === lotId);
  return lotRange?.bid_range ?? ["", ""];
};

export const lotAllLiRangesWithPB = (currentLotRange) => {
  // In case of PB:
  // - if a lot is having both li and lot total
  //   then range for both totals is given in bid_range
  //   e.i. [lotTotalmin, lotTotalmax, { object containing range for all LIs in lot }]
  return Array.isArray(currentLotRange?.bid_range)
    ? currentLotRange?.bid_range?.find((bd) => {
        return isObject(bd);
      })
    : currentLotRange?.bid_range;
};

export const findLicBidRange = (lotsBidRange = [], li = {}) => {
  const currentLotRange = lotsBidRange?.find(({ id }) => id === li.lot_id);
  const range = lotAllLiRangesWithPB(currentLotRange)?.[li.id];
  return range?.length ? range : ["", ""];
};

export const unPlacedBidInDetailLevelAuction = (isAuction, event, bid, lot) => {
  let bidPresent = !bid || !bid.price;
  if (!isLotTotalPresent(lot)) bidPresent = false;
  return isAuction && event.bid_at_detail_level && bidPresent;
};

export const isSpecialFormula = (lic) => {
  const patt = new RegExp(/(\d+[,.]\d+)[a-z]/);
  return !patt.test(lic.formula);
};

// Only for returned LICs, BLICs can be created.
export const validLineItemComponentForBlic = (lics) => {
  let validLics = [];
  lics.forEach((lic) => {
    if (lic.formula) {
      if (lic.valid_cell) {
        validLics.push(lic);
      } else {
        !isSpecialFormula(lic) && validLics.push(lic);
      }
    } else {
      validLics.push(lic);
    }
  });
  return validLics;
};

export const show = (obj, key, lic) => {
  if (lic) {
    return obj && !["", null, undefined].includes(obj[key]) && !lc.lot_component_type ? obj[key] : "---";
  } else {
    return obj && !["", null, undefined].includes(obj[key]) ? obj[key] : "-";
  }
};

export const btnIconTypes = {
  LOADING: "fa fa-spinner fa-spin",
  SUCCESS: "fa fa-check green-color",
  ERROR: "fa fa-times red-color",
  DEFAULT: "fa fa-check",
};

export const hasValue = (value) => {
  return ![null, undefined, ""].includes(value);
};

export const getProcessedData = ({
  lic,
  licCurrency,
  blic,
  unitSetUoms = [],
  bidLineItemComponents,
  bids,
  bid,
  unitSet = [],
}) => {
  const type = componentType(lic?.lot_component_type);
  const initState = {
    isPrice: blic?.price ?? "",
    isText: blic?.attribute_value ?? null,
    isDecimal: hasValue(blic?.price) ? Number(blic.price) : null,
    isDate: hasValue(blic?.date_value) ? new Date(blic.date_value) : null,
    isPicklist: blic?.attribute_value ?? null,
    uomValue: blic?.unit_of_measure_id ?? lic?.unit_of_measure_id ?? null,
    btnIcon: btnIconTypes.DEFAULT,
    fieldError: null,
    licCurrency,
    selectedExchangeRateId: blic?.exchange_rate_id || lic?.exchange_rate_id,
    loaded: false,
    uomQuantity: preferredQuantity(lic, unitSetUoms, unitSet, blic, bid, bidLineItemComponents, bids),
    description: lic?.description ?? "",
  };

  return {
    initState,
    type,
    hasValue: hasValue(initState[type]),
  };
};

export const bidPropsAreEqual = (prevProps, nextProps) => {
  const { isPlaceBidActive, isDetailView, lic, isAllDetailView, blic, bid } = prevProps;
  let reRender = false;

  if (Array.isArray(advancedLots.updateLic) && advancedLots.updateLic.includes(lic.id)) {
    reRender = true;
    advancedLots.updateLic = advancedLots.updateLic.filter((licId) => licId !== lic.id);
  }

  if (advancedLots.createUpdateBlicId === lic.id) {
    reRender = true;
  }

  switch (true) {
    case reRender:
    case !isEqual(bid, nextProps.bid):
    case !isEqual(blic, nextProps.blic):
    case blic !== nextProps.blic:
    case bid !== nextProps.bid:
    case isDetailView !== nextProps.isDetailView:
    case isAllDetailView !== nextProps.isAllDetailView:
    case isPlaceBidActive !== nextProps.isPlaceBidActive:
      return false;
    default:
      return true;
  }
};

/**
 * @description If first arg is not `""` or `null` or `undefined` then fallback
 *              value will be returned
 * @param {*} value
 * @param {*} fallback
 * @returns {*}
 */
export const showValue = (value, fallback = "-") => (hasValue(value) ? value : fallback);

/**
 * Check if browser is Internet Explorer
 * @returns {boolean}
 */
export const isIE = () => {
  return Boolean(document.documentMode);
};

export const hostOverviewTableClasses = (event, lots, eventParticipants) => {
  const anyComplexLots = !anyComplexLotsExist(lots) ? "simple-lots" : "complex-lots";
  const partcipantClasses = htmlClassWithParticipant(event, eventParticipants, lots);
  return `overview-container v2 ${eventStatusClass(event)} ${anyComplexLots}
    qual-bid advance-part-2 ${partcipantClasses} components-open`;
};

export const triStateDisplay = (field, translations) => {
  let classNames = null;
  if (field === true) {
    classNames = { span: "yes green-color", icon: "check", translation: translations.yes };
  } else if (field === false) {
    classNames = { span: "no red-color", icon: "times", translation: translations.no };
  }

  switch (field) {
    case true:
    case false:
      return (
        <span className={classNames.span} title={classNames.translation}>
          <span className={`fa fa-${classNames.icon}`}></span>
        </span>
      );
    case "-":
      return (
        <div className="red-color">
          <span className="pending fa fa-times" title={translations.Pending}>
            {translations.Pending}
          </span>
        </div>
      );
  }
};

export const online = (user) => {
  return user.seen_at && moment.utc(user.seen_at).format() > moment.utc().subtract(5, "minutes").format();
};

export const notDeclinedForEvent = (eventParticipants) => {
  return eventParticipants.filter((ep) => ep.accepted !== false && [true, null].includes(ep.participating));
};

export const showMessageDateFormat = (dateValue, timeZoneIdentifier) => {
  return moment.tz(dateValue, timeZoneIdentifier).format("HH:mm:ss");
};

export const filterMessageRecipients = (recipients, messageId) => {
  return recipients.filter((recipient) => recipient.message_id === messageId);
};

export const userCanReply = (recipients, message, user, role, permittedToEdit) => {
  const userIds = recipients.map((recipient) => recipient.user_id);
  return message.user_id !== user.id && (userIds.includes(user.id) || (role === "Host" && permittedToEdit));
};

export const isRead = (recipients, userId) => {
  const mr = recipients.find((recipient) => recipient.user_id === userId);
  return !mr ? true : mr.message_read;
};

export const evalMessage = (message) => {
  if (message === undefined) return "";
  return message.message?.body ?? message.message;
};

export const requiresComplexDetailAdded = (event, lot) => {
  const bidAtDetailLevel = event.bid_at_detail_level || false;
  return lot.complex_structure && !["pending", "running", "pause"].includes(event.current_state) && !bidAtDetailLevel;
};

export const requiresComplexDetail = (event, lot) => {
  const bidAtDetailLevel = event.bid_at_detail_level || false;
  return (
    lot.complex_structure &&
    (event.current_state !== "running" || (bidAtDetailLevel && event.current_state === "running"))
  );
};

export const eventCompletedForParticipant = (event, remainingParticipants = [], userId) => {
  if (event.event_type === "Japanese") {
    return !participantActiveInJapaneseAuction(event, remainingParticipants, userId);
  } else {
    return event.current_state === "completed";
  }
};

export const cellClass = (bid = {}, rankValue) => {
  let cellClass = "";
  if (
    window.advancedLots &&
    Array.isArray(window.advancedLots.affectedBid) &&
    window.advancedLots.affectedBid.length > 0
  ) {
    const validAffectedBids = advancedLots.affectedBid.filter((bid) => typeof bid === "object");
    const oldBid = validAffectedBids.find(({ lot_id }) => lot_id === bid.lot_id);
    advancedLots.affectedBid = validAffectedBids.filter(({ lot_id }) => lot_id !== bid.lot_id);
    if (oldBid) {
      if (rankValue < oldBid.rank) {
        cellClass = "value_up_green";
      } else if (rankValue > oldBid.rank) {
        cellClass = "value_down_red";
      }
    }
  }
  return cellClass;
};

//  Check if it needs to be display complex lot buttons or not
export const canShowComplexBidButtons = (event, lot, userId) => {
  if (event.current_state === "pause") return false;

  return isJapaneseEvent(event) && !participantActiveInJapaneseAuction(event, [userId], userId);
};

export const toggleButton = (onClick) => {
  return (
    <span id="toggle-details" className="btn btn-default btn-xs small-btn small-blue-btn" onClick={onClick}>
      <i className="fa fa-sort-amount-desc"></i>
    </span>
  );
};

export const leadParticipantModalProps = (props) => {
  let modalProps = {};
  leadParticipantModalPropsData.forEach((key) => {
    modalProps[key] = props[key];
  });
  return modalProps;
};

export const fetchDecimalPlace = (lic, range) => {
  if (range < 1.0 && range > -1.0) return lic?.decimal_place || 2;
  return 2;
};

export const leadingBidPrice = (lic, price, currency, locale, numberFormat) =>
  showCurrency(price, currency, fetchDecimalPlace(lic, price), locale, numberFormat);

export const hostCellValue = (type, lic) => {
  let fieldValue = null;
  switch (type) {
    case "isDate":
      const date = moment(show(lic, "date_value")).format("DD MMM HH:MM");
      fieldValue = date !== "Invalid date" ? date : "-";
      break;
    case "isText":
    case "isPicklist":
      fieldValue = show(lic, "attribute_value");
      break;
    case "isDecimal":
      const num = show(lic, "used_price");
      fieldValue = !isNaN(Number(num)) ? Number(num) : num;
      break;
  }
  return fieldValue;
};

export const getPriceValue = (price, locale, decimalPlace) => {
  return !hasValue(price) ? price : showLocalisedStrippedDecimal(price, locale, decimalPlace);
};

/**
 *
 * @param {*} number
 * @returns {Boolean}
 *
 * returns true if the param passed is a number
 * returns false if the param passed is either undefined, null or NaN
 */
export const isANumber = (number) => !isNaN(parseInt(number));

/**
 *
 * @param {Object} lot
 * @returns {Boolean}
 *
 */
export const isLotTotalPresent = (lot = {}) => Boolean(lot?.lot_total_lic_id);

/**
 *
 * @param {Object} li
 * @returns {Boolean}
 *
 */
export const isLiTotalPresent = (li = {}) => Boolean(li?.line_item_total_lic_id);

/**
 * @description Check if lot allows partial bidding
 * @param {Object} lot
 * @returns {Boolean}
 *
 */
export const isPBPermittedInLot = (lot = {}) => Boolean(lot?.permit_partial_bids);
