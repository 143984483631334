import React, { useCallback, useMemo, useState } from "react";
import type { NormalizedBranch, NormalizedNode } from "@/slices/rules/types";
import { Operation } from "@/slices/rules/types";
import { NodeComp } from "./node";
import { assert } from "@/utils/assert";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { collapseToggled, operationChanged, selectCollapsedById, selectBranchIsSameAsParent } from "@/slices/rules";
import { makeEnum } from "@/slices/util/makeEnum";
import { inEnum } from "@/utils/inEnum";
import { objectEntries } from "@/utils";
import styles from "./node.module.scss";
import clsx from "clsx";
import { t } from "@/i18n";
import { BootstrapArrow } from "@/cl/select";
import { Button } from "@/cl/button";
import { Collapser, useParentCollapsed } from "./collapse-context";
import { useDebouncedSync } from "@/hooks/use-debounced-sync";

export const OpString = makeEnum("and", "or", "nand", "nor");
export type OpString = keyof typeof OpString;

const operationMap: Record<OpString, [op: Operation, negated: boolean]> = {
  and: [Operation.And, false],
  or: [Operation.Or, false],
  nand: [Operation.And, true],
  nor: [Operation.Or, true],
};

const reverseOperationMap = objectEntries(operationMap).reduce<Record<Operation, Map<boolean, OpString>>>(
  (acc, [key, [operation, negated]]) => {
    acc[operation].set(negated, key);
    return acc;
  },
  {
    [Operation.And]: new Map(),
    [Operation.Or]: new Map(),
  }
);

function OperationDropdown({ node }: { node: NormalizedNode<NormalizedBranch> }) {
  const dispatch = useAppDispatch();
  const [{ operation, negated }, handleOpChange] = useDebouncedSync(
    useMemo(() => ({ operation: node.state.operation, negated: node.negated }), [node.state.operation, node.negated]),
    useCallback((newOp) => dispatch(operationChanged({ id: node.uuid, ...newOp })), [dispatch, node.uuid])
  );
  const [focused, setFocused] = useState(false);
  return (
    <div className={styles.dropdown}>
      <div className={clsx(styles.connector, styles.before)} />
      <label className={clsx(styles.selectContainer, focused && styles.focused)}>
        <select
          className={styles.select}
          value={reverseOperationMap[operation].get(negated)}
          onChange={(e) => {
            assert(inEnum(OpString, e.target.value), "Invalid operation");
            const [operation, negated] = operationMap[e.target.value];
            handleOpChange({ operation, negated });
          }}
          aria-label={t("workflow_rules.select_operation")}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        >
          {Object.values(OpString).map((op) => (
            <option key={op} value={op}>
              {t(`workflow_rules.operation.${op}`)}
            </option>
          ))}
        </select>
        <BootstrapArrow className={styles.selectIcon} />
      </label>
      <div className={clsx(styles.connector, styles.after)} />
    </div>
  );
}

function CollapseButton({ nodeId, branchCollapsed }: { nodeId: string; branchCollapsed: boolean }) {
  const dispatch = useAppDispatch();
  return (
    <Button
      brand="null"
      className={styles.collapseContainer}
      onClick={() => dispatch(collapseToggled(nodeId))}
      aria-label={t(`workflow_rules.${branchCollapsed ? "expand" : "collapse"}`)}
    >
      <Button as="span" icon brand="outlined-primary" className={styles.collapseButton}>
        <i className={`fa fa-chevron-${branchCollapsed ? "down" : "up"}`} />
      </Button>
    </Button>
  );
}

export function BranchNode({ node }: { node: NormalizedNode<NormalizedBranch> }) {
  const isRoot = !node.parentUuid;
  const isSameAsParent = useAppSelector((state) => selectBranchIsSameAsParent(state, node.uuid));
  const branchCollapsed = useAppSelector((state) => selectCollapsedById(state, node.uuid));
  const parentCollapsed = useParentCollapsed();
  const collapsed = parentCollapsed || branchCollapsed;
  const noBorder = (isSameAsParent || isRoot) && !branchCollapsed;
  const hideCollapseButton = noBorder || parentCollapsed;
  return (
    <Collapser collapsed={branchCollapsed}>
      <div
        className={clsx(styles.branch, {
          [styles.noBorder]: noBorder,
          [styles.collapsed]: collapsed,
        })}
      >
        <div className={styles.branchContents}>
          <NodeComp uuid={node.state.child_nodes[0]} />
          <OperationDropdown {...{ node }} />
          <NodeComp uuid={node.state.child_nodes[1]} />
        </div>
        {!hideCollapseButton && <CollapseButton {...{ branchCollapsed }} nodeId={node.uuid} />}
      </div>
    </Collapser>
  );
}
