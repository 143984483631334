import { useEffect, useState } from "react";

/**
 * @description common utility to manage debounced state
 * @template T
 * @param {T} defaultValue default value to of the debounced state
 * @param {number} delay delay to expect value to change after it's original state has changed i.e 300 for 300ms
 * @returns {[value: T, debouncedValue: T, setValue: React.Dispatch<T>]}
 */
export default function useDebounceState(defaultValue, delay) {
  // Only pass the defaultValue to one of the state, if its a function we only
  // want this to be called once
  const [value, setValue] = useState(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => setDebouncedValue(value), delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => clearTimeout(handler);
  }, [value, delay]);

  return [value, debouncedValue, setValue];
}
