import React from "react";
import PropTypes from "prop-types";

import { useVirtual } from "react-virtual";

import "./grid.scss";

/**
 * Prop types for the Grid
 */
export const GridPropTypes = {
  /**
   * An array of all the items to render in the table
   */
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   * A callback function that will be used to estimate the height of the
   * current row. NOTE, this only needs to estimate the height of the row, it
   * will be used as a min height for the row, the row offset will be
   * calculated on the content in the row.
   *
   * @example
   * const estimateSize = React.useCallBack((index) => {
   *   const item = items[index];
   *   // Do something with the item
   *
   *   // Return the height this row at the index will be.
   *   return 10;
   * });
   */
  estimateSize: PropTypes.func.isRequired,
  /**
   * The total height of the grid. Only the items that will be visible in this
   * size will be rendered at a time
   */
  height: PropTypes.number.isRequired,
  /**
   * An optional class name that will be added to the wrapper div along with
   * `dj-vgrid`
   */
  className: PropTypes.string,
  /**
   * The head and render function that will be render the head and all the
   * table rows in the tbody
   */
  children: function (props) {
    if (props.children.length !== 2) {
      return new Error("Grid must have exactly 2 children");
    }

    const [head, row] = props.children;
    if (!React.isValidElement(head)) {
      return new Error("Head is not a valid React element");
    }

    if (typeof head.type === "string" && head.type !== "thead") {
      return new Error("The first child of Gird must be thead");
    }

    if (typeof row !== "function") {
      return new Error("Body is not a valid render prop");
    }
  },
};

/**
 * A virtual gird table to render a large amount of rows
 *
 * @example
 * const estimateSize = React.useCallBack((index) => {
 *   const item = items[index];
 *   // Do something with the item
 *
 *   // Return the height this row at the index will be.
 *   return 10;
 * });
 *
 * return (
 *   <Grid items={["A", "B", "C"]} height={10000} estimateSize={estimateSizeFunction}>
 *     <thead>
 *       <tr>
 *         <th>The head</th>
 *       </tr>
 *     </thead>
 *     {(item, { props }) => {
 *       return (
 *         <tr {...props}>
 *           <td>{item}</td>
 *         </tr>
 *       );
 *     }}
 *   </Grid>
 * );
 *
 * @type {React.FC<PropTypes.InferProps<GridPropTypes>>}
 */
export const Grid = ({ items, estimateSize, className, height, children }) => {
  const [head, row] = children;
  const parentRef = React.useRef();
  const rowVirtualizer = useVirtual({
    size: items.length,
    parentRef,
    estimateSize,
  });

  return (
    <div className={`dj-vgrid ${className}`} ref={parentRef} style={{ maxHeight: height }}>
      <table className="table table-with-border">
        {head}
        <tbody
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            position: "relative",
          }}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => {
            const index = virtualRow.index;
            const item = items[index];
            const props = {
              key: virtualRow.key,
              ref: virtualRow.measureRef,
              className: virtualRow.index % 2 ? "dj-vgrid-even" : "dj-vgrid-odd",
              style: {
                position: "absolute",
                minHeight: virtualRow.size,
                transform: `translateY(${virtualRow.start}px)`,
              },
            };

            return row(item, { index, props });
          })}
        </tbody>
      </table>
    </div>
  );
};

Grid.propTypes = GridPropTypes;

export default Grid;
