import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Modal from "react-modal";
import Tooltip from "../../../common/Tooltip";

export class PicklistModal extends Component {
  constructor() {
    super();
    this.picklistTypesDOMIdAndText = this.picklistTypesDOMIdAndText.bind(this);
    this.picklistTypesCheckboxes = this.picklistTypesCheckboxes.bind(this);
    this.picklistOptionFields = this.picklistOptionFields.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeydown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown, false);
  }

  handleKeydown(event) {
    const { handleFormSubmit } = this.props;
    if (event.keyCode === 13) {
      handleFormSubmit(event);
    }
  }

  render() {
    const {
      closeModal,
      modalIsOpen,
      translations,
      picklist,
      handleFormSubmit,
      picklistName,
      handlePicklistNameChange,
      addNewPicklistOption,
      title,
    } = this.props;
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-content col-md-4 col-sm-4 custom-field-popup"
        appElement={document.getElementById("advanced-lots-body")}
      >
        <div className="block block-transparent remove-margin-b">
          <div className="block-header bg-primary-default">
            <ul className="block-options">
              <li>
                <button data-dismiss="modal" type="button" onClick={closeModal}>
                  <i className="ion-close" />
                </button>
              </li>
            </ul>
            <h3 className="block-title">{title}</h3>
          </div>
          <div className="block-content picklist-body">
            <div className="form">
              <div className="col-md-12">
                <div className="input inline form-group">
                  <div className="inline">
                    <label htmlFor="picklist_name">{translations.name}</label>
                    <input
                      autoComplete="off"
                      className="form-control"
                      type="text"
                      id="picklist_name"
                      value={picklistName}
                      onChange={handlePicklistNameChange}
                      autoFocus
                    />
                    {this.picklistTypesCheckboxes()}
                  </div>
                  <div className="form-group">
                    <div id="picklist_option_fields">
                      {translations.picklist_options}
                      <div className="fields">{this.picklistOptionFields()}</div>
                    </div>
                  </div>
                  <div className="input form-group">
                    <a
                      className="btn btn-default button button-black button-mini add_nested_fields"
                      onClick={addNewPicklistOption}
                    >
                      <i className="fa fa-plus"></i>&nbsp;&nbsp;
                      {translations.add_picklist_option}
                    </a>
                  </div>
                  <div className="modal-footer">
                    <div className="input inline form-group">
                      <div className="inline">
                        <button name="commit" className="btn btn-primary pull-right" onClick={handleFormSubmit}>
                          {translations.save}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  picklistTypesDOMIdAndText(type) {
    const { picklist_types } = this.props.translations;
    switch (type) {
      case 0:
        return [picklist_types.none, type, "picklist_picklist_type_none", "picklist_type_none"];
      case 1:
        return [picklist_types.currency, type, "picklist_picklist_type_currency", "picklist_type_currency"];
      case 2:
        return [picklist_types.numeric, type, "picklist_picklist_type_numeric", "picklist_type_numeric"];
    }
  }

  picklistTypesCheckboxes() {
    const { translations, picklistType, handlePicklistTypeChange, picklistId } = this.props;
    const { picklist_option } = translations.infos;
    const _this = this;
    return (
      <div id="input inline form-group">
        <div className="label-title">
          <Tooltip title={picklist_option.title} body={picklist_option.body} />
          <label htmlFor="picklist_picklist_type">{translations.picklist_type}</label>
        </div>
        {[0, 1, 2].map(function (type) {
          const attributes = _this.picklistTypesDOMIdAndText(type);
          return (
            <label
              className="css-input css-radio css-radio-sm css-radio-primary display-as-line push-10-r"
              htmlFor={attributes[3]}
              key={attributes[2]}
            >
              <input
                className="picklist_type_option"
                type="radio"
                value={attributes[1]}
                checked={parseInt(picklistType) === attributes[1]}
                id={attributes[2]}
                onChange={handlePicklistTypeChange}
                disabled={picklistId !== undefined}
              />
              <span></span>
              {attributes[0]}
            </label>
          );
        })}
      </div>
    );
  }

  picklistOptionFields() {
    const { translations, picklist, picklistOptions, handleOptionNameChange, removePicklistOption } = this.props;
    return (
      <div className="input fields">
        {picklistOptions.map(function (po, idx) {
          if (po["_destroy"] === undefined) {
            return (
              <Fragment key={idx}>
                <span>
                  <input
                    className="form-control"
                    type="text"
                    value={po.value}
                    onChange={handleOptionNameChange}
                    data-id={idx}
                    name="value"
                  />
                </span>
                <a
                  title={translations.remove_picklist_option}
                  className="button button-mini button-black icon-only red-color remove_nested_fields click"
                  data-id={idx}
                  onClick={removePicklistOption}
                >
                  <i className="fa fa-trash"></i>&nbsp;&nbsp;
                  {translations.remove_picklist_option}
                </a>
                <div className="clearfix"></div>
              </Fragment>
            );
          }
        })}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(PicklistModal);
