import React, { Component } from "react";
import RenderHTML from "@/components/common/RenderHTML";

class SeeBrief extends Component {
  render() {
    return (
      <div className="category_holder prd_details_add_box">
        <a className="category_title active" data-toggle="collapse" href="#collapseBrief">
          {this.props.translations.summary_status.see_brief}
        </a>
        <div className="details_form_box panel-collapse collapse" id="collapseBrief">
          <RenderHTML content={this.props.eventBrief} />
        </div>
      </div>
    );
  }
}

export default SeeBrief;
