import React, { useState } from "react";
import PropTypes from "prop-types";
import LineItemForm from "./LineItemForm";
import { useAppSelector } from "@/hooks/redux";
import { lotAutomationEnforcedAndNotSandpit } from "../lotCommon";
import { t } from "@/i18n";

const useLineItemModalTitle = ({ lineItem, calcRow }) => {
  // if line item present show Edit modal
  if (lineItem) {
    return calcRow ? t("edit_add_total") : t("line_item_edit");
  }

  return calcRow ? t("add_total") : t("add_line_item");
};

function LotName({ name, onClick }) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events
    <a className="line-item-name" onClick={onClick}>
      {name}
    </a>
  );
}

LotName.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const LineItemModalTrigger = ({ openModal, title, ...props }) => {
  const { classFromEventState, classes, create, lineItem = {}, anyCompletedBids } = props;
  const { total, setLineItemToBeDeleted } = props;
  const lAEnforcedAndNotSandpit = useAppSelector(lotAutomationEnforcedAndNotSandpit);
  // disabling linting errors as it is ruining styles of the dropdown
  if (create) {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid
      <a className={`${classFromEventState} ${classes}`} onClick={openModal} onKeyDown={openModal}>
        <i className="fa fa-table" /> {title} {t("questionnaire_questions.edit.row")}
      </a>
    );
  }

  if (total) {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid
      <a
        className={`${classFromEventState} status-event sm-butt glowing green tiny new-line-item-content-loader blue-color xs-full-width`}
        onClick={openModal}
        onKeyDown={openModal}
      >
        <i className="fa fa-table" />
        &nbsp;{t("add_event_total")}
      </a>
    );
  }

  if (lAEnforcedAndNotSandpit) {
    return <div className="line-item-name">{lineItem.name}</div>;
  }

  return (
    <>
      <LotName name={lineItem.name} onClick={openModal} />
      <div className="icon-onhover icon-onhover-right">
        {!anyCompletedBids && (
          <button
            title={lineItem.name}
            className="btn edit hover opacity red-color"
            data-toggle="modal"
            data-target={`#lineItemDeleteConfirmation-${lineItem.lot_id}`}
            onClick={() => setLineItemToBeDeleted(lineItem)}
          >
            <i className="fa fa-trash" />
          </button>
        )}
      </div>
    </>
  );
};

const LineItemModal = (props) => {
  const { translations, calcRow, lineItem, anyCompletedBids, lotId, hideFromParticipantsSwitch } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const title = useLineItemModalTitle({ lineItem, calcRow });

  const openModal = () => setModalIsOpen(true);

  return (
    <>
      <LineItemModalTrigger {...{ ...props, openModal, title }} />
      {modalIsOpen && (
        <LineItemForm
          {...{
            translations,
            calcRow,
            lineItem,
            lotId,
            setModalIsOpen,
            modalIsOpen,
            title,
            anyCompletedBids,
            hideFromParticipantsSwitch,
          }}
        />
      )}
    </>
  );
};

export default LineItemModal;
