import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

/**
 * Footer wrapper for activity modal.
 *
 * @param {object} props
 * @returns {JSX}
 */
const ModalFooter = ({ draftActivity, handleDeleteActivity, submitActivity, publishOrSaveBtn }) => {
  const { translations } = useSelector((state) => state.ActivityReducer);

  return (
    <div className="modal-footer">
      <div className="col-md-12 p-r0">
        <div className="pull-right">
          {draftActivity.draft && (
            <span className="non_licensed_user_rule_limit p-r20">{translations.activity_in_draft_status}</span>
          )}
          <button name="button" className="btn btn-sm btn-default m-r10" onClick={handleDeleteActivity}>
            {translations.back}
          </button>
          <button type="button" className="btn btn-default btn-sm m-r10" onClick={() => submitActivity(true)}>
            {draftActivity.draft ? translations.follow_up : translations.create_sub_activity}
          </button>
          {publishOrSaveBtn}
        </div>
      </div>
    </div>
  );
};

ModalFooter.propTypes = {
  draftActivity: PropTypes.object.isRequired,
  handleDeleteActivity: PropTypes.func.isRequired,
  submitActivity: PropTypes.func.isRequired,
  publishOrSaveBtn: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ModalFooter;
