import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useLotStatus } from "@/selectors/lotSelectors";
import LotRejectButton from "./LotRejectButton";
import PlaceBidButtonGroup from "./PlaceBidButtonGroup";

const enterBidStateSelection = createSelector(
  ({ lotReducers }) => lotReducers.event_rounds,
  ({ lotReducers }) => lotReducers.translations,
  ({ lotReducers }) => lotReducers.auction_tab,
  (event_rounds, translations, auctionTab) => ({
    hasMultipleBidRounds: event_rounds?.length > 1,
    translations,
    auctionTab,
  })
);

const EnterBidButtonTextPropType = {
  isLotLevelMonitor: PropTypes.bool,
  isJapaneseMonitor: PropTypes.bool,
  isAuction: PropTypes.bool,
  hasBid: PropTypes.bool,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<EnterBidButtonTextPropType>>}
 */
const EnterBidButtonText = (props) => {
  const { isLotLevelMonitor, isJapaneseMonitor, isAuction, hasBid } = props;
  const { hasMultipleBidRounds, translations = {}, auctionTab } = useSelector(enterBidStateSelection);
  const { enter_bid_detail, enter_bid, update_bid } = translations;
  if ((isLotLevelMonitor || isJapaneseMonitor) && isAuction) return enter_bid_detail;
  else if (hasMultipleBidRounds && hasBid && !auctionTab) return update_bid;
  return enter_bid;
};

EnterBidButtonText.propTypes = EnterBidButtonTextPropType;
EnterBidButtonText.defaultProps = {};

const BidButtonGroup = (props) => {
  const { isPending } = useLotStatus(props.lot.id);
  const auctionTab = useSelector(({ lotReducers }) => lotReducers.auction_tab);
  const isShowingLotRejectButton = (auctionTab && !props.bid) || !auctionTab;

  if (props.lotState.isPlaceBidActive) return <PlaceBidButtonGroup {...props} />;

  return (
    <>
      {isShowingLotRejectButton && <LotRejectButton {...props} />}
      {!(props.isLotLevelMonitor && props.detailsAdded) && (
        <button
          className="btn btn-sm btn-primary place-bid pull-right m-r5"
          disabled={isPending}
          onClick={props.onClickPlaceBid}
        >
          <EnterBidButtonText
            hasBid={Boolean(props.bid)}
            isAuction={props.isAuction}
            isJapaneseMonitor={props.isJapaneseMonitor}
            isLotLevelMonitor={props.isLotLevelMonitor}
          />
          <i className="fa fa-pencil m-l5"></i>
        </button>
      )}
    </>
  );
};

export default BidButtonGroup;
