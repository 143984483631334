import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import Tooltip from "../../common/Tooltip";
import Select2 from "react-select2-wrapper";
import { updateAuctionType } from "../../../actions/lotActions";

const AuctionTypeButton = (props) => {
  const dispatch = useDispatch();
  const { translations, event } = props;
  const [bidAtDetailLevel, setBidAtDetailLevel] = useState(event.bid_at_detail_level ? "detail_level" : "lot_level");

  const [isLoader, setIsloader] = useState(false);

  const handleAuctionType = (e) => {
    setBidAtDetailLevel(e.target.value);
    setIsloader(true);
    const payload = {
      event: {
        bid_at_detail_level: e.target.value === "detail_level" ? true : false,
      },
    };
    dispatch(updateAuctionType(payload, event.id)).then((response) => {
      setIsloader(false);
    });
  };

  return (
    <Fragment>
      <Select2
        className={`btn btn-sm btn-prominent shadow-diffuse select2-container--draft`}
        data={[
          {
            id: "lot_level",
            text: translations.submit_bid_at_bid_level_lot,
          },
          {
            id: "detail_level",
            text: translations.submit_bid_at_bid_level_detailed,
          },
        ]}
        name="bidAtDetailLevel"
        onSelect={(e) => handleAuctionType(e)}
        value={bidAtDetailLevel}
        options={{ minimumResultsForSearch: -1 }}
        style={{ width: "150px" }}
      />
      <div className="bid-level-loader">
        {isLoader && (
          <Fragment>
            &nbsp;
            <span className="event-detail-panel-spinner m-t10">
              <i className="fa fa-spinner fa-white fa-spin" />
            </span>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default AuctionTypeButton;
