import PropTypes from "prop-types";
import type { ComponentPropsWithoutRef } from "react";
import React, { forwardRef } from "react";

export const Switch = forwardRef<HTMLInputElement, ComponentPropsWithoutRef<"input">>(function Switch(
  { children, ...props },
  ref
) {
  return (
    <div className="switch-toggle">
      <label className="switch">
        <input type="checkbox" {...props} ref={ref} />
        <span className="slider round"></span>
      </label>
      {children}
    </div>
  );
});

Switch.propTypes = {
  children: PropTypes.node,
};

export default Switch;
