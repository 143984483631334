import PropTypes from "prop-types";

export const LineItem = {
  id: PropTypes.number,
  name: PropTypes.string,
  tag: PropTypes.string,
  is_calculation: PropTypes.bool,
  hide_from_participants: PropTypes.bool,
  lot_id: PropTypes.number,
  updated_at: PropTypes.string,
  line_item_total_lic_id: PropTypes.any,
};

/** @typedef {PropTypes.InferProps<typeof LineItem>} LineItem */

export default LineItem;
