import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { unstable_batchedUpdates } from "react-dom";

import { deleteActivity, reloadActivityList, updateActivitiesCount } from "../../actions/activityActions";
import ActivityTableInner from "./ActivityTableInner";
import ActivityTableMain from "./ActivityTableMain";

const List = (props) => {
  const { activities, paginationParams, highlight, searchParam, setHighlight, calledFromIndex } = props;
  const [activityId, setActivityId] = useState("");
  const dispatch = useDispatch();
  const { per_page } = paginationParams;
  let { page } = paginationParams;

  useEffect(() => {
    if (highlight) {
      const context = document.querySelector("#activity-list");
      const instance = new Mark(context);
      instance.unmark();
      instance.mark(searchParam);
      setHighlight(false);
    }
  }, [highlight]);

  const handleDeleteActivity = () => {
    if (activities.length === 1 && page > 1) {
      page -= 1;
    }

    dispatch(deleteActivity(activityId, page, per_page)).then((response) => {
      props.setPaginationParams((state) => {
        state.page = page;
        return state;
      });

      return response;
    });
  };

  let table = "";

  if (calledFromIndex) {
    table = <ActivityTableMain {...props} handleDeleteActivity={handleDeleteActivity} setActivityId={setActivityId} />;
  } else {
    table = <ActivityTableInner {...props} setActivityId={setActivityId} handleDeleteActivity={handleDeleteActivity} />;
  }

  return table;
};

export default List;
