export const languages = {
  ar: () => import("../../config/locales/ar.yml"),
  de: () => import("../../config/locales/de.yml"),
  "devise.ar": () => import("../../config/locales/devise.ar.yml"),
  "devise.de": () => import("../../config/locales/devise.de.yml"),
  "devise.el": () => import("../../config/locales/devise.el.yml"),
  "devise.en": () => import("../../config/locales/devise.en.yml"),
  "devise.es": () => import("../../config/locales/devise.es.yml"),
  "devise.fr": () => import("../../config/locales/devise.fr.yml"),
  "devise.id": () => import("../../config/locales/devise.id.yml"),
  "devise.it": () => import("../../config/locales/devise.it.yml"),
  "devise.ja": () => import("../../config/locales/devise.ja.yml"),
  "devise.ko": () => import("../../config/locales/devise.ko.yml"),
  "devise.nl": () => import("../../config/locales/devise.nl.yml"),
  "devise.pl": () => import("../../config/locales/devise.pl.yml"),
  "devise.pt": () => import("../../config/locales/devise.pt.yml"),
  "devise.ro": () => import("../../config/locales/devise.ro.yml"),
  "devise.ru": () => import("../../config/locales/devise.ru.yml"),
  "devise.sl": () => import("../../config/locales/devise.sl.yml"),
  "devise.sv": () => import("../../config/locales/devise.sv.yml"),
  "devise.zh-CN": () => import("../../config/locales/devise.zh-CN.yml"),
  "devise.zh-TW": () => import("../../config/locales/devise.zh-TW.yml"),
  el: () => import("../../config/locales/el.yml"),
  en: () => import("../../config/locales/en.yml"),
  es: () => import("../../config/locales/es.yml"),
  fr: () => import("../../config/locales/fr.yml"),
  id: () => import("../../config/locales/id.yml"),
  it: () => import("../../config/locales/it.yml"),
  ja: () => import("../../config/locales/ja.yml"),
  ko: () => import("../../config/locales/ko.yml"),
  nl: () => import("../../config/locales/nl.yml"),
  no: () => import("../../config/locales/no.yml"),
  pl: () => import("../../config/locales/pl.yml"),
  pt: () => import("../../config/locales/pt.yml"),
  ro: () => import("../../config/locales/ro.yml"),
  ru: () => import("../../config/locales/ru.yml"),
  "simple_form.en": () => import("../../config/locales/simple_form.en.yml"),
  sl: () => import("../../config/locales/sl.yml"),
  sv: () => import("../../config/locales/sv.yml"),
  th: () => import("../../config/locales/th.yml"),
  "tooltips.ar": () => import("../../config/locales/tooltips.ar.yml"),
  "tooltips.de": () => import("../../config/locales/tooltips.de.yml"),
  "tooltips.el": () => import("../../config/locales/tooltips.el.yml"),
  "tooltips.en": () => import("../../config/locales/tooltips.en.yml"),
  "tooltips.es": () => import("../../config/locales/tooltips.es.yml"),
  "tooltips.fr": () => import("../../config/locales/tooltips.fr.yml"),
  "tooltips.id": () => import("../../config/locales/tooltips.id.yml"),
  "tooltips.it": () => import("../../config/locales/tooltips.it.yml"),
  "tooltips.ja": () => import("../../config/locales/tooltips.ja.yml"),
  "tooltips.ko": () => import("../../config/locales/tooltips.ko.yml"),
  "tooltips.nl": () => import("../../config/locales/tooltips.nl.yml"),
  "tooltips.no": () => import("../../config/locales/tooltips.no.yml"),
  "tooltips.pl": () => import("../../config/locales/tooltips.pl.yml"),
  "tooltips.pt": () => import("../../config/locales/tooltips.pt.yml"),
  "tooltips.ro": () => import("../../config/locales/tooltips.ro.yml"),
  "tooltips.ru": () => import("../../config/locales/tooltips.ru.yml"),
  "tooltips.sl": () => import("../../config/locales/tooltips.sl.yml"),
  "tooltips.sv": () => import("../../config/locales/tooltips.sv.yml"),
  "tooltips.th": () => import("../../config/locales/tooltips.th.yml"),
  "tooltips.tr": () => import("../../config/locales/tooltips.tr.yml"),
  "tooltips.vi": () => import("../../config/locales/tooltips.vi.yml"),
  "tooltips.zh-CN": () => import("../../config/locales/tooltips.zh-CN.yml"),
  "tooltips.zh-TW": () => import("../../config/locales/tooltips.zh-TW.yml"),
  tr: () => import("../../config/locales/tr.yml"),
  vi: () => import("../../config/locales/vi.yml"),
  "zh-CN": () => import("../../config/locales/zh-CN.yml"),
  "zh-TW": () => import("../../config/locales/zh-TW.yml"),
};

export const simLanguages = {
  ar: () => import("../../vendor/engines/sim_dojo/config/locales/ar.yml"),
  de: () => import("../../vendor/engines/sim_dojo/config/locales/de.yml"),
  el: () => import("../../vendor/engines/sim_dojo/config/locales/el.yml"),
  en: () => import("../../vendor/engines/sim_dojo/config/locales/en.yml"),
  es: () => import("../../vendor/engines/sim_dojo/config/locales/es.yml"),
  fr: () => import("../../vendor/engines/sim_dojo/config/locales/fr.yml"),
  id: () => import("../../vendor/engines/sim_dojo/config/locales/id.yml"),
  it: () => import("../../vendor/engines/sim_dojo/config/locales/it.yml"),
  ja: () => import("../../vendor/engines/sim_dojo/config/locales/ja.yml"),
  ko: () => import("../../vendor/engines/sim_dojo/config/locales/ko.yml"),
  nl: () => import("../../vendor/engines/sim_dojo/config/locales/nl.yml"),
  no: () => import("../../vendor/engines/sim_dojo/config/locales/no.yml"),
  pl: () => import("../../vendor/engines/sim_dojo/config/locales/pl.yml"),
  pt: () => import("../../vendor/engines/sim_dojo/config/locales/pt.yml"),
  ro: () => import("../../vendor/engines/sim_dojo/config/locales/ro.yml"),
  ru: () => import("../../vendor/engines/sim_dojo/config/locales/ru.yml"),
  sl: () => import("../../vendor/engines/sim_dojo/config/locales/sl.yml"),
  sv: () => import("../../vendor/engines/sim_dojo/config/locales/sv.yml"),
  th: () => import("../../vendor/engines/sim_dojo/config/locales/th.yml"),
  tr: () => import("../../vendor/engines/sim_dojo/config/locales/tr.yml"),
  vi: () => import("../../vendor/engines/sim_dojo/config/locales/vi.yml"),
  "zh-CN": () => import("../../vendor/engines/sim_dojo/config/locales/zh-CN.yml"),
  "zh-TW": () => import("../../vendor/engines/sim_dojo/config/locales/zh-TW.yml"),
};

export const categoryLanguages = {
  ar: () => import("../../vendor/engines/category_dojo/config/locales/ar.yml"),
  de: () => import("../../vendor/engines/category_dojo/config/locales/de.yml"),
  el: () => import("../../vendor/engines/category_dojo/config/locales/el.yml"),
  en: () => import("../../vendor/engines/category_dojo/config/locales/en.yml"),
  es: () => import("../../vendor/engines/category_dojo/config/locales/es.yml"),
  fr: () => import("../../vendor/engines/category_dojo/config/locales/fr.yml"),
  id: () => import("../../vendor/engines/category_dojo/config/locales/id.yml"),
  it: () => import("../../vendor/engines/category_dojo/config/locales/it.yml"),
  ja: () => import("../../vendor/engines/category_dojo/config/locales/ja.yml"),
  ko: () => import("../../vendor/engines/category_dojo/config/locales/ko.yml"),
  nl: () => import("../../vendor/engines/category_dojo/config/locales/nl.yml"),
  no: () => import("../../vendor/engines/category_dojo/config/locales/no.yml"),
  pl: () => import("../../vendor/engines/category_dojo/config/locales/pl.yml"),
  pt: () => import("../../vendor/engines/category_dojo/config/locales/pt.yml"),
  ro: () => import("../../vendor/engines/category_dojo/config/locales/ro.yml"),
  ru: () => import("../../vendor/engines/category_dojo/config/locales/ru.yml"),
  sl: () => import("../../vendor/engines/category_dojo/config/locales/sl.yml"),
  sv: () => import("../../vendor/engines/category_dojo/config/locales/sv.yml"),
  th: () => import("../../vendor/engines/category_dojo/config/locales/th.yml"),
  tr: () => import("../../vendor/engines/category_dojo/config/locales/tr.yml"),
  vn: () => import("../../vendor/engines/category_dojo/config/locales/vn.yml"),
  "zh-CN": () => import("../../vendor/engines/category_dojo/config/locales/zh-CN.yml"),
  "zh-TW": () => import("../../vendor/engines/category_dojo/config/locales/zh-TW.yml"),
};

export const srmLanguages = {
  ar: () => import("../../vendor/engines/srm/config/locales/ar.yml"),
  de: () => import("../../vendor/engines/srm/config/locales/de.yml"),
  el: () => import("../../vendor/engines/srm/config/locales/el.yml"),
  en: () => import("../../vendor/engines/srm/config/locales/en.yml"),
  es: () => import("../../vendor/engines/srm/config/locales/es.yml"),
  fr: () => import("../../vendor/engines/srm/config/locales/fr.yml"),
  id: () => import("../../vendor/engines/srm/config/locales/id.yml"),
  it: () => import("../../vendor/engines/srm/config/locales/it.yml"),
  ja: () => import("../../vendor/engines/srm/config/locales/ja.yml"),
  ko: () => import("../../vendor/engines/srm/config/locales/ko.yml"),
  nl: () => import("../../vendor/engines/srm/config/locales/nl.yml"),
  no: () => import("../../vendor/engines/srm/config/locales/no.yml"),
  pl: () => import("../../vendor/engines/srm/config/locales/pl.yml"),
  pt: () => import("../../vendor/engines/srm/config/locales/pt.yml"),
  ro: () => import("../../vendor/engines/srm/config/locales/ro.yml"),
  ru: () => import("../../vendor/engines/srm/config/locales/ru.yml"),
  sl: () => import("../../vendor/engines/srm/config/locales/sl.yml"),
  sv: () => import("../../vendor/engines/srm/config/locales/sv.yml"),
  th: () => import("../../vendor/engines/srm/config/locales/th.yml"),
  tr: () => import("../../vendor/engines/srm/config/locales/tr.yml"),
  vi: () => import("../../vendor/engines/srm/config/locales/vi.yml"),
  "zh-CN": () => import("../../vendor/engines/srm/config/locales/zh-CN.yml"),
  "zh-TW": () => import("../../vendor/engines/srm/config/locales/zh-TW.yml"),
};
