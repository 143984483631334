import React, { Component } from "react";

class DocumentLinkLocation extends Component {
  componentDidUpdate() {
    this.setValue();
  }

  setValue = () => {
    this.documentLinkLocation.value = this.props.configuration_setting_values.document_link_location;
  };

  linkLocation = (e) => {
    let temp = e.target.value;
    const { onValueChange } = this.props;
    onValueChange(temp, "document_link_location");
  };

  render() {
    const { translations, durationType, configuration_setting_values, currentUserEditConfigPermission } = this.props;
    return (
      <div className="clearboth form-item-block">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="form-item-block-title">{translations.participant_invite_guide_title}</div>
            <div className="input inline m-b10">
              <label className="config-label col-md-6 col-sm-6 col-xs-12 p-0">
                {translations.document_link_location_title}
              </label>
              <input
                ref={(ref) => (this.documentLinkLocation = ref)}
                type="text"
                name="document_link_location"
                id="document_link_location"
                defaultValue={configuration_setting_values.document_link_location}
                disabled={!currentUserEditConfigPermission}
                className="form-control col-md-4 col-sm-4 col-xs-12 dark-border"
                onBlur={(e) => this.linkLocation(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentLinkLocation;
