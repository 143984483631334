import { reducer as formReducer } from "redux-form";

import dashboardReducers from "./dashboardReducers";
import adminReducers from "./adminReducers";
import errorReducers from "./errorReducers";
import messageReducers from "./messageReducers";
import contractConfigurationReducer from "./contract/contractConfigurationReducers";
import lotReducers from "./lotReducers";
import createEditReducers from "./contract/createEditReducers";
import contractListingReducers from "./contract/contractListingReducers";
import simSettingReducers from "./simSettingReducers";
import loaderReducers from "./loaderReducers";
import bidLineItemComponentReducers from "./bidLineItemComponentReducers";
import modalHOCReducers from "../hoc/modalHOC/modalHOC.duck";
import lineItemReducers from "./lineItemReducers";
import uomReducers from "./uomReducers";
import picklistAndUnitSetReducers from "./picklistAndUnitSetReducers";
import srmReducers from "./srmReducers";
import customFieldsReducers from "./customField";
import ActivityReducer from "./activityReducer";

import qqReducer from "./qq/qqReducers";
import {
  participantGroupsReducer,
  eventParticipantsReducer,
  eventParticipantsOnboardingStatusReducer,
} from "./qq/companyReducers";
import { adminTextReducer, deleteAdminTextReducer } from "./qq/adminTextReducers";
import changeLanguageReducer from "./qq/changeLanguageReducers";
import qqmessagesReducer from "./qq/qqmessagesReducers";
import {
  userReducer,
  permissionReducer,
  tooltipReducer,
  companyDetailsReducer,
  featureEnableReducer,
  notifyParticipant,
  supplierStatus,
  updateSummaryHeader,
  sandpitParticipantsReducer,
  setListingParametersReducer,
  updateEventStateReducer,
  documentTemplateReducer,
} from "./qq/genericReducers";
import emListingReducer from "./qq/emarketplace/emListingReducers";
import emMessageReducer from "./qq/emarketplace/emMessageReducers";
import emSummaryReducer from "./qq/emarketplace/emSummaryReducers";
import materialDataReducer from "./qq/emarketplace/materialDataReducers";
import participantReducers from "./qq/emarketplace/participantReducers";
import errorMessageReducer from "./qq/errorMessageReducers";
import successMessageReducer from "./qq/successMessageReducers";

export default {
  customFieldsReducers,
  dashboardReducers,
  adminReducers,
  errorReducers,
  messageReducers,
  contractConfigurationReducer,
  contractListingReducers,
  simSettingReducers,
  lotReducers,
  createEditReducers,
  loaderReducers,
  bidLineItemComponentReducers,
  modalHOCReducers,
  lineItemReducers,
  uomReducers,
  picklistAndUnitSetReducers,
  srmReducers,
  ActivityReducer,
  form: formReducer,
  qqReducer,
  participantGroupsReducer,
  eventParticipantsReducer,
  eventParticipantsOnboardingStatusReducer,
  adminTextReducer,
  deleteAdminTextReducer,
  changeLanguageReducer,
  qqmessagesReducer,
  userReducer,
  permissionReducer,
  tooltipReducer,
  companyDetailsReducer,
  featureEnableReducer,
  notifyParticipant,
  supplierStatus,
  updateSummaryHeader,
  sandpitParticipantsReducer,
  setListingParametersReducer,
  updateEventStateReducer,
  documentTemplateReducer,
  emListingReducer,
  emMessageReducer,
  emSummaryReducer,
  materialDataReducer,
  participantReducers,
  errorMessageReducer,
  successMessageReducer,
};
