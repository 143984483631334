import React from "react";
import classNames from "classnames";
import { currencyPosition, fetchDecimalPlace, leadingBidPrice, showCurrency } from "../common";
import { canDisplayRank, canShowBidFormStatus, overallRankIfWeighted } from "../bidCommon";
import { useSelector } from "react-redux";
import { last } from "lodash";
import { postRfqPreAuction } from "../eventCommon";

/**
 * @description Show bid range of the lot
 * @param {React.Props} props
 * @returns {JSX.Element}
 */
const ShowRange = (props) => {
  const { translations, isInRange, minBidRange, maxBidRange, licCurrency, totalLic, locale } = props;

  // return if range is not present
  if (!minBidRange || !maxBidRange) return <></>;

  // show range in user specified currency
  const showRangeInCurrency = (range) =>
    showCurrency(range, licCurrency, fetchDecimalPlace(totalLic, range), locale, translations.number_format);

  return (
    <>
      <span className="title p-l5">{translations.bid_range}:</span>
      <span className={`font-w600 current-bid font-s18 vertical-aligntop ${isInRange ? "yes" : "no"}`}>
        <span>
          &nbsp;{showRangeInCurrency(minBidRange)}&nbsp;-&nbsp;{showRangeInCurrency(maxBidRange)}
        </span>
      </span>
    </>
  );
};

/**
 * @description Show title for the rank ie. Rank: or Rank after round 1/2...
 * @param {React.Props} props
 * @returns {JSX.Element}
 */
const Title = ({ title }) => <span className="title">{title}:</span>;

/**
 * @description Show Title or rank of a cell
 * @param {React.Props} props
 * @returns {JSX.Element}
 */
const RankContainer = ({ rank, title, id }) => (
  <>
    <Title title={title} />
    {rank ? (
      <span id={`complex_lot_bid_rank_${id}`} className={`lot-rank-symbol ranked${rank}`}>
        {rank}
      </span>
    ) : (
      "-"
    )}
  </>
);

/**
 * @description Show rank of a lot for different type of event
 * @param {React.Props} props
 * @returns {JSX.Element}
 */
const RankForDifferentEventTyped = (props) => {
  const { event, lot, bid, weightedRfqOrAuction, auctionTab } = props;
  const { event_rounds, eventRound, role } = useSelector(({ lotReducers }) => lotReducers);
  const { rank, rank_after_round } = props.translations;

  // calculate rank id bid exists
  const participantRank = bid ? overallRankIfWeighted(bid, weightedRfqOrAuction) : null;

  const isShowingRankForMsrfq =
    canDisplayRank(event, bid, auctionTab, role) &&
    participantRank &&
    event_rounds?.length > 1 &&
    !eventRound &&
    !!last(event_rounds)?.show_rank;

  const isShowingRankForRankedEvent = event.event_type === "Ranked" && (auctionTab || !postRfqPreAuction(event));

  // show leading bid price for Open events if best bid exists
  if (event.event_type === "Open") return <OpenEventBidValue {...props} />;
  // show rank for ranked event with title rank
  else if (isShowingRankForRankedEvent) return <RankContainer title={rank} id={lot.id} rank={participantRank} />;
  // show rank for rfq event with title "Rank after round"
  else if (isShowingRankForMsrfq) {
    const rankAfterRound = rank_after_round + " " + event_rounds?.[event_rounds?.length - 2]?.event_round;
    return <RankContainer title={rankAfterRound} id={lot.id} rank={participantRank} />;
  }
  return <></>;
};

/**
 * @description Show leading bid value for open events.
 * @param {React.Props} props
 * @returns {JSX.Element}
 */
const OpenEventBidValue = (props) => {
  const { licCurrency, lot, bid, bestBids, locale, totalLic } = props;
  const { leading_bid_value, number_format } = props.translations;
  return (
    <>
      <Title title={leading_bid_value} />
      &nbsp;
      <span className={`font-w600 current-bid font-s18 vertical-aligntop ${bid && bid.errors ? "no" : "yes"}`}>
        {bestBids[lot.id]
          ? leadingBidPrice(totalLic, bestBids[lot.id][0].price, licCurrency, locale, number_format)
          : "-"}
      </span>
    </>
  );
};

/**
 * @description Show rank of a lot.
 * @param {React.Props} props
 * @returns {JSX.Element}
 */
const ShowRankDetails = (props) => {
  const { auctionTab, event } = props;

  const isUnfinishedEvent = ["running", "pause", "completed", "closed", "rfq"].includes(event.current_state);
  const isShowingRankDetails = isUnfinishedEvent && (auctionTab || event.event_category_rfq);

  // return if isShowingRankDetails is falsy
  if (!isShowingRankDetails) return <></>;

  return (
    <span className="bid-status">
      <RankForDifferentEventTyped {...props} />
    </span>
  );
};

/**
 * @description Show Bid range/Rank for a lot
 * @param {React.Props} props
 * @returns {JSX.Element}
 */
const ShowBidRankOrRange = (props) => {
  const { auctionTab, lotBidLineItemComponents, event, lot, bid, isAuction, isProxyBid, isPlaceBidActive, lotLics } =
    props;

  // return if event is not auction or rfq
  if (!(isAuction || auctionTab || event.event_category_rfq)) return <></>;

  const showBidFormStatus = canShowBidFormStatus(
    event,
    lot,
    bid,
    lotBidLineItemComponents,
    auctionTab && isPlaceBidActive ? true : false
  );

  // calculate totalLic for lot
  const totalLic = lotLics.find((lic) => lot.lot_total_lic_id === lic.id);

  return (
    <li className="pull-left p-l15">
      <div className="markup">
        {isProxyBid || showBidFormStatus ? (
          <ShowRange totalLic={totalLic} {...props} />
        ) : (
          <ShowRankDetails totalLic={totalLic} {...props} />
        )}
      </div>
    </li>
  );
};

/**
 * @description Show bid status for a lot. This consists of Bid range/Rank
 * @param {React.Props} props
 * @returns {JSX.Element}
 */
export default function ComplexLotBidStatus(props) {
  const { translations, isAuctionPending, totalBidPrice, isInRange, auctionTab, lot, bid, isPlaceBidActive } = props;

  // calculate licCurrency for multcurrency events
  let licCurrency = props.licCurrency;
  if (props.isMultiCurrencyEvent && bid?.exchange_rate_id && props.currenciesHash?.[bid?.exchange_rate_id]) {
    licCurrency = props.currenciesHash[bid.exchange_rate_id];
  }

  return (
    <div className={`animated fadeIn m-t2 pull-left box-bid-info equal-table-head-bottom lot_${lot.id}`}>
      <span className="bid-status">
        <ul className="bid-field m-t10">
          <li className="pull-left">
            <span className="title">{translations.your_current_bid}:</span>
            <span
              className={classNames({
                "bold current-bid font-s18": true,
                "text-primary": isPlaceBidActive && isInRange,
                yes: !isPlaceBidActive && isInRange,
                no: !isInRange,
              })}
            >
              &nbsp;
              {currencyPosition(
                isAuctionPending && auctionTab ? "-" : totalBidPrice,
                licCurrency,
                translations.number_format
              )}
            </span>
          </li>
          <ShowBidRankOrRange licCurrency={licCurrency} {...props} />
        </ul>
      </span>
    </div>
  );
}
