import React, { Component } from 'react';
import { makeElementSticky } from '../../js/common';

let interval;

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
  UNSAFE_componentWillMount() {
    this.getTimeUntil(this.props.deadline);
    makeElementSticky('sheet-other_actions', 'countdown_timer_fixed');
  }
  componentDidMount() {
    interval = setInterval(() => this.getTimeUntil(this.props.deadline), 1000);
  }

  componentWillUnmount() {
    clearInterval(interval);
  }

  getTimeUntil(deadline) {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      this.setState({
        days: 0, hours: 0, minutes: 0, seconds: 0,
      });
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.round(time / (1000 * 60 * 60 * 24));
      this.setState({
        days, hours, minutes, seconds,
      });
    }
  }

  leading0(num) {
    return num < 10 ? `0${num}` : num;
  }

  getDeadLine() {
    const {
      days, hours, minutes, seconds,
    } = this.state;

    const { deadline, formattedDeadline, translations } = this.props;
    if (days > 0) {
      const daysStr = days === 1 ? translations.clock.day.replace("%{days}", days) : translations.clock.days.replace("%{days}", days);
      return (
        <div className='time_remaining_wrapper action to-time'>
          <i className='fa fa-clock-o' aria-hidden='true' />
          <span className='time'>{translations.summary_status.deadline}{":"}
            <b> {daysStr}</b>
          </span>
        </div>);
    } else if (days === 0 && hours > 0) {
      const hoursStr = hours === 1 ? translations.clock.about_hour.replace("%{hours}", hours) : translations.clock.about_hours.replace("%{hours}", hours);
      return (
        <div className='time_remaining_wrapper action to-time'>
          <i className='fa fa-clock-o' aria-hidden='true' />
          <span className='time'>{translations.summary_status.deadline}{":"}
            <b> {hoursStr}</b>
          </span>
        </div>);
    } else if (days === 0 && hours === 0 && minutes > 0) {
      return (
        <div className='time_remaining_wrapper action to-time'>
          <i className='fa fa-clock-o' aria-hidden='true' />
          <span className='time'>{translations.summary_status.deadline}{":"}
            <b> {this.leading0(minutes)}:{this.leading0(seconds)}</b>
          </span>
        </div>);
    } else if (days === 0 && hours === 0 && minutes === 0 && seconds > 0) {
      return (
        <div className='time_remaining_wrapper action to-time'>
          <i className='fa fa-clock-o' aria-hidden='true' />
          <span className='time'>{translations.summary_status.deadline}{":"}
            <b> 00:{this.leading0(seconds)}</b>
          </span>
        </div>);
    }
    clearInterval(interval);
    this.props.updateQuoteDetails();
  }

  render() {
    return (
      <div className='countdown_timer_fixed'>
        {this.getDeadLine()}
      </div>
    );
  }
}
export default Clock;
