import React, { useMemo, useCallback } from "react";
import Select2 from "react-select2-wrapper";
import Tooltip from "@/components/common/Tooltip";
import { InputWithSelectPropTypes, SimSettingInputPropTypes } from "./prop-types";

/**
 * @type {React.FC<typeof InputWithSelectPropTypes>}
 */

const InputWithSelect = (props) => {
  const { durationType, disabled, name, handleMesureChange, handleUnitChange, mesure, unit } = props;
  return (
    <>
      <input
        type="text"
        id={name}
        name={`${name}-Mesure`}
        defaultValue={mesure}
        className="number_precision form-control col-md-2 col-sm-2 col-xs-2 dark-border"
        size="5"
        onBlur={handleMesureChange}
        disabled={disabled}
      />
      <div className="col-md-2 col-sm-3 col-xs-9">
        <Select2
          name={`${name}-Unit`}
          className="duration_type form-control"
          value={unit}
          data={durationType}
          onSelect={handleUnitChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};

InputWithSelect.propTypes = InputWithSelectPropTypes;

/**
 * @type {React.FC<typeof SimSettingInputPropTypes>}
 */

const SimSettingInput = (props) => {
  const { translations, onChange, name, label } = props;

  const [mesure, unit] = useMemo(() => props.value.split("."), [props.value]);
  const handleMesureChange = useCallback((event) => onChange(`${event.target.value}.${unit}`), [onChange, unit]);
  const handleUnitChange = useCallback((event) => onChange(`${mesure}.${event.target.value}`), [mesure, onChange]);

  return (
    <div className="input inline m-b10" key={name}>
      <label htmlFor={name} className="config-label col-md-4 col-sm-4 col-xs-12 p-0">
        {translations[label]}
      </label>
      <InputWithSelect
        {...props}
        handleMesureChange={handleMesureChange}
        handleUnitChange={handleUnitChange}
        mesure={mesure}
        unit={unit}
      />
      {label === "notify_participants" ? <div className="vertical-center">{translations.before_expiry}.</div> : null}
      <div className="vertical-center">
        <Tooltip {...translations.configuration[name]} />
      </div>
    </div>
  );
};

SimSettingInput.propTypes = SimSettingInputPropTypes;

export default SimSettingInput;
