import React from "react";
import BidButtonGroup from "./BidButtonGroup";
import LotStatusLabel from "./LotStatusLabel";
const BidButtonsOrStatus = (props) => {
  return props.showButtons ||
    props.allowtoShowProxyBtn ||
    ((props.isJapaneseMonitor || props.isLotLevelMonitor) &&
      props.bid &&
      !props.isEventTotal &&
      !props.isRejectedLot &&
      props.isEventCompleted &&
      !props.bidDetailsAdded) ? (
    <BidButtonGroup {...props} />
  ) : (
    <LotStatusLabel
      bidDetailsAdded={props.bidDetailsAdded}
      currencySymbol={props.licCurrency?.symbol}
      lot={props.lot}
      showLabel={props.bid?.showLabel}
      totalBidPrice={props.totalBidPrice}
    />
  );
};

export default BidButtonsOrStatus;
