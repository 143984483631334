import React, { Component } from "react";
import { connect } from "react-redux";
import map from "lodash/map";
import pick from "lodash/pick";
import remove from "lodash/remove";
import CreateLotButtons from "./CreateLotButtons";
import LotsContainer from "./LotsContainer";
import { classesFromEventState, eventTotalLot } from "../common";
import { getUpdatedValue, lotAutomationEnforcedAndNotSandpit, selectLotAutomationState } from "./lotCommon";
import LotModal from "./LotModal";
import * as actionTypes from "../../../actions/actionsTypes";
import { regenerateLots } from "@/actions/lotActions";

class EditEventLots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: props.event,
      lots: props.lots,
      allowedLotType: props.allowed_lot_type,
      translations: props.translations,
      lotComponents: props.lot_components,
      lineItems: props.line_items,
      bids: props.bids,
      lineItemComponents: props.line_item_components,
      isJapaneseEvent: props.is_japanese_event,
      weightedRfqOrAuction: props.weighted_rfq_or_auction,
      permittedToEdit: props.permitted_to_edit,
      eventCurrency: props.event_currency,
      currenciesHash: props.currencies_hash,
      maxPrecisionFor: props.max_precision_for,
      lotTemplates: props.lot_templates,
      locale: props.locale,
      currentUser: props.current_user,
      maxNumberOfRankedLics: props.max_number_of_ranked_lics,
      maxNumberOfCellsPerEvent: props.max_number_of_cells_per_event,
      maxNumberOfCellsForBidAtDetailLevel: props.max_number_of_cells_for_bid_at_detail_level,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const data = nextProps.data;
    let lineItemComponents = [];
    let lots = [];
    let reRenderDetails = prevState.reRenderDetails || {};
    let newState = {};

    if (data) {
      const { actionType } = data;
      switch (actionType) {
        case actionTypes.CREATE_UPDATE_LOT:
          lots = getUpdatedValue(prevState.lots, data.lot);
          newState = { lots: lots };
          break;
        case actionTypes.CREATE_LOT_COMPONENT:
        case actionTypes.UPDATE_LOT_COMPONENT: {
          // const lotComponents = getUpdatedValue(prevState.lotComponents, data.lotComponent);
          let lots = prevState.lots;

          const isChangingLc = (lc) => {
            /**
             * change lc only if
             * - it belongs to same lot of edited lc
             * - it has line item total and lc.id !== data.lotComponent.id
             */
            return (
              lc.id !== data.lotComponent.id &&
              !!data.lotComponent.line_item_total &&
              data.lotComponent.lot_id === lc.lot_id
            );
          };

          const lcs = [data.lotComponent, ...prevState.lotComponents.filter((lc) => lc.id !== data.lotComponent.id)]
            .map((lc) => (isChangingLc(lc) ? { ...lc, line_item_total: false } : lc))
            .sort((lc1, lc2) => lc1.id - lc2.id);

          if (data.lineItemComponents !== undefined) {
            lineItemComponents = [...data.lineItemComponents];
          }

          newState = {
            lots,
            lotComponents: lcs,
            lot_components: lcs,
            lineItemComponents: data.lineItemComponents,
          };
          break;
        }
        case actionTypes.UPLOAD_LOT_TEMPLATE:
          newState = {
            lineItemComponents: data.lineItemComponents,
            lineItems: data.lineItems,
          };
          break;
        case actionTypes.DELETE_LOT:
        case actionTypes.DELETE_LINE_ITEM:
        case actionTypes.DELETE_LOT_COMPONENT:
          newState = {
            lots: data.lots,
            lineItems: data.lineItems,
            lotComponents: data.lotComponents,
            lineItemComponents: data.lineItemComponents,
          };
          break;
        case actionTypes.USE_LOT_TEMPLATE:
          newState = {
            lots: data.lots,
            lineItems: data.lineItems,
            lotComponents: data.lotComponents,
            lineItemComponents: data.lineItemComponents,
          };
          break;
        case actionTypes.CREATE_LOT_TEMPLATE:
          let { lotTemplates } = prevState;
          lotTemplates = getUpdatedValue(lotTemplates, data.lotTemplate);
          newState = { lotTemplates: lotTemplates };
          break;
        case actionTypes.UPDATE_LINE_ITEM_COMPONENT: {
          lots = getUpdatedValue(prevState.lots, data.lot);
          var removableBidIds = map(data.lotBids, pick("id"));
          remove(prevState.bids, (bid) => removableBidIds.includes(bid.id));
          const { lineItems, lotComponents } = data;

          newState = {
            lineItemComponents: data.lineItemComponents,
            lots: lots,
            lotComponents: lotComponents,
            lineItems: lineItems,
            bids: [...prevState.bids, ...data.lotBids],
          };
          break;
        }
        case actionTypes.SET_LOT_TOTAL:
          lots = getUpdatedValue(prevState.lots, data.lot);
          newState = {
            lineItemComponents: data.lineItemComponents,
            lots: lots,
          };
          break;
        case regenerateLots.fulfilled.type: {
          newState = {
            lots: data.lots,
            lineItems: data.lineItems,
            lotComponents: data.lotComponents,
            lineItemComponents: data.lineItemComponents,
          };
          break;
        }
        default:
          return null;
      }
    } else {
      return null;
    }
    newState["reRenderDetails"] = reRenderDetails;
    return newState;
  }

  componentDidUpdate() {
    const { lots } = this.state;
    const { lotAutomationState } = this.props;
    if (lots.length === 0 && !lotAutomationState.isEnforced) {
      let path = window.location.pathname.split("/");
      path[path.length - 1] = "lots";
      window.location.pathname = path.join("/");
    }
  }

  render() {
    const { allowedLotType, translations, lotComponents, event, lots, bids, permittedToEdit, lotTemplates } =
      this.state;
    const classFromEventState = classesFromEventState(event);
    const {
      openOrJapanese,
      time_zone_identifier: timeZoneIdentifier,
      hide_from_participants_switch: hideFromParticipantsSwitch,
      partial_bidding_switch: partialBiddingSwitch,
      any_completed_bids: anyCompletedBids,
      lAEnforcedAndNotSandpit,
    } = this.props;

    const etLot = eventTotalLot(lots);
    const eventHasAnyBids = bids.length > 0;
    const showCreateET = !etLot && permittedToEdit && !eventHasAnyBids && !lAEnforcedAndNotSandpit;

    return (
      <>
        <CreateLotButtons
          allowedLotType={allowedLotType}
          translations={translations}
          lotComponents={lotComponents}
          event={event}
          lots={lots}
          classFromEventState={classFromEventState}
          lotTemplates={lotTemplates}
          permittedToEdit={permittedToEdit}
          eventHasAnyBids={eventHasAnyBids}
          etLot={etLot}
          partialBiddingSwitch={partialBiddingSwitch}
          anyCompletedBids={anyCompletedBids}
        />
        <LotsContainer
          {...this.state}
          classFromEventState={classFromEventState}
          openOrJapanese={openOrJapanese}
          timeZoneIdentifier={timeZoneIdentifier}
          hideFromParticipantsSwitch={hideFromParticipantsSwitch}
          partialBiddingSwitch={partialBiddingSwitch}
          anyCompletedBids={anyCompletedBids}
        />
        {showCreateET && (
          <LotModal
            total={true}
            translations={translations}
            eventId={event.id}
            allowedLotType={allowedLotType}
            lots={lots}
            classFromEventState={classFromEventState}
            partialBiddingSwitch={partialBiddingSwitch}
            anyCompletedBids={anyCompletedBids}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.lotReducers,
    lotAutomationState: selectLotAutomationState(state),
    lAEnforcedAndNotSandpit: lotAutomationEnforcedAndNotSandpit(state),
  };
};

export default connect(mapStateToProps, null)(EditEventLots);
