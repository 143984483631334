// eslint-disable-next-line no-unused-vars
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { uniq } from "lodash";
import { createSelector } from "@reduxjs/toolkit";
import { useForm } from "./useForm";
import useParticipant, { createParticipantChangeHandler } from "./useParticipant";
import { useQuickSelectHandler } from "./useQuickSelect";
import { getFileName } from "../../common";
import { createMessageChangeHandler } from "./useMessage";

/**
 *
 * @param {boolean} isHostTabSelected
 * @param {Array} documents
 * @returns {[{text: string, id: number}]} documentsToSelect
 */
export const getDocumentsToSelect = (isHostTabSelected, documents) =>
  (isHostTabSelected
    ? documents
    : documents.filter(({ with_permission_to }) => !with_permission_to.includes("internal_document"))
  ).map(({ name, filename, id }) => ({ text: name || getFileName(filename), id }));

const createDocumentChangeHandler = (setState) => {
  /**
   * Handle document selection
   * @param {React.MouseEvent<HTMLElement>} e
   */
  return (e) => {
    const isSelected = e.params.data.selected;
    const selectedDocId = parseInt(e.params.data.id);

    setState(({ selectedDocumentIds }) => ({
      selectedDocumentIds: isSelected
        ? uniq([...selectedDocumentIds, selectedDocId])
        : selectedDocumentIds.filter((docId) => docId !== selectedDocId),
    }));
  };
};

// get all the states that are used in useMessageModal
const messageModalStateSelecion = createSelector(
  ({ lotReducers }) => lotReducers.event,
  ({ lotReducers }) => lotReducers.current_user,
  ({ lotReducers }) => lotReducers.hosts,
  ({ lotReducers }) => lotReducers.host_permission,
  ({ messageReducers }) => messageReducers.showModal,
  (event, currentUser, allHosts, hostPermission, showModal) => {
    // filter out current user from list of hosts
    const hosts = allHosts.filter((host) => host.id !== currentUser.id);
    return {
      event,
      hosts,
      hostPermission,
      showModal,
    };
  }
);

function useMessageModal({ marketDojo, documents, selectedParticipants }) {
  const { hosts } = useSelector(messageModalStateSelecion);
  const { formState, isHostTabSelected, setState, selectTabs, handleSendMessage } = useForm(
    selectedParticipants,
    marketDojo
  );

  const handleMessageChange = createMessageChangeHandler(setState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const documentsToSelect = useMemo(
    () => getDocumentsToSelect(isHostTabSelected, documents),
    [isHostTabSelected, documents]
  );

  /**
   * Handle document selection
   * @param {React.MouseEvent<HTMLElement>} e
   */
  const handleDocumentSelect = createDocumentChangeHandler(setState);

  const isParticipantSelectedonOtherTab = useParticipant(hosts, isHostTabSelected);
  /**
   * Handle participant selection
   * @param {React.MouseEvent<HTMLElement>} e
   */
  const handleParticipantChange = createParticipantChangeHandler(setState, isParticipantSelectedonOtherTab);

  /**
   * Handle quick select change
   * @param {React.MouseEvent<HTMLElement>} e
   */
  const handleQuickSelect = useQuickSelectHandler(setState, isHostTabSelected, hosts);

  return {
    ...formState,
    isHostTabSelected,
    hosts,
    documentsToSelect,
    handleDocumentSelect,
    handleMessageChange,
    handleParticipantChange,
    handleQuickSelect,
    handleSendMessage,
    selectTabs,
    setState,
  };
}

export default useMessageModal;
