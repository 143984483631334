import React from "react";
import { useSelector } from "react-redux";
import { canShowRank, isRankedCell } from "../../../../common";
import OverallScoreHeading from "./OverallScoreHeading";
import { isRfq, isWeightedRfqOrAuction } from "../../../../eventCommon";

const LiHeadingForPriceComponent = (props) => {
  const event_rounds = useSelector((state) => state.lotReducers.event_rounds) || [];
  const { lic, beforeAuction, event, titleWithTooltip } = props;
  const participantHeadings = [
    ["participant", "bid_participant"],
    ["participants_bid", "bid_bids"],
  ];
  if (isRfq(event) && event_rounds.length > 1) participantHeadings.splice(1, 0, ["rfq_round", "rfq_round"]);

  const isShowingRank =
    isRankedCell(lic) && canShowRank(event, beforeAuction) && !(beforeAuction && props.isJapaneseEvent);

  return (
    <tr>
      {isShowingRank && titleWithTooltip(isWeightedRfqOrAuction(event) ? "weighted_rank" : "rank", "bid_rank")}
      {participantHeadings.map((values) => titleWithTooltip(...values))}
      <OverallScoreHeading {...props} titleWithTooltip={titleWithTooltip} />
      {[
        ["time_of_bid", "bid_time_of_bid"],
        ["total_lot_price_difference", "lot_total_lot_price_difference"],
        ["total_lot_price_difference_percentage", "lot_total_lot_price_difference_percentage"],
      ].map((values) => {
        return titleWithTooltip(...values);
      })}
    </tr>
  );
};

export default LiHeadingForPriceComponent;
