import React from "react";
import { useSelector } from "react-redux";
import Select2 from "react-select2-wrapper";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { eventParticipantStatus } from "@/components/advanced_lots/common";
import { displayName } from "@/components/advanced_lots/eventParticipantCommon";
import { getTab, quickSelectOptions } from "./Container";
import { TableHeader } from "./Header";
import {
  DocumentSelectPropTypes,
  ParticipantListPropTypes,
  ParticipantQuickSelectPropTypes,
  ParticipantTablePropTypes,
  TabContentPropTypes,
} from "./prop-types";

/**
 *
 * @type {React.FC<PropTypes.InferProps<TabContentPropTypes>>}
 * @returns {React.ReactElement}
 */
const TabContent = ({
  isHostTabSelected: isHostTab,
  marketDojo,
  handleDocumentSelect,
  documentsToSelect,
  selectedDocumentIds,
  quickSelect,
  handleQuickSelect,
  selectedParticipantIds,
  handleParticipantChange,
  hosts,
}) => {
  if (!isHostTab && !marketDojo) return null;

  return (
    <>
      <DocumentSelect
        documentsToSelect={documentsToSelect}
        handleDocumentSelect={handleDocumentSelect}
        selectedDocumentIds={selectedDocumentIds}
      />

      <ParticipantQuickSelect isHostTab={isHostTab} handleQuickSelect={handleQuickSelect} quickSelect={quickSelect} />

      <ParticipantTable
        isHostTab={isHostTab}
        handleParticipantChange={handleParticipantChange}
        selectedParticipantIds={selectedParticipantIds}
        hosts={hosts}
      />
    </>
  );
};

TabContent.propTypes = TabContentPropTypes;
TabContent.defaultProps = {};

export default TabContent;

/**
 *
 * @type {React.FC<PropTypes.InferProps<DocumentSelectPropTypes>>}
 * @returns {React.ReactElement}
 */
export const DocumentSelect = ({ selectedDocumentIds, documentsToSelect, handleDocumentSelect }) => {
  const { document, selectOption } = useSelector(({ lotReducers }) => lotReducers.translations);

  if (!documentsToSelect.length) return null;

  return (
    <div className="input form-group">
      <label htmlFor="selectDocuments">{document}</label>
      <div className="markup">
        <Select2
          id="selectDocuments"
          multiple
          value={selectedDocumentIds}
          data={documentsToSelect}
          options={{
            placeholder: selectOption,
          }}
          onSelect={handleDocumentSelect}
          onUnselect={handleDocumentSelect}
        />
      </div>
    </div>
  );
};

DocumentSelect.propTypes = DocumentSelectPropTypes;
DocumentSelect.defaultProps = {
  selectedDocumentIds: [],
  documentsToSelect: [{}],
  handleDocumentSelect: () => {},
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<ParticipantQuickSelectPropTypes>>}
 * @returns {React.ReactElement}
 */
const ParticipantQuickSelect = ({ isHostTab, handleQuickSelect, quickSelect }) => {
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);

  const tab = getTab(isHostTab);

  return (
    <div className="input participant-list">
      <label className="message_message">{translations.sendMessageTo}</label>
      <div className="markup">
        <table className="table">
          <tbody>
            <tr>
              {quickSelectOptions[tab].map(({ value, text }) => (
                <td key={value}>
                  <label className="css-input css-checkbox css-checkbox-default" htmlFor={value}>
                    <input
                      id={value}
                      onChange={handleQuickSelect}
                      type="checkbox"
                      value={value}
                      checked={quickSelect.includes(value)}
                      className="all_participants_checkboxes"
                      title={translations[text]}
                    />
                    <span />
                    {translations[text]}
                  </label>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

ParticipantQuickSelect.propTypes = ParticipantQuickSelectPropTypes;
ParticipantQuickSelect.defaultProps = {
  handleQuickSelect: () => {},
  quickSelect: [],
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<ParticipantTablePropTypes>>}
 * @returns {React.ReactElement}
 */
const ParticipantTable = ({ hosts, isHostTab, handleParticipantChange, selectedParticipantIds }) => {
  const eventParticipants = useSelector(({ lotReducers }) => lotReducers.event_participants);
  return (
    <div className="input scroll-y recipient">
      <div className="scrolling_outer">
        <div id="chtable-0wrapper" className="scrolling_inner" style={{ height: "185px" }}>
          <table className="table _scrolling _thead">
            <TableHeader isHostTab={isHostTab} />
            <tbody>
              {(isHostTab ? hosts : eventParticipants).map((participant) => (
                <ParticipantList
                  key={participant.id}
                  isHostTab={isHostTab}
                  participant={participant}
                  handleParticipantChange={handleParticipantChange}
                  selectedParticipantIds={selectedParticipantIds}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

ParticipantTable.propTypes = ParticipantTablePropTypes;
ParticipantTable.defaultProps = {
  hosts: [],
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<ParticipantListPropTypes>>}
 * @returns {React.ReactElement}
 */
const ParticipantList = ({ isHostTab, participant, handleParticipantChange, selectedParticipantIds }) => {
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);
  const participants = useSelector(({ lotReducers }) => lotReducers.participants) ?? [];
  const hostPermission = useSelector(({ lotReducers }) => lotReducers.host_permission);
  const { id, user_id, display_name, status, email } = participant;

  let userId = user_id,
    userNameOrEmail = display_name ?? displayName(participant, participants),
    participantStatus = translations[status] || translations[eventParticipantStatus(participant)];

  if (isHostTab) {
    userId = id;
    userNameOrEmail = email;
    participantStatus = hostPermission[id];
  }

  return (
    <tr className={isHostTab ? "editor viewer" : "active_participants"} key={id}>
      <td>
        <label className="css-input css-checkbox css-checkbox-default" htmlFor={userId}>
          <input
            value={userId}
            data-testid={userId}
            onChange={handleParticipantChange}
            type="checkbox"
            checked={selectedParticipantIds.includes(userId)}
            id={userId}
          />
          <span />
        </label>
      </td>
      <td {...(() => isHostTab && { colSpan: 2 })()}>
        <label htmlFor={userId}>{userNameOrEmail}</label>
      </td>
      {!isHostTab && (
        <td>
          <label htmlFor={userId}>{email}</label>
        </td>
      )}
      <td>{participantStatus}</td>
    </tr>
  );
};

ParticipantList.propTypes = ParticipantListPropTypes;
ParticipantList.defaultProps = {
  selectedParticipantIds: [],
  participant: {},
  handleParticipantChange: () => {},
};
