import React from "react";
import HeaderForJapaneseAndLotLevelEvent from "../HeaderForJapaneseAndLotLevelEvent";

export const Header = (props) => {
  const leadingOrRank = props.event.event_type == "Open" ? ["leading_bid", "lot_leading_bid"] : ["your_rank", "rank"];
  const values = [
    ["lot_position", "lot_position", "lot", "wid-7"],
    ["lot_name", "lot_lot_name", "", "wid-17"],
    ["bid_range", "bid_range", "bidrange", "wid-14"],
    ["your_current_bid", "bid_price", "price", "wid-16"],
    ["total_lot_value_placed", "total_lot_value_placed", "", "wid-16"],
    [leadingOrRank[0], leadingOrRank[1], "rank", "wid-10"],
    ["enter_new_bid", "enter_new_bid", "newbid"],
  ];
  return (
    <div className="table-top ranked-auction">
      <HeaderForJapaneseAndLotLevelEvent translations={props.translations} values={values} />
    </div>
  );
};
export default Header;