import { addNotes } from "@/actions/contract/contractCreateEditActions";
import { useState } from "react";
import { useDispatch } from "react-redux";

const initFormState = {
  content: "",
  make_private_note: false,
  bookmark_note: false,
};

export default function useCreateNote(contractId) {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState(initFormState);
  const [isLoading, setIsLoading] = useState(false);

  const handleContentChange = (value) => setFormState((state) => ({ ...state, content: value }));

  const handleAddNote = async () => {
    const { content, bookmark_note, make_private_note } = formState;
    if (!content) return;

    setIsLoading(true);
    const result = await dispatch(addNotes(contractId, content, make_private_note, bookmark_note));
    if (result) setFormState(initFormState);

    setIsLoading(false);
  };

  const handleCheckBoxChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    setFormState((state) => ({ ...state, [name]: checked }));
  };

  return { formState, isLoading, handleAddNote, handleCheckBoxChange, handleContentChange };
}
