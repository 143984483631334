import React from "react";
import PropTypes from "prop-types";
import { Contract } from "@/common-prop-types";

const ContractActionIconPropTypes = {
  /**
   * All the necessary translations for the Contracts
   *
   * @type {object}
   */
  translations: PropTypes.object.isRequired,

  /**
   * All the necessary translations for the Contracts
   *
   * @type {string}
   */
  currentState: PropTypes.string.isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<ContractActionIconPropTypes>>}
 */

export const ContractActionIcon = ({ translations, currentState }) => {
  switch (currentState) {
    case "draft":
      return <i title={translations.remove} className="fa fa-trash" aria-hidden="true"></i>;
    case "archived":
      return <i title={translations.unarchive} className="fa fa-undo" aria-hidden="true"></i>;
    default:
      return <i title={translations.archive} className="fa fa-file-archive-o" aria-hidden="true"></i>;
  }
};

ContractActionIcon.propTypes = ContractActionIconPropTypes;
ContractActionIcon.defaultProps = {
  translations: {},
  currentState: "active",
};

const ContractActionsPropTypes = {
  /**
   * @type {object}
   */
  contract: PropTypes.shape(Contract),

  /**
   * All the necessary translations for the Contracts
   *
   * @type {object}
   */
  translations: PropTypes.object.isRequired,

  /**
   *
   * @type {Array}
   */
  editContracts: PropTypes.arrayOf(PropTypes.number),

  /**
   * Current user details
   *
   * @type {object}
   */
  currentUser: PropTypes.object,

  /**
   * Fucntion to be triggered on clikcing the contract's action icon
   * It could be delete, archive or unarchive icon for a contract
   *
   * @type {() => void}
   */
  openConfirmation: PropTypes.func.isRequired,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<ContractActionsPropTypes>>}
 */

const ContractActions = ({ contract, editContracts, currentUser, openConfirmation, translations }) => {
  return (
    <td style={{ textAlign: "center" }} data-testid="actionIcon">
      <div className="dropdown dropdown-table">
        {editContracts.includes(contract.id) || contract.user_id === currentUser.id ? (
          <button className="hands-on action-Icon" onClick={(e) => openConfirmation(e, contract)}>
            <ContractActionIcon translations={translations} currentState={contract.current_state} />
          </button>
        ) : null}
      </div>
    </td>
  );
};
ContractActions.propTypes = ContractActionsPropTypes;
ContractActions.defaultProps = {
  contract: {},
  editContracts: [],
  currentUser: {},
  openConfirmation: () => {},
  translations: {},
};

export default ContractActions;
