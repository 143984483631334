import React from "react";
import Tooltip from "../../../../common/Tooltip";

const FeedbackSurveyScoreWrapper = ({ translations, children }) => {
  return (
    <div className="form-group">
      <Tooltip title={translations.infos.survey_score.title} body={translations.infos.survey_score.body} />
      <label>{translations.survey_score}</label>
      <table className="table">{children}</table>
    </div>
  );
};

const SurveyScoreInputWrapper = ({arr, setSurveyScore, surveyScore}) => {
  return (
    <tr>
      {arr.map((idx) => (
        <td key={idx}>
          <input type="radio" value={idx} onChange={setSurveyScore} checked={surveyScore == idx} />
        </td>
      ))}
    </tr>
  );
};

const arr = Array.from(Array(11).keys());
const FeedbackSurveyScore = (props) => {
  const { translations, surveyScore, setSurveyScore } = props;
  return (
    <FeedbackSurveyScoreWrapper translations={translations}>
      <tbody>
        <tr>
          {[translations.not_at_all_likely, translations.extremely_likely].map((text, idx) => (
            <td colSpan="6" key={idx}>
              <span className={idx == 0 ? "float-left" : "float-right"}>{text}</span>{" "}
            </td>
          ))}
        </tr>
        <tr>
          {arr.map((idx) => (
            <td key={idx}>{idx}</td>
          ))}
        </tr>
        <SurveyScoreInputWrapper arr={arr} setSurveyScore={setSurveyScore} surveyScore={surveyScore} />
      </tbody>
    </FeedbackSurveyScoreWrapper>
  );
};

export default FeedbackSurveyScore;
