import React from "react";
import type { Leaf, NormalizedNode } from "@/slices/rules/types";
import { checkNode } from "@/slices/rules/types";
import CheckboxParameter from "./checkbox";
import NumberParameter from "./number";
import DateParameter from "./date";
import TextParameter from "./text";

export default function Parameter({ node }: { node: NormalizedNode<Leaf> }) {
  switch (true) {
    case checkNode.isLeaf.withCheckbox(node):
      return <CheckboxParameter node={node} />;
    case checkNode.isLeaf.withNumber(node):
      return <NumberParameter node={node} />;
    case checkNode.isLeaf.withDate(node):
      return <DateParameter node={node} />;
    case checkNode.isLeaf.withText(node):
      return <TextParameter node={node} />;
    default:
      return null;
  }
}
