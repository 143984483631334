/**
 * Create a from with all of the data as hidden inputs
 *
 * This function should not be used in the app its only exported for testing.
 * @internal
 */
export function createHiddenForm(data = {}) {
  const form = document.createElement("form");

  for (const key in data) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  }

  return form;
}

/**
 * Make a post request in js by create a form and submitting it. Any data
 * passed will be added as hidden inputs and will end up in the request body.
 * This can be used as an alternative to `window.open` when you want to post
 * data.
 *
 * @example
 * postRequest({ url: '/some/url', data: { foo: 'bar' } });
 *
 *
 * @example
 * // You can post to a new tab by passing a target to the function.
 * postRequest({ url: '/some/url', target: "_blank" });
 *
 */
export function postRequest({ url, target, data = {} }) {
  const form = createHiddenForm(data);

  form.action = url;
  form.method = "post";
  if (typeof target === "string") form.target = target;

  document.body.appendChild(form);
  form.submit();

  // Clean up the form from the dom. This is for when we are using a target
  // `_blank`, its not so important if we are refreshing the page.
  document.body.removeChild(form);
}
