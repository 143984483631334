import React from "react";

const HeaderValues = (props) => {
  const { translation, value, classNames } = props;
  return (
    <tr className={classNames}>
      <td>{translation}</td>
      <td>
        <strong>{value}</strong>
      </td>
    </tr>
  );
};

HeaderValues.defaultProps = {
  classNames: "",
};

export default HeaderValues;
