import React from "react";

const searchActivity = (props) => {
  const { searchParam, setSearchParam, translations, handlePageChange } = props;

  return (
    <div className="col-md-12">
      <div className="search-center text-center">
        <div className="input-search-center">
          <div className="input-group">
            <input
              type="text"
              name="search"
              className="form-control placeholder"
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
              placeholder={translations.search}
            />
            <span className="input-group-btn">
              <button
                className="btn btn-default btn-gray"
                id="search"
                type="button"
                onClick={() => handlePageChange({}, true)}
              >
                <i className="fa fa-search"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default searchActivity;
