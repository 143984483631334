import type { Field, MultiChoiceField, PicklistField } from "@/slices/rules/fields";
import type {
  DateParameter,
  Leaf,
  NormalizedBranch,
  NormalizedNode,
  NormalizedTree,
  TextParameter,
} from "@/slices/rules/types";
import { checkNode, getLeafParameterType } from "@/slices/rules/types";
import { brand } from "@/types/util";
import { assert } from "@/utils/assert";
import { isValid, parse } from "date-fns";

export enum NodeIssue {
  /** Field name is not chosen */
  FieldNotChosen = "FieldNotChosen",
  /** Field name is not valid */
  InvalidField = "InvalidField",
  /** Leaf parameter is a valid type, but the wrong type for the field */
  MismatchedParameterType = "MismatchedParameterType",
  /** Leaf parameter is not a valid type. */
  InvalidParameterType = "InvalidParameterType",
  /** Node not found in tree */
  NodeNotFound = "NodeNotFound",
  /** Date is not chosen */
  DateNotChosen = "DateNotChosen",
  /** Date is invalid */
  InvalidDate = "InvalidDate",
  /** Picklist is not chosen */
  PicklistNotChosen = "PicklistNotChosen",
  /** Option is invalid */
  InvalidOption = "InvalidPicklistOption",
}

export const NodeIssueTranslation: Record<NodeIssue, string> = {
  [NodeIssue.NodeNotFound]: "node_not_found",
  [NodeIssue.FieldNotChosen]: "field_not_chosen",
  [NodeIssue.InvalidField]: "invalid_field",
  [NodeIssue.MismatchedParameterType]: "invalid_field",
  [NodeIssue.InvalidParameterType]: "invalid_param_type",
  [NodeIssue.InvalidDate]: "invalid_date",
  [NodeIssue.DateNotChosen]: "date_not_chosen",
  [NodeIssue.PicklistNotChosen]: "picklist_not_chosen",
  [NodeIssue.InvalidOption]: "invalid_option",
};

export type NodeValidationMap = Partial<Record<string, NodeIssue>>;

const noIssues = brand(true, "valid");

const issueFound = brand(false, "invalid");

type ValidatorResult = typeof noIssues | typeof issueFound;

const reportIssue = (uuid: string, issue: NodeIssue, invalidNodes: NodeValidationMap) => {
  invalidNodes[uuid] = issue;
  return issueFound;
};

const makeValidator =
  (uuid: string, invalidNodes: NodeValidationMap) =>
  (isValid: unknown, issue: NodeIssue): ValidatorResult =>
    isValid ? noIssues : reportIssue(uuid, issue, invalidNodes);

type NodeValidator<Args extends unknown[]> = (invalidNodes: NodeValidationMap, ...args: Args) => ValidatorResult;

export const dbDateFormat = "yyyy-MM-dd 00:00:00 +0000";

const validateDateNode: NodeValidator<[node: NormalizedNode<Leaf<DateParameter>>]> = (invalidNodes, node) => {
  const validate = makeValidator(node.uuid, invalidNodes);
  return (
    validate(node.state.leaf_parameter.target_date, NodeIssue.DateNotChosen) &&
    validate(isValid(parse(node.state.leaf_parameter.target_date, dbDateFormat, new Date())), NodeIssue.InvalidDate)
  );
};

const validatePicklistNode: NodeValidator<[node: NormalizedNode<Leaf<TextParameter>>, field: PicklistField]> = (
  invalidNodes,
  node,
  field
) => {
  const validate = makeValidator(node.uuid, invalidNodes);
  return (
    validate(node.state.leaf_parameter.parameters[0], NodeIssue.PicklistNotChosen) &&
    validate(field.options.includes(node.state.leaf_parameter.parameters[0]), NodeIssue.InvalidOption) &&
    validate(node.state.leaf_parameter.parameters.length === 1, NodeIssue.InvalidParameterType) &&
    validate(node.state.leaf_parameter.count === 1, NodeIssue.InvalidParameterType)
  );
};

const validateMultiChoiceNode: NodeValidator<[node: NormalizedNode<Leaf<TextParameter>>, field: MultiChoiceField]> = (
  invalidNodes,
  node,
  field
) => {
  const validate = makeValidator(node.uuid, invalidNodes);
  return (
    validate(node.state.leaf_parameter.parameters.length <= field.options.length, NodeIssue.InvalidParameterType) &&
    validate(
      node.state.leaf_parameter.parameters.every((option) => field.options.includes(option)),
      NodeIssue.InvalidOption
    ) &&
    validate(node.state.leaf_parameter.count <= field.options.length, NodeIssue.InvalidParameterType)
  );
};

const validateTextNode: NodeValidator<
  [node: NormalizedNode<Leaf<TextParameter>>, field: PicklistField | MultiChoiceField]
> = (invalidNodes: NodeValidationMap, node, field) => {
  if (field.type === "pick_one_from_list") {
    return validatePicklistNode(invalidNodes, node, field);
  } else if (field.type === "multiple_choice") {
    return validateMultiChoiceNode(invalidNodes, node, field);
  }
  return noIssues;
};

export const textParameterTypes: Array<Field["type"]> = ["pick_one_from_list", "multiple_choice"];

const validateField = (
  invalidNodes: NodeValidationMap,
  node: NormalizedNode<Leaf>,
  fields: Record<string, Field>
): [true, Field] | [false, never?] => {
  const field = fields[node.state.field_name];
  return field ? [noIssues, field] : [reportIssue(node.uuid, NodeIssue.InvalidField, invalidNodes), undefined];
};

const validateParameterType: NodeValidator<[node: NormalizedNode<Leaf>, field: Field]> = (
  invalidNodes,
  node,
  field
) => {
  let parameterType: ReturnType<typeof getLeafParameterType>;
  try {
    parameterType = getLeafParameterType(node.state.leaf_parameter);
  } catch {
    return reportIssue(node.uuid, NodeIssue.InvalidParameterType, invalidNodes);
  }
  const parameterMatches =
    parameterType === "text" ? textParameterTypes.includes(field.type) : parameterType === field.type;
  if (!parameterMatches) {
    return reportIssue(node.uuid, NodeIssue.MismatchedParameterType, invalidNodes);
  }
  return noIssues;
};

const validateLeafNodeByType: NodeValidator<[node: NormalizedNode<Leaf>, field: Field]> = (
  invalidNodes,
  node,
  field
) => {
  if (checkNode.isLeaf.withDate(node)) {
    return validateDateNode(invalidNodes, node);
  } else if (
    checkNode.isLeaf.withText(node) &&
    (field.type === "pick_one_from_list" || field.type === "multiple_choice")
  ) {
    return validateTextNode(invalidNodes, node, field);
  }
  return noIssues;
};

const validateLeafNode: NodeValidator<[node: NormalizedNode<Leaf>, fields: Record<string, Field>]> = (
  invalidNodes,
  node,
  fields
) => {
  if (!node.state.field_name) {
    return reportIssue(node.uuid, NodeIssue.FieldNotChosen, invalidNodes);
  }
  const [validField, field] = validateField(invalidNodes, node, fields);
  if (!validField || !validateParameterType(invalidNodes, node, field)) return issueFound;

  return validateLeafNodeByType(invalidNodes, node, field);
};

const validateBranchNode: NodeValidator<
  [node: NormalizedNode<NormalizedBranch>, fields: Record<string, Field>, tree: NormalizedTree]
> = (invalidNodes, node, fields, tree) => {
  let valid: ValidatorResult = noIssues;
  for (const child of node.state.child_nodes) {
    if (!validateNode(child, fields, tree, invalidNodes)[0]) {
      // we don't report issues for branches, just leaves
      valid = issueFound;
    }
  }
  return valid;
};

/**
 * Validates a node against a set of fields, returning false if the node is invalid.
 * @param node The node to validate
 * @param fields A record of fields to validate against
 * @param invalidNodes A map of invalid nodes to issues
 * @returns A tuple of a boolean indicating if the node is valid, and a map of invalid nodes to issues
 */
export const validateNode = (
  uuid: string,
  fields: Record<string, Field>,
  tree: NormalizedTree,
  invalidNodes: NodeValidationMap = {}
): [valid: false, invalidNodes: NodeValidationMap] | [valid: true, invalidNodes?: never] => {
  const node = tree[uuid];
  if (!node) {
    return [reportIssue(uuid, NodeIssue.NodeNotFound, invalidNodes), invalidNodes];
  }
  if (checkNode.isBranch(node)) {
    return validateBranchNode(invalidNodes, node, fields, tree) ? [true] : [false, invalidNodes];
  }
  assert(checkNode.isLeaf(node), "Node is not a leaf or a branch");
  return validateLeafNode(invalidNodes, node, fields) ? [true] : [false, invalidNodes];
};
