import React, { useMemo, useState, useEffect, lazy, Suspense } from "react";
import {
  showCurrency,
  lotCurrentValue,
  lotQualificationValue,
  lotBids,
  showValue,
  findLotTotalLic,
  canShowExpandButtons,
  isLotTotalPresent,
} from "../../common";

import {
  bestBidBeforeAuctionStarted,
  bestBidsBeforeAuctionStarted,
  activeBidsBeforeAuctionStarted,
  latestBidDuringAuctionForLot,
  validBidsDuringAuction,
} from "../../bidCommon";
import last from "lodash/last";
import ParticipantBidDetail from "./participantBidDetail";
import LotInformationForOverview from "./lotInformationForOverview";
const LotLineItemsInformation = lazy(() => import("./lotLineItemInformation"));
import ParticipantBidValueForLotAndLineItem from "./ParticipantBidValueForLotAndLineItem";
import { expandLiForLot, expandLcForParticipant } from "../../customCollapse";
import LotDetailModal from "./modal/LotDetailModal";
import UpdateCurrentPriceComplexLot from "../../monitor/UpdateCurrentPriceComplexLot";
import { useSelector } from "react-redux";
import SliceProvider from "@/context/SliceContext";
import { lotLineItemsInformationSlice } from "@/slices/pagination";

/**
 *
 * @description  HOST MONITOR TAB
 *
 * This will show Lot information and bid value
 */
const LotNameAndBidDetailsForOverview = (props) => {
  const {
    event,
    bids,
    lot,
    translations,
    currency,
    locale,
    isMultiCurrencyEvent,
    participatingParticipants,
    lotLics = [],
    weightedRfqOrAuction,
    lotLis,
    currenciesHash,
    lots,
    beforeAuction,
    isLcOpened,
    isLiOpened,
    isEpOpened,
    toggleLiCollapse,
    permittedToEdit,
    isJapaneseEvent,
    bidRound,
    auctionTab,
  } = props;

  const { loaderReducers } = useSelector((state) => state);
  const { isFetchBidsLoading } = loaderReducers;

  const isLotTotal = isLotTotalPresent(lot);
  const lotTotalLic = findLotTotalLic(lotLics, lot.lot_total_lic_id) ?? {};

  // calculate current and qualification price for Lot
  const precision = lotTotalLic?.decimal_place ?? 2;
  const price = (value) => showCurrency(value, currency, precision, locale, translations.number_format);
  const currentValue = price(showValue(lotCurrentValue(lot, true)));
  const qualificationValue = price(showValue(lotQualificationValue(lot, true)));

  const lineItemId = useMemo(() => {
    const lic = lotLics.find((lic) => lic.id === lot.lot_total_lic_id);
    if (lic) {
      return lic.line_item_id;
    }
  }, [lotLics, lot]);

  const lineItem = lotLis.find((li) => li.id === lineItemId);
  const LBids = lotBids(lot.id, bids);
  const lotsActiveBids = validBidsDuringAuction(event, participatingParticipants, LBids).length;

  let bestBidsBeforeAuction = auctionTab
    ? latestBidDuringAuctionForLot(LBids, participatingParticipants, event, weightedRfqOrAuction)
    : bestBidsBeforeAuctionStarted(event, LBids, participatingParticipants, weightedRfqOrAuction);
  const bestBidBeforeAuction = bestBidBeforeAuctionStarted(bestBidsBeforeAuction);
  const activeBidCountBeforeAuction = activeBidsBeforeAuctionStarted(event, LBids, participatingParticipants);

  const [activeBids, setActiveBids] = useState(lotsActiveBids);
  const getCellClass = () => {
    if (activeBids < lotsActiveBids) {
      return "value_up_green";
    } else if (activeBids > lotsActiveBids) {
      return "value_down_red";
    }
  };
  const changeCellClass = auctionTab ? getCellClass() : "";

  useEffect(() => {
    setTimeout(() => {
      setActiveBids(lotsActiveBids);
    }, 1000);
  }, [lotsActiveBids]);

  // Header for lot overview modal
  const header = `${translations.current_bids}: ${lot.name}`;

  return (
    <>
      <div className={mainDivClasses(lot, lots)}>
        <div className={`mycell row-title first-row-title ${lot.is_event_total ? "light-gray-color" : ""}`}>
          <div className="cell-inner">
            <span title={lot.name} className="pull-left light-gray-color">
              {lot.position}.
              <LotDetailModal
                viewComponent={lot.name}
                translations={translations}
                lot={lot}
                currentValue={currentValue}
                qualificationValue={qualificationValue}
              />
            </span>
            {canShowExpandButtons(event, beforeAuction) && (
              <a
                onClick={toggleLiCollapse}
                className={`icon-only collapse-expend-butt lot-button pull-left ${
                  expandLiForLot(isLiOpened, lot.id) ? "ion-ios-minus-outline" : "ion-ios-plus-outline"
                }`}
                data-lot-id={lot.id}
              ></a>
            )}
          </div>
        </div>
        {/* Lot Current Price */}
        <div className="mycell lot-information">
          <div className="row">
            <div className="mycell" title={currentValue}>
              {isLotTotal ? currentValue : "-"}
              {isLotTotal &&
                (auctionTab || event.event_category_rfq) &&
                event.current_state === "completed" &&
                permittedToEdit &&
                !lot.is_event_total && (
                  <UpdateCurrentPriceComplexLot
                    translations={translations}
                    lot={lot}
                    lotLics={lotLics}
                    currency={currency}
                    currenciesHash={currenciesHash}
                    isMultiCurrencyEvent={isMultiCurrencyEvent}
                  />
                )}
            </div>
            {/* Acive Bid Count or Qualification value */}
            <div
              className={`mycell ${changeCellClass}`}
              title={auctionTab ? "" : isLotTotal ? qualificationValue : "-"}
            >
              {auctionTab ? (isJapaneseEvent ? bidRound : lotsActiveBids) : isLotTotal ? qualificationValue : "-"}
            </div>
          </div>
        </div>
        {participatingParticipants.map((ep) => {
          return (isEpOpened || (isEpOpened && expandLiForLot(isLiOpened, lot.id))) &&
            !expandLcForParticipant(isLcOpened, ep.id) ? (
            <ParticipantBidValueForLotAndLineItem
              {...props}
              key={`${ep.id}-${Date.parse(ep.updated_at)}-${lot.id}`}
              ep={ep}
              LBids={LBids}
              isLineItemDetail={false}
              isLoadingStatus={isFetchBidsLoading}
              currentValue={currentValue}
              qualificationValue={qualificationValue}
              header={header}
              bestBidBeforeAuction={bestBidBeforeAuction}
            />
          ) : (
            expandLcForParticipant(isLcOpened, ep.id) && (
              <ParticipantBidDetail
                {...props}
                key={`${ep.id}-${Date.parse(ep.updated_at)}-${lot.id}`}
                lineItemId={lineItemId}
                ep={ep}
                LBids={LBids}
                header={header}
                lineItem={lineItem}
                isLineItemDetail={false}
                currentValue={currentValue}
                isLoadingStatus={isFetchBidsLoading}
                bestBidBeforeAuction={bestBidBeforeAuction}
              />
            )
          );
        })}

        <LotInformationForOverview
          {...props}
          precision={precision}
          bestBidsBeforeAuction={bestBidsBeforeAuction}
          bestBidBeforeAuction={bestBidBeforeAuction}
          LBids={LBids}
          activeBidCountBeforeAuction={activeBidCountBeforeAuction}
          currentValue={currentValue}
          qualificationValue={qualificationValue}
        />
      </div>
      {expandLiForLot(isLiOpened, lot.id) && (
        <Suspense fallback={`${translations.loading}...`}>
          <SliceProvider slice={lotLineItemsInformationSlice}>
            <LotLineItemsInformation
              {...props}
              bestBidsBeforeAuction={bestBidsBeforeAuction}
              bestBidBeforeAuction={bestBidBeforeAuction}
              LBids={LBids}
              activeBidCountBeforeAuction={activeBidCountBeforeAuction}
              lotsActiveBids={lotsActiveBids}
            />
          </SliceProvider>
        </Suspense>
      )}
    </>
  );
};

/**
 * @description get classes for div wrapping lot row
 *
 * @param {Object} lot
 * @param {Array} lots
 *
 * @returns {String} classes - Calculated classes for Lot Row
 */
const mainDivClasses = (lot, lots) => {
  let classes = `row lot-row lot_row_${lot.id} closed lot-information-first-cell lot-row-last `;

  classes += lot.is_event_total ? "lot-totals-row lot-totals-row-last " : " ";
  classes += last(lots) == lot ? "lot-row-last " : " ";
  classes += !lot.is_event_total && last(lots) == lot ? "without-total-last-border " : " ";
  return classes;
};

export default LotNameAndBidDetailsForOverview;
