import React from 'react';
import config from './js/config';

const PermissionDenied = props => (
  <div className='col-sm-12 permission-denied'>
    <div className='block block-themed animated fadeIn'>
      <div className='block-content-full block-content-narrow'>
        <div className='main_box'>
          <div className='middle'>
            <h2>Sorry, you do not have permission to access this area.</h2>
                The typical reasons for seeing this message are:
            <h3>If you are a Host</h3>
            <ul className='list'>
              <li>You are not able to make edits as the Online Negotiation Event has already begun,</li>
              <li>The Event is not one of yours,</li>
              <li>The Event has either been completed or removed,</li>
              <li>You are trying to access an area that is limited to Admin users only.</li>
            </ul>
            <h3>If you are a Participant</h3>
            <ul className='list'>
              <li>You have recently been declined from the Event whilst
                    still logged in to Market Dojo (please check your e-mail to see if you have been declined),
              </li>
              <li>You have not been invited by the Host to the Event you are trying to access,</li>
              <li>The Event has either been completed or removed,</li>
              <li>You are trying to access an area that is limited to Admin users only.</li>
            </ul>
            <h3>To proceed...</h3>
            <div>Please press the browser “Back” button to return to your previous screen or click &nbsp;
              {config.isQuickQuotes ?
                <a href={`/quick_quotes/quotes`}>{'here'}</a>
                :
                <a href={`/emarketplace`}>{'here'}</a>
              }
                  &nbsp;to return to your Dashboard.&nbsp;
                Alternatively, if you feel that none of the above apply to your instance,
                please inform the Market Dojo <a href='/support'><span>support team</span></a> and we shall be happy to help.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PermissionDenied;
