import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Tooltip from "../../../common/Tooltip";
import Select2 from "react-select2-wrapper";
import { useAppSelector } from "@/hooks/redux";
import { selectCompanyTier } from "@/slices/company";

const ApprovedSupplierForm = (props) => {
  const { translations, configuration_setting_values, currentUserEditConfigPermission, onValueChange } = props;
  const approvedSupplierFormUpdate = (e) => onValueChange(e.target.value, e.target.name);

  let options = [
    { text: translations.groups, id: "groups" },
    { text: translations.custom_fields, id: "custom_fields" },
    { text: translations.groups_and_custom_fields, id: "groups_&_custom_fields" },
    { text: translations.do_not_categorise, id: "do_not_categorise" },
  ];

  return (
    <div className="inline m-b10">
      <label className="config-label col-md-4 col-sm-4 col-xs-12 p-0">{translations.approved_supplier_form}</label>
      <div className="col-md-3 col-sm-3 col-xs-12">
        <Select2
          name="approved_supplier_form"
          className="settings form-control"
          value={configuration_setting_values.approved_supplier_form}
          data={options}
          onSelect={(e) => approvedSupplierFormUpdate(e)}
          disabled={!currentUserEditConfigPermission}
        />
      </div>
    </div>
  );
};

export const SimplifiedExpiredDocumentProcess = (props) => {
  const { translations, configuration_setting_values,
          currentUserEditConfigPermission, onValueChange } = props;
  const [simplified_expired_doc_process, setSimplifiedExpiredDocProcess] = useState(
    configuration_setting_values.allow_simplified_expired_document_process
  );
  const simplifiedExpiredDocProcess = (e) => {
    let value = e.target.checked;
    onValueChange(value, "allow_simplified_expired_document_process");
    setSimplifiedExpiredDocProcess(value);
  }
  return(
    <>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <SimplifiedExpiredDocumentInput
            translations={translations} currentUserEditConfigPermission={currentUserEditConfigPermission}
            simplified_expired_doc_process={simplified_expired_doc_process}
            simplifiedExpiredDocProcess={(e) => simplifiedExpiredDocProcess(e)}
          />
        </div>
      </div>
    </>
  );
};

export const SimplifiedExpiredDocumentInput = (props) => {
  const{
    translations,
    currentUserEditConfigPermission,
    simplified_expired_doc_process,
    simplifiedExpiredDocProcess
  } = props;
  return(
    <div className="input inline m-b10">
      <label className="config-label col-md-5 col-sm-5 col-xs-12 p-0">
        {translations.allow_simplified_expired_document_process}
      </label>
      <input
        type="checkbox"
        name="simplified_expired_document_process"
        id="simplified_expired_document_process"
        className="material-checkbox"
        checked={simplified_expired_doc_process}
        onChange={(e) => simplifiedExpiredDocProcess(e)}
        disabled={!currentUserEditConfigPermission}
      />
      <div className="inline-block p-l15">
        <Tooltip {...translations.configuration.allow_simplified_expired_document_process} />
      </div>
    </div>
  );
};

const SimWorkFlow = (props) => {
  const { translations, configuration_setting_values, currentUserEditConfigPermission, onValueChange } = props;
  const companyTier = useAppSelector((state) => selectCompanyTier(state, "sim"));

  const [only_admins_to_override_workflow, setAdminWorkflow] = useState(
    configuration_setting_values.only_admins_to_override_workflow
  );

  const workflowUpdate = (event) => {
    let value = event.target.checked;
    let selectedValue = substractValues(value);
    onValueChange(selectedValue, "only_admins_to_override_workflow");
    setAdminWorkflow(selectedValue);
  };

  const substractValues = (value) => {
    return value ? "on" : "off";
  };

  const workflow_values = () => {
    return only_admins_to_override_workflow == "on" ? true : false;
  };

  return (
    <React.Fragment>
      <div className="col-md-6 form-item-block wid-49">
        <div className="form-item-block-title">{translations.admin_views.configuration.workflow}</div>
        {companyTier === "gold" && (
          <>
            <SimplifiedExpiredDocumentProcess {...props} />
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="input inline m-b10">
                  <label className="config-label col-md-5 col-sm-5 col-xs-12 p-0">
                    {translations.admin_views.configuration.only_admins_to_override_workflow}
                  </label>
                  <input
                    type="checkbox"
                    name="only_admins_to_override_workflow"
                    id="only_admins_to_override_workflow"
                    className="material-checkbox"
                    checked={workflow_values()}
                    onChange={(event) => workflowUpdate(event)}
                    disabled={!currentUserEditConfigPermission}
                  />
                  <div className="inline-block p-l15">
                    <Tooltip {...translations.configuration.only_admins_to_override_workflow} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <ApprovedSupplierForm {...props} />
      </div>
    </React.Fragment>
  );
};

export default SimWorkFlow;
