import React, { useState } from "react";
import Tooltip from "../../../common/Tooltip";

const NotifyBeforeOnboardingComplete = (props) => {
  const { translations, configuration_setting_values, currentUserEditConfigPermission, onValueChange } = props;
  const [notify_before_onboarding_complete, setNotifyBeforeOnboarding] = useState(
    configuration_setting_values.require_unexpired_docs_for_onboarding
  );
  const notifyBeforeOnboardingUpdate = (event) => {
    let selectedValue = event.target.checked
    onValueChange(selectedValue, "require_unexpired_docs_for_onboarding");
    setNotifyBeforeOnboarding(selectedValue);
  };
  return (
    <div className="input inline m-b10">
      <label className="config-label col-md-5 col-sm-5 col-xs-12 p-0">
        {translations.require_unexpired_docs_for_onboarding}
      </label>
      <input type="checkbox" name="notify_before_onboarding_complete"
        id="notify_before_onboarding_complete" className="material-checkbox"
        checked={notify_before_onboarding_complete}
        onChange={notifyBeforeOnboardingUpdate}
        disabled={!currentUserEditConfigPermission}
      />
      <div className="inline-block p-l15">
        <Tooltip {...translations.configuration.require_unexpired_docs_for_onboarding} />
      </div>
    </div>
  );
};

export default NotifyBeforeOnboardingComplete;
