import React, { Fragment, lazy, Suspense } from "react";
import propTypes from "prop-types";
const ParticipantBidDetails = lazy(() => import("./ParticipantBidDetails"));

/**
 * @description
 * Monitor Tab =>
 * Opens up once clicked on Show Components =>
 * maps lotComponents
 */
export default function ParticipantBidDetail(props) {
  const { ep, lotLcs } = props;

  return (
    <div className={`mycell supplier lot-component-bid-value supplier-cell-${ep.user_id}`}>
      <div className="row">
        {lotLcs.map((lotComponent, index) => (
          <Fragment key={`${index}-${lotComponent.id}-${Date.parse(lotComponent.updated_at)}`}>
            <Suspense fallback={<>Loading...</>}>
              <ParticipantBidDetails {...props} lotComponent={lotComponent} index={index} />
            </Suspense>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

ParticipantBidDetail.propTypes = {
  ep: propTypes.object.isRequired,
  lotLcs: propTypes.array.isRequired,
};

ParticipantBidDetail.defaultProps = {
  ep: {},
  lotLcs: [],
};
