import React, { Component } from "react";
import { connect } from "react-redux";
import map from "lodash/map";
import { selectListAll, sortContractsBy } from "../../../actions/contract/contractListingActions";

class ContractFilterHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectAllPage: {},
      contractsSelected: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const intr = setInterval(function () {
      const element = document.querySelectorAll("thead.sticky-header tr");
      if (element.length) {
        clearInterval(intr);
        let trHeight = element.item(0).offsetHeight;
        if (trHeight <= 30) {
          document.querySelectorAll("tr.search-row td").forEach((item) => item.classList.add("one_line_col"));
        }
        if (trHeight <= 45 && trHeight > 30) {
          document.querySelectorAll("tr.search-row td").forEach((item) => item.classList.add("two_line_col"));
        }
        if (trHeight <= 60 && trHeight > 45) {
          document.querySelectorAll("tr.search-row td").forEach((item) => item.classList.add("three_line_col"));
        }
      }
    }, 500);
    if (
      nextProps.data &&
      nextProps.data.selectall &&
      JSON.stringify(prevState.contractsSelected[nextProps.selectedPage]) !=
        JSON.stringify(nextProps.data.selectall.contractsSelected[nextProps.selectedPage])
    ) {
      //if any of the contracts are selected or deselected remove selectall
      return {
        selectAllPage: nextProps.data.selectall.selectAllPage,
        contractsSelected: nextProps.data.selectall.contractsSelected,
      };
    }
    return null;
  }

  generateCustomFieldFilterHeader = () => {
    const { dropdown_options, translations } = this.props;
    let custom_columns = [];
    map(Object.keys(dropdown_options), (cc) => {
      custom_columns.push(JSON.parse(cc));
    });
    return map(custom_columns, (cc) => {
      return (
        <th key={cc.id} className={[1, 2].includes(cc.column_type_cd) ? "w-11" : "w-8"}>
          {translations[cc.name] || cc.name}
        </th>
      );
    });
  };

  selectAll = (e) => {
    const { selectedPage, contracts, currentUserCompany, allow_admin_section } = this.props;
    let { selectAllPage, contractsSelected } = this.state;
    let c = [...contracts];
    let tmp = [];
    c.map((contract) => {
      if (currentUserCompany.id == contract.contract_user_company && allow_admin_section) {
        tmp.push(contract.id);
      }
    });
    if (e.target.checked) {
      selectAllPage[selectedPage] = true;
      contractsSelected[selectedPage] = tmp;
      this.setState({
        selectAllPage,
        contractsSelected,
      });
    } else {
      delete selectAllPage[selectedPage];
      delete contractsSelected[selectedPage];
      this.setState({
        selectAllPage,
        contractsSelected,
      });
    }
    this.props.selectListAll({
      selectAllPage: selectAllPage,
      contractsSelected: contractsSelected,
    });
  };

  clickHandle = (e, field) => {
    if (e.target.classList.contains("asc")) {
      e.target.classList.remove("asc");
      e.target.classList.add("desc");
      this.props.sortContractsBy(field, "desc");
    } else if (e.target.classList.contains("desc")) {
      e.target.classList.remove("desc");
      e.target.classList.add("asc");
      this.props.sortContractsBy(field, "asc");
    } else {
      $(".asc").removeClass("asc");
      $(".desc").removeClass("desc");
      e.target.classList.add("asc");
      this.props.sortContractsBy(field, "asc");
    }
  };
  render() {
    const {
      translations,
      contracts,
      allow_admin_section,
      any_non_permitted_contract,
      canShow,
      custom_columns,
      currentUserCompany,
      selectedPage,
      data,
    } = this.props;
    const { selectAllPage } = this.state;
    let showChangeOwner = data && data.selectall && Object.keys(data.selectall.contractsSelected).length;
    const thProps = {
      owner: {
        className: "sortable w-8",
        onClick: (e) => this.clickHandle(e, "owner"),
      },
      category: {
        className: "sortable category-col w-11",
        onClick: (e) => this.clickHandle(e, "category"),
      },
      title: {
        className: "title-col w-10",
      },
      parent: {
        className: "w-8",
      },
      contact_company_name: {
        className: "sortable contact_company_name-col w-8",
        onClick: (e) => this.clickHandle(e, "contact_company_name"),
      },
      supplier: {
        className: "w-10",
      },
      reference: {
        className: "w-8",
      },
      auto_renew: {
        className: "w-8",
      },
      start_date: {
        className: "sortable w-11",
        onClick: (e) => this.clickHandle(e, "start_date"),
      },
      expiry_date: {
        className: "sortable w-11",
        onClick: (e) => this.clickHandle(e, "expiry_date"),
      },
      stakeholders: {
        className: "w-8",
      },
      current_state: {
        className: "sortable w-10 col-two",
        onClick: (e) => this.clickHandle(e, "current_state"),
      },
      notice_period: {
        className: "w-8",
      },
      total_value: {
        className: "sortable w-11",
        onClick: (e) => this.clickHandle(e, "total_value"),
      },
      annual_value: {
        className: "sortable w-11",
        onClick: (e) => this.clickHandle(e, "annual_value"),
      },
    };

    return (
      <React.Fragment>
        <tr>
          <th style={{ width: "13px" }}></th>
          {any_non_permitted_contract.includes(currentUserCompany.id) && contracts.length > 0 && allow_admin_section && (
            <th
              className={"change_owner_box" + (showChangeOwner ? " w-10" : "")}
              id="change_owner_header"
              style={{ width: "33px" }}
            >
              <input
                type="checkbox"
                name="all_contracts"
                id="all_contracts"
                checked={selectAllPage[selectedPage]}
                onChange={(e) => this.selectAll(e)}
              />
              <label className="all_contracts_lbl" htmlFor="all_contracts"></label>
            </th>
          )}
          {map(canShow.data, (value, key) => {
            if (value) {
              return (
                <th id={key} key={key} {...thProps[key]}>
                  {translations[key === "title" ? "contract_title" : key]}
                </th>
              );
            }
          })}
          {custom_columns.length > 0 && this.generateCustomFieldFilterHeader()}
          <th className="w-8"></th>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.contractListingReducers,
});
const mapDispatchToProps = (dispatch) => ({
  selectListAll: (data) => dispatch(selectListAll(data)),
  sortContractsBy: (field, order) => dispatch(sortContractsBy(field, order)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContractFilterHeader);
