import actionTypes from '../../../actions/qq/actionsTypes';

const emMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.get_bids:
      return {
        ...state,
        bids_list: action.payload,
      };
    case actionTypes.get_bids_summary:
      return {
        ...state,
        bids_summary: action.payload,
      };
    case actionTypes.get_messages:
      return {
        ...state,
        messages_list: action.payload,
      };
    default:
      return state;
  }
};

export default emMessageReducer;
