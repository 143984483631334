import React, { Component } from "react";
import PropTypes from "prop-types";

import InfoPopover from "../../../InfoPopover";

/**
 * @typedef {PropTypes.InferProps<AwardToolTips.propTypes>} Props
 * @extends Component<Props>
 */
class AwardToolTips extends Component {
  static propTypes = {
    /**
     * The name of the tooltip data that this will display. There must be a
     * valid title and body for this name under the tooltipData.awardTooltips.
     * For example if the name is `lot` there must be valid data for
     * `tooltipData.awardTooltips.lot.title`
     */
    tooltipName: PropTypes.string.isRequired,
    /**
     * All of the tool top data with valid traslations
     */
    tooltipData: PropTypes.object.isRequired,
  };

  render() {
    const awardTooltips = this.props.tooltipData?.awardTooltips;
    const tooltipName = this.props.tooltipName;

    if (!tooltipName || !awardTooltips || !awardTooltips[tooltipName]) {
      return "";
    }

    return <InfoPopover title={awardTooltips[tooltipName].title} popoverContent={awardTooltips[tooltipName].body} />;
  }
}

export default AwardToolTips;
