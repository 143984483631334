import React from "react";
import ShowParticipantDetails from "./ParticipantDetailsTab";
import ShowTabDetails from "./TabDetails";
import ProxyBidUploadTemplate from "../../../../bids/ProxyBidUploadTemplate";

const DifferentiateTabData = (props) => {
  return <div className="bg-white p-15 two-block-box">{getActiveTab(props)}</div>;
};

const getActiveTab = (props) => {
  const {
    translations,
    activeTab,
    event,
    lots,
    user,
    participant,
    timeZoneIdentifier,
    bids,
    participatingParticipants,
    currenciesHash,
    locale,
    permittedToEdit,
    deleteBid,
    eventPassedFinalDeadline,
    permissionToView,
    isMultiCurrencyEvent,
    currency,
    hosts,
    lotComponents,
    lineItems,
    bidLineItemComponents,
    allUnitSets,
    allUom,
    bidControls,
    role,
    canShowDeleteBtn,
  } = props;
  const isAuctionTab = activeTab === translations.auction_bids_html;
  const isMsrfqTab = activeTab === translations.rfq_bids;
  const isParticipantsTab = activeTab === translations.participant_details_html;
  const isAuctionOrRfqTab = isAuctionTab || isMsrfqTab;

  let result = null;
  if (isParticipantsTab) {
    result = (
      <ShowParticipantDetails
        event={event}
        participant={participant}
        translations={translations}
        user={user}
        timeZoneIdentifier={timeZoneIdentifier}
        permittedToEdit={permittedToEdit}
      />
    );
  } else if (isAuctionOrRfqTab) {
    result = (
      <ShowTabDetails
        {...props}
        event={event}
        participant={participant}
        translations={translations}
        user={user}
        timeZoneIdentifier={timeZoneIdentifier}
        lots={lots}
        bids={bids}
        participatingParticipants={participatingParticipants}
        permittedToEdit={permittedToEdit}
        currenciesHash={currenciesHash}
        locale={locale}
        deleteBid={deleteBid}
        eventPassedFinalDeadline={eventPassedFinalDeadline}
        permissionToView={permissionToView}
        isMultiCurrencyEvent={isMultiCurrencyEvent}
        currency={currency}
        hosts={hosts}
        lotComponents={lotComponents}
        lineItems={lineItems}
        bidLineItemComponents={bidLineItemComponents}
        allUnitSets={allUnitSets}
        allUom={allUom}
        bidControls={bidControls}
        beforeAuction={false}
        role={role}
        auctionTab={true}
        canShowDeleteBtn={canShowDeleteBtn}
      />
    );
  } else {
    const isEventFinished = !["running", "pause", "completed"].includes(event.current_state);
    result = (
      <div className="p-t1">
        {isEventFinished && <ProxyBidUploadTemplate {...props} translations={translations} event={event} lots={lots} />}
        <ShowTabDetails
          {...props}
          event={event}
          participant={participant}
          translations={translations}
          user={user}
          timeZoneIdentifier={timeZoneIdentifier}
          lots={lots}
          bids={bids}
          participatingParticipants={participatingParticipants}
          permittedToEdit={permittedToEdit}
          currenciesHash={currenciesHash}
          locale={locale}
          deleteBid={deleteBid}
          eventPassedFinalDeadline={eventPassedFinalDeadline}
          permissionToView={permissionToView}
          isMultiCurrencyEvent={isMultiCurrencyEvent}
          currency={currency}
          hosts={hosts}
          lotComponents={lotComponents}
          lineItems={lineItems}
          bidLineItemComponents={bidLineItemComponents}
          allUnitSets={allUnitSets}
          allUom={allUom}
          bidControls={bidControls}
          beforeAuction={true}
          role={role}
          auctionTab={false}
        />
      </div>
    );
  }
  return result;
};

export default DifferentiateTabData;
