import React from "react";

const ShowNoFeedMessage = (props) => {
  const { role, translations, hasRankedLineItemComponents } = props;
  return (
    <div className="no_messages">
      <p
        dangerouslySetInnerHTML={{
          __html:
            role === "Host"
              ? translations.no_live_auction_feed_messages_html
              : hasRankedLineItemComponents
              ? translations.no_live_auction_feed_participant_html_for_ranked_event
              : translations.no_live_auction_feed_messages_html_participant_html,
        }}
      ></p>
    </div>
  );
};

export default ShowNoFeedMessage;
