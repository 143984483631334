import { connectStreamSource } from "@hotwired/turbo";

export class TurboStream extends EventTarget {
  async load(element: HTMLElement) {
    connectStreamSource(this);

    element.querySelectorAll('[data-lazy-stream]').forEach((element) => {
      if (element instanceof HTMLElement) {
        const url = element.dataset.lazyStream;
        if (!url) {
          return;
        }

        fetch(url).then((response) => response.text()).then((html) => {
          this.#dispatchEvent(html);
        });
      }
    });
  }

  #dispatchEvent(data: string) {
    const event = new MessageEvent("message", { data });
    return this.dispatchEvent(event);
  }
}