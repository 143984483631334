import React from "react";
import { useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { FooterPropTypes } from "./prop-types";

/**
 * @type {React.FC<PropTypes.InferProps<ModalFooterPropTypes>>}
 * @returns {React.ReactElement}
 */
const Footer = ({ isSendButtonDisabled, isHostTabSelected, sendMessage, checkToReopenEvent, hosts }) => {
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);

  if (isHostTabSelected && !hosts?.length) return null;

  return (
    <div className="modal-footer">
      <button href="#" className="button button button-black cancel btn btn-sm btn-default" data-dismiss="modal">
        {translations.cancel}
      </button>
      <button
        type="button"
        className="btn btn-primary sm-butt push-10-l"
        disabled={isSendButtonDisabled}
        {...(checkToReopenEvent
          ? {
              "data-target": "#reopenEventConfirmModal",
              "data-toggle": "modal",
            }
          : { onClick: sendMessage })}
      >
        {translations.sendMessage}
      </button>
    </div>
  );
};

Footer.propTypes = FooterPropTypes;
Footer.defaultProps = {
  isSendButtonDisabled: true,
  checkToReopenEvent: false,
  isMessageType: true,
  isHostTabSelected: true,
  sendMessage: () => {},
};

export default Footer;
