import React, { useMemo, useRef } from "react";
import Select2 from "react-select2-wrapper";
import { t } from "@/i18n";
import type { CustomColumnCommonProps } from "./pick-one-from-list";
import { useSelectAttribute } from "@adeattwood/react-form";
import Feedback from "@/cl/input/feedback";

interface MultipleChoiceProps extends CustomColumnCommonProps {}

const MultipleChoice: React.FC<MultipleChoiceProps> = ({ field, readOnly }) => {
  const inputRef = useRef<HTMLSelectElement>(null);

  const { props, error } = useSelectAttribute(field.id.toString(), true);
  const options = useMemo(
    () => field.custom_column_options.map((option) => ({ text: option.name, id: option.name })),
    [field.custom_column_options]
  );

  return (
    <>
      <Select2
        {...props}
        multiple
        className="form-control sim_dojo_multiple_select_cf"
        data={options}
        defaultValue={field.custom_column_values.flatMap((value) => value.name)}
        options={{ placeholder: t("select"), width: "100%" }}
        ref={inputRef}
        disabled={readOnly || !field.active}
        required={field.required}
        aria-labelledby={`custom-field-${field.id}-name`}
      />
      <Feedback className="required" id={props.id} error={error} />
    </>
  );
};

export default React.memo(MultipleChoice);
