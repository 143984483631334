import React from "react";
import flatten from "lodash/flatten";

import { isLotTotal } from "../../common";

const LicTagsForFormula = (props) => {
  const { lot, lots, handleFormulaChange, dragEnd, dragStart, lotWiseFormulaLics, draggable } = props;

  return (
    <div className="form-group">
      <div className="keys" id="lineItemComponents">
        <div id="constants">
          {/* # Show tags */}
          <div id="accordion" className="panel-group">
            {Object.keys(lotWiseFormulaLics).map((key, index) => {
              const currentLot = lots.find((l) => l.id == key);
              const lotFormulaLics = Object.values(lotWiseFormulaLics[key]);
              return flatten(lotFormulaLics).length == 0 ? (
                ""
              ) : (
                <div className="panel panel-default" key={`#collapse-${index}`}>
                  {lot.is_event_total && (
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href={`#collapse-${index}`}
                      className={"panel-title custom-display-block panel-heading " + (index === 0 ? "" : "collapsed")}
                    >
                      {currentLot.name}
                    </a>
                  )}

                  <div id={`collapse-${index}`} className={"panel-collapse collapse " + (index === 0 ? "in" : "")}>
                    <div className="panel-body">
                      <div className="lot-formula-ele verticle-scroll">
                        <div className="lot-formula-scroll">
                          {lotFormulaLics.map((liComponents, i) => {
                            return (
                              <div key={`${i}_${key}`}>
                                {liComponents.map((liComponent, index) => {
                                  return (
                                    <span
                                      key={`${index}_${liComponent.id}`}
                                      className={`value btn btn-default ${liComponent.is_formula_lic ? "calc" : ""} ${
                                        isLotTotal(currentLot, liComponent) && "lot_total"
                                      }`}
                                      onClick={handleFormulaChange}
                                      draggable={draggable}
                                      onDragEnd={dragEnd}
                                      onDragStart={dragStart}
                                    >
                                      {liComponent.tag}
                                    </span>
                                  );
                                })}
                                <div className="clearfix"></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicTagsForFormula;
