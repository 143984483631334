import React, { Suspense, lazy, useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { activeAndDroppedParticipants, getCurrentEventRound } from "../common";
import ParticipantTable from "./ParticipantTable";
import moment from "moment";
import { isReverseDirection } from "../../../../common";

const ReactDatePicker = lazy(() => import("react-datepicker"));

export default function AddEventForm({ formState, setFormState, ...rest }) {
  const { translations, event, event_rounds, event_participants, event_participant_event_rounds } = useSelector(
    (state) => state.lotReducers
  );
  const { bid_deadline, loading, allow_bids_to_increase, allow_bids_to_decrease } = translations;
  const dateRef = useRef();

  const currentEventRoundId = getCurrentEventRound(event_rounds)?.id;
  const { activeParticipants, droppedParticipants } = activeAndDroppedParticipants(
    event_participants,
    event_participant_event_rounds,
    currentEventRoundId
  );

  const now = moment().toDate();

  const isToday = moment(formState.event_round.deadline).day() === moment().day() || !formState.event_round.deadline;

  const [timeConstrains, setTimeConstrains] = useState({
    minDate: now,
    minTime: now,
    maxTime: moment().endOf("day").toDate(),
  });

  useEffect(() => {
    setTimeConstrains((constrains) => ({
      ...constrains,
      minTime: isToday ? now : moment().startOf("day").toDate(),
    }));
  }, [formState.event_round.deadline]);

  useEffect(() => {
    // set Active participants ids
    setFormState((state) => ({ ...state, participant_ids: activeParticipants.map((participant) => participant.id) }));
  }, []);

  const handleChange = (e) => {
    e.persist();
    const { name, checked, id } = e.target;

    // handle event_round value change
    if (Object.keys(formState.event_round).includes(name))
      setFormState((state) => ({
        ...state,
        event_round: { ...state.event_round, [name]: !!checked },
      }));
    // handle participant change
    else
      setFormState((state) => ({
        ...state,
        [name]: !!checked
          ? [...state[name], Number(id)]
          : state[name].filter((item) => {
              return item !== Number(id);
            }),
      }));
  };

  // handle date change
  const handleDateChange = (e) => {
    setFormState((state) => ({
      ...state,
      event_round: { ...state.event_round, deadline: moment(e).isBefore(now) ? now : e },
    }));
  };

  const iconStyle = { height: "40px", width: "40px", paddingTop: "12px" };

  return (
    <>
      <div className="form-group">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="bid-deadline">{bid_deadline}</label>
            <div className="datetime-input datetime-zone">
              <div className="input-group" style={{ display: "flex", alignItems: "flex-start" }}>
                <Suspense fallback={<div>{loading}</div>}>
                  <ReactDatePicker
                    minDate={timeConstrains.minDate}
                    minTime={timeConstrains.minTime}
                    maxTime={timeConstrains.maxTime}
                    excludeOutOfBoundsTime
                    selected={formState.event_round.deadline}
                    onChange={handleDateChange}
                    timeIntervals={5}
                    ref={dateRef}
                    name="deadline"
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="yyyy-MM-dd H:mm"
                    timeCaption="Time"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    id="deadline"
                    className="form-control form_date form_datetime"
                    autoComplete="off"
                  />
                </Suspense>
                <span
                  className="input-group-addon open_calendar"
                  style={iconStyle}
                  onClick={() => dateRef.current?.setFocus?.()}
                >
                  <span className="fa fa-calendar"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          {["allow_worse_bids", "show_rank"].map((item) => (
            <div className="col-md-6" key={item}>
              <label className="css-input css-checkbox css-checkbox-default" htmlFor={item}>
                <input
                  type="checkbox"
                  name={item}
                  id={item}
                  onChange={handleChange}
                  checked={formState.event_round[item]}
                />
                <span></span>
              </label>
              {item === "allow_worse_bids"
                ? isReverseDirection(event)
                  ? allow_bids_to_increase
                  : allow_bids_to_decrease
                : translations.show_ranks_from_last_round_to_participants}
            </div>
          ))}
        </div>
        <br />
        {/* render participants table here */}
        <ParticipantTable
          {...rest}
          key="participant_ids"
          name="participant_ids"
          participants={activeParticipants}
          handleChange={handleChange}
          formValues={formState.participant_ids}
        />
        {!!droppedParticipants.length && (
          <ParticipantTable
            {...rest}
            key="dropped_participant_ids"
            name="dropped_participant_ids"
            isDroppedTable={true}
            participants={droppedParticipants}
            handleChange={handleChange}
            formValues={formState.dropped_participant_ids}
          />
        )}
      </div>
    </>
  );
}

AddEventForm.defaultProps = {
  formState: {},
  setFormState: () => {},
};

AddEventForm.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func,
};
