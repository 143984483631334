import last from "lodash/last";
import { sortedParticipantsByLotAndRank } from "../../eventCommon";

/**
 * @internal
 */
export const injectWinningBidsAndParticipantInToLot = (
  bids,
  event,
  participants,
  awardedParticipantsList,
  unitOfMeasures
) => {
  return function (lot) {
    const uom = unitOfMeasures.find((u) => u.id === lot.unit_of_measure_id);
    const selectedParticipantId = awardedParticipantsList?.[lot.id]?.awarded_participant_id;
    const participant = participants.find((participant) => participant.id === selectedParticipantId);
    const participantBids = bids.filter((bid) =>
      Boolean(!bid.rejected_lot && !bid.decline_bid && bid.lot_id === lot.id && bid.user_id === selectedParticipantId)
    );
    const participantWinningBid = last(sortedParticipantsByLotAndRank(participantBids, event));

    // Return a new lot object with data we need before making params to send to EOD
    return { ...lot, participantWinningBid, participant, uom };
  };
};
