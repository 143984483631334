import React from "react";
import Select2 from "react-select2-wrapper";
import Tooltip from "@/components/common/Tooltip";
import { currenciesForSelect } from "@/components/advanced_lots/common";
import { t } from "@/i18n";

type CurrencyHash = {
  [key: number]: {
    id: number;
    name: string;
    symbol: string;
    description: string;
    created_at: string;
    updated_at: string;
    position: number;
    currency_group_id: number;
    default: boolean;
  };
};

type Layout = "builder";

interface SelectLotCurrencyExchangeRateProps {
  layout?: Layout;
  currenciesHash: CurrencyHash;
  value: number;
  onChange: (id: string | number) => void;
  disabled?: boolean;
  name: string;
  id: string;
}

export default function SelectLotCurrencyExchangeRate({
  layout,
  currenciesHash,
  value,
  onChange,
  disabled,
  name,
  id,
}: SelectLotCurrencyExchangeRateProps) {
  return (
    <Layout layout={layout} htmlFor={id}>
      <Select2
        className="form-control permission-action-select"
        id={id}
        name={name}
        data={currenciesForSelect(currenciesHash)}
        onSelect={(e) => onChange(e.target.value)}
        value={value}
        options={{ minimumResultsForSearch: -1 }}
        disabled={disabled}
      />
    </Layout>
  );
}

interface LayoutProps {
  layout?: Layout;
  htmlFor: string;
  children: React.ReactNode;
}

const Layout = ({ layout, htmlFor, children }: LayoutProps) => {
  if (layout === "builder") {
    return (
      <div className="form-group">
        <div id="builder">
          <div className="col-sm-12">
            <div className="col input">
              <div className="input">
                <Label htmlFor={htmlFor} />
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-md-12">
      <div className="form-group">
        <div className="label-title">
          <Label htmlFor={htmlFor} />
        </div>
        <div className="input">{children}</div>
      </div>
    </div>
  );
};

const Label = ({ htmlFor }: { htmlFor: string }) => {
  return (
    <>
      <Tooltip
        title={t("qq_tooltips.tooltips.host.lot.currency.title", { ns: "tooltips" })}
        body={t("qq_tooltips.tooltips.host.lot.currency.body", { ns: "tooltips" })}
      />
      <label htmlFor={htmlFor}>{t("currency")}</label>
    </>
  );
};
