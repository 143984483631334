import React from "react";
import { bidRoundPrice, bidRoundValue, currencyPosition, showCurrency } from "../../common";
import { placeBid } from "../../../../actions/bidActions";
import { useDispatch } from "react-redux";
import { Confirm } from "../../../common/Confirm";

const JapaneseProxyBid = ({
  translations,
  lot: _lot,
  allUom,
  event,
  bidRound,
  auctionControls,
  currency,
  bid,
  openModal,
  participant,
  currentUser,
  setIsOpen,
  locale,
}) => {
  const {
    lot,
    lot_name,
    uom,
    quantity_uom,
    current_bid_level_total,
    current_bid_level_uom,
    your_bid,
    infos,
    number_format: numberFormat,
    no_bids,
    accept,
    decline,
    value_entered,
    are_you_sure,
  } = translations;

  const {
    lot_position,
    lot_lot_name,
    lot_unit_of_measure: lotUom,
    quantity_uom: quanUom,
    bid_price,
    current_bid_level_uom: currBidUom,
    current_bid_level_total: currBidTotal,
  } = infos;

  const dispatch = useDispatch();
  const { name, quantity, position } = _lot;

  const getTooltip = (title, body) => {
    return (
      <span>
        &nbsp;&nbsp;
        <i
          className="ion-ios-information-outline position-left "
          data-toggle="popover"
          data-placement="up"
          data-title={title}
          data-content={body}
        ></i>
      </span>
    );
  };

  const bidPrice = bid && bid.price ? bid.price : "";
  const price = bidRoundPrice(_lot, event, bidRound, auctionControls);
  const totalPrice = bidRoundValue(_lot, event, bidRound, auctionControls);
  const _allUom = Array.isArray(allUom) ? allUom : [];
  const _uom = _allUom.find((u) => u.id === _lot.unit_of_measure_id);

  const message = (
    <>
      {value_entered}
      <br />
      <strong>{showCurrency(totalPrice, currency, numberFormat.precision, locale, numberFormat)}</strong>
      <br />
      {are_you_sure}
    </>
  );

  const onClickDecline = () => {
    dispatch(
      placeBid(_lot.id, {
        bid: {
          event_id: event.id,
          lot_id: _lot.id,
          user_id: participant.user_id,
          host_id: currentUser.id,
          decline_bid: "true",
          bid_round: bidRound,
        },
        before_auction: "false",
        proxy: 1,
        myTimeRemaining: document.getElementById("time_remaining").innerText,
      })
    ).then((res) => {
      setIsOpen(false);
    });
  };

  const onClickAccept = () => {
    dispatch(
      placeBid(_lot.id, {
        bid: {
          event_id: event.id,
          lot_id: _lot.id,
          user_id: participant.user_id,
          price: price,
          bid_round: bidRound,
          host_id: currentUser.id,
        },
        before_auction: "false",
        proxy: "1",
        myTimeRemaining: document.getElementById("time_remaining").innerText,
      })
    ).then((res) => {
      setIsOpen(false);
    });
  };

  return (
    <>
      <Confirm
        translations={translations}
        message={message}
        htmlId={`japaneseProxyBidAuctionConfirm-${_lot.id}`}
        onOkayButtonClick={onClickAccept}
      />
      <Confirm
        translations={translations}
        message={are_you_sure}
        htmlId={`japaneseProxyBidAuctionCancel-${_lot.id}`}
        onOkayButtonClick={onClickDecline}
      />
      <div className="block-content special-tab-content">
        <div className="row">
          <div className="col-md-12 m-t10">
            <div className="block">
              <table className="table custom-responsive-table table-bordered auction-bid-tab">
                <thead>
                  <tr>
                    <th>
                      {lot} # {getTooltip(lot_position.title, lot_position.body)}
                    </th>
                    <th className="title">
                      {lot_name} {getTooltip(lot_lot_name.title, lot_lot_name.body)}
                    </th>
                    <th>
                      {uom}
                      {getTooltip(lotUom.title, lotUom.body)}
                    </th>
                    <th className="number">
                      {quantity_uom}
                      {getTooltip(quanUom.title, quanUom.body)}
                    </th>
                    <th>
                      {your_bid} {getTooltip(bid_price.title, bid_price.body)}
                    </th>
                    <th className="text_center">
                      {current_bid_level_uom}
                      {getTooltip(currBidUom.title, currBidUom.body)}
                    </th>
                    <th className="text_center">
                      {current_bid_level_total}
                      {getTooltip(currBidTotal.title, currBidTotal.body)}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{position}</td>
                    <td>{name}</td>
                    <td>{_uom && _uom.name ? _uom.name : "-"}</td>
                    <td>{quantity}</td>
                    <td>
                      {bidPrice
                        ? showCurrency(bidPrice, currency, numberFormat.precision, locale, numberFormat)
                        : no_bids}
                    </td>
                    <td>{showCurrency(price, currency, numberFormat.precision, locale, numberFormat)}</td>
                    <td>{showCurrency(totalPrice, currency, 2, locale, numberFormat)}</td>
                    <td>
                      <div className="accept-decline-btn-jpn-proxy">
                        <a
                          className="btn btn-default light-gray-color br-green-color"
                          onClick={() => openModal(`#japaneseProxyBidAuctionConfirm-${_lot.id}`)}
                        >
                          {accept}
                        </a>
                        &nbsp; &nbsp;
                        <a
                          className="btn btn-default light-gray-color br-red-color"
                          onClick={() => openModal(`#japaneseProxyBidAuctionCancel-${_lot.id}`)}
                        >
                          {decline}
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JapaneseProxyBid;
