import React from "react";

/**
 * React functional common modal component can be used to show modal popups
 * OpenModal function can be used from lotReducer to open the modal by just
 * passing the modalId in the openModal function
 * Example: openModal(`#${modalId}`);
 * @param {object} props
 * @param {string} props.modalId
 * @param {object} props.translations
 * @param {string} props.modalClass : Default is 'modal-sm'
 * @param {boolean} props.backDrop : Default is true
 * @param {string | HtmlElement} props.title : Default is blank
 * @param {string | HtmlElement} props.messageBody : Default is blank
 * @param {string} props.okayButtonClass : Default is blank
 * @param {string} props.cancelButtonClass : Default is blank
 * @param {function} props.okButtonClick
 * @param {string} props.cancelButtonText : Default is translation.cancel
 * @param {string} props.okButtonText : Default is translation.ok
 * @param {string} props.showCancelButton : Default is true
 */
const Modal = ({
  modalId,
  translations,
  modalClass = "modal-sm",
  backDrop = true,
  title = "",
  messageBody = "",
  okayButtonClass = "",
  cancelButtonClass = "",
  okButtonClick = (e) => {
    console.warn("okay button click function not defined.");
  },
  cancelButtonText = translations.cancel,
  okButtonText = translations.ok,
  showCancelButton = true,
}) => {
  return (
    <>
      <div
        id={modalId}
        className="modal fade modal-dialog-popout top-position"
        role="dialog"
        style={{ display: "none" }}
        data-backdrop={backDrop}
      >
        <div className={`modal-dialog modal-dialog-popout custom-modal ${modalClass} modal-draggable-content`}>
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              {title}
            </div>
            <div className="modal-body">
              <p>{messageBody}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={`btn btn-primary ok-btn ${okayButtonClass}`}
                onClick={okButtonClick}
                data-dismiss="modal"
              >
                {okButtonText}
              </button>
              {showCancelButton ? (
                <button type="button" className={`btn btn-default ${cancelButtonClass}`} data-dismiss="modal">
                  {cancelButtonText}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
