import type { EntityState } from "@reduxjs/toolkit";
import { makeEnum } from "@/slices/util/makeEnum";
import type { OneOf, Override, Satisfies } from "@/types/util";
import { fieldAdapter, type Field } from "./fields";
import type { TemplateIdentifier } from "../lots/templates";

export const Operation = makeEnum("And", "Or");

export type Operation = keyof typeof Operation;

export interface Branch {
  operation: Operation;
  child_nodes: [Node, Node];
}

export type NormalizedBranch = Override<Branch, { child_nodes: [string, string] }>;

export const Comparison = makeEnum("Eq", "Gt", "Gte", "Lt", "Lte");

export type Comparison = keyof typeof Comparison;

export interface TextParameter {
  count: number;
  parameters: Array<string>;
}

export interface NumberParameter {
  target_figure: number;
}

export interface CheckboxParameter {
  target_selection: boolean;
}

export interface DateParameter {
  target_date: string;
}

export type LeafParameter = TextParameter | NumberParameter | CheckboxParameter | DateParameter;

export const checkLeafParameter = {
  isText: (parameter): parameter is TextParameter => parameter.count !== undefined,
  isNumber: (parameter): parameter is NumberParameter => parameter.target_figure !== undefined,
  isCheckbox: (parameter): parameter is CheckboxParameter => parameter.target_selection !== undefined,
  isDate: (parameter): parameter is DateParameter => parameter.target_date !== undefined,
} satisfies Record<string, (parameter: OneOf<LeafParameter>) => parameter is LeafParameter>;

export const leafParameterType = {
  isText: "text",
  isNumber: "numeric",
  isCheckbox: "checkbox",
  isDate: "date",
} as const satisfies Record<keyof typeof checkLeafParameter, string>;

export const getLeafParameterType = (parameter: LeafParameter) => {
  const type = (Object.keys(checkLeafParameter) as Array<keyof typeof checkLeafParameter>).find((key) =>
    checkLeafParameter[key](parameter)
  );
  if (!type) {
    throw new Error("Invalid parameter type");
  }
  return leafParameterType[type];
};

export interface Leaf<Parameter extends LeafParameter = LeafParameter> {
  comparison: Comparison;
  field_name: string;
  leaf_parameter: Parameter;
  meta?: Record<string, string>;
}

export const checkLeaf = {
  isText: (leaf): leaf is Leaf<TextParameter> => checkLeafParameter.isText(leaf.leaf_parameter),
  isNumber: (leaf): leaf is Leaf<NumberParameter> => checkLeafParameter.isNumber(leaf.leaf_parameter),
  isCheckbox: (leaf): leaf is Leaf<CheckboxParameter> => checkLeafParameter.isCheckbox(leaf.leaf_parameter),
  isDate: (leaf): leaf is Leaf<DateParameter> => checkLeafParameter.isDate(leaf.leaf_parameter),
} satisfies Record<string, (leaf: Leaf) => leaf is Leaf>;

export type NodeState = Branch | Leaf;

export type NormalizedNodeState = NormalizedBranch | Leaf;

function isBranch(state: OneOf<NormalizedNodeState>): state is NormalizedBranch;
function isBranch(state: OneOf<NodeState>): state is Branch;
function isBranch(state: OneOf<NodeState> | OneOf<NormalizedNodeState>): state is Branch | NormalizedBranch {
  return !!state.operation;
}

export const checkNodeState = {
  isBranch,
  isLeaf: (state: OneOf<NodeState | NormalizedNodeState>): state is Leaf => !!state.comparison,
} satisfies Record<string, (state: NormalizedNodeState) => state is NormalizedNodeState>;

function isBranchNode(node: OneOf<NormalizedNode>): node is NormalizedNode<NormalizedBranch>;
function isBranchNode(node: OneOf<Node>): node is Node<Branch>;
function isBranchNode(
  node: OneOf<Node> | OneOf<NormalizedNode>
): node is Node<Branch> | NormalizedNode<NormalizedBranch> {
  return isBranch(node.state as never);
}

function isLeafNode(node: Node): node is Node<Leaf>;
function isLeafNode(node: NormalizedNode): node is NormalizedNode<Leaf>;
function isLeafNode(node: Node | NormalizedNode): node is Node<Leaf> | NormalizedNode<Leaf> {
  return checkNodeState.isLeaf(node.state);
}

export const checkNode = {
  isLeaf: Object.assign(isLeafNode, {
    withText: (node): node is Node<Leaf<TextParameter>> => checkNode.isLeaf(node) && checkLeaf.isText(node.state),
    withNumber: (node): node is Node<Leaf<NumberParameter>> => checkNode.isLeaf(node) && checkLeaf.isNumber(node.state),
    withCheckbox: (node): node is Node<Leaf<CheckboxParameter>> =>
      checkNode.isLeaf(node) && checkLeaf.isCheckbox(node.state),
    withDate: (node): node is Node<Leaf<DateParameter>> => checkNode.isLeaf(node) && checkLeaf.isDate(node.state),
  } satisfies Record<string, (node: NormalizedNode) => node is NormalizedNode>),
  isBranch: isBranchNode,
} satisfies Record<string, (node: NormalizedNode) => node is NormalizedNode>;

export interface Node<State extends NodeState = NodeState> {
  uuid: string;
  negated: boolean;
  state: State;
}

export type NormalizedNode<State extends NormalizedNodeState = NormalizedNodeState> = Override<
  Node<never>,
  { state: State; parentUuid?: string }
>;

export type NormalizedTree = Partial<Record<string, NormalizedNode>>;

export type MultiKind = "any" | "all" | "none";

export type FieldTypeToLeafParameter = Satisfies<
  {
    pick_one_from_list: TextParameter;
    multiple_choice: TextParameter;
    numeric: NumberParameter;
    checkbox: CheckboxParameter;
    date: DateParameter;
  },
  Record<Field["type"], LeafParameter>
>;

export const emptyParameters: FieldTypeToLeafParameter = {
  pick_one_from_list: { count: 1, parameters: [] },
  multiple_choice: { count: 1, parameters: [] },
  numeric: { target_figure: 0 },
  checkbox: { target_selection: false },
  date: { target_date: "" },
};

export type RawGetRulesAndFieldsResponse = {
  available_fields: Field[];
} & (
  | {
      existing_rule: Node;
      rule_id: number;
    }
  | { existing_rule: null; rule_id: null }
);

export type GetRulesAndFieldsResponse = {
  available_fields: EntityState<Field, Field["id"]>;
} & (
  | {
      existing_rule: Node;
      rule_id: number;
    }
  | { existing_rule: null; rule_id: null }
);

export interface UpdateRulesRequest extends TemplateIdentifier {
  ruleId?: number;
  rules?: Node;
}

export interface RawUpdateRulesRequest {
  workflow_selection_rule_attributes: {
    id?: number;
    conditions?: Node;
  };
  id: number;
  company_id: number;
}

const nonIntermediate = (field: Field) => {
  switch (field.type) {
    case "multiple_choice":
    case "pick_one_from_list":
      return !!field.options;
    default:
      return true;
  }
};

export const transformGetRulesAndFieldsResponse = (
  response: RawGetRulesAndFieldsResponse
): GetRulesAndFieldsResponse => ({
  ...(response.existing_rule?.uuid
    ? { existing_rule: response.existing_rule, rule_id: response.rule_id }
    : { existing_rule: null, rule_id: null }),
  available_fields: fieldAdapter.setAll(
    fieldAdapter.getInitialState(),
    response.available_fields.filter(nonIntermediate)
  ),
});

export const makeUpdateRulesRequest = ({
  companyId,
  templateId,
  ruleId,
  rules,
}: UpdateRulesRequest): RawUpdateRulesRequest => ({
  workflow_selection_rule_attributes: {
    id: ruleId,
    conditions: rules,
  },
  id: templateId,
  company_id: companyId,
});
