/**
 * Map of all the brand for Market Dojo and there display names.
 */
export const brandMap = {
  primary: "Primary",
  "outlined-primary": "Outlined Primary",
  null: "Null",
  "md-primary": "Market Dojo Primary",
  "md-outline-primary": "Market Dojo Outlined Primary",
};

/**
 * A list of all the brand name for Market Dojo
 *
 * This is the list that will be used in code to identify each of the brands.
 *
 * @type {Array<keyof brandMap>}
 */
export const brands = Object.keys(brandMap);
