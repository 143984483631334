import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { finishEditing } from "../../../actions/eventActions";
import { showMessageModal } from "../../../actions/messageActions";
import { reqLotEdit } from "../../../actions/lotActions";

const EventNavigation = (props) => {
  const eventNavigationBtn = useRef(null);
  const dispatch = useDispatch();

  let { event, role, translations, currentState } = props;
  const buttonDefaultValue = translations.save_changes;

  const [isSaveButtonDisabled, toggleSaveButtonState] = useState(false);
  const [isEditButtonDisabled, toggleEditButtonState] = useState(false);
  const [showLockIcon, toggleShowLockIcon] = useState(true);
  const [buttonValue, setButtonValue] = useState(buttonDefaultValue);

  const onSaveButtonClick = async () => {
    toggleEditButtonState(false);
    const result = await dispatch(finishEditing(event.id));
    if (result) {
      const isCurrentUserHost = role === "Host";
      const replyUserId = isCurrentUserHost ? null : event.user_id;
      dispatch(showMessageModal(true, null, replyUserId, "ShowUpdateMessageSendMessageModal"));
      disableButton(toggleSaveButtonState, toggleShowLockIcon, setButtonValue, translations.saving);
    } else {
      enableButton(toggleSaveButtonState, toggleShowLockIcon, setButtonValue, translations.save_changes);
    }
  };

  const onEditButtonClick = () => {
    toggleEditButtonState(true);
    dispatch(reqLotEdit(event.id));
    enableButton(toggleSaveButtonState, toggleShowLockIcon, setButtonValue, translations.save_changes);
  };

  const getButton = () => {
    if (currentState === "edit") {
      return (
        <>
          <span className="edit-mode-text">{translations.edit_mode}</span>

          <button
            className="btn btn-primary"
            id={"saveChanges"}
            onClick={() => {
              onSaveButtonClick(props);
            }}
            disabled={isSaveButtonDisabled}
            ref={eventNavigationBtn}
            data-label={"send_new_message_btn"}
          >
            {buttonValue}
            {showLockIcon ? <i className="fa fa-lock m-l5"></i> : null}
          </button>
        </>
      );
    } else if (!["completed", "closed", "running", "pause"].includes(currentState)) {
      return (
        <>
          {event.event_category_auction && (
            <ShowDownloadNowBtn
              translations={translations}
              event={event}
              classNames={"blue-bordered-btn btn btn-default blue-color auto-width"}
            />
          )}
          <button id={"edit"} className="btn btn-primary" onClick={onEditButtonClick} disabled={isEditButtonDisabled}>
            {isEditButtonDisabled ? (
              <>
                {translations.edit}
                <i className="fa fa-spinner m-l5"></i>
              </>
            ) : (
              <>
                {translations.edit}
                <i className="fa fa-unlock m-l5"></i>
              </>
            )}
          </button>
        </>
      );
    } else if (event.event_category_auction && ["running", "pause"].includes(currentState)) {
      return <ShowDownloadNowBtn translations={translations} event={event} classNames={"btn btn-primary"} />;
    }
  };

  return (
    <div className="container-fluid">
      <div className="further-btn-hgt">
        <div className="step-block">
          <div className="container">
            <div className="row">
              <div className="col-md-12 edit-mode-footer text-right">
                <div className="edit-mode-event-footer">
                  <div className="pull-right">{getButton()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const disableButton = (toggleButtonState, toggleShowLockIcon, setButtonValue, value) => {
  toggleButtonState(true);
  toggleShowLockIcon(false);
  setButtonValue(value);
};

const enableButton = (toggleButtonState, toggleShowLockIcon, setButtonValue, value) => {
  toggleButtonState(false);
  toggleShowLockIcon(true);
  setButtonValue(value);
};

const ShowDownloadNowBtn = (props) => {
  const { translations, event, classNames } = props;
  return (
    <a className={classNames + " p-t10 position-right"} href={`/events/${event.id}.xlsx?report_type=RFQ`}>
      {translations.download_now}
      <i className="fa fa-download m-l5"></i>
    </a>
  );
};

export default EventNavigation;
