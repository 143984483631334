import * as apiCall from '../api';
import actionTypes from '../actionsTypes';
import config from '../config';

const getMaterialDataSuccess = (data) => ({
  type: actionTypes.get_material_data,
  payload: data,
});

const getMaterialDataFail = (error) => ({
  type: error.response.status,
  payload: error,
});

const getMaterialData = () => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/materialrequest/categories/?subdomain=${config.SUBDOMAIN}`)
    .then((response) => {
      dispatch(getMaterialDataSuccess(response.data));
    }).catch((error) => error.response)
);

export default getMaterialData;
