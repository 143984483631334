import React from "react";
import { Button } from "@/cl/button";

import { useAttribute, useListAttribute } from "@adeattwood/react-form";
import { t } from "@/i18n";

/**
 * Helper function to create a new custom field object with all the default
 * values
 */
function newCustomField() {
  return {
    id: -1,
    column_type_cd: 0,
    name: "",
    custom_column_item_types: [],
  };
}

const isInViewport = (element) => {
  if (!element) return false;

  const elementRect = element.getBoundingClientRect();
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

  return elementRect.bottom > 0 && elementRect.top - viewHeight <= 0;
};

const scrollToTheBottomOfThePage = () => {
  const reduceMotion = window.matchMedia("(prefers-reduced-motion: reduce)");
  const behavior = reduceMotion.matches ? "auto" : "smooth";
  window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior });
};

const useOnClick = (add) => () => {
  add();

  // Scroll to the bottom of the page on the next tick. This is to give the
  // page time to register there is a new row in the table, before
  // calculating the scrollHeight. This will only scroll to the bottom if the
  // element is not already in the viewport to reduce the jumping around.
  setTimeout(() => {
    if (!isInViewport(document.querySelector(".new-custom-column"))) {
      scrollToTheBottomOfThePage();
    }
  });
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof AddCustomFieldButtonPropTypes>>}
 */
const AddCustomFieldButton = () => {
  const { value, add } = useListAttribute("customFields", newCustomField);
  const searchAttribute = useAttribute("search");
  const onClick = useOnClick(add);
  // Disable the button if there is already a new custom field in the list.
  // This is so the user can only add one at a time.
  const isDisabled = searchAttribute.value?.length > 0 || value.some((customField) => customField.id === -1);

  return (
    <Button type="button" brand="md-primary" onClick={onClick} disabled={isDisabled}>
      {t("centralised_custom_fields.add_new_custom_field")}
    </Button>
  );
};

export default AddCustomFieldButton;
