import React from "react";
import head from "lodash/head";
import last from "lodash/last";
import uniqBy from "lodash/uniqBy";
import compact from "lodash/compact";
import has from "lodash/has";
import max from "lodash/max";
import min from "lodash/min";
import uniq from "lodash/uniq";
import flattenDeep from "lodash/flattenDeep";

import {
  activeEventParticipants,
  lotCurrentValue,
  currentValue,
  showCurrency,
  findLotTotalLic,
  isReverseDirection,
  findUom,
  convertQuantity,
  host,
  participant,
  calculation,
  differenceOfferedForHostEnteredLic,
  isPrice,
  isRanked,
  componentType,
  formatDatetime,
  isDecimal,
  qualificationValue,
  showLocalisedStrippedDecimal,
  usedValue,
  showPicklistValue,
  isValidCell,
  eventParticipantStatus,
  isVisibleRank,
  findUser,
  showValue,
  hasValue,
  requiresComplexDetail,
  bidRoundPrice,
  roundBidControls,
  isLotTotalPresent,
  isANumber,
} from "./common";
import moment from "moment-timezone";
import {
  inprogress,
  completed,
  closed,
  aroundRfq,
  isWeightedRfqOrAuction,
  deadlinesInThePast,
  postRfqPreAuction,
} from "./eventCommon";
import CommonModal from "../../hoc/modalHOC/CommonModal";
import SingleLotMatrix from "./lots/host_rfq_screen/modal/SingleLotMatrix";
import features from "@/utils/features";

export const latestBidBeforeAuctionForLot = (lot, validBids, ep, event) => {
  const epBids = eventParticipantBids(ep, validBids) ?? [];

  const measureFrom = event.start_at ? convertTimeIntoUtc(event.start_at) : convertTimeIntoUtc();

  return epBids
    .filter((bid) => {
      return bid.lot_id === lot.id && convertTimeIntoUtc(new Date(bid.created_at).toISOString()) <= measureFrom;
    })
    ?.reverse()?.[0];
};

export const latestBidBeforeAuctionForLots = (validBids, ep, event) => {
  const epBids = eventParticipantBids(ep, validBids);

  const measureFrom = event.start_at ? convertTimeIntoUtc(event.start_at) : convertTimeIntoUtc();
  return epBids.filter((bid) => {
    return convertTimeIntoUtc(new Date(bid.created_at).toISOString()) <= measureFrom;
  });
};

export const bidAtRFQ = (event) => {
  const isEventCategoryAuctin = !event.event_category_auction;
  const isEventcategoryRfq = event.event_category_rfq;
  return isEventCategoryAuctin && isEventcategoryRfq;
};

export const latestBidDuringAuctionForLot = (bids, eps, event, weightedRfqOrAuction, auctionTab = false) => {
  const activeBids = validBids(eps, bids, event.event_type === "Japanese");
  if (event.event_type !== "Japanese") {
    const userIds = eps.map((ep) => ep.user_id);
    const rankField = weightedRfqOrAuction ? "overall_rank" : "rank";
    const bidAtRfqState = bidAtRFQ(event);
    const bestBidFilter = (bid) => {
      return (
        userIds.includes(bid.user_id) &&
        !!bid.bid_at_rfq_state === !!bidAtRfqState &&
        bid.rejected_lot === null &&
        bid.current_state === "submitted"
      );
    };

    let bestBids = activeBids.filter(bestBidFilter);
    if (auctionTab) return bestBids;
    const minRank = bestBids.map((bid) => bid[rankField]).sort();
    bestBids.sort(function (bid1, bid2) {
      return bid1[rankField] - bid2[rankField];
    });
    return bestBids.filter((bid) => bid[rankField] === minRank[0]);
  } else if (event.bid_deadline || event.event_type === "Japanese") {
    const latBids = activeBids.filter(
      (bid) => bid.current && convertTimeIntoUtc(bid.created_at) >= convertTimeIntoUtc(event.start_at)
    );
    return latBids;
  }
};

export const eventParticipantBids = (ep, bids) =>
  bids?.filter((bid) => bid.user_id === ep.user_id && bid.current_state === "submitted");

export const preferredBlicValue = (
  blic,
  lic,
  blicCurrency,
  licCurrency,
  unitOfMeasures,
  unitSet,
  bid,
  blics,
  bids,
  locale,
  numberFormat,
  preferred = "participant",
  precision = 2
) => {
  let result = "-";
  if (blic) {
    const currency = blicCurrency || preferredCurrency(blicCurrency, licCurrency, preferred);
    if (preferred == "host") {
      const val = hostPreferredValue(blic, lic);
      result = showCurrency(showValue(val), currency, precision, locale, numberFormat);
    } else {
      const val = participantPreferredValue(blic, lic, unitOfMeasures, unitSet, bid, blics, bids);
      result = showCurrency(val, currency, precision, locale, numberFormat);
    }
  }

  return result;
};

// bCurrency can be bid's currency or BLIC currency as well as lCurrency
// can be lot's or LIC currency When fetching bid's currency then pass lot
// currency and while fetching BLIC currency then pass LIC currency
export const preferredCurrency = (bCurrency, lCurrency, userRole = "participant") => {
  if (userRole === "participant") {
    return bCurrency || lCurrency;
  } else {
    return lCurrency;
  }
};

export const fetchAssociatedBlicOrBidCurrency = (currenciesHash, bid, blic) => {
  let er_id;
  er_id = blic && blic.exchange_rate_id ? blic.exchange_rate_id : bid.exchange_rate_id;
  if (er_id) {
    return currenciesHash[er_id];
  } else {
    return currenciesHash["default"];
  }
};

export const findBid = (bids, bidId) => bids.find((bid) => bid.id === bidId);

export const bestBidByOverallScore = (event, eventParticipants, bids) => {
  let activeBids = validBidsBeforeAuction(event, eventParticipants, bids);
  activeBids.sort(function (bid1, bid2) {
    return bid1.weighted_score - bid2.weighted_score;
  });

  activeBids.reverse();
  return activeBids;
};

export const bestBidBeforeAuctionStarted = (bestBids) => {
  return head(bestBids);
};

export const bestBidsBeforeAuctionStarted = (event, bids, participatingParticipants, weightedRfqOrAuction) => {
  let result = [];
  if (bids.length > 0) {
    let activeBids = validBidsBeforeAuction(event, participatingParticipants, bids).filter((bid) => Boolean(bid.price));
    const priceField = weightedRfqOrAuction ? "weighted_score" : "host_price";
    activeBids.sort(function (bid1, bid2) {
      return bid1[priceField] - bid2[priceField];
    });

    if (orderDirection(event, weightedRfqOrAuction) === "DESC") {
      activeBids.reverse();
    }
    if (activeBids.length > 0) {
      const minPrice = head(activeBids)[priceField];

      result = uniqBy(
        activeBids.filter((bid) => bid[priceField] === minPrice),
        "user_id"
      );
      if (event.tied_bids === "separated_by_timestamp" && result.length > 1) {
        result.sort(function (bid1, bid2) {
          return bid1.id - bid2.id;
        });
      }
    }
  }
  return result;
};

export const bestBidPriceBeforeAuctionStarted = (bestBid, isMultiCurrencyEvent, preferred = "participant") => {
  const result = preferred === "host" ? hostPreferredPrice(bestBid) : bestBid.price;
  return result;
};

export const bestBidValueBeforeAuctionStarted = (bestBid, isMultiCurrencyEvent, lot, preferred = "participant") => {
  let result;
  if (bestBid) {
    const price = bestBidPriceBeforeAuctionStarted(bestBid, isMultiCurrencyEvent, preferred);
    result = hasValue(price) ? price * lot.quantity : null;
  }
  return result;
};

export const convertTimeIntoUtc = (value = null) => {
  let result = value ? moment.utc(value).format() : moment.utc().format();
  return moment(result);
};

export const orderDirection = (event, weightedRfqOrAuction) => {
  if (event.event_direction === "Forward" || (event.event_direction === "Reverse" && weightedRfqOrAuction)) {
    return "DESC";
  } else if (event.event_direction === "Reverse") {
    return "ASC";
  }
};

export const previousBid = (currentBid, bids, lot, userId) => {
  return last(
    bids.filter(
      (bid) =>
        bid.id !== currentBid?.id &&
        !bid.rejected_lot &&
        !bids.decline_bid &&
        bid.user_id === userId &&
        bid.lot_id === lot.id &&
        bid.current_state === "submitted"
    )
  );
};
export const bestBidName = (bestBids, users, event, tiedTranslation) => {
  if (bestBids?.length < 1) {
    return "-";
  } else if (event?.tied_bids !== "separated_by_timestamp" && bestBids?.length > 1) {
    return tiedTranslation;
  } else {
    const bid = bestBids?.[0];
    const user = users?.find((user) => user.id === bid?.user_id);
    return companyNameOrEmail(user);
  }
};
export const bidUser = (bid, users = []) => users.find((user) => user.id === bid?.user_id);

export const companyNameOrEmail = (user = {}) => {
  let result;
  const userCompanyName = user.user_company_name;
  if (userCompanyName && userCompanyName !== "-") {
    result = userCompanyName;
  } else {
    result = user.email;
  }
  return result;
};

export const companyNameAndEmail = (user = {}) => {
  if (user.user_company_name && user.email) return `${user.user_company_name} (${user.email})`;
  return user.email;
};

export const leadParticipantHtml = (
  bestBid,
  bestBidNames,
  event,
  tiedTranslation,
  beforeAuction = false,
  isReturnLink = false
) => {
  let result;
  if (!bestBid) {
    result = "-";
  } else if (bestBidNames === tiedTranslation) {
    result = bestBidNames;
  } else {
    // event_event_participant_path(event, event.find_participant(best_bid.user_id), before_auction: before_auction)
    result = !isReturnLink ? <a href="#">{bestBidNames} </a> : bestBidNames;
  }
  return result;
};

export const activeBidsBeforeAuctionStarted = (event, bids, eventParticipants) => {
  const eps = activeEventParticipants(eventParticipants);
  return validBidsBeforeAuction(event, eps, bids).length;
};
export const validBidsBeforeAuction = (event, activeEps, bids) => {
  const activeBids = validBids(activeEps, bids);
  const measureFrom = event.start_at ? convertTimeIntoUtc(event.start_at) : convertTimeIntoUtc();
  return activeBids.filter((bid) => {
    return convertTimeIntoUtc(new Date(bid.created_at).toISOString()) <= measureFrom;
  });
};

export const validBidsDuringAuction = (event, activeEps, bids) => {
  let activeBids = validBids(activeEps, bids);
  if (
    !event.start_at ||
    (convertTimeIntoUtc() < convertTimeIntoUtc(event.start_at) && event.event_type !== "Japanese")
  ) {
    const startAt = convertTimeIntoUtc(event.start_at) || convertTimeIntoUtc();
    activeBids = activeBids.filter((bid) => {
      return bid.current && convertTimeIntoUtc(bids.created_at) <= startAt && !bid.bid_at_rfq_state;
    });
  } else if (event.bid_deadline || event.event_type === "Japanese") {
    activeBids = activeBids.filter(
      (bid) => bid.current && convertTimeIntoUtc(bid.created_at) >= convertTimeIntoUtc(event.start_at)
    );
  }
  return activeBids;
};

export const validBids = (activeEps, bids, isJapanese = false) => {
  const userIds = activeEps.map((ep) => ep.user_id);

  const activeBids = bids.filter((bid) => {
    return (
      userIds.includes(bid.user_id) &&
      !bid.rejected_lot &&
      (!bid.decline_bid || isJapanese) &&
      bid.current_state === "submitted"
    );
  });
  return activeBids;
};

// # Savings/profit offered before auction started.
export const differenceOfferedBeforeAuctionStarted = (
  event,
  lot,
  bids,
  eventParticipants,
  bestBid,
  isMultiCurrencyEvent
) => {
  const bidsReceived = activeBidsBeforeAuctionStarted(event, bids, eventParticipants);
  const currentVal = lotCurrentValue(lot);
  const bestBidValue = bestBidValueBeforeAuctionStarted(bestBid, isMultiCurrencyEvent, lot, "host");
  let result;
  if (currentVal == undefined || bidsReceived.length === 0) {
    result = "-";
  } else if (event.event_direction == "Reverse") {
    result = currentVal - bestBidValue;
    if (lot.current_price === 0) {
      if (result.round(2) == 0.0) {
        result = Math.abs(result.toFixed(2));
      }
    }
  } else {
    result = bestBidValue - currentVal;
  }
  return result;
};

export const differenceOfferedWithCurrency = (val, currency, locale, numberFormat, precision = 2) => {
  let result = "";
  // Need to check it because if diffValue is negative then '-' sign will
  // prepend to the currency
  if (Math.sign(val) === -1) {
    val = Math.abs(val);
    result = "-";
  }
  val = isNaN(val) ? "-" : val;
  const diffValueWithCurrency = showCurrency(val, currency, precision, locale, numberFormat);
  return result.concat(diffValueWithCurrency);
};
// # Savings/profit offered in percentage before auction started
export const differenceOfferedPercentageBeforeAuctionStarted = (
  event,
  lot,
  eventParticipants,
  bids,
  bestBid,
  isMultiCurrencyEvent
) => {
  const bidsReceived = activeBidsBeforeAuctionStarted(event, bids, eventParticipants);
  const differenceOffered = differenceOfferedBeforeAuctionStarted(
    event,
    lot,
    bids,
    eventParticipants,
    bestBid,
    isMultiCurrencyEvent
  );
  const currentVal = lotCurrentValue(lot);
  let result;
  if ((currentVal && differenceOffered == "-") || (bidsReceived === 0 && differenceOffered == "-")) {
    result = "-%";
  } else if ((currentVal && differenceOffered == "n/a") || (bidsReceived === 0 && differenceOffered == "n/a")) {
    result = "-%";
  } else if (differenceOffered == "n/a" || differenceOffered == "-") {
    result = "-%";
  } else {
    if (currentVal && currentVal != 0) {
      result = ((differenceOffered / currentVal) * 100).toFixed(2) + "%";
    } else {
      result = "-%";
    }
  }
  return result;
};

export const formatDifferenceOfferedPercentage = (value, naTranslation, locale) => {
  let res = {};
  if (["n/a", naTranslation].includes(value)) {
    res["value"] = naTranslation;
    res["class"] = "green";
  } else {
    const val = parseFloat(value);
    res["value"] = isNaN(val) ? "-%" : `${val.toLocaleString(locale, { minimumFractionDigits: 2 })}%`;
    res["class"] = isNaN(val) || val >= 0 ? "green" : "red";
  }
  return res;
};

//It can be use for bid or BLIC
export const hostPreferredPrice = (obj) => {
  return obj.host_price ?? obj.price;
};

//It can be use for bid or BLIC
export const hostPreferredValue = (bidOrBlic, lotOrLic, lotLics = [], checkQuantityFromTotal = false) => {
  const price = hostPreferredPrice(bidOrBlic);
  let quantity = lotOrLic.quantity;
  if (checkQuantityFromTotal && bidOrBlic && bidOrBlic.line_item_component_id) {
    let totalLic = lotLics.find(({ id }) => id === bidOrBlic.line_item_component_id);
    if (totalLic && totalLic.quantity) {
      quantity = totalLic.quantity;
    }
  }
  return isANumber(price) ? price * quantity : price;
};

export const participantPreferredValue = (blic, lic = {}, unitOfMeasures, unitSet, bid, blics, bids) => {
  const price = blic?.price ?? "";
  const quantity = preferredQuantity(lic, unitOfMeasures, unitSet, blic, bid, blics, bids);
  return !price ? price : price * quantity;
};

export const participantPreferredValueForBid = (bid, lot) => {
  return bid.price * lot.quantity;
};

export const preferredQuantity = (lic, unitOfMeasures, unitSet = {}, blic, bid, blics, bids) => {
  let result = lic.quantity;

  if (!lic.is_formula_lic && lic.is_uom_set) {
    const uomId = persistedUomId(blic, lic, bid, blics, bids, unitSet, unitOfMeasures);
    const uom = findUom(lic.unit_of_measure_id, unitOfMeasures);
    result = uomId === lic.unit_of_measure_id ? result : convertQuantity(uom, uomId, lic.quantity, unitOfMeasures);
  }

  // return 1 if quantity is undefined or null
  return result ?? 1;
};
export const persistedUomId = (blic, lic, bid, blics, bids, unitSet, unitOfMeasures) => {
  let result;
  if (blic && blic.unit_of_measure_id) {
    result = blic.unit_of_measure_id;
  } else if (blic && bidBLics(blic, bid, blics, bids).length > 0) {
    // Fetch the recent BLIC for this LIC
    result = last(bidBLics(blic, bid, blics, bids)).unit_of_measure_id;
  } else if (blics.length && bid && relatedBidLineItemComponents(bid.id, blics).length > 0) {
    // Fetch recent BLIC for this participant in which he/she used this host provided UoMs
    const relatedBlics = relatedBidLineItemComponents(bid.id, blics);
    const unitSetUom = compact(unitOfMeasures.filter((uom) => uom.unit_set_id === unitSet.id));
    const finalBlic = last(relatedBlics.filter((b) => unitSetUom.map((uom) => uom.id).includes(b.unit_of_measure_id)));
    result = finalBlic && finalBlic.unit_of_measure_id ? finalBlic.unit_of_measure_id : lic.unit_of_measure_id;
  } else {
    result = lic.unit_of_measure_id;
  }
  return result;
};

export const relatedBidLineItemComponents = (bidId, blics) => {
  return blics?.filter((blic) => blic.bid_id === bidId);
};

export const bidBLics = (blic, bid, blics, bids) => {
  const userIds = bids.map((b) => b.user_id);
  return blics.map(
    (bidLineItemComponent) =>
      bidLineItemComponent.line_item_component_id === blic.line_item_component_id &&
      userIds.includes(bid.user_id) &&
      bidLineItemComponent.id !== blic.id
  );
};

export const bidBidLineItemComponents = (bidId, blics) => blics.filter((blic) => blic.bid_id === bidId);

export const findBlicForLic = (licId, blics = []) =>
  blics.filter((blic) => blic.line_item_component_id === licId).reverse()[0];

// For finding participant's BLIC need to use bid id instead to userId as
// there is no direct connection between BLIC and user but bid belongs to single
// user and blic belong to single bid.
export const findParticipantBlicForLic = (licId, blics = [], bidId) =>
  blics.filter((blic) => blic.line_item_component_id === licId && blic.bid_id === bidId).reverse()[0];
export const bestLiTotalBlicsBeforeAuctionByLineItemId = (
  event,
  participatingParticipants,
  bids,
  lots,
  lineItemComponents,
  bidLineItemComponents,
  auctionTab = false,
  lotLis = []
) => {
  const currentBidIds = auctionTab
    ? validBidsDuringAuction(event, participatingParticipants, bids).map((bid) => bid.id)
    : validBidsBeforeAuction(event, participatingParticipants, bids).map((bid) => bid.id);
  const totalLics = flattenDeep(
    lots.map((lot) => {
      if (isLotTotalPresent(lot)) return findLotTotalLic(lineItemComponents, lot.lot_total_lic_id);
      return lotLis.map((li) => findLotTotalLic(lineItemComponents, li.line_item_total_lic_id));
    })
  );

  const totalLotComponentIds = totalLics.map((lic) => lic?.lot_component_id);
  const lineItemLicIds = lineItemComponents
    .filter((lic) => totalLotComponentIds.includes(lic.lot_component_id))
    .map((lic) => lic.id);
  let finalBlics = {};

  bidLineItemComponents.forEach((blic) => {
    if (currentBidIds.includes(blic.bid_id) && lineItemLicIds.includes(blic.line_item_component_id)) {
      if (!has(finalBlics, blic.line_item_component_id)) {
        finalBlics[blic.line_item_component_id] = [];
      }

      finalBlics[blic.line_item_component_id].push(blic);
    }
  });
  return finalBlics;
};
export const bestBlic = (event, blics, weightedRfqOrAuction, lic) => {
  let res = [];
  if (blics.length > 0 && lic.is_ranked) {
    if (weightedRfqOrAuction) {
      const bestOverallScore = max(compact(blics.map((blic) => blic.weighted_score)));
      res = blics.filter((blic) => blic.weighted_score === bestOverallScore);
    } else {
      const hostPrice = blics
        .filter((blic) => (!["", undefined, null].includes(blic.host_price) ? true : false))
        .map((blic) => blic.host_price);
      const bestValue = isReverseDirection(event) ? min(hostPrice) : max(hostPrice);
      res = blics.filter((blic) => blic.host_price === bestValue);
    }
    if (event.tied_bids == "separated_by_timestamp" && res.length > 1) {
      res.sort(function (bid1, bid2) {
        return bid1.id - bid2.id;
      });
    }
  }
  return res;
};

export const bestBlicName = (bestBid, lic, bestBlics, participants, bids, bestBidBlics, translations) => {
  if (bestBlics.length === 0) {
    const b = findBlicForLic(lic.id, bestBidBlics);
    return bestBid && bestBid.details_added && b ? translations.not_ranked : "-";
  } else {
    const bidIds = uniq(bestBlics.map((blic) => blic.bid_id));
    const filtertedBids = bids.filter((bid) => bidIds.includes(bid.id));
    const userIds = filtertedBids.map((bid) => bid.user_id);
    const users = compact(uniq(userIds.map((userId) => participants.find((participant) => participant.id === userId))));
    let result = [];
    users.forEach((user) => result.push(companyNameOrEmail(user)));
    result = compact(result).join(",");
    return result;
  }
};

export const blicCurrentValue = (blic, lic, preferred = "host") => {
  let result;
  if (preferred === "host") {
    result = currentValue(lic);
  } else {
    // CurrencyConverter.new(line_item_component, self, line_item_component.current_value).convert
  }
  return result;
};

export const differenceOfferedPercentageForBlic = (event, blic, lic, unitOfMeasures, bid, blics, bids) => {
  const val = differenceOfferedForBlic(event, blic, lic, unitOfMeasures, bid, blics, bids);

  let result = "-";
  const currentVal = blicCurrentValue(blic, lic);
  if (val !== "-" && currentVal && currentVal !== 0) {
    result = Number(((val / currentVal) * 100).toFixed(2));
  }

  return result;
};

export const differenceOfferedForBlic = (
  event,
  blic,
  lic,
  unitOfMeasures,
  unitSet,
  bid,
  blics,
  bids,
  preferred = "host"
) => {
  const val = blicValue(blic, lic, unitOfMeasures, unitSet, bid, blics, bids, preferred);
  const currentVal = blicCurrentValue(blic, lic, preferred);
  let result;
  if (!currentVal || !val) {
    result = "-";
  } else if (isReverseDirection(event)) {
    result = currentVal - val;
  } else {
    result = val - currentVal;
  }
  return result;
};

export const blicValue = (blic, lic, unitOfMeasures, unitSet, bid, blics, bids, preferred = "participant") => {
  if (blic && blic.price) {
    const result =
      preferred === "host"
        ? hostPreferredValue(blic, lic)
        : participantPreferredValue(blic, lic, unitOfMeasures, unitSet, bid, blics, bids);
    return result;
  }
};
export const differenceOfferedPercentageForBid = (bid, lot, event) => {
  const diffOfferedForBid = differenceOfferedForBid(bid, lot, event);
  if (diffOfferedForBid === "-" || isNaN(diffOfferedForBid) || diffOfferedForBid === "n/a") {
    return "-%";
  } else {
    const lotCurrentVal = lotCurrentValue(lot);
    if (lotCurrentVal && lotCurrentVal != 0) {
      return Number(((diffOfferedForBid / lotCurrentVal) * 100).toFixed(2));
    } else {
      return "-%";
    }
  }
};

// # Savings/Profit offered
export const differenceOfferedForBid = (bid, lot, event) => {
  //   # This is the difference between the Current Value (set by the host) and the  price offered
  const bidVal = hostPreferredValue(bid, lot);
  const lotCurrentVal = lotCurrentValue(lot);
  if (!lotCurrentVal || !hasValue(bidVal) || lotCurrentVal == 0) {
    return "-";
  } else if (hasValue(bidVal) && lotCurrentVal) {
    const val = isReverseDirection(event) ? lotCurrentVal - bidVal : bidVal - lotCurrentVal;
    return val.toFixed(2);
  }
};

export const bidValue = (bid, lot, preferred = "participant", lotLics = [], checkQuantityFromTotal = false) => {
  if (bid && hasValue(bid.price)) {
    const result =
      preferred === "host"
        ? hostPreferredValue(bid, lot, lotLics, checkQuantityFromTotal)
        : participantPreferredValueForBid(bid, lot);
    return result;
  }
};

const checkAndReturnWeightedScore = (score) => `${score === null || isNaN(score) ? "-" : score + "%"}`;
export const blicWeightedScore = (lotComponent, blic, naInSmallLetters) => {
  if (blic) {
    return checkAndReturnWeightedScore(blic.weighted_score);
  } else {
    return host(lotComponent) ? naInSmallLetters : "-";
  }
};

export const differenceOfferedForCell = (
  bid,
  blic,
  lic,
  lotComponent,
  lineItem,
  event,
  licCurrency,
  allUnitSets,
  unitSetUoms,
  bids,
  blics,
  locale,
  numberFormat
) => {
  let r = "-";
  let val;
  if (bid && lic) {
    if ((participant(lotComponent) || calculation(lotComponent)) && blic) {
      val = differenceOfferedForBlic(event, blic, lic, unitSetUoms, allUnitSets, bid, blics, bids);
    } else if (host(lotComponent)) {
      val = differenceOfferedForHostEnteredLic(event, lic, lineItem, lotComponent);
    }
    r = differenceOfferedWithCurrency(val, licCurrency, locale, numberFormat, lotComponent.decimal_place);
  }
  return r;
};

export const showRankOfCell = (event, blic, bid, lic = {}, lc, lot, role, edit, auctionTab) => {
  if (
    blic &&
    !edit &&
    isPrice(lc) &&
    isRanked(lic) &&
    lot.complex_structure &&
    canDisplayRank(event, bid, auctionTab, role)
  ) {
    const rank = isWeightedRfqOrAuction(event) ? blic.overall_rank : blic.rank;
    if (rank && ((role === "Participant" && isVisibleRank(lic)) || role == "Host")) {
      return (
        <span className={`blic-rank-symbol badge ranked${rank} active-bid-val`} id={`blic_rank_${lic.id}`}>
          {rank}
        </span>
      );
    }
  }
};

export const canDisplayRank = (event = {}, bid = {}, auctionTab, role) => {
  let result;
  if (!auctionTab && role === "Participant" && !event.event_category_rfq) {
    result = false;
  } else if (!auctionTab && role === "Host" && aroundRfq(event)) {
    result = true;
  } else if (!auctionTab && (inprogress(event) || completed(event) || closed(event))) {
    result = false;
  } else if (aroundRfq(event) && !postRfqPreAuction(event)) {
    result = true;
  } else if (["Open", "Japanese"].includes(event.event_type)) {
    result = role === "Host";
  } else if (bid.bid_at_rfq_state) {
    result = false;
  } else {
    result = true;
  }
  return result;
};
export const detailsProvided = (bidBLics) => bidBLics.length > 0;

export const getHostComponentData = (lic, licCurrency, translations, locale, timeZoneIdentifier) => {
  let result = "";
  const isFormulaLicCell = lic.is_formula_lic;
  const isDetailsProvided = detailsProvided(bidBLics);
  const { number_format } = translations;

  switch (componentType(lic.lot_component_type)) {
    case "isPrice":
      let priceVal = isFormulaLicCell ? qualificationValue(lic) : usedValue(lic);
      priceVal = (isDetailsProvided || isFormulaLicCell) && showValue(priceVal);
      result = showCurrency(priceVal, licCurrency, lic.decimal_place, locale, number_format);
      break;
    case "isText":
      result = isDetailsProvided && lic.value ? lic.value : "-";
      break;
    case "isDate":
      result = isDetailsProvided && lic?.value ? formatDatetime(lic.value, timeZoneIdentifier) : "-";
      break;
    case "isDecimal":
      const decimalVal = lic.current_value || currentValue(lic);
      result = "-";
      if (!isFormulaLicCell && isDetailsProvided && !["", null, undefined].includes(decimalVal)) {
        result = showLocalisedStrippedDecimal(decimalVal, locale, 0);
      }
      break;
    case "isPicklist":
      result = isFormulaLicCell ? "" : showPicklistValue(lic, licCurrency, translations, locale) || "-";
      break;
  }
  return result;
};

export const getFormulaComponentData = (
  blic,
  lic,
  blicCurrency,
  licCurrency,
  unitOfMeasures,
  unitSet,
  bid,
  blics,
  bids,
  locale,
  preferred,
  number_format
) => {
  let result = "";
  const precision = lic?.decimal_place ?? 2;

  if (blic && !["", null, undefined].includes(blic.price)) {
    result = lic.is_price
      ? preferredBlicValue(
          blic,
          lic,
          blicCurrency,
          licCurrency,
          unitOfMeasures,
          unitSet,
          bid,
          blics,
          bids,
          locale,
          number_format,
          preferred,
          precision
        )
      : "";
  } else if (blic) {
    result = lic.is_price
      ? showCurrency(showValue(blic.price), blicCurrency, precision, locale, number_format)
      : isDecimal(lic)
      ? showLocalisedStrippedDecimal(blic.price, locale, 0)
      : "";
  } else {
    result = lic.is_price ? showCurrency("-", licCurrency, 2, locale, number_format) : isDecimal(lic) ? "-" : "";
  }
  return result;
};

export const showBidLineItemComponent = (
  bid,
  blic,
  lic,
  licCurrency,
  blicCurrency,
  blics,
  translations,
  unitOfMeasures,
  unitSet,
  bids,
  locale,
  timeZoneIdentifier,
  preferred = "participant",
  options = {}
) => {
  let result = "";
  const isFormulaLicCell = lic.is_formula_lic;
  const isDetailsProvided = detailsProvided(bidBLics);
  const { number_format } = translations;
  const isPriceComponent = lic.is_price;
  const validCell = isValidCell(lic);
  const precision = lic?.decimal_place ?? 2;
  if (lic && (lic.host || (!validCell && isFormulaLicCell))) {
    result = getHostComponentData(lic, licCurrency, translations, locale, timeZoneIdentifier);
  } else if (isFormulaLicCell && validCell) {
    result = getFormulaComponentData(
      blic,
      lic,
      blicCurrency,
      licCurrency,
      unitOfMeasures,
      unitSet,
      bid,
      blics,
      bids,
      locale,
      preferred,
      number_format
    );
  } else if (bid && !blic) {
    // do not show qualification value in place of bid value in case of msrfq
    if (!validCell && isDetailsProvided && !bid?.event_round_id && blic) {
      result = showCurrency(qualificationValue(lic), licCurrency, precision, locale, number_format);
    } else {
      result = isPriceComponent ? showCurrency("-", licCurrency, precision, locale, number_format) : "-";
    }
  } else if (blic && hasValue(blic.attribute_value)) {
    result = blic.attribute_value;
  } else if (isDecimal(lic) && hasValue(blic.price)) {
    result = showLocalisedStrippedDecimal(blic.price, locale, 0);
  } else if (blic && blic.date_value) {
    result = formatDatetime(blic.date_value, timeZoneIdentifier);
  } else if (options["priceTag"]) {
    result = preferredParticipantBlicPriceForHost(
      blic,
      lic,
      licCurrency,
      blicCurrency,
      locale,
      number_format,
      preferred
    );
  } else {
    result = preferredBlicValue(
      blic,
      lic,
      blicCurrency,
      licCurrency,
      unitOfMeasures,
      unitSet,
      bid,
      blics,
      bids,
      locale,
      number_format,
      preferred,
      precision
    );
  }
  return result;
};

export const preferredParticipantBlicPriceForHost = (
  blic,
  lic,
  licCurrency,
  blicCurrency,
  locale,
  numberFormat,
  preferred = "participant"
) => {
  const precision = lic.decimal_place ?? 2;
  let result = "-";
  if (blic) {
    if (preferred === "host") {
      result = showCurrency(
        hostPreferredPrice(blic),
        preferredCurrency(blicCurrency, licCurrency, "host"),
        precision,
        locale,
        numberFormat
      );
    } else {
      result = showCurrency(blic.price, preferredCurrency(blicCurrency, licCurrency), precision, locale, numberFormat);
    }
  }
  return result;
};

export const rankForParticipant = (bid, typeOfRank, eventParticipant) => {
  let result;
  if (eventParticipant) {
    if (eventParticipant.accepted) {
      if (typeOfRank === "price") {
        result = bid.rank;
      } else if (typeOfRank === "weightedScore" || typeOfRank === "overall") {
        result = bid.overall_rank;
      } else {
        result = "-";
      }
    } else {
      result = eventParticipantStatus(eventParticipant);
    }
  }
  return result;
};
export const awardedBid = (bid, lot) => bid.user_id === lot.awarded_participant_id;

// Savings/Profit offered
export const differenceOfferedPop = (bid, lot, event, bidList, eventParticipants) => {
  // This is the difference between the Current Value (set by the host) and the  price offered
  let res;
  if (!lot.current_price) {
    res = "-";
  } else if (
    (!lot.current_price || lot.current_price == 0 || !hostPreferredPrice(bid)) &&
    activeBidsBeforeAuctionStarted(event, bidList, eventParticipants) === 0
  ) {
    res = "-";
  } else {
    res = isReverseDirection(event)
      ? lot.current_price - hostPreferredPrice(bid)
      : hostPreferredPrice(bid) - lot.current_price;
  }
  return res;
};

export const showBid = (bid, lot, currency, locale, preferred, props = {}, precision = 2) => {
  const { hosts, translations, isLineItemDetail, showOverallScore, bidLineItemComponents, blic } = props;
  const numberFormat = translations.number_format;
  let host;
  if (hosts) {
    host = bid.host_id && findUser(hosts, bid.host_id);
  }
  let result;
  if (lot.complex_structure) {
    let bidOrBlic = blic;
    if (props.modalType !== "LineItem") {
      if (features.enabled("show_total_blic_price")) {
        bidOrBlic = findLicBidBlic(bidLineItemComponents, lot.lot_total_lic_id, bid.id) || {};
      } else {
        bidOrBlic = bid;
      }
    }

    let bidPrice = preferred === "host" ? hostPreferredPrice(bidOrBlic) : bidOrBlic.price;
    bidPrice = hasValue(bidPrice) ? bidPrice : "-";
    result = showCurrency(bidPrice, currency, precision, locale, numberFormat);
    if (bid.details_added) {
      const header = `${translations.lot_name}: ${lot.name}`;
      result = (
        <CommonModal
          viewComponent={result}
          header={header}
          content={
            <SingleLotMatrix
              bid={bid}
              lot={lot}
              isLineItemDetail={isLineItemDetail}
              showOverallScore={showOverallScore}
              translations={translations}
            />
          }
          modalCloseButtonText={props.translations.close}
          modalCloseButtonClass="btn btn-sm btn-default"
        />
      );
    }
  }
  return (
    <>
      {result}
      {host && (
        <span className="proxy_bid" title={`${props.translations.proxy_bid} ${host.name}`}>
          P
        </span>
      )}
    </>
  );
};

export const preferredBlicPrice = (blic, lic, actingAs, precision, locale, numberFormat, lCurrency, bCurrency) => {
  if (blic) {
    if (actingAs === "Host") {
      if (lic) {
        return showCurrency(
          hostPreferredPrice(blic),
          preferredCurrency(bCurrency, lCurrency, "host"),
          precision,
          locale,
          numberFormat
        );
      }
    } else {
      return showCurrency(blic.price, preferredCurrency(bCurrency, lCurrency), precision, locale, numberFormat);
    }
  } else {
    return "-";
  }
};
export const timedOutBid = (bid) => bid.timed_out_bid || bid.id == null;
export const timeOutOrDeclinedBidPrice = (lot, event, bid, auctionControls, currency, locale, translations) => {
  const translation = timedOutBid(bid) ? translations.timed_out_bid_level : translations.declined_bid_level;
  return (
    <td>
      <b>{`${translation} (${translations.at} ${showCurrency(
        bidRoundPrice(lot, event, bid.bid_round, auctionControls) || "-",
        currency,
        2,
        locale,
        translations.number_format
      )})`}</b>
    </td>
  );
};

const getCalcPrice = (value, unitSetUoms, blic, lic, lc) => {
  const price = Number(value);
  if (isNaN(price)) {
    return !isNaN(parseFloat(value)) ? parseFloat(value) : value;
  } else {
    return price * getQuantity(lic, blic, lc, unitSetUoms);
  }
};

export const calculatedPrice = (value, lic, blic, lc, unitSetUoms) => {
  return getCalcPrice(value, unitSetUoms, blic, lic, lc);
};

export const getQuantity = (lic, blic, lc, unitSetUoms) => {
  if (!!lc.is_uom_set && blic && blic.unit_of_measure_id) {
    const uom = unitSetUoms.find(({ id }) => id === blic.unit_of_measure_id);
    if (uom) return uom.ratio;
  } else {
    return lic && lic.quantity ? parseFloat(lic.quantity) : 1;
  }
};

/**
 *
 * @param {*} value
 * @param {Number} decimal (Optional)
 * @returns {Number} roundValue
 */
export const roundValue = (value, decimal = 2) => {
  const roundedDecimal = (value ?? "").toLocaleString("en", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
  // NaN fallback to passed value
  return !isNaN(roundedDecimal) ? roundedDecimal : value;
};

/**
 * Check if bid is submitted
 * @param {object} bid
 * @returns Boolean
 */
export const bidSubmitted = (bid = {}) => {
  return bid.current_state === "submitted";
};

/**
 * Find blic by licId and bidId
 *
 * @param {Array} bidBlics
 * @param {Number} licId
 * @param {Number} bidId
 * @returns {Object} blic
 */
export const findLicBidBlic = (bidBlics, licId, bidId) => {
  return bidBlics?.find((blic) => blic.line_item_component_id === licId && blic.bid_id === bidId);
};

export const thisParticipantBidOnThisRound = (bids, participantId, round) => {
  return (
    bids.filter(
      (bid) =>
        bid.user_id === participantId &&
        !bid.rejected_lot &&
        !bid.decline_bid &&
        bid.current_state === "submitted" &&
        bid.bid_round === round
    ).length > 0
  );
};

export const participantActiveInJapaneseAuction = (event, remainingParticipants, userId) => {
  if (event.event_type != "Japanese") {
    return "not calculated";
  }
  let r = false;
  if (["pending", "pause", "edit", "running"].includes(event.current_state)) {
    r = remainingParticipants.filter((u) => u == userId).length > 0;
  }
  return r;
};

export const rejectedBidForUser = (bid) => {
  return bid && bid.rejected_lot && bidSubmitted(bid);
};

export const fetchCorrectBidForLotsInAuction = (
  event,
  lot,
  bids,
  userId,
  role,
  beforeAuction,
  participant,
  weightedRfqOrAuction
) => {
  const lBids = bids.filter((bid) => bid.lot_id === lot.id && bid.user_id === userId);
  if (role === "Participant" && itIsBeforeAuction(event)) {
    return null;
  } else {
    if (event.current_state === "pause" || (event.current_state === "completed" && !event.bid_at_detail_level)) {
      return (
        last(latestBidDuringAuctionForLot(lBids, [participant], event, weightedRfqOrAuction)) ||
        thisParticipantsIncompleteBid(event, lBids, userId, beforeAuction)
      );
    } else if (event.current_state === "completed" && event.bid_at_detail_level) {
      return last(latestBidDuringAuctionForLot(lBids, [participant], event, weightedRfqOrAuction));
    } else {
      return (
        thisParticipantsIncompleteBid(event, lBids, userId, beforeAuction) ||
        last(latestBidDuringAuctionForLot(lBids, [participant], event, weightedRfqOrAuction))
      );
    }
  }
};

export const itIsBeforeAuction = (event) => {
  return (
    (!event.start_at || convertTimeIntoUtc(event.start_at) > convertTimeIntoUtc()) &&
    event.extra_event_state !== "post_rfq_pre_auction" &&
    deadlinesInThePast(event)
  );
};

export const thisParticipantsIncompleteBid = (event, bids, userId, beforeAuction) => {
  if (beforeAuction && event.extra_event_state !== "post_rfq_pre_auction") {
    return last(bids.filter((bid) => bid.user_id === userId && event.current_state === "new"));
  } else {
    const bidAtRfqState = event.event_category_rfq && !event.event_category_auction ? true : false;

    return last(
      bids.filter(
        (bid) => bid.bid_at_rfq_state === bidAtRfqState && bid.user_id === userId && bid.current_state === "new"
      )
    );
  }
};

export const canShowBidFormStatus = (event, lot, bid, blics, auctionTabCheck = false) =>
  requiresComplexDetail(event, lot) &&
  bid &&
  !lot.is_event_total &&
  (anyBidLineItemComponentsExist(bid.id, blics) || auctionTabCheck) &&
  bid.current_state !== "submitted" &&
  event.current_state !== "pause";

export const anyBidLineItemComponentsExist = (bidId, bidLineItemComponents = {}) => {
  if (!bidLineItemComponents.length) return false;

  return relatedBidLineItemComponents(bidId, bidLineItemComponents).length >= 1;
};

export const overallRankIfWeighted = (bid, weightedRfqOrAuction) => {
  return weightedRfqOrAuction ? bid.overall_rank : bid.rank;
};

export const roundBidWithControls = (bidControls, round, user) => {
  return (
    bidControls.filter((bc) => bc.bid_round === round && bc.participant_id === user.id && bc.reaccept).length === 0
  );
};

export const participantNotTimedOutOrDeclined = (bids, round, user, bidControls) => {
  const pb = last(
    bids.filter((bid) => bid.bid_round === round - 1 && bid.user_id === user.id && bid.current_state === "submitted")
  );

  const cb = last(
    bids.filter((bid) => bid.bid_round === round && bid.user_id === user.id && bid.current_state === "submitted")
  );

  const rbc = roundBidControls(bidControls, round, user);
  const rbwc = roundBidWithControls(bidControls, round, user);

  return (
    (!pb && !cb && !rbc) ||
    (!pb && cb && ((cb.decline_bid && rbwc) || cb.current)) ||
    (!cb && pb && ((pb.decline_bid && !rbc) || pb.current)) ||
    (cb && pb && (cb.current || (cb.decline_bid && rbwc)) && (cb.current || !rbc))
  );
};
