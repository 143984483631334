import { emptyApi } from "@/slices/api";
import type { GetRulesAndFieldsResponse, UpdateRulesRequest } from "@/slices/rules/types";
import { makeUpdateRulesRequest, transformGetRulesAndFieldsResponse } from "@/slices/rules/types";

export interface TemplateIdentifier {
  companyId: number;
  templateId: number;
}

export const lotTemplatesApi = emptyApi.enhanceEndpoints({ addTagTypes: ["LotRules"] }).injectEndpoints({
  endpoints: (build) => ({
    getLotRulesAndFields: build.query<GetRulesAndFieldsResponse, TemplateIdentifier>({
      query: ({ companyId, templateId }) => `v2/companies/${companyId}/lot_templates/${templateId}/rule`,
      transformResponse: transformGetRulesAndFieldsResponse,
      providesTags: (result, err, { templateId }) => (result ? [{ type: "LotRules", id: templateId }] : []),
    }),
    updateLotRules: build.mutation<void, UpdateRulesRequest>({
      query: ({ companyId, templateId, ruleId, rules }) => ({
        url: `v2/companies/${companyId}/lot_templates/${templateId}/rule`,
        method: "PUT",
        body: makeUpdateRulesRequest({ companyId, templateId, ruleId, rules }),
      }),
      invalidatesTags: (result, err, { templateId }) => [{ type: "LotRules", id: templateId }],
    }),
  }),
  overrideExisting: true,
});

export const { useGetLotRulesAndFieldsQuery, useUpdateLotRulesMutation } = lotTemplatesApi;
