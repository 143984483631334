import { BaseController } from "./controller_base";
import { assert } from "@/utils/assert";

export default class extends BaseController {
  connect() {
    if (this.element.hasAttribute("data-on-enter")) {
      this.showHide(this.element as HTMLElement);
    }
  }

  showHide(el: HTMLElement) {
    let element = el;
    if (el instanceof Event) element = el.target as HTMLElement;

    for (const target of this.#getTargets(element, "data-show")) {
      target.style.display = "block";
    }
    for (const target of this.#getTargets(element, "data-hide")) {
      target.style.display = "none";
    }
    for (const target of this.#getTargets(element, "data-toggle")) {
      if (target.style.display === "none") {
        target.style.display = "block";
      }
    }
  }

  #getTargets(element: HTMLElement, attribute: string): HTMLElement[] {
    const attr = element.getAttribute(attribute);
    if (!attr) {
      return [];
    }

    return attr.split(" ").map((target) => {
      const element = document.getElementById(target);
      assert(element, `No element found with id ${target}`);
      return element;
    });
  }
}
