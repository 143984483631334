import React, { useEffect, useRef } from "react";

// Conditionally import the trix editor if it has not been registered already.
// This happens when we have loaded trix another way (looking at you
// sprockets). If we load trix twice, we get the custom element exception.
//
// NOTE: This only stops it one way, if this is loaded first, we will still get
// the error. If that happens, then we need to change the JS load order in the
// view.
//
// See: https://developer.mozilla.org/en-US/docs/Web/API/CustomElementRegistry/define#exceptions
if (!customElements.get("trix-editor")) {
  import("trix");
}

const TrixEditor = ({ value, onChange }) => {
  const trixInput = useRef(null);

  useEffect(() => {
    // Create a local variable binding inside the useEffect so if the `current`
    // changes outside we are removing the event listener from the current
    // input. For Example
    //
    // trixInput.current.addEventListener
    // trixInput.current = true input
    // trixInput.current.removeEventListener

    const input = trixInput.current;
    if (!input) return;
    const updateInput = () => onChange(trixInput.current.value);
    input.addEventListener("trix-change", updateInput);
    return () => input.removeEventListener("trix-change", updateInput);
  }, [trixInput, onChange]);

  useEffect(() => {
    if (!trixInput.current) return;
    if (Boolean(value) && trixInput.current.inputElement?.value === value) return;
    trixInput.current.value = value;
  }, [value]);

  return (
    <>
      <input type="hidden" data-testid="trix" value={value} />
      <trix-editor
        ref={trixInput}
        data-direct-upload-url="/rails/active_storage/direct_uploads"
        data-blob-url-template="/rails/active_storage/blobs/:signed_id/*filename"
      />
    </>
  );
};

export default TrixEditor;
