import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { discardDraftActivity, setRefreshModal, toggleDiscardActivityModal } from "../../../actions/activityActions";

const handleDiscardActivity = (dispatch, activityId, messsage) =>
  dispatch(discardDraftActivity(activityId, messsage)).then((response) => {
    if (response.status) {
      dispatch(
        setRefreshModal({
          refresh: true,
        })
      );
      dispatch(
        toggleDiscardActivityModal({
          hide: true,
        })
      );
    }
  });

const toggleModal = (dispatch) => dispatch(toggleDiscardActivityModal({ hide: true }));

const ModalStyle = {
  overlay: {
    zIndex: 199,
  },
  content: {
    zIndex: 200,
  },
};

const NewActivityWrapper = function ({ children }) {
  const dispatch = useDispatch();

  const { calledFromIndex, translations, non_persistent_state } = useSelector((state) => state.ActivityReducer);

  return (
    <>
      {children}
      {!calledFromIndex && (
        <Modal
          style={ModalStyle}
          className="modal-content scrollbar-hidden"
          isOpen={non_persistent_state.show_discard_model}
        >
          <ModalHeader dispatch={dispatch} translations={translations} />
          <div className="modal-body">
            <p className="alert alert-danger">
              You have a draft activity do you want to discard this and create a new one?
            </p>
          </div>
          <ModalFooter
            dispatch={dispatch}
            translations={translations}
            activity_draft_id={non_persistent_state.activity_draft_id}
          />
        </Modal>
      )}
    </>
  );
};

NewActivityWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const ModalHeader = ({ dispatch, translations }) => {
  return (
    <div className="modal-header">
      <button type="button" className="close" onClick={(e) => toggleModal(dispatch)}>
        &times;
      </button>
      <h4 className="modal-title">{translations.pleaseConfirm}</h4>
    </div>
  );
};

ModalHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
};

const ModalFooter = ({ translations, dispatch, activity_draft_id }) => {
  return (
    <div className="modal-footer">
      <button type="button" className="btn btn-default height-40 m-b0" onClick={(e) => toggleModal(dispatch)}>
        {translations.cancel}
      </button>
      <button
        type="button"
        className={`btn btn-primary ok-btn height-40`}
        onClick={(e) => handleDiscardActivity(dispatch, activity_draft_id, translations.activity_discarded)}
      >
        {translations.ok}
        <i className="fa fa-check m-l5"></i>
      </button>
    </div>
  );
};

ModalFooter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
};

export default NewActivityWrapper;
