import { t } from "@/i18n";
import React from "react";
import PropTypes from "prop-types";
import { AuthPropType } from ".";
import { useModuleUiConfig } from "./types";

/**
 * @type {React.FC}
 */
const TableHead = ({ auth }) => {
  const modules = useModuleUiConfig();

  // A user can edit the item if they have edit permission on any of the
  // modules
  const canEdit = Object.values(auth).some((item) => item === true);

  return (
    <thead>
      <tr>
        <th style={{ width: "16.6%" }}>{t("title")}</th>
        <th style={{ width: "16.6%" }}>{t("type")}</th>
        {modules.map(({ module, label }) => (
          <th key={module} style={{ width: "16.6%" }}>
            {label}
          </th>
        ))}
        {canEdit && <th style={{ minWidth: "140px" }}>{/* Actions have no text */}</th>}
      </tr>
    </thead>
  );
};

TableHead.propTypes = {
  /**
   * The current users access on the modules
   */
  auth: PropTypes.shape(AuthPropType).isRequired,
};

export default TableHead;
