import { useCallback } from "react";
import { useSelector } from "react-redux";
import { findParticipantBlicForLic, participantPreferredValue } from "../bidCommon";
import { isANumber } from "../common";
import useUnitSets from "./useUnitSets";

/* eslint-disable no-unused-vars */
import { InferProps } from "prop-types";
import { Bid, LineItemComponent } from "@/common-prop-types";
/* eslint-enable no-unused-vars */

/**
 * @param {number} bidId
 * @returns {boolean}
 */
const blicFilter = (bidId) => (blic) => blic.bid_id === bidId && isANumber(blic.price);

const useGetTotalBidValueCallback = ({ bid, getLotTotalBlic }) => {
  const { unitSetUoms, getUnitSet } = useUnitSets();
  const event = useSelector(({ lotReducers }) => lotReducers.event);
  const bidLineItemComponents = useSelector(({ lotReducers }) => lotReducers.bid_line_item_components);

  return useCallback(
    /**
     *
     * @param {boolean} showPrice if false then price will not be visible
     * @param {InferProps<LineItemComponent>} lotTotalLic
     * @param {boolean} showAllLineItemDetails
     * @returns {string | number | undefined} bidValue
     */
    (showPrice, lotTotalLic, showAllLineItemDetails) => {
      const unitSet = getUnitSet(lotTotalLic);
      const lotTotalBlic = getLotTotalBlic(showAllLineItemDetails, lotTotalLic?.id);
      const { participant_preferred_value, price } = lotTotalBlic ?? {};
      const isPriceVisible = showPrice && [participant_preferred_value, price].some(isANumber);

      if (!isPriceVisible) return "";
      if (event.bid_at_detail_level || showAllLineItemDetails) {
        return (
          participant_preferred_value ??
          participantPreferredValue(lotTotalBlic, lotTotalLic, unitSetUoms, unitSet, bid, bidLineItemComponents, [bid])
        );
      }
      return price;
    },
    [bid, bidLineItemComponents, event.bid_at_detail_level, getLotTotalBlic, getUnitSet, unitSetUoms]
  );
};

const useGetLotTotalBlicCallback = ({ bid }) => {
  const bidLineItemComponents = useSelector(({ lotReducers }) => lotReducers.bid_line_item_components);
  const event = useSelector(({ lotReducers }) => lotReducers.event);
  const lotTotalBlics = useSelector(({ lotReducers }) => lotReducers.lot_total_blics);

  return useCallback(
    /**
     *
     * @param {boolean} showAllLineItemDetails
     * @param {number} totalLicId
     * @returns {InferProps<Bid>} bid
     */
    (showAllLineItemDetails, totalLicId) => {
      // If event's bid_at_detail_level is true then, in this case,
      // if BLICs are present, only Your bid value need to show and
      // for the Japanese and lot level event, 'Your bid' will always be
      // visible to the user and, if the user clicks on the 'enter bid details'
      // button then isAllDetailView becomes true, In that case,
      //  'Your bid' will be shown if BLIC is present and
      // will change according to the BLICs.
      return event.bid_at_detail_level || showAllLineItemDetails
        ? bid &&
            (lotTotalBlics?.find(blicFilter(bid.id)) ||
              findParticipantBlicForLic(totalLicId, bidLineItemComponents, bid.id))
        : bid;
    },
    [bid, bidLineItemComponents, event.bid_at_detail_level, lotTotalBlics]
  );
};

/**
 *
 * @param {InferProps<Bid>} bid
 */
const useBid = (bid) => {
  const getLotTotalBlic = useGetLotTotalBlicCallback({ bid });
  const getTotalBidValue = useGetTotalBidValueCallback({ bid, getLotTotalBlic });

  return { getLotTotalBlic, getTotalBidValue };
};

export default useBid;
