import React, { Component } from "react";
import { greenTick } from "../../actions/commonActions";

// This is common class for the custom confirmation modal we have in MD Rails application
// To open the modal add this data-attributes to the button or anchor tag:
// data-toggle="modal" data-target="#this.props.htmlId" (whatever you pass to the component in props)
// Pass the reference of method in "onOkayButtonClick" props which will handle the API call or other things needs to be done
// Also pass the translations hash in props with "pleaseConfirm, ok, cancel" keys and pass a "message" prop according to the requirement
export class Confirm extends Component {
  constructor() {
    super();
    this.handleOkButtonClick = this.handleOkButtonClick.bind(this);
  }

  async handleOkButtonClick(e) {
    greenTick();
    this.btn.setAttribute("disabled", "disabled");
    await this.props.onOkayButtonClick(e);
  }

  render() {
    const { categoryDojo } = this.props;
    if (this.btn !== undefined) {
      this.btn.removeAttribute("disabled");
    }
    return (
      <div
        id={this.props.htmlId}
        className="modal fade modal-dialog-popout top-position"
        role="dialog"
        style={{ display: "none" }}
        data-backdrop={!categoryDojo}
      >
        <div
          className={`modal-dialog-popout custom-modal modal-sm modal-draggable-content modal-dialog ${this.props.modalClass}`}
          style={{ position: "relative" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" onClick={this.props.removeHook}>
                &times;
              </button>
              <h4 className="modal-title">{this.props.title || this.props.translations.pleaseConfirm}</h4>
            </div>
            <div className="modal-body text-left">{this.props.message}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default height-40 m-b0"
                data-dismiss="modal"
                onClick={this.props.removeHook}
              >
                {this.props.cancelButtonText !== undefined
                  ? this.props.cancelButtonText
                  : this.props.translations.cancel}
              </button>
              <button
                type="button"
                className={`btn btn-primary ${categoryDojo ? "btn-default" : ""} ok-btn height-40`}
                onClick={this.handleOkButtonClick}
                ref={(btn) => {
                  this.btn = btn;
                }}
                data-dismiss="modal"
              >
                {this.props.okButtonText !== undefined ? this.props.okButtonText : this.props.translations.ok}
                <i className="fa fa-check m-l5"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Confirm.defaultProps = {
  removeHook: () => {},
};
