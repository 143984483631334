import React, { useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { unstable_batchedUpdates } from "react-dom";
import uniqBy from "lodash/uniqBy";
import { ExtractUrlParams } from "./RouteFilter";
import PropTypes from "prop-types";

const activityTableHeader = (props) => {
  const {
    translations,
    customColumnOptions,
    hostOwnerEmails,
    activityConcernedContacts,
    supplierNames,
    statusValues,
    setFilter,
  } = props;

  let filters = ExtractUrlParams(props, window.location.search);
  const [subject, setSubject] = useState(filters.subject || "");
  const [owners, setOwners] = useState(filters.owners || []);
  const [statuses, setStatuses] = useState(filters.status || []);
  const [activityTypes, setActivityTypes] = useState(filters.activity_type || []);
  const [priorities, setPriorities] = useState(filters.priority || []);
  const [createdAtFrom, setCreatedAtFrom] = useState(filters.createdAtFrom || "");
  const [createdAtTo, setcreatedAtTo] = useState(filters.createdAtTo || "");
  const [dueDateFrom, setDueDateFrom] = useState(filters.dueDateFrom || "");
  const [dueDateTo, setDueDateTo] = useState(filters.dueDateTo || "");
  const [suppliers, setSuppliers] = useState(filters.suppliers || []);
  const [contacts, setContacts] = useState(filters.contacts || []);
  const [parents, setParents] = useState([]);

  const validateButtons = () =>
    !![
      subject,
      owners,
      statuses,
      activityTypes,
      priorities,
      createdAtFrom,
      createdAtTo,
      dueDateFrom,
      dueDateTo,
      suppliers,
      contacts,
    ].filter((item) => (typeof item === "object" && !Array.isArray(item) && item) || (item && item.length)).length;

  const setFilterData = () => {
    let filterData = {
      subject: subject,
      owners: owners.map((o) => o.id),
      status: statuses.map((s) => s.id),
      activity_type: activityTypes.map((at) => at.text),
      priority: priorities.map((p) => p.text),
      suppliers: suppliers.map((s) => s.id),
      contacts: contacts.map((c) => c.id),
      due_date: [dueDateFrom, dueDateTo],
      parent_id: parents.map((parent) => parent.id),
      created_at: [createdAtFrom, createdAtTo],
      myFilters: false,
      applied: true,
    };

    setFilter(filterData);
  };

  const resetState = () => {
    unstable_batchedUpdates(() => {
      setFilter();
      setSubject("");
      setOwners([]);
      setStatuses([]);
      setActivityTypes([]);
      setPriorities([]);
      setCreatedAtFrom("");
      setcreatedAtTo("");
      setDueDateFrom("");
      setDueDateTo("");
      setSuppliers([]);
      setContacts([]);
      setParents([]);
    });
  };

  const handleCreatedAtValues = (value) => {
    if (value > createdAtTo) {
      unstable_batchedUpdates(() => {
        setCreatedAtFrom(value);
        setcreatedAtTo("");
      });
    } else {
      setCreatedAtFrom(value);
    }
  };

  const handleDueDateValues = (value) => {
    if (value > dueDateTo) {
      unstable_batchedUpdates(() => {
        setDueDateFrom(value);
        setDueDateTo("");
      });
    } else {
      setDueDateFrom(value);
    }
  };

  return (
    <tr>
      <td className="wid-10">
        {translations.subject}
        <div style={{ marginTop: 0 }} className="form-group form-textspace">
          <input
            type="text"
            name="subject"
            className="search_text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
      </td>
      <td className="wid-13">
        <MultiSelectWrapper
          label={translations.owners}
          options={{
            options: emailsForSelect(hostOwnerEmails),
            name: "hostOwners",
            onSelect: (values) => setOwners(values),
            onRemove: (values) => setOwners(values),
            selectedValues: owners,
          }}
        />
      </td>
      <td>
        <MultiSelectWrapper
          label={translations.status}
          options={{
            options: statusValuesForFilter(statusValues, translations),
            name: "status",
            onSelect: (e) => setStatuses(e),
            onRemove: (e) => setStatuses(e),
            selectedValues: statuses,
          }}
        />
      </td>
      <td>
        <MultiSelectWrapper
          label={translations.type}
          options={{
            options: activityTypeOptions(customColumnOptions, translations),
            name: "activityType",
            displayValue: "translated_text",
            onSelect: (e) => setActivityTypes(e),
            onRemove: (e) => setActivityTypes(e),
            selectedValues: activityTypes,
          }}
        />
      </td>
      <td>
        <MultiSelectWrapper
          label={translations.priority}
          options={{
            options: priorityOptions(customColumnOptions),
            name: "priority",
            onSelect: (e) => setPriorities(e),
            onRemove: (e) => setPriorities(e),
            selectedValues: priorities,
          }}
        />
      </td>
      <td className="wid-14">
        <div>{translations.created_at}</div>
        <DatePicker
          name="createdAtFrom"
          selected={createdAtFrom}
          onChange={(value) => handleCreatedAtValues(value)}
          dateFormat="yyyy-MM-dd"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className="dtpicker form-control"
          autoComplete="off"
          placeholderText={translations.from}
        />
        <DatePicker
          name="createdAtTo"
          selected={createdAtTo}
          onChange={(value) => setcreatedAtTo(value)}
          dateFormat="yyyy-MM-dd"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className="dtpicker form-control"
          autoComplete="off"
          minDate={createdAtFrom}
          showDisabledMonthNavigation
          placeholderText={translations.to}
        />
      </td>
      <td className="wid-14">
        <div>{translations.due_date}</div>
        <DatePicker
          name="dueDateFrom"
          selected={dueDateFrom}
          onChange={(value) => handleDueDateValues(value)}
          dateFormat="yyyy-MM-dd"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className="dtpicker form-control"
          autoComplete="off"
          placeholderText={translations.from}
        />
        <DatePicker
          name="dueDateTo"
          selected={dueDateTo}
          onChange={(value) => setDueDateTo(value)}
          dateFormat="yyyy-MM-dd"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className="dtpicker form-control"
          autoComplete="off"
          minDate={dueDateFrom}
          showDisabledMonthNavigation
          placeholderText={translations.to}
        />
      </td>
      <td>
        <MultiSelectWrapper
          label={translations.suppliers}
          options={{
            options: objectsForSelect(supplierNames),
            name: "supplier",
            onSelect: (values) => setSuppliers(values),
            onRemove: (values) => setSuppliers(values),
            selectedValues: suppliers,
          }}
        />
      </td>
      <td>
        <MultiSelectWrapper
          label={translations.contacts}
          options={{
            options: objectsForSelect(activityConcernedContacts),
            name: "contacts",
            onSelect: (values) => setContacts(values),
            onRemove: (values) => setContacts(values),
            selectedValues: contacts,
          }}
        />
      </td>
      {props.active && <td>{translations.parent_activity}</td>}
      <td className="btn-group-sm p-t30 wid-10">
        <button
          className="btn btn-primary sm-butt m-r5"
          id="search_activities"
          title="Search"
          disabled={!validateButtons()}
          onClick={() => setFilterData()}
        >
          <i className="fa fa-search m-l0 vertical-align-top white-color"></i>
        </button>
        <button className="btn btn-primary sm-butt" id="reset" title="Reset Filter" onClick={() => resetState()}>
          <i className="fa fa-refresh"></i>
        </button>
      </td>
    </tr>
  );
};

const valuesForSelect = (valueType, cco, translations = null) => {
  const options = cco.filter((option) => option.custom_column_name === valueType);
  return options.map((option) => {
    let translated_text = option.name;
    if (translations && translations[option.name.toLowerCase()]) {
      translated_text = translations[option.name.toLowerCase()];
    }
    return { id: option.id, text: option.name, translated_text };
  });
};

// Filtering the activity types to be displayed on the activity form from custom column options
const activityTypeOptions = (cco, translations = null) => valuesForSelect("activity_type", cco, translations);

// Filtering the priority options to be displayed on the activity form from custom column options
const priorityOptions = (cco) => valuesForSelect("priority", cco);

const statusValuesForFilter = (statusValues, translations) => {
  return statusValues.map((value) => {
    return { id: value, text: translations[value] };
  });
};

const objectsForSelect = (objects) => {
  return (objects || []).map((object) => {
    return { id: object[0], text: object[1] };
  });
};

const emailsForSelect = (hostOwnerEmails) => {
  const ownerObjects = hostOwnerEmails.map((owner) => {
    return { id: owner.id, text: owner.email };
  });
  return uniqBy(ownerObjects, "id");
};

const MultiSelectWrapper = ({ options, label }) => (
  <>
    {label}
    <div style={{ marginTop: 0 }} className="form-group form-textspace">
      <Multiselect showCheckbox={true} displayValue="text" {...options} />
    </div>
  </>
);

MultiSelectWrapper.propTypes = {
  options: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default activityTableHeader;
