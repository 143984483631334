import PropTypes from "prop-types";

export const SimCommonPropTypes = {
  /**
   * The object defining all of the display info for the component
   *
   * @type {object}
   */
  translations: PropTypes.object.isRequired,

  /**
   * function to be invoked if the input field's value get changed
   *
   * @type {() => void}
   */
  onValueChange: PropTypes.func,

  /**
   * To check if the current user has the permission to edit this input field
   *
   * @type {boolean}
   */
  currentUserEditConfigPermission: PropTypes.bool,

  /**
   * SIM setting values that we are getting from the backend.
   *
   * @type {object}
   */
  configurationSettingValues: PropTypes.object,
};

export const ExpiredQAPropTypes = {
  ...SimCommonPropTypes,

  /**
   * All the available time duration a user can choose for the participants.
   * It could be "Years", "Months", "Weeks" or "Days".
   *
   * @type {Array}
   */
  durationType: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
};

export const SettingWithSelectPropTypes = {
  ...SimCommonPropTypes,
};

export const SimSettingCheckboxPropTypes = {
  /**
   * Value for that particular SIM setting
   *
   * @type {string | boolean}
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  /**
   * It will call an update function that will update the SIM setting value in the backend
   *
   * @type {(dataValue: string | boolean, name: string) => void}
   */
  onChange: PropTypes.func.isRequired,

  /**
   * To make the input or checkbox disable if the user don't have the permission
   *
   * @type {boolean}
   */
  disabled: PropTypes.bool,

  /**
   * name of the particular Sim setting
   *
   * @type {string}
   */
  name: PropTypes.string.isRequired,
};

export const SimSettingInputPropTypes = {
  ...ExpiredQAPropTypes,
  ...SimSettingCheckboxPropTypes,

  /**
   * label of the particular Sim setting
   *
   * @type {string}
   */
  label: PropTypes.string.isRequired,
};

export const InputWithSelectPropTypes = {
  ...SimSettingInputPropTypes,

  /**
   * It will update the Input value for Sim settings
   *
   * @type {() => void}
   */
  handleMesureChange: PropTypes.func.isRequired,

  /**
   * It will update the Select value for Sim settings
   *
   * @type {() => void}
   */
  handleUnitChange: PropTypes.func.isRequired,
};
