import React, { Component } from "react";
import ConfigurationSettingInfo from "./ConfigurationSettingInfo";
import MDSettings from "./MDSettings";
import SimDojoSettings from "./SimDojoSettings";

class SimSettings extends Component {
  render() {
    const {
      translations,
      company,
      isSimDojo,
      durationType,
      configuration_setting_values,
      configuration_setting_enum_values,
      SettingType,
      currentUserEditConfigPermission,
    } = this.props;
    let permission;
    if (currentUserEditConfigPermission != null && currentUserEditConfigPermission.action_cd == 1) {
      permission = true;
    } else {
      permission = false;
    }

    return (
      <div className="block-content tab-content">
        <div className="tab-pane active" id="btabs-static-display-settings">
          <ConfigurationSettingInfo translations={translations.admin_views.configuration.setting_info} />
          <div className="clearfix"></div>
          <div className="form">
            <span id="config_company_id" data-company={company.id}></span>
            {isSimDojo ? (
              <SimDojoSettings {...this.props} currentUserEditConfigPermission={permission} />
            ) : (
              <MDSettings
                translations={translations}
                company={company}
                durationType={durationType}
                configuration_setting_values={configuration_setting_values}
                configuration_setting_enum_values={configuration_setting_enum_values}
                currentUserEditConfigPermission={permission}
                SettingType={SettingType}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SimSettings;
