import React, { Component } from "react";
import { connect } from "react-redux";

import EventParticipantPermissionTab from "./EventParticipantPermissionTab";
import { createParticipantPermission } from "../../../../actions/adminActions";
import PermissionsLayout from "./permissions-layout";

export class SimPermissionsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionsState: null,
    };
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { data } = newProps;
    const { permissionsState } = prevState;
    if (data && data.participantPermissions && !data.participantPermissionId && !permissionsState) {
      newProps.resetHostPermission();
    }
    return null;
  }

  createParticipantParmissionOnOkButton = () => {
    const { permissionsState } = this.state;
    const { userPermission, resetHostPermission, createParticipantPermission } = this.props;
    if (permissionsState && permissionsState.resource_id != 0) {
      createParticipantPermission(permissionsState.action, permissionsState.resource_id, userPermission.id, null);
      this.setState({ permissionsState: null });
    } else {
      resetHostPermission();
    }
  };

  addparticipantpermissions = (permissionsState) => {
    this.setState({
      permissionsState,
    });
  };

  render() {
    const {
      simDojo,
      translations,
      simPermissionsList,
      userPermission,
      categoryDojo,
      companyId,
      currentUser,
      actionSelect,
      simDojoOwnerId,
      permissionAction,
      permissionOwner,
      groupsForSelect,
      alertForParticipantPermission,
      resetHostPermission,
      currentUserCanEditPermissions,
    } = this.props;
    return (
      <div className="tab-pane fade" id="simPermission" role="tabpanel" aria-labelledby="simPermission-tab">
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12">
              {/* SIM Permissions Selects */}
              <PermissionsLayout
                name="sim_permissions_html"
                permissionsList={simPermissionsList}
                categoryDojo={categoryDojo}
                actionSelect={actionSelect}
              />
            </div>
          </div>
          {simDojo && (
            <EventParticipantPermissionTab
              simDojo={simDojo}
              translations={translations}
              permissionAction={permissionAction}
              permissionOwner={permissionOwner}
              groupsForSelect={groupsForSelect}
              userPermission={userPermission}
              companyId={companyId}
              alertForParticipantPermission={(userPermissionId) => alertForParticipantPermission(userPermissionId)}
              addParticipantpermissions={(data) => this.addparticipantpermissions(data)}
              currentUser={currentUser}
              currentUserCanEditPermissions={currentUserCanEditPermissions}
            />
          )}
        </div>
        <div className="modal-footer">
          <div className="block-content text-left">
            {userPermission.user.id === simDojoOwnerId && !userPermission.user.superhost ? (
              <div className="non_licensed_user_rule_limit">{`* ${translations.supplierOnboardingUser}`}</div>
            ) : (
              ""
            )}
            {userPermission.user.company_id !== companyId ? (
              <div className="non_licensed_user_rule_limit">{`* ${translations.ruleCannotBeForExternalUser}`}</div>
            ) : (
              ""
            )}
            {!userPermission.user.cat_dojo_license ? (
              <div className="non_licensed_user_rule_limit">{translations.nonLicensedUserRuleLimit}</div>
            ) : (
              ""
            )}
          </div>
          <div className="block-content pull-right">
            <button
              className={`btn btn-primary ${categoryDojo ? "btn-default" : ""}`}
              onClick={() => this.createParticipantParmissionOnOkButton()}
            >
              {translations.ok}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.adminReducers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createParticipantPermission: (action, resourceId, userPermissionId, participantPermissionId) =>
    dispatch(createParticipantPermission(action, resourceId, userPermissionId, participantPermissionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimPermissionsTab);
