import * as apiCall from './api';
import config from './config';
import * as globalActionTypes from "../actionsTypes";
import actionTypes from './actionsTypes';
import { mutatedEvents } from './qqActions';

const fetchUserDetailsSuccess = (details) => ({
  type: actionTypes.fetch_user_details,
  payload: details,
});

const adminPermissionSuccess = (permission) => ({
  type: actionTypes.fetch_admin_permission,
  payload: permission,
});

const whiteLablelingSuccess = (companyDetails) => ({
  type: actionTypes.fetch_company_details,
  payload: companyDetails,
});

const emptyCurrentStateSuccess = (type, payload) => ({
  type,
  payload,
});

const featureEnabledSuccess = (feature) => ({
  type: actionTypes.feature_enabled,
  payload: feature,
});

const getError = (error) => ({
  type: globalActionTypes.ERROR_CODE,
  payload: error,
});

const supplierStatusSuccess = (status) => ({
  type: actionTypes.supplier_status,
  payload: status,
});

const sandpitParticipantsSuccess = (users) => ({
  type: actionTypes.sandpit_participants,
  payload: users,
});

const showSuccessMessage = (message) => ({
  type: actionTypes.show_success,
  payload: message,
});

export const changeStateSuccess = (updatedEvent) => ({
  type: actionTypes.change_event_state,
  payload: updatedEvent.event,
});

export const fetchDocumentTemplateSuccess = (documentTemplate) => ({
  type: actionTypes.fetch_document_templates,
  payload: documentTemplate,
});

export const adminPermission = (userId) => (dispatch) => {
  apiCall.get(`${config.BASE_URL}/users/${userId}/admin_permissions`)
    .then((response) => {
      dispatch(adminPermissionSuccess(response.data.admin_permissions));
    })
    .catch((error) => {
      dispatch(getError(error));
    });
};

export const whiteLableling = (companyId) => (dispatch) => {
  apiCall.get(`${config.BASE_URL}/companies/${companyId}`)
    .then((response) => {
      dispatch(whiteLablelingSuccess(response.data.company));
    })
    .catch((error) => {
      dispatch(getError(error));
    });
};

export const featureEnabled = (userId, featureName) => (dispatch) => {
  apiCall.get(`${config.BASE_URL}/users/${userId}/features/${featureName}`)
    .then((response) => {
      dispatch(featureEnabledSuccess({ [featureName]: response.data }));
    })
    .catch((error) => {
      dispatch(getError(error));
    });
};

export const fetchUserDetails = () => (dispatch) => {
  return apiCall.get(`${config.BASE_URL}/user_info`)
    .then((response) => {
      dispatch(adminPermission(response.data.user.id));
      dispatch(whiteLableling(response.data.user.company.id));
      dispatch(featureEnabled(response.data.user.id, 'cant_buy_credits'));
      dispatch(fetchUserDetailsSuccess(response.data.user));
      return response.data.user
    })
    .catch((error) => {});
};

export const fetchDocuments = (quoteId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/quotes/${quoteId}/documents`)
    .then((response) => response)
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const fetchQuestions = (quoteId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/quotes/${quoteId}/questions`)
    .then((response) => response)
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const emptyCurrentState = (actionType, payload) => (dispatch) => {
  dispatch(emptyCurrentStateSuccess(actionType, payload));
};

export const showToasterMessages = (type, message) => (dispatch) => {
  dispatch({
    type,
    payload: message,
  });
};

export const toggleSuppliersStatus = (companyId, payload) => (dispatch) => (
  apiCall.put(`${config.BASE_URL}/companies/${companyId}`, payload)
    .then((response) => {
      dispatch(supplierStatusSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const validateParticipant = (payload) => (dispatch) => (
  apiCall.post(`${config.BASE_URL}/users/validate_participant`, payload)
    .then((response) => response)
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const updateSummaryHeader = (type, payload) => (dispatch) => {
  dispatch({
    type,
    payload,
  });
};

export const fetchSandpitParticipants = () => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/sandpit_participants`)
    .then((response) => {
      dispatch(sandpitParticipantsSuccess(response.data.sandpit_participants));
    })
    .catch((error) => {
      dispatch(getError(error));
    })
);
export const setListingParameters = (type, payload) => (dispatch) => {
  dispatch({
    type,
    payload,
  });
};

export const changeEventState = (eventId, state, message='') => (dispatch) => (
  apiCall.put(`${config.BASE_URL}/events/${eventId}`, { event: { current_state: state, return_event_details: true } })
    .then((response) => {
      dispatch(changeStateSuccess(response.data));
      if (state === 'reopen' && message != '') {
        dispatch(showSuccessMessage(message));
      }
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const removeEvent = (eventId, state) => (dispatch) => (
  apiCall.remove(`${config.BASE_URL}/events/${eventId}`, { event: { current_state: state, return_event_details: true } })
    .then((response) => {
      dispatch(mutatedEvents(eventId));
      return response;
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);

export const fetchDocumentTemplate = (companyId) => (dispatch) => (
  apiCall.get(`${config.BASE_URL}/companies/${companyId}/document_templates`)
    .then((response) => {
      dispatch(fetchDocumentTemplateSuccess(response.data.document_templates));
    })
    .catch((error) => {
      dispatch(getError(error));
      return error.response;
    })
);
