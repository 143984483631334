/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { HostPropType, ParticipantPropType } from "@/common-prop-types";
import TabContent, { ParticipantQuickSelect, DocumentSelect, ParticipantList, ParticipantTable } from "./TabContent";
import SendMessageModal from "./index";
import { ModalHeader, TableHeader } from "./Header";
import Tabs from "./Tabs";
import Footer from "./Footer";
import MessageInput from "./MessageInput";
import ModalBody from "./ModalBody";

const CommonPropsTypes = {
  /**
   * check if host tab is selected
   *
   * @type {boolean}
   */
  isHostTabSelected: PropTypes.bool.isRequired,

  /**
   * marketDojo is to check if modal is opeaned in marketDojo or simDojo
   *
   *  @type {Boolean}
   */
  marketDojo: PropTypes.bool.isRequired,

  /**
   * list of hosts
   *
   * @type {[{}]}
   */
  hosts: PropTypes.arrayOf(HostPropType),
};

const MessagePropTypes = {
  /**
   * message tobe displayed in input
   *
   * @type {string}
   */
  message: PropTypes.string.isRequired,

  /**
   * function to handle change in input
   *
   * @type {string}
   */
  handleMessageChange: PropTypes.func.isRequired,
};

export const DocumentSelectPropTypes = {
  /**
   * list of documents tobe selected
   *
   * @type {{text: string, id: number}[] | []}
   */
  documentsToSelect: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string.isRequired, id: PropTypes.number.isRequired })
  ),

  /**
   * change handler for documents select input
   *
   * @type {() => void}
   */
  handleDocumentSelect: PropTypes.func.isRequired,

  /**
   * list of all the selected document's ids
   *
   * @type {Number[]}
   */
  selectedDocumentIds: PropTypes.array.isRequired,
};

const ParticipantsPropTypes = {
  /**
   * list of selected participants
   *
   * @type {Number[]}
   */
  selectedParticipantIds: PropTypes.arrayOf(PropTypes.number.isRequired),

  /**
   * change handle for participants
   *
   * @type {()=> void}
   */
  handleParticipantChange: PropTypes.func.isRequired,
};

/**
 * PropTypes for ParticipantQuickSelect
 *
 * @see ParticipantQuickSelect
 */
export const QuickSelectPropTypes = {
  /**
   * Value of quick select checkbox.
   *
   * @type {string[] | undefined}
   */
  quickSelect: PropTypes.arrayOf(PropTypes.string.isRequired),

  /**
   * change handler for quickselect checkboxes
   *
   * @type {() => void}
   */
  handleQuickSelect: PropTypes.func.isRequired,
};

/**
 * PropTypes for SendMessageModal
 *
 * @see SendMessageModal
 */
export const SendMessageModalPropTypes = {
  /**
   * This will be an array of all the documnets available to
   * select for host.
   *
   * @type {Array | undefined}
   */
  documents: PropTypes.array,

  marketDojo: CommonPropsTypes.marketDojo,

  /**
   * Check if user has permissions to edit the event
   *
   * @type {Boolean | undefined}
   */
  hasPermissionToEdit: PropTypes.bool,

  /**
   * Array of Ids of participants which are already selected.
   *
   * @type {Number[] | undefined}
   */
  selectedParticipants: PropTypes.arrayOf(PropTypes.number),

  /**
   * setter function to change selected participants.
   *
   * @type {() => void | undefined}
   */
  setSelectedParticipants: PropTypes.func,

  /**
   * Id to uniquily identify modal
   *
   * @type {string}
   */
  htmlId: PropTypes.string.isRequired,
};

/**
 * PropTypes for ModalHeader
 *
 * @see ModalHeader
 */
export const ModalHeaderPropTypes = {
  /**
   * Title of the modal
   *
   * @type {string}
   */
  eventName: PropTypes.string.isRequired,
};

/**
 * PropTypes for TableHeader
 *
 * @see TableHeader
 */
export const TableHeaderPropTypes = {
  isHostTab: CommonPropsTypes.isHostTabSelected,
};

/**
 * PropTypes for Tabs
 *
 * @see Tabs
 */
export const TabsPropTypes = {
  isHostTabSelected: CommonPropsTypes.isHostTabSelected,

  /**
   * set selected tab
   *
   * @type {()=> void}
   */
  selectTabs: PropTypes.func.isRequired,
};

/**
 * PropTypes for Footer
 *
 * @see Footer
 */
export const FooterPropTypes = {
  /**
   * This is to check if "Send Message" button is disabled
   *
   * @type {boolean}
   */
  isSendButtonDisabled: PropTypes.bool.isRequired,

  /**
   * This is to check if event needs to re-open before sending message.
   * If checkToReopenEvent is true, reopenEventConfirmModal will open.
   *
   * @type {boolean}
   */
  checkToReopenEvent: PropTypes.bool.isRequired,

  isHostTabSelected: CommonPropsTypes.isHostTabSelected,

  hosts: CommonPropsTypes.hosts,

  /**
   * function tobe invoked on click of "Send Message" button
   *
   * @type {() => void}
   */
  sendMessage: PropTypes.func.isRequired,
};

/**
 * PropTypes for MessageInput
 *
 * @see MessageInput
 */
export const MessageInputProps = {
  /**
   * Label for message input
   *
   * @type {string}
   */
  label: PropTypes.string.isRequired,

  ...MessagePropTypes,
};

/**
 * PropTypes for ModalBody
 *
 * @see ModalBody
 */
export const ModalBodyPropTypes = {
  marketDojo: CommonPropsTypes.marketDojo,

  isHostTabSelected: CommonPropsTypes.isHostTabSelected,

  ...MessagePropTypes,

  ...DocumentSelectPropTypes,

  ...QuickSelectPropTypes,

  ...ParticipantsPropTypes,

  hosts: CommonPropsTypes.hosts,
};

/**
 * PropTypes for ParticipantQuickSelect
 *
 * @see ParticipantQuickSelect
 */
export const ParticipantQuickSelectPropTypes = {
  isHostTab: CommonPropsTypes.isHostTabSelected,

  ...QuickSelectPropTypes,
};

/**
 * PropTypes for ParticipantTable
 *
 * @see ParticipantTable
 */
export const ParticipantTablePropTypes = {
  isHostTab: CommonPropsTypes.isHostTabSelected,

  hosts: CommonPropsTypes.hosts,

  ...ParticipantsPropTypes,
};

/**
 * PropTypes for TabContent
 *
 * @see TabContent
 */
export const TabContentPropTypes = {
  ...ParticipantTablePropTypes,
  ...ParticipantQuickSelectPropTypes,
  ...DocumentSelectPropTypes,
  hosts: CommonPropsTypes.hosts,
};

/**
 * PropTypes for ParticipantList
 *
 * @see ParticipantList
 */
export const ParticipantListPropTypes = {
  ...ParticipantTablePropTypes,

  /**
   * participant to be shown in the list
   *
   * @type {{ id: number, user_id: number, accepted: boolean, participating: boolean, display_name: string, status: string }}
   */
  participant: ParticipantPropType,

  hosts: CommonPropsTypes.hosts,
};
