import React, { Component } from "react";
import Select2 from "react-select2-wrapper";

import Tooltip from "../../../common/Tooltip";
export class ContractsPermissionTab extends Component {
  showLoader = (e, key, type) => {
    let loader_ref = this[`loader_${type}_${key}`];
    let tick_ref = this[`tick_${type}_${key}`];
    const { optionsForSelect } = this.props;
    if (key != "0") {
      loader_ref.classList.remove("hidden");
    }
    optionsForSelect(e, key, type);
    if (key != "0") {
      setTimeout(() => {
        loader_ref.classList.add("hidden");
        tick_ref.classList.remove("loader-hide");
      }, 200);
      setTimeout(() => {
        tick_ref.classList.add("loader-hide");
      }, 300);
    }
  };
  render() {
    const {
      showAdminPermission,
      contractDojo,
      translations,
      permissionsState,
      permissionAction,
      permissionResourceType,
      permissionResourceId,
      groupsForSelect,
      objectChanged,
      userPermission,
      resourceSelectOptions,
      usersForSelect,
      companyId,
      closeModal,
      optionsForSelect,
      savePermission,
      showAlert,
      contractsForSelect,
      currentUser,
    } = this.props;
    return (
      <div
        className={`tab-pane ${contractDojo ? "" : "fade"} ${showAdminPermission ? "" : ""}`}
        id="contractPermission"
        role="tabpanel"
        aria-labelledby="contractPermission-tab"
        data-backdrop={!contractDojo}
      >
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="modal-content-section">
                <div className="section-title">
                  <h4>{translations.contractPermissions}</h4>
                </div>
                {/* Contract Permissions Select */}
                <div className="form-group">
                  <div className="row">
                    {Object.entries(permissionsState).map(([key, cpHash]) => {
                      let permittedToEdit = this.permittedToEditPermission(cpHash, key);
                      return permittedToEdit ? (
                        <React.Fragment key={key}>
                          <div className="col-md-3 col-sm-3" id={`action-select-container-${key}`}>
                            <Tooltip
                              title={permissionAction.title}
                              body={permissionAction.body}
                              currentUser={currentUser}
                            />
                            <label>{translations.userCan}</label>
                            <div className="permission-select-container display-settings-select-container">
                              <Select2
                                data={[
                                  {
                                    text: translations.view,
                                    id: "view",
                                  },
                                  {
                                    text: translations.edit,
                                    id: "edit",
                                  },
                                ]}
                                value={cpHash.permissionSelectedOptionContract}
                                options={{
                                  minimumResultsForSearch: -1,
                                }}
                                onSelect={(e) => this.showLoader(e, key, "permission")}
                                className="action-select"
                                disabled={!permittedToEdit}
                              />
                            </div>
                            <span className="hidden up-loader" ref={(ref) => (this[`loader_permission_${key}`] = ref)}>
                              <i className="fa fa-spinner fa-white fa-spin" />
                            </span>
                            <span
                              className="loader-hide up-loader"
                              ref={(ref) => (this[`tick_permission_${key}`] = ref)}
                            >
                              <i alt="Yes" className="fa fa-check text-success" />
                            </span>
                          </div>
                          <div className="col-md-3 col-sm-3" id={`resource-type-select-container-${key}`}>
                            <Tooltip
                              title={permissionResourceType.title}
                              body={permissionResourceType.body}
                              currentUser={currentUser}
                            />
                            <label>{translations.contractsIf}</label>
                            <div className="permission-select-container display-settings-select-container">
                              <Select2
                                data={resourceSelectOptions()}
                                value={cpHash.resourceTypeSelectedOptionContract}
                                options={{
                                  minimumResultsForSearch: -1,
                                }}
                                onSelect={(e) => this.showLoader(e, key, "resource")}
                                className="resource-type-select"
                                disabled={!permittedToEdit}
                              />
                            </div>
                            <span className="hidden up-loader" ref={(ref) => (this[`loader_resource_${key}`] = ref)}>
                              <i className="fa fa-spinner fa-white fa-spin" />
                            </span>
                            <span className="loader-hide up-loader" ref={(ref) => (this[`tick_resource_${key}`] = ref)}>
                              <i alt="Yes" className="fa fa-check text-success" />
                            </span>
                          </div>
                          <div className="col-md-4 col-sm-4" id={`resource-select-container-${key}`}>
                            <Tooltip
                              title={permissionResourceId.title}
                              body={permissionResourceId.body}
                              currentUser={currentUser}
                            />
                            <label>{translations.is}</label>
                            <div className="permission-select-container display-settings-select-container">
                              {/* We have added three selects intentionally for performance issues */}
                              {cpHash.resourceTypeSelectedOptionContract === "User" && (
                                <Select2
                                  data={usersForSelect}
                                  value={cpHash.resourceIdSelectedOptionContract}
                                  onSelect={(e) => this.showLoader(e, key, "resourceId")}
                                  className="user-resource-select"
                                  disabled={!permittedToEdit}
                                />
                              )}
                              {cpHash.resourceTypeSelectedOptionContract === "Group" && (
                                <Select2
                                  data={groupsForSelect}
                                  value={cpHash.resourceIdSelectedOptionContract}
                                  onSelect={(e) => this.showLoader(e, key, "resourceId")}
                                  className="group-resource-select"
                                  disabled={!permittedToEdit}
                                />
                              )}
                              {cpHash.resourceTypeSelectedOptionContract === "Contract" && (
                                <Select2
                                  data={contractsForSelect}
                                  value={cpHash.resourceIdSelectedOptionContract}
                                  onSelect={(e) => this.showLoader(e, key, "resourceId")}
                                  className="contract-resource-select"
                                  disabled={!permittedToEdit}
                                />
                              )}
                            </div>
                            <span className="hidden up-loader" ref={(ref) => (this[`loader_resourceId_${key}`] = ref)}>
                              <i className="fa fa-spinner fa-white fa-spin" />
                            </span>
                            <span
                              className="loader-hide up-loader"
                              ref={(ref) => (this[`tick_resourceId_${key}`] = ref)}
                            >
                              <i alt="Yes" className="fa fa-check text-success" />
                            </span>
                          </div>
                          <div className="col-md-2 col-sm-2 text-left" id="action-buttons">
                            <label className="display-block hidden-xs">&nbsp;</label>
                            {key === "0" ? (
                              <button
                                className="btn btn-default btn-icon m-l10"
                                id="create-button"
                                type="button"
                                title={translations.add}
                                onClick={() => savePermission(key)}
                                disabled={cpHash.resourceIdSelectedOptionContract === 0}
                              >
                                <i className="fa fa-plus green-color" aria-hidden="true" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-default btn-icon m-l10"
                                id="delete-button"
                                type="button"
                                title={translations.delete}
                                data-toggle="modal"
                                data-target="#contractPermissionConfirmModal"
                                onClick={() => showAlert(true, userPermission.id, key)}
                              >
                                <i className="fa fa-trash-o red-color" aria-hidden="true" />
                              </button>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="block-content text-left">
            {userPermission.user.company_id !== companyId ? (
              <div className="non_licensed_user_rule_limit">{`* ${translations.ruleCannotBeForExternalUser}`}</div>
            ) : (
              ""
            )}
            {!userPermission.user.cat_dojo_license ? (
              <div className="non_licensed_user_rule_limit">{translations.nonLicensedUserRuleLimit}</div>
            ) : (
              ""
            )}
          </div>
          <div className="block-content pull-right">
            <button className="btn btn-primary" data-dismiss="modal" onClick={() => closeModal("ok")}>
              {translations.ok}
            </button>
          </div>
        </div>
      </div>
    );
  }

  permittedToEditPermission(cpHash, key) {
    if (key === "0" || cpHash.resourceIdSelectedOptionContract === 0) {
      return true;
    }
    const { currentUserCanEditPermissions } = this.props;
    if (currentUserCanEditPermissions) {
      return true;
    }
    return false;
  }
}
