import React, { Component } from 'react';
import { BarLoader } from 'react-spinners';
import  PropTypes  from 'prop-types';

/**
 * @typedef {PropTypes.InferProps<LoaderMessage.propTypes>} Props
 * @extends Component<Props, {}>
 */
class LoaderMessage extends Component {
  static propTypes = {
    /**
     * The text that will be displayed above the loading bar.
     */
    loaderText: PropTypes.string.isRequired,
  }

  render() {
    const { loaderText } = this.props;

    return (
      <div className='custom-react-loader'>
        {loaderText && <span>{loaderText}</span>}
        <BarLoader
          color='#c40000'
          cssOverride={{display: 'block'}}
          loading
        />
      </div>
    );
  }
}

export default LoaderMessage;
