import React from "react";
import PropTypes from "prop-types";

import InputDate from "./input-date";
import InputDecimal from "./input-decimal";
import InputPickList from "./input-pick-list";
import InputPrice from "./input-price";
import InputText from "./input-text";
import Alert from "../common/alert";

import { LineItem, LineItemComponent, Lot } from "@/common-prop-types";

import { useDispatch, useSelector } from "react-redux";
import { createBidLineItemComponent } from "@/actions/bidLineItemComponentActions";
import { setActiveLi } from "@/actions/lotActions";
import { componentType } from "../../../common";
import { useAffectedFormulas, useLineItemComponentError, useLineItemComponentWarning } from "@/selectors/lotSelectors";

/**
 * A map of functions that will get the value for each input type
 */
const getValueMap = {
  isPrice: (item) => item.price,
  isText: (item) => item.attribute_value,
  isPicklist: (item) => item.attribute_value,
  isDate: (item) => (item.date_value ? new Date(item.date_value) : null),
  isDecimal: (item) => (item.price !== null ? Number(item.price) : null),
};

/**
 * Get the input value from a bid line item component for the current type of
 * input.
 *
 * @param {any} bidLineItemComponent
 * @param {String} type The input type from `componentType`
 *
 * @returns {String|Date|Number|null}
 */
const getValue = (type, bidLineItemComponent) => {
  if (typeof bidLineItemComponent === "undefined") {
    return null;
  }

  if (typeof getValueMap[type] !== "undefined") {
    return getValueMap[type](bidLineItemComponent);
  }

  if (process.env.NODE_ENV !== "production") {
    throw new Error(`Invalid component type '${type}' could not get value`);
  }

  return null;
};

/**
 * Gets the current state of the input.
 *
 * @returns {'clean' | 'loading' | 'danger' | 'success'}
 */
const getState = (value, pending, error) => {
  if (error) {
    return "danger";
  }

  if (pending) {
    return "loading";
  }

  return value || value === 0 ? "success" : "clean";
};

/**
 * Map of input types to components. Each of the components must render an
 * input and call the `onChange` prop with there new value There are
 * `CommonInputProps` that each input must implement.
 */
const componentMap = {
  isDate: InputDate,
  isDecimal: InputDecimal,
  isPicklist: InputPickList,
  isPrice: InputPrice,
  isText: InputText,
};

/**
 * The labels for each of the inputs. The map key is a input type and the value
 * is a key in the translations object.
 */
const labelMap = {
  isDate: "select_date_and_time",
  isDecimal: "enter_number",
  isPicklist: "pick_option",
  isPrice: "used_price_uom",
  isText: "your_answer",
};

/**
 * Extracted hook to handel the changing of an input
 */
const useOnChange = ({ bidId, lineItem, lineItemComponent, previousValue }) => {
  const dispatch = useDispatch();
  const affectedFormulas = useAffectedFormulas(lineItemComponent.tag);
  const type = componentType(lineItemComponent.lot_component_type);

  const hasValue = (value) => value !== null && value !== undefined && value !== "";

  return (value) => {
    const blicValue = getValue(type, value[0]);
    if (!hasValue(blicValue) && !hasValue(previousValue)) return;

    dispatch(setActiveLi({ li: lineItem }));

    const payload = {
      bid_line_item_component: {
        values: value.map((item) => ({ ...item, line_item_component_id: lineItemComponent.id })),
        formula_cell_ids: affectedFormulas.map((formula) => formula.id),
      },
    };

    const lineItemComponentId = lineItemComponent.id;
    const lineItemId = lineItemComponent.line_item_id;
    const lotId = lineItem.lot_id;
    dispatch(createBidLineItemComponent({ bidId, lotId, payload, lineItemComponentId, lineItemId }));
  };
};

export const CommonInputProps = {
  /**
   * The value that will be displayed in the input
   */
  value: PropTypes.any.isRequired,
  /**
   * The callback function that will be called when a input value is changed
   *
   * @type {(newValue: any) => void}
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The callback function that will be called when the input focuses
   *
   * @type {() => void}
   */
  onFocus: PropTypes.func.isRequired,
  /**
   * TODO(ade): Sort this prop out should really put this in into is on reducer
   */
  translations: PropTypes.any,
  /**
   * The line item component that this input is for
   */
  lineItemComponent: PropTypes.shape(LineItemComponent),
  /**
   * The line item that this input is for
   */
  lineItem: PropTypes.shape(LineItem),
  /**
   * The lot this component is part of
   */
  lot: PropTypes.shape(Lot),
};

const PlaceBidFormComponentPropTypes = {
  /**
   * The line item component for this input
   */
  lineItemComponent: PropTypes.shape(LineItemComponent),
  /**
   * The line item this is part of.
   *
   * Currently this is only there to set the active line item, however it would
   * be good to get rid of this in favor or lot validation when a change is
   * made.
   */
  lineItem: PropTypes.shape(LineItem),
  /**
   * TODO(ade): Sort this prop out should really put this in into is on reducer
   */
  translations: PropTypes.any,
  /**
   * TODO(ade): Sort out proptypes for this
   */
  bidLineItemComponent: PropTypes.any,
  /**
   * The lot this component is part of
   */
  lot: PropTypes.shape(Lot),
  /**
   * The current bid this is rendering for. If this is empty then this
   * component will not render anything. This can happen just after canceling a
   * bid.
   */
  bid: PropTypes.shape({
    /**
     * The ID of the bid.
     */
    id: PropTypes.number.isRequired,
  }),
  /**
   * If you are displaying the details of the bid component
   */
  isAllDetailView: PropTypes.bool,
  /**
   * The rank to be displayed
   */
  rank: PropTypes.any,
};

const getAlertAttributes = (lineItemComponentError, lineItemComponentWarning) => {
  return {
    message: lineItemComponentError || lineItemComponentWarning,
    type: lineItemComponentError ? "danger" : "info",
    className: lineItemComponentError && "text-city",
  };
};

export const PlaceBidFormComponent = ({
  lineItemComponent,
  lineItem,
  bidLineItemComponent,
  bid,
  lot,
  rank,
  translations,
  isAllDetailView,
}) => {
  const lotId = lot.id;
  const bidId = bid?.id;

  const lineItemComponentError = useLineItemComponentError(lotId, lineItemComponent.id);
  const lineItemComponentWarning = useLineItemComponentWarning(lotId, lineItemComponent.id);
  const isLineItemComponentPending = useSelector(
    (state) => state.lotReducers.lineItemComponentPendingState?.[lotId]?.[lineItemComponent.id]
  );
  const type = componentType(lineItemComponent.lot_component_type);
  const value = getValue(type, bidLineItemComponent);
  const onChange = useOnChange({ bidId, lineItem, lineItemComponent, previousValue: value });

  // Return an empty component if we have not got one defined for the provided
  // lineItemComponent type or we do not have a bid that we can link to.
  const Component = componentMap[type];
  if (typeof Component === "undefined" || !bidId) {
    return null;
  }

  const state = getState(value, isLineItemComponentPending, lineItemComponentError);

  // Only pass the relevant prop to the price component as it needs more props.
  // Because components only render when there props change we are giving the
  // other types of inputs every chance to prevent a re render.
  const props = { onChange, value, translations, state, lineItemComponent, lineItem, lot };
  if (type === "isPrice") {
    Object.assign(props, { bidLineItemComponent, isAllDetailView, rank });
  }

  return (
    <div className={`input simple-bid-input bid-input font-s13 text-black-op is-${state}`}>
      {Boolean(translations[labelMap[type]]) && <label>{translations[labelMap[type]]}</label>}
      {React.createElement(componentMap[type], props)}
      <Alert {...getAlertAttributes(lineItemComponentError, lineItemComponentWarning)} />
    </div>
  );
};

PlaceBidFormComponent.propTypes = PlaceBidFormComponentPropTypes;

export default PlaceBidFormComponent;
