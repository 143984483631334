import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Input from "@/cl/input/react-form";
import Box from "@/cl/box/box";

import { useDispatch, useSelector } from "react-redux";
import { listCustomFields } from "@/actions/customField";
import { Form, useListAttribute } from "@adeattwood/react-form";

import { t } from "@/i18n";
import AddCustomFieldButton from "./add-custom-field-button";
import TableBody from "./table-body";
import TableHead from "./table-head";
import { CompanyIdContextProvider } from "./company-id-context";
import { useValidator } from "./validator";
import { Tag } from "./tag";

export const AuthPropType = {
  can_edit_srm: PropTypes.bool.isRequired,
  can_edit_sim: PropTypes.bool.isRequired,
  can_edit_contracts: PropTypes.bool.isRequired,
};

function SearchInput() {
  const placeholder = t("centralised_custom_fields.search");
  const label = t("centralised_custom_fields.search_label");

  return <Input type="search" attribute="search" placeholder={placeholder} aria-label={label} />;
}

function CustomFieldCount() {
  const { value } = useListAttribute("customFields");
  return (
    <Tag pill brand="primary">
      {t("centralised_custom_fields.custom_fields_count", { count: value.length })}
    </Tag>
  );
}

function Container({ children }) {
  return (
    <div className="col-sm-12 my-3">
      <div className={`mx-auto p-3`}>
        <Box flex justifyContentBetween>
          {children}
        </Box>
      </div>
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node,
};

export function CustomColumnsForm({ auth }) {
  const customFieldValidator = useValidator();

  // A user can edit the item if they have edit permission on any of the
  // modules
  const canEdit = Object.values(auth).some((item) => item === true);

  return (
    <Form initialValues={{ customFields: [] }} validator={customFieldValidator}>
      <Container>
        <Box flex alignItemsCenter>
          <CustomFieldCount />
        </Box>
        <Box flex alignItemsCenter>
          <div className="me-3" style={{ minWidth: "280px" }}>
            <SearchInput />
          </div>
          {canEdit && <AddCustomFieldButton />}
        </Box>
      </Container>
      <div className="col-sm-12" style={{ overflowX: "auto" }}>
        <div className="table-wrapper m-b0 m-auto" style={{ tableLayout: "auto", minWidth: "1400px" }}>
          <table className="table is-striped">
            <TableHead auth={auth} />
            <TableBody auth={auth} />
          </table>
        </div>
      </div>
      <Container>
        <Box />
        <Box>{canEdit && <AddCustomFieldButton />}</Box>
      </Container>
    </Form>
  );
}

CustomColumnsForm.propTypes = {
  /**
   * The current users access on the modules
   */
  auth: PropTypes.shape(AuthPropType).isRequired,
};

export function CentralisedCustomFields({ companyId, auth }) {
  const dispatch = useDispatch();
  const allStatus = useSelector(({ customFieldsReducers }) => customFieldsReducers.statuses.all);
  useEffect(() => {
    if (typeof allStatus === "undefined") {
      dispatch(listCustomFields({ companyId }));
    }
  }, [allStatus, dispatch, companyId]);

  // We don't want to be rendering anything if we don't have any initial custom
  // fields.
  if (typeof allStatus === "undefined") {
    return null;
  }

  return (
    <CompanyIdContextProvider value={companyId}>
      <div className="row two-block-box md-bs5">
        <CustomColumnsForm auth={auth} />
      </div>
    </CompanyIdContextProvider>
  );
}

CentralisedCustomFields.propTypes = {
  /**
   * The company id for the custom fields the component is rendering
   */
  companyId: PropTypes.number.isRequired,
  /**
   * The current users access on the modules
   */
  auth: PropTypes.shape(AuthPropType).isRequired,
};

export default CentralisedCustomFields;
