import React from "react";

import ListingPageConfiguration from "./ListingPageConfiguration";
import NewEditPageConfiguration from "./newEditPage/NewEditPageConfiguration";

class ContainerComponent extends React.Component {
  render() {
    const { contractIndexFields, companyId, translations, currentUserEditConfigPermission } = this.props;
    return (
      <div className="row two-block-box contract-configuration">
        <ListingPageConfiguration
          contractIndexFields={contractIndexFields}
          companyId={companyId}
          translations={translations}
          currentUserEditConfigPermission={_resolvePermission(currentUserEditConfigPermission)}
        />
        <NewEditPageConfiguration
          {...this.props}
          currentUserEditConfigPermission={_resolvePermission(currentUserEditConfigPermission)}
        />
      </div>
    );
  }
}

const _resolvePermission = (permission) => {
  // view
  if (permission === null || typeof permission == "undefined") {
    return true;
  }

  return permission;
};

export default ContainerComponent;
