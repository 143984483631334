import React, { useState } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import EventLotComponent from "../lots/EventLotComponent";
import JapaneseProxyBid from "../lots/host_rfq_screen/JapaneseProxyBid";
import LotLevelProxyBid from "../lots/host_rfq_screen/LotLevelProxyBid";
import { remainingTime } from "../eventCommon";
import { useDispatch } from "react-redux";
import { dispatchModalSubmitRequestCompleted, resetCommonModal } from "../../../hoc/modalHOC/modalHOC.duck";
import LotRejectButton from "../lots/participantScreenComponents/LotRejectButton";
import PlaceBidButtonGroup from "../lots/participantScreenComponents/PlaceBidButtonGroup";
import { cancelBid } from "../../../actions/bidActions";
import { getTabInfo } from "./LotBidComponent";

export const ModalLotTitle = ({ name = "", onClose = () => {}, suffix = "" }) => {
  return (
    <div className="block-header bg-primary-default">
      <ul className="block-options">
        <li>
          <button onClick={onClose} type="button">
            <i className="ion-close"></i>
          </button>
        </li>
      </ul>
      <h3 className="block-title">
        {suffix}: <span title={name}>{name}</span>
      </h3>
    </div>
  );
};

const ProxyBidButtonAndModal = (props) => {
  const {
    translations,
    setIsOpen,
    lot,
    isOpen,
    permittedToEdit,
    onClickPlaceBid,
    activeTab,
    event,
    lineItems = [],
    bid,
    bidRound,
    latestBid,
    LBids,
    participant,
    isAuction,
  } = props;

  const { isJapaneseAuctionTab, isLotLevelAuctionTab: isLotLevelAuction } = getTabInfo(event, activeTab);

  const [enableSubmit, setEnableSubmit] = useState(false);
  const dispatch = useDispatch();
  let modalBody;
  const lc = lineItems.find((lineItem) => lineItem.lot_id == lot.id);
  const declined = bid && bid.decline_bid ? true : false;
  let showProxyButton = lc && !lc.is_calculation && !declined;
  const onRequestClose = () => {
    setIsOpen(false);
  };
  if (isJapaneseAuctionTab && bidRound > 1) {
    const eligbleBidRound = latestBid && latestBid.bid_round ? latestBid.bid_round + 1 : null;

    if (!latestBid || eligbleBidRound !== bidRound) showProxyButton = false;
  }
  advancedLots.proxyRank = true;
  const proxyButtonModal = () => {
    if (isJapaneseAuctionTab) {
      modalBody = <JapaneseProxyBid {...props} />;
      remainingTime((remainTime) => {
        if (remainTime < 0) {
          onRequestClose();
          setTimeout(() => {
            dispatch(resetCommonModal());
          }, 1000);
          dispatch(dispatchModalSubmitRequestCompleted());
        }
      });
    } else if (isLotLevelAuction) {
      modalBody = <LotLevelProxyBid {...props} onRequestClose={onRequestClose} />;
    } else {
      const showAllDetailsFunc = [];
      const lotBids = LBids.filter((bid) => bid.lot_id === lot.id && bid.user_id === participant.user_id);

      modalBody = (
        <EventLotComponent
          {...props}
          hideTopButton={true}
          showAllDetailsFunc={showAllDetailsFunc}
          lBids={lotBids}
          setEnableSubmitForProxyBidModal={setEnableSubmit}
        />
      );
    }

    const isShowingLotRejectButton = (!latestBid && isAuction) || !isAuction;

    return (
      <>
        {
          <a
            title={translations.proxy_bid}
            className={classNames({
              "btn btn-height btn-primary": permittedToEdit,
            })}
            onClick={() => onClickPlaceBid()}
          >
            {translations.proxy}
          </a>
        }
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          className="modal-content col-md-8 col-sm-8 custom-field-popup"
          appElement={document.getElementById("advanced-lots-body")}
        >
          <div className="block block-transparent remove-margin-b">
            <ModalLotTitle suffix={translations.lot_name} name={lot.name} onClose={onRequestClose} />
          </div>
          {modalBody}
          <div className="pop-footer modal-footer">
            <div className="col-md-12">
              {isShowingLotRejectButton && (
                <span className="pull-left">
                  <LotRejectButton
                    translations={translations}
                    lot={lot}
                    currentUser={{
                      id: props.participant.user_id,
                    }}
                    afterSuccess={onRequestClose}
                    event={props.event}
                    openModal={props.openModal}
                    lotState={props.lotState}
                    setLotState={props.setLotState}
                    isProxy={true}
                    hostId={props.currentUser.id}
                  />
                </span>
              )}

              <PlaceBidButtonGroup
                lotState={props.lotState}
                translations={props.translations}
                enableSubmit={enableSubmit}
                openModal={props.openModal}
                lot={props.lot}
                onCancelBid={onCancelBid.bind({ ...props, dispatch })}
                hideAlert={true}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  };

  return showProxyButton && proxyButtonModal();
};

const onCancelBid = function () {
  const { bid, lot, dispatch, setLotState, setIsOpen } = this;
  const bidId = bid && bid.id ? bid.id : null;
  dispatch(cancelBid(lot.id, bidId)).then(() => {
    setLotState({
      isPlaceBidActive: false,
      isAllDetailView: false,
    });
  });
  setIsOpen(false);
  advancedLots.openSuccessModal = false;
};

export default ProxyBidButtonAndModal;
