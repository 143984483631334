import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import UnitSetModal from "./UnitSetModal";
import { createUnitSet, updateUnitSet } from "../../../../actions/unitSetActions";
import { lotAutomationEnforcedAndNotSandpit } from "../lotCommon";
import { useSelector } from "react-redux";

export class UnitSetButtons extends Component {
  defaultState(modalIsOpen) {
    return {
      modalIsOpen: modalIsOpen,
      unitSetName: "",
      defaultUOM: { name: "" },
      unitOfMeasures: [],
      unitSetId: undefined,
    };
  }

  editUnitSetState(unitSet, chosenUnitSetUoms) {
    const defaultUOM = chosenUnitSetUoms.filter((uom) => uom.default)[0];
    const uoms = chosenUnitSetUoms.filter((uom) => !uom.default);
    return {
      modalIsOpen: true,
      unitSetName: unitSet.name,
      unitOfMeasures: uoms,
      defaultUOM: defaultUOM,
      unitSetId: unitSet.id,
    };
  }

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.defaultState = this.defaultState.bind(this);
    this.handleUnitSetNameChange = this.handleUnitSetNameChange.bind(this);
    this.addUOM = this.addUOM.bind(this);
    this.handleUOMNameChange = this.handleUOMNameChange.bind(this);
    this.handleUOMRatioChange = this.handleUOMRatioChange.bind(this);
    this.removeUOM = this.removeUOM.bind(this);
    this.handleDefaultUOMNameChange = this.handleDefaultUOMNameChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = this.defaultState(false);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal(unitSet, chosenUnitSetUoms) {
    const state = unitSet ? this.editUnitSetState(unitSet, chosenUnitSetUoms) : this.defaultState(true);
    this.setState(state);
  }

  handleUnitSetNameChange(event) {
    this.setState({ unitSetName: event.target.value });
  }

  addUOM() {
    const { unitOfMeasures } = this.state;
    unitOfMeasures.push({ name: "", ratio: "" });
    this.setState({ unitOfMeasures });
  }

  handleUOMNameChange(event, index) {
    const { unitOfMeasures } = this.state;
    unitOfMeasures[index].name = event.target.value;
    this.setState({ unitOfMeasures });
  }

  handleUOMRatioChange(event, index) {
    const { unitOfMeasures } = this.state;
    unitOfMeasures[index].ratio = event.target.value;
    this.setState({ unitOfMeasures });
  }

  handleDefaultUOMNameChange(event) {
    this.setState({ defaultUOM: { name: event.target.value } });
  }

  removeUOM(index) {
    const { unitOfMeasures } = this.state;
    unitOfMeasures[index]._destroy = true;
    this.setState({ unitOfMeasures });
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    const { currentUser, handleUnitSetChange } = this.props;
    const { defaultUOM, unitOfMeasures, unitSetId, unitSetName } = this.state;
    const payload = {
      unit_set: {
        resource_type: "User",
        resource_id: currentUser.id,
        name: unitSetName,
        unit_of_measures_attributes: {
          0: {
            name: defaultUOM.name,
            default: true,
            ratio: 1,
            resource_type: "User",
            resource_id: currentUser.id,
            id: defaultUOM.id,
          },
        },
      },
    };
    unitOfMeasures.map(function (uom, i) {
      let p1 = {
        name: uom.name,
        ratio: uom.ratio,
        resource_type: "User",
        resource_id: currentUser.id,
        _destroy: uom._destroy,
        id: uom.id,
      };
      payload.unit_set.unit_of_measures_attributes[i + 1] = p1;
    });
    let result = undefined;
    if (unitSetId) {
      result = await this.props.updateUnitSet(payload, unitSetId);
    } else {
      result = await this.props.createUnitSet(payload);
    }
    if (result) {
      if (unitSetId === undefined) {
        handleUnitSetChange({ target: { value: result } });
      }
      this.closeModal();
    }
  }

  render() {
    const { classFromEventState, translations, currentUser, unitSet, chosenUnitSetUoms } = this.props;

    return (
      <>
        {unitSet && unitSet.resource_id === currentUser.id && unitSet.resource_type === "User" && (
          <span id="edit_unitset_link">
            <ModalTriggerButton
              id="editUnitSet"
              className={classFromEventState}
              handleClick={() => this.openModal(unitSet, chosenUnitSetUoms)}
              label={translations.edit_unit_set}
              edit
            />
          </span>
        )}
        <ModalTriggerButton
          className={classFromEventState}
          handleClick={() => this.openModal(false)}
          label={translations.add_unit_set}
        />
        <UnitSetModal
          closeModal={this.closeModal}
          translations={translations}
          handleUnitSetNameChange={this.handleUnitSetNameChange}
          handleFormSubmit={this.handleFormSubmit}
          addUOM={this.addUOM}
          handleUOMNameChange={this.handleUOMNameChange}
          handleUOMRatioChange={this.handleUOMRatioChange}
          removeUOM={this.removeUOM}
          handleDefaultUOMNameChange={this.handleDefaultUOMNameChange}
          classFromEventState={classFromEventState}
          {...this.state}
        />
      </>
    );
  }
}

export const ModalTriggerButton = (props) => {
  const { id = "", className, handleClick, label, edit } = props;
  const lAEnforcedAndNotSandpit = useSelector(lotAutomationEnforcedAndNotSandpit);
  return (
    <button
      id={id}
      type="button"
      className={`btn auto_width_btn m-b20 ${className} status-event sm-butt`}
      onClick={handleClick}
      disabled={lAEnforcedAndNotSandpit}
    >
      {<i className={`fa fa-${edit ? "pencil" : "plus"}`}></i>}&nbsp;&nbsp;
      {label}
    </button>
  );
};

ModalTriggerButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  edit: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  createUnitSet: (payload) => dispatch(createUnitSet(payload)),
  updateUnitSet: (payload, unitSetId) => dispatch(updateUnitSet(payload, unitSetId)),
});

export default connect(null, mapDispatchToProps)(UnitSetButtons);
