import { getCSRFParam, getCSRFToken } from "@/lib/contracts";
import React from "react";

/**
 * When placed in a form, pulls the CSRF token and name to be automatically included in the form data
 *
 * @example
 * <form>
 *   <CSRFToken />
 * </form>
 */
export const CSRFToken = () => {
  return <input type="hidden" name={getCSRFParam()} value={getCSRFToken()} />;
};
