import React from "react";

const LiDetailComponent = ({ translations, isPlaceBidActive }) => {
  const { currency, uom, quantity, host_price_per_uom, used_price_uom, your_answer, description } = translations;
  return !isPlaceBidActive ? (
    <ul className="lot-summary summary-panel lic-title listing-link">
      <li title={currency}>{currency}</li>
      <li title={uom}>{uom}</li>
      <li title={quantity}>{quantity}</li>
      <li title={host_price_per_uom}>{host_price_per_uom}</li>
      <li title={used_price_uom}>{used_price_uom}</li>
      <li title={your_answer}>{your_answer}</li>
      <li title={description}>{description}</li>
    </ul>
  ) : null;
};

export default LiDetailComponent;
