import { openBidModal } from "@/actions/lotActions";
import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmationModalId } from "../../lots/eventLotComponnet/ConfirmBid";
import useEvent from "../useEvent";
import useLot from "../useLot";
import useFieldStatus, { useValidLotValues } from "./useFieldStatus";

export const useLotEffect = (lot, bid, enableSubmit) => {
  const { lotBids } = useLot(lot, bid);
  const { validLicsForBlics, validLotBlics } = useValidLotValues(lot, bid);

  const dispatch = useDispatch();

  useEffect(() => {
    // Open participantsPlaceBidConfirmModal only when
    // participant places bid for first time for a lot
    const { openSuccessModal, finalRender } = window.advancedLots;

    const isSubsequentBid = lotBids.length > 1;
    const isFinalRenderLot = typeof openSuccessModal === "number" && openSuccessModal === lot.id && finalRender;
    const isLotCompletelyFilled = validLicsForBlics?.length === validLotBlics?.length;

    if (enableSubmit && isFinalRenderLot && !isSubsequentBid && isLotCompletelyFilled && lot.quantity) {
      dispatch(openBidModal(`#${confirmationModalId + lot.id}`));
      window.advancedLots.openSuccessModal = false;
      window.advancedLots.finalRender = false;
    }
  }, [dispatch, enableSubmit, lot, validLotBlics, lotBids, validLicsForBlics]);
};

const useEnableSubmit = (lot, bid, isPlaceBidActive, isLotLevelMonitor, bidDetailsAdded, isBidSubmitted, isInRange) => {
  const errors = useSelector(({ lotReducers }) => lotReducers.lotItemComponentErrors?.[lot.id]);
  const { event, isAuctionPending } = useEvent();
  const { areFieldsFilled } = useFieldStatus(lot, bid);

  const isSubmitButtonVisible = useMemo(
    () => isPlaceBidActive || isAuctionPending || event.event_category_rfq || (!!isLotLevelMonitor && !bidDetailsAdded),
    [bidDetailsAdded, event.event_category_rfq, isAuctionPending, isLotLevelMonitor, isPlaceBidActive]
  );

  const areFieldsValidToEnableSubmitButton = useMemo(
    () => areFieldsFilled && !Object.keys(errors || {}).length && isInRange,
    [areFieldsFilled, errors, isInRange]
  );

  const enableSubmit = useMemo(
    () => isSubmitButtonVisible && !isBidSubmitted && areFieldsValidToEnableSubmitButton,
    [areFieldsValidToEnableSubmitButton, isBidSubmitted, isSubmitButtonVisible]
  );

  return enableSubmit;
};

export default useEnableSubmit;
