import React from "react";
import PropTypes from "prop-types";

import Label from "../input/label";
import Feedback from "../input/feedback";
import { buildProps } from "../input";

export const SelectPropTypes = {
  /**
   * The name attribute of the input.
   */
  name: PropTypes.string.isRequired,
  /**
   * The id attribute of the input. If you don't supply one the name will be
   * used as the id attribute
   */
  id: PropTypes.string,
  /**
   * The label given to the input
   */
  label: PropTypes.string,
  /**
   * The error message that will be displayed under the input. If this is
   * provided then the input will be put into the error state
   */
  error: PropTypes.string,
  /**
   * Help info text that will be displayed under the input
   */
  help: PropTypes.string,
  /**
   * The options that will be available in the select.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

/**
 * The Market Dojo input component
 *
 * @type {React.FC<PropTypes.InferProps<typeof SelectPropTypes> & Omit<React.ComponentProps<"select">, "children"> & { options: Omit<React.ComponentProps<"option">, "children">[]  }>}
 */
export const Select = (props) => {
  const { name, label, error, help, options, ...inputProps } = props;
  const id = props.id || name;

  return (
    <>
      <Label {...{ id, label }} />
      <select {...buildProps({ inputProps, id, name, error, className: "form-select" })}>
        {options.map(({ label, value, ...props }) => (
          <option key={value} value={value} {...props}>
            {label}
          </option>
        ))}
      </select>
      <Feedback {...{ id, help, error }} />
    </>
  );
};

Select.propTypes = SelectPropTypes;

export default Select;
