import React, { Component } from "react";
import Select2 from "react-select2-wrapper";

import Tooltip from "../../../common/Tooltip";
export class EventsPermissionTab extends Component {
  showLoader = (e, key, type) => {
    let loader_ref = this[`loader_${type}_${key}`];
    let tick_ref = this[`tick_${type}_${key}`];
    const { optionsForSelect } = this.props;
    if (key != "0") {
      loader_ref.classList.remove("hidden");
    }
    optionsForSelect(e, key, type);
    if (key != "0") {
      setTimeout(() => {
        loader_ref.classList.add("hidden");
        tick_ref.classList.remove("loader-hide");
      }, 200);
      setTimeout(() => {
        tick_ref.classList.add("loader-hide");
      }, 300);
    }
  };

  render() {
    const {
      showAdminPermission,
      categoryDojo,
      translations,
      permissionsState,
      permissionAction,
      permissionResourceType,
      permissionResourceId,
      groupsForSelect,
      objectChanged,
      userPermission,
      resourceSelectOptions,
      usersForSelect,
      companyId,
      closeModal,
      optionsForSelect,
      savePermission,
      showAlert,
      contractsForSelect,
      eventsForSelect,
      currentUser,
    } = this.props;
    return (
      <div
        className={`tab-pane fade ${showAdminPermission ? "" : "active in"}`}
        id="eventPermission"
        role="tabpanel"
        aria-labelledby="eventPermission-tab"
        data-backdrop={!categoryDojo}
      >
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="modal-content-section">
                <div className="section-title">
                  <h4>{translations.eventPermissions}</h4>
                </div>
                {/* Event Permissions Select */}
                <div className="form-group">
                  <div className="row">
                    {Object.entries(permissionsState).map(([key, epHash]) => {
                      let permittedToEdit = this.permittedToEditPermission(epHash, key);
                      return permittedToEdit ? (
                        <React.Fragment key={key}>
                          <div className="col-md-3 col-sm-3" id={`action-select-container-${key}`}>
                            <Tooltip
                              title={permissionAction.title}
                              body={permissionAction.body}
                              currentUser={currentUser}
                            />
                            <label>{translations.userCan}</label>
                            <div className="permission-select-container display-settings-select-container">
                              <Select2
                                data={[
                                  {
                                    text: translations.view,
                                    id: "view",
                                  },
                                  {
                                    text: translations.edit,
                                    id: "edit",
                                  },
                                ]}
                                value={epHash.permissionSelectedOption}
                                options={{
                                  minimumResultsForSearch: -1,
                                }}
                                onSelect={(e) => this.showLoader(e, key, "permission")}
                                className="action-select"
                                disabled={!permittedToEdit}
                              />
                            </div>
                            <span className="hidden up-loader" ref={(ref) => (this[`loader_permission_${key}`] = ref)}>
                              <i className="fa fa-spinner fa-white fa-spin" />
                            </span>
                            <span
                              className="loader-hide up-loader"
                              ref={(ref) => (this[`tick_permission_${key}`] = ref)}
                            >
                              <i alt="Yes" className="fa fa-check text-success" />
                            </span>
                          </div>
                          <div className="col-md-3 col-sm-3" id={`resource-type-select-container-${key}`}>
                            <Tooltip
                              title={permissionResourceType.title}
                              body={permissionResourceType.body}
                              currentUser={currentUser}
                            />
                            <label>{translations.eventsIf}</label>
                            <div className="permission-select-container display-settings-select-container">
                              <Select2
                                data={resourceSelectOptions()}
                                value={epHash.resourceTypeSelectedOption}
                                options={{
                                  minimumResultsForSearch: -1,
                                }}
                                onSelect={(e) => this.showLoader(e, key, "resource")}
                                className="resource-type-select"
                                disabled={!permittedToEdit}
                              />
                            </div>
                            <span className="hidden up-loader" ref={(ref) => (this[`loader_resource_${key}`] = ref)}>
                              <i className="fa fa-spinner fa-white fa-spin" />
                            </span>
                            <span className="loader-hide up-loader" ref={(ref) => (this[`tick_resource_${key}`] = ref)}>
                              <i alt="Yes" className="fa fa-check text-success" />
                            </span>
                          </div>
                          <div className="col-md-4 col-sm-4" id={`resource-select-container-${key}`}>
                            <Tooltip
                              title={permissionResourceId.title}
                              body={permissionResourceId.body}
                              currentUser={currentUser}
                            />
                            <label>{translations.is}</label>
                            <div className="permission-select-container display-settings-select-container">
                              {/* We have added three selects intentionally for performance issues */}
                              {epHash.resourceTypeSelectedOption === "User" && (
                                <Select2
                                  data={usersForSelect}
                                  value={epHash.resourceIdSelectedOption}
                                  onSelect={(e) => this.showLoader(e, key, "resourceId")}
                                  className="user-resource-select"
                                  disabled={!permittedToEdit}
                                  options={{
                                    dropdownParent: "#pop-custom-content",
                                  }}
                                />
                              )}
                              {epHash.resourceTypeSelectedOption === "Event" && (
                                <Select2
                                  data={eventsForSelect}
                                  value={epHash.resourceIdSelectedOption}
                                  onSelect={(e) => this.showLoader(e, key, "resourceId")}
                                  className="event-resource-select"
                                  disabled={!permittedToEdit}
                                  options={{
                                    dropdownParent: "#pop-custom-content",
                                  }}
                                />
                              )}
                              {epHash.resourceTypeSelectedOption === "Group" && (
                                <Select2
                                  data={groupsForSelect}
                                  value={epHash.resourceIdSelectedOption}
                                  onSelect={(e) => this.showLoader(e, key, "resourceId")}
                                  className="group-resource-select"
                                  disabled={!permittedToEdit}
                                  options={{
                                    dropdownParent: "#pop-custom-content",
                                  }}
                                />
                              )}
                              {epHash.resourceTypeSelectedOption === "Contract" && (
                                <Select2
                                  data={contractsForSelect}
                                  value={epHash.resourceIdSelectedOption}
                                  onSelect={(e) => this.showLoader(e, key, "resourceId")}
                                  className="contract-resource-select"
                                  disabled={!permittedToEdit}
                                />
                              )}
                            </div>
                            <span className="hidden up-loader" ref={(ref) => (this[`loader_resourceId_${key}`] = ref)}>
                              <i className="fa fa-spinner fa-white fa-spin" />
                            </span>
                            <span
                              className="loader-hide up-loader"
                              ref={(ref) => (this[`tick_resourceId_${key}`] = ref)}
                            >
                              <i alt="Yes" className="fa fa-check text-success" />
                            </span>
                          </div>
                          <div className="col-md-2 col-sm-2 text-left" id="action-buttons">
                            <label className="display-block hidden-xs">&nbsp;</label>
                            {key === "0" ? (
                              <button
                                className="btn btn-default btn-icon m-l10"
                                id="create-button"
                                type="button"
                                title={translations.add}
                                onClick={() => savePermission(key)}
                                disabled={epHash.resourceIdSelectedOption === 0}
                              >
                                <i className="fa fa-plus green-color" aria-hidden="true" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-default btn-icon m-l10"
                                id="delete-button"
                                type="button"
                                title={translations.delete}
                                data-toggle="modal"
                                data-target="#eventPermissionConfirmModal"
                                onClick={() => showAlert(true, userPermission.id, key)}
                              >
                                <i className="fa fa-trash-o red-color" aria-hidden="true" />
                              </button>
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="block-content text-left">
            {userPermission.user.company_id !== companyId ? (
              <div className="non_licensed_user_rule_limit">{`* ${translations.ruleCannotBeForExternalUser}`}</div>
            ) : (
              ""
            )}
            {userPermission.user.credit_balance !== "Annual License" ? (
              <div className="non_licensed_user_rule_limit">{translations.nonLicensedUserRuleLimit}</div>
            ) : (
              ""
            )}
          </div>
          <div className="block-content pull-right">
            <button className="btn btn-primary" data-dismiss="modal" onClick={() => closeModal("ok")}>
              {translations.ok}
            </button>
          </div>
        </div>
      </div>
    );
  }

  permittedToEditPermission(epHash, key) {
    if (key === "0" || epHash.resourceIdSelectedOption === 0) {
      return true;
    }
    const { eventsForSelect, currentUser, currentUserCanEditPermissions, editOwnEventPermissions, contractsForSelect } =
      this.props;
    const selectedId = parseInt(epHash.resourceIdSelectedOption);
    if (currentUserCanEditPermissions) {
      return true;
    } else if (editOwnEventPermissions) {
      if (epHash.resourceTypeSelectedOption === "Group") {
        return false;
      } else if (epHash.resourceTypeSelectedOption === "User") {
        return epHash.resourceIdSelectedOption === currentUser.id;
      } else if (epHash.resourceTypeSelectedOption === "Event") {
        return eventsForSelect.map((ep) => ep.id).includes(selectedId);
      } else if (epHash.resourceTypeSelectedOption === "Contract") {
        return contractsForSelect.map((ep) => ep.id).includes(selectedId);
      }
    }
    return false;
  }
}
