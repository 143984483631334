import type { ContextType } from "react";
import React, { useMemo, useState } from "react";
import clsx from "clsx";
import type { CustomFieldsTableProps } from "./custom-fields-table";
import CustomFieldsTable from "./custom-fields-table";
import Tooltip from "@/components/common/Tooltip";
import { t } from "@/i18n";
import type { CustomFieldsFormWrapperProps, CustomFieldsFormRequiredProps, Field } from ".";
import { useToggle } from "@/hooks/use-toggle";
import type { FormContext } from "@adeattwood/react-form";
import { Form, useFormContext } from "@adeattwood/react-form";
import { buildValidator } from "@/components/contract/custom-field-form/contract-cf-validator";
import { useAppDispatch } from "@/hooks/redux";
import { updateCustomFields } from "@/actions/contract/contractCreateEditActions";
import { useTimeout } from "@/hooks/use-timeout";
import styles from "./custom-field-form.module.scss";

export type CustomFieldsFormProps = CustomFieldsFormRequiredProps &
  Pick<
    CustomFieldsFormWrapperProps,
    "contactId" | "hide_table_header" | "height" | "eventId" | "readOnly" | "hideTitle"
  > & {
    hideTableHeader?: boolean;
    isContractModule: boolean;
  };
type ShowMoreButtonProps = Pick<CustomFieldsFormProps, "customFields" | "isContractModule"> &
  ReturnType<typeof useToggler>;

const getClassNames = (
  { hideTableHeader, isContractModule }: CustomFieldsFormProps,
  { showFullHeight }: ReturnType<typeof useToggler>
) => {
  return clsx({
    "overflow-x-auto": true,
    "border-none": hideTableHeader,
    contract_scrolling_inner: isContractModule,
    scrolling_inner: !isContractModule,
    cf_full_height: isContractModule && showFullHeight,
  });
};

const useToggler = () => {
  const [showFullHeight, toggleShowMore] = useToggle();
  const showMoreBtnText = showFullHeight ? "show_less_custom_fields" : "show_more_custom_fields";

  return { showFullHeight, showMoreBtnText, toggleShowMore };
};

const CustomFieldsFormHeader = ({ isContractModule }: { isContractModule: boolean }) => {
  const title = t("contextual_help.host.contract_help_texts.custom_fields.title", { ns: "tooltips" });
  const body = t("contextual_help.host.contract_help_texts.custom_fields.body", { ns: "tooltips" });

  return (
    <div className={isContractModule ? "title" : "form-item-block-title light-gray m-b5"}>
      {t("custom_fields")}
      <div className="inline-block p-l10">
        <Tooltip title={title} body={body} />
      </div>
    </div>
  );
};

const ShowMoreButton: React.FC<ShowMoreButtonProps> = (props) => {
  const { isContractModule, customFields, showFullHeight, showMoreBtnText, toggleShowMore } = props;
  if (!(isContractModule && customFields.length > 4)) return null;

  return (
    <button
      type="button"
      className="btn mt-3 btn-sm show_more_cf"
      data-testid="showMoreBtn"
      onClick={() => toggleShowMore.set(!showFullHeight)}
    >
      {t(showMoreBtnText)}
    </button>
  );
};

export type CustomFieldsForm = Record<string, string | boolean | string[]>;

const emptyStates: Partial<Record<Field["column_type"], Field["custom_column_values"][number]["name"]>> = {
  multiple_choice: [],
  checkbox: "false",
};

const getInitialValues = (customFields: Field[]) =>
  customFields.reduce<CustomFieldsForm>((acc, field) => {
    const empty = emptyStates[field.column_type] ?? "";
    const value = field.custom_column_values.length ? field.custom_column_values[0].name : empty;
    acc[field.id.toString()] = field.column_type == "checkbox" ? value === "true" : value;
    return acc;
  }, {});

const SubmitButton = () => {
  const formContext = useFormContext() as ContextType<typeof FormContext>;
  return (
    <button
      type="submit"
      className="btn btn-primary mt-3 btn-sm"
      disabled={formContext.status === "error" || formContext.status === "submitting"}
    >
      {t("update")}
    </button>
  );
};

const getEntityId = (props: CustomFieldsFormProps) =>
  props.componentName === "SrmContactCustomFieldsForm" ? props.contactId! : props.contractId;

const getCustomFieldTableProps = (props: CustomFieldsFormProps): CustomFieldsTableProps => {
  const { componentName, customFields, hideTableHeader, showPage, readOnly, eventId } = props;
  const entityId = getEntityId(props);
  return { customFields, componentName, hideTableHeader, showPage, readOnly, entityId, eventId };
};

const getShowMoreButtonProps = (
  { customFields, isContractModule }: CustomFieldsFormProps,
  { showFullHeight, showMoreBtnText, toggleShowMore }: ReturnType<typeof useToggler>
) => ({ customFields, isContractModule, showFullHeight, showMoreBtnText, toggleShowMore });

const useShowSuccess = () => {
  const [setSuccessTimeout] = useTimeout();
  const [shouldShow, setShowSuccess] = useState(false);
  return {
    shouldShow,
    showSuccess: () => {
      setShowSuccess(true);
      setSuccessTimeout(setShowSuccess, 1000, false);
    },
  };
};

const useHandleSubmit = (props: CustomFieldsFormProps) => {
  const dispatch = useAppDispatch();
  const { showSuccess, shouldShow } = useShowSuccess();
  return {
    onSubmit: async ({ formState }: { formState: CustomFieldsForm }) => {
      const action = await dispatch(
        updateCustomFields({
          entityId: getEntityId(props),
          eventId: props.eventId,
          form: formState,
          componentName: props.componentName,
        })
      );
      // doing this way rather than calling unwrap means we don't have to deal with the promise rejection
      // since the rejection is already handled by the reducers
      if (action.meta.requestStatus === "fulfilled") {
        showSuccess();
      }
    },
    shouldShowSuccess: shouldShow,
  };
};

const CustomFieldsForm: React.FC<CustomFieldsFormProps> = (props) => {
  const { customFields, isContractModule, height = "auto", hideTitle } = props;
  const togglerProps = useToggler();

  const className = getClassNames(props, togglerProps);

  const initialValues = useMemo(() => getInitialValues(customFields), [customFields]);
  const validator = useMemo(() => buildValidator(customFields), [customFields]);

  const { onSubmit, shouldShowSuccess } = useHandleSubmit(props);

  return (
    <div className="form-section-block">
      <Form {...{ initialValues, validator, onSubmit }}>
        {!hideTitle && <CustomFieldsFormHeader {...{ isContractModule }} />}
        <div {...{ className }} style={{ height }}>
          <CustomFieldsTable {...getCustomFieldTableProps(props)} />
        </div>
        <ShowMoreButton {...getShowMoreButtonProps(props, togglerProps)} />
        <div className={styles.submitBtn}>
          {shouldShowSuccess && <i data-testid="checkSuccess" className="fa fa-check" />}
          <SubmitButton />
        </div>
      </Form>
    </div>
  );
};

export default CustomFieldsForm;
