import * as ajaxCall from "./contractAjax";
import * as actionTypes from "../actionsTypes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { customFetch } from "@/lib/fetch";

export const setInitialContractProps = (data) => ({
  type: actionTypes.SET_CONTRACT_INITIAL_PROPS,
  payload: data,
});

const basicDetailsUpdateSuccess = (data) => ({
  type: actionTypes.UPDATE_BASIC_DETAILS_FIELD_VALUE,
  payload: data,
});

const stakeHolderSuccess = (data) => ({
  type: actionTypes.UPDATE_STAKEHOLDERS,
  payload: data,
});

const supplierSuccess = (data) => ({
  type: actionTypes.UPDATE_SUPPLIERS,
  payload: data,
});

const customNotificationsSuccess = (data) => ({
  type: actionTypes.UPDATE_CUSTOMNOTIFICATIONS,
  payload: data,
});

const notesSuccess = (data) => ({
  type: actionTypes.UPDATE_NOTES,
  payload: data,
});

const datesAndAlertsSuccess = (data) => ({
  type: actionTypes.UPDATE_DATESANDALERTS,
  payload: data,
});

export const historySuccess = (data) => ({
  type: actionTypes.UPDATE_HISTORY,
  payload: data,
});

const getError = (response) => {
  const message = Object.values(response.data)[0];
  return {
    type: actionTypes.ERROR,
    error: message,
  };
};

const handleMultipleErrorMessage = (error) => {
  let messages = [].concat.apply([], error);
  return {
    type: actionTypes.ERROR,
    error: messages.join("\n"),
  };
};

export const updateBasicDetailsTextFeilds = (contractId, data) => (dispatch) => {
  const payload = { contract: data, single_field: true };
  return ajaxCall
    .put(`/contracts/${contractId}`, payload)
    .then((response) => {
      dispatch(basicDetailsUpdateSuccess(response.data));
      dispatch(historySuccess(response.data.history));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const updateBasicDetailsSelectFeilds = (contractId, fieldId, fieldValue) => (dispatch) => {
  const payload = { custom_column: { column_id: fieldId, value: fieldValue } };
  return ajaxCall
    .post(`/contracts/${contractId}/create_update_custom_column`, payload)
    .then((response) => {
      dispatch(basicDetailsUpdateSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

function stringifyBooleans(inputForm) {
  return Object.entries(inputForm).reduce((form, [field, value]) => {
    if (typeof value === "boolean") {
      if (form === inputForm) form = { ...form };
      form[field] = value ? "true" : "false";
    }
    return form;
  }, inputForm);
}

export const updateCustomFields = createAsyncThunk(
  "updateCustomFields",
  /**
   * @type {import("@reduxjs/toolkit").AsyncThunkPayloadCreator<Array<import("@/components/contract/custom-field-form").Field>, { entityId: PropertyKey; form: import("@/components/contract/custom-field-form/custom-field-form").CustomFieldsForm, componentName: string; eventId?: PropertyKey }>}
   */
  async ({ entityId, form, componentName, eventId }) => {
    const componentUrlMap = {
      CustomFieldsForm: `/simdojo/events/${eventId}/event_participants/${entityId}/create_update_custom_column_values`,
      SrmCustomFieldsForm: `/srm/accounts/${entityId}/create_update_custom_column_values`,
      SrmContactCustomFieldsForm: `/srm/contacts/${entityId}/create_update_custom_column_values`,
      CreateEditContainer: `/contracts/${entityId}/create_update_custom_column_values`,
    };

    const res = await customFetch({
      url: componentUrlMap[componentName] || componentUrlMap.CreateEditContainer,
      method: "POST",
      body: { form: stringifyBooleans(form) },
    });

    return res.json();
  }
);

export const updateContractValue = (contractId, data) => (dispatch) => {
  const payload = { contract: data, single_field: true };
  return ajaxCall
    .put(`/contracts/${contractId}`, payload)
    .then((response) => {
      dispatch(historySuccess(response.data.history));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const updateDateAndAlertsValue = (contractId, data) => (dispatch) => {
  const payload = { contract: data, single_field: true };
  return ajaxCall
    .put(`/contracts/${contractId}`, payload)
    .then((response) => {
      if (response.data) {
        dispatch(historySuccess(response.data.history));
      }
      dispatch(datesAndAlertsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const updateContractNotificationInterval = (contractId, unit, interval) => (dispatch) => {
  const payload = { contract_notification: { interval: {} } };
  payload.contract_notification.interval[interval] = unit;
  return ajaxCall
    .post(`/contracts/${contractId}/add_or_update_contract_notification`, payload)
    .then((response) => {
      dispatch(historySuccess(response.data.history));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const uploadFileToContract = (contractId, fileType, file) => (dispatch) => {
  const payload = { file_type: fileType, document: { filename: file } };
  return ajaxCall
    .post(`/contracts/${contractId}/add_file`, payload)
    .then((response) => {
      dispatch(historySuccess(response.data.history));
      return response.data.files;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const removeFileFromContract = (contractId, fileId) => (dispatch) => {
  const payload = { file_id: fileId };
  return ajaxCall
    .remove(`/contracts/${contractId}/remove_file`, payload)
    .then((response) => {
      dispatch(historySuccess(response.data.history));
      return response.data.files;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const updateFilePhysicalLocation = (companyId, contractId, fileId, physicalLocation) => (dispatch) => {
  const payload = {
    versioned_file: { metadata: { physical_location: physicalLocation } },
    contract_id: contractId,
  };
  return ajaxCall
    .put(`/companies/${companyId}/file_repository/versioned_files/${fileId}`, payload)
    .then((response) => {
      dispatch(historySuccess(response.data.history));
      return response.data.files;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const updateCategory = (contractId, categoryId) => (dispatch) => {
  const payload = {
    contract: {
      contract_category: categoryId,
    },
    single_field: true,
  };
  return ajaxCall
    .post(`/contracts/${contractId}/add_category`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const addSupplierContact = (contractId, userEamils) => (dispatch) => {
  const payload = {
    contract_id: contractId,
    associated_data: {
      contact_user: userEamils,
    },
  };
  return ajaxCall
    .post(`/contracts/add_contact`, payload)
    .then((response) => {
      dispatch(supplierSuccess(response.data.suppliers.added));
      dispatch(historySuccess(response.data.history));
      if (response.data.error) {
        dispatch(handleMultipleErrorMessage(response.data.error));
      }
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const addStakeholderContact = (contractId, userEamils) => (dispatch) => {
  const payload = {
    contract_id: contractId,
    associated_data: {
      stakeholder: userEamils,
    },
  };
  return ajaxCall
    .post(`/contracts/add_stakeholder`, payload)
    .then((response) => {
      dispatch(stakeHolderSuccess(response.data.stakeholders.added));
      dispatch(historySuccess(response.data.history));
      if (response.data.error) {
        dispatch(handleMultipleErrorMessage(response.data.error));
      }
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const updateContactLabel =
  (contractId, userId, label, contactCompanyName, type, additionalData = {}) =>
  (dispatch) => {
    const payload = {
      contract_user: {
        user_id: userId,
        label,
        contact_company_name: contactCompanyName,
      },
      user: additionalData,
    };
    return ajaxCall
      .post(`/contracts/${contractId}/add_label`, payload)
      .then((response) => {
        dispatch(historySuccess(response.data.history));
        return response.data;
      })
      .catch((error) => {
        dispatch(getError(error.response));
      });
  };

export const removeContact = (contractId, userId, type) => (dispatch) => {
  const payload = {
    user_id: userId,
  };
  return ajaxCall
    .remove(`/contracts/${contractId}/remove_user`, payload)
    .then((response) => {
      dispatch(historySuccess(response.data.history));
      if (type == "supplier") {
        dispatch(supplierSuccess(response.data.suppliers.added));
      } else {
        dispatch(stakeHolderSuccess(response.data.stakeholders.added));
      }
      if (response.data.error) {
        dispatch(handleMultipleErrorMessage(response.data.error));
      }
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const addChildContract = (contractId, childId) => (dispatch) => {
  const payload = {
    child_id: childId,
  };
  return ajaxCall
    .post(`/contracts/${contractId}/add_relation`, payload)
    .then((response) => {
      dispatch(historySuccess(response.data.history));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const removeChildContract = (contractId, childId) => (dispatch) => {
  const payload = {
    other_contract_id: childId,
  };
  return ajaxCall
    .post(`/contracts/${contractId}/remove_relation`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const addParentContract = (contractId, parentId) => (dispatch) => {
  const payload = {
    parent_id: parentId,
  };
  return ajaxCall
    .post(`/contracts/${contractId}/add_relation`, payload)
    .then((response) => {
      dispatch(historySuccess(response.data.history));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const addNotes = (contractId, content, makeNotePrivate, bookmarkNote) => async (dispatch) => {
  const payload = {
    note_data: {
      body: content,
      private: makeNotePrivate,
      bookmark: bookmarkNote,
    },
  };
  try {
    const {
      data: { notes, history },
    } = await ajaxCall.post(`/contracts/${contractId}/add_notes`, payload);

    dispatch(notesSuccess(notes));
    dispatch(historySuccess(history));
    return notes;
  } catch (error) {
    dispatch(getError(error.response));
  }
};

export const updateNotes =
  (contractId, noteId, includeInAlerts, bookmarkNote, callByIncludeAlerts, content) => (dispatch) => {
    let [payloadKey, payloadValue] = callByIncludeAlerts
      ? ["include_in_alerts", includeInAlerts]
      : ["bookmark", bookmarkNote];
    const payload = {
      note_data: { [payloadKey]: payloadValue, body: content },
      note_id: noteId,
    };
    return ajaxCall
      .post(`/contracts/${contractId}/update_note`, payload)
      .then((response) => {
        dispatch(historySuccess(response.data.history));
        dispatch(notesSuccess(response.data.notes));
        return response;
      })
      .catch((error) => {
        dispatch(getError(error.response));
      });
  };

export const createCustomNotification =
  (contractId, subject, content, startDate, includeNotes, interval, suppliers, stakeholders) => (dispatch) => {
    const payload = {
      contract_notification: {
        subject: subject,
        content: content,
        start_date: startDate,
        include_notes: includeNotes,
        interval: interval,
        recipient_ids: { stakeholders, suppliers },
      },
    };
    return ajaxCall
      .post(`/contracts/${contractId}/contract_notification`, payload)
      .then((response) => {
        dispatch(historySuccess(response.data.history));
        dispatch(customNotificationsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getError(error.response));
      });
  };

export const updateCustomNotification =
  (contractId, notificationId, subject, content, startDate, includeNotes, interval, suppliers, stakeholders) =>
  (dispatch) => {
    const payload = {
      contract_notification_id: notificationId,
      contract_notification: {
        subject: subject,
        content: content,
        start_date: startDate,
        include_notes: includeNotes,
        interval: interval,
        recipient_ids: { stakeholders, suppliers },
      },
    };
    return ajaxCall
      .put(`/contracts/${contractId}/contract_notification`, payload)
      .then((response) => {
        dispatch(historySuccess(response.data.history));
        dispatch(customNotificationsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getError(error.response));
      });
  };

export const removeCustomNotification = (contractId, notificationId) => (dispatch) => {
  const payload = {
    contract_notification_id: notificationId,
  };
  return ajaxCall
    .remove(`/contracts/${contractId}/contract_notification`, payload)
    .then((response) => {
      dispatch(historySuccess(response.data.history));
      dispatch(customNotificationsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const updateRecipientsForNotification =
  (contractId, notificationId, includeNotes, suppliers, stakeholders) => (dispatch) => {
    const payload = {
      contract_notification_id: notificationId,
      contract_notification: {
        include_notes: includeNotes,
        recipient_ids: { stakeholders, suppliers },
      },
    };
    return ajaxCall
      .put(`/contracts/${contractId}/contract_notification`, payload)
      .then((response) => {
        dispatch(historySuccess(response.data.history));
        return response.data;
      })
      .catch((error) => {
        dispatch(getError(error.response));
      });
  };

export const saveContract = (contractId) => (dispatch) => {
  const payload = {
    save_contract: true,
  };
  return ajaxCall
    .put(`/contracts/${contractId}`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
};

export const getHistory =
  (contractId = null, eventParticipantId = null, eventId = null) =>
  (dispatch) => {
    let url = contractId
      ? `/contracts/${contractId}/audit_trail`
      : `/simdojo/events/${eventId}/event_participants/${eventParticipantId}/audit_logs`;
    return ajaxCall
      .get(url)
      .then((response) => {
        dispatch(historySuccess(response.data.history));
        return response.data;
      })
      .catch((error) => {
        dispatch(getError(error.response));
      });
  };
