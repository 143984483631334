import React from "react";
import { isRfq, isWeightedRfqOrAuction } from "../../../../eventCommon";
import { passedPreQualificationQuestionnaires } from "../../../../eventParticipantCommon";

export const getTabElements = (props, contextProps, activeTab, updateActiveTab) => {
  const { translations, event, participants } = props;
  const isWeightedAuctionOrRfq = isWeightedRfqOrAuction(event);
  let results = [];
  const { allQuestionnaires, allQuestionnaireAnswers } = contextProps;
  let allPassedPreQualificationQuestionnaires = passedPreQualificationQuestionnaires(
    allQuestionnaires,
    allQuestionnaireAnswers,
    participants
  );
  results.push(
    <li
      key={translations.participant_details_html}
      className={`tab-item  ${activeTab === translations.participant_details_html ? "active" : ""}`}
      name={translations.participant_details_html}
      onClick={(e) => {
        updateActiveTab(e.target.name);
      }}
    >
      <a href="#" name={translations.participant_details_html}>
        {translations.participant_details_html}
      </a>
    </li>
  );

  if (allPassedPreQualificationQuestionnaires) {
    if (event.event_category_rfq) {
      results.push(
        <li
          key={translations.rfq_bids_html}
          name={translations.rfq_bids_html}
          onClick={(e) => {
            updateActiveTab(e.target.name);
          }}
          className={`tab-item weighted_rfq_or_auction_tab
            ${
              activeTab === translations.qualification_bids_html || activeTab === translations.rfq_bids_html
                ? "active"
                : ""
            }`}
        >
          <a href="#" name={translations.rfq_bids_html}>
            {isWeightedAuctionOrRfq ? <i className="fa fa-balance-scale"></i> : null}
            {translations.rfq_bids_html}
          </a>
        </li>
      );
    }
    if (!(isRfq(event) || event.bid_deadline)) {
      results.push(
        <li
          key={translations.qualification_bids_html}
          name={translations.qualification_bids_html}
          onClick={(e) => {
            updateActiveTab(e.target.name);
          }}
          className={`tab-item weighted_rfq_or_auction_tab
          ${
            activeTab === translations.qualification_bids_html || activeTab === translations.rfq_bids_html
              ? "active"
              : ""
          }
          `}
        >
          <a name={translations.qualification_bids_html} href="#">
            {isWeightedAuctionOrRfq ? <i className="fa fa-balance-scale"></i> : null}
            {translations.qualification_bids_html}
          </a>
        </li>
      );
    }

    if (!(isRfq(event) && !event.progress_to_auction)) {
      results.push(
        <li
          key={translations.auction_bids_html}
          name={translations.auction_bids_html}
          onClick={(e) => {
            updateActiveTab(e.target.name);
          }}
          className={`tab-item weighted_rfq_or_auction_tab

          ${activeTab === translations.auction_bids_html ? "active" : ""}
          `}
        >
          <a href="#" name={translations.auction_bids_html}>
            {isWeightedAuctionOrRfq ? <i className="fa fa-balance-scale"></i> : null}
            {translations.auction_bids_html}
          </a>
        </li>
      );
    }
  }

  return results;
};
