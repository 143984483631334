import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetCommonModal, makeCommonModalActive } from "./modalHOC.duck";

// https://medium.com/@onoufriosm/scalable-modals-in-react-20a193dc9381
const ModalHOC = (props) => {
  // open state will responsible for showing or hiding modal
  const [open, toggle] = useState(false);
  const dispatch = useDispatch();
  //Get state from redux-store
  const states = useSelector((state) => state.modalHOCReducers);
  const { showViewComponent = true } = props;

  // This will manage the API call function(if any) before opening the pop up.
  const handleAPICall = async () => {
    const { apiCallingFunction } = props;
    if (apiCallingFunction) {
      await apiCallingFunction();
    }
    toggle(!open);
  };

  // To manage previous states
  const usePrevious = (states) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = states;
    });
    return ref.current;
  };

  const prevState = usePrevious(states);

  useEffect(() => {
    if (prevState && prevState.isSubmitted !== states.isSubmitted) {
      //Reset modal-store if submitted
      dispatch(resetCommonModal());
      toggle(false);
    }
  }, [states]);

  /**
   * If viewComponent is an object then onClick would trigger the modal.
   * If it's a function then the toggleModal prop will
   * be passed and the component would have to manually trigger the modal
   */
  const renderViewComponent = (viewComponent, props) => {
    if (typeof viewComponent === "function") {
      return viewComponent(props);
    }

    return showViewComponent ? (
      <a
        onClick={() => {
          handleAPICall();
          dispatch(makeCommonModalActive());
        }}
        id={viewComponent}
        title={props.viewComponentTitle ?? viewComponent}
      >
        {viewComponent}
      </a>
    ) : (
      <></>
    );
  };

  // To show or hide modal
  const toggleModal = () => {
    if (open) {
      dispatch(resetCommonModal());
    }
    toggle(!open);
  };

  //this props are coming from where modal is triggered.
  const { modalComponent, viewComponent, viewComponentTitle } = props;

  // this props are made by this component itself.
  const modalProps = {
    open,
    toggleModal: toggleModal,
    viewComponentTitle,
  };
  return (
    <Fragment>
      {/* viewComponent is text and while clicking on it modal will open */}
      {renderViewComponent(viewComponent, modalProps)}
      {/* modalComponent will receive as a props which contains
            modal details which will triggered */}
      {modalComponent(modalProps)}
    </Fragment>
  );
};
export default ModalHOC;
