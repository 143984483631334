import React from "react";
import {
  eventParticipantStatus,
  isLotTotal,
  fetchAssociatedCurrency,
  showLocalisedStrippedDecimal,
  formatPercentileNumber,
  canShowRank,
  findUser,
  companyNameOrEmail,
  isRankedCell,
  findLotTotalLic,
} from "../../../common";
import {
  preferredBlicPrice,
  differenceOfferedForBlic,
  differenceOfferedPercentageForBlic,
  differenceOfferedWithCurrency,
  showBid,
  fetchAssociatedBlicOrBidCurrency,
} from "../../../bidCommon";
import { isRfq, isWeightedRfqOrAuction } from "../../../eventCommon";
import { fetchQuestionnaireScore } from "../../../QuestionnaireAnswerCommon";
import { useSelector } from "react-redux";
import EventRoundDetails from "./best_bid_modal_components/EventRoundDetails";
import RankDetails from "./best_bid_modal_components/RankDetails";

const PriceBlicRowDetails = (props) => {
  const {
    blic,
    lic,
    bid,
    event,
    beforeAuction,
    isJapaneseEvent,
    user,
    componentType,
    lot,
    formatDatetime,
    idx,
    currenciesHash,
    role,
    translations,
    locale,
    allUom,
    unitSet,
    blics,
    bids,
    isMultiCurrencyEvent,
    currency,
    showOverallScore,
    auctionTab,
    timeZoneIdentifier,
    participant,
  } = props;

  const event_rounds = useSelector(({ lotReducers }) => lotReducers.event_rounds);
  const totalLic = findLotTotalLic(props.lotLics, lot.lot_total_lic_id);
  const precision = lic?.decimal_place ?? totalLic?.decimal_place;

  const getBlicPrice = (blicPrice) => {
    const host = bid.host_id && findUser(props.hosts, bid.host_id);
    return (
      <div>
        {blicPrice}
        {host ? (
          <span className="proxy_bid" title={`${props.translations.proxy_bid} ${host.name}`}>
            P
          </span>
        ) : null}
      </div>
    );
  };

  const getOverallTds = () => {
    return (
      <>
        <td>{bid.rejected_lot || !bid.current ? "-" : `${formatPercentileNumber(bid.price_score, locale)}`}</td>
        <td>{fetchQuestionnaireScore(props, participant)}</td>
        <td>{bid.rejected_lot || !bid.current ? "-" : `${formatPercentileNumber(bid.weighted_score, locale)}`}</td>
      </>
    );
  };

  const getComponentTypeTd = () => {
    let result = null;
    switch (componentType(lic.lot_component_type)) {
      case "isPrice":
        const licCurrency = isMultiCurrencyEvent ? fetchAssociatedCurrency(currenciesHash, lot, lic) : currency;
        const blicCurrency = isMultiCurrencyEvent
          ? fetchAssociatedBlicOrBidCurrency(currenciesHash, bid, blic)
          : currency;
        const blicPrice = preferredBlicPrice(
          blic,
          lic,
          role,
          precision,
          locale,
          translations.number_format,
          licCurrency,
          blicCurrency
        );
        result = isLotTotal(lot, lic)
          ? showBid(bid, lot, currency, locale, "host", { ...props }, precision)
          : getBlicPrice(blicPrice);
        break;
      case "isText":
      case "isPicklist":
        result = blic.attribute_value;
        break;
      case "isDecimal":
        if (blic.price) {
          showLocalisedStrippedDecimal(blic.price);
        }
        break;
      case "isDate":
        if (blic.date_value) {
          formatDatetime(blic.date_value, timeZoneIdentifier);
        }
        break;
      default:
        result = null;
    }
    return result;
  };

  const licCurrency = isMultiCurrencyEvent ? fetchAssociatedCurrency(currenciesHash, lot, lic) : currency;

  const diffVal = differenceOfferedForBlic(event, blic, lic, allUom, unitSet, bid, blics, bids);
  const diffValWithCurrency = differenceOfferedWithCurrency(
    diffVal,
    licCurrency,
    locale,
    translations.number_format,
    precision
  );

  return (
    <tr key={idx} className={blic.rank ? "ranked" : "unranked"} rel={blic.bid_id}>
      <RankDetails bid={bid} blic={blic} lic={lic} participant={participant} />
      <td> {companyNameOrEmail(user)} </td>
      <EventRoundDetails blic={blic} />
      <td>
        <>{getComponentTypeTd()}</>
      </td>
      {showOverallScore ? getOverallTds() : null}
      <td>{formatDatetime(blic.created_at, timeZoneIdentifier)}</td>
      <td>{diffValWithCurrency}</td>
      <td>
        {formatPercentileNumber(differenceOfferedPercentageForBlic(event, blic, lic, allUom, bid, blics, bids), locale)}
      </td>
    </tr>
  );
};

export default PriceBlicRowDetails;
