import React from "react";

import Tooltip from "../../common/Tooltip";
import BidUploadModal from "../bids/BidUploadModal";

const BidTemplateDropdown = (props) => {
  const { translations, event, eventParticipant, setLotStateCb, lots } = props;
  const { bid_template } = translations.infos;
  return (
    <>
      <div className="pull-right">
        <div className="button button-event-current dropdown">
          <div className="btn-group btn-action">
            <button
              type="button"
              className="dropdown-toggle status-event sm-butt current form-control min-width200 max-w-none"
              data-toggle="dropdown"
            >
              {translations.use_bid_template} <span className="caret"></span>
            </button>
            <ul className="dropdown-menu" role="menu">
              <li>
                <a href={`/events/${event.id}/lots.xlsx?bid_upload=true`}>
                  <i className="fa fa-download m-r5"></i>
                  {translations.download_bid_template}
                </a>
              </li>
              <BidUploadModal
                translations={translations}
                event={event}
                eventParticipant={eventParticipant}
                setLotStateCb={setLotStateCb}
                lots={lots}
              />
            </ul>
          </div>
        </div>
      </div>
      <div className="pull-right push-10-r m-t4">
        <Tooltip title={bid_template.title} body={bid_template.body} />
      </div>
    </>
  );
};

export default BidTemplateDropdown;
