import React from "react";
import LiHeadingForPriceComponent from "./LiHeadingForPriceComponent";
import TableHeaderWithTooltip from "./TableHeaderWithTooltip";

const LineItemTableHeading = (props) => {
  const titleWithTooltip = (title, tooltip) => (
    <TableHeaderWithTooltip translations={props.translations} title={title} tooltip={tooltip} key={title} />
  );

  return props.lic.is_price ? (
    <LiHeadingForPriceComponent {...props} titleWithTooltip={titleWithTooltip} />
  ) : (
    <tr>
      {[
        ["tab_participants_html", "bid_participant"],
        ["participants_bid", "bid_bids"],
        ["time_of_bid", "bid_time_of_bid"],
      ].map((values) => {
        return titleWithTooltip(...values);
      })}
    </tr>
  );
};

export default LineItemTableHeading;
