import React from "react";
import LotLevelMonitor from "../LotLevelMonitor";
import Header from "./Header";
const LotLevelEvent = (props) => {
  return (
    <div className="v2 event-current" id="lot-table-box">
      <div className="clear-padding simple-box">
        <div className="event-list responsive-table-container clearfix">
          <Header {...props} />
          {props.lots.map((lot, idx) => {
            const LBids = props.bids.filter((b) => b.lot_id === lot.id);
            let setLotStateCb = {};
            return (
              <LotLevelMonitor
                lotNumber={idx + 1}
                key={lot.id}
                {...props}
                lot={lot}
                currency={props.event_currency}
                participant={props.event_participant}
                LBids={LBids}
                lotComponents={props.lot_components}
                lineItems={props.line_items}
                lineItemComponents={props.line_item_components}
                licCurrency={props.event_currency}
                currentUser={props.current_user}
                setLotStateCb={setLotStateCb}
                unitOfMeasures={props.unit_of_measures}
                unitSets={props.unit_sets}
                isAuction={true}
                auctionTab={props.auction_tab}
                beforeAuction={props.before_auction}
                isRankedMonitor={props.isRankedMonitor}
                role={props.role}
                weightedRfqOrAuction={props.weighted_rfq_or_auction}
                isEventCompleted={props.isEventCompleted}
                unitSetUoms={props.unit_set_unit_of_measures}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LotLevelEvent;
