import React from "react";
import PropTypes from "prop-types";

const ErrorPropTypes = {
  /**
   * The error message that will be displayed. Nothing will be rendered if no
   * message is provided
   *
   * @type {String | undefined}
   */
  message: PropTypes.string,
};

const ErrorDefaultProps = {};

/**
 * @type {React.FC<typeof ErrorPropTypes>}
 */
export const Error = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <div
      title={message}
      className="alert alert-danger font-s13 m-b0 m-t10 text-city vertical-center white-space-pre-wrap wid-100 animated fadeIn"
    >
      {message}
    </div>
  );
};

Error.propTypes = ErrorPropTypes;
Error.defaultProps = ErrorDefaultProps;

export default Error;
