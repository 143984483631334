import React from "react";
import { bidBidLineItemComponents, latestBidDuringAuctionForLot } from "../../../../bidCommon";
import { isWeightedRfqOrAuction } from "../../../../eventCommon";
import BidRowDetails from "../BidRowDetails";
const BidDetailsForLot = (props) => {
  const { bidList, lot, event } = props;
  const startAt = Date.parse(event.start_at);
  const activeBidList =
    props.showTabs && props.activeTab === props.translations.show_participants_best_bids
      ? latestBidDuringAuctionForLot(
          bidList,
          props.participatingParticipants,
          event,
          !isWeightedRfqOrAuction(event),
          props.auctionTab
        )
      : bidList;

  return (
    activeBidList &&
    activeBidList.map((bid) => {
      const participant = props.eventParticipants.find((p) => p.user_id === bid.user_id);
      const user = props.participants.find((user) => user.id === bid.user_id);
      const bidBlics = bidBidLineItemComponents(bid.id, props.bidLineItemComponents);
      return (
        <tr
          className={bid.rank ? "ranked" : "unranked"}
          id={`row_${lot.id}_${bid.user_id}`}
          rel={bid.id}
          key={`${bid.id}-${lot.id}-${user.id}`}
        >
          <BidRowDetails
            lot={lot}
            bid={bid}
            participant={participant}
            startAt={startAt}
            user={user}
            event={event}
            bidList={bidList}
            isLineItemDetail={false}
            bidBlics={bidBlics}
            isJapaneseEvent={props.isJapaneseEvent}
            beforeAuction={props.beforeAuction}
            permittedToEdit={props.permittedToEdit}
            translations={props.translations}
            participatingParticipants={props.participatingParticipants}
            currency={props.currency}
            locale={props.locale}
            bidControls={props.bidControls}
            auctionControls={props.auctionControls}
            hosts={props.hosts}
            showOverallScore={props.showOverallScore}
            lotLcs={props.lotLcs}
            lotLis={props.lotLis}
            lineItemComponents={props.lineItemComponents}
            role={props.role}
            allUom={props.allUom}
            isMultiCurrencyEvent={props.isMultiCurrencyEvent}
            LBids={props.LBids}
            allUnitSets={props.allUnitSets}
            timeZoneIdentifier={props.timeZoneIdentifier}
            bidRound={props.bidRound}
            lots={props.lots}
            bids={props.bids}
            permissionToView={props.permissionToView}
            currenciesHash={props.currenciesHash}
            eventPassedFinalDeadline={props.eventPassedFinalDeadline}
            lotComponents={props.lotComponents}
            lineItems={props.lineItems}
            bidLineItemComponents={props.bidLineItemComponents}
            participants={props.participants}
            unitOfMeasures={props.unitOfMeasures}
            currentUser={props.currentUser}
            unitSetUoms={props.unitSetUoms}
            questionnaireAnswers={props.questionnaireAnswers}
            questionnaireQuestions={props.questionnaireQuestions}
            answers={props.answers}
            questionnaireAnswersQuestions={props.questionnaireAnswersQuestions}
            questionnaires={props.questionnaires}
            questionnaireSections={props.questionnaireSections}
            picklistOptions={props.picklistOptions}
            auctionTab={props.auctionTab}
            canShowDeleteBtn={props.canShowDeleteBtn}
          />
        </tr>
      );
    })
  );
};

export default BidDetailsForLot;
