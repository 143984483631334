import React from "react";
import { clsx } from "clsx";
import { t } from "@/i18n";

type SpinnerSize = "sm" | "lg";

interface Spinner {
  size?: SpinnerSize;
  className?: string;
  title?: string;
}

function getSpinnerClass(size: SpinnerSize, className?: string) {
  return clsx(
    "spinner-border",
    {
      "spinner-border-sm": size === "sm",
    },
    className
  );
}

export function Spinner(props: Spinner) {
  const { size = "lg", className, title } = props;
  const spinnerClass = getSpinnerClass(size, className);

  return (
    <div className={spinnerClass} title={title} role="spinbutton">
      <span className="visually-hidden">{t("loading")}</span>
    </div>
  );
}
