import React from "react";
import LotComponentModal from "./LotComponentModal";

const AddLotComponentButton = (props) => {
  const {
    lot,
    lotLcs,
    translations,
    classFromEventState,
    eventCurrency,
    currenciesHash,
    event,
    currentUser,
    hideFromParticipantsSwitch,
  } = props;
  return (
    <div className="pull-right m-t1">
      {!lot.is_event_total && lotLcs.length > 0 ? (
        <div className="btn-action btn-group">
          {/* !URL monitor_event_lots_path(@event)  */}
          <button
            name="button"
            type="submit"
            data-toggle="dropdown"
            className={`status-event sm-butt auto_width_btn form-control m-r0 ${classFromEventState} max-w-none`}
          >
            <i className="fa fa-table" />
            &nbsp;{translations.add_component} {translations.questionnaire_questions.edit.column}
            &nbsp;
            <span className="caret" />
          </button>
          <ul className="dropdown-menu cursor-pointer">
            <li>
              {/* URL new_event_lot_lot_component_path(@event, lot) */}
              <LotComponentModal
                classes="plus-lot-component-content-loader blue-color xs-full-width"
                title={translations.add_component}
                translations={translations}
                eventCurrency={eventCurrency}
                lot={lot}
                showPlusIcon={true}
                create={true}
                currenciesHash={currenciesHash}
                event={event}
                classFromEventState={classFromEventState}
                currentUser={currentUser}
                hideFromParticipantsSwitch={hideFromParticipantsSwitch}
              />
            </li>
            <li>
              {/* URL new_event_lot_lot_component_path(@event, lot, calc_column: true) */}
              <LotComponentModal
                classes="plus-lot-component-content-loader blue-color xs-full-width"
                title={translations.add_total}
                total={true}
                translations={translations}
                eventCurrency={eventCurrency}
                lot={lot}
                showPlusIcon={true}
                create={true}
                currenciesHash={currenciesHash}
                event={event}
                classFromEventState={classFromEventState}
                currentUser={currentUser}
                hideFromParticipantsSwitch={hideFromParticipantsSwitch}
              />
            </li>
          </ul>
        </div>
      ) : (
        <LotComponentModal
          classes={`auto_width_btn bg-white draft status-event sm-butt m-r0
              ${lotLcs == 0 ? "glowing" : ""}
              ${classFromEventState}`}
          title={
            lotLcs == 0 && lot.is_event_total ? translations.add_event_total_component : translations.add_lot_component
          }
          translations={translations}
          eventCurrency={eventCurrency}
          lot={lot}
          showPlusIcon={true}
          create={true}
          total={lot.is_event_total}
          currenciesHash={currenciesHash}
          event={event}
          classFromEventState={classFromEventState}
          currentUser={currentUser}
          hideFromParticipantsSwitch={hideFromParticipantsSwitch}
        />
      )}
    </div>
  );
};

export default AddLotComponentButton;
