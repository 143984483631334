/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// Support component names relative to this directory:
import "core-js/stable";
import "regenerator-runtime/runtime";
import { Turbo } from "@hotwired/turbo-rails";
import "../flatpicker";
import { initI18n } from "@/i18n";
import features from "@/utils/features";
import { Application } from "@hotwired/stimulus";
import RailsNestedForm from '@stimulus-components/rails-nested-form'
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import "jquery-datetimepicker";

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");

if (features.disabled("turbo_drive_navigation")) {
  Turbo.session.drive = false;
}

// Make jQuery available in the global scope so that we can use it in our erb
// views. I am really hoping to get rid of this at some point, by migrating to
// pure js
import "../lib/jquery";
import "select2/dist/js/select2.full";
import "react-select2-wrapper";

// Remove the react rails event listeners so components don't get mounted when
// the document is ready. We want to wait until we are ready and all our assets
// are loaded.
//
// See: https://github.com/reactjs/react-rails/wiki/How-to:-Stop-listening-to-events
ReactRailsUJS.removeEvent("turbolinks:render", ReactRailsUJS.handleMount);
ReactRailsUJS.removeEvent("DOMContentLoaded", ReactRailsUJS.handleMount);

// Disable the eslint error for react hooks. It thinks this is a `useContext`
// hook that can only be used in a react component.
//
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);

// Mound all of th react components when all the language files are downloaded.
initI18n().then(() => {
  ReactRailsUJS.handleMount();
  ReactRailsUJS.detectEvents();

  // Load all the stimulus controllers when we have all the translations loaded
  const stimulus = Application.start();
  const context = require.context("./controllers", true, /\.[tj]s$/);
  stimulus.load(definitionsFromContext(context));
  stimulus.register('nested-form', RailsNestedForm);
});

/**
 * Mount react components when turbo stream renders. This is a workaround for
 * turbo refusing to add a after render and providing an element. We need to
 * override the default renderer and do what ever we want to after WE call the
 * default one.
 *
 * GOTCHA: This will not work if you use the `replace` action and don't add the
 * target element back into the dom. After the render has happened there is no
 * way to get the element you inserted we only have access to the target. If
 * you remove that we cannot mount react components into it.
 */
document.addEventListener("turbo:before-stream-render", function (event) {
  const defaultRender = event.detail.render;

  event.detail.render = async function (streamElement, ...args) {
    await defaultRender(streamElement);
    for (const target of streamElement.targetElements) {
      ReactRailsUJS.mountComponents(target);
    }
  };
});

window.DATETIME_PICKER_STEP_MINUTES = 15;

require("@rails/actiontext");
require("channels");
