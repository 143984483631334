import React from "react";
import GetDynamicRows from "./GetDynamicRowsLotMatrixLicDetails";

const LotMatrixLicDetailsTable = (props) => {
  const { lotLcs, lotLis } = props;
  return (
    <table
      className="table custom-responsive-table complex-lots-table bid-details"
      border="0"
      cellSpacing="0"
      cellPadding="0"
    >
      <tbody>
        <tr className="popup-head">
          <td className="active">&nbsp;</td>
          {lotLcs.map((component, index) => {
            return (
              <td
                key={index}
                className="cell-td"
                align="center"
                style={{ ["verticalAlign"]: "top" }}
                title={component.name}
              >
                {component.name}
              </td>
            );
          })}
        </tr>
        {lotLis.map((item, index) => {
          return <GetDynamicRows key={index} item={item} index={index} {...props} />;
        })}
      </tbody>
    </table>
  );
};

export default LotMatrixLicDetailsTable;
