module.exports = {
  GET_CALENDAR_EVENTS: "GET_CALENDAR_EVENTS",
  GET_COMPANY_USER_PERMISSIONS: "GET_COMPANY_USER_PERMISSIONS",
  DELETE_USER_PERMISSIONS: "DELETE_USER_PERMISSIONS",
  SHOW_USER_PERMISSION_MODAL: "SHOW_USER_PERMISSION_MODAL",
  UPDATE_ADMIN_PERMISSION: "UPDATE_ADMIN_PERMISSION",
  DELETE_ADMIN_PERMISSION: "DELETE_ADMIN_PERMISSION",
  CREATE_ADMIN_PERMISSION: "CREATE_ADMIN_PERMISSION",
  SHOW_ALERT: "SHOW_ALERT",
  ERROR: "ERROR",
  ERROR_CODE: "ERROR_CODE",
  SUCCESS: "SUCCESS",
  CREATE_USER_PERMISSION: "CREATE_USER_PERMISSION",
  GET_ALL_DEFAULT_GROUP_USERS: "GET_ALL_DEFAULT_GROUP_USERS",
  CREATE_EVENT_PERMISSION: "CREATE_EVENT_PERMISSION",
  UPDATE_EVENT_PERMISSION: "UPDATE_EVENT_PERMISSION",
  DELETE_EVENT_PERMISSION: "DELETE_EVENT_PERMISSION",
  CREATE_CONTRACT_PERMISSION: "CREATE_CONTRACT_PERMISSION",
  UPDATE_CONTRACT_PERMISSION: "UPDATE_CONTRACT_PERMISSION",
  DELETE_CONTRACT_PERMISSION: "DELETE_CONTRACT_PERMISSION",
  TRANSFER_SUPERHOST: "TRANSFER_SUPERHOST",
  CLOSE_MODAL: "CLOSE_MODAL",
  CREATE_CATEGORY_PERMISSION: "CREATE_CATEGORY_PERMISSION",
  UPDATE_CATEGORY_PERMISSION: "UPDATE_CATEGORY_PERMISSION",
  DELETE_CATEGORY_PERMISSION: "DELETE_CATEGORY_PERMISSION",
  MESSAGE_SENT: "MESSAGE_SENT",
  SHOW_MESSAGE_MODAL: "SHOW_MESSAGE_MODAL",
  GET_MESSAGES: "GET_MESSAGES",
  CREATE_UPDATE_JURISDICTIONS: "CREATE_UPDATE_JURISDICTIONS",
  UPDATE_HOME_PAGE_FIELDS_STATUS: "UPDATE_HOME_PAGE_FIELDS_STATUS",
  EDIT_PAGE_FIELD_STATUS_UPDATE: "EDIT_PAGE_FIELD_STATUS_UPDATE",
  CUSTOM_NOTIFICATION_STATUS_UPDATE: "CUSTOM_NOTIFICATION_STATUS_UPDATE",
  CREATE_UPDATE_LOT: "CREATE_UPDATE_LOT",
  CREATE_LINE_ITEM: "CREATE_LINE_ITEM",
  CREATE_LOT_COMPONENT: "CREATE_LOT_COMPONENT",
  UPDATE_LOT_COMPONENT: "UPDATE_LOT_COMPONENT",
  UPDATE_BASIC_DETAILS_FIELD_VALUE: "UPDATE_BASIC_DETAILS_FIELD_VALUE",
  UPDATE_STAKEHOLDERS: "UPDATE_STAKEHOLDERS",
  UPDATE_SUPPLIERS: "UPDATE_SUPPLIERS",
  UPDATE_CUSTOMNOTIFICATIONS: "UPDATE_CUSTOMNOTIFICATIONS",
  UPDATE_NOTES: "UPDATE_NOTES",
  UPDATE_DATESANDALERTS: "UPDATE_DATESANDALERTS",
  UPDATE_HISTORY: "UPDATE_HISTORY",
  SIM_CONFIGURATION_SUCCESS: "SIM_CONFIGURATION_SUCCESS",
  UPDATE_LINE_ITEM_COMPONENT: "UPDATE_LINE_ITEM_COMPONENT",
  DELETE_LOT: "DELETE_LOT",
  CREATE_LOT_TEMPLATE: "CREATE_LOT_TEMPLATE",
  USE_LOT_TEMPLATE: "USE_LOT_TEMPLATE",
  DELETE_LINE_ITEM: "DELETE_LINE_ITEM",
  DELETE_LOT_COMPONENT: "DELETE_LOT_COMPONENT",
  UPLOAD_LOT_TEMPLATE: "UPLOAD_LOT_TEMPLATE",
  UPDATE_EVENT: "UPDATE_EVENT",
  SET_LOT_TOTAL: "SET_LOT_TOTAL",
  ENFORCE_LOCALES: "ENFORCE_LOCALES",
  CREATE_PICKLIST: "CREATE_PICKLIST",
  UPDATE_PICKLIST: "UPDATE_PICKLIST",
  TAG_UI_SUCCESS: "TAG_UI_SUCCESS",
  CREATE_UNIT_SET: "CREATE_UNIT_SET",
  UPDATE_UNIT_SET: "UPDATE_UNIT_SET",
  FETCH_BIDS: "FETCH_BIDS",
  PLACE_BID: "PLACE_BID",
  PLACE_BID_PENDING: "PLACE_BID_PENDING",
  FETCH_BIDS_REQUESTED: "FETCH_BIDS_REQUESTED",
  FETCH_BIDS_COMPLETED: "FETCH_BIDS_COMPLETED",
  DELETE_BID: "DELETE_BID",
  FETCH_BLICS: "FETCH_BLICS",
  FETCH_BLICS_REQUESTED: "FETCH_BLICS_REQUESTED",
  FETCH_BLICS_COMPLETED: "FETCH_BLICS_COMPLETED",
  UPDATE_LINE_ITEM: "UPDATE_LINE_ITEM",
  ARCHIVED: "ARCHIVED",
  ARCHIVED_SUCCESS: "ARCHIVED_SUCCESS",
  SELECT_ALL: "SELECT_ALL",
  SORT_CONTRACTS: "SORT_CONTRACTS",
  RESET_CONTRACTS: "RESET_CONTRACTS",
  CONTRACT_OWNER_CHANGED: "CONTRACT_OWNER_CHANGED",
  FILTERED_CONTRACTS: "FILTERED_CONTRACTS",
  SEARCH_RESULTS: "SEARCH_RESULTS",
  GET_LATEST_BID: "GET_LATEST_BID",
  SET_LOT_INITIAL_PROPS: "SET_LOT_INITIAL_PROPS",
  COMMON_MODAL_SUBMIT_REQUEST: "COMMON_MODAL_SUBMIT_REQUEST",
  COMMON_MODAL_SUBMIT_REQUEST_COMPLETED: "COMMON_MODAL_SUBMIT_REQUEST_COMPLETED",
  COMMON_MODAL_SUBMIT_FAILURE: "COMMON_MODAL_SUBMIT_FAILURE",
  COMMON_MODAL_SET_INITIAL: "COMMON_MODAL_SET_INITIAL",
  UPDATE_UOM_SUCCESS: "UPDATE_UOM_SUCCESS",
  COMMON_MODAL_ACTIVE: "COMMON_MODAL_ACTIVE",
  UPDATE_AWARD_LOTS: "UPDATE_AWARD_LOTS",
  LOT_LOADER_REQUESTED: "LOT_LOADER_REQUESTED",
  FINISH_LOT_LOADER_REQUEST: "FINISH_LOT_LOADER_REQUEST",
  UPDATE_AWARD_LOT: "UPDATE_AWARD_LOT",
  UPDATE_BLIC_LIST: "UPDATE_BLIC_LIST",
  SET_BLIC_LIST: "SET_BLIC_LIST",
  GET_BLIC_LIST: "GET_BLIC_LIST",
  BID_SUBMITTED: "BID_SUBMITTED",
  BID_SUBMITTED_PENDING: "BID_SUBMITTED_PENDING",
  FLASH_FOR_UPLOAD: "FLASH_FOR_UPLOAD",
  LOT_REJECTED: "LOT_REJECTED",
  UPDATE_BLICS: "UPDATE_BLICS",
  MAKE_PARTICIPANT_PERMISSION: "MAKE_PARTICIPANT_PERMISSION",
  DELETE_PARTICIPANT_PERMISSION: "DELETE_PARTICIPANT_PERMISSION",
  CANCEL_BID: "CANCEL_BID",
  CANCEL_BID_PENDING: "CANCEL_BID_PENDING",
  UPLOAD_BID_TEMPLATE: "UPLOAD_BID_TEMPLATE",
  BLIC_ERROR: "BLIC_ERROR",
  BID_RANGE: "BID_RANGE",
  UPDATE_ACTIVITY: "UPDATE_ACTIVITY",
  ERRORS: "ERRORS",
  EDIT_LINE_ITEM_COMPONENT: "EDIT_LINE_ITEM_COMPONENT",
  NEW_EDIT_LOT_COMPONENT: "NEW_EDIT_LOT_COMPONENT",
  LOT_ERROR: "LOT_ERROR",
  LOT_CHART_DATA: "LOT_CHART_DATA",
  MONITOR_LIVE_FEED: "MONITOR_LIVE_FEED",
  FETCH_SIM_QUESTIONS: "FETCH_SIM_QUESTIONS",
  CREATE_OR_UPDATE_LINKED_QUESTION: "CREATE_OR_UPDATE_LINKED_QUESTION",
  FETCH_SIM_QUESTIONNAIRES: "FETCH_SIM_QUESTIONNAIRES",
  DELETE_LINKED_QUESTION: "DELETE_LINKED_QUESTION",
  MESSAGE_ON_LIVE_FEED: "MESSAGE_ON_LIVE_FEED",
  MARK_AS_READ: "MARK_AS_READ",
  SET_INITIAL_ACTIVITY_STATE: "SET_INITIAL_ACTIVITY_STATE",
  ADD_ACTIVITY: "ADD_ACTIVITY",
  MUTATE_ACTIVITIES: "MUTATE_ACTIVITIES",
  REMOVE_ACTIVITY: "REMOVE_ACTIVITY",
  UPDATE_ACTIVITY_COUNT: "UPDATE_ACTIVITY_COUNT",
  TOGGLE_DISCARD_ACTIVITY_MODAL: "TOGGLE_DISCARD_ACTIVITY_MODAL",
  SET_MODAL_REFRESH: "SET_MODAL_REFRESH",
  RELOAD_ACTIVITY_LIST: "RELOAD_ACTIVITY_LIST",
  SWITCH_ACTIVITY_TAB: "SWITCH_ACTIVITY_TAB",
  CLOSE_BID_MODAL: "CLOSE_BID_MODAL",
  OPEN_BID_MODAL: "OPEN_BID_MODAL",
  NO_ACTION: "NO_ACTION",
  CREATE_DOCUMENT_TYPE: "CREATE_DOCUMENT_TYPE",
  DELETE_DOCUMENT_TYPE: "DELETE_DOCUMENT_TYPE",
  SET_ACTIVE_LI: "SET_ACTIVE_LI",
  DELETE_LINE_ITEM_BLICS: "DELETE_LINE_ITEM_BLICS",
  CREATE_MSRFQ_ROUND: "CREATE_MSRFQ_ROUND",
  SET_ACTIVE_MSRFQ_ROUND: "SET_ACTIVE_MSRFQ_ROUND",
  SET_BEST_OVERALL_ROUND: "SET_BEST_OVERALL_ROUND",
  SET_CONTRACT_INITIAL_PROPS: "SET_CONTRACT_INITIAL_PROPS",
};
