import React, { Component } from "react";
import CommonModal from "../../../../../hoc/modalHOC/CommonModal";
import WithLoader from "../../../../../hoc/WithLoader";
import BestBidForModalWithLoader from "./BestBidForModalWithLoader";
export default class AllParticipantsBestBidForLotModal extends Component {
  render() {
    const { viewComponent, translations, apiCallingFunction, header } = this.props;
    return (
      <CommonModal
        viewComponent={viewComponent}
        header={header}
        content={<DisplayContentWithLoader {...this.props} />}
        modalCloseButtonText={translations.ok}
        modalCloseButtonClass="btn btn-sm btn-default"
        apiCallingFunction={apiCallingFunction}
      />
    );
  }
}

{
  /*  Need to pass beforeAuction={true} manually because event after
   * completing auction we need to show RFQ bids on RFQ screen if want to
   * set false then pass it in a props
   */
}

AllParticipantsBestBidForLotModal.defaultProps = {
  beforeAuction: true,
  isAllParticipant: false,
};

const DisplayContent = (props) => {
  return <BestBidForModalWithLoader {...props} />;
};

const DisplayContentWithLoader = WithLoader(DisplayContent);
