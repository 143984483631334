import React from "react";
import { AnswerConsumer } from "../../../../../../context/AnswerContext";
import { getTabElements } from "./services";
const GetTab = (props) => {
  const { activeTab, updateActiveTab } = props;
  const tabElements = (contextProps) => {
    return getTabElements(props, contextProps, activeTab, updateActiveTab);
  };
  return (
    <AnswerConsumer>
      {(contextProps) => {
        return (
          <div className="special-tab-content tab-status-current two-block-box p-b0 p-t5">
            <ul className="nav nav-tabs m-b0" data-toggle="tabs">
              {tabElements(contextProps)}
            </ul>
          </div>
        );
      }}
    </AnswerConsumer>
  );
};
export default GetTab;
