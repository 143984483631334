/**
 * Pluing to a a select element into the flatpicker calendar container in the
 * footer.
 *
 * @param {string} select - The id of the select element to add to the calendar
 */
export default function FlatPickerSelect({ select }) {
  const selectElement = document.getElementById(select);
  if (!selectElement) {
    return () => {};
  }

  const targetSelectNode = selectElement.cloneNode(true);
  selectElement.style.display = "none";

  function handleSelectionChange() {
    selectElement.value = this.value;
  }

  return function (flatPicker) {
    return {
      onReady() {
        const wrapper = document.createElement("div");
        wrapper.classList.add("flatpickr-select-wrapper");

        targetSelectNode.addEventListener("change", handleSelectionChange);

        wrapper.appendChild(targetSelectNode);
        flatPicker.calendarContainer.appendChild(wrapper);
      },
      onDestroy() {
        targetSelectNode.removeEventListener("change", handleSelectionChange);
      },
    };
  };
}
