import * as actionTypes from "../actions/actionsTypes";

const picklistAndUnitSetReducers = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.CREATE_PICKLIST:
    case actionTypes.UPDATE_PICKLIST:
      return {
        ...state,
        picklist: payload.picklist,
        picklistOptions: payload.picklistOptions,
        actionType: action.type,
      };
    case actionTypes.CREATE_UNIT_SET:
    case actionTypes.UPDATE_UNIT_SET:
      return {
        ...state,
        unitSet: payload.unitSet,
        unitOfMeasures: payload.unitOfMeasures,
        actionType: action.type,
      };
    default:
      return state;
  }
};

export default picklistAndUnitSetReducers;
