import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import ReactTimeout from "react-timeout";
import Loader from "react-loader-advanced";

import Summary from "./Summary";
import ParticipantList from "./ParticipantList";
import Messages from "./Messages";
import {
  getMaterialRequestById,
  updateDeadline,
  getAwardedLots,
} from "../../../../../actions/qq/emarketplace/emSummaryActions";
import AwardLots from "./AwardLots";
import { confirm } from "../../../util/confirm";
import * as emListingActions from "../../../../../actions/qq/emarketplace/emListingActions";
import { notifyParticipant } from "../../../../../actions/qq/editActions";
import LoaderMessage from "../../../LoaderMessage";
import { emptyCurrentState, changeEventState } from "../../../../../actions/qq/genericActions";
import SeeBrief from "./SeeBrief";

class EMSummaryStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      deadlineEditMode: false,
      declinedParticipantsList: [],
      showAwardLotButton: false,
      updatedEvent: {},
    };
  }

  async UNSAFE_componentWillMount() {
    const requestId = this.props.event.id;
    if (requestId) {
      // TODO(ade): Work with the rails devs to do all this server side. We are
      // now passing the event in as a props that is from the server so there
      // is no need to get it from the API.
      const result = await this.props.getRequestById(requestId);
      if (result.status !== 200) {
        this.props.history.push(`/emarketplace/request/${requestId}/notfound`);
      } else if (result.data.event && result.data.event.emarketplace) {
        this.setState({
          showLoader: false,
        });
        if (result.data.event.current_state === "edit") {
          this.setState(
            {
              deadlineEditMode: true,
            },
            () => {
              const deadline = result.data.event.bid_deadline;
              const zoneIdx = deadline.indexOf("+");
              const zoneString = deadline.substring(zoneIdx);
              this.deadlineInput.value = moment(deadline).utcOffset(zoneString).format("YYYY-MM-DD HH:mm");
              this.deadlineInput.click();
            }
          );
        }
      } else {
        this.props.history.push("/emarketplace/unauthorised");
      }
      const response = await this.props.getAwardedLots(requestId);
      if (response.status) {
        this.setState({
          showAwardLotButton: true,
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const declinedParticipants = [];
    if (!_.isEmpty(newProps.requestDetail)) {
      if (newProps.requestDetail.bids_summary) {
        newProps.requestDetail.bids_summary.event_participants.forEach((participant) => {
          if (participant.status === "Declined") {
            declinedParticipants.push(participant.user_id);
          }
        });
        this.setState({
          declinedParticipantsList: declinedParticipants,
        });
      }
      this.props.setTimeout(() => {
        this.setState({
          showLoader: false,
        });
      }, 2000);
    }
    if (!_.isEmpty(newProps.updatedEvents.updatedEvent)) {
      this.setState({
        updatedEvent: newProps.updatedEvents.updatedEvent,
      });
    }
  }

  componentWillUnmount() {
    this.props.emptyCurrentState("GET_REQUEST_BY_ID", undefined);
    this.props.emptyCurrentState("CONDITIONAL_CHANGE_STATE_MR", undefined);
  }

  onDateSelected(event) {
    $("#emarketplace-deadline-custom-date-time").datetimepicker({
      format: "Y-m-d H:i",
      minDate: 0,
      step: 60,
      minTime: 0,
      scrollMonth: false,
      scrollInput: false,
      onChangeDateTime(currentDateTime) {
        if (currentDateTime && currentDateTime.getDate() === new Date().getDate()) {
          this.setOptions({ minTime: 0 });
        } else {
          this.setOptions({ minTime: false });
        }
      },
      onShow(currentDateTime) {
        if (currentDateTime.getDate() === new Date().getDate()) {
          this.setOptions({ minTime: 0 });
        } else {
          this.setOptions({ minTime: false });
        }
      },
    });
  }

  confirmation(event, EMRequest, mrState) {
    event.preventDefault();
    let popupContext = "";
    if (mrState === "close") {
      popupContext = this.props.translations.emarketplace.pop_up.confirm_popup_close;
    } else {
      popupContext = this.props.translations.emarketplace.pop_up.confirm_popup_reopen;
    }
    let pop_up_translations = {
      ConfirmLabel: this.props.translations.pop_up.confirm,
      okLabel: this.props.translations.pop_up.ok,
      cancelLabel: this.props.translations.pop_up.cancel,
    };
    confirm(popupContext, pop_up_translations).then(
      () => {
        this.props.changeEventState(EMRequest.id, mrState);
        if (this.state.searchTerm) {
          this.setState({ searchTerm: "" });
          if (this.props.page > 1) {
            this.props.history.push("/emarketplace/?page=1");
          }
        }
      },
      () => {
        // `cancel` callback
      }
    );
    return false;
  }

  searchByMRState(e, currentState) {
    e.preventDefault();
    this.props.history.push(`/emarketplace/?view=${currentState.toLowerCase()}`);
  }

  async editDeadline(e, deadline, requestId) {
    e.target.disabled = true;
    const zoneIdx = deadline.indexOf("+");
    const zoneString = deadline.substring(zoneIdx);
    $("#emarketplace-deadline-custom-date-time").click();
    await this.props.emptyCurrentState("CHANGE_EVENT_STATE", undefined);
    const data = { event: { current_state: "edit" } };
    const response = await this.props.updateDeadline(requestId, data);
    if (response.status === 200) {
      this.setState(
        {
          deadlineEditMode: true,
        },
        () => {
          this.deadlineInput.value = moment(deadline).utcOffset(zoneString).format("YYYY-MM-DD HH:mm");
          this.deadlineInput.click();
        }
      );
    } else if (e.target.disabled) {
      e.target.disabled = false;
    }
  }

  async cancelDeadlineEdit(e, requestId) {
    e.preventDefault();
    const data = { event: { current_state: "finish_editing" } };
    const response = await this.props.updateDeadline(requestId, data);
    if (response.status === 200) {
      this.setState({
        deadlineEditMode: false,
      });
    }
  }

  async saveDeadline(e, requestId) {
    e.preventDefault();
    this.setState({
      showLoader: true,
    });
    const data = { event: { deadline: this.deadlineInput.value, current_state: "finish_editing" } };
    const result = await this.props.updateDeadline(requestId, data);
    if (result.status === 200) {
      await this.props.notifyParticipant(requestId, true, "");
      this.setState({
        deadlineEditMode: false,
        showLoader: false,
      });
    } else {
      this.setState({
        showLoader: false,
      });
      this.deadlineInput.click();
    }
  }

  render() {
    const requestId = this.props.event.id;
    const { translations } = this.props;
    let { data } = this.props.requestDetail;
    if (data && !_.isEmpty(this.state.updatedEvent)) {
      data = this.state.updatedEvent;
    }
    return (
      <div className="top-content">
        <div className="container">
          <Loader
            show={this.state.showLoader}
            message={<LoaderMessage loaderText={translations.loader_text} />}
            disableDefaultStyles
            priority={1}
          >
            <div className="wrapper">
              <div className="body_section material_page_sec">
                <div className="container">
                  {requestId && data ? (
                    <div>
                      {data.current_state === "edit" && (
                        <div className="alert alert-warning">
                          {translations.emarketplace.summary_status.deadline_editmode_alert}
                        </div>
                      )}
                      <div className="col-md-12 request-quote p-0">
                        <div className="pull-left left-box">
                          {(data.current_user_has_edit_permission === true && (
                            <div
                              className={`btn-group
                            button-status button-${
                              data.current_state === "closed" ? data.current_state.toLowerCase() : "current"
                            }`}
                            >
                              <button
                                type="button"
                                className={`dropdown-toggle status-event-summary
                              ${
                                data.current_state === "closed" ? data.current_state.toLowerCase() : "current"
                              }  form-control`}
                                data-toggle="dropdown"
                              >
                                {data.current_state === "closed"
                                  ? translations.qq_listing.qq_list_table.closed
                                  : translations.summary_status.current}
                                <span className="caret" />
                              </button>
                              <ul className="dropdown-menu" role="menu">
                                <li label={data.current_state === "completed" ? "current" : data.current_state}>
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      this.searchByMRState(
                                        e,
                                        data.current_state === "completed" ? "current" : data.current_state
                                      );
                                    }}
                                  >
                                    {data.current_state === "closed"
                                      ? translations.qq_listing.qq_list_table.closed
                                      : translations.summary_status.current}
                                  </a>
                                </li>
                                {data.current_state === "closed" && (
                                  <li label="reopen">
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        this.confirmation(e, data, "reopen");
                                      }}
                                    >
                                      {translations.qq_listing.qq_list_table.reopen}
                                    </a>
                                  </li>
                                )}
                                {data.current_state === "completed" && (
                                  <li label="close">
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        this.confirmation(e, data, "close");
                                      }}
                                    >
                                      {translations.qq_listing.qq_list_table.close_event}
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )) || (
                            <div
                              className={`btn-group
                            button-status button-${
                              data.current_state === "closed" ? data.current_state.toLowerCase() : "current"
                            }`}
                            >
                              <button
                                type="button"
                                className={`dropdown-toggle status-event-summary
                              ${
                                data.current_state === "closed" ? data.current_state.toLowerCase() : "current"
                              }  form-control`}
                                data-toggle="dropdown"
                              >
                                {data.current_state === "closed"
                                  ? translations.qq_listing.qq_list_table.closed
                                  : translations.summary_status.current}
                                <span className="caret" />
                              </button>
                              <ul className="dropdown-menu" role="menu">
                                <li
                                  label={
                                    data.current_state === "completed" || data.current_state === "rfq"
                                      ? "current"
                                      : data.current_state
                                  }
                                >
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      this.searchByMRState(
                                        e,
                                        data.current_state === "completed" || data.current_state === "rfq"
                                          ? "current"
                                          : data.current_state
                                      );
                                    }}
                                  >
                                    {data.current_state === "closed"
                                      ? translations.qq_listing.qq_list_table.closed
                                      : translations.summary_status.current}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                          <h2 className="remove-line">{data.name}</h2>
                          {data.user.company.publicly_visible_prefix_event && (
                            <div className="id-box">
                              <b>{translations.summary_status.id} :</b> {data.id}
                            </div>
                          )}
                        </div>
                        <div className="pull-right date-box">
                          <ul>
                            {this.state.deadlineEditMode && data.current_user_has_edit_permission ? (
                              <div className="edit-deadline">
                                <input
                                  id="emarketplace-deadline-custom-date-time"
                                  className="form-control date-picker-cstm"
                                  type="text"
                                  onClick={(e) => {
                                    this.onDateSelected(e);
                                  }}
                                  ref={(ref) => {
                                    this.deadlineInput = ref;
                                  }}
                                  autoComplete="off"
                                />
                                <a
                                  href="#"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Save"
                                  onClick={(e) => {
                                    this.saveDeadline(e, data.id);
                                  }}
                                >
                                  {" "}
                                  <span className="edit-button clearfix small tick fa fa-check text-success" />
                                </a>
                                <a
                                  href="#"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Cancel"
                                  onClick={(e) => {
                                    this.cancelDeadlineEdit(e, data.id);
                                  }}
                                >
                                  {" "}
                                  <span className="edit-button clearfix small tick fa fa-remove text-danger" />
                                </a>
                              </div>
                            ) : (
                              <li>
                                <b>
                                  <i className="fa fa-clock-o" aria-hidden="true" />
                                  &nbsp;
                                  {moment(data.bid_deadline).isBefore(new Date())
                                    ? translations.emarketplace.em_list_table.mr_finished
                                    : translations.emarketplace.em_list_table.mr_deadline}{" "}
                                  :
                                </b>
                                &nbsp;
                                <span>{data.formatted_bid_deadline || "-"}</span>&nbsp;
                                {data.current_state !== "closed" && data.current_user_has_edit_permission && (
                                  <button
                                    type="button"
                                    className="edit-deadline-btn fa fa-pencil text-danger"
                                    data-toggle="tooltip"
                                    title="Edit deadline!"
                                    data-placement="top"
                                    onClick={(e) => {
                                      this.editDeadline(e, data.bid_deadline, data.id);
                                    }}
                                  />
                                )}
                              </li>
                            )}
                            <li>
                              <b>{translations.summary_status.owner}:</b> {data.user.name}
                            </li>
                          </ul>
                          <div className="pull-right award-button">
                            {moment(data.bid_deadline).isBefore(new Date()) && data.current_state !== "edit" ? (
                              <AwardLots
                                translations={translations}
                                toolTipTranslations={this.props.tooltip_translations}
                                requestId={requestId}
                                declinedParticipantsList={this.state.declinedParticipantsList}
                                showAwardLotButton={this.state.showAwardLotButton}
                                userHasEditPermission={data.current_user_has_edit_permission}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <br />
                        </div>
                      </div>
                      <SeeBrief translations={translations} eventBrief={data.event_brief} />
                      <Summary translations={translations} requestId={requestId} />
                      <ParticipantList translations={translations} requestId={requestId} requestName={data.name} />
                      <Messages
                        user={this.props.user}
                        translations={translations}
                        requestId={requestId}
                        requestName={data.name}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Loader>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedLang: state.changeLanguageReducer,
  requestDetail: state.emSummaryReducer,
  updatedEvents: state.updateEventStateReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getRequestById: (requestId) => dispatch(getMaterialRequestById(requestId)),
  updateDeadline: (requestId, data) => dispatch(updateDeadline(requestId, data)),
  notifyParticipant: (requestId, status, message) => dispatch(notifyParticipant(requestId, status, message)),
  emptyCurrentState: (actionType, payload) => dispatch(emptyCurrentState(actionType, payload)),
  getAwardedLots: (requestId) => dispatch(getAwardedLots(requestId)),
  changeEventState: (requestId, state) => dispatch(changeEventState(requestId, state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReactTimeout(EMSummaryStatus));
