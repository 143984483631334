import React from "react";
import classNames from "classnames";

const TabsHeader = (props) => {
  const { activeTab, setActiveTab, translations } = props;
  const liValue = (translation) => (
    <li
      className={classNames({
        active: activeTab === translations[translation],
      })}
      onClick={() => setActiveTab(translations[translation])}
    >
      <a>{translations[translation]}</a>
    </li>
  );

  return (
    <div className="content special-tab-content tab-status-current lg-tab-list">
      <ul className="nav nav-tabs">
        {liValue("show_all_bids")}
        {liValue("show_participants_best_bids")}
      </ul>
    </div>
  );
};

export default TabsHeader;
