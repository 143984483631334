import React, { forwardRef } from "react";
import clsx from "clsx";
import { useAppSelector } from "@/hooks/redux";
import CustomFieldsForm from "./custom-field-form";
import type { ComponentName } from "./pick-one-from-list";
import type { CustomFieldsFormProps } from "./custom-field-form";

export interface Field {
  id: number;
  name: string;
  required: boolean;
  active: boolean;
  column_type: string;

  // These are going to be the options for the field if it's a select, otherwise
  // this will be an empty array
  custom_column_options: CustomColumnOption[];

  // These are going to be the values for the field. This array will only have
  // one entry in case of pick-one-from-list and all the other type of CF except
  // multiple_choice. It will have multiple entries in case of multiple_choice.
  custom_column_values: CustomColumnValue[];
}

export interface CustomColumnOption {
  name: string;
}

export interface CustomColumnValue {
  name: string | string[];
}

export interface CustomFieldsFormWrapperProps {
  componentName?: ComponentName;
  customFields?: Field[];
  contractId?: number;
  showPage?: boolean;
  contactId?: number;
  hide_table_header?: boolean;
  height?: number | string;
  eventId?: number;
  readOnly?: boolean;
  hideTitle?: boolean;
}

export type CustomFieldsFormRequiredProps = Required<
  Pick<CustomFieldsFormWrapperProps, "componentName" | "customFields" | "showPage" | "contractId">
>;

type ReturnedProps = Omit<CustomFieldsFormProps, "isContractModule">;

interface SelectorResult {
  componentName: ComponentName | undefined;
  customFields: Field[] | undefined;
  showPage: boolean | undefined;
  contractId: number | undefined;
}

/**
 * We are getting all the data from the redux store for the Contracts module.
 * And then passing them as props in the child component.
 * This function will make sure that we are getting everything we need from the
 * store and define their types accordingly.
 *
 * Note: We can't get all these data from the store in other modules ATM because
 * the reducer is not initialized for them. We are getting the data via props.
 */
function areDefined(
  obj: SelectorResult
): obj is { [K in keyof SelectorResult]: Exclude<SelectorResult[K], undefined> } {
  return Object.values(obj).every((value) => value !== undefined);
}

const useCustomFieldsFormProps = (props: CustomFieldsFormWrapperProps): ReturnedProps => {
  const propsForContracts: SelectorResult = {
    componentName: useAppSelector(({ createEditReducers }) => createEditReducers.componentName),
    customFields: useAppSelector(({ createEditReducers }) => createEditReducers.customFields),
    showPage: useAppSelector(({ createEditReducers }) => createEditReducers.showPage),
    contractId: useAppSelector(({ createEditReducers }) => createEditReducers.contract?.id),
  };

  if (areDefined(propsForContracts)) {
    return { ...propsForContracts };
  }

  // Renaming the 'hide_table_header' to 'hideTableHeader' just to follow js
  // naming convension
  return { ...props, hideTableHeader: props.hide_table_header } as ReturnedProps;
};

const CustomFieldsFormWrapper = forwardRef<HTMLDivElement, CustomFieldsFormWrapperProps>((props, ref) => {
  const data = useCustomFieldsFormProps(props);
  const isContractModule = data.componentName === "CreateEditContainer";

  return (
    <div className={clsx({ "col-md-12 col-sm-12": isContractModule })} ref={ref}>
      <CustomFieldsForm {...data} isContractModule={isContractModule} />
    </div>
  );
});

CustomFieldsFormWrapper.displayName = "CustomFieldsFormWrapper";
export default CustomFieldsFormWrapper;
