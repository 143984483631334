import React, { Component } from "react";
import { connect } from "react-redux";

import InfoPopover from "../../../InfoPopover";
import EMListElement from "./EMListElement";

class EMListTable extends Component {
  render() {
    // pagination
    const tooltipText = this.props.tooltipData;
    const { searchedRequests, perPage, translations, company } = this.props;
    let startCount = 0;
    return (
      <div>
        {tooltipText && (
          <div className="event-list event-list-scroll">
            <table className="list-table">
              <thead className="table-block-title">
                <tr>
                  <th>
                    {translations.emarketplace.em_list_table.status}
                    <InfoPopover
                      title={tooltipText.host.event.status.title}
                      popoverContent={tooltipText.host.event.status.body}
                    />
                  </th>
                  <th>
                    {translations.emarketplace.em_list_table.material_request_name}
                    <InfoPopover
                      title={tooltipText.host.event.name.title}
                      popoverContent={tooltipText.host.event.name.body}
                    />
                  </th>
                  <th>
                    {translations.emarketplace.em_list_table.material_request_owner}
                    <InfoPopover
                      title={tooltipText.host.event.owner.title}
                      popoverContent={tooltipText.host.event.owner.body}
                    />
                  </th>
                  <th>
                    {translations.emarketplace.em_list_table.next_key_deadline}
                    <InfoPopover
                      title={tooltipText.host.event.time_until_event.title}
                      popoverContent={tooltipText.host.event.time_until_event.body}
                    />
                  </th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {searchedRequests.map((EMRequest) => {
                  if (startCount < perPage) {
                    startCount += 1;
                    return (
                      <EMListElement
                        key={EMRequest.id}
                        EMRequest={EMRequest}
                        searchByStatus={(requestState) => {
                          this.props.searchByStatus(requestState);
                        }}
                        confirmation={(request, state) => {
                          this.props.confirmation(request, state);
                        }}
                        translations={translations}
                        tooltipText={tooltipText}
                        company={company}
                      />
                    );
                  }
                  return false;
                })}
                {searchedRequests.length === 0 && !this.props.showLoader && (
                  <tr className="no_data center">
                    <td colSpan="7">{translations.emarketplace.em_list_table.material_request_not_found}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLang: state.changeLanguageReducer,
});

export default connect(mapStateToProps, null)(EMListTable);
