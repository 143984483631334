import React, { useState } from "react";
import { ConditionalElements } from "./ConditionalElements";
import { useSelector } from "react-redux";

const ShowBeforeAuctionDetail = (props) => {
  const { numberOfBids, canShowDeleteBtn, role } = props;
  const [isOpen, setIsOpen] = useState(false);
  const lotReducers = useSelector((state) => state.lotReducers);
  // Need to check why lotReducers is using here ?
  const {
    openModal,
    // bid_line_item_components: bidLineItemComponents,
    // bids,
    errors,
    lotsBidRange,
  } = lotReducers;
  const conditionalElementProps = {
    ...props,
    isOpen,
    setIsOpen,
    openModal,
    errors,
    lotsBidRange,
    canShowDeleteBtn,
    role,
  };
  return (
    <>
      <td> {numberOfBids}</td>
      <ConditionalElements {...conditionalElementProps} />
    </>
  );
};

export default ShowBeforeAuctionDetail;
