import * as apiCall from "../api";
import actionTypes from "../actionsTypes";
import config from "../config";

const createRequestSuccess = (payload) => ({
  type: actionTypes.show_success,
  payload,
});

const createRequestFail = (error) => ({
  type: error.response.status,
  payload: error,
});

const createRequest = (data, message) => (dispatch) =>
  apiCall
    .post(`${config.BASE_URL}/events`, { event: data })
    .then((response) => {
      dispatch(createRequestSuccess(message));
      return response;
    })
    .catch((error) => {
      dispatch(createRequestFail(error));
      return error;
    });

export default createRequest;
