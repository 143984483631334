import { MANUALLY_SCORED_QUESTION_TYPES } from "./QuestionnaireQuestionCommon";

export const filterAnswersByQA = (qa, qqas) => qqas.filter((qqa) => qqa.questionnaire_answer_id === qa.id);

export const findQuetionForQQA = (questions, qqa) => questions.find((q) => q.id === qqa.questionnaire_question_id);

export const manuallyOrScoredByHuman = (qqa) => (qqa.manually_scored && !qqa.scored_by_human ? true : false);

export const notScoredByHumans = (questions, qqas) => {
  const questionIds = questions.filter((question) => question.scoring > 0).map((question) => question.id);
  return qqas.filter(
    (qqa) => qqa.manually_scored && !qqa.scored_by_human && questionIds.includes(qqa.questionnaire_question_id)
  );
};

export const weightedScore = (qqa) => {};

export const score = (qqa, question) => {
  return qqa.manually_scored ? qqa.given_score : calculatedScore(qqa, question);
};

export const calculatedScore = (qqa, question) => {
  let score = 0;

  if (
    MANUALLY_SCORED_QUESTION_TYPES.includes(question.question_type) ||
    qqa.manually_scored ||
    !Array.isArray(qqa.content)
  ) {
    return score;
  }
  // qqa.content.forEach(cid => {
  //    score += score +
  // });
};
