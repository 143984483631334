import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Provider as AlertContainer } from "react-alert";
import AlertTemplate from "react-alert-template-oldschool-dark";

import { fetchDocumentTemplate } from "../../../../actions/qq/genericActions";
import { alertOptions } from "../../js/common";

class DocumentLibraryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModel: false,
      documentTemplates: {},
      selectedDocumentTemplate: [],
    };
  }

  static getDerivedStateFromProps(newProps, prevState) {
    if (newProps.documentTemplates.documentResponse) {
      return {
        documentTemplates: newProps.documentTemplates.documentPayload,
        hasTemplates: newProps.documentTemplates.documentResponse,
      };
    }
    return null;
  }

  openPopup(e, companyId) {
    e.preventDefault();
    const { fetchDocumentTemplate } = this.props;
    this.setState({ hasTemplates: false });
    fetchDocumentTemplate(companyId);
    this.setState({ showModel: true });
  }

  close() {
    this.setState({ showModel: false, selectedDocumentTemplate: [] });
  }

  uploadByDocumentTemplate() {
    let { selectedDocumentTemplate } = this.state;
    let { RefreshDocuments, parentComponent, dropzoneFiles } = this.props;
    if (parentComponent == "Documents") {
      let documentLibraryuploads = localStorage.getItem("documentLibraryuploads");
      documentLibraryuploads = JSON.parse(documentLibraryuploads);
      documentLibraryuploads.documents = [...documentLibraryuploads.documents, ...selectedDocumentTemplate];
      localStorage.setItem("documentLibraryuploads", JSON.stringify(documentLibraryuploads));
      RefreshDocuments(documentLibraryuploads.documents);
    } else {
      let restField = localStorage.getItem("restField");
      restField = JSON.parse(restField);
      restField.documents = [...restField.documents, ...selectedDocumentTemplate];
      localStorage.setItem("restField", JSON.stringify(restField));
      let withOutDropZoneFile = _.differenceBy(restField.documents, dropzoneFiles, "source");
      RefreshDocuments(withOutDropZoneFile);
    }
    this.setState({ selectedDocumentTemplate: [] });
    this.close();
  }

  documentTemplateChange(e, documentTemplate) {
    let { selectedDocumentTemplate } = this.state;
    let { docsLimit, dropzoneFileAndDocumentLibraryCount, translations } = this.props;
    let documentKeyValue = {
      name: documentTemplate.display_name,
      id: documentTemplate.id,
    };
    if (e.target.checked) {
      selectedDocumentTemplate.push(documentKeyValue);
    } else {
      _.remove(selectedDocumentTemplate, documentKeyValue);
    }
    let limitWithNewAndAlreadyUploaded = dropzoneFileAndDocumentLibraryCount + selectedDocumentTemplate.length;
    if (docsLimit < limitWithNewAndAlreadyUploaded) {
      if (this.msg && this.msg.state.alerts.length === 0) {
        this.msg.error(translations.error_message.document_maxfile_alert);
      }
      _.remove(selectedDocumentTemplate, documentKeyValue);
      e.target.checked = false;
    }
    this.setState({ selectedDocumentTemplate });
  }

  render() {
    const { companyId, translations } = this.props;
    const { showModel, documentTemplates, hasTemplates } = this.state;
    return (
      <div className="m-t20 m-b10">
        <button type="button" className="btn btn-primary btn-fontsmall" onClick={(e) => this.openPopup(e, companyId)}>
          {translations.qq_create.qq_documents.from_library}
        </button>
        <div className="main-popup popup-md">
          <Modal show={showModel} onHide={() => {}}>
            <div className="modal-content clearfix">
              <Modal.Header>
                <h4 className="modal-title">{translations.qq_create.qq_documents.upload_new_document}</h4>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.close();
                  }}
                  className="close-icon modal-btn-close"
                >
                  <i className="fa fa-times" aria-hidden="true" />
                </a>
              </Modal.Header>
              <Modal.Body>
                <div className="modal-body popover-content-inner">
                  {!_.isEmpty(documentTemplates) ? (
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>{translations.name}</th>
                          <th className="text-center">{translations.version}</th>
                          <th className="text-center">{translations.qq_create.qq_documents.upload}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentTemplates.map((documentTemplate, position) => (
                          <tr key={documentTemplate.id} className="document">
                            <td className="generated-text">{documentTemplate.display_name}</td>
                            <td className="text-center">{documentTemplate.template_version}</td>
                            <td className="doc_tickbox text-center">
                              <div className="checkbox">
                                <input
                                  type="checkbox"
                                  value={documentTemplate.filename.url}
                                  id={`document_checkbox-${documentTemplate.id}`}
                                  onChange={(e) => {
                                    this.documentTemplateChange(e, documentTemplate);
                                  }}
                                />
                                <label htmlFor={`document_checkbox-${documentTemplate.id}`}>
                                  <span />
                                </label>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : hasTemplates && _.isEmpty(documentTemplates) ? (
                    translations.qq_create.qq_documents.no_document
                  ) : null}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="modal-footer">
                  {!_.isEmpty(documentTemplates) && (
                    <button
                      type="button"
                      className="btn btn-primary btn-fontsmall"
                      onClick={() => this.uploadByDocumentTemplate()}
                    >
                      {translations.qq_create.qq_documents.uploads}
                    </button>
                  )}
                </div>
              </Modal.Footer>
            </div>
          </Modal>
          <AlertContainer
            template={AlertTemplate}
            _ref={(alert) => {
              this.msg = alert;
            }}
            {...alertOptions}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  documentTemplates: state.documentTemplateReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDocumentTemplate: (companyId) => dispatch(fetchDocumentTemplate(companyId)),
});

DocumentLibraryModal.defaultProps = {
  dropzoneFiles: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentLibraryModal);
