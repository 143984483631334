import type { Nullish, TupleOfLength } from "@/types/util";

/**
 * Filters out duplicate values in an array, using a custom function to determine uniqueness.
 * Derived result will be compared with SameValueZero equality.
 *
 * @param fn Function to derive a value from each item to determine uniqueness. Defaults to the identity function.
 *
 * @example
 * arr.filter(uniqueBy());
 * arr.filter(uniqueBy((item) => item.id))
 */
export const uniqueBy = <T>(fn: (value: T) => unknown = (v) => v) => {
  const seen = new Set<unknown>();
  return (value: T) => {
    const derived = fn(value);
    if (seen.has(derived)) return false;
    seen.add(derived);
    return true;
  };
};

/**
 * Toggles the inclusion of an element in an array. *Mutates* in place.
 * @param arr Array to be modified
 * @param item Item to be toggled
 * @param predicate Predicate to find index of item. If not included, `indexOf` (strict equality) will be used.
 * @returns Array after modification
 */
export const toggleInArray = <T>(arr: T[], item: T, predicate?: Parameters<T[]["findIndex"]>[0]) => {
  const idx = predicate ? arr.findIndex(predicate) : arr.indexOf(item);
  if (idx >= 0) {
    arr.splice(idx, 1);
  } else {
    arr.push(item);
  }
  return arr;
};

/**
 * Toggle a key in a dictionary object. Deletes key if present, otherwise sets it to true. *Mutates* in place.
 * @param record Record to be modified
 * @param key Key to toggle
 */
export const toggleKey = <K extends PropertyKey>(record: Partial<Record<K, true>>, key: K) => {
  if (record[key]) {
    delete record[key];
  } else {
    record[key] = true;
  }
};

/**
 * Wraps Object.entries to preserve keys.
 * This is technically an unsafe assertion because structural typing allows surplus keys, so use with caution.
 * @example
 * const obj = { a: 1, b: 2 };
 * const entries = objectEntries(obj); // [["a", 1], ["b", 2]]
 *
 * function receiveObj(obj: { a: number }) {
 *  const entries = objectEntries(obj); // typescript thinks this is [["a", 1]] but it's actually [["a", 1], ["b", 2]]
 * }
 *
 * receiveObj(obj);
 */
export const objectEntries = Object.entries as <T>(o: T) => { [K in keyof T]: [K, T[K]] }[keyof T][];

/**
 * Wraps Object.keys to preserve keys.
 * This is technically an unsafe assertion because structural typing allows surplus keys, so use with caution.
 * @example
 * const obj = { a: 1, b: 2 };
 * const keys = objectKeys(obj); // ["a", "b"]
 *
 * function receiveObj(obj: { a: number }) {
 *   const keys = objectKeys(obj); // typescript thinks this is ["a"] but it's actually ["a", "b"]
 * }
 *
 * receiveObj(obj);
 */
export const objectKeys = Object.keys as <T>(o: T) => (keyof T)[];

export const hasLengthOf = <T, N extends number>(arr: T[], length: N): arr is TupleOfLength<T, N> =>
  arr.length === length;

export const hasAtLeastLengthOf = <T, N extends number>(arr: T[], length: N): arr is TupleOfLength<T, N> & T[] =>
  arr.length >= length;

export const safeAssign: <T>(target: T, ...sources: Partial<T>[]) => T = Object.assign;

export const wait = (ms: number = 0) => new Promise<void>((resolve) => setTimeout(resolve, ms));

export const ensureArray = <T>(value: T): T extends ReadonlyArray<any> ? T : Array<T> =>
  (Array.isArray(value) ? value : [value]) as never;

export const toggleAll = <T>(currentSelection: T[], allItems: T[]) => {
  if (currentSelection.length >= allItems.length) {
    return [];
  }
  return allItems;
};

export const toggleAllKeys = <K extends PropertyKey>(
  record: Partial<Record<K, true>>,
  allItems: Array<K>
): Partial<Record<K, true>> => {
  const newKeys = toggleAll(Object.keys(record) as Array<K>, allItems);
  const newRecord: Partial<Record<K, true>> = {};
  for (const key of newKeys) {
    newRecord[key] = true;
  }
  return newRecord;
};

export type SortDirection = "asc" | "desc";

/**
 * Flips the number if the direction is descending.
 */
export const applySortDirection = (n: number, direction: SortDirection) => (direction === "asc" ? n : -n);

export interface CollatorOptionsWithLocales extends Intl.CollatorOptions {
  locales?: Intl.LocalesArgument;
}

type SortPredicate<T> = (a: T, b: T) => number;

const defaultCollatorOptions: Intl.CollatorOptions = {
  numeric: true,
  sensitivity: "base",
};

const badValues = new Set(["unknown", ""]);

export const getLang = () => {
  if (typeof document === "undefined") return undefined;
  const { lang } = document.documentElement;
  return badValues.has(lang) ? undefined : lang;
};

const isNullishString = (value: unknown) => value == null || typeof value === "string";

const compareNullishStrings = (a: Nullish<string>, b: Nullish<string>, collator: Intl.Collator) => {
  // both empty/nullish - equal
  if (!a && !b) return 0;

  // one empty/nullish - the other is greater
  if (!a || !b) return a ? -1 : 1;

  // both are strings, so we can use the collator
  return collator.compare(a, b);
};

/**
 * Default sort function that calls localeCompare for strings, and uses > for everything else.
 * @example
 * const arr = [3, 1, 2];
 * arr.sort(sortWithDir("asc")); // [1, 2, 3]
 *
 * const arr = ["Alice", "Bob"];
 * arr.sort(sortWithDir("desc")); // ["Bob", "Alice"]
 */
export const sortWithDir = <T>(
  direction: SortDirection = "asc",
  { locales = getLang(), ...options }: CollatorOptionsWithLocales = {}
): SortPredicate<T> => {
  // we take the risk of making a Collator even if we don't need it
  // because the alternative is localeCompare, which would make a Collator every time it's called
  // so sorting strings will be much faster, and sorting other things will be a little slower
  const collator = new Intl.Collator(locales, { ...defaultCollatorOptions, ...options });
  return (a, b) => {
    if (a === b) return 0;

    if (isNullishString(a) && isNullishString(b)) {
      return applySortDirection(compareNullishStrings(a, b, collator), direction);
    }

    const comp = a > b ? 1 : -1;
    return applySortDirection(comp, direction);
  };
};

/**
 * Derive a custom value from each array item before sorting.
 *
 * @example
 * const arr = [{ name: "Alice" }, { name: "Bob" }];
 * arr.sort(sortBy((item) => item.name));
 */
export const sortBy = <T, U>(
  selectValue: (item: T) => U,
  sortDirection: SortDirection = "asc",
  {
    locale,
    customSort,
  }: {
    locale?: CollatorOptionsWithLocales;
    /**
     * Custom sort function to use instead of the default.
     * Return a number as if the order was ascending, and the function will flip it if the order is descending.
     */
    customSort?: SortPredicate<U>;
  } = {}
): SortPredicate<T> => {
  const defaultSort = sortWithDir(sortDirection, locale);
  return (a, b) => {
    const aVal = selectValue(a);
    const bVal = selectValue(b);

    if (customSort) return applySortDirection(customSort(aVal, bVal), sortDirection);

    return defaultSort(aVal, bVal);
  };
};

/**
 * Sort an array of objects by a key.
 * @example
 * const arr = [{ name: "Alice" }, { name: "Bob" }];
 * arr.sort(sortByKey("name"));
 */
export const sortByKey = <T>(
  key: keyof T,
  direction: SortDirection = "asc",
  {
    customSorts = {},
    locale,
  }: {
    customSorts?: { [K in keyof T]?: (a: T[K], b: T[K], originalA: T, originalB: T) => number };
    locale?: CollatorOptionsWithLocales;
  } = {}
): SortPredicate<T> =>
  customSorts[key]
    ? (a, b) => applySortDirection(customSorts[key]!(a[key], b[key], a, b), direction)
    : sortBy((item) => item[key], direction, {
        locale,
      });

export function partition<T, U extends T>(
  arr: readonly T[],
  predicate: (item: T) => item is U
): [truthy: U[], falsy: Exclude<T, U>[]];
export function partition<T>(arr: readonly T[], predicate: (item: T) => boolean): [truthy: T[], falsy: T[]];
export function partition<T>(arr: readonly T[], predicate: (item: T) => boolean): [truthy: T[], falsy: T[]] {
  const truthy: T[] = [];
  const falsy: T[] = [];
  for (const item of arr) (predicate(item) ? truthy : falsy).push(item);
  return [truthy, falsy];
}
