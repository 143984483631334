import React, { useMemo } from "react";
import type { Contract } from "@/common-prop-types";
import { Multiselect } from "multiselect-react-dropdown";
import { selectColumnOptions, selectMultiFilter, multiFilterToggled } from "@/slices/contracts/listing";
import { useAppSelector, useAppDispatch } from "@/hooks/redux";
import type { CommonFilterInputProps } from "@/components/contract/new/listings/filter-input";

interface Option {
  id: string;
  text: string;
}

export function FilterInputText({ attribute }: CommonFilterInputProps) {
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => selectColumnOptions(state, attribute as keyof Contract));
  const values = useAppSelector((state) => selectMultiFilter(state, attribute));
  const valueOptions = useMemo(() => values?.map((v) => ({ id: v, text: v })) ?? [], [values]);
  const toggleItem = (_: Option[], { text }: Option) => dispatch(multiFilterToggled(attribute, text));

  return (
    <Multiselect
      id={`select_${attribute}`}
      options={options}
      selectedValues={valueOptions}
      displayValue="text"
      onSelect={toggleItem}
      onRemove={toggleItem}
      showCheckbox
      className=""
      placeholder=""
    />
  );
}
