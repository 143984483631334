import React from "react";
import { Button } from "@/cl/button";
import styles from "./node.module.scss";
import type { EntityState } from "@reduxjs/toolkit";
import type { Field } from "@/slices/rules/fields";
import { FieldsProvider } from "./field-context";
import { NodeComp } from "./node";
import { useAppDispatch } from "@/hooks/redux";
import { conditionAdded, rulesInitialised } from "@/slices/rules";
import { t } from "@/i18n";

interface NodeEditorProps {
  fields: EntityState<Field, Field["id"]>;
  rootId: string | null;
  loading?: boolean;
}

export const NodeEditor = ({ fields, rootId, loading }: NodeEditorProps) => {
  const dispatch = useAppDispatch();
  return (
    <FieldsProvider fields={fields}>
      <div className={styles.container}>
        {rootId && <NodeComp uuid={rootId} />}
        <Button
          brand="primary"
          className={styles.floatingButton}
          onClick={() => dispatch(rootId ? conditionAdded(rootId) : rulesInitialised())}
          spinning={loading}
        >
          {t("workflow_rules.add_condition")}
        </Button>
      </div>
    </FieldsProvider>
  );
};
