import React, { useReducer, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ActivityModal from "./ActivityModal";
import {
  createOrFetchDraftActivity,
  discardDraftActivity,
  fetchActivity,
  setRefreshModal,
  toggleDiscardActivityModal,
} from "../../actions/activityActions";

const activityButton = (props) => {
  const {
    permitted_to_create_activity,
    translations,
    custom_column_options,
    values_for_status,
    current_user,
    activities: allActivities,
    concernedParties: allConcernedParties,
    setActivities,
    setConcernedParties: setGlobalConcernedParties,
    activitySubject,
    paginationParams,
    setPaginationParams,
    hostOwnerEmails,
    setHostOwnerEmails,
    max_single_filesize: maxFileSize,
    showActivity,
    can_create_activiy: canCreateActivity,
    childActions,
  } = props;
  let { activityId } = props;
  const dispatch = useDispatch();

  const { contact_id, calledFromIndex, supplier_id, called_from, non_persistent_state } = useSelector(
    (state) => state.ActivityReducer
  );
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    modalIsOpen: false,
    draftActivity: undefined,
    concernedParties: [],
    notes: [],
    documents: [],
  });

  // Disable the New Activity button and Edit Activity button if the custom column options (priority and activity_type) are not present and display the info message
  const showDropDownFieldMessage =
    activityTypes(custom_column_options).length === 0 || priorityOptions(custom_column_options).length === 0;

  // Disable the New Activity button if the custom column options (priority and activity_type) are not present and the company has SRM trial licence and they have exhausted their limit for the week
  const disableBtn = showDropDownFieldMessage || !canCreateActivity;

  const fetchDraftActivity = (changeModal = true) => {
    const params = {
      activity: {
        activity_type: defaultActivityTypeId(custom_column_options),
        priority: defaultPriorityOptionId(custom_column_options),
      },
    };
    if (contact_id) params.contact_id = contact_id;
    return dispatch(createOrFetchDraftActivity(params)).then((response) => {
      if (response && response.status === 200) {
        const { activity, concerned_parties, documents, notes } = response.data;
        let state = {
          draftActivity: activity,
          concernedParties: concerned_parties,
          documents: documents,
          notes: notes,
        };
        if (changeModal) {
          state.modalIsOpen = true;
        }
        setState({ ...state });
        return response.data || {};
      }
      return {};
    });
  };

  const fetchExistingActivity = () => {
    return dispatch(fetchActivity(activityId)).then((response) => {
      setState({
        draftActivity: response.activity,
        concernedParties: response.concerned_parties,
        documents: response.documents,
        notes: response.notes,
        modalIsOpen: true,
      });

      return response;
    });
  };

  // Change the setIsOpen state to true to display the modal
  const openModal = () =>
    new Promise((resolve, reject) => resolve(activityId ? fetchExistingActivity() : fetchDraftActivity(false))).then(
      (doc) => {
        if (activityId) {
          return doc;
        }

        let notExists = false;
        if (doc.concerned_parties.length) {
          doc.concerned_parties.map((party) => {
            if (party.account_id !== supplier_id && called_from === "SUPPLIER") {
              notExists = true;
            }
            if (party.concerned_id !== contact_id && called_from === "CONTACT") {
              notExists = true;
            }
          });
        }

        if (!calledFromIndex && notExists) {
          return dispatch(toggleDiscardActivityModal({ show: true, activity_draft_id: doc.activity.id }));
        } else {
          fetchDraftActivity();
        }
      }
    );

  // Change the setIsOpen state to false to close the modal
  const closeModal = () => setState({ modalIsOpen: false });
  useEffect(() => {
    if (non_persistent_state.refresh) {
      dispatch(
        setRefreshModal({
          refresh: false,
        })
      );
      dispatch(
        discardDraftActivity({
          hide: true,
        })
      );
      fetchDraftActivity();
    }
  }, [non_persistent_state.refresh]);

  const editOrCreateButton = () => {
    if (activityId) {
      if (activitySubject) {
        return (
          <a className="cursor-pointer" onClick={openModal} disabled={showDropDownFieldMessage}>
            {activitySubject}
          </a>
        );
      } else {
        return (
          <a className="btn btn-icon" title={translations.edit} onClick={openModal} disabled={showDropDownFieldMessage}>
            <i className="fa fa-pencil"></i>
          </a>
        );
      }
    } else {
      return (
        <button className="btn btn-primary btn-sm m-t4" onClick={openModal} disabled={disableBtn}>
          <i className="fa fa-plus-circle font-s14"></i>
          &nbsp; {translations.new_activity}
        </button>
      );
    }
  };

  useEffect(() => {
    if (showActivity) {
      activityId = showActivity;
      openModal();
    }
  }, []);

  return (
    <>
      {/* When the priority or activity type custom column options are not present then display this message */}
      {showDropDownFieldMessage && !activityId && (
        <>
          <div className="alert alert-fixed alert-info fade in">
            <p
              dangerouslySetInnerHTML={{
                __html: translations.no_custom_columns,
              }}
            />
          </div>
          <br />
        </>
      )}
      {/* When the company has SRM trial licence and they have exhausted their limit to create activities this week then display this message */}
      {!canCreateActivity && !activityId && (
        <>
          <div className="alert alert-fixed alert-info fade in">
            <p
              dangerouslySetInnerHTML={{
                __html: translations.trial_licence_limit_reached,
              }}
            />
          </div>
          <br />
        </>
      )}
      {/* New Activity button to open the modal */}
      {permitted_to_create_activity && editOrCreateButton()}
      {permitted_to_create_activity && state.modalIsOpen && (
        <ActivityModal
          modalIsOpen={state.modalIsOpen}
          closeModal={closeModal}
          translations={translations}
          custom_column_options={custom_column_options}
          values_for_status={values_for_status}
          current_user={current_user}
          activityTypes={activityTypes}
          priorityOptions={priorityOptions}
          draftActivity={state.draftActivity}
          concernedParties={state.concernedParties}
          documents={state.documents}
          notes={state.notes}
          allActivities={allActivities}
          allConcernedParties={allConcernedParties}
          setActivities={setActivities}
          setConcernedParties={(updatedCP) => {
            setGlobalConcernedParties(updatedCP);
            setState({ concernedParties: updatedCP });
          }}
          paginationParams={paginationParams}
          setPaginationParams={setPaginationParams}
          maxFileSize={maxFileSize}
          permitted_to_create_activity={permitted_to_create_activity}
          fetchDraftActivity={fetchDraftActivity}
          hostOwnerEmails={hostOwnerEmails}
          setHostOwnerEmails={setHostOwnerEmails}
          childActions={childActions}
        />
      )}
    </>
  );
};

const chooseDefaultOption = (options) => {
  let defaultOption = options.find((option) => option.default_option);
  if (defaultOption === undefined) {
    defaultOption = options[0];
  }
  return defaultOption;
};

const valuesForSelect = (options) => {
  return options.map((option) => {
    return { id: option.id, text: option.name };
  });
};

const filteredValues = (valueType, cco) => {
  return cco.filter((option) => option.custom_column_name === valueType);
};

// Filtering the activity types to be displayed on the activity form from custom column options
const activityTypes = (cco) => valuesForSelect(filteredValues("activity_type", cco));

// Filtering the priority options to be displayed on the activity form from custom column options
const priorityOptions = (cco) => valuesForSelect(filteredValues("priority", cco));

// Finding the default option of activity type to be chosen on the activity form by default
// It is not mandatory to have this in database
const defaultActivityTypeId = (cco) => {
  return chooseDefaultOption(filteredValues("activity_type", cco)).id;
};

// Finding the default option of priority to be chosen on the activity form by default
// It is not mandatory to have this in database
const defaultPriorityOptionId = (cco) => {
  return chooseDefaultOption(filteredValues("priority", cco)).id;
};

export default activityButton;
export {
  activityTypes,
  priorityOptions,
  defaultActivityTypeId,
  defaultPriorityOptionId,
  filteredValues,
  valuesForSelect,
};
