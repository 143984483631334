import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
  addSupplierContact,
  removeContact,
  updateContactLabel,
} from "../../../actions/contract/contractCreateEditActions";
import { Confirm } from "../../common/Confirm";
import ContractTooltip from "./ContractTooltip";
import RenderHTML from "@/components/common/RenderHTML";

class SupplierContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableAddUserButton: false,
      selectedUsers: [],
      userIdToRemove: "",
    };
    this.handleAddUser = this.handleAddUser.bind(this);
    this.supplierError = React.createRef();
    this.supplierContactSection = React.createRef();
  }

  componentDidMount() {
    this.addTagItToTextBox();
  }

  componentDidUpdate() {
    this.addTagItToTextBox();
  }

  async handleAddUser(e) {
    this.setState({
      disableAddUserButton: true,
    });
    const userEmails = this.userEmails.value;
    if (userEmails.length) {
      await this.props.addSupplierContact(this.props.contractId, userEmails);
      $("#supplier_tag_handler").tagit("removeAll");
    }
    this.setState({
      disableAddUserButton: false,
    });
  }

  addTagItToTextBox() {
    const { availableSuppliers } = this.props;
    const selfThis = this;
    $("#supplier_tag_handler").tagit({
      availableTags: availableSuppliers,
      singleField: true,
      singleFieldNode: $("#supplier_emails"),
      beforeTagAdded: function () {
        selfThis.supplierError.current.classList.add("invisible");
      },
    });
  }

  handleRemove(userId) {
    this.props.removeContact(this.props.contractId, userId, "supplier");
  }

  async updateUserToRemove(userId) {
    this.setState({
      userIdToRemove: userId,
    });
  }

  validateSupllierContacts() {
    const { addedSuppliers } = this.props;
    if (!addedSuppliers.length) {
      this.supplierError.current.classList.remove("invisible", "sd-hidden");
      this.supplierError.current.innerText = this.props.translations.add_atleast_one_contact;
    }
    return !(addedSuppliers.length > 0);
  }

  handleLabelChange(e, supplier) {
    const { user_id, contact_company_name, phone_number, name } = supplier;
    const { isCompanyNameEnabled } = this.props;

    const contactCompanyName = isCompanyNameEnabled
      ? this[`${user_id}_contactCompanyName`].value
      : contact_company_name;

    const additionalData = {
      user_company_name: contactCompanyName,
      name: this[`${user_id}_supplier_name`] ? this[`${user_id}_supplier_name`].value : name,
      office_phone: this[`${user_id}_supplier_phone_number`]
        ? this[`${user_id}_supplier_phone_number`].value
        : phone_number,
    };

    const label = this[`${user_id}_supplier_label`].value;

    this.props.updateContactLabel(
      this.props.contractId,
      user_id,
      label,
      contactCompanyName,
      "supplier",
      additionalData
    );
  }
  /**
   * returns div element with parsed values
   * @param {string} value
   * @returns {string} - ellipsis element with parsed values as string
   */
  cellEllipsisDivElement(value) {
    //keeping this as string and not as JSX as the RenderHTML component accepts string in content and not object.
    return `<div className="table-cell-ellipsis" title=${value}>${value}</div>`;
  }

  /**
   * renders elements with HTML tags
   * @param {string} value
   * @param {string | undefined} url
   * @returns {JSX} - element with/without redirection link as per conodition[no redirection for e-mail values]
   */
  tableCellEllipsis(value, url) {
    return url ? (
      <a href={url}>{<RenderHTML content={this.cellEllipsisDivElement(value)} />}</a>
    ) : (
      <RenderHTML content={this.cellEllipsisDivElement(value)} />
    );
  }

  render() {
    const { translations, showPage, addedSuppliers, isCompanyNameEnabled } = this.props;
    return (
      <div className="col-md-12 col-sm-12" ref={this.supplierContactSection}>
        <div className="form-section-block supplier-contract-section overflow-x-auto">
          <ContractTooltip translations={translations} titleText={"supplier_contact"} />
          {!showPage && (
            <Fragment>
              <div className="form-group" style={{ width: "45%", float: "left" }}>
                <label htmlFor="event-name" className="label-ellipsis">
                  {translations.find_an_existing_user} <span className="required">*</span>
                </label>
                <input type="hidden" id="supplier_emails" ref={(ref) => (this["userEmails"] = ref)} />
                <ul id="supplier_tag_handler" />
                <span className="error-text invisible sd-hidden" ref={this.supplierError} />
              </div>
              <div className="col-12 mt-add-button">
                <label>&nbsp;</label>
                <button
                  className="btn btn-sm btn-default"
                  style={{
                    color: "#ff8c00",
                    borderColor: "#ff8c00",
                    float: "left",
                    height: "37px",
                    paddingTop: "8px",
                  }}
                  disabled={this.state.disableAddUserButton}
                  onClick={this.handleAddUser}
                >
                  {translations.add}
                </button>
              </div>
            </Fragment>
          )}
          {addedSuppliers.length > 0 && (
            <table className="w-100 table-layout-fixed">
              <thead className="table-block-title">
                <tr>
                  <td className="w-20">
                    <div>{translations.label}</div>
                  </td>
                  {isCompanyNameEnabled && (
                    <td className="w-20">
                      <div>{translations.contact_company_name}</div>
                    </td>
                  )}
                  <td className="w-10">
                    <div>{translations.name}</div>
                  </td>
                  <td className="w-10">
                    <div>{translations.email}</div>
                  </td>
                  <td className="w-10">
                    <div>{translations.phone_number || "-"}</div>
                  </td>
                  {!showPage && <td className="w-8"> </td>}
                </tr>
              </thead>
              <tbody id="participant_summary_body">
                {addedSuppliers.map((supplier) => (
                  <tr key={supplier.id}>
                    <td>
                      {showPage ? (
                        supplier.label
                      ) : (
                        <input
                          className="form-control w-75"
                          type="text"
                          data-testid="Supplier-Label-Input"
                          ref={(ref) => (this[`${supplier.user_id}_supplier_label`] = ref)}
                          onBlur={(e) => this.handleLabelChange(e, supplier)}
                          defaultValue={supplier.label}
                        />
                      )}
                    </td>
                    {isCompanyNameEnabled && (
                      <td>
                        {showPage ? (
                          supplier.contact_company_name
                        ) : (
                          <input
                            className="form-control w-75"
                            type="text"
                            data-testid="Supplier-CompanyName-Input"
                            ref={(ref) => (this[`${supplier.user_id}_contactCompanyName`] = ref)}
                            onBlur={(e) => this.handleLabelChange(e, supplier)}
                            defaultValue={supplier.contact_company_name}
                          />
                        )}
                      </td>
                    )}
                    <td>{this.showField(supplier, supplier.name, `${supplier.user_id}_supplier_name`)}</td>
                    <td>{this.tableCellEllipsis(supplier.email)}</td>
                    <td>
                      {this.showField(supplier, supplier.phone_number, `${supplier.user_id}_supplier_phone_number`)}
                    </td>
                    {!showPage && (
                      <td>
                        <a
                          onClick={() => this.updateUserToRemove(supplier.user_id)}
                          data-toggle="modal"
                          data-target="#supllierRemoveConfirmModal"
                          rel="nofollow"
                          href="#"
                        >
                          <i title={translations.remove} className="fa fa-times text-danger" />
                        </a>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <p className="clear-both" />
        </div>
        <Confirm
          translations={translations}
          title={translations.confirmation_popup_details.delete_supplier_title}
          message={translations.confirmation_popup_details.delete_supplier_text}
          htmlId="supllierRemoveConfirmModal"
          onOkayButtonClick={() => this.handleRemove(this.state.userIdToRemove)}
        />
      </div>
    );
  }
  showField(supplier, value, refName) {
    const { url, confirmed_at } = supplier;
    return confirmed_at ? (
      this.tableCellEllipsis(value, url)
    ) : (
      <input
        defaultValue={value}
        className="form-control w-75"
        type="text"
        data-testid="Name-And-Phone-Input"
        ref={(ref) => (this[refName] = ref)}
        onBlur={(e) => this.handleLabelChange(e, supplier)}
      />
    );
  }
}

const mapStateToProps = ({ createEditReducers }) => ({
  availableSuppliers: createEditReducers.contractUserData.suppliers.available,
  addedSuppliers: createEditReducers.contractUserData.suppliers.added,
  isCompanyNameEnabled: createEditReducers.contractUserData.suppliers.is_company_name_enabled,
  contractId: createEditReducers.contract.id,
  translations: createEditReducers.translations,
  showPage: createEditReducers.showPage,
});

const mapDispatchToProps = (dispatch) => ({
  addSupplierContact: (contractId, userEmails) => dispatch(addSupplierContact(contractId, userEmails)),
  removeContact: (contractId, userId, type) => dispatch(removeContact(contractId, userId, type)),
  updateContactLabel: (contractId, userId, label, contactCompanyName, type, additionalData) =>
    dispatch(updateContactLabel(contractId, userId, label, contactCompanyName, type, additionalData)),
});
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SupplierContactForm);
