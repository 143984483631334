import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import { getError } from "./errorActions";

const showSuccess = (successMessage) => {
  if (successMessage === undefined || !(successMessage.length > 1)) {
    return { type: "NA" };
  } else {
    return { type: actionTypes.SUCCESS, success: successMessage };
  }
};

const picklistSuccess = (data, actionType) => {
  return {
    type: actionType,
    payload: {
      picklist: data.picklist,
      picklistOptions: data.picklist_options,
    },
  };
};

export const createPicklist = (payload) => (dispatch) =>
  apiCall
    .post(`${config.V2_BASE_URL}/picklists`, payload)
    .then((response) => {
      dispatch(picklistSuccess(response.data, actionTypes.CREATE_PICKLIST));
      dispatch(showSuccess(response.data.message));
      return response.data.picklist.id;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const updatePicklist = (payload, picklistId) => (dispatch) =>
  apiCall
    .put(`${config.V2_BASE_URL}/picklists/${picklistId}`, payload)
    .then((response) => {
      dispatch(picklistSuccess(response.data, actionTypes.UPDATE_PICKLIST));
      dispatch(showSuccess(response.data.message));
      return response.status;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
