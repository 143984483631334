import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { deleteLineItemBlics } from "@/actions/bidLineItemComponentActions";
import { confirm } from "@/utils/confirm";

import { LineItem } from "@/common-prop-types";

const LiDeleteButton = ({ bid, li, translations }) => {
  const dispatch = useDispatch();

  return (
    <div className="icon-onhover-right" title={li.name}>
      <a
        href="#"
        role="button"
        className="btn edit hover opacity red-color"
        onClick={() => {
          confirm({ body: translations.delete_line_item_blics_confirmation }).accept(() => {
            dispatch(deleteLineItemBlics(bid.id, li.id));
          });
        }}
      >
        <i className="fa fa-trash" />
      </a>
    </div>
  );
};

LiDeleteButton.propTypes = {
  translations: PropTypes.any.isRequired,
  li: PropTypes.shape(LineItem),
  bid: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default LiDeleteButton;
