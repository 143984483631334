import React from "react";
import { latestBidDuringAuctionForLot, rankForParticipant } from "../../bidCommon";
import LotBidComponent from "../../bids/LotBidComponent";

const RankedEvent = (props) => {
  const { weighted_rfq_or_auction: weightedRfqOrAuction, event_participant: eventParticipant, bids = [] } = props;

  return props.lots.map((lot) => {
    const LBids = bids.filter((b) => b.lot_id === lot.id);
    let rankValue = null;
    let latestBid = null;

    if (props.isRankedMonitor) {
      let typeOfRank = weightedRfqOrAuction ? "weightedScore" : "price";
      const latestBids = latestBidDuringAuctionForLot(LBids, [eventParticipant], props.event, weightedRfqOrAuction);
      latestBid = latestBids?.[0] ?? {};
      rankValue = rankForParticipant(latestBid, typeOfRank, eventParticipant);
    }

    return (
      <LotBidComponent
        {...props}
        key={lot.id}
        lot={lot}
        licCurrency={props.event_currency}
        lineItems={props.line_items}
        currenciesHash={props.currencies_hash}
        unitOfMeasures={props.unit_of_measures}
        currentUser={props.current_user}
        picklistOptions={props.picklist_options}
        lotComponents={props.lot_components}
        lineItemComponents={props.line_item_components}
        unitSetUoms={props.unit_set_unit_of_measures}
        unitSets={props.unit_sets}
        lotTotalBlics={props.lot_total_blics}
        auctionTab={props.auction_tab}
        isAuction={true}
        rankValue={rankValue}
        bid={latestBid}
        LBids={LBids}
        beforeAuction={props.before_auction}
        participant={eventParticipant}
        weightedRfqOrAuction={weightedRfqOrAuction}
        isPlaceBidActiveFlag={true}
      />
    );
  });
};

export default RankedEvent;
