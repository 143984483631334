import React, { useRef } from "react";
import { Cell } from "@/components/common/grid";
import Select2 from "react-select2-wrapper";
import { last } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  calculation,
  componentType,
  convertToLocaleString,
  host,
  findUnitSet,
  unPlacedBidInDetailLevelAuction,
  isSpecialFormula,
  participant,
  showValue,
  multiCurrencyEvent,
} from "../../common";
import { bidSubmitted, findParticipantBlicForLic, showRankOfCell } from "../../bidCommon";
import SummaryPanel from "./SummaryPanel";
import { createBlic } from "../../../../actions/bidLineItemComponentActions";
import PriceComponentSummaryPanel from "../line_item_component/PriceComponentSummaryPanel";
import PlaceBidFormComponent from "../line_item_component/PlaceBidFormComponent";
import LicHostCellComponent from "./LicHostCellComponent";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import LicName from "./lotLicCommon/LicName";
import { isRfq } from "../../eventCommon";

const LotLisComponent = (props) => {
  const {
    lot,
    li,
    lotLics,
    lotHook,
    isPlaceBidActive,
    currenciesHash = {},
    currency,
    unitOfMeasures,
    lotLcs,
    translations,
    event,
    picklistOptions,
    bidLineItemComponents,
    bid,
    isBidSubmitted,
    currentUser,
    unitSets,
    unitSetUoms,
    isRejectedLot,
    isAuction,
    locale,
    rankValue,
    showPrice,
    lotTotalLic = {},
    isAuctionPending,
    auctionTab,
    role,
    previousBid,
  } = props;

  const dispatch = useDispatch();
  const eventRound = useSelector(({ lotReducers }) => lotReducers.eventRound);
  const eventRounds = useSelector(({ lotReducers }) => lotReducers.event_rounds);
  const isShowingRank = isRfq(event) ? !eventRound && last(eventRounds)?.show_rank : true;
  const isMultiCurrencyEvent = multiCurrencyEvent(event);
  const lotLic = lotLics.filter((lic) => lic.line_item_id === li.id);
  const numberFormat = useSelector(({ lotReducers }) => lotReducers.translations.number_format);
  const isDetailView = lotHook.lineItemsDetailsState[li.id];

  const { is_calculation } = li;
  return (
    <>
      <LicName
        {...props}
        toggleDetailsView={() => lotHook.toggleDetailsView(li.id)}
        isDetailView={isDetailView}
        bid={bid}
      />
      {lotLcs.map((lc, i) => {
        const isHost = host(lc);
        const unitSet = findUnitSet(unitSets, lc.unit_set_id);
        const lic = lotLic.find((l) => l.lot_component_id === lc.id);
        const ref = useRef();
        if (lic) {
          let licCurrency = currency;
          let blic = bid && findParticipantBlicForLic(lic.id, bidLineItemComponents, bid.id);
          if (unPlacedBidInDetailLevelAuction(isAuction, event, bid)) {
            let bidBLic = bid && findParticipantBlicForLic(lic.id, bidLineItemComponents, bid.id);
            if (bidBLic && bidBLic.price) {
              blic = bidBLic;
            }
          }
          if (isMultiCurrencyEvent) {
            licCurrency =
              currenciesHash && currenciesHash[lic.exchange_rate_id]
                ? currenciesHash[lic.exchange_rate_id]
                : licCurrency;
            if (blic && blic.exchange_rate_id) {
              licCurrency =
                currenciesHash && currenciesHash[blic.exchange_rate_id]
                  ? currenciesHash[blic.exchange_rate_id]
                  : licCurrency;
            }
          }

          const type = componentType(lc.lot_component_type);
          const isCalculation = is_calculation || calculation(lc);
          if (isCalculation) {
            let fTotal = "-";

            if (showPrice) {
              const isFormulaCell = !lic.valid_cell && is_calculation && isSpecialFormula(lic);
              const hasActiveBid = isPlaceBidActive || bidSubmitted(bid);

              if (isHost || (hasActiveBid && isFormulaCell))
                fTotal = lic.total_qualification_value ?? lic.qualification_price ?? fTotal;

              // showValue will fallback to fTotal if blic has no price
              fTotal = showValue(blic?.price, fTotal);
            }

            let valueComponent = null;
            const _onClose = (e) => {
              if (e.target && e.target.value) {
                const [selectedExchangeRateId, selectedExchangeRateObj] = Object.entries(currenciesHash).find(
                  ([blic, currency]) => currency.id === Number(e.target.value)
                );

                if (blic) {
                  const values = [
                    {
                      line_item_component_id: lic.id,
                      price: blic.price,
                      exchange_rate_id: Number(selectedExchangeRateId),
                      calculation_blic: true,
                    },
                  ];
                  dispatch(createBlic(bid.id, { bid_line_item_component: { values } }, lic.id));
                }
              }
            };
            switch (type) {
              case "isPrice":
                {
                  const select =
                    isPlaceBidActive &&
                    lotHook.showAllLineItemDetails &&
                    !isBidSubmitted &&
                    isMultiCurrencyEvent &&
                    !previousBid ? (
                      <>
                        <label
                          style={{ fontWeight: "bold" }}
                          className="bid_line_item_component_bid_line_item_component_attribute_value"
                        >
                          {translations.currency}
                        </label>
                        <div className="input simple-bid-input bid-input input-group" style={{ display: "flex" }}>
                          {!host(lc) ? (
                            <Select2
                              className="form-control"
                              value={licCurrency.id}
                              data={Object.values(currenciesHash).map((u) => {
                                return { text: u.name, id: u.id };
                              })}
                              options={{
                                width: "auto",
                                placeholder: translations.please_select,
                              }}
                              onClose={_onClose}
                            />
                          ) : null}
                        </div>
                      </>
                    ) : null;
                  const precision = lotTotalLic && lotTotalLic.decimal_place ? lotTotalLic.decimal_place : 2;
                  fTotal = convertToLocaleString(isAuctionPending && auctionTab ? "-" : fTotal, locale, precision);
                  valueComponent = (
                    <>
                      {select}
                      <span className="dummy-bid-field">
                        <span className="num">
                          {numberFormat && numberFormat.currency_position === "suffix" ? (
                            <>
                              <span ref={ref} id="" className="str_check_bid_value">
                                {fTotal}
                              </span>
                              &nbsp;
                              <span className="str_currency_sym">{licCurrency.symbol}</span>
                            </>
                          ) : (
                            <>
                              <span className="str_currency_sym">{licCurrency.symbol}</span>
                              <span ref={ref} id="" className="str_check_bid_value">
                                {fTotal}
                              </span>
                            </>
                          )}
                        </span>
                      </span>
                    </>
                  );
                }
                break;
              case "isDecimal":
                valueComponent = (
                  <span className="dummy-bid-field">
                    <span ref={ref} id="" className="str_check_bid_value">
                      {is_calculation ? "" : fTotal}
                    </span>
                  </span>
                );
                break;
              case "default":
                valueComponent = (
                  <span className="dummy-bid-field">
                    <span id="" className="str_check_bid_value"></span>
                  </span>
                );
                break;
            }

            const totalLi = (
              <Cell
                className={classNames({
                  "cell-lot-component line_item_title_bg": true,
                  "bg-light-green": is_calculation,
                })}
                key={lic.id}
              >
                {valueComponent}
                &nbsp;
                {isShowingRank && showRankOfCell(event, blic, bid, lic, lc, lot, role, false, auctionTab)}
                <div
                  className={classNames({
                    "-hide": !(!isPlaceBidActive && isDetailView && isBidSubmitted),
                  })}
                >
                  <SummaryPanel
                    renderData={[licCurrency.name, "-", "-", "-"]}
                    event={event}
                    blic={blic}
                    bid={bid}
                    lic={lic}
                    lc={lc}
                    lot={lot}
                    role={role}
                    auctionTab={auctionTab}
                  />
                </div>
              </Cell>
            );
            advancedLots.formulaLics[lot.id].push({ fLic: lic, ref });
            return totalLi;
          } else {
            const isParticipant = participant(lc);
            const uomId = blic && blic.unit_of_measure_id ? blic.unit_of_measure_id : lic.unit_of_measure_id;
            const allUoms = [...unitOfMeasures, ...(Array.isArray(unitSetUoms) ? unitSetUoms : [])];
            const uom = allUoms.find((uom) => uomId === uom.id);

            if (isPlaceBidActive && (!isBidSubmitted || isAuction) && !isRejectedLot) {
              if (isParticipant) {
                return (
                  <Cell className="cell-lot-component line_item_title_bg vertical-aligntop" key={lic.id}>
                    <PlaceBidFormComponent
                      {...props}
                      lot={lot}
                      unitOfMeasures={unitOfMeasures}
                      lic={lic}
                      isAllDetailView={lotHook.showAllLineItemDetails}
                      picklistOptions={picklistOptions}
                      event={event}
                      bid={bid}
                      currenciesHash={currenciesHash}
                      isMultiCurrencyEvent={isMultiCurrencyEvent}
                      uom={uom}
                      licCurrency={licCurrency}
                      translations={translations}
                      bidLineItemComponents={bidLineItemComponents}
                      blic={blic}
                      isParticipant={isParticipant}
                      lc={lc}
                      li={li}
                      currentUser={currentUser}
                      unitSetUoms={unitSetUoms}
                      unitSets={unitSets}
                      isAuction={isAuction}
                      rankValue={rankValue}
                      previousBid={previousBid}
                    />
                  </Cell>
                );
              } else {
                return (
                  <Cell className="cell-lot-component line_item_title_bg" key={lic.id}>
                    <LicHostCellComponent
                      lic={lic}
                      type={type}
                      licCurrency={licCurrency}
                      locale={locale}
                      translations={translations}
                    />
                  </Cell>
                );
              }
            } else {
              return (
                <Cell className="cell-lot-component line_item_title_bg" key={lic.id}>
                  <PriceComponentSummaryPanel
                    {...props}
                    lot={lot}
                    lic={lic}
                    currenciesHash={currenciesHash}
                    licCurrency={licCurrency}
                    isMultiCurrencyEvent={isMultiCurrencyEvent}
                    unitOfMeasures={unitOfMeasures}
                    lotLcs={lotLcs}
                    isDetailView={isDetailView}
                    isPlaceBidActive={isPlaceBidActive}
                    event={event}
                    isAllDetailView={lotHook.showAllLineItemDetails}
                    picklistOptions={picklistOptions}
                    bidLineItemComponents={bidLineItemComponents}
                    bid={bid}
                    translations={translations}
                    lotLics={lotLics}
                    isBidSubmitted={isBidSubmitted}
                    currentUser={currentUser}
                    unitSetUoms={unitSetUoms}
                    unitSets={unitSets}
                    blic={blic}
                    isHost={isHost}
                    isRejectedLot={isRejectedLot}
                    unitSet={unitSet}
                    isAuction={isAuction}
                    locale={locale}
                    rankValue={rankValue}
                    isParticipant={isParticipant}
                    uom={uom}
                    lc={lc}
                    role={role}
                    auctionTab={auctionTab}
                    isAuctionPending={isAuctionPending}
                  />
                </Cell>
              );
            }
          }
        } else {
          return (
            <Cell
              className={classNames({
                "cell-lot-component line_item_title_bg2": true,
                "bg-light-green": is_calculation,
              })}
              key={lc.id + i}
            />
          );
        }
      })}
    </>
  );
};

export default React.memo(LotLisComponent, (prevProps, nextProps) => {
  try {
    const { isPlaceBidActive, lotHook, li, lot, bid, bidLineItemComponents } = prevProps;
    // TODO(ade): Find out why we have lot line item components that are not an
    // array
    const lotLic = Array.isArray(nextProps.lotLics)
      ? nextProps.lotLics.filter((lic) => lic.line_item_id === li.id)
      : nextProps.lotLics;

    switch (true) {
      case lotHook.lineItemsDetailsState[li.id] !== nextProps.lotHook.lineItemsDetailsState[li.id]:
      case isPlaceBidActive !== nextProps.isPlaceBidActive:
      case lot.is_event_total:
      case !isEqual(bid, nextProps.bid):
      case Array.isArray(lotLic) && lotLic.find((lic) => lic.id === advancedLots.createUpdateBlicId):
      case !isEqual(bidLineItemComponents, nextProps.bidLineItemComponents):
        return false;
      default:
        return true;
    }
  } catch (error) {
    return false;
  }
});
