import React from "react";

const LinkProjectChkbox = (props) => {
  return (
    <div>
      <input
        name={props.name}
        id={props.id}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
      />
      <label htmlFor={props.id}>
        <span></span>
        {props.label}
      </label>
    </div>
  );
};

export default LinkProjectChkbox;
