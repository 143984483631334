import React, { Component } from "react";
import { connect } from "react-redux";
import Tooltip from "../../../common/Tooltip";
import { showAlert, showUserPermissionModal, requestLicence } from "../../../../actions/adminActions";
import RenderPermissionDetails from "./AdminPermission";
import CategoryPermission from "./CategoryPermission";
import EventAndContractPermissionDetails from "./ContractPermission";
import EventParticipantPermission from "./EventParticipantPermission";
import features from "@/utils/features";
import { confirm } from "@/utils/confirm";
import requestLicenceIcon from "../../../../../assets/images/icons/licence-request.svg";
import RenderHTML from "@/components/common/RenderHTML";
import PropTypes from "prop-types";

const HiddenHeaderPropTypes = {
  /**
   * @type {string} heading
   */
  heading: PropTypes.string,

  /**
   * @type {object} content
   */
  content: PropTypes.shape({ title: PropTypes.string, body: PropTypes.string }),
};

const PermissionRowDataPropTypes = {
  heading: PropTypes.string,
  content: PropTypes.shape({ title: PropTypes.string, body: PropTypes.string }),
  isBig: PropTypes.bool.isRequired,
  hidden: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

/**
 * @type {React.FC<typeof HiddenHeaderPropTypes>}
 */
export const HiddenHeader = ({ heading, content }) => {
  return (
    <div className="col-caption hidden-lg hidden-md hidden-sm">
      {heading}
      <Tooltip title={content.title} body={content.body} />
    </div>
  );
};
HiddenHeader.propTypes = HiddenHeaderPropTypes;

/**
 * @type {React.FC<typeof PermissionRowDataPropTypes>}
 */
const PermissionRowData = ({ heading, content, isBig, hidden, children }) => {
  if (hidden) {
    return null;
  }

  return (
    <td className={isBig ? "big-cell" : "small-cell"}>
      <HiddenHeader heading={heading} content={content} />
      {children}
    </td>
  );
};
PermissionRowData.propTypes = PermissionRowDataPropTypes;

export class HostPermissionsTableRows extends Component {
  render() {
    const { user, status, hostGroups, admin, items } = this.props.translations.infos;
    const {
      currentUserCanEditPermissions,
      translations,
      userPermissions,
      permissionsToSkip,
      marketDojo,
      adminPermissionsList,
      simPermissionsList,
      currentUser,
      companyId,
      categoryDojo,
      contractDojo,
      simDojo,
      editOwnEventPermissions,
      editOwnCategoryPermissions,
    } = this.props;

    return (
      <tbody>
        {userPermissions &&
          userPermissions.map((up, _key) => {
            return (
              <tr key={up.id}>
                <td className="small-cell">
                  <HiddenHeader heading={translations.user} content={user} />
                  {up.user.superhost && (
                    <div>
                      <strong>{translations.superhost}</strong> <i className="icon-crown green-color" />
                    </div>
                  )}
                  <div>
                    <strong>{up.user.name || up.user.email}</strong>
                  </div>
                  <span className="light-gray">{up.user.user_company_name}</span>
                  {up.user.registered && (
                    <div>
                      <i>{up.user.email}</i>
                    </div>
                  )}
                </td>
                <td className="small-cell">
                  <HiddenHeader heading={translations.event_participants.status} content={status} />
                  {up.user.registered ? (
                    <div className="green-color">
                      <i className="fa fa-check" /> {translations.event_participants.registered}
                    </div>
                  ) : (
                    <div className="red-color">
                      <i className="fa fa-times" /> {translations.event_participants.not_registered}
                    </div>
                  )}
                  {(marketDojo || categoryDojo) && this.creditBalanceCheck(up, translations)}
                </td>
                <td className="small-cell">
                  <HiddenHeader heading={translations.group} content={hostGroups} />
                  {up.user.host_groups_names.map((groupName) => (
                    <RenderHTML key={groupName} content={`${groupName} <br />`} />
                  ))}
                </td>
                <PermissionRowData heading={translations.administration} content={admin} isBig={true}>
                  <RenderPermissionDetails
                    allPermissions={adminPermissionsList}
                    permissionList={up.admin_permissions}
                  />
                </PermissionRowData>
                {marketDojo && (
                  <td className="big-cell">
                    <HiddenHeader heading={translations.events} content={items} />
                    <EventAndContractPermissionDetails
                      adminPermissionsList={adminPermissionsList}
                      permissionsList={up.event_permissions}
                      creditBalance={up.user.credit_balance}
                      showIcon={true}
                    />
                    <EventAndContractPermissionDetails
                      adminPermissionsList={adminPermissionsList}
                      permissionsList={up.external_company_event_permissions.event_permissions}
                      creditBalance={up.user.credit_balance}
                    />
                  </td>
                )}
                <PermissionRowData heading={translations.contracts} content={items} isBig={true} hidden={!contractDojo}>
                  <EventAndContractPermissionDetails
                    adminPermissionsList={adminPermissionsList}
                    permissionsList={up.contract_permissions}
                  />
                </PermissionRowData>
                {simDojo && (
                  <td className="big-cell">
                    <HiddenHeader heading={translations.contracts} content={items} />
                    <EventParticipantPermission
                      simPermissions={up.admin_permissions}
                      eventparticipantPermissions={up.participant_permissions}
                      simPermissionsList={simPermissionsList}
                      translations={translations}
                      userPermission={up}
                      showIcon={true}
                    />
                  </td>
                )}
                {categoryDojo && (
                  <td className="big-cell">
                    <HiddenHeader heading={translations.events} content={items} />
                    <CategoryPermission
                      categoryPermissions={up.category_permissions}
                      adminPermissionsList={adminPermissionsList}
                      categoryDojoLicense={up.user.cat_dojo_license}
                      showIcon={true}
                    />
                    <CategoryPermission
                      categoryPermissions={up.external_company_category_permissions.category_permissions}
                      adminPermissionsList={adminPermissionsList}
                      categoryDojoLicense={up.user.cat_dojo_license}
                    />
                  </td>
                )}
                <td className="tiny-cell">
                  <div className="col-caption hidden-lg hidden-md hidden-sm">ACTIONS </div>
                  {(currentUserCanEditPermissions ||
                    (marketDojo && editOwnEventPermissions) ||
                    categoryDojo ||
                    editOwnCategoryPermissions) && (
                    <div className="butt-group">
                      {marketDojo && features.enabled("licence_request") && (
                        <button
                          className="btn btn-default btn-icon"
                          title={translations.request_a_licence}
                          onClick={() =>
                            confirm({
                              title: translations.licence_request,
                              body: translations.confirm_request_licence,
                              confirmBtnText: "confirm",
                            }).accept(() => this.props.requestLicence(up.user.id))
                          }
                          data-testid={"requestLicence" + up.user.id}
                          disabled={Boolean(this.props.requestLicenceStatus?.[up.user.id])}
                        >
                          <img src={requestLicenceIcon} alt={translations.licence_request} height="13px" />
                        </button>
                      )}
                      {(marketDojo ||
                        (up.user.company_id === companyId &&
                          ((up.user.superhost && up.user.id === currentUser.id) || !up.user.superhost)) ||
                        categoryDojo) && (
                        <React.Fragment>
                          <button
                            className={`btn ${
                              categoryDojo ? "" : "btn-default"
                            } send-notification-to-user-button btn-icon m-l8 position-relative`}
                            title={up.notification_status ? translations.userNotified : translations.userNeedToNotify}
                            onClick={() => this.props.showAlert(true, up.id)}
                            data-target="#notifyChangesToUserConfirmModal"
                            data-toggle="modal"
                          >
                            <i className="fa fa-envelope" aria-hidden="true" />
                            <span
                              className={`position-absolute icon-badge ${
                                up.notification_status ? "bg-success" : "bg-orange"
                              }`}
                            />
                          </button>
                          <button
                            className={`btn ${
                              categoryDojo ? "" : "btn-default"
                            } m-l8 edit-user-permission-button btn-icon`}
                            title={translations.edit}
                            data-target="#host-permissions-modal"
                            data-toggle="modal"
                            onClick={() => {
                              this.onEditButtonClick(up);
                            }}
                          >
                            <i className="fa fa-pencil" aria-hidden="true" />
                          </button>
                        </React.Fragment>
                      )}
                      {!currentUserCanEditPermissions ||
                      (up.user.superhost && up.user.company_id === this.props.companyId) ? (
                        ""
                      ) : (
                        <React.Fragment>
                          <button
                            className={`btn ${
                              categoryDojo ? "" : "btn-default"
                            } btn-icon m-l8 delete-user-permission-button`}
                            title={translations.delete}
                            onClick={() => this.props.showAlert(true, up.id)}
                            data-toggle="modal"
                            data-target="#userPermissionConfirmModal"
                            rel="nofollow"
                          >
                            <i className="fa fa-trash red-color" aria-hidden="true" />
                          </button>
                          {/* The deactivate button should be visible on Market Dojo and Sim Dojo */}
                          {!categoryDojo && up.user.company_id === this.props.companyId && (
                            <button
                              className={"btn btn-default btn-icon m-l8"}
                              title={translations.deactivate}
                              onClick={() => this.props.showAlert(true, up.id)}
                              data-toggle="modal"
                              data-target="#deactivateUserConfirmModal"
                              rel="nofollow"
                            >
                              <i className="fa fa-ban" aria-hidden="true"></i>
                            </button>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  }

  validOrExpiredLicence(creditBalance, translationText, expired) {
    let classNames = expired ? ["red-color", "fa fa-warning"] : ["green-color", "fa fa-check"];
    creditBalance.push(
      <div className={classNames[0]}>
        <i className={classNames[1]} />
        {translationText}
      </div>
    );
    return creditBalance;
  }

  noLicence(creditBalance, translations) {
    creditBalance.push(
      <div className="red-color">
        <i className="fa fa-times" />
        {translations.no_licence}
      </div>
    );
    return creditBalance;
  }

  creditBalanceCheck(up, translations) {
    let creditBalance = [];
    const { marketDojo, categoryDojo } = this.props;
    if (marketDojo) {
      if (up.user.credit_balance === "Annual License" && !up.user.has_license_expired) {
        this.validOrExpiredLicence(creditBalance, translations.md_licence, false);
      }
      if (
        up.user.credit_balance != 0 &&
        up.user.credit_balance != "Annual License" &&
        !up.user.has_quick_quotes_licence
      ) {
        creditBalance.push(
          <div className="green-color">
            <i className="fa fa-check" /> {up.user.credit_balance}
            &nbsp;
            {translations.credits}
          </div>
        );
      } else {
        this.checkMdAndQQLicence(up, translations, creditBalance);
      }
    } else if (categoryDojo) {
      if (up.user.cat_dojo_license === "Annual-CategoryDojo") {
        this.validOrExpiredLicence(creditBalance, translations.licence, false);
      } else if (up.user.cat_dojo_license === "Annual-CategoryDojo Expired") {
        this.validOrExpiredLicence(creditBalance, translations.license_expired, true);
      } else {
        this.noLicence(creditBalance, translations);
      }
    }
    return creditBalance;
  }

  checkMdAndQQLicence(up, translations, creditBalance) {
    if (up.user.credit_balance === "Annual License" && up.user.has_license_expired) {
      this.validOrExpiredLicence(creditBalance, translations.expired_md_licence, true);
    }
    if (up.user.has_quick_quotes_licence) {
      this.checkQQLicence(up, translations, creditBalance);
    }
    if (up.user.credit_balance === 0 && !up.user.has_quick_quotes_licence) {
      this.noLicence(creditBalance, translations);
    }
    return creditBalance;
  }

  checkQQLicence(up, translations, creditBalance) {
    if (!up.user.quick_quotes_licence_expired) {
      this.validOrExpiredLicence(creditBalance, translations.quick_quotes_licence, false);
    } else {
      this.validOrExpiredLicence(creditBalance, translations.expired_quick_quotes_licence, true);
    }
    return creditBalance;
  }

  onEditButtonClick(up) {
    this.props.showUserPermissionModal(up);
  }
}

const mapStateToProps = ({ adminReducers }) => ({ requestLicenceStatus: adminReducers.requestLicenceStatus });

const mapDispatchToProps = (dispatch, props) => ({
  showUserPermissionModal: (userPermission) => dispatch(showUserPermissionModal(userPermission)),
  showAlert: (show, userPermissionId) => dispatch(showAlert(show, userPermissionId)),
  requestLicence: (userId) => dispatch(requestLicence({ companyId: props.companyId, userId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(HostPermissionsTableRows);
