import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { confirmable } from 'react-confirm';

class ConfirmationPopUp extends React.Component {
  render() {
    const {
      okLabel,
      cancelLabel,
      ConfirmLabel,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      enableEscape = true,
    } = this.props;
    return (
      <div className='static-modal'>
        <Modal className='small-popup' show={ show } onHide={ dismiss } backdrop={ enableEscape ? true : 'static' } keyboard={ enableEscape }>
          <Modal.Header>
            <button type='button' className='close' onClick={ cancel }>×</button>
            <Modal.Title>{ConfirmLabel}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirmation}
          </Modal.Body>
          <Modal.Footer>
            <Button className='button-l' onClick={ proceed }>{okLabel}</Button>
            <Button onClick={ cancel }>{cancelLabel}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}


export default confirmable(ConfirmationPopUp);
