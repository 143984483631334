import { createRequiredContext } from "required-react-context";
import type { SetStateAction } from "react";
import type { MultiLevelSelectProps } from "./Input";
import type { CheckedItem } from "./types";

interface ItemsContext extends Omit<MultiLevelSelectProps, "name"> {
  checkedItems: CheckedItem[];
  setCheckedItems: React.Dispatch<SetStateAction<CheckedItem[]>>;
}

export const { useItems, ItemsProvider } = createRequiredContext<ItemsContext>().with({
  name: "items",
  providerProp: "value",
});
