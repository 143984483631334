import React, { useState } from "react";
import CreateLotTemplateForm from "./CreateLotTemplateForm";

const CreateLotTemplate = (props) => {
  const { translations, classFromEventState } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <a
        className={`btn auto_width_btn ${classFromEventState} status-event sm-butt`}
        onClick={() => setModalIsOpen(true)}
      >
        <i className="fa fa-save" />
        &nbsp;
        {translations.save_to_library}
      </a>
      {modalIsOpen && <CreateLotTemplateForm {...props} setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} />}
    </>
  );
};

export default CreateLotTemplate;
