import React from "react";
import PropTypes from "prop-types";
import { ActivityDate, ActivityLabel } from "../activities/tabs/SubActivityTabContent";
import { Header } from "../activities/tabs/SubActivityTab";

let ResolveChildren = function (parent_id) {
  return this.child_activities.filter((child) => child.parent_id === parent_id);
};

const RowBuilder = ({ row, depth, isChild, children, translations }) => {
  return (
    <React.Fragment key={"widget-table-fragment-" + row.id}>
      <tr className={(isChild ? "bg-grey" : "bg-white") + " activity activity-my_activities"}>
        <td className="title activity-subject table-cell-ellipsis">
          <a
            style={{
              marginLeft: depth * 10 + "px",
            }}
            href={"/srm/activities?show_activity=" + row.id}
          >
            {row.subject}
          </a>
        </td>
        <ActivityDate activity={row} />
        <ActivityLabel activity={row} translations={translations} />
      </tr>

      {!row.noRows &&
        children &&
        children.map((child) => (
          <RowBuilder
            depth={(depth || 0) + 1}
            isChild={true}
            row={child}
            key={"widget-table-" + child.id}
            children={ResolveChildren(child.id)}
            translations={translations}
          />
        ))}
    </React.Fragment>
  );
};

const TableBuilder = ({ rows, translations }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <table className="border custom-responsive-table table-layout-fixed">
          <Header translations={translations} />
          <tbody>
            {rows.map((row) => (
              <RowBuilder
                key={"widget-table-" + row.id}
                row={row}
                children={ResolveChildren(row.id)}
                translations={translations}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const MyObjectives = (props) => {
  let { objectives, child_activities, translations } = props;

  child_activities.map((child) => {
    let make_parent = !objectives.filter((obj) => obj.id === child.parent_id).length;
    let from_child = !child_activities.filter((obj) => obj.id === child.parent_id).length;

    if (make_parent && from_child) {
      objectives.push(child);
    }
  });

  ResolveChildren = ResolveChildren.bind(props);

  return objectives.length ? (
    <TableBuilder rows={objectives} translations={translations} />
  ) : (
    <p className={"alert alert-info"}>{translations.no_objectives}</p>
  );
};

MyObjectives.defaultProps = {
  objectives: [],
  child_activities: [],
  translations: {},
};

MyObjectives.propTypes = {
  objectives: PropTypes.array.isRequired,
  child_activities: PropTypes.array.isRequired,
  translations: PropTypes.object.isRequired,
};

TableBuilder.propTypes = {
  rows: PropTypes.array.isRequired,
  translations: PropTypes.object.isRequired,
};

RowBuilder.propTypes = {
  row: PropTypes.object.isRequired,
  depth: PropTypes.number,
  isChild: PropTypes.bool,
  children: PropTypes.array,
  translations: PropTypes.object.isRequired,
};

export default MyObjectives;

export { RowBuilder, TableBuilder };
