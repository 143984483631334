import React from "react";
import { useDispatch } from "react-redux";
import { setInitialProps } from "../../actions/lotActions";
import HostMonitor from "./monitor/HostMonitor";
import ParticipantMonitor from "./monitor/ParticipantMonitor";
import actionsTypes from "../../actions/actionsTypes";
import { isHost } from "@/utils/roles";

const Monitor = (props) => {
  const { role, bids, bid_line_item_components: bidLineItemComponents, lots } = props;
  const [reduxStoreReady, setReduxStoreReady] = React.useState(false);

  const dispatch = useDispatch();
  let initialState = { ...props };
  if (Array.isArray(props.bids)) {
    const bids1 = props.bids.filter(({ bid_at_rfq_state, rejected_lot }) => !bid_at_rfq_state || !!rejected_lot);
    const rfqBids = props.bids.filter(({ bid_at_rfq_state }) => bid_at_rfq_state);
    if (rfqBids.length) {
      const rfqBidsIds = rfqBids.map(({ id }) => id);
      if (Array.isArray(props.bid_line_item_components)) {
        const bid_line_item_components1 = props.bid_line_item_components.filter(
          ({ bid_id }) => !rfqBidsIds.includes(bid_id)
        );
        initialState.bid_line_item_components = bid_line_item_components1;
      }
    }
    initialState.bids = bids1;
  }
  React.useEffect(() => {
    dispatch(setInitialProps(initialState));
    setReduxStoreReady(true);

    let socketConnectionInitialized = false;

    setTimeout(function () {
      const socketObject = document.ChartSocket;
      if (socketObject.connected) {
        getUpdatedData();
        socketConnectionInitialized = true;
      }
      socketObject.on("connect", () => {
        if (!socketConnectionInitialized) {
          getUpdatedData();
          socketConnectionInitialized = true;
        }
      });
      socketObject.on("disconnect", () => {});
    }, 1000);
  }, []);

  const getUpdatedData = () => {
    const socketObject = document.ChartSocket;
    socketObject.on("message", async (data) => {
      if (typeof data === "object" && !data.hasOwnProperty("bids")) {
        dispatch({
          type: actionsTypes.MESSAGE_ON_LIVE_FEED,
          payload: data,
        });
      }
    });
  };

  // We should not render anything until all the data get loaded in our
  // redux-store. Otherwise it can throw an error for data being undefined when
  // we try to fetch the data from the store before the data get loaded.
  if (!reduxStoreReady || !lots?.length) return null;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 clear-padding">
          <div className="block-content tab-content clear-padding">
            <div className="tab-pane active">
              {isHost(role) ? (
                <HostMonitor {...props} bids={bids} bidLineItemComponents={bidLineItemComponents} />
              ) : (
                <ParticipantMonitor {...props} bids={bids} bidLineItemComponents={bidLineItemComponents} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Monitor;
