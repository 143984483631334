import React, { Component } from "react";
import {
  componentType,
  qualificationValue,
  usedValue,
  showCurrency,
  formatDatetime,
  isPrice,
  isDecimal,
  showLocalisedStrippedDecimal,
  isRankedCell,
  findUnitSet,
  findUom,
  showValue,
} from "../../common";
import { showBidLineItemComponent } from "../../bidCommon";
import AllParticipantsBestBidForLotModal from "./modal/AllParticipantsBestBidForLotModal";
import { connect } from "react-redux";
import { fetchBlicsDetails } from "../../../../actions/bidLineItemComponentActions";

// FIXME: refactor into unit components
class ShowSingleBidLineItemComponent extends Component {
  render() {
    const {
      event,
      edit,
      preferred,
      licCurrency,
      lot,
      bid,
      blic,
      lic,
      locale,
      translations,
      blicCurrency,
      bidBlics,
      allUom,
      unitSet,
      LBids,
      isLineItemDetail,
      isCompleteDetailed,
      timeZoneIdentifier,
      header,
      bidList,
      bids,
      participatingParticipants,
      showOverallScore,
      permissionToView,
      isJapaneseEvent,
      participants,
      currenciesHash,
      isMultiCurrencyEvent,
      role,
      lots,
      lotComponents,
      lineItems,
      bidLineItemComponents,
      bidControls,
      unitSetUoms,
      unitOfMeasures,
      lotLics,
      lotLis,
      lotLcs,
      lineItemComponents,
      allUnitSets,
      bidRound,
      hosts,
      lineItemBestBlics,
      currentUser,
      loaderReducers: { isFetchBlicsLoading },
      priceTag,
      eventParticipants,
    } = this.props;
    const { enter_info, number_format } = translations;
    let result;
    if (lic && bid) {
      if (edit && lic.participant && !lic.is_calculation) {
        // TODO: This part will be cover when worked on participant side
        if (blic) {
          // return render 'bid_line_item_components/form', event: event, lot: lot, bid: bid, bid_line_item_component: blic, lic: lic
        } else {
          // new_or_edit_event_lot_bid_line_item_component_path(event, lot, bid, blic, 'bid_line_item_component[line_item_component_id]' => lic.id), remote: true, class: 'show-bid-form button small green')
          result = (
            <a href="#" className="show-bid-form button small green">
              {enter_info}
            </a>
          );
        }
      } else if (edit && lic && lic.host) {
        result = this.showLicValue();
      } else {
        result = showBidLineItemComponent(
          bid,
          blic,
          lic,
          licCurrency,
          blicCurrency,
          bidBlics,
          translations,
          allUom,
          unitSet,
          LBids,
          locale,
          timeZoneIdentifier,
          preferred,
          { priceTag: priceTag }
        );
      }
    } else if (lic && lic.host && lic.calculable_lc) {
      if (lic.is_price) {
        result = showCurrency(lic.current_value, licCurrency, lic.decimal_place, locale, number_format);
      } else {
        const currentVal = lic.current_value;
        result =
          !["", null, undefined].includes(currentVal) && isDecimal(lic)
            ? showLocalisedStrippedDecimal(currentVal, locale)
            : "";
      }
    } else if (lic) {
      if (lic.is_price) {
        result = showCurrency("-", licCurrency, lic.decimal_place, locale, number_format);
      } else {
        result = isDecimal(lic) ? "-" : "";
      }
    }
    if (blic) {
      const unitSet = lic.unit_set_id && findUnitSet(allUnitSets, lic.unit_set_id);
      const allUom = unitSetUoms.concat(unitOfMeasures);
      const uom = findUom(lic.unit_of_measure_id, allUom);
      const currentValueWithCurrency = showCurrency(
        showValue(lic.current_value),
        licCurrency,
        lic.decimal_place,
        locale,
        number_format
      );
      const qualificationValueWithCurrency = showCurrency(
        showValue(lic.current_value),
        licCurrency,
        lic.decimal_place,
        locale,
        number_format
      );
      const participant = bid && participants.find((participant) => participant.id === bid.user_id);
      return (
        <span title={result}>
          {!isCompleteDetailed && (lic.is_price || isLineItemDetail) ? (
            <AllParticipantsBestBidForLotModal
              viewComponent={result}
              header={header}
              translations={translations}
              bidList={bidList}
              bids={bids}
              role={role}
              lot={lot}
              isPrice={isPrice}
              isRankedCell={isRankedCell}
              uom={uom}
              apiCallingFunction={() => this.fetchModalDetails(participant.id)}
              modalType="LineItem"
              lic={lic}
              participatingParticipants={participatingParticipants}
              currentValue={currentValueWithCurrency}
              qualificationValue={qualificationValueWithCurrency}
              showOverallScore={showOverallScore}
              isLoading={isFetchBlicsLoading}
              locale={locale}
              permissionToView={permissionToView}
              event={event}
              isJapaneseEvent={isJapaneseEvent}
              participants={participants}
              currenciesHash={currenciesHash}
              allUom={allUom}
              unitSet={unitSet}
              hosts={hosts}
              isMultiCurrencyEvent={isMultiCurrencyEvent}
              currency={licCurrency}
              timeZoneIdentifier={timeZoneIdentifier}
              lots={lots}
              lotComponents={lotComponents}
              lineItems={lineItems}
              bidLineItemComponents={bidLineItemComponents}
              LBids={LBids}
              bidControls={bidControls}
              unitSetUoms={unitSetUoms}
              unitOfMeasures={unitOfMeasures}
              lotLics={lotLics}
              lotLis={lotLis}
              lotLcs={lotLcs}
              bidBlics={lineItemBestBlics}
              lineItemComponents={lineItemComponents}
              allUnitSets={allUnitSets}
              bidRound={bidRound}
              participant={participant}
              currentUser={currentUser}
              eventParticipants={eventParticipants}
            />
          ) : (
            result
          )}
        </span>
      );
    } else if (lic && lic.host && !lic.calculable_lc) {
      result = this.showLicValue();
      return <span title={result}>{result}</span>;
    } else {
      return !isCompleteDetailed && lic && lic.is_calculation && !result ? "-" : <span title={result}>{result}</span>;
    }
  }
  fetchModalDetails = (participantId) => {
    const { lineItemLic, fetchBlicsDetails, event, auctionTab } = this.props;
    fetchBlicsDetails(lineItemLic.id, !auctionTab, false, event.id, participantId, auctionTab);
  };
  showLicValue = () => {
    const { licCurrency, lic, locale, timeZoneIdentifier } = this.props;
    let result = "-";
    switch (componentType(lic.lot_component_type)) {
      case "isPrice":
        const priceVal = lic.is_formula_lic ? qualificationValue(lic) : usedValue(lic);
        result = showCurrency(priceVal, licCurrency, lic.decimal_place, locale, number_format);
        break;
      case "isDecimal":
        const decimalVal = lic.current_value;
        result = "-";
        if (!["", null, undefined].includes(decimalVal)) {
          result = showLocalisedStrippedDecimal(decimalVal, locale);
        }
        break;

      case "isDate":
        result = "-";
        if (lic.value) {
          result = formatDatetime(lic.value, timeZoneIdentifier);
        }
        break;
      case "isPicklist":
        const val = lic.value || "-";
        result = lic.is_formula_lic ? "" : val;
        break;
      case "isText":
        result = lic.value || "";
        break;
    }
    return result;
  };
}

const mapStateToProps = ({ bidLineItemComponentReducers, loaderReducers }) => ({
  bidList: bidLineItemComponentReducers && bidLineItemComponentReducers.bidList,
  loaderReducers,
});
const mapDispatchToProps = (dispatch) => ({
  fetchBlicsDetails: (licId, beforeAuction, showOverallScore, eventId, participantId, auctionTab) =>
    dispatch(fetchBlicsDetails(licId, beforeAuction, showOverallScore, eventId, participantId, auctionTab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowSingleBidLineItemComponent);

ShowSingleBidLineItemComponent.defaultProps = {
  /** isCompleteDetailed will use to show/hide LIC record related to single lot
   *  for a participant on a modal which show each LIC record for best bid.
   * */
  isCompleteDetailed: false,
  loaderReducers: { isFetchBlicsLoading: false },
};
