import React from "react";
import { canShowRank, eventParticipantStatus, isJapaneseEvent, isRankedCell } from "@/components/advanced_lots/common";
import { isWeightedRfqOrAuction } from "@/components/advanced_lots/eventCommon";
import { createSelector } from "@reduxjs/toolkit";
import { useAppSelector } from "@/hooks/redux";
import type { LineItemComponent, BidLineItemComponent, Bid, Participant } from "@/common-prop-types";

interface UseRankDetails {
  lic: LineItemComponent;
  bid: Bid;
  participant: Participant;
}
interface RankDetailsProps extends UseRankDetails {
  blic: BidLineItemComponent;
}

const rankDetailsSelector = createSelector(
  ({ lotReducers }) => lotReducers.event,
  ({ lotReducers }) => lotReducers.before_auction,
  (event, beforeAuction) => {
    const shouldDisplayRank = canShowRank(event, beforeAuction) && !(beforeAuction && isJapaneseEvent(event));

    return { event, shouldDisplayRank };
  }
);

const useRankDetails = ({ lic, bid, participant }: UseRankDetails) => {
  const { event, shouldDisplayRank } = useAppSelector(rankDetailsSelector);
  const shouldShowRank: boolean = isRankedCell(lic) && shouldDisplayRank;
  const bidHasRank = Boolean(bid.rank);

  return {
    shouldShowRank,
    showRankForNonWeightedRfQOrAuction: bidHasRank && !isWeightedRfqOrAuction(event),
    hasParticipantAcceptedInvitationAndLot:
      bidHasRank && !bid.rejected_lot && eventParticipantStatus(participant) !== "Declined",
  };
};

function RankDetails({ lic, blic, bid, participant }: RankDetailsProps) {
  const { hasParticipantAcceptedInvitationAndLot, shouldShowRank, showRankForNonWeightedRfQOrAuction } = useRankDetails(
    { lic, bid, participant }
  );

  if (!shouldShowRank) return null;
  if (showRankForNonWeightedRfQOrAuction) {
    return (
      <td>
        <span className={`${Number.isInteger(blic.rank) ? "blic-rank-symbol badge" : "red-color"} ranked${blic.rank}`}>
          {blic.rank}
        </span>
      </td>
    );
  }

  if (hasParticipantAcceptedInvitationAndLot) {
    return <td>{`${blic.overall_rank}`}</td>;
  }

  return <td>&nbsp;</td>;
}

export default RankDetails;
