import { preQualification } from "./QuestionnarieCommon";
import { eventParticipantStatus } from "./common";
import { companyNameOrEmail } from "./bidCommon";

export const passedPreQualificationQuestionnaires = (questionnaires, qAnswers, participant) => {
  let result = true;
  preQualification(questionnaires)?.forEach((questionnaire) => {
    const qAnswer = qAnswers.find(
      (qa) => qa.questionnaire_id === questionnaire.id && qa.participant_id === participant.user_id
    );
    if (qAnswer && !qAnswer.accepted) {
      result = false;
    }
  });
  return result;
};
export const statusForBids = (ep) => {
  // Its not appropriate to show the Active or Pending status for bids,
  // but Declined makes sense
  const result = eventParticipantStatus(ep);
  return result == "Active" || result == "Pending" ? "-" : result;
};

export const displayName = (ep, users) => {
  if (users.length) {
    const user = users.find((user) => user.id === ep.user_id);
    return user && companyNameOrEmail(user);
  }
};
