import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import PropTypes from "prop-types";

import { SendMessageModalPropTypes } from "./prop-types";
import { Confirm } from "@/components/common/Confirm";
import useMessageModal from "@/components/advanced_lots/hooks/useMessageModal";
import Tabs from "./Tabs";
import Footer from "./Footer";
import ModalBody from "./ModalBody";
import { ModalHeader } from "./Header";
import { isHost } from "@/utils/roles";

export const HOSTTAB = "hostTab";
export const PARTICIPANTTAB = "participantTab";

/**
 * list of all quickSelect options for host and participant tab
 */
export const quickSelectOptions = {
  [HOSTTAB]: [
    { value: "all_hosts", text: "messageAllHosts" },
    { value: "owner", text: "owner" },
    { value: "editor", text: "editors" },
    { value: "viewer", text: "viewers" },
    { value: "scorer", text: "scorers" },
  ],
  [PARTICIPANTTAB]: [
    { value: "all_participants", text: "messageAllParticipants" },
    { value: "active", text: "activeParticipants" },
    { value: "pending", text: "messageAllPendingParticipants" },
    { value: "declined", text: "messageAllDeclinedParticipants" },
  ],
};

/**
 * Get tab name for selected tab
 *
 * @param {boolean} isHostTabSelected
 * @returns {HOSTTAB | PARTICIPANTTAB}
 */
export const getTab = (isHostTabSelected) => (isHostTabSelected ? HOSTTAB : PARTICIPANTTAB);

/**
 *
 * @param {string} message
 * @returns {boolean} true if message is valid
 */
export const isValidMessage = (message = "") => !!message.replace(/<[^>]+>|&nbsp;*/g, "").trim();

// eslint-disable-next-line no-unused-vars
const ReopenEventModalPropTypes = {
  /**
   * Check if Confirm modal is visible
   *
   * @type {boolean}
   */
  isShowing: PropTypes.bool.isRequired,

  /**
   * handle confirmation or closing of modal
   *
   * @type {(e: React.MouseEvent, isConfirmedByHost: boolean) => void}
   */
  handleSendMessage: PropTypes.func.isRequired,
};

/**
 * ReopenEventModal content
 *
 * @type {React.FC<PropTypes.InferProps<ReopenEventModalPropTypes>>}
 * @returns {React.ReactElement}
 */
const ReopenEventModal = ({ isShowing, handleSendMessage }) => {
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);

  /* show confirmation pop-up if the event is in closed state
   *  and host want to send message to participants
   */
  if (!isShowing) return null;

  return (
    <div id="reopen-event-custom">
      <Confirm
        htmlId="reopenEventConfirmModal"
        modalClass="text-left modal-md"
        translations={translations}
        message={translations.reopenEventMessageForHost}
        onOkayButtonClick={(e) => handleSendMessage(e, true)}
        removeHook={(e) => handleSendMessage(e, false)}
      />
    </div>
  );
};

ReopenEventModal.propTypes = ReopenEventModalPropTypes;
ReopenEventModal.defaultProps = {
  isShowing: false,
  handleSendMessage: () => {},
};

// state selector for header
const headerStateSelection = createSelector(
  ({ lotReducers }) => lotReducers.event,
  ({ lotReducers }) => lotReducers.role,
  ({ lotReducers }) => lotReducers.in_sandpit,
  ({ messageReducers }) => messageReducers.showModal?.isNewMessage,
  (event, role, inSandpit, isNewMessage) => ({ event, isCurrentUserHost: isHost(role), inSandpit, isNewMessage })
);

const HeaderPropTypes = {
  /**
   * check if host tab is selected
   *
   * @type {boolean}
   */
  isHostTabSelected: PropTypes.bool.isRequired,

  /**
   * handler function for change in active tabs
   *
   * @type {(tab: string) => void}
   */
  selectTabs: PropTypes.func.isRequired,

  /**
   * handler function for change in formState
   *
   * @type {() => void}
   */
  setState: PropTypes.func.isRequired,
};

/**
 * Header of SendMessageModal
 *
 * @type {React.FC<PropTypes.InferProps<HeaderPropTypes>>}
 * @returns {React.ReactElement}
 */
const Header = (props) => {
  const { event, isCurrentUserHost, inSandpit, isNewMessage } = useSelector(headerStateSelection);

  if (!isNewMessage) return <ModalHeader eventName={event.name} />;

  return (
    <Fragment>
      <ModalHeader eventName={event.name} />
      {isCurrentUserHost && !inSandpit && <Tabs {...props} />}
    </Fragment>
  );
};

Header.propTypes = HeaderPropTypes;

Header.defaultProps = {
  isHostTabSelected: false,
  selectTabs: () => {},
};

/**
 * SendMessageModal content
 *
 * @type {React.FC<PropTypes.InferProps<SendMessageModalPropTypes>>}
 * @returns {React.ReactElement}
 */
const Container = (props) => {
  const { hasPermissionToEdit, marketDojo, documents, selectedParticipants, setSelectedParticipants, htmlId } = props;
  const event = useSelector(({ lotReducers }) => lotReducers.event);
  const useMessageModalValues = useMessageModal({ marketDojo, documents, selectedParticipants, htmlId });
  const { hosts, message, selectedParticipantIds, isHostTabSelected } = useMessageModalValues;
  const { handleSendMessage, selectTabs, setState, ...rest } = useMessageModalValues;
  const checkToReopenEvent = marketDojo && event.current_state == "closed" && hasPermissionToEdit && !isHostTabSelected;

  useEffect(() => {
    /**
     * setSelected participants of parent of this modal
     * to same as selected in this modal
     */
    setSelectedParticipants?.(selectedParticipantIds);
  }, [selectedParticipantIds]);

  const modalBodyProps = { ...rest, isHostTabSelected, message, marketDojo, selectedParticipantIds, hosts };
  const isSendButtonDisabled = !isValidMessage(message) || !selectedParticipantIds?.length;
  const footerProps = { checkToReopenEvent, hosts, isHostTabSelected, isSendButtonDisabled };

  return (
    <Fragment>
      <Header isHostTabSelected={isHostTabSelected} selectTabs={selectTabs} setState={setState} />
      <ModalBody {...modalBodyProps} />
      <Footer {...footerProps} sendMessage={handleSendMessage} />
      <ReopenEventModal isShowing={checkToReopenEvent} handleSendMessage={handleSendMessage} />
    </Fragment>
  );
};

Container.propTypes = SendMessageModalPropTypes;
Container.defaultProps = {
  documents: [],
  selectedParticipants: [],
  setSelectedParticipants: () => {},
};

export default Container;
