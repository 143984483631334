import * as apiCall from "./api";
import * as actionTypes from "./actionsTypes";
import { getErrors } from "./errorActions";
import config from "../config";

const showSuccess = (successMessage) => {
  return { type: actionTypes.SUCCESS, success: successMessage };
};

const fetchSimQuestionsDetailsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_SIM_QUESTIONS,
    payload: {
      questions: data.questions,
      linkedQuestions: data.linked_questions,
      sections: data.sections,
    },
  };
};

const fetchSimQuestionnairesDetailsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_SIM_QUESTIONNAIRES,
    payload: {
      questionnaires: data.questionnaires,
    },
  };
};

const linkedQuestionSuccess = (data) => {
  return {
    type: actionTypes.CREATE_OR_UPDATE_LINKED_QUESTION,
    payload: data,
  };
};

const deleteLinkedQuestionSuccess = (data) => {
  return {
    type: actionTypes.DELETE_LINKED_QUESTION,
    payload: data,
  };
};

export const fetchSimQuestionnaires = (companyId) => (dispatch) =>
  apiCall
    .get(`${config.SRM_BASE_URL}/company_linked_questions/fetch_sim_questionnaires?company_id=${companyId}`)
    .then((response) => {
      dispatch(fetchSimQuestionnairesDetailsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });

export const fetchSimQuestions = (questionnaireId, companyId) => (dispatch) => {
  apiCall
    .get(
      `${config.SRM_BASE_URL}/company_linked_questions/fetch_sim_questions?questionnaire_id=${questionnaireId}&company_id=${companyId}`
    )
    .then((response) => {
      dispatch(fetchSimQuestionsDetailsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getErrors(error.response));
    });
};

export const createOrUpdateLinkedQuestion = (payload) => {
  return (dispatch) =>
    apiCall
      .post(`${config.SRM_BASE_URL}/company_linked_questions`, payload)
      .then((response) => {
        dispatch(linkedQuestionSuccess(response.data));
        dispatch(showSuccess(response.data.message));
        return response.data;
      })
      .catch((error) => {
        dispatch(getErrors(error.response));
      });
};

export const deleteLinkedQuestion = (linkedQuestionId) => {
  return (dispatch) =>
    apiCall
      .remove(`${config.SRM_BASE_URL}/company_linked_questions/${linkedQuestionId}`)
      .then((response) => {
        dispatch(showSuccess(response.data.message));
        dispatch(deleteLinkedQuestionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getErrors(error.response));
      });
};
