import React from "react";
import classNames from "classnames";

const AddOrEditModalContent = (props) => {
  const { addNew = false, translations, unitSetName, selectedUom = {} } = props;
  const editId = `edit_unit_of_measure_${selectedUom && selectedUom.id}`;

  const setConversion = (e) => {
    e.target.value && props.updateConversion(e.target.value);
  };

  const setName = (e) => {
    e.target.value && props.updateName(e.target.value);
  };

  return (
    <React.Fragment>
      <form
        className="form"
        id={classNames({
          new_unit_of_measure: addNew,
          [editId]: !addNew,
        })}
      >
        <div className="input form-group">
          <label htmlFor="unit_of_measure_name">{translations.uom}</label>
          <div className="markup">
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setName(e);
              }}
              defaultValue={selectedUom.name}
              name="unit_of_measure[name]"
              id="unit_of_measure_name"
            />
          </div>
        </div>

        <div className="input">
          <label htmlFor="unit_of_measure_unit_set">
            {translations.unit_set} : {unitSetName}
          </label>
          <div className="markup">
            <input type="hidden" name="unit_of_measure[unit_set_id]" id="unit_of_measure_unit_set_id" />
          </div>
        </div>

        {!selectedUom.default || addNew ? (
          <div className="input form-group">
            <label htmlFor="unit_of_measure_conversion">{translations.conversion}</label>
            <div className="markup">
              <input
                className="uom_precision form-control"
                type="text"
                onChange={(e) => {
                  setConversion(e);
                }}
                defaultValue={selectedUom && selectedUom.ratio}
                name="unit_of_measure[ratio]"
                id="unit_of_measure_ratio"
              />
            </div>
          </div>
        ) : null}
      </form>
    </React.Fragment>
  );
};

export default AddOrEditModalContent;
