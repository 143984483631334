import { t } from "@/i18n";
import { updateError, updateValid } from "./date-field";
import type { Field } from ".";
import type { ValidationFunction } from "@adeattwood/react-form";
import { createValidator } from "@adeattwood/react-form";
import type { CustomFieldsForm } from "@/components/contract/custom-field-form/custom-field-form";
import { compose } from "@reduxjs/toolkit";
import { isValid, parseISO } from "date-fns/fp";

/**
 * This function will check if a custom field has an empty value.
 */
const isValueEmpty = (customField: Field): boolean => {
  const values = customField.custom_column_values;

  if (values.length !== 0) {
    // "values" array item will be an object and it will always be just one.
    // In case of multiple_choice "values[0]?.name" will be an array.
    return customField.column_type === "multiple_choice" ? values[0]?.name.length === 0 : values[0]?.name === "";
  }

  // returning true as if "values" is an empty array then the field is empty
  return true;
};

/**
 * This function will validate custom fields while saving a contract.
 */
export const validateCustomFieldsForContracts = (customFields: Field[]): boolean => {
  let isError = false;
  customFields.forEach((customField) => {
    if (customField.required) {
      const errorField = document.getElementById(`error_${customField.id}`) as HTMLSpanElement;
      if (isValueEmpty(customField)) {
        updateError(errorField, t("required"));
        isError = true;
      } else {
        updateValid(errorField);
      }
    }
  });

  return isError;
};

const makeRequiredMultiValidator = (field: Field) => (formState: CustomFieldsForm) => {
  return !(formState[field.id.toString()] as string[] | undefined)?.length ? t("required") : undefined;
};

const makeRequiredValidator = (field: Field) => {
  if (field.column_type === "multiple_choice") return makeRequiredMultiValidator(field);
  return (formState: CustomFieldsForm) => {
    return !formState[field.id.toString()] ? t("required") : undefined;
  };
};

const validateDate = compose(isValid, parseISO);

const makeDateValidator = (field: Field) => (formState: CustomFieldsForm) => {
  const value = formState[field.id.toString()] as string | undefined;
  const valid = (!field.required && !value) || validateDate(value ?? "");
  if (!valid) return t("workflow_rules.issues.invalid_date");
};

export const buildValidator = (customFields: Field[]) => {
  const rules = customFields.reduce(
    (acc, field) => {
      if (field.required && field.column_type !== "checkbox") {
        (acc[field.id.toString()] ??= []).push(makeRequiredValidator(field));
      }
      if (field.column_type === "date") {
        (acc[field.id.toString()] ??= []).push(makeDateValidator(field));
      }
      return acc;
    },
    {} as Record<string, ValidationFunction<CustomFieldsForm>[]>
  );
  return createValidator(rules);
};
