import React, { useState } from "react";
import Tooltip from "../../common/Tooltip";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { uploadTemplate } from "../../../actions/bidActions";

export default ({ event, translations, lots, participant }) => {
  const dispatch = useDispatch();
  const { bid_template } = translations.infos;
  const [uploadState, setUploadState] = useState({
    showUpload: false,
    uploadFile: null,
    uploading: false,
  });

  const setState = (_state) => {
    setUploadState({ ...uploadState, ..._state });
  };

  const onUploadBid = (e) => {
    setState({
      showUpload: true,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setState({
      uploading: true,
    });
    const bidTemplate = uploadState.uploadFile;
    const reader = new FileReader();
    let fileObj = {};
    reader.readAsDataURL(bidTemplate);
    let result = undefined;
    reader.onload = () => {
      const obj = {};
      obj.name = bidTemplate.name;
      obj.source = reader.result;
      obj.file_type = bidTemplate.type;
      fileObj = obj;
      dispatch(uploadTemplate({ csv: fileObj, event_participant: participant.id }, event.id)).then((response) => {
        setState({
          showUpload: false,
          uploadFile: null,
          uploading: false,
        });
      });
    };
  };

  const onClose = (e) => {
    setState({
      showUpload: false,
      uploadFile: null,
    });
  };

  const handleFileUpload = (event) => {
    setState({
      uploadFile: event.target.files[0],
    });
    document.getElementById(`inputfileLabel`).innerHTML = event.target.value.split("\\").pop();
  };

  const uploadComponent = () => {
    return (
      <div className="panel panel-default">
        <form onSubmit={handleFormSubmit}>
          <div className="block block-transparent remove-margin-b">
            <div className="block-header bg-primary-default">
              <h3 className="block-title">{translations.upload_bids}</h3>
            </div>

            <div className="block-content lot-fieldset">
              <div className="alert alert-info bid-upload-msg">{translations.bid_template_help_text_complex}</div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="upload_bids">
                      {translations.upload_bids}
                      &nbsp;
                      <span className="required">*</span>
                      &nbsp;
                      <Tooltip title={bid_template.title} body={bid_template.body} />
                    </label>
                    <div className="markup m-t10 text-center upload_lot">
                      <input
                        type="file"
                        name="csv"
                        id="csv"
                        className="inputfile inputfile-6 wid-100"
                        onChange={handleFileUpload}
                      />
                      <label htmlFor="csv" className="wid-100">
                        <span id="inputfileLabel"></span>{" "}
                        <strong className="float-right">
                          <i className="fa fa-upload"></i>
                        </strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="input inline">
                <button
                  data-dismiss="modal"
                  type="button"
                  className={classNames({
                    "btn btn-sm btn-default": true,
                    disabled: uploadState.uploading,
                  })}
                  onClick={onClose}
                >
                  {translations.cancel}
                </button>
                <button
                  name="button"
                  className={classNames({
                    "btn btn-sm btn-primary": true,
                    disabled: !uploadState.uploadFile || uploadState.uploading,
                  })}
                  type="submit"
                >
                  {uploadState.uploading ? translations.uploading : translations.upload_bids}{" "}
                  <i className="fa fa-check" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className="proxybid-upload container">
        <div className="row">
          <div className="col-md-12">
            <div className="pull-right">
              <div className="button button-event-current dropdown">
                <div className="btn-group btn-action">
                  <button
                    type="button"
                    className="dropdown-toggle status-event sm-butt current form-control"
                    data-toggle="dropdown"
                    style={{ maxWidth: "100%" }}
                  >
                    {translations.use_bid_template} <span className="caret"></span>
                  </button>
                  <ul className="dropdown-menu" role="menu">
                    <li>
                      <a href={`/events/${event.id}/lots.xlsx?bid_upload=true`}>{translations.download_bid_template}</a>
                      <a id="upload_bid_template" className="cursor-pointer" onClick={onUploadBid}>
                        {translations.upload_bids}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="pull-right push-10-r m-t4">
              <Tooltip title={bid_template.title} body={bid_template.body} />
            </div>
          </div>
        </div>
      </div>
      {uploadState.showUpload ? uploadComponent() : null}
    </>
  );
};
