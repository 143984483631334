import React, { useState } from "react";
import GetTab from "./GetTab";
import UseMemoCommon from "../../../../../common/UseMemoCommon";
import ShowTabData from "./DifferentiateTabData";
import { isRfq } from "../../../../eventCommon";

const Main = (props) => {
  const { translations, event, auctionTab, canShowDeleteBtn } = props;
  const [activeTab, updateActiveTab] = useState(
    auctionTab
      ? translations.auction_bids_html
      : isRfq(event)
      ? translations.rfq_bids_html
      : translations.qualification_bids_html
  );
  const GetTabMemo = UseMemoCommon(<GetTab activeTab={activeTab} updateActiveTab={updateActiveTab} {...props} />, [
    activeTab,
  ]);
  return (
    <div>
      {GetTabMemo}
      <ShowTabData {...props} activeTab={activeTab} translations={translations} canShowDeleteBtn={canShowDeleteBtn} />
    </div>
  );
};

export default Main;
