import { emptyApi } from "@/slices/api";
import type { TemplateIdentifier } from "@/slices/lots/templates";
import type { GetRulesAndFieldsResponse, UpdateRulesRequest } from "@/slices/rules/types";
import { makeUpdateRulesRequest, transformGetRulesAndFieldsResponse } from "@/slices/rules/types";

export const documentTemplatesApi = emptyApi.enhanceEndpoints({ addTagTypes: ["DocRules"] }).injectEndpoints({
  endpoints: (build) => ({
    getDocumentRulesAndFields: build.query<GetRulesAndFieldsResponse, TemplateIdentifier>({
      query: ({ companyId, templateId }) => `v2/companies/${companyId}/document_templates/${templateId}/rule`,
      transformResponse: transformGetRulesAndFieldsResponse,
      providesTags: (result, err, { templateId }) => (result ? [{ type: "DocRules", id: templateId }] : []),
    }),
    updateDocumentRules: build.mutation<void, UpdateRulesRequest>({
      query: ({ companyId, templateId, ruleId, rules }) => ({
        url: `v2/companies/${companyId}/document_templates/${templateId}/rule`,
        method: "PUT",
        body: makeUpdateRulesRequest({ companyId, templateId, ruleId, rules }),
      }),
      invalidatesTags: (result, err, { templateId }) => [{ type: "DocRules", id: templateId }],
    }),
  }),
  overrideExisting: true,
});

export const { useGetDocumentRulesAndFieldsQuery, useUpdateDocumentRulesMutation } = documentTemplatesApi;
