/* eslint-disable @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-explicit-any */
import { getCSRFToken } from "@/lib/contracts";
import type { OneOf } from "@/types/util";
import { isPlainObject } from "@reduxjs/toolkit";
import type { fetchBaseQuery } from "@reduxjs/toolkit/query";

// url must be absolute for tests
export const TEST_BASE_URL = "https://next.testmd.co.uk/";
export const getTestUrl = (path: string) => joinUrls(TEST_BASE_URL, path);

export const ensureAbsoluteUrl = (url: string) => {
  if (process.env.NODE_ENV === "test" && !absoluteUrl.test(url)) {
    return getTestUrl(url);
  }
  return url;
};

type PrepareHeadersApi = typeof fetchBaseQuery extends (args: {
  prepareHeaders?: (headers: Headers, api: infer Api) => Headers;
}) => any
  ? Api
  : never;

export const prepareHeaders = (headers: Headers, { requestId }: OneOf<FetchConfig | PrepareHeadersApi> = {}) => {
  const csrfToken = getCSRFToken();
  if (csrfToken) {
    headers.set("X-CSRF-Token", csrfToken);
  }
  headers.set("X-Requested-With", "XMLHttpRequest");
  if (requestId) {
    headers.set("X-STREAM-REQUEST-ID", requestId);
  }
  return headers;
};

interface CustomRequestInit extends Omit<RequestInit, "headers"> {
  headers?: Headers | string[][] | Record<string, string | undefined> | undefined;
}

export interface FetchArgs extends CustomRequestInit {
  url: string;
  params?: Record<string, any>;
  body?: any;
}

interface FetchConfig {
  requestId?: string;
  signal?: AbortSignal;
}
// If either :// or // is present consider it to be an absolute url
const absoluteUrl = /(^|:)\/\//;

const withoutTrailingSlash = (url: string) => url.replace(/\/$/, "");
const withoutLeadingSlash = (url: string) => url.replace(/^\//, "");

function joinUrls(base: string | undefined, url: string | undefined): string {
  if (!base) {
    return url!;
  }
  if (!url) {
    return base;
  }

  if (absoluteUrl.test(url)) {
    return url;
  }

  const delimiter = base.endsWith("/") || !url.startsWith("?") ? "/" : "";
  base = withoutTrailingSlash(base);
  url = withoutLeadingSlash(url);

  return `${base}${delimiter}${url}`;
}

const isJsonifiable = (body: any) =>
  typeof body === "object" && (isPlainObject(body) || Array.isArray(body) || typeof body?.toJSON === "function");
const isJsonContentType = (headers: Headers) =>
  /*applicat*/ /ion\/(vnd\.api\+)?json/.test(headers.get("content-type") || "");

function stringifyJsonBody(config: RequestInit & { headers: Headers; body?: any }) {
  if (!config.headers?.has("content-type") && isJsonifiable(config.body)) {
    config.headers.set("content-type", "application/json");
  }

  if (isJsonifiable(config.body) && isJsonContentType(config.headers)) {
    config.body = JSON.stringify(config.body);
  }
}

function getFinalArgs(arg: FetchArgs | string, fetchConfig: FetchConfig = {}): Parameters<typeof fetch> {
  const fetchArgs: FetchArgs = typeof arg === "string" ? { url: arg } : arg;
  // eslint-disable-next-line prefer-const
  let { url, params, ...rest } = fetchArgs;
  const headers = new Headers(stripUndefined(fetchArgs.headers));

  prepareHeaders(headers, fetchConfig);

  const config = { ...rest, headers, signal: fetchConfig.signal || rest.signal } satisfies RequestInit;

  stringifyJsonBody(config);

  if (params) {
    const divider = url.includes("?") ? "&" : "?";
    const query = new URLSearchParams(stripUndefined(params));
    url += divider + query.toString();
  }

  url = ensureAbsoluteUrl(url);

  return [url, config];
}

function stripUndefined(obj: any) {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const copy: Record<string, any> = { ...obj };
  for (const [k, v] of Object.entries(copy)) {
    if (v === undefined) delete copy[k];
  }
  return copy;
}

async function getErrorMessage(res: Response) {
  let message = `Failed to connect to ${res.url}: ${res.status} ${res.statusText}`;
  const text = await res.text();
  try {
    const parsed: unknown = JSON.parse(text);
    const hasErrorKey = isPlainObject(parsed) && "error" in parsed;
    message = hasErrorKey && typeof parsed.error === "string" ? parsed.error : `${message} ${text}`;
  } catch {
    // not a JSON response
    message += ` ${text}`;
  }
  return message;
}

/**
 * A wrapper around `fetch` with opinionated defaults.
 *
 * - Accepts similar arguments to `fetchBaseQuery`, including useful behaviour for headers and params.
 * - Sets the `X-CSRF-Token` header if a CSRF token is available.
 * - Sets the `X-Requested-With` header to `XMLHttpRequest`.
 * - Sets the `X-STREAM-REQUEST-ID` header if a `requestId` is provided.
 * - Throws an error if the response is not `ok`, extracting the error message from the response body if possible.
 * - Makes the URL absolute in test environments. (see `TEST_BASE_URL`)
 */
export async function customFetch(fetchArgs: FetchArgs | string, fetchConfig?: FetchConfig) {
  const res = await fetch(...getFinalArgs(fetchArgs, fetchConfig));
  if (!res.ok) {
    throw new Error(await getErrorMessage(res));
  }
  return res;
}
