import React from "react";
import { useSelector } from "react-redux";
import {
  differenceOfferedPercentageForBlic,
  formatDifferenceOfferedPercentage,
  differenceOfferedForBlic,
  preferredCurrency,
  differenceOfferedWithCurrency,
} from "../../../bidCommon";
import { SavingBlock } from "../lotInformationForOverview/SavingOffered";

/**
 *
 * @description Host monitor
 *
 * Column - Saving Percentage
 */
export default function LiSavingPercentage(props) {
  const { blicCurrency, licCurrency, allUom, lineItemBestBlic, lineItemLic, bid } = props;

  const {
    event,
    translations: { na_in_small_letters, number_format },
    locale,
    bids,
    bid_line_item_components: bidLineItemComponents,
    unit_set_unit_of_measures: unitSetUoms,
  } = useSelector((state) => state.lotReducers);

  const val = differenceOfferedPercentageForBlic(
    event,
    lineItemBestBlic,
    lineItemLic,
    allUom,
    bid,
    bidLineItemComponents,
    bids
  );
  const result = formatDifferenceOfferedPercentage(val, na_in_small_letters, locale);
  const diffValue = differenceOfferedForBlic(
    event,
    lineItemBestBlic,
    lineItemLic,
    allUom,
    unitSetUoms,
    bid,
    bidLineItemComponents,
    bids
  );
  const finalCurrency = preferredCurrency(blicCurrency, licCurrency, "host");
  const diffValueWithCurrency = differenceOfferedWithCurrency(diffValue, finalCurrency, locale, number_format);

  return <SavingBlock diffValueWithCurrency={diffValueWithCurrency} result={result} />;
}
