import PropTypes from "prop-types";

export const LotComponent = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  /**
   * Who is this user that will be entering data into this component.
   *
   * - 0: host entered
   * - 1: participant entered
   * - 2: calculation component (total)
   */
  provider_cd: PropTypes.number,
  tag: PropTypes.string,
  unit_set_id: PropTypes.any,
  default_uom_id: PropTypes.any,
  is_uom_set: PropTypes.number,
  decimal_place: PropTypes.number,
  default_formula_lic_id: PropTypes.any,
  default_is_ranked: PropTypes.number,
  default_is_visible_rank: PropTypes.number,
  default_exchange_rate_id: PropTypes.any,
  lot_component_type: PropTypes.number,
  picklist_id: PropTypes.number,
  lot_id: PropTypes.number,
  updated_at: PropTypes.string,
  line_item_total: PropTypes.number,
};

/** @typedef {PropTypes.InferProps<typeof LotComponent>} LotComponent */

export default LotComponent;
