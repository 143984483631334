import type { AppStartListening } from "@/middleware/listener";
import { emptyApi } from "@/slices/api";
import { hydrated } from "@/slices/hydrate";
import type { Override, WithRequired } from "@/types/util";
import { partition } from "@/utils";
import { assert } from "@/utils/assert";
import type { EntityState } from "@reduxjs/toolkit";
import { createEntityAdapter, isAllOf } from "@reduxjs/toolkit";

export interface User {
  id: string;
  created_at?: string;
  updated_at?: string;
  accepted_t_and_c?: boolean;
  company_id?: string;
  email: string;
  manually_locked?: boolean;
  seen_at?: string;
  display_name?: string;
  avatar_url?: string;
}

export type EmailStatus = "pending" | "delivered" | "failed";

export interface EventCollaborator {
  id: string;
  created_at: string;
  updated_at: string;
  event_id: string;
  event_participant_id: string;
  invite_delivery_status: EmailStatus;
  is_lead: boolean;
  user: User;
}

export interface EventParticipantIdentifier {
  eventId: string;
  eventParticipantId: string;
}

export interface EventCollaboratorIdentifier extends EventParticipantIdentifier {
  collaboratorId: string;
}

export const eventCollaboratorAdapter = createEntityAdapter<EventCollaborator>();

export interface RawGetEventCollaboratorsResponse {
  collaborators: EventCollaborator[];
}

export interface GetEventCollaboratorsResponse extends EntityState<EventCollaborator, EventCollaborator["id"]> {
  leadCollaborator: EventCollaborator;
}

function getEventCollaboratorsResponse({
  collaborators,
}: RawGetEventCollaboratorsResponse): GetEventCollaboratorsResponse {
  const [leadCollaborators, otherCollaborators] = partition(collaborators, (collaborator) => collaborator.is_lead);
  const [leadCollaborator] = leadCollaborators;
  assert(leadCollaborator && leadCollaborators.length === 1, "Exactly one lead collaborator should exist");
  return eventCollaboratorAdapter.getInitialState({ leadCollaborator }, otherCollaborators);
}

export const eventCollaboratorApi = emptyApi.enhanceEndpoints({ addTagTypes: ["EventCollaborator"] }).injectEndpoints({
  endpoints: (build) => ({
    getEventCollaborators: build.query<GetEventCollaboratorsResponse, EventParticipantIdentifier>({
      query: ({ eventId, eventParticipantId }) =>
        `v2/events/${eventId}/event_participants/${eventParticipantId}/collaborators`,
      transformResponse: getEventCollaboratorsResponse,
      providesTags: (res) => [
        { type: "EventCollaborator", id: "LIST" },
        ...(res?.ids.map((id) => ({ type: "EventCollaborator" as const, id })) ?? []),
      ],
    }),
    addEventCollaborator: build.mutation<
      EventCollaborator,
      {
        user: Pick<User, "email">;
      } & EventParticipantIdentifier
    >({
      query: ({ eventId, eventParticipantId, user }) => ({
        url: `v2/events/${eventId}/event_participants/${eventParticipantId}/collaborators`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: (res) => [res ? { type: "EventCollaborator", id: res.id } : null],
    }),
    removeEventCollaborator: build.mutation<void, EventCollaboratorIdentifier>({
      query: ({ eventId, eventParticipantId, collaboratorId }) => ({
        url: `v2/events/${eventId}/event_participants/${eventParticipantId}/collaborators/${collaboratorId}`,
        method: "DELETE",
      }),
      invalidatesTags: (res, err, { collaboratorId }) => [{ type: "EventCollaborator", id: collaboratorId }],
    }),
    resendCollaboratorInvitation: build.mutation<{ status: string }, EventCollaboratorIdentifier>({
      query: ({ eventId, eventParticipantId, collaboratorId }) => ({
        url: `v2/events/${eventId}/event_participants/${eventParticipantId}/collaborators/${collaboratorId}/resend`,
        method: "POST",
      }),
      invalidatesTags: (res, _err, { collaboratorId }) => [
        res ? { type: "EventCollaborator", id: collaboratorId } : null,
      ],
    }),
  }),
});

export const {
  useGetEventCollaboratorsQuery,
  useAddEventCollaboratorMutation,
  useRemoveEventCollaboratorMutation,
  useResendCollaboratorInvitationMutation,
} = eventCollaboratorApi;

type HydrationAction = ReturnType<typeof hydrated>;

const isHydratedWithCollaborators = isAllOf(
  hydrated,
  (
    // technically an assertion, but a safe (and useful) one
    // we know this predicate won't run unless hydrated matches first
    action: HydrationAction
  ): action is Override<HydrationAction, { payload: WithRequired<HydrationAction["payload"], "eventCollaborators"> }> =>
    !!action.payload.eventCollaborators
);

export const setupEventCollaboratorListener = (startListening: AppStartListening) =>
  startListening({
    matcher: isHydratedWithCollaborators,
    effect({ payload }, { dispatch }) {
      const { collaborators } = payload.eventCollaborators;
      const { event_id, event_participant_id } = collaborators[0];
      // hydrate our cache with the data we got from the server
      dispatch(
        eventCollaboratorApi.util.upsertQueryEntries([
          {
            endpointName: "getEventCollaborators",
            arg: { eventId: event_id, eventParticipantId: event_participant_id },
            value: getEventCollaboratorsResponse({ collaborators }),
          },
        ])
      );
    },
  });

export const {
  selectAll: selectAllEventCollaborators,
  selectById: selectEventCollaboratorById,
  selectIds: selectEventCollaboratorIds,
  selectEntities: selectEventCollaboratorEntities,
  selectTotal: selectTotalEventCollaborators,
} = eventCollaboratorAdapter.getSelectors();

export const selectLeadCollaborator = (state: GetEventCollaboratorsResponse) => state.leadCollaborator;
