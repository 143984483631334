import PropTypes from "prop-types";

export const LineItemComponent = {
  id: PropTypes.number,
  line_item_id: PropTypes.number,
  lot_component_id: PropTypes.number,
  tag: PropTypes.string,
  is_ranked: PropTypes.number,
  is_visible_rank: PropTypes.number,
  exchange_rate_id: PropTypes.any,
  valid_cell: PropTypes.number,
  quantity: PropTypes.number,
  description: PropTypes.any,
  is_calculation: PropTypes.number,
  lot_component_type: PropTypes.number,
  decimal_place: PropTypes.number,
  is_uom_set: PropTypes.number,
  picklist_id: PropTypes.number,
  unit_set_id: PropTypes.any,
  unit_of_measure_id: PropTypes.any,
  current_price: PropTypes.any,
  used_price: PropTypes.any,
  attribute_value: PropTypes.any,
  date_value: PropTypes.any,
  picklist_option_id: PropTypes.any,
  formula: PropTypes.any,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  unit_set_name: PropTypes.any,
  line_item_total: PropTypes.number,
  qualification_price: PropTypes.any,
  total_qualification_value: PropTypes.any,
  is_price: PropTypes.number,
  host: PropTypes.number,
  participant: PropTypes.number,
  calculation: PropTypes.number,
  is_formula_lic: PropTypes.number,
  calculable_lc: PropTypes.number,
  calculable_currency_lc: PropTypes.number,
  is_formula_calculation_component: PropTypes.number,
  is_default_formula: PropTypes.number,
};

/** @typedef {PropTypes.InferProps<typeof LineItemComponent>} LineItemComponent */

export default LineItemComponent;
