import React from "react";
import type { CustomColumnCommonProps } from "./pick-one-from-list";
import { useCheckboxAttribute } from "@adeattwood/react-form";
import Feedback from "@/cl/input/feedback";

interface CheckboxFieldProps extends CustomColumnCommonProps {}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ field, readOnly }) => {
  const { props, error } = useCheckboxAttribute(field.id.toString());
  return (
    <>
      <input
        {...props}
        type="checkbox"
        className="form-control height-15"
        disabled={readOnly || !field.active}
        aria-labelledby={`custom-field-${field.id}-name`}
      />
      <label htmlFor={props.id} className="hidden"></label>
      <Feedback className="required" id={props.id} error={error} />
    </>
  );
};

export default CheckboxField;
