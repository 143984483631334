import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import { requestLoaderForActions } from "../utils/requestLoaderForActions";
import { getError } from "./errorActions";
import { makeApiCall } from "./commonActions";
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";

const fetchBilcDetailsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BLICS,
    payload: {
      bidList: data.bid_list,
      questionnaires: data.questionnaires,
      questionnaireSections: data.questionnaire_sections,
      questionnaireQuestions: data.questionnaire_questions,
      questionnaireAnswers: data.questionnaire_answers,
      answers: data.answers,
      questionnaireAnswersQuestions: data.questionnaire_answers_questions,
      questionnaireAnswersSections: data.questionnaire_answers_sections,
    },
  };
};

const createBlicSuccess = ({ data }, licId, lotId) => {
  if (!window.advancedLots) window.advancedLots = {};
  window.advancedLots.createUpdateBlicId = licId;
  return {
    type: actionTypes.UPDATE_BLIC_LIST,
    data,
    licId,
    lotId,
  };
};

const deleteLineItemBlicsSuccess = (data) => {
  return {
    type: actionTypes.DELETE_LINE_ITEM_BLICS,
    payload: data,
  };
};

export const setValidationErrors = createAction("setValidationErrors");

export const fetchBlicsDetails =
  (licId, beforeAuction, showOverallScore, eventId, participantId, auctionTab) => async (dispatch) => {
    dispatch(requestLoaderForActions(actionTypes.FETCH_BLICS_REQUESTED));
    const params = participantId ? `&participant_id=${participantId}` : "";
    const url = `${config.V2_BASE_URL}/line_item_components/${licId}/bid_line_item_components?before_auction=${beforeAuction}&show_overall_score=${showOverallScore}&event_id=${eventId}&auction_tab=${auctionTab}${params}`;
    await apiCall
      .get(url)
      .then((response) => {
        dispatch(fetchBilcDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getError(error.response));
      })
      .finally(() => {
        dispatch(requestLoaderForActions(actionTypes.FETCH_BLICS_COMPLETED));
      });
  };

export const createBidLineItemComponent = createAsyncThunk(
  "bidLineComponent/create",
  async ({ bidId, lotId, payload, lineItemComponentId, lineItemId }) => {
    const url = `${config.V2_BASE_URL}/bids/${bidId}/bid_line_item_components`;
    const { data } = await apiCall.post(url, payload);

    return { lotId, lineItemComponentId, data, lineItemId };
  }
);

/**
 * @deprecated Please use `createBidLineItemComponent`
 */
export const createBlic = (bidId, payload, licId, lotId) => {
  return (dispatch) =>
    apiCall
      .post(`${config.V2_BASE_URL}/bids/${bidId}/bid_line_item_components`, payload)
      .then((response) => dispatch(createBlicSuccess(response, licId, lotId)))
      .catch((error) => {
        dispatch(getError(error.response));
      });
};

export const updateBlic = (lotId, bidId, { exchange_rate_id, event_participant, id, event_id }) => {
  const url = `${config.V2_BASE_URL}/lots/${lotId}/bids/${bidId}/update_bid_range?exchange_rate_id=${exchange_rate_id}&event_participant=${event_participant}&id=${id}&event_id=${event_id}`;
  return (dispatch) => apiCall.get(url).then((response) => dispatch(createBlicSuccess(response)));
};

export const deleteLineItemBlics = (bidId, lineItemId) => (dispatch) =>
  makeApiCall(
    "remove",
    `${config.V2_BASE_URL}/bids/${bidId}/bid_line_item_components/destroy_line_item_blics?line_item_id=${lineItemId}`,
    deleteLineItemBlicsSuccess,
    dispatch
  );
