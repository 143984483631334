import { makeApiCall } from "../../actions/commonActions";
import config from "../../config";
import { updateRealTimeBids } from "../../actions/lotActions";

export default function ({ event_id, bid_id, lot_id, notification_type }, { dispatch }) {
  const callApi = (url) => {
    makeApiCall("get", `${config.V2_BASE_URL}/events/${event_id}/bids/${url}`, updateRealTimeBids, dispatch);
  };

  if (notification_type === "new_bid") {
    callApi(`${bid_id}/real_time_update`);
  }

  if (notification_type === "delete_bid") {
    callApi(`real_time_update_for_delete?lot_id=${lot_id}`);
  }
}
