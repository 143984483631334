import React, { Fragment, lazy, Suspense, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  latestBidDuringAuctionForLot,
  latestBidBeforeAuctionForLot,
  relatedBidLineItemComponents,
  fetchAssociatedBlicOrBidCurrency,
  findLicBidBlic,
} from "../../../bidCommon";
import { multiCurrencyEvent, fetchAssociatedCurrency, isPrice } from "../../../common";

const WeightedScore = lazy(() => import("./WeightedScore"));
const BidValue = lazy(() => import("./BidValue"));
const SavingsPercentage = lazy(() => import("./SavingsPercentage"));
const RankOfCell = lazy(() => import("./RankOfCell"));
const SingleBlicValue = lazy(() => import("./SingleBlicValue"));

/**
 * @description
 * Monitor Tab =>
 * Opens up once clicked on Show Components =>
 * Shows single lotComponent
 *
 * Contains - WEIGHTED SCORE | BID VALUE	| SAVINGS | RANK
 */
const ParticipantBidDetails = memo((props) => {
  const { ep, LBids, lineItemId, lot, currency, auctionTab, weightedRfqOrAuction, index, lotComponent } = props;

  const {
    event,
    bid_line_item_components: bidLineItemComponents,
    currencies_hash: currenciesHash,
    line_item_components: lineItemComponents,
    translations,
  } = useSelector((state) => state.lotReducers);

  const bestBidBeforeAuction = useMemo(
    () =>
      auctionTab
        ? latestBidDuringAuctionForLot(LBids, [ep], event, weightedRfqOrAuction).reverse()[0]
        : latestBidBeforeAuctionForLot(lot, LBids, ep, event),
    [LBids, ep]
  );
  const bidBlics = useMemo(
    () => (bestBidBeforeAuction ? relatedBidLineItemComponents(bestBidBeforeAuction.id, bidLineItemComponents) : []),
    [bidLineItemComponents]
  );

  const lic = lineItemComponents?.find(
    (lic) => lic.line_item_id === lineItemId && lic.lot_component_id === lotComponent?.id
  );

  const licCurrency = multiCurrencyEvent(event)
    ? fetchAssociatedCurrency(currenciesHash, lot, lic, lotComponent)
    : currency;

  let blic;
  if (lic && bestBidBeforeAuction) blic = findLicBidBlic(bidBlics, lic.id, bestBidBeforeAuction.id);

  const blicCurrency =
    blic && multiCurrencyEvent(event)
      ? fetchAssociatedBlicOrBidCurrency(currenciesHash, bestBidBeforeAuction, lic)
      : licCurrency;

  const commonProps = { blic, lic, lotComponent, bestBidBeforeAuction, participantLotBid: bestBidBeforeAuction };

  const singleBidOrBlicValueProps = { ...props, ...commonProps, bidBlics, blicCurrency, licCurrency };

  if (!isPrice(lotComponent))
    return (
      <div className="mycell non-price" key={`${lot.id}-${bestBidBeforeAuction && bestBidBeforeAuction.id}-${index}`}>
        <Suspense fallback={`${translations.loading}...`}>
          <SingleBlicValue {...singleBidOrBlicValueProps} />
        </Suspense>
      </div>
    );

  return (
    <Fragment key={`${lot.id}-${bestBidBeforeAuction && bestBidBeforeAuction.id}-${index}`}>
      <Suspense fallback={`${translations.loading}...`}>
        <WeightedScore {...commonProps} displayScores={props.displayScores} />
      </Suspense>
      <Suspense fallback={`${translations.loading}...`}>
        <BidValue {...singleBidOrBlicValueProps} />
      </Suspense>
      <Suspense fallback={`${translations.loading}...`}>
        <SavingsPercentage {...props} {...commonProps} bidBlics={bidBlics} licCurrency={licCurrency} />
      </Suspense>
      <Suspense fallback={`${translations.loading}...`}>
        <RankOfCell {...commonProps} beforeAuction={props.beforeAuction} lot={lot} />
      </Suspense>
    </Fragment>
  );
}, isEqual);

export default ParticipantBidDetails;
