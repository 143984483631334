import React from "react";
import PropTypes from "prop-types";
import features from "@/utils/features";

function EditPermissionActionButtons({ translations = {} }) {
  const actionButtons = [
    { dataTarget: "#addUserModal", text: "addUser" },
    { dataTarget: "#notifyChangesConfirmModal", text: "notifyRules" },
  ];

  if (features.enabled("default_host_permissions"))
    actionButtons.push({ dataTarget: "#defaultPermissionsModal", text: "defaultPermissions" });

  return (
    <div className="col-md-12 clear-padding-desktop m-b10">
      <div className="decor-line" />
      {actionButtons.map(({ dataTarget, text }) => (
        <button
          key={dataTarget}
          data-toggle="modal"
          data-target={dataTarget}
          className="btn-blue-outline btn btn-default m-r5"
        >
          {`+ ${translations[text]}`}
        </button>
      ))}
    </div>
  );
}

EditPermissionActionButtons.propTypes = {
  translations: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default EditPermissionActionButtons;
