import React, { Component } from 'react';
import RenderHTML from "@/components/common/RenderHTML";

class SeeBrief extends Component {
  render() {
    const { quoteDesc, translations } = this.props;
    return (
      <div className='panel panel-default'>
        <div className='panel-heading' role='tab' id='headingFour'>
          <h4 className='panel-title'>
            <a className='collapsed' role='button' data-toggle='collapse' href='#collapseFour'>
              {translations.summary_status.see_brief}
            </a>
          </h4>
        </div>
        <div id='collapseFour' className='panel-collapse collapse'>
          <div className='panel-body'>
            <div className='col-xs-12 message-top'>
              <div className='table-box list'>
                <RenderHTML content={quoteDesc} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SeeBrief;
