// #ENTRYPOINT
import React from "react";
import Alert from "react-s-alert";
import { connect } from "react-redux";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import { lazy, Suspense } from "react";
import isEmpty from "lodash/isEmpty";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-oldschool-dark";
import { alertOptions } from "./quick_quotes/js/common";

const Calendar = lazy(() => import("./dashboard/Calendar"));
const HostPermissions = lazy(() => import("./admin/host/host_permissions/HostPermissions"));
const MessagesContainer = lazy(() => import("./messages/MessageContainer"));
const ContainerComponent = lazy(() => import("./contract/contractConfiguration/ContainerComponent"));
const CreateEditContainer = lazy(() => import("./contract/createEdit/CreateEditContainer"));
const SimSettings = lazy(() => import("./admin/host/sim_settings/SimSettings"));
import AdvancedLot from "./advanced_lots/AdvancedLot";
import Monitor from "./advanced_lots/Monitor";
import MyObjectives from "./dashboard/MyObjectives";
const CustomFieldsFormModal = lazy(() => import("./contract/custom-field-form/modal"));
const SrmSettings = lazy(() => import("./srm_settings/SrmSettings"));
const CustomFieldsFormWrapper = lazy(() => import("./contract/custom-field-form"));
const HistoryDetails = lazy(() => import("./contract/createEdit/HistoryDetails"));
const ContractListingMain = lazy(() => import("./contract/contractListing/ContractListingMain"));
const SrmActivityIndex = lazy(() => import("./activities/Index"));
const QuickQuote = lazy(() => import("./quick_quotes/QuickQuote"));
const PermissionDenied = lazy(() => import("./quick_quotes/PermissionDenied"));
const QQCreate = lazy(() => import("./quick_quotes/pages/QQEvent/QQCreate"));
const QQSummaryStatus = lazy(() => import("./quick_quotes/pages/QQSummary/QQSummaryStatus"));
const EditForm = lazy(() => import("./quick_quotes/pages/QQEdit/EditForm"));
const EMListing = lazy(() => import("./quick_quotes/pages/emarketplace/material_request_listing/EMListing"));
const EMCreate = lazy(() => import("./quick_quotes/pages/emarketplace/material_request/EMCreate"));
const EMSummaryStatus = lazy(
  () => import("./quick_quotes/pages/emarketplace/material_request_summary/EMSummaryStatus")
);
const CentralisedCustomFields = lazy(() => import("./custom_columns"));

class App extends React.Component {
  renderComponent(componentName) {
    const { translations } = this.props;
    switch (componentName) {
      case "Calendar":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <Calendar {...this.props} />
          </Suspense>
        );
      case "HostPermissions":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <HostPermissions {...this.props} />
          </Suspense>
        );
      case "Messages":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <MessagesContainer {...this.props} />
          </Suspense>
        );
      case "ContractConfiguration":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <ContainerComponent {...this.props} />
          </Suspense>
        );
      case "CreateEditContainer":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <CreateEditContainer {...this.props} />
          </Suspense>
        );

      case "ContractListingMain":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <ContractListingMain {...this.props} />
          </Suspense>
        );
      case "SimSettings":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <SimSettings {...this.props} />
          </Suspense>
        );
      case "AdvancedLot":
        return <AdvancedLot {...this.props} />;
      case "CustomFieldsForm":
      case "SrmCustomFieldsForm":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <CustomFieldsFormWrapper {...this.props} />
          </Suspense>
        );
      case "SrmContactCustomFieldsForm":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <CustomFieldsFormModal fieldCount={this.props.customFields.length}>
              <CustomFieldsFormWrapper hideTitle {...this.props} />
            </CustomFieldsFormModal>
          </Suspense>
        );
      case "HistoryDetails":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <HistoryDetails {...this.props} />
          </Suspense>
        );
      case "SrmActivityIndex":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <SrmActivityIndex {...this.props} />
          </Suspense>
        );
      case "Monitor":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <Monitor {...this.props} />
          </Suspense>
        );
      case "SrmSettings":
        return (
          <Suspense fallback={<div>{"translations.loading"}</div>}>
            <SrmSettings {...this.props} />
          </Suspense>
        );
      case "Widget:MyObjective":
        return (
          <Suspense fallback={<div>{"translations.loading"}</div>}>
            <MyObjectives {...this.props} />
          </Suspense>
        );
      case "QuickQuote":
        return (
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <Suspense fallback={<div>{translations.loading}</div>}>
              <QuickQuote {...this.props} />
            </Suspense>
          </AlertProvider>
        );
      case "PermissionDenied":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <PermissionDenied {...this.props} />
          </Suspense>
        );
      case "EMListing":
        return (
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <Suspense fallback={<div>{translations.loading}</div>}>
              <EMListing {...this.props} />
            </Suspense>
          </AlertProvider>
        );
      case "EMCreate":
        return (
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <Suspense fallback={<div>{translations.loading}</div>}>
              <EMCreate {...this.props} />
            </Suspense>
          </AlertProvider>
        );
      case "EMSummaryStatus":
        return (
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <Suspense fallback={<div>{translations.loading}</div>}>
              <EMSummaryStatus {...this.props} />
            </Suspense>
          </AlertProvider>
        );
      case "QQSummaryStatus":
        return (
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <Suspense fallback={<div>{translations.loading}</div>}>
              <QQSummaryStatus {...this.props} />
            </Suspense>
          </AlertProvider>
        );
      case "QQCreate":
        return (
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <Suspense fallback={<div>{translations.loading}</div>}>
              <QQCreate {...this.props} />
            </Suspense>
          </AlertProvider>
        );
      case "EditForm":
        return (
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <Suspense fallback={<div>{translations.loading}</div>}>
              <EditForm {...this.props} />
            </Suspense>
          </AlertProvider>
        );
      case "CentralisedCustomFields":
        return (
          <Suspense fallback={<div>{translations.loading}</div>}>
            <CentralisedCustomFields {...this.props} />
          </Suspense>
        );
    }
  }

  componentDidMount() {
    const notify = JSON.parse(window.localStorage.getItem("notify") || "{}");
    if (notify?.eventId) {
      window.localStorage.removeItem("notify");

      const { status, message: notifyMessage } = notify;
      this.props.dispatch({
        type: "notify",
        payload: { status, notifyMessage },
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!isEmpty(newProps.data.error)) {
      const errorData = newProps.data.error;
      let errorMessage = "";
      if (typeof errorData === "object") {
        errorMessage = errorData.toString();
      } else {
        errorMessage = errorData;
      }
      this.displayErrorMessage(errorMessage);
    }

    if (!isEmpty(newProps.data.errors)) {
      const errorData = newProps.data.errors;
      let errorMessage = errorData.join(" <br /> ");
      this.displayErrorMessage(errorMessage);
    }

    if (!isEmpty(newProps.data.success)) {
      const successData = newProps.data.success;
      let successMessage = "";
      if (typeof successData === "object") {
        successMessage = successData.toString();
      } else {
        successMessage = successData;
      }
      Alert.success(successMessage, {
        position: "top-left",
        effect: "slide",
        timeout: window.successAlertTimeout || 8000,
        html: true,
        offset: 70,
      });
    }
  }

  displayErrorMessage(errorMessage) {
    Alert.error(errorMessage, {
      position: "top-left",
      effect: "slide",
      timeout: window.errorAlertTimeout || 8000,
      html: true,
      offset: 70,
    });
  }

  shouldComponentUpdate(newProps) {
    if (!isEmpty(newProps.data.error) || !isEmpty(newProps.data.success)) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderComponent(this.props.componentName)}
        <Alert stack={{ limit: 3 }} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { data: state.errorReducers };
};

export default connect(mapStateToProps, null)(App);
