import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import { isIE } from "../common";
import UpdateSingleLicCurrentPrice from "./UpdateSingleLicCurrentPrice";

const UpdateLicCurrentPrice = (props) => {
  const { translations, lot, modalIsOpen, setModalIsOpen = () => {}, priceLicsWithoutFormula } = props;

  const closeModal = () => {
    setModalIsOpen(false);
    window.location.reload();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={classNames({
        "modal-content col-md-8 col-sm-8 custom-field-popup": true,
        "modal2-ie": isIE(),
      })}
      appElement={document.getElementById("advanced-lots-body")}
    >
      <div className="block block-transparent remove-margin-b">
        <div className="block-header bg-primary-default">
          <ModalCloseIcon closeModal={closeModal} />
          <h3 className="block-title">{`${translations.edit_lot} (${lot.name})`}</h3>
        </div>
        {priceLicsWithoutFormula.map((lic) => {
          return (
            <div className="single_form" key={`{current_price_${lic.id}}`}>
              <UpdateSingleLicCurrentPrice {...props} lic={lic} />
            </div>
          );
        })}
        <div className="modal-footer" id="lot_component_submit">
          <button data-dismiss="modal" type="button" className="close-form btn btn-sm btn-default" onClick={closeModal}>
            {translations.cancel}
          </button>
          <button name="button" type="submit" className="btn btn-sm btn-primary" onClick={closeModal}>
            {translations.save} <i className="fa fa-check" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const ModalCloseIcon = (props) => {
  return (
    <ul className="block-options">
      <li>
        <button type="button" onClick={props.closeModal}>
          <i className="ion-close" />
        </button>
      </li>
    </ul>
  );
};

export default UpdateLicCurrentPrice;
