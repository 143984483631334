import { useDevDebugValue } from "@/hooks/use-dev-debug-value";
import { useReducer } from "react";

export interface Toggle {
  /** Toggle the current state */
  (): void;
  /** Set the state directly */
  set(value: boolean): void;
}

/**
 * A hook to manage a boolean state, with a toggle function.
 * @param initialState The initial state of the boolean. Default is `false`.
 * @returns A tuple with the current state and a function to toggle it.
 * @example
 * const [hidden, toggleHidden] = useToggle();
 * toggleHidden();
 * // can also call
 * toggleHidden.set(true);
 * // to set the state directly
 *
 */
export function useToggle(initialState = false): [boolean, Toggle] {
  const [state, toggle] = useReducer((state: boolean, incoming = !state) => incoming, initialState);
  useDevDebugValue(state);
  return [state, Object.assign(toggle, { set: toggle })];
}
