import { useDevDebugValue } from "@/hooks/use-dev-debug-value";
import { useShallowStableValue } from "@/hooks/use-shallow-stable";
import type { Nullish } from "@/types/util";
import type { RefObject } from "react";
import { useEffect } from "react";

export type EventTypes<El> = {
  [K in keyof El]: K extends `on${infer EventName}` ? EventName : never;
}[keyof El];

export type EventForType<El, EventType extends EventTypes<El>> = EventType extends EventType // force distribution over union, to avoid intersection
  ? El extends Record<`on${EventType}`, ((event: infer E) => unknown) | null>
    ? E
    : never
  : never;

export interface UseEventListenerConfig extends Omit<AddEventListenerOptions, "signal"> {
  /** Whether the event listener should be disabled. */
  disabled?: boolean;
}

/**
 * A hook to add an event listener to a given element.
 * @param ref A React ref object or a DOM element.
 * @param type The name of the event to listen for.
 * @param callback The callback to call when the event is triggered.
 * @param config The configuration object.
 */
export function useEventListener<El extends EventTarget, EventName extends EventTypes<El>>(
  target: Nullish<RefObject<El> | El>,
  type: EventName,
  callback: (event: EventForType<El, EventName>) => void,
  config: UseEventListenerConfig = {}
) {
  const stableConfig = useShallowStableValue(config);
  useEffect(() => {
    if (!target) return;
    const { disabled, ...rest } = stableConfig;
    const el = "current" in target ? target.current : target;
    if (disabled || !el) return;
    const ac = new AbortController();
    el.addEventListener(type, callback as never, {
      signal: ac.signal,
      ...rest,
    });
    return () => ac.abort();
  }, [target, type, callback, stableConfig]);
  useDevDebugValue({ target, type });
}
