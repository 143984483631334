export const QUESTION_TYPES = [
  "yes_no",
  "pick_one_from_list",
  "multiple_choice",
  "one_line_text",
  " paragraph_text",
  "document_upload",
  "date",
  "table",
  "numeric",
];
export const MANUALLY_SCORED_QUESTION_TYPES = [
  "one_line_text",
  "paragraph_text",
  "document_upload",
  "date",
  "table",
  "numeric",
];
export const manuallyScoredTypeQuestion = (question) => MANUALLY_SCORED_QUESTION_TYPES.includes(question.question_type);

export const findSectionForQuestion = (sections, qq) => {
  return sections.find((section) => section.id === qq.id);
};
