import PropTypes from "prop-types";

export const CategoryPermissionPropTypes = {
  id: PropTypes.number,
  action: PropTypes.string,
  resource_type: PropTypes.string,
  resource_name: PropTypes.string,
  resource_id: PropTypes.number,
  notified: PropTypes.bool,
};

export const EventAndContractPermissionPropTypes = {
  ...CategoryPermissionPropTypes,
  sandpit_event: PropTypes.any,
  event_state: PropTypes.any,
  contract_state: PropTypes.string,
};

export const AdminPermissionPropTypes = {
  id: PropTypes.number,
  action: PropTypes.string,
  section: PropTypes.string,
  notified: PropTypes.bool,
  user_permission_id: PropTypes.number,
};
