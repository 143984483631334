import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createDocumentType, deleteDocumentType } from "../../../../actions/contract/contractConfigurationActions";
import { getUpdatedValue } from "../../../advanced_lots/lots/lotCommon";
import { Confirm } from "../../../common/Confirm";

export const addNewDocumentType = (
  dispatch,
  companyId,
  documentTypeName,
  documentFileTypes,
  setDocumentTypeName,
  setIsAddNewOption,
  setDocumentFileTypes
) => {
  dispatch(createDocumentType(companyId, documentTypeName)).then((response) => {
    if (response) {
      setDocumentFileTypes(getUpdatedValue(documentFileTypes, response.contract_document_type));
      setIsAddNewOption(false);
      setDocumentTypeName("");
    }
  });
};

const destroyDocumentType = (
  documentId,
  dispatch,
  companyId,
  documentFileTypes,
  deleteDocumentType,
  setDocumentFileTypes
) => {
  dispatch(deleteDocumentType(companyId, documentId)).then((response) => {
    if (response) {
      setDocumentFileTypes(documentFileTypes.filter((document) => document.id !== documentId));
    }
  });
};

const showHideDocumentTypeForm = (flag, setIsAddNewOption, setDocumentTypeName) => {
  setIsAddNewOption(flag);
  setDocumentTypeName("");
};

const DocumentUploadType = (props) => {
  const { currentUserEditConfigPermission, translations, companyId } = props;
  const [isAddNewOption, setIsAddNewOption] = useState(false);
  const [documentTypeName, setDocumentTypeName] = useState("");
  const [documentFileTypes, setDocumentFileTypes] = useState(props.documentFileTypes);
  const dispatch = useDispatch();

  return (
    <>
      <div className="row verticle-scroll max-h360">
        <table className="m-b0 table table-bordered">
          <TableHeaders translations={translations} />
          <DocumentUploadTypePanel
            currentUserEditConfigPermission={currentUserEditConfigPermission}
            documentFileTypes={documentFileTypes}
            destroyDocumentType={destroyDocumentType}
            isAddNewOption={isAddNewOption}
            documentTypeName={documentTypeName}
            setDocumentTypeName={setDocumentTypeName}
            showHideDocumentTypeForm={showHideDocumentTypeForm}
            companyId={companyId}
            dispatch={dispatch}
            setIsAddNewOption={setIsAddNewOption}
            setDocumentFileTypes={setDocumentFileTypes}
            addNewDocumentType={addNewDocumentType}
            deleteDocumentType={deleteDocumentType}
            translations={translations}
          />
        </table>
      </div>
      <AddNewDocumentUploadButton
        currentUserEditConfigPermission={currentUserEditConfigPermission}
        showHideDocumentTypeForm={showHideDocumentTypeForm}
        translations={translations}
        setIsAddNewOption={setIsAddNewOption}
        setDocumentTypeName={setDocumentTypeName}
      />
    </>
  );
};

const TableHeaders = (props) => {
  const { translations } = props;
  return (
    <thead>
      <tr>
        <th className="wid-90">{translations.type}</th>
        <th className="wid-20"></th>
      </tr>
    </thead>
  );
};

const DocumentUploadTypePanel = (props) => {
  const {
    currentUserEditConfigPermission,
    documentFileTypes,
    destroyDocumentType,
    isAddNewOption,
    documentTypeName,
    setDocumentTypeName,
    showHideDocumentTypeForm,
    addNewDocumentType,
    companyId,
    dispatch,
    setIsAddNewOption,
    setDocumentFileTypes,
    deleteDocumentType,
    translations,
  } = props;
  return (
    <tbody>
      <FileTypeList
        currentUserEditConfigPermission={currentUserEditConfigPermission}
        translations={translations}
        documentFileTypes={documentFileTypes}
        destroyDocumentType={destroyDocumentType}
        companyId={companyId}
        dispatch={dispatch}
        setDocumentFileTypes={setDocumentFileTypes}
        deleteDocumentType={deleteDocumentType}
      />
      <AddNewType
        isAddNewOption={isAddNewOption}
        documentTypeName={documentTypeName}
        setDocumentTypeName={setDocumentTypeName}
        showHideDocumentTypeForm={showHideDocumentTypeForm}
        addNewDocumentType={addNewDocumentType}
        documentFileTypes={documentFileTypes}
        companyId={companyId}
        dispatch={dispatch}
        setIsAddNewOption={setIsAddNewOption}
        setDocumentFileTypes={setDocumentFileTypes}
        translations={translations}
      />
    </tbody>
  );
};

const FileTypeList = (props) => {
  const {
    currentUserEditConfigPermission,
    translations,
    documentFileTypes,
    destroyDocumentType,
    companyId,
    dispatch,
    setDocumentFileTypes,
    deleteDocumentType,
  } = props;
  return documentFileTypes.map((document, idx) => (
    <tr key={idx}>
      <td>
        <div className="dropdown">
          <div
            className="btn-group-sm dropdown table-cell-ellipsis"
            title={translations[document.name] || document.name}
          >
            {translations[document.name] || document.name}
          </div>
        </div>
      </td>
      {currentUserEditConfigPermission && (
        <>
          <td className="text-center">
            <button
              className="btn-default btn-icon m-l10"
              type="button"
              title={translations.cancel}
              data-toggle="modal"
              className="btn-default btn-icon"
              data-target={`#documentTypeDestroyModal-${document.id}`}
              rel="nofollow"
            >
              <i className="fa fa-trash red-color" aria-hidden="true" />
            </button>
          </td>
          <Confirm
            translations={translations}
            message={translations.are_you_sure}
            htmlId={`documentTypeDestroyModal-${document.id}`}
            onOkayButtonClick={() =>
              destroyDocumentType(
                document.id,
                dispatch,
                companyId,
                documentFileTypes,
                deleteDocumentType,
                setDocumentFileTypes
              )
            }
          />
        </>
      )}
    </tr>
  ));
};

export const AddNewType = (props) => {
  const {
    isAddNewOption,
    documentTypeName,
    setDocumentTypeName,
    showHideDocumentTypeForm,
    addNewDocumentType,
    documentFileTypes,
    companyId,
    dispatch,
    setIsAddNewOption,
    setDocumentFileTypes,
    translations,
  } = props;
  return (
    isAddNewOption && (
      <tr style={{ height: "50px" }}>
        <td className="">
          <div className="dropdown">
            <div className="btn-group-sm dropdown">
              <input
                type="text"
                className="form-control col-md-5"
                name="name"
                value={documentTypeName}
                placeholder={translations.type}
                onChange={(e) => setDocumentTypeName(e.target.value)}
                autoFocus={true}
              />
            </div>
          </div>
        </td>
        <td className="text-center">
          <div>
            <button
              className="btn-default btn-icon"
              type="button"
              title="Save"
              disabled={!documentTypeName || documentTypeName.length > 65}
              onClick={() =>
                addNewDocumentType(
                  dispatch,
                  companyId,
                  documentTypeName,
                  documentFileTypes,
                  setDocumentTypeName,
                  setIsAddNewOption,
                  setDocumentFileTypes
                )
              }
            >
              <i className="fa fa-save" aria-hidden="true" />
            </button>
            <button
              className="btn-default btn-icon m-l10"
              type="button"
              title="Cancel"
              onClick={() => showHideDocumentTypeForm(false, setIsAddNewOption, setDocumentTypeName)}
            >
              <i className="fa fa-times red-color" aria-hidden="true" />
            </button>
          </div>
        </td>
      </tr>
    )
  );
};

const AddNewDocumentUploadButton = (props) => {
  const {
    currentUserEditConfigPermission,
    showHideDocumentTypeForm,
    translations,
    setIsAddNewOption,
    setDocumentTypeName,
  } = props;
  return currentUserEditConfigPermission ? (
    <div className="p-t25 p-b25 font-s13">
      <button
        className={"btnClass"}
        onClick={() => showHideDocumentTypeForm(true, setIsAddNewOption, setDocumentTypeName)}
        name="add-new-document-type"
        style={{
          float: "left",
        }}
      >
        <i className="fa fa-plus-circle add-tag" aria-hidden="true"></i>
        &nbsp;{translations.add_new_document_type}
      </button>
    </div>
  ) : (
    ""
  );
};

export default DocumentUploadType;
