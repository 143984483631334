import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { priorityLabel } from "../StatusLabel";

export const PriorityWrapper = ({ priority }) => {
  const { custom_column_options } = useSelector((state) => state.ActivityReducer);
  const style = {
    backgroundColor: priorityLabel(priority, custom_column_options),
  };

  return (
    <span style={style} className="font-s12 label">
      {priority}
    </span>
  );
};

const Priority = ({ priority }) => {
  return (
    <td>
      <PriorityWrapper priority={priority} />
    </td>
  );
};

Priority.propTypes = {
  priority: PropTypes.string.isRequired,
};

export default Priority;
