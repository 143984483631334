import React from "react";

const customToolbar = (toolbar, disableButton, fullView, translations) => {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={() => toolbar.onNavigate("TODAY")}>
          {translations.today}
        </button>
        <button type="button" disabled={disableButton} onClick={() => toolbar.onNavigate("PREV")}>
          {fullView ? translations.previous : <span className="fa fa-arrow-left" />}
        </button>
        <button type="button" disabled={disableButton} onClick={() => toolbar.onNavigate("NEXT")}>
          {fullView ? translations.next : <span className="fa fa-arrow-right" />}
        </button>
      </span>
      <span className="rbc-toolbar-label">{toolbar.label}</span>
      <span className="rbc-btn-group">
        <button
          type="button"
          className={toolbar.view === "month" ? "rbc-active" : ""}
          onClick={(e) => toolbar.onView("month")}
        >
          {translations.month}
        </button>
        <button
          type="button"
          className={toolbar.view === "week" ? "rbc-active" : ""}
          onClick={() => toolbar.onView("week")}
        >
          {translations.week}
        </button>
        <button
          type="button"
          className={toolbar.view === "day" ? "rbc-active" : ""}
          onClick={() => toolbar.onView("day")}
        >
          {translations.day}
        </button>
        {fullView && (
          <button
            type="button"
            className={toolbar.view === "agenda" ? "rbc-active" : ""}
            onClick={() => toolbar.onView("agenda")}
          >
            {translations.agenda}
          </button>
        )}
      </span>
    </div>
  );
};

export default customToolbar;
