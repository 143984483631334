import React from "react";
import { useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { TabsPropTypes } from "./prop-types";
import { HOSTTAB, PARTICIPANTTAB } from "./Container";

/**
 * Check if a tab is active
 *
 * @param {string} tab
 * @param {boolean} isHostTabSelected
 * @returns {boolean}
 */
const isTabActive = (tab, isHostTabSelected) =>
  !!((tab === HOSTTAB && isHostTabSelected) || (tab === PARTICIPANTTAB && !isHostTabSelected));

/**
 *
 * @type {React.FC<PropTypes.InferProps<TabsPropTypes>>}
 * @returns {React.ReactElement}
 */
const Tabs = ({ isHostTabSelected, selectTabs, setState }) => {
  const translations = useSelector(({ lotReducers }) => lotReducers.translations);
  const tabs = [PARTICIPANTTAB, HOSTTAB];
  const handleTabChange = (index) => {
    selectTabs(!index ? "Participant" : "Host");
    setState({ message: "" });
  };
  return (
    <div className="content special-tab-content bid-download">
      <span className="tab-line" />
      <div className="row">
        <div className="col-md-12">
          <div className="participant-nav-tabs block">
            <ul className="nav nav-tabs" role="tablist">
              {tabs.map((tab, index) => (
                <li className={`nav-item ${isTabActive(tab, isHostTabSelected) ? "active" : ""}`} key={tab}>
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href={`#${tab}`}
                    role="tab"
                    aria-controls={tab}
                    aria-selected={!!tabs[Number(!isHostTabSelected)]}
                    onClick={() => handleTabChange(index)}
                  >
                    {translations[!index ? "participant" : "event_host"]}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

Tabs.propTypes = TabsPropTypes;
Tabs.defaultProps = {
  isHostTabSelected: false,
  selectTabs: () => {},
};

export default Tabs;
