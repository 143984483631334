import React, { Component } from "react";
import { connect } from "react-redux";

import LotContainer from "./LotContainer";
import { sortLot } from "../../../actions/lotActions";
import { multiCurrencyEvent } from "../common";

export class LotsContainer extends Component {
  constructor() {
    super();
    this.dragOver = this.dragOver.bind(this);
    this.updateDraggedLotId = this.updateDraggedLotId.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.state = {
      draggedElId: "",
    };
  }

  updateDraggedLotId(id) {
    this.setState({ draggedElId: id });
  }

  // If the user drags and drops the tag somehwere outside the formula field then
  // we check its class and add another class so in the LineItemComponentFormulaField
  // component in dragEnd() function we can check if this element has been marked
  // for removal then remove it from the formula.
  drop(e) {
    e.preventDefault();
    let element = document.getElementsByClassName("grey-tag");
    if (element.length) {
      element[0].classList.add("remove-from-formula");
    }
  }

  findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }

  dragOver(e) {
    const { draggedElId } = this.state;
    if (draggedElId) {
      const selected = document.getElementById(draggedElId);
      const container = document.getElementById("lots-container");
      const parentEl = this.findAncestor(e.target, "lot-container");
      if (parentEl !== null) {
        if (this.isBefore(selected, parentEl)) {
          container.insertBefore(selected, parentEl);
        } else {
          container.insertBefore(selected, parentEl.nextSibling);
        }
      }
    }
  }

  async dragEnd() {
    const { draggedElId } = this.state;
    if (draggedElId) {
      const { event, sortLot } = this.props;
      const selected = document.getElementById(draggedElId);
      const items = Array.from(document.getElementsByClassName("complex-lot")).map((el) => el.getAttribute("data-id"));
      const payload = {
        sorted_element_id: selected.getAttribute("data-id"),
        item: items,
      };
      const result = await sortLot(event.id, payload);
      if (result) {
        this.setState({ draggedElId: "" });
        /* selected.classList.add("highlight-background");
        setTimeout(function() {
          selected.classList.remove("highlight-background");
        }, 1000); */
      }
    }
  }

  isBefore(el1, el2) {
    var cur;
    if (el2 !== null && el2.parentNode === el1.parentNode) {
      for (cur = el1.previousSibling; cur; cur = cur.previousSibling) {
        if (cur == el2) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  render() {
    const {
      lots,
      event,
      lineItems,
      bids,
      lotComponents,
      lineItemComponents,
      translations,
      isJapaneseEvent,
      weightedRfqOrAuction,
      maxNumberOfRankedLics,
      maxNumberOfCellsPerEvent,
      maxNumberOfCellsForBidAtDetailLevel,
      permittedToEdit,
      maxPrecisionFor,
      currenciesHash,
      eventCurrency,
      classFromEventState,
      allowedLotType,
      openOrJapanese,
      locale,
      currentUser,
      reRenderDetails,
      timeZoneIdentifier,
      hideFromParticipantsSwitch,
      partialBiddingSwitch,
    } = this.props;
    const isMultiCurrencyEvent = multiCurrencyEvent(event);

    const lotsWithoutEventTotal = lots.filter(({ is_event_total }) => !is_event_total);
    const eventTotalLot = lots.find(({ is_event_total }) => is_event_total);
    const lotsContainer = lotsWithoutEventTotal.map((lot) => (
      <LotContainer
        key={lot.id}
        lot={lot}
        classFromEventState={classFromEventState}
        lineItems={lineItems}
        bids={bids}
        lots={lots}
        lotComponents={lotComponents}
        lineItemComponents={lineItemComponents}
        translations={translations}
        isJapaneseEvent={isJapaneseEvent}
        weightedRfqOrAuction={weightedRfqOrAuction}
        maxNumberOfCellsForBidAtDetailLevel={maxNumberOfCellsForBidAtDetailLevel}
        maxNumberOfRankedLics={maxNumberOfRankedLics}
        maxNumberOfCellsPerEvent={maxNumberOfCellsPerEvent}
        permittedToEdit={permittedToEdit}
        eventCurrency={eventCurrency}
        currenciesHash={currenciesHash}
        maxPrecisionFor={maxPrecisionFor}
        event={event}
        allowedLotType={allowedLotType}
        openOrJapanese={openOrJapanese}
        locale={locale}
        updateDraggedLotId={this.updateDraggedLotId}
        currentUser={currentUser}
        reRenderDetails={reRenderDetails}
        timeZoneIdentifier={timeZoneIdentifier}
        isMultiCurrencyEvent={isMultiCurrencyEvent}
        hideFromParticipantsSwitch={hideFromParticipantsSwitch}
        partialBiddingSwitch={partialBiddingSwitch}
      />
    ));
    return (
      <>
        <div className="row" onDrop={this.drop}>
          <div className={`matrix-overview complex-box col-md-12 event-${event.current_state}`} id="matrix-overview">
            <div className="lots-elements">
              <div className="row" onDragOver={this.dragOver} onDragEnd={this.dragEnd} id="lots-container">
                {lotsContainer}
              </div>
            </div>
          </div>
        </div>
        {eventTotalLot && (
          <div className="row">
            <div className={`matrix-overview complex-box col-md-12 event-${event.current_state}`} id="matrix-overview">
              <div className="lots-elements">
                <div className="row" onDragOver={this.dragOver} onDragEnd={this.dragEnd} id="lots-container">
                  <LotContainer
                    key={eventTotalLot.id}
                    lot={eventTotalLot}
                    classFromEventState={classFromEventState}
                    lineItems={lineItems}
                    bids={bids}
                    lots={lots}
                    lotComponents={lotComponents}
                    lineItemComponents={lineItemComponents}
                    translations={translations}
                    isJapaneseEvent={isJapaneseEvent}
                    weightedRfqOrAuction={weightedRfqOrAuction}
                    maxNumberOfRankedLics={maxNumberOfRankedLics}
                    permittedToEdit={permittedToEdit}
                    eventCurrency={eventCurrency}
                    currenciesHash={currenciesHash}
                    maxPrecisionFor={maxPrecisionFor}
                    event={event}
                    allowedLotType={allowedLotType}
                    openOrJapanese={openOrJapanese}
                    locale={locale}
                    updateDraggedLotId={this.updateDraggedLotId}
                    currentUser={currentUser}
                    reRenderDetails={reRenderDetails}
                    timeZoneIdentifier={timeZoneIdentifier}
                    isMultiCurrencyEvent={isMultiCurrencyEvent}
                    hideFromParticipantsSwitch={hideFromParticipantsSwitch}
                    partialBiddingSwitch={partialBiddingSwitch}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sortLot: (eventId, payload) => dispatch(sortLot(eventId, payload)),
});

export default connect(null, mapDispatchToProps)(LotsContainer);
