import React, { useMemo } from "react";
import { useItems } from "./items-context";
import { Checkbox } from "@/cl/checkbox";
import { getCheckedGroups } from "./Input";
import { belongsToSameGroup, isParentChecked } from "./use-select-item";
import type { CheckedItem, Item, StackItem } from "./types";

interface HiddenCheckedItems {
  otherCheckedItems: CheckedItem[];
}

/**
 * This custom hook will return an array of checked items that aren't currently
 * in the DOM but still need to be included when the form is submitted.
 */
const useCheckedGroups = (otherCheckedItems: CheckedItem[]) => {
  const { items, type_name_map } = useItems();
  const checkedGroupNames = getCheckedGroups(otherCheckedItems);

  /**
   * This will get us the parent items for our checked items.
   */
  const checkedGroups = useMemo(
    () => checkedGroupNames.map((name) => items.find((item) => item.label === name)),
    [checkedGroupNames, items]
  ) as Item[];

  /**
   * Once we retrieve the parent items, we can verify if all their children are
   * selected. To do this, we simply compare the total number of children the
   * parent has with the number of checked items in the same group.
   */
  const checkedItems: CheckedItem[] = [];
  for (const item of checkedGroups) {
    const children = item.children as Item[];
    const stackItem: StackItem = { parent: item, items: children };
    const checkedChildForStackItem = otherCheckedItems.filter((checkedItem) =>
      belongsToSameGroup(checkedItem, stackItem)
    );
    const shouldPostChildren = !type_name_map[stackItem.parent.type];
    const parentChecked = isParentChecked(stackItem, checkedChildForStackItem);
    const itemsToBePushed = parentChecked ? getItemsToBePushed(item, shouldPostChildren) : checkedChildForStackItem;

    itemsToBePushed.forEach((newItem) => checkedItems.push(newItem));
  }
  return checkedItems;
};

const getItemsToBePushed = (item: Item, shouldPostChildren: boolean) => {
  if (shouldPostChildren) {
    return (item.children as Item[]).map((childItem) => ({ group: item.label, item: childItem }));
  } else {
    return [{ group: item.label, item }];
  }
};

/**
 * This React component will render a hidden checked checkboxes for the items
 * returned by useCheckedGroups hook.
 * @see useCheckedGroups
 */
export default function HiddenCheckedItems({ otherCheckedItems }: HiddenCheckedItems) {
  const { type_name_map } = useItems();
  const checkedItems = useCheckedGroups(otherCheckedItems);

  return (
    <>
      {checkedItems.map((checkedItem) => {
        const item = checkedItem.item;
        return (
          <div className="hide" key={item.label}>
            <Checkbox
              id={`item-${item.type}-${item.value}`}
              name={type_name_map[item.type]}
              label={item.label}
              value={item.value}
              checked
              readOnly
            />
          </div>
        );
      })}
    </>
  );
}
