import React from "react";
import PropTypes from "prop-types";

/**
 * All of the available styles for the icon
 */
const Styles = Object.freeze({
  success: "fa fa-check green-color",
  danger: "fa fa-times red-color",
  loading: "fa fa-spinner fa-spin",
});

const IconPropTypes = {
  /**
   * The icon you would like display
   *
   * @type {'success' | 'danger' | 'loading'}
   */
  state: PropTypes.oneOf(Object.keys(Styles)).isRequired,
};

const IconDefaultProps = {};

/**
 * @type {React.FC<typeof IconPropTypes>}
 */
export const Icon = ({ state }) => {
  return (
    <span className="input-group-btn" role="status">
      <div className="btn" style={{ cursor: "auto" }}>
        <i className={Styles[state]} />
      </div>
    </span>
  );
};

Icon.propTypes = IconPropTypes;
Icon.defaultProps = IconDefaultProps;

export default Icon;
