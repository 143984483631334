import maxBy from "lodash/maxBy";

/**
 *
 * @description Extract Active and Dropped participants from Event Participants
 * @param {Array} event_participants
 * @param {Array} event_participant_event_rounds
 * @param {Number} currentEventRoundId
 * @returns {object} { activeParticipants, droppedParticipants }
 */
export function activeAndDroppedParticipants(
  event_participants = [],
  event_participant_event_rounds = [],
  currentEventRoundId
) {
  // find participants for current round
  const roundParticipantsIds = getParticipantsForCurrentRound(event_participant_event_rounds, currentEventRoundId)?.map(
    (round) => round.event_participant_id
  );

  // filter active participants
  const activeParticipants = event_participants.filter((participant) => {
    return (
      !!participant.accepted &&
      [1, true, null].includes(participant.participating) &&
      roundParticipantsIds.includes(participant.id)
    );
  });

  const activeParticipantIds = activeParticipants.map((participant) => participant.id);

  // filter dropped participants from event participants
  const droppedParticipants = event_participants.filter(
    (participant) => !!participant.accepted && !activeParticipantIds.includes(participant.id)
  );
  return {
    activeParticipants,
    droppedParticipants,
  };
}

/**
 *
 * @description Extract participants for current msrfq round
 * @param {Array} event_participant_event_rounds
 * @param {Array} currentEventRound
 * @returns {Array} Event round participants
 */
export const getParticipantsForCurrentRound = (event_participant_event_rounds = [], currentEventRoundId) =>
  event_participant_event_rounds.filter((round) => round.event_round_id === currentEventRoundId);

/**
 *
 * @description Extract current event round
 * @param {Array} event_rounds
 * @returns {Object} Event round
 */
export const getCurrentEventRound = (event_rounds = []) => maxBy(event_rounds, "event_round");

/**
 *
 * @description Check if user can add more rounds
 * @param {Boolean} isHost
 * @param {Boolean} isPermittedToEdit
 * @param {Object} event
 * @returns {Boolean} true if user can add more rounds
 */
export const canAddMoreRounds = (isHost, isPermittedToEdit, event) =>
  isHost && isPermittedToEdit && event.current_state == "completed" && !event.event_category_auction;
