import React from "react";
import RenderHTML from "@/components/common/RenderHTML";
import { t } from "@/i18n";
import {
  // As we are importing a variable which is being used in jsdoc so eslint was giving an error for no-unused-vars
  // eslint-disable-next-line no-unused-vars
  EventAndContractPermissionPropTypes,
} from "./prop-types";
import PropTypes from "prop-types";

const EventAndContractPermissionDetailsPropTypes = {
  showIcon: PropTypes.bool,
  adminPermissionsList: PropTypes.arrayOf(PropTypes.string),
  creditBalance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  permissionsList: PropTypes.arrayOf(PropTypes.shape(EventAndContractPermissionPropTypes)),
};

/**
 * @param {EventAndContractPermissionPropTypes} contractPermission
 * @param {(key: string) => void} t
 * @returns {string} permissionText
 */
const getContractPermissionText = (contractPermission, t) => {
  const resourceType = contractPermission.resource_type;
  const resourceName = contractPermission.resource_name;
  const showContractState = contractPermission.contract_state ? `(${contractPermission.contract_state})` : "";

  if (resourceType === "User" || resourceType === "Group") {
    return `${t("user_permissions.permission.contracts_created_by", {
      permission: resourceName,
    })} ${showContractState}`;
  }
  if (resourceType === "Contract") {
    return `${t("contracts")} ${resourceName} ${showContractState}`;
  }

  return "";
};

/**
 * @param {string} eventState
 * @returns {string}
 */
const showEventState = (eventState) => {
  return eventState ? `(${eventState})` : "";
};

/**
 * @param {EventAndContractPermissionPropTypes} eventPermission
 * @param {(key: string) => void} t
 * @returns {string} permissionText
 */
const getEventPermissionText = (eventPermission, t) => {
  const resourceType = eventPermission.resource_type;
  const resourceName = eventPermission.resource_name;
  const eventState = eventPermission.event_state;

  if (resourceType === "User" || resourceType === "Group") {
    return `${t("events_created_by")} ${resourceName} ${showEventState(eventState)}`;
  }
  if (resourceType === "Contract") {
    return `${t("contracts")} ${resourceName} ${showEventState(eventState)}`;
  }

  const sandpitText = eventPermission.sandpit_event ? `(${t("sandpit_html")})` : "";
  return `${t("event")} ${resourceName} ${sandpitText} ${showEventState(eventState)}`;
};

/**
 * @param {boolean} showIcon
 * @param {EventAndContractPermissionPropTypes} permission
 * @param {string | number | undefined} creditBalance
 * @returns {boolean} canShowExclamationMark
 */
export const isShowingExclamationMark = (showIcon, permission, creditBalance) => {
  return (
    showIcon &&
    permission.action === "edit" &&
    creditBalance !== "Annual License" &&
    permission.resource_type !== "Contract"
  );
};

/**
 * @type {React.FC<typeof EventAndContractPermissionDetailsPropTypes>}
 */
const EventAndContractPermissionDetails = (props) => {
  const { permissionsList, adminPermissionsList, creditBalance, showIcon } = props;
  return (
    <>
      {Array.isArray(permissionsList) &&
        permissionsList.map((permission, index) => {
          if (permission.resource_type === "Contract" && !adminPermissionsList.includes("contracts")) {
            return null;
          }
          const canShowExclamationMark = isShowingExclamationMark(showIcon, permission, creditBalance);
          const isEventColumn = creditBalance !== undefined;

          const permissionText = isEventColumn
            ? getEventPermissionText(permission, t)
            : getContractPermissionText(permission, t);
          const string = `${t("can")} ${t(permission.action)} ${permissionText}`;

          return (
            <>
              <RenderHTML key={index} content={string} />
              {isEventColumn && canShowExclamationMark && <span role="alert" className="exclamation_mark" />}
              <br />
            </>
          );
        })}
    </>
  );
};
EventAndContractPermissionDetails.propTypes = EventAndContractPermissionDetailsPropTypes;
export default EventAndContractPermissionDetails;
