import React from "react";
import PropTypes from "prop-types";

import Label from "../input/label";
import Feedback from "../input/feedback";

import { InputPropTypes, buildProps } from "../input/input";
import { useIndeterminate } from "@/hooks/use-indeterminate";

/**
 * @type {React.FC<PropTypes.InferProps<typeof <InputPropTypes>>}
 */
export const InternalCheckboxRadio = (props) => {
  const { name, label, error, help, type, isIndeterminate = false, ...inputProps } = props;
  const id = props.id || name;
  const checkboxRef = useIndeterminate(isIndeterminate);

  return (
    <>
      <div className="d-flex align-items-top">
        <input
          {...buildProps({ inputProps, id, name, error, className: "form-check-input" })}
          type={type}
          ref={checkboxRef}
        />
        <Label {...{ id, label }} className="form-check-label ms-2" />
      </div>
      <Feedback {...{ id, help, error }} />
    </>
  );
};

InternalCheckboxRadio.propTypes = { ...InputPropTypes, type: PropTypes.oneOf(["checkbox", "radio"]) };

export default InternalCheckboxRadio;
