import cookies from "browser-cookies";
import { jwtDecode } from "jwt-decode";

const headers = cookies.get("auth_headers") ? jwtDecode(cookies.get("auth_headers")) : {};
const BASE_URL = `${window.location.origin}/api/v1`;
const V2_BASE_URL = `${window.location.origin}/api/v2`;
const SRM_BASE_URL = `${window.location.origin}/srm/api/v1`;
const WS_URL = `${document.location.protocol == "https:" ? "wss://" : "ws://"}${window.location.host}/cable`;

const config = {
  BASE_URL,
  headers,
  V2_BASE_URL,
  SRM_BASE_URL,
  WS_URL,
};

export default config;
