import type { ReactNode } from "react";

/**
 * Evaluates a render prop or returns the prop directly if its a react node
 * like type.
 *
 * @example
 * const Component = ({ children }) => {
 *   const value = useValueContext();
 *   return renderProp(children, { value });
 * }
 *
 * @see https://reactjs.org/docs/render-props.html
 */
export const renderProp = <Args extends Array<any>>(
  prop: ReactNode | ((...args: Args) => ReactNode),
  ...args: Args
) => {
  if (typeof prop === "function") {
    return prop(...args);
  }

  return prop;
};

export default renderProp;
