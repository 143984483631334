import { createSelector, createSlice } from "@reduxjs/toolkit";
import { hydrated } from "@/slices/hydrate";
import type { Company, LicenseTiers, TierType, ProductType } from "@/common-prop-types";
import { Tiers } from "@/common-prop-types";

export interface CompanyState {
  company: Record<string, never> | Company;
  licenseTiers: LicenseTiers;
}

function isValidTier(maybeTier?: string | null): maybeTier is TierType {
  if (!maybeTier) return false;

  return Tiers.includes(maybeTier as TierType);
}

const DEFAULT_PRODUCT_TIER = "bronze";

export const companySlice = createSlice({
  name: "company",
  initialState: {
    company: {},
    licenseTiers: {},
  } as CompanyState,
  reducers: {},
  selectors: {
    selectCompanyTier: createSelector(
      (state: CompanyState) => state.licenseTiers,
      (_, product: ProductType) => product,
      (licenseTiers: LicenseTiers, product: ProductType): TierType => {
        const productTier = product in licenseTiers ? licenseTiers[product] : null;
        return isValidTier(productTier) ? productTier : DEFAULT_PRODUCT_TIER;
      }
    ),
  },
  extraReducers(builder) {
    builder.addCase(hydrated, (state, action) => {
      const { company, licence_tiers } = action.payload;
      if (company) state.company = company;
      if (licence_tiers) state.licenseTiers = licence_tiers;
    });
  },
});

export const { selectCompanyTier } = companySlice.selectors;
