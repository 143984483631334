import React from "react";
import { useSelector } from "react-redux";
import { isRfq } from "../../../../eventCommon";
import OverallScoreHeading from "./OverallScoreHeading";
import RankOrRoundHeading from "./RankOrRoundHeading";
import TableHeaderWithTooltip from "./TableHeaderWithTooltip";

const LotTableHeading = (props) => {
  const { translations, event, beforeAuction, isJapaneseEvent } = props;
  const event_rounds = useSelector(({ lotReducers }) => lotReducers.event_rounds);

  const titleWithTooltip = (title, tooltip, classNames = "") => {
    const defaultNames = "w-rfq-thead-popup participant-bid ";
    return (
      <TableHeaderWithTooltip
        translations={translations}
        title={title}
        tooltip={tooltip}
        classNames={defaultNames.concat(classNames)}
      />
    );
  };

  return (
    <tr>
      <RankOrRoundHeading
        translations={translations}
        beforeAuction={beforeAuction}
        event={event}
        isJapaneseEvent={isJapaneseEvent}
      />

      {titleWithTooltip("participant", "bid_participant", "participant-name")}
      {Boolean(event_rounds?.length) && isRfq(event) && titleWithTooltip("rfq_round", "rfq_round", "participant-bid")}
      {titleWithTooltip("participants_bid", "bid_bids", "participant-bid")}
      <OverallScoreHeading {...props} titleWithTooltip={titleWithTooltip} />
      {titleWithTooltip("time_of_bid", "bid_time_of_bid", "time-bid")}
      {titleWithTooltip("total_lot_price_difference", "lot_total_lot_price_difference", "price-score")}
      {titleWithTooltip(
        "total_lot_price_difference_percentage",
        "lot_total_lot_price_difference_percentage",
        "price-score"
      )}
      {props.permittedToEdit &&
        props.canShowDeleteBtn &&
        !props.lot.is_event_total &&
        titleWithTooltip("delete_bid", "bid_delete_bid", "delete-btn")}
    </tr>
  );
};

export default LotTableHeading;
