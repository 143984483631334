import React, { Component } from "react";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { bidRoundPrice } from "./common";
import { validBidsDuringAuction } from "./bidCommon";

export const inprogress = (event) => running(event) || pause(event);
export const running = (event) => event.current_state === "running";
export const pause = (event) => event.current_state === "pause";
export const completed = (event = {}) => event.current_state === "completed";
export const closed = (event) => event.current_state === "closed";
export const pending = (event) => event.current_state === "pending";
export const edit = (event) => event.current_state === "edit";

export const isRfq = (event) => event?.event_category === "RFQ";

export const eligibleForClone = (event) => event.event_category_auction || event.event_category_rfq;

export const postRfqPreAuction = (event) => event.extra_event_state === "post_rfq_pre_auction";

export const aroundRfq = (event) => {
  return (
    (event.start_at === null && event.event_type === null) ||
    isRfq(event) ||
    (postRfqPreAuction(event) && !(inprogress(event) || completed(event))) ||
    pending(event)
  );
};

const isEventValidForLive = (event) => {
  return eligibleForClone(event) && ["closed", "completed"].includes(event.current_state);
};

const canUserCloneEvent = (props) => {
  return props.role === "Host" && props.in_sandpit;
};

const isTabValid = () => {
  return !($("#lots-tab").hasClass("active") && $("#lots-tab").text().includes("Qual. Bid"));
};

export const isWeightedRfqOrAuction = (event) => {
  return event.rfq_with_weighting || event.auction_with_weighting;
};
export const noChangeBidChange = (auctionControls) => {
  return changedBidChange(auctionControls) === 0;
};
export const changedBidChange = (auctionControls) => {
  return auctionControls.filter((auctionControl) => auctionControl.bid_change);
};

export const bidBidChange = (auctionControls, event, round) => {
  let result = 0;
  if (round === 1) {
    result = event.bid_change;
  } else if (round > 1) {
    const bidChangePercentage = auctionControls.filter((ac) => {
      return ac.round < round && ac.time_difference == 0 && ac.bid_change;
    });
    if (bidChangePercentage.length > 0) {
      result = last(bidChangePercentage);
    } else {
      result = event.bid_change;
    }
  }
  return result;
};

export const rfqQuestionnaireWeighting = (event) => 100 - event.rfq_price_weighting;

export const auctionQuestionnaireWeighting = (event) => 100 - event.auction_price_weighting;

export const isBeforeAuction = (event) => {
  return !event.start_at || moment(event.start_at) > moment();
};

export const preAuctionRanksIntact = (event) => (!event.start_at ? true : moment() < moment(event.start_at));

export const deadlinesInThePast = (event) => {
  let deadlinePassed = false;
  if (event.bid_deadline && moment(event.bid_deadline) < moment()) {
    deadlinePassed = true;
  } else if (event.end_at && moment(event.end_at) < moment()) {
    deadlinePassed = true;
  }
  return deadlinePassed;
};

export const hideRankIfRunningRfq = (event, isFinalDeadlinePasses) =>
  showEventRoundData(event) && !event.event_category_auction && isFinalDeadlinePasses;

export const rejectedLotsByUserId = (bids, lotId, userId) => {
  return bids.filter((bid) => bid.rejected_lot && bid.lot_id === lotId && bid.user_id === userId);
};

export const isPause = (auctionControls) => auctionControls.find((ac) => !ac.time_difference);

export const lotsUserBidInRound = (participant, bidRound, auctionControls, bids, lots, event) => {
  const participantsValidBids = validBidsDuringAuction(event, [participant], bids);
  let lotIds = [];
  let lotBidRoundPrice = [];
  lots.map((l) => {
    lotIds.push(l.id);
    lotBidRoundPrice.push(bidRoundPrice(l, event, bidRound, auctionControls).toFixed(2));
  });
  let result = [];
  participantsValidBids.forEach((bid) => {
    if (lotIds.includes(bid.lot_id) && lotBidRoundPrice.includes(bid.host_price.toFixed(2))) {
      result.push(bid.lot_id);
    }
  });
  return result;
};

// TODO Need to add code for event rounds
export const showEventRoundData = (event, eventRounds) => {
  return eventRounds && eventRounds.length && event.event_category_rfq && !event.quick_quotes;
};

export const rfqFinished = (event) => {
  return (
    event.event_category_rfq &&
    (event.extra_event_state === "post_rfq_pre_auction" ||
      (!event.bid_deadline === null && moment(event.bid_deadline) < moment()) ||
      event.progress_to_auction)
  );
};

export const isQualBidAndAuctionEvent = (event) => event.event_category === "Qualification Bids and Auction";

export const qualAucBidRunningComplete = (event) =>
  isQualBidAndAuctionEvent(event) && ["running", "completed"].includes(event.current_state);

export const auctionPending = (event) =>
  isQualBidAndAuctionEvent(event) && ["post_rfq_pre_auction", "pending"].includes(event.current_state);

export const sortedParticipantsByLotAndRank = (lBids, event) => {
  if (isWeightedRfqOrAuction(event)) {
    if (event.event_category_rfq && event.event_category_auction) {
      return orderBy(
        lBids.filter((bid) => {
          return moment(bids.created_at) > moment(bids.start_at) && bid.current;
        }),
        ["weighted_score"],
        ["desc"]
      );
    } else {
      return orderBy(
        lBids.filter((bid) => {
          return bid.current;
        }),
        ["weighted_score"],
        ["desc"]
      );
    }
  } else {
    return orderBy(
      lBids.filter((bid) => {
        return bid.rank;
      }),
      ["rank"],
      ["asc"]
    );
  }
};

export const initialize = () => {
  String.prototype.decimalCount = function () {
    const splitted = this ? this.split(".") : this;
    if (splitted && splitted.length <= 1) return 0;
    return splitted[1] && splitted[1].length ? splitted[1].length : 0;
  };
  window.isReactRootCalled = true;
};

export const remainingTime = (cb) => {
  window.passRemainingTimeToReact = function (cb, remain) {
    if (typeof cb === "function") cb(remain);
  }.bind(this, cb);
};
