import React, { useState } from "react";
import UpdateLicCurrentPrice from "./UpdateLicCurrentPrice";

export const LicCurrentPrice = (props) => {
  const { modalIsOpen, setModalIsOpen, priceLicsWithoutFormula } = props;
  return (
    <>
      {priceLicsWithoutFormula.length && (
        <a onClick={() => setModalIsOpen(true)}>
          <i className="fa fa-pencil blue-color"></i>
        </a>
      )}
      {priceLicsWithoutFormula.length && modalIsOpen && (
        <UpdateLicCurrentPrice
          {...props}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          priceLicsWithoutFormula={priceLicsWithoutFormula}
        />
      )}
    </>
  );
};

const UpdateCurrentPriceComplexLot = (props) => {
  // check if window variable exists or not
  const [modalIsOpen, setModalIsOpen] = useState(window.isLicUpdateModalOpen ?? false);
  const priceLicsWithoutFormula = props.lotLics.filter((lic) => lic.lot_component_type === 0 && !lic.formula);
  React.useEffect(() => {
    // set window variable for modal open state when component unmounts
    // window variable is added to retain the modal open state if component unmounts
    return () => {
      // only update isLicUpdateModalOpen when state is false at point of unmountung
      //  this will stop un necessary opeaning of moda;
      if (window.isLicUpdateModalOpen === false) window.isLicUpdateModalOpen = true;
    };
  }, [modalIsOpen]);

  return (
    <LicCurrentPrice
      {...props}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      priceLicsWithoutFormula={priceLicsWithoutFormula}
    />
  );
};

export default UpdateCurrentPriceComplexLot;
