import TrixEditor from "@/components/common/TrixEditor";
import React, { Component, Fragment } from "react";
import StakeholderNotification from "./StakeholderNotification";
import SuppliersNotification from "./SuppliersNotification";
import SupplierStakeholderInput from "./SupplierStakeholderInput";

class CustomNotificationModal extends Component {
  showHideStakeholder = (show, e) => {
    const { showHideStakeholderbox } = this.props;
    e.preventDefault();
    showHideStakeholderbox(show);
  };

  componentDidUpdate = () => {
    if (this.props.isItIE) {
      this.messageArea.value = this.props.customNotification.content;
    }
    this.startDate.value = this.props.customNotification.startDate;
  };

  render() {
    const {
      customNotification,
      stakeHoldersBox,
      createUpdateCustomNotification,
      handleIncludeRecipients,
      subjectError,
      contentError,
      startDateError,
      includedStakeholders,
      includedSuppliers,
      translations,
      suppliers,
      stakeholders,
      isItIE,
    } = this.props;
    const { subject, content, startDate, intervalValue, intervalUnit, includeNotes, id } = customNotification;
    return (
      <div
        id="customNotificationModal"
        className="modal fade modal-dialog-popout top-position ui-draggable"
        role="dialog"
        style={{ display: "none" }}
        data-backdrop={true}
      >
        <div className="modal-draggable-content modal-full-width">
          <div className="modal-dialog modal-dialog-popout modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title">{translations.add_notification}</h4>
              </div>
              <div className="modal-body pb-0">
                <div className="row">
                  <div className="col-12 form-group">
                    <label>{translations.subject}</label>
                    <input
                      name="subject"
                      className="form-control"
                      type="text"
                      value={subject}
                      onChange={(e) => this.props.handleChange(e, "subject")}
                    />
                    {subjectError.length > 0 && <span className="error-text">{subjectError}</span>}
                  </div>
                  <div className="col-12 form-group">
                    <label>{translations.content}</label>
                    {isItIE ? (
                      <textarea
                        className="message_area"
                        ref={(ref) => (this.messageArea = ref)}
                        defaultValue={content}
                        onBlur={(e) => {
                          this.props.handleChange(e, "content");
                        }}
                      ></textarea>
                    ) : (
                      <TrixEditor
                        activeClassName="p10"
                        value={content}
                        onChange={(e) => {
                          this.props.handleChange(e, "content");
                        }}
                      />
                    )}
                    {contentError.length > 0 && <span className="error-text">{contentError}</span>}
                  </div>
                  <div className="col-4 mb-3">
                    <label>
                      {translations.start_date}
                      <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-notification-start-date"
                      id="startDate"
                      ref={(ref) => (this.startDate = ref)}
                      autoComplete="off"
                      placeholder="Select a date"
                      defaultValue={startDate}
                    />
                    {startDateError.length > 0 && <span className="error-text">{startDateError}</span>}
                  </div>
                  <div className="col-4 mb-3">
                    <label>{translations.interval}</label>
                    <input
                      name="notification-interval"
                      type="number"
                      autoComplete="off"
                      size="10"
                      className="form-control dark-border"
                      value={intervalValue}
                      onChange={(e) => this.props.handleChange(e, "intervalValue")}
                    />
                  </div>
                  <div className="col-4 mb-3">
                    <label>&nbsp;</label>
                    <select
                      name="interval_unit"
                      id="interval_unit"
                      className="custom-select contract-input form-control"
                      value={intervalUnit}
                      onChange={(e) => this.props.handleChange(e, "intervalUnit")}
                    >
                      <option value="days">{translations.days}</option>
                      <option value="weeks">{translations.weeks}</option>
                      <option value="months">{translations.months}</option>
                      <option value="years">{translations.years}</option>
                    </select>
                  </div>
                  <div className="col-12 form-group">
                    <input
                      type="checkbox"
                      checked={includeNotes}
                      id="contract_notification_include_notes"
                      onChange={(e) => this.props.handleChange(e, "includeNotes")}
                    />
                    <label htmlFor="contract_notification_include_notes">{translations.include_notes}</label>
                    <br />
                    <span className="font-italic">{translations.include_note_msg}</span>
                  </div>
                  <div className="col-12 form-group recipients-btn">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className={`nav-item ${stakeHoldersBox ? "active" : "inactive"}`}>
                        <a className="nav-link" href="#" onClick={(e) => this.showHideStakeholder(true, e)}>
                          {translations.stakeholders}
                        </a>
                      </li>
                      <li className={`nav-item ${stakeHoldersBox ? "inactive" : "active"}`}>
                        <a className="nav-link" href="#" onClick={(e) => this.showHideStakeholder(false, e)}>
                          {translations.suppliers}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12">
                    {stakeHoldersBox &&
                      (stakeholders.length > 0 ? (
                        <Fragment>
                          <SupplierStakeholderInput
                            {...this.props}
                            userType={'stakeholders'}
                            inputId={'stakeholders_all'}
                          />
                          <label htmlFor="stakeholders_all">All Stakeholders</label>
                          <StakeholderNotification
                            {...this.props}
                            inputId={'stakeholder_'}
                          />
                        </Fragment>
                      ) : (
                        <div className="notification-content">{translations.notification_stakeholder_msg}</div>
                      ))}
                      <SuppliersNotification
                        {...this.props}
                        inputIds={['suppliers_all', 'supplier_']}
                      />
                  </div>
                </div>
              </div>
              <div className="modal-footer pb-3">
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  {translations.cancel}
                </button>
                <button
                  type="button"
                  className={`btn btn-primary btn-default ok-btn`}
                  onClick={() => createUpdateCustomNotification(id)}
                >
                  {translations.save}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomNotificationModal;
