import { t } from "@/i18n";
import { createValidator, getAll } from "@adeattwood/react-form";

const isRequired = (value, message) => {
  if (!value || !value.trim()) {
    return message;
  }
};

const isStringEqual = (value, expected) => {
  if (typeof value !== "string" || typeof expected !== "string") {
    // If they are not strings we can not tell if they are equal. If we need to
    // check that the values are required we should be using a required rule.
    return false;
  }

  return value.toLowerCase().trim() === expected.toLowerCase().trim();
};

const maxLength = (t, value, count) => {
  if (value && value.length > count) {
    return t("errors.messages.too_long.many", { count });
  }
};

/**
 * Ensure all custom column options names are unique in the context of the
 * custom column
 *
 * @param {ReturnType<useTranslationContext>["t"]} t
 */
function customFieldsOptionsNameUniqueness(t) {
  return function (formState, { path, value }) {
    const [customColumnIndex] = path.match(/\d+/);
    const all = getAll(formState, `customFields.${customColumnIndex}.custom_column_options..name`);

    const namesMatching = all.filter((item) => isStringEqual(item.value, value));
    if (namesMatching.length > 1) {
      return t("centralised_custom_fields.option_name_has_already_been_taken");
    }
  };
}

export const useValidator = () => {
  return createValidator({
    "customFields..name": [
      (_, { value }) => isRequired(value, t("centralised_custom_fields.custom_column_name_cannot_be_blank")),
      (_, { value }) => maxLength(t, value, 65),
      // Ensure all the custom column names are unique.
      (formState, { attribute, value }) => {
        const allNames = getAll(formState, attribute);
        const matchingNames = allNames.filter((item) => isStringEqual(item.value, value));
        if (matchingNames.length > 1) {
          return t("centralised_custom_fields.name_has_already_been_taken");
        }
      },
    ],
    "customFields..custom_column_options..name": [
      (_, { value }) => isRequired(value, t("centralised_custom_fields.option_name_cannot_be_blank")),
      (_, { value }) => maxLength(t, value, 200),
      // Ensure all custom column options names are unique in the context of the
      // custom column
      customFieldsOptionsNameUniqueness(t),
    ],
  });
};

export default useValidator;
