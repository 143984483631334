import React, { Component } from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import Loader from "react-loader-advanced";

import { getBidsByRequestId } from "../../../../../actions/qq/emarketplace/emSummaryActions";
import LoaderMessage from "../../../LoaderMessage";
import formatData from "../../../js/languageFormatter";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      lots: [],
      orderBy: "Price",
      hasDeliveryDateView: false,
      bestBidsList: [],
    };
  }
  async UNSAFE_componentWillMount() {
    const { requestId } = this.props;
    if (requestId) {
      await this.props.getBidsByRequestId(requestId);
      this.setState({
        showLoader: false,
      });
    }
  }
  async UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.emSummary.bids_list) {
      this.setState({
        showLoader: false,
      });
    }
    if (newProps.emSummary.bids_summary) {
      const data = await this.chnageDataFormat(newProps.emSummary.bids_summary.lots);
      const bestBidsList = _.map(data, (obj, idx) => this.getBestBids(obj.lot, idx, this.state.orderBy, obj.bids));
      this.setState({
        lots: data,
        hasDeliveryDateView: data[0].lot.participant_lot_components_size === 3,
        bestBidsList,
      });
    }
  }

  getBestBids(lot, index, orderBy, lotBids) {
    const translations = this.props.translations;
    const { data } = this.props.emSummary;
    const bidList = Object.keys(lotBids).map((key) => lotBids[key]);
    let bestBid = [];
    let participantStr = "-";
    if (this.state.hasDeliveryDateView) {
      if (orderBy === "Price") {
        const bestBidByPrice = this.bestBids(bidList, lot.id);
        if (bestBidByPrice.length === 1) {
          participantStr = bestBidByPrice[0].user_name;
          bestBid = bestBidByPrice;
        } else if (bestBidByPrice.length > 1) {
          const bestBidByDelivery = bestBidByPrice.filter((bid) => bid["Achievable delivery date"] === "Yes");
          if (bestBidByDelivery.length === 1) {
            participantStr = bestBidByDelivery[0].user_name;
            bestBid = bestBidByDelivery;
          } else if (bestBidByDelivery.length > 1 || bestBidByDelivery.length === 0) {
            const bids = bestBidByDelivery.length ? bestBidByDelivery : bestBidByPrice;
            const bestBidByLeadTime = this.sortByLeadTime(bids);
            if (bestBidByLeadTime.length === 1) {
              participantStr = bestBidByLeadTime[0].user_name;
              bestBid = bestBidByLeadTime;
            } else {
              bestBid = bestBidByLeadTime;
              participantStr = translations.summary_status.tied;
            }
          }
        }
      } else {
        const bestBidByDelivery = bidList.filter(
          (bid) => bid["Achievable delivery date"] === "Yes" && !bid.rejected_lot && bid.rank !== null
        );
        if (bestBidByDelivery.length === 1) {
          participantStr = bestBidByDelivery[0].user_name;
          bestBid = bestBidByDelivery;
        } else if (bestBidByDelivery.length > 1 || bestBidByDelivery.length === 0) {
          const bids = bestBidByDelivery.length ? bestBidByDelivery : bidList;
          const bestBidByLeadTime = this.sortByLeadTime(bids);
          if (bestBidByLeadTime.length === 1) {
            participantStr = bestBidByLeadTime[0].user_name;
            bestBid = bestBidByLeadTime;
          } else {
            const bidsByLeadTime = bestBidByLeadTime.length ? bestBidByLeadTime : bidList;
            const bestBidByPrice = this.bestBids(bidsByLeadTime, lot.id);
            if (bestBidByPrice.length === 1) {
              participantStr = bestBidByPrice[0].user_name;
              bestBid = bestBidByPrice;
            } else {
              bestBid = bestBidByPrice;
              if (bestBid.length) {
                participantStr = translations.summary_status.tied;
              }
            }
          }
        }
      }
      let tdForDeliveryDate = <td>-</td>;
      let tdForLeadTime = <td>-</td>;
      if (bestBid.length > 0) {
        bestBid[0].bid_line_item_components.map((item) => {
          if (item.lot_component_title === "Achievable delivery date") {
            tdForDeliveryDate =
              item.attribute_value === "Yes" ? (
                <td key={item.id} className="green-text">
                  {" "}
                  {translations.emarketplace.yes}
                </td>
              ) : (
                <td key={item.id} className="red-text">
                  {" "}
                  {translations.emarketplace.no}
                </td>
              );
          } else if (item.lot_component_title === "Lead time(working days)") {
            tdForLeadTime = <td key={item.id}>{item.price}</td>;
          }
        });
      }
      return (
        <tr key={lot.id}>
          <td>{data.name}</td>
          <td>
            {bestBid.length > 0
              ? formatData(
                  this.props.selectedLang,
                  data.currency.symbol,
                  _.round(bestBid[0].host_price * lot.quantity, 2)
                )
              : `${data.currency.symbol}`}
          </td>
          {tdForDeliveryDate}
          {tdForLeadTime}
          <td>{participantStr}</td>
        </tr>
      );
    }
    bestBid = this.bestBids(bidList, lot.id);
    if (bestBid.length === 1) {
      participantStr = bestBid[0].user_name;
    } else if (bestBid.length > 1) {
      participantStr = translations.summary_status.tied;
    }

    return (
      <tr key={lot.id}>
        <td>{data.name}</td>
        <td>
          {bestBid.length > 0
            ? formatData(
                this.props.selectedLang,
                data.currency.symbol,
                _.round(bestBid[0].host_price * lot.quantity, 2)
              )
            : `${data.currency.symbol}`}
        </td>
        <td>{participantStr}</td>
      </tr>
    );
  }

  getFormatedDeliveryDates(data) {
    let earliestDate = "";
    let latestDate = "";
    data.forEach((item) => {
      const lotComponentName = item.lot_component.name.toLowerCase();
      if (lotComponentName === "earliest delivery date") {
        earliestDate = item.line_item_components[0].permitted_attributes.formatted_date_value;
      } else if (lotComponentName === "latest delivery date") {
        latestDate = item.line_item_components[0].permitted_attributes.formatted_date_value;
      }
    });
    return `${earliestDate} - ${latestDate}`;
  }

  sortByLeadTime(bids) {
    const minLeadTime = Math.min(...bids.map((bid) => bid["Lead time(working days)"]));
    return bids.filter((bid) => bid["Lead time(working days)"] === minLeadTime);
  }

  bestBids(bidList, lotId) {
    let bids = [];
    let rank = null;
    if (bidList.length > 0) {
      const data = _.maxBy(bidList, "rank");
      rank = data ? data.rank : rank;
      if (rank) {
        _.forEach(bidList, (value) => {
          if (lotId === value.lot_id && !value.rejected_lot && value.rank !== null) {
            if (rank === value.rank) {
              bids.push(value);
            } else if (value.rank !== null && rank > value.rank) {
              bids = [];
              bids.push(value);
              ({ rank } = value);
            }
          }
        });
      }
    }
    return bids;
  }

  async chnageDataFormat(data) {
    await data.forEach(async (lot) => {
      await _.forEach(lot.bids, (bid) => {
        bid.bid_line_item_components.forEach((item) => {
          if (item.lot_component_title === "Lead time(working days)") {
            bid[item.lot_component_title] = item.price;
          } else if (item.lot_component_title === "Achievable delivery date") {
            bid[item.lot_component_title] = item.attribute_value;
          }
        });
      });
    });
    return data;
  }

  async changeSortingView(flag) {
    this.setState({
      showLoader: true,
    });
    const orderBy = flag;
    const data = await this.chnageDataFormat(this.state.lots);
    const bestBidsList = _.map(data, (obj, idx) => this.getBestBids(obj.lot, idx, orderBy, obj.bids));
    this.setState({
      orderBy,
      bestBidsList,
      showLoader: false,
    });
  }
  render() {
    const { translations } = this.props;
    let data = [];
    if (this.state.lots.length) {
      data = this.state.lots;
    } else {
      data = this.props.emSummary.bids_summary ? this.props.emSummary.bids_summary.lots : [];
    }
    return (
      <Loader
        show={this.state.showLoader}
        message={<LoaderMessage loaderText={translations.loader_text} />}
        disableDefaultStyles
      >
        <div className="category_holder" id="choose_category">
          <a className="category_title active" data-toggle="collapse" href="#collapseSummary">
            {translations.summary_status.summary.summary}
          </a>
          <div className="panel-collapse collapse" id="collapseSummary">
            {this.state.hasDeliveryDateView && (
              <div className="btn-group button-status button-current price-sort-btn">
                <span>{translations.emarketplace.sort_by}</span>
                <div className="btn-group button-status button-current">
                  <button
                    type="button"
                    className="dropdown-toggle status-event-summary current form-control"
                    data-toggle="dropdown"
                  >
                    {this.state.orderBy === "Price"
                      ? translations.emarketplace.price
                      : translations.emarketplace.delivery}
                    <span className="caret" />
                  </button>
                  <ul className="dropdown-menu" role="menu">
                    <li>
                      <button
                        onClick={() => {
                          this.changeSortingView("Price");
                        }}
                      >
                        {translations.emarketplace.price}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          this.changeSortingView("Delivery");
                        }}
                      >
                        {translations.emarketplace.delivery}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <div className="table_holder">
              {data.length > 0 && (
                <table name="summary_table" cellPadding="0" cellSpacing="0" border="0">
                  <thead>
                    {this.state.hasDeliveryDateView ? (
                      <tr>
                        <th>{translations.summary_status.summary.lot_name}</th>
                        <th>{translations.summary_status.summary.best_bid}</th>
                        <th>
                          {translations.emarketplace.achievable_delivery_date} <br /> (
                          {this.getFormatedDeliveryDates(data[0].detail.data)})
                        </th>
                        <th>{translations.emarketplace.lead_time}</th>
                        <th>{translations.summary_status.summary.participant_name}</th>
                      </tr>
                    ) : (
                      <tr>
                        <th>{translations.summary_status.summary.lot_name}</th>
                        <th>{translations.summary_status.summary.best_bid}</th>
                        <th>{translations.summary_status.summary.participant_name}</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>{this.state.bestBidsList}</tbody>
                </table>
              )}
              {!this.state.showLoader && !data.length && (
                <div className="details_form_box text-center">{translations.emarketplace.summary_status.no_bids}</div>
              )}
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLang: state.changeLanguageReducer,
  emSummary: state.emSummaryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getBidsByRequestId: (requestId) => dispatch(getBidsByRequestId(requestId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
