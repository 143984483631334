import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AllParticipantsBestBidForLotModal from "../modal/AllParticipantsBestBidForLotModal";
import { isPrice, isRankedCell, findUom } from "../../../common";
import { fetchBlicsDetails } from "../../../../../actions/bidLineItemComponentActions";

/**
 *
 * @description Host monitor
 *
 * Column - Best Bid -> Best Bid Modal
 *
 */
export default function LiDetailModal(props) {
  const dispatch = useDispatch();
  const { licCurrency, showOverallScore, allUom, lineItemBestBlics, lineItemLic, event, auctionTab } = props;
  const { loaderReducers, bidLineItemComponentReducers } = useSelector((state) => state);
  const { isFetchBlicsLoading } = loaderReducers;

  const uom = findUom(lineItemLic?.unit_of_measure_id, allUom);

  const fetchModalDetails = (showOverallScore) => {
    dispatch(fetchBlicsDetails(lineItemLic.id, !auctionTab, showOverallScore, event.id, null, auctionTab));
  };

  return (
    <AllParticipantsBestBidForLotModal
      {...props}
      {...bidLineItemComponentReducers}
      isPrice={isPrice}
      isRankedCell={isRankedCell}
      uom={uom}
      apiCallingFunction={() => fetchModalDetails(showOverallScore)}
      modalType="LineItem"
      lic={lineItemLic}
      isLoading={isFetchBlicsLoading}
      currency={licCurrency}
      bidBlics={lineItemBestBlics}
    />
  );
}

LiDetailModal.defaultProps = {
  showOverallScore: false,
};
