import moment from "moment";
import React from "react";
import PropTypes from "prop-types";

const Date = ({ dateTime }) => {
  return <td>{moment.utc(moment(dateTime)).format("DD MMMM YYYY")}</td>;
};

Date.propTypes = {
  dateTime: PropTypes.string,
};

export default Date;
