import PropTypes from "prop-types";

const MessagePropType = {
  body: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  record_id: PropTypes.number.isRequired,
  record_type: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
};

export const LiveFeedMessagePropType = {
  bid_id: PropTypes.number,
  bid_round: PropTypes.number,
  bounced: PropTypes.bool.isRequired,
  created_at: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  message: PropTypes.oneOfType([PropTypes.shape(MessagePropType), PropTypes.string]),
  message_reply_required: PropTypes.any,
  message_subject_id: PropTypes.number,
  message_subject_type: PropTypes.string.isRequired,
  parent_id: PropTypes.string,
  sim_dojo_event_participant_id: PropTypes.number,
  system_message_type: PropTypes.string.isRequired,
  updated_at: PropTypes.string,
  user_id: PropTypes.number.isRequired,
};

export default LiveFeedMessagePropType;
