import React, { useMemo } from "react";
import { clsx } from "clsx";
import { Checkbox } from "@/cl/checkbox";
import { useItems } from "./items-context";
import { t } from "@/i18n";
import { getCheckedGroups } from "./Input";
import { isIndeterminate, useParentSelect, useSelectItem } from "./use-select-item";
import type { CheckedItem, Item } from "./types";

import styles from "./multi_level_select.module.scss";

export interface ButtonLevelProps {
  filteredItems: Item[];
  onClick: (item: Item) => void;
  selectedLevels: string[];
}

export interface ButtonWithCheckboxProps extends Omit<ButtonLevelProps, "filteredItems"> {
  filteredItem: Item;
}

const useSelectAll = ({ filteredItems, checkedItems }: { filteredItems: Item[]; checkedItems: CheckedItem[] }) => {
  const parentItemCount = useMemo(
    () => filteredItems?.map((filteredItem) => filteredItem.label).length,
    [filteredItems]
  );
  const selectedGroupCount = useMemo(() => getCheckedGroups(checkedItems).length, [checkedItems]);

  const areAllSelected = parentItemCount === selectedGroupCount;
  const indeterminate = isIndeterminate(areAllSelected, checkedItems);

  return { areAllSelected, indeterminate };
};

export function ButtonLevel({ filteredItems, onClick, selectedLevels }: ButtonLevelProps) {
  const { checkedItems, setCheckedItems } = useItems();
  const { areAllSelected, indeterminate } = useSelectAll({ filteredItems, checkedItems });

  if (filteredItems.length === 0) return null;

  const selectAll = (checked: boolean) => {
    if (selectedLevels.length === 0) onClick(filteredItems[0]);

    const itemsToBeChecked = checked
      ? filteredItems.map((filteredItem) => ({ group: filteredItem.label, item: filteredItem }))
      : [];
    setCheckedItems(itemsToBeChecked);
  };

  return (
    <div className="p-2 w-50 d-flex flex-column">
      <Checkbox
        name=""
        id={`select-all-${filteredItems[0].type}`}
        label={t("select_all")}
        onChange={(e) => selectAll(e.target.checked)}
        checked={areAllSelected}
        isIndeterminate={indeterminate}
      />
      <div className={`flex-grow-1 overflow-auto ${styles.slimScroll}`}>
        {filteredItems.map((filteredItem) => (
          <div key={filteredItem.label}>
            <ButtonWithCheckbox filteredItem={filteredItem} onClick={onClick} selectedLevels={selectedLevels} />
          </div>
        ))}
      </div>
    </div>
  );
}

const ButtonWithCheckbox = ({ filteredItem, onClick, selectedLevels }: ButtonWithCheckboxProps) => {
  const stackItem = {
    parent: filteredItem,
    items: filteredItem.children as Item[],
  };
  const { onParentChange } = useSelectItem(stackItem);
  const { parentChecked, indeterminate } = useParentSelect(stackItem);

  return (
    <button
      className={clsx("d-flex align-items-center", styles.button, {
        [styles.buttonActive]: selectedLevels.includes(filteredItem.label),
      })}
      type="button"
      onClick={() => {
        onClick(filteredItem);
      }}
    >
      <Checkbox
        name=""
        id={filteredItem.label.split(" ").join("-")}
        isIndeterminate={indeterminate}
        onChange={(e) => onParentChange(e.target.checked)}
        checked={parentChecked}
      />
      <span className="ps-2">{filteredItem.label}</span>
    </button>
  );
};
