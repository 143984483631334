import io from 'socket.io-client';
import cookies from 'browser-cookies';
import config from './config';

const connectToWebsocket = (eventId, userId) => {
  const isProduction = process.env.NODE_ENV === 'production';
  const url = isProduction ? config.DOMAIN_URL : 'http://localhost:9292';
  const SocketIOoptions = {
    query: `event_id=${eventId}&auth_headers=${cookies.get('auth_headers')}&user_id=${userId}`,
    transports: ['websocket', 'flashsocket', 'jsonp-polling', 'xhr-polling', 'htmlfile'],
    'try multiple transports': true,
    reconnect: true,
    'reconnection delay': 500,
    'max reconnection attempts': 20,
    secure: isProduction,
    'force new connection': true,
  };

  const socket = io.connect(`${url}/events`, SocketIOoptions);

  const connectToRoom = () => {
    // eslint-disable-next-line no-console
    console.log('connected to room');
    socket.emit('room', `event_monitor_${eventId}`);
    socket.authenticated = true;
  };

  socket.on('connect', connectToRoom);
  socket.on('disconnect', () => {
    // eslint-disable-next-line no-console
    console.log('disconnected');
    socket.authenticated = false;
  });
  socket.on('error', (reason) => {
    // eslint-disable-next-line no-console
    console.error('Unable to connect Socket.IO', reason);
  });

  return socket;
};

export default connectToWebsocket;
