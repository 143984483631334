import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "searchButton",
    "naicsCodeSearchInput",
    "naicsErrorMsg"
  ];

  /**
   * @type {HTMLInputElement[]}
   */
  inputFields = [];

  connect() {
    this.inputFields = this.getInputFields();
    this.inputFields.forEach(input => input.addEventListener("input", this.handleInputChanges));

    this.observer = this.observeLazyLoadedInputs();
  }

  /**
   * Selects all relevant input fields (excluding hidden and checkbox types).
   * Excluding hidden type inputs because we are not considering authentication_token input.
   * Excluding checkbox type inputs because these will be handled when LazyLoadedInputs are added (continents and sectors, sub-sectors).
   * @returns {HTMLInputElement[]}
   */
  getInputFields() {
    return Array.from(this.element.querySelectorAll("input")).filter(input => input.type !== "hidden" && input.type !== "checkbox");
  }

  /**
   * Observes dynamically added checkboxes inside specific sections (sectors, sub-sectors, and continents).
   * We use this method because these checkboxes are added after the initial DOM load (lazy-loaded content).
   * @returns {MutationObserver} The MutationObserver instance used to observe DOM changes.
   */
  observeLazyLoadedInputs() {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach(mutation => {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach(node => this.handleNewCheckboxes(node));
        }
      });
    });

    observer.observe(this.element, { childList: true, subtree: true });
    return observer;
  }

  /**
   * Handles newly added checkboxes for sectors, sub-sectors, and continents and adds event listeners to them.
   * These checkboxes are loaded after the DOM has loaded, so we need to process them when they appear.
   * @param {Node} node
   * @returns {void}
   */
  handleNewCheckboxes(node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const newCheckboxes = Array.from(node.querySelectorAll("input[type='checkbox']")).filter(input =>
        input.closest("#location-dropdown-placeholder") || input.closest("#industry-dropdown-placeholder")
      );

      newCheckboxes.forEach(checkbox => {
        checkbox.addEventListener("input", this.handleInputChanges);
      });
    }
  }

  /**
   * Checks if any input has a value or if any checkbox is selected.
   * Updates the search button enabled/disabled state based on input values.
   * Filters out the NAICS code input as it is validated separately.
   * @returns {void}
   */
  handleInputChanges = () => {
    this.searchButtonTarget.disabled = !this.#hasValidInput(
      this.inputFields.filter(({ id }) => id !== "search_naics_code")
    );
    this.#validateAndHandleNaicsCode();
  };

  /**
   * Checks if any input or checkbox has a valid value.
   * @returns {boolean} True if valid input exists, otherwise false
   */
  #hasValidInput = (inputFields) => {
    const hasTextInputValue = inputFields.some(input => input.type !== "checkbox" && input.value.trim() !== "");
    const selectionCountElements = this.element.querySelectorAll(".selection-count");

    const hasSomeSelectedValues = Array.from(selectionCountElements).some((element) => {
      const value = parseInt(element.textContent);
      return value > 0;
    });

    return hasTextInputValue || hasSomeSelectedValues;
  }

  /**
   * Validates the NAICS code and manages the error message visibility.
   * @returns {void}
   */
  #validateAndHandleNaicsCode = () => {
    this.#allowOnlyNumberInput(this.#getNaicsCodeInput().value);

    const naicsValue = this.naicsCodeSearchInputTarget.value;
    naicsValue ? this.#checkValidNaicsCode(naicsValue) : this.naicsErrorMsgTarget.classList.add("hidden");
  }

  #getNaicsCodeInput = () => this.inputFields.find(({ id }) => id === "search_naics_code");
  #hasNAICSCode = (value) => value.trim() !== "";
  #isValidNAICS = (value) => {
    const NAICSCodeLength = value.length;
    return [2, 4, 6].includes(NAICSCodeLength);
  };

  /**
   * Checks the validity of the NAICS code and manages the error message.
   * @returns {void}
   */
  #checkValidNaicsCode = (value) => {
    const naicsCodeValue = this.naicsCodeSearchInputTarget.value;
    const hasNAICSCode = this.#hasNAICSCode(naicsCodeValue);
    const shouldHideError = !hasNAICSCode || (hasNAICSCode && this.#isValidNAICS(naicsCodeValue));

    this.naicsErrorMsgTarget.classList.toggle("hidden", shouldHideError);
    this.searchButtonTarget.disabled = !shouldHideError || !hasNAICSCode;
  }

  /**
   * Will not allow user to give input other than numbers.
   * @returns {void}
   */
  #allowOnlyNumberInput = (value) => {
    this.naicsCodeSearchInputTarget.value = value.replace(/[^0-9]/g, "");
  }

  /**
   * Remove event listener and disconnects the observer.
   * @returns {void}
   */
  disconnect() {
    this.inputFields.forEach(input => input.removeEventListener("input", this.handleInputChanges));
    if (this.observer) this.observer.disconnect();
  }
}
