import * as apiCall from "./api";
import config from "../config";
import * as actionTypes from "./actionsTypes";
import { getError } from "./errorActions";

const createLineItemSuccess = (data) => {
  return {
    type: actionTypes.CREATE_LINE_ITEM,
    payload: {
      lineItem: data.line_item,
      lineItemComponents: data.line_item_components,
    },
  };
};

const updateLineItemSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_LINE_ITEM,
    payload: {
      lineItem: data.line_item,
    },
  };
};
const deleteLineItemSuccess = (data) => {
  return {
    type: actionTypes.DELETE_LINE_ITEM,
    payload: {
      lots: data.lots,
      lineItems: data.line_items,
      lotComponents: data.lot_components,
      lineItemComponents: data.line_item_components,
    },
  };
};

export const createLineItem = (lotId, payload) => async (dispatch) =>
  await apiCall
    .post(`${config.V2_BASE_URL}/lots/${lotId}/line_items`, payload)
    .then((response) => dispatch(createLineItemSuccess(response.data)))
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const updateLineItem = (lotId, lineItemId, payload) => async (dispatch) =>
  await apiCall
    .put(`${config.V2_BASE_URL}/lots/${lotId}/line_items/${lineItemId}`, payload)
    .then((response) => {
      dispatch(updateLineItemSuccess(response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });

export const deleteLineItem = (lineItem) => (dispatch) =>
  apiCall
    .remove(`${config.V2_BASE_URL}/lots/${lineItem.lot_id}/line_items/${lineItem.id}`)
    .then((response) => {
      dispatch(deleteLineItemSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getError(error.response));
    });
