import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import useDebounceState from "../useDebounceState";
import { useSliceActions, useSliceSelector } from "@/context/SliceContext";
import useInfiniteLoader from "../useInfiniteLoader";
import { getCompanyUserPermissions } from "@/actions/adminActions";

export default function useHostPermissionsFetch(companyId, categoryDojo) {
  const dispatch = useDispatch();
  const lastElementRef = useRef();
  const searchRef = useRef("");
  const [search, debouncedSearch, setSearch] = useDebounceState("", 300);
  const { currentPage } = useSliceSelector();
  const { resetPaginationState, setPaginationState } = useSliceActions();

  useInfiniteLoader(lastElementRef);

  useEffect(() => {
    const fetchData = async () => {
      if (searchRef.current !== debouncedSearch) {
        searchRef.current = debouncedSearch;
        dispatch(resetPaginationState());
      } else dispatch(setPaginationState({ isLoading: true }));

      const data = await dispatch(getCompanyUserPermissions(companyId, categoryDojo, currentPage, debouncedSearch));

      dispatch(setPaginationState({ isLoading: false, hasMoreData: Boolean(data.length) }));
    };

    fetchData();
  }, [categoryDojo, companyId, currentPage, debouncedSearch, dispatch, resetPaginationState, setPaginationState]);

  return { lastElementRef, setSearch, search };
}
