import flatpickr from "flatpickr";

import FlatPickerSelect from "./select-plugin";
import ShortcutButtonsPlugin from "./footer-buttons-plugin";
import { languages } from "./locale";

import "flatpickr/dist/flatpickr.css";
import "shortcut-buttons-flatpickr/dist/themes/light.css";
import "./styles.scss";

const availableLanguages = Object.keys(languages).map((key) => key.replace(/\.\/(.*)\.js/, "$1"));
const languageInCookie = document.cookie.match("locale=(\\w+)");
const currentLanguage = languageInCookie?.[1] ?? "default";
const languageToUse = availableLanguages.includes(currentLanguage) ? currentLanguage : "default";

document.addEventListener("DOMContentLoaded", () => {
  for (const element of document.querySelectorAll(".flatpicker-date-range")) {
    languages[languageToUse]().then((lang) => {
      flatpickr(element, {
        mode: "range",
        locale: lang.default[languageToUse] || lang.default,
        dateFormat: "Y-m-d",
        plugins: [
          ShortcutButtonsPlugin({ save: element.dataset.save, reset: element.dataset.reset }),
          FlatPickerSelect({ select: element.dataset.selectId }),
        ],
      });
    });
  }
});
