import React from "react";
import HeaderForJapaneseAndLotLevelEvent from "../HeaderForJapaneseAndLotLevelEvent";

const Header = (props) => {
  const current_bid = "current_bid_level_total";
  const values = [
    ["lot", "lot_position", "lot"],
    ["lot_name", "lot_lot_name", "lotname"],
    ["quantity_uom", "quantity_uom", "quantity"],
    ["your_bid", "bid_price", "price"],
    ["current_bid_level_uom", "current_bid_level_uom", "current-bid"],
    [current_bid, current_bid, "current-total"],
    // tool-tip text for currency is not defined yet
    // TODO: replace "" with key for currency text if availabel
    ["currency", "", "jap-currency"],
    ["accept_decline", "accept_decline", "jap-buttons"],
  ];
  return <HeaderForJapaneseAndLotLevelEvent translations={props.translations} values={values} />;
};
export default Header;
