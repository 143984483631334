import React from "react";
import Tooltip from "../../../../../../common/Tooltip";
export default (props) => {
  const { translations, event, weightedRfqOrAuction, beforeAuction, preAuctionRanksIntact } = props;
  const {
    lot_position,
    lot_name,
    number_of_bids_placed,
    latest_bid_price,
    latest_bid_value,
    difference_value,
    difference_percentage,
    weighted_rank,
    rank,
    user_status,
  } = translations;
  const {
    event_name,
    lot_lot_name,
    lot_bids_received,
    bid_latest_price,
    bid_latest_value,
    lot_total_lot_difference,
    lot_total_lot_difference_percentage,
    bid_rank,
    bid_user_status,
  } = translations.infos;
  const showHeaderForRank = () => {
    let result;
    if (!beforeAuction || (beforeAuction && preAuctionRanksIntact)) {
      const val = weightedRfqOrAuction ? weighted_rank : rank;
      result = (
        <th className="rfq-thead-popup w-8 font-s12">
          {val}&nbsp;
          <Tooltip title={bid_rank.title} body={bid_rank.body} />
        </th>
      );
    } else {
      result = (
        <th className="w-8 font-s12">
          {user_status}&nbsp;
          <Tooltip title={bid_user_status.title} body={bid_user_status.body} />
        </th>
      );
    }
    return result;
  };
  return (
    <thead>
      <tr>
        <th className="rfq-thead-popup w-8 font-s12">
          {lot_position}&nbsp;
          <Tooltip title={event_name.title} body={event_name.body} />
        </th>
        <th className="rfq-thead-popup wid-15 font-s12">
          {lot_name}&nbsp;
          <Tooltip title={lot_lot_name.title} body={lot_lot_name.body} />
        </th>
        <th className="rfq-thead-popup wid-8 font-s12">
          {number_of_bids_placed}&nbsp;
          <Tooltip title={lot_bids_received.title} body={lot_bids_received.body} />
        </th>
        {showHeaderForRank()}
        <th className="rfq-thead-popup wid-17 font-s12">
          {latest_bid_price}&nbsp;
          <Tooltip title={bid_latest_price.title} body={bid_latest_price.body} />
        </th>
        <th className="rfq-thead-popup wid-15 font-s12">
          {latest_bid_value}&nbsp;
          <Tooltip title={bid_latest_value.title} body={bid_latest_value.body} />
        </th>
        <th className="rfq-thead-popup wid-13 font-s12">
          {difference_value}&nbsp;
          <Tooltip title={lot_total_lot_difference.title} body={lot_total_lot_difference.body} />
        </th>
        <th className="rfq-thead-popup wid-10 font-s12">
          {difference_percentage}&nbsp;
          <Tooltip title={lot_total_lot_difference_percentage.title} body={lot_total_lot_difference_percentage.body} />
        </th>
        <th className="rfq-thead-popup wid-8 font-s12"></th>
        <th className="rfq-thead-popup wid-8 font-s12"></th>
      </tr>
    </thead>
  );
};
